import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

import { IValidationError } from './types';
import { ValidationErrorDialogComponent } from './validation-error-dialog.component';

export type ValidationErrorDialogRef =
  MatDialogRef<ValidationErrorDialogComponent>;
export type ValidationErrorDialogConfig = MatDialogConfig<IValidationError>;

/**
 * Service to simply open up the validation error dialog.
 */
@Injectable({ providedIn: 'root' })
export class ValidationErrorDialogService {
  constructor(private _matDialog: MatDialog) {}

  open(config: ValidationErrorDialogConfig): ValidationErrorDialogRef {
    return this._matDialog.open(ValidationErrorDialogComponent, {
      ...config,
      panelClass: [
        'mg-bordered-dialog',
        'mg-no-padding-dialog',
        'mg-light-border-dialog',
      ],
      backdropClass: 'mg-dialog-backdrop-blue',
    });
  }
}
