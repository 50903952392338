import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModalOverlayService } from '@shared/components/modal-overlay';

import { HpmDashboardEndTimePipe } from './hpm-dashboard-endtime.pipe';
import { HpmDashboardCountDownPipe } from './hpm-dashboard-status.pipe';

@NgModule({
  imports: [
    // Minga dependencies
    CommonModule,
  ],
  exports: [HpmDashboardCountDownPipe, HpmDashboardEndTimePipe],
  declarations: [HpmDashboardCountDownPipe, HpmDashboardEndTimePipe],
  providers: [ModalOverlayService],
})
export class HpmDashboardPipesModule {}
