import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BmReportsStaffDatasourceService } from '../../services';

@Component({
  selector: 'mg-bm-reports-staff',
  templateUrl: './bm-reports-staff.component.html',
  styleUrls: ['./bm-reports-staff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BmReportsStaffDatasourceService],
})
export class BmReportsStaffComponent {
  constructor(public ds: BmReportsStaffDatasourceService) {}
}
