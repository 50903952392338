<div
  fxLayout="row"
  fxLayoutAlign="space-between center">
  <mg-text
    variant="body-md"
    [color]="option.disabled ? 'on-disabled-bg' : 'surface'">
    {{ option.label }}
  </mg-text>
  <mg-text
    variant="label-md"
    color="surface-alt"
    *ngIf="option.contextLabel">
    {{ option.contextLabel }}
  </mg-text>
</div>
