<div class="legacy">
  <ng-container *ngIf="action">
    <div class="legacy-action">
      <ng-container *ngTemplateOutlet="action"></ng-container>
    </div>
  </ng-container>
  <section class="legacy-content">
    <ng-content></ng-content>
  </section>
</div>
