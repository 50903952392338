import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DesignedSvgViewerComponent } from './DesignedSvgViewer.component';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    CommonModule,
  ],
  declarations: [DesignedSvgViewerComponent],
  exports: [DesignedSvgViewerComponent],
})
export class DesignedSvgViewerModule {}
