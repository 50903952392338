import { Injectable } from '@angular/core';

import { makeRatingRequest } from '@lib/cordova/launch-review';
import { merge } from 'rxjs';

import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { UserStorage } from 'minga/app/src/app/services/UserStorage';

// about 3 months is how long we should wait before showing the dialog to this
// device again.
const MAX_TIME_SINCE_LAST_VIEW = 3 * 30 * 24 * 60 * 60 * 1000;
@Injectable({ providedIn: 'root' })
export class AppRateService {
  private showRateApp: boolean = false;
  private lastSeenDate: Date | null = null;

  constructor(
    private userStorage: UserStorage,
    private contentEvents: ContentEvents,
    private analyticsService: AnalyticsService,
  ) {
    merge(
      this.contentEvents.onCommentSuccess,
      this.contentEvents.onContentEventGoingSuccess,
      this.contentEvents.onPostCreateSuccess,
      this.contentEvents.onPollAnswer,
      this.contentEvents.onAddLike,
    ).subscribe(() => {
      this.checkShouldShowAppReviewDialog();
    });
  }

  setShowRateApp(showRateAppDialog: boolean) {
    this.showRateApp = showRateAppDialog;
  }

  async checkShouldShowAppReviewDialog() {
    // user has not met criteria for showing yet.
    if (!this.showRateApp) {
      return;
    }
    const now = new Date();
    this.lastSeenDate = await this.getLastSeenRatingDialog();
    if (
      now.valueOf() - this.lastSeenDate.valueOf() >
      MAX_TIME_SINCE_LAST_VIEW
    ) {
      const wasSeen = await makeRatingRequest();
      if (wasSeen) {
        // don't want to bother running all this for every other event from now
        // on.
        this.showRateApp = false;
        await this.setLastSeenRatingDialog();
      }
    }
  }

  private async getLastSeenRatingDialog(): Promise<Date> {
    if (this.lastSeenDate) {
      return this.lastSeenDate;
    }
    const lastSeenDate = await this.userStorage.getItem<Date>(
      'lastSeenRatingDialog',
    );
    return lastSeenDate || new Date(0);
  }

  private async setLastSeenRatingDialog() {
    this.analyticsService.sendAppRatingViewed();
    await this.userStorage.setItem<Date>('lastSeenRatingDialog', new Date());
  }
}
