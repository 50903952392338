import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgIconModule } from 'minga/app/src/app/icon';
import { ActivateDeactivateToggleButtonModule } from 'src/app/components/ActivateDeactivateToggleButton';
import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgFormFieldModule } from 'src/app/form-field';
import { PermissionsModule } from 'src/app/permissions';
import { MgPipesModule } from 'src/app/pipes';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { MembershipListTableModule } from '@shared/components/membership-list-table';
import {
  ModalOverlayService,
  OverlayModule,
} from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text';
import { TooltipModule } from '@shared/components/tooltip';
import { WeekdayToggleModule } from '@shared/components/weekday-toggle';

import { HpmRestrictionsBlackoutEditComponent } from './components/hpm-restrictions-blackout-edit/hpm-restrictions-blackout-edit.component';
import { HpmRestrictionsBlackoutComponent } from './components/hpm-restrictions-blackout/hpm-restrictions-blackout.component';
import { HpmRestrictionsEditComponent } from './components/hpm-restrictions-edit/hpm-restrictions-edit.component';
import { HpmRestrictionsComponent } from './hpm-restrictions.component';
import { HpmRestrictionsGetBlackoutDaysPipe } from './pipes';
import {
  HpmRestrictionsBlackoutService,
  HpmRestrictionsService,
} from './services';

@NgModule({
  imports: [
    // Minga depedencies
    MgIconModule,
    MgEmptyStateModule,
    ManagerOverlayModule,
    OverlayModule,
    PermissionsModule,
    ActivateDeactivateToggleButtonModule,
    MgEmptyStateModule,
    MgPipesModule,
    MgFormFieldModule,
    MgImageModule,
    FormModule,
    WeekdayToggleModule,
    TooltipModule,
    MembershipListTableModule,
    GenericModule,
    TextModule,

    // External depedencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatMenuModule,
  ],
  exports: [HpmRestrictionsComponent],
  declarations: [
    // Pipes
    HpmRestrictionsGetBlackoutDaysPipe,

    // Components
    HpmRestrictionsComponent,
    HpmRestrictionsEditComponent,
    HpmRestrictionsBlackoutComponent,
    HpmRestrictionsBlackoutEditComponent,
  ],
  providers: [
    HpmRestrictionsService,
    HpmRestrictionsBlackoutService,
    ModalOverlayService,
  ],
})
export class HpmRestrictionsModule {}
