import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SaveCancelDialog } from 'minga/app/src/app/dialog/SaveCancel';
import {
  IMingaLink,
  IMingaProfile,
  MingaManagerService,
} from 'minga/app/src/app/services/MingaManager';
import { IAddressModelValue } from 'src/app/components/Input/Address';
import { RootService } from 'src/app/minimal/services/RootService';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';
import { mgResolveImageUrl } from 'src/app/util/asset';
import { completeUrl } from 'src/app/util/link';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  MINGA_PROFILE_FORM_GROUP,
  MingaProfileFormFields,
  MingaProfileMessage,
} from './constants';
import { MingaProfileService } from './service';
import { UpdateMingaProfilePayload } from './types';

@Component({
  selector: 'mg-minga-profile',
  templateUrl: './minga-profile.component.html',
  styleUrls: ['./minga-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MingaProfileService],
})
export class MingaProfileComponent implements OnInit, OnDestroy {
  // Constants

  public readonly MSG = MingaProfileMessage;
  public readonly FORM_FIELDS = MingaProfileFormFields;

  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Form

  public readonly form = this._fb.group(MINGA_PROFILE_FORM_GROUP);
  public newAddress: string;

  // State

  private readonly _isLoadingSubject = new BehaviorSubject<boolean>(true);
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  /** Is editing details */
  private readonly _isEditingSubject = new BehaviorSubject<boolean>(false);
  public readonly isEditing$ = this._isEditingSubject.asObservable();

  // Computed getters

  get enableDetailsControls() {
    const name = this.form.controls[MingaProfileFormFields.NAME];
    const address = this.form.controls[MingaProfileFormFields.ADDRESS];
    return (name.dirty && name.valid) || (address.dirty && address.valid);
  }

  // Misc

  public logoInputFiles: File[] = [];
  public uploadFormControl: FormControl;

  /** Component Constructor */
  constructor(
    public mingaProfile: MingaProfileService,
    private _mingaManager: MingaManagerService,
    private _systemAlertSnackbar: SystemAlertSnackBarService,
    private _mingaStore: MingaStoreFacadeService,
    private _root: RootService,
    private _dialog: MatDialog,
    private _fb: FormBuilder,
  ) {
    this.uploadFormControl = new FormControl();
    this.uploadFormControl.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.onUploadLogoDone(this.uploadFormControl.value);
      }
    });
  }

  ngOnInit(): void {
    this.mingaProfile.fetchMingaProfile();
    this.mingaProfile.data$
      .pipe(
        takeUntil(this._destroyedSubject),
        filter(v => !!v),
      )
      .subscribe(this._resetForm.bind(this));
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._isLoadingSubject.complete();
  }

  public async cancelChanges() {
    this._isEditingSubject.next(false);
    const profile = this.mingaProfile.getProfileData();
    this._resetForm(profile);
  }

  public getEditContactContext(
    type: 'phone' | 'website' | 'email',
    value: string,
  ) {
    switch (type) {
      case 'phone':
        const phoneField = this.form.controls[this.FORM_FIELDS.PHONE];
        return {
          $implicit: value || this.MSG.ADD_PHONE_LABEL,
          icon: 'mg-phone',
          handler: () => this.changeLink('phone', value, phoneField),
        };
      case 'website':
        const websiteField = this.form.controls[this.FORM_FIELDS.WEBSITE];
        return {
          $implicit: value || this.MSG.ADD_WEBSITE_LABEL,
          icon: 'mg-link',
          handler: () => this.changeLink('website', value, websiteField),
        };
      case 'email':
        const emailField = this.form.controls[this.FORM_FIELDS.EMAIL];
        return {
          $implicit: value || this.MSG.ADD_EMAIL_LABEL,
          icon: 'mg-email',
          handler: () => this.changeLink('email', value, emailField),
        };
      default: {
        return {};
      }
    }
  }

  public getCustomLinkContext(link: IMingaLink) {
    return {
      $implicit: link.title,
      icon: link.icon,
      href: link.url,
      iconNameSpace: 'minga.profile',
      handler: () => this.editLink(link),
    };
  }

  public toggleEditMode(): void {
    const currentValue = this._isEditingSubject.getValue();
    this._isEditingSubject.next(!currentValue);
  }

  public async changeLink(
    type: 'phone' | 'website' | 'email',
    value: string,
    formControl: AbstractControl,
  ) {
    return new Promise((resolve, reject) => {
      const options = {
        data: {
          text:
            type === 'website'
              ? 'dialog.url.title'
              : type === 'email'
              ? 'dialog.email.title'
              : 'dialog.phone.title',
          inputPlaceholder:
            type === 'website'
              ? 'dialog.url.placeholder'
              : type === 'email'
              ? 'dialog.email.placeholder'
              : 'dialog.phone.placeholder',
          saveButtonLocale: 'button.save',
          inputType: 'text',
          inputValue: value,
          inputControl: formControl,
          textParams: 'Website Address',
        },
      };
      const dialog = this._dialog.open(SaveCancelDialog, options);
      dialog.afterClosed().subscribe(result => {
        if (typeof result == 'string') {
          switch (type) {
            case 'phone':
              this.form.controls[this.FORM_FIELDS.PHONE].setValue(result);
              break;
            case 'website':
              const url = completeUrl(result);
              this.form.controls[this.FORM_FIELDS.WEBSITE].setValue(url);
              break;
            case 'email':
              this.form.controls[this.FORM_FIELDS.EMAIL].setValue(result);
              break;
          }
          this.updateProfile();
        }
      });
    });
  }

  public async updateProfile() {
    try {
      const changes = this._getUpdateMingaProfilePayload();
      await this._root.addLoadingPromise(
        this.mingaProfile.updateMingaProfile(changes),
      );
      this._isEditingSubject.next(false);
    } catch (err) {}
  }

  public async addNewLink() {
    await this.mingaProfile.openMingaLinkForm(null);
  }

  public async editLink(link: IMingaLink) {
    await this.mingaProfile.openMingaLinkForm({ link });
  }

  public onUploadLogoDone(asset) {
    return this._onUploadLogoDone(asset).then(
      () => {
        this.mingaProfile.fetchMingaProfile();
      },
      err => {
        console.error(err);
      },
    );
  }

  public updateAddress(addressModel: IAddressModelValue) {
    this.form.controls[this.FORM_FIELDS.ADDRESS].setValue(
      addressModel.place_id,
    );
  }

  private async _onUploadLogoDone(asset) {
    const response = await this._mingaManager.updateMingaImage(null, asset);
    const status = response.getStatus();

    if (status === 0) {
      this._systemAlertSnackbar.success(`Minga Logo has been updated.`);

      const profileImageInfo = response.getMingaImageInfo();
      if (profileImageInfo)
        this._mingaStore.setMingaImageUrl(mgResolveImageUrl(profileImageInfo));
    }
  }

  private _resetForm(profile: IMingaProfile) {
    if (profile == null) return;
    this.form.reset({
      [MingaProfileFormFields.NAME]: profile.name,
      [MingaProfileFormFields.ADDRESS]: profile.mingaAddress,
      [MingaProfileFormFields.EMAIL]: profile.email,
      [MingaProfileFormFields.PHONE]: profile.phone,
      [MingaProfileFormFields.WEBSITE]: profile.websiteUrl,
    });
  }

  private _getUpdateMingaProfilePayload() {
    return Object.keys(this.form.controls).reduce((acc, controlName) => {
      const control = this.form.controls[controlName];
      if (control.dirty) acc[controlName] = control.value;
      return acc;
    }, {} as UpdateMingaProfilePayload);
  }
}
