import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from 'minga/app/src/app/button';
import { AddressModule } from 'minga/app/src/app/components/Input/Address';
import { FileInputModule } from 'minga/app/src/app/components/Input/FileInput';
import { FileUploadModule } from 'minga/app/src/app/components/Input/FileUpload';
import { MingaIdentityModule } from 'minga/app/src/app/components/MingaIdentity';
import { SaveCancelDialogModule } from 'minga/app/src/app/dialog/SaveCancel';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { GenericModule } from '@shared/components/generic';
import { CommonFormModules } from '@shared/modules';

import { MingaProfileComponent } from './minga-profile.component';
import { MINGA_PROFILE_ROUTES } from './minga-profile.routes';

@NgModule({
  declarations: [MingaProfileComponent],
  imports: [
    // Routing
    RouterModule.forChild(MINGA_PROFILE_ROUTES),

    // Common Modules
    CommonFormModules,

    // Minga dependencies
    MgAvatarModule,
    FileInputModule,
    FileUploadModule,
    MgSpinnerModule,
    AddressModule,
    MgButtonModule,
    MgEllipsisModule,
    MgPipesModule,
    MingaIdentityModule,
    MgFormFieldModule,
    SaveCancelDialogModule,
    PermissionsModule,
    GenericModule,
    LayoutLegacyContentModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    FormsModule,
  ],
  exports: [RouterModule],
})
export class MingaProfileModule {}
