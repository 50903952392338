import { forwardRef } from '@angular/core';

import { ModalOverlayPrimaryHeaderBackground } from '@shared/components/modal-overlay';

import {
  PsArchiveService,
  PsAutomationService,
  PsBehaviorService,
  PsCheckInService,
  PsEventService,
  PsFlexTimeActivitiesService,
  PsGroupService,
  PsHallPassService,
  PsListMembershipService,
  PsPointsRewardService,
  PsPointsService,
} from '../services';
import { PsAutomationResetService } from '../services/forms/ps-automation-reset.service';
import { PsChallengeService } from '../services/forms/ps-challenge.service';
import { PsStandAloneService } from '../services/forms/ps-standalone.service';
import { PsTicketsService } from '../services/forms/ps-tickets.service';
import {
  PsAutomationResetData,
  PsBehaviorsData,
  PsChallengeData,
  PsCheckinData,
  PsEventData,
  PsFilterState,
  PsFilters,
  PsFlexTimeActivitiesData,
  PsGroupData,
  PsHallPassData,
  PsMembershipListData,
  PsMultiAutomationResetData,
  PsPointsData,
  PsPointsRewardData,
  PsSearchTools,
  PsStandAloneConfig,
  PsTicketsData,
} from '../types';
import {
  getPeopleSelectorInjectionToken,
  validatePeopleSelectorFormConfig,
} from '../utils';

export enum PeopleSelectorRoutes {
  ROOT = 'people-selector',
}

export enum PeopleSelectorMessages {
  SELECT_ALL = 'Select All',
  DESELECT_ALL = 'Deselect All',
  SELECT_ALL_TOGGLE = 'Select All / Clear',
  CANCEL_BUTTON = 'Cancel',
  SEARCH_BUTTON = 'Search',
  ERRORS_BUTTON = 'View Errors',
}

export enum PeopleSelectorTooltips {
  FLEXTIME_REGISTER = 'Registered students are able to change activity',
  FLEXTIME_ASSIGN = "Assigned students can't unregister themselves",
}

export const PS_FORM_FILTER_DEFAULT_STATE: PsFilterState = {
  keywords: '',
  grades: [],
  lists: [],
  roles: [],
  groups: [],
  schools: [],
  fileUploadedPeople: [],
};

export const PS_CAMERA_REOPEN_DELAY = 1500;

export const PS_CAMERA_NOT_FOUND_REOPEN_DELAY = 1500;

export const DEFAULT_PS_SEARCH_TOOLS: PsSearchTools[] = [
  'barcode',
  'camera',
  'file',
];

export const DEFAULT_PS_FORM_FILTERS: PsFilters[] = [
  'groups',
  'lists',
  'grades',
  'keywords',
  'roles',
];

export const PEOPLE_SELECTOR_FORMS = {
  Standalone: validatePeopleSelectorFormConfig({
    path: 'standalone',
    injectionToken: getPeopleSelectorInjectionToken('standalone'),
    service: forwardRef(() => PsStandAloneService),
    data: {} as PsStandAloneConfig,
    pages: {
      select: {
        path: 'select',
        type: 'search',
        text: {
          title: 'Select',
          submit: 'Select',
        },
      },
    },
  }),
  'Hall Pass': validatePeopleSelectorFormConfig({
    path: 'hall-pass',
    injectionToken: getPeopleSelectorInjectionToken('hall-pass'),
    service: forwardRef(() => PsHallPassService),
    theme: {
      primaryButton: 'yellow',
      headerBg: ModalOverlayPrimaryHeaderBackground.PINK,
    },
    data: {} as PsHallPassData,
    pages: {
      assign: {
        path: 'assign',
        type: 'search',
        text: {
          title: 'Assign',
          submit: 'Assign',
        },
      },
    },
  }),
  'FlexTime Activity': validatePeopleSelectorFormConfig({
    path: 'flextime-activities',
    injectionToken: getPeopleSelectorInjectionToken('flextime-activities'),
    service: forwardRef(() => PsFlexTimeActivitiesService),
    theme: {
      primaryButton: 'kiwi',
      headerBg: ModalOverlayPrimaryHeaderBackground.ALT_TEAL,
    },
    data: {} as PsFlexTimeActivitiesData,
    pages: {
      assign: {
        path: 'assign',
        type: 'search',
        text: {
          title: 'Assign',
          submit: 'Assign',
        },
        tooltip: PeopleSelectorTooltips.FLEXTIME_ASSIGN,
      },
      register: {
        path: 'register',
        type: 'search',
        text: {
          title: 'Register',
          submit: 'Register',
        },
        tooltip: PeopleSelectorTooltips.FLEXTIME_REGISTER,
      },
      remove: {
        path: 'remove',
        type: 'list',
        text: {
          title: 'Remove',
          submit: 'Remove',
        },
        searchTools: [],
        filters: ['keywords'],
      },
      checkin: {
        path: 'checkin',
        type: 'list',
        text: {
          title: 'Check In',
          submit: 'Check In',
        },
        searchTools: ['file', 'barcode', 'camera'],
        filters: ['keywords'],
      },
    },
  }),
  Archive: validatePeopleSelectorFormConfig({
    path: 'archive',
    injectionToken: getPeopleSelectorInjectionToken('archive'),
    service: forwardRef(() => PsArchiveService),
    pages: {
      add: {
        path: 'add',
        type: 'search',
        text: {
          title: 'Add',
          submit: 'Archive',
        },
      },
    },
  }),
  Behavior: validatePeopleSelectorFormConfig({
    path: 'behavior',
    injectionToken: getPeopleSelectorInjectionToken('behavior'),
    service: forwardRef(() => PsBehaviorService),
    theme: {
      primaryButton: 'pink',
      headerBg: ModalOverlayPrimaryHeaderBackground.GREEN,
    },
    data: {} as PsBehaviorsData,
    pages: {
      assign: {
        path: 'assign',
        type: 'search',
        text: {
          title: 'Assign',
          submit: 'Assign',
        },
      },
    },
  }),
  'Check In': validatePeopleSelectorFormConfig({
    path: 'checkin',
    injectionToken: getPeopleSelectorInjectionToken('checkin'),
    service: forwardRef(() => PsCheckInService),
    theme: {
      primaryButton: 'orange',
      headerBg: ModalOverlayPrimaryHeaderBackground.TEAL,
    },
    data: {} as PsCheckinData,
    pages: {
      add: {
        path: 'add',
        type: 'search',
        text: {
          title: 'Add',
          submit: 'Add',
        },
      },
    },
  }),
  Group: validatePeopleSelectorFormConfig({
    path: 'group',
    injectionToken: getPeopleSelectorInjectionToken('group'),
    service: forwardRef(() => PsGroupService),
    data: {} as PsGroupData,
    pages: {
      add: {
        path: 'add',
        type: 'search',
        text: {
          title: 'Add',
          submit: 'Add',
        },
      },
      remove: {
        path: 'remove',
        type: 'list',
        text: {
          title: 'Remove',
          submit: 'Remove',
        },
        searchTools: [],
        filters: ['keywords'],
      },
    },
  }),
  'List Membership': validatePeopleSelectorFormConfig({
    path: 'list-membership',
    injectionToken: getPeopleSelectorInjectionToken('list-membership'),
    service: forwardRef(() => PsListMembershipService),
    data: {} as PsMembershipListData,
    pages: {
      add: {
        path: 'add',
        type: 'search',
        text: {
          title: 'Add',
          submit: 'Add',
        },
      },
      remove: {
        path: 'remove',
        type: 'list',
        text: {
          title: 'Remove',
          submit: 'Remove',
        },
        searchTools: ['file'],
        filters: ['keywords'],
      },
    },
  }),
  Points: validatePeopleSelectorFormConfig({
    path: 'points',
    injectionToken: getPeopleSelectorInjectionToken('points'),
    service: forwardRef(() => PsPointsService),
    data: {} as PsPointsData,
    pages: {
      redeem: {
        path: 'redeem',
        type: 'search',
        text: {
          title: 'Redeem',
          submit: 'Assign',
        },
      },
    },
  }),
  Automation: validatePeopleSelectorFormConfig({
    path: 'automation',
    injectionToken: getPeopleSelectorInjectionToken('automation'),
    service: forwardRef(() => PsAutomationService),
    data: {} as PsAutomationResetData,
    pages: {
      reset: {
        path: 'reset',
        type: 'search',
        text: {
          title: 'Reset',
          submit: 'Reset',
        },
      },
    },
  }),
  Challenge: validatePeopleSelectorFormConfig({
    path: 'challenge',
    injectionToken: getPeopleSelectorInjectionToken('challenge'),
    service: forwardRef(() => PsChallengeService),
    data: {} as PsChallengeData,
    pages: {
      invite: {
        path: 'invite',
        type: 'search',
        text: {
          title: 'Invite',
          submit: 'Invite',
        },
      },
      invited: {
        path: 'invited',
        type: 'list',
        text: {
          title: 'Invited',
          submit: 'Invited',
        },
        isReadOnly: true,
      },
    },
  }),
  Event: validatePeopleSelectorFormConfig({
    path: 'event',
    injectionToken: getPeopleSelectorInjectionToken('event'),
    service: forwardRef(() => PsEventService),
    data: {} as PsEventData,
    pages: {
      checkin: {
        path: 'checkin',
        type: 'search',
        text: {
          title: 'Check in',
          submit: 'Check in',
        },
      },
      checkout: {
        path: 'checkout',
        type: 'list',
        text: {
          title: 'Check out',
          submit: 'Check out',
        },
      },
      invite: {
        path: 'invite',
        type: 'search',
        text: {
          title: 'Invite',
          submit: 'Invite',
        },
      },
    },
  }),
  'Points Reward': validatePeopleSelectorFormConfig({
    path: 'points-reward',
    injectionToken: getPeopleSelectorInjectionToken('points-reward'),
    service: forwardRef(() => PsPointsRewardService),
    data: {} as PsPointsRewardData,
    pages: {
      redeem: {
        path: 'redeem',
        type: 'search',
        text: {
          title: 'Redeem',
          submit: 'Redeem',
        },
        maxSelection: 1,
      },
    },
  }),
  'Automation Reset': validatePeopleSelectorFormConfig({
    path: 'automation-reset',
    injectionToken: getPeopleSelectorInjectionToken('automation-reset'),
    service: forwardRef(() => PsAutomationResetService),
    data: {} as PsMultiAutomationResetData,
    pages: {
      reset: {
        path: 'reset',
        type: 'search',
        text: {
          title: 'Update',
          submit: 'Update',
        },
      },
    },
  }),
  Tickets: validatePeopleSelectorFormConfig({
    path: 'tickets',
    injectionToken: getPeopleSelectorInjectionToken('tickets'),
    service: forwardRef(() => PsTicketsService),
    data: {} as PsTicketsData,
    pages: {
      add: {
        path: 'add',
        type: 'search',
        text: {
          title: 'Add',
          submit: 'Add',
        },
      },
    },
  }),
} as const;
