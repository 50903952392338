<div
  class="mg-person-list-element"
  [class.mg-show-content]="showContent">
  <div class="person-container">
    <mg-avatar
      class="author-avatar"
      [src]="person?.profileImageUrl"
      [color]="person?.badgeIconColor"
      [personHash]="person?.personHash"></mg-avatar>
    <mg-person-minimal
      [person]="person"
      [class.no-wrap]="noWrap"></mg-person-minimal>
  </div>
  <div class="content-wrap mg-minicopy">
    <ng-content></ng-content>
  </div>
</div>
