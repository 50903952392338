import { EventEmitter, Injectable } from '@angular/core';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import { StatusCode, UniqueHash } from 'minga/proto/common/legacy_pb';
import { ContentViews } from 'minga/proto/gateway/content_views_ng_grpc_pb';
import {
  ContentView,
  LoadContentRequest,
  LongAnnouncementCardView,
  LongBirthdayCardView,
  LongCardView,
  LongEventCardView,
  LongInspirationCardView,
  LongPollCardView,
} from 'minga/proto/gateway/content_views_pb';

export interface IRetrieveContentOptions {
  contentHash?: string;
  contextHash: string;
  storeData?: boolean;
}

/**
 * This class is for storying content data temporarily to be deleted once
 * consumed.
 */
@Injectable({ providedIn: 'root' })
export class ConsumableContentDataService {
  consumableDataMap: Map<string, ContentView.AsObject> = new Map();

  constructor(
    private contentViewsService: ContentViews,
    private rootService: RootService,
  ) {}

  /**
   * Store content data per a given key (context or content hash).
   */
  storeData(key: string, data: ContentView.AsObject): void {
    this.consumableDataMap.set(key, data);
  }

  /**
   * Get card content using cached data or if not available will retrieve the
   * data.
   */
  async getData(
    options: IRetrieveContentOptions,
  ): Promise<ContentView.AsObject | null> {
    const cachedData = this.consumeCachedData(options);

    if (cachedData) {
      return cachedData;
    }

    return await this.retrieveContent(options);
  }

  /**
   * Retrieve long card content from server using either a context or content
   * hash.
   */
  async retrieveContent(
    options: IRetrieveContentOptions,
  ): Promise<ContentView.AsObject | null> {
    let request = new LoadContentRequest();
    let uniqueHash = new UniqueHash();
    if (options.contextHash) {
      uniqueHash.setValue(options.contextHash);
      request.addContentContextHash(uniqueHash);
    } else if (options.contentHash) {
      uniqueHash.setValue(options.contentHash);
      request.addContentHash(uniqueHash);
    }

    const response = await this.rootService.addLoadingPromise(
      this.contentViewsService.loadContent(request),
    );

    const status = response.getStatus();
    if (status == StatusCode.OK) {
      const contents = response.getContentList();

      if (contents.length) {
        const contentView = contents[0].toObject();

        // keep data for later if set in options
        if (contentView && options.storeData) {
          this.storeData(uniqueHash.getValue(), contentView);
        }

        return contentView;
      }
    }

    return null;
  }

  /**
   * Retrieve the stored data by a given key, once requested and found the data
   * is deleted.
   */
  consumeCachedData(
    options: IRetrieveContentOptions,
  ): ContentView.AsObject | null {
    const hash: string | undefined = options.contextHash || options.contentHash;
    if (hash) {
      const data = this.consumableDataMap.get(hash);

      // if consumed, remove it unless specified in options
      if (data && !options.storeData) {
        this.consumableDataMap.delete(hash);
      }

      return data || null;
    } else {
      console.error(`consumeCachedData() no context or content hash given.`);
      return null;
    }
  }
}
