import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MembershipListTableModule } from '@shared/components/membership-list-table';
import { TextModule } from '@shared/components/text/text.module';
import { TooltipModule } from '@shared/components/tooltip';

import { FormModule } from '../form/form.module';
import { UserListFilterModule } from '../user-list-filter/user-list-filter.module';
import { FormRestrictionInputComponent } from './form-restriction-input.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonToggleModule,
    TooltipModule,
    MatInputModule,
    MembershipListTableModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    UserListFilterModule,

    FormsModule,
    ReactiveFormsModule,
    FormModule,
    TextModule,
  ],
  exports: [FormRestrictionInputComponent],
  declarations: [FormRestrictionInputComponent],
})
export class FormRestrictionModule {}
