import { MatBottomSheetConfig } from '@angular/material/bottom-sheet';

export type BottomSheetConfig<Data> = MatBottomSheetConfig<Data>;

export enum BottomSheetEventType {
  CLOSE = 'close', // close button clicked
  ESC = 'esc', // esc keyboard
  BACK = 'back', // back button clicked
  SUBMIT = 'submit', // primary button clicked
  CANCEL = 'cancel', // secondary button clicked
}
