export interface OnOverlayClose {
  onOverlayClose(): Promise<boolean> | boolean;
}

export interface OverlayStepInfo {
  text: string | boolean;
  icon: string;
  disabled?: boolean;
  xsHideText?: boolean;
}

export interface IOverlayStepNav {
  class?: string;
}

export interface IOverlayConfig {
  mobileTransparentNav?: boolean;
  background?: string;
  boxShadow?: string;

  /** Sets css var --overlay-border-radius */
  borderRadius?: string;

  /** Content background styles */
  contentBackground?: string;

  /**
   * Content background style to use _only_ while the overlay is 'full'.
   * @NOTE This will override the `contentBackground` setting while full.
   */
  fullContentBackground?: string;

  /**
   * Disable default pulldown to close behaviour. Defaults to `false`
   */
  disableOverlayPulldown?: boolean;

  /**
   * Ignore child navigation updates and handlers. Defaults to `false`
   */
  ignoreChildNavigation?: boolean;

  /**
   * Ignore child config updates. Defaults to `false`
   */
  ignoreChildOverlayConfig?: boolean;

  /**
   * Whether or not to use wide overlay
   */
  wide?: boolean;

  /**
   * Contains the overlay borders within the viewport.
   * This option is only available when the following criteria is met:
   * - the size of the viewport is not already at it's 'full' size
   * - `wide` is also set to `true`
   * - the overlay has 'split content'
   */
  containWithinViewport?: boolean;
}

export interface IParseOverlayStepperResultOptions {
  defaultText: string;
  defaultIcon?: string;
}

export interface IOverlayNextStepper {
  onOverlayNext(): Promise<void> | void;
  hasOverlayNext(): string | boolean | OverlayStepInfo;
}

export interface IOverlayPreviousStepper {
  onOverlayPrevious(): Promise<void> | void;
  hasOverlayPrevious(): string | boolean | OverlayStepInfo;
}

export interface IOverlayStepperUpdating {
  registerOnOverlayPreviousUpdate(
    fn: (previousValue: string | boolean | OverlayStepInfo) => void,
  ): void;
  registerOnOverlayNextUpdate(
    fn: (nextValue: string | boolean | OverlayStepInfo) => void,
  ): void;
}

export interface IOverlayStepper
  extends IOverlayNextStepper,
    IOverlayPreviousStepper {}

export interface IOverlayNavDisableable {
  registerOnOverlayNextDisabled(fn: (disabled: boolean) => void): void;
  registerOnOverlayPreviousDisabled(fn: (disabled: boolean) => void): void;
}

export interface IOverlayConfigurable {
  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void): void;
}

// Not recommended to use
export interface IOverlayNavRefreshable {
  registerOnOverlayNavRefresh(fn: () => void): void;
}

export type IOverlayComponent = Partial<
  IOverlayNextStepper &
    IOverlayPreviousStepper &
    IOverlayStepperUpdating &
    IOverlayNavDisableable &
    IOverlayConfigurable &
    IOverlayNavRefreshable
>;

export function parseOverlayStepperResult(
  result: boolean | string | OverlayStepInfo,
  options?: IParseOverlayStepperResultOptions,
): OverlayStepInfo {
  options = options || { defaultText: '' };
  options.defaultIcon = options.defaultIcon || '';

  const overlayStepInfo: OverlayStepInfo = {
    icon: '',
    text: '',
  };

  if (typeof result === 'boolean') {
    overlayStepInfo.text = options.defaultText;
    overlayStepInfo.icon = options.defaultIcon;
    overlayStepInfo.disabled = !result;
  } else if (typeof result === 'string') {
    overlayStepInfo.text = result;
    overlayStepInfo.icon = options.defaultIcon;
    overlayStepInfo.disabled = !result;
  } else if (result && typeof result === 'object') {
    return Object.assign({}, overlayStepInfo, result);
  }

  return overlayStepInfo;
}
