import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { MgNotificationBaseModule } from '../MgNotificationBase';

import { MgBasicNotificationElement } from './MgBasicNotification.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgNotificationBaseModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgBasicNotificationElement],
  exports: [MgBasicNotificationElement],
})
export class MgBasicNotificationModule {}
