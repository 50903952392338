<div class="settings">
  <!-- Membership List Settings -->
  <section
    *ngIf="'MINGA_ACCOUNT_MANAGE' | hasPermission"
    class="section">
    <mg-membership-list-table
      [types]="LIST_TYPES"
      [hideHeader]="true">
    </mg-membership-list-table>
  </section>

  <!-- Behavior Settings -->
  <section
    *ngIf="'MINGA_ACCOUNT_MANAGE' | hasPermission"
    class="section">
    <mg-text variant="header-md-secondary">
      {{ MESSAGES.SUBHEADING_BEHAVIORS_TITLE }}
    </mg-text>
    <div
      fxLayout="column"
      fxLayoutGap="12px">
      <mg-setting-toggle
        [id]="'pbis-settings-click-teacher-behav'"
        [setting]="mingaSettings?.PBIS_TEACHERS_TRACK"
        [label]="MESSAGES.SETTING_TEACHERS_CAN_ASSIGN">
      </mg-setting-toggle>
      <mg-setting-toggle
        [id]="'pbis-settings-click-staff-behav'"
        [setting]="mingaSettings?.PBIS_STAFF_TRACK"
        [label]="MESSAGES.SETTING_STAFF_CAN_ASSIGN">
      </mg-setting-toggle>
      <mg-setting-toggle
        [id]="'pbis-settings-click-student-behav'"
        [setting]="mingaSettings?.PBIS_STUDENT_LEADERS_TRACK"
        [label]="MESSAGES.SETTING_STUDENT_LEADERS_CAN_ASSIGN">
      </mg-setting-toggle>
      <div *ngIf="canShowAutoAssignTardy$ | async">
        <mg-setting-toggle
          [setting]="mingaSettings?.PBIS_HP_TARDY"
          [label]="MESSAGES.SETTING_AUTO_SEND_TARDY">
        </mg-setting-toggle>
      </div>
    </div>
  </section>

  <!-- Consequence Settings -->
  <section
    *ngIf="'MINGA_ACCOUNT_MANAGE' | hasPermission"
    class="section">
    <mg-text variant="header-md-secondary">
      {{ MESSAGES.SUBHEADING_CONSEQUENCES_TITLE }}
    </mg-text>
    <div
      fxLayout="column"
      fxLayoutGap="12px">
      <mg-setting-toggle
        [id]="'pbis-settings-click-enable-csq'"
        [setting]="mingaSettings?.BM_CONSEQUENCE_ENABLE"
        [label]="MESSAGES.SETTING_ENABLE_CONSEQUENCES"
        (change)="onConsequencesEnabledChange($event)">
      </mg-setting-toggle>
      <ng-container *ngIf="isConsequencesEnabled$ | async">
        <div class="indent">
          <mg-setting-toggle
            [id]="'pbis-settings-click-teacher-csq'"
            [setting]="mingaSettings?.BM_CONSEQUENCE_TEACHER"
            [label]="MESSAGES.SETTING_TEACHERS_ASSIGN_CONSEQUENCE">
          </mg-setting-toggle>
          <mg-setting-toggle
            [id]="'pbis-settings-click-staff-csq'"
            [setting]="mingaSettings?.BM_CONSEQUENCE_STAFF"
            [label]="MESSAGES.SETTING_STAFF_ASSIGN_CONSEQUENCE">
          </mg-setting-toggle>
        </div>
      </ng-container>
    </div>
  </section>
</div>
