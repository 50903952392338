import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { IconNavHoverType } from 'minga/app/src/app/services/RootNav';

@Component({
  selector: 'mg-navicon',
  templateUrl: './Navicon.component.html',
  styleUrls: ['./Navicon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Navicon {
  @Input()
  icon: string = '';

  @Input()
  hover: IconNavHoverType = IconNavHoverType.ACTIVE;

  constructor(private element: ElementRef) {}
}
