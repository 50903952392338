import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { ReplaySubject } from 'rxjs';

import { LayoutService } from '@modules/layout/services';
import { ToolsService } from '@modules/tools/services';

@Component({
  selector: 'mg-tools-root',
  templateUrl: './tools-root.component.html',
  styleUrls: ['./tools-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsRootComponent implements OnDestroy {
  private _destroyedSubj$ = new ReplaySubject<void>(1);

  public toolType$ = this._toolsService.toolType$;

  constructor(
    private _toolsService: ToolsService,
    private _router: Router,
    public layout: LayoutService,
  ) {
    this._toolsService.setUsersToolType();
  }

  ngOnDestroy(): void {
    this._destroyedSubj$.next();
    this._destroyedSubj$.complete();
  }

  public closeRoute() {
    this._router.navigateByUrl('/');
  }
}
