import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, from, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import {
  FlexCheckInStatuses,
  MinimalFlexTimePeriod,
} from 'minga/domain/flexTime';
import { ReportTypes } from 'minga/domain/reportTypes';
import { day } from 'minga/shared/day';
import { ManagerReportHeaderFilterReportType } from 'src/app/components/manager-report/components';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { Person } from 'src/app/people';

import { FormSelectComponent, FormSelectOption } from '@shared/components/form';
import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import { MultiPersonSearchComponent } from '@shared/components/multi-person-search';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimePeriodService } from '@shared/services/flex-time';

import {
  FlexTimeManagerMessages,
  FlexTimeManagerRoutes,
} from '../../constants';
import { FtmActivityTemplatesService } from '../ftm-activity-templates/services';
import {
  FTM_REPORTS_FILTER_INIT_STATE,
  FtmReportFilterType,
  FtmReportPageOptions,
  FtmReportsMessages,
} from './constants';
import { FtmReportsService } from './services';

@Component({
  selector: 'mg-ftm-reports',
  templateUrl: './ftm-reports.component.html',
  styleUrls: ['./ftm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmReportsComponent implements OnDestroy {
  @ViewChild(MultiPersonSearchComponent)
  private _peopleSearchComponent: MultiPersonSearchComponent;
  @ViewChildren(FormSelectComponent)
  private _selectComponents: QueryList<FormSelectComponent>;

  /** Constants */
  public readonly FTM_MESSAGES = FlexTimeManagerMessages;
  public readonly MESSAGES = FtmReportsMessages;
  public readonly REPORT_SELECT_OPTIONS = FtmReportPageOptions;
  public readonly REPORT_PAGES = ReportTypes;

  /** General Observables */
  private readonly _destroyed$ = new ReplaySubject<void>(1);
  public readonly media$: Observable<any>;

  /** Table Data Service */
  private _dataServiceSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public readonly dataService$: Observable<any> =
    this._dataServiceSubj.asObservable();

  /** Filters */
  public readonly activityOptions$: Observable<FormSelectOption<any>[]>;
  public readonly periodOptions$: Observable<FormSelectOption<any>[]>;
  public readonly checkinStatusOptions = $enum(FlexCheckInStatuses).map(
    value => ({
      label: value,
      value,
    }),
  );
  public readonly activities$: Observable<number[]>;
  public readonly periods$: Observable<number[]>;
  public readonly checkinStatus$: Observable<string>;
  public range = initializeRange({
    start: {
      value: FTM_REPORTS_FILTER_INIT_STATE.startDate,
    },
    end: {
      value: FTM_REPORTS_FILTER_INIT_STATE.endDate,
    },
  });

  /** Periods */
  private _periods = new BehaviorSubject<MinimalFlexTimePeriod[]>([]);

  /** General Variables */
  public reportType: ManagerReportHeaderFilterReportType = {
    active: ReportTypes.FLEX_PERIOD_REGISTERED,
    options: FtmReportPageOptions,
  };

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    public ftmReports: FtmReportsService,
    private _ftmActivities: FtmActivityTemplatesService,
    private _ftPeriods: FlexTimePeriodService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _analytics: AnalyticsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {
    // have to do this so that we are updated whenever the url
    // is changed.
    this._route.url.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      if (this._route.children[0]) {
        this._route.children[0].url
          .pipe(takeUntil(this._destroyed$))
          .subscribe(url => {
            if (url[0]) {
              const report = url[0].path;
              const possiblePaths = this.reportType.options.map(
                type => type.value,
              );
              if (possiblePaths.includes(report)) {
                this.reportType.active = report;
                this._cdr.markForCheck();
              }
            }
          });
      }
    });

    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );

    this.activities$ = this.ftmReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => curFilter.activities),
    );
    this.periods$ = this.ftmReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => curFilter.periods),
    );
    this.checkinStatus$ = this.ftmReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => curFilter.checkinStatus),
    );

    this._ftmActivities.fetchAll();
    this.activityOptions$ = this._ftmActivities.activities$.pipe(
      takeUntil(this._destroyed$),
      map(activities =>
        activities.map(activity => ({
          label: activity.name,
          value: activity.id,
        })),
      ),
    );

    this.fetchAllPeriods(
      this.range.get('start').value,
      this.range.get('end').value,
    );

    this.periodOptions$ = this._periods.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(periods =>
        periods.map(period => ({
          label: period.title,
          value: period.id,
        })),
      ),
    );

    this.range.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(range => {
        this.ftmReports.setFilter(FtmReportFilterType.START_DATE, range.start);
        this.ftmReports.setFilter(FtmReportFilterType.END_DATE, range.end);
        this.fetchAllPeriods(range.start, range.end);
      });
  }

  ngOnDestroy(): void {
    this.ftmReports.clearFilter();
    this._destroyed$.next();
    this._destroyed$.complete();
    this._periods.complete();
  }

  async onChangeReportType(value: string) {
    this.reportType.active = value;
    await this._router.navigateByUrl(
      `/${FlexTimeManagerRoutes.ROOT}/${FlexTimeManagerRoutes.REPORTS}/${value}`,
    );
    this._cdr.markForCheck();
    return;
  }

  onPersonSelected(people: Person[]) {
    this.ftmReports.setFilter(
      FtmReportFilterType.REGISTRANT,
      people.map(p => p.hash),
    );
  }

  onChangeActivity(event: FormSelectOption) {
    this.ftmReports.setFilter(FtmReportFilterType.ACTIVITY, event);
  }

  onChangePeriod(event: FormSelectOption) {
    this.ftmReports.setFilter(FtmReportFilterType.PERIOD, event);
  }

  onChangeCheckinStatus(event: FormSelectOption) {
    this.ftmReports.setFilter(FtmReportFilterType.CHECKIN_STATUS, event);
  }

  public changeUserList(list: number[]) {
    this.ftmReports.setFilter(FtmReportFilterType.USER_LIST, list);
  }

  clearFilter() {
    this._peopleSearchComponent.setValue([]);
    this.ftmReports.clearFilter();

    this._selectComponents.forEach((component, _) => {
      if (component.placeholder === 'Report Type') return;

      component.control.setValue(undefined);
    });

    this.range.patchValue({
      start: FTM_REPORTS_FILTER_INIT_STATE.startDate,
      end: FTM_REPORTS_FILTER_INIT_STATE.endDate,
    });
  }

  async applyFilter() {
    if (
      this.reportType.active === ReportTypes.FLEX_PERIOD_UNREGISTERED &&
      !this.ftmReports.filter.periods.length
    ) {
      this._systemAlertSnackBar.open({
        type: 'error',
        message: FtmReportsMessages.UNREGISTERED_NO_PERIOD_ERROR,
      });
    }
    this.ftmReports.applyFilter();
    this._analytics.sendManagerReport({
      managerType: 'flexTime',
      reportType: this.reportType.active,
    });
  }

  public onActivate(componentRef: any) {
    this._dataServiceSubj.next(componentRef.ds);
  }

  public async fetchAllPeriods(
    startDate?: day.Dayjs,
    endDate?: day.Dayjs,
  ): Promise<void> {
    try {
      const result = await this._ftPeriods.fetchAllMinimal(startDate, endDate);
      this._periods.next(result);
    } catch (err) {
      this._systemAlertSnackBar.error('failed to fetch all flex time periods');
    }
  }
}
