import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EventPerson, EventStatus } from 'minga/domain/event';
import { EventReportColumns } from 'minga/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { PermissionsService } from 'src/app/permissions';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { EventPeopleReportService } from './EventPeople.report.service';

@Injectable()
export class EventPeopleDatasource extends ReportDatasourceService<EventPerson> {
  private _notGoingStatus = false;
  private _typicalColumns = EventReportColumns.EVENT;

  constructor(
    _router: Router,
    private _eventReportService: EventPeopleReportService,
    _alertModal: SystemAlertModalService,
    _permissions: PermissionsService,
    _snackBar: SystemAlertSnackBarService,
    _modalOverlay: ModalOverlayService,
  ) {
    super(
      _router,
      _eventReportService,
      EventPeopleDatasource.name,
      _alertModal,
      _snackBar,
      _modalOverlay,
    );

    super.setDisplayColumns(EventReportColumns.EVENT);
  }

  setEventHasTickets() {
    super.setDisplayColumns(EventReportColumns.EVENT_TICKETED);
    this._typicalColumns = EventReportColumns.EVENT_TICKETED;
  }

  async fetch(offset: number, limit: number) {
    if (
      this._eventReportService.filter.personStatus === EventStatus.NOT_GOING &&
      !this._notGoingStatus
    ) {
      this._notGoingStatus = true;
      super.setDisplayColumns(EventReportColumns.NOT_GOING);
    } else if (
      this._eventReportService.filter.personStatus !== EventStatus.NOT_GOING &&
      this._notGoingStatus
    ) {
      this._notGoingStatus = false;
      super.setDisplayColumns(this._typicalColumns);
    }

    return await this._eventReportService.getEventPeople(offset, limit);
  }
}
