import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MingaAudio } from '@shared/constants';
import { AudioPlayerService } from '@shared/services/audio-player';

import { KioskSoundMessage } from '../constants';
import { KioskStateStorage } from '../utils';

@Injectable()
export class KioskAudioService implements OnDestroy {
  // Constants

  private readonly _audioFiles: MingaAudio[] = [
    MingaAudio.POSITIVE_PING,
    MingaAudio.NEGATIVE_PING,
  ];

  // State

  private readonly _enableSoundSubject = new BehaviorSubject<boolean>(
    KioskStateStorage.get()?.audioEnabled ?? false,
  );
  public readonly enableSound$ = this._enableSoundSubject.asObservable();

  // Service constructor

  constructor(
    public systemSnack: SystemAlertSnackBarService,
    private _audioPlayerService: AudioPlayerService<MingaAudio>,
  ) {
    this._audioPlayerService.addAudio(this._audioFiles);
  }

  // Service lifecycle

  ngOnDestroy(): void {
    this._enableSoundSubject.complete();
    this._audioPlayerService.removeAudio(this._audioFiles);
  }

  // Public methods

  public async toggleEnableSound() {
    try {
      const audioEnabled = !this._enableSoundSubject.getValue();
      KioskStateStorage.set({ audioEnabled });
      this._enableSoundSubject.next(audioEnabled);
      this.systemSnack.success(
        audioEnabled
          ? KioskSoundMessage.SETTING_ENABLED_MESSAGE
          : KioskSoundMessage.SETTING_DISABLED_MESSAGE,
      );
    } catch (error) {
      this.systemSnack.error('Error setting sound preference');
    }
  }

  public async play(sound: 'success' | 'error') {
    const enableSound = this._enableSoundSubject.getValue();
    if (!enableSound) return;
    switch (sound) {
      case 'success': {
        await this._audioPlayerService.playAudio(MingaAudio.POSITIVE_PING);
        break;
      }
      case 'error': {
        await this._audioPlayerService.playAudio(MingaAudio.NEGATIVE_PING);
        break;
      }
      default: {
        break;
      }
    }
  }
}
