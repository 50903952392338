import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, ReplaySubject } from 'rxjs';

import { BadgeManager } from 'src/app/minimal/services/BadgeManager';
import { StreamManager } from 'src/app/minimal/services/StreamManager';
import { MessagingFacade } from 'src/app/modules/direct-message/store';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { GlobalSearchService } from '@modules/global-search';
import { LayoutService } from '@modules/layout/services';
import { BreadcrumbService } from '@modules/layout/services/breadcrumb.service';

import {
  NavigationService,
  SecondaryNavigationRoute,
} from '@shared/services/navigation';

@Component({
  selector: 'mg-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutHeaderComponent implements OnDestroy {
  // View children

  @ViewChild('scrollableContainer') scrollableContainer!: ElementRef;

  // Cleanup

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Inputs

  @Input() public title: string;
  @Input() public menu: SecondaryNavigationRoute[];
  @Input() public breadcrumbs: any[];
  @Input() public showUtilityNav = true;

  // State

  private _isMouseDown = false;
  private _startX = 0;
  private _scrollLeft = 0;

  /** Allow direct messages */
  public readonly isDmModuleEnabled$: Observable<boolean> =
    this._settingService.isDmModuleEnabled();

  public readonly hasUnreadMessages$: Observable<boolean> =
    this._messagingFacade.hasUnreadMessages$;

  /** Component constructor */
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public layout: LayoutService,
    public navigation: NavigationService,
    public breadcrumbService: BreadcrumbService,
    public badgeManager: BadgeManager,
    public globalSearch: GlobalSearchService,
    private _messagingFacade: MessagingFacade,
    private _settingService: MingaSettingsService,
    private _streamManager: StreamManager,
  ) {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public trackBy(index: number, item: SecondaryNavigationRoute) {
    return item.path || index;
  }

  public onMouseDown(event: MouseEvent) {
    this._isMouseDown = true;
    this._startX =
      event.pageX - this.scrollableContainer.nativeElement.offsetLeft;
    this._scrollLeft = this.scrollableContainer.nativeElement.scrollLeft;
  }

  public onMouseLeave() {
    this._isMouseDown = false;
  }

  public onMouseUp() {
    this._isMouseDown = false;
  }

  public onMouseMove(event: MouseEvent) {
    if (!this._isMouseDown) return;
    event.preventDefault();
    const SPEED = 1;
    const x = event.pageX - this.scrollableContainer.nativeElement.offsetLeft;
    const move = (x - this._startX) * SPEED;
    this.scrollableContainer.nativeElement.scrollLeft = this._scrollLeft - move;
  }

  public restartStream(streamName: string | string[]) {
    this._streamManager.restartStreams(streamName);
  }
}
