import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';

import { GenericModule } from '@shared/components/generic';
import { TextModule } from '@shared/components/text';

import { PaginatorComponent } from './paginator.component';
import { PageButtonsPipe } from './pipes';

@NgModule({
  imports: [
    // Minga dependencies
    GenericModule,
    TextModule,

    // External dependencies
    CommonModule,
    MatPaginatorModule,
    MatMenuModule,
    FlexLayoutModule,
    MatIconModule,
  ],
  declarations: [
    // Pipes
    PageButtonsPipe,
    PaginatorComponent,
  ],
  exports: [PaginatorComponent],
})
export class PaginatorModule {}
