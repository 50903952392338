import { ChartOptions } from 'chart.js';

export const BAR_CHART_OPTIONS: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: true,
      titleFont: {
        family: 'Muli',
        size: 14,
      },
      bodyFont: {
        family: 'Muli',
        size: 14,
      },
      titleMarginBottom: 10,
      padding: 10,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        tooltipFormat: 'MMM DD, YYYY',
      },
      stacked: true,
      ticks: {
        color: '#B2B2B2',
        font: {
          family: 'Muli',
          size: 16,
          weight: 'bold',
        },
      },
      grid: {
        display: true,
        lineWidth: 0,
        color: 'transparent',
        drawBorder: true,
        borderColor: '#70A5CA',
        borderWidth: 2,
      },
    },
    y: {
      ticks: {
        color: '#B2B2B2',
        font: {
          family: 'Muli',
          size: 14,
          weight: 'bold',
        },
        maxTicksLimit: 3,
        callback: (val: number) => val,
      },
      beginAtZero: true,
      grid: {
        display: true,
        lineWidth: 0,
        color: 'transparent',
        drawBorder: true,
        borderColor: '#70A5CA',
        borderWidth: 2,
      },
      title: {
        display: true,
        font: {
          family: 'Muli',
          size: 14,
          weight: 'bold',
        },
        color: '#B2B2B2',
        text: 'Count',
      },
    },
  },
};
