import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { scrollIntoView } from 'minga/app/src/app/util/scroll-into-view';
import { BmReportsFilters } from 'minga/domain/reportFilters';
import {
  ScheduledReport,
  ScheduledReportFrequency,
} from 'minga/domain/scheduledReports';
import { RootService } from 'src/app/minimal/services/RootService';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SelectFieldsOption } from '@shared/components/select-fields';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { ScheduledReportsService } from '@shared/services/scheduled-reports/scheduled-reports.service';

import {
  DAY_OPTIONS,
  DELIVERY_OPTIONS,
  FREQUENCY_OPTIONS,
  REPORT_TYPE_OPTIONS,
  SCHEDULED_REPORTS_EDIT_FORMGROUP,
  ScheduledReportEditFormFields,
  ScheduledReportEditMessage,
  getDayEnum,
} from '../../constants';
import { ScheduledReportsStore } from '../../store/scheduled-reports.store';
import {
  ClientSupportedScheduledReportFilter,
  MmSrReportsEditData,
  MmSrReportsEditResponse,
  ScheduledReportType,
} from '../../types';

@Component({
  selector: 'mg-mm-sr-reports-edit',
  templateUrl: './mm-sr-reports-edit.component.html',
  styleUrls: ['./mm-sr-reports-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ScheduledReportsStore],
})
export class MmSrReportsEditComponent
  extends CrudFormBase<ScheduledReport>
  implements OnInit, OnDestroy, AfterViewInit
{
  private _destroyed$ = new ReplaySubject<void>(1);

  public MESSAGES = ScheduledReportEditMessage;
  public FORM_FIELDS = ScheduledReportEditFormFields;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.BLUE,
  };
  public readonly form = this._fb.group(SCHEDULED_REPORTS_EDIT_FORMGROUP);
  public isNew: boolean;
  public REPORT_TYPE_OPTIONS = REPORT_TYPE_OPTIONS;
  public FREQUENCY_OPTIONS = FREQUENCY_OPTIONS;
  public DELIVERY_OPTIONS = DELIVERY_OPTIONS;
  public DAY_OPTIONS = DAY_OPTIONS;
  public REPORT_TYPES = ScheduledReportType;
  public FREQUENCE_TYPES = ScheduledReportFrequency;
  public emailOptions: SelectFieldsOption<string>[];

  private _defaultFilterValuesSubj =
    new BehaviorSubject<ClientSupportedScheduledReportFilter>(null);
  public defaultFilterValues$ = this._defaultFilterValuesSubj.asObservable();

  @ViewChild('crudForm', { static: false })
  crudForm?: ElementRef<HTMLElement>;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: MmSrReportsEditData,
    private _modalRef: ModalOverlayRef<
      MmSrReportsEditResponse,
      MmSrReportsEditData
    >,
    private _fb: FormBuilder,
    private _systemAlertModal: SystemAlertModalService,
    private _scheduledReportsService: ScheduledReportsService,
    public rootService: RootService,
    private _cdr: ChangeDetectorRef,
  ) {
    super({
      id: dialogData?.id,
      get: async id => {
        return this._scheduledReportsService.get(id);
      },
      create: data => {
        return this._scheduledReportsService.create(data as ScheduledReport);
      },
      update: data => {
        return this._scheduledReportsService.update(data as ScheduledReport);
      },
      delete: async data => {
        await this._scheduledReportsService.delete(data.id);
        return data;
      },
      onSetForm: data => {
        const reportType =
          dialogData?.prepopulatedFields?.reportType || data.reportType;

        this.form.patchValue({
          [ScheduledReportEditFormFields.NAME]: data.name,
          [ScheduledReportEditFormFields.REPORT_TYPE]: reportType,
          [ScheduledReportEditFormFields.FREQUENCY]:
            data.frequency ?? ScheduledReportFrequency.Daily,
          [ScheduledReportEditFormFields.TIME]:
            data.time ?? day().set('hour', 15).set('minute', 0).format('HH:00'),
          [ScheduledReportEditFormFields.DAY]: data.day ?? getDayEnum(day()),
          [ScheduledReportEditFormFields.DELIVERY_TYPE]: data.deliveryType,
          [ScheduledReportEditFormFields.EMAIL]: data.email || [],
          [ScheduledReportEditFormFields.SFTP_HOST]: data.sftpHost,
          [ScheduledReportEditFormFields.SFTP_USER_NAME]: data.sftpUserName,
          [ScheduledReportEditFormFields.SFTP_PASSWORD]: data.sftpPassword,
        });

        if (data.email) {
          this.emailOptions = data.email.map(email => ({
            label: email,
            value: email,
          }));
        }

        if (data.reportType) {
          this.form.get(this.FORM_FIELDS.REPORT_TYPE).setValue(data.reportType);
        }

        this._defaultFilterValuesSubj.next({
          reportType: data.reportType as any,
          filters: data.filters || ({} as any),
        });

        this.form.markAsPristine();
        this.form.markAsUntouched();
      },
      onValidate: data => {
        // mark all the fields as touched
        Object.values(this.form.controls).forEach(control => {
          control.markAsTouched();
          control.updateValueAndValidity();
        });

        // filters sub section needs to be marked as touched
        this._cdr.markForCheck();

        return this.form.valid;
      },
      onSuccess: (type, data) => {
        if (type === 'delete') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE, {
            deleted: data.id,
          });
        }

        if (type === 'create') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
            created: data,
          });
        }

        if (type === 'update') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
            updated: data,
          });
        }
      },
      onSubmit: data => {
        const active = !data.id ? true : data.active;

        const report: Partial<ScheduledReport> = {
          ...(data || {}),
          ...this.form.value,
          active,
        };

        return report;
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        if (this.form.pristine) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: ScheduledReportEditMessage.DELETE_CONFIRM_DISCARD_TITLE,
          message: ScheduledReportEditMessage.DELETE_CONFIRM_DISCARD_DESC,
          closeBtn: ScheduledReportEditMessage.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn:
            ScheduledReportEditMessage.DELETE_CONFIRM_DISCARD_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        if (response?.type === SystemAlertCloseEvents.CONFIRM) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        }
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: ScheduledReportEditMessage.DELETE_CONFIRM_TITLE,
          closeBtn: ScheduledReportEditMessage.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn:
            ScheduledReportEditMessage.DELETE_CONFIRM_DELETE_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        return response?.type === SystemAlertCloseEvents.CONFIRM;
      },
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.crudForm.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });
    this.isNew = !this.dialogData?.id;
  }

  ngOnInit(): void {
    this.init();
    this.form
      .get(this.FORM_FIELDS.FREQUENCY)
      .valueChanges.pipe(takeUntil(this._destroyed$))
      .subscribe(value => {
        this._onFrequencyChange(value);
      });

    this.form
      .get(this.FORM_FIELDS.DELIVERY_TYPE)
      .valueChanges.pipe(takeUntil(this._destroyed$))
      .subscribe(value => {
        this._onDeliveryMethodChange(value);
      });

    this.form
      .get(this.FORM_FIELDS.REPORT_TYPE)
      .valueChanges.pipe(
        takeUntil(this._destroyed$),
        filter(r => r),
      )
      .subscribe(value => {
        this._onReportTypeChange(value);
      });
  }

  ngAfterViewInit(): void {
    if (this.isNew) {
      // need to wait till ngSwitch is done for setting these so wait till after view init
      this._setDefaultFilters();
    }
  }
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public addNewEmail(email: string) {
    return { label: email, value: email };
  }

  private _onFrequencyChange(value: string) {
    this._updateDayValidators(value);
  }

  private _onDeliveryMethodChange(value: string[]) {
    const val = value || [];
    this._updateEmailValidators(val);
    this._updateSftpValidators(val);
  }
  private _onReportTypeChange(reportType) {
    this._defaultFilterValuesSubj.next({
      reportType,
      filters: {} as any,
    });
  }

  private _updateEmailValidators(value: string[]) {
    const emailControl = this.form.get(this.FORM_FIELDS.EMAIL);
    if (value.includes('email')) {
      emailControl.setValidators([Validators.required]);
    } else {
      emailControl.setValue([]);
      emailControl.clearValidators();
    }

    emailControl.updateValueAndValidity();
  }

  private _updateDayValidators(value: string) {
    const dayControl = this.form.get(this.FORM_FIELDS.DAY);
    if (value === 'weekly') {
      dayControl.setValidators([Validators.required]);
    } else {
      dayControl.clearValidators();
    }

    dayControl.updateValueAndValidity();
  }

  private _updateSftpValidators(value: string[]) {
    const host = this.form.get(this.FORM_FIELDS.SFTP_HOST);
    const username = this.form.get(this.FORM_FIELDS.SFTP_USER_NAME);
    const password = this.form.get(this.FORM_FIELDS.SFTP_PASSWORD);
    if (value.includes('sftp')) {
      host.setValidators([Validators.required]);
      username.setValidators([Validators.required]);

      // edit we don't prepopulate the password again so it's not required on update
      if (this.isNew) {
        password.setValidators([Validators.required]);
      }
    } else {
      host.setValue('');
      host.clearValidators();
      username.clearValidators();
      username.setValue('');
      password.clearValidators();
      password.setValue('');
    }

    host.updateValueAndValidity();
    username.updateValueAndValidity();
    password.updateValueAndValidity();
  }

  private _setDefaultFilters() {
    const defaultFilterValues = this.dialogData?.prepopulatedFields || {
      reportType: ScheduledReportType.PBIS_HISTORY,
      filters: {} as BmReportsFilters,
    };
    this._defaultFilterValuesSubj.next(defaultFilterValues as any);
  }
}
