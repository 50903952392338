<ng-container *ngIf="showTopNav">
  <div *mgOverlayStructure="true; region: 'top'; mode: mode">
    <mg-overlay-nav-item
      *ngIf="overlayPrevious"
      class="mg-align-left"
      (click)="overlayPreviousClick($event)"
      [icon]="overlayPrevious?.icon"
      [disabled]="overlayPrevious?.disabled">
      <span [fxHide.xs]="overlayPrevious?.xsHideText">
        {{ overlayPrevious?.text }}
      </span>
    </mg-overlay-nav-item>

    <mg-overlay-nav-item
      *ngIf="overlayNext"
      class="mg-align-right"
      (click)="overlayNextClick($event)"
      [icon]="overlayNext?.icon"
      [disabled]="overlayNext?.disabled">
      {{ overlayNext?.text }}
    </mg-overlay-nav-item>
  </div>
</ng-container>
