<form
  [formGroup]="formGroup"
  class="landing-form flex-spaced-content"
  (submit)="onSubmit()"
  fxLayout="column">
  <h1 class="mg-centered landing-title1">Let's get started!</h1>
  <p class="landing-subtitle mg-smallcopy">Where is your Minga?</p>

  <mg-country-selector
    formControlName="country"
    name="country">
  </mg-country-selector>

  <mg-button
    type="outline"
    class="large"
    [disabled]="formGroup.invalid">
    Next
  </mg-button>
</form>
