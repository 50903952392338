import { Pipe, PipeTransform } from '@angular/core';

import { ScheduledReportFrequency } from 'minga/domain/scheduledReports';

import { FREQUENCY_TYPES } from '../constants';

@Pipe({ name: 'frequencyLabel' })
export class FrequencyLabelPipe implements PipeTransform {
  transform(frequency: ScheduledReportFrequency): string {
    return FREQUENCY_TYPES[frequency] || '';
  }
}
