import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  PersonViewEvent,
  PersonViewMinimal,
} from 'minga/proto/gateway/person_view_pb';

@Component({
  selector: 'mg-person-list-element',
  templateUrl: './MgPersonListElement.element.html',
  styleUrls: ['./MgPersonListElement.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPersonListElement {
  @Input()
  person?: PersonViewMinimal.AsObject | PersonViewEvent.AsObject;

  @Input()
  showContent: boolean = false;

  @Input()
  noWrap: boolean = false;
}
