import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { PortalLayoutSection } from '../constants/portal-layout.constants';
import { PortalLayoutService } from '../services/portal-layout.service';
import { PortalLayoutAbstract } from '../utils/portal-layout.abstract';

/**
 * warning: the last rendered header will be the one that is used
 */
@Component({
  selector: 'mg-portal-layout-header',
  template: `
    <ng-template #portalContent>
      <ng-content></ng-content>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalLayoutHeaderComponent
  extends PortalLayoutAbstract
  implements AfterViewInit, OnDestroy
{
  // Children

  @ViewChild('portalContent')
  public readonly portalContent: TemplateRef<unknown>;

  /** Component constructor */
  constructor(public portalLayout: PortalLayoutService) {
    super(PortalLayoutSection.HEADER, portalLayout);
  }

  ngAfterViewInit() {
    this.init(this.portalContent);
  }

  ngOnDestroy() {
    this.portalLayout.clearSectionContent(PortalLayoutSection.HEADER);
  }
}
