import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import {
  MingaNameComponent,
  MingaNameSlot_Hint,
  MingaNameSlot_Label,
} from './MingaName.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    MgFormFieldModule,

    // External dependencies
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
  ],
  declarations: [MingaNameComponent, MingaNameSlot_Label, MingaNameSlot_Hint],
  exports: [MingaNameComponent, MingaNameSlot_Label, MingaNameSlot_Hint],
})
export class MingaNameModule {}
