import { LocationStrategy } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';

import { ReplaySubject, Subscription, combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MOBILE_BREAKPOINTS } from 'src/styles/flex-breakpoints/constants';

import { NavPermissions } from 'minga/app/src/app/minimal/services/NavPermissions';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { splashScreen } from 'minga/app/src/splashscreen';
import { MingaPermission, SettingsRecord } from 'minga/util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import {
  CommonManagerOverlayRoutes,
  ManagerOverlayColorTheme,
} from './manager-overlay.constants';
import {
  ManagerOverlayRoutesPage,
  ManagerOverlayRoutesTabs,
  ManagerOverlayTabs,
} from './manager-overlay.types';

/**
 * Manager Overlay
 *
 * This component is to be used by manager type routes / pages.
 */
@Component({
  selector: 'mg-manager-overlay',
  templateUrl: './manager-overlay.component.html',
  styleUrls: ['./manager-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagerOverlayComponent implements OnInit, OnDestroy {
  @ContentChild('overlayTitle', { read: TemplateRef })
  overlayTitle: TemplateRef<any>;

  @ViewChild(MatMenuTrigger)
  trigger: MatMenuTrigger;

  protected mentionsSub: Subscription;
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Breakpoint Related */
  public mqAlias: string;
  public isOnMobile = true;

  /** Footer Tabs */
  public tabs: ManagerOverlayTabs = {
    data: [],
    labels: [],
    activeIndex: 0,
    currentUrlTabSegment: '',
  };

  /** Manager Routes */
  public managerRoutes: ManagerOverlayRoutesPage[] = [];
  private _currentManagerRoute: string;

  /** Drawer Menu */
  public openedSideNav: boolean;

  /** Inputs */
  @Input() showLogo = true;
  @Input() noPadding = false;
  @Input() selectedIndex?: number;
  @Input()
  colorTheme: ManagerOverlayColorTheme =
    ManagerOverlayColorTheme.PEOPLE_MANAGER;

  /** Outputs */
  @Output() overlayClose = new EventEmitter<void>();
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter();

  /** Component Constructor */
  constructor(
    public media: MediaObserver,
    public route: ActivatedRoute,
    private _navPermissions: NavPermissions,
    private _router: Router,
    private _url: LocationStrategy,
    private _permissions: PermissionsService,
    private _settingService: MingaSettingsService,
    private _cdr: ChangeDetectorRef,
    private _analytics: AnalyticsService,
  ) {
    media
      .asObservable()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(mediaChanges => {
        this.isOnMobile = MOBILE_BREAKPOINTS.includes(mediaChanges[0].mqAlias)
          ? true
          : false;
        this.mqAlias = mediaChanges[0].mqAlias;
        this._cdr.markForCheck();
      });

    _router.events
      .pipe(filter((e): e is RouterEvent => e instanceof NavigationEnd))
      .subscribe(() => {
        const pathSegments = this._url.path().split('/');
        // first two are '' and the manager route
        if (pathSegments.length > 2) {
          this.tabs.currentUrlTabSegment = pathSegments[2];
          this._syncUrlWithTab();
          this._cdr.markForCheck();
        }
      });

    combineLatest([route.data, _settingService.getAllSettingsAsObject()])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([data, settingRecord]) => {
        this._currentManagerRoute = data.defaultRootPath;
        this._handleRouteTabs(data.tabs, settingRecord);
        this._handleManagerRoutes(CommonManagerOverlayRoutes);
        this._analytics.logEvent('managerOpened', {
          type: data.defaultRootPath,
        });
      });

    // removed for now as it' causing issues with modals not being scrollable.
    // document.body.classList.add("no-overflow");
  }

  /** Component Lifecycle: On Mount */
  ngOnInit() {
    splashScreen.hide();
  }

  /** Component Lifecycle: On Unmount */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    // document.body.classList.remove("no-overflow");
  }

  /**
   * Handle Manager Routes
   *
   * Creates the related pages array, removes links that the user does not have
   * permissions to, is disabled or is on the current page already.
   */
  private _handleManagerRoutes(
    routes: ManagerOverlayRoutesPage[],
  ): Promise<void> {
    this.managerRoutes = [];

    if (!routes.length) return;

    routes.forEach(route => {
      const { path, featureFlag, permissions } = route;

      if (featureFlag) {
        let isEnabled = false;
        this._settingService
          .getModuleEnabledObs(featureFlag)
          .pipe(takeUntil(this._destroyed$))
          .subscribe(enabled => (isEnabled = enabled));
        if (!isEnabled) return;
      }

      const hasAtleastOnePermission = permissions.some(permission => {
        return this._permissions.hasPermission(permission);
      });

      if (path === 'points-manager' && !window.MINGA_APP_BROWSER) {
        if (
          !this._permissions.hasPermission(
            [
              MingaPermission.POINTS_MANAGE,
              MingaPermission.MANAGE_REWARDS,
              MingaPermission.MINGA_ACCOUNT_MANAGE,
            ],
            false,
          )
        )
          return;
      }

      if (hasAtleastOnePermission) {
        this.managerRoutes.push(route);
      }
    });
    this._cdr.markForCheck();
  }

  /**
   * Handle Route Tabs
   *
   * only allow tabs that the user has permission to get to
   */
  private _handleRouteTabs(
    tabs: ManagerOverlayRoutesTabs[],
    settingRecord: SettingsRecord,
  ) {
    if (!tabs) {
      return;
    }
    if (!window.MINGA_APP_BROWSER) {
      tabs = tabs.filter(tab => tab.path !== 'reports');
    }
    this.tabs.data = [];
    this.tabs.labels = [];
    for (const tab of tabs) {
      let push = false;
      if (this._navPermissions.hasTabRoutePermissions(tab.permissions)) {
        push = true;
      }
      if (tab.setting && !settingRecord[tab.setting]) {
        push = false;
      }
      if (push) {
        this.tabs.data.push(tab);
        this.tabs.labels.push(tab.title);
      }
    }
    if (this.tabs.data.length) {
      this._syncUrlWithTab();
    }
  }

  /**
   * If a tab's path matches the last url segnment, set the tab index to that
   * tab.
   */
  private _syncUrlWithTab() {
    if (this.tabs.data.length && this.tabs.currentUrlTabSegment) {
      for (let i = 0; i < this.tabs.data.length; i++) {
        const currentTab = this.tabs.data[i];

        if (this.tabs.currentUrlTabSegment === currentTab.path) {
          this.tabs.activeIndex = i;
          return;
        }
      }
    }
  }

  onSelectedIndexChange(index: number) {
    this.selectedIndexChange.emit(index);
  }

  onTabIndexChange(index: number) {
    if (this.tabs.data[index]) {
      const tabRoute = this.tabs.data[index];
      this._router.navigate([
        `/${this._currentManagerRoute}/` + tabRoute.path,
        { outlets: { o: null } },
      ]);
    }
  }

  handleOnClickCloseIcon() {
    this.overlayClose.emit();
  }

  handleOnClickOpenDrawer() {
    this.openedSideNav = !this.openedSideNav;
    this._cdr.markForCheck();
  }

  goTo(path: string) {
    this._router.navigate([path]);
  }

  addColorThemeClasses(baseClass: string): any {
    return {
      [baseClass]: true,
      [this.colorTheme]: true,
    };
  }
}
