import { CheckinReasonIcons } from 'minga/domain/checkin';
import { DefaultHallPassTypeIcons } from 'minga/domain/hallPass/defaults';

import { FormSelectOption } from '@shared/components/form';

export const TYPE_ICON_OPTIONS: FormSelectOption<string>[] = [
  {
    label: DefaultHallPassTypeIcons.RESTROOM,
    value: DefaultHallPassTypeIcons.RESTROOM,
  },
  {
    label: DefaultHallPassTypeIcons.HEALTH,
    value: DefaultHallPassTypeIcons.HEALTH,
  },
  {
    label: DefaultHallPassTypeIcons.OFFICE,
    value: DefaultHallPassTypeIcons.OFFICE,
  },
  {
    label: DefaultHallPassTypeIcons.HALL,
    value: DefaultHallPassTypeIcons.HALL,
  },
  {
    label: DefaultHallPassTypeIcons.LIBRARY,
    value: DefaultHallPassTypeIcons.LIBRARY,
  },
  {
    label: DefaultHallPassTypeIcons.FOUNTAIN,
    value: DefaultHallPassTypeIcons.FOUNTAIN,
  },
  {
    label: DefaultHallPassTypeIcons.TARDY,
    value: DefaultHallPassTypeIcons.TARDY,
  },
  {
    label: DefaultHallPassTypeIcons.ATHLETICS,
    value: DefaultHallPassTypeIcons.ATHLETICS,
  },
  {
    label: DefaultHallPassTypeIcons.TRANSFER,
    value: DefaultHallPassTypeIcons.TRANSFER,
  },
  {
    label: DefaultHallPassTypeIcons.MEDIA,
    value: DefaultHallPassTypeIcons.MEDIA,
  },
  {
    label: CheckinReasonIcons.CHECKIN_1,
    value: CheckinReasonIcons.CHECKIN_1,
  },
  {
    label: CheckinReasonIcons.CHECKIN_2,
    value: CheckinReasonIcons.CHECKIN_2,
  },
  {
    label: CheckinReasonIcons.CHECKIN_3,
    value: CheckinReasonIcons.CHECKIN_3,
  },
  {
    label: CheckinReasonIcons.CHECKIN_4,
    value: CheckinReasonIcons.CHECKIN_4,
  },
  {
    label: CheckinReasonIcons.CHECKIN_5,
    value: CheckinReasonIcons.CHECKIN_5,
  },
  {
    label: CheckinReasonIcons.CHECKIN_6,
    value: CheckinReasonIcons.CHECKIN_6,
  },
  {
    label: CheckinReasonIcons.CHECKIN_7,
    value: CheckinReasonIcons.CHECKIN_7,
  },
  {
    label: CheckinReasonIcons.CHECKIN_8,
    value: CheckinReasonIcons.CHECKIN_8,
  },
  {
    label: CheckinReasonIcons.CHECKIN_9,
    value: CheckinReasonIcons.CHECKIN_9,
  },
  {
    label: 'hallpass',
    value: 'hallpass',
  },
];
