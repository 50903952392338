import { Dayjs } from 'dayjs';

export enum FtmDashboardMessages {
  SECTION_HEADER_PERIODS = 'Periods',
  BUTTON_LABEL_REFRESH = 'Refresh dashboard',
  SECTION_HEADER_CURRENT_ACTIVITIES = 'Current activities',
  FLEX_PERIOD_SELECT_LABEL = 'FlexTime period',
  FETCHING_PERIODS_ERROR = 'There was an issue fetching data, try refreshing the page',
  AUTO_REFRESH = 'Auto refresh',
  AUTO_REFRESH_FAILED = 'There was a problem setting auto refresh, try again',
  ABSENTEES_SINGULAR = 'Absentee',
  ABSENTEES_PLURAL = 'Absentees',

  SUBPAGE_TITLE = 'Dashboard',
  PERIODS_TITLE = 'Periods',
  ACTIVITIES_TITLE = 'Activities',
  STUDENTS_REGISTERED = 'Registered',
  STUDENTS_NOT_REGISTERED = 'Not registered',
  STUDENTS_CHECKED_IN = 'Checked in',
  STUDENTS_NOT_CHECKED_IN = 'Not checked in',

  NO_PERIODS_TITLE = 'Periods',
  NO_PERIODS_DESC = "Looks like there's no periods for today",
  NO_ACTIVITIES_TITLE = 'No activities found!',
  NO_ACTIVITIES_DESC = 'Only activities created for the current or next upcoming period(s) will appear here.',
  NO_TEACHERS_TITLE = 'No result found',
  NO_TEACHERS_DESC = 'No activity has been assigned to that teacher this period',
}

export interface FlexTimePeriodTile {
  id: number;
  name: string;
  count?: number;
  absentees?: number;
  date: string;
  startTime: string;
  endTime: string;
  totalSpaces?: number;
  dateObject?: Dayjs;
}

export const AUTO_REFRESH_STATE = 'autoFlexRefreshState';
export const POLLING_INTERVAL = 60000;
