import {
  Injectable,
  TemplateRef,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SearchAreaDirective } from '../directives/SearchArea.directive';

import {
  ISearchInstance,
  SearchInstanceType,
  SearchService,
} from './Search.service';

export interface SearchAreaRef {
  viewContainer: ViewContainerRef;
  setSearchQuery(query: string): void;
  unregister(): void;
}

/**
 * The Search Area service is a way for `SearchAreaDirective`s to communicate
 * with search area views.
 */
@Injectable({ providedIn: 'root' })
export class SearchAreaService {
  private _defaultSearchAreaTemplate?: TemplateRef<any>;
  private _searchAreaDirectives: SearchAreaDirective[] = [];
  private _viewContainers: ViewContainerRef[] = [];
  private _searchShowingResultsSubject = new BehaviorSubject<boolean>(false);
  private _viewRefs = new WeakMap<
    SearchAreaDirective,
    WeakMap<ViewContainerRef, ViewRef>
  >();
  private _searchInstances = new WeakMap<
    SearchAreaDirective,
    ISearchInstance
  >();

  get showingResults(): Observable<boolean> {
    return this._searchShowingResultsSubject.asObservable();
  }

  constructor(private search: SearchService) {}

  /**
   * Add search area directive to active list. There shouldn't be a reason to
   * use this method outside of SearchAreaDirective
   */
  addSearchArea(directive: SearchAreaDirective) {
    this._searchAreaDirectives.push(directive);
    this._viewContainers.forEach(viewContainer => {
      this._setupViewContainer(directive, viewContainer);
    });

    const inst = this.search.createSearchInstance(SearchInstanceType.OVERLAY);

    this._searchInstances.set(directive, inst);

    setTimeout(() => inst.setPlaceholder(directive.placeholder), 0);

    // This is kind of lazy
    directive.onPlaceholderChange.subscribe(placeholder => {
      setTimeout(() => inst.setPlaceholder(placeholder), 0);
    });
    directive.onShowingResultsChange.subscribe(() => {});

    return inst;
  }

  /**
   * Remove search area directive to active list. There shouldn't be a reason to
   * use this method outside of SearchAreaDirective
   */
  removeSearchArea(directive: SearchAreaDirective) {
    const index = this._searchAreaDirectives.indexOf(directive);

    const inst = this._searchInstances.get(directive);
    if (inst) {
      inst.destroy();
      this._searchInstances.delete(directive);
    }

    if (index > -1) {
      this._searchAreaDirectives.splice(index, 1);
      this._viewContainers.forEach(viewContainer => {
        this._tearDownViewContainer(directive, viewContainer);
      });
    }
  }

  /**
   * Register a view container for search area directives to injector their
   * content
   */
  registerSearchAreaView(viewContainer: ViewContainerRef): SearchAreaRef {
    this._viewContainers.push(viewContainer);

    const activeDirective = this._getActiveDirective();

    if (activeDirective) {
      this._setupViewContainer(activeDirective, viewContainer);
    }

    return {
      viewContainer,
      unregister: () => {
        const index = this._viewContainers.indexOf(viewContainer);
        if (index > -1) {
          this._viewContainers.splice(index, 1);
          this._searchAreaDirectives.forEach(directive => {
            this._tearDownViewContainer(directive, viewContainer);
          });
        }
      },
      setSearchQuery: (query: string) => {
        this._notifyDirectivesQueryChange(query);
      },
    };
  }

  clearActiveSearchArea() {
    const activeDirective = this._getActiveDirective();
    if (activeDirective) {
      this._viewContainers.forEach(viewContainer => {
        this._destroyViewRef(activeDirective, viewContainer);
      });
    }
  }

  hasActiveSearchArea() {
    return !!this._getActiveDirective();
  }

  private _getActiveDirective(): SearchAreaDirective | null {
    return this._searchAreaDirectives[this._searchAreaDirectives.length - 1];
  }

  private _notifyDirectivesQueryChange(query: string) {
    this.search.setSearchQuery(query);
    this._searchAreaDirectives.forEach(directive => {
      directive.onQueryChange(query);
    });
  }

  private _setViewRef(
    directive: SearchAreaDirective,
    viewContainer: ViewContainerRef,
    viewRef: ViewRef,
  ) {
    if (this._viewRefs.has(directive)) {
      const directiveMap = this._viewRefs.get(directive);
      if (directiveMap.has(viewContainer)) {
        // Destroy existing
        directiveMap.get(viewContainer).destroy();
        // Set new!
        directiveMap.set(viewContainer, viewRef);
      } else {
        directiveMap.set(viewContainer, viewRef);
      }
    } else {
      this._viewRefs.set(directive, new WeakMap());
      this._viewRefs.get(directive).set(viewContainer, viewRef);
    }
  }

  private _getViewRef(
    directive: SearchAreaDirective,
    viewContainer: ViewContainerRef,
  ) {
    if (this._viewRefs.has(directive)) {
      const directiveMap = this._viewRefs.get(directive);
      if (directiveMap.has(viewContainer)) {
        return directiveMap.get(viewContainer) || null;
      }
    }

    return null;
  }

  private _destroyViewRef(
    directive: SearchAreaDirective,
    viewContainer: ViewContainerRef,
  ) {
    if (this._viewRefs.has(directive)) {
      const directiveMap = this._viewRefs.get(directive);
      if (directiveMap.has(viewContainer)) {
        directiveMap.get(viewContainer).destroy();
        directiveMap.delete(viewContainer);
      }
    }
  }

  private _setupViewContainer(
    directive: SearchAreaDirective,
    viewContainer: ViewContainerRef,
  ) {
    const viewRef = directive.setupViewContainer(viewContainer);
    this._setViewRef(directive, viewContainer, viewRef);
  }

  private _tearDownViewContainer(
    directive: SearchAreaDirective,
    viewContainer: ViewContainerRef,
  ) {
    this._destroyViewRef(directive, viewContainer);
  }
}
