import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, Observable } from 'rxjs';

import {
  IListUploadField,
  IListUploadValidationResponse,
  ListUploaderRow,
} from '@shared/components/XlsListUploader';

export interface IXlsListUploaderDialogData {
  readonly onValidate?: (
    records: ListUploaderRow[],
  ) => IListUploadValidationResponse;
  readonly fields: IListUploadField[];
  file?: File;
  readonly allowNoIdField?: boolean;
  readonly allowDuplicateRows?: boolean;
}

@Component({
  selector: 'mg-xls-list-uploader-dialog',
  templateUrl: './XlsListUploaderDialog.component.html',
  styleUrls: ['./XlsListUploaderDialog.component.scss'],
})
export class XlsListUploaderDialogComponent implements OnInit {
  file: File | null = null;

  fields: IListUploadField[] = [];

  fileSubject: BehaviorSubject<File | null> = new BehaviorSubject<File | null>(
    null,
  );
  files$: Observable<File | null>;

  allowDuplicateRows: boolean;
  allowNoIdfield: boolean;

  onValidate?: (records: ListUploaderRow[]) => IListUploadValidationResponse;

  constructor(
    private _matDialog: MatDialogRef<XlsListUploaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IXlsListUploaderDialogData,
  ) {
    this.files$ = this.fileSubject.asObservable();
    if (data.onValidate) {
      this.onValidate = data.onValidate;
    }
    if (data.fields) {
      this.fields = data.fields;
    }
    if (data.allowDuplicateRows) {
      this.allowDuplicateRows = data.allowDuplicateRows;
    }
    if (data.allowNoIdField) {
      this.allowNoIdfield = data.allowNoIdField;
    }
  }

  ngOnInit() {
    if (this.data.file) {
      this.file = this.data.file;
      this.onFileInputChange();
    }
  }

  async onFileInputChange() {
    // No files. Don't continue
    if (!this.file) return;

    this.fileSubject.next(this.file);
  }

  async onSubmit(rows: ListUploaderRow[]) {
    this._matDialog.close(rows);
  }
}
