<mg-button-icon
  (click)="goBack()"
  class="top left"
  icon="back"></mg-button-icon>
<form
  class="landing-form flex-spaced-content"
  fxLayout="column"
  #form="ngForm"
  (submit)="onSubmit()">
  <h1 class="mg-centered landing-title1">
    {{ 'landing.pin.enterTitle' | translate }}
  </h1>
  <mg-pin
    mgAutofocus.gt-xs
    class="input-padding mg-minicopy"
    name="pin"
    #pin="ngModel"
    [(ngModel)]="landing.pin"
    [required]="true"
    [error]="errorKey"
    [hint]="'landing.pin.hint'"
    mgNoHintMargin="true"
    [hiddenRequired]="true"
    [readonly]="false"
    placeholder="Provide your PIN to join">
  </mg-pin>
  <mg-button
    type="outline"
    class="large"
    [disabled]="form.invalid"
    >Next</mg-button
  >
</form>

<ng-template #successOverlayTemplate>
  <div class="success-overlay">
    <div class="checkmark-wrap">
      <mg-success-checkmark></mg-success-checkmark>
      <div class="mg-bodycopy">Success!</div>
    </div>
  </div>
</ng-template>
