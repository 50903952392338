import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { SystemAlertSnackBarMessage } from './constants';
import { SystemAlertSnackBarType } from './types';

@Component({
  selector: 'mg-system-alert',
  templateUrl: './system-alert.component.html',
  styleUrls: ['./system-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SystemAlertComponent {
  /** Constants */
  public readonly MSG = SystemAlertSnackBarMessage;

  /** Inputs */
  @Input() type: SystemAlertSnackBarType;
  @Input() message: string;
  @Input() dismissable: boolean;
  @Input() buttonText: string;
  @Input() isAlert = true;

  /** Outputs */
  @Output() dismissSnackbar = new EventEmitter<void>();

  public dismissAlert = false;

  public get classes() {
    return {
      'system-alert-snackbar-container': this.isAlert,
      [this.type]: true,
      alert: this.isAlert,
    };
  }

  public get icon() {
    switch (this.type) {
      case 'success':
        return 'resolved-o';
      case 'warning':
        return 'mg-warning';
      case 'error':
        return 'warning-o';
      default:
        return '';
    }
  }

  public close() {
    this.dismissAlert = true;
    this.dismissSnackbar.emit();
  }
}
