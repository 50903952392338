import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';

import { TextAlign } from 'chart.js';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { DEFAULTS } from './text.constants';
import {
  Variant,
  Color,
  Spacing,
  TextTransform,
  FontWeight,
  TextElement,
} from './text.types';
import { getColor, getElement, getSpacing, getVariant } from './text.utils';

@Component({
  selector: 'mg-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent implements OnInit, OnDestroy {
  private _variant: Variant = 'body';
  private _classes = new BehaviorSubject<string[]>([]);
  public classes$ = this._classes.asObservable();
  private _classesObj: Record<string, string> = {};
  private _renderDebounceSubject = new BehaviorSubject<string>(undefined);
  private _destroyedSubject = new ReplaySubject<void>(1);
  private _childElement: HTMLElement;

  @Input() set variant(val: Variant) {
    this._variant = getVariant(val, 'body');
    this._updateWrapperClasses({ variant: this._variant });
  }
  /**
   * Optional gives consumer the ability to decide what underlying
   * html element is used when rendering text
   */
  @Input() element?: TextElement;

  @Input() set textAlign(val: TextAlign) {
    this._updateWrapperClasses({ textAlign: val });
  }

  @Input() set fontWeight(val: FontWeight) {
    this._updateWrapperClasses({ fontWeight: `font-weight-${val}` });
  }

  @Input() set textTransform(val: TextTransform) {
    this._updateWrapperClasses({ textTransform: val });
  }

  @Input() set color(val: Color) {
    const color = getColor(val);
    this._updateWrapperClasses({ color });
  }

  @Input() set spacing(val: Spacing) {
    const spacing = getSpacing(val);
    this._updateWrapperClasses({ spacing: `spacing-${spacing}` });
  }

  /** configures how many lines before clipping with an ellipsis */
  @Input() set numberOfLines(val: number) {
    const numberOfLines = Math.min(val, 10);
    this._updateWrapperClasses({
      numberOfLines: `lines-${numberOfLines}`,
      numberOfLinesGeneric: `lines`,
    });
  }

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _renderer: Renderer2,
    private _cdr: ChangeDetectorRef,
  ) {
    this._renderDebounceSubject
      .pipe(
        takeUntil(this._destroyedSubject),
        debounceTime(100),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        if (this._childElement) {
          const wrapperClassList = this._childElement.classList;
          while (wrapperClassList.length > 0) {
            // remove all classes from wrapper element
            wrapperClassList.remove(wrapperClassList.item(0));
          }
          // replace with new classes that includes the new variant type
          wrapperClassList.add(...this._getClasses());
        }
      });
  }

  ngOnInit(): void {
    this._renderView();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  private _renderView() {
    const textWrapperElement = this._getElement();
    const wrapperClasses = this._getClasses();

    const rootElement = this._viewContainerRef.element.nativeElement.firstChild;

    const wrapperElement = this._renderer.createElement(textWrapperElement);
    wrapperElement.classList.add(...wrapperClasses);
    wrapperElement.appendChild(rootElement);

    this._childElement = wrapperElement;
    this._viewContainerRef.element.nativeElement.appendChild(wrapperElement);
  }

  private _getElement(): TextElement {
    if (!this.element) {
      const variantConfig = DEFAULTS[this._variant];
      return variantConfig.element;
    } else {
      return getElement(this.element, 'p');
    }
  }

  private _updateWrapperClasses(newClass: Record<string, string>): void {
    // keep track of classes in an object so input changes wont cause problems
    this._classesObj = {
      ...this._classesObj,
      ...newClass,
    };

    const classString = this._getClasses().join('-');
    this._renderDebounceSubject.next(classString);
  }

  private _getClasses(): string[] {
    return Object.values(this._classesObj);
  }
}
