<div class="calendar">
  <mat-calendar
    #calendar
    [headerComponent]="customHeader"
    [selected]="selectedRange"
    (selectedChange)="onDateSelected($event)"
    [minDate]="minDate"
    [maxDate]="maxDate">
  </mat-calendar>
</div>
