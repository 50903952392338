import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { EventContentService } from 'minga/app/src/app/minimal/services/EventContent';
import { MingaSettingsService } from 'minga/app/src/app/store/Minga/services';
import {
  isEventHappening,
  isEventOver,
} from 'minga/app/src/app/util/eventDates';
import { EventStatus } from 'minga/domain/event';
import { ShortEventCardView } from 'minga/proto/gateway/content_views_pb';
import { MingaPermission } from 'minga/util';
import { SentryService } from 'src/app/minimal/services/Sentry/Sentry.service';
import { PermissionsService } from 'src/app/permissions';

export interface IEventOption {
  key: string;
  value: string;
}

export const mapEventStatusToFriendlyString = (status: EventStatus): string => {
  switch (status) {
    case EventStatus.CHECKED_IN:
      return 'Checked In';
    case EventStatus.CHECKED_OUT:
      return 'Checked Out';
    case EventStatus.GOING:
      return "I'm Going";
    case EventStatus.CHECK_IN:
      return 'Check In?';
    case EventStatus.CHECK_OUT:
      return 'Check out?';
    case EventStatus.INTERESTED:
      return 'Interested';
    case EventStatus.INVITED:
      return "I'm Invited";
    case EventStatus.NOT_GOING:
      return 'Not Going';
    default:
      return 'RSVP';
  }
};
@Component({
  selector: 'mg-event-going-state',
  templateUrl: './EventGoingState.component.html',
  styleUrls: ['./EventGoingState.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventGoingStateComponent implements OnInit, OnDestroy {
  @Input()
  content?: ShortEventCardView.AsObject;

  @Input()
  context: string;

  currentStatus?: EventStatus;

  happening: boolean | null = null;

  availableStates$?: Observable<EventStatus[]>;
  currentState$: Observable<string>;

  goingClass: string;

  private _checkInStateSub?: Subscription;

  constructor(
    private _eventContentService: EventContentService,
    private _contentEvents: ContentEvents,
    private _cdr: ChangeDetectorRef,
    private _sentryService: SentryService,
    private _settingService: MingaSettingsService,
    private _permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    if (!this.context) {
      this._sentryService.captureMessageAsError(
        'EventGoingStateComponent ngOnInit happend without context',
      );
    }

    if (this.content.eventStatus !== 'NONE') {
      const status = $enum(EventStatus).asValueOrDefault(
        this.content.eventStatus,
        EventStatus.NONE,
      );
      this._eventContentService.setCheckIn(this.context, status);
    }

    this.availableStates$ = combineLatest(
      this._eventContentService.observeCheckIn(this.context),
      this._settingService.isCheckinModuleEnabled(),
      this._permissionsService.observePermission(MingaPermission.SELF_CHECKIN),
    ).pipe(
      map(([checkin, checkinEnabled, selfCheckinPerm]) => {
        this.happening = isEventHappening(
          this.content?.startTimestamp,
          this.content?.endTimestamp,
        );
        const isCheckedIn =
          checkin === EventStatus.CHECKED_IN ||
          checkin === EventStatus.CHECKED_OUT;
        const allowManualCheckin =
          checkinEnabled &&
          this.content?.allowSelfCheckIn &&
          this.content?.hasCode;

        if (
          this.happening &&
          allowManualCheckin &&
          selfCheckinPerm &&
          !isCheckedIn
        ) {
          return [EventStatus.CHECK_IN];
        }

        if (checkin === EventStatus.CHECKED_IN) {
          if (allowManualCheckin) {
            return [EventStatus.CHECK_OUT];
          } else {
            return [];
          }
        }
        if (checkin === EventStatus.CHECKED_OUT) {
          return [];
        }

        return [
          EventStatus.GOING,
          EventStatus.NOT_GOING,
          EventStatus.INTERESTED,
        ];
      }),
    );

    this.currentState$ = this._eventContentService
      .observeCheckIn(this.context)
      .pipe(map(status => mapEventStatusToFriendlyString(status)));
    this._checkInStateSub = this._eventContentService
      .observeCheckIn(this.context)
      .subscribe(status => {
        this.goingClass = status;
        this.currentStatus = status;
      });
  }

  ngOnDestroy() {
    if (this._checkInStateSub) {
      this._checkInStateSub.unsubscribe();
    }
  }

  goingClick(newState: string) {
    const status = $enum(EventStatus).asValueOrDefault(
      newState,
      EventStatus.NONE,
    );
    if (!this.content) return;
    if (this._contentEvents) {
      let going = true;
      if (status === EventStatus.NONE || status === EventStatus.NOT_GOING) {
        going = false;
      }

      this._contentEvents.emitContentEventCheckIn({
        contentContextHash: this.context,
        going,
        status,
        date: new Date(this.content.startTimestamp),
        endDate: new Date(this.content.endTimestamp),
        checkedIn: false,
        hasCode: this.content.hasCode,
        contentTitle: this.content.title,
        eventReasonId: this.content.eventReasonId,
      });
    }
  }

  isEventOver() {
    return isEventOver(
      this.content?.startTimestamp,
      this.content?.endTimestamp,
      this.content?.allDay,
    );
  }
}
