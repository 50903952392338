import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MgButtonModule } from 'minga/app/src/app/button';
import { NavsearchModule } from 'minga/app/src/app/components/Navsearch';
import { MgIconModule } from 'minga/app/src/app/icon';
import { TopNavModule } from 'minga/app/src/app/navigation/components/TopNav';

import { SearchOverlayComponent } from './SearchOverlay.component';

@NgModule({
  imports: [
    // Minga dependencies
    TopNavModule,
    NavsearchModule,
    MgIconModule,
    MgButtonModule,

    // External dependencies
    CommonModule,
    RouterModule,
    FormsModule,
  ],
  declarations: [SearchOverlayComponent],
  exports: [SearchOverlayComponent],
})
export class SearchOverlayModule {}
