import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgAvatarElement } from './MgAvatar.element';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    CommonModule,
    RouterModule,
  ],

  declarations: [MgAvatarElement],
  exports: [MgAvatarElement],
})
export class MgAvatarModule {}
