export enum MingaManagerDashboardModuleMessages {
  DIGITAL_ID = 'Digital Student IDs',
  HALL_PASS = 'Digital Hall Pass',
  COMMUNITY = 'Community',
  BEHAVIOR = 'Behaviors',
  CHECK_IN = 'Check In',
  FLEX_TIME = 'FlexTime',
  SMS = 'SMS',

  SMS_ERROR_MSG = 'Unable to load SMS details',
  SMS_ADD = 'ADD MORE SMS CREDITS',
}
