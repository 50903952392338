import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavType } from '@modules/tools/tools.types';

@Component({
  selector: 'mg-tools-nav-item',
  templateUrl: './tools-nav-item.component.html',
  styleUrls: ['./tools-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsNavItemComponent {
  @Input() label: string;

  @Input() icon: string;

  @Input() type: NavType;

  @Input() subLabel?: string;

  @Input() showBadge: boolean;

  constructor() {}
}
