<mg-report-table
  matSort
  statusReference="flex"
  [dataSourceService]="ds"
  [selectable]="canMultiSelect$ | async"
  [actionService]="actionService"
  [spaceAfterContent]="true"
  (matSortChange)="ds.sort($event)">
  <mg-role-column [datasource]="ds"></mg-role-column>
  <mg-archive-column [datasource]="ds"></mg-archive-column>
  <ng-template #actionBar>
    <mg-btn
      *ngIf="actionService.isSelectionValid()"
      variant="text"
      (click)="actionService.archiveFlexRegistration()">
      {{ MESSAGES.ARCHIVE_LABEL }}
    </mg-btn>
  </ng-template>
</mg-report-table>
