import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { filter, first, map } from 'rxjs/operators';

import { BarcodeScanner } from 'src/app/barcodeScanner';
import { GroupMemberActions } from 'src/app/groups/actions';
import { GroupsFacadeService } from 'src/app/groups/services';
import { RootService } from 'src/app/minimal/services/RootService';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PsData, PsSummaryErrorsData } from '../../types';
import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsGroupService extends PeopleSelectorFormService<'Group'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _groups: GroupsFacadeService,
    private _actions$: Actions<GroupMemberActions.TypeUnion>,
    private _rootService: RootService,
  ) {
    super(
      {
        name: 'Group',
        pageDefinitions: {
          add: {
            submitFn: async () => this.submitAdd(),
            searchFn: async (type, filters) =>
              this._psCollectionSearch.collectionSearch(type, filters),
          },
          remove: {
            submitFn: async () => this.submitRemove(),
            searchFn: async () => this._getMembersFromGroupDetails(),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );
  }

  public async submitAdd(): Promise<void> {
    const { groupHash } = this.data;
    this._groups.dispatchAddGroupMembers(
      groupHash,
      this.selection.getSelectionHashes(),
    );
    await this._rootService
      .addLoadingPromise(
        this._actions$
          .pipe(
            ofType(
              GroupMemberActions.TypeEnum.AddGroupMembersSuccess,
              GroupMemberActions.TypeEnum.AddGroupMembersFailure,
            ),
            first(),
          )
          .toPromise(),
      )
      .then(async ({ type, payload }: any) => {
        if (type === GroupMemberActions.TypeEnum.AddGroupMembersSuccess)
          await this._showSuccessModal('add');
        else await this._showErrorModal('add', payload?.message);
      });
  }

  public async submitRemove(): Promise<void> {
    const { groupHash } = this.data;
    this._groups.dispatchRemoveGroupMembers(
      groupHash,
      this.selection.getSelectionHashes(),
    );
    await this._rootService
      .addLoadingPromise(
        this._actions$
          .pipe(
            ofType(
              GroupMemberActions.TypeEnum.RemoveGroupMemberSuccess,
              GroupMemberActions.TypeEnum.RemoveGroupMemberFailure,
            ),
            first(),
          )
          .toPromise(),
      )
      .then(async ({ type, payload }: any) => {
        const selectionHashes = new Set<string>(
          this.selection.getSelectionHashes(),
        );
        const memberHashes = new Set<string>(
          payload.members.map(p => p.person.personHash),
        );

        const validHashes = Array.from(selectionHashes).filter(
          hash => !memberHashes.has(hash),
        );
        const invalidHashes = Array.from(selectionHashes).filter(hash =>
          memberHashes.has(hash),
        );

        if (
          type === GroupMemberActions.TypeEnum.RemoveGroupMemberSuccess &&
          invalidHashes.length + validHashes.length > 0
        ) {
          if (invalidHashes.length === 0) {
            await this._showSuccessModal('remove');
          } else if (validHashes.length === 0) {
            await this._showErrorModal('remove', `Can't be removed from group`);
          } else {
            await this._showSummaryModal(validHashes, invalidHashes);
          }
        } else {
          throw new Error(
            'Error removing member from group: ' + payload?.message,
          );
        }
      });
  }

  private async _getMembersFromGroupDetails(): Promise<PsData[]> {
    const { groupHash } = this.data;
    this._groups.dispatchLoadGroupDetails(groupHash);
    return this._groups
      .getGroupDetails(groupHash)
      .pipe(
        filter(details => !!details),
        first(),
        map(({ members }) =>
          members.map(
            ({
              person: {
                personHash,
                badgeIconUrl: badge,
                displayName,
                studentId,
              },
            }) =>
              ({
                personHash,
                displayName,
                badge,
                studentId,
              } as PsData),
          ),
        ),
      )
      .toPromise();
  }

  private async _showSuccessModal(type: 'add' | 'remove'): Promise<void> {
    const selection = this.selection.getSelection();
    const hasManyPeople = selection.length > 1;
    const extendedMessage = `${selection.length} people have been ${
      type === 'add' ? 'added to' : 'removed from'
    } the group`;
    await this.peopleSelector.openDialog({
      type: 'success',
      title: hasManyPeople ? undefined : selection[0].displayName,
      subTitle: type === 'add' ? 'Added to group' : 'Removed from group',
      message: this.formTitle,
      extendedMessage: hasManyPeople ? extendedMessage : undefined,
    });
  }

  private async _showErrorModal(
    type: 'add' | 'remove',
    message: string,
  ): Promise<void> {
    const selection = this.selection.getSelection();
    const hasManyPeople = selection.length > 1;
    await this.peopleSelector.openDialog({
      type: 'error',
      title: hasManyPeople ? undefined : selection[0].displayName,
      subTitle: type === 'add' ? 'Add to group' : 'Remove from group',
      message: this.formTitle,
      extendedMessage: message,
    });
  }

  private async _showSummaryModal(
    validHashes: string[],
    invalidHashes: string[],
  ): Promise<void> {
    const errorsData = this._makeErrorData(
      validHashes,
      invalidHashes,
      'remove',
    );
    await this.peopleSelector.openSummary({
      message: `could not be removed`,
      title: `Group members summary`,
      subMessage: '',
      errorsData,
      displayOnly: true,
    });
  }

  private _makeErrorData(
    validHashes: string[],
    invalidHashes: string[],
    type: 'add' | 'remove',
  ): PsSummaryErrorsData[] {
    const successes = validHashes.map(hash => {
      const person = this.selection.getSelected(hash);
      return {
        hash,
        name: person.displayName,
        status: 'success',
        reasons: [type === 'add' ? 'Added' : 'Removed'],
      } as PsSummaryErrorsData;
    });
    const errors = invalidHashes.map(hash => {
      const person = this.selection.getSelected(hash);
      return {
        hash,
        name: person.displayName,
        status: 'error',
        reasons: [`Can't ${type === 'add' ? 'add' : 'remove'} this user`],
      } as PsSummaryErrorsData;
    });

    return [...errors, ...successes];
  }
}
