import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { Observable, Subject } from 'rxjs';

const mentionClickedSubject = new Subject<MgMentionElement>();

export interface IMgMentionElementProperties {
  hash: string;
}

@Component({
  selector: 'mg-mention-element',
  templateUrl: './MgMention.element.html',
  styleUrls: ['./MgMention.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgMentionElement {
  static onMentionClicked: Observable<MgMentionElement> =
    mentionClickedSubject.asObservable();

  @Input()
  hash: string = '';

  get element(): MgMentionElement.Element {
    // This should be safe to do
    return <any>this._element.nativeElement;
  }

  @HostListener('click', ['$event'])
  _onClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    mentionClickedSubject.next(this);
  }

  constructor(private _element: ElementRef) {}
}

export namespace MgMentionElement {
  export type Element = NgElement & WithProperties<IMgMentionElementProperties>;
}

declare global {
  interface HTMLElementTagNameMap {
    'mg-mention': MgMentionElement.Element;
  }
}
