<mg-button-icon
  (click)="cancel()"
  class="top left"
  icon="close"></mg-button-icon>
<div class="mg-dialog save">
  <p
    [innerHTML]="text | translate: translateParams"
    class="dialog-title"></p>
  <p
    *ngIf="body"
    [innerHTML]="body | translate: bodyParams"
    class="body-text"></p>

  <ng-container *ngIf="inputType && inputControl">
    <mat-form-field mgFormField>
      <input
        matInput
        [name]="'saveCancel-' + inputType"
        [placeholder]="inputPlaceholder | translate"
        [(ngModel)]="inputValue"
        (keydown)="onKeyDown($event)"
        [type]="inputType"
        [autocomplete]="'saveCancel-' + inputType"
        autocapitalize="none"
        [formControl]="inputControl"
        required />

      <mat-error
        *ngIf="
          inputControl.invalid && (inputControl.dirty || inputControl.touched)
        ">
        <ng-container [ngSwitch]="inputControlErrorKey">
          <span *ngSwitchCase="'pattern'">
            {{ 'input.generic.error.pattern' | translate: translateParams }}
          </span>
          <span *ngSwitchCase="'maxlength'">
            {{ 'input.generic.error.pattern' | translate: translateParams }}
          </span>
          <span *ngSwitchCase="'email'">
            <!-- Sorry, that's not a real email address -->
            {{ 'input.email.error.email' | translate }}
          </span>
          <span *ngSwitchDefault>
            {{ 'input.generic.error.required' | translate: translateParams }}
          </span>
        </ng-container>
      </mat-error>
    </mat-form-field>
  </ng-container>

  <div class="footer-button-container">
    <mg-button
      type="text"
      (click)="close(false)"
      >{{ cancelButtonLocaleKey | translate }}</mg-button
    >
    <mg-button-outline
      (click)="close(true)"
      [disabled]="disableSaveButton">
      {{ saveButtonLocaleKey | translate }}
    </mg-button-outline>
  </div>
</div>
