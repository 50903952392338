<div class="mat-calendar-header">
  <button
    mat-icon-button
    class="dt-reset-button"
    (click)="resetDatePicker()"
    aria-label="Reset">
    <mat-icon>refresh</mat-icon>
  </button>
  <div class="mat-calendar-controls">
    <button
      mat-icon-button
      class="mat-calendar-previous-button"
      (click)="previousClicked()"
      [attr.aria-label]="prevButtonLabel"></button>
    <button
      mat-button
      type="button"
      class="mat-calendar-period-button"
      (click)="currentPeriodClicked()"
      [attr.aria-label]="periodButtonLabel"
      [attr.aria-label]="periodButtonLabel"
      cdkAriaLive="polite">
      <span class="calendar-custom-label">{{ periodButtonText }}</span>
      <div
        class="mat-calendar-arrow"
        [class.mat-calendar-invert]="calendar.currentView !== 'month'"></div>
    </button>
    <button
      mat-icon-button
      class="mat-calendar-next-button"
      (click)="nextClicked()"
      [attr.aria-label]="nextButtonLabel"></button>
  </div>
  <button
    mat-icon-button
    class="dt-close-button"
    (click)="closeDatePicker()"
    aria-label="Close">
    <mat-icon>close</mat-icon>
  </button>
</div>
