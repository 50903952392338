import { Injector, NgModule } from '@angular/core';

import { MgProgressiveImageElement } from './MgProgressiveImage.element';

@NgModule({
  declarations: [MgProgressiveImageElement],
  exports: [MgProgressiveImageElement],
})
export class MgProgressiveImageModule {
  constructor() {}
}
