import {
  Directive,
  Host,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { IStreamSwipeStackHead } from './IStreamSwipeStackItem';
import { StreamSwipeStackComponent } from './StreamSwipeStack.component';

@Directive({
  selector: '[mgStreamSwipeStackHead]',
})
export class StreamSwipeStackHeadDirective implements IStreamSwipeStackHead {
  @Input()
  mgStreamSwipeStackHead: any;

  constructor(
    @Host() private streamSwipeStack: StreamSwipeStackComponent<any>,
    public templateRef: TemplateRef<any>,
    public viewContainer: ViewContainerRef,
  ) {
    this.streamSwipeStack.registerHead(this);
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.streamSwipeStack.unregisterHead(this);
  }
}
