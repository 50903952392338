import {
  Directive,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

import { SearchAreaService } from '../services/SearchArea.service';

@Directive({
  exportAs: 'mgSearchArea',
  selector: '[mgSearchArea]',
})
export class SearchAreaDirective implements OnDestroy, OnInit, OnChanges {
  showingResults: boolean = false;

  @Input()
  placeholder: string = 'Search';

  @Output()
  onPlaceholderChange = new EventEmitter<string>();

  @Output()
  onShowingResultsChange = new EventEmitter<boolean>();

  @Output()
  searchText = new EventEmitter<string>();

  constructor(
    private template: TemplateRef<any>,
    private searchAreaService: SearchAreaService,
  ) {}

  setShowingResults(value: boolean) {
    this.showingResults = value;
    this.onShowingResultsChange.emit(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.placeholder) {
      this.onPlaceholderChange.emit(this.placeholder);
    }

    if (changes.showingResults) {
      this.onShowingResultsChange.emit(this.showingResults);
    }
  }

  onQueryChange(query: string) {
    this.searchText.emit(query);
  }

  setupViewContainer(viewContainer: ViewContainerRef): EmbeddedViewRef<any> {
    return viewContainer.createEmbeddedView(this.template);
  }

  ngOnInit() {
    this.searchAreaService.addSearchArea(this);
  }

  ngOnDestroy() {
    this.searchAreaService.removeSearchArea(this);
  }
}
