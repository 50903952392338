import { FormGroup } from '@angular/forms';

import {
  ExplicitItemConfig,
  FormConfig,
  ItemConfig,
} from './crud-form-base.types';

export const markNestedFormGroupAsDirty = (form: FormGroup) => {
  Object.values(form.controls).forEach(control => {
    if (control instanceof FormGroup) {
      markNestedFormGroupAsDirty(control);
    } else {
      control.markAsTouched();
      control.updateValueAndValidity();
    }
  });
};

const isImplicit = <Data>(value: ItemConfig<Data>): boolean => {
  return Array.isArray(value);
};

/**
 * Sets form values from data object (form initialization)
 */
export const setForm =
  <FormFields extends PropertyKey, Data>(
    formConfig: FormConfig<FormFields, Data>,
  ) =>
  (data: Data, form: FormGroup): void => {
    const formData = {};

    Object.entries(formConfig).forEach(([key, value]) => {
      const implicit = isImplicit(value as ItemConfig<Data>);
      const defaultValue = implicit
        ? value[0]
        : (value as ExplicitItemConfig<Data>).formControl[0];

      if (implicit) {
        formData[key] = data[key] || defaultValue;
      } else {
        const itemConfig = value as ExplicitItemConfig<Data>;

        if (itemConfig.set) {
          formData[key] = itemConfig.set(data);
        } else {
          const dataKey = itemConfig.key;
          formData[key] = data[dataKey] || defaultValue;
        }
      }
    });

    form.patchValue(formData);
    form.markAsPristine();
    form.markAsUntouched();
  };

/**
 * Goes through form data and maps to data object (form submission)
 */
export const getData =
  <FormFields extends PropertyKey, Data>(
    formConfig: FormConfig<FormFields, Data>,
  ) =>
  (data: Data, form: FormGroup): Data => {
    const mapped = {
      ...data,
    };

    const formValues = form.value;

    Object.entries(formValues)
      .filter(([key]) => formConfig[key])
      .forEach(([key, value]) => {
        const itemConfig = formConfig[key] as ExplicitItemConfig<Data>;
        const implicit = isImplicit(formConfig[key]);

        if (implicit) {
          mapped[key] = value;
        } else {
          const dataKey = itemConfig.key;
          mapped[dataKey] = itemConfig.get ? itemConfig.get(formValues) : value;
        }
      });

    return mapped;
  };

/**
 * Initializes form control configuration
 */
export const createForm =
  <Config extends FormConfig<any, any>>(formConfig: Config) =>
  () => {
    const config = {};
    Object.entries(formConfig).forEach(([key, value]) => {
      const formControl = Array.isArray(value) ? value : value.formControl;
      config[key] = formControl;
    });

    return config;
  };
