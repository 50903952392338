import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MgChallengeShortCardModule } from 'minga/app/src/app/elements/MgShortCard/MgChallengeShortCard';
import { MgSmsMessageShortCardModule } from 'minga/app/src/app/elements/MgShortCard/MgSmsMessageShortCard';

import { MgMiniCardModule } from '../MgMiniCard';
import { MgAnnouncementShortCardModule } from '../MgShortCard/MgAnnouncementShortCard';
import { MgBirthdayShortCardModule } from '../MgShortCard/MgBirthdayShortCard';
import { MgEventMiniCardModule } from '../MgShortCard/MgEventMiniCard/MgEventMiniCard.module';
import { MgEventShortCardModule } from '../MgShortCard/MgEventShortCard';
import { MgInspirationShortCardModule } from '../MgShortCard/MgInspirationShortCard';
import { MgPollShortCardModule } from '../MgShortCard/MgPollShortCard/MgPollShortCard.module';
import { MgPostShortCardModule } from '../MgShortCard/MgPostShortCard';
import { MgVideoShortCardModule } from '../MgShortCard/MgVideoShortCard';

import { MgAnyShortCardElement } from './MgAnyShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgMiniCardModule,
    MgAnnouncementShortCardModule,
    MgBirthdayShortCardModule,
    MgEventMiniCardModule,
    MgEventShortCardModule,
    MgInspirationShortCardModule,
    MgPollShortCardModule,
    MgPostShortCardModule,
    MgVideoShortCardModule,
    MgChallengeShortCardModule,
    MgSmsMessageShortCardModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgAnyShortCardElement],
  exports: [MgAnyShortCardElement],
})
export class MgAnyShortCardModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-any-short-card')) {
      //       //   MgAnyShortCardElement,
      //   {injector},
      // ));
    }
  }
}
