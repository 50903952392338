import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { splashScreen } from 'minga/app/src/splashscreen';

import { HomeService } from './services';

@Component({
  selector: 'mg-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HomeService],
})
export class HomeComponent implements OnInit {
  /** Component Constructor */
  constructor(public route: ActivatedRoute, public router: Router) {}

  ngOnInit() {
    splashScreen.hide();
  }
}
