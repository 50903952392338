import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { DialogModule } from 'minga/app/src/app/components/Dialog';
import { MgEmptyStateModule } from 'minga/app/src/app/components/EmptyState';
import { FitGridModule } from 'minga/app/src/app/components/FitGrid';
import { GalleryLightboxModule } from 'minga/app/src/app/components/Lightbox/GalleryLightbox';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgStreamScrollerModule } from 'minga/app/src/app/components/MgStreamScroller';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { MgButtonModule } from 'src/app/button';
import { PermissionsModule } from 'src/app/permissions';

import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { GenericModule } from '@shared/components/generic';

import { HomeActionButtonModule } from '../home-action-button/home-action-button.module';
import { HomeGalleryAddComponent } from './components/home-gallery-add/home-gallery-add.component';
import { HomeGalleryFeedComponent } from './components/home-gallery-feed/home-gallery-feed.component';
import { HomeGalleryComponent } from './home-gallery.component';
import { EventGalleryService, GalleryDataResolver } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    FitGridModule,
    MgStreamScrollerModule,
    MgImageModule,
    MgPipesModule,
    MgEmptyStateModule,
    LayoutLegacyContentModule,
    HomeActionButtonModule,
    GenericModule,
    PermissionsModule,
    MgButtonModule,
    GalleryLightboxModule,
    DialogModule,

    // External dependencies
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
  ],
  declarations: [
    HomeGalleryComponent,
    HomeGalleryAddComponent,
    HomeGalleryFeedComponent,
  ],
  providers: [EventGalleryService, GalleryDataResolver],
  exports: [HomeGalleryComponent],
})
export class HomeGalleryModule {}
