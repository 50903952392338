import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'mg-button-block',
  templateUrl: './BlockButton.component.html',
  styleUrls: ['./BlockButton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockButton {
  @HostBinding('class.disabled')
  @Input()
  disabled: boolean = false;
  @Input()
  type = '';
  @Input()
  active: boolean = false;
  @Input()
  color: string = 'primary';
  typePostFix = 'block';

  constructor() {}

  get _type() {
    return this.type + ' ' + this.typePostFix;
  }
}
