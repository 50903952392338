// Taken from: https://github.com/firebase/firebase-js-sdk/issues/2103
export function patchCordovaBrowserTabPlugin() {
  const cordova: any = (window as any).cordova;
  if (!cordova) return;

  const SafariViewController: any = (window as any).SafariViewController;

  /* Check Plugins */
  if (cordova.plugins === undefined) {
    cordova.plugins = {};
  }

  /* Browsertab */
  cordova.plugins.browsertab = {};

  /* Browsertab - Is Available */
  cordova.plugins.browsertab.isAvailable = function (success: any) {
    console.log('is available');
    if (SafariViewController) {
      SafariViewController.isAvailable(success);
    } else {
      return success(false);
    }
  };
  if (!SafariViewController) {
    console.warn('SafariViewController unavailable');
    return;
  }

  /* Browsertab - Open Url */
  cordova.plugins.browsertab.openUrl = function (url: any) {
    console.log('opening url in sfviewcontroller', url);
    SafariViewController.show(
      { url, toolbarColor: '#003366', barColor: '#003366' },
      function () {},
      function () {},
    );
  };

  /* Browsertab - Close */
  cordova.plugins.browsertab.close = function () {
    console.log('hiding safari view controller');
    SafariViewController.hide();
  };
}
