import { Pipe, PipeTransform } from '@angular/core';

import { find } from 'lodash';

@Pipe({ name: 'getDueDateActiveFromId' })
export class GetDueDateActiveFromIdPipe implements PipeTransform {
  transform(consequences: any[], consequenceId: any): boolean | null {
    const matchedConsequence = find(consequences, { value: consequenceId });
    if (matchedConsequence) {
      return matchedConsequence.dueDate ?? false;
    }

    return false;
  }
}
