<mg-short-card
  #shortCard
  *ngIf="content"
  [authorPersonView]="content?.authorPersonView"
  [timestamp]="content?.timestamp"
  [title]="content?.title"
  [body]="body || content?.body | ugcLinksHighlight"
  [ownerGroupHash]="content?.ownerGroupHash"
  [lightContent]="true"
  [pinned]="pinned"
  [markKeywords]="markKeywords"
  [commentRoles]="commentRoles"
  [context]="context"
  [content]="content">
  <mg-short-card-byline>
    <div class="announcement-icon-wrap">
      <div class="announcement-icon">
        <div class="announcement-icon-img"></div>
      </div>
    </div>
  </mg-short-card-byline>

  <mg-short-card-featured-image>
    <div class="banner">
      <div class="banner-image">
        <mge-icon
          *ngIf="shortCard.pinned"
          class="pinned-icon"
          icon="pin-o"></mge-icon>
      </div>
      <div class="announcement-text">ANNOUNCEMENT</div>
    </div>
  </mg-short-card-featured-image>

  <mg-short-card-body>
    <div class="body-contents content-block">
      <mg-clipped-html
        #clippedBodyHtml
        [fullHtml]="shortCard.body"
        [clippedLength]="maxBodyLength"
        (afterViewContainerSetup)="cdr.detectChanges()">
        <mg-clipped-html-head></mg-clipped-html-head>
        <mg-clipped-html-tail *ngIf="hasExpandableBody">
          <a
            class="more-link"
            href="javascript:;"
            >...More</a
          >
        </mg-clipped-html-tail>
      </mg-clipped-html>
    </div>
  </mg-short-card-body>

  <mg-short-card-group-name>
    <ng-container *ngIf="shortCard.shouldShowGroupLinkSlot()">
      <div
        class="announcement-group-container post-info"
        fxLayout="row">
        <div
          class="group-link-container"
          fxFlex
          (click)="shortCard.groupLinkClick($event)">
          <span class="group-icon"></span>
          <span class="group-link-text"
            >Posted in
            <span class="group-link">{{ shortCard.groupName }}</span></span
          >
        </div>
        <mg-ellipsis [mgContentContextMenuClick]="context"></mg-ellipsis>
      </div>
    </ng-container>
  </mg-short-card-group-name>

  <mg-short-card-title>
    <div
      class="announcement-headline-container post-info"
      fxLayout="row">
      <h1
        class="title content-block"
        fxFlex
        [ngClass]="'group-content-' + shortCard.shouldShowGroupLinkSlot()"
        [innerHTML]="content.title"></h1>
      <ng-container *ngIf="!shortCard.shouldShowGroupLinkSlot()">
        <mg-ellipsis [mgContentContextMenuClick]="context"></mg-ellipsis>
      </ng-container>
    </div>

    <div class="date-posted mg-minicopy">
      {{ shortCard.humanize(content.timestamp) }}
    </div>
  </mg-short-card-title>

  <mg-short-card-action-bar></mg-short-card-action-bar>
</mg-short-card>
