import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ISearchInstance,
  SearchInstanceType,
  SearchService,
} from 'minga/app/src/app/search/services/Search.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mg-search-active-route',
  template: '',
})
export class SearchActiveRouteComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input()
  searchPlaceholder: string = '';

  @Output()
  searchQueryChange: EventEmitter<string>;

  private _sub?: Subscription;
  private _searchInstance?: ISearchInstance;

  constructor(private search: SearchService) {
    this.searchQueryChange = new EventEmitter();
  }

  ngOnInit() {
    this._searchInstance = this.search.createSearchInstance(
      SearchInstanceType.INLINE,
    );
    this._searchInstance.setPlaceholder(this.searchPlaceholder);
    this._sub = this.search.searchQuery$
      .pipe(debounceTime(400))
      .subscribe(searchQuery => this.searchQueryChange.emit(searchQuery));
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }

    if (this._searchInstance) {
      this._searchInstance.destroy();
      delete this._searchInstance;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchPlaceholder) {
      if (this._searchInstance) {
        this._searchInstance.setPlaceholder(this.searchPlaceholder);
      }
    }
  }
}
