import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommentViewModule } from 'minga/app/src/app/content-views/components/CommentView';
import { MgDirectivesModule } from 'minga/app/src/app/directives';
import { InputsModule } from 'minga/app/src/app/inputs';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { CommentListViewComponent } from './CommentListView.component';

@NgModule({
  imports: [
    // Minga dependencies
    CommentViewModule,
    InputsModule,
    MgDirectivesModule,
    MgPipesModule,
    MgSpinnerModule,
    PermissionsModule,
    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [CommentListViewComponent],
  exports: [CommentListViewComponent],
})
export class CommentListViewModule {}
