import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Breadcrumb } from '@shared/components/navigation/components/breadcrumb/breadcrumb.types';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  // Cleanup
  private readonly _breadcrumbSubject$ = new BehaviorSubject<Breadcrumb[]>([]);
  public breadcrumb$ = this._breadcrumbSubject$.asObservable();

  /** Service constructor */
  constructor() {}

  public setBreadcrumb(breadcrumb: Breadcrumb[]) {
    this._breadcrumbSubject$.next(breadcrumb);
  }

  public resetBreadcrumb() {
    this._breadcrumbSubject$.next([]);
  }
}
