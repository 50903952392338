<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="(formState$ | async) === 'loading'"
  overlayTitle="Schedule a report">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainTemplate>
  <main
    class="modal-form"
    #crudForm>
    <form
      id="scheduled-report-form"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <fieldset *ngIf="(formState$ | async) === 'invalid'">
          <mg-system-alert
            type="error"
            [message]="MESSAGES.ERROR_GENERIC"></mg-system-alert>
        </fieldset>
        <fieldset>
          <mg-form-text-input
            [label]="MESSAGES.FIELD_LABEL_NAME"
            [control]="form.get(FORM_FIELDS.NAME)">
          </mg-form-text-input>
        </fieldset>

        <fieldset>
          <legend>
            <mg-text variant="header-sm-secondary">
              {{ MESSAGES.FIELDSET_REPORT_TYPE }}
            </mg-text>
          </legend>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mg-form-select
              [placeholder]="MESSAGES.FIELD_LABEL_REPORT_TYPE"
              [options]="REPORT_TYPE_OPTIONS"
              [value]="form.get(FORM_FIELDS.REPORT_TYPE).value"
              [control]="form.get(FORM_FIELDS.REPORT_TYPE)">
            </mg-form-select>

            <ng-container
              *ngIf="(formState$ | async) !== 'loading'"
              [ngTemplateOutlet]="reportFilters"></ng-container>
          </div>
        </fieldset>
        <fieldset>
          <legend>
            <mg-text variant="header-sm-secondary">
              {{ MESSAGES.FIELDSET_FREQUENCE }}
            </mg-text>
          </legend>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mg-form-select
              class="frequency-width"
              [placeholder]="MESSAGES.FIELD_LABEL_FREQUENCE"
              [options]="FREQUENCY_OPTIONS"
              [value]="form.get(FORM_FIELDS.FREQUENCY).value"
              [control]="form.get(FORM_FIELDS.FREQUENCY)">
            </mg-form-select>
            <ng-container
              *ngIf="
                form.get(FORM_FIELDS.FREQUENCY).value === FREQUENCE_TYPES.Weekly
              ">
              <mg-form-select
                class="frequency-width"
                appendTo="body"
                [placeholder]="MESSAGES.FIELD_LABEL_DATE"
                [options]="DAY_OPTIONS"
                [value]="form.get(FORM_FIELDS.DAY).value"
                [control]="form.get(FORM_FIELDS.DAY)">
              </mg-form-select>
            </ng-container>
            <div>
              <mg-form-text-input
                class="frequency-width"
                inputType="time"
                [step]="60"
                [label]="MESSAGES.FIELD_LABEL_TIME"
                [control]="form.get(FORM_FIELDS.TIME)">
              </mg-form-text-input>
              <!-- Hide hint if overdue report, since that one is all time. -->
              <mg-text
                *ngIf="
                  form.get(FORM_FIELDS.REPORT_TYPE).value !==
                  REPORT_TYPES.PBIS_OVERDUE_CONS
                "
                color="surface-alt"
                variant="label-md">
                {{
                  form.get(FORM_FIELDS.FREQUENCY).value
                    | frequencyHint
                      : form.get(FORM_FIELDS.DAY).value
                      : form.get(FORM_FIELDS.TIME).value
                }}
              </mg-text>
            </div>

            <!-- The reports will include data from Thursday at 0am till next Wednesday at 3:00pm  -->
          </div>
        </fieldset>
        <fieldset>
          <legend>
            <mg-text variant="header-sm-secondary">
              {{ MESSAGES.FIELDSET_DELIVERY_METHOD }}
            </mg-text>
          </legend>
          <mg-form-select
            appendTo="body"
            [multiple]="true"
            [isFullWidth]="true"
            [placeholder]="MESSAGES.FIELD_LABEL_DELIVERY_METHOD"
            [options]="DELIVERY_OPTIONS"
            [control]="form.get(FORM_FIELDS.DELIVERY_TYPE)">
          </mg-form-select>
        </fieldset>
        <fieldset
          *ngIf="form.get(FORM_FIELDS.DELIVERY_TYPE).value | includes: 'email'">
          <div>
            <mg-text
              variant="header-sm"
              spacing="3"
              >{{ MESSAGES.FIELD_LABEL_EMAIL }}</mg-text
            >

            <mg-form-select
              mgTeacherSearch
              appendTo="body"
              [placeholder]="MESSAGES.FIELD_LABEL_EMAIL2"
              [useCachedValues]="false"
              [value]="form.get(FORM_FIELDS.EMAIL).value"
              [closeOnSelect]="true"
              [multiple]="true"
              [searchable]="true"
              [isFullWidth]="true"
              [hideSelected]="false"
              (blurEvent)="form.get(FORM_FIELDS.EMAIL).markAsTouched()"
              [control]="form.get(FORM_FIELDS.EMAIL)">
            </mg-form-select>
          </div>
        </fieldset>
        <fieldset
          *ngIf="form.get(FORM_FIELDS.DELIVERY_TYPE).value | includes: 'sftp'">
          <div>
            <mg-text
              variant="header-sm"
              spacing="3"
              >{{ MESSAGES.FIELD_LABEL_SFTP_INFO }}</mg-text
            >
            <div
              fxLayout="column"
              fxLayoutAlign="start stretch"
              fxLayoutGap="12px">
              <mg-form-text-input
                [label]="MESSAGES.FIELD_LABEL_SFTP_HOST"
                [control]="form.get(FORM_FIELDS.SFTP_HOST)">
              </mg-form-text-input>
              <mg-form-text-input
                [label]="MESSAGES.FIELD_LABEL_SFTP_USERNAME"
                [control]="form.get(FORM_FIELDS.SFTP_USER_NAME)">
              </mg-form-text-input>
              <mg-form-text-input
                [label]="MESSAGES.FIELD_LABEL_SFTP_PASSWORD"
                [control]="form.get(FORM_FIELDS.SFTP_PASSWORD)">
              </mg-form-text-input>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  </main>
</ng-template>

<!-- Footer Content Templat -->
<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [variant]="(isNew$ | async) === true ? 'text' : 'destructive'"
        (pressed)="confirmDelete()">
        {{ deleteLabel }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="scheduled-report-form"
        variant="filled"
        [disabled]="!canSubmit">
        {{ submitLabel }}
      </mg-btn>
    </div>
  </footer>
</ng-template>

<!-- Report filters by type -->
<ng-template #reportFilters>
  <mg-form-error *ngIf="form.getError('invalidFilters')">
    {{ MESSAGES.ERROR_INVALID_FILTERS }}
  </mg-form-error>

  <ng-container *ngIf="form.get(FORM_FIELDS.REPORT_TYPE).value as reportType">
    <ng-container *ngIf="reportType === REPORT_TYPES.PBIS_HISTORY">
      <mg-sr-behavior-filters
        [form]="form"
        [defaultValues$]="defaultFilterValues$"></mg-sr-behavior-filters>
    </ng-container>
    <ng-container *ngIf="reportType === REPORT_TYPES.PBIS_CONS_HISTORY">
      <mg-sr-consequence-filters
        [form]="form"
        [defaultValues$]="defaultFilterValues$"></mg-sr-consequence-filters>
    </ng-container>
    <ng-container *ngIf="reportType === REPORT_TYPES.PBIS_OVERDUE_CONS">
      <mg-sr-consequence-overdue-filters
        [form]="form"
        [defaultValues$]="
          defaultFilterValues$
        "></mg-sr-consequence-overdue-filters>
    </ng-container>
    <ng-container *ngIf="reportType === REPORT_TYPES.CHECKIN_HISTORY">
      <mg-sr-checkin-filters
        [form]="form"
        [defaultValues$]="defaultFilterValues$"></mg-sr-checkin-filters>
    </ng-container>
    <!-- all hall pass report types have the same filters -->
    <ng-container *ngIf="reportType.includes('hall_pass')">
      <mg-sr-hall-pass-filters
        [form]="form"
        [defaultValues$]="defaultFilterValues$"></mg-sr-hall-pass-filters>
    </ng-container>
  </ng-container>
</ng-template>
