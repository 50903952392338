import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { _getDeviceModel } from '@lib/cordova/device';
import { first } from 'rxjs/operators';
import YouTubePlayer from 'youtube-player';

import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';
import { VimeoPlayer } from 'minga/app/src/app/util/vimeo-player';
import { FeedVideoManager } from 'minga/proto/gateway/feed_video_ng_grpc_pb';
import {
  VimeoVideoDataRequest,
  YouTubeVideoDataRequest,
} from 'minga/proto/gateway/feed_video_pb';

export const DEFAULT_VIDEO_HEIGHT = 360;
// Matches maxwidth
export const DEFAULT_VIDEO_MAXWIDTH = 740;
// The width that vimeo thumbnails get video dimensions
export const VIMEO_MIN_THUMBNAIL_WIDTH = 640;

export interface IVideoData {
  youtubeId?: string;
  vimeoId?: string;
  title: string;
  body?: string;
  thumbnails: IVideoThumbnail[];
  url?: string;
  width?: number;
  height?: number;
  youtubeWidth?: number;
  youtubeHeight?: number;
  youtubeTitle?: string;
  cloudflareVideoUrl?: string;
  cloudflareUid?: string;
  cloudflareReady?: boolean;
}

export interface IVideoThumbnail {
  url: string;
  width?: number;
  height?: number;
  custom?: boolean;
}

declare var YoutubeVideoPlayer: any;

@Injectable({ providedIn: 'root' })
export class VideoService {
  youtubePlayer;
  vimeoPlayer;

  constructor(
    private http: HttpClient,
    private analytics: AnalyticsService,
    private videoManager: FeedVideoManager,
    private appConfig: AppConfigService,
  ) {}

  embedFromData(
    element: HTMLElement,
    videoData: IVideoData,
    autoplay: boolean = false,
  ) {
    this.removeElementChildren(element);
    this.youtubePlayer = this.vimeoPlayer = null;

    let youtubeElement = document.createElement('div');
    youtubeElement.className = 'youtube-player';
    element.appendChild(youtubeElement);

    let width: string | number = videoData.width ? videoData.width : '100%';
    let height: string | number = videoData.height
      ? videoData.height
      : DEFAULT_VIDEO_HEIGHT;

    if (!videoData.vimeoId && !videoData.youtubeId) {
      let ytCode = videoData.youtubeId
        ? videoData.youtubeId
        : this._getYoutubeIdFromUrl(videoData.url);
      if (!ytCode) {
        ytCode = this._getVimeoIdFromUrl(videoData.url);
        if (ytCode) {
          videoData.vimeoId = ytCode;
        }
      } else {
        videoData.youtubeId = ytCode;
      }
    }

    if (videoData.vimeoId) {
      let vimeoId = videoData.vimeoId;
      return this._createVimeoPlayer(
        youtubeElement,
        vimeoId,
        autoplay,
        width,
        height,
      );
    } else if (videoData.youtubeId) {
      let ytCode = videoData.youtubeId;

      return this._createYouTubePlayer(
        youtubeElement,
        ytCode,
        autoplay,
        width,
        height,
      );
    }

    return null;
  }

  playVideo(autoplay = false) {
    if (this.youtubePlayer) {
      this.youtubePlayer.playVideo().then(() => {
        console.log('then after playVideo() of youtube player');
      });

      this.youtubePlayer.on('stateChange', event => {
        if (event.data == -1 && autoplay) {
          // console.log(
          //   'calling playvideo again on', this.youtubePlayer.getVideoUrl());
          event.target.playVideo();
        }
      });
    } else if (this.vimeoPlayer) {
      this.vimeoPlayer.play();
    }
  }

  pauseVideo() {
    if (this.youtubePlayer) {
      this.youtubePlayer.pauseVideo();
    } else if (this.vimeoPlayer) {
      this.vimeoPlayer.pause();
    }
  }

  destroyYouTubePlayer() {
    if (this.youtubePlayer) {
      // remove event listeners
      // this.youtubePlayer.off('stateChange');
    }
  }

  private _createYouTubePlayer(
    element: HTMLElement,
    youtubeId: string,
    autoplay: boolean = false,
    width: string | number = '100%',
    height: string | number = DEFAULT_VIDEO_HEIGHT,
  ) {
    const deviceModel = _getDeviceModel();
    const isIPad = deviceModel && deviceModel.toLowerCase().includes('ipad');

    this.youtubePlayer = YouTubePlayer(element, {
      videoId: youtubeId,
      // @TODO: @types/youtube-player doesn't agree with these typings
      width: <any>width,
      // @TODO: @types/youtube-player doesn't agree with these typings
      height: <any>height,
      playerVars: {
        controls: 1,
        modestbranding: 1,
        autoplay: autoplay ? 1 : 0,
        enablejsapi: 1,
        // @TODO: @types/youtube-player doesn't agree with these typings
        // version: 3,
        fs: isIPad ? 0 : 1,
        playsinline: 1, // makes iOS not start in fullscreen
        rel: 0, // do not show related videos at end of video
        // showinfo: 0, // do not show title of video in video player
      },
    });
    return this.youtubePlayer;
  }

  private _createVimeoPlayer(
    element: HTMLElement,
    vimeoId: string,
    autoplay: boolean = false,
    width: string | number = '100%',
    height: string | number = DEFAULT_VIDEO_HEIGHT,
  ) {
    var options = {
      width: width == '100%' ? null : width,
      height: height,
      autoplay: autoplay,
      playsinline: true,
      id: vimeoId,
      title: false,
      byline: false,
      portrait: false,
      sidedock: false,
      color: '33CCFF', // our bright blue
    };

    this.vimeoPlayer = new VimeoPlayer(element, options);
    return this.vimeoPlayer;
  }

  removeElementChildren(element: HTMLElement) {
    // remove child elements
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  async embedFromUrl(
    element,
    videoUrl,
    videoData?: IVideoData,
    videoThumbnail?: string,
  ): Promise<IVideoThumbnail> {
    if (videoData) {
      return this.embedFromData(element, videoData, null);
    } else {
      let ytData = await this.getYouTubeData(videoUrl);
      if (ytData) {
        return this.embedFromData(element, ytData, null);
      } else {
        this.removeElementChildren;
        return Promise.resolve(null);
      }
    }
  }

  private _getYoutubeIdFromUrl(videoUrl: string): string {
    let ytCode = '';
    if (videoUrl.toLowerCase().indexOf('youtube.com') > -1) {
      let parsedUrl = new URL(videoUrl);
      ytCode = parsedUrl.searchParams.get('v') || '';
    } else if (videoUrl.toLowerCase().indexOf('youtu.be') > -1) {
      let parsedUrl = new URL(videoUrl);
      ytCode = parsedUrl.pathname.substr(1);
    } else {
      console.warn(
        `Video._getYoutubeIdFromUrl() '${videoUrl}' is not a recognizable YouTube URL.`,
      );
    }
    return ytCode;
  }

  private _getVimeoIdFromUrl(videoUrl: string) {
    let vidCode = '';
    if (videoUrl.toLowerCase().indexOf('vimeo.com') > -1) {
      // Use first number param as vidCode
      let parsedUrl = new URL(videoUrl);
      let parsedUrlParts = parsedUrl.pathname.split('/');
      for (let i = 0; i < parsedUrlParts.length; ++i) {
        if (!isNaN(parseInt(parsedUrlParts[i]))) {
          vidCode = parsedUrlParts[i];
        }
      }
      return vidCode;
    } else {
      console.warn(
        `Video.getVimeoVideoDataFromUrl() '${videoUrl}' is not a recognizable Vimeo URL.`,
      );
      return null;
    }
  }

  async getVimeoDataFromUrl(videoUrl: string): Promise<IVideoData | null> {
    const request = new VimeoVideoDataRequest();
    request.setVideoUrl(videoUrl);

    try {
      const response = await this.videoManager.getVimeoVideoData(request);
      let thumbnails = [];
      const responseThumbnail = response.getThumbnail();
      let thumbWidth = null;
      let thumbHeight = null;
      if (responseThumbnail) {
        thumbWidth = responseThumbnail.getWidth();
        thumbHeight = responseThumbnail.getHeight();

        thumbnails.push({
          url: responseThumbnail.getUrl(),
          width: thumbWidth,
          height: thumbHeight,
          custom: responseThumbnail.getCustom(),
        });
      }

      let videoData: IVideoData = {
        vimeoId: response.getVimeoId(),
        title: response.getTitle(),
        url: videoUrl,
        // body: response.getBody(),
        thumbnails: thumbnails,
        width: thumbWidth,
        height: thumbHeight,
        youtubeWidth: response.getWidth(),
        youtubeHeight: response.getHeight(),
        youtubeTitle: response.getTitle(),
      };

      return videoData;
    } catch (err) {
      console.error(`getVimeoVideoData error: `, err);
    }

    return null;
  }

  private async _getYouTubeVideoDataServerside(
    videoUrl?: string,
    videoId?: string,
  ): Promise<IVideoData | null> {
    if (!videoUrl && !videoId) {
      console.error(
        `_getYouTubeVideoDataServerside() cannot get data without url or id.`,
      );
      return null;
    }

    const request = new YouTubeVideoDataRequest();
    if (videoUrl) {
      request.setVideoUrl(videoUrl);
    }
    if (videoId) {
      request.setYouTubeId(videoId);
    }
    try {
      const response = await this.videoManager.getYouTubeVideoData(request);
      let thumbnails = [];
      const responseThumbnail = response.getThumbnail();
      let thumbWidth = null;
      let thumbHeight = null;
      if (responseThumbnail) {
        thumbWidth = responseThumbnail.getWidth();
        thumbHeight = responseThumbnail.getHeight();

        thumbnails.push({
          url: responseThumbnail.getUrl(),
          width: thumbWidth,
          height: thumbHeight,
          custom: responseThumbnail.getCustom(),
        });
      }

      let videoData: IVideoData = {
        youtubeId: response.getYouTubeId(),
        title: response.getTitle(),
        url: videoUrl,
        body: response.getBody(),
        thumbnails: thumbnails,
        width: thumbWidth,
        height: thumbHeight,
        youtubeWidth: response.getWidth(),
        youtubeHeight: response.getHeight(),
        youtubeTitle: response.getTitle(),
      };

      return videoData;
    } catch (err) {
      console.info(`getVimeoVideoData retrieve data error: `, err);
    }

    return null;
  }

  private async _getYouTubeVideoDataDirectly(
    videoUrl?: string,
    ytCode?: string,
  ): Promise<IVideoData | null> {
    if (!videoUrl && !ytCode) {
      console.error(
        `_getYouTubeVideoDataDirectly() cannot get data without url or id.`,
      );
      return null;
    }

    if (!ytCode && videoUrl) {
      ytCode = this._getYoutubeIdFromUrl(videoUrl);
    }
    if (!ytCode) {
      return null;
    }
    const firebaseConfig = await this.appConfig.getFirebaseConfig();
    let youtubeApiKey = firebaseConfig.apiKey;
    let requestUrl = `https://www.googleapis.com/youtube/v3/videos?id=${ytCode}&key=${youtubeApiKey}&part=snippet`;

    let youtubeData: any = await this.http
      .get(requestUrl)
      .pipe(first())
      .toPromise();
    if (!youtubeData || !youtubeData.items.length) return null;

    let videoItem = youtubeData.items[0];
    let vidId = videoItem.id;
    let vidTitle = videoItem.snippet.title;
    let vidBody = videoItem.snippet.description;

    let thumbnails = [];

    // Find best quality thumbnail from youtube
    let snippetThumbnails = videoItem.snippet.thumbnails;
    let thumbnail = snippetThumbnails['default'];
    if (snippetThumbnails['maxres']) {
      thumbnail = snippetThumbnails['maxres'];
    } else if (snippetThumbnails['standard']) {
      thumbnail = snippetThumbnails['standard'];
    } else if (snippetThumbnails['high']) {
      thumbnail = snippetThumbnails['high'];
    }
    if (!thumbnail) {
      for (let key in videoItem.snippet.thumbnails) {
        thumbnail = videoItem.snippet.thumbnails[key];
        break;
      }
    }
    thumbnails.push({
      url: thumbnail.url,
      width: thumbnail.width,
      height: thumbnail.height,
    });

    let videoData: IVideoData = {
      youtubeId: vidId,
      title: vidTitle,
      // body: vidBody,
      thumbnails: thumbnails,
      width: thumbnail.width,
      height: thumbnail.height,
      youtubeWidth: thumbnail.width,
      youtubeHeight: thumbnail.height,
      youtubeTitle: vidTitle,
      url: videoUrl,
    };

    return videoData;
  }

  async getYouTubeData(
    videoUrl?: string,
    videoId?: string,
  ): Promise<IVideoData | null> {
    if (window.MINGA_APP_IOS) {
      return await this._getYouTubeVideoDataServerside(videoUrl, videoId);
    } else {
      return await this._getYouTubeVideoDataDirectly(videoUrl, videoId);
    }
  }

  async getVideoDataFromUrl(videoUrl: string): Promise<IVideoData | null> {
    // Is url to the vimeo video

    if (videoUrl.toLowerCase().indexOf('vimeo.com') > -1) {
      return await this.getVimeoDataFromUrl(videoUrl);
    } else if (
      videoUrl.toLowerCase().indexOf('youtube.com') > -1 ||
      videoUrl.indexOf('youtu.be') > -1
    ) {
      return await this.getYouTubeData(videoUrl);
    } else {
      // console.error("Video.getVideoDataFromUrl() Only Vimeo and YouTube url's
      // are supported right now!", videoUrl);
      this.analytics.logEvent('Video', {
        error: `Non youtube or vimeo url was given`,
      });
      throw `Video.getVideoDataFromUrl() Only Vimeo and YouTube url's are supported right now!`;
    }
  }
}
