import { Injectable } from '@angular/core';

import * as mingaProto from 'minga/proto/gateway/minga_pb';
import { IMingaDistrict, UpsertMingaDistrict } from 'minga/domain/minga';
import { MingaManager } from 'minga/proto/gateway/minga_ng_grpc_pb';
import { MingaDistrictMapper } from 'minga/shared-grpc/minga';
import { downloadjs } from 'src/app/util/downloadjs';

import { CacheService } from '../cache/cache.service';
import { CacheKey } from '../cache/cache.types';
import { ErrorHandlerService } from '../error-handler/error-handler.service';

@Injectable({ providedIn: 'root' })
export class DistrictsService {
  private _cacheItem = this._cacheService.create<IMingaDistrict[]>(
    CacheKey.DISTRICT_LIST,
    data => {
      return this._fetchAll();
    },
    {
      ttl: 60,
    },
  );

  constructor(
    private _errorHandler: ErrorHandlerService,
    private _cacheService: CacheService,
    private _mingaManager: MingaManager,
  ) {}

  public async get(id: number): Promise<IMingaDistrict> {
    try {
      const request = new mingaProto.GetMingaDistrictRequest();
      request.setId(id);
      const response = await this._mingaManager.getMingaDistrict(request);
      return MingaDistrictMapper.fromProto(response.getDistrict());
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch district',
        error,
        true,
      );
    }
  }

  public async fetchAll(opts?: {
    revalidate?: boolean;
  }): Promise<IMingaDistrict[]> {
    return await this._cacheItem.get({}, opts).toPromise();
  }

  public updateCache(districts: IMingaDistrict[]) {
    this._cacheItem.set(districts);
  }

  public async upsert(district: UpsertMingaDistrict): Promise<IMingaDistrict> {
    try {
      const request = new mingaProto.UpsertMingaDistrictRequest();
      request.setDistrict(
        MingaDistrictMapper.toProto(district as IMingaDistrict),
      );
      const response = await this._mingaManager.upsertMingaDistrict(request);
      return MingaDistrictMapper.fromProto(response.getDistrict());
    } catch (error) {
      const type = district.id ? 'update' : 'create';
      throw this._errorHandler.gateWayError(
        `Failed to ${type} district`,
        error,
        true,
      );
    }
  }

  public async delete(id: number): Promise<void> {
    try {
      const request = new mingaProto.DeleteMingaDistrictRequest();
      request.setId(id);
      await this._mingaManager.deleteMingaDistrict(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete district',
        error,
        true,
      );
    }
  }

  public async export(): Promise<void> {
    try {
      let filename = 'districts.xlsx';
      const request = new mingaProto.ExportDistrictsRequest();

      const obs = this._mingaManager.exportDistricts(request);

      let fileData = new Uint8Array(0);

      await new Promise((resolve, reject) => {
        obs.subscribe(
          chunk => {
            if (chunk.hasChunk()) {
              const chunkData = chunk.getChunk_asU8();
              const newFileData = new Uint8Array(
                fileData.length + chunkData.length,
              );
              newFileData.set(fileData);
              newFileData.set(chunkData, fileData.length);
              fileData = newFileData;
            } else if (chunk.hasFilename()) {
              filename = chunk.getFilename();
            }
          },
          reject,
          resolve,
        );
      });

      downloadjs(fileData as any, filename);
    } catch (e) {
      throw this._errorHandler.gateWayError(
        'Failed to export districts',
        e,
        true,
      );
    }
  }

  private async _fetchAll(): Promise<IMingaDistrict[]> {
    const request = new mingaProto.GetMingaDistrictsRequest();
    const response = await this._mingaManager.getMingaDistricts(request);
    return response.getDistrictsList().map(MingaDistrictMapper.fromProto) || [];
  }
}
