import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';

import { TextModule } from '@shared/components/text';

import { MgDateSeparatorElement } from './MgDateSeparator.element';

@NgModule({
  imports: [CommonModule, TextModule],
  declarations: [MgDateSeparatorElement],
  exports: [MgDateSeparatorElement],
})
export class MgDateSeparatorModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-date-separator')) {
      //       //   MgDateSeparatorElement,
      //   {injector},
      // ));
    }
  }
}
