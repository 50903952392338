import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

import { PointsDialog } from './PointsDialog.component';
import { IPointsDialogOptions } from './types';

export type PointsDialogRef = MatDialogRef<PointsDialog>;
export type PointsDialogConfig = MatDialogConfig<IPointsDialogOptions>;

/**
 * Service to simply open up the points dialog.
 */
@Injectable({ providedIn: 'root' })
export class PointsDialogService {
  constructor(private matDialog: MatDialog) {}

  open(config: PointsDialogConfig): PointsDialogRef {
    return this.matDialog.open(PointsDialog, {
      ...config,
    });
  }
}
