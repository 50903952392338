<mg-button-icon
  (click)="goBack()"
  class="top left"
  icon="back"></mg-button-icon>

<form
  #form="ngForm"
  class="landing-form flex-spaced-content"
  (submit)="onSubmit()"
  fxLayout="column">
  <h1 class="mg-centered landing-title1">Forgot Password</h1>

  <mat-form-field
    mgFormField
    mgAutofocus.gt-xs
    [mgHideOptionalMarker]="true">
    <mat-label>Email Address</mat-label>
    <input
      matInput
      #emailNgModel="ngModel"
      type="email"
      name="email"
      maxlength="50"
      mgEmailValidator
      [(ngModel)]="landing.email" />
    <mat-error *ngIf="emailNgModel?.errors?.invalidEmail">
      {{ 'input.email.error.email' | translate }}
    </mat-error>
  </mat-form-field>

  <mg-button
    type="outline"
    [disabled]="form.invalid || !landing.email"
    class="large">
    Reset Password
  </mg-button>
</form>
