<!-- Container -->
<div
  class="input"
  #inputContainer>
  <!-- Floating label -->
  <label
    *ngIf="formControl && label?.length"
    class="input-label"
    [ngClass]="formStyleClasses"
    [class.focused]="formattedDate$ | async"
    [class.hasError]="
      formControl.invalid && (formControl.dirty || formControl.touched)
    "
    [class.disabled]="inputDisabled">
    {{ label }}
  </label>

  <span class="icon-right">
    <mg-icon>mg-calendar-today</mg-icon>
  </span>

  <div class="input-wrapper">
    <!-- Clear button -->
    <mg-btn
      *ngIf="isClearable && formattedDate$ | async"
      variant="icon-secondary"
      iconSet="material"
      icon="clear"
      class="clear-action"
      (pressed)="clearValue()">
    </mg-btn>
    <!-- Input element -->
    <input
      #inputElement
      class="input-element"
      readonly
      [class.error]="
        formControl.invalid && (formControl.dirty || formControl.touched)
      "
      [class.valid]="
        formControl.valid && (formControl.dirty || formControl.touched)
      "
      [class.disabled]="inputDisabled"
      [class.has-icon-right]="true"
      [ngClass]="formStyleClasses"
      [value]="formattedDate$ | async"
      (focus)="inputGainedFocus()"
      (blur)="inputLostFocus()"
      (click)="openCalendar()"
      [attr.data-analytics]="id"
      [attr.data-test]="id" />
  </div>

  <!-- Error messages -->
  <mg-form-error
    *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)">
    {{ formControl?.errors | makeInputErrorMessage }}
  </mg-form-error>
  <!-- Hint -->
  <div
    *ngIf="hint"
    class="hint">
    {{ hint }}
  </div>

  <!-- we need this fake input here to attach "matDatepicker" to it because
  attaching it to the real input won't work because of the custom date 
  formatting we want to show, we can't use a hidden input since it doesnt 
  respect document flow -->
  <input
    class="visually-hidden"
    [value]="formControl.value ? formControl.value.toDate() : ''"
    [min]="minDate ? minDate.toDate() : undefined"
    [max]="maxDate ? maxDate.toDate() : undefined"
    [matDatepicker]="picker"
    (dateChange)="dateChange($event)" />
  <mat-datepicker
    [touchUi]="(mediaService.breakpoint$ | async) === 'xsmall'"
    [calendarHeaderComponent]="customHeader"
    #picker></mat-datepicker>
</div>
