import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import { firebase } from 'minga/app/src/firebase';
import { ISsoAuthError, ISsoProviderInfo } from 'minga/domain/auth';
import { AppConfigService } from 'src/app/minimal/services/AppConfig';
import { RootService } from 'src/app/minimal/services/RootService';

/**
 * Single Sign On User Interface for Minga. This component holds all UI for the
 * SSO providers that Minga supports. Each style guideline required by the
 * providers are covered here.
 */
@Component({
  selector: 'mg-sso-ui',
  templateUrl: './SsoUi.component.html',
  styleUrls: ['./SsoUi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoUiComponent implements OnInit, OnDestroy {
  /** @internal */
  private _authStateChangedUnsub?: firebase.Unsubscribe;
  /** @internal */
  private _subscription = new Subscription();

  /**
   * Currently authenticated as a known Minga user
   */
  readonly authenticated$: Observable<boolean>;

  /**
   * Convenience output that just wrap the AuthInfo service observable
   * `ssoProviderInfo$` since typically using this component listening to this
   * observable is desireable
   */
  @Output()
  readonly authSuccess: EventEmitter<{
    ssoProviderInfo: ISsoProviderInfo;
    detached: boolean;
  }>;

  /**
   * Convenience output that just wrap the AuthInfo service observable
   * `ssoError$` since typically using this component listening to this
   * observable is desireable
   */
  @Output()
  readonly authError: EventEmitter<ISsoAuthError>;

  enableClever = false;
  overrideCleverConfig = false;

  constructor(
    private _cdr: ChangeDetectorRef,
    private authInfo: AuthInfoService,
    private auth: AuthService,
    public rootService: RootService,
    private _route: ActivatedRoute,
    private appConfig: AppConfigService,
  ) {
    this.authSuccess = new EventEmitter();
    this.authError = new EventEmitter();
    this.authenticated$ = this.authInfo.authPersonHash$.pipe(map(h => !!h));
  }

  ngOnInit() {
    this._authStateChangedUnsub = firebase.auth().onAuthStateChanged(user => {
      this._cdr.markForCheck();
    });

    const infoSub = this.authInfo.ssoProviderInfo$.subscribe(info => {
      this.authSuccess.emit(info);
    });

    const linkedSub = this.authInfo.linkedSsoProviderInfo$.subscribe(info => {
      this._cdr.markForCheck();
    });

    const errorSub = this.authInfo.ssoError$.subscribe(err => {
      if (err) {
        this.authError.emit(err);
      }
    });

    this.appConfig
      .getClever()
      .then(clever => (this.enableClever = clever.loginEnabled));

    const routeSub = this._route.url.subscribe(urlSegments => {
      if (urlSegments.find(val => val.path == 'clever')) {
        this.enableClever = true;
        this.overrideCleverConfig = true;
      }
    });

    this._subscription.add(infoSub);
    this._subscription.add(errorSub);
    this._subscription.add(linkedSub);
    this._subscription.add(routeSub);
  }

  ngOnDestroy() {
    if (this._authStateChangedUnsub) {
      this._authStateChangedUnsub();
      delete this._authStateChangedUnsub;
    }

    this._subscription.unsubscribe();
  }

  private isProviderConnected(providerId: string) {
    const user = firebase.auth().currentUser;

    if (user) {
      for (const data of user.providerData) {
        if (data && data.providerId === providerId) {
          return true;
        }
      }
    }
    return false;
  }

  private isProviderLinked(providerId: string) {
    // If we have no auth info our provider is not linked. If the provider still
    // exists in firebase auth that means we're authenticated, but detached
    if (!this.authInfo.authInfo) {
      return false;
    }

    return this.isProviderConnected(providerId);
  }

  isGoogleLinked() {
    return this.isProviderLinked(firebase.auth.GoogleAuthProvider.PROVIDER_ID);
  }

  isMicrosoftLinked() {
    // @NOTE: There are no provider ID typings for microsoft
    return this.isProviderLinked('microsoft.com');
  }

  async signInWithGoogle() {
    await this.auth.signInWithGoogle();
  }

  async signInWithMicrosoft() {
    await this.auth.signInWithMicrosoft();
  }

  async signInWithClever() {
    await this.auth.signInWithClever();
  }
}
