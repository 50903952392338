import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {
  BehaviorSubject,
  EMPTY,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  timer,
} from 'rxjs';
import {
  delay,
  filter,
  finalize,
  map,
  startWith,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs/operators';

import { KIOSK_SUMMARY_AUTO_RESET_TIMEOUT } from '../constants';
import { KioskService } from '../services';
import { KioskFormStep, KioskPerson, KioskType } from '../types';
import { KioskStateStorage } from './kiosk-state-storage.util';

@Injectable()
export abstract class KioskFormAbstractComponent<ExtraFormSteps, TypeData>
  implements OnDestroy
{
  // Abstract members
  public abstract readonly allTypes$: Observable<KioskType<TypeData>[]>;

  // Abstract methods

  public abstract assign(person: KioskPerson): Promise<any>;
  public abstract fetchTypes(): Observable<KioskType<TypeData>[]>;

  // Hooks

  /**
   * On Destroyed
   *
   * since OnDestroy is being implemented here at the base level, this is a
   * callback hook that is called when the component is destroyed.
   */
  public abstract onDestroyed(): void;
  public abstract onReset(): void;

  // Clean up

  public readonly _destroyedSubject = new ReplaySubject<void>(1);
  public readonly _manuallyStopEmittingSubject = new ReplaySubject<void>(1);

  // State

  public autoResetTimer$: Observable<never | number> = EMPTY;

  private readonly _isLoadingSubject = new Subject<boolean>();
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  private _assignmentInProgressSubject = new BehaviorSubject<boolean>(true);
  public readonly assignmentInProgress$ =
    this._assignmentInProgressSubject.asObservable();

  private readonly _formStepSubject = new BehaviorSubject<
    KioskFormStep<ExtraFormSteps>
  >(this._recoverFormStep());
  public readonly formStep$ = this._formStepSubject.asObservable();

  public readonly showBackButton$ = combineLatest([
    this.formStep$,
    this.kiosk.categoryOptions$,
  ]).pipe(
    takeUntil(this._destroyedSubject),
    map(([step, options]) => {
      if (step === 'setup' && options.length === 1) {
        return false;
      } else {
        return true;
      }
    }),
  );

  /** Kiosk person */
  private readonly _personSubject = new BehaviorSubject<KioskPerson>(null);
  public readonly person$ = this._personSubject.asObservable();

  /**
   * Available types
   *
   * These are the types that the teacher selects during the setup step.
   */
  private readonly _availableTypesSubject = new BehaviorSubject<
    KioskType<TypeData>[]
  >(KioskStateStorage.get().availableTypes);
  public readonly availableTypes$ = this._availableTypesSubject.asObservable();

  /**
   * Selected types
   *
   * This is the type that a student selects during the type selector step.
   */
  private readonly _selectedTypeSubject = new BehaviorSubject<
    KioskType<TypeData>
  >(null);
  public readonly selectedType$ = this._selectedTypeSubject.asObservable();

  // Derived state

  public readonly showTypeSelector$ = this.selectedType$.pipe(
    map(type => {
      if (type === null) return true;
      return type;
    }),
  );

  // Constructor

  constructor(public kiosk: KioskService, private _router: Router) {}

  // Lifecycle

  ngOnDestroy() {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._manuallyStopEmittingSubject.next();
    this._manuallyStopEmittingSubject.complete();
    this.onDestroyed();
  }

  // Public methods

  private _recoverFormStep(): KioskFormStep<ExtraFormSteps> {
    const { availableTypes, isKioskMode } = KioskStateStorage.get();
    if (!isKioskMode) return 'setup';
    if (!availableTypes) return 'type-selector';
    else return 'assigner';
  }

  public loadingObservableWrapper<Z = any>(promise: Observable<Z[]>) {
    return promise.pipe(
      startWith(null as any),
      delay(0),
      tap(() => this._isLoadingSubject.next(true)),
      filter((v: Z[] | null) => v !== null),
      finalize(() => this._isLoadingSubject.next(false)),
    );
  }

  public startAutoClose(
    timeInSeconds: number = KIOSK_SUMMARY_AUTO_RESET_TIMEOUT,
  ) {
    this.autoResetTimer$ = timer(0, 1000).pipe(
      map(n => timeInSeconds - n),
      takeWhile(n => n >= 0),
      tap(n => {
        if (n === 0) this.reset();
      }),
    );
  }

  public goBack() {
    this.kiosk.setCategory(null);
    this._router.navigate(['/kiosk']);
  }

  public async reset() {
    this._selectedTypeSubject.next(null);
    this._formStepSubject.next('assigner');
    this.onReset();
  }

  public async setPerson(person: KioskPerson) {
    this._personSubject.next(person);
  }

  public getPerson(): KioskPerson {
    return this._personSubject.getValue();
  }

  public setFormStep(step: KioskFormStep<ExtraFormSteps>) {
    this._formStepSubject.next(step);
  }

  public getFormStep() {
    return this._formStepSubject.getValue();
  }

  public getAvailableTypes(): KioskType<TypeData>[] {
    return this._availableTypesSubject.getValue();
  }

  public setAvailableTypes(availableTypes: KioskType<TypeData>[]) {
    this._availableTypesSubject.next(availableTypes);
    const size = availableTypes.length;
    if (size === 1) this.setSelectedType(availableTypes[0]);
    this.kiosk.setIsKioskMode(true);
    KioskStateStorage.set<TypeData>({ availableTypes, isKioskMode: true });
    this._formStepSubject.next('assigner');
  }

  public async updateAvailableTypes() {
    const availableTypes = this._availableTypesSubject.getValue();
    if (!availableTypes) return;

    const types = await this.fetchTypes().toPromise();

    const filteredAvailableTypes = availableTypes.filter(availableType =>
      types.some(type => type.id === availableType.id),
    );

    this._availableTypesSubject.next(filteredAvailableTypes);
  }

  public getSelectedType(): KioskType<TypeData> {
    const selectedType = this._selectedTypeSubject.getValue();
    if (selectedType) return selectedType;
    const getAvailableTypes = this.getAvailableTypes();
    if (getAvailableTypes.length > 1) return null;
    const type = getAvailableTypes[0];
    this.setSelectedType(type);
    return type;
  }

  public setSelectedType(selectedType: KioskType<TypeData>) {
    this._selectedTypeSubject.next(selectedType);
  }

  public setAssignmentInProgress(inProgress: boolean) {
    this._assignmentInProgressSubject.next(inProgress);
  }
}
