import {
  AfterViewInit,
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
} from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';

import { MentionableUIComponent } from '../components/MentionableUI/MentionableUI.component';
import { MentionsService } from '../services/Mentions.service';

import { ContentEditableMentionStrategy } from './mentionable/ContentEditableMentionStrategy';
import {
  IMentionSearchChangeEvent,
  IMentionSearchCommitEvent,
  IMentionSearchStartEvent,
  MentionStrategy,
} from './mentionable/MentionStrategy';
import { TextAreaMentionStrategy } from './mentionable/TextAreaMentionStrategy';
import { TextInputMentionStrategy } from './mentionable/TextInputMentionStrategy';

export const MENTION_CHAR = '@';

export enum MgMentionableMode {
  INLINE = 'inline',
  ABOVE = 'above',
  BELOW = 'below',
}

function isElementFixed(element: HTMLElement) {
  if (getComputedStyle(element).position === 'fixed') {
    return true;
  } else if (element.parentElement) {
    return isElementFixed(element.parentElement);
  }

  return false;
}

@Directive({
  selector: '[mgMentionable2]',
  exportAs: 'mgMentionable2',
})
export class MgMentionable2Directive implements OnDestroy, AfterViewInit {
  mentionStrategy: MentionStrategy;
  mentionStrategyElement: HTMLElement;

  private _searchSub?: Subscription;
  private _searchText$ = new BehaviorSubject<string>('');
  readonly searchText$: Observable<string>;
  readonly searchTextDebounced$: Observable<string>;

  private _mentionUiCompRef?: ComponentRef<MentionableUIComponent>;
  private _mentionUiCompfactory: ComponentFactory<MentionableUIComponent>;

  // Track active searches. Prevent old results from overwritting new results.
  private _currentSearchId: number = 0;

  constructor(
    private mentionService: MentionsService,
    private appRef: ApplicationRef,
    private element: ElementRef,
    private injector: Injector,
    factoryResolver: ComponentFactoryResolver,
  ) {
    this._mentionUiCompfactory = factoryResolver.resolveComponentFactory(
      MentionableUIComponent,
    );

    this.searchText$ = this._searchText$.asObservable();

    this.searchTextDebounced$ = this.searchText$.pipe(
      debounceTime(300),
      shareReplay(1),
      distinctUntilChanged(),
    );
  }

  @Input()
  mode: string = 'inline';

  @Input()
  set mgMentionable2(value: any) {
    if (typeof value === 'string') {
      switch (value) {
        case 'inline':
          this.mode = 'inline';
          break;
        case 'above':
          this.mode = 'above';
          break;
      }

      this._repositionMentionUi();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this._repositionMentionUi();
  }

  async search(searchString: string) {
    const searchId = ++this._currentSearchId;
    this._searchText$.next(searchString);

    if (!this._searchSub) {
      this._searchSub = this.searchTextDebounced$
        .pipe(
          switchMap(searchText =>
            this.mentionService.findMentionables(
              searchText,
              // currently only specifically in gallery photo
              // MentionableSelect component are events to be mentionable
              { searchPeople: true, searchGroups: true },
            ),
          ),

          filter(items => items.length > 0),
        )
        .subscribe(
          response => {
            const mentionUi = this._ensureMentionUi();
            mentionUi.instance.setResults(response);
          },
          err => {
            const mentionUi = this._ensureMentionUi();
            mentionUi.instance.setResultsError(err);
          },
        );
    }

    // let searchResp = await this.mentionService.searchMention(
    //   mentionSearchReq,
    // );

    // const searchStatus = searchResp.getStatus();

    // if(searchStatus === StatusCode.OK) {
    //   let personList = searchResp.getPersonList();

    //   // Don't override new searches
    //   if(searchId == this._currentSearchId) {
    //     mentionUi.instance.setResults(personList);
    //   }

    //   if(personList.length > 0) {
    //     this._searchCache[cacheKey] = personList;
    //   }
    // } else if(searchId == this._currentSearchId) {
    //   mentionUi.instance.setResultsError();
    // }
  }

  private isMentionStrategyElementFixed() {
    let element = this.mentionStrategyElement;
    return isElementFixed(element);
  }

  private repositionMentionUiAbove() {
    const rect = this.element.nativeElement.getBoundingClientRect();
    const menuUiEl = this._mentionUiCompRef.location.nativeElement;

    menuUiEl.style.right = '';
    menuUiEl.style.top = '';
    menuUiEl.style.maxWidth = rect.width + 'px';
    menuUiEl.style.minWidth = rect.width + 'px';
    menuUiEl.style.left = rect.left + 'px';
    menuUiEl.style.left = rect.left + 'px';

    if (this.isMentionStrategyElementFixed()) {
      menuUiEl.style.position = 'fixed';
      menuUiEl.style.bottom = innerHeight - rect.top + 'px';
    } else {
      menuUiEl.style.position = 'absolute';
      const bottomOffset = window.innerHeight - window.scrollY;
      menuUiEl.style.bottom = bottomOffset - rect.top + 'px';
    }
  }

  private repositionMentionUiInline() {
    const pos = this.mentionStrategy.getMentionSearchPos();
    const menuUiEl = this._mentionUiCompRef.location.nativeElement;

    menuUiEl.style.top = pos.y + 'px';
    menuUiEl.style.left = pos.x + 'px';
    if (!this.isMentionStrategyElementFixed()) {
      // on overlays, if you've scrolled, pos.y will be wrong.
      menuUiEl.style.top = pos.y + window.scrollY + 'px';
    }
  }

  private _repositionMentionUi() {
    if (!this.mentionStrategyElement) {
      return;
    }

    if (!this._mentionUiCompRef) {
      return;
    }

    if (!this._mentionUiCompRef.location) {
      return;
    }

    switch (this.mode) {
      case 'above':
        this.repositionMentionUiAbove();
        break;
      case 'inline':
        this.repositionMentionUiInline();
        break;
    }
  }

  private _ensureMentionUi(): ComponentRef<MentionableUIComponent> {
    if (!this._mentionUiCompRef) {
      this._mentionUiCompRef = this._mentionUiCompfactory.create(this.injector);

      this._mentionUiCompRef.instance.resultSelect.subscribe(item => {
        if (this.mentionStrategyElement) {
          // Maintain focus after selection
          // this.mentionStrategyElement.focus();
        }
        // pass result item to mention strategy to handle
        this.mentionStrategy.commitMentionSearch(item);
      });

      const locEl = this._mentionUiCompRef.location.nativeElement;
      locEl.style.position = 'absolute';
      locEl.style.zIndex = 1000;

      this._repositionMentionUi();

      document.body.appendChild(locEl);
      this.appRef.attachView(this._mentionUiCompRef.hostView);
    }

    return this._mentionUiCompRef;
  }

  private _clearMentionUi() {
    if (!this._mentionUiCompRef) {
      return;
    }

    if (this._mentionUiCompRef) {
      this.appRef.detachView(this._mentionUiCompRef.hostView);
      this._mentionUiCompRef.destroy();
    }
    this._mentionUiCompRef = null;
    if (this._searchSub) {
      this._searchSub.unsubscribe();
      this._searchSub = undefined;
    }
  }

  private _mentionSearchStart(e: IMentionSearchStartEvent) {
    const mentionUi = this._ensureMentionUi();

    mentionUi.instance.setSearchInput('');
    this.search('');
  }

  private _mentionSearchChange(e: IMentionSearchChangeEvent) {
    const mentionUi = this._ensureMentionUi();

    mentionUi.instance.setSearchInput(e.searchString);
    this.search(e.searchString);
  }

  private _mentionSearchCommit(e: IMentionSearchCommitEvent) {
    if (!this._mentionUiCompRef) {
      return;
    }

    const resultItem = this._mentionUiCompRef.instance.getSelectedMention();

    if (resultItem) {
      e.insertMention(MENTION_CHAR + resultItem.displayName, resultItem.hash);
    }

    this._clearMentionUi();
  }

  private _initMentionStrategyListeners() {
    this.mentionStrategy.onMentionSearchStart.subscribe(e => {
      this._mentionSearchStart(e);
      this._repositionMentionUi();
    });

    this.mentionStrategy.onMentionSearchChange.subscribe(e => {
      this._mentionSearchChange(e);
      this._repositionMentionUi();
    });

    this.mentionStrategy.onMentionSearchCommit.subscribe(e => {
      this._mentionSearchCommit(e);
    });

    this.mentionStrategy.onMentionSearchEnd.subscribe(e => {
      this._clearMentionUi();
    });
  }

  private _initMentionStrategyElement(element: HTMLElement): boolean {
    if (element.isContentEditable) {
      this.mentionStrategy = new ContentEditableMentionStrategy();
      this._initMentionStrategyListeners();
      this.mentionStrategy.connect(element);
      this.mentionStrategyElement = element;
      return true;
    } else if (element instanceof HTMLTextAreaElement) {
      this.mentionStrategy = new TextAreaMentionStrategy();
      this._initMentionStrategyListeners();
      this.mentionStrategy.connect(element);
      this.mentionStrategyElement = element;
      return true;
    } else if (element instanceof HTMLInputElement) {
      this.mentionStrategy = new TextInputMentionStrategy();
      this._initMentionStrategyListeners();
      this.mentionStrategy.connect(element);
      this.mentionStrategyElement = element;
      return true;
    }

    return false;
  }

  private _initMentionStrategy() {
    if (!this.element) {
      throw new Error('element not ready');
    }

    const nativeElement = <HTMLElement>this.element.nativeElement;

    if (!this._initMentionStrategyElement(nativeElement)) {
      const element = <HTMLElement>(
        nativeElement.querySelector(`input,textarea,[contenteditable=true]`)
      );

      if (element) {
        if (!this._initMentionStrategyElement(element)) {
          throw new Error('Cannot initialize mention strategy element');
        }
      }
    }
  }

  ngOnDestroy() {
    this._clearMentionUi();

    if (this.mentionStrategy) {
      this.mentionStrategy.disconnect();
    }

    if (this._searchSub) {
      this._searchSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    // running this immediately doesn't work with quill
    setTimeout(() => this._initMentionStrategy(), 100);
  }
}
