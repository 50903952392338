import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';

import { LogoModule } from 'src/app/components/Logo';
import { MgSpinnerModule } from 'src/app/spinner';

import { OverlayModule } from '@shared/components/modal-overlay';

import { TextModule } from '../text/text.module';
import { SwitchMingaModalComponent } from './switch-minga-modal.component';

@NgModule({
  declarations: [SwitchMingaModalComponent],
  imports: [
    MgSpinnerModule,
    CommonModule,
    OverlayModule,
    FlexLayoutModule,
    TextModule,
    LogoModule,
    MatRippleModule,
  ],
})
export class SwitchMingaModalModule {}
