<form
  (submit)="submit($event)"
  action>
  <div
    class="input-row mg-smallcopy"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div class="person-avatar">
      <mg-person-avatar [noDisplayName]="true"></mg-person-avatar>
    </div>

    <mat-form-field
      floatPlaceholder="never"
      mgFormField
      [mgNoHintMargin]="true"
      [mgHideOptionalMarker]="true"
      [mgNoBoldFontWeight]="true"
      [class.disabled]="submitting">
      <mg-textarea
        fxFlex
        #textareaInput
        [placeholder]="placeholder | translate"
        allowedTags="mg-mention"
        mgMentionable2="above"
        [multiline]="multiline"
        [disabled]="submitting"
        (keydown)="onKeydown($event)"
        [(ngModel)]="commentText"
        (ngModelChange)="saveCommentDelayed()"
        name="commentText">
      </mg-textarea>

      <div
        matSuffix
        (click)="clearCommentText()"
        *ngIf="commentText">
        <mg-icon>close-thin</mg-icon>
      </div>
    </mat-form-field>
    <mg-button
      class="normal responsive-height"
      type="outline"
      (click)="submit($event)"
      [disabled]="submitting || !commentText">
      {{ buttonText | translate }}
    </mg-button>
  </div>
</form>
