export enum HallPassReportMessages {
  NAME = 'Name, ID number or Email',
  PASS_TYPE = 'Hall Pass Type',
  USER_LIST = 'User List',
}

export enum HallPassReportFields {
  ISSUED_TO = 'issuedTo',
  ISSUED_BY = 'issuedBy',
  HALLPASS_TYPE = 'hallpassType',
  CREATED_BY = 'createdBy',
  USER_LIST = 'userList',
}
