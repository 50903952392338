import { ChangeDetectionStrategy, Component } from '@angular/core';

import { HpmReportsLatePassesService } from '../../services';

@Component({
  selector: 'mg-hpm-reports-late',
  template: `
    <mg-report-table
      matSort
      [dataSourceService]="ds"
      (matSortChange)="ds.sort($event)">
      <mg-role-column [datasource]="ds"></mg-role-column>
      <mg-history-column
        [datasource]="ds"
        colorTheme="navy">
      </mg-history-column>
      <mg-summary-column
        [datasource]="ds"
        iconName="polls-o"
        colorTheme="navy">
      </mg-summary-column>
    </mg-report-table>
  `,
  styleUrls: ['../hpm-reports.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HpmReportsLatePassesService],
})
export class HpmReportsLateComponent {
  constructor(public ds: HpmReportsLatePassesService) {}
}
