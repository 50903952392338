import { Validators } from '@angular/forms';

import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';

export enum DatePresetMessages {
  MODAL_TITLE_ADD = 'Add Custom Date Range',
  MODAL_TITLE_EDIT = 'Edit Custom Date Range',
  LABEL_TITLE = 'Title (optional)',
  LABEL_END_DATE_TITLE = 'Set end date to today',
  LABEL_END_DATE_DESC = 'Ensures that the date range always ends on the current day',
  ERROR_GENERIC = 'Please complete the missing information',

  LABEL_END_DATE_TOOLTIP = "Start date can't be in the future in order to enable this option",

  DELETE_CONFIRM_TITLE = 'Are you sure you want to delete this date preset?',
  DELETE_CONFIRM_DISCARD_TITLE = 'Are you sure you want to discard this date preset?',
  DELETE_CONFIRM_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_DISCARD_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_CANCEL_BTN = 'Cancel',
  DELETE_CONFIRM_DELETE_BTN = 'Delete',
  DELETE_CONFIRM_DISCARD_BTN = 'Discard',

  SNACKBAR_CREATE_SUCCESS = 'New custom date range added',

  LIST_MODAL_TITLE = 'Manage Date Ranges',
  LIST_TABLE_TITLE = 'Title',
  LIST_TABLE_EDIT = 'Edit',
  LIST_TABLE_ACTIVE = 'Active',
  LIST_ADD_BTN = 'Add date range',
  LIST_MODAL_CANCEL = 'Cancel',
}

export enum DATE_PRESET_FORM_FIELDS {
  NAME = 'name',
  END_DATE_IS_CURRENT_DAY = 'endDateIsCurrentDay',
  DATE_RANGE = 'dateRange',
}

export const DATE_PRESETS_FORM = () => ({
  [DATE_PRESET_FORM_FIELDS.NAME]: [null],
  [DATE_PRESET_FORM_FIELDS.END_DATE_IS_CURRENT_DAY]: [
    false,
    [Validators.required],
  ],
  [DATE_PRESET_FORM_FIELDS.DATE_RANGE]: initializeRange({
    start: {
      value: null,
      validators: [Validators.required],
    },
    end: {
      value: null,
      validators: [Validators.required],
    },
  }),
});
