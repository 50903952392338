import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

import { MgIconModule } from 'src/app/icon';
import { PermissionsModule } from 'src/app/permissions';

import { GenericModule } from '@shared/components/generic';
import { TextModule } from '@shared/components/text';

import { HomeActionButtonComponent } from './home-action-button.component';

@NgModule({
  imports: [
    // Minga dependencies
    GenericModule,
    TextModule,
    MgIconModule,
    PermissionsModule,

    // External dependencies
    CommonModule,
    MatMenuModule,
    RouterModule,
    FlexLayoutModule,
  ],
  declarations: [HomeActionButtonComponent],
  exports: [HomeActionButtonComponent],
})
export class HomeActionButtonModule {}
