import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';

import { CalendarQuickFilterComponent } from './CalendarQuickFilter.component';
export { CalendarQuickFilterComponent };
@NgModule({
  imports: [
    // Minga dependencies
    MgFormFieldModule,

    // External dependencies
    MatSelectModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
  ],
  declarations: [CalendarQuickFilterComponent],
  exports: [CalendarQuickFilterComponent],
})
export class CalendarQuickFilterModule {}
