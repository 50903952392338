import { Provider } from '@angular/core';
import { BREAKPOINT } from '@angular/flex-layout';

const MINGA_CUSTOM_BREAKPOINTS = [
  {
    alias: 'md',
    suffix: 'med',
    mediaQuery: 'screen and (min-width: 960px) and (max-width: 1099.9px)',
    overlapping: true,
    priority: 1000, // override the existing md breakpoint
  },
  {
    alias: 'ml',
    suffix: 'med-lg',
    mediaQuery: 'screen and (min-width: 1100px) and (max-width: 1279.9px)',
    overlapping: false,
    priority: 1001, // Needed if overriding the default print breakpoint
  },
];

export const MingaFlexBreakPointsProvider: Provider = {
  provide: BREAKPOINT,
  useValue: MINGA_CUSTOM_BREAKPOINTS,
  multi: true,
};
