import { Injectable } from '@angular/core';

import { BehaviorSubject, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class KeyboardEventsService {
  // Open state
  private readonly _isKeyboardOpenSubject = new BehaviorSubject<boolean>(false);
  public readonly isKeyboardOpen$ = this._isKeyboardOpenSubject.asObservable();

  // Hide events
  public readonly keyboardWillHide$ = fromEvent(
    window,
    'keyboardWillHide',
  ).pipe(tap(() => this._isKeyboardOpenSubject.next(false)));
  public readonly keyboardDidHide$ = fromEvent(window, 'keyboardDidHide');

  // Show events
  public readonly keyboardWillShow$ = fromEvent(
    window,
    'keyboardWillShow',
  ).pipe(tap(() => this._isKeyboardOpenSubject.next(true)));
  public readonly keyboardDidShow$ = fromEvent(window, 'keyboardDidShow');

  constructor() {}
}
