<div
  #swipeStackContainer
  class="swipe-stack-container anim1"
  [class.swipe-stack-end]="!cyclic && stackIndex >= stackSize - 1"
  [class.swipe-stack-begin]="!cyclic && stackIndex <= 0"
  style="animation-play-state: paused">
  <div class="stack-top stack-item-container">
    <div
      class="stack-item"
      (animationiteration)="onAnimationIteration($event)"
      (animationend)="onAnimationIteration($event)">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="stack-preview stack-item-container">
    <div class="stack-item">
      <ng-content select="[mgSwipeStackPreview]"></ng-content>
    </div>
  </div>

  <div class="stack-preview-placeholder stack-item-container">
    <div class="stack-item">
      <ng-content select="[mgSwipeStackPreviewPlaceholder]"></ng-content>
    </div>
  </div>
</div>
