import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';

import { Observable, ReplaySubject, from, of } from 'rxjs';

import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';
import { MingaPermission, mingaSettingTypes } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';
import { ListMembershipService } from 'src/app/services/ListMembership';

import { LayoutService } from '@modules/layout/services';

import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';
import { KioskService } from '@shared/services/kiosk/kiosk.service';

import { FlexTimeManagerMessages } from '../../constants';
import { FtmSettingsMessages } from './constants';

@Component({
  selector: 'mg-ftm-settings',
  templateUrl: './ftm-settings.component.html',
  styleUrls: ['./ftm-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmSettingsComponent implements OnDestroy {
  /** Constants */
  public readonly FTM_MESSAGES = FlexTimeManagerMessages;
  public readonly MESSAGES = FtmSettingsMessages;
  public readonly SETTINGS = mingaSettingTypes;
  public readonly LIST_TYPES = [
    MembershipListType.FLEX_MANAGER,
    MembershipListType.FLEX_REPORT_VIEWER,
  ];

  /** General Observables */
  private readonly _destroyed = new ReplaySubject<void>(1);

  /** Loading */
  public readonly isLoading$: Observable<boolean> = of(false);

  public ftmLists$: Observable<IMembershipList[]>;

  /** Component Constructor */
  constructor(
    public layout: LayoutService,
    private _membershipService: ListMembershipService,
    private _cdr: ChangeDetectorRef,
    private _permissionService: PermissionsService,
    public kioskPermissions: KioskPermissionsService,
    private _kioskService: KioskService,
  ) {
    this.initFtmSettingsLists();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  initFtmSettingsLists() {
    if (
      !this._permissionService.hasPermission(
        MingaPermission.MINGA_PEOPLE_MANAGE,
      )
    )
      return;
    const types = [
      MembershipListType.FLEX_MANAGER,
      MembershipListType.FLEX_REPORT_VIEWER,
    ];
    this.ftmLists$ = from(
      this._membershipService.getOrCreateMembershipLists(types),
    );
    this._cdr.markForCheck();
  }

  public reloadLists() {
    this.initFtmSettingsLists();
  }

  public async openDisclaimer(value: boolean) {
    if (value) {
      await this._kioskService.openDisclaimer(mingaSettingTypes.FTM_KIOSK);
    }
  }
}
