import { Injectable } from '@angular/core';

import * as class_types_pb from 'minga/proto/class_types/class_types_pb';
import { IConsequenceClassTypeCount } from 'minga/domain/consequences';
import { IHallPassClassTypeCount } from 'minga/domain/hallPass';
import { IPbisClassTypeCount } from 'minga/domain/pbis';
import { ClassTypesService } from 'minga/proto/class_types/class_types_ng_grpc_pb';
import { fromProto as consequenceMapper } from 'minga/shared-grpc/consequences/consequence-type-count.mappers.proto';
import { fromProto as hallPassMapper } from 'minga/shared-grpc/hall_pass/HallPassTypeCountMapper';
import { fromProto as behaviorMapper } from 'minga/shared-grpc/pbis/PbisTypeCount.mapper';

@Injectable({ providedIn: 'root' })
export class MyActionsService {
  constructor(private _classTypesService: ClassTypesService) {}

  public async fetchAll(): Promise<{
    consequences: IConsequenceClassTypeCount[];
    behaviors: IPbisClassTypeCount[];
    hallPasses: IHallPassClassTypeCount[];
  }> {
    const request = new class_types_pb.GetClassTypesRequest();
    const response = await this._classTypesService.getClassTypes(request);

    const consequences = (response.getConsequenceTypeCountsList() || []).map(
      consequenceMapper,
    );
    const behaviors = (response.getPbisTypeCountsList() || []).map(
      behaviorMapper,
    );
    const hallPasses = (response.getHallpassTypeCountsList() || []).map(
      hallPassMapper,
    );

    return {
      consequences,
      behaviors,
      hallPasses,
    };
  }
}
