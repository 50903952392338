<mat-table
  matSort
  class="list-table no-explicit-mobile-layout list-table-relaxed"
  [dataSource]="dataSource"
  [trackBy]="trackByFn">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <mg-icon
        icon-namespace="minga.behaviors"
        [iconName]="item.iconType || fallbackIcon.icon"
        [style.color]="item.iconColor || fallbackIcon.color"></mg-icon>
      <mg-text variant="header-sm">
        {{ item.name }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <mat-header-cell
      *matHeaderCellDef
      class="w-12 text-center">
      <mg-text
        class="column-header"
        variant="body-xs"
        color="grey-300">
        Edit
      </mg-text>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="w-12 text-center">
      <mg-btn
        icon="mg-edit"
        variant="icon"
        iconSet="minga"
        tooltip="Edit"
        [id]="'pbis-behaviors-click-toggle'"
        (pressed)="clickEdit(item)">
      </mg-btn>
    </mat-cell>
  </ng-container>

  <!-- Active Column -->
  <ng-container matColumnDef="active">
    <mat-header-cell
      *matHeaderCellDef
      class="w-12 text-center">
      <mg-text
        class="column-header"
        variant="body-xs"
        color="grey-300">
        Active
      </mg-text>
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="w-12 text-right">
      <mg-form-toggle
        [id]="'pbis-behaviors-click-toggle'"
        [value]="item?.active"
        (valueChange)="clickToggleActive(item, $event)"></mg-form-toggle>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="TABLE_COLUMNS"> </mat-header-row>
  <mat-row *matRowDef="let row; columns: TABLE_COLUMNS"> </mat-row>
</mat-table>
