import { Pipe, PipeTransform } from '@angular/core';
import { mgResolveImageUrls } from '../util/asset/imageAssetResolve';

@Pipe({ name: 'mgImageUrls' })
export class MgImageUrlsPipe implements PipeTransform {
  constructor() {}

  transform(value: any, options?: any): string[] {
    if (!value) return [];
    options = options || [];

    if (typeof options === 'string') {
      options = options.split(',').map(s => s.trim());
    }

    if (!Array.isArray(options)) {
      console.warn('mgImageUrls pipe must be a string or array of strings');
      options = [];
    }

    return mgResolveImageUrls(value, options);
  }
}
