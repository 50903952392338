import { QuaggaJSConfigObject } from '@ericblade/quagga2';

import { CameraTypes } from '../types';

export enum WebcamModalMessages {
  BUTTON_TAKE_PICTURE = 'Capture image',
  BUTTON_RETAKE_PICTURE = 'Retake image',
  BUTTON_START_VIDEO = 'Start',
  BUTTON_STOP_VIDEO = 'Stop',
  BUTTON_CANCEL = 'Cancel',
  BUTTON_SUBMIT = 'Submit',
  BUTTON_DONE = 'Done',

  TITLE_REVIEW = 'Review',

  WEBCAM_ERROR_GENERAL = 'Oops, something went wrong trying to initalize your webcam',
}

export const WEBCAM_MODAL_SCAN_SAME_ID_GAP = 10000;

export const MODAL_TITLE_MAP: Record<CameraTypes, string> = {
  scanner: 'Scan a barcode',
  image: 'Capture image',
  video: 'Record a video',
};

export const WEBCAM_QUAGGA_CONFIG: QuaggaJSConfigObject = {
  inputStream: {
    name: 'Live',
    type: 'LiveStream',
    singleChannel: true,
    area: {
      top: '0%',
      right: '0%',
      left: '0%',
      bottom: '0%',
    },
    constraints: {
      facingMode: 'environment',
      noiseSuppression: false,
      autoGainControl: true,
    },
  },
  decoder: {
    readers: ['code_128_reader', 'code_39_reader', 'qrcode'],
    debug: {
      drawScanline: true,
      showPattern: true,
    },
  },
  debug: true,
  locate: true,
  numOfWorkers: navigator?.hardwareConcurrency || 4,
  frequency: 10,
  locator: {
    halfSample: true,
    patchSize: 'medium',
  },
};
