<mg-overlay-primary
  class="promotional-modal"
  size="medium"
  [overlayTitle]="dialogData.title.text"
  [promotionalIcon]="dialogData.title.icon"
  [promotionalColor]="dialogData.module">
  <ng-template #modalHeader>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </ng-template>
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<ng-template #headerTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="4px">
    <mg-text
      variant="header-lg"
      color="white">
      {{ dialogData.header.title }}
    </mg-text>
    <mg-text
      class="title-highlight"
      variant="header-xl-secondary"
      color="white">
      {{ dialogData.header.highlight }}
    </mg-text>
    <mg-text
      variant="body-md"
      textAlign="center"
      color="white">
      {{ dialogData.header.subtitle }}
    </mg-text>
  </div>
</ng-template>
<ng-template #mainTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="center start"
    fxLayoutGap="12px">
    <ng-container *ngFor="let keyPoint of dialogData.body">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px">
        <mg-icon [ngClass]="'icon-' + dialogData.module">
          {{ keyPoint.icon }}
        </mg-icon>
        <mg-text
          variant="body-md"
          textAlign="left">
          {{ keyPoint.text }}
        </mg-text>
      </div>
      <div class="divider"></div>
    </ng-container>
  </div>
</ng-template>
<ng-template #footerTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <mg-btn
      variant="text"
      [href]="dialogData.learnMoreLink">
      {{ MESSAGES.LEARN_MORE_LABEL }}
    </mg-btn>
    <mg-btn
      variant="filled"
      (pressed)="openUpgradeRequestModal()">
      {{ MESSAGES.UPGRADE_LABEL }}
    </mg-btn>
  </div>
</ng-template>
