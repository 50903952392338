<ng-container *ngIf="!isEventOver() && context">
  <ng-container *ngIf="availableStates$ | async as availableStates">
    <ng-container
      *ngIf="
        availableStates.length &&
        !availableStates.includes('CHECK_IN') &&
        !availableStates.includes('CHECK_OUT') &&
        (currentState$ | async) as currentState
      ">
      <mat-button-toggle-group
        [ngClass]="['mg-cancel-content-link', 'status-button-group']"
        (change)="goingClick($event.value)">
        <mat-button-toggle
          value="GOING"
          [class.going]="currentStatus === 'GOING'">
          {{ content.goingCount >= 15 ? content.goingCount : '' }} Going
        </mat-button-toggle>
        <mat-button-toggle
          value="INTERESTED"
          [class.interested]="currentStatus === 'INTERESTED'"
          >Maybe</mat-button-toggle
        >
        <mat-button-toggle
          value="NOT_GOING"
          [class.not-going]="currentStatus === 'NOT_GOING'"
          >Can't go</mat-button-toggle
        >
      </mat-button-toggle-group>
    </ng-container>
    <mg-button
      type="outline"
      class="event-button mg-cancel-content-link"
      [class.checkout]="availableStates.includes('CHECK_OUT')"
      (click)="goingClick(availableStates[0])"
      (change)="goingClick($event.value)"
      *ngIf="
        availableStates.length &&
        (availableStates.includes('CHECK_IN') ||
          availableStates.includes('CHECK_OUT'))
      ">
      Check {{ availableStates.includes('CHECK_IN') ? 'In' : 'Out' }}
    </mg-button>
  </ng-container>
</ng-container>
