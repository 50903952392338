import { Component, ChangeDetectionStrategy } from '@angular/core';

import { PmReportsRewardsRedeemedDatasourceService } from '../../services/pm-rewards-redeemed-datasource.service';

@Component({
  selector: 'mg-pm-reports-rewards',
  template: `
    <mg-report-table
      matSort
      [dataSourceService]="ds"
      (matSortChange)="ds.sort($event)">
    </mg-report-table>
  `,
  styleUrls: ['../pm-reports.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PmReportsRewardsRedeemedDatasourceService],
})
export class PmReportsRewardsComponent {
  constructor(public ds: PmReportsRewardsRedeemedDatasourceService) {}
}
