import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { ManagerReportChartSectionComponent } from './manager-report-chart-section.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [ManagerReportChartSectionComponent],
  exports: [ManagerReportChartSectionComponent],
})
export class ManagerReportChartSectionModule {}
