import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgAutofocusDirective } from './Autofocus.directive';
import { MgConfirmDirective } from './Conform';
import { MgFixedDirective } from './Fixed.directive';
import { MgFocusSelectTextDirective } from './FocusSelectText.directive';
import { MgIdNumberDirective } from './MgIdNumber.directive';
import { MgModuleEnabledDirective } from './ModuleEnabled.directive';
import { MgNoTabHeaderDirective } from './NoTabHeader.directive';
import { MgPinchZoomDirective } from './PinchZoom.directive';
import { MgPolymerBindingDirective } from './PolymerBinding.directive';
import { PreventNewlinesDirective } from './PreventNewlines.directive';
import { MgTextDirective } from './Text.directive';

@NgModule({
  imports: [FlexLayoutModule],
  exports: [
    MgAutofocusDirective,
    MgConfirmDirective,
    MgFixedDirective,
    MgFocusSelectTextDirective,
    MgPinchZoomDirective,
    MgPolymerBindingDirective,
    PreventNewlinesDirective,
    MgTextDirective,
    MgNoTabHeaderDirective,
    MgModuleEnabledDirective,
    MgIdNumberDirective,
  ],
  declarations: [
    MgAutofocusDirective,
    MgConfirmDirective,
    MgFixedDirective,
    MgFocusSelectTextDirective,
    MgPinchZoomDirective,
    MgPolymerBindingDirective,
    PreventNewlinesDirective,
    MgTextDirective,
    MgNoTabHeaderDirective,
    MgModuleEnabledDirective,
    MgIdNumberDirective,
  ],
})
export class MgDirectivesModule {}
