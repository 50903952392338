<!-- Flex column wrapper -->
<!-- Audio notifications -->
<div class="settings-container">
  <div
    *ngIf="allowAudioNotifications"
    class="audio-container">
    <mat-slide-toggle
      labelPosition="before"
      [attr.data-analytics]="'kiosk-audio-toggle'"
      [checked]="kioskAudio.enableSound$ | async"
      (change)="kioskAudio.toggleEnableSound()">
      Enable audio notification
    </mat-slide-toggle>
  </div>
  <!-- Additional Settings -->
  <ng-content select="[additionalToggles]"></ng-content>
</div>

<div
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="24px">
  <div>
    <!-- Subtitle -->
    <mg-text
      *ngIf="subTitle"
      variant="header-xl"
      textAlign="center"
      spacing="1"
      color="surface-alt">
      {{ subTitle }}
    </mg-text>

    <!-- Title -->
    <mg-text
      variant="header-xxl-secondary"
      textAlign="center"
      spacing="2">
      {{ title }}
    </mg-text>
  </div>

  <!-- Projected content (for custom filters) -->
  <ng-content></ng-content>

  <!-- Main content -->
  <ng-container
    *ngIf="!isLoading; else loadingStateTemplate"
    [ngTemplateOutlet]="mainContentTemplate">
  </ng-container>
</div>

<!-- Main content template -->
<ng-template #mainContentTemplate>
  <!-- Tiles grid -->
  <div
    *ngIf="kioskTypes?.length; else emptyStateTemplate"
    class="types-grid">
    <div
      *ngFor="let type of kioskTypes"
      class="grid-item">
      <ng-container
        [ngTemplateOutlet]="selectionButtonTemplate"
        [ngTemplateOutletContext]="{
          $implicit: type,
          selected: isSelected(type)
        }">
      </ng-container>
      <!-- <mg-sa-tile
        [tile]="$any(type)"
        [selected]="isSelected(type)"
        (pressed)="addToSelection($event)">
      </mg-sa-tile> -->
    </div>
  </div>
</ng-template>

<!-- Footer template -->
<ng-template #kioskPortalFooterRef>
  <div
    fxLayout="row"
    fxLayout.lt-md="column-reverse"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap.lt-md="8px"
    class="footer-container">
    <!-- Back button -->
    <mg-btn
      *ngIf="showBackButton"
      variant="text"
      iconSet="minga"
      [responsive]="true"
      [size]="(media.isMobileView$ | async) ? 'large' : 'kiosk'"
      (pressed)="back.emit('')">
      {{ backLabel }}
    </mg-btn>
    <!-- Submit button -->
    <mg-btn
      iconSet="minga"
      [responsive]="true"
      [size]="(media.isMobileView$ | async) ? 'large' : 'kiosk'"
      [disabled]="disableSubmit || (canSubmit$ | async) === false"
      (pressed)="submit()">
      {{ submitLabel }}
    </mg-btn>
  </div>
</ng-template>

<!-- Loading state template -->
<ng-template #loadingStateTemplate>
  <div class="loading-state-container">
    <mg-spinner
      [thickness]="5"
      [diameter]="40">
    </mg-spinner>
  </div>
</ng-template>

<!-- Empty state template -->
<ng-template #emptyStateTemplate>
  <mg-empty-state
    [title]="emptyStateTitle"
    [subtitle]="emptyStateSubTitle">
  </mg-empty-state>
</ng-template>

<!-- Selection button template -->
<ng-template
  #selectionButtonTemplate
  let-type
  let-selected="selected">
  <button
    class="type-button"
    type="button"
    [attr.data-analytics]="
      maxSelection !== 1 ? 'kiosk-type-setup' : 'kiosk-type-student-select'
    "
    [class.selected]="selected"
    (click)="addToSelection(type)">
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="type-button-container">
      <!-- Checkbox -->
      <mg-form-checkbox
        *ngIf="maxSelection !== 1; else radioTemplate"
        class="type-button-checkbox"
        [checked]="selected">
      </mg-form-checkbox>
      <!-- Radio -->
      <ng-template #radioTemplate>
        <div
          fxFlex="noshrink"
          class="type-button-radio"></div>
      </ng-template>
      <div class="type-button-icon">
        <mg-icon
          [style.color]="type.color"
          [icon-namespace]="type?.iconNamespace">
          {{ type.icon ? type.icon : 'hallpass' }}
        </mg-icon>
      </div>
      <ng-template #assetPath>
        <mg-image
          class="type-button-asset"
          [srcs]="[type.assetPath]">
        </mg-image>
      </ng-template>
      <div fxFlex="grow">
        <mg-text
          variant="body-sm-secondary"
          textAlign="left"
          [numberOfLines]="2">
          {{ type.name }}
        </mg-text>
      </div>
    </div>
  </button>
</ng-template>
