<!-- Success overlay template -->
<ng-template #successOverlayTemplate>
  <div class="success-overlay">
    <div class="checkmark-wrap">
      <mg-success-checkmark></mg-success-checkmark>
      <div class="mg-bodycopy">Success!</div>
    </div>
  </div>
</ng-template>

<!-- Mod failed overlay template -->
<ng-template #modFailedOverlayTemplate>
  <div class="mod-failed-overlap">
    <div class="mod-failed-body user-content mg-centered">
      <p><mg-icon>warning-o</mg-icon></p>
      <h2 class="mg-headline2">
        {{ 'moderation.failed.headline.' + modFailHeadline | translate }}
      </h2>
      <div *ngFor="let item of getModFailItemsAsArray(); let i = index">
        <p
          class="mg-bodycopy"
          *ngIf="item.hasIndex">
          <span
            [innerHTML]="
              'moderation.failed.' + item.bodyLocale
                | translate: { value: item.index }
            ">
          </span>
        </p>
        <p
          *ngIf="!item.hasIndex"
          class="mg-bodycopy"
          [innerHTML]="
            'moderation.failed.' + item.bodyLocale | translate: item.categories
          "></p>
        <p
          class="mg-bodycopy"
          *ngIf="item.bodySecondLocale && item.hasIndexSecond">
          <span
            [innerHTML]="
              'moderation.failed.' + item.bodySecondLocale
                | translate: { value: item.index }
            ">
          </span>
        </p>
        <p
          class="mg-bodycopy"
          *ngIf="item.bodySecondLocale && !item.hasIndexSecond"
          [innerHTML]="
            'moderation.failed.' + item.bodySecondLocale
              | translate: item.categoriesSecond
          "></p>
      </div>
      <p
        class="mg-bodycopy"
        [innerHTML]="'moderation.failed.aiDescGeneral' | translate">
        &nbsp;
      </p>
      <p
        *ngIf="shouldAllowPostAnyways$ | async"
        [innerHTML]="'moderation.failed.aiCanPostAnyways' | translate"></p>
      <p
        *ngIf="(shouldAllowPostAnyways$ | async) === false"
        [innerHTML]="'moderation.failed.aiNoPost' | translate"></p>
      <p class="mg-centered">
        <mg-button (click)="closeActiveModal()">
          {{ 'moderation.failed.button.ok' | translate }}
        </mg-button>
      </p>
      <ng-container *ngIf="modFailContextHash || modFailGalleryPhotoUuid">
        <p
          *ngIf="shouldAllowPostAnyways$ | async"
          class="mg-centered">
          <mg-button
            [disabled]="isLoading"
            color="warn"
            type="outline"
            (click)="overrideModFail()">
            {{ 'moderation.failed.button.override' | translate }}
          </mg-button>
        </p>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- Content -->
<ng-content></ng-content>
