<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="12px">
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="4px">
    <mg-text
      variant="body-md"
      [color]="disabled ? 'on-disabled-bg' : 'surface'">
      {{ label }}
    </mg-text>
    <mg-tooltip
      *ngIf="helpText"
      [label]="helpText">
    </mg-tooltip>
  </div>
  <div>
    <mg-form-text-input
      inputType="number"
      [id]="id"
      [isDisabled]="disabled"
      [step]="step"
      [min]="min.toString()"
      [control]="control"
      [max]="max">
    </mg-form-text-input>
  </div>
</div>
