<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="isLoading$ | async"
  [overlayTitle]="modalTitle$ | async">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainTemplate>
  <main [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="15px">
      <!-- Name Field -->
      <mg-form-text-input
        [id]="'flextime-period-create-input-name'"
        [label]="MESSAGES.FIELD_LABEL_TITLE"
        [control]="form.get(FORM_FIELD.TITLE)">
      </mg-form-text-input>
      <!-- Date Field -->
      <mg-form-date
        [id]="'flextime-period-create-input-date'"
        [minDate]="currentDate"
        [label]="MESSAGES.FIELD_LABEL_DATE"
        [control]="form.get(FORM_FIELD.DATE)">
      </mg-form-date>
      <div
        fxLayout="row"
        fxLayoutAlign="start stretch"
        fxLayoutGap="15px">
        <div fxFlex="50%">
          <!-- Start Time Field -->
          <mg-form-text-input
            inputType="time"
            [id]="'flextime-period-create-input-start-time'"
            [label]="MESSAGES.FIELD_LABEL_START_TIME"
            [min]="minTime"
            [step]="60"
            [control]="form.get(FORM_FIELD.START_TIME)">
          </mg-form-text-input>
          <mg-form-error
            *ngIf="form.dirty && form.getError('invalidTimeComparison')">
            {{ form?.errors | makeInputErrorMessage }}
          </mg-form-error>
          <mg-form-error
            *ngIf="form.dirty && form.getError('invalidDateTimeInPast')">
            {{ form?.errors | makeInputErrorMessage }}
          </mg-form-error>
        </div>
        <!-- End Time Field -->
        <mg-form-text-input
          inputType="time"
          [id]="'flextime-period-create-input-end-time'"
          [label]="MESSAGES.FIELD_LABEL_END_TIME"
          [step]="60"
          [control]="form.get(FORM_FIELD.END_TIME)">
        </mg-form-text-input>
      </div>
      <div
        fxLayout="column"
        fxLayoutGap="12px">
        <div>
          <!-- Activity Manage Toggle -->
          <mg-form-toggle
            [id]="'flextime-period-create-input-teachers-add-activities'"
            [label]="MESSAGES.FIELD_LABEL_ACTIVITY_MANAGE"
            [control]="form.get(FORM_FIELD.ACTIVITY_MANAGE)">
          </mg-form-toggle>
          <!-- Student Manage Toggle -->
          <mg-form-toggle
            [id]="'flextime-period-create-input-teachers-unregister'"
            [label]="MESSAGES.FIELD_LABEL_STUDENT_MANAGE"
            [control]="form.get(FORM_FIELD.STUDENT_MANAGE)">
          </mg-form-toggle>
          <!-- Student Self Manage Toggle -->
          <mg-form-toggle
            [id]="'flextime-period-create-input-students-self-register'"
            [label]="MESSAGES.FIELD_LABEL_STUDENT_SELF_MANAGE"
            [secondaryLabel]="MESSAGES.FIELD_LABEL_STUDENT_SELF_MANAGE_TOOLTIP"
            [control]="form.get(FORM_FIELD.STUDENT_SELF_MANAGE)">
          </mg-form-toggle>
        </div>

        <!-- Clone Toggles -->
        <div *ngIf="dialogData.isClone">
          <mg-form-toggle
            [label]="MESSAGES.FIELD_LABEL_CLONE_REGISTERED"
            [control]="form.get(FORM_FIELD.CLONE_REGISTERED)">
          </mg-form-toggle>
          <mg-form-toggle
            [label]="MESSAGES.FIELD_LABEL_CLONE_ASSIGNED"
            [control]="form.get(FORM_FIELD.CLONE_ASSIGNED)">
          </mg-form-toggle>
        </div>
      </div>
    </div>
  </main>
</ng-template>

<!-- Footer Content Templat -->
<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [id]="'flextime-period-create-input-cancel'"
        [variant]="(isNew$ | async) ? 'text' : 'destructive'"
        (pressed)="delete()">
        {{
          (isNew$ | async)
            ? MESSAGES.BUTTON_LABEL_CANCEL
            : MESSAGES.BUTTON_LABEL_DELETE
        }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        variant="filled"
        [id]="'flextime-period-create-input-create'"
        [loading]="isSubmitting$ | async"
        [disabled]="!canSubmit"
        (pressed)="submit()">
        {{
          (isNew$ | async)
            ? MESSAGES.BUTTON_LABEL_CREATE
            : MESSAGES.BUTTON_LABEL_SAVE
        }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
