import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RouterModule } from '@angular/router';

import { CalendarQuickFilterModule } from 'minga/app/src/app/components/CalendarQuickFilter/CalendarQuickFilter.module';
import { MgIconModule } from 'minga/app/src/app/icon';

import { TextModule } from '@shared/components/text';

import { CalendarScheduleNavComponent } from './CalendarScheduleNav.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    CalendarQuickFilterModule,
    MatButtonToggleModule,
    TextModule,

    // External dependencies
    CommonModule,
    RouterModule,
    FlexLayoutModule,
  ],
  declarations: [CalendarScheduleNavComponent],
  exports: [CalendarScheduleNavComponent],
})
export class CalendarScheduleNavModule {}
