import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Observable, ReplaySubject, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { MingaPermission } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';

import { LayoutService } from '@modules/layout/services';

import { CarouselStylePresets } from '@shared/components/tiles-carousel';
import { FlexTimePeriodService } from '@shared/services/flex-time';
import { HallPassService } from '@shared/services/hall-pass';

import {
  DEFAULT_ROUTE,
  NAVIGATION_STATE_ASSIGN_TYPE_KEY,
  NAVIGATION_STATE_SELF_ASSIGN_KEY,
  SelectionAssignerRoutes,
} from './constants';
import { SelectionAssignerService } from './services';
import {
  BaseSaPageData,
  CarouselTileData,
  FilterSaPageData,
  SaAssignOverlayConfig,
  TabSaPageData,
} from './types';

@Component({
  selector: 'mg-selection-assigner',
  templateUrl: './selection-assigner.component.html',
  styleUrls: ['./selection-assigner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SelectionAssignerService, HallPassService, FlexTimePeriodService],
})
export class SelectionAssignerComponent implements OnDestroy {
  @ViewChild('assignerContainer', { static: false, read: ElementRef })
  private _assignerContainer: ElementRef;
  @ViewChild('assignerForm', { static: false, read: ElementRef })
  private _assignerForm: ElementRef;

  /** General Observables */
  public presetCarouselLayout$: Observable<CarouselStylePresets>;
  public carouselList$: Observable<CarouselTileData[]>;
  public media$: Observable<string>;
  private _destroyedSubject = new ReplaySubject<void>(1);

  /** Form Controls */
  public typeControl = this.saService.typeFormControl;

  /** Const */
  public modalConfig: SaAssignOverlayConfig;
  public pageData: BaseSaPageData | TabSaPageData;

  /** Other */
  public isSelfAssign: boolean;
  public submitButtonLabel: 'Next' | 'Create' = 'Next';
  public filterMsgs: FilterSaPageData = {
    filterPlaceholder: '',
    filterEmptyHeading: '',
    filterEmptyMsg: '',
  };
  private _previousUrl: string;

  /** Component Constructor */
  constructor(
    public saService: SelectionAssignerService,
    private _router: Router,
    private _location: Location,
    private _mediaObserver: MediaObserver,
    private _activatedRoute: ActivatedRoute,
    private _permissions: PermissionsService,
    private _cdr: ChangeDetectorRef,
    public layoutService: LayoutService,
  ) {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._destroyedSubject),
      )
      .subscribe((data: any) => {
        this._previousUrl = this._router
          .getCurrentNavigation()
          .previousNavigation.finalUrl.toString();

        this.isSelfAssign =
          sessionStorage.getItem(NAVIGATION_STATE_SELF_ASSIGN_KEY) ??
          this._router.getCurrentNavigation().extras?.state?.selfAssign;
        if (this.isSelfAssign)
          sessionStorage.setItem(NAVIGATION_STATE_SELF_ASSIGN_KEY, 'true');

        const importBehaviors =
          this._router.getCurrentNavigation().extras?.state?.importBehaviors;
        this.saService.setImportBehaviors(importBehaviors);

        const urlWithoutParams = data?.url?.split('?')[0];
        let activeSelector = urlWithoutParams
          ?.split('/')
          ?.pop()
          .replace(')', '');

        const navigationStateSelector =
          sessionStorage.getItem(NAVIGATION_STATE_ASSIGN_TYPE_KEY) ??
          this._router.getCurrentNavigation().extras.state?.type;
        if (navigationStateSelector) {
          activeSelector = navigationStateSelector;
          sessionStorage.setItem(
            NAVIGATION_STATE_ASSIGN_TYPE_KEY,
            activeSelector,
          );
        }
        this.saService.activeSelectorSubject.next(activeSelector);
      });

    this.saService.activeSelector$
      .pipe(
        takeUntil(this._destroyedSubject),
        filter(selector => !!selector),
      )
      .subscribe(selector => {
        sessionStorage.setItem(NAVIGATION_STATE_ASSIGN_TYPE_KEY, selector);

        this._reset();
        this._setModalConfig();
      });

    this.presetCarouselLayout$ = this._mediaObserver.asObservable().pipe(
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
      switchMap(media => {
        return ['xs', 'sm'].includes(media)
          ? of(CarouselStylePresets.VERT_LIST)
          : of(CarouselStylePresets.THREE_X_FOUR);
      }),
    );

    this.media$ = this._mediaObserver.asObservable().pipe(
      takeUntil(this._destroyedSubject),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );

    this.typeControl.valueChanges
      .pipe(takeUntil(this._destroyedSubject), skip(1))
      .subscribe(() => {
        this._scrollToBottom();
      });

    this.carouselList$ = this.saService.carouselData$;
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    sessionStorage.removeItem(NAVIGATION_STATE_ASSIGN_TYPE_KEY);
    sessionStorage.removeItem(NAVIGATION_STATE_SELF_ASSIGN_KEY);
  }

  public back() {
    if (this._previousUrl === DEFAULT_ROUTE) this._router.navigateByUrl('/');
    else this._location.back();
  }

  public close() {
    this._router.navigate([{ outlets: { modal: null } }]);
  }

  public filterValueSelected(data: CarouselTileData[]) {
    this.saService.carouselDataSubject.next(data);
  }

  public async submit() {
    await this.saService.submit();
  }

  private async _setModalConfig() {
    this.modalConfig = this.saService.getModalConfig();
    if (this.isSelfAssign) this.submitButtonLabel = 'Create';
    if (this.isSelfAssign && this.modalConfig.selfAssignTitle) {
      this.modalConfig.formTitle = this.modalConfig.selfAssignTitle;
    }

    if (this.modalConfig?.tabbedHeader) {
      const pagesData = this.modalConfig?.data as TabSaPageData[];
      if (pagesData) {
        const activePageData = pagesData.find(
          data => data.selector === this.saService.activeSelectorSubject.value,
        );
        this.pageData = activePageData;
      }
    } else {
      this.pageData = this.modalConfig?.data as BaseSaPageData;
    }

    const route = this._activatedRoute.snapshot.firstChild.routeConfig
      .path as SelectionAssignerRoutes;

    if (route === SelectionAssignerRoutes.BEHAVIOR) {
      const canCreateConsequence = this._permissions.hasPermission(
        MingaPermission.CONSEQUENCE_CREATE,
      );
      if (!canCreateConsequence && Array.isArray(this.modalConfig.data)) {
        this.modalConfig.data = this.modalConfig.data?.filter(
          tab => tab.selector !== 'consequence',
        );
        this._cdr.markForCheck();
      }
    }

    this.filterMsgs = this.pageData?.filterInfo;
  }

  private _reset() {
    this.saService.reset();
  }

  private _scrollToBottom() {
    const element = this._assignerContainer?.nativeElement;
    if (!element && element?.scrollTop !== 0) return;

    element?.scrollIntoView({
      behavior: 'smooth',
    });
  }
}
