<span
  style="display: none"
  mgText
  #customError="mgText">
  <ng-content select="[custom-error]"></ng-content>
</span>

<span
  style="display: none"
  mgText
  #customHint="mgText">
  <ng-content select="[custom-hint]"></ng-content>
</span>

<mat-form-field
  hideRequiredMarker
  mgFormField
  class="mg-email"
  [mgNoFloatTop]="(editMode && !editing) || !float"
  [mgNoHintMargin]="mgNoHintMargin">
  <div
    matSuffix
    class="input-action mg-minicopy"
    *ngIf="editMode && !statusPending && !editing"
    (click)="toggleEdit(true, $event)">
    Edit
  </div>
  <div
    matSuffix
    class="input-actions mg-minicopy"
    *ngIf="editMode && !statusPending && editing">
    <span
      class="input-action"
      (click)="onSave()"
      [class.disabled]="saveDisabled"
      >Save</span
    >
    <mg-button-icon
      class="input-action icon icon-close"
      icon="close-thin"
      [no-active]="true"
      (click)="toggleEdit(false, $event)"></mg-button-icon>
  </div>

  <mat-label>Email Address</mat-label>

  <input
    matInput
    #inputElement
    [mgUniqueEmail]="unique"
    type="email"
    (ngModelChange)="change($event)"
    [autocomplete]="inputName"
    [formControl]="outerErrors ? outerFormControl : inputControl"
    [readonly]="readonly || (enableReadOnlyState && editMode && !editing)"
    [name]="inputName"
    [required]="required"
    (focus)="onFocus($event)"
    [errorStateMatcher]="matcher"
    (blur)="onBlur($event)"
    (keydown)="onFocus($event); onKeyDown($event)" />

  <div
    class="input-icon"
    mat-icon-button
    matSuffix
    *ngIf="
      !validatePatternOnly &&
      !novalidate &&
      (statusDone || statusInvalid || statusPending)
    ">
    <mat-icon *ngIf="statusDone">done</mat-icon>
    <mat-icon *ngIf="statusInvalid">error_outline</mat-icon>

    <mg-spinner
      color="primary"
      diameter="25"
      *ngIf="statusPending">
    </mg-spinner>
  </div>

  <mat-hint *ngIf="!nohint && !hintSlot">
    <ng-container *ngIf="!customHint.text">
      {{ 'input.email.hint' | translate }}
    </ng-container>
    <ng-container *ngIf="customHint.text">
      <span>{{ customHint.text }}</span>
    </ng-container>
  </mat-hint>

  <mat-hint *ngIf="hintSlot">
    <ng-content select="mg-email-hint"></ng-content>
  </mat-hint>

  <mat-error *ngIf="statusTouched && !noerror">
    <ng-container
      [ngSwitch]="inputControlErrorKey"
      *ngIf="!customError.text">
      <span *ngSwitchCase="'maxlength'">
        <!-- Email address must be 50 characters or less -->
        {{ 'input.email.error.maxlength' | translate }}
      </span>
      <span
        *ngSwitchCase="
          inputControlErrorKey == 'pattern' || inputControlErrorKey == 'email'
            ? inputControlErrorKey
            : ''
        ">
        <!-- Sorry, that's not a real email address -->
        {{ 'input.email.error.email' | translate }}
      </span>
      <span *ngSwitchCase="'emailTaken'">
        <!-- Sorry, that email is already taken -->
        {{ 'input.email.error.emailTaken' | translate }}
      </span>
      <span *ngSwitchCase="'savePersonDetail'">
        {{ 'form.saveFieldFail' | translate: translateParams }}
      </span>
      <span *ngSwitchCase="'required'">
        <!-- You must provide an email address -->
        {{ 'input.email.error.required' | translate }}
      </span>
    </ng-container>

    <ng-container *ngIf="customError.text">
      <span>{{ customError.text }}</span>
    </ng-container>
  </mat-error>
</mat-form-field>
