<mg-silh-landing-layout>
  <div class="landing">
    <div
      *ngIf="
        kioskPermissions.isKioskFeatureEnabled() &&
        router.url === '/' + LANDING_ROUTES.ROOT + '/' + LANDING_ROUTES.EMAIL
      "
      class="kiosk-btn">
      <mg-kiosk-btn
        [routerLink]="[
          '/',
          LANDING_ROUTES.ROOT,
          LANDING_ROUTES.KIOSK_LOGIN
        ]"></mg-kiosk-btn>
    </div>
    <div
      class="extra-top-margin"
      *ngIf="extraTopMargin"></div>
    <div class="landing-content">
      <div class="landing-content-routes">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</mg-silh-landing-layout>
