<mg-button-icon
  (click)="cancel()"
  class="top left"
  icon="close"></mg-button-icon>

<p
  class="mg-dialog message dialog-title"
  [innerHTML]="'dialog.deleteConfirm.title' | translate: titleParams"></p>
<p
  class="mg-dialog message"
  [innerHTML]="'dialog.deleteConfirm.subtitle' | translate: translateParams"
  *ngIf="showSubTitle"></p>

<div
  class="post-step-spacing"
  *ngIf="matchString">
  <input
    class="post-input"
    [class.invalid]="disableButton"
    name="input"
    autocomplete="off"
    type="text"
    [(ngModel)]="inputString"
    [ngModelOptions]="{ standalone: true }"
    [placeholder]="
      'dialog.deleteConfirm.placeholder' | translate: translateParams
    " />
</div>

<div class="btn-wrap footer-button-container">
  <mg-button
    type="text"
    (click)="cancel()"
    >{{ 'button.cancel' | translate }}</mg-button
  >
  <mg-button-outline
    (click)="delete()"
    [disabled]="disableButton">
    {{ 'button.delete' | translate }}
  </mg-button-outline>
</div>
