<mg-stream-scroller
  *ngIf="authPersonHash$ | async as authPersonHash"
  mgStreamControl="ProfilePoints"
  [service]="pointsProto"
  rpcStream="streamPointsHistory"
  rpcControl="streamPointsHistoryControl"
  [filter]="getFilter(authPersonHash)">
  <ng-template
    let-item
    let-itemId="itemId"
    mgStreamItem>
    <mg-date-separator
      [text]="checkForSeparator(item)"
      streamName="ProfilePoints"></mg-date-separator>
    <mg-points-history-item [item]="item"></mg-points-history-item>
  </ng-template>

  <mg-empty-state
    class="empty-points"
    mg-stream-empty
    [title]="MESSAGES.EMPTY_POINTS"></mg-empty-state>
</mg-stream-scroller>
