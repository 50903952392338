import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';
import { SVG } from '@svgdotjs/svg.js';

import { loadFontFamily } from 'minga/app/src/app/util/font';
import {
  DesignedSvgBackgroundSlotValue,
  DesignedSvgData,
  DesignedSvgDataValue,
  DesignedSvgImageSlotValue,
  DesignedSvgManipulations,
  DesignedSvgSlotPair,
  DesignedSvgSlotType,
  DesignedSvgTextSlotValue,
  IDesignedSvgManipulation,
} from 'minga/shared/designed_svg/types';

import {
  IDesignedSvgMetadata,
  IDesignedSvgSlotMetadataExtra,
} from '../../types';
import {
  addAdditionalSlotNode,
  getBgSlotValue,
  getImageSlotValue,
  getManipulatableMatrixSlotNode,
  getManipulatableSizeSlotNode,
  getSlotDesignedSvgManipulation,
  getTextBoundsElement,
  getTextExtraOptions,
  getTextSlotTextElement,
  getTextSlotValue,
  gradientIdCount,
  IDesignedSvgSlotMetadataWithNode,
  normalizeBgSlotStyle,
  normalizeImageSlotStyle,
  normalizeTextSlotStyle,
  refreshTextSlot,
  setAdditionalSlotNodeValue,
  setBgSlotValue,
  setImageSlotValue,
  setTextSlotValue,
  SHADOW_ROOT_STYLESHEET_HREF,
  updateTextSlot,
} from './utility';

const MIN_IMAGE_SIZE = 100;

interface IFontFamilyOption {
  name: string;
  disableBold?: boolean;
  disableItalic?: boolean;
}

const SVG_CONTAINER_FONTS: IFontFamilyOption[] = [
  {
    name: 'Amatic SC',
  },
  {
    name: 'Caveat',
  },
  {
    name: 'Graduate',
  },
  {
    name: 'Indie Flower',
  },
  {
    name: 'Muli',
  },
  {
    name: 'Nothing You Could Do',
  },
  {
    name: 'Oswald',
  },
  {
    name: 'Permanent Marker',
  },
  {
    name: 'Roboto Slab',
  },
];

/**
 * The `<mg-designed-svg-viewer>` renders SVGs that have been specifically
 * designed to have different areas (slots) replaced with custom values. This
 * includes background colors, text and images.
 *
 * The slots are parsed and outputted through the `designedSvgMetadata`
 * allowing you to optionally provide ways of changing the values dynamically.
 */
@Component({
  selector: 'mg-designed-svg-viewer',
  templateUrl: './DesignedSvgViewer.component.html',
  styleUrls: ['./DesignedSvgViewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignedSvgViewerComponent implements OnInit {
  /** @internal */
  private _fetched: boolean;
  /** @internal */
  private _designedSvgSrc: string;
  /** @internal */
  private _needsMigration: boolean;
  /** @internal */
  private _designedSvgData: DesignedSvgData = {};
  /** @internal */
  private _designedSvgManipulations: DesignedSvgManipulations | null = null;
  /** @internal */
  private _additionalSlotNodes: (SVGElement | null)[] = [];

  /** @internal */
  private _designedSvgMetadata: IDesignedSvgMetadata<IDesignedSvgSlotMetadataWithNode>;

  /** @internal */
  private _shadowRoot?: ShadowRoot;

  get needsMigration() {
    return this._needsMigration;
  }

  /**
   * Container that holds the SVG markup
   */
  @ViewChild('designedSvgContainer', { static: true })
  designedSvgContainer?: ElementRef<HTMLDivElement>;

  /**
   * Value used for svg viewbox width while loading the svg
   */
  @Input()
  predictedViewboxWidth: number;

  /**
   * Value used for svg viewbox height while loading the svg
   */
  @Input()
  predictedViewboxHeight: number;

  /**
   * Emits the parsed SVG metadata after the designed SVG has been fetched.
   */
  @Output()
  readonly designedSvgMetadata: EventEmitter<IDesignedSvgMetadata>;

  /**
   * URL the SVG can be fetched from.
   */
  @Input()
  set designedSvgSrc(src: string) {
    this._designedSvgSrc = src;
    this._fetchSvgSrcIfContainerReady();
  }

  get designedSvgSrc() {
    return this._designedSvgSrc;
  }

  /**
   * Data to be inserted into the SVG. The keys in `designedSvgData` will only
   * be used if they match up with the `designedSvgMetadata` slot `fullId`s.
   */
  @Input()
  set designedSvgData(value: DesignedSvgData) {
    this._designedSvgData = value || {};
    this._applyDesignedSvgDataIfReady();
    this._applyDesignedSvgManipulationsIfReady();
  }

  get designedSvgData() {
    return this._designedSvgData;
  }

  @Input()
  set designedSvgManipulations(value: DesignedSvgManipulations | null) {
    this._designedSvgManipulations = value || null;
    this._applyDesignedSvgManipulationsIfReady();
  }

  get designedSvgManipulations() {
    return this._designedSvgManipulations;
  }

  get predicatedContainerPadding() {
    if (!this._fetched) {
      if (this.predictedViewboxWidth !== 0) {
        const pc = this.predictedViewboxHeight / this.predictedViewboxWidth;
        return `${pc * 100}%`;
      }
    }

    return '';
  }

  /**
   * Fired when the svg has been fetched, metadata has been processed, and data
   * has been applied. If the svg src changes this will be fired again.
   */
  @Output()
  readonly designedSvgReady: EventEmitter<void>;

  @Output()
  readonly additionalSlotAdded: EventEmitter<void>;

  @Output()
  readonly additionalSlotRemoved: EventEmitter<void>;

  constructor(/** @internal */ private _cdr: ChangeDetectorRef) {
    this.designedSvgMetadata = new EventEmitter();
    this.additionalSlotAdded = new EventEmitter();
    this.additionalSlotRemoved = new EventEmitter();
    this.designedSvgReady = new EventEmitter();
    this._designedSvgMetadata = {
      backgroundSlots: [],
      imageSlots: [],
      textSlots: [],
    };
  }

  ngOnInit() {
    this._fetchSvgSrc();
  }

  /**
   * Re-applies the manipulations. If a manipulation doesn't exist for a slot
   * one will be created based on the node attributes.
   */
  forceManipulationsUpdate() {
    const manipulations = { ...(this._designedSvgManipulations || {}) };

    const slots = [
      ...this._designedSvgMetadata.backgroundSlots,
      ...this._designedSvgMetadata.imageSlots,
      ...this._designedSvgMetadata.textSlots,
    ];

    for (const slot of slots) {
      if (!manipulations[slot.fullId]) {
        manipulations[slot.fullId] = getSlotDesignedSvgManipulation(
          slot.fullId,
          slot.node,
        );
      }
    }

    this._designedSvgManipulations = manipulations;
    this._applyDesignedSvgManipulations();
  }

  /**
   * Find the a suitable text slot value to be used as a default. Useful for
   * creating new additional slots based on pre-existing ones.
   */
  getDefaultTextSlotValue(): DesignedSvgTextSlotValue {
    const defaultTextSlot =
      this._designedSvgMetadata.textSlots.find(
        v => typeof v.defaultValue !== 'string',
      ) || this._designedSvgMetadata.textSlots[0];

    if (defaultTextSlot) {
      return _.clone(defaultTextSlot.defaultValue as DesignedSvgTextSlotValue);
    }

    const additionalSlots = this._designedSvgData.additionalSlots;
    if (additionalSlots && additionalSlots.length > 0) {
      const additionalTextSlots = additionalSlots!.filter(v => v[0] === 'txt');
      const defaultAdditionalTextSlotPair = additionalTextSlots!.find(
        v => typeof v[1] !== 'string',
      );

      if (defaultAdditionalTextSlotPair) {
        return _.clone(
          defaultAdditionalTextSlotPair[1] as DesignedSvgTextSlotValue,
        );
      }
    }

    return {
      ...this.getTextSlotDefaultStyles(),
      fontFamily: 'Muli',
      text: '',
    };
  }

  /**
   * Grabs some text styles to be used as a default for a text slot
   */
  getTextSlotDefaultStyles() {
    let fontSize = 0;
    let color = '';

    const textSlot = this._designedSvgMetadata.textSlots[0];
    let style: CSSStyleDeclaration;
    if (textSlot) {
      const textSlotElement =
        getTextSlotTextElement(textSlot.node) || textSlot.node;
      style = getComputedStyle(textSlotElement);
    } else {
      const svg = this.getSvgElementReference();
      style = getComputedStyle(svg);
    }

    // `getComputedStyle` always returns `px` for fontSize so a `parseFloat`
    // should be okay
    fontSize = parseFloat(style.fontSize);
    color = style.color || '';

    return { fontSize, color };
  }

  /**
   * @NOTE The resulting SVG is a _REFERENCE_. If you to modify it's contents
   *       it will reflect the viewer. It is recommended you DO NOT do this
   *       unless you're doing some specific edits for uploading an updated SVG.
   *       Use the `designedSvgData` input instead of this.
   */
  getSvgElementReference(): SVGSVGElement {
    const svg = this._shadowRoot!.querySelector('svg');
    if (!svg) {
      throw new Error('getSvgElementReference called before SVG was ready');
    }

    return svg;
  }

  /**
   * Overwrites the existing metadata with new metadata and applies the changes
   * to the SVG nodes
   */
  applyNewMetadata(metadata: IDesignedSvgMetadata) {
    if (!this._designedSvgMetadata) {
      throw new Error(
        'Cannot apply new metadata when original metadata has not been fetched yet',
      );
    }

    for (const textSlot of metadata.textSlots) {
      for (const existingSlot of this._designedSvgMetadata.textSlots) {
        if (existingSlot.fullId === textSlot.fullId) {
          updateTextSlot(existingSlot);
          break;
        }
      }
    }
  }

  highlightSlot(slotId: string) {
    for (const textSlot of this._designedSvgMetadata.textSlots) {
      if (textSlot.fullId === slotId) {
        const txtBoundsEl = getTextBoundsElement(textSlot.node);
        if (txtBoundsEl) {
          txtBoundsEl.style.outline = '1px solid red';
        }
        break;
      }
    }
  }

  clearHighlight(slotId: string) {
    for (const textSlot of this._designedSvgMetadata.textSlots) {
      if (textSlot.fullId === slotId) {
        const txtBoundsEl = getTextBoundsElement(textSlot.node);
        if (txtBoundsEl) {
          txtBoundsEl.style.outline = '';
        }
        break;
      }
    }
  }

  clearAllHighlights() {
    for (const textSlot of this._designedSvgMetadata.textSlots) {
      const txtBoundsEl = getTextBoundsElement(textSlot.node);
      if (txtBoundsEl) {
        txtBoundsEl.style.outline = '';
      }
    }
  }

  /**
   * Tests the text slot value against the text bounds. Returns `false` if the
   * text ends up outside the text bounds and `true` otherwise.
   */
  testTextSlotValueAgainstBounds(
    slotId: string,
    testSlotValue: DesignedSvgTextSlotValue,
  ): boolean {
    const textSlot = this._getTextSlotById(slotId);

    const txtNode = getTextSlotTextElement(textSlot.node);
    const slotNode = textSlot.node as SVGGElement | SVGTextElement;
    if (!txtNode) {
      return true;
    }

    const rect = getTextBoundsElement(slotNode) as SVGRectElement;
    const boundingRect = rect.getBoundingClientRect();
    const boundingWidth = Math.round(boundingRect.width);
    const boundingHeight = Math.round(boundingRect.height);

    // Store the current value to restore later
    const currentValue = getTextSlotValue(slotNode);

    let text =
      typeof testSlotValue === 'string' ? testSlotValue : testSlotValue.text;

    // Add extra newline if our last char is a newline to test against
    const lastChar = text[text.length - 1];
    if (lastChar === '\n') {
      text += '\n';
    }
    // We're replacing all our lines with a dot (.) and a newline so that we can
    // test against empty lines. Otherwise an empty line will take up zero space
    text = text.replace(/^\s*[\r\n]/gm, '.\n');

    // Set text node with the test value so we can make some calculations
    if (typeof testSlotValue === 'string') {
      setTextSlotValue(slotNode, text);
    } else {
      setTextSlotValue(slotNode, { ...testSlotValue, text });
    }

    // Trimming all the tspans to simulate what setTextSlotValue() will do when
    // measuring the text box
    const tspans = Array.from(slotNode.querySelectorAll('tspan'));
    for (const tspan of tspans) {
      if (tspan.textContent) {
        tspan.textContent = tspan.textContent.trim();
      }
    }

    const afterRect = txtNode.getBoundingClientRect();
    const afterWidth = Math.round(afterRect.width);
    const afterHeight = Math.round(afterRect.height);

    // Restore old value
    setTextSlotValue(slotNode, currentValue);

    return afterWidth <= boundingWidth && afterHeight <= boundingHeight;
  }

  computeTextSlotLineHeights(): { [keyname: string]: number } {
    const linesHeights: { [keyname: string]: number } = {};

    for (const textSlot of this._designedSvgMetadata.textSlots) {
      const txtNode = getTextSlotTextElement(textSlot.node);
      if (txtNode) {
        let distances: number[] = [];
        const txtNodeBbox = SVG(txtNode).bbox();
        const tspans = Array.from(txtNode.querySelectorAll('tspan'));
        if (tspans.length > 0) {
          const firstTspan = tspans[0];
          const firstDist = SVG(firstTspan).bbox().y - txtNodeBbox.y;
          distances.push(firstDist);
          for (let i = 0; tspans.length - 1 > i; ++i) {
            const currTspan = tspans[i];
            const nextTspan = tspans[i + 1];

            const currBbox = SVG(currTspan).bbox();
            const nextBbox = SVG(nextTspan).bbox();

            distances.push(nextBbox.y - currBbox.y);
          }

          // Remove outliars that have an extreme distance over others
          distances = distances.filter(dist => {
            for (const otherDist of distances) {
              if (dist > otherDist * 1.9) {
                return false;
              }
            }

            return true;
          });

          // Get the average distance and set that as the line height
          linesHeights[textSlot.fullId] =
            distances.reduce((total, v) => total + v, 0) / distances.length;
        }
      }
    }

    return linesHeights;
  }

  /**
   * Checks if the internal svg container is ready. Useful if you're in a state
   * where `getSvgElementReference()` might throw.
   *
   * @NOTE Try not to use this method and to instead workout your timings
   *       better. Especially in performance critical areas.
   */
  isSvgContainerReady(): boolean {
    return !!this._shadowRoot;
  }

  /**
   * Get the slot metadata with the svg node.
   *
   * @NOTE Modifying the `nodes` in this metadata will affect the SVG. Please
   *       use with caution.
   */
  getSlotMetadata(): Readonly<
    IDesignedSvgMetadata<IDesignedSvgSlotMetadataWithNode>
  > {
    return this._designedSvgMetadata;
  }

  /**
   * Get the slot metadata with the svg node.
   *
   * @NOTE Modifying the `nodes` in this metadata will affect the SVG. Please
   *       use with caution.
   */
  getAdditionalSlotNodes(): ReadonlyArray<SVGElement | null> {
    return this._additionalSlotNodes;
  }

  migrateTemplate() {
    const svg = this.getSvgElementReference();
    if (svg.dataset.mingaSvg !== 'v2') {
      this.removeExcessiveSlotScale();
      svg.dataset.mingaSvg = 'v2';
      this._needsMigration = false;
    } else {
      console.error(
        'Erronously called migrateTemplate() on already up to date template',
      );
    }
  }

  /**
   * Removes all scale transforms from existing slot nodes
   */
  removeExcessiveSlotScale() {
    for (const imageSlot of this._designedSvgMetadata.imageSlots) {
      const slotNode = SVG(imageSlot.node);

      const transform = slotNode.transform();
      const scaleX = transform.scaleX || 1;
      const scaleY = transform.scaleY || 1;
      const translateX = transform.translateX || 0;
      const translateY = transform.translateY || 0;
      const x = slotNode.x();
      const y = slotNode.y();
      slotNode.untransform();
      slotNode.width(slotNode.width() * scaleX);
      slotNode.height(slotNode.height() * scaleY);
      slotNode.x(0);
      slotNode.y(0);

      delete transform.scaleX;
      delete transform.scaleY;
      delete transform.a;
      delete transform.b;
      delete transform.c;
      delete transform.d;
      delete transform.e;
      delete transform.f;

      if (transform.translateX) {
        transform.translateX = translateX + x;
      }
      if (transform.translateY) {
        transform.translateY = translateY + y;
      }
      slotNode.transform(transform);
    }
  }

  /**
   * @returns additional slot index
   */
  addAdditionalSlot(type: 'txt', value: DesignedSvgTextSlotValue): number;

  /**
   * @returns additional slot index
   */
  addAdditionalSlot(type: 'img', value: DesignedSvgImageSlotValue): number;

  /**
   * @returns additional slot index
   */
  addAdditionalSlot(type: 'bg', value: DesignedSvgBackgroundSlotValue): number;

  addAdditionalSlot(type: DesignedSvgSlotType, value: any) {
    this._designedSvgData!.additionalSlots =
      this._designedSvgData!.additionalSlots || [];

    this._designedSvgManipulations!.additionalManipulations =
      this._designedSvgManipulations!.additionalManipulations || [];

    const additionalSlots = this._designedSvgData!.additionalSlots;
    const additionalManipulations =
      this._designedSvgManipulations!.additionalManipulations;

    const index = additionalSlots.length;

    // The lengths of the additional slots and the additional manipulations
    // should never be different.
    if (additionalSlots.length !== additionalManipulations.length) {
      throw new Error(`Invalid slot/manipulation length(s)`);
    }

    const slotPair: DesignedSvgSlotPair = [type, value];
    additionalSlots.push(slotPair);
    const defaultManipulation: IDesignedSvgManipulation = {};

    if (this.isSvgContainerReady()) {
      const newSlotNode = addAdditionalSlotNode(
        this.getSvgElementReference(),
        slotPair,
      );
      this._additionalSlotNodes[index] = newSlotNode;
      this.additionalSlotAdded.emit();
    }

    additionalManipulations.push(defaultManipulation);

    return index;
  }

  /**
   * @returns `true` if additional slot was actually removed
   */
  removeAdditionalSlot(slotIdOrIndex: number | string) {
    if (!this._designedSvgData!.additionalSlots) {
      return false;
    }

    if (!this._designedSvgManipulations!.additionalManipulations) {
      throw new Error(`Invalid slot/manipulation length(s)`);
    }

    let index = -1;
    if (typeof slotIdOrIndex === 'number') {
      index = slotIdOrIndex;
    } else if (DesignedSvgData.isAdditionalSlot(slotIdOrIndex)) {
      index = DesignedSvgData.getAdditionalSlotIndex(slotIdOrIndex);
    }

    if (isNaN(index) || index < 0) {
      return false;
    }

    if (!this._additionalSlotNodes[index]) {
      return false;
    }

    this._additionalSlotNodes[index]!.remove();
    this._additionalSlotNodes.splice(index, 1);
    this._designedSvgData!.additionalSlots.splice(index, 1);
    this._designedSvgManipulations!.additionalManipulations.splice(index, 1);

    this._applyDesignedSvgManipulationsIfReady();

    this.additionalSlotRemoved.emit();

    return true;
  }

  /**
   * Modifies the svg markup to normalize the different ways slots could be
   * styled. Useful when importing a new SVG.
   *
   * @NOTE This method should not be called often. Use sparingly
   */
  normalizeSlotStyles() {
    const modifyNode = (
      modifyFn: (node: SVGElement) => SVGElement,
      item: IDesignedSvgSlotMetadataWithNode,
    ) => (item.node = modifyFn(item.node));

    const metadata = this.getSlotMetadata();

    metadata.backgroundSlots.forEach(
      modifyNode.bind(this, normalizeBgSlotStyle),
    );
    metadata.imageSlots.forEach(modifyNode.bind(this, normalizeImageSlotStyle));
    metadata.textSlots.forEach(modifyNode.bind(this, normalizeTextSlotStyle));
  }

  /**
   * Sets the default metadata values from the actual SVG markup. Useful when
   * the SVG has been modified externally
   */
  setDefaultSlotValuesFromMarkup() {
    const metadata = this._designedSvgMetadata;

    for (let i = 0; metadata.backgroundSlots.length > i; ++i) {
      metadata.backgroundSlots[i] = {
        ...metadata.backgroundSlots[i],
        defaultValue: getBgSlotValue(metadata.backgroundSlots[i].node),
      };
    }

    for (let i = 0; metadata.imageSlots.length > i; ++i) {
      metadata.imageSlots[i] = {
        ...metadata.imageSlots[i],
        defaultValue: getImageSlotValue(metadata.imageSlots[i].node),
      };
    }

    for (let i = 0; metadata.textSlots.length > i; ++i) {
      metadata.textSlots[i] = {
        ...metadata.textSlots[i],
        defaultValue: getTextSlotValue(metadata.textSlots[i].node),
      };
    }

    this.designedSvgMetadata.emit(this._designedSvgMetadata);
  }

  private _applyDesignedSvgDataIfReady() {
    if (!this.designedSvgContainer) return;
    if (!this._fetched) return;

    this._applyDesignedSvgData();
  }

  private _applyDesignedSvgManipulationsIfReady() {
    if (!this.designedSvgContainer) return;
    if (!this._fetched) return;

    this._applyDesignedSvgManipulations();
  }

  private _collectSvgMetadata() {
    this._designedSvgMetadata = {
      backgroundSlots: this._getDesignedSvgSlots(
        'minga_bg_',
        'Background ',
        getBgSlotValue,
        () => ({}),
      ),
      imageSlots: this._getDesignedSvgSlots(
        'minga_image_',
        'Image ',
        getImageSlotValue,
        () => ({}),
      ),
      textSlots: this._getDesignedSvgSlots(
        'minga_text_',
        'Text ',
        getTextSlotValue,
        getTextExtraOptions,
      ),
    };

    this.designedSvgMetadata.emit(this._designedSvgMetadata);
  }

  /**
   * @internal
   * Used to slightly adjust the SVG slot nodes to be more consistent
   */
  private _initiateSlotNodes() {
    for (const textSlot of this._designedSvgMetadata.textSlots) {
      const nodeName = textSlot.node.nodeName.toLowerCase();

      // If our text slot is using <text> as it's root node we replace it with
      // a <g> node instead.
      if (nodeName === 'text') {
        const groupNode = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'g',
        );

        groupNode.id = textSlot.node.id;
        textSlot.node.removeAttribute('id');

        textSlot.node.replaceWith(groupNode);
        groupNode.appendChild(textSlot.node);

        textSlot.node = groupNode;
      }

      const textNode = getTextSlotTextElement(textSlot.node);
      if (textNode !== null) {
        if (textNode.hasAttribute('transform')) {
          textSlot.node.setAttribute(
            'transform',
            textNode.getAttribute('transform')!,
          );

          textNode.removeAttribute('transform');
        }
      }

      let rect = textSlot.node.querySelector('rect');
      if (!rect) {
        rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        rect.setAttribute('fill-opacity', '0');
      }

      rect.removeAttribute('x');
      rect.removeAttribute('y');
      rect.removeAttribute('transform');
    }
  }

  private _getTextSlotById(slotId: string): IDesignedSvgSlotMetadataWithNode {
    if (DesignedSvgData.isAdditionalSlot(slotId)) {
      const additionaSlotIndex = DesignedSvgData.getAdditionalSlotIndex(slotId);
      const additionalSlotNode = this._additionalSlotNodes[additionaSlotIndex];
      return {
        defaultValue: '',
        fullId: slotId,
        id: slotId,
        node: additionalSlotNode!,
        inputLabel: `Additional Text #${additionaSlotIndex + 1}`,
      };
    } else {
      for (const textSlot of this._designedSvgMetadata.textSlots) {
        if (textSlot.fullId === slotId) {
          return textSlot;
        }
      }
    }

    throw new Error(`No text slot with id ${slotId}`);
  }

  private _applyDesignedSvgData() {
    for (const slot of this._designedSvgMetadata.textSlots) {
      const slotData = this.designedSvgData[slot.fullId];
      if (slotData || slotData === '') {
        if (DesignedSvgData.isText(slot.fullId, slotData)) {
          setTextSlotValue(slot.node, slotData);
        }
      }
    }

    for (const slot of this._designedSvgMetadata.imageSlots) {
      const slotData = this.designedSvgData[slot.fullId];
      if (slotData || slotData === '') {
        if (DesignedSvgData.isImage(slot.fullId, slotData)) {
          setImageSlotValue(slot.node, slotData);
        }
      }
    }

    if (this._designedSvgMetadata.backgroundSlots.length > 0) {
      const defs = this._getSvgDefs();
      const svg = this.getSvgElementReference();

      for (const slot of this._designedSvgMetadata.backgroundSlots) {
        const slotData = this.designedSvgData[slot.fullId];
        if (slotData || slotData === '') {
          if (DesignedSvgData.isBackground(slot.fullId, slotData)) {
            let num = gradientIdCount.get(slot.id) || 0;
            if (num === 10000) num = 0;
            gradientIdCount.set(slot.id, ++num);
            slot.node = setBgSlotValue(
              slot.node,
              `gradient${num}_${slot.id}`,
              svg,
              defs,
              slotData,
            );
          }
        }
      }
    }

    this._reloadAdditionalSlotNodes();
  }

  getSvgViewportFactor() {
    const svg = this.getSvgElementReference();
    const viewbox = SVG(svg).viewbox();
    return viewbox.width / svg.getBoundingClientRect().width;
  }

  private _reloadAdditionalSlotNodes() {
    if (this.designedSvgData.additionalSlots) {
      const additionalSlots = this.designedSvgData.additionalSlots;

      if (additionalSlots.length > 0) {
        if (!this.designedSvgManipulations) {
          this.designedSvgManipulations = {};
        }

        if (!this.designedSvgManipulations.additionalManipulations) {
          this.designedSvgManipulations.additionalManipulations = [];
        }

        const additionalManipulations =
          this.designedSvgManipulations.additionalManipulations;

        const svg = this.getSvgElementReference();

        let _viewportFactor = 0;
        const viewportFactor = () => {
          if (_viewportFactor) return _viewportFactor;
          const viewbox = SVG(svg).viewbox();

          _viewportFactor = viewbox.width / svg.getBoundingClientRect().width;

          return _viewportFactor;
        };

        for (let i = 0; additionalSlots.length > i; ++i) {
          const slotPair = additionalSlots[i];
          if (!additionalManipulations[i]) {
            additionalManipulations[i] = {};
          }
          const slotManipulation = additionalManipulations[i];
          let slotNode: SVGGElement | SVGImageElement | null = null;
          if (!this._additionalSlotNodes[i]) {
            slotNode = addAdditionalSlotNode(svg, slotPair);
            this._additionalSlotNodes[i] = slotNode;
          } else {
            slotNode = this._additionalSlotNodes[i] as
              | SVGGElement
              | SVGImageElement;
            setAdditionalSlotNodeValue(slotNode, slotPair);
          }

          if (slotNode && slotPair[0] === 'img') {
            const bbox = slotNode.getBBox();

            if (!slotManipulation.w || !slotManipulation.h) {
              const { w, h } = slotManipulation;
              if (!w) {
                slotManipulation.w = Math.max(
                  MIN_IMAGE_SIZE * viewportFactor(),
                  bbox.width * viewportFactor(),
                );
                SVG(slotNode).attr('width', slotManipulation.w);
              }
              if (!h) {
                slotManipulation.h = Math.max(
                  MIN_IMAGE_SIZE * viewportFactor(),
                  bbox.height * viewportFactor(),
                );
                SVG(slotNode).attr('height', slotManipulation.h);
              }
            }
          }
        }

        while (this._additionalSlotNodes.length > additionalSlots.length) {
          const extraAdditionalSlotNode = this._additionalSlotNodes.pop();
          extraAdditionalSlotNode?.remove();
        }
      }
    }
  }

  private _applyDesignedSvgManipulations() {
    if (!this._designedSvgManipulations) return;

    const slots = [
      ...this._designedSvgMetadata.imageSlots,
      ...this._designedSvgMetadata.textSlots,
      ...this._designedSvgMetadata.backgroundSlots,
    ];

    for (const slot of slots) {
      const manipulation = this._designedSvgManipulations[slot.fullId];
      if (manipulation) {
        const sizeNode = SVG(
          getManipulatableSizeSlotNode(slot.fullId, slot.node),
        );
        const transformNode = SVG(
          getManipulatableMatrixSlotNode(slot.fullId, slot.node),
        );
        if (manipulation.w) sizeNode.width(manipulation.w);
        if (manipulation.h) sizeNode.height(manipulation.h);
        if (manipulation.m) {
          transformNode.transform({
            a: manipulation.m[0],
            b: manipulation.m[1],
            c: manipulation.m[2],
            d: manipulation.m[3],
            e: manipulation.m[4],
            f: manipulation.m[5],
          });
        }

        if (DesignedSvgData.isText(slot.fullId)) {
          refreshTextSlot(slot.fullId, slot.node);
        }
      }
    }

    if (this._designedSvgManipulations.additionalManipulations) {
      this._reloadAdditionalSlotNodes();

      const additionalManipulations =
        this._designedSvgManipulations.additionalManipulations || [];
      for (let i = 0; additionalManipulations.length > i; ++i) {
        const manipulation = additionalManipulations[i];
        const slotNode = this._additionalSlotNodes[i];
        const additionalSlot = this.designedSvgData!.additionalSlots![i];
        const slotId = `additionalSlots[${i}]`;
        if (manipulation) {
          const sizeNode = SVG(getManipulatableSizeSlotNode(slotId, slotNode!));
          const transformNode = SVG(
            getManipulatableMatrixSlotNode(slotId, slotNode!),
          );
          if (manipulation.w) sizeNode.width(manipulation.w);
          if (manipulation.h) sizeNode.height(manipulation.h);
          if (manipulation.m) {
            transformNode.transform({
              a: manipulation.m[0],
              b: manipulation.m[1],
              c: manipulation.m[2],
              d: manipulation.m[3],
              e: manipulation.m[4],
              f: manipulation.m[5],
            });
          }

          if (additionalSlot[0] === 'txt') {
            refreshTextSlot(slotId, slotNode!);
          }
        }
      }
    }
  }

  private _getDesignedSvgSlots(
    prefix: string,
    defaultInputLabelPrefix: string,
    getDefaultValue: (node: SVGElement) => DesignedSvgDataValue,
    getExtraOptions: (node: SVGElement) => IDesignedSvgSlotMetadataExtra,
  ): IDesignedSvgSlotMetadataWithNode[] {
    const shadowRoot = this._getSvgContainerShadowRoot();
    const slots: IDesignedSvgSlotMetadataWithNode[] = [];

    const result = document.evaluate(
      `.//*[starts-with(@id,'${prefix}')]`,
      shadowRoot.querySelector('svg')!,
      null,
      XPathResult.ORDERED_NODE_ITERATOR_TYPE,
      null,
    );

    for (let itr = result.iterateNext(); itr; itr = result.iterateNext()) {
      if (!itr) continue;

      const node = itr as SVGElement;
      const fullId = node.id;
      const id = fullId.substr(prefix.length);
      const defaultInputLabel = defaultInputLabelPrefix + id;
      slots.push({
        ...getExtraOptions(node),
        id,
        node,
        fullId,
        inputLabel: node.dataset.mingaInputLabel || defaultInputLabel,
        defaultValue: getDefaultValue(node),
      });
    }

    slots.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }

      return 0;
    });

    return slots;
  }

  private async _fetchSvgSrcIfContainerReady() {
    if (!this.designedSvgContainer) return;

    await this._fetchSvgSrc();
  }

  private async _fetchSvgSrc() {
    if (!this._designedSvgSrc) return;

    const response = await fetch(this._designedSvgSrc);

    if (!response.ok) {
      console.error('<mg-designed-svg> Failed to fetch:', this._designedSvgSrc);
      return;
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType !== 'image/svg+xml') {
      console.warn('Got wrong Content-Type for designed svg:', contentType);
      return;
    }

    const markup = await response.text();

    const shadowRoot = this._getSvgContainerShadowRoot();

    const existingSvg = shadowRoot.querySelector('svg');
    if (existingSvg) shadowRoot.removeChild(existingSvg);

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = markup;
    const svg = tempDiv.firstElementChild! as SVGSVGElement;
    shadowRoot.appendChild(svg);
    this._needsMigration = svg.dataset.mingaSvg !== 'v2';

    this._additionalSlotNodes = [];
    this._collectSvgMetadata();
    this._initiateSlotNodes();
    this._applyDesignedSvgData();
    this._applyDesignedSvgManipulations();
    if (!this._fetched) {
      this._fetched = true;
      this._cdr.markForCheck();
    }

    this.designedSvgReady.emit();
  }

  /**
   * Get the SVG Container ShadowRoot. The ShadowRoot is used to encapsulate the
   * markup that gets injected into the container.
   */
  private _getSvgContainerShadowRoot(): ShadowRoot {
    if (!this._shadowRoot) {
      const element = this.designedSvgContainer!.nativeElement;
      this._shadowRoot = element.attachShadow({ mode: 'closed' });

      const stylesLinkEl = document.createElement('link');
      stylesLinkEl.rel = 'stylesheet';
      stylesLinkEl.href = SHADOW_ROOT_STYLESHEET_HREF;
      this._shadowRoot.appendChild(stylesLinkEl);

      // imported fonts do not work with manual stylesheet loading(injection),
      // so load svg container fonts separately
      this.loadSvgContainerFonts();
    }

    return this._shadowRoot;
  }

  private _getSvgDefs(): SVGDefsElement {
    const svg = this.getSvgElementReference();
    let defs = svg.getElementsByTagName('defs')[0];
    if (!defs) {
      defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
      if (svg.firstChild) {
        svg.insertBefore(defs, svg.firstChild);
      } else {
        svg.appendChild(defs);
      }
    }

    return defs;
  }

  async loadSvgContainerFonts() {
    Promise.all(SVG_CONTAINER_FONTS.map(font => loadFontFamily(font.name)));
  }
}
