import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  CarouselStylePresets,
  DEFAULT_VERT_LIST_TILES,
  presetGridArrayValues,
} from './tiles-carousel.constants';

@Component({
  selector: 'mg-tiles-carousel',
  templateUrl: './tiles-carousel.component.html',
  styleUrls: ['./tiles-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TilesCarouselComponent implements AfterViewInit, OnDestroy {
  /** Child Components */
  @ViewChild('carouselContainer', { static: false })
  private _carouselContainer: ElementRef;
  @ViewChild('tilesContainer', { static: false })
  private _tilesContainer: ElementRef;
  @ViewChildren('tileContent')
  private _tileContent: QueryList<ElementRef>;

  numOfPanels = 0;
  selectedPanelIndex = 0;
  isParsingNgContent = true;
  // allowNgContentObservable = false;
  tiles: HTMLElement[];
  panelRefs: ElementRef[];
  listOfContentPerPanel: HTMLElement[][];
  isInitalLoad = true;
  gridLayoutValues = [0, 1];
  public readonly PRESET = CarouselStylePresets;

  /** General Observables */
  private _destroyed$: Subject<void> = new Subject<void>();
  public layoutStyleSubj = new BehaviorSubject<CarouselStylePresets>(undefined);

  /** Inputs */
  @Input()
  set stylePreset(style: CarouselStylePresets) {
    this.layoutStyleSubj.next(style);
  }
  @Input() isLoading = false;
  @Input() gutter = 16;
  @Input() fxLayoutAlign = 'center center';
  @Input() alignMiddle = false;
  @Input() showPagination = false;
  @Output() panelChange?: EventEmitter<{ tilesPerPanel: number }> =
    new EventEmitter();

  get fxLayoutGap(): string {
    return `${this.gutter}px`;
  }

  get disablePrevious(): boolean {
    return this.selectedPanelIndex === 0;
  }

  get disableNext(): boolean {
    return this.selectedPanelIndex >= this.numOfPanels - 1;
  }

  get animationDuration() {
    return this.isInitalLoad ? '0' : '500ms';
  }

  get isGrid() {
    return (
      this.layoutStyleSubj.value !== CarouselStylePresets.LIST &&
      this.layoutStyleSubj.value !== CarouselStylePresets.VERT_LIST
    );
  }

  /** Component Constructor */
  constructor(private _renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.layoutStyleSubj.pipe(takeUntil(this._destroyed$)).subscribe(style => {
      if (typeof style === 'number') {
        this._initPanelContent();
      }
    });

    setTimeout(() => {
      // Disabling this for now, seems like if this components receive a quick change event this flag
      // is causing an issue where the carousel will not render anything at all
      // this.allowNgContentObservable = true;
      this.isInitalLoad = false;
    }, 100);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    if (!this.isParsingNgContent) {
      this._initPanelContent();
    }
  }

  moveTilesLeft() {
    if (this.selectedPanelIndex > 0) {
      this.selectedPanelIndex--;
    }
  }

  moveTilesRight() {
    if (this.selectedPanelIndex < this.numOfPanels) {
      this.selectedPanelIndex++;
    }
  }

  carouselSwipeLeft(e) {
    this.moveTilesRight();
  }

  carouselSwipeRight(e) {
    this.moveTilesLeft();
  }

  onNgContentChange(changes: MutationRecord[]) {
    // if (!this.allowNgContentObservable) return;
    this.isParsingNgContent = true;
    this._initPanelContent();
  }

  /** Initialize Tiles Carousel Display */
  private _initPanelContent() {
    if (!this._tilesContainer && !this.tiles) return;

    const containerWidth: number =
      this._carouselContainer.nativeElement.offsetWidth;

    // For some reason the container can calculate
    // to zero width initially before re-calculating
    // if we dont check for value > 0 here will get Infinity for numOfPanels
    // basically crashing the app
    // TODO actually fix why we get initial render with zero width
    if (!containerWidth) return;

    this.gridLayoutValues = presetGridArrayValues[this.layoutStyleSubj.value];

    this._setTileElements();

    const firstTile = this.tiles[0];
    if (!firstTile) return;

    const numOfGridTiles = this.gridLayoutValues.reduce(
      (product, value) => product * value,
    );

    const tileWidth = firstTile.offsetWidth + this.gutter;
    const tilesPerPanel =
      this.layoutStyleSubj.value === CarouselStylePresets.VERT_LIST
        ? DEFAULT_VERT_LIST_TILES
        : Math.floor(containerWidth / tileWidth);
    this.numOfPanels = this.isGrid
      ? Math.ceil(this.tiles.length / numOfGridTiles)
      : Math.ceil(this.tiles.length / tilesPerPanel);

    this.listOfContentPerPanel = [];
    const elementList = Array.from(this.tiles);

    this.panelChange.emit({
      tilesPerPanel,
    });

    // divide list of tiles into sublists for each panel
    let index = 0;
    for (let i = 0; i < this.numOfPanels; i++) {
      if (this.isGrid) {
        const subset =
          i === this.numOfPanels - 1
            ? elementList.slice(index, this.tiles.length)
            : elementList.slice(index, index + numOfGridTiles);

        this.listOfContentPerPanel.push(subset);
        index += numOfGridTiles;
      } else {
        const subset =
          i === this.numOfPanels - 1
            ? elementList.slice(index, this.tiles.length)
            : elementList.slice(index, index + tilesPerPanel);

        const lastOfSet = subset[subset.length - 1];
        if (lastOfSet) this._renderer.setStyle(lastOfSet, 'marginRight', 0);
        this.listOfContentPerPanel.push(subset);
        index += tilesPerPanel;
      }
    }

    if (this.isParsingNgContent) {
      this._getPanelRefs();
      this.isParsingNgContent = false;
    } else {
      this._initPanels();
    }
  }

  /** Insert Tiles Into Panels */
  private _initPanels() {
    let index = 0;
    this.panelRefs.forEach(elementRef => {
      const tilesForPanel = this.listOfContentPerPanel[index];
      if (tilesForPanel) {
        tilesForPanel.forEach(tile => {
          this._renderer.appendChild(elementRef.nativeElement, tile);
        });
      }

      ++index;
    });
  }

  /** Get Tab Panel Element References */
  private _getPanelRefs() {
    this._tileContent.changes
      .pipe(takeUntil(this._destroyed$))
      .subscribe(elementRefs => {
        this.panelRefs = elementRefs.toArray();
        this._initPanels();
      });
  }

  /** Retrieve Tiles From Either Ng-Content Or Panels */
  private _setTileElements() {
    if (this.isParsingNgContent) {
      this.tiles = this._tilesContainer.nativeElement.children;
    } else {
      this.tiles = [];
      this.panelRefs.forEach(elementRef => {
        const childElements = elementRef.nativeElement.children;
        this.tiles = [...this.tiles, ...childElements];
      });
      this.tiles.forEach(tile => {
        this._renderer.setStyle(tile, 'marginRight', `${this.gutter}px`);
      });
    }
  }
}
