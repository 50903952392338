<div
  class="mg-file-mini-card"
  [ngClass]="'downloading-' + isDownloadingFile + ' downloaded-' + downloadComplete">
  <a
    [href]="getHrefValue()"
    [title]="getTitleValue()"
    class="file-anchor"
    (click)="onAnchorClick($event)">
    <mg-file-extension-box
      class="inverse-on-hover"
      [ngClass]="extBoxClass"
      [extension]="content.fileExtension"></mg-file-extension-box>
    <div class="content">
      <div class="title mg-bodycopy-bold">
        {{content.name}}
        <mg-ellipsis [mgContentContextMenuClick]="context"></mg-ellipsis>
      </div>

      <div class="subtitle">
        <ng-container *ngIf="content.url"> Link shared </ng-container>
        <ng-container *ngIf="!content.url"> File uploaded </ng-container>
        by
        <span class="author-name"
          >{{content.authorPersonView.displayName}}</span
        >
        <ng-container *ngIf="shouldShowGroupLinkSlot((currentGroup$ | async))">
          <span
            class="group-link-text"
            (click)="groupLinkClick($event)">
            Uploaded to
            <span class="group-link"
              >{{(ownerGroup$ | async)?.name}}
            </span></span
          >
        </ng-container>
      </div>

      <div
        class="subtitle"
        [innerHTML]="timestampString | mgSafeHtml"></div>
    </div>
  </a>
  <div class="file-actions-container">
    <div
      class="mg-mini-button"
      [ngClass]="'show-' + downloadComplete"
      (click)="onFileOpen($event)">
      Open
    </div>
    <ng-container *ngIf="isDownloadingFile">
      <div
        class="cancel-download-link"
        (click)="onFileIndexDownloadAbort($event)">
        Cancel download
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="isDownloadingFile">
    <div class="progress-indicator-wrapper">
      <div
        class="progress-indicator"
        [style.width]="downloadProgress + '%'"></div>
    </div>
  </ng-container>
</div>
