<mat-form-field
  mgFormField
  hideRequiredMarker
  floatLabel="always"
  class="mg-address-line">
  <mat-label><ng-content select="mg-address-label"></ng-content></mat-label>

  <div
    matSuffix
    class="input-actions mg-minicopy input-action"
    *ngIf="editMode && !statusPending && !editing"
    (click)="clickEdit($event)">
    Edit
  </div>
  <div
    matSuffix
    class="input-actions mg-minicopy"
    *ngIf="editMode && !statusPending && editing">
    <span
      class="input-action"
      (click)="onSaveClick($event)"
      [class.disabled]="saveDisabled"
      >Save</span
    >
    <mg-button-icon
      class="input-action icon icon-close"
      icon="close-thin"
      (click)="onCancelClick($event)"
      [no-active]="true"></mg-button-icon>
  </div>
  <input
    #textInput
    matInput
    type="text"
    [placeholder]="placeholder"
    (focus)="onFocus()"
    (keydown)="onKeyDown($event)"
    [formControl]="inputControl"
    [(ngModel)]="displayValue"
    [required]="required" />

  <div
    *ngIf="
      outerFormControl &&
      outerFormControl.touched &&
      outerFormControl.asyncValidator
    "
    class="input-icon"
    mat-icon-button
    matSuffix>
    <mat-icon *ngIf="outerFormControl.valid">done</mat-icon>
    <mat-icon *ngIf="outerFormControl.invalid">error_outline</mat-icon>
    <mg-spinner
      color="primary"
      mode="indeterminate"
      diameter="25"
      *ngIf="outerFormControl.pending">
    </mg-spinner>
  </div>

  <mat-hint>
    <ng-content select="mg-address-hint"></ng-content>
    <ng-container *ngIf="!hintSlot">
      {{ 'input.address.hint' | translate }}
    </ng-container>
  </mat-hint>
</mat-form-field>
