<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="isLoading$ | async"
  [overlayTitle]="modalTitle">
  <ng-container>
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  </ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <ng-container *ngIf="(list$ | async)?.listType === LIST_TYPES.NO_PARTY">
      <ng-container *ngTemplateOutlet="noPartyGroupTemplate"></ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    [fxLayoutAlign]="
      (list$ | async)?.listType === LIST_TYPES.NO_PARTY
        ? 'space-between center'
        : 'end center'
    ">
    <div *ngIf="(list$ | async)?.listType === LIST_TYPES.NO_PARTY">
      <mg-btn
        variant="destructive"
        (pressed)="deleteList()">
        {{ MESSAGES.BUTTON_LABEL_DELETE }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        variant="filled"
        [disabled]="!canSubmit"
        (pressed)="submit()">
        {{
          !list.id
            ? MESSAGES.BUTTON_LABEL_CREATE
            : name.pristine
            ? MESSAGES.BUTTON_LABEL_CLOSE
            : MESSAGES.BUTTON_LABEL_SAVE
        }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<!-- No Party Group Template -->
<ng-template #noPartyGroupTemplate>
  <mg-form-text-input
    label="{{ MESSAGES.NAME_INPUT_LABEL }}"
    [control]="name">
  </mg-form-text-input>
</ng-template>
