<form
  class="landing-form flex-spaced-content"
  fxLayout="column"
  [formGroup]="formGroup"
  (submit)="onSubmit()">
  <div>
    <h1 class="mg-centered landing-title1">
      {{ 'landing.password.createTitle' | translate }}
    </h1>
  </div>

  <mg-password
    mgAutofocus.gt-xs
    class="input-padding mg-minicopy bottom-margin"
    name="password"
    formControlName="password"
    placeholder="Enter your password"
    [mgNoHintMargin]="true"
    [required]="true">
  </mg-password>

  <mg-button
    type="outline"
    class="large"
    [disabled]="formGroup.invalid">
    Next
  </mg-button>
</form>
