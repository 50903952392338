import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { LoaderOverlayComponent } from './LoaderOverlay.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [LoaderOverlayComponent],
  exports: [LoaderOverlayComponent],
})
export class LoaderOverlayModule {}
