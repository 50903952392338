import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  ContentEvents,
  IContentEventGoingErrorEvent,
} from 'minga/app/src/app/minimal/services/ContentEvents';
import { EventContentService } from 'minga/app/src/app/minimal/services/EventContent';
import {
  adjustAllDayEventForDifferentTimezone,
  checkIfAllDayEventIsFromAnotherTimezone,
} from 'minga/app/src/app/util/event';
import { displayTime, isEventOver } from 'minga/app/src/app/util/eventDates';
import { ShortEventCardView } from 'minga/proto/gateway/content_views_pb';
import { isMultiDay } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Component({
  selector: 'mg-event-short-card',
  templateUrl: './MgEventShortCard.element.html',
  styleUrls: ['./MgEventShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgEventShortCardElement implements OnInit, OnDestroy {
  @Input()
  content?: ShortEventCardView.AsObject;

  @Input()
  pinned = false;

  @Input()
  markKeywords = '';

  @Input()
  context = '';

  @Input()
  commentRoles: string[] | null = null;

  isPhotoGalleryEnabled$: Observable<boolean>;

  private _contentEventGoingSub?: Subscription;
  private _contentEventGoingErrorSub?: Subscription;

  groupName?: string;
  longtime = '';
  time = '';

  constructor(
    private eventContentService: EventContentService,
    private contentEvents: ContentEvents,
    private cdr: ChangeDetectorRef,
    private _systemAlertSnackBar: SystemAlertSnackBarService,

    private _settingService: MingaSettingsService,
  ) {
    this.isPhotoGalleryEnabled$ =
      this._settingService.isPhotoGalleryModuleEnabled();
  }

  ngOnInit() {
    if (!this.context) {
      console.error('MgShortEventCard ngOnInit happened without context');
    }

    this._contentEventGoingSub = this.eventContentService
      .observeGoingCountChange(this.context)
      .subscribe(ev => this._onContentEventGoing(ev));

    this._contentEventGoingErrorSub =
      this.contentEvents.onContentEventGoingError
        .pipe(filter(ev => ev.contentContextHash === this.context))
        .subscribe(ev => this._onContentEventGoingError(ev));

    this.time = this.getDateComponent({ day: 'numeric' });
  }

  ngOnDestroy() {
    if (this._contentEventGoingSub) {
      this._contentEventGoingSub.unsubscribe();
    }

    if (this._contentEventGoingErrorSub) {
      this._contentEventGoingErrorSub.unsubscribe();
    }
  }

  private _onContentEventGoing(increment: number) {
    if (this.content) {
      this.content.goingCount = Math.max(
        0,
        this.content.goingCount + increment,
      );
    }

    this.cdr.markForCheck();
  }

  private _onContentEventGoingError(ev: IContentEventGoingErrorEvent) {
    this._systemAlertSnackBar.error(
      'Oops! There was an error trying to do that! Please try again later',
    );
  }

  getDateComponent(options: any): string {
    let time = '';
    let timestamp = this.content?.startTimestamp;
    let moredays = true;
    const multiDay = this.checkMultiDay();
    while (this.content && moredays) {
      const date = new Date(timestamp);

      if (
        this.content.allDay &&
        checkIfAllDayEventIsFromAnotherTimezone(date)
      ) {
        adjustAllDayEventForDifferentTimezone(date);
      }

      time += date.toLocaleString('en-us', options).toUpperCase();
      if (timestamp === this.content.endTimestamp) {
        if (time.length > 4) {
          this.longtime = 'long-day-duration';
        }
        moredays = false;
      } else if (options.day === 'numeric' && multiDay) {
        timestamp = this.content.endTimestamp;
        time += '-';
      } else {
        moredays = false;
      }
    }
    this.cdr.markForCheck();
    return time;
  }

  checkMultiDay() {
    if (this.content) {
      const start = new Date(this.content.startTimestamp);
      const end = new Date(this.content.endTimestamp);

      return isMultiDay(start, end, this.content.allDay);
    }
    return false;
  }

  getStartTime(): string {
    if (this.content && this.content.allDay) {
      return 'All Day';
    }

    if (this.content) {
      return displayTime(this.content.startTimestamp);
    }
    return '';
  }

  enoughGoing(goingCount) {
    return goingCount > 0;
  }

  isEventOver() {
    return isEventOver(
      this.content.startTimestamp,
      this.content.endTimestamp,
      this.content?.allDay,
    );
  }

  goingCountClick(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    this.eventContentService.emitSeeEventPeopleGoing({
      contextHash: this.context,
      content: this.content,
    });
  }

  onGroupNameChange(groupName: string) {
    this.groupName = groupName;
  }
}
