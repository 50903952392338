import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: 'input[mgFocusSelectText]',
  exportAs: 'mgFocusSelectText',
})
export class MgFocusSelectTextDirective implements OnInit, OnDestroy {
  inputText: string = '';

  constructor(private element: ElementRef) {}

  @Input()
  set mgFocusSelectText(value: string) {
    this.inputText = value;
    // reset external value, reset the input's value too
    if (!value) {
      this.element.nativeElement.value = null;
    }
  }

  set inputTouched(value: boolean) {
    if (value) {
      this.element.nativeElement.classList.add('mg-focus-select-dirty');
    } else {
      this.element.nativeElement.classList.remove('mg-focus-select-dirty');
    }
  }

  ngOnInit() {
    this.element.nativeElement.addEventListener(
      'focus',
      this.onFocus.bind(this),
    );
    this.element.nativeElement.addEventListener('blur', this.onBlur.bind(this));
  }

  onFocus(e: FocusEvent) {
    const targetElement: HTMLInputElement = <HTMLInputElement>e.target;
    this.inputTouched = true;
    if (!this.element.nativeElement.value && this.inputText) {
      this.element.nativeElement.value = this.inputText;
    }

    window.requestAnimationFrame(() => {
      targetElement.setSelectionRange(
        0,
        this.element.nativeElement.value.length,
      );
    });
  }

  onBlur() {
    if (
      !this.element.nativeElement.value ||
      this.element.nativeElement.value == this.inputText
    ) {
      this.inputTouched = false;
      if (this.inputText) {
        this.element.nativeElement.value = this.inputText;
      }
    }
  }

  ngOnDestroy() {
    this.element.nativeElement.removeEventListener('focus', this.onFocus);
    this.element.nativeElement.removeEventListener('blur', this.onFocus);
  }
}
