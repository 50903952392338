import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { BadgeDialogService } from 'minga/app/src/app/dialog/BadgeDialog/BadgeDialog.service';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { BadgeDialog } from './BadgeDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgImageModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
    MatDialogModule,
  ],

  declarations: [BadgeDialog],
  exports: [BadgeDialog],
  providers: [BadgeDialogService],
})
export class BadgeDialogModule {}
