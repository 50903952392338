<div class="root-content-wrap left-aligned-screen">
  <section class="container summary-section">
    <ng-container *ngIf="rightCol">
      <ng-container *ngTemplateOutlet="rightCol"></ng-container>
    </ng-container>
  </section>
  <section class="section container">
    <div class="max-width-container main-content">
      <ng-container *ngIf="leftCol">
        <ng-container *ngTemplateOutlet="leftCol"></ng-container>
      </ng-container>
    </div>
  </section>
</div>
