import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MgeIconModule } from 'minga/app/src/app/elements/MgeIcon';

import { MgShortCardModule } from '../MgShortCard.module';

import { MgInspirationShortCardElement } from './MgInspirationShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgeIconModule,
    MgShortCardModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgInspirationShortCardElement],
  exports: [MgInspirationShortCardElement],
})
export class MgInspirationShortCardModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-inspiration-short-card')) {
      //       //   MgInspirationShortCardElement,
      //   {injector},
      // ));
    }
  }
}
