<ng-template #challengeShortCardNoStackTmpl>
  <div class="challenge-swipe-stack-item">
    <ng-container *ngTemplateOutlet="challengeShortCardTmpl;"> </ng-container>
  </div>
</ng-template>

<ng-template #challengeShortCardTmpl>
  <ng-container *ngIf="content">
    <mg-short-card
      #shortCard
      class="challenge-short-card"
      [featuredImage]="content.featuredImage"
      [body]="content.body"
      [likeCount]="content.likeCount"
      [ownerGroupHash]="content.ownerGroupHash"
      [commentList]="content.commentList"
      [commentCount]="content.commentCount"
      [totalCommentCount]="content.totalCommentCount"
      [pinned]="pinned"
      [markKeywords]="markKeywords"
      [commentRoles]="commentRoles"
      [context]="context"
      [mgContentContextMenu]="context"
      [content]="content">
      <mg-short-card-byline>
        <ng-container *ngIf="pinned">
          <mge-icon
            class="pinned-icon"
            icon="pin-o"></mge-icon>
        </ng-container>
      </mg-short-card-byline>

      <mg-short-card-featured-image>
        <mg-image
          class="challenge-featured-image"
          [srcs]="shortCard.featuredImage | mgImageUrls:shortCard.imageSizes"></mg-image>
        <ng-container *ngIf="content.points && content.points > 0">
          <mg-points-banner [points]="content.points"></mg-points-banner>
        </ng-container>
      </mg-short-card-featured-image>

      <mg-short-card-body [class]="color">
        <mg-short-card-group-name>
          <ng-container *ngIf="shortCard.shouldShowGroupLinkSlot()">
            <div
              class="group-container"
              fxLayout="row">
              <div
                class="group-link-container"
                fxFlex
                (click)="shortCard.groupLinkClick($event)">
                <span class="group-icon"></span>
                <span class="group-link-text"
                  >Posted in
                  <span class="group-link">{{shortCard.groupName}}</span></span
                >
              </div>
            </div>
          </ng-container>
        </mg-short-card-group-name>
        <div
          class="ellipsis"
          [mgContentContextMenuClick]="context"
          *ngIf="context">
          <mg-ellipsis></mg-ellipsis>
        </div>

        <div
          class="challenge-title"
          [innerHtml]="content.title"></div>
        <p
          class="due-date"
          *ngIf="dueDate">
          DUE DATE: {{dueDateSubheading}}
        </p>
        <div class="action-container">
          <div
            class="completed-subheading"
            *ngIf="hasResponded">
            <div
              class="challenge-icon"
              [ngClass]="typeStyleClass"></div>
            <p>Completed!</p>
          </div>
          <ng-container *ngIf="hasPermission">
            <div *ngIf="!hasResponded && dueDate && (dueDate >= currentDate)">
              <p class="action-subheading">Ready to do this?</p>
              <button
                mgBubbleBtn
                class="mg-bodycopy response-button">
                <div
                  class="challenge-icon"
                  [ngClass]="typeStyleClass"></div>
                <p>Let's do it!</p>
              </button>
            </div>
          </ng-container>
        </div>
      </mg-short-card-body>

      <mg-short-card-action-bar>
        <div *ngIf="content.responsesSummaryList?.length">
          <div class="avatar-container">
            <hr class="action-divider" />
            <mg-avatar-cluster [items]="avatarClusterItems"></mg-avatar-cluster>
            <hr class="action-divider" />
          </div>
          <p class="people-responded">{{peopleResponsesText}}</p>
          <div class="see-responses">See what they said!</div>
        </div>
      </mg-short-card-action-bar>
    </mg-short-card>
  </ng-container>
</ng-template>

<ng-container *ngIf="responseStream else challengeShortCardNoStackTmpl">
  <mg-stream-swipe-stack
    #streamSwipeStack
    *ngIf="content"
    [dataSource]="responseStream"
    (swipeStackTap)="onSwipeStackTap($event)">
    <div
      *mgStreamSwipeStackHead
      class="challenge-swipe-stack-item">
      <ng-container *ngTemplateOutlet="challengeShortCardTmpl;"> </ng-container>
    </div>

    <div
      *mgStreamSwipeStackLoading
      class="challenge-swipe-stack-item">
      <div class="spinner-container">
        <mg-spinner></mg-spinner>
      </div>
    </div>

    <div
      *mgStreamSwipeStackItem="let item"
      class="challenge-swipe-stack-item">
      <mg-challenge-response-short-card
        [content]="item"
        [themeColor]="content?.color"
        [mgContentContextMenu]="item.contextHash">
      </mg-challenge-response-short-card>
    </div>
  </mg-stream-swipe-stack>
</ng-container>

<ng-container *ngIf="streamSwipeStack">
  <div class="swipe-nav">
    <div
      *ngIf="!streamSwipeStack.showingBottom"
      class="swipe-nav-icon-wrap next">
      <div
        class="swipe-nav-icon mg-cancel-swipe-stack-tap mg-cancel-content-link"
        (tap)="streamSwipeStack?.next()">
        <mg-icon>right-arrow-active</mg-icon>
      </div>
    </div>

    <div
      *ngIf="!streamSwipeStack.showingTop"
      class="swipe-nav-icon-wrap previous">
      <div
        class="swipe-nav-icon mg-cancel-swipe-stack-tap mg-cancel-content-link"
        (tap)="streamSwipeStack?.previous()">
        <mg-icon>left-arrow-active</mg-icon>
      </div>
    </div>
  </div>
</ng-container>
