<ng-container *ngIf="step == 0">
  <div class="header">
    <p class="title mg-headline3">Welcome to {{ mingaName }}</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">We're excited you're here!</p>
  </div>
  <mg-ftue-video videoName="Welcome-FTUE-TeacherWelcome"></mg-ftue-video>
</ng-container>

<ng-container *ngIf="step == 1 && _enableGroups">
  <div class="header">
    <p class="title mg-headline3">Getting Started</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Check out our Getting Started Checklist in the Help Center tab on the
      left-hand side to help you get up and running quickly.
    </p>
  </div>
  <mg-ftue-video videoName="Welcome-FTUE-OrganizeGroups"></mg-ftue-video>
</ng-container>

<ng-container *ngIf="step == stepCount - 2">
  <div class="header">
    <p class="title mg-headline3">Connect Wherever You Are</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Download the Minga app on your Apple or Android device or connect with
      Chrome from your computer.
    </p>
  </div>
  <mg-ftue-video videoName="Welcome-FTUE-Connect"></mg-ftue-video>
</ng-container>

<ng-container *ngIf="step == stepCount - 1">
  <div class="header">
    <p class="title mg-headline3">Let's Get Started</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Update your Profile, download the Minga app, and use the Getting Started
      Checklist.
    </p>
  </div>
  <mg-ftue-video videoName="Welcome-FTUE-LetsGetStartedTeacher"></mg-ftue-video>
</ng-container>

<div class="footer">
  <button
    mgBubbleBtn
    class="mg-headline"
    (click)="next()">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="stepCount - 1">Get Started!</ng-container>
      <ng-container *ngSwitchDefault>Next</ng-container>
    </ng-container>
  </button>

  <div class="mg-centered">
    <mg-step-indicator
      [allowStepClickNavigation]="true"
      [(activeStep)]="step"
      [stepCount]="stepCount"></mg-step-indicator>
  </div>
</div>
