import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GroupPreviewClickMode } from 'minga/app/src/app/groups/components/MgGroupPreview';
import { Group } from 'minga/app/src/app/groups/models';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'mg-parent-group-picker',
  templateUrl: './ParentGroupPicker.component.html',
  styleUrls: ['./ParentGroupPicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParentGroupPickerComponent {
  /**
   * Pass through to MgGroupListComponent.clickMode
   */
  @Input()
  clickMode: GroupPreviewClickMode = GroupPreviewClickMode.GROUP;

  /**
   * Pass through to MgGroupListComponent.noSuccessInterstitial
   */
  @Input()
  noSuccessInterstitial: boolean = false;

  readonly groupsLists$: Observable<{ picked: Group[]; available: Group[] }>;

  constructor(private groupsFacade: GroupsFacadeService) {
    const loadedParentGroups$ = combineLatest([
      this.groupsFacade.getGroupsLoaded(),
      this.groupsFacade.getParentGroups(),
    ]).pipe(
      filter(([loaded]) => loaded),
      map(([, groups]) => groups),
    );

    this.groupsLists$ = loadedParentGroups$.pipe(
      map(groups => ({
        picked: groups.filter(group => !!group.rank),
        available: groups.filter(group => !group.rank),
      })),
    );
  }
}
