import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import * as points_pb from 'minga/proto/points/points_pb';
import { PointReward, PointRewardToUpsert } from 'minga/domain/points';
import { PointsManager } from 'minga/proto/points/points_ng_grpc_pb';
import { PointRewardMapper } from 'minga/shared-grpc/points';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Injectable()
export class PmRewardsService {
  /** Rewards */
  private readonly _rewardTypes = new BehaviorSubject<PointReward[]>([]);
  public readonly rewardTypes$ = this._rewardTypes
    .asObservable()
    .pipe(shareReplay());

  /** Service Constructor */
  constructor(
    private _pointsManager: PointsManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  public async fetchRewardTypes(): Promise<void> {
    try {
      const results = await this._listRewardTypes();
      this._rewardTypes.next(results);
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to fetch reward types');
    }
  }

  public async fetchRewardType(typeId: number): Promise<PointReward> {
    try {
      const result = await this._getRewardType(typeId);
      this._updateExistingRewardType(result);
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error(
        `Failed to fetch reward type with id: ${typeId}`,
      );
    }
  }

  public async createRewardType(
    rewardType: PointRewardToUpsert,
  ): Promise<boolean> {
    try {
      const result = await this._upsertRewardType(rewardType);
      this._rewardTypes.next([result, ...this._rewardTypes.getValue()]);
      return true;
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to create reward type' + error);
      return false;
    }
  }

  public async updateRewardType(
    rewardType: PointRewardToUpsert,
  ): Promise<boolean> {
    try {
      const result = await this._upsertRewardType(rewardType);
      this._updateExistingRewardType(result);
      return true;
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to update reward type' + error);
      return false;
    }
  }

  public async deleteRewardType(id: any): Promise<void> {
    try {
      await this._deleteRewardType(id);
      const rewardTypes = this._rewardTypes.getValue();
      this._rewardTypes.next(rewardTypes.filter(t => t.id !== id));
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to delete reward type');
    }
  }

  public async changeRewardStatus(type: any, status: boolean): Promise<void> {
    try {
      const rewardType = { ...type };
      rewardType.active = status;
      const result = await this._upsertRewardType(rewardType);
      this._updateExistingRewardType(result);
      this._systemAlertSnackBar.success(
        `Successfully set ${rewardType.name} to ${
          status ? 'active' : 'inactive'
        }`,
      );
    } catch (error) {
      this._systemAlertSnackBar.error(`Failed to update reward status`);
    }
  }

  private async _listRewardTypes(activeOnly = false): Promise<PointReward[]> {
    const request = new points_pb.GetPointRewardsRequest();
    request.setActive(activeOnly);
    const response = await this._pointsManager.getPointRewards(request);
    const pointRewardsList = response.getPointRewardsList();
    return pointRewardsList.map(PointRewardMapper.fromProto) as PointReward[];
  }

  private async _getRewardType(id: number): Promise<PointReward> {
    const request = new points_pb.GetPointRewardRequest();
    request.setId(id);
    const response = await this._pointsManager.getPointReward(request);
    const pointReward = response.getPointReward();
    return PointRewardMapper.fromProto(pointReward) as PointReward;
  }

  private async _upsertRewardType(
    rewardType: PointRewardToUpsert,
  ): Promise<PointReward> {
    const request = new points_pb.UpsertPointRewardRequest();
    request.setPointReward(
      PointRewardMapper.toProto(rewardType as PointReward),
    );
    const response = await this._pointsManager.upsertPointReward(request);
    const pointReward = response.getPointReward();
    return PointRewardMapper.fromProto(pointReward) as PointReward;
  }

  private async _deleteRewardType(id: number): Promise<void> {
    const request = new points_pb.DeletePointRewardRequest();
    request.setId(id);
    await this._pointsManager.deletePointReward(request);
  }

  private _updateExistingRewardType(rewardType: PointReward) {
    const rewardTypes = [...this._rewardTypes.getValue()];
    rewardTypes[rewardTypes.findIndex(({ id }) => id === rewardType.id)] =
      rewardType;
    this._rewardTypes.next([...rewardTypes]);
  }
}
