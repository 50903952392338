import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgLikeModule } from 'minga/app/src/app/elements/MgLike';
import { MgIconModule } from 'minga/app/src/app/icon';

import { MgBasicMiniCardElement } from './MgBasicMiniCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgLikeModule,
    MgEllipsisModule,
    MgIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgBasicMiniCardElement],
  exports: [MgBasicMiniCardElement],
})
export class MgBasicMiniCardModule {}
