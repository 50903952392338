<mg-button-icon
  (click)="goBack()"
  class="top left"
  icon="back"></mg-button-icon>

<form
  [formGroup]="formGroup"
  (submit)="onSubmit()"
  class="landing-form flex-spaced-content"
  fxLayout="column"
  autocomplete="on">
  <h1 class="mg-centered landing-title1">Hello again!</h1>

  <!-- So that chrome will save email as well -->
  <input
    tabindex="-1"
    aria-hidden
    type="email"
    name="email"
    class="mg-hidden-no-flow"
    [(ngModel)]="landing.email"
    [ngModelOptions]="{ standalone: true }" />

  <mg-password
    mgAutofocus.gt-xs
    class="input-padding mg-minicopy"
    name="password"
    [formControl]="passwordFormControl"
    placeholder="Enter your password"
    [required]="true"
    [validation]="false"
    [mgNoHintMargin]="true"
    [hints]="false">
  </mg-password>

  <div class="bottom-links-container">
    <mg-button
      type="outline"
      [disabled]="formGroup.invalid">
      Next
    </mg-button>
    <span
      routerLink="/landing/forgot"
      class="textLinkCentered mg-minicopy">
      Forgot Password?
    </span>
  </div>
</form>
