import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { CollapsibleMessages, CollapsibleState } from './collapsible.constants';

/**
 * Collasible
 */
@Component({
  selector: 'mg-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('collapse', [
      state(
        CollapsibleState.OPEN,
        style({
          height: AUTO_STYLE,
          visibility: AUTO_STYLE,
          opacity: AUTO_STYLE,
        }),
      ),
      state(
        CollapsibleState.CLOSED,
        style({ height: '0', visibility: 'hidden', opacity: 0 }),
      ),
      transition(
        `${CollapsibleState.OPEN} <=> ${CollapsibleState.CLOSED}`,
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class CollapsibleComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = CollapsibleMessages;

  /** General Observables */
  public readonly media$: Observable<string>;
  private readonly _destroyed$ = new ReplaySubject<void>(1);

  /** Enabled State */
  private readonly _isEnabled$ = new BehaviorSubject<boolean>(true);
  public readonly isEnabled$ = this._isEnabled$.asObservable();

  /** Collapse State */
  private readonly _collasibleState$ = new BehaviorSubject<CollapsibleState>(
    CollapsibleState.OPEN,
  );
  public readonly collasibleState$ = combineLatest([
    this.isEnabled$,
    this._collasibleState$.asObservable(),
  ]).pipe(
    takeUntil(this._destroyed$),
    map(([enabled, collasibleState]) =>
      enabled ? collasibleState : CollapsibleState.OPEN,
    ),
  );
  public readonly isCollapsed$ = this.collasibleState$.pipe(
    map(val => val === CollapsibleState.CLOSED),
  );

  /** Inputs */
  @Input() collapseText: string;
  @Input() expandText: string;
  @Input() set enabled(val: boolean) {
    this._isEnabled$.next(val);
  }
  @Input()
  set initiallyCollapsed(val: boolean) {
    if (!val) return;
    this._collasibleState$.next(CollapsibleState.CLOSED);
  }

  @Input() btnPosition: 'above' | 'below' = 'below' as const;

  /** Component Constructor */
  constructor(public _mediaObserver: MediaObserver) {
    this.media$ = this._mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._collasibleState$.complete();
    this._isEnabled$.complete();
    this._destroyed$.complete();
  }

  public async toggleCollapseState(): Promise<void> {
    const currentState = this._collasibleState$.getValue();
    this._collasibleState$.next(
      currentState === CollapsibleState.OPEN
        ? CollapsibleState.CLOSED
        : CollapsibleState.OPEN,
    );
  }
}
