import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export default class PrintService {
  /** Service Constructor */
  constructor() {}

  /**
   * Print Image
   *
   * @param src image url to print
   * @param width width value as a css property
   * @link https://developer.mozilla.org/en-US/docs/Web/Guide/Printing#print_an_external_page_without_opening_it
   */
  public printImage(src: string, width?: string) {
    function closePrint() {
      document.body.removeChild(this.__container__);
    }
    function setPrint() {
      this.contentWindow.__container__ = this;
      this.contentWindow.onbeforeunload = closePrint;
      this.contentWindow.onafterprint = closePrint;
      this.contentWindow.focus(); // Required for IE
      this.contentWindow.print();
    }
    const iframe = document.createElement('iframe');
    iframe.style.position = 'fixed';
    iframe.style.right = '0';
    iframe.style.bottom = '0';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    document.body.appendChild(iframe);
    this._createHtml(iframe.contentWindow.document, src, width);
    iframe.onload = setPrint;
  }

  private _createHtml(document: Document, imageSrc: string, width?: string) {
    document.open();
    document.write(
      [
        '<html>',
        '   <head>',
        '   </head>',
        '   <body id="body">',
        '       <img id="image" src="' + imageSrc + '"/>',
        '   </body>',
        '</html>',
      ].join(''),
    );
    const bodyEl = document.getElementById('body');
    bodyEl.style.display = 'flex';
    bodyEl.style.alignItems = 'center';
    bodyEl.style.justifyContent = 'center';
    bodyEl.style.width = '100vw';
    bodyEl.style.height = '100vh';
    if (width) {
      const imageEl = document.getElementById('image');
      imageEl.style.width = width;
    }
    document.close();
  }
}
