<ng-template #streamHeadTpl>
  <div
    *ngIf="contextHash && ('GALLERY_ADD_PHOTO' | hasPermission)"
    class="banner-library-item camera"
    [routerLink]="[
      '',
      { outlets: { o: ['gallery', 'add', 'event', contextHash] } }
    ]">
    <mg-btn
      variant="icon"
      iconSet="minga"
      icon="mg-import">
    </mg-btn>
  </div>
</ng-template>

<mg-stream-scroller
  *ngIf="!!contentHash"
  #streamScroller
  class="banner-library-container"
  rpcStream="streamV2"
  rpcControl="streamV2Control"
  [filter]="filter"
  [service]="galleryService"
  [horizontal]="true"
  [fullWidthOnEmptyStream]="true"
  [hideExtraItemsOnEmptyStream]="true"
  [extraHeadItems]="[streamHeadTpl]">
  <ng-template
    let-item
    let-itemId="itemId"
    mgStreamItem>
    <ng-container *ngIf="item.itemList.length > 0">
      <div
        *ngFor="let tile of item.itemList; let i = index; trackBy: trackBy"
        class="banner-library-item"
        (click)="clickItem(tile)">
        <mg-image
          class="tile-image"
          [srcs]="
            tile.imageInfo
              | mgImageUrls
                : ['blurloading1', ['bannerlibpreview', 'cardbanner']]
          "></mg-image>
      </div>
    </ng-container>
  </ng-template>

  <div mg-stream-empty>
    <div
      class="add-first-image-block"
      *ngIf="
        contextHash && ('GALLERY_ADD_PHOTO' | hasPermission);
        else noAddEmpty
      "
      [routerLink]="[
        '',
        { outlets: { o: ['gallery', 'add', 'event', contextHash] } }
      ]">
      <mg-btn
        variant="icon"
        iconSet="minga"
        icon="mg-import">
      </mg-btn>
      <p class="mg-smallcopy">Click to add your first image</p>
    </div>

    <ng-template #noAddEmpty>
      <mg-empty-state
        #noAddEmpty
        [title]="'emptyState.eventPhotoGallery.title' | translate">
      </mg-empty-state>
    </ng-template>
  </div>
</mg-stream-scroller>
