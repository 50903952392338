<div class="mg-detailed-moderation-result">
  <!-- <template is="dom-if" if="[[showContextMenu(content)]]">
    <mg-ellipsis on-click="clickContextMenu"></mg-ellipsis>
  </template> -->
  <div class="content content-block">
    <div
      class="report-icon"
      [ngClass]="
        ' resolved-'+isResolved(content) +
        ' deleted-'+isDeleted(content) +
        ' reported-'+isReported(content) +
        ' storing-status-'+updateFromStream(content, updateStatusFromStream)">
      <div class="report-icon-wrap">
        <mge-icon
          *ngIf="isReported(content)"
          icon="warning-o"></mge-icon>
        <mge-icon
          *ngIf="isDeleted(content)"
          icon="trash-o"></mge-icon>
        <mge-icon
          *ngIf="isResolved(content)"
          icon="resolved-o"></mge-icon>
      </div>
    </div>
    <div class="inner-content">
      <div
        class="moderated-text-wrap"
        [innerHTML]="moderatedBodyHTML(content.title, showFullBody)"></div>

      <div class="content-author">
        <span class="content-type">{{getContentTypeText(content)}}</span> posted
        by:
        <span class="content-author-name"
          >{{content.contentAuthorFirstName}}
          {{content.contentAuthorLastName}}</span
        >
      </div>
      <div class="report-timestamp">
        <!-- @TODO: Bazel - was the second parameter supposed to cast bool? -->
        {{dateFormat(content.timestamp, !!content.timestamp)}}
      </div>
      <ng-container
        *ngIf="getMingaName(content.mingaName, content.mingaHash, showMinga)">
        <div class="text-minga">
          {{getMingaName(content.mingaName, content.mingaHash, showMinga)}}
        </div>
      </ng-container>
    </div>
    <button
      class="view-button"
      (click)="viewButtonClick($event)">
      View
      <ng-container *ngIf="isReported(content)"> & Resolve </ng-container>
    </button>
  </div>
  <ng-container *ngIf="content.contentHistoryList.length">
    <div
      class="footer"
      [ngClass]="'showing-all-' + showAllHistory">
      <div
        *ngFor="let historyItem of getHistoryList(content, showAllHistory)"
        class="content-history"
        [ngClass]="
          ' resolved-' + isResolved(historyItem) +
          ' deleted-' + isDeleted(historyItem) + 
          ' reported-' + isReported(historyItem)">
        <div class="content-history-row content-block">
          <div>
            <span class="history-item-prefix"
              >{{getHistoryItemPrefixText(historyItem)}}</span
            >
            by:
            <span class="content-author-name"
              >{{historyItem.personDisplayName}}</span
            >
          </div>
          <div class="content-history-center">
            {{getHistoryItemReason(historyItem)}}
          </div>
          <div>{{dateFormat(historyItem.timestamp)}}</div>
        </div>
        <div
          *ngIf="showModerationInfo(historyItem.detailsList)"
          class="moderation-info content-block">
          <ng-container *ngFor="let detailItem of historyItem.detailsList">
            <div
              *ngIf="detailItem.fileurl && showItemTags(detailItem)"
              class="moderation-info-item"
              [ngClass]="'image-link-' + detailItem.fileurl"
              (click)="openContentImage(detailItem)">
              <span
                *ngFor="let tagItem of detailItem.tagsList"
                class="provider-item-link"
                >{{tagItem.key}}</span
              >
              <span class="provider-item-link"
                >[{{displayHumanIndex(detailItem.index)}}]</span
              >
            </div>

            <div
              *ngIf="!detailItem.fileurl"
              class="moderation-info-item text-tags"
              (click)="openContentImage(detailItem)">
              <span
                *ngFor="let tagItem of detailItem.tagsList"
                class="provider-item"
                >{{tagItem.key}}</span
              >
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div
      *ngIf="getShowBottomFooterLink(content)"
      class="footer-show-link-container content-block"
      (click)="toggleShowAllHistory($event)">
      <ng-container *ngIf="!showAllHistory">
        Show All {{content.contentHistoryList.length}}
      </ng-container>
      <ng-container *ngIf="showAllHistory"> Show Less </ng-container>
    </div>
  </ng-container>
</div>
