<button
  #button
  type="button"
  class="generic-chip"
  matRipple
  matRippleColor="rgba(255, 255, 255, .10)"
  [matRippleDisabled]="disabled"
  [disabled]="disabled"
  [ngClass]="classes"
  [attr.data-analytics]="id"
  [attr.data-test]="id">
  <mg-icon
    *ngIf="icon || (active && showCheckmark)"
    class="icon icon-left"
    [ngClass]="iconColor ? 'app-color-' + iconColor : ''"
    [iconName]="active && showCheckmark ? 'check-mark' : icon"></mg-icon>

  <div class="text-container">
    <span class="text-main">
      <ng-content></ng-content>
    </span>
    <span
      class="text-sub"
      *ngIf="subTitle">
      {{ subTitle }}
    </span>
  </div>

  <mg-icon
    icon
    *ngIf="iconRight"
    class="icon-right"
    [iconName]="iconRight"></mg-icon>
</button>
