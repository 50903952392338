<div class="mg-textarea-wrap">
  <div
    *ngIf="placeholder && !textarea.textContent.length"
    class="textarea textarea-placeholder">
    {{ placeholder }}
  </div>

  <div
    #textarea
    class="textarea editable"
    [class.disabled]="disabled"
    contenteditable="true"
    (focus)="focusOutput.emit($event)"
    (blur)="blurOutput.emit($event)"
    (keydown)="onKeydown($event)"
    (paste)="onPaste($event)"
    [style.minHeight]="minHeight"></div>
</div>
