<ng-container *ngIf="isOverlay">
  <ng-container *ngTemplateOutlet="legacyOverlay"></ng-container>
</ng-container>
<ng-container *ngIf="!isOverlay">
  <ng-container *ngTemplateOutlet="modalOverlay"></ng-container>
</ng-container>

<ng-template #legacyOverlay>
  <div
    *mgOverlayStructure="true; region: 'top'"
    #overlayNavTop>
    <mg-overlay-nav-item
      class="mg-align-left"
      icon="close"
      (click)="onOverlayClose()">
      Close
    </mg-overlay-nav-item>
    <mg-overlay-nav-item
      *ngIf="listId"
      class="mg-align-right"
      icon="mg-export"
      (click)="exportMembershipList()">
      Export list
    </mg-overlay-nav-item>
  </div>
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
</ng-template>

<ng-template #modalOverlay>
  <!-- Main Overlay -->
  <mg-overlay-primary
    size="auto"
    [overlayTitle]="'View Members'"
    [isLoading]="false">
    <ng-container *ngTemplateOutlet="mainContent"></ng-container>
  </mg-overlay-primary>
</ng-template>

<ng-template #mainContent>
  <div [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <div
      fxLayout="row"
      fxLayoutAlign="end center">
      <ng-container *ngIf="!isOverlay">
        <mg-generic-button
          colorTheme="navy"
          variant="clear"
          size="small"
          paddingSize="none"
          iconLeft="mg-export"
          [hasRipples]="false"
          [isRounded]="false"
          (onClick)="exportMembershipList()">
          Export list
        </mg-generic-button>
      </ng-container>
    </div>
    <ng-container *ngIf="members$ | async as members; else loadingTpl">
      <div class="person-list">
        <div
          *ngFor="let person of members"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="member-info">
          <div class="person-wrap">
            <mg-person-minimal
              [person]="person"
              class="no-wrap"></mg-person-minimal>
          </div>
        </div>
        <div
          *ngIf="members.length == 0"
          class="member-info">
          No one has been added to this list yet.
        </div>
      </div>
    </ng-container>

    <ng-template #loadingTpl>
      <mg-spinner></mg-spinner>
    </ng-template>
  </div>
</ng-template>
