import { Component, forwardRef, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const MG_COUNTRY_SELECTOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CountrySelectorComponent),
  multi: true,
};

@Component({
  selector: 'mg-country-selector',
  templateUrl: './CountrySelector.component.html',
  styleUrls: ['./CountrySelector.component.scss'],
  providers: [MG_COUNTRY_SELECTOR_VALUE_ACCESSOR],
})
export class CountrySelectorComponent implements ControlValueAccessor {
  private _onChange: any;
  private _onTouched: any;
  value: string = '';

  triggerOnChange() {
    if (this._onChange) {
      this._onChange(this.value);
    }
  }

  triggerOnTouched() {
    if (this._onTouched) {
      this._onTouched();
    }
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  writeValue(value: any) {
    if (value === null) {
      this.value = '';
    } else if (typeof value === 'string') {
      this.value = value;
    }
  }
  _setValue(value: any) {
    this.writeValue(value);
    this.triggerOnChange();
    this.triggerOnTouched();
  }
}
