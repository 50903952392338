import { CommonModule } from '@angular/common';
import { forwardRef, NgModule } from '@angular/core';
import { VirtualScrollerModule } from '@minga/ngx-virtual-scroller';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { MgStreamScrollerComponent } from './MgStreamScroller.component';
import { MgStreamScrollerItemDirective } from './MgStreamScrollerItem.directive';
import { MgTableStreamHeaderDirective } from './MgTableStreamHeader.directive';
import { MgTableStreamScrollerComponent } from './MgTableStreamScroller.component';
import { StreamControlDirective } from './StreamControl.directive';

export {
  MgStreamScrollerComponent,
  MgStreamScrollerItemDirective,
  MgTableStreamHeaderDirective,
  MgTableStreamScrollerComponent,
  StreamControlDirective,
};

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,

    // External dependencies
    CommonModule,
    VirtualScrollerModule,
  ],
  declarations: [
    MgStreamScrollerComponent,
    MgTableStreamScrollerComponent,
    MgStreamScrollerItemDirective,
    MgTableStreamHeaderDirective,
    StreamControlDirective,
  ],
  exports: [
    MgStreamScrollerComponent,
    MgTableStreamScrollerComponent,
    MgStreamScrollerItemDirective,
    MgTableStreamHeaderDirective,
    StreamControlDirective,
  ],
  providers: [],
})
export class MgStreamScrollerModule {}
