<mg-report-table
  matSort
  [dataSourceService]="ds"
  (matSortChange)="ds.sort($event)">
  <mg-history-column
    [datasource]="ds"
    [dropdown]="historyDropDown"></mg-history-column>
  <mg-summary-column [datasource]="ds"></mg-summary-column>
  <mg-role-column [datasource]="ds"></mg-role-column>
</mg-report-table>
