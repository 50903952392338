import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';

import { Navbar } from './Navbar.component';

@NgModule({
  imports: [FlexLayoutModule, MatToolbarModule],
  declarations: [Navbar],
  exports: [Navbar],
})
export class NavbarModule {}
