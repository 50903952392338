<section
  fxLayout="column"
  fxLayout.gt-sm="row"
  class="container">
  <!-- Title / Breadcrumbs -->
  <div
    *ngIf="navigation.headerTitleType$ | async as headerTitleType"
    class="label-container"
    [ngClass]="headerTitleType">
    <mg-text
      *ngIf="headerTitleType === 'title'; else breadcrumb"
      variant="header-xl-secondary">
      {{ navigation.primaryTitle$ | async }}
    </mg-text>
    <ng-template #breadcrumb>
      <ng-container
        [ngTemplateOutlet]="breadcrumbTemplate"
        [ngTemplateOutletContext]="{
          $implicit: breadcrumbs || (breadcrumbService.breadcrumb$ | async)
        }">
      </ng-container>
    </ng-template>
  </div>

  <!-- Main secondary navigation -->
  <div class="secondary-nav">
    <div class="overflow-shadow">
      <nav
        #scrollableContainer
        *ngIf="
          menu || (navigation.secondaryNavigationRoutes$ | async) as routes
        "
        (mousedown)="onMouseDown($event)"
        (mouseleave)="onMouseLeave()"
        (mouseup)="onMouseUp()"
        (mousemove)="onMouseMove($event)"
        aria-label="Secondary Navigation">
        <ul class="main">
          <ng-container *ngFor="let r of routes; trackBy: trackBy">
            <li *ngIf="r.canAccess$ | async">
              <!-- Button for nested routes -->
              <ng-container *ngIf="r.children; else link">
                <button
                  [attr.title]="r.description"
                  [attr.aria-label]="r.title"
                  [class.active]="router.isActive(r.path, false)"
                  [matMenuTriggerFor]="dropdownMenu">
                  {{ r.title }}
                  <mg-icon>mg-drop-down-arrow</mg-icon>
                </button>
                <!-- Drop down menu -->
                <mat-menu
                  #dropdownMenu="matMenu"
                  xPosition="after">
                  <ul
                    *ngFor="let child of r.children; trackBy: trackBy"
                    class="sub">
                    <li *ngIf="child.canAccess$ | async">
                      <a
                        [attr.aria-label]="child.title"
                        [routerLink]="child.path"
                        [attr.data-test]="'secondary-nav-item'"
                        mat-menu-item
                        fxLayoutAlign="start center">
                        <mg-text variant="body">{{ child.title }}</mg-text>

                        <mg-tag
                          *ngIf="child.isBeta"
                          class="beta-tag"
                          color="green"
                          size="small">
                          Beta
                        </mg-tag>
                      </a>
                    </li>
                  </ul>
                </mat-menu>
              </ng-container>
              <!-- Standard link -->
              <ng-template #link>
                <a
                  [attr.title]="r.description"
                  [attr.aria-label]="r.title"
                  [routerLink]="r.path"
                  [attr.data-test]="'secondary-nav-item'"
                  [class.active]="router.isActive(r.path, r.exactPathMatching)">
                  {{ r.title }}

                  <mg-tag
                    *ngIf="r.isBeta"
                    class="beta-tag"
                    color="green"
                    size="small">
                    Beta
                  </mg-tag>
                </a>
              </ng-template>
            </li>
          </ng-container>
        </ul>
      </nav>
    </div>
  </div>

  <!-- Utilities action -->
  <ng-container
    *ngIf="showUtilityNav"
    [ngTemplateOutlet]="utilitiesActionMenuTemplate">
  </ng-container>
</section>

<!-- Breadcrumb template -->
<ng-template
  #breadcrumbTemplate
  let-routes>
  <nav
    *ngIf="routes?.length > 0"
    aria-label="Breadcrumb Navigation"
    class="breadcrumb">
    <ul>
      <li *ngFor="let route of routes; trackBy: trackBy; let lastItem = last">
        <ng-container *ngIf="lastItem; else link">
          <mg-text variant="body-sm-secondary">
            {{ route.title }}
          </mg-text>
        </ng-container>

        <ng-template #link>
          <a
            [attr.aria-label]="route.title"
            [routerLink]="route.path"
            routerLinkActive="active">
            <mg-text variant="body-sm">
              {{ route.title }}
            </mg-text>
          </a>
          <mg-text variant="body-sm">/</mg-text>
        </ng-template>
      </li>
    </ul>
  </nav>
</ng-template>

<!-- Utilities action menu template -->
<ng-template #utilitiesActionMenuTemplate>
  <div class="utility-actions-container">
    <nav
      aria-label="Utility Nav"
      class="utility-actions-nav">
      <!-- Search -->
      <div *ngIf="globalSearch.scope$ | async as scope">
        <mg-btn
          variant="icon"
          iconSet="minga"
          icon="mg-search"
          (pressed)="globalSearch.open(scope)">
        </mg-btn>
      </div>
      <!-- Direct messages -->
      <div *ngIf="isDmModuleEnabled$ | async">
        <div
          *ngIf="hasUnreadMessages$ | async"
          class="dot"></div>
        <mg-btn
          (pressed)="restartStream('ConversationPreviewFeed')"
          routerLink="/messaging"
          variant="icon"
          iconSet="minga"
          icon="mg-chat">
        </mg-btn>
      </div>
      <!-- Notifications -->
      <div
        *ngIf="
          ('STUDENT_TOOLS' | hasPermission) ||
          ('TEACHER_TOOLS' | hasPermission) ||
          ('IS_PARENT' | hasPermission)
        ">
        <div
          *ngIf="badgeManager.onDot('notification') | async"
          class="dot"></div>
        <mg-btn
          routerLink="/tools/notifications"
          variant="icon"
          iconSet="minga"
          icon="notification">
        </mg-btn>
      </div>
    </nav>
  </div>
</ng-template>
