<div fxLayout="row">
  <div class="mg-avatar-wrap">
    <mg-avatar
      [src]="avatarUrl$ | async"
      [color]="color$ | async"
      [borderless]="borderless">
    </mg-avatar>
    <mg-avatar class="placeholder"></mg-avatar>
  </div>
  <p
    *ngIf="!noDisplayName"
    class="display-name mg-minicopy">
    {{ displayName$ | async }}
  </p>
</div>
