import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ModerationModule } from '@shared/components/moderation';

import { SuperAdminComponent } from './super-admin.component';
import { SUPER_ADMIN_ROUTE } from './super-admin.routes';

@NgModule({
  imports: [
    // Routing
    RouterModule.forChild(SUPER_ADMIN_ROUTE),

    // Minga dependencies
    ModerationModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [SuperAdminComponent],
  exports: [RouterModule],
})
export class SuperAdminModule {}
