<mg-button-icon
  (click)="close()"
  class="top left"
  icon="close"></mg-button-icon>

<p class="mg-dialog message dialog-title">
  Would you like to keep this as a draft?
</p>

<div class="footer-button-container">
  <mg-button
    type="text"
    (click)="discard()"
    >{{ 'button.discard' | translate }}</mg-button
  >
  <mg-button-outline
    (click)="keep()"
    [disabled]="disableSaveButton">
    {{ 'button.keepDraft' | translate }}
  </mg-button-outline>
</div>
