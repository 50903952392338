import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { IAuthPerson } from 'minga/domain/auth';

import { ToolType } from '@modules/tools/tools.types';

@Component({
  selector: 'mg-tools-header',
  templateUrl: './tools-header.component.html',
  styleUrls: ['./tools-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsHeaderComponent {
  @Input() user: IAuthPerson;

  @Input() type: ToolType;

  constructor(public router: Router) {}
}
