export enum PointsManagerMessages {
  PAGE_TITLE = 'Points Manager',
  PAGE_TITLE_TEAMS = 'Teams',
  PAGE_TITLE_REPORTS = 'Reports',
  PAGE_TITLE_REWARDS = 'Rewards',
  PAGE_TITLE_SETTINGS = 'Settings',
  PAGE_HEADER_TITLE = 'Points',
}

export enum PointsManagerRoutes {
  ROOT = 'points-manager',
  TEAMS = 'teams',
  REPORTS = 'reports',
  REWARDS = 'rewards',
  SETTINGS = 'settings',
}
