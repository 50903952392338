import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  CountryComponent,
  CountryModule,
} from 'minga/app/src/app/components/Country';

import { CountrySelectorComponent } from './CountrySelector.component';

@NgModule({
  imports: [FormsModule, CommonModule, CountryModule],
  declarations: [CountrySelectorComponent],
  exports: [CountrySelectorComponent, CountryComponent],
})
export class CountrySelectorModule {}
