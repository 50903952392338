import { MingaAppRoutes } from 'src/app/types';

import { CountryGuard, KioskNonAuthedGuard } from '@shared/guards';

import { LandingCountryComponent } from './components/landing-country/landing-country.component';
import { LandingCreateComponent } from './components/landing-create/landing-create.component';
import { LandingEmailComponent } from './components/landing-email/landing-email.component';
import { LandingForgotComponent } from './components/landing-forgot/landing-forgot.component';
import { LandingJoiningComponent } from './components/landing-joining/landing-joining.component';
import { LandingKioskLoginComponent } from './components/landing-kiosk-login/landing-kiosk-login.component';
import { LandingPasswordComponent } from './components/landing-password/landing-password.component';
import { LandingPinComponent } from './components/landing-pin/landing-pin.component';
import { LandingResetComponent } from './components/landing-reset/landing-reset.component';
import { LandingRoute } from './constants';
import { LandingHasEmail, LandingHasPin } from './guards';
import { LandingComponent } from './landing.component';

export const LANDING_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: LandingComponent,
    canActivate: [KioskNonAuthedGuard],
    data: {
      landingTitle: 'Welcome to',
      landingSlogan: 'Creating Amazing Schools.',
      landingLogo: true,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: LandingRoute.EMAIL,
      },
      {
        path: LandingRoute.COUNTRY,
        component: LandingCountryComponent,
        data: {
          landingTitle: 'Welcome to',
          landingSlogan: 'Creating Amazing Schools.',
          landingLogo: true,
        },
      },
      {
        path: LandingRoute.EMAIL,
        component: LandingEmailComponent,
        canActivate: [CountryGuard],
        data: {
          landingTitle: 'Welcome to',
          landingSlogan: 'Creating Amazing Schools.',
          landingLogo: true,
        },
      },
      {
        path: LandingRoute.CLEVER,
        component: LandingEmailComponent,
        canActivate: [CountryGuard],
        data: {
          landingTitle: 'Welcome to',
          landingSlogan: 'Creating Amazing Schools.',
          landingLogo: true,
        },
      },
      {
        path: LandingRoute.PIN,
        canActivate: [LandingHasEmail],
        component: LandingPinComponent,
        data: {
          landingTitle: 'Welcome to',
          landingSlogan: 'Creating Amazing Schools.',
          landingLogo: true,
        },
      },
      {
        path: LandingRoute.PIN_RESET,
        component: LandingPinComponent,
        data: {
          landingTitle: 'Welcome to',
          landingSlogan: 'Creating Amazing Schools.',
          landingLogo: true,
        },
      },
      {
        path: LandingRoute.JOINING,
        component: LandingJoiningComponent,
        data: {
          landingTitle: '',
          landingSlogan: '',
          landingLogo: false,
          extraTopMargin: true,
        },
      },
      {
        path: LandingRoute.PASSWORD,
        component: LandingPasswordComponent,
        canActivate: [LandingHasEmail],
        data: {
          landingTitle: '',
        },
      },
      {
        path: LandingRoute.CREATE,
        component: LandingCreateComponent,
        canActivate: [LandingHasPin, LandingHasEmail],
        data: {
          landingTitle: '',
        },
      },
      {
        path: LandingRoute.CREATE_RESET,
        component: LandingCreateComponent,
        data: {
          landingTitle: '',
        },
      },
      {
        path: LandingRoute.FORGOT,
        component: LandingForgotComponent,
        data: {
          landingTitle: 'Welcome to',
          landingSlogan: 'Creating Amazing Schools.',
          landingLogo: true,
        },
      },
      {
        path: LandingRoute.RESET,
        component: LandingResetComponent,
        data: {
          landingTitle: '',
          landingSlogan: '',
          landingLogo: true,
        },
      },
    ],
  },
  {
    path: LandingRoute.KIOSK_LOGIN,
    component: LandingKioskLoginComponent,
    canActivate: [KioskNonAuthedGuard],
    data: {
      title: 'Kiosk Login',
    },
  },
];
