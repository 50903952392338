/**
 * Used to launch the application under Bazel production mode.
 */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from 'minga/app/src/app/app.module';

import './config';
import { fetchEnvironmentConfig } from './environment';
import { buildEnvironment } from './environment/build';

fetchEnvironmentConfig().then(() => {
  if (buildEnvironment.production) {
    enableProdMode();
    Sentry.init({
      dsn: 'https://f3a85be85f3c40c8945bd59d39d73a0d@o1374651.ingest.sentry.io/6682092',
      integrations: [
        new BrowserTracing({
          tracingOrigins: [
            'https://api.stg.minga.io',
            'https://api.us.minga.io',
          ],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.05,
    });
  }
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
