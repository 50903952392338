export enum FlexTimeManagerMessages {
  PAGE_TITLE = 'FlexTime Manager',
  PAGE_TITLE_DASHBOARD = 'Dashboard',
  PAGE_TITLE_REPORTS = 'Reports',
  PAGE_TITLE_PERIODS = 'Periods',
  PAGE_TITLE_ACTIVITIES = 'Activities',
  PAGE_TITLE_SETTINGS = 'Settings',
  PAGE_TITLE_ACTIVITY_TEMPLATES = 'Activity Templates',
  PAGE_HEADER_TITLE = 'FlexTime',
}

export enum FlexTimeManagerRoutes {
  ROOT = 'flextime',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  PERIODS = 'periods',
  ACTIVITIES = 'activities',
  SETTINGS = 'settings',
  ACTIVITY_TEMPLATES = 'activity-templates',
}

export enum FlexTimeReportsRoutes {
  HISTORY = 'flex_period_registered',
  UNREGISTERED = 'flex_period_unregistered',
}
