import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import {
  MINGA_COLOR_PALETTE,
  MingaColors,
  MingaColorsType,
} from '@shared/constants/mingaColors';
import { MingaColorsPalette } from '@shared/types/mingaColors';

import {
  GenericButtonSizes,
  GenericButtonTypes,
  GenericButtonVariants,
} from './generic-button.types';

/**
 * Generic Button
 * @deprecated - use mg-btn
 *
 * Generic Button Component. This is a purely presentional component.
 * Do not put any logic in this component.
 */
@Component({
  selector: 'mg-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericButtonComponent implements OnChanges {
  /** Child Components */
  @ViewChild('buttonElement', { static: true })
  buttonElement?: ElementRef;

  /** Inputs */
  @Input() colorTheme: MingaColorsType;
  @Input() variant: GenericButtonVariants = 'primary';
  @Input() size: GenericButtonSizes = 'medium';
  @Input() isWide: boolean;
  @Input() iconLeft: string;
  @Input() icon: string;
  @Input() iconRight: string;
  @Input() isDisabled: boolean;
  @Input() isActive: boolean;
  @Input() isLoading: boolean;
  @Input() isRounded = true;
  @Input() hasRipples = true;
  @Input() paddingSize: GenericButtonSizes | 'none' = this.size;
  @Input() type: GenericButtonTypes = 'button';
  @Input() attrType: 'button' | 'submit' = 'button';
  @Input() attrFormId = '';
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  /** Outputs */
  @Output() onClick = new EventEmitter<any>();

  /** Styles */
  private _styleVariables: Record<string, any>;
  public get styleVariables() {
    return this._styleVariables;
  }
  public get styleClasses(): Record<string, boolean> {
    return {
      rounded: this.isRounded,
      disabled: this.isDisabled,
      [`variant-${this.variant}`]: true,
      [`size-${this.size}`]: true,
      [`padding-size-${this.paddingSize}`]: true,
      wide: this.isWide,
      noRipples: !this.hasRipples,
    };
  }

  /** Component Constructor */
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.colorTheme?.currentValue) {
      const colorTheme = changes?.colorTheme.currentValue;
      const colorFromMap =
        MINGA_COLOR_PALETTE[colorTheme] ||
        MINGA_COLOR_PALETTE[MingaColors.BLUE];
      this._makeColorPalette(colorFromMap);
    }
  }

  private _makeColorPalette(ColorsPalette: MingaColorsPalette) {
    const states = ColorsPalette;
    const result = {};
    Object.keys(states).forEach(state => {
      const { color, fontColor } = states[state];
      result[`--color-${state}`] = color;
      result[`--font-color-${state}`] = fontColor;
    });
    this._styleVariables = result;
  }

  public async handleOnClickButton(event: MouseEvent): Promise<void> {
    if (this.isDisabled) return;
    this.onClick.emit();
  }
}
