import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BannerHeadlineComponent } from './BannerHeadline.component';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    CommonModule,
  ],
  declarations: [BannerHeadlineComponent],
  exports: [BannerHeadlineComponent],
})
export class BannerHeadlineModule {}
