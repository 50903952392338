import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgButtonModule } from 'minga/app/src/app/button';
import { StepIndicatorModule } from 'minga/app/src/app/components/StepIndicator';
import { DefaultParentGroupPickerModule } from 'minga/app/src/app/groups/containers/DefaultParentGroupPicker';
import { PermissionsModule } from 'minga/app/src/app/permissions';

import { WelcomeDistrictSchoolSelectComponent } from './WelcomeDistrictSchoolSelect.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    StepIndicatorModule,
    DefaultParentGroupPickerModule,
    PermissionsModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [WelcomeDistrictSchoolSelectComponent],
  exports: [WelcomeDistrictSchoolSelectComponent],
})
export class WelcomeDistrictSchoolSelectModule {}
