import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mg-photo-gallery-add-photo',
  templateUrl: './PhotoGalleryAddPhoto.component.html',
  styleUrls: ['./PhotoGalleryAddPhoto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoGalleryAddPhotoComponent {
  @Input()
  text: string = `Add to<br>Gallery`;

  constructor() {}
}
