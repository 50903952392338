import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

import { BehaviorSubject } from 'rxjs';

import { SystemAlertSnackBarData } from '../system-alert/types';

@Component({
  selector: 'mg-system-alert-snackbar',
  templateUrl: './system-alert-snackbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SystemAlertSnackbarComponent {
  /** Constants */
  public readonly DISMISSABLE_TYPES = ['warning', 'error'];

  /** Behavior Subjects */
  private readonly _showComponentSubj = new BehaviorSubject<boolean>(true);
  public showComponent$ = this._showComponentSubj.asObservable();

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SystemAlertSnackBarData,
    private _snackBar: MatSnackBar,
  ) {}

  public close() {
    this._showComponentSubj.next(false);
    this._snackBar.dismiss();
    this.data.action?.onClick?.();
  }
}
