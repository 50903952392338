import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgIconModule } from 'src/app/icon';

import { AdvancedTooltipComponent } from './advanced-tooltip.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,

    // External dependencies

    CommonModule,
    FlexLayoutModule,
  ],
  exports: [AdvancedTooltipComponent],
  declarations: [AdvancedTooltipComponent],
})
export class AdvancedTooltipModule {}
