import { Injector, NgModule } from '@angular/core';

import { MgDateBadgeElement } from './MgDateBadge.element';

@NgModule({
  declarations: [MgDateBadgeElement],
  exports: [MgDateBadgeElement],
})
export class MgDateBadgeModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-date-badge')) {
      //       //   MgDateBadgeElement,
      //   {injector},
      // ));
    }
  }
}
