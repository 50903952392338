<div class="mg-pill-checkbox">
  <div class="mg-pill-checkbox-content">
    <ng-content></ng-content>
  </div>
  <div
    class="mg-pill-checkbox-checkmark"
    #pillCheckmark>
    <mat-icon>{{ icon }}</mat-icon>
  </div>
</div>
