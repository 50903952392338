import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FormModule } from '@shared/components/form';

import { SelectFieldsModule } from '../select-fields';
import { TextModule } from '../text';
import { TooltipModule } from '../tooltip';
import { SettingNumberTextboxComponent } from './components/setting-number-textbox';
import { SettingSelectComponent } from './components/setting-select';
import { SettingToggleComponent } from './components/setting-toggle/setting-toggle.component';

@NgModule({
  declarations: [
    SettingToggleComponent,
    SettingNumberTextboxComponent,
    SettingSelectComponent,
  ],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    SelectFieldsModule,
    FormModule,
    TooltipModule,
    TextModule,
  ],
  exports: [
    SettingToggleComponent,
    SettingNumberTextboxComponent,
    SettingSelectComponent,
  ],
})
export class SettingsModule {}
