import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MgVideoPlayerIconModule } from 'minga/app/src/app/elements/MgVideoPlayerIcon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { MgShortCardModule } from '../MgShortCard.module';

import { MgVideoShortCardElement } from './MgVideoShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgVideoPlayerIconModule,
    MgShortCardModule,
    MgSpinnerModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgVideoShortCardElement],
  exports: [MgVideoShortCardElement],
})
export class MgVideoShortCardModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-video-short-card')) {
      //       //   MgVideoShortCardElement,
      //   {injector},
      // ));
    }
  }
}
