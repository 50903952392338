import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from 'src/app/minimal/services/AppConfig';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

@Component({
  selector: 'mg-mm-subscription-upgrade-modal',
  templateUrl: './mm-subscription-upgrade-modal.component.html',
  styleUrls: ['./mm-subscription-upgrade-modal.component.scss'],
})
export class MmSubscriptionUpgradeModalComponent {
  // Subjects and Observables
  public readonly hubspotUrl$ = of(
    this._appConfig.getValue('moduleUpgradeHubspotUrl'),
  ).pipe(
    map(url =>
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        url + this.hubspotUrlParams,
      ),
    ),
  );

  constructor(
    private _domSanitizer: DomSanitizer,
    private _authInfo: AuthInfoService,
    private _appConfig: AppConfigService,
  ) {}

  get hubspotUrlParams() {
    const person = this._authInfo.authPerson;
    return `?embed=true&email=${person.email}&firstName=${person.firstName}&lastName=${person.lastName}`;
  }
}
