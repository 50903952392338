<mg-layout-legacy-content>
  <ng-container
    *ngIf="conversations$ | async as conversations; else loadingTpl">
    <ng-container *ngIf="conversations.length > 0; else noConversations">
      <div
        *ngFor="let item of conversations; trackBy: trackByFn"
        class="item">
        <mg-dm-list-item
          [data]="item"
          [readStatus]="item.readStatus"
          (click)="
            clickConversationPreview.emit({ event: $event, data: item })
          ">
        </mg-dm-list-item>
      </div>
    </ng-container>

    <ng-template #noConversations>
      <mg-empty-state
        [title]="'No conversations yet!'"
        [subtitle]="'Start your first conversation by searching for a person.'">
      </mg-empty-state>
    </ng-template>
  </ng-container>

  <ng-template #loadingTpl>
    <mg-spinner></mg-spinner>
  </ng-template>
</mg-layout-legacy-content>
