import { GroupCollectionActions, GroupMemberActions } from '../actions';
import { GroupCollectionState } from '../state';

export function groupCollectionReducer(
  state: GroupCollectionState = GroupCollectionState.initialState,
  action: GroupCollectionActions.TypeUnion | GroupMemberActions.TypeUnion,
): GroupCollectionState {
  switch (action.type) {
    case GroupCollectionActions.TypeEnum.LoadGroupsSuccess: {
      return GroupCollectionState.entities.setAll(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        expires: action.expiry,
      });
    }
    case GroupCollectionActions.TypeEnum.LoadGroupsFailure: {
      return {
        ...state,
        loaded: false,
        loading: false,
      };
    }
    case GroupCollectionActions.TypeEnum.LoadGroupsCollection: {
      return {
        ...state,
        loading: true,
      };
    }
    case GroupCollectionActions.TypeEnum.SelectGroup: {
      return {
        ...state,
        selectedGroupId: action.payload,
      };
    }
    // re-add the group on failure to delete.
    case GroupCollectionActions.TypeEnum.DeleteGroupFailure:
    case GroupCollectionActions.TypeEnum.AddGroupLocally: {
      return GroupCollectionState.entities.addOne(action.payload, state);
    }
    case GroupCollectionActions.TypeEnum.UpdateGroupLocally: {
      // const updates: Updates
      return GroupCollectionState.entities.updateOne(
        {
          id: action.payload.hash,
          changes: action.payload,
        },
        state,
      );
    }

    case GroupMemberActions.TypeEnum.CancelJoinGroup:
      return {
        ...state,
      };
    case GroupMemberActions.TypeEnum.DeclineGroupMember:
      return {
        ...state,
      };

    case GroupMemberActions.TypeEnum.UpdateGroupMembers: {
      return {
        ...state,
        loading: true,
      };
    }
    case GroupMemberActions.TypeEnum.UpdateGroupMembersFailure: {
      return {
        ...state,
        loading: false,
      };
    }
    case GroupMemberActions.TypeEnum.UpdateGroupMembersSuccess:
    case GroupMemberActions.TypeEnum.AddGroupMembersSuccess: {
      return GroupCollectionState.entities.updateOne(
        {
          changes: { memberCount: action.payload.members.length },
          id: action.payload.hash,
        },
        {
          ...state,
          loaded: true,
          loading: false,
        },
      );
    }

    case GroupMemberActions.TypeEnum.JoinGroupNoConfirm:
    case GroupMemberActions.TypeEnum.JoinGroup: {
      // optimistically add the group to 'my groups'
      return GroupCollectionState.entities.updateOne(
        {
          changes: action.payload,
          id: action.payload.hash,
        },
        {
          ...state,
          loaded: true,
          loading: false,
        },
      );
    }

    case GroupMemberActions.TypeEnum.JoinGroupSuccess: {
      // update group ranking from server response
      return GroupCollectionState.entities.updateOne(
        {
          changes: action.payload,
          id: action.payload.hash,
        },
        {
          ...state,
          loaded: true,
          loading: false,
        },
      );
    }

    // joining failure should result in the same thing as cancelling joining.
    case GroupMemberActions.TypeEnum.JoinGroupFailure:
    case GroupMemberActions.TypeEnum.CancelJoinGroupSuccess: {
      return GroupCollectionState.entities.updateOne(
        {
          changes: action.payload,
          id: action.payload.hash,
        },
        {
          ...state,
          loaded: true,
          loading: false,
        },
      );
    }

    case GroupMemberActions.TypeEnum.RemoveGroupMemberSuccess: {
      return GroupCollectionState.entities.updateOne(
        {
          changes: { rank: null, memberCount: action.payload.members.length },
          id: action.payload.hash,
        },
        {
          ...state,
          loaded: true,
          loading: false,
        },
      );
    }
    case GroupMemberActions.TypeEnum.RemoveGroupMember:
      return {
        ...state,
      };
    case GroupCollectionActions.TypeEnum.DeleteGroup: {
      return GroupCollectionState.entities.removeOne(
        action.payload.hash,
        state,
      );
    }
    case GroupCollectionActions.TypeEnum.InvalidateGroupsCollection: {
      return {
        ...state,
        expires: Date.now(),
      };
    }
    default: {
      return state;
    }
  }
}
