<mg-overlay-primary
  overlayTitle="Broadcast Alert"
  size="small"
  (closeModal)="close()">
  <p
    class="title-text dialog-title mg-centered"
    [innerHTML]="'dialog.broadcast.title' | translate: translateParams"></p>
  <form
    #form="ngForm"
    (submit)="submit()"
    class="broadcast-form">
    <div>
      <mat-form-field
        mgFormField
        class="mg-broadcast-title">
        <mat-label>Alert headline</mat-label>
        <input
          matInput
          name="pushNoteTitle"
          maxlength="65"
          [(ngModel)]="pushNoteTitle"
          required />
        <mat-hint [align]="'end'">
          {{ pushNoteTitle?.length || 0 }} / 65
        </mat-hint>
        <mat-error
          *ngIf="
            titleControl.invalid &&
            (titleControl.dirty || titleControl.touched || form.submitted)
          ">
          <ng-container [ngSwitch]="titleControlErrorKey">
            <span *ngSwitchCase="'maxlength'">
              {{ 'input.broadcastTitle.error.maxlength' | translate }}
            </span>
            <span *ngSwitchCase="'pattern'">
              {{ 'input.broadcastTitle.error.pattern' | translate }}
            </span>
            <span *ngSwitchDefault>
              {{ 'input.broadcastTitle.error.required' | translate }}
            </span>
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field
        mgFormField
        class="mg-broadcast-body">
        <mat-label>Alert details...</mat-label>
        <textarea
          matInput
          name="pushNoteBody"
          maxlength="240"
          [matTextareaAutosize]="true"
          [matAutosizeMinRows]="1"
          [matAutosizeMaxRows]="10"
          [(ngModel)]="pushNoteBody"></textarea>
        <mat-hint [align]="'end'">
          {{ pushNoteBody?.length || 0 }} / 240
        </mat-hint>
        <mat-error
          *ngIf="
            bodyControl.invalid &&
            (bodyControl.dirty || bodyControl.touched || form.submitted)
          ">
          <ng-container [ngSwitch]="bodyControlErrorKey">
            <span *ngSwitchCase="'maxlength'">
              {{ 'input.broadcastTitle.error.maxlength' | translate }}
            </span>
            <span *ngSwitchCase="'pattern'">
              {{ 'input.broadcastTitle.error.pattern' | translate }}
            </span>
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="broadcast-globally-toggle-wrap">
      <div>Send as email as well?</div>
      <div>
        <mat-slide-toggle
          class="broadcast-globally-toggle"
          name="shouldSendAsEmail"
          [(ngModel)]="shouldSendAsEmail">
        </mat-slide-toggle>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      *hasPermission="'SUPERADMIN'"
      class="broadcast-globally-toggle-wrap">
      <div>Broadcast to All Mingas</div>
      <div>
        <mat-slide-toggle
          class="broadcast-globally-toggle"
          name="broadcastGloballyToggle"
          [(ngModel)]="broadcastGlobally"
          (ngModelChange)="onBroadcastToggleChange()">
        </mat-slide-toggle>
      </div>
    </div>

    <div *ngIf="broadcastGlobally">
      <p>
        You are about to broadcast this message to
        <strong>ALL Mingas</strong>. Are you sure you want to do this?
      </p>
      <mat-form-field mgFormField>
        <mat-label>To confirm, please type {{ confirmString }}</mat-label>
        <input
          matInput
          type="text"
          required
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="confirmStringInput" />
      </mat-form-field>
    </div>

    <div
      class="btn-wrap"
      fxLayout="row"
      fxLayoutAlign="end center">
      <mg-btn
        variant="filled"
        [disabled]="isSubmitDisabled()"
        (pressed)="submit()">
        Send
      </mg-btn>
    </div>
  </form>
</mg-overlay-primary>

<ng-template #successOverlayTemplate>
  <div class="success-overlay">
    <div class="checkmark-wrap">
      <mg-success-checkmark></mg-success-checkmark>
      <div class="mg-bodycopy">Alert Sent!</div>
    </div>
  </div>
</ng-template>
