<ng-container *ngIf="content?.shortCard">
  <ng-container
    *ngIf="!content.shortCard.videoUrl && !content.shortCard.cloudflareUid">
    <mg-post-short-card
      [markKeywords]="markKeywords"
      [pinned]="pinned"
      [content]="content.shortCard"
      [context]="content.contentContextHash?.value"
      [commentRoles]="content.allowedCommentingRoleTypesList"></mg-post-short-card>
  </ng-container>
  <ng-container
    *ngIf="content.shortCard.videoUrl || content.shortCard.cloudflareUid">
    <mg-video-short-card
      [markKeywords]="markKeywords"
      [pinned]="pinned"
      [content]="content.shortCard"
      [context]="content.contentContextHash.value"
      [commentRoles]="content.allowedCommentingRoleTypesList"></mg-video-short-card>
  </ng-container>
</ng-container>

<ng-container *ngIf="content?.shortEventCard">
  <mg-event-short-card
    [markKeywords]="markKeywords"
    [pinned]="pinned"
    [content]="content.shortEventCard"
    [commentRoles]="content.allowedCommentingRoleTypesList"
    [context]="content.contentContextHash.value">
  </mg-event-short-card>
</ng-container>

<ng-container *ngIf="content?.shortAnnoucementCard">
  <mg-announcement-short-card
    [markKeywords]="markKeywords"
    [pinned]="pinned"
    [content]="content.shortAnnoucementCard"
    [commentRoles]="content.allowedCommentingRoleTypesList"
    [context]="content.contentContextHash.value">
  </mg-announcement-short-card>
</ng-container>

<ng-container *ngIf="content?.shortInspirationCard">
  <mg-inspiration-short-card
    [markKeywords]="markKeywords"
    [pinned]="pinned"
    [content]="content.shortInspirationCard"
    [commentRoles]="content.allowedCommentingRoleTypesList"
    [context]="content.contentContextHash.value">
  </mg-inspiration-short-card>
</ng-container>

<ng-container *ngIf="content?.shortBirthdayCard">
  <mg-birthday-short-card
    [markKeywords]="markKeywords"
    [pinned]="pinned"
    [content]="content.shortBirthdayCard"
    [commentRoles]="content.allowedCommentingRoleTypesList"
    [context]="content.contentContextHash.value">
  </mg-birthday-short-card>
</ng-container>

<ng-container *ngIf="content?.shortPollCard">
  <mg-poll-short-card
    [content]="content.shortPollCard"
    [pinned]="pinned"
    [showResultsAlways]="showPollResults"
    [commentRoles]="content.allowedCommentingRoleTypesList"
    [context]="content.contentContextHash.value">
  </mg-poll-short-card>
</ng-container>

<ng-container *ngIf="content?.shortFileCard">
  <mg-file-mini-card
    [content]="content.shortFileCard"
    [context]="content.contentContextHash.value">
  </mg-file-mini-card>
</ng-container>

<ng-container *ngIf="content?.shortChallengeCard">
  <mg-challenge-short-card
    [content]="content.shortChallengeCard"
    [context]="content.contentContextHash.value"
    (challengeClick)="challengeClick.emit($event)"
    (challengeResponseClick)="challengeResponseClick.emit($event)">
  </mg-challenge-short-card>
</ng-container>

<ng-container *ngIf="content?.shortSmsMessageCard as smsContent">
  <mg-sms-message-short-card
    [context]="content.contentContextHash.value"
    [pinned]="pinned"
    [message]="smsContent.message"
    [authorName]="smsContent.authorPersonView.displayName"
    [publishDate]="smsContent.timestamp"></mg-sms-message-short-card>
</ng-container>
