import { NgModule } from '@angular/core';

import { FtueHighlightPipe } from './FtueHighlight.pipe';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [FtueHighlightPipe],
  exports: [FtueHighlightPipe],
})
export class FtueHighlightPipeModule {}
