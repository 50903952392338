import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatColumnDef } from '@angular/material/table';

import { ColumnInfo, TemplateColumnKeys } from 'minga/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';

import { ReportTableColumnTemplateComponent } from './rt-column-template.component';

@Component({
  selector: 'mg-role-column',
  styles: [
    '.badge-img { width: 24px; height: 24px; display: inline-block; background-size: contain; background-repeat: no-repeat; background-position: center; }',
    "[class*='w-'] {flex: initial; min-width: 0 !important }",
  ],
  template: `<ng-container matColumnDef="{{ datasource.TEMPLATE_KEYS.ROLE }}">
    <mat-header-cell
      *matHeaderCellDef
      [mat-sort-header]="datasource.TEMPLATE_KEYS.ROLE"
      [disabled]="true"
      [ngStyle]="{ flex: '0 54px' }">
      Role
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      matTooltipClass="mg-tooltip"
      [matTooltip]="getRole(item)"
      [ngStyle]="{ flex: '0 54px', 'justify-content': 'center' }">
      <span
        class="badge-img"
        [style.backgroundImage]="'url(' + getBadgeIconUrl(item) + ')'">
      </span>
    </mat-cell>
  </ng-container>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'role-column cdk-visually-hidden',
    '[attr.ariaHidden]': 'true',
  },
  providers: [
    {
      provide: ReportTableColumnTemplateComponent,
      useExisting: ReportTableRoleTemplateComponent,
    },
  ],
})
export class ReportTableRoleTemplateComponent implements AfterContentInit {
  @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;
  @Input()
  datasource: ReportDatasourceService<any>;

  col: ColumnInfo;

  constructor() {}

  ngAfterContentInit(): void {
    this._setColumnInfo();
  }

  public getRole(item: any) {
    return this.col?.accessorFn ? this.col.accessorFn(item, true) : item.role;
  }

  public getBadgeIconUrl(item: any) {
    return this.col?.accessorFn ? this.col.accessorFn(item) : item.badgeIconUrl;
  }

  private _setColumnInfo() {
    const col = this.datasource
      .getTemplateColumns()
      .filter(c => c.key === TemplateColumnKeys.ROLE)[0];
    this.col = col;
  }
}
