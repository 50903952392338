import { Overlay } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { RouterOutletOption } from '@shared/constants/routerOutlet';

import { PsDialogComponent } from '../components/ps-dialog/ps-dialog.component';
import { PsSummaryComponent } from '../components/ps-summary/ps-summary.component';
import {
  PEOPLE_SELECTOR_FORMS,
  PS_DIALOG_DEFAULT_DATA,
  PeopleSelectorRoutes,
} from '../constants';
import {
  PeopleSelectorForms,
  PeopleSelectorPageData,
  PsData,
  PsDialogData,
  PsDialogResponse,
  PsEvent,
  PsFormName,
  PsStandAloneConfig,
  PsSummaryModalData,
  PsSummaryResponse,
} from '../types';

@Injectable({ providedIn: 'root' })
export class PeopleSelectorService {
  /** General events */
  private readonly _eventsSubj = new Subject<PsEvent>();
  public readonly events$ = this._eventsSubj.asObservable().pipe();

  /** Scanner camera event */
  private readonly _openScannerCameraSubject = new Subject<{ title: string }>();
  public readonly openScannerCamera$ =
    this._openScannerCameraSubject.asObservable();

  private _dontCloseModal = false;

  /** Service Constructor */
  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _location: Location,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService<
      PsSummaryResponse,
      PsSummaryModalData
    >,
    private _overlay: Overlay,
  ) {}

  public openScannerCamera(title: string) {
    this._openScannerCameraSubject.next({ title });
  }

  public async openStandAlone(
    formName: string,
    config?: PsStandAloneConfig['config'],
    preSelection?: PsData[],
  ): Promise<PsData[]> {
    const resultSubject = new Subject<PsData[]>();
    await this.open('Standalone', 'select', {
      data: {
        resultSubject,
        config,
        preSelection,
      },
      title: formName,
    });
    const selection = await resultSubject.pipe(take(1)).toPromise();
    resultSubject.complete();
    return selection;
  }

  public async open<
    K extends PsFormName,
    Z extends keyof PeopleSelectorForms[K]['pages'] & string,
    T extends PeopleSelectorForms[K],
    U extends boolean = false,
  >(
    ...args: T extends { data: unknown }
      ? [K, Z, PeopleSelectorPageData & { data: T['data'] }, U?]
      : [K, Z, PeopleSelectorPageData, U?]
  ): Promise<void> {
    const [name, page, data, skipLocationChange] = args;
    this._dontCloseModal = data.dontCloseModal || false;
    const { path } = PEOPLE_SELECTOR_FORMS[name];
    if (data) this._setPageDataInSessionStorage(path, page, data);
    await this._router.navigate(
      [
        '',
        {
          outlets: {
            ['o']: null,
            [RouterOutletOption.MODAL]: [PeopleSelectorRoutes.ROOT, path, page],
          },
        },
      ],
      {
        state: { ...(data || {}) },
        skipLocationChange,
      },
    );
  }

  public async openDialog(data: PsDialogData): Promise<PsDialogResponse> {
    const dialogRef = this.dialog.open<
      PsDialogComponent,
      PsDialogData,
      PsDialogResponse
    >(PsDialogComponent, {
      data: { ...PS_DIALOG_DEFAULT_DATA, ...data },
      height: 'auto',
      maxHeight: '95vh',
      backdropClass: 'ps-dialog-backdrop',
      disableClose: true,
      panelClass: 'ps-dialog-panel',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      // auto focus would cause the secondary button to be in focus (override button for error dialog)
      autoFocus: false,
    });

    /**
     * By default the mat-dialog will auto close success and warning types, but not
     * error types. Overriding that behaviour here to also close error types.
     */
    if (data.type === 'error' && data.closeTimeout) {
      setTimeout(() => dialogRef.close(), data.closeTimeout);
    }

    return await dialogRef.afterClosed().pipe(take(1)).toPromise();
  }

  public async openSummary(data: PsSummaryModalData): Promise<string[]> {
    const modalRef = this._modalOverlay.open<PsSummaryModalData>(
      PsSummaryComponent,
      {
        data,
        disposeOnNavigation: false,
      },
    );
    const result = await modalRef.afterClosed.toPromise();
    if (result.type === 'submit' && result.data) {
      const { selectionHashes } = result.data;
      return selectionHashes;
    }
    return [];
  }

  public emitEvent(event: PsEvent): void {
    this._eventsSubj.next(event);
  }

  public eventWhen(event: PsEvent) {
    return this.events$.pipe(filter(e => e === event));
  }

  public async close(skipEvent?: boolean): Promise<void> {
    if (!this._dontCloseModal) {
      if (this._activatedRoute?.outlet === RouterOutletOption.MODAL)
        this._router.navigate([
          { outlets: { [RouterOutletOption.MODAL]: null } },
        ]);
      else this._location.back();
    }
    if (!skipEvent) this.emitEvent('after-closed');
  }

  public getPageDataFromSessionStorage(path: string, page: string) {
    let result: PeopleSelectorPageData | undefined;
    try {
      const cachedString = sessionStorage.getItem(
        this._getStorageKey(path, page),
      );
      result = JSON.parse(cachedString) as PeopleSelectorPageData;
    } catch (error) {
      this._showCommonErrorAlert('Failed to load session data.');
    }
    return result;
  }

  public removePageDataFromSessionStorage(path: string, page: string) {
    try {
      sessionStorage.removeItem(this._getStorageKey(path, page));
    } catch (error) {
      this._showCommonErrorAlert('Failed to remove session data.');
    }
  }

  private _setPageDataInSessionStorage(
    path: string,
    page: string,
    formOptions: any,
  ) {
    try {
      sessionStorage.setItem(
        this._getStorageKey(path, page),
        JSON.stringify(formOptions),
      );
    } catch (error) {
      this._showCommonErrorAlert('Failed to store session data.');
    }
  }

  private _showCommonErrorAlert(message: string): void {
    this._systemAlertSnackBar.open({
      type: 'error',
      message,
    });
  }

  private _getStorageKey(path: string, page: string) {
    return `people-selector-${path}-${page}`;
  }
}
