<div>
  <div class="input">@{{ input }}</div>
</div>
<div class="results">
  <div
    *ngIf="_loading"
    class="loading-wrap">
    <mg-spinner
      [thickness]="1"
      [diameter]="10">
    </mg-spinner>
  </div>
  <div
    *ngIf="_loading && results.length === 0"
    class="searching-msg">
    <div>Searching...</div>
  </div>
  <div
    *ngIf="!_loading && results.length === 0"
    class="no-results">
    <div>No results for "{{ input }}"</div>
  </div>
  <ng-container *ngFor="let result of results; let i = index">
    <div
      class="result"
      [class.highlighted]="result.highlighted"
      (click)="selectMentionClick($event, i)">
      <mg-avatar
        class="result-avatar"
        [src]="result.displayImage"
        [color]="result.badgeIconColor"></mg-avatar>
      <div
        class="result-name"
        [innerHTML]="result.displayNameHtml"></div>
    </div>
  </ng-container>
  <div style="clear: both"></div>
</div>
