import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const MG_PILL_CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PillCheckboxComponent),
  multi: true,
};

@Component({
  providers: [MG_PILL_CHECKBOX_VALUE_ACCESSOR],
  selector: 'mg-pill-checkbox',
  templateUrl: './PillCheckbox.component.html',
  styleUrls: ['./PillCheckbox.component.scss'],
})
export class PillCheckboxComponent implements ControlValueAccessor {
  @ViewChild('pillCheckmark', { static: true })
  pillCheckmark?: ElementRef;

  @HostBinding('attr.tabindex')
  tabindex: string | number = '0';

  @Input()
  icon: string = 'check';

  @Input()
  @HostBinding('class.mg-pill-checkmark-disabled')
  disabled: boolean = false;

  @HostBinding('class.mg-pill-checkmark-has-icon')
  get hasIcon(): boolean {
    return !!this.icon;
  }

  @HostBinding('class.mg-pill-checkmark-checked')
  get checked() {
    return this._value;
  }

  @HostBinding('class.mg-pill-checkmark-unchecked')
  get unchecked() {
    return !this._value;
  }

  private _value: boolean = false;
  private _onChange?: (value: any) => void;
  private _onTouched: any;

  @HostListener('click', ['$event'])
  onClick(ev: MouseEvent) {
    if (this.disabled) return;

    this._value = !this._value;

    if (this._onChange) {
      this._onChange(this._value);
    }
  }

  @HostListener('keypress', ['$event'])
  onKeypress(ev: KeyboardEvent) {
    if (this.disabled) return;

    if (ev.key === 'Enter' || ev.key === ' ') {
      ev.preventDefault();

      this._value = !this._value;

      if (this._onChange) {
        this._onChange(this._value);
      }
    }
  }

  writeValue(value: any) {
    this._value = !!value;
  }

  registerOnChange(onChange: any) {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this._onTouched = onTouched;
  }
}
