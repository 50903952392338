import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import * as flex_proto from 'minga/proto/flex_time/flex_time_pb';
import * as report_proto from 'minga/proto/stats_report/stats_report_pb';
import { FlexRegistrationInfo } from 'minga/domain/flexTime';
import { FtmReportFilters } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { FlexTimeManager } from 'minga/proto/flex_time/flex_time_ng_grpc_pb';
import { StatsReportManager } from 'minga/proto/stats_report/stats_report_ng_grpc_pb';
import { FlexRegistrationMapper as flexRegMapper } from 'minga/shared-grpc/flex_time';
import { FlexUnregisteredMapper } from 'minga/shared-grpc/flex_time';
import { FlexFilterMapper } from 'minga/shared-grpc/report_filters';
import { ReportsService } from 'src/app/components/manager-report/services/report-service.service';
import { RootService } from 'src/app/minimal/services/RootService';

import {
  FTM_REPORTS_FILTER_INIT_STATE,
  FTM_REPORTS_FILTER_NON_ARRAY_TYPES,
  FtmReportFilterType,
} from '../constants';

@Injectable()
export class FtmReportsService extends ReportsService<FtmReportFilters> {
  constructor(
    _reportManager: StatsReportManager,
    _rootService: RootService,
    private _flexManager: FlexTimeManager,
  ) {
    super(
      FTM_REPORTS_FILTER_INIT_STATE,
      FTM_REPORTS_FILTER_NON_ARRAY_TYPES,
      undefined,
      _reportManager,
      _rootService,
    );
  }

  async getRegistrantsReport(offset = 0, limit = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.FLEX_PERIOD_REGISTERED,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info => flexRegMapper.fromProto(info.getFlexReg())),
      pageToken,
    };
  }

  async getUnregisteredReport(offset = 0, limit = 10, sort?: Sort) {
    const { infos, pageToken } = await this._getReport(
      ReportTypes.FLEX_PERIOD_UNREGISTERED,
      offset,
      limit,
      sort,
    );
    return {
      items: infos.map(info =>
        FlexUnregisteredMapper.fromProto(info.getFlexUnreg()),
      ),
      pageToken,
    };
  }

  _handleSetFilter(filter: FtmReportFilterType, value: any) {
    switch (filter) {
      case FtmReportFilterType.ACTIVITY: {
        this.filter.activities = value;
        break;
      }
      case FtmReportFilterType.REGISTRANT: {
        this.filter.registrant = value;
        break;
      }
      case FtmReportFilterType.PERIOD: {
        this.filter.periods = value;
        break;
      }
      case FtmReportFilterType.CHECKIN_STATUS: {
        this.filter.checkinStatus = value;
        break;
      }
      case FtmReportFilterType.START_DATE: {
        this.filter.startDate = value;
        break;
      }
      case FtmReportFilterType.END_DATE: {
        this.filter.endDate = value;
        break;
      }
      case FtmReportFilterType.USER_LIST: {
        this.filter.userList = value;
        break;
      }
      default: {
        break;
      }
    }
  }

  protected _mapFiltersToFilterMessage(
    reportType: ReportTypes = ReportTypes.FLEX_PERIOD_REGISTERED,
    offset: number = 0,
    limit?: number,
  ) {
    const onlyOnePeriod = reportType === ReportTypes.FLEX_PERIOD_UNREGISTERED;
    const req = new report_proto.GetOrExportReportRequest();
    const filter = FlexFilterMapper.toProto(
      this.filter,
      limit,
      offset,
      onlyOnePeriod,
    );
    req.setFlexFilters(filter);
    req.setReportType(reportType);
    return req;
  }

  public async archiveFlexRegistration(
    reg: FlexRegistrationInfo | FlexRegistrationInfo[],
  ) {
    let ftIds: number[] = [];
    if (Array.isArray(reg)) {
      ftIds = reg.map(p => p.id);
    } else {
      ftIds = [reg.id];
    }
    const req = new flex_proto.ArchiveFlexTimeRegistrationRequest();
    req.setIdsList(ftIds);
    await this._flexManager.archiveFlexTimeRegistration(req);
  }
}
