import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BmReportsConsequenceOverdueDatasourceService } from '../../services';

@Component({
  selector: 'mg-bm-reports-cons-overdue',
  templateUrl: './bm-reports-cons-overdue.component.html',
  styleUrls: ['./bm-reports-cons-overdue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BmReportsConsequenceOverdueDatasourceService],
})
export class BmReportsConsOverdueComponent {
  /** Component Constructor */
  constructor(public ds: BmReportsConsequenceOverdueDatasourceService) {}
}
