import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MgIconModule } from 'minga/app/src/app/icon';

import { GenericButtonModule } from '@shared/components/button';

import { CalendarComponent } from './calendar.component';

@NgModule({
  imports: [
    // Minga dependencies
    GenericButtonModule,
    MgIconModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatDatepickerModule,
  ],
  declarations: [CalendarComponent],
  exports: [CalendarComponent],
})
export class CalendarModule {}
