<mg-short-card
  #shortCard
  *ngIf="content"
  [authorPersonView]="content?.authorPersonView"
  [timestamp]="content?.timestamp"
  [ownerGroupHash]="content?.ownerGroupHash"
  [pinned]="pinned"
  [markKeywords]="markKeywords"
  [context]="context"
  [commentRoles]="commentRoles"
  [style.backgroundImage]="bgImageUrl"
  [content]="content">
  <mg-short-card-byline>
    <div class="poll-icon-margin"></div>
    <div class="poll-icon-wrap">
      <div class="poll-icon">
        <div class="poll-icon-img"></div>
      </div>
    </div>
  </mg-short-card-byline>

  <mg-short-card-group-name></mg-short-card-group-name>
  <mg-short-card-title></mg-short-card-title>

  <mg-short-card-body>
    <div class="body-contents-wrap">
      <div class="poll-top-right">
        <mg-ellipsis
          class="poll-ellipsis"
          [mgContentContextMenuClick]="context">
        </mg-ellipsis>

        <ng-container *ngIf="shortCard.pinned">
          <mge-icon
            class="pinned-icon"
            icon="pin-o"></mge-icon>
        </ng-container>
      </div>

      <ng-container *ngIf="content.points && content.points > 0">
        <mg-points-banner [points]="content.points"></mg-points-banner>
      </ng-container>

      <ng-container *ngIf="isPollClosed()">
        <div
          fxLayoutAlign="center center"
          class="poll-closed">
          <mg-text
            variant="body-md-secondary"
            color="on-error-bg">
            Poll closed
          </mg-text>
        </div>
      </ng-container>

      <div
        class="body-contents"
        [class.mg-results-shown]="shouldShowResults()"
        [class.mg-selection-locked]="isSelectionLocked()"
        [class.mg-closed]="isPollClosed()">
        <div
          class="poll-title"
          [class.poll-title--points]="content.points && content.points > 0">
          <span [innerHTML]="content.question | mgSafeHtml"></span>
          <ng-container *ngIf="shortCard.groupName">
            <div
              class="group-link-container"
              (click)="shortCard.groupLinkClick($event)">
              <span class="group-icon"></span>
              <span class="group-link-text"
                >Posted in
                <span class="group-link">{{ shortCard.groupName }}</span></span
              >
            </div>
          </ng-container>
        </div>

        <div
          class="choices-wrap"
          [ngClass]="'layout-' + pollLayoutName(content.pollLayout)">
          <ng-container
            *ngFor="let choice of content.choiceList; let i = index">
            <div
              class="choice-container"
              (click)="choiceClick(i)"
              [attr.data-choiceindex]="i"
              [class.mg-choice-selected]="isChoiceSelected(i)">
              <ng-container *ngIf="choice.text">
                <div class="poll-text-choice">
                  <span
                    class="text-choice-text"
                    [innerHtml]="choice.text | mgSafeHtml">
                  </span>

                  <ng-container *ngIf="showVotes">
                    <div
                      class="text-choice-votes"
                      [ngClass]="'show-' + shouldShowResults()">
                      {{ voteText(choice.count) }}
                    </div>
                  </ng-container>

                  <span class="text-choice-checkbox"></span>

                  <mg-odometer
                    odometer-percent
                    class="text-choice-progress-percent choice-progress-percent"
                    [ngClass]="'show-' + shouldShowResults()"
                    [value]="
                      getChoicePercent(choice.count, content.totalChoiceCount)
                    ">
                  </mg-odometer>

                  <ng-container *ngIf="shouldShowResults()">
                    <div
                      class="text-choice-progress"
                      [ngStyle]="
                        textChoiceProgressStyle(choice.count, i)
                      "></div>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngIf="choice.icon">
                <div class="poll-icon-choice">
                  <div class="poll-icon-choice-icon">
                    <mge-icon [icon]="choice.icon"></mge-icon>
                  </div>
                  <mg-odometer
                    odometer-percent
                    class="icon-choice-progress-percent choice-progress-percent"
                    [ngClass]="'show-' + shouldShowResults()"
                    [value]="
                      getChoicePercent(choice.count, content.totalChoiceCount)
                    ">
                  </mg-odometer>
                  <ng-container *ngIf="showVotes">
                    <div
                      class="icon-choice-votes"
                      [ngClass]="'show-' + shouldShowResults()">
                      {{ voteText(choice.count) }}
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </mg-short-card-body>

  <mg-short-card-action-bar></mg-short-card-action-bar>
</mg-short-card>
