import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { MgValidators } from 'src/app/input/validators';
import { AuthService } from 'src/app/minimal/services/Auth';
import { RootService } from 'src/app/minimal/services/RootService';

import { KioskRoute } from '@modules/kiosk';
import {
  KIOSK_FORM_FIELDS,
  KIOSK_MESSAGES,
  LandingRoute,
} from '@modules/landing/constants';

@Component({
  selector: 'mg-landing-kiosk-login',
  templateUrl: './landing-kiosk-login.component.html',
  styleUrls: ['./landing-kiosk-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingKioskLoginComponent implements OnInit {
  private _errorMessageSubject = new BehaviorSubject<string>('');
  public error$ = this._errorMessageSubject.asObservable();

  public LANDING_ROUTE = LandingRoute;
  public MESSAGES = KIOSK_MESSAGES;
  public FORM_FIELDS = KIOSK_FORM_FIELDS;
  public form = this._fb.group({
    [KIOSK_FORM_FIELDS.EMAIL]: ['', [Validators.required]],
    [KIOSK_FORM_FIELDS.PIN]: [
      '',
      [Validators.required, MgValidators.KioskPinValidator],
    ],
  });
  constructor(
    private _fb: FormBuilder,
    private _rootService: RootService,
    private _authService: AuthService,
    private _router: Router,
  ) {}

  ngOnInit(): void {}

  public async onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this._reset();

    const email = this.form.get(KIOSK_FORM_FIELDS.EMAIL).value;
    const pin = this.form.get(KIOSK_FORM_FIELDS.PIN).value;

    try {
      const response = await this._rootService.addLoadingPromise(
        this._authService.loginToKiosk(email, pin),
      );

      if (response.success) {
        this._router.navigate(['/', KioskRoute.ROOT]);
      } else {
        this._errorMessageSubject.next(response.reason);
      }
    } catch (error) {
      this._errorMessageSubject.next(
        'There was a problem login into the kiosk. Please try again.',
      );
    }
  }

  private _reset() {
    this._errorMessageSubject.next('');
  }
}
