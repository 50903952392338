<mat-form-field
  class="filter-select"
  mgFormField
  [mgHideOptionalMarker]="true"
  [mgNoHintMargin]="true"
  [mgResponsiveHeight]="true"
  [mgNoFloatTop]="true"
  appearance="legacy"
  floatLabel="never">
  <mat-label>Quick Filter</mat-label>
  <mat-select
    [(ngModel)]="quickFilter"
    placeholder="Quick Filter"
    panelClass="rounded-dropdown"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onQuickFilterChange()">
    <mat-option [value]="eventStatuses.NONE">All Events</mat-option>
    <mat-option [value]="'My Events'">My Events</mat-option>
    <mat-option [value]="eventStatuses.INVITED">- Invited to</mat-option>
    <mat-option [value]="eventStatuses.INTERESTED">- Maybe</mat-option>
    <mat-option [value]="eventStatuses.GOING">- Going</mat-option>
    <mat-option [value]="eventStatuses.NOT_GOING">- Can't Go</mat-option>
  </mat-select>
</mat-form-field>
