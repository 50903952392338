import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from 'minga/app/src/app/button';
import {
  ManagerReportChartModule,
  ManagerReportChartSectionModule,
  ManagerReportDataTableModule,
  ManagerReportHeaderModule,
} from 'minga/app/src/app/components/manager-report/components';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgIconModule } from 'minga/app/src/app/icon';
import { ScrollTargetDirectiveModule } from 'minga/app/src/app/misc/ScrollTarget/directive';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { MgOverlayToolsModule } from 'src/app/overlay/MgOverlayTools.module';

import { AutomationCounterResetModule } from '@shared/components/automation-counter-reset/automation-counter-reset.module';
import { GenericButtonModule } from '@shared/components/button';
import { CollapsibleModule } from '@shared/components/collapsible';
import { CountTileModule } from '@shared/components/count-tile';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import {
  ModalOverlayService,
  OverlayModule,
} from '@shared/components/modal-overlay';
import { MultiPersonSearchModule } from '@shared/components/multi-person-search';
import { ReportTableModule } from '@shared/components/report-table';
import { SystemAlertModule } from '@shared/components/system-alert';
import { TextModule } from '@shared/components/text';
import { UserListFilterModule } from '@shared/components/user-list-filter/user-list-filter.module';
import { ArrayPipesModule } from '@shared/pipes';

import { BmReportsComponent } from './bm-reports.component';
import { BmReportsAutomationCounterComponent } from './components/bm-reports-auto-counter/bm-reports-auto-counter.component';
import { BmReportsAutoCounterResetComponent } from './components/bm-reports-auto-counter/components/bm-reports-auto-counter-reset/bm-reports-auto-counter-reset.component';
import { BmReportsConsHistoryEditComponent } from './components/bm-reports-cons-history/bm-reports-cons-history-edit.component';
import { BmReportsConsHistoryComponent } from './components/bm-reports-cons-history/bm-reports-cons-history.component';
import { BmReportsConsOverdueComponent } from './components/bm-reports-cons-overdue/bm-reports-cons-overdue.component';
import { BmReportsConsTypesComponent } from './components/bm-reports-cons-types/bm-reports-cons-types.component';
import { BmReportsHistoryEditComponent } from './components/bm-reports-history/bm-reports-history-edit.component';
import { BmReportsHistoryComponent } from './components/bm-reports-history/bm-reports-history.component';
import { BmReportsStaffComponent } from './components/bm-reports-staff/bm-reports-staff.component';
import { BmReportsStudentComponent } from './components/bm-reports-student/bm-reports-student.component';
import { BmReportsSummaryComponent } from './components/bm-reports-summary/bm-reports-summary.component';
import { BmReportsTypesComponent } from './components/bm-reports-types/bm-reports-types.component';
import {
  BmReportsAutoCounterDatasourceService,
  BmReportsConsequenceHistoryDatasourceService,
  BmReportsService,
} from './services';
import { BmReportActionService } from './services/bm-reports-action.service';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    GenericModule,
    MgButtonModule,
    MgIconModule,
    MgFormFieldModule,
    MgSpinnerModule,
    ScrollTargetDirectiveModule,
    ManagerReportHeaderModule,
    GenericButtonModule,
    FormModule,
    CollapsibleModule,
    MgOverlayToolsModule,
    MultiPersonSearchModule,
    CountTileModule,
    ManagerReportChartModule,
    ManagerReportChartSectionModule,
    ManagerReportDataTableModule,
    ArrayPipesModule,
    ReportTableModule,
    TextModule,
    OverlayModule,
    AutomationCounterResetModule,
    SystemAlertModule,
    UserListFilterModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    RouterModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatTableModule,
  ],
  declarations: [
    BmReportsComponent,
    BmReportsHistoryComponent,
    BmReportsStaffComponent,
    BmReportsStudentComponent,
    BmReportsSummaryComponent,
    BmReportsTypesComponent,
    BmReportsConsHistoryComponent,
    BmReportsConsTypesComponent,
    BmReportsAutomationCounterComponent,
    BmReportsAutoCounterResetComponent,
    BmReportsConsOverdueComponent,
    BmReportsHistoryEditComponent,
    BmReportsConsHistoryEditComponent,
  ],
  exports: [BmReportsComponent],
  providers: [
    BmReportsService,
    BmReportsConsequenceHistoryDatasourceService,
    BmReportsAutoCounterDatasourceService,
    BmReportActionService,
    ModalOverlayService,
  ],
})
export class BmReportsModule {}
