import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgButtonModule } from 'minga/app/src/app/button';
import { StepIndicatorModule } from 'minga/app/src/app/components/StepIndicator';
import { FtueVideoModule } from 'minga/app/src/app/ftue/common/FtueVideo';

import { WelcomeStudentComponent } from './WelcomeStudent.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    FtueVideoModule,
    StepIndicatorModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [WelcomeStudentComponent],
  exports: [WelcomeStudentComponent],
})
export class WelcomeStudentModule {}
