import { PeopleUserListPageState } from '../types/people-userlists.types';

export enum PeopleUserListsMessages {
  TITLE = 'User Lists',
  TITLE_TOOLTIP = 'User lists allow you to manage frequently accessed groups of users that can be used as a filter in various sections of the app',
  FILTER_LABEL_TITLE = 'Search by title or list id',
  FILTER_LABEL_LIST_MANAGER = 'Search by list manager',

  BUTTON_LABEL_CREATE = 'Create list',
  BUTTON_LABEL_IMPORT = 'Import file',

  BUTTON_LABEL_ALL_LISTS = 'All lists',
  BUTTON_LABEL_MY_LISTS = 'My lists',

  COL_TITLE = 'Title',
  COL_LIST_ID = 'List ID',
  COL_LIST_MANAGER = 'List Manager',
  COL_CREATED_BY = 'Created by',
  COL_LAST_UPDATED = 'Last Updated',
  COL_PUBLIC = 'Public',
  COL_MANAGE_MEMBERS = 'Members',
  COL_ACTIVE = 'Active',
  COL_EDIT = 'Edit',

  EMPTY_STATE_TITLE = 'No user list found',
  EMPTY_STATE_BODY = 'User lists will be visible here',
  EMPTY_STATE_FILTERS_BODY = 'No user lists match the current filters',

  BUTTON_LABEL_ADD = 'Add',

  LIST_ID_TOOLTIP = "This List ID links Minga's user list to class lists within your SIS, enabling automatic updates during SIS syncs",
}

export const PEOPLE_USER_LIST_PAGE_DEFAULT_STATE: PeopleUserListPageState = {
  lists: new Map(),
  activeListId: null,
  totalLists: null,
  filters: {
    title: '',
    listManagerHashes: [],
    showMine: false,
  },
  loading: {
    allLists: false,
    activeList: false,
    delete: false,
    create: false,
    update: false,
  },
};
