//TS2742
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinct, filter, map, pairwise } from 'rxjs/operators';

import { firebase } from 'minga/app/src/firebase';

import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import {
  FirebaseMessage,
  FirebaseMessaging,
  FirebaseMessagingPermissionStatus,
  IFirebaseNotificationMessage,
} from '../FirebaseMessaging';

function convertBrowserPermission(
  perm: NotificationPermission,
): FirebaseMessagingPermissionStatus {
  switch (perm) {
    case 'default':
      return 'unknown';
    case 'granted':
      return 'granted';
    // With the firestore fallback we do not need notification permission to
    // receive messages.
    case 'denied':
      return 'data_only';
  }
}

export class FirebaseMessagingFirestoreFallback extends FirebaseMessaging {
  /** @internal */
  private readonly _messageReceived = new Subject<FirebaseMessage>();
  /** @internal */
  private readonly _tokenRefresh = new Subject<void>();
  private readonly _notificationClicked =
    new BehaviorSubject<IFirebaseNotificationMessage | null>(null);

  readonly name = 'Fallback';
  readonly messageReceived$ = this._messageReceived.asObservable();
  readonly tokenRefresh$ = this._tokenRefresh.asObservable();
  readonly notificationClicked$ = this._notificationClicked.pipe(
    filter(not => !!not),
    map(not => not as IFirebaseNotificationMessage),
  );

  constructor(
    private _id$: Observable<string>,
    private _kioskPermissions: KioskPermissionsService,
  ) {
    super();
  }

  async init() {
    let updateTimeout: any;
    const collection = () => firebase.firestore().collection('messaging');

    this._id$.pipe(distinct(), pairwise()).subscribe(([prevId, currId]) => {
      clearTimeout(updateTimeout);
      if (prevId) {
        collection().doc(prevId).delete();
      }

      if (currId) {
        const updateDoc = () => {
          collection().doc(currId).set({ lastUpdated: Date.now() });
          // Update every 30 minutes
          updateTimeout = setTimeout(updateDoc, 1.8e6);
        };
        updateDoc();

        const messages = collection().doc(currId).collection('messages');
        messages.onSnapshot(snapshot => {
          for (const change of snapshot.docChanges()) {
            if (change.type === 'added') {
              this._handleAddedDoc(change.doc.data());
              messages.doc(change.doc.id).delete();
            }
          }
        });
      }
    });

    // we want to disable all notifications for users in kiosk mode
    this._kioskPermissions.isUserInKioskModeObs().subscribe(inKioskMode => {
      if (inKioskMode) {
        this.deleteCurrentToken();
      }
    });
  }

  clearLastNotificationClicked() {
    this._notificationClicked.next(null);
  }

  async getPermissionStatus(): Promise<FirebaseMessagingPermissionStatus> {
    if (window.Notification) {
      return convertBrowserPermission(Notification.permission);
    }

    // Even without Notification support in the browser we can still receive
    // messages.
    return 'data_only';
  }

  async deleteCurrentToken(): Promise<void> {}

  async getToken(): Promise<string> {
    return '';
  }

  async getDeviceId(): Promise<string> {
    return '';
  }

  async requestPermission(): Promise<FirebaseMessagingPermissionStatus> {
    if (window.Notification) {
      const perm = await window.Notification.requestPermission();
      return convertBrowserPermission(perm);
    }

    // Even without Notification support in the browser we can still receive
    // messages.
    return 'data_only';
  }

  private async _handleAddedDoc(doc: any) {
    this._messageReceived.next({
      ...doc,
      type: doc.notification ? 'notification' : 'data',
    });

    const perm = await this.getPermissionStatus();
    const canSendNotification =
      perm === 'granted' && (navigator.serviceWorker || window.Notification);

    if (canSendNotification && doc.notification) {
      const title =
        doc.webpush?.notification?.dir || doc.notification?.title || '';
      const options: NotificationOptions = { data: doc.data };
      if (doc.webpush?.notification?.dir) {
        options.dir = doc.webpush.notification.dir;
      }
      if (doc.webpush?.notification?.tag) {
        options.tag = doc.webpush.notification.tag;
      }
      if (doc.webpush?.notification?.data) {
        options.data = doc.webpush.notification.data;
      }
      if (doc.webpush?.notification?.body) {
        options.body = doc.webpush.notification.body;
      } else if (doc.notification.body) {
        options.body = doc.notification.body;
      }
      if (doc.webpush?.notification?.badge) {
        options.badge = doc.webpush.notification.badge;
      }
      if (doc.webpush?.notification?.actions) {
        options.actions = doc.webpush.notification.actions;
      }
      if (
        typeof doc.webpush?.notification?.requireInteraction !== 'undefined'
      ) {
        options.requireInteraction =
          doc.webpush.notification.requireInteraction;
      }

      try {
        const registration =
          navigator.serviceWorker && (await navigator.serviceWorker.ready);
        if (registration?.showNotification) {
          await registration.showNotification(title, options);
        } else if (window.Notification) {
          const note = new window.Notification(title, options);
          note.onclick = () => {
            this._notificationClicked.next({
              ...doc,
              type: 'notification',
            });
          };
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
}
