<div class="header">
  <ng-container *hasPermission="'IS_PARENT'; else notParentEmpty">
    <p class="title mg-headline3">JOIN YOUR CHILD'S SCHOOL</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Select your children's school(s) to get started.
    </p>
  </ng-container>
  <ng-template #notParentEmpty>
    <p class="title mg-headline3">JOIN YOUR SCHOOL</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">Select your school to get started.</p>
  </ng-template>
</div>

<mg-default-parent-group-picker
  [clickMode]="'JOIN'"
  [noSuccessInterstitial]="true">
</mg-default-parent-group-picker>

<div class="footer">
  <button
    mgBubbleBtn
    class="mg-headline"
    [disabled]="!canClickDone"
    (click)="done.emit()">
    Done
  </button>
  <p
    class="subtitle mg-smallcopy"
    *hasPermission="'GROUP_ALLOW_ANY_PARENT_GROUP'">
    <a (click)="done.emit()">(Skip this step)</a>
  </p>

  <div class="mg-centered">
    <mg-step-indicator
      [allowStepClickNavigation]="false"
      [(activeStep)]="step"
      [stepCount]="stepCount"></mg-step-indicator>
  </div>
</div>
