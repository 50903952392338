<div class="person-container">
  <div class="person-avatar">
    <mg-avatar [src]="profileInfo.profileImageUrl"></mg-avatar>
  </div>

  <div class="person-info">
    <mg-text
      variant="header-lg-secondary"
      [numberOfLines]="2">
      {{ profileInfo.firstName + ' ' + profileInfo.lastName }}
    </mg-text>
    <mg-text
      variant="header-sm"
      color="surface-alt"
      [numberOfLines]="1">
      {{ profileInfo.badgeRoleName }}
    </mg-text>
  </div>
</div>
