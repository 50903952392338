import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'mg-button',
  templateUrl: './Button.component.html',
  styleUrls: ['./Button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgButtonComponent {
  @Input()
  color = 'primary';

  @Input()
  disableRipple: boolean = true;

  @HostBinding('class.active')
  @Input()
  active: boolean = false;

  @HostBinding('class.disabled')
  @Input()
  disabled: boolean = false;

  @HostBinding('attr.type')
  @Input()
  type: string = '';

  constructor() {}
}
