import * as day from 'dayjs';

import { IAuthPerson } from 'minga/domain/auth';
import { FlexTimeActivityInstance } from 'minga/domain/flexTime';

export enum FtmSharedMessages {
  // Activity Card
  REGISTER_BTN = 'Register For Activity',
  CHANGE_BTN = 'Change',
  REGISTERED_LABEL = 'Registered',
  ASSIGNED_LABEL = 'Assigned',
  SEATS_AVAILABLE = 'Seats Available:',
  LOCATION = 'Location:',
  TEACHER = 'Teacher:',
  DESC = 'Description:',

  // Activity Registration
  MODAL_TITLE_REGISTER = 'Activity Registration',
  MODAL_TITLE_ASSIGN = 'Assign Activity',
  MODAL_ERROR_FETCHING_ACTIVITIES = 'There was a problem fetching the activities, please try again',
  PERIOD_FILTER_LABEL = 'FlexTime Period',
  TEACHER_FILTER_LABEL = 'Teacher',
  TEXT_FILTER_LABEL = 'Search by Activity',
  REGISTRATION_ERROR = 'There was a problem registering for this activity, please try again',
  CHANGE_CONFIRM_TITLE = 'Change Activity',
  CHANGE_CONFIRM_DESC = 'Are you sure you want to choose a new activity?',
  CHANGE_CONFIRM_BTN = 'Change',
  CHANGE_CLOSE_BTN = 'Cancel',
  NO_ACTIVITIES_AVAILABLE = 'There are no activities available for register',
  PERIOD_LOCKED = 'This period is currently not allowing registration',

  EMPTY_STATE_TITLE = 'No results found',
  EMPTY_STATE_SUBTITLE_REGISTER = 'There are no activities available for register',
  EMPTY_STATE_SUBTITLE_ASSIGN = 'There are no activities available for assignment',
  EMPTY_STATE_SUBTITLE_GENERIC = 'There are no activities available',

  VALIDATE_ASSIGNMENT_MODAL_TITLE = 'This student is already registered to another activity',
  VALIDATE_ASSIGNMENT_MODAL_MESSAGE = 'Would you like to replace their current activity?',
  VALIDATE_ASSIGNMENT_ERROR = 'There was an issue validating the assignment. Please try again',
  VALIDATE_ASSIGNMENT_CONFIRM_BTN = 'Register',
  VALIDATE_ASSIGNMENT_CLOSE_BTN = 'Cancel',
}

export type ActivitySelectorModalData = {
  periodId?: number;
  currentActivityId?: number;
  teacher?: IAuthPerson;
  startDate?: day.Dayjs;
  endDate?: day.Dayjs;
  selectorType?: 'register' | 'assign';
};

export type ActivitySelectorModalResponse = {
  activityInstance?: FlexTimeActivityInstance;
};

export enum ActivitySelectorModalFormFields {
  PERIOD_ID = 'periodId',
  TEACHER_ID = 'teacherId',
  ACTIVITY_SEARCH = 'activitySearch',
}

export enum ActivityCardFields {
  NAME = 'NAME',
  DESCRIPTION = 'DESCRIPTION',
  TEACHER = 'TEACHER',
  LOCATION = 'LOCATION',
  SEATS = 'SEATS',
}

export type ActivityListFilters = {
  searchFilter: string;
  periodFilter: number;
  teacherFilter: string;
};
