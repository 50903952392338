import { Router } from '@angular/router';

import { LandingService } from '../services';
import {
  ILandingGuardClass,
  ILandingProperties,
  LandingGuardClassConstructor,
} from '../types';

export const hasAtLeastOneLandingProperty = (
  properties: (keyof ILandingProperties)[],
  commands: any[],
): LandingGuardClassConstructor => {
  return class implements ILandingGuardClass {
    constructor(private _landing: LandingService, private _router: Router) {}

    canActivate(): boolean {
      for (const property of properties) {
        const hasProperty = !!this._landing[property];

        if (!hasProperty) {
          this._router.navigate(commands);
        }

        return hasProperty;
      }

      return false;
    }
  };
};
