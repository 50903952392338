import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { mingaSettingTypes } from 'minga/util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { BadgeManager } from 'src/app/minimal/services/BadgeManager';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { LayoutService } from '@modules/layout/services';
import { ToolsRoutes } from '@modules/tools/tools.types';

import { StMessages } from '../../constants';
import { StudentToolsService } from '../../services';

@Component({
  selector: 'mg-st-root',
  templateUrl: './st-root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./st-root.component.scss'],
})
export class StRootComponent implements OnInit {
  public MESSAGES = StMessages;
  public TOOLS_ROUTES = ToolsRoutes;
  public readonly person$ = this._authInfo.authPerson$;
  public isPbisEnabled$ = this._settingService.isPbisModuleEnabled();
  public isTrackingEnabled$ = this._settingService.isPbisModuleEnabled();
  public activeBehaviorsCount$ =
    this._studentToolsService.activeBehaviorsCount$;
  public isLeaderboardEnabled$ = this._settingService.getSettingValueObs(
    mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD,
  );
  public isFlexTimeEnabled$ = this._settingService.isFlexTimeModuleEnabled();

  constructor(
    private _authInfo: AuthInfoService,
    public badgeManager: BadgeManager,
    private _settingService: MingaSettingsService,
    private _studentToolsService: StudentToolsService,
    private _analytics: AnalyticsService,
    public layout: LayoutService,
  ) {
    this._analytics.logEvent('studentToolsOpened', {});
  }

  ngOnInit(): void {
    this._studentToolsService.getStudentToolsBehaviors();
  }
}
