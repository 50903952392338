<mg-top-nav *ngIf="searchOverlayActive"></mg-top-nav>

<div
  *ngIf="searchOverlayActive"
  class="search-bg"
  [class.has-search-query]="!!navSearchValue"></div>

<mg-top-nav class="placeholder"></mg-top-nav>

<mg-top-nav [class.show-top-nav]="searchOverlayActive">
  <mg-top-nav-left>
    <mg-icon
      (click)="clearSearch()"
      [routerLink]="['', { outlets: { search: null } }]"
      >back</mg-icon
    >
  </mg-top-nav-left>
  <mg-top-nav-mid>
    <mg-navsearch
      [placeholder]="search.searchPlaceholder$ | async"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="navSearchValue"
      (ngModelChange)="onNavSearchValueChange($event, true)"
      #navsearch></mg-navsearch>
  </mg-top-nav-mid>
</mg-top-nav>

<div
  class="search-wrap"
  [class.routeActive]="searchOverlayActive">
  <div class="search-content">
    <ng-content></ng-content>
  </div>
</div>
