import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

export enum SearchInstanceType {
  INLINE = 'inline',
  OVERLAY = 'overlay',
}

export interface ISearchInstance {
  readonly type: SearchInstanceType;
  readonly placeholder: string;
  readonly placeholder$: Observable<string>;

  setPlaceholder(placeholder: string): void;
  destroy(): void;
}

export class SearchInstance implements ISearchInstance {
  private _placeholderSubject = new BehaviorSubject('');

  readonly type: SearchInstanceType;

  get placeholder(): string {
    return this._placeholderSubject.getValue();
  }

  get placeholder$(): Observable<string> {
    return this._placeholderSubject.asObservable();
  }

  constructor(type: SearchInstanceType, private _destroyFn: () => void) {
    this.type = type;
  }

  setPlaceholder(placeholder: string) {
    this._placeholderSubject.next(placeholder);
  }

  destroy() {
    this._destroyFn();
    this._placeholderSubject.complete();
  }
}

export const DEFAULT_SEARCH_PLACEHOLDER: string = 'Search';

/**
 * The Search service stores the search global state
 */
@Injectable({ providedIn: 'root' })
export class SearchService {
  private _resetSearchSubject = new Subject<void>();
  private _searchQuerySubject = new BehaviorSubject<string>('');
  private _instances: ISearchInstance[] = [];

  searchQuery$: Observable<string>;

  get activeSearchInstance(): ISearchInstance {
    const inst = this._instances[0];
    return inst || null;
  }

  get searchInstanceType(): SearchInstanceType | null {
    const inst = this.activeSearchInstance;
    if (inst) {
      return inst.type;
    }

    return null;
  }

  get searchInstanceType$(): Observable<SearchInstanceType | null> {
    return of(this._instances).pipe(
      map(insts => insts[0]),
      map(inst => {
        if (!inst) return null;
        return inst.type;
      }),
    );
  }

  get searchPlaceholder$(): Observable<string> {
    return of(this._instances).pipe(
      map(insts => insts[0]),
      switchMap(inst => {
        if (!inst) return of(DEFAULT_SEARCH_PLACEHOLDER);
        return inst.placeholder$;
      }),
      map(placeholder => placeholder || DEFAULT_SEARCH_PLACEHOLDER),
    );
  }

  get searchPlaceholder() {
    const inst = this._instances[0];
    if (inst) {
      return inst.placeholder;
    }

    return DEFAULT_SEARCH_PLACEHOLDER;
  }

  get searchQuery(): string {
    return this._searchQuerySubject.getValue();
  }

  get onResetSearch(): Observable<void> {
    return this._resetSearchSubject.asObservable();
  }

  constructor() {
    this.searchQuery$ = this._searchQuerySubject.asObservable();
  }

  public resetSearch() {
    this._resetSearchSubject.next();
    this.setSearchQuery('');
  }

  createSearchInstance(type: SearchInstanceType): ISearchInstance {
    const inst = new SearchInstance(type, () => {
      const index = this._instances.indexOf(inst);
      if (index !== -1) {
        this._instances.splice(index, 1);
      }
    });

    this._instances.unshift(inst);

    return inst;
  }

  setSearchQuery(query: string) {
    this._searchQuerySubject.next(query);
  }
}
