<div class="mg-date-time-picker">
  <mat-form-field
    *ngIf="!timeOnly"
    class="mg-datepicker-container"
    mgFormField
    hideRequiredMarker
    [mgHideOptionalMarker]="hideOptionalMarker"
    [class.stacked]="!dateOnly && stackInputs"
    [appearance]="appearance"
    [mgMultiLineHint]="multiLineHint">
    <mat-label>{{ getDatePlaceholder() }}</mat-label>

    <span
      *ngIf="datePrefix"
      matPrefix>
      <strong>{{ datePrefix }}</strong>
    </span>
    <mat-hint
      *ngIf="(dateOnly || !stackInputs) && dateHintLocale"
      [innerHTML]="dateHintLocale | translate"></mat-hint>
    <mat-error *ngIf="dateError"></mat-error>

    <mat-datepicker-toggle
      *ngIf="!shouldShowDateResetIcon"
      matSuffix
      [for]="datePicker">
      <mg-icon matDatepickerToggleIcon>calendar-open</mg-icon>
    </mat-datepicker-toggle>
    <div
      matSuffix
      *ngIf="shouldShowDateResetIcon"
      (click)="resetDate($event)"
      class="clickable-suffix">
      <mg-icon matSuffix>close-thin</mg-icon>
    </div>

    <input
      #displayDateInput
      matInput
      tabindex="-1"
      autocomplete="off"
      [required]="required"
      (focus)="displayDateInput.blur(); datePicker.open()"
      [disabled]="datePicker.opened || disabled"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="displayDate" />
    <mat-datepicker
      #datePicker
      touchUi="true"
      (closed)="displayDateInput.blur(); hiddenDateInput.blur()"
      [calendarHeaderComponent]="hasCustomHeader ? customDTHeader : null">
    </mat-datepicker>

    <input
      style="display: none"
      tabindex="-1"
      #hiddenDateInput
      autocomplete="off"
      [required]="required"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="date"
      [min]="minDate"
      [max]="maxDate"
      [matDatepicker]="datePicker"
      (ngModelChange)="dateChange($event)" />
  </mat-form-field>

  <div
    *ngIf="!dateOnly"
    class="mg-timepicker-container">
    <div
      *ngIf="!!showNativeTime"
      class="mg-timepicker-time-cover"
      [class.has-time-reset]="!!timeInput && shouldShowTimeResetIcon"
      (click)="showTimePicker($event)"></div>
    <div
      class="mg-timepicker-reset-cover"
      *ngIf="!!showNativeTime && !!timeInput && shouldShowTimeResetIcon"
      (click)="resetTimeInput($event)"></div>

    <mat-form-field
      class="date-form-field mg-timepicker"
      mgFormField
      hideRequiredMarker
      [mgHideOptionalMarker]="hideOptionalMarker"
      [appearance]="appearance"
      [mgFormStyles]="mgFormStyles"
      [class.disable]="showNativeTime"
      #timePickerEl>
      <span
        *ngIf="timePrefix"
        matPrefix>
        <strong>{{ timePrefix }}</strong>
      </span>

      <mat-label>{{ getTimePlaceholder() }}</mat-label>

      <input
        matInput
        [required]="required"
        autocomplete="off"
        [disabled]="disabled"
        type="text"
        [(ngModel)]="timeInput"
        (focus)="showTimePicker($event)"
        (ngModelChange)="timeInputChange($event)"
        (blur)="timeInputBlur()" />

      <div matSuffix>
        <mg-icon
          *ngIf="shouldShowTimeResetIcon"
          class="reset-icon"
          (click)="resetTimeInput($event)"
          >hamburger-active</mg-icon
        >

        <mat-icon *ngIf="!shouldShowTimeResetIcon">access_time</mat-icon>
      </div>

      <!-- <div matSuffix>
        <mg-icon
          class="reset-icon"
          (click)="resetTimeInput($event)"
          *ngIf="timeInput && canReset"
        >hamburger-active</mg-icon>
      </div> -->

      <mat-hint
        *ngIf="stackInputs && dateHintLocale"
        [innerHTML]="dateHintLocale | translate"></mat-hint>
      <mat-error *ngIf="stackInputs && dateError"></mat-error>
    </mat-form-field>
  </div>
</div>
