<!-- Page Header -->
<mg-manager-overlay-subpage
  *ngIf="layout.useLegacyLayout$ | async; else newLayoutTemplate"
  [loading]="isLoading$ | async">
  <ng-template #headerLeft>
    <h2 class="header-title">{{ MESSAGES.PAGE_TITLE }}</h2>
  </ng-template>
  <ng-template #headerRight>
    <div class="header-right">
      <div
        fxFlex="noshrink"
        fxLayoutAlign="end end"
        fxLayoutAlign.xs="center center">
        <ng-container *ngTemplateOutlet="createButtonTemplate"></ng-container>
      </div>
    </div>
  </ng-template>
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</mg-manager-overlay-subpage>

<!-- Create button template -->
<ng-template #createButtonTemplate>
  <mg-btn
    mat-button
    variant="filled"
    iconSet="minga"
    iconRight="down-toggle"
    [responsive]="true"
    [matMenuTriggerFor]="dropdownMenu">
    {{ MESSAGES.BUTTON_CREATE_NEW }}
  </mg-btn>
  <mat-menu
    #dropdownMenu="matMenu"
    xPosition="before">
    <button
      mat-menu-item
      [attr.data-analytics]="'hallpass-restrictions-click-create-npg'"
      (click)="
        _restrictionsService.openRestrictionsEditModal({
          isNewList: true
        })
      ">
      {{ MESSAGES.BUTTON_CREATE_NO_PARTY }}
    </button>
    <button
      mat-menu-item
      [attr.data-analytics]="'hallpass-restrictions-click-create-blackout'"
      (click)="_blackoutService.openEditModal({ isNewSchedule: true })">
      {{ MESSAGES.BUTTON_CREATE_BLACKOUT }}
    </button>
  </mat-menu>
</ng-template>

<!-- New layout template -->
<ng-template #newLayoutTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign.gt-sm="end end"
    [style.marginBottom]="'16px'">
    <ng-container *ngTemplateOutlet="createButtonTemplate"></ng-container>
  </div>
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</ng-template>

<!-- Page Content -->
<ng-template #mainContentTemplate>
  <div class="settings">
    <section>
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between stretch"
        fxLayoutGap="32px">
        <!-- Left Content -->
        <div fxFlex="55">
          <ng-container
            *ngTemplateOutlet="noPartyGroupsTemplate"></ng-container>
        </div>
        <!-- Right Content -->
        <div fxFlex>
          <ng-container *ngTemplateOutlet="passListTemplate"></ng-container>
        </div>
      </div>
      <div class="spacer"></div>
      <ng-container *ngTemplateOutlet="blackOutTemplate"></ng-container>
    </section>
  </div>
</ng-template>

<!-- No Party Groups Template -->
<ng-template #noPartyGroupsTemplate>
  <div
    class="section-header-container"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.lt-md="start center"
    fxLayoutAlign.xs="start stretch"
    fxLayoutGap.xs="20px">
    <div
      fxFlex="grow"
      [ngStyle.xs]="{ textAlign: 'center' }">
      <span class="section-header">No Party Groups</span>
      <mg-tooltip label="{{ noGroupsListMessage }}"></mg-tooltip>
    </div>
  </div>
  <mg-membership-list-table
    [types]="noPartyLists"
    [descriptions]="false"
    [canChangeName]="true"
    [canChangeStatus]="true"
    [refreshList]="_restrictionsService.refreshNoPartyGroupLists.asObservable()"
    [showDeniedPasses]="true"
    (navigateToDeniedPasses)="openDeniedPassesReport($event)">
  </mg-membership-list-table>
</ng-template>

<!-- Blackout Schedule Template -->
<ng-template #blackOutTemplate>
  <mg-hpm-restrictions-blackout></mg-hpm-restrictions-blackout>
</ng-template>

<!-- Pass List Template -->
<ng-template #passListTemplate>
  <div
    class="section-header-container"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.lt-md="start center"
    fxLayoutAlign.xs="start stretch"
    fxLayoutGap.xs="20px">
    <div
      fxFlex="grow"
      [ngStyle.xs]="{ textAlign: 'center' }">
      <span class="section-header">Pass List</span>
    </div>
  </div>
  <mg-membership-list-table
    [types]="noPassLists"
    [hideHeader]="true">
  </mg-membership-list-table>
</ng-template>
