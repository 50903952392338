import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { QuickFilterType } from 'minga/app/src/app/components/CalendarQuickFilter';
import {
  EventCalendarService,
  IEventCalendarSettings,
} from 'minga/app/src/app/services/EventCalendar';

@Component({
  selector: 'mg-calendar-schedule-nav',
  templateUrl: './CalendarScheduleNav.component.html',
  styleUrls: ['./CalendarScheduleNav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarScheduleNavComponent {
  calendarScheduleOptionsOpened: boolean;
  readonly settings$: Observable<IEventCalendarSettings>;

  @Input() calendarPath: string;
  @Input() schedulePath: string;

  @Output() clickOptions: EventEmitter<void> = new EventEmitter();

  constructor(private _calendarService: EventCalendarService) {
    this.settings$ = _calendarService.settings$.pipe(
      map(settings => ({ ...settings })),
    );
  }

  openCalendarOptions() {
    this.calendarScheduleOptionsOpened = !this.calendarScheduleOptionsOpened;
  }

  onClickOptions() {
    this.openCalendarOptions();
    this.clickOptions.emit();
  }

  onQuickFilterChange(value: QuickFilterType) {
    this._calendarService.setQuickFilter(value);
  }
}
