<mg-tools-two-col-layout [class.profile-view]="!!personHash">
  <ng-template #leftCol>
    <div
      *ngIf="scheduleIsLoading$ | async; else schedule"
      class="loading-container">
      <mg-spinner
        [diameter]="40"
        [thickness]="3">
      </mg-spinner>
    </div>
  </ng-template>
  <ng-template #rightCol>
    <div
      class="summary"
      *ngIf="schedule$ | async as schedule">
      <div class="summary-badge">
        <img src="/assets/flex-time/badge.svg" />
      </div>
      <mg-text
        variant="header-xl"
        textAlign="center"
        spacing="1-half"
        >{{ MESSAGES.SUMMARY_LINE_1 }}</mg-text
      >
      <mg-text
        variant="header-xl"
        textAlign="center"
        color="flextime"
        spacing="1-half"
        >{{ schedule?.registeredCount || 0 }}</mg-text
      >
      <mg-text
        variant="header-xl"
        textAlign="center"
        spacing="1-half"
        >{{
          schedule?.registeredCount === 1
            ? MESSAGES.SUMMARY_LINE_2_SINGULAR
            : MESSAGES.SUMMARY_LINE_2_PLURAL
        }}</mg-text
      >
    </div>
  </ng-template>
</mg-tools-two-col-layout>

<ng-template #schedule>
  <mg-ftm-activites-student-scheduler
    [schedule]="schedule$ | async"
    (activityRegistered)="activityRegistered($event)"
    [personHash]="personHash"></mg-ftm-activites-student-scheduler>
</ng-template>
