import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EditableHallPass } from 'minga/domain/hallPass';
import {
  ColumnInfo,
  HallPassReportColumns,
  TemplateColumnKeys,
} from 'minga/shared/reports_columns';
import { MingaPermission } from 'minga/util';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { PermissionsService } from 'src/app/permissions';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { HpmReportsHistoryEditComponent } from '../components/hpm-reports-history/hpm-reports-history-edit.component';
import { HpmReportsService } from '../services';

@Injectable()
export class HpmReportsHistoryService extends ReportDatasourceService<EditableHallPass> {
  protected _editForm = HpmReportsHistoryEditComponent;

  /** Service Constructor */
  constructor(
    private _hpmReports: HpmReportsService,
    _router: Router,
    private _permissions: PermissionsService,
    protected _alertModal: SystemAlertModalService,
    protected _snackBar: SystemAlertSnackBarService,
    protected _modalOverlay: ModalOverlayService,
  ) {
    super(
      _router,
      _hpmReports,
      HpmReportsHistoryService.name,
      _alertModal,
      _snackBar,
      _modalOverlay,
    );
    this._archiveFn = this._hpmReports.archiveHallPass.bind(this._hpmReports);
    let displayCol: ColumnInfo[] = [];
    if (this._permissions.hasPermission(MingaPermission.HALL_PASS_TYPE_MANAGE))
      displayCol = [
        { key: TemplateColumnKeys.EDIT, header: '', columnClasses: ['w-16'] },
        {
          header: '',
          key: TemplateColumnKeys.ARCHIVE,
          columnClasses: ['w-16'],
        },
      ];
    super.setDisplayColumns(HallPassReportColumns.HISTORY, displayCol);
  }

  async fetch(offset: number, limit: number) {
    return await this._hpmReports.getHallPassHistory(
      offset,
      limit,
      this._currentSort,
    );
  }
}
