import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { PointsNotificationHandlerService } from 'minga/app/src/app/notifications/points';
import { INotificationType } from 'minga/domain/notification';
import { Notification } from 'minga/proto/gateway/notification_pb';

import { NotificationListenerBaseService } from '../NotificationListenerBase.service';

/**
 * Listen to incoming notifications to look for points notifications
 */
@Injectable({ providedIn: 'root' })
export class PointsNotificationListenerService
  extends NotificationListenerBaseService
  implements OnDestroy
{
  private _destroyed$ = new ReplaySubject<void>(undefined);

  constructor(
    public store: Store<any>,
    private pointsHandler: PointsNotificationHandlerService,
  ) {
    super(store);

    this.notifications$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(notification => {
        this._handleNotification(notification);
      });
  }

  private async _handleNotification(msg: Notification.AsObject) {
    const notificationType = $enum(INotificationType).asValueOrThrow(
      msg.notificationType,
    );

    if (this.pointsHandler.isPointsNotification(msg.notificationType || '')) {
      return await this.pointsHandler.handleDisplayPointNotification(msg);
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
