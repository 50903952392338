import { NgModule } from '@angular/core';

import { MgLegacyOverlayNavModule } from './components/MgLegacyOverlayNav';
import { MgOverlayNavItemModule } from './components/MgOverlayNavItem';
import { MgOverlayHideModule } from './directives/MgOverlayHide';
import { MgOverlayStructureModule } from './directives/MgOverlayStructure';

export { MgLegacyOverlayNavComponent } from './components/MgLegacyOverlayNav';
export { MgOverlayNavItemComponent } from './components/MgOverlayNavItem';
export { MgOverlayHideDirective } from './directives/MgOverlayHide';
export { MgOverlayStructureDirective } from './directives/MgOverlayStructure';

@NgModule({
  imports: [
    MgOverlayStructureModule,
    MgOverlayNavItemModule,
    MgLegacyOverlayNavModule,
    MgOverlayHideModule,
  ],
  exports: [
    MgOverlayStructureModule,
    MgOverlayNavItemModule,
    MgLegacyOverlayNavModule,
    MgOverlayHideModule,
  ],
})
export class MgOverlayToolsModule {}
