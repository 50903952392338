<mg-basic-notification
  *ngIf="isBasicNotification()"
  [notification]="notification"></mg-basic-notification>

<mg-event-notification
  *ngIf="isEventNotification()"
  [notification]="notification"></mg-event-notification>

<mg-person-notification
  *ngIf="isPersonNotification()"
  [notification]="notification"></mg-person-notification>

<mg-person-title-notification
  *ngIf="isPersonTitleNotification()"
  [notification]="notification"></mg-person-title-notification>

<mg-points-notification
  *ngIf="isPointsNotification()"
  [notification]="notification"></mg-points-notification>

<mg-event-invite-notification
  *ngIf="isEventInviteNotification()"
  [notification]="notification"></mg-event-invite-notification>

<mg-badge-notification
  *ngIf="isBadgeNotification()"
  [notification]="notification"></mg-badge-notification>

<mg-challenge-invite-notification
  *ngIf="isChallengeInviteNotification()"
  [notification]="notification"></mg-challenge-invite-notification>
<mg-challenge-response-notification
  *ngIf="isChallengeResponseNotification()"
  [notification]="notification"></mg-challenge-response-notification>
