import { waitForCordovaDeviceReady } from '../device-ready';
import { MOBILE_ACCOUNT_TYPE } from './account-manager.constants';
import {
  ICordovaAccountManager,
  IMobileAccountCredentials,
} from './account-manager.types';

/**
 * AccountManager (cordova plugin used) functions:
 * getDataFromKey: function(accountType, group, key, successCallback,
 * errorCallback) getPassword:
 * function(accountType, group, key, successCallback, errorCallback)
 * getUserAccount: function(accountType, group, returnKey, successCallback,
 * errorCallback) initWithKey: function(encryptionKey, successCallback,
 * errorCallback) registerAccount: function(userName, password, accountType,
 * group, userData, successCallback, errorCallback) removeAccount:
 * function(accountType, successCallback, errorCallback) resetPassword:
 * function(accountType, group, successCallback, errorCallback) setPassword:
 * function(accountType, group, newPassword, successCallback, errorCallback)
 * setUserData: function(accountType, group, data, successCallback,
 * errorCallback)
 *
 for more info see:
 https://github.com/ChangeGamers/cordova-plugin-mg-kunder-keychain
 */

const _getCordovaAccountManager = (): ICordovaAccountManager | null => {
  return (window as any).AccountManager || null;
};

/**
 *  Register the encryptionKey for AES encryption. It must be called before
 * other Account Manager methods
 */
export const cordovaAccountInitWithKey = async (): Promise<void> => {
  await waitForCordovaDeviceReady();
  const accountManager = _getCordovaAccountManager();
  if (!accountManager) {
    console.warn(`cordovaAccountInitWithKey() missing AccountManager plugin.`);
    return;
  }
  return new Promise((resolve, reject) =>
    accountManager.initWithKey(
      MOBILE_ACCOUNT_TYPE,
      () => resolve(),
      (err: any) => {
        console.error(
          `[AccountManager] error occurred during initialization: `,
          err,
        );
        reject();
      },
    ),
  );
};

/**
 * Retrieve email and password from Keychain (iOS)
 */
export const cordovaAccountGetCredentials =
  async (): Promise<IMobileAccountCredentials | null> => {
    await waitForCordovaDeviceReady();
    const accountManager = _getCordovaAccountManager();
    if (!accountManager) {
      console.warn(
        `cordovaAccountGetCredentials() missing AccountManager plugin.`,
      );
      return null;
    }
    const email = await new Promise<string | undefined>((resolve, reject) => {
      accountManager.getUserAccount(
        MOBILE_ACCOUNT_TYPE,
        null,
        MOBILE_ACCOUNT_TYPE,
        (result: any) => {
          resolve(result[MOBILE_ACCOUNT_TYPE]);
        },
        (err: any) => {
          reject(err);
        },
      );
    });
    const password = await new Promise<string | undefined>(
      (resolve, reject) => {
        accountManager.getPassword(
          MOBILE_ACCOUNT_TYPE,
          null,
          MOBILE_ACCOUNT_TYPE,
          (result: any) => {
            resolve(result[MOBILE_ACCOUNT_TYPE]);
          },
          (err: any) => {
            reject(err);
          },
        );
      },
    );

    if (!email) return null;

    return { email, password: password || '' };
  };

/**
 * Register an user in Account Manager
 *
 * @param email
 * @param password
 */
export const cordovaAccountSetCredentials = async (
  email: string,
  password: string,
): Promise<void> => {
  await waitForCordovaDeviceReady();
  const accountManager = _getCordovaAccountManager();
  if (!accountManager) {
    console.warn(
      `cordovaAccountSetCredentials() missing AccountManager plugin.`,
    );
    return;
  }
  return new Promise((resolve, reject) => {
    accountManager.registerAccount(
      email,
      password,
      MOBILE_ACCOUNT_TYPE,
      null,
      {},
      () => resolve(),
      (err: any) => {
        reject(err);
      },
    );
  });
};

/**
 * Update account password
 *
 * @param password
 */
export const cordovaAccountSetPassword = async (
  password: string,
): Promise<void> => {
  await waitForCordovaDeviceReady();
  const accountManager = _getCordovaAccountManager();
  if (!accountManager) {
    console.warn(`cordovaAccountSetPassword() missing AccountManager plugin.`);
    return;
  }
  return new Promise((resolve, reject) => {
    accountManager.setPassword(
      MOBILE_ACCOUNT_TYPE,
      null,
      password,
      (result: any) => {
        resolve();
      },
      (err: any) => {
        reject(err);
      },
    );
  });
};

/**
 *  Remove all data from keychain (iOS)
 */
export const cordovaAccountRemoveAccount = async (): Promise<void> => {
  await waitForCordovaDeviceReady();
  const accountManager = _getCordovaAccountManager();
  if (!accountManager) {
    console.warn(`cordovaAccountSetPassword() missing AccountManager plugin.`);
    return;
  }
  return new Promise((resolve, reject) => {
    accountManager.removeAccount(
      MOBILE_ACCOUNT_TYPE,
      () => resolve(),
      (err: any) => {
        reject(err);
      },
    );
  });
};
