import Quill from 'quill';
const Parchment = Quill.import('parchment');

class MgMentionQuillFormat extends Parchment.Embed {
  static blotName: string = 'mention';
  static className: string = 'ql-mg-memtion';
  static tagName: string = 'MG-MENTION';
}

Quill.register({ 'formats/mention': MgMentionQuillFormat });

export {};
