<!-- Subpage Header -->
<div
  *ngIf="!loading"
  class="subpage-header full-width-layout"
  [class.old-layout]="layout.useLegacyLayout$ | async"
  [class.white-bg]="(layout.useLegacyLayout$ | async) === false">
  <div *ngIf="header">
    <ng-container *ngTemplateOutlet="header"></ng-container>
  </div>
</div>

<!-- Subpage Content -->
<div
  class="subpage-content"
  [class.no-bottom-padding]="noBottomPadding"
  [class.center-content]="loading">
  <ng-container *ngIf="content">
    <div
      fxLayout="row wrap"
      fxLayoutAlign="center center"
      fxLayoutAlign.gt-sm="end center"
      fxLayoutGap="8px"
      fxLayoutGap.gt-sm="15px"
      class="quick-action-container">
      <div
        *ngIf="scheduleReportsButton"
        fxFlex="nogrow">
        <mg-btn
          variant="text"
          size="small"
          iconSet="minga"
          [id]="'report-report-view-click-schedule-report'"
          [icon]="
            (media$ | async) !== 'xs' && (media$ | async) !== 'sm'
              ? 'mg-schedule-report'
              : null
          "
          (pressed)="scheduleReportsPressed.emit()">
          {{ MESSAGES.SCHEDULE_REPORTS_BUTTON }}
        </mg-btn>
      </div>
      <div
        *ngIf="exportListButton"
        fxFlex="nogrow">
        <mg-btn
          variant="text"
          size="small"
          iconSet="minga"
          [id]="'report-report-view-click-export'"
          [icon]="
            (media$ | async) !== 'xs' && (media$ | async) !== 'sm'
              ? 'mg-export'
              : null
          "
          (pressed)="exportListPressed.emit()">
          {{ MESSAGES.EXPORT_LIST_BUTTON }}
        </mg-btn>
      </div>
      <div
        *ngIf="columnSettingsButton"
        fxFlex="nogrow">
        <mg-btn
          variant="text"
          size="small"
          iconRight="expand_more"
          [id]="'report-report-view-click-column-settings'"
          [matMenuTriggerFor]="menu"
          (pressed)="columnSettingsPressed.emit()">
          {{ MESSAGES.COLUMN_SETTINGS_BUTTON }}
        </mg-btn>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
</div>

<mat-menu
  #menu="matMenu"
  xPosition="before">
  <div
    class="toggle-columns-container"
    (click)="$event.stopPropagation()">
    <mg-text
      variant="body-xs-secondary"
      spacing="2-half">
      Show columns:
    </mg-text>
    <!-- @TODO: Make this into a reusable component, so mm-dashboard.component
          can also use this. -->
    <div *ngFor="let column of dataService?.toggleColumns">
      <span *ngIf="column.header">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="4px">
          <mg-form-checkbox
            [checked]="!column.hidden"
            (changed)="dataService?.toggleColumn(column.key)">
          </mg-form-checkbox>
          <mg-text
            variant="body-xs-secondary"
            fontWeight="400">
            {{ column.header }}
          </mg-text>
        </div>
      </span>
    </div>
  </div>
</mat-menu>
