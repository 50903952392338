export enum LayoutPrimaryNavMessage {
  BUTTON_HELP_BUTTON = 'Help Center',
  BUTTON_HELP_BUTTON_DESCRIPTION = 'Open the help center',

  // Feedback
  BUTTON_FEEDBACK = 'Feedback',
  BUTTON_FEEDBACK_DESCRIPTION = 'Send feedback to the Minga team',

  // About us
  BUTTON_ABOUT_US = 'About Minga',
  BUTTON_ABOUT_US_DESCRIPTION = 'Learn more about Minga',

  // Logout
  BUTTON_LOGOUT = 'Logout',
  BUTTON_LOGOUT_DESCRIPTION = 'Logout of Minga',

  BUTTON_SUICIDE_HOTLINE = 'Suicide Prevention Hotline',
}

export enum LayoutLocalStorageKeys {
  PRIMARY_NAVIGATION_STATE = 'minga-app-primary-navigation-state',
}

/**
 * '1' for true, you can figure out the rest
 */
export const PRIMARY_NAVIGATION_STATE_DEFAULT_STATE = '1';

export enum ErrorPagesMessage {
  // Error 403
  ERROR_403_TITLE = 'Access denied',
  ERROR__403_QUOTE = '“Once we accept our limits, we go beyond them.”',
  ERROR_403_DESCRIPTION = 'Too bad you’ll have to accept that your limit is not having access to this page.',
  ERROR_403_IMAGE = 'assets/error/403-access-denied.svg',

  // Error 404
  ERROR_404_TITLE = 'Page not found',
  ERROR__404_QUOTE = '“If you can’t explain it simply, you don’t understand it well enough.”',
  ERROR_404_DESCRIPTION = 'Similarly, we can’t explain why this page cannot be found.',
  ERROR_404_IMAGE = 'assets/error/404-page-not-found.svg',

  // Error 500
  ERROR_500_TITLE = 'Server error',
  ERROR__500_QUOTE = '“Imagination is more important than knowledge.”',
  ERROR_500_DESCRIPTION = 'So, imagine this page is working while we fix our server error.',
  ERROR_500_IMAGE = 'assets/error/500-server-error.svg',
}
