import { grpc } from '@improbable-eng/grpc-web';

export interface GrpcMetadataInterceptor {
  onBeforeSend(metadata: grpc.Metadata): void;
  onReceive(metadata: grpc.Metadata): void;
}

declare global {
  interface Window {
    addGrpcMetadataInterceptor(interceptor: GrpcMetadataInterceptor): void;
  }
}

let grpcMetadataInterceptors: GrpcMetadataInterceptor[] = [];

window.addGrpcMetadataInterceptor = (interceptor: GrpcMetadataInterceptor) => {
  grpcMetadataInterceptors.push(interceptor);
};

const originalInvoke = grpc.invoke;

(<any>grpc).invoke = function (methodDescriptor: any, props: any) {
  let metadata = props.metadata || new grpc.Metadata();

  for (let interceptor of grpcMetadataInterceptors) {
    interceptor.onBeforeSend(metadata);
  }

  props.metadata = metadata;

  let originalOnHeaders = props.onHeaders;

  props.onHeaders = (headers: any) => {
    for (let interceptor of grpcMetadataInterceptors) {
      interceptor.onReceive(headers);
    }

    if (typeof originalOnHeaders === 'function') {
      return originalOnHeaders(headers);
    }
  };

  return originalInvoke.call(grpc, methodDescriptor, props);
};
