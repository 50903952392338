import { Component, ChangeDetectionStrategy } from '@angular/core';

import { PmReportsStudentDatasourceService } from '../../services';

@Component({
  selector: 'mg-pm-reports-student',
  template: `
    <mg-report-table
      matSort
      [dataSourceService]="ds"
      (matSortChange)="ds.sort($event)">
      <mg-history-column [datasource]="ds"></mg-history-column>
    </mg-report-table>
  `,
  styleUrls: ['../pm-reports.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PmReportsStudentDatasourceService],
})
export class PmReportsStudentComponent {
  constructor(public ds: PmReportsStudentDatasourceService) {}
}
