<mg-lightbox #lightbox>
  <mg-icon
    class="close-icon"
    (click)="close()"
    >close-active</mg-icon
  >

  <div
    class="content-container"
    fxLayout="column">
    <div
      class="nav-left"
      [class.disabled]="!hasPrevious()">
      <mg-icon (click)="slidePrevious()">left-arrow-active</mg-icon>
    </div>

    <div
      class="nav-right"
      [class.disabled]="!hasNext()">
      <mg-icon (click)="slideNext()">right-arrow-active</mg-icon>
    </div>

    <div
      [style.transform]="'translateX(' + activeImageIndex * -2 * 100 + 'vw)'"
      class="items-container"
      #itemsContainerElement>
      <div
        *ngFor="let attachment of attachments; let i = index"
        class="item-container"
        [class.item-active]="i == activeImageIndex"
        [style.transform]="'translateX(' + i * 100 + 'vw)'">
        <div
          class="item-content-wrap"
          [style.width.px]="lightBoxImageWidth(attachment)">
          <div class="item-content">
            <div
              class="mg-smallcopy photo-count"
              fxLayout="row"
              fxLayoutGap="8px"
              fxLayoutAlign="start center">
              <div fxFlex="auto">
                Image {{ i + 1 }} of {{ attachments.length }}
              </div>
            </div>

            <div
              mgPinchZoom
              class="image-container">
              <mg-image
                [active]="i == activeImageIndex"
                [style.height.px]="lightBoxImageHeight(attachment)"
                [srcs]="
                  attachment.image
                    | mgImageUrls: ['blurloading1', 'longcardbanner']
                ">
              </mg-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mg-lightbox>
