<mg-file-list
  (uploadFile)="onFileChange($event)"
  [enableCreateFile]="enableFileManagement"
  [enableDelete]="enableFileManagement"
  [enableCreateURL]="enableCreateURL"
  [files]="files"
  [loading]="loadingFiles"
  [listStyle]="listStyle"
  [downloadingFile]="downloadingFile"
  (downloadFile)="onFileDownload($event)"
  (openFileIndex)="onFileOpen($event)"
  (abortDownloadFileIndex)="onFileIndexDownloadAbort($event)"
  (deleteFileIndex)="onFileIndexDelete($event)"
  (uploadUrl)="onUploadUrl()">
</mg-file-list>
