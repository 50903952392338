import { Injectable } from '@angular/core';

import { $enum } from 'ts-enum-util';

import { IChallengeDraft } from 'minga/app/src/app/modules/challenges/types';
import { dateTimeMessageToDateObject } from 'minga/app/src/app/util/date';
import {
  ChallengeColor,
  ChallengePrivacy,
  ChallengeType,
} from 'minga/domain/challenge';
import { Challenge as ChallengeProto } from 'minga/proto/content/challenge/challenge_ng_grpc_pb';
import { ContentInfoQuery } from 'minga/proto/content/common_pb';
import { BannerLibrary } from 'minga/proto/gateway/banner_ng_grpc_pb';
import { Banner, BannerLibraryGet } from 'minga/proto/gateway/banner_pb';
import { MingaRoleType } from 'minga/util';

@Injectable({ providedIn: 'root' })
export class ChallengeContentInfoService {
  constructor(
    private challengeProto: ChallengeProto,
    private bannerService: BannerLibrary,
  ) {}

  async getContentInfo(contentHash: string): Promise<IChallengeDraft> {
    const request = new ContentInfoQuery();
    request.setContentHash(contentHash);
    return this._makeContentInfoRequest(request);
  }

  async getContentInfoByContext(contextHash: string): Promise<IChallengeDraft> {
    const request = new ContentInfoQuery();
    request.setContentContextHash(contextHash);
    return this._makeContentInfoRequest(request);
  }

  private async _makeContentInfoRequest(
    request: ContentInfoQuery,
  ): Promise<IChallengeDraft> {
    const response = await this.challengeProto.getInfo(request);
    const title = response.getTitle();
    const body = response.getBody();
    const dueDate = dateTimeMessageToDateObject(response.getDueDate());
    const privacy = $enum(ChallengePrivacy).asValueOrThrow(
      response.getPrivacy(),
    );
    const commentsAllowed = response.getCommentsAllowed();
    const uploadedImage = response.getBannerAsset();
    const type = $enum(ChallengeType).asValueOrThrow(response.getType());
    const color = $enum(ChallengeColor).asValueOrThrow(response.getColor());
    const points = response.getPointValue();
    const uploadedBadge = response.getBadgeAsset();
    const allowedRoles = response.getAllowedRolesList().map(role => {
      return $enum(MingaRoleType).asValueOrThrow(role);
    });

    let banner: Banner.AsObject | undefined;
    if (response.getBannerHash()) {
      const bannerGet = new BannerLibraryGet();
      bannerGet.setBannerHash(response.getBannerHash());
      banner = (await this.bannerService.get(bannerGet)).toObject();
    }
    let badge: Banner.AsObject | undefined;
    if (response.getBadgeHash()) {
      const badgeGet = new BannerLibraryGet();
      badgeGet.setBannerHash(response.getBadgeHash());
      badge = (await this.bannerService.get(badgeGet)).toObject();
    }
    const requiresApproval = response.getRequiresApproval();

    return {
      title,
      body,
      dueDate,
      privacy,
      commentsAllowed,
      uploadedImage,
      banner,
      type,
      contentHash: response.getContentHash() || '',
      contextHash: response.getContextHash() || '',
      color,
      points,
      uploadedBadge,
      badge,
      allowedRoles,
      requiresApproval,
    };
  }
}
