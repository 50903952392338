import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgTextareaComponent } from './Textarea.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MgTextareaComponent],
  exports: [MgTextareaComponent],
})
export class MgTextareaModule {}
