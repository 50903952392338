import { mgResolveAssetUrl } from './assetResolve';

export function mgResolveImagePath(
  value: any,
  options?: string | string[],
  defaultSize: string = 'raw',
) {
  let sizeKeys: string[] = [];
  if (typeof options == 'string') {
    sizeKeys.push(options);
  } else if (Array.isArray(options)) {
    sizeKeys = options;
  }

  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'object' && 'getSizeMap' in value) {
    let sizeKeys: string[] = [];
    if (typeof options == 'string') {
      sizeKeys.push(options);
    } else if (Array.isArray(options)) {
      sizeKeys = options;
    }

    if (defaultSize) {
      sizeKeys.push(defaultSize);
    }

    const sizeMap = value.getSizeMap();

    for (let sizeKey of sizeKeys) {
      if (sizeMap.has(sizeKey)) {
        const imageSize = sizeMap.get(sizeKey);
        return imageSize.getPath();
      }
    }
  } else if (typeof value === 'object' && 'sizeMap' in value) {
    const sizeMap = value.sizeMap;

    if (defaultSize) {
      sizeKeys.push(defaultSize);
    }

    for (let sizeKey of sizeKeys) {
      for (let [key, value] of sizeMap) {
        if (key === sizeKey) {
          return value.path;
        }
      }
    }
  } else if (typeof value === 'object') {
    if (defaultSize) {
      sizeKeys.push(defaultSize);
    }

    for (let sizeKey of sizeKeys) {
      if (sizeKey in value) {
        return value[sizeKey].path;
      }
    }
  }

  return '';
}

export function mgResolveImageUrl(
  value: any,
  options?: string | string[],
  defaultSize: string = 'raw',
): string {
  const imagePath = mgResolveImagePath(value, options, defaultSize);

  if (imagePath) {
    return mgResolveAssetUrl(imagePath);
  }

  return '';
}

export function mgResolveImageUrls(
  value: any,
  sizes: (string | string[])[],
): string[] {
  const imageUrls: string[] = [];

  for (const size of sizes) {
    const sizeUrl = mgResolveImageUrl(value, size, '');
    if (sizeUrl) {
      imageUrls.push(sizeUrl);
    }
  }

  if (imageUrls.length === 0) {
    const rawSizeUrl = mgResolveImageUrl(value, 'raw', '');
    if (rawSizeUrl) {
      imageUrls.push(rawSizeUrl);
    }
  }

  return imageUrls;
}
