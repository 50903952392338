import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { PsData } from '../types';

export class PsFormSelectionModel<DataType extends PsData> {
  /** Selection State */
  private readonly _selectionSubj = new BehaviorSubject(
    new Map<string, DataType>(),
  );
  public readonly selection$ = this._selectionSubj
    .asObservable()
    .pipe(map(selection => Array.from(selection.values())));

  /** Size */
  public readonly size$ = this._selectionSubj.pipe(
    map(selection => selection.size),
  );
  public readonly hasSelection$ = this.size$.pipe(
    map(size => size > 0),
    distinctUntilChanged(),
  );

  /** Selected Hashes */
  public readonly selectedHashes$ = this.selection$.pipe(
    map(selection => selection.map(person => person.personHash)),
  );

  /** Class Constructor */
  constructor() {}

  public getSelection(): DataType[] {
    const selection = this._selectionSubj.getValue();
    return Array.from(selection.values());
  }

  public getSelectionHashes(): string[] {
    return this.getSelection().map(person => person.personHash);
  }

  public getCurrentSize() {
    const selection = this._selectionSubj.getValue();
    return selection.size;
  }

  public toggle(value: DataType): void {
    if (this.isSelected(value)) this.deselect(value);
    else this.select([value]);
  }

  public select(people: DataType[]): void {
    if (!people) return;
    const selection = this._selectionSubj.getValue();
    people.forEach(person => selection.set(person.personHash, person));
    this._selectionSubj.next(selection);
  }

  public deselect(...people: DataType[]): void {
    people.forEach(person => this._unmarkSelected(person));
  }

  public isSelected(value: DataType): boolean {
    const selection = this._selectionSubj.getValue();
    return selection.has(value.personHash);
  }

  public getSelected(personHash: string): DataType {
    const selection = this._selectionSubj.getValue();
    return selection.get(personHash);
  }

  public clear(): void {
    this._unmarkAll();
  }

  private _unmarkSelected(person: DataType | undefined): void {
    if (!person && !this.isSelected(person)) return;
    try {
      const selection = this._selectionSubj.getValue();
      selection.delete(person.personHash);
      this._selectionSubj.next(selection);
    } catch (error) {
      console.log('failed to unmark selected', error);
    }
  }

  private _unmarkAll(): void {
    const selection = this._selectionSubj.getValue();
    if (selection.size === 0) return;
    try {
      selection.forEach(person => selection.delete(person.personHash));
      this._selectionSubj.next(selection);
    } catch (error) {
      console.log('failed to unmark all', error);
    }
  }

  public cleanup() {
    this._selectionSubj.complete();
  }
}
