<div
  class="overlay-bg"
  (click)="overlayBgClick($event)"
  *ngIf="overlayActive"></div>

<div
  class="cover-container cover-container-on-top"
  *ngIf="overlayActive && hasStructureTemplate('cover')">
  <div
    mgOverlayStructureView
    overlayStructureRegion="cover"></div>
</div>

<div
  class="overlay-wrap"
  [class.routerActive]="overlayActive">
  <div
    class="overlay-outlet-wrap"
    (click)="overlayOutletWrapClick($event)">
    <div
      #overlayTopNavTopSentinel
      class="overlay-nav-sentinel overlay-top-nav-sentinel overlay-nav-sentinel-top"></div>
    <div
      *ngIf="overlayActive && overlayComponent"
      class="overlay-nav overlay-nav-outer overlay-nav-top"
      fxLayout="column"
      fxLayoutAlign="center"
      [class.sticky]="overlayTopNavSticky">
      <div
        mgOverlayStructureView
        overlayStructureRegion="top"></div>
    </div>
    <div
      #overlayTopNavBtmSentinel
      class="overlay-nav-sentinel overlay-top-nav-sentinel overlay-nav-sentinel-bottom"></div>

    <div class="overlay-container">
      <div
        class="header-container"
        [class.top-nav-sticky]="overlayTopNavSticky"
        *ngIf="hasStructureTemplate('header')"
        #headerContainerEl>
        <div
          mgOverlayStructureView
          overlayStructureRegion="header"></div>
      </div>
      <div
        class="cover-container cover-container-in-content"
        *ngIf="hasStructureTemplate('cover')">
        <div
          mgOverlayStructureView
          overlayStructureRegion="cover"></div>
      </div>

      <div class="content-containers-wrap">
        <div
          class="split-content-container"
          *ngIf="hasStructureTemplate('split-content')">
          <div
            mgOverlayStructureView
            overlayStructureRegion="split-content"></div>
        </div>

        <div
          class="content-container"
          [mgScrollTarget]="
            overlayActive && overlayComponent && usingNestedScroll
          ">
          <div class="safari-sticky-fix">
            <div class="safari-flex-content">
              <div
                *ngIf="overlayActive && overlayComponent"
                class="overlay-nav sticky overlay-nav-top overlay-nav-content-container"
                fxLayout="column"
                fxLayoutAlign="center">
                <div
                  mgOverlayStructureView
                  overlayStructureRegion="top"></div>
              </div>
              <div class="content-wrap">
                <ng-content></ng-content>
              </div>
            </div>
            <div
              *ngIf="
                overlayActive &&
                overlayComponent &&
                hasStructureTemplate('bottom')
              "
              class="overlay-nav sticky overlay-nav-bottom overlay-nav-content-container"
              fxLayout="column"
              fxLayoutAlign="center"
              #contentBottomNavEl>
              <div
                mgOverlayStructureView
                overlayStructureRegion="bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      #overlayBottomNavTopSentinel
      class="overlay-nav-sentinel overlay-bottom-nav-sentinel overlay-nav-sentinel-top"></div>
    <div
      *ngIf="
        overlayActive && overlayComponent && hasStructureTemplate('bottom')
      "
      class="overlay-nav overlay-nav-outer overlay-nav-bottom"
      fxLayout="column"
      fxLayoutAlign="center"
      [class.sticky]="overlayBottomNavSticky">
      <div
        mgOverlayStructureView
        overlayStructureRegion="bottom"></div>
    </div>
    <div
      #overlayBottomNavBtmSentinel
      class="overlay-nav-sentinel overlay-bottom-nav-sentinel overlay-nav-sentinel-bottom"></div>
  </div>
</div>
