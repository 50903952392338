export interface IFontSizeResult {
  fontSize: number;
  reducedTextLength?: number;
}

/**
 * Find a font size to get text given to fit wthin a container of a given size.
 * @param text text string to fit within dimensions given
 * @param width width of container to fit within
 * @param height height of container to fit within
 * @param startingFontSize largest font size to start with
 * @param minFontSize min acceptable font size to try and fit within dimensions
 */
export function calcFontSizeToFit(
  text: string,
  width: number,
  height: number,
  startingFontSize: number,
  minFontSize: number = 6,
  oneRowLimited: boolean = false,
): IFontSizeResult {
  // approx constant, based off Arial being 1.9
  const fontConstant = 2;
  // taken from _text_mixins
  const lineHeight = 1.35;

  let fontSize = startingFontSize;
  let textLength = text.length;
  let fontSizeFits = false;

  while (!fontSizeFits && textLength > 0) {
    const currentText = text.substring(0, textLength);
    const numberOfLineBreaks = (currentText.match(/\n/g) || []).length;

    const charactersPerLine = (width * fontConstant) / fontSize;
    const lengthFronNewLines = numberOfLineBreaks * charactersPerLine;
    // use text length plus number of lines with max characters possible per
    // line
    const workingLength = textLength + lengthFronNewLines;

    const maxPossibleRows = oneRowLimited
      ? 1
      : height / (fontSize * lineHeight);
    const maxPossibleLength = maxPossibleRows * charactersPerLine;

    if (workingLength >= maxPossibleLength) {
      // lessen fontSize till fits
      fontSize -= 1;

      // if made as small as we can, now start reducing text length till fits
      if (fontSize <= minFontSize) {
        fontSize = minFontSize;
        textLength -= 1;
      }
    } else {
      fontSizeFits = true;
    }
  }

  const response: IFontSizeResult = { fontSize };

  if (textLength < text.length) {
    response.reducedTextLength = textLength;
  }

  return response;
}
