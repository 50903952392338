import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MgAnnouncementShortCardModule } from 'minga/app/src/app/elements/MgShortCard/MgAnnouncementShortCard';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { UgcLinksContentPipeModule } from 'minga/app/src/app/ugc-links/pipes/UgcLinksContent';

import { AnnouncementLongCardComponent } from './AnnouncementLongCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgAnnouncementShortCardModule,
    UgcLinksContentPipeModule,
    MgOverlayToolsModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [AnnouncementLongCardComponent],
  exports: [AnnouncementLongCardComponent],
})
export class AnnouncementLongCardModule {}
