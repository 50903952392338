export namespace IResolvedContentLinkValue {
  export function defaultValue(): IResolvedContentLinkValue {
    return {
      context: '',
    };
  }
}

export interface IResolvedContentLinkValue {
  context: string;
  contentHash?: string;
  authorHash?: string;
  overlay?: boolean; // whether this is being used in an overlay
}

export function resolveContentLinkValue(value: any) {
  const result = IResolvedContentLinkValue.defaultValue();

  // Assumed to be the content context if value is a string
  if (typeof value === 'string') {
    result.context = value;
  } else if (value && typeof value === 'object') {
    // @HACK: If our value is an object we look at a few common properties

    if (typeof value.contentContextHash === 'string') {
      result.context = value.contentContextHash;
    }

    if (typeof value.contentHash === 'string') {
      result.contentHash = value.contentHash;
    }

    if (value.authorPersonView) {
      // Potentially an author person view not necessarily
      const authorPersonView: any = value.authorPersonView;

      if (typeof authorPersonView.personHash === 'string') {
        result.authorHash = authorPersonView.personHash;
      }
    }

    // whether this is being used in an overlay or not.
    if (value.overlay) {
      result.overlay = value.overlay;
    }

    if (!result.context) {
      console.warn('Bad resolveContentLinkValue value:', value);
    }
  } else {
    console.warn('Unhandled resolveContentLinkValue:', value);
  }

  return result;
}
