<div class="mg-like">
  <div
    class="action icon like"
    [ngClass]="
      ' liked-' + isLiked() +
      ' clicked-' + likeClicked() +
      ' hide-icon-' + getHideIconState() +
      ' hide-count-' + hidecount +
      ' show-text-' + showtext
    ">
    <div
      class="action-hit-area mg-cancel-swipe-stack-tap"
      (tap)="like($event)"></div>
    <mg-icon class="like">like</mg-icon>
    <mg-icon class="like-active">like-active</mg-icon>
  </div>
  <span class="like-count">{{getDisplayCount()}}</span>&nbsp;<span
    class="like-text"
    >{{getLikeText()}}</span
  >
</div>
