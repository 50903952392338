import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ModalOverlayService } from '@shared/components/modal-overlay';

import { CheckinManagerReasonsComponent } from '../checkin-manager-reasons.component';

@Injectable()
export class CheckinManagerReasonsDeactivateGuard
  implements CanDeactivate<CheckinManagerReasonsComponent>
{
  isOverlayOpen: boolean = false;

  /** Service Constructor */
  constructor(private _modalOverlay: ModalOverlayService) {
    this._modalOverlay.isOpen.subscribe(isOpen => {
      this.isOverlayOpen = isOpen;
    });
  }

  /**
   * Con Deactivate
   */
  canDeactivate(component: CheckinManagerReasonsComponent) {
    return this.isOverlayOpen ? false : true;
  }
}
