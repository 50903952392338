export enum DatePresetMessages {
  SNACKBAR_CREATE_SUCCESS = 'New custom date range added',
  SNACKBAR_UPDATE_SUCCESS = 'Custom date range saved',
  SNACKBAR_DELETE_SUCCESS = 'Custom date range deleted',
  SNACKBAR_TOOGLE_ACTIVE_SUCCESS = 'Custom date range activated',
  SNACKBAR_TOOGLE_DEACTIVE_SUCCESS = 'Custom date range deactivated',

  LIST_MODAL_TITLE = 'Manage Date Ranges',
  LIST_TABLE_TITLE = 'Title',
  LIST_TABLE_EDIT = 'Edit',
  LIST_TABLE_ACTIVE = 'Active',
  LIST_ADD_BTN = 'Add date range',
  LIST_MODAL_BACK = 'Back',
}
