import { OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgSelectModule } from '@ng-select/ng-select';

import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { CalendarModule } from '@shared/components/calendar';
import { SlugifyPipeModule } from '@shared/pipes/slugify/slugify-pipe.module';

import { GenericModule } from '../generic';
import { TextModule } from '../text/text.module';
import { TooltipModule } from '../tooltip';
import { FormCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormDateRangeComponent } from './components/form-date-range/form-date-range.component';
import { FormDateRangeModule } from './components/form-date-range/form-date-range.module';
import { FormDateComponent } from './components/form-date/form-date.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { FormGroupedSelectComponent } from './components/form-grouped-select/form-grouped-select.component';
import { FormMultiDateComponent } from './components/form-multi-date/form-multi-date.component';
import { FormSelectOptionComponent } from './components/form-select/form-select-option/form-select-option.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormTextInputComponent } from './components/form-text-input/form-text-input.component';
import { FormToggleComponent } from './components/form-toggle/form-toggle.component';
import {
  MingaPartnerSearchDirective,
  MingaSearchDirective,
  MingaSwitcherDirective,
  OutputValueOnlyDirective,
  TeacherSearchDirective,
} from './directives';
import { MakeInputErrorMessagePipe } from './pipes';
import { FormTextAreaPreviewPipe } from './pipes/form-textarea-preview.pipe';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    CalendarModule,
    TooltipModule,
    GenericModule,
    FormDateRangeModule,
    SlugifyPipeModule,

    // External dependencies
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkScrollableModule,
    ScrollingModule,
    NgSelectModule,
    MatSlideToggleModule,
    OverlayModule,
    TextModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormDateRangeModule,
    MatTooltipModule,
  ],
  exports: [
    // Directives
    MingaSwitcherDirective,
    MingaPartnerSearchDirective,
    MingaSearchDirective,
    OutputValueOnlyDirective,
    TeacherSearchDirective,

    // Components
    FormSelectComponent,
    FormTextInputComponent,
    FormToggleComponent,
    FormErrorComponent,
    FormCheckboxComponent,
    FormMultiDateComponent,
    FormSelectOptionComponent,
    FormDateComponent,
    FormDateRangeComponent,
    FormGroupedSelectComponent,
    // Pipes
    MakeInputErrorMessagePipe,
  ],
  declarations: [
    // Pipes
    MakeInputErrorMessagePipe,
    FormTextAreaPreviewPipe,

    // Directives
    MingaSwitcherDirective,
    MingaSearchDirective,
    MingaPartnerSearchDirective,
    OutputValueOnlyDirective,
    TeacherSearchDirective,

    // Components
    FormTextInputComponent,
    FormSelectComponent,
    FormToggleComponent,
    FormErrorComponent,
    FormCheckboxComponent,
    FormMultiDateComponent,
    FormSelectOptionComponent,
    FormDateComponent,
    FormGroupedSelectComponent,
  ],
})
export class FormModule {}
