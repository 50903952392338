<ng-container [ngSwitch]="hover">
  <div
    *ngSwitchCase="'background'"
    class="mg-navicon-container background"
    matRipple>
    <mg-icon *ngSwitchCase="'background'">{{ icon }}</mg-icon>
  </div>
  <mg-button-icon
    [icon]="icon"
    *ngSwitchDefault></mg-button-icon>
</ng-container>
