import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from 'minga/app/src/app/button';
import { EventGoingStateModule } from 'minga/app/src/app/components/EventGoingState';
import { GalleryPhotoEventStreamModule } from 'minga/app/src/app/components/GalleryPhotoEventStream';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { PointsBannerModule } from 'minga/app/src/app/components/Points/PointsBanner';
import { PhotoGalleryAddPhotoModule } from 'minga/app/src/app/components/TopOfFeed/PhotoGalleryAddPhoto';
import { PhotoGalleryPreviewModule } from 'minga/app/src/app/components/TopOfFeed/PhotoGalleryPreview';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgDateBadgeModule } from 'minga/app/src/app/elements/MgDateBadge';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgPersonMinimalModule } from 'minga/app/src/app/elements/MgPersonMinimal';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { UgcLinksContentPipeModule } from 'minga/app/src/app/ugc-links/pipes/UgcLinksContent';
import { EventPeopleModule } from 'src/app/components/Overlay/EventPeople';

import { GenericButtonModule } from '@shared/components/button';
import { GenericModule } from '@shared/components/generic';
import { MembershipListTableModule } from '@shared/components/membership-list-table';

import { LongCardBaseModule } from '../LongCardBase';
import { EventLongCardComponent } from './EventLongCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgDateBadgeModule,
    MgButtonModule,
    MgAvatarModule,
    MgPersonMinimalModule,
    MgEllipsisModule,
    MgPipesModule,
    LongCardBaseModule,
    MgImageModule,
    UgcLinksContentPipeModule,
    PermissionsModule,
    PhotoGalleryPreviewModule,
    PhotoGalleryAddPhotoModule,
    MgOverlayToolsModule,
    MgContentLinkModule,
    MgIconModule,
    PointsBannerModule,
    EventGoingStateModule,
    GalleryPhotoEventStreamModule,
    GenericButtonModule,
    GenericModule,
    MembershipListTableModule,
    EventPeopleModule,

    // External dependencies
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    RouterModule,
    MatIconModule,
  ],
  declarations: [EventLongCardComponent],
  exports: [EventLongCardComponent],
})
export class EventLongCardModule {}
