import {
  Injectable,
  TemplateRef,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

export type OverlayStructureRegionId =
  | 'top'
  | 'bottom'
  | 'split-content'
  | 'cover'
  | 'header';

/**
 * additive - Adds another overlay template without removing others
 * replace - Adds another overlay template and ignore previously registered.
 *           @NOTE this will not replace templates added _after_ this one
 * override - Only this template will be created. All other registered templates
 *            are ignored.
 */
export type OverlayStructureTemplateAddMode =
  | 'additive'
  | 'replace'
  | 'override';

@Injectable({ providedIn: 'root' })
export class OverlayStructureRegistry {
  private _viewRefs: WeakMap<ViewContainerRef, ViewRef>;
  private _structureTemplatesMap: Map<
    OverlayStructureRegionId,
    [TemplateRef<any>, OverlayStructureTemplateAddMode][]
  >;
  private _structureViewContainersMap: Map<
    OverlayStructureRegionId,
    ViewContainerRef[]
  >;

  constructor() {
    this._viewRefs = new WeakMap();
    this._structureTemplatesMap = new Map();
    this._structureViewContainersMap = new Map();
  }

  hasStructureTemplate(structureId: OverlayStructureRegionId): boolean {
    const templates = this.getTemplates(structureId);
    return templates.length > 0;
  }

  addStructureTemplate(
    structureId: OverlayStructureRegionId,
    template: TemplateRef<any>,
    mode: OverlayStructureTemplateAddMode,
  ) {
    const templates = this.getTemplates(structureId);
    templates.push([template, mode]);
    this.setupAllViewContainers(structureId);
  }

  removeStructureTemplate(
    structureId: OverlayStructureRegionId,
    template: TemplateRef<any>,
  ) {
    const templates = this.getTemplates(structureId);

    const index = templates.findIndex(([t]) => t === template);
    if (index !== -1) {
      templates.splice(index, 1);
      this.setupAllViewContainers(structureId);
    }
  }

  addStructureViewContainer(
    structureId: OverlayStructureRegionId,
    viewContainer: ViewContainerRef,
  ) {
    const viewContainers = this.getViewContainers(structureId);
    viewContainers.push(viewContainer);
    this.setupAllViewContainers(structureId);
  }

  removeStructureViewContainer(
    structureId: OverlayStructureRegionId,
    viewContainer: ViewContainerRef,
  ) {
    const viewContainers = this.getViewContainers(structureId);
    const index = viewContainers.indexOf(viewContainer);
    if (index !== -1) {
      viewContainers.splice(index, 1);
      this.setupAllViewContainers(structureId);
    }
  }

  private setupAllViewContainers(structureId: OverlayStructureRegionId) {
    const viewContainers = this.getViewContainers(structureId);
    for (const viewContainer of viewContainers) {
      this.setupViewContainer(structureId, viewContainer);
    }
  }

  private setupViewContainer(
    structureId: OverlayStructureRegionId,
    viewContainer: ViewContainerRef,
  ) {
    const templates = this.getTemplates(structureId);

    viewContainer.clear();

    for (let i = templates.length - 1; i + 1 > 0; --i) {
      const [template, mode] = templates[i];
      if (mode === 'override') {
        viewContainer.createEmbeddedView(template);
        return;
      }
    }

    for (let i = templates.length - 1; i + 1 > 0; --i) {
      const [template, mode] = templates[i];
      if (mode === 'override') continue;

      viewContainer.createEmbeddedView(template);
      if (mode === 'replace') break;
    }
  }

  private getTemplates(
    structureId: OverlayStructureRegionId,
  ): [TemplateRef<any>, OverlayStructureTemplateAddMode][] {
    let templates = this._structureTemplatesMap.get(structureId);
    if (!templates) {
      templates = [];
      this._structureTemplatesMap.set(structureId, templates);
    }

    return templates;
  }

  private getViewContainers(
    structureId: OverlayStructureRegionId,
  ): ViewContainerRef[] {
    let viewContainers = this._structureViewContainersMap.get(structureId);
    if (!viewContainers) {
      viewContainers = [];
      this._structureViewContainersMap.set(structureId, viewContainers);
    }

    return viewContainers;
  }
}
