<mg-lightbox #lightbox>
  <mg-icon
    class="close-icon"
    (click)="close()"
    >close-active</mg-icon
  >

  <div class="content-container">
    <div
      class="nav-left"
      [class.disabled]="!hasPrevious()">
      <mg-icon (click)="slidePrevious()">left-arrow-active</mg-icon>
    </div>

    <div
      class="nav-right"
      [class.disabled]="!hasNext()">
      <mg-icon (click)="slideNext()">right-arrow-active</mg-icon>
    </div>

    <div
      class="items-container"
      #itemsContainerElement>
      <div
        *ngFor="
          let viewItem of viewItems$ | async;
          let i = index;
          trackBy: _trackBy
        "
        class="item-container"
        [class.item-active]="viewItem.item.index == _activeGlobalIndex"
        [style.transform]="
          'translateX(' + (viewItem.item.index - i) * 100 + 'vw)'
        ">
        <div
          class="item-content-wrap"
          [style.width.px]="
            lightBoxImageWidth(
              viewItem.item.imageInfo,
              viewItem.item.tags.length > 0
            )
          ">
          <div class="item-content">
            <div
              class="mg-smallcopy photo-count"
              fxLayout="row"
              fxLayoutGap="8px"
              fxLayoutAlign="start center">
              <div fxFlex="auto">
                Image {{ viewItem.item.index + 1 }} of
                {{ dataSource?.total$ | async }}
              </div>
              <div
                *ngIf="
                  viewItem.item.shouldShowResolve &&
                  viewItem.moderationOptions?.resolve
                ">
                <mg-button
                  class="micro"
                  type="outline"
                  color="green"
                  (click)="resolveItemClick($event, viewItem.item)"
                  >Resolve</mg-button
                >
              </div>
              <div *ngIf="viewItem.moderationOptions?.publish">
                <mg-button
                  class="micro"
                  type="outline"
                  color="green"
                  (click)="publishItemClick($event, viewItem.item)"
                  >Publish</mg-button
                >
              </div>
              <div
                *ngIf="
                  viewItem.item.shouldShowReport &&
                  viewItem.moderationOptions?.report
                "
                title="Report">
                <mg-icon (click)="reportItemClick($event, viewItem.item)"
                  >warning-o</mg-icon
                >
              </div>
              <div
                *ngIf="
                  viewItem.item.shouldShowDelete &&
                  viewItem.moderationOptions?.delete
                "
                title="Delete">
                <mg-icon (click)="deleteItemClick($event, viewItem.item)"
                  >trash-o</mg-icon
                >
              </div>
            </div>

            <div
              mgPinchZoom
              class="image-container">
              <mg-image
                (contextmenu)="onContextmenu($event, viewItem.item)"
                [active]="viewItem.item.index == _activeGlobalIndex || false"
                [style.height.px]="
                  lightBoxImageHeight(
                    viewItem.item.imageInfo,
                    viewItem.item.tags.length > 0
                  )
                "
                [srcs]="
                  viewItem.item.imageInfo
                    | mgImageUrls: ['blurloading1', 'longcardbanner']
                ">
              </mg-image>
            </div>

            <div class="mg-smallcopy author-info">
              <strong>{{ viewItem.item.authorDisplayName }}</strong
              >,&nbsp;
              <span>{{ viewItem.item.timestamp | date: 'MMM d, y' }}</span>
            </div>

            <div
              class="event-tags mg-smallcopy"
              *ngFor="
                let eventTag of viewItem.viewEvents$ | async;
                let l = count;
                let i = index
              ">
              <span *ngIf="i == 0 && l == 1">Event:&nbsp;</span>
              <span *ngIf="i == 0 && l > 1">Events:&nbsp;</span>
              <a
                href="javascript:;"
                class="has-click-handler"
                (click)="openEventGallery(eventTag.contextHash)">
                <span class="tag-name">{{ eventTag.contentTagTitle }}</span>
              </a>
              <span *ngIf="i !== (l || 0) - 1">,&nbsp;</span>
            </div>

            <div
              class="photo-description mg-smallcopy"
              [innerHTML]="viewItem.item.description | mgSafeHtml"></div>

            <div
              *ngIf="(viewItem.viewTags$ | async)?.length > 0"
              class="gallery-tags mg-smallcopy">
              <span>With:&nbsp;</span>
              <ng-container
                *ngFor="let tag of viewItem.viewTags$ | async; let i = index">
                <a
                  href="javascript:;"
                  (click)="tag.clickHandler && tag.clickHandler($event)"
                  [class.has-click-handler]="tag.clickHandler"
                  class="gallery-tag">
                  <span class="tag-name">{{ tag.name$ | async }}</span>
                </a>
                <span
                  *ngIf="i !== ((viewItem.viewTags$ | async)?.length || 0) - 1"
                  >,&nbsp;</span
                >
              </ng-container>
              <span *ngIf="((viewItem.hiddenTagsCount$ | async) || 0) > 0">
                ... and {{ viewItem.hiddenTagsCount$ | async }} more
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mg-lightbox>
