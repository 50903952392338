<span class="display-name archived-{{person.archived}}">{{displayName}}</span>
<span
  class="badge"
  title="{{person.badgeRoleName}}">
  <ng-container *ngIf="person.badgeIconUrl">
    <span
      class="badge-img"
      [style.backgroundImage]="'url(' + person.badgeIconUrl + ')'"></span>
    <span class="badge-img placeholder"></span>
  </ng-container>
</span>
