import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { closeCurrentOverlay } from 'minga/app/src/app/util/overlay';
import { EventContentService } from 'src/app/minimal/services/EventContent';
import { LikeService } from 'src/app/minimal/services/Like';
import { PollAnswerStateService } from 'src/app/minimal/services/PollAnswerState';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';

import { TypeEnum, TypeUnion } from './rootActions';

@Injectable()
export class RootEffects {
  constructor(
    private _actions$: Actions<TypeUnion>,
    private _contentEvents: ContentEvents,
    private _router: Router,
    private _pollAnswerState: PollAnswerStateService,
    private _eventContent: EventContentService,
    private _likeService: LikeService,
    private _mingaStore: MingaStoreFacadeService,
  ) {}

  @Effect({ dispatch: false })
  displayModerationFailed$: Observable<void> = this._actions$.pipe(
    ofType(TypeEnum.ModerationFailed),
    map(action => {
      const moderation = action.data.moderation;

      this._contentEvents.emitFailedModeration({
        contentHash: action.data.contentHash || '',
        contextHash: action.data.contextHash || '',
        galleryPhotoUuid: action.data.galleryPhotoUuid || '',
        imageFailed: !moderation.allImageContentPassed,
        imageTextFailed:
          !moderation.allImageTextContentPassed &&
          moderation.allImageContentPassed,
        tags: moderation.tags,
        imageTags: moderation.imageTags,
        emailContentSend: action.data.emailContentSend,
      });

      setTimeout(() => {
        closeCurrentOverlay(this._router, {
          replaceUrl: true,
        });
      }, 300);
    }),
  );

  logout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TypeEnum.Logout),
        map(() => {
          this._pollAnswerState.clear();
          this._eventContent.clear();
          this._likeService.clear();
          this._mingaStore.clearCachedMingaSettings();
        }),
      ),
    { dispatch: false },
  );
}
