import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ToolsMessages } from '@modules/tools/tools.constants';

import { MediaService } from '@shared/services/media';

@Component({
  selector: 'mg-tools-notifications',
  templateUrl: './tools-notifications.component.html',
  styleUrls: ['./tools-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsNotificationsComponent {
  public MESSAGES = ToolsMessages;
  constructor(public media: MediaService) {}
}
