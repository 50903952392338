<div fxLayout="row">
  <mg-button
    class="assess-button approve-button"
    *ngIf="!isApproved()">
    <div
      class="assess-button-content"
      (click)="onApproveClicked($event)">
      <mat-icon>check</mat-icon>
      <div>Approve</div>
    </div>
  </mg-button>
  <mg-button
    class="assess-button decline-button"
    *ngIf="!isDenied()">
    <div
      class="assess-button-content"
      (click)="onDeclineClicked($event)">
      <mat-icon>close</mat-icon>
      <div>Decline</div>
    </div>
  </mg-button>
</div>

<ng-template #declineOverlay>
  <div class="decline-title">
    Tell this person why you have not approved this response
  </div>
  <mg-textarea
    class="optional-decline-reason"
    name="declineChallengeResponse"
    placeholder="Optional"
    [(ngModel)]="declineResponseReason">
  </mg-textarea>
  <div class="button-wrapper">
    <mg-button class="assess-button decline-button overlay-button">
      <div
        class="assess-button-content"
        (click)="onSendDeclinedClicked()">
        <mat-icon>close</mat-icon>
        <div>Decline</div>
      </div>
    </mg-button>
  </div>
</ng-template>
