import { createSelector, MemoizedSelector } from '@ngrx/store';

import { GroupFormState } from '../state';

import { getGroupsState } from './feature-selector';

export const getGroupFormState: MemoizedSelector<any, GroupFormState> =
  createSelector(getGroupsState, state => state.groupForm);

export const getLoadingGroupForm: MemoizedSelector<GroupFormState, boolean> =
  createSelector(getGroupFormState, state => state.loading);
