<div
  class="modal-body"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="16px"
  [ngStyle.gt-sm]="{ 'min-width.px': 668, 'min-height.px': 170 }">
  <!-- Help text -->
  <mg-text
    *ngIf="type === 'all' || type === 'report'"
    variant="body-xs"
    color="surface-alt"
    textAlign="left">
    Update the automation group counter for the behavior(s) triggering its
    automations
  </mg-text>
  <!-- Student size -->
  <div
    *ngIf="type === 'selection'"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-text variant="body-md">Student(s) to update</mg-text>
    </div>
    <div>
      <mg-btn
        variant="text"
        size="small"
        iconSet="minga"
        icon="mg-user-list"
        (pressed)="editSelection.emit()">
        {{ selectionSize$ | async }}
      </mg-btn>
    </div>
  </div>
  <!-- Count field -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-text variant="body-md"> New automation counter value </mg-text>
    </div>
    <div class="number-dropdown">
      <mg-form-text-input
        inputType="number"
        [control]="countFC">
      </mg-form-text-input>
    </div>
  </div>
</div>
