<div class="mg-full-graphic-mini-card">
  <div
    class="full-graphic-image"
    [style.backgroundImage]="'url(' + imageUrl + ')'"></div>

  <div class="full-graphic-image-placeholder"></div>

  <div
    #gradientElement
    class="full-graphic-gradient"></div>

  <div class="actions">
    <mg-like
      class="action mg-cancel-content-link"
      hideicon
      showtext
      [context]="context"
      [count]="content?.likeCount || 0"></mg-like>
    <a
      class="action"
      (click)="commentActionClick($event)"
      >{{commentsActionText}}</a
    >
  </div>
</div>
