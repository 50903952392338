import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { CommonUIModules } from '@shared/modules';

import { LayoutHeaderComponent } from './layout-header.component';

@NgModule({
  imports: [
    // Minga dependencies
    CommonUIModules,

    // External dependencies
    MatMenuModule,
  ],
  declarations: [LayoutHeaderComponent],
  exports: [LayoutHeaderComponent],
})
export class LayoutHeaderModule {}
