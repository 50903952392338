import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { BlockButton } from '../BlockButton/BlockButton.component';

@Component({
  selector: 'mg-button-outline',
  templateUrl: './OutlineButton.component.html',
  styleUrls: ['./OutlineButton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutlineButton extends BlockButton {
  @Input()
  type = '';
  @Input()
  color: string = 'primary';
  typePostFix = 'outline';

  constructor() {
    super();
  }
}
