import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

/**
 * Component for displaying step indicator 'dots'.
 */
@Component({
  selector: 'mg-step-indicator',
  templateUrl: './StepIndicator.component.html',
  styleUrls: ['./StepIndicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepIndicatorComponent {
  /**
   * Amount of steps. This corresponds to the amount of dots displayed
   */
  @Input()
  stepCount: number = 0;

  /**
   * The current active step
   */
  @Input()
  activeStep: number = 0;

  /**
   * If `allowStepClickNavigation` is true `activeStepChange` will trigger when
   * a dot is clicked.
   */
  @Output()
  activeStepChange: EventEmitter<number> = new EventEmitter();

  /**
   * If `activeStepChange` is true the active step will change when a dot is
   * clicked.
   */
  @HostBinding('class.mg-allow-step-click')
  @Input()
  allowStepClickNavigation: boolean = false;

  @Input()
  disableForwardDotNavigation: boolean = false;

  clickStepNode(index: number) {
    if (this.allowStepClickNavigation) {
      if (this.disableForwardDotNavigation && index > this.activeStep) {
        return;
      }
      this.activeStep = index;
      this.activeStepChange.emit(this.activeStep);
    }
  }
}
