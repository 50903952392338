<div class="root-content-wrap">
  <!-- mobile tools section -->
  <div
    fxHide.gt-sm
    class="mobile-tools">
    <ng-container *ngTemplateOutlet="mobileTools"></ng-container>
  </div>

  <!-- desktop tools section -->
  <div
    fxHide
    fxShow.gt-sm
    fxLayout="column"
    fxLayoutAlign="center center"
    class="desktop-tools">
    <ng-container *ngTemplateOutlet="desktopTools"></ng-container>
  </div>

  <section class="section container groups-section">
    <mg-tools-stickers [personHash]="authHash$ | async"></mg-tools-stickers>
  </section>

  <section class="section container groups-section">
    <mg-tools-groups></mg-tools-groups>
  </section>
</div>

<ng-template #mobileTools>
  <section class="section container">
    <mg-text
      variant="header-md-secondary"
      spacing="1">
      {{ MESSAGES.TITLE_TOOLS }}
    </mg-text>
    <div
      fxLayout="row wrap"
      class="tools-wrap">
      <ng-container
        *ngIf="isStudentIdEnabled$ | async"
        [ngTemplateOutlet]="idSection">
      </ng-container>

      <!-- Hall pass -->
      <ng-container *ngIf="canCreateHallPass$ | async">
        <ng-container
          *ngIf="
            hallPassOptions
              | filterHpOptions: isHallPassEnabled$
              | async as filteredHpOptions
          "
          [ngTemplateOutlet]="hallPassSection"
          [ngTemplateOutletContext]="{
            $implicit: filteredHpOptions
          }">
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="(isPbisEnabled$ | async) && (isLeaderboardEnabled$ | async)"
        [ngTemplateOutlet]="leaderboardSection">
      </ng-container>
      <ng-container
        *ngIf="(isPbisEnabled$ | async) && (canUserTrackBehaviors$ | async)"
        [ngTemplateOutlet]="behavioursSection">
      </ng-container>
      <ng-container
        *ngIf="isCheckinEnabled$ | async"
        [ngTemplateOutlet]="selfCheckinTemplate">
      </ng-container>
    </div>
  </section>
</ng-template>

<ng-template #desktopTools>
  <section
    class="section container"
    fxLayout="row"
    fxLayoutAlign="center center">
    <section
      *ngIf="isStudentIdEnabled$ | async"
      fxLayout="column"
      fxLayoutGap="16px"
      class="sub-section">
      <mg-text
        variant="header-md-secondary"
        textAlign="center">
        {{ MESSAGES.TITLE_ID_TOOLS }}
      </mg-text>
      <div fxLayout="row">
        <ng-container [ngTemplateOutlet]="idSection"> </ng-container>
      </div>
    </section>
    <section
      *ngIf="(isHallPassEnabled$ | async) && (canCreateHallPass$ | async)"
      fxLayout="column"
      fxLayoutGap="16px"
      class="sub-section">
      <mg-text
        variant="header-md-secondary"
        textAlign="center">
        {{ MESSAGES.TITLE_HALL_PASS_TOOLS }}
      </mg-text>
      <div fxLayout="row">
        <ng-container [ngTemplateOutlet]="hallPassSection"> </ng-container>
      </div>
    </section>

    <section
      *ngIf="isCheckinEnabled$ | async"
      fxLayout="column"
      fxLayoutGap="16px"
      class="sub-section">
      <mg-text
        variant="header-md-secondary"
        textAlign="center">
        {{ MESSAGES.TITLE_CHECKIN_TOOLS }}
      </mg-text>
      <div fxLayout="row">
        <ng-container [ngTemplateOutlet]="selfCheckinTemplate"> </ng-container>
      </div>
    </section>

    <section
      *ngIf="(isPbisEnabled$ | async) && (isLeaderboardEnabled$ | async)"
      fxLayout="column"
      fxLayoutGap="16px"
      class="sub-section">
      <mg-text
        variant="header-md-secondary"
        textAlign="center">
        {{ MESSAGES.TITLE_REWARDS_TOOLS }}
      </mg-text>
      <div fxLayout="row">
        <ng-container [ngTemplateOutlet]="leaderboardSection"></ng-container>
      </div>
    </section>
  </section>

  <section
    *ngIf="(isPbisEnabled$ | async) && (canUserTrackBehaviors$ | async)"
    class="section container"
    fxLayout="row"
    fxLayoutyAlign="center center">
    <section
      fxLayout="column"
      fxLayoutGap="16px"
      class="sub-section">
      <mg-text
        variant="header-md-secondary"
        textAlign="center">
        {{ MESSAGES.TITLE_PBIS_TOOLS }}
      </mg-text>
      <div fxLayout="row">
        <ng-container [ngTemplateOutlet]="behavioursSection"></ng-container>
      </div>
    </section>
  </section>
</ng-template>

<ng-template #idSection>
  <ng-container *ngIf="isDigitalIDEnabled$ | async">
    <mg-tools-link
      analyticEventName="studentToolsViewId"
      [label]="MESSAGES.ID_TOOLS_YOUR_ID"
      [asset]="'/assets/teacher-tools/digital_id_img.svg'"
      (click)="openViewId()">
    </mg-tools-link>
  </ng-container>
</ng-template>

<ng-template #hallPassSection>
  <mg-tools-link
    analyticEventName="studentToolsCreateHallPass"
    [label]="MESSAGES.HALL_PASS_CREATE"
    [asset]="'/assets/teacher-tools/hall_pass_img.svg'"
    (click)="handleLaunchHallpassAssignmentForm()">
  </mg-tools-link>
</ng-template>

<ng-template #leaderboardSection>
  <ng-container>
    <mg-tools-link
      analyticEventName="studentToolsViewLeaderboard"
      [label]="MESSAGES.LEADERBOARD"
      [asset]="'/assets/teacher-tools/leaderboard_img.svg'"
      [routerLink]="'/tools/points/leaderboard'">
    </mg-tools-link>
  </ng-container>
</ng-template>

<ng-template #behavioursSection>
  <div
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="16px">
    <ng-container
      *ngFor="let option of pbisOptions | filterPbisOptions | async">
      <mg-tools-link
        [analyticEventName]="'studentToolsViewPbis_' + option.type"
        [label]="option.label"
        [icon]="option.icon"
        [asset]="option.asset"
        (click)="handlePbisTools(option.type)">
      </mg-tools-link>
    </ng-container>
  </div>
</ng-template>

<ng-template #selfCheckinTemplate>
  <ng-container *ngIf="isCheckinEnabled$ | async">
    <mg-tools-link
      analyticEventName="studentToolsSelfCheckin"
      [label]="MESSAGES.SELF_CHECKIN"
      [asset]="'/assets/teacher-tools/check_in_img.svg'"
      (click)="openSelfCheckScanner(false)">
    </mg-tools-link>
  </ng-container>
  <!-- DISABLE STUDENT SELF CHECKOUT TEMPORARILY -->
  <!-- <mg-tools-link
    analyticEventName="studentToolsSelfCheckout"
    [label]="MESSAGES.SELF_CHECKOUT"
    [asset]="'/assets/teacher-tools/check_in_img.svg'"
    (click)="openSelfCheckScanner(true)">
  </mg-tools-link> -->
</ng-template>
