import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Dictionary, EntitySelectors } from '@ngrx/entity/src/models';
import { createFeatureSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';

import { NotificationStoreModel } from '../model/notification.model';

/**
 * Notification store just uses the default entity state, with no additional
 * fields.
 */
export interface NotificationsState
  extends EntityState<NotificationStoreModel> {}

export const selectNotificationState: MemoizedSelector<
  any,
  NotificationsState
> = createFeatureSelector<NotificationsState>('notifications');

export namespace NotificationsState {
  export const entityAdapter: EntityAdapter<NotificationStoreModel> =
    createEntityAdapter<NotificationStoreModel>({
      selectId: (notification: NotificationStoreModel) => notification.id || '',
    });

  /**
   * @internal use NotificationsState.select*() methods instead
   */
  export const _selectors = entityAdapter.getSelectors(selectNotificationState);

  export const initialState = entityAdapter.getInitialState({});

  export const selectAll: (state: any) => NotificationStoreModel[] =
    _selectors.selectAll;

  export const selectEntities: (
    state: any,
  ) => Dictionary<NotificationStoreModel> = _selectors.selectEntities;

  export const selectIds: (state: any) => string[] | number[] =
    _selectors.selectIds;

  export const selectTotal: (state: any) => number = _selectors.selectTotal;
}
