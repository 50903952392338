import { Injectable } from '@angular/core';
import { ReportService } from 'minga/app/src/app/services/Report';
import { MingaGallery } from 'minga/proto/gateway/gallery_ng_grpc_pb';
import {
  MingaGalleryCreateRequest,
  MingaGalleryDeleteRequest,
  MingaGalleryGetPhotoTotalRequest,
  MingaGallerySummaryRequest,
  MingaGallerySummaryResponse,
  Tag,
} from 'minga/proto/gateway/gallery_pb';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface IGallerySummaryFetchOptions {
  eventContentHash?: string;
}

export interface IAddGalleryPhotoOptions {
  imageAssets: string[];
  description: string;
  taggedGroups: string[];
  taggedPeople: string[];
  taggedContent: string[];
}

export interface IDeleteGalleryPhotoOptions {
  galleryPhotoUuid: string;
}

@Injectable({ providedIn: 'root' })
export abstract class GalleryServiceBase {
  protected _loading: BehaviorSubject<boolean>;
  protected _summary: BehaviorSubject<MingaGallerySummaryResponse.AsObject | null>;

  readonly loading$: Observable<boolean>;
  readonly summary$: Observable<MingaGallerySummaryResponse.AsObject>;

  constructor(
    protected mingaGalleryProto: MingaGallery,
    protected reportService: ReportService,
  ) {
    this._loading = new BehaviorSubject<boolean>(false);
    this._summary =
      new BehaviorSubject<MingaGallerySummaryResponse.AsObject | null>(null);
    this.loading$ = this._loading.asObservable();
    this.summary$ = this._summary.pipe(
      filter(summary => !!summary),
      map(summary => summary as MingaGallerySummaryResponse.AsObject),
    );
  }

  /**
   * Get data for summary$; includes photototal, newPhotoCount, newestPhotos and
   * aspect ratio
   */
  abstract fetchSummary(): Promise<void>;

  /**
   * Get the number of photos for a given search query
   * @param search
   */
  abstract async getSearchPhotoTotal(search: string): Promise<number>;

  abstract async addPhoto(options: IAddGalleryPhotoOptions): Promise<void>;

  abstract async deletePhoto(
    options: IDeleteGalleryPhotoOptions,
  ): Promise<boolean>;

  abstract async resolvePhoto(galleryPhotoUuid: string): Promise<boolean>;

  protected async _addPhoto(
    summary: BehaviorSubject<MingaGallerySummaryResponse.AsObject | null>,
    options: IAddGalleryPhotoOptions,
  ) {
    const request = new MingaGalleryCreateRequest();
    request.setDescription(options.description);
    request.setImageAssetList(options.imageAssets);

    for (const taggedGroup of options.taggedGroups) {
      const tagMsg = new Tag();
      const groupTagMsg = new Tag.GroupTag();
      groupTagMsg.setGroupHash(taggedGroup);

      tagMsg.setGroupTag(groupTagMsg);
      request.addTag(tagMsg);
    }

    for (const taggedPerson of options.taggedPeople) {
      const tagMsg = new Tag();
      const personTagMsg = new Tag.PersonTag();
      personTagMsg.setPersonHash(taggedPerson);

      tagMsg.setPersonTag(personTagMsg);
      request.addTag(tagMsg);
    }

    for (const taggedContent of options.taggedContent) {
      const tagMsg = new Tag();
      const contentTagMsg = new Tag.ContentTag();
      contentTagMsg.setContentHash(taggedContent);

      tagMsg.setContentTag(contentTagMsg);
      request.addTag(tagMsg);
    }

    const response = await this.mingaGalleryProto.create(request);

    const moderationList = response.getModerationResultList();
    const photoUuidList = response.getGalleryPhotoUuidList();

    const moderationPassed =
      await this.reportService.handlePhotoModerationResults(
        moderationList,
        photoUuidList,
      );
    if (!moderationPassed) {
      return;
    }

    const summaryValue = summary.getValue();
    if (summaryValue) {
      summaryValue.photoTotal += photoUuidList.length;
      summary.next(summaryValue);
    }
  }

  protected async _deletePhoto(
    summary: BehaviorSubject<MingaGallerySummaryResponse.AsObject | null>,
    options: IDeleteGalleryPhotoOptions,
  ): Promise<boolean> {
    const request = new MingaGalleryDeleteRequest();
    request.setGalleryPhotoUuid(options.galleryPhotoUuid);
    const response = await this.mingaGalleryProto.delete(request);
    const success = response.getSuccess();

    if (success) {
      const summaryValue = this._summary.getValue();
      if (summaryValue) {
        summaryValue.photoTotal = Math.max(0, summaryValue.photoTotal - 1);
        summary.next(summaryValue);
      }
    }

    return success;
  }

  protected async _resolvePhoto(galleryPhotoUuid: string) {
    return await this.reportService.resolve({ galleryPhotoUuid });
  }
}
