import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';

import { ReplaySubject } from 'rxjs';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PsModalComponent } from './components/ps-modal/ps-modal.component';
import { PeopleSelectorService } from './services';
import {
  PeopleSelectorModalResponse,
  PeopleSelectorPageData,
  PeopleSelectorRouteData,
  PsData,
  PsModalData,
} from './types';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleSelectorComponent implements OnDestroy {
  /** General Subjects */
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  /** Component Constructor */
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _modalOverlay: ModalOverlayService<
      PeopleSelectorModalResponse,
      PsModalData
    >,
    private _peopleSelector: PeopleSelectorService,
    private _systemSnack: SystemAlertSnackBarService,
  ) {
    this._openModal(
      this._route.snapshot.url,
      this._route.snapshot.data as PeopleSelectorRouteData,
      this._router.getCurrentNavigation().extras.state as any,
    );
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
  }

  private async _openModal(
    url: UrlSegment[],
    { injectionToken }: PeopleSelectorRouteData,
    pageData: PeopleSelectorPageData | undefined,
  ): Promise<void> {
    const formPath = url[0].path;
    const pagePath = url[1].path;
    pageData =
      pageData ||
      this._peopleSelector.getPageDataFromSessionStorage(formPath, pagePath);
    if (!pageData) {
      this._systemSnack.open({
        type: 'error',
        message: 'Missing required page data',
      });
      return this._peopleSelector.close(true);
    }
    const preSelection: PsData[] | undefined = (pageData as any)?.data
      ?.preSelection;

    const modalRef = this._modalOverlay.open<PsModalData>(PsModalComponent, {
      hasBackdrop: true,
      data: {
        formPath,
        preSelection,
        pagePath,
        injectionToken,
      },
    });
    modalRef.afterClosed.subscribe(() => this._peopleSelector.close());
  }
}
