import { SpinnerSize } from './MgSpinner.types';

export const SPINNER_SIZE_MAP: Record<
  SpinnerSize,
  {
    width: number;
    stroke: number;
  }
> = {
  small: {
    width: 20,
    stroke: 4,
  },
  medium: {
    width: 24,
    stroke: 4,
  },
  large: {
    width: 60,
    stroke: 8,
  },
};
