import {
  PollLayout,
  ShortPollCardView,
} from 'minga/proto/gateway/content_views_pb';

export function isPollClosed(shortCard: ShortPollCardView.AsObject) {
  if (shortCard.closeTimestamp) {
    const now = Date.now();
    const closeDate = shortCard.closeTimestamp;

    if (now >= closeDate) {
      return true;
    }
  }

  return shortCard.pollClosed;
}

export function pollLayoutName(pollLayout: PollLayout) {
  switch (pollLayout) {
    case PollLayout.PL_GRID:
      return 'grid';
    case PollLayout.PL_LIST:
      return 'list';
  }
  return 'unknown';
}
