import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'mg-activate-deactivate-toggle-button',
  templateUrl: './ActivateDeactivateToggleButton.component.html',
  styleUrls: ['./ActivateDeactivateToggleButton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivateDeactivateToggleButtonComponent implements OnInit {
  @Input()
  initialState: boolean = false;

  @Input()
  activeText: string = 'ACTIVE';

  @Input()
  inactiveText: string = 'INACTIVE';

  @Output()
  onToggleStateChange: EventEmitter<boolean> = new EventEmitter();

  active: boolean = false;

  constructor() {}

  ngOnInit() {
    this.active = this.initialState;
  }

  public async setActiveState(value: boolean) {
    this.active = value;
  }

  onToggleStateClick() {
    this.active = !this.active;
    this.onToggleStateChange.emit(this.active);
  }
}
