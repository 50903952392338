<nav
  class="nav-wrap"
  fxLayout="row"
  fxFlex="100%"
  fxLayoutAlign="center center"
  fxLayoutAlign.gt-sm="end center">
  <div
    class="nav"
    fxLayout="row"
    fxLayoutAlign="center center">
    <ng-content></ng-content>
  </div>
</nav>
