import { Component, Inject } from '@angular/core';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';

import { MingaManagerSubscriptionMessage } from '../../constants';
import { PromotionalModalConfig } from '../../types';
import { MmSubscriptionUpgradeModalComponent } from '../mm-subscription-upgrade-modal/mm-subscription-upgrade-modal.component';

@Component({
  selector: 'mg-mm-subscription-feature-modal',
  templateUrl: './mm-subscription-feature-modal.component.html',
  styleUrls: ['./mm-subscription-feature-modal.component.scss'],
})
export class MmSubscriptionFeatureModalComponent {
  // Constants
  public readonly MESSAGES = MingaManagerSubscriptionMessage;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: PromotionalModalConfig,
    private _modalRef: ModalOverlayRef,
    private _modalOverlay: ModalOverlayService,
  ) {}

  public openUpgradeRequestModal() {
    this._modalOverlay.open(MmSubscriptionUpgradeModalComponent);
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }
}
