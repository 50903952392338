import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';

import {
  OverlayStructureRegionId,
  OverlayStructureRegistry,
} from '../../services/OverlayStructureRegistry';

@Directive({ selector: '[mgOverlayStructureView]' })
export class MgOverlayStructureViewDirective implements OnInit, OnDestroy {
  @Input()
  overlayStructureRegion: OverlayStructureRegionId | null = null;

  constructor(
    private viewContainer: ViewContainerRef,
    private overlayStructureRegistry: OverlayStructureRegistry,
  ) {}

  ngOnInit() {
    if (!this.overlayStructureRegion) {
      return;
    }

    this.overlayStructureRegistry.addStructureViewContainer(
      this.overlayStructureRegion,
      this.viewContainer,
    );
  }

  ngOnDestroy() {
    if (this.overlayStructureRegion) {
      this.overlayStructureRegistry.removeStructureViewContainer(
        this.overlayStructureRegion,
        this.viewContainer,
      );
    }
  }
}
