import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { LongCardBaseComponent } from 'minga/app/src/app/content-views/components/LongCardBase';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { calcFontSizeToFit } from 'minga/app/src/app/util/fontSizing';
import {
  ChallengeColor,
  ChallengePrivacy,
  ChallengeType,
} from 'minga/domain/challenge';
import { ChallengeResponseCard } from 'minga/proto/content/challenge/challenge_response_pb';
import { dateTimeObjectToDateObject } from 'minga/util/protobuf';

import { LongCardBaseClass } from '../LongCardBase/LongCardBaseClass';

const RESPONSE_PREVIEW_LIMIT = 3;

@Component({
  selector: 'mg-challenge-response-long-card',
  templateUrl: './ChallengeResponseLongCard.component.html',
  styleUrls: ['./ChallengeResponseLongCard.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChallengeResponseLongCardComponent
  extends LongCardBaseClass
  implements OnChanges, AfterViewInit
{
  bodyTextSize?: number;
  reducedBodyText?: string;
  expandedBodyText: boolean = false;
  useFixedCommentInput: boolean = false;

  @Input()
  content?: ChallengeResponseCard.AsObject;

  @Input()
  themeColor: ChallengeColor = ChallengeColor.RED;

  @Input()
  showApproval: boolean = false;

  @Output()
  approved: EventEmitter<string> = new EventEmitter();

  @Output()
  declined: EventEmitter<string> = new EventEmitter();

  publishDateTime: number = 0;
  changesSubject: Subject<any> = new Subject();

  @ViewChild('longCard', { static: true })
  longCardRef?: LongCardBaseComponent;

  @ViewChild('bodyOverImage', { static: false })
  bodyOverImageEl?: ElementRef;

  @HostListener('window:resize', [])
  onWindowResize() {
    this.calcBodyTextStyle();
  }

  @HostListener('click', ['$event'])
  onClick(ev: MouseEvent) {
    if (ev.target && (<any>ev.target).classList) {
      if (
        (<any>ev.target).classList.contains('mg-response-long-card-more-link')
      ) {
        this.expandedBodyText = true;
      }
    }
  }

  readonly bannerImageSizes: ReadonlyArray<string | string[]> = [
    'blurloading1',
    ['longcardbanner', 'cardbanner', 'banner'],
  ];

  constructor(
    contentEvents: ContentEvents,
    public media: MediaObserver,
    router: Router,
    groupFacade: GroupsFacadeService,
    contentState: ContentState,
    private _cdr: ChangeDetectorRef,
  ) {
    super(contentEvents, groupFacade, contentState, router);
  }

  get typeStyleClass() {
    if (!this.content || !this.content.type) return '';

    switch (this.content.type) {
      case ChallengeType.TEXT:
        return 'text';
      case ChallengeType.VIDEO:
        return 'video';
      case ChallengeType.IMAGE:
      default:
        return 'photo';
    }
  }

  get featuredImageBackgroundSize() {
    if (!this.content || !this.content.type) return 'cover';

    if (this.content.type == ChallengeType.TEXT) {
      return 'cover';
    } else {
      return 'contain';
    }
  }

  get themeColorClass() {
    return 'challenge-theme-color-' + this.themeColor;
  }

  get hasNoBackground() {
    return (
      !this.content ||
      (!this.content.featuredImage &&
        !this.content.backgroundImage &&
        !this.content.backgroundColor)
    );
  }

  get bodyText() {
    if (this.expandedBodyText) return this.content?.body || '';
    return this.reducedBodyText || this.content?.body || '';
  }

  ngAfterViewInit() {
    this.calcBodyTextStyle();
  }

  calcBodyTextStyle() {
    if (!(this.content?.type == 'TEXT')) {
      return;
    }
    const maxFontSize = 29;
    const minFontSize = 16;

    if (this.content && this.bodyOverImageEl) {
      let elWidth = this.bodyOverImageEl.nativeElement.offsetWidth;
      let elHeight = this.bodyOverImageEl.nativeElement.offsetHeight;
      const elStyles = window.getComputedStyle(
        this.bodyOverImageEl.nativeElement,
      );

      // get horizontal padding to subtract from width, only horizontal as
      // this element doesn't have vertical padding
      const paddingHorz =
        parseFloat(elStyles.paddingLeft) + parseFloat(elStyles.paddingRight);
      elWidth -= paddingHorz;

      if (this.bodyOverImageEl.nativeElement.children.length) {
        const bodyParagraphEl = this.bodyOverImageEl.nativeElement.children[0];
        const bodyElStyles = window.getComputedStyle(bodyParagraphEl);

        const marginVert =
          parseFloat(bodyElStyles.marginTop) +
          parseFloat(bodyElStyles.marginBottom);
        // subtract the vertical margin in the body paragraph from height
        elHeight -= marginVert;
      }
      const response = calcFontSizeToFit(
        this.content.body,
        elWidth,
        elHeight,
        maxFontSize,
        minFontSize,
      );
      this.bodyTextSize = response.fontSize;
      const reducedTextLength = response.reducedTextLength;

      // if had to reduce text length, add ellipsis at end
      if (reducedTextLength) {
        this.reducedBodyText =
          this.content.body.substring(0, reducedTextLength - 5) +
          ` <a class="mg-response-long-card-more-link">...More</a>`;
      }

      this._cdr.markForCheck();
    }
  }

  getFeaturedImageUrl() {
    if (this.content?.featuredImage) {
      return this.getImageUrl(this.content.featuredImage, [
        'longcardbanner',
        'cardbanner',
        'banner',
      ]);
    } else {
      return '';
    }
  }

  resetExpandedBodyText() {
    this.reducedBodyText = '';
    this.expandedBodyText = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      this.contextHash = this.content?.contextHash;
      if (this.content?.backgroundImage && !this.content?.featuredImage) {
        this.content.featuredImage = this.content.backgroundImage;
      }
      // reset when content changes
      this.resetExpandedBodyText();

      this.updatePublishDate();
      this.calcBodyTextStyle();

      // notify that there was a change in content
      this.changesSubject.next();
    }
  }

  updatePublishDate() {
    if (!this.content?.publishDate) {
      this.publishDateTime = 0;
      return;
    }

    this.publishDateTime = dateTimeObjectToDateObject(
      this.content.publishDate,
    ).getTime();
  }

  onApproved(contextHash: string) {
    this.approved.emit(contextHash);
  }

  onDeclined(contextHash: string) {
    this.declined.emit(contextHash);
  }
}
