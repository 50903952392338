import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgPollShortCardModule } from 'minga/app/src/app/elements/MgShortCard/MgPollShortCard';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { PollLongCardComponent } from './PollLongCard.component';

@NgModule({
  imports: [
    MgPollShortCardModule,
    MgPipesModule,
    MgOverlayToolsModule,
    PermissionsModule,

    CommonModule,
  ],
  declarations: [PollLongCardComponent],
  exports: [PollLongCardComponent],
})
export class PollLongCardModule {}
