export enum HpmTypesTableMessages {
  COLUMN_LABEL_TYPE_NAME = 'Pass Name',
  COLUMN_LABEL_STATUS = 'Active',
  COLUMN_LABEL_EDIT = 'Edit',
  COLUMN_LABEL_STUDENT_CREATED = 'Student Created',

  EMPTY_STATE_TITLE = 'No hall pass types found',
  EMPTY_STATE_SUBTITLE = 'To get started, create a new hall pass type',

  SNACK_UPDATE_SUCCESS = 'Hall Pass Type Updated Successfully',
  SNACK_DELETE_SUCCESS = 'Hall Pass Type Deleted Successfully',
  SNACK_CREATE_SUCCESS = 'Hall Pass Type Created Successfully',
  SNACK_UPDATE_FAIL = 'Error Updating Hall Pass type',
}

export const HP_TYPES_TABLE_COLUMNS: string[] = [
  'icon',
  'name',
  'status',
  'edit',
  'active',
];
