import { Injectable } from '@angular/core';
import { Data, Router } from '@angular/router';

import { NavPermissions } from 'minga/app/src/app/minimal/services/NavPermissions';

@Injectable({ providedIn: 'root' })
export class PbisRouteService {
  private _originPath = '';
  currentTab = '';

  constructor(
    private _router: Router,
    private _navPermissions: NavPermissions,
  ) {}

  gotoPbis(disableOrigin: boolean = false) {
    if (!disableOrigin) {
      this._originPath = this._router.url;
    }
    this._router.navigate(['/pbis', { outlets: { o: null } }]);
  }

  returnFromPBISRoute() {
    if (this._originPath === this._router.url) {
      this._router.navigateByUrl('/');
    } else {
      this._router.navigateByUrl(this._originPath || '/');
    }
  }

  /**
   * Checks if user has route permissions for a given tab.
   *
   * @param permissions data.permissions of student id route tab data
   */
  hasTabRoutePermissions(permissions: Data) {
    return this._navPermissions.hasTabRoutePermissions(permissions);
  }
}
