<div class="mg-empty-state">
  <img
    *ngIf="imageAsset"
    [src]="imageAsset" />
  <mg-text
    variant="header-md"
    color="outline"
    spacing="1">
    {{ title }}
  </mg-text>
  <mg-text
    variant="body-sm"
    color="outline">
    {{ subtitle }}
  </mg-text>

  <div
    *ngIf="actionButtonLabel"
    class="action-button-container">
    <mg-btn
      variant="outlined"
      (pressed)="handleAction.emit()">
      {{ actionButtonLabel }}
    </mg-btn>
  </div>

  <div
    class="icons-container"
    *ngIf="icons.length">
    <ng-container *ngFor="let icon of icons; let iconIndex = index">
      <div class="icon-container">
        <div
          class="icon-wrap"
          [class.with-side-text]="!!icon.sideText">
          <div class="icon">
            <div class="hand-icon"></div>
            <mg-icon>
              {{ icon.icon }}
            </mg-icon>
          </div>
          <div class="icon-side-text">{{ icon.sideText }}</div>
        </div>
        <div
          class="icon-bottom-text"
          *ngIf="icon.text">
          {{ icon.text }}
        </div>
      </div>
      <div
        class="arrow"
        *ngIf="iconIndex + 1 < icons.length">
        <mat-icon>arrow_right_alt</mat-icon>
      </div>
    </ng-container>
  </div>
</div>
