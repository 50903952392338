<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [overlayTitle]="modalConfig.title"
  [isLoading]="data.isLoading">
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="modalFooterTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainContent>
  <div
    class="modal-form"
    fxLayout="column"
    fxLayoutGap="12px">
    <!-- General Section -->
    <section>
      <!-- Name Field -->
      <mg-form-text-input
        [label]="MESSAGES.FIELD_LABEL_NAME"
        [control]="form.get(FORM_FIELD.NAME)">
      </mg-form-text-input>

      <!-- Icon Select Menu -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <mg-text variant="body-md">
          {{ MESSAGES.FIELD_LABEL_ICON }}
        </mg-text>
        <mg-form-select
          appendTo="body"
          [value]="form.get(FORM_FIELD.ICON).value"
          [control]="form.get(FORM_FIELD.ICON)"
          [closeOnSelect]="true"
          [multiple]="false"
          [searchable]="false"
          [condensed]="true"
          [options]="ICON_OPTIONS">
          <ng-template let-item="item">
            <div class="icon-wrap">
              <mg-icon
                [iconName]="item.label"
                [style.color]="form.get(FORM_FIELD.COLOR).value">
              </mg-icon>
            </div>
          </ng-template>
        </mg-form-select>
      </div>

      <!-- Color Field -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <mg-text variant="body-md">
          {{ MESSAGES.FIELD_LABEL_COLOR }}
        </mg-text>
        <div>
          <mg-form-text-input
            inputType="color"
            list="colorDatalist"
            [condensed]="true"
            [control]="form.get(FORM_FIELD.COLOR)">
          </mg-form-text-input>
          <datalist id="colorDatalist">
            <div *ngFor="let item of PRESET_COLORS">
              <option>{{ item }}</option>
            </div>
          </datalist>
        </div>
      </div>

      <!-- Priority Field -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="4px">
          <mg-text variant="body-md">
            {{ MESSAGES.FIELD_LABEL_PRIORITY }}
          </mg-text>
          <mg-tooltip [label]="HELP_MESSAGES.INPUT_PRIORITY"> </mg-tooltip>
        </div>
        <div>
          <mg-form-text-input
            inputType="number"
            [control]="form.get(FORM_FIELD.PRIORITY)">
          </mg-form-text-input>
        </div>
      </div>

      <!-- Default Pass Time Field -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="4px">
          <mg-text variant="body-md">
            {{ MESSAGES.FIELD_LABEL_PASS_TIME }}
          </mg-text>
          <mg-tooltip [label]="HELP_MESSAGES.INPUT_DEFAULT_DURATION">
          </mg-tooltip>
        </div>
        <div>
          <mg-form-text-input
            inputType="number"
            [control]="form.get(FORM_FIELD.DEFAULT_PASS_TIME)">
          </mg-form-text-input>
        </div>
      </div>

      <!-- Max. Simultaneous Passes -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="4px">
          <mg-text variant="body-md">
            {{ MESSAGES.FIELD_LABEL_MAX_PASSES }}
          </mg-text>
          <mg-tooltip [label]="HELP_MESSAGES.INPUT_MAX_ACTIVE_TYPES">
          </mg-tooltip>
        </div>
        <div>
          <mg-form-text-input
            inputType="number"
            [control]="form.get(FORM_FIELD.MAX_PASSES)">
          </mg-form-text-input>
        </div>
      </div>
    </section>

    <div class="spacer"></div>

    <!-- Kiosk Section -->
    <ng-container *ngIf="kioskPermissions.isKioskFeatureEnabled()">
      <section>
        <mg-text
          variant="header-md-secondary"
          textAlign="left"
          spacing="1">
          Kiosk mode
        </mg-text>
        <mg-form-toggle
          [id]="'hallpass-hp-edit-click-kiosk'"
          [label]="MESSAGES.ENABLE_FOR_KIOSK_MSG"
          [tooltip]="HELP_MESSAGES.INPUT_ENABLE_FOR_KIOSK"
          [disabled]="!kioskEnabled"
          [control]="form.get(FORM_FIELD.AVAILABLE_IN_KIOSK)">
        </mg-form-toggle>
      </section>
      <div class="spacer"></div>
    </ng-container>

    <!-- Restriction Section -->
    <section>
      <mg-text
        variant="header-md-secondary"
        textAlign="left"
        spacing="1">
        {{ MESSAGES.LABEL_RESTRICTION_SECTION }}
      </mg-text>
      <div class="bordered-section">
        <!-- Membership List Setting -->
        <div class="subsection">
          <mg-form-toggle
            [id]="'hallpass-hp-edit-click-available-to'"
            [label]="MESSAGES.LABEL_MEMBERSHIP"
            [control]="form.get(FORM_FIELD.LIMIT_STUDENTS)">
          </mg-form-toggle>
          <ng-container
            *ngIf="form.get(FORM_FIELD.LIMIT_STUDENTS).value === true">
            <mg-membership-list-table
              class="indent"
              [hideHeader]="true"
              [lists]="passTypeList"
              [useDefaultListName]="false"
              (listUpdated)="onMembershipListChange($event)">
            </mg-membership-list-table>
          </ng-container>
        </div>
        <!-- Restriction Settings -->
        <div class="subsection">
          <mg-form-restriction-input
            [id]="'hallpass-hp-edit-click-can-assign'"
            [label]="MESSAGES.LABEL_PASS_RESTRICTIONS"
            [selectableTypes]="[
              RESTRICTION_TABS.Role,
              RESTRICTION_TABS.Lists,
              RESTRICTION_TABS.Groups,
              RESTRICTION_TABS.MembershipList
            ]"
            [membershipListType]="MEMBERSHIP_LIST_TYPE.HALL_PASS_TYPE"
            [value]="form.get(FORM_FIELD.LIMIT_PASS_RESTRICTIONS).value"
            [control]="form.get(FORM_FIELD.LIMIT_PASS_RESTRICTIONS)">
          </mg-form-restriction-input>
        </div>
        <!-- Daily Limit Setting -->
        <div class="subsection">
          <mg-form-toggle
            [id]="'hallpass-hp-edit-click-kiosk'"
            [label]="MESSAGES.LABEL_DAILY_LIMIT_TOGGLE"
            [control]="form.get(FORM_FIELD.LIMIT_DAILY_PASSES_TOGGLE)">
          </mg-form-toggle>
          <div
            *ngIf="form.get(FORM_FIELD.LIMIT_DAILY_PASSES_TOGGLE).value"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="indent">
            <mg-text variant="body-md">
              {{ MESSAGES.LABEL_DAILY_LIMIT }}
            </mg-text>
            <div>
              <mg-form-text-input
                inputType="number"
                [control]="form.get(FORM_FIELD.LIMIT_DAILY_PASSES)">
              </mg-form-text-input>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="spacer"></div>

    <!-- Settings Section -->
    <section>
      <mg-text
        variant="header-md-secondary"
        textAlign="left"
        spacing="1">
        {{ MESSAGES.LABEL_SETTINGS_SECTION }}
      </mg-text>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <mg-text variant="body-md">
          {{ MESSAGES.LABEL_MANUALLY_END_PASS }}
        </mg-text>
        <mg-form-select
          appendTo="body"
          [value]="form.get(FORM_FIELD.MANUALLY_END_PASS).value"
          [control]="form.get(FORM_FIELD.MANUALLY_END_PASS)"
          [closeOnSelect]="true"
          [multiple]="false"
          [searchable]="false"
          [condensed]="true"
          [options]="MANUALLY_END_PASS_OPTIONS">
        </mg-form-select>
      </div>
      <mg-form-toggle
        [id]="'hallpass-hp-edit-click-manually-end'"
        [label]="MESSAGES.STUDENT_END_PASS_MSG"
        [tooltip]="HELP_MESSAGES.INPUT_STUDENT_END_PASS"
        [control]="form.get(FORM_FIELD.STUDENT_CAN_END_PASS)">
      </mg-form-toggle>
      <div
        *ngIf="form.get(FORM_FIELD.STUDENT_CAN_END_PASS).value"
        class="indent">
        <mg-form-toggle
          [id]="'hallpass-hp-edit-click-mobile-end'"
          [label]="MESSAGES.MOBILE_DEVICE_END_MSG"
          [control]="form.get(FORM_FIELD.CAN_END_ON_MOBILE)">
        </mg-form-toggle>
      </div>
      <mg-form-toggle
        [id]="'hallpass-hp-edit-click-can-create'"
        [label]="MESSAGES.STUDENT_CREATED_MSG"
        [tooltip]="HELP_MESSAGES.INPUT_STUDENT_CREATED"
        [control]="form.get(FORM_FIELD.STUDENT_CREATED)">
      </mg-form-toggle>
      <div
        *ngIf="form.get(FORM_FIELD.STUDENT_CREATED).value"
        class="indent">
        <mg-form-toggle
          [label]="MESSAGES.TEACHER_APPROVED_MSG"
          [tooltip]="HELP_MESSAGES.INPUT_TEACHER_APPROVED"
          [control]="form.get(FORM_FIELD.REQUIRED_TEACHER_APPROVAL)">
        </mg-form-toggle>
      </div>
      <mg-form-toggle
        [id]="'hallpass-hp-edit-click-ignore-black'"
        [label]="MESSAGES.IGNORE_BLACKOUTS_MSG"
        [control]="form.get(FORM_FIELD.IGNORE_BLACKOUTS)">
      </mg-form-toggle>
      <mg-form-toggle
        [id]="'hallpass-hp-edit-click-ignore-limit'"
        [label]="MESSAGES.IGNORE_LIMITS_MSG"
        [control]="form.get(FORM_FIELD.IGNORE_LIMITS)">
      </mg-form-toggle>
      <!-- @todo some toggles missing -->
    </section>
  </div>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #modalFooterTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <mg-btn
      [id]="isNewType ? 'hallpass-hp-click-cancel' : 'hallpass-hp-click-delete'"
      [variant]="isNewType ? 'text' : 'destructive'"
      (pressed)="onClickDelete()">
      {{
        isNewType ? MESSAGES.BUTTON_LABEL_CANCEL : MESSAGES.BUTTON_LABEL_DELETE
      }}
    </mg-btn>
    <div>
      <mg-btn
        variant="filled"
        [id]="isNewType ? 'hallpass-hp-click-create' : 'hallpass-hp-click-save'"
        [disabled]="!canSubmit"
        (pressed)="onClickSave()">
        {{
          isNewType ? MESSAGES.BUTTON_LABEL_CREATE : MESSAGES.BUTTON_LABEL_SAVE
        }}
      </mg-btn>
    </div>
  </div>
</ng-template>
