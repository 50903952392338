import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Notification } from 'minga/proto/gateway/notification_pb';

@Component({
  selector: 'mg-event-invite-notification',
  templateUrl: './MgEventInviteNotification.element.html',
  styleUrls: ['./MgEventInviteNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgEventInviteNotificationElement {
  @Input()
  notification?: Notification.AsObject;
}
