import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { mingaSettingTypes } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { SelectFieldsOption } from '@shared/components/select-fields';

@Component({
  selector: 'mg-setting-select',
  templateUrl: './setting-select.component.html',
  styleUrls: ['./setting-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingSelectComponent<T extends string | boolean>
  implements OnInit, OnDestroy
{
  private _destroyed$ = new ReplaySubject<void>(1);

  initialValue: T;

  @Input() label: string;
  @Input() setting: mingaSettingTypes;
  @Input() disabled = false;
  @Input() options: SelectFieldsOption<T>[] = [];
  @Input() helpText: string;
  @Input() public control: FormControl;
  @Input() selectSize: 'auto' | 'small' | 'medium' | 'large' = 'auto';

  constructor(
    private _mingaSettings: MingaSettingsService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    // subscribe to the value of the setting from the store.
    this._mingaSettings
      .getSettingValueObs(this.setting)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => {
        if (value !== undefined) {
          this.initialValue = value;
          this._cdr.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onValueSelect(value: string) {
    this._mingaSettings.updateSetting(this.setting, value);
  }
}
