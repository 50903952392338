import { FirebaseMessaging } from 'minga/app/src/app/firebase/messaging';
import {
  IRealtimeMessagePayload,
  RealtimeDataConsumer,
} from 'minga/shared/realtime';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export class FirebaseMessagingRealtimeDataConsumer extends RealtimeDataConsumer {
  readonly messageNotice$: Observable<IRealtimeMessagePayload>;

  constructor(messaging: FirebaseMessaging) {
    super();
    this.messageNotice$ = messaging.messageReceived$.pipe(
      map(msg => msg.data?.rtm),
      filter(rtmData => typeof rtmData !== 'undefined'),
      map(rtmData => JSON.parse(rtmData) as IRealtimeMessagePayload),
    );
  }
}
