import {
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injectable,
  InjectionToken,
  Injector,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';

export interface IMgModalConfig {
  full?: boolean;
  animation?: 'fade';
  backgroundColor?: string;
  data?: any;
}

export interface IMgModal {
  close(value?: any): void;
  readonly closed: boolean;
  readonly onClosed: EventEmitter<any>;
}

export interface IModalOpenedEvent {
  modal: IMgModal;
  options: IMgModalConfig;
}

export const MG_MODAL = new InjectionToken<IMgModal>('MgModal');

@Injectable()
export class MgModalService {
  private _container?: ViewContainerRef;
  private _activeModal?: IMgModal;
  private _modalContainerReadyPromise: Promise<void>;
  private _modalContainerReadyPromiseResolve?: () => void;

  onClosed = new EventEmitter<any>();
  onOpened = new EventEmitter<IModalOpenedEvent>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {
    this._modalContainerReadyPromise = new Promise(resolve => {
      this._modalContainerReadyPromiseResolve = resolve;
    });
  }

  waitForModalContainerReady(): Promise<void> {
    return this._modalContainerReadyPromise;
  }

  closeActiveModal() {
    if (this._activeModal && !this._activeModal.closed) {
      this._activeModal.close();
    }
  }

  setModalContainer(container: ViewContainerRef) {
    this._container = container;
    if (this._modalContainerReadyPromiseResolve) {
      this._modalContainerReadyPromiseResolve();
      delete this._modalContainerReadyPromiseResolve;
    }
  }

  clearModalContainer(container: ViewContainerRef) {
    if (this._container === container) {
      this._modalContainerReadyPromise = new Promise(resolve => {
        this._modalContainerReadyPromiseResolve = resolve;
      });
      delete this._container;
    }
  }

  open<T>(template: TemplateRef<T>, options: IMgModalConfig = {}): IMgModal {
    if (!this._container) {
      throw new Error(
        'MgModalService.setModalContainer() must be called before MgModalService.open()',
      );
    }

    if (this._activeModal && !this._activeModal.closed) {
      this._activeModal.close();
    }

    let embededView = this._container.createEmbeddedView(template);

    let onClosed = new EventEmitter();
    let modal: IMgModal = {
      close: (value?: any) => {
        embededView.destroy();
        onClosed.emit(value);
        this.onClosed.emit({ modal, value });
        onClosed.complete();
      },
      get closed() {
        return embededView.destroyed;
      },
      onClosed,
    };

    this._activeModal = modal;

    this.onOpened.emit(<IModalOpenedEvent>{ modal, options });

    return modal;
  }

  openComponent<T>(component: Type<T>, options: IMgModalConfig): IMgModal {
    if (!this._container) {
      throw new Error(
        'MgModalService.setModalContainer() must be called before MgModalService.openComponent()',
      );
    }

    const factory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    let modal: IMgModal;

    const injector = Injector.create(
      [{ provide: MG_MODAL, useFactory: () => modal, deps: [] }],
      this.injector,
    );

    const compRef = this._container.createComponent(factory, 0, injector);

    let closed = false;
    let onClosed = new EventEmitter();
    modal = {
      close: (value?: any) => {
        closed = true;
        compRef.destroy();
        onClosed.emit(value);
        this.onClosed.emit({ modal, value });
        onClosed.complete();
      },
      get closed() {
        return closed;
      },
      onClosed,
    };

    this._activeModal = modal;

    this.onOpened.emit(<IModalOpenedEvent>{ modal, options });

    return modal;
  }
}
