import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NaviconModule } from 'minga/app/src/app/components/Navicon';
import { NavItemModule } from 'minga/app/src/app/components/NavItem';
import { FtueHighlightPipeModule } from 'minga/app/src/app/ftue/Highlight/pipes/FtueHighlight';
import { MgIconModule } from 'minga/app/src/app/icon';
import { NavLinkComponent } from './NavLink.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    NaviconModule,
    NavItemModule,
    FtueHighlightPipeModule,

    // External dependencies
    CommonModule,
    RouterModule,
    FlexLayoutModule,
  ],
  declarations: [NavLinkComponent],
  exports: [NavLinkComponent],
})
export class NavLinkModule {}
