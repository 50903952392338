import {
  hideCordovaSplashscreen,
  showCordovaSplashscreen,
} from '@lib/cordova/splashscreen';

import { ISplashScreen } from './interface';

// Matches wait time on cordova/config.xml
const SS_WAIT_TIME = 300;

export class CordovaSplashscreen implements ISplashScreen {
  async show() {
    showCordovaSplashscreen();
    await new Promise(resolve => setTimeout(resolve, SS_WAIT_TIME));
  }

  async hide() {
    hideCordovaSplashscreen();
    await new Promise(resolve => setTimeout(resolve, SS_WAIT_TIME));
  }
}
