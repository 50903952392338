<div
  *mgOverlayStructure="true; region: 'top'"
  #overlayNavTop>
  <mg-overlay-nav-item
    class="mg-align-left"
    icon="close"
    (click)="onOverlayPrevious()">
  </mg-overlay-nav-item>

  <mg-overlay-nav-item
    class="mg-align-right"
    icon="publish-o"
    (click)="onOverlayNext()">
    <span *ngIf="newPeopleToAdd.length"
      >&nbsp;({{ newPeopleToAdd.length }})</span
    >Start Conversation
  </mg-overlay-nav-item>
</div>

<div
  class="mg-create-conversation-overlay"
  [ngClass.xs]="'mobile'">
  <p class="mg-headline form-input-hpadding">Start A Conversation</p>
  <div class="search-container">
    <mat-form-field
      mgFormField
      class="search-field form-input-hpadding flex-input"
      [mgHideOptionalMarker]="true">
      <mat-chip-list
        #chipList
        class="single-line"
        [class.has-elements]="newPeopleToAdd.length">
        <mat-chip
          *ngFor="let result of newPeopleToAdd; let i = index"
          [removable]="true"
          [selectable]="true"
          (keydown)="onChipKeydown($event, result, i)"
          (click)="onRemovePerson($event, i)">
          {{ result.displayName }}
          <mat-icon
            (click)="onRemovePerson($event, i)"
            matChipRemove
            >close</mat-icon
          >
        </mat-chip>
      </mat-chip-list>
      <input
        [matChipInputFor]="chipList"
        matInput
        #searchInput
        [ngModelOptions]="{ standalone: true }"
        name="searchText"
        minlength="3"
        maxlength="25"
        autocomplete="off"
        placeholder="Search for a person"
        [(ngModel)]="searchText"
        (focus)="onSearchInputFocus($event)"
        (blur)="onSearchInputBlur($event)"
        (ngModelChange)="onSearchChange($event)" />

      <mat-icon
        matSuffix
        *ngIf="!openPersonNameList"
        >search</mat-icon
      >
      <mg-icon
        matSuffix
        *ngIf="openPersonNameList"
        class="close-icon"
        (mousedown)="closePersonList()"
        >close-thin</mg-icon
      >
    </mat-form-field>
    <p class="disabled-disclaimer mg-minicopy form-input-hpadding">
      You are able to send messages to members based on roles/permissions.
    </p>
    <ng-template #noActiveSearch>
      <div
        class="empty-state-container"
        *ngIf="!newPeopleToAdd.length"></div>
    </ng-template>
    <ng-container *ngIf="openPersonNameList && searchText; else noActiveSearch">
      <div class="gradient-divider"></div>
      <div class="results form-input-hpadding">
        <ng-container *ngIf="results$ | async as results; else searchingTpl">
          <div
            *ngIf="results.length == 0"
            class="no-results">
            <p>No results for "{{ searchText }}"</p>
          </div>
        </ng-container>
        <ng-template #searchingTpl>
          <div class="loading-wrap">
            <mg-spinner
              [thickness]="1"
              [diameter]="10">
            </mg-spinner>
          </div>
          <div class="searching-msg">
            <p>Searching...</p>
          </div>
        </ng-template>

        <div class="results-container">
          <ng-container *ngFor="let result of results$ | async">
            <div
              class="result"
              (mousedown)="selectPersonClick($event, result)"
              [class.disabled]="
                isDisabled(result) && (isStaff$ | async) === false
              "
              [class.added]="isUserHashAdded(result.hash)">
              <div class="left-col">
                {{ result?.imageUrl }}
                <mg-avatar
                  class="result-avatar"
                  [src]="result.profileImageUrl"
                  [color]="result.badgeIconColor"></mg-avatar>
                <div
                  class="result-name mg-bodycopy-bold"
                  [innerHTML]="result.displayName"></div>
              </div>
              <mg-icon *ngIf="isUserHashAdded(result.hash)">close-thin</mg-icon>
              <mg-button
                type="outline"
                class="small"
                *ngIf="!isUserHashAdded(result.hash)"
                [disabled]="isDisabled(result) && (isStaff$ | async) === false"
                >{{ 'button.add' | translate }}</mg-button
              >
            </div>
          </ng-container>
        </div>
        <div class="gradient-divider bottom"></div>
      </div>
    </ng-container>
  </div>
</div>
