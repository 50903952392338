import {
  IBarcodeScannerOptions,
  IBarcodeScannerResult,
} from './barcode-scanner.types';

export const scanBarcode = async (
  options: Partial<IBarcodeScannerOptions>,
): Promise<IBarcodeScannerResult> => {
  const cordova = (window as any).cordova;
  if (!cordova?.plugins?.mlkit?.barcodeScanner) {
    throw new Error('Cordova barcode scanning unsupported on this platform');
  }
  return new Promise<IBarcodeScannerResult>((resolve, reject) => {
    cordova.plugins.mlkit.barcodeScanner.scan(
      options,
      (result: IBarcodeScannerResult) => resolve(result),
      (err: any) => reject(err),
    );
  });
};
