import { Component, EventEmitter, Input, Output } from '@angular/core';

import * as day from 'dayjs';
import * as xlsx from 'xlsx';

import { IListUploadRowWithError } from '@shared/components/XlsListUploader';

export interface ISummaryRow {
  label: string;
  metric: string;
}
@Component({
  selector: 'mg-list-uploader-summary',
  templateUrl: './ListUploaderSummary.component.html',
  styleUrls: ['./ListUploaderSummary.component.scss'],
})
export class ListUploaderSummaryComponent {
  @Input()
  rowsWithErrors: IListUploadRowWithError[] = [];

  @Input()
  headers: string[] = [];

  @Input()
  summaryRows: ISummaryRow[] = [];

  @Input()
  message = '';

  @Input()
  showButton = true;

  @Input()
  errorOffset = 0;

  @Output()
  closeSummary = new EventEmitter<void>();

  constructor() {}

  private _createXlsxWorkbook() {
    const workBook = xlsx.utils.book_new();
    if (this.headers) {
      this.headers.push('Errors');
    }

    const newRows: string[][] = this.rowsWithErrors.map((errRecord, index) => {
      const record = errRecord.row;
      let errMsg = '';

      if (Array.isArray(errRecord.errors)) {
        errMsg = errRecord.errors.join('\n');
      } else {
        errMsg = errRecord.errors;
      }
      record.errors = errMsg;
      let rowArray: string[] = [];

      for (const key in record) {
        if (Object.prototype.hasOwnProperty.call(record, key)) {
          const element = record[key];
          if (Array.isArray(element)) {
            rowArray = [...rowArray, ...element];
          } else {
            rowArray.push(element);
          }
        }
      }

      return rowArray;
    });

    // add headers to top.
    if (this.headers) {
      newRows.unshift(this.headers);
    }

    const sheet = xlsx.utils.aoa_to_sheet(newRows, {
      cellDates: true,
    });

    if (!sheet['!cols']) {
      sheet['!cols'] = [];
    }

    xlsx.utils.book_append_sheet(workBook, sheet);

    return workBook;
  }

  downloadXlsx() {
    const workBook = this._createXlsxWorkbook();
    const date = day().format('YYYY-MM-DD');

    xlsx.writeFile(workBook, `${date}-minga-upload-errors.xlsx`);
  }

  onClose() {
    this.closeSummary.emit();
  }
}
