<div fxLayout="column">
  <mg-btn
    *ngIf="(canPostContent$ | async) && !currentGroup && isCommunityEnabled"
    variant="filled"
    iconSet="minga"
    iconRight="down-toggle"
    [wide]="true"
    [matMenuTriggerFor]="dropdownMenu">
    {{ MESSAGES.ADD }}
  </mg-btn>
  <mat-menu
    #dropdownMenu="matMenu"
    xPosition="before">
    <!-- Create Post -->
    <button
      *ngIf="'CONTENT_POST_CREATE' | hasPermission"
      mat-menu-item
      [routerLink]="[
        '',
        {
          outlets: {
            o: ['content', addContentLinkType, 'create', CONTENT_TYPES.POST]
          }
        }
      ]">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="default-icon">mg-add-o</mg-icon>
        <mg-text
          variant="body"
          color="surface">
          {{ MESSAGES.POST }}
        </mg-text>
      </div>
    </button>
    <!-- Create Video -->
    <button
      *ngIf="'CONTENT_VIDEO_CREATE' | hasPermission"
      mat-menu-item
      [routerLink]="[
        '',
        {
          outlets: {
            o: ['content', addContentLinkType, 'create', CONTENT_TYPES.VIDEO]
          }
        }
      ]">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="default-icon">mg-play-o</mg-icon>
        <mg-text
          variant="body"
          color="surface">
          {{ MESSAGES.VIDEO }}
        </mg-text>
      </div>
    </button>
    <!-- Create Event -->
    <button
      *ngIf="'CONTENT_EVENT_CREATE' | hasPermission"
      mat-menu-item
      [routerLink]="[
        '',
        {
          outlets: {
            o: ['content', addContentLinkType, 'create', CONTENT_TYPES.EVENT]
          }
        }
      ]">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="default-icon">mg-events-o</mg-icon>
        <mg-text
          variant="body"
          color="surface">
          {{ MESSAGES.EVENT }}
        </mg-text>
      </div>
    </button>
    <!-- Create Announcement -->
    <button
      *ngIf="'CONTENT_ANNOUNCEMENT_CREATE' | hasPermission"
      mat-menu-item
      [routerLink]="[
        '',
        {
          outlets: {
            o: [
              'content',
              addContentLinkType,
              'create',
              CONTENT_TYPES.ANNOUNCEMENT
            ]
          }
        }
      ]">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="default-icon">mg-announcement-o</mg-icon>
        <mg-text
          variant="body"
          color="surface">
          {{ MESSAGES.ANNOUNCEMENT }}
        </mg-text>
      </div>
    </button>
    <!-- Challenges -->
    <button
      *ngIf="'CONTENT_CHALLENGE_CREATE' | hasPermission"
      mat-menu-item
      (click)="gotoChallengeAdmin()">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="default-icon">mg-challenges-o</mg-icon>
        <mg-text
          variant="body"
          color="surface">
          {{ MESSAGES.CHALLENGE }}
        </mg-text>
      </div>
    </button>
    <!-- Create Poll -->
    <button
      *ngIf="'CONTENT_POLL_CREATE' | hasPermission"
      mat-menu-item
      [routerLink]="[
        '',
        {
          outlets: {
            o: ['content', addContentLinkType, 'create', CONTENT_TYPES.POLL]
          }
        }
      ]">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="default-icon">mg-polls-o</mg-icon>
        <mg-text
          variant="body"
          color="surface">
          {{ MESSAGES.POLL }}
        </mg-text>
      </div>
    </button>
    <!-- Create SMS Message -->
    <button
      *ngIf="
        ('CONTENT_SMS_MESSAGE_CREATE' | hasPermission) &&
        (isSMSEnabled$ | async)
      "
      mat-menu-item
      [routerLink]="[
        '',
        {
          outlets: {
            o: [
              'content',
              addContentLinkType,
              'create',
              CONTENT_TYPES.SMS_MESSAGE
            ]
          }
        }
      ]">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="default-icon">mg-sms-o</mg-icon>
        <mg-text
          variant="body"
          color="surface">
          {{ MESSAGES.SMS }}
        </mg-text>
      </div>
    </button>
    <!-- Photo Gallery -->
    <button
      *ngIf="
        ('GALLERY_ADD_PHOTO' | hasPermission) &&
        (isPhotoGalleryEnabled$ | async)
      "
      mat-menu-item
      [routerLink]="['', { outlets: { o: ['gallery', 'add'] } }]">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="default-icon">mg-photo-gallery-o</mg-icon>
        <mg-text
          variant="body"
          color="surface">
          {{ MESSAGES.PHOTO_GALLERY }}
        </mg-text>
      </div>
    </button>
    <!-- Create Full Graphic -->
    <button
      *ngIf="'CONTENT_FULL_GRAPHIC_CREATE' | hasPermission"
      mat-menu-item
      [routerLink]="[
        '',
        {
          outlets: {
            o: [
              'content',
              addContentLinkType,
              'create',
              CONTENT_TYPES.FULL_GRAPHIC
            ]
          }
        }
      ]">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="default-icon">mg-add-o</mg-icon>
        <mg-text
          variant="body"
          color="surface">
          {{ MESSAGES.GRAPHIC }}
        </mg-text>
      </div>
    </button>
    <!-- Create Alert -->
    <button
      *ngIf="'APP_BROADCAST_SEND' | hasPermission"
      mat-menu-item
      (click)="goToAlertModal()">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <mg-icon class="red-icon">mg-alert-o</mg-icon>
        <mg-text
          variant="body"
          color="error">
          {{ MESSAGES.ALERT }}
        </mg-text>
      </div>
    </button>
  </mat-menu>
</div>
