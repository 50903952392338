<mg-calendar-schedule-nav
  [calendarPath]="EVENT_ROUTES.CALENDAR"
  [schedulePath]="EVENT_ROUTES.SCHEDULE"
  (clickOptions)="openCalendarOptions()"></mg-calendar-schedule-nav>

<div
  *ngIf="calendarScheduleOptionsOpened"
  class="calendar-schedule-options-container">
  <div
    class="calendar-schedule-options"
    *ngIf="settings$ | async as settings">
    <h1
      class="calendar-settings-title"
      *ngIf="settings.showCalendarSettings">
      Calendar Settings
    </h1>
    <h1
      class="calendar-settings-title"
      *ngIf="!settings.showCalendarSettings">
      Events List Settings
    </h1>
    <mg-calendar-schedule-options
      #calendarScheduleOptions
      [includeWeekends]="!settings.excludeWeekend"
      [calendarView]="settings.calendarView"
      [groupHashes]="settings.groupHashes"
      [selectedCategory]="settings.category"
      (groupHashesChange)="_onGroupSelectChange(settings, $event)"
      (eventCategoryChange)="_onCategorySelectChange(settings, $event)"
      [showPastEvents]="settings.showPastEvents"
      [showCalendarSettings]="settings.showCalendarSettings"
      (submit)="applyOptions($event, settings)">
    </mg-calendar-schedule-options>
  </div>
</div>
