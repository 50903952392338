<ng-template
  mgSearchArea
  placeholder="Search Events"
  (searchText)="searchText = $event">
  <mg-search-content
    contentType="EVENT"
    [query]="searchText"></mg-search-content>
</ng-template>

<mg-layout-legacy-content>
  <ng-template #action>
    <mg-btn
      *ngIf="
        ('MINGA_CONTENT_MAIN_FEED' | hasPermission) &&
        ('CONTENT_EVENT_CREATE' | hasPermission)
      "
      [responsive]="true"
      [routerLink]="[
        '',
        { outlets: { o: ['content', 'm', 'create', 'event'] } }
      ]">
      Create event
    </mg-btn>
  </ng-template>
  <mg-events-home></mg-events-home>

  <router-outlet></router-outlet>
</mg-layout-legacy-content>
