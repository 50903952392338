import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule, Routes } from '@angular/router';

import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgPersonMinimalModule } from 'minga/app/src/app/elements/MgPersonMinimal';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/MgOverlayTools.module';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { MgIconModule } from 'src/app/icon';

import { GenericButtonModule } from '@shared/components/button';
import { OverlayModule } from '@shared/components/modal-overlay';

import { ViewMembershipListMembersComponent } from './ViewMembershipListMembers.component';

export const routes: Routes = [
  {
    path: '',
    component: ViewMembershipListMembersComponent,
  },
];

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    MgAvatarModule,
    MgPersonMinimalModule,
    OverlayModule,
    MgOverlayToolsModule,
    MgIconModule,
    GenericButtonModule,

    // External dependencies
    RouterModule.forChild(routes),
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [ViewMembershipListMembersComponent],
  exports: [ViewMembershipListMembersComponent],
})
export class ViewMembershipListMembersModule {}
