import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { cloneDeep } from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IHallPassType } from 'minga/domain/hallPass';
import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';
import { mingaSettingTypes } from 'minga/util';
import { HallPassService } from 'src/app/services/HallPass';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  FormRestrictionTabs,
  fromRestrictionInput,
  toRestrictionInput,
} from '@shared/components/form-restriction-input/form-restriction-input.constants';
import { COLOR_PICKER_PRESETS } from '@shared/components/form/constants';
import { MembershipListTableLists } from '@shared/components/membership-list-table';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { TYPE_ICON_OPTIONS } from '@shared/constants';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import {
  HPM_TYPE_FORM,
  HpmTypesEditFormFields,
  HpmTypesEditHelpMessages,
  HpmTypesEditMessages,
} from './hpm-types-edit.constants';
import {
  ENDING_METHOD_OPTIONS,
  HpmTypesEditData,
  HpmTypesEditDialogData,
} from './hpm-types-edit.types';

@Component({
  selector: 'mg-hpm-types-edit',
  templateUrl: './hpm-types-edit.component.html',
  styleUrls: ['./hpm-types-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmTypesEditComponent implements OnInit, OnDestroy {
  /** Messages */
  public readonly MESSAGES = HpmTypesEditMessages;
  public readonly RESTRICTION_TABS = FormRestrictionTabs;
  public readonly MEMBERSHIP_LIST_TYPE = MembershipListType;
  public readonly HELP_MESSAGES: typeof HpmTypesEditHelpMessages =
    HpmTypesEditHelpMessages;
  public readonly FORM_FIELD: typeof HpmTypesEditFormFields =
    HpmTypesEditFormFields;
  public readonly ICON_OPTIONS = TYPE_ICON_OPTIONS;
  public readonly PRESET_COLORS = Object.values(COLOR_PICKER_PRESETS).reduce(
    (acc, curr) => [...acc, ...curr],
    [],
  );
  public readonly MANUALLY_END_PASS_OPTIONS = ENDING_METHOD_OPTIONS;

  /** I don't know */
  readonly bannerImageSizes: ReadonlyArray<string | string[]> = [
    'blurloading1',
    ['longcardbanner', 'cardbanner', 'banner'],
  ];

  /** Subscription Cleanup */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Type Subscription */
  public _type$: Observable<IHallPassType>;
  private _type: IHallPassType;

  /** Component Data */
  data: HpmTypesEditData = {
    original: undefined,
    changes: undefined,
    isLoading: true,
  };

  /** Default Membership List Info */
  defaultMembershipListInfo: IMembershipList = {
    id: undefined,
    listType: MembershipListType.PASS_TYPE,
    memberCount: 0,
  };

  /** Is New Type */
  get isNewType(): boolean {
    return this.dialogData.type === 'new' ? true : false;
  }

  get modalConfig(): {
    title: string;
    headerBg: ModalOverlayPrimaryHeaderBackground;
  } {
    const { original } = this.data;
    if (!original) {
      return {
        title: 'Edit',
        headerBg: ModalOverlayPrimaryHeaderBackground.PINK,
      };
    }
    if (this.isNewType) {
      return {
        title: HpmTypesEditMessages.MODAL_TITLE_NEW_HALLPASS,
        headerBg: ModalOverlayPrimaryHeaderBackground.PINK,
      };
    }
    return {
      title: HpmTypesEditMessages.MODAL_TITLE_HALLPASS,
      headerBg: ModalOverlayPrimaryHeaderBackground.PINK,
    };
  }

  /** Get Default Status */
  get isDefaultType(): boolean {
    if (this.isNewType) return false;
    if (!this.data.original) return false;
    return this.data.original.createdBy ? false : true;
  }

  get passTypeList(): MembershipListTableLists[] {
    if (this.data.original.membershipListId) {
      return [
        {
          id: this.data.original.membershipListId,
        },
      ];
    }
    return [
      {
        type: MembershipListType.PASS_TYPE,
      },
    ];
  }

  /** Form Controls */
  public readonly form = this._fb.group(cloneDeep(HPM_TYPE_FORM));

  public get canSubmit() {
    if (this.isNewType) return this.form.dirty && this.form.valid;
    return this.form.valid;
  }

  /** Global Settings */
  public studentCanEndPass = false;
  public canEndOnMobile = false;
  public hallPassEndingMethod = false;
  public kioskEnabled = false;

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA) public dialogData: HpmTypesEditDialogData,
    private _modalOverlayRef: ModalOverlayRef,
    private _hpmService: HallPassService,
    private _cdr: ChangeDetectorRef,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _dialog: MatDialog,
    private _fb: FormBuilder,
    private _settingService: MingaSettingsService,
    public kioskPermissions: KioskPermissionsService,
  ) {}

  /** Component Lifecycle: On Mount; */
  async ngOnInit() {
    this._settingService
      .getSettingValueObs(mingaSettingTypes.PASS_STUDENTS_END_PASSES)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => (this.studentCanEndPass = value));

    this._settingService
      .getSettingValueObs(
        mingaSettingTypes.PASS_STUDENTS_END_PASSES_FROM_MOBILE,
      )
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => (this.canEndOnMobile = value));

    this._settingService
      .getSettingValueObs(mingaSettingTypes.PASS_MUST_MANUALLY_END)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => (this.hallPassEndingMethod = value));

    this._settingService
      .getSettingValueObs(mingaSettingTypes.PASS_HALLPASS_KIOSK)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => (this.kioskEnabled = value));

    await this._setInitialForm();
  }

  /** Component Lifecycle: On Unmount */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public onMembershipListChange(list: IMembershipList) {
    const data = this.data.changes;
    if (data.membershipListId !== list.id) data.membershipListId = list.id;
  }

  public async onClickDelete(): Promise<void> {
    if (!this.data.original) return;
    const { name, id } = this.data.original;
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: this.isNewType
            ? `Are you sure you want to discard this new type?`
            : `Are you sure you want to delete ${name}?`,
          deleteBtn: 'Delete',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async (res: any) => {
      if (!res) return;
      if (res.confirmed) {
        if (!this.isNewType) {
          await this._hpmService.deleteHallPassType(id);
        }
        this._cdr.markForCheck();
        if (this.isNewType) {
          this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        } else {
          this._modalOverlayRef.close(
            ModalOverlayServiceCloseEventType.DELETE,
            {
              id,
            },
          );
        }
      }
    });
  }

  public async onClickSave(): Promise<void> {
    if (!this.canSubmit) return;
    this.data.isLoading = true;
    const name = this.form.get(HpmTypesEditFormFields.NAME).value;
    const color = this.form.get(HpmTypesEditFormFields.COLOR).value;
    const priority = parseInt(
      this.form.get(HpmTypesEditFormFields.PRIORITY).value,
      10,
    );
    const defaultHallPassTime = parseInt(
      this.form.get(HpmTypesEditFormFields.DEFAULT_PASS_TIME).value,
      10,
    );
    const maxSimultaneousPasses = parseInt(
      this.form.get(HpmTypesEditFormFields.MAX_PASSES).value,
      10,
    );
    const selfIssue = this.form.get(
      HpmTypesEditFormFields.STUDENT_CREATED,
    ).value;
    const ignoreBlackout = this.form.get(
      HpmTypesEditFormFields.IGNORE_BLACKOUTS,
    ).value;
    const ignoreLimits = this.form.get(
      HpmTypesEditFormFields.IGNORE_LIMITS,
    ).value;
    const requireTeacherApproval = this.form.get(
      HpmTypesEditFormFields.REQUIRED_TEACHER_APPROVAL,
    ).value;
    const icon = this.form.get(HpmTypesEditFormFields.ICON).value;

    const limitStudentsListEnabled = this.form.get(
      HpmTypesEditFormFields.LIMIT_STUDENTS,
    ).value;
    const limiDailyPassesEnabled = this.form.get(
      HpmTypesEditFormFields.LIMIT_DAILY_PASSES_TOGGLE,
    ).value;

    const limitDailyPasses = parseInt(
      this.form.get(HpmTypesEditFormFields.LIMIT_DAILY_PASSES).value,
      10,
    );

    const restriction =
      this.form.get(HpmTypesEditFormFields.LIMIT_PASS_RESTRICTIONS).value ||
      undefined;

    const manuallyEndPass = this.form.get(
      HpmTypesEditFormFields.MANUALLY_END_PASS,
    ).value;

    const studentCanEndPass = this.form.get(
      HpmTypesEditFormFields.STUDENT_CAN_END_PASS,
    ).value;
    const canEndOnMobile = this.form.get(
      HpmTypesEditFormFields.CAN_END_ON_MOBILE,
    ).value;

    const availableInKiosk = this.form.get(
      HpmTypesEditFormFields.AVAILABLE_IN_KIOSK,
    ).value;

    const type: IHallPassType = {
      ...this.data.original,
      name,
      color,
      priority,
      defaultHallPassTime,
      maxSimultaneousPasses,
      selfIssue,
      ignoreBlackout,
      ignoreLimits,
      membershipListId: limitStudentsListEnabled
        ? this.data.changes.membershipListId
        : undefined,
      bannerHash: icon,
      requireTeacherApproval: selfIssue ? requireTeacherApproval : false,
      restriction: {
        id: this.data.original.restriction?.id ?? undefined,
        ...fromRestrictionInput(restriction, this.data.original.restriction),
      },
      dailyPassLimit: limiDailyPassesEnabled ? limitDailyPasses : undefined,
      manuallyEndPass,
      studentCanEndPass,
      canEndOnMobile: studentCanEndPass && canEndOnMobile,
      availableInKiosk,
    };

    try {
      const updatedType = await this._hpmService.updateHallPassType(type);
      this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
        isNewType: this.isNewType,
        type: updatedType,
      });
    } catch (error) {
      this._systemAlertSnackBar.open({
        type: 'error',
        message: error,
      });
    } finally {
      this.data.isLoading = false;
    }
  }

  private async _setInitialForm(): Promise<void> {
    const { data } = this.dialogData;
    const type = this.isNewType
      ? (data as IHallPassType)
      : await this._hpmService.getHallPassTypeById(data as number);

    this.data.original = { ...type };
    this.data.changes = { ...type };

    const nameField = this.form.get(HpmTypesEditFormFields.NAME);
    const colorField = this.form.get(HpmTypesEditFormFields.COLOR);
    const priorityField = this.form.get(HpmTypesEditFormFields.PRIORITY);
    const defaultTimeField = this.form.get(
      HpmTypesEditFormFields.DEFAULT_PASS_TIME,
    );
    const maxPassesField = this.form.get(HpmTypesEditFormFields.MAX_PASSES);
    const studentCreatedField = this.form.get(
      HpmTypesEditFormFields.STUDENT_CREATED,
    );
    const ignoreBlackoutsField = this.form.get(
      HpmTypesEditFormFields.IGNORE_BLACKOUTS,
    );
    const ignoreLimitsField = this.form.get(
      HpmTypesEditFormFields.IGNORE_LIMITS,
    );
    const iconField = this.form.get(HpmTypesEditFormFields.ICON);
    const requireTeacherApproval = this.form.get(
      HpmTypesEditFormFields.REQUIRED_TEACHER_APPROVAL,
    );
    const limitStudents = this.form.get(HpmTypesEditFormFields.LIMIT_STUDENTS);
    const limitDailyPassesToggle = this.form.get(
      HpmTypesEditFormFields.LIMIT_DAILY_PASSES_TOGGLE,
    );
    const limitDailyPasses = this.form.get(
      HpmTypesEditFormFields.LIMIT_DAILY_PASSES,
    );
    const endingMethod = this.form.get(
      HpmTypesEditFormFields.MANUALLY_END_PASS,
    );
    const studentCanEndPass = this.form.get(
      HpmTypesEditFormFields.STUDENT_CAN_END_PASS,
    );
    const canEndOnMobile = this.form.get(
      HpmTypesEditFormFields.CAN_END_ON_MOBILE,
    );
    const availableInKiosk = this.form.get(
      HpmTypesEditFormFields.AVAILABLE_IN_KIOSK,
    );

    limitStudents.setValue(!!type.membershipListId);
    limitDailyPassesToggle.setValue(!!type.dailyPassLimit);
    limitDailyPasses.setValue(!!type.dailyPassLimit ? type.dailyPassLimit : 1);
    nameField.setValue(type.name);
    colorField.setValue(type.color);
    priorityField.setValue(type.priority || 0);
    defaultTimeField.setValue(type.defaultHallPassTime);
    maxPassesField.setValue(type.maxSimultaneousPasses);
    studentCreatedField.setValue(type.selfIssue);
    ignoreBlackoutsField.setValue(type.ignoreBlackout);
    ignoreLimitsField.setValue(type.ignoreLimits);
    iconField.setValue(type.bannerHash);
    requireTeacherApproval.setValue(!!type.requireTeacherApproval);
    endingMethod.setValue(type.manuallyEndPass ?? this.hallPassEndingMethod);
    studentCanEndPass.setValue(
      type.studentCanEndPass ?? this.studentCanEndPass,
    );
    canEndOnMobile.setValue(type.canEndOnMobile ?? this.canEndOnMobile);
    availableInKiosk.setValue(type.availableInKiosk);

    if (type.restriction) {
      this.form
        .get(HpmTypesEditFormFields.LIMIT_PASS_RESTRICTIONS)
        .patchValue(toRestrictionInput(type.restriction));
    }

    this.data.isLoading = false;
    this._cdr.markForCheck();
  }
}
