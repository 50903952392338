import { NgModule } from '@angular/core';

import { MgSmsMessageMiniCardComponent } from './MgSmsMessageMiniCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [MgSmsMessageMiniCardComponent],
  exports: [MgSmsMessageMiniCardComponent],
})
export class MgSmsMessageMiniCardModule {}
