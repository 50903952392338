import { NgModule } from '@angular/core';

import { MgContentContextMenuDirective } from './mgContentContextMenu.directive';
import { MgContentContextMenuClickDirective } from './mgContentContextMenuClick.directive';
import { MgContentLinkDirective } from './mgContentLink.directive';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [
    MgContentLinkDirective,
    MgContentContextMenuDirective,
    MgContentContextMenuClickDirective,
  ],
  exports: [
    MgContentLinkDirective,
    MgContentContextMenuDirective,
    MgContentContextMenuClickDirective,
  ],
})
export class MgContentLinkModule {}
