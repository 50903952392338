<mg-legacy-overlay-nav [component]="this"></mg-legacy-overlay-nav>

<div class="form-block report-form-content">
  <p>I would like to report this content. I am concerned that it is:</p>

  <mat-radio-group [(ngModel)]="optionSelected">
    <mat-radio-button
      *ngFor="let item of reportMessages; let i = index"
      [value]="i + 1">
      {{ item }}
    </mat-radio-button>
  </mat-radio-group>

  <mg-button-outline
    (click)="submit()"
    [ngClass.xs]="'mobile'"
    [disabled]="!optionSelected || loading"
    type="block"
    class="submit-button">
    Send Report
  </mg-button-outline>
</div>

<ng-template #successOverlayTemplate>
  <div class="success-overlay">
    <div class="checkmark-wrap">
      <mg-success-checkmark></mg-success-checkmark>
      <div class="mg-bodycopy">Report Sent!</div>
    </div>
  </div>
</ng-template>
