<div class="row">
  <div class="column">
    <mg-role-select
      placeholder="Default role (required)"
      [disallowNone]="true"
      [required]="true"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="listState.defaultRole"
      value="listState.defaultRole"
      (ngModelChange)="onStateChange()">
    </mg-role-select>

    <mat-form-field mgFormField>
      <mat-label>What to do with people that already exist</mat-label>
      <mat-select
        [(ngModel)]="listState.updateOrSkip"
        (ngModelChange)="onStateChange()">
        <mat-option value="skip">Skip people that already exist</mat-option>
        <mat-option value="update"
          >Update fields if they already exist</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <ng-container>
    <div class="checkbox-container">
      <mat-checkbox
        *ngIf="showPin"
        [(ngModel)]="listState.sendPinEmails"
        (ngModelChange)="onStateChange()">
        Send PIN emails
      </mat-checkbox>
      <mat-checkbox
        [(ngModel)]="listState.archiveUsers"
        (change)="archiveDialog($event)">
        Archive existing users with the default role not on list
      </mat-checkbox>
    </div>
  </ng-container>
</div>
