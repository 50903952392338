import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';

import { MgMentionElement } from './MgMention.element';

@NgModule({
  declarations: [MgMentionElement],
  exports: [MgMentionElement],
})
export class MgMentionModule {
  constructor(injector: Injector) {
    if (!customElements.get('mg-mention')) {
      customElements.define(
        'mg-mention',
        createCustomElement(MgMentionElement, { injector }),
      );
    }
  }
}
