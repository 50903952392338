import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  takeUntil,
} from 'rxjs/operators';

import {
  FlexTimeActivityInstance,
  MinimalFlexTimePeriod,
} from 'minga/domain/flexTime';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import {
  FlexTimeActivityInstanceService,
  FlexTimePeriodService,
} from '@shared/services/flex-time';

@Injectable()
export class FlexTimeManagerService implements OnDestroy {
  /** General Observables */
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  /** Loading */
  private readonly _isLoadingSubj = new BehaviorSubject(true);
  public readonly isLoading$ = this._isLoadingSubj
    .asObservable()
    .pipe(shareReplay());

  private _periodFilterSubj = new BehaviorSubject<number>(null);
  public readonly periodFilter$ = this._periodFilterSubj
    .asObservable()
    .pipe(takeUntil(this._destroyedSubj), distinctUntilChanged());

  /** FlexTime Periods */
  private _periodsSubj = new BehaviorSubject<MinimalFlexTimePeriod[]>([]);
  public readonly periods$ = this._periodsSubj
    .asObservable()
    .pipe(shareReplay());
  public readonly periodOptions$ = this.periods$.pipe(
    takeUntil(this._destroyedSubj),
    map(periods => this._ftPeriod.formatPeriodsOptions(periods)),
  );

  /** Activity Instances */
  private _instancesSubj = new BehaviorSubject<FlexTimeActivityInstance[]>([]);
  public readonly instances$ = this._instancesSubj
    .asObservable()
    .pipe(shareReplay());
  private readonly _isLoadingInstancesSubj = new BehaviorSubject(true);
  public readonly isLoadingInstances$ = this._isLoadingInstancesSubj
    .asObservable()
    .pipe(shareReplay());

  /** Service Constructor */
  constructor(
    private _ftActivityInstances: FlexTimeActivityInstanceService,
    private _ftPeriod: FlexTimePeriodService,
    private _dialog: MatDialog,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
    this._instancesSubj.complete();
    this._periodsSubj.complete();
    this._isLoadingSubj.complete();
    this._isLoadingInstancesSubj.complete();
  }

  public deleteActivityInstance(id: number): void {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: 'Are you sure you want to remove this activity?',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || !response.confirmed || response.cancelled) return;
      await this._ftActivityInstances.delete(id).then(() => {
        const instances = this._instancesSubj.getValue();
        this._instancesSubj.next(instances.filter(i => i.id !== id));
        this._systemAlertSnackBar.open({
          type: 'success',
          message: 'Successfully removed activity',
        });
      });
    });
  }

  public async fetchPeriods(
    startDate?: day.Dayjs,
    endDate?: day.Dayjs,
    showLoader = true,
  ): Promise<void> {
    this._isLoadingSubj.next(showLoader);
    await this._ftPeriod
      .fetchAllMinimal(startDate, endDate)
      .then(result => {
        this._periodsSubj.next(result);

        if (!result.length) {
          this._instancesSubj.next([]);
        }

        const periodFilter = this._periodFilterSubj.getValue();
        const initialPeriod =
          this._ftPeriod.getCurrentlyInProgressPeriod(result);
        if (!periodFilter && initialPeriod) {
          this.setPeriodFilter(initialPeriod.id);
        }
      })
      .finally(() => {
        this._isLoadingSubj.next(false);
      });
  }

  public async fetchActivityInstances(opts: {
    periodId: number;
    startDate?: day.Dayjs;
    endDate?: day.Dayjs;
    personHashes?: string[];
  }): Promise<void> {
    this._isLoadingInstancesSubj.next(true);
    await this._ftActivityInstances
      .fetchAll(opts || {})
      .then(result => this._instancesSubj.next(result))
      .finally(() => {
        this._isLoadingInstancesSubj.next(false);
      });
  }

  public setPeriodFilter(periodId) {
    this._periodFilterSubj.next(periodId);
  }
}
