import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ReplaySubject } from 'rxjs';

import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import { ILoginResult } from 'src/app/minimal/services/Auth/types';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { LandingService } from '../../services';

@Component({
  selector: 'mg-landing-create',
  templateUrl: './landing-create.component.html',
  styleUrls: ['./landing-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingCreateComponent implements OnInit, OnDestroy {
  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State

  formGroup = new FormGroup({
    password: new FormControl(),
  });
  success = false;
  resetToken = '';

  /** Component constructor */
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    public landing: LandingService,
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      this.resetToken = params.resetToken || '';
    });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async onSubmit() {
    let response: ILoginResult | null = null;

    if (this.resetToken) {
      response = await this._authService.createAccountWithResetToken(
        this.landing.email,
        this.formGroup.controls.password.value,
        this.resetToken,
      );
    } else {
      response = await this._authService.createAccountWithPin(
        this.landing.email,
        this.formGroup.controls.password.value,
        this.landing.pin,
      );
    }

    if (response.mingaLogo) {
      this.landing.mingaLogo = response.mingaLogo;
    }

    if (response.mingaName) {
      this.landing.mingaName = response.mingaName;
    }

    if (response.personName) {
      this.landing.personName = response.personName;
    }

    if (response.success) {
      if (this.resetToken) {
        this._systemAlertSnackBar.success('Password reset successfully');
        await this._router.navigate(['/home']);
      }
      // If our component has already been destroyed, that means we've navigated
      // somewhere else already. Changing the route again could be jarring to
      // the user.
      if (!this._destroyedSubject) {
        await this._router.navigate(['/landing/joining'], {
          skipLocationChange: true,
        });
      }
    } else {
      this._systemAlertSnackBar.error(
        response.reason || 'Could not create account at this time',
      );
    }
  }
}
