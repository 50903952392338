<div class="settings">
  <!-- Membership List Settings -->
  <section
    *ngIf="'MINGA_ACCOUNT_MANAGE' | hasPermission"
    class="section">
    <mg-membership-list-table
      [types]="LIST_TYPES"
      [hideHeader]="true">
    </mg-membership-list-table>
  </section>

  <!-- Permission Settings -->
  <section
    *ngIf="'MINGA_ACCOUNT_MANAGE' | hasPermission"
    class="section">
    <mg-text variant="header-sm-secondary">
      {{ MESSAGES.SUBHEADING_PERMISSIONS_TITLE }}
    </mg-text>
    <div
      fxLayout="column"
      fxLayoutGap="12px">
      <mg-setting-toggle
        [setting]="MINGA_SETTINGS?.FEATURE_TRACKING_TEACHER_POINTS"
        [label]="MESSAGES.SETTING_TEACHERS_ALLOCATE_POINTS"
        [helpTooltip]="MESSAGES.SETTING_TIP_TEACHERS_POINTS_MANAGE">
      </mg-setting-toggle>

      <mg-setting-toggle
        [setting]="MINGA_SETTINGS?.FEATURE_TRACKING_STAFF_POINTS"
        [label]="MESSAGES.SETTING_STAFF_ALLOCATE_POINTS"
        [helpTooltip]="MESSAGES.SETTING_TIP_STAFF_POINTS_MANAGE">
      </mg-setting-toggle>
    </div>
  </section>

  <!-- Rewards Settings -->
  <section
    *ngIf="'MINGA_ACCOUNT_MANAGE' | hasPermission"
    class="section">
    <mg-text variant="header-sm-secondary">
      {{ MESSAGES.SUBHEADING_REWARDS_TITLE }}
    </mg-text>
    <div
      fxLayout="column"
      fxLayoutGap="12px">
      <mg-setting-toggle
        [setting]="MINGA_SETTINGS?.PM_REWARDS_STORE"
        [label]="MESSAGES.SETTING_ENABLE_REWARDS"
        (change)="onRewardsEnabledChange($event)">
      </mg-setting-toggle>
      <div
        *ngIf="areRewardsEnabled$ | async"
        class="indent">
        <mg-setting-toggle
          [setting]="MINGA_SETTINGS?.PM_TEACHERS_REWARDS"
          [label]="MESSAGES.SETTING_TEACHERS_CAN_MANAGE">
        </mg-setting-toggle>
        <mg-setting-toggle
          [setting]="MINGA_SETTINGS?.PM_STAFF_REWARDS"
          [label]="MESSAGES.SETTING_STAFF_CAN_MANAGE">
        </mg-setting-toggle>
      </div>
    </div>
  </section>

  <!-- Leaderboard Settings -->
  <section
    *ngIf="'MINGA_ACCOUNT_MANAGE' | hasPermission"
    class="section">
    <mg-text variant="header-sm-secondary">
      {{ MESSAGES.SUBHEADING_LEADERBOARD_TITLE }}
    </mg-text>
    <div
      fxLayout="column"
      fxLayoutGap="12px">
      <mg-setting-toggle
        [setting]="MINGA_SETTINGS?.FEATURE_TRACKING_PUBLIC_LEADERBOARD"
        [label]="MESSAGES.SETTING_ENABLE_LEADERBOARD"
        (change)="onEnablePublicLeaderboardChange($event)">
      </mg-setting-toggle>
      <div
        *ngIf="isLeaderboardEnabled$ | async"
        class="indent">
        <mg-setting-toggle
          [setting]="MINGA_SETTINGS?.PBIS_INDIVIDUAL_POINTS"
          [label]="MESSAGES.SETTING_SHOW_INDIVIDUAL_POINTS">
        </mg-setting-toggle>
        <mg-setting-toggle
          [setting]="MINGA_SETTINGS?.PBIS_TEAM_POINTS"
          [label]="MESSAGES.SETTING_SHOW_TEAM_POINTS">
        </mg-setting-toggle>
      </div>
    </div>
  </section>
</div>
