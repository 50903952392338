import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mg-welcome-owner',
  templateUrl: './WelcomeOwner.component.html',
  styleUrls: ['./WelcomeOwner.component.scss'],
})
export class WelcomeOwnerComponent {
  readonly stepCount = 5;
  step: number = 0;

  @Output()
  done: EventEmitter<void> = new EventEmitter();

  @Input()
  mingaName: string = 'Your Minga';

  next() {
    if (this.step + 1 < this.stepCount) {
      this.step++;
    } else {
      this.done.emit();
    }
  }
}
