import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  IPageInfo,
  VirtualScrollerComponent,
} from '@minga/ngx-virtual-scroller';
import {
  ContentEvents,
  IContentDeletedEvent,
} from 'minga/app/src/app/minimal/services/ContentEvents';
import { ScrollTargetService } from 'minga/app/src/app/misc/ScrollTarget/service';
import {
  IMgStreamControl,
  IMgStreamFilter,
  IMgStreamItem,
  mgStreamControlObservable,
} from 'minga/app/src/app/util/stream';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  IMgStreamScrollerInstance,
  MG_STREAM_SCROLLER_INSTANCE,
} from './MgStreamScrollerInstance';
import { MgStreamScrollerItemDirective } from './MgStreamScrollerItem.directive';

export type MgStreamScrollerItemMapperFunction = (item: any) => any;

@Component({
  selector: 'mg-stream-scroller',
  templateUrl: './MgStreamScroller.component.html',
  styleUrls: ['./MgStreamScroller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MG_STREAM_SCROLLER_INSTANCE,
      useExisting: forwardRef(() => MgStreamScrollerComponent),
    },
  ],
})
export class MgStreamScrollerComponent<ServiceT = any, ItemT = any>
  implements IMgStreamScrollerInstance, OnInit, OnDestroy
{
  private _scrollBottomAnimFrame: any;
  private _destroyed$ = new ReplaySubject<void>(1);

  @ContentChild(MgStreamScrollerItemDirective, {
    static: false,
    read: TemplateRef,
  })
  streamItemTemplate?: TemplateRef<any>;

  @ViewChild('virtualScroller', { static: false })
  virtualScroller?: VirtualScrollerComponent;

  @Input()
  filter: IMgStreamFilter | null = null;

  @Input()
  service: ServiceT | null = null;

  @Input()
  rpcStream?: keyof ServiceT;

  @Input()
  rpcControl?: keyof ServiceT;

  @Input()
  itemMapper?: MgStreamScrollerItemMapperFunction;

  @Output()
  streamControlCreated: EventEmitter<IMgStreamControl<ItemT>>;

  @Input('mgTrimmed')
  @HostBinding('class.mg-trimmed')
  trimmedMargins: boolean = false;

  /**
   * The number of elements to be rendered above & below the current virtual
   * scroller viewport. Defaults to `5`, you may need to increase this for
   * smaller elements.
   */
  @Input()
  bufferAmount: number = 5;

  /**
   * How many items close to the top or bottom should a load trigger
   */
  @Input()
  loadThreshold: number = 5;

  @Input()
  @HostBinding('class.mg-grid')
  grid: boolean = false;

  @Input()
  clearOnRestart: boolean = false;

  @HostBinding('class.mg-horizontal')
  @Input()
  horizontal: boolean = false;

  @Input()
  extraHeadItems: TemplateRef<any>[] = [];

  @Input()
  extraTailItems: TemplateRef<any>[] = [];

  @Input()
  hideExtraItemsOnEmptyStream: boolean = false;

  @Input()
  fullWidthOnEmptyStream: boolean = false;

  private _delayedSeekTimeout: any;
  private _delayedUpdateRefreshing: boolean = false;
  private _itemDirectives: MgStreamScrollerItemDirective[] = [];
  private _contentDeletedSub?: Subscription;
  private _loadingSub?: Subscription;
  private readonly _loading$ = new BehaviorSubject<boolean>(false);

  stream: IMgStreamControl<ItemT> | null = null;
  items$: Observable<IMgStreamItem<ItemT>[]> | null = null;
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  get isHorizontalVScroller() {
    const streamEmpty = this.isStreamEmpty() && !this.stream?.error?.status;
    if (this.horizontal) {
      if (this.fullWidthOnEmptyStream && streamEmpty) {
        return false;
      }
      return true;
    }
    return false;
  }

  get frontExhausted() {
    if (this.stream) {
      return this.stream.frontExhausted;
    }

    return false;
  }

  get backExhausted() {
    if (this.stream) {
      return this.stream.backExhausted;
    }

    return false;
  }

  get isDone(): boolean {
    if (this.stream) {
      return this.stream.isDone;
    }

    return false;
  }

  get parentScroll() {
    return this.horizontal
      ? undefined
      : this.scrollTargetService.getScrollTarget();
  }

  get showExtraitems() {
    if (this.hideExtraItemsOnEmptyStream) {
      return !(this.isStreamEmpty() && !this.stream?.error?.status);
    }

    return true;
  }

  streamItemTrackBy = (index: number, item: IMgStreamItem<any>) => {
    return item.itemId;
  };

  constructor(
    private contentevents: ContentEvents,
    private scrollTargetService: ScrollTargetService,
  ) {
    this.streamControlCreated = new EventEmitter();

    this.scrollTargetService.scrollTargetChange$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.virtualScroller?.refresh());
  }

  ngOnInit(): void {
    this._contentDeletedSub = this.contentevents.onContentDeleted
      .pipe(takeUntil(this._destroyed$))
      .subscribe(ev => this._onContentDeleted(ev));
  }

  getStreamGrpcErrorCode(): number {
    if (this.stream && this.stream.error) {
      if (this.stream.error.status === 'GrpcError') {
        return this.stream.error.grpcErrorCode;
      }
    }

    return 0;
  }

  restartStream() {
    if (this.stream) {
      this.stream.restart({
        clear: this.clearOnRestart,
      });
    }
  }

  // not used with current virtual scroller
  onUpperThreshold(pageInfo: IPageInfo) {
    if (!this.stream) return;

    if (!this.isDone && !this.stream.isBackLoading && !this.backExhausted) {
      const itemCount = this.stream.length;
      const shouldLoad =
        pageInfo.startIndex <= -itemCount - 1 - -this.loadThreshold;
      if (shouldLoad) {
        this.stream.seekBack();
      }
    }
  }

  onLowerThreshold(pageInfo: IPageInfo) {
    if (!this.stream) return;

    if (
      !this.isDone &&
      !this.stream.isFrontLoading &&
      !this.frontExhausted &&
      pageInfo.endIndex > 0
    ) {
      const itemCount = this.stream.length;
      const shouldLoad =
        pageInfo.endIndex >= itemCount - 1 - this.loadThreshold;

      if (shouldLoad) {
        this.stream.seekFront();
      }
    }
  }

  _onVsStart(pageInfo: IPageInfo) {
    // @TODO: call onUpperThreshold(...)
  }

  _onVsEnd(pageInfo: IPageInfo) {
    this.onLowerThreshold(pageInfo);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.service || changes.rpcStream || changes.rpcControl) {
      this._initStream();
    }

    if (changes.filter) {
      this._updateFilter();
    }
  }

  private _updateFilter() {
    if (!this.stream) return;
    this.stream.updateFilter(this.filter, {
      clear: this.clearOnRestart,
    });
  }

  private _initStream() {
    if (!this.service) return;
    if (!this.rpcStream) return;
    if (!this.rpcControl) return;

    if (this._loadingSub) {
      this._loadingSub.unsubscribe();
    }

    if (this.stream) {
      this.stream.close();
    }

    this.stream = mgStreamControlObservable(
      this.service,
      this.rpcControl,
      this.rpcStream,
      this.filter,
      this.itemMapper,
    );
    this.stream.seekFront();

    this.streamControlCreated.emit(this.stream);

    this._loadingSub = this.stream.loading$.subscribe(loading =>
      this._loading$.next(loading),
    );

    this.items$ = this.stream.asObservable();
  }

  ngOnDestroy() {
    if (this.stream) {
      this.stream.close();
    }

    this._destroyed$.next();
    this._destroyed$.complete();
  }

  isStreamEmpty(): boolean {
    if (this.stream) {
      return this.stream.length == 0 && this.stream.isDone;
    }

    return false;
  }

  unregisterStreamItem(streamItem: MgStreamScrollerItemDirective) {
    const index = this._itemDirectives.indexOf(streamItem);
    if (index !== -1) {
      this._itemDirectives.splice(index, 1);
    }
  }

  registerStreamItem(streamItem: MgStreamScrollerItemDirective) {
    this._itemDirectives.push(streamItem);
  }

  private _onContentDeleted(ev: IContentDeletedEvent) {
    this.restartStream();
  }
}
