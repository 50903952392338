import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  state('in', style({ opacity: 1 })),
  transition('void => *', [style({ opacity: 0 }), animate('0.5s ease-out')]),
  transition('* => void', [animate('0.5s ease-out'), style({ opacity: 0 })]),
]);
