import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
} from '@angular/core';

import { AnalyticsService } from 'src/app/minimal/services/Analytics';

@Component({
  selector: 'mg-tools-link',
  templateUrl: './tools-link.component.html',
  styleUrls: ['./tools-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsLinkComponent {
  @Input() class?: string;

  @Input() label?: string;

  @Input() icon?: string;

  @Input() asset?: string;

  @Input() disabled?: boolean;

  @Input() analyticEventName?: string;

  @HostListener('click')
  handleClick() {
    if (this.analyticEventName) {
      this._analytics.logEvent(this.analyticEventName, {});
    }
  }

  constructor(private _analytics: AnalyticsService) {}
}
