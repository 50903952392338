import { Pipe } from '@angular/core';

@Pipe({ name: 'mgStripHtml' })
export class MgStripHtmlPipe {
  constructor() {}

  transform(value: any): string {
    if (typeof value === 'string') {
      const div = document.createElement('div');
      div.innerHTML = value;
      return div.textContent || '';
    }

    return '';
  }
}
