import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BaseShortCardClass } from 'minga/app/src/app/elements/MgShortCard/BaseShortCardClass';
import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { PollAnswerStateService } from 'minga/app/src/app/minimal/services/PollAnswerState';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { mgResolveAssetUrl } from 'minga/app/src/app/util/asset';
import { isPollClosed, pollLayoutName } from 'minga/app/src/app/util/poll';
import { PollResults } from 'minga/proto/content/poll/answer_pb';
import { ShortPollCardView } from 'minga/proto/gateway/content_views_pb';
import { MingaPermission } from 'minga/util';

export interface IMgPollShortCardElementProperties {
  content?: ShortPollCardView.AsObject;
  selectedChoiceIndex: number;
  showResults: boolean;
  showResultsAlways: boolean;
  showVotes: boolean;
  answered: boolean;
}

@Component({
  selector: 'mg-poll-short-card',
  templateUrl: './MgPollShortCard.element.html',
  styleUrls: ['./MgPollShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPollShortCardElement
  extends BaseShortCardClass
  implements IMgPollShortCardElementProperties, OnInit, OnChanges
{
  @Input()
  content?: ShortPollCardView.AsObject;

  @Input()
  pinned: boolean = false;

  @Input()
  markKeywords: string = '';

  @Input()
  context: string = '';

  @Input()
  commentRoles: string[] | null = null;

  @Input()
  selectedChoiceIndex: number = -1;

  @Input()
  showResults: boolean = false;

  // Show results regardless of `showResults`, `content.showResults` or if
  // a selection is already made.
  @Input()
  showResultsAlways: boolean = false;

  @Input()
  showVotes: boolean = false;

  @HostBinding('class.mg-answered')
  answered: boolean = false;

  @HostBinding('class.mg-cancel-content-link')
  readonly cancelContentLink: boolean = true;

  animHappened: boolean = false;

  // Flag to determine if the answer was answered locally on this instance of
  // mg-poll-short-card
  _answeredLocally: boolean = false;

  private _pollResults: PollResults | null = null;

  readonly pollLayoutName = pollLayoutName;

  constructor(
    public pollAnswerService: PollAnswerStateService,
    private _cdr: ChangeDetectorRef,
    private permissions: PermissionsService,
    private analytics: AnalyticsService,
  ) {
    super();
  }

  @HostListener('click', ['$event'])
  _onClick(e: MouseEvent) {
    e.stopImmediatePropagation();
  }

  private _hasAnswer(): boolean {
    if (this._answeredLocally) {
      return this.answered;
    }

    if (this.context) {
      return this.pollAnswerService.hasAnswer(this.context);
    }

    return false;
  }

  ngOnInit(): void {
    if (this.content.hasResponded != -1) {
      this.pollAnswerService.setAnswer(this.context, this.content.hasResponded);
      this.answered = true;
      this._answeredLocally = true;
      const choiceIdIndex = this.content.choiceList
        .map(({ choiceId }) => choiceId)
        .indexOf(this.content.hasResponded);
      this.selectedChoiceIndex = choiceIdIndex;
    }
    this.content.totalChoiceCount = this.content.pollResults?.totalCount;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.context) {
      this._contextChanged();
    }
  }

  shouldShowResults() {
    return (
      this.showResultsAlways ||
      this.showResults ||
      (this.content && this.content.showResults && this._hasAnswer())
    );
  }

  resetSelection() {
    this.animHappened = false;
    this.selectedChoiceIndex = -1;
    this.showResults = false;
    this.answered = false;
  }

  _applyContentPollResults() {
    if (this.content && this.content.pollResults) {
      const pollResults = new PollResults();
      pollResults.setTotalCount(this.content.pollResults.totalCount);
      const pollChoiceCountMap = pollResults.getPollChoiceCountMap();

      for (let [key, value] of this.content.pollResults.pollChoiceCountMap) {
        pollChoiceCountMap.set(key, value);
      }

      this._storePollResults(pollResults);
      requestAnimationFrame(() => {
        this._applyPollResults(this._pollResults);
        this.animHappened = true;
        this._cdr.markForCheck();
      });
    }
  }

  _applyCurrentAnswerState() {
    if (this.pollAnswerService.hasAnswer(this.context)) {
      this.answered = true;
      const choiceId = this.pollAnswerService.getAnswer(this.context);
      const selectedIndex = this.content.choiceList
        .map(({ choiceId }) => choiceId)
        .indexOf(choiceId);

      this.selectedChoiceIndex = selectedIndex;
      this.showResults = !!this.content.showResults;
    } else {
      this.answered = false;
      this.showResults = false;
    }

    if (this.shouldShowResults()) {
      this._applyContentPollResults();
    }

    if (this.isSelectionLocked()) {
      this.showResults = !!this.content.showResults;
    }

    this._cdr.markForCheck();
  }

  get bgImageUrl(): string {
    if (this.content && this.content.pollBgImage) {
      const bgImageUrl = this.content.pollBgImage;

      return `url(${mgResolveAssetUrl(bgImageUrl)})`;
    }

    return '';
  }

  _contextChanged() {
    this.resetSelection();
    if (this.content) {
      this._applyCurrentAnswerState();
    }
  }

  isSelectionLocked() {
    return (
      this.selectedChoiceIndex > -1 ||
      this.isPollClosed() ||
      !this.permissions.hasPermission(MingaPermission.CONTENT_POLL_ANSWER)
    );
  }

  isChoiceSelected(index: number) {
    return index == this.selectedChoiceIndex;
  }

  getChoicePercent(count: number, totalCount: number) {
    const total = totalCount || 1;
    return (count / total) * 100;
  }

  private _storePollResults(results: PollResults) {
    this._pollResults = results;
  }

  private _applyPollResults(results: PollResults) {
    if (!results) {
      console.warn(
        '<mg-poll-short-card> _applyPollResults has no results to apply.',
      );
      return;
    }

    const totalCount = results.getTotalCount();

    this.content.totalChoiceCount = totalCount;

    const choiceCounts = results.getPollChoiceCountMap().toArray();

    for (let [choiceId, choiceCount] of choiceCounts) {
      const choiceIdIndex = this.content.choiceList
        .map(({ choiceId }) => choiceId)
        .indexOf(choiceId);
      this.content.choiceList[choiceIdIndex].count = choiceCount;
    }
  }

  private async _submitChoice(choiceId: number) {
    if (this.context) {
      const results = await this.pollAnswerService.answerPoll(
        this.context,
        choiceId,
      );

      this.content.pollResults = results.toObject();

      this._storePollResults(results);
      this.analytics.sendPollChoiceSelectedEvent();
    }
  }

  private _zeroChoiceCount() {
    this.content.choiceList.forEach(choice => (choice.count = 0));
  }

  async choiceClick(choiceindex: number) {
    if (this.isSelectionLocked()) {
      return;
    }

    this._answeredLocally = true;
    this.selectedChoiceIndex = choiceindex;
    this.content.totalChoiceCount = this.content.totalChoiceCount + 1;

    if (!this.animHappened) {
      // Temporarily set to 0 for animation
      this._zeroChoiceCount();
    }

    this._cdr.markForCheck();

    await Promise.all([
      this._submitChoice(this.content.choiceList[choiceindex].choiceId),
      new Promise(resolve => setTimeout(resolve, 400)),
    ]).catch(err => {
      this.resetSelection();
      throw err;
    });

    this.answered = true;

    if (this.content.showResults) {
      this.showResults = true;
      requestAnimationFrame(() => {
        this._applyPollResults(this._pollResults);
        this.animHappened = true;
        this._cdr.markForCheck();
      });
    }

    this._cdr.markForCheck();
  }

  textChoiceProgressStyle(count: number, index: number) {
    const total = this.content.totalChoiceCount || 1;
    const progress = count / total;
    const rightPercent = Math.abs(progress - 1) * 100;
    const duration = progress * 2000;
    const delay = Math.abs(index - this.selectedChoiceIndex) * 100;

    return {
      'transition-delay': `${delay}ms`,
      'transition-duration': `${duration}ms`,
      right: `${rightPercent}%`,
    };
  }

  isPollClosed() {
    if (!this.content) return true;
    return isPollClosed(this.content);
  }

  voteText(count: number) {
    return `${count} vote${count == 1 ? '' : 's'}`;
  }
}
