<mg-manager-overlay-reports-subpage
  [loading]="false"
  [noBottomPadding]="reportType.active === REPORT_TYPE.HALL_PASS_SUMMARY"
  [columnSettingsButton]="reportType.active !== REPORT_TYPE.HALL_PASS_SUMMARY"
  [scheduleReportsButton]="
    (SUPPPORTED_SCHEDULE_TYPES | includes: $any(reportType).active) &&
    (canScheduleReport$ | async)
  "
  (scheduleReportsPressed)="onScheduleReport()"
  [dataService]="dataService$ | async"
  (exportListPressed)="hpmReports.exportReport(this.reportType.active)">
  <!-- Header -->
  <ng-template #header>
    <ng-container
      *ngIf="
        (media$ | async) !== 'xs' && (media$ | async) !== 'sm';
        else mobileHeaderTemplate
      ">
      <div class="filters-group">
        <div class="filters-group-row">
          <ng-container
            *ngTemplateOutlet="reportTypeFilterTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="peopleSearchTemplate"></ng-container>
        </div>
        <div class="filters-group-row">
          <ng-container
            *ngIf="(media$ | async) !== 'xs'"
            [ngTemplateOutlet]="hallPassTypes"></ng-container>
          <ng-container [ngTemplateOutlet]="userListTemplate"></ng-container>
          <ng-container
            *ngIf="reportType.active === REPORT_TYPE.HALL_PASS_HISTORY"
            [ngTemplateOutlet]="status"></ng-container>
          <ng-container
            *ngIf="
              reportType.active === REPORT_TYPE.HALL_PASS_HISTORY &&
              ('HALL_PASS_TYPE_MANAGE' | hasPermission)
            "
            [ngTemplateOutlet]="deniedByTemplate"></ng-container>
          <ng-container
            *ngIf="
              reportType.active === REPORT_TYPE.HALL_PASS_HISTORY &&
              ('HALL_PASS_TYPE_MANAGE' | hasPermission)
            "
            [ngTemplateOutlet]="denialReasonTemplate"></ng-container>
        </div>
        <div class="filters-group-row">
          <ng-container *ngTemplateOutlet="createdByTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="filterButtonTemplate"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Filter Template -->
<ng-template #reportTypeFilterTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="305px">
    <mg-form-select
      placeholder="Report Type"
      labelBackground="grey"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="reportType.active"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="reportType.options"
      [floatingLabel]="false"
      (selectionChanged)="onChangeReportType($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Template -->
<ng-template #datePickerTemplate>
  <div class="filters-field half-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>

<!-- People Search Template -->
<ng-template #peopleSearchTemplate>
  <div
    class="filters-field full-width"
    fxFlex.gt-sm="260px">
    <mg-multi-person-search
      [id]="'report-report-view-click-filter-search'"
      (selectionChanged)="onPersonSelected($event)">
    </mg-multi-person-search>
  </div>
</ng-template>

<!-- Hall Pass Types Template -->
<ng-template #hallPassTypes>
  <div class="half-width">
    <mg-form-select
      #typeFilterSelect
      [placeholder]="MESSAGES.SELECT_LABEL_TYPE"
      [value]="hallpassType$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [isFullWidth]="true"
      [searchable]="true"
      [options]="hallpassTypesSelectOptions$ | async"
      (selectionChanged)="onChangeHallpassType($event)">
    </mg-form-select>
  </div>
</ng-template>

<ng-template #userListTemplate>
  <div class="half-width">
    <mg-user-list-filter
      [id]="'report-report-view-click-filter-userlist'"
      [placeholder]="MESSAGES.SELECT_LABEL_USER_LIST"
      [value]="(hpmReports.filter$ | async).userList"
      (selectChanged)="onChangeUserList($any($event))">
    </mg-user-list-filter>
  </div>
</ng-template>

<ng-template #status>
  <div class="half-width">
    <mg-form-select
      [placeholder]="MESSAGES.SELECT_LABEL_STATUS"
      [value]="status$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [options]="STATUS_OPTIONS"
      [isFullWidth]="true"
      (selectionChanged)="onChangeStatus($event)">
    </mg-form-select>
  </div>
</ng-template>

<ng-template #deniedByTemplate>
  <div class="half-width">
    <mg-form-select
      #deniedByFilterSelect
      [placeholder]="MESSAGES.SELECT_LABEL_DENIED_BY"
      [value]="deniedBy$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [options]="DENIED_BY_OPTIONS"
      [disabled]="disableDeniedBy$ | async"
      [isFullWidth]="true"
      (selectionChanged)="onChangeDeniedBy($event)">
    </mg-form-select>
  </div>
</ng-template>

<ng-template #denialReasonTemplate>
  <div class="half-width">
    <mg-form-select
      #denialReasonFilterSelect
      [placeholder]="MESSAGES.SELECT_LABEL_DENIAL_REASON"
      [value]="denialReason$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [options]="denialReasonOptions$ | async"
      [disabled]="!(denialReasonOptions$ | async)?.length"
      [isFullWidth]="true"
      (selectionChanged)="onChangeDenialReason($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Created By Template -->
<ng-template #createdByTemplate>
  <mat-button-toggle-group
    name="createdByFilter"
    class="filter-toggle-group"
    [value]="createdBy$ | async"
    (change)="onChangeCreatedBy($event)">
    <mat-button-toggle
      *ngFor="let option of CREATED_BY_OPTIONS"
      [value]="option.value">
      <mg-text variant="body-sm-secondary">
        {{ option.label }}
      </mg-text>
    </mat-button-toggle>
  </mat-button-toggle-group>
</ng-template>

<!-- Filter Buttons Template -->
<ng-template #filterButtonTemplate>
  <div
    fxFlex="noshrink"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="10px">
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-apply'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'filled'
            : 'outlined'
        "
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [responsive]="true"
        [wide]="true"
        (pressed)="applyFilter()">
        {{ MESSAGES.BUTTON_APPLY_FILTER }}
      </mg-btn>
    </div>
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-clear'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'outlined'
            : 'text'
        "
        [responsive]="true"
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [wide]="true"
        (pressed)="clearFilter()">
        {{ MESSAGES.BUTTON_CLEAR_FILTER }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<ng-template #mobileHeaderTemplate>
  <div class="filters-group">
    <div class="filters-group-row">
      <ng-container *ngTemplateOutlet="reportTypeFilterTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="peopleSearchTemplate"></ng-container>
    </div>
    <mg-collapsible
      [collapseText]="MESSAGES.HIDE_FILTERS"
      [expandText]="MESSAGES.SHOW_FILTERS">
      <div class="filters-group-row">
        <ng-container *ngTemplateOutlet="hallPassTypes"></ng-container>
        <ng-container *ngTemplateOutlet="userListTemplate"></ng-container>
        <ng-container
          *ngIf="reportType.active === REPORT_TYPE.HALL_PASS_HISTORY"
          [ngTemplateOutlet]="status"></ng-container>
        <ng-container
          *ngIf="
            reportType.active === REPORT_TYPE.HALL_PASS_HISTORY &&
            ('HALL_PASS_TYPE_MANAGE' | hasPermission)
          "
          [ngTemplateOutlet]="deniedByTemplate"></ng-container>
        <ng-container
          *ngIf="
            reportType.active === REPORT_TYPE.HALL_PASS_HISTORY &&
            ('HALL_PASS_TYPE_MANAGE' | hasPermission)
          "
          [ngTemplateOutlet]="denialReasonTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="createdByTemplate"></ng-container>
      </div>
    </mg-collapsible>
    <div class="filters-group-row">
      <ng-container *ngTemplateOutlet="filterButtonTemplate"></ng-container>
    </div>
  </div>
</ng-template>
