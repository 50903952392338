import { ITeacherToolsPbis } from '@modules/teacher-tools';
import { PBIS_CHECKIN_TYPES } from '@modules/teacher-tools/constants/tt-view.constants';

export enum StMessages {
  NAV_MY_TOOLS = 'My Tools',
  NAV_BEHAVIOURS = 'Behaviors',
  NAV_POINTS = 'Points',
  NAV_NOTIFICATIONS = 'Notifications',
  NAV_FLEX_TIME = 'FlexTime',

  // My tools
  TITLE_TOOLS = 'My Tools',
  // Behavior Tools Strings
  PBIS_PRAISE = 'Assign a Praise',
  PBIS_GUIDANCE = 'Assign a Guidance',
  PBIS_CONSEQUENCE = 'Assign a Consequence',
  PBIS_REDEEM_POINTS = 'Redeem Points',
  PBIS_MANAGE_POINTS = 'Manage Points',

  // Section Titles
  TITLE_ID_TOOLS = 'Digital ID',
  TITLE_HALL_PASS_TOOLS = 'Hall Pass',
  TITLE_CHECKIN_TOOLS = 'Check In',
  TITLE_PBIS_TOOLS = 'Behaviors',
  TITLE_REWARDS_TOOLS = 'Rewards',

  // ID Tools Strings
  ID_TOOLS_YOUR_ID = 'View Your ID',

  // Hall Pass Tools Strings
  HALL_PASS_CREATE = 'Create a Hall Pass',

  // Leaderboard
  LEADERBOARD = 'See School Leaderboard',

  // Behaviour Strings
  ERROR_LOADING_BEHAVIORS = 'There was a problem loading your behaviors, try refreshing the page.',
  EMPTY_BEHAVIORS = "You don't have any behaviors.",
  ACTIVE_BEHAVIORS = 'Not Completed',
  HISTORIC_BEHAVIORS = 'History',

  CONSEQUENCE_LABEL = 'Consequence: ',
  ASSIGNED_BY = 'Assigned by: ',
  ASSIGNED_DATE = 'Assigned Date: ',
  DUE_DATE = 'Due Date: ',
  COMPLETED_DATE = 'Completed Date: ',
  NOTE_LABEL = 'Note: ',
  OVERDUE_ESCALATE = 'Escalated',
  OVERDUE_ADD = 'Additional',

  NOTE_ESCALATION = 'The original consequence was replaced with this new consequence because it was overdue. (The original consequence doesn’t require completion anymore)',
  NOTE_ADDITIONAL = 'You were assigned an additional consequence because the original consequence is overdue. (The original consequence still requires completion)',

  // Points strings
  POINTS_NAV_POINTS = 'My Points',
  POINTS_NAV_LEADERBOARD = 'Leaderboard',
  POINTS_NAV_REWARDS = 'Rewards',
  POINTS_BANNER_MESSAGE = "You're a natural leader!",
  LIFETIME_POINTS = 'Total Points',
  REDEEMABLE_POINTS = 'Redeemable',

  // Checkin strings
  SELF_CHECKIN = 'Self Check In',
  SELF_CHECKOUT = 'Self Check Out',

  // Flex Time
  SUMMARY_LINE_1 = "You're registered for",
  SUMMARY_LINE_2_SINGULAR = 'upcoming activity!',
  SUMMARY_LINE_2_PLURAL = 'upcoming activities!',
  FETCH_SCHEDULE_ERROR = 'Unable to fetch your schedule, please reload the page and try again',

  REGISTRATION_SUCCESS_TITLE = 'Activity Registration',
  REGISTRATION_SUCCESS_MESSAGE = 'Success!',
  REGISTRATION_SUCCESS_SUB_MESSAGE = 'You have been registered for:',
  REGISTRATION_ERROR = 'There was a problem registering for this activity, please try again',
  CHANGE_SUCCESS = 'Succesfully changed your activity',
}

export const PBIS_OPTIONS: ITeacherToolsPbis[] = [
  {
    label: StMessages.PBIS_PRAISE,
    asset: '/assets/teacher-tools/praise_img.svg',
    type: PBIS_CHECKIN_TYPES.PRAISE,
  },
  {
    label: StMessages.PBIS_GUIDANCE,
    asset: '/assets/teacher-tools/guidance_img.svg',
    type: PBIS_CHECKIN_TYPES.GUIDANCE,
  },
  {
    label: StMessages.PBIS_CONSEQUENCE,
    asset: '/assets/teacher-tools/consequence_img.svg',
    type: PBIS_CHECKIN_TYPES.CONSEQUENCE,
  },
  {
    label: StMessages.PBIS_REDEEM_POINTS,
    asset: '/assets/teacher-tools/points_img.svg',
    type: PBIS_CHECKIN_TYPES.REWARDS,
  },
  {
    label: StMessages.PBIS_MANAGE_POINTS,
    asset: '/assets/teacher-tools/manage_pts_img.svg',
    type: PBIS_CHECKIN_TYPES.MANAGE_POINTS,
  },
];
