import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  IShortCardChallengeClickEvent,
  IShortCardChallengeResponseClickEvent,
} from 'minga/app/src/app/elements/MgShortCard/MgChallengeShortCard';
import { ContentFeedItemView } from 'minga/proto/gateway/feed_pb';

/**
 * Simply displays the correct short card based on the 'content' property.
 */
@Component({
  selector: 'mg-any-short-card',
  templateUrl: './MgAnyShortCard.element.html',
  styleUrls: ['./MgAnyShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgAnyShortCardElement {
  @Input()
  content?: ContentFeedItemView.AsObject;

  @Input()
  pinned: boolean = false;

  @Input()
  context: string = '';

  @Input()
  markKeywords: string = '';

  /**
   * Only applies to poll content
   */
  @Input()
  showPollResults: boolean = false;

  @Output()
  readonly challengeClick: EventEmitter<IShortCardChallengeClickEvent>;

  @Output()
  readonly challengeResponseClick: EventEmitter<IShortCardChallengeResponseClickEvent>;

  constructor() {
    this.challengeClick = new EventEmitter();
    this.challengeResponseClick = new EventEmitter();
  }
}
