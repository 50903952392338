import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { eventErrorOutput } from 'src/app/minimal/services/EventContent';

import { IValidationError } from './types';

@Component({
  selector: 'mg-validation-error-dialog',
  templateUrl: './validation-error-dialog.component.html',
  styleUrls: ['./validation-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorDialogComponent {
  dialogData?: IValidationError;
  peopleNames: string[];
  title: string;
  message: string;

  public readonly outputOptions = eventErrorOutput;

  constructor(
    private _dialogRef: MatDialogRef<ValidationErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IValidationError,
    private _cdr: ChangeDetectorRef,
  ) {
    this.dialogData = data;
    this.title = this.dialogData.title;
    this.message = this.dialogData.message;

    this.peopleNames = [];
    for (const key in this.dialogData.people) {
      if (this.dialogData.people.hasOwnProperty(key)) {
        const value = this.dialogData.people[key];
        this.peopleNames.push(value);
      }
    }
  }

  onClose(bypass) {
    this._dialogRef.close(bypass);
  }
}
