<mg-notification-base
  [read]="notification?.read"
  [viewed]="notification?.viewed"
  [icon]="notification?.iconUrl"
  [iconColor]="notification?.iconColor"
  [avatarSrc]="personProfileImage | mgAssetUrl"
  [timestamp]="notification?.timestamp">
  <mg-notification-base-title [truncated]="false">
    <span
      [innerHTML]="boldPersonNames(notification?.title | mgStripHtml) | mgTextClipper:{clipAmount: 75}"></span>
  </mg-notification-base-title>

  <mg-notification-base-body>
    {{notification?.body | mgStripHtml}}
  </mg-notification-base-body>
</mg-notification-base>
