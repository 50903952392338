<div
  fxLayout="column"
  fxLayoutGap="16px">
  <mg-text variant="header-md-secondary">
    My Groups ({{ (groups$ | async)?.length }})
  </mg-text>
  <ng-container *ngIf="groups$ | async as groups; isLoading">
    <div
      *ngIf="groups?.length; else empty"
      class="group-wrap">
      <mg-carousel
        [config]="carouselConfig"
        [dataSource]="groups">
        <mg-carousel-item
          mgCarouselItem
          *mgCarouselDef="let item; let j = index">
          <div class="group">
            <a
              [routerLink]="['/groups/view/' + item.hash]"
              class="group-link">
              <div
                class="group-img-wrap"
                [style.background-image]="
                  'url(' + getGroupBanner(item) + ')'
                "></div>
              <mg-text
                [numberOfLines]="2"
                [variant]="
                  (mediaService.isMobileView$ | async) ? 'body-xs' : 'body-sm'
                "
                textAlign="center">
                {{ item.name }}
              </mg-text>
            </a>
          </div>
        </mg-carousel-item>
      </mg-carousel>
    </div>
  </ng-container>
  <ng-template #empty>
    <div class="empty-groups">
      <mg-empty-state
        mg-stream-empty
        [title]="MESSAGES.GROUP_NO_NEW_CONTENT">
      </mg-empty-state>
    </div>
  </ng-template>
</div>

<ng-template #isLoading>
  <mg-spinner
    color="primary"
    [diameter]="25"
    [thickness]="3">
  </mg-spinner>
</ng-template>
