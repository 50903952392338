import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgLikeModule } from 'minga/app/src/app/elements/MgLike';
import { MgPersonMinimalModule } from 'minga/app/src/app/elements/MgPersonMinimal';
import { MgIconModule } from 'minga/app/src/app/icon';
import { ChallengeResponseApprovalControlsModule } from 'minga/app/src/app/modules/challenges/components/ChallengeResponseApprovalControls';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { UgcLinksContentPipeModule } from 'minga/app/src/app/ugc-links/pipes/UgcLinksContent';

import { LongCardBaseModule } from '../LongCardBase';
import { ChallengeResponseLongCardComponent } from './ChallengeResponseLongCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgAvatarModule,
    MgPersonMinimalModule,
    MgEllipsisModule,
    MgPipesModule,
    LongCardBaseModule,
    MgImageModule,
    UgcLinksContentPipeModule,
    MgOverlayToolsModule,
    MgIconModule,
    MgLikeModule,
    MgContentLinkModule,
    ChallengeResponseApprovalControlsModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
  ],
  declarations: [ChallengeResponseLongCardComponent],
  exports: [ChallengeResponseLongCardComponent],
})
export class ChallengeResponseLongCardModule {}
