import { Injectable } from '@angular/core';
import { Challenge as ChallengeProto } from 'minga/proto/content/challenge/challenge_ng_grpc_pb';
import {
  ChallengeInvitePerson,
  InviteChallengePeople,
} from 'minga/proto/content/challenge/challenge_pb';
import { ContentInfoQuery } from 'minga/proto/content/common_pb';
import { Observable, Subject } from 'rxjs';

export interface IChallengeInviteSuccesEvent {
  inviteResponseCount?: number;
  invitePeopleCount?: number;
  challengeContextHash: string;
}

@Injectable({ providedIn: 'root' })
export class ChallengeInvitesService {
  private _tempChallengeInvites: string[] = [];
  private _onChallengeInviteSuccess: Subject<IChallengeInviteSuccesEvent> =
    new Subject();

  constructor(private challengesProto: ChallengeProto) {}

  storeTempChallengeInvitesPeople(peopleHashes: string[]) {
    this._tempChallengeInvites = peopleHashes;
  }

  consumeTempChalengeInvitesPeople(): string[] {
    const currentInvites = this._tempChallengeInvites;
    // reset
    this._tempChallengeInvites = [];

    return currentInvites;
  }

  async fetchChallengeInvites(
    challengeContextHash: string,
  ): Promise<ChallengeInvitePerson.AsObject[]> {
    const request = new ContentInfoQuery();
    request.setContentContextHash(challengeContextHash);

    const response = await this.challengesProto.getInvitedPeople(request);
    return response.toObject().personList;
  }

  async invitePeopleToChallenge(
    challengeContextHash: string,
    peopleHashes: string[],
  ) {
    const request = new InviteChallengePeople();
    request.setContentContextHash(challengeContextHash);
    request.setInvitedPersonHashList(peopleHashes);

    return await this.challengesProto.invitePeople(request);
  }

  emitChallengeInviteSuccess(ev: IChallengeInviteSuccesEvent) {
    this._onChallengeInviteSuccess.next(ev);
  }
  get onChallengeInviteSuccess(): Observable<IChallengeInviteSuccesEvent> {
    return this._onChallengeInviteSuccess.asObservable();
  }
}
