import { ChangeDetectionStrategy, Component } from '@angular/core';

import { KioskDisclaimerMessage } from '@modules/kiosk/constants';

import {
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';

@Component({
  selector: 'mg-kiosk-disclaimer',
  templateUrl: './kiosk-disclaimer.component.html',
  styleUrls: ['./kiosk-disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskDisclaimerComponent {
  // Constants
  public readonly MESSAGES = KioskDisclaimerMessage;

  constructor(private _modalRef: ModalOverlayRef) {}

  public close() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  public confirm() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT);
  }
}
