export enum ConsequenceMessages {
  NAME = 'Name, ID number or Email',
  TYPE = 'Consequence name',
  CATEGORY_TYPE = 'Category Type',
  CATEGORY_STATUS = 'Consequence Status',
  GRADE = 'Grade',
  LOADING = 'Loading filters...',
  USER_LIST = 'User List',
}

export enum ConsequenceFields {
  ISSUED_TO = 'issuedTo',
  ISSUED_BY = 'issuedBy',
  TYPE = 'consTypes',
  GRADE = 'grades',
  CATEGORY_TYPE = 'consCatTypes',
  CATEGORY_STATUS = 'consStatus',
  USER_LIST = 'userList',
}
