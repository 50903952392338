import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { PortalLayoutSection } from '../constants/portal-layout.constants';

@Injectable({ providedIn: 'root' })
export class PortalLayoutService {
  /** Layout section content map */
  private readonly _layoutSectionContentMapSubject = new BehaviorSubject<
    Map<PortalLayoutSection, TemplateRef<unknown>>
  >(new Map());

  private readonly _layoutSectionContentMap$ =
    this._layoutSectionContentMapSubject.asObservable();

  private readonly _headerContentSubject =
    new BehaviorSubject<TemplateRef<unknown> | null>(null);
  public readonly headerContent$ = this._headerContentSubject.asObservable();

  private readonly _footerContentSubject =
    new BehaviorSubject<TemplateRef<unknown> | null>(null);
  public readonly footerContent$ = this._footerContentSubject.asObservable();

  private readonly _searchContentSubject =
    new BehaviorSubject<TemplateRef<unknown> | null>(null);
  public readonly searchContent$ = this._searchContentSubject.asObservable();

  /**
   * Service constructor
   */
  constructor() {}

  public observeTemplate$(
    section: PortalLayoutSection,
    viewContainer: ViewContainerRef,
  ) {
    return this._layoutSectionContentMap$.pipe(
      map(m => {
        const content = m?.get(section);
        if (!content) return;
        return new TemplatePortal(content, viewContainer);
      }),
    );
  }

  public setContent(
    section: PortalLayoutSection,
    template: TemplateRef<unknown>,
  ) {
    try {
      if (section === PortalLayoutSection.HEADER) {
        this._headerContentSubject.next(template);
      } else if (section === PortalLayoutSection.FOOTER) {
        this._footerContentSubject.next(template);
      } else if (section === PortalLayoutSection.SEARCH) {
        this._searchContentSubject.next(template);
      } else throw new Error('Invalid section');
    } catch (error) {
      console.warn('Failed to set section content', error);
    }
  }

  public clearSectionContent(section: PortalLayoutSection) {
    try {
      if (section === PortalLayoutSection.HEADER) {
        this._headerContentSubject.next(null);
      } else if (section === PortalLayoutSection.FOOTER) {
        this._footerContentSubject.next(null);
      } else if (section === PortalLayoutSection.SEARCH) {
        this._searchContentSubject.next(null);
      } else throw new Error('Invalid section');
    } catch (error) {
      console.warn('Failed to clear section content', error);
    }
  }

  /**
   * Clear all portal content
   *
   * Should be called when layout changes, i.e login/logout
   */
  public clearAll() {}
}
