import { Injectable } from '@angular/core';

import * as ft_activity_pb from 'minga/proto/flex_time/flex_time_activity_pb';
import { FlexReportUnregisteredTableData } from 'minga/domain/flexTime';
import { Registration } from 'minga/domain/registration';
import {
  ACTIVITY_RESTRICTION_ERRORS_TITLES,
  RESTRICTION_ERRORS,
  RestrictionErrorMinimal,
} from 'minga/domain/restrictions';
import { RootService } from 'src/app/minimal/services/RootService';

import {
  PeopleSelectorService,
  PsData,
  PsSummaryErrorsData,
} from '@modules/people-selector';

import {
  CloseTimeoutDuration,
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import {
  FlexTimePermissionsService,
  FlexTimeRegistrationService,
} from '@shared/services/flex-time';

type FlexActionInfo = {
  type: 'assign' | 'register';
  verb: string;
  action: string;
};

@Injectable({
  providedIn: 'root',
})
export class FlexTimeRegistrationValidationService {
  constructor(
    public snackbar: SystemAlertSnackBarService,
    public alertModal: SystemAlertModalService,
    private _rootService: RootService,
    public _peopleSelector: PeopleSelectorService,
    private _ftRegistration: FlexTimeRegistrationService,
    private _flexPermissions: FlexTimePermissionsService,
  ) {}

  public async assign({
    activityId,
    periodId,
    bypassRestrictions = false,
    hashes,
    selectedPeople,
  }: {
    activityId?: number;
    periodId?: number;
    bypassRestrictions?: boolean;
    hashes?: string[];
    selectedPeople?: PsData[] | FlexReportUnregisteredTableData[];
  }): Promise<void> {
    const actionInfo: FlexActionInfo = {
      type: 'assign',
      verb: 'Assign',
      action: 'Assignment',
    };

    this._doFlexAction({
      actionInfo,
      activityId,
      periodId,
      bypassRestrictions,
      hashes,
      selectedPeople,
    });
  }

  public async register({
    activityId,
    periodId,
    bypassRestrictions = false,
    hashes,
    selectedPeople,
  }: {
    activityId?: number;
    periodId?: number;
    bypassRestrictions?: boolean;
    hashes?: string[];
    selectedPeople?: PsData[] | FlexReportUnregisteredTableData[];
  }): Promise<void> {
    const actionInfo: FlexActionInfo = {
      type: 'register',
      verb: 'Register',
      action: 'Registration',
    };

    this._doFlexAction({
      actionInfo,
      activityId,
      periodId,
      bypassRestrictions,
      hashes,
      selectedPeople,
    });
  }

  private async _doFlexAction({
    actionInfo,
    activityId,
    periodId,
    bypassRestrictions = false,
    hashes,
    selectedPeople,
  }: {
    actionInfo: FlexActionInfo;
    activityId?: number;
    periodId?: number;
    bypassRestrictions?: boolean;
    hashes?: string[];
    selectedPeople?: PsData[] | FlexReportUnregisteredTableData[];
  }): Promise<void> {
    try {
      let targetHashes = hashes;

      if (!hashes && !bypassRestrictions) {
        targetHashes = await this._validateRegistration(
          periodId,
          activityId,
          actionInfo,
          this._getSelectedHashes(selectedPeople),
          selectedPeople,
        );
        if (targetHashes?.length) bypassRestrictions = true;
      }
      if (!targetHashes?.length) return;
      const { assignments, errors } = await this._rootService.addLoadingPromise(
        this._ftRegistration.assign(
          activityId,
          targetHashes,
          actionInfo.type === 'register',
          bypassRestrictions,
        ),
      );
      if (assignments.length > 0 && errors.length === 0) {
        this._peopleSelector.openDialog({
          type: 'success',
          subTitle: `Student(s) successfully ${actionInfo.type}ed`,
          closeTimeout: CloseTimeoutDuration.SHORT,
        });
      }
      if (errors.length) {
        const overidePeopleHashes = await this._handleAssignErrors(
          actionInfo,
          assignments,
          errors,
          selectedPeople,
        );
        if (overidePeopleHashes.length) {
          await this._doFlexAction({
            actionInfo,
            activityId,
            periodId,
            bypassRestrictions: true,
            hashes: overidePeopleHashes,
          });
        }
      }
    } catch (error) {
      this.snackbar.open({
        type: 'error',
        message:
          error.message || 'Something went wrong, please try again later',
      });
    }
  }

  private async _validateRegistration(
    periodId: number,
    activityId: number,
    actionInfo: FlexActionInfo,
    hashes,
    selectedPeople,
  ): Promise<string[]> {
    const singlePersonSubmission = hashes.length === 1;

    const { success, existingRegistrations, restrictionsErrors } =
      await this._rootService.addLoadingPromise(
        this._ftRegistration.validate(periodId, hashes, activityId),
      );

    if (success.length === hashes.length) return hashes;

    if (singlePersonSubmission) {
      return await this._handlePersonValidationErrors(
        existingRegistrations,
        restrictionsErrors,
        actionInfo,
        hashes,
      );
    } else {
      if (success.length)
        await this._doFlexAction({
          actionInfo,
          activityId,
          periodId,
          bypassRestrictions: true,
          hashes: success,
        });

      const errorsData = this._makeErrorData(
        success,
        existingRegistrations,
        restrictionsErrors,
        actionInfo,
        selectedPeople,
      );
      const overrideHashes = await this._peopleSelector.openSummary({
        message: `could not be ${actionInfo.verb}ed`,
        title: `${actionInfo.action} summary`,
        subMessage: `Select the people you want to “${actionInfo.verb}” to replace the activity`,
        submitLabel: actionInfo.verb,
        errorsData,
      });
      if (overrideHashes.length)
        await this._doFlexAction({
          actionInfo,
          activityId,
          periodId,
          bypassRestrictions: true,
          hashes: overrideHashes,
        });
    }
  }

  private async _handlePersonValidationErrors(
    registrations: ft_activity_pb.ExistingRegistration.AsObject[],
    restrictions: RestrictionErrorMinimal[],
    actionInfo: FlexActionInfo,
    hashes: string[],
  ) {
    const canOveride = this._flexPermissions.isFlexTimeAdmin();
    let hardStop = false;
    const overrides: null | boolean[] = [null, null];
    if (restrictions && restrictions.length > 0) {
      for (const restriction of restrictions) {
        if (hardStop) break;
        const { code } = restriction;
        const systemAlertModal = await this.alertModal.open({
          modalType: SystemAlertModalType.ERROR,
          heading: `This person doesn't match the activity restriction`,
          message:
            RESTRICTION_ERRORS[code].message || `${actionInfo.type} denied`,
          closeBtn: 'Close',
          confirmActionBtn: `${actionInfo.verb} anyway`,
        });
        const { type } = await systemAlertModal.afterClosed().toPromise();
        if (type === SystemAlertCloseEvents.CONFIRM) overrides[0] = true;
        else hardStop = true;
      }
    }
    if (registrations && registrations.length > 0 && !hardStop) {
      const overrideHashes = await this._existingRegistrationError(
        actionInfo,
        canOveride,
        hashes,
        registrations[0],
      );
      if (overrideHashes?.length > 0) overrides[1] = true;
    }
    const checks = overrides.filter(type => typeof type === 'boolean');
    if (checks.length > 0) return checks.every(v => v) ? hashes : [];
    else return [];
  }

  private async _existingRegistrationError(
    actionInfo: FlexActionInfo,
    canOveride: boolean,
    hashes: string[],
    {
      activityName,
      activityTeacherName,
      registeredBy,
      activityLocation,
      studentName,
      type: registrationType,
    }: ft_activity_pb.ExistingRegistration.AsObject,
  ): Promise<string[]> {
    const isAssignment =
      registrationType === ft_activity_pb.RegistrationType.ASSIGNMENT;
    const canContinue = !isAssignment || canOveride;
    const systemAlertModal = await this.alertModal.open({
      modalType: canContinue
        ? SystemAlertModalType.WARNING
        : SystemAlertModalType.ERROR,
      heading: `This student is already ${actionInfo.type}ed to another activity`,
      message: canContinue
        ? 'Would you like to replace their current activity?'
        : `This student is already ${actionInfo.type}ed to another activity`,
      confirmActionBtn: canContinue ? actionInfo.verb : 'Close',
      closeBtn: canContinue && 'Cancel',
      detailedMessage: [
        [`${actionInfo.verb}ed by`, registeredBy],
        ['Student', studentName],
        ['Activity', activityName],
        ['Location', activityLocation],
        ['Teacher', activityTeacherName],
      ],
    });
    const { type } = await systemAlertModal.afterClosed().toPromise();
    if (type === SystemAlertCloseEvents.CONFIRM)
      return canContinue ? hashes : [];
  }

  private _makeErrorData(
    success: string[],
    data: ft_activity_pb.ExistingRegistration.AsObject[],
    restrictions: RestrictionErrorMinimal[],
    actionInfo: FlexActionInfo,
    selectedPeople: PsData[] | FlexReportUnregisteredTableData[],
  ): PsSummaryErrorsData[] {
    const successes = success.map(hash => {
      const person = this._getSelected(selectedPeople, hash);
      return {
        hash: this._getHash(person),
        name: this._getPersonName(person),
        status: 'success',
        reasons: [`${actionInfo.verb}ed`],
      } as PsSummaryErrorsData;
    });
    const restrictionsErrors = restrictions.map(
      ({ code, personHash, personName }) =>
        ({
          status: 'error',
          hash: personHash,
          name: personName,
          reasons: [
            RESTRICTION_ERRORS[code].message || `${actionInfo.type} denied`,
          ],
        } as PsSummaryErrorsData),
    );
    const errors = data.map(
      ({
        studentHash,
        studentName,
        registeredBy,
        activityName,
        activityTeacherName,
        activityLocation,
        type,
      }) =>
        ({
          hash: studentHash,
          name: studentName,
          status: 'error',
          reasons: [
            `${
              type === ft_activity_pb.RegistrationType.ASSIGNMENT
                ? 'Assigned'
                : 'Registered'
            } to another activity`,
          ],
          advancedTooltip: [
            [
              type === ft_activity_pb.RegistrationType.ASSIGNMENT
                ? 'Assigned by'
                : 'Registered by',
              registeredBy,
            ],
            ['Activity', activityName],
            ['Teacher', activityTeacherName],
            ['Location', activityLocation],
          ],
        } as PsSummaryErrorsData),
    );
    return [...restrictionsErrors, ...errors, ...successes];
  }

  private async _handleAssignErrors(
    actionInfo: FlexActionInfo,
    successes: Registration[],
    errors: RestrictionErrorMinimal[],
    selectedPeople: PsData[] | FlexReportUnregisteredTableData[],
  ): Promise<string[]> {
    const successMapped = successes.map(({ personHash }) => {
      const person = this._getSelected(selectedPeople, personHash);
      return {
        status: 'success',
        hash: this._getHash(person),
        name: this._getPersonName(person),
        reasons: [`${actionInfo.verb}ed`],
      } as PsSummaryErrorsData;
    });
    const errorsMapped: PsSummaryErrorsData[] = errors.map(
      ({ personHash, personName, code }) =>
        ({
          status: 'error',
          hash: personHash,
          name: personName,
          reasons: [RESTRICTION_ERRORS[code].title || 'Assignment denied'],
        } as PsSummaryErrorsData),
    );
    if (successes.length === 0 && errors.length === 1) {
      const { personHash, code } = errors[0];
      const systemAlertModal = await this.alertModal.open({
        modalType: SystemAlertModalType.ERROR,
        heading:
          ACTIVITY_RESTRICTION_ERRORS_TITLES[code].header ||
          `${actionInfo.action} denied`,
        message:
          RESTRICTION_ERRORS[code].message || `${actionInfo.action} denied`,
        closeBtn: 'Close',
        confirmActionBtn: `${actionInfo.verb} anyway`,
      });

      const systemAlertModalData = await systemAlertModal
        .afterClosed()
        .toPromise();

      switch (systemAlertModalData?.type) {
        case SystemAlertCloseEvents.CONFIRM:
          return [personHash];
        case SystemAlertCloseEvents.CLOSE:
          return [];
      }
    } else {
      const hashes = await this._peopleSelector.openSummary({
        message: `could not be ${actionInfo.type}ed`,
        title: `${actionInfo.action} summary`,
        subMessage: `Select the people you want to “${actionInfo.verb}”`,
        submitLabel: actionInfo.verb,
        errorsData: [...successMapped, ...errorsMapped],
      });
      return hashes;
    }
  }

  private _getSelected(
    selectedPeople: (PsData | FlexReportUnregisteredTableData)[],
    personHash: string,
  ) {
    const selectedPerson = selectedPeople.find(
      (person: PsData | FlexReportUnregisteredTableData): person is PsData => {
        return this._getHash(person) === personHash;
      },
    );

    if (!selectedPerson) throw new Error('Person not found');

    return selectedPerson;
  }

  private _getSelectedHashes(
    selectedPeople: PsData[] | FlexReportUnregisteredTableData[],
  ) {
    return (selectedPeople as Array<PsData | FlexReportUnregisteredTableData>)
      .map(person => this._getHash(person))
      .filter(hash => hash !== null);
  }

  private _getPersonName(
    person: PsData | FlexReportUnregisteredTableData,
  ): string {
    if ('firstName' in person && 'lastName' in person) {
      return `${person.firstName} ${person.lastName}`;
    } else if ('displayName' in person) {
      return person.displayName;
    }
    return '';
  }

  private _getHash(
    person: PsData | FlexReportUnregisteredTableData,
  ): string | null {
    if ('hash' in person) {
      return person.hash;
    } else if ('personHash' in person) {
      return person.personHash;
    }
    return null;
  }
}
