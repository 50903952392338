import { pickBy } from 'lodash';

import { IHallPassBlackOut } from 'minga/domain/hallPass/IHallPassBlackOut';
import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';

import { HPM_RESTRICTION_INITIAL_NOPARTY_LIST_STATE } from '../constants';
import { HpmRestrictionsLists, HpmRestrictionsListType } from '../types';

export const getNewHpmRestrictionListState = async (
  listType: HpmRestrictionsListType,
): Promise<IMembershipList> => {
  switch (listType) {
    case MembershipListType.NO_PARTY: {
      return { ...HPM_RESTRICTION_INITIAL_NOPARTY_LIST_STATE };
    }
    default: {
      return { ...HPM_RESTRICTION_INITIAL_NOPARTY_LIST_STATE };
    }
  }
};

export const getInitialListsState = (
  lists: HpmRestrictionsListType[],
): HpmRestrictionsLists => {
  const state = {} as HpmRestrictionsLists;
  lists.forEach(list => (state[list] = []));
  return state;
};

export const getScheduleBlockedDay = (schedule: IHallPassBlackOut) => {
  return Object.keys(
    pickBy(
      {
        Monday: schedule.blockMonday,
        Tuesday: schedule.blockTuesday,
        Wednesday: schedule.blockWednesday,
        Thursday: schedule.blockThursday,
        Friday: schedule.blockFriday,
        Saturday: schedule.blockSaturday,
        Sunday: schedule.blockSunday,
      },
      day => !!day,
    ),
  );
};
