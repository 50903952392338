import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MgButtonModule } from 'minga/app/src/app/button';

import { EventGoingStateComponent } from './EventGoingState.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,

    // External dependencies
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonToggleModule,
  ],
  declarations: [EventGoingStateComponent],
  exports: [EventGoingStateComponent],
})
export class EventGoingStateModule {}
