<ng-template #pickedTitle>
  <p class="school-picker-title"><strong>Your School(s)</strong></p>
</ng-template>

<ng-template #availableTitle>
  <p><strong>All Schools</strong></p>
</ng-template>

<mg-parent-group-picker
  [clickMode]="'JOIN'"
  [noSuccessInterstitial]="noSuccessInterstitial">
  <ng-container
    pickedTitle
    *ngTemplateOutlet="pickedTitle"></ng-container>
  <ng-container
    availableTitle
    *ngTemplateOutlet="availableTitle"></ng-container>

  <ng-container noAvailableGroups>
    <ng-container
      availableTitle
      *ngTemplateOutlet="availableTitle"></ng-container>

    <p class="mg-centered">You've joined all available schools already!</p>
  </ng-container>
  <ng-container noPickedGroups>
    <ng-container
      pickedTitle
      *ngTemplateOutlet="pickedTitle"></ng-container>

    <ng-container *hasPermission="'IS_PARENT'; else notParentEmpty">
      <p class="subtitle mg-smallcopy">
        Find and join your children's school(s) from the list
      </p>
    </ng-container>
    <ng-template #notParentEmpty>
      <p class="subtitle mg-smallcopy">
        Find and join your school from the list
      </p>
    </ng-template>
  </ng-container>

  <ng-container noGroups>
    <p class="mg-centered">Your Minga has no schools</p>
  </ng-container>
</mg-parent-group-picker>
