import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mg-nav-tabs',
  templateUrl: './NavTabs.component.html',
  styleUrls: ['./NavTabs.component.scss'],
})
export class NavTabsComponent {
  /* @NOTE: There's a matching Output below for change emission */
  @Input()
  selectedIndex: number | null = null;

  @Input()
  tabLabels: string[] = [];

  @Output()
  selectedIndexChange: EventEmitter<number> = new EventEmitter();

  onSelectedIndexChange(index: number) {
    this.selectedIndexChange.emit(index);
  }
}
