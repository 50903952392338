import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface IDeleteConfirmationOptions {
  matchString?: string;
  titleLocaleValue?: string;
  placeholderLocaleValue?: string;
  placeholderValue?: string;
  showSubTitle?: boolean;
}

@Component({
  selector: 'mg-remove-program-confirm-dialog',
  templateUrl: './DeleteConfirmation.component.html',
  styleUrls: ['./DeleteConfirmation.component.scss'],
})
export class DeleteConfirmationDialogComponent implements OnInit {
  titleLocaleValue: string = '';
  matchString: string = '';
  inputString: string = '';
  placeholderValue: string = '';
  translateParams: any;
  titleParams: any;
  showSubTitle: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    private _systemAlertSnackBar: SystemAlertSnackBarService,

    @Inject(MAT_DIALOG_DATA) public data: IDeleteConfirmationOptions,
  ) {
    this.titleLocaleValue = data['titleLocaleValue'] || '';
    this.placeholderValue = data['placeholderValue'] || '';
    this.matchString = data['matchString'] || '';
    this.showSubTitle = 'showSubTitle' in data ? !!data['showSubTitle'] : true;

    this.translateParams = { value: this.matchString };
    this.titleParams = { value: this.titleLocaleValue || this.matchString };
  }

  ngOnInit() {}

  get disableButton() {
    return !!(this.inputString != this.matchString);
  }

  delete() {
    if (this.inputString != this.matchString) {
      this._systemAlertSnackBar.error(
        `The value you entered does not match, cannot delete ${this.placeholderValue}`,
      );
      return;
    }

    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
