<mg-legacy-overlay-nav [component]="this"></mg-legacy-overlay-nav>

<mg-long-card-base
  [component]="this"
  #longCard
  *ngIf="content"
  [class.no-bg]="hasNoBackground"
  [ngClass]="themeColorClass">
  <div
    class="image-container"
    [class.no-bg]="hasNoBackground"
    [style.background-color]="content.backgroundColor"
    [class.expanded-text]="expandedBodyText">
    <mg-image
      *ngIf="content.featuredImage"
      [srcs]="content.featuredImage | mgImageUrls: bannerImageSizes"
      [background-size]="featuredImageBackgroundSize"></mg-image>
    <div
      class="image-content"
      #bodyOverImage>
      <p
        *ngIf="content.type == 'TEXT'"
        class="body"
        [innerHTML]="bodyText | mgSafeHtml"
        [style.font-size.px]="bodyTextSize"></p>
    </div>
  </div>
  <div class="person-info-container">
    <div class="person-info">
      <ng-container
        *ngIf="
          content.authorPersonView && content.authorPersonView.profileImageUrl
        ">
        <mg-avatar
          class="author-avatar"
          [ngClass]="'archived-' + content.authorPersonView.archived"
          [src]="content.authorPersonView.profileImageUrl"
          [color]="content.authorPersonView.badgeIconColor"
          [personHash]="content.authorPersonView.personHash"></mg-avatar>
      </ng-container>

      <div
        class="post-byline-wrap mg-bodycopy"
        *ngIf="content.authorPersonView">
        <mg-person-minimal
          [person]="content.authorPersonView"></mg-person-minimal>
        <div
          *ngIf="publishDateTime"
          class="timestamp">
          {{ longCard.humanize(publishDateTime) }}
        </div>
      </div>
    </div>
    <div class="ellipsis">
      <mg-ellipsis [mgContentContextMenuClick]="contextHash"></mg-ellipsis>
    </div>
  </div>
  <div
    *ngIf="!content.backgroundImage && content.featuredImage"
    class="below-image-content">
    <p
      class="body"
      [innerHTML]="content.body"></p>
  </div>
  <div class="divider"></div>
</mg-long-card-base>

<div
  *ngIf="showApproval"
  class="approval-controls">
  <mg-challenge-approval-controls
    [currentState]="content.approved"
    [contextHash]="content.contextHash"
    (approved)="onApproved($event)"
    (declined)="onDeclined($event)"></mg-challenge-approval-controls>
</div>
