import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScrollTargetService {
  /** @internal */
  private _targets: Element[] = [];
  private _scrollTargetChange = new Subject<Element | Window>();

  readonly scrollTargetChange$: Observable<Element | Window>;

  constructor() {
    this.scrollTargetChange$ = this._scrollTargetChange.asObservable();
  }

  getScrollTarget(): Element | Window {
    return this._targets.length == 0
      ? window
      : this._targets[this._targets.length - 1];
  }

  pushScrollTarget(target: Element) {
    this._targets.push(target);
    this._scrollTargetChange.next(target);
  }

  pullScrollTarget(target: Element): boolean {
    const index = this._targets.indexOf(target);
    if (index !== -1) {
      this._targets.splice(index, 1);
      this._scrollTargetChange.next(this.getScrollTarget());
      return true;
    }

    return false;
  }
}
