import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';

import { calcFontSizeToFit } from 'minga/app/src/app/util/fontSizing';
import { ChallengeColor, ChallengeType } from 'minga/domain/challenge';
import { ChallengeResponseCard } from 'minga/proto/content/challenge/challenge_response_pb';

import { BaseShortCardClass } from '../BaseShortCardClass';

export interface IMgChallengeResponseShortCardElementProperties {}
const PREVIEW_LIMIT = 3;

@Component({
  selector: 'mg-challenge-response-short-card',
  templateUrl: './MgChallengeResponseShortCard.element.html',
  styleUrls: ['./MgChallengeResponseShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgChallengeResponseShortCardElement
  extends BaseShortCardClass
  implements AfterViewInit
{
  /** @internal */
  private _content: ChallengeResponseCard.AsObject | null = null;
  /** @internal */
  private _themeColor: ChallengeColor = ChallengeColor.RED;

  responsePreviewLength: number = 60;
  typeStyleClass: string = '';
  themeColorClass: string = '';
  dueDate: Date | null = null;
  bodyTextSize?: number;
  featuredImageBackgroundSize: string = 'cover';

  @ViewChild('bodyOverImage', { static: false })
  bodyOverImageEl?: ElementRef;

  @HostBinding('class.has-comments')
  get hasComments() {
    return this._content?.commentList?.length;
  }

  @HostBinding('class.mg-cancel-content-link')
  readonly cancelContentLink: boolean = true;

  @HostListener('window:resize', [])
  onWindowResize() {
    this.calcBodyTextStyle();
    this.responsePreviewLength = window.innerWidth > 599 ? 105 : 60;
  }

  @Input()
  set content(content: ChallengeResponseCard.AsObject | null) {
    this._content = content || null;
    if (this._content?.backgroundImage && !this._content?.featuredImage) {
      this._content.featuredImage = this._content.backgroundImage;
    }
    if (this._content?.color) {
      this.themeColorClass = 'challenge-theme-color-' + this._content.color;
    }

    this.updateBasedOffType();
    this.calcBodyTextStyle();
  }

  get content(): ChallengeResponseCard.AsObject | null {
    return this._content;
  }

  get hasNoBackground() {
    return (
      !this._content ||
      (!this._content.featuredImage &&
        !this._content.backgroundImage &&
        !this._content.backgroundColor)
    );
  }

  @Input()
  set themeColor(color: ChallengeColor) {
    this._themeColor = color;
  }

  get themeColor() {
    return this._themeColor;
  }

  @Input()
  pinned: boolean = false;

  @Input()
  markKeywords: string = '';

  @Input()
  commentRoles: string[] | null = null;

  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  updateBasedOffType() {
    if (!this._content || !this._content.type) this.typeStyleClass = '';

    switch (this._content?.type) {
      case ChallengeType.TEXT:
        this.typeStyleClass = 'text';
        this.featuredImageBackgroundSize = 'cover';
        break;
      case ChallengeType.VIDEO:
        this.typeStyleClass = 'video';
        this.featuredImageBackgroundSize = 'cover';
        break;
      case ChallengeType.IMAGE:
      default:
        this.typeStyleClass = 'photo';
        this.featuredImageBackgroundSize = 'contain';
    }
  }

  ngAfterViewInit() {
    this.onWindowResize();
  }

  calcBodyTextStyle() {
    if (!(this.content?.type == 'TEXT')) {
      return;
    }
    const maxFontSize = 29;
    const minFontSize = 14;

    if (this.content && this.bodyOverImageEl) {
      let elWidth = this.bodyOverImageEl.nativeElement.offsetWidth;
      let elHeight = this.bodyOverImageEl.nativeElement.offsetHeight;
      const elStyles = window.getComputedStyle(
        this.bodyOverImageEl.nativeElement,
      );

      // get padding to subtract from container dimensions
      const paddingHorz =
        parseFloat(elStyles.paddingLeft) + parseFloat(elStyles.paddingRight);
      elWidth -= paddingHorz;
      const paddingVert =
        parseFloat(elStyles.paddingTop) + parseFloat(elStyles.paddingBottom);
      elHeight -= paddingVert;

      const response = calcFontSizeToFit(
        this.content.body,
        elWidth,
        elHeight,
        maxFontSize,
        minFontSize,
      );

      this.bodyTextSize = response.fontSize;
      const reducedTextLength = response.reducedTextLength;

      // if had to reduce text length, add ellipsis at end
      if (reducedTextLength) {
        this.content.body = this.content.body.substring(
          0,
          reducedTextLength - 4,
        );
        this.content.body += '...';
      }

      this._cdr.markForCheck();
    }
  }

  noClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }
}
