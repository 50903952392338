import * as day from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';
import { Observable, of } from 'rxjs';
day.extend(localeData);

/**
 * Get Name Of Weeks Days Observable
 */
export const getNameOfWeeksDaysObservable = (): Observable<string[]> => {
  const weekdays = day.weekdays(true);
  return of(weekdays);
};
