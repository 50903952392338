import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[mgAutofocus],[mgAutofocus.gt-xs]',
  exportAs: 'mgAutofocus',
})
export class MgAutofocusDirective implements OnInit, OnDestroy {
  @Input('mgAutofocus')
  mgAutofocus: string;

  @Input('mgAutofocus.gt-xs')
  onlyGtXs;

  private _mediaSubscription: Subscription;

  mqAlias: string;

  constructor(private element: ElementRef, private media: MediaObserver) {
    this._initMediaObservable();
  }

  private _initMediaObservable() {
    this._mediaSubscription = this.media
      .asObservable()
      .subscribe(mediaChanges => {
        for (const mediaChange of mediaChanges) {
          this.mqAlias = mediaChange.mqAlias;
          // @TODO: Angular8 - @angular/flex-layout documentation
          break;
        }
      });
  }

  private _cleanupMediaObservable() {
    this._mediaSubscription.unsubscribe();
  }

  ngOnInit() {
    if (!this.element) {
      return;
    }
    let el = this.element.nativeElement;
    if (!el) {
      return;
    }

    switch ('' + this.mgAutofocus) {
      case '0':
      case 'off':
      case 'false':
        return;
    }
    if (typeof this.onlyGtXs !== 'undefined' && this.mqAlias === 'xs') {
      return;
    }

    // @HACK: without setTimeout autofocus isn't working,
    // ngZone.run didn't work either
    // @TODO: look into a better solution...
    setTimeout(() => {
      let inputEl = el.querySelector('input');
      if (inputEl) {
        inputEl.focus();
      } else {
        el.focus();
      }
    });
  }

  ngOnDestroy() {
    this._cleanupMediaObservable();
  }
}
