import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { MgEmptyStateModule } from 'minga/app/src/app/components/EmptyState';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { PermissionsModule } from 'src/app/permissions';
import { MgPipesModule } from 'src/app/pipes';

import { TeacherToolsFlexTimeService } from '@modules/teacher-tools/services/tt-flex-time.service';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { PaginatorModule } from '@shared/components/paginator';
import { TextModule } from '@shared/components/text/text.module';
import { TooltipModule } from '@shared/components/tooltip';

import { MultiPersonSearchModule } from '../../../../shared/components/multi-person-search/multi-person-search.module';
import { FtmSharedModule } from '../ftm-shared/ftm-shared.module';
import { FtmActivitiesComponent } from './ftm-activities.component';

@NgModule({
  declarations: [FtmActivitiesComponent],
  providers: [TeacherToolsFlexTimeService],
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    MgSpinnerModule,
    FormModule,
    MgIconModule,
    MgEmptyStateModule,
    TooltipModule,
    MgImageModule,
    MgPipesModule,
    FtmSharedModule,
    TextModule,
    GenericModule,
    PaginatorModule,
    PermissionsModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatRippleModule,
    ReactiveFormsModule,
    MultiPersonSearchModule,
    MatExpansionModule,
    MatButtonToggleModule,
  ],
})
export class FtmActivitiesModule {}
