import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ReportTypes } from 'minga/domain/reportTypes';

import { BmReportsFilterType } from '../../constants';
import { BmReportsStudentDatasourceService } from '../../services';

@Component({
  selector: 'mg-bm-reports-student',
  templateUrl: './bm-reports-student.component.html',
  styleUrls: ['./bm-reports-student.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BmReportsStudentDatasourceService],
})
export class BmReportsStudentComponent {
  public historyDropDown = [
    {
      label: 'Behavior History',
      filter: BmReportsFilterType.ISSUED_TO,
      url: '/pbis/reports/' + ReportTypes.PBIS_HISTORY,
    },
    {
      label: 'Consequence History',
      filter: BmReportsFilterType.ISSUED_TO,
      url: '/pbis/reports/' + ReportTypes.PBIS_CONS_HISTORY,
    },
  ];

  /** Component Constructor */
  constructor(public ds: BmReportsStudentDatasourceService) {}
}
