import { createSelector, MemoizedSelector } from '@ngrx/store';

import { GroupFormActions } from '../actions/';
import { GroupFormState } from '../state';

export function groupFormReducer(
  state: GroupFormState = GroupFormState.initialState,
  action: GroupFormActions.TypeUnion,
): GroupFormState {
  switch (action.type) {
    case GroupFormActions.TypeEnum.CreateGroup: {
      return {
        ...state,
        loading: true,
      };
    }
    case GroupFormActions.TypeEnum.CreateGroupFailure: {
      return {
        ...state,
        loading: false,
      };
    }
    case GroupFormActions.TypeEnum.CreateGroupSuccess: {
      return {
        ...state,
        loading: false,
      };
    }
    case GroupFormActions.TypeEnum.UpdateGroup: {
      return {
        ...state,
        loading: true,
      };
    }
    case GroupFormActions.TypeEnum.UpdateGroupFailure: {
      return {
        ...state,
        loading: false,
      };
    }
    case GroupFormActions.TypeEnum.UpdateGroupSuccess: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
