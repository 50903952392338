<mg-overlay-primary
  size="auto"
  [isLoading]="false"
  [overlayTitle]=""
  [skipLoadCheck]="true">
  <ng-container *ngTemplateOutlet="main"></ng-container>
</mg-overlay-primary>

<ng-template #main>
  <iframe
    #hubspot
    [src]="hubspotUrl$ | async"
    [style.background-color]="'white'"
    frameborder="0">
  </iframe>
</ng-template>
