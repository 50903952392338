<div [mgContentContextMenu]="context">
  <div class="top-bar">
    <div class="sms-image"></div>
    <div class="top-bar-text">TEXT MESSAGE</div>
    <mg-ellipsis
      *ngIf="context"
      [mgContentContextMenuClick]="context"></mg-ellipsis>
  </div>

  <div class="content-container">
    <div class="byline">
      <span
        *ngIf="authorName"
        class="byline-author-name"
        >{{ authorName }}</span
      >
      <span *ngIf="authorName && publishDate"> - </span>
      <span
        *ngIf="publishDate"
        class="byline-publish-date"
        >{{ publishDateDisplayString }}</span
      >
    </div>
    <div class="message-text">{{ message }}</div>
  </div>
</div>
