<ng-template #defaultNavLinks>
  <div
    class="nav-item-inner nav-item-icon mg-button-hover-harbour"
    [class.ftue-highlight]="
      highlightKey && (highlightKey | mgFtueHighlight | async)
    "
    [title]="titleText"
    [ngClass]="[iconColor]">
    <div
      class="nav-item-badge"
      *ngIf="showBadge"
      [ngClass.lt-md]="'xs'"></div>
    <div
      fxHide
      fxShow.xs="true"
      fxShow.sm="true"
      class="nav-item-ripple"
      matRipple></div>
    <div class="navitem-contents">
      <mg-navicon [icon]="icon"></mg-navicon>
      <span mg-nav-item-text>{{ text }}</span>
    </div>
  </div>
</ng-template>

<ng-container *ngTemplateOutlet="template ? template : defaultNavLinks">
</ng-container>
