import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';

import { EventsHomeModule } from 'minga/app/src/app/components/EventsHome';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { SearchModule } from 'minga/app/src/app/search';
import { SearchContentModule } from 'minga/app/src/app/search-content';
import { PermissionsModule } from 'src/app/permissions';

import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { GenericModule } from '@shared/components/generic';

import { EventsComponent } from './Events.component';

@NgModule({
  imports: [
    // Minga dependencies
    SearchContentModule,
    SearchModule,
    MgFormFieldModule,
    EventsHomeModule,
    LayoutLegacyContentModule,
    GenericModule,
    PermissionsModule,

    // External dependencies
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatCheckboxModule,
  ],
  declarations: [EventsComponent],
  exports: [EventsComponent],
})
export class EventsModule {}
