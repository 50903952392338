<div
  fxLayout="row wrap"
  fxLayoutAlign="start center">
  <div
    *ngFor="let role of roles"
    class="multi-select-role-item"
    fxLayout="row"
    fxLayoutAlign="start center"
    [class.reduce-flex-basis]="reduceFlexBasis">
    <mat-checkbox
      class="mg-align-center"
      [disabled]="
        role.disabled || (disallowDeselect && isSelected(role.roleType))
      "
      [checked]="isSelected(role.roleType)"
      (change)="toggleRoleSelection(role.roleType)">
      <span class="role-text">{{ role.name }}</span> &nbsp;
    </mat-checkbox>
    <mg-tooltip
      *ngIf="role?.tooltip"
      label="{{ role?.tooltip }}"></mg-tooltip>
  </div>
</div>
