<div
  class="active-date-title-wrap"
  fxLayout="row"
  fxLayoutAlign="space-evenly center">
  <div
    fxLayout="row"
    fxLayoutAlign="center center">
    <div
      class="active-date-nav left"
      (click)="activeDateNavLeft()">
      <mg-icon>left-arrow-active</mg-icon>
    </div>
    <div class="active-date-title">{{ _activeDateTitle }}</div>
    <div
      class="active-date-nav right"
      (click)="activeDateNavRight()">
      <mg-icon>right-arrow-active</mg-icon>
    </div>
  </div>
</div>
