<mg-layout-legacy-content>
  <p
    *ngIf="layout.useLegacyLayout$ | async"
    class="mg-headline page-header">
    <mg-icon class="default-icon">polls-o</mg-icon>
    Polls
  </p>

  <div class="feed-items">
    <mg-stream-scroller
      mgStreamControl="AdminPolls"
      [service]="pollService"
      rpcStream="streamPollsV2"
      rpcControl="streamPollsV2Control">
      <ng-template
        let-item
        let-itemId="itemId"
        mgStreamItem>
        <mg-poll-mini-card
          mg-stream-item
          [mgContentLink]="itemId"
          [showVotes]="'MINGA_POLLS_MANAGE' | hasPermission"
          [context]="itemId"
          [content]="item">
        </mg-poll-mini-card>
      </ng-template>

      <mg-empty-state
        mg-stream-empty
        [title]="'emptyState.managePollsTitle' | translate"
        [subtitle]="
          canCreatePolls ? ('emptyState.managePollsSubtitle' | translate) : ''
        ">
      </mg-empty-state>
    </mg-stream-scroller>
  </div>

  <ng-template #action>
    <mg-home-action-button></mg-home-action-button>
  </ng-template>
</mg-layout-legacy-content>
