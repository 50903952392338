<div class="floating-icon-container mg-bodycopy">
  <div
    class="floating-icon left"
    (click)="onClose(outputOptions.DONT_CHECK_IN)">
    <mg-icon>close</mg-icon>
  </div>
</div>
<div class="body">
  <div class="body-content">
    <div class="title-text">
      {{ title }}
    </div>
    <div class="body-text">
      <div
        class="sub-title-text"
        [innerHTML]="message | mgSafeHtml"></div>
      <div class="button-wrapper">
        <mg-generic-button
          colorTheme="red"
          (onClick)="onClose(outputOptions.SKIP_BLOCKED)">
          Skip
        </mg-generic-button>
        <div
          class="continue-anyways"
          (click)="onClose(outputOptions.ALLOW_BLOCKED)">
          Continue Anyway
        </div>
      </div>
    </div>
  </div>
</div>
