import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Observable } from 'rxjs';

import { KioskStateStorage } from '../utils';

@Injectable()
export class KioskGuard implements CanActivate {
  /** Guard constructor */
  constructor(public router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { selectedCategory = null } = KioskStateStorage.get();
    if (!selectedCategory) return true;
    return this.router.createUrlTree(['/kiosk', selectedCategory]);
  }
}
