<div class="summary-container">
  <p class="heading">Imported!</p>
  <p class="subheading">Here are the details</p>

  <div class="info-container">
    <div class="success-banner row">SUCCESS</div>

    <div
      *ngIf="message"
      class="row success-message">
      {{ message }}
    </div>
    <div
      *ngFor="let summary of summaryRows"
      class="success-rows">
      <div class="row">
        <div class="first-column-item">{{ summary.label }}</div>
        <div class="second-column-item">{{ summary.metric }}</div>
      </div>
    </div>
  </div>

  <div
    class="info-container"
    *ngIf="rowsWithErrors.length > 0">
    <div class="error-banner row">
      ERRORS &nbsp; <span (click)="downloadXlsx()">(download)</span>
    </div>

    <ng-container *ngIf="rowsWithErrors.length > 0">
      <div class="row">
        <div class="header first-column-item">Rows</div>
        <div class="header second-column-item">Error Description</div>
      </div>

      <div
        *ngFor="let error of rowsWithErrors"
        class="error-rows">
        <div class="row">
          <div class="first-column-item">
            {{ error.recordIndex + errorOffset }}
          </div>
          <div class="second-column-item">{{ error.errors }}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="showButton"
    class="button-wrapper">
    <mg-generic-button
      class="done-button"
      colorTheme="blue"
      variant="primary"
      size="medium"
      paddingSize="medium"
      (click)="onClose()">
      Done
    </mg-generic-button>
  </div>
</div>
