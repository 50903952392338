<mg-stream-scroller
  mgStreamControl="GalleryFeed"
  [mgTrimmed]="true"
  [filter]="filter"
  [service]="streamService"
  rpcStream="streamV2"
  rpcControl="streamV2Control"
  [mgTrimmed]="true"
  [bufferAmount]="2"
  [loadThreshold]="3"
  (streamControlCreated)="streamControlCreated.emit($event)">
  <mg-empty-state
    mg-stream-empty
    *ngIf="!query; else searchEmptyState"
    [title]="'emptyState.photoGallery.title' | translate"
    [subtitle]="'emptyState.photoGallery.subtitle' | translate">
  </mg-empty-state>

  <ng-template #searchEmptyState>
    <mg-empty-state
      mg-stream-empty
      title="No results for '{{ query }}'">
    </mg-empty-state>
  </ng-template>

  <ng-template
    let-item
    let-itemIndex="itemIndex"
    mgStreamItem>
    <ng-container *ngIf="item.itemList.length > 0">
      <div
        *ngIf="item.title"
        class="gallery-section-title mg-smallcopy">
        {{ item.title }}
      </div>
      <mg-fit-grid
        [rowHeight]="109"
        [maxAspectRatio]="maxAspectRatio"
        [minAspectRatio]="minAspectRatio">
        <div
          *ngFor="let tile of item.itemList; let i = index; trackBy: trackBy"
          mgFitGridTile
          [tileWidth]="getTileWidth(tile)"
          [tileHeight]="getTileHeight(tile)">
          <mg-image
            class="tile-image"
            (click)="_openItem(itemIndex, i, tile)"
            [srcs]="
              tile.imageInfo
                | mgImageUrls
                  : ['blurloading1', ['bannerlibpreview', 'cardbanner']]
            ">
          </mg-image>
        </div>
      </mg-fit-grid>
    </ng-container>
  </ng-template>
</mg-stream-scroller>
