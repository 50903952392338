import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EventStatus } from 'minga/domain/event';
import { PersonEventStatus } from 'minga/util';
import { BarcodeScanner } from 'src/app/barcodeScanner';
import {
  EventConfirmationDialogService,
  IEventGuestStatus,
} from 'src/app/event-confirmation-dialog';
import { EventContentService } from 'src/app/minimal/services/EventContent';
import { RootService } from 'src/app/minimal/services/RootService';

import { PsData } from '@modules/people-selector/types';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PeopleSelectorService } from '../people-selector.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';
import { PsCheckInAbstractService } from './ps-checkin-abstract.service';

@Injectable()
export class PsEventService extends PsCheckInAbstractService<'Event'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _eventService: EventContentService,
    private _eventConfirmationDialog: EventConfirmationDialogService,
    private _rootService: RootService,
    private _eventContentService: EventContentService,
  ) {
    super(
      {
        name: 'Event',
        pageDefinitions: {
          invite: {
            submitFn: async () => this.invite(),
            searchFn: async (type, filters) =>
              this._psCollectionSearch.collectionSearch(type, filters),
          },
          checkin: {
            submitFn: async () => this.assign(),
            searchFn: async (type, filters) =>
              this._psCollectionSearch.collectionSearch(type, filters),
          },
          checkout: {
            submitFn: async () => this.remove(),
            searchFn: async () => this._fetchCheckedIn(),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );
  }

  public async assign(): Promise<void> {
    const { contextHash, eventReasonId } = this.data;
    const needsTicket =
      this._eventService.getTicketsRequired(contextHash) ?? false;

    if (contextHash) {
      const personHashes = this.selection.getSelectionHashes();
      if (personHashes.length) {
        if (!needsTicket) {
          await this._checkinUsers(eventReasonId, personHashes);
        } else {
          const dialogRef = this._eventConfirmationDialog.open({
            data: {
              confirmText: 'Check In',
              confirmPretext: 'Confirm check in',
              onSave: async (hashes, guests?: IEventGuestStatus[]) => {
                return await this._checkinUsers(
                  eventReasonId,
                  hashes || [],
                  guests,
                );
              },
              eventContextHash: contextHash,
              status: EventStatus.CHECKED_IN,
              selectedPersonhashes: personHashes,
              canUpdate: item => !item.checkInTime,
            },
            panelClass: ['mg-bordered-dialog', 'mg-no-padding-dialog'],
            backdropClass: 'mg-white-backdrop',
          });
          await dialogRef.beforeClosed().toPromise();
        }
        this._eventContentService.triggerEventManagementUpdate(contextHash);
      }
    }
  }

  public async remove(): Promise<void> {
    const { contextHash, eventReasonId } = this.data;
    const needsTicket =
      this._eventService.getTicketsRequired(contextHash) ?? false;
    if (contextHash) {
      const personHashes = this.selection.getSelectionHashes();
      if (personHashes.length) {
        if (!needsTicket) {
          await this._checkoutUsers(eventReasonId, personHashes, []);
        } else {
          const dialogRef = this._eventConfirmationDialog.open({
            data: {
              confirmText: 'Check Out',
              confirmPretext: 'Confirm Check Out',
              eventContextHash: contextHash,
              status: EventStatus.CHECKED_OUT,
              selectedPersonhashes: personHashes,
              canUpdate: item => !item.checkOutTime,
              onSave: async (hashes, guests?: IEventGuestStatus[]) => {
                return await this._checkoutUsers(
                  eventReasonId,
                  hashes || [],
                  guests,
                );
              },
            },
            panelClass: ['mg-bordered-dialog', 'mg-no-padding-dialog'],
            backdropClass: 'mg-white-backdrop',
          });

          await dialogRef.beforeClosed().toPromise();
        }
        this._eventContentService.triggerEventManagementUpdate(contextHash);
      }
    }
  }

  public async invite(): Promise<void> {
    const { contextHash } = this.data;
    const selectionHashes = this.selection.getSelectionHashes();
    await this._rootService.addLoadingPromise(
      this._eventContentService.invitePeopleToEvent(
        contextHash,
        selectionHashes,
      ),
    );
    await this.peopleSelector.openDialog({
      type: 'success',
      subTitle: 'Invites Sent',
    });
  }

  private async _fetchCheckedIn(): Promise<PsData[]> {
    const { contextHash } = this.data;
    const invitedPeople = await this._eventService.getEventGoingPeople(
      contextHash,
      PersonEventStatus.CheckedIn,
    );

    return invitedPeople.map(person => {
      return {
        personHash: person.personHash,
        displayName: person.displayName,
        badge: person.badgeRoleName,
        email: person.personEmail,
        studentId: person.studentId,
      };
    });
  }

  private async _checkinUsers(
    eventReasonId: number,
    personHashes: string[],
    guests?: IEventGuestStatus[],
  ): Promise<boolean> {
    const guestIds: number[] = [];
    if (guests) {
      const items = guests
        .filter(guest => guest.selected && guest.canUpdate)
        .map(guest => guest.guestId);
      if (items) {
        items.forEach(guest => guestIds.push(guest));
      }
    }

    try {
      const result = await this._rootService.addLoadingPromise(
        this._eventService.checkInUsers(
          eventReasonId,
          personHashes || [],
          guestIds,
          false,
        ),
      );

      if (result.errors.length) {
        const bypassHashes = await this._handleError({
          errors: result.errors,
          successPeopleHashes: result.successPeopleHashes,
          reasonName: 'Event',
        });

        if (bypassHashes?.length) {
          await this._rootService.addLoadingPromise(
            this._eventService.checkInUsers(
              eventReasonId,
              bypassHashes,
              guestIds,
              true,
            ),
          );
          //add the selected error ones to success list
          personHashes = [...result.successPeopleHashes, ...bypassHashes];
        } else {
          //not adding error users
          personHashes = result.successPeopleHashes;
        }
      }

      this._showSuccessDialog({
        reasonName: 'Event',
        selectionHashes: personHashes,
      });
    } catch (error) {
      this.snackbar.open({
        type: 'error',
        message: 'Something went wrong when trying to make the request',
      });
      return false;
    }
    return true;
  }

  private async _checkoutUsers(
    eventReasonId: number,
    personHashes: string[],
    guests?: IEventGuestStatus[],
  ) {
    const guestIds: number[] = [];
    if (guests) {
      const items = guests
        .filter(guest => guest.selected && guest.canUpdate)
        .map(guest => guest.guestId);
      if (items) {
        items.forEach(guest => guestIds.push(guest));
      }
    }

    const result = await this._eventService.checkOutUsers(
      eventReasonId,
      personHashes || [],
      guestIds,
    );

    if (result.errors.length) {
      await this._handleError({
        successPeopleHashes: result.successPeopleHashes,
        errors: result.errors,
        reasonName: 'Event',
        preventBypass: true,
        checkType: 'out',
      });
      return false;
    } else {
      this._showSuccessDialog({
        reasonName: 'Event',
        selectionHashes: personHashes,
        checkType: 'out',
      });
    }
    return true;
  }
}
