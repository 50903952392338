<section class="image-select">
  <mg-text
    *ngIf="label"
    textAlign="left"
    variant="body-md"
    spacing="1"
    >{{ label }}
  </mg-text>
  <mg-spinner
    *ngIf="loading$ | async; else carousel"
    [diameter]="30"
    [thickness]="2">
  </mg-spinner>
  <ng-template #carousel>
    <mg-carousel
      #carousel
      [id]="id"
      [id]="id"
      [trackBy]="trackBy"
      [config]="carouselConfig"
      [dataSource]="sortedImageList"
      (carouselLoad)="carouselLoad($event)">
      <mg-carousel-item
        mgCarouselItem
        *mgCarouselDef="let item; let j = index">
        <ng-container *ngIf="item.allowCustom; else bannerThumb">
          <div
            class="custom-uploader"
            [ngClass]="{
              selected: customAssetPath
            }">
            <mg-file-input-upload
              accept="image/*"
              icon="camera"
              [customTitleText]="customUploadLabel"
              previewAspectRatio="1:1"
              [showFileDetails]="false"
              [(ngModel)]="customAssetPath"
              (onFileUpload)="saveUploadedAsset($event)"
              (onStatusChange)="onStatusChange($event)">
            </mg-file-input-upload>
          </div>
        </ng-container>
        <ng-template #bannerThumb>
          <button
            type="button"
            class="image-select-thumb"
            (click)="selectThumb(item)"
            [ngClass]="{
              selected: formControl.value.bannerHash === item?.bannerHash
            }">
            <mg-image
              class="image-select-img"
              [srcs]="
                item.image | mgImageUrls: ['blurloading1', 'bannerlibpreview']
              ">
            </mg-image>
          </button>
        </ng-template>
      </mg-carousel-item>
    </mg-carousel>
  </ng-template>
</section>
