import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ContentMetadata } from 'minga/proto/content/content_pb';
import { Publisher } from 'minga/proto/gateway/publisher_ng_grpc_pb';
import { RepublishRequest } from 'minga/proto/gateway/publisher_pb';
import { day } from 'minga/shared/day';

@Component({
  selector: 'mg-debug-content-dialog',
  templateUrl: './DebugContentDialog.component.html',
  styleUrls: ['./DebugContentDialog.component.scss'],
})
export class DebugContentDialogComponent {
  contentMetadata?: ContentMetadata.AsObject;
  contentContextHash: string = '';
  republishing: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DebugContentDialogComponent>,
    private publisher: Publisher,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.contentMetadata = data.contentMetadata;
    this.contentContextHash = data.contentContextHash;
  }

  formatTimestamp(timestamp: number) {
    if (!timestamp) {
      return '--';
    }

    return day(timestamp).format('MMM Do @ h:mm a');
  }

  private async _republish() {
    const request = new RepublishRequest();
    if (this.contentMetadata) {
      request.setHash(this.contentMetadata.contentHash);
    }

    await this.publisher.republish(request).toPromise();
  }

  private async _republishOne() {
    const request = new RepublishRequest();
    request.setHash(this.contentContextHash);

    await this.publisher.republish(request).toPromise();
  }

  async republish() {
    if (this.republishing) return;

    this.republishing = true;

    await this._republish().catch(err => {
      this.republishing = false;
      throw err;
    });

    this.republishing = false;
  }

  async republishOne() {
    if (this.republishing) return;

    this.republishing = true;

    await this._republishOne().catch(err => {
      this.republishing = false;
      throw err;
    });

    this.republishing = false;
  }
}
