import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import * as path from 'path';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
} from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  take,
  takeUntil,
} from 'rxjs/operators';

import { BarcodeScanner } from 'minga/app/src/app/barcodeScanner';
import { GradeFunctions } from 'minga/util';
import { GroupsFacadeService } from 'src/app/groups/services';
import { RolesService } from 'src/app/roles/services';
import { GradesService } from 'src/app/services/Grades';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { XlsListUploaderDialogComponent } from '@shared/components/XlsListUploader/XlsListUploaderDialog';
import { FormSelectOption } from '@shared/components/form';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MediaBreakpoints, MediaService } from '@shared/services/media';

import {
  AUTOSEARCH_DEBOUNCE_TIME,
  HYBRID_CAMERA_SCAN_SAME_ID_GAP,
  PS_ACCEPTED_FILE_TYPES,
  PsSearchMessages,
} from '../../constants';
import {
  PeopleSelectorFormService,
  PeopleSelectorService,
} from '../../services';
import { PsData, PsUploadRow } from '../../types';

const lastScannedValueSubject = new BehaviorSubject<{
  value: string;
  timestamp: number;
} | null>(null);

@Component({
  selector: 'mg-ps-search',
  templateUrl: './ps-search.component.html',
  styleUrls: ['./ps-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsSearchComponent implements OnInit, OnDestroy {
  /** Children */
  @ViewChild('searchField') searchField: ElementRef<HTMLInputElement>;
  @ViewChild('fileInput', { static: true })
  fileInput?: ElementRef<HTMLInputElement>;

  /** Input Focus Subj */
  public keywordsFocusSubj = new Subject();
  public barCodeFocusSubj = new Subject();

  // Constants

  public readonly MSG = PsSearchMessages;
  public readonly cameraAvailable = this._barCodeScanner.isAvailable();
  public readonly ACCEPTED_FILE_TYPES = PS_ACCEPTED_FILE_TYPES.join(',');
  public readonly IS_HYBRID_APP = !window.MINGA_APP_BROWSER;

  /** General Observables */
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  /** Misc */
  public sideBarTitle$: Observable<string>;
  public accordionOpenState: boolean;

  /** Filters Accordion Initial State */
  public readonly isDesktop$ = this.media.breakpoint$.pipe(
    takeUntil(this._destroyedSubj),
    map(bp => {
      const breakpoints: MediaBreakpoints[] = [
        'medium',
        'medium-large',
        'large',
        'xlarge',
      ];
      return breakpoints.includes(bp) ? true : false;
    }),
  );

  /** Keyword Search Filter */
  public readonly searchFieldControl = this._fb.control('');

  /** Barcode Filter */
  public readonly barCodeFieldControl = this._fb.control('');

  /** Group Filter Options */
  public groupOptions$: Observable<FormSelectOption<string>[]>;

  /** Selected user lists */
  public selectedLists$: Observable<number[]>;

  /** Grade Filter Options */
  public readonly gradeOptions$: Observable<FormSelectOption<string>[]> =
    this._gradesService.grades$.pipe(
      takeUntil(this._destroyedSubj),
      map(grades =>
        grades.map(grade => ({
          label: GradeFunctions.toString(grade),
          value: grade,
        })),
      ),
    );

  /** Role Filter Options */
  public readonly roleOptions$: Observable<FormSelectOption<string>[]> =
    this._rolesService.roles$.pipe(
      takeUntil(this._destroyedSubj),
      map(roles =>
        roles
          .filter(role => role.name !== 'Super Admin')
          .map(role => ({
            label: role.name,
            value: role.name,
          })),
      ),
    );

  /** Inputs */
  @Input() form: PeopleSelectorFormService;

  /** Component Constructor */
  constructor(
    public media: MediaService,
    public settingsService: MingaSettingsService,
    private _peopleSelector: PeopleSelectorService,
    private _systemSnack: SystemAlertSnackBarService,
    private _barCodeScanner: BarcodeScanner,
    private _fb: FormBuilder,
    private _groupsFacade: GroupsFacadeService,
    private _gradesService: GradesService,
    private _rolesService: RolesService,
    private _matDialog: MatDialog,
  ) {
    this._openCameraSub();
  }

  ngOnInit(): void {
    if (!this.form) return;
    this._sidebarTitle();
    this._pageSwitchSub();
    this._searchInputSub();
    this._barCodeFocusSub();
    this._peopleSubmitSub();
    this._searchToolSwitchSub();
    this._focusBarcodeSubscription();

    this.selectedLists$ = this.form.filters.state$.pipe(
      map(state => (state.lists || []).map(Number)),
    );
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
    this.keywordsFocusSubj.complete();
    this.barCodeFocusSubj.complete();
  }

  public async openCamera(): Promise<void> {
    this.searchFieldControl.reset();
    const pageType = this.form.pages.getActivePageType();
    try {
      const { cancelled = false, text = '' } = await this._barCodeScanner.scan(
        ['barcode'],
        this.form?.formTitle,
      );
      if (cancelled || !text) return;
      const submissionFunction = async (val: string) => {
        if (pageType === 'search') {
          this.form.filters.set('keywords', val);
          await this.form.search('text');
          if (this.form.searchResults.size > 0) {
            this.form.selection.select([
              this.form.searchResults.dataSource.data[0],
            ]);
            await this.form.submit('camera');
          } else {
            await this.form.onSearchScanNoResults(val, 'camera');
          }
        } else {
          const found = this.form.searchResults.dataSource.data.find(
            ({ studentId }) => studentId === val,
          );
          if (found) {
            this.form.selection.select([found]);
            await this.form.submit('camera');
          } else {
            await this.form.onListScanNoResults(val, 'camera');
          }
        }
      };
      if (this.IS_HYBRID_APP) {
        await this._handleHybridAppCameraScanSubmission(
          text,
          submissionFunction,
        );
      } else {
        await submissionFunction(text);
      }
    } catch (err) {
      if (window.MINGA_APP_IOS) return;
      this._systemSnack.open({
        type: 'error',
        message:
          err?.message ??
          'Something went wrong when trying to access the camera.',
      });
    }
  }

  public async submitBarcodeInput() {
    const barCodeString = this.barCodeFieldControl.value?.trim() ?? '';
    const pageType = this.form.pages.getActivePageType();
    this.barCodeFieldControl.reset();

    if (barCodeString.length <= 2) return;

    let found: PsData;
    if (pageType === 'search') {
      this.form.filters.set('keywords', barCodeString);
      await this.form.search();
      found = this.form.searchResults.dataSource.data[0];
      if (!found) this.form.onSearchScanNoResults(barCodeString, 'barcode');
    } else {
      found = this.form.searchResults.dataSource.data.find(
        ({ studentId }) => studentId === barCodeString,
      );
      if (!found) await this.form.onListScanNoResults(barCodeString, 'barcode');
    }
    if (found) {
      this.form.selection.clear();
      this.form.selection.select([found]);
      await this.form.submit('barcode');
    }
  }

  public async toggleBarCode() {
    const currentMode = this.form.getCurrentSearchMode();
    this.form.setSearchMode(currentMode !== 'barcode' ? 'barcode' : 'keywords');
  }

  public uploadFile(event: Event) {
    try {
      const element = event.currentTarget as HTMLInputElement;
      const file: File | null = element.files[0];
      const extname = path.extname(file.name);
      if (!PS_ACCEPTED_FILE_TYPES.includes(extname)) {
        throw new Error(
          'Please upload an Excel (.xlsx) or a Comma-separated Values (.csv) file',
        );
      }
      const data = this.form.filters.setFile(file);
      const dialogRef = this._matDialog.open(XlsListUploaderDialogComponent, {
        data,
        panelClass: 'mg-no-padding-dialog',
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this._destroyedSubj))
        .subscribe((res: PsUploadRow[] | undefined) => {
          if (!res) {
            this.form.filters.clear('file');
          } else {
            this.form.filters.set('fileUploadedPeople', res);
            this.form.search('file');
          }
        });
    } catch (error) {
      this._systemSnack.open({
        message: error.message,
        type: 'error',
      });
    }
  }

  private async _handleHybridAppCameraScanSubmission(
    value: string,
    callBackFunction: (v) => Promise<void>,
  ) {
    const lastScannedValue = lastScannedValueSubject.getValue();
    const currentTimeStamp = new Date().getTime();
    const saveAndSubmit = async () => {
      lastScannedValueSubject.next({ value, timestamp: currentTimeStamp });
      await callBackFunction(value);
    };
    if (lastScannedValue == null) {
      await saveAndSubmit();
    } else if (lastScannedValue?.value !== value) {
      await saveAndSubmit();
    } else {
      const timeDiff = currentTimeStamp - lastScannedValue?.timestamp;
      if (timeDiff > HYBRID_CAMERA_SCAN_SAME_ID_GAP) await saveAndSubmit();
    }
  }

  private _sidebarTitle() {
    this.sideBarTitle$ = combineLatest([
      this.form.filters.hasLoadedFile$,
      this.form.pages.activePageType$,
    ]).pipe(
      takeUntil(this._destroyedSubj),
      map(([hasFile, pageType]) => {
        if (pageType === 'list') return PsSearchMessages.LIST_TITLE;
        return hasFile ? PsSearchMessages.FILE_TITLE : PsSearchMessages.TITLE;
      }),
    );
  }

  private _searchInputSub() {
    return this.searchFieldControl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(AUTOSEARCH_DEBOUNCE_TIME))
      .subscribe(async text => {
        if (typeof text === 'string') this.form.filters.set('keywords', text);
        if (!text) return;
        const pageType = this.form.pages.getActivePageType();
        if (pageType === 'search') await this.form.search();
      });
  }

  private _barCodeFocusSub() {
    return this.form.searchMode$
      .pipe(
        takeUntil(this._destroyedSubj),
        filter(val => val === 'barcode'),
        delay(100),
      )
      .subscribe(() => this.barCodeFocusSubj.next());
  }

  private _openCameraSub() {
    return this._peopleSelector
      .eventWhen('open-camera')
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(async () => await this.openCamera());
  }

  private async _pageSwitchSub() {
    return this.form.pages.activePath$
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(() => {
        this._resetSearchField();
      });
  }

  private _focusBarcodeSubscription() {
    return this._peopleSelector
      .eventWhen('focus-barcode')
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(() => this.barCodeFocusSubj.next());
  }

  private _searchToolSwitchSub() {
    return this.form.searchMode$
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(searchMode => {
        if (searchMode === 'barcode') {
          this._peopleSelector.emitEvent('focus-barcode');
        } else if (searchMode === 'keywords') {
          if (this.searchFieldControl.value) this.searchFieldControl.reset('');
          this.keywordsFocusSubj.next();
        }
      });
  }

  private _fetchSupportedFiltersData() {
    return this.form.pages.activePage$
      .pipe(
        takeUntil(this._destroyedSubj),
        take(1),
        map(({ filters }) => filters),
      )
      .subscribe(filters =>
        filters.forEach(f => {
          switch (f) {
            case 'grades':
              this._gradesService.fetchIfNeeded();
              break;
            case 'roles':
              this._rolesService.fetchIfNeeded();
              break;
            case 'groups':
              this.groupOptions$ = this._groupsFacade.getAllGroups().pipe(
                map(groups =>
                  groups.map(group => ({
                    label: group.name,
                    value: group.hash,
                  })),
                ),
              );
              break;
            default:
              break;
          }
        }),
      );
  }

  public setUserListFilter(val: number[]) {
    this.form.filters.set(
      'lists',
      (val || []).map(v => v.toString()),
    );
  }

  private _peopleSubmitSub() {
    return this._peopleSelector
      .eventWhen('submitted')
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(() => {
        this._resetSearchField();
      });
  }

  private _resetSearchField() {
    const searchMode = this.form.getCurrentSearchMode();
    if (searchMode === 'barcode') {
      this.barCodeFieldControl.reset();
      this._peopleSelector.emitEvent('focus-barcode');
    } else if (searchMode === 'keywords') {
      if (this.searchFieldControl.value) this.searchFieldControl.reset('');
      this.searchFieldControl.reset();
      this.keywordsFocusSubj.next();
    }
    this._fetchSupportedFiltersData();
  }
}
