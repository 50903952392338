<section
  class="sections"
  fxLayout="column"
  fxLayoutGap="24px">
  <div class="page-header">
    <div>
      <mg-form-text-input
        iconRight="mg-search"
        [label]="'Search'"
        [control]="textSearchControl">
      </mg-form-text-input>
    </div>
    <div>
      <mg-btn
        variant="filled"
        [responsive]="true"
        (pressed)="onEditClick()">
        {{ MESSAGES.ADD_DISTRICT_BTN }}
      </mg-btn>
    </div>
  </div>
  <section
    class="main-content"
    fxLayout="column"
    fxLayoutGap="8px">
    <div
      fxLayout="row"
      fxLayout="center center"
      fxLayoutAlign.gt-sm="end center">
      <mg-btn
        mat-button
        variant="text"
        size="small"
        iconSet="minga"
        icon="mg-export"
        (pressed)="export()">
        {{ MESSAGES.EXPORT_BTN }}
      </mg-btn>
    </div>
    <mat-table
      class="list-table no-explicit-mobile-layout"
      matSort
      [dataSource]="dataSource"
      [trackBy]="trackById">
      <ng-container [matColumnDef]="COLUMN_DEF.NAME">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="w-lg-flex text-left">
          <mg-text
            variant="label-md-secondary"
            color="grey-300">
            {{ MESSAGES.COL_NAME }}
          </mg-text>
        </mat-header-cell>

        <mat-cell
          *matCellDef="let element"
          class="w-lg-flex text-left">
          <mg-text
            variant="body-sm"
            [numberOfLines]="1">
            {{ element.name }}
          </mg-text>
        </mat-cell>
      </ng-container>
      <ng-container [matColumnDef]="COLUMN_DEF.HUBSPOT_ID">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="text-left">
          <mg-text
            variant="label-md-secondary"
            color="grey-300">
            {{ MESSAGES.COL_HUBSPOT }}
          </mg-text>
        </mat-header-cell>

        <mat-cell
          class="text-left"
          *matCellDef="let element">
          <mg-text variant="body-sm">
            {{ element.hubspotId }}
          </mg-text>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="COLUMN_DEF.EDIT">
        <mat-header-cell
          *matHeaderCellDef
          class="w-10 text-right">
          <mg-text
            variant="label-md-secondary"
            color="grey-300">
            {{ MESSAGES.COL_EDIT }}
          </mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="w-10 text-right">
          <mg-btn
            icon="mg-edit"
            variant="icon"
            iconSet="minga"
            (pressed)="onEditClick(element.id)">
          </mg-btn>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns"
        [fxHide]="!dataSource?.data.length"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <div
      *ngIf="
        (mmDistrictsService.loading$ | async) === false &&
        (dataSource?.data.length === 0 || dataSource?.filteredData.length === 0)
      "
      class="empty-state">
      <mg-empty-state title="No districts found"> </mg-empty-state>
    </div>

    <div
      *ngIf="mmDistrictsService.loading$ | async"
      class="loading-container">
      <mg-spinner
        [diameter]="40"
        [thickness]="3">
      </mg-spinner>
    </div>
  </section>
  <mg-paginator [pageSizeOptions]="[100, 500, 1000]"></mg-paginator>
</section>
