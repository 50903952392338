<div class="container">
  <!-- No Data -->
  <ng-container *ngIf="noStats; else showGraph">
    <div class="no-data-available">
      <span></span>
      <p>{{ MESSAGES.NO_DATA }}</p>
    </div>
  </ng-container>
</div>

<!-- Graph -->
<ng-template #showGraph>
  <canvas
    id="hall-pass-report-chart"
    baseChart
    [datasets]="dataSets"
    [labels]="xLabels"
    [options]="chartOptions"
    [plugins]="chart.plugins"
    [legend]="chart.legend"
    [chartType]="chart.type">
  </canvas>
</ng-template>
