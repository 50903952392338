import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Notification } from 'minga/proto/gateway/notification_pb';

@Component({
  selector: 'mg-basic-notification',
  templateUrl: './MgBasicNotification.element.html',
  styleUrls: ['./MgBasicNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgBasicNotificationElement {
  @Input()
  notification?: Notification.AsObject;
}
