import { get } from 'lodash';

import { onDeviceReady$ } from '../device-ready';
import { CordovaPluginStatusBar } from './status-bar.types';

const getCordovaStatusBarPlugin = async () => {
  try {
    await onDeviceReady$.toPromise();
    const statusBar = get(window, 'StatusBar', null) as CordovaPluginStatusBar;
    if (statusBar != null) return statusBar;
    else throw Error('Missing StatusBar object on window');
  } catch (error) {
    console.warn('getCordovaStatusBarPlugin() error:', error);
  }
};

export const setStatusBarAppearance = async (
  backgroundColor: string,
  textColor: 'light' | 'dark',
) => {
  const statusBar = await getCordovaStatusBarPlugin();
  if (!statusBar) return;
  statusBar.backgroundColorByHexString(backgroundColor);
  if (textColor === 'light') statusBar.styleLightContent();
  else statusBar.styleDefault();
};
