import { NgModule } from '@angular/core';

import { MgOverlayStructureViewDirective } from './MgOverlayStructureView.directive';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [MgOverlayStructureViewDirective],
  exports: [MgOverlayStructureViewDirective],
})
export class MgOverlayStructureViewModule {}
