import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SystemAlertSnackBarData } from '@shared/components/system-alert';

import { SystemAlertSnackbarComponent } from '../system-alert-snackbar.component';

@Injectable({ providedIn: 'root' })
export class SystemAlertSnackBarService {
  /** Service Constructor */
  constructor(private _snackBar: MatSnackBar) {}

  public open(config: SystemAlertSnackBarData) {
    const { ...data } = this._makeConfig(config);
    return this._snackBar.openFromComponent(SystemAlertSnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['system-alert-snackbar-container', data.type],
      duration: ['error', 'warning'].includes(data.type) ? null : 6000,
      data,
    });
  }

  public async success(message: string) {
    return this.open({
      type: 'success',
      message,
    });
  }

  public async error(message: string) {
    return this.open({
      type: 'error',
      message,
    });
  }

  public async warning(message: string) {
    return this.open({
      type: 'warning',
      message,
    });
  }

  public async default(message: string) {
    return this.open({
      type: 'default',
      message,
    });
  }

  private _makeConfig(
    options: SystemAlertSnackBarData,
  ): SystemAlertSnackBarData {
    return {
      ...options,
    };
  }
}
