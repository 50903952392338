<mg-button
  type="clear"
  [disabled]="disabled"
  [disableRipple]="true"
  [attr.color]="color"
  [class.no-active]="noActive">
  <mg-icon
    class="default-icon"
    [icon-namespace]="iconNamespace">
    {{ icon }}
  </mg-icon>
  <mg-icon
    class="active-icon"
    [icon-namespace]="iconNamespace">
    {{ icon }}-active
  </mg-icon>
  <ng-content></ng-content>
</mg-button>
<svg
  style="width: 0; height: 0; position: absolute"
  aria-hidden="true"
  focusable="false">
  <linearGradient
    id="mg-gradient1"
    x2="1"
    y2="1">
    <!-- $md-blue1 -->
    <stop stop-color="#4abcff" />
    <!-- $md-purple -->
    <stop
      offset="1"
      stop-color="#9c49ff" />
  </linearGradient>
</svg>
