import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { RouterOutletOption } from '@shared/constants';
import { NavigationService } from '@shared/services/navigation';

import { GlobalSearchQueryParam } from '../constants';
import { SearchScope } from '../types';

@Injectable({ providedIn: 'root' })
export class GlobalSearchService {
  // State

  private readonly _valueSubject = new BehaviorSubject<string>('');
  public readonly value$ = this._valueSubject.asObservable();

  public readonly scope$ = this.navigation.currentRouteConfig$.pipe(
    map(({ data = {} }) => data?.searchScope as SearchScope),
    distinctUntilChanged(),
  );

  /** Service constructor */
  constructor(public router: Router, public navigation: NavigationService) {}

  public setValue(value: string) {
    this._valueSubject.next(value);
  }

  public open(scope: SearchScope, query?: string) {
    if (query) this._valueSubject.next(query);
    this.router.navigate(
      ['', { outlets: { [RouterOutletOption.SEARCH]: [scope] } }],
      {
        queryParams: { [GlobalSearchQueryParam.QUERY]: query },
      },
    );
  }

  public close() {
    this.setValue('');
    this.router.navigate([
      '',
      { outlets: { [RouterOutletOption.SEARCH]: null } },
    ]);
  }
}
