<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="isLoading$ | async"
  [overlayTitle]="modalTitle"
  [skipLoadCheck]="true">
  <ng-container>
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  </ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <mg-form-text-input
      [control]="form.get(FORM_FIELD.NAME)"
      label="{{ MESSAGES.NEW_SCHEDULE_NAME_PLACEHOLDER }}">
    </mg-form-text-input>
    <ng-container *ngTemplateOutlet="timeScheduleTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="daysActiveTemplate"></ng-container>
  </div>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        variant="destructive"
        (pressed)="delete()">
        {{ MESSAGES.BUTTON_LABEL_DELETE }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        variant="filled"
        [disabled]="!canSubmit"
        (pressed)="submit()">
        {{
          (isNewSchedule$ | async)
            ? MESSAGES.BUTTON_LABEL_CREATE
            : MESSAGES.BUTTON_LABEL_SAVE
        }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<!-- Time Schedule Template -->
<ng-template #timeScheduleTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
    class="section-title">
    <div class="section-title-text">
      {{ MESSAGES.SECTION_TITLE_TIME_SCHEDULE }}
    </div>
    <div>
      <mg-btn
        variant="outlined"
        (pressed)="addTimeBlock()">
        {{ MESSAGES.BUTTON_LABEL_ADD_TIME }}
      </mg-btn>
    </div>
  </div>
  <mat-table
    *ngIf="(timeBlocks$ | async)?.length"
    [dataSource]="timeBlocks$">
    <!-- Start Time Column -->
    <ng-container matColumnDef="startTime">
      <mat-header-cell *matHeaderCellDef>
        {{ MESSAGES.COLUMN_LABEL_START_TIME }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item; let i = index">
        <mg-form-text-input
          [inputType]="'time'"
          [control]="timeForm.at(i).get('startTime')"
          [formGroupRef]="timeForm.at(i)"></mg-form-text-input>
      </mat-cell>
    </ng-container>
    <!-- End Time Column -->
    <ng-container matColumnDef="endTime">
      <mat-header-cell *matHeaderCellDef>
        {{ MESSAGES.COLUMN_LABEL_END_TIME }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item; let i = index">
        <mg-form-text-input
          [inputType]="'time'"
          [control]="timeForm.at(i).get('endTime')"
          [formGroupRef]="timeForm.at(i)"></mg-form-text-input>
      </mat-cell>
    </ng-container>
    <!-- Delete Time Block Column -->
    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef>
        {{ MESSAGES.COLUMN_LABEL_DELETE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item; let i = index">
        <mg-btn
          variant="icon"
          iconSet="minga"
          icon="trash"
          (pressed)="deleteTimeBlock(i)">
        </mg-btn>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
    <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS"></mat-row>
  </mat-table>
  <div
    *ngIf="!(timeBlocks$ | async)?.length"
    class="empty-state-container">
    <mg-empty-state
      mg-stream-empty
      [title]="MESSAGES.NO_TIMESLOTS_TITLE"
      [subtitle]="MESSAGES.NO_TIMESLOTS_SUBTITLE">
    </mg-empty-state>
  </div>
</ng-template>

<!-- Days Active Template -->
<ng-template #daysActiveTemplate>
  <div class="section days-active-container">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.xs="start start"
      fxLayoutGap="10px"
      class="section-title">
      <div class="section-title-text">
        {{ MESSAGES.SECTION_TITLE_ACTIVE_DAYS }}
      </div>
      <mg-weekday-toggle
        [initialValue]="schedule$ | async | getBlockDays"
        (valueChanged)="updateActiveDays($event)">
      </mg-weekday-toggle>
    </div>
  </div>
</ng-template>
