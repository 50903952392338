<ng-container *ngIf="!(loading$ | async) && (items$ | async)?.length === 0">
  <ng-content select="[mg-empty-state]"></ng-content>
</ng-container>

<virtual-scroller
  #virtualScroller
  (vsEnd)="_onVsEnd($event)"
  [items]="(items$ | async) || []"
  [bufferAmount]="bufferAmount"
  [enableUnequalChildrenSizes]="true"
  [modifyOverflowStyleOfParentScroll]="false"
  [parentScroll]="parentScroll">
  <div
    #container
    class="items">
    <ng-template
      ngFor
      let-item
      let-viewIndex="viewIndex"
      [ngForOf]="virtualScroller.viewPortItems"
      [ngForTrackBy]="trackBy">
      <div class="item">
        <ng-container
          *ngTemplateOutlet="
            streamItemTemplate;
            context: {
              $implicit: item.item,
              item: item.item,
              itemId: item.itemId,
              itemIndex: item.itemIndex,
              viewIndex: viewIndex,
              viewPortItems: virtualScroller.viewPortItems
            }
          ">
        </ng-container>
      </div>
    </ng-template>
  </div>
</virtual-scroller>

<mg-spinner
  *ngIf="loading$ | async"
  [diameter]="40"
  [thickness]="3">
</mg-spinner>
