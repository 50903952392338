import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { peopleCollectionStoreKeyname, reducer } from './store/state';

@NgModule({
  imports: [
    StoreModule.forFeature(peopleCollectionStoreKeyname, reducer),
    EffectsModule.forFeature([]),
  ],
  declarations: [],

  providers: [],
})
export class PeopleModule {}
