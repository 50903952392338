/**
 * Takes a dictionary or enum and converts it into a filter-friendly {label, value} array.
 *
 * @param raw
 * @returns
 */
export const dictToReportFilter = (raw: any) => {
  // numeric enums return the numbers and "string" keys we want for filters
  // so, have to get rid of the numbers.
  const converted = Object.keys(raw).filter(k => isNaN(Number(k)));
  const res = converted.map(key => ({
    label:
      key.charAt(0).toUpperCase() +
      key.replace(/_/g, ' ').slice(1).toLowerCase(),
    value: raw[key],
  }));
  return res;
};
