import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { GenericTagColor, GenericTagSize } from '../../../tag';

@Component({
  selector: 'mg-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class GenericMenuItemComponent implements OnDestroy {
  @ViewChild('button') button: ElementRef;

  /** General Subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Inputs */
  @Input() badgeLabel: string;
  @Input() badgeColor: GenericTagColor;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() tagSize: GenericTagSize = 'large';
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  /** Outputs */
  @Output() pressed = new EventEmitter<void>();

  @HostListener('click') onClick() {
    if (this.disabled || this.loading) return;
    this.button.nativeElement.blur();
    this.pressed.emit();
  }

  /** Component Constructor */
  constructor() {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
