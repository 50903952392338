<div
  class="tile"
  matRipple
  [matRippleCentered]="true"
  [matRippleColor]="'rgba(255, 255, 255, .1)'"
  [ngClass]="tileStyleClasses"
  [style]="tileStyles"
  [class.disabled]="onDisableHover"
  [attr.data-analytics]="id"
  [attr.data-test]="id"
  (click)="handleOnClickTile()">
  <div
    class="tile-content"
    [title]="label"
    [ngClass]="tileContentStyles">
    <div class="tile-asset">
      <div
        *ngIf="staticImage; else typeIconTemplate"
        [style.backgroundImage]="'url(' + staticImage + ')'"
        class="tile-static-image"></div>
    </div>
    <div class="tile-label">
      <span class="ellipsis-2">{{ label }}</span>
    </div>
    <div
      *ngIf="subtitle"
      class="subtitle">
      <span class="ellipsis-2">{{ subtitle }}</span>
    </div>
  </div>
  <div
    class="tile-count"
    [ngClass]="tileContentStyles"
    [title]="count">
    <span class="ellipsis-1">{{ count }}</span>
  </div>
</div>

<!-- Type Icon -->
<ng-template #typeIconTemplate>
  <mg-icon
    *ngIf="typeIcon; else iconTemplate"
    iconName="{{ typeIcon }}"
    class="tile-type-icon">
  </mg-icon>

  <!-- Tile Icon -->
  <ng-template #iconTemplate>
    <mg-icon
      class="tile-icon"
      [iconName]="icon">
    </mg-icon>
  </ng-template>
</ng-template>
