import { Type } from '@angular/core';

import { IModerationResult } from 'minga/domain/moderation';

import { ContentPublishInfoEditComponent } from './ContentPublishInfoEditComponent';

export interface IContentSaveResult {
  contentContextHash: string;
  moderationResult: IModerationResult | null;
}

export abstract class ContentManager<ContextInfo, PublishInfo> {
  /**
   * Name of this manager. Must be different between managers
   */
  abstract readonly name: string;

  /**
   * Add content to the given context
   */
  abstract addContent(
    contextInfo: ContextInfo,
    contentHash: string,
    publishInfo: PublishInfo,
  ): Promise<IContentSaveResult>;

  /**
   * Move content (change publish informatinon) in the given context. The new
   * publish info is partial so you may update only 1 portion of the publish
   * information if desired.
   */
  abstract moveContent(
    contextInfo: ContextInfo,
    contentHash: string,
    publishInfoDelta: Partial<PublishInfo>,
  ): Promise<IContentSaveResult>;

  /**
   * Get the existing publish info for the content in the given context. Returns
   * `null` if the content is not published in the given context.
   */
  abstract getPublishInfo(
    contextInfo: ContextInfo,
    contentHash: string,
  ): Promise<PublishInfo | null>;

  /**
   * Get default publish info. To be used on `addContent()` when
   * `getPublishInfoEditComponent()` returns `null`
   */
  abstract getDefaultPublishInfo(): PublishInfo;

  /**
   * Get component that will be responsible for editing PublishInfo
   */
  abstract getPublishInfoEditComponent(): Type<
    ContentPublishInfoEditComponent<PublishInfo>
  > | null;
}
