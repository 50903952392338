import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LogoModule } from 'minga/app/src/app/components/Logo';
import { NavsearchModule } from 'minga/app/src/app/components/Navsearch';
import { PersonAvatarModule } from 'minga/app/src/app/components/PersonAvatar';

import {
  TopNavComponent,
  TopNavSlot_Left,
  TopNavSlot_Mid,
  TopNavSlot_Right,
} from './TopNav.component';

@NgModule({
  imports: [
    // Minga dependencies
    NavsearchModule,
    LogoModule,
    PersonAvatarModule,

    // External dependencies
    FlexLayoutModule,
  ],
  declarations: [
    TopNavComponent,
    TopNavSlot_Left,
    TopNavSlot_Mid,
    TopNavSlot_Right,
  ],
  exports: [TopNavComponent, TopNavSlot_Left, TopNavSlot_Mid, TopNavSlot_Right],
})
export class TopNavModule {}
