import { Location } from '@angular/common';
import { Component, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { OnOverlayClose } from 'minga/app/src/app/misc/overlay';
import { LinkOpenerService } from 'minga/app/src/app/services/LinkOpener';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

/**
 * Display an overlay to the user to tell them to upgrade their app.
 */
@Component({
  selector: 'mg-old-version-overlay',
  templateUrl: './OldVersionNotifier.component.html',
  styleUrls: ['./OldVersionNotifier.component.scss'],
})
export class OldVersionNotifierComponent implements OnOverlayClose {
  MINGA_ANDROID_STORE =
    'https://play.google.com/store/apps/details?id=io.minga.app.android';
  MINGA_IOS_STORE = 'https://itunes.apple.com/us/app/minga/id1376350263';

  isIos = window.MINGA_APP_IOS;
  isAndroid = window.MINGA_APP_ANDROID;

  constructor(
    private _location: Location,
    private linkOpener: LinkOpenerService,
    private authService: AuthInfoService,
    private router: Router,
    @Optional() private swUpdate: SwUpdate,
  ) {}

  cancel() {
    this.onOverlayPrevious();
  }

  get personName() {
    if (this.authService.authPerson) {
      return this.authService.authPerson.displayName;
    } else {
      // if they aren't logged in, then just say 'hi there'
      return 'there';
    }
  }

  get overlayPrevious() {
    return {
      icon: 'close',
      text: 'Close',
    };
  }

  hasOverlayPrevious() {
    return this.overlayPrevious;
  }

  async onOverlayPrevious() {
    await this.onOverlayClose();
  }

  async onOverlayClose() {
    this._location.back();

    return true;
  }
  get devicePixelRatio() {
    return Math.round(devicePixelRatio);
  }

  get storeLink() {
    if (this.isIos) return this.MINGA_IOS_STORE;
    if (this.isAndroid) return this.MINGA_ANDROID_STORE;
  }

  openStoreLink() {
    const link = this.storeLink;
    if (link) {
      this.linkOpener.open(link);
    } else {
      // if we didn't get a link back, that means it's browser,
      // so tell service worker to update, then reload page
      // to try and get the newest version.
      this.swUpdate.activateUpdate().then(() => {
        this.router.navigate(['']).then(() => document.location.reload());
      });
    }
  }
}
