import { Pipe, PipeTransform } from '@angular/core';

/**
 * String Substring
 */
@Pipe({ name: 'substring' })
export class StringSubstringPipe implements PipeTransform {
  transform(str: string, start: number, end: number): string {
    return str.substring(start, end);
  }
}
