import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'zeroNotNA' })
export class MgZeroNotNAPipe implements PipeTransform {
  transform(value?: number, options?: any) {
    if (typeof value === 'number') {
      return value;
    } else {
      return 'N/A';
    }
  }
}
