import { OnDestroy, OnInit, Directive } from '@angular/core';
import { IActiveLayoutComponentService } from './IActiveLayoutComponentService';

@Directive()
export abstract class LayoutComponentBase implements OnInit, OnDestroy {
  abstract readonly layoutName: string;

  constructor(
    private activeLayoutComponentService: IActiveLayoutComponentService,
  ) {}

  ngOnInit() {
    this.activeLayoutComponentService._layoutComponentInit(this);
  }

  ngOnDestroy() {
    this.activeLayoutComponentService._layoutComponentDestroy(this);
  }
}
