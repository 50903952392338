import { HostBinding, HostListener, Input, Directive } from '@angular/core';
import * as _ from 'lodash';
import {ContentEvents} from 'minga/app/src/app/minimal/services/ContentEvents';
import {ContentState} from 'minga/app/src/app/services/ContentState';
import {mgResolveImageUrl} from 'minga/app/src/app/util/asset';
import {day} from 'minga/shared/day';
import {ImageInfo} from 'minga/proto/image/image_pb';
import textClipper from 'minga/util_web/text-clipper';

@Directive()
export abstract class MgMiniCardBaseElement {
  abstract contentevents: ContentEvents;
  abstract contentstate: ContentState;

  @Input()
  abstract content?: any;

  @Input()
  abstract context: string = '';

  @HostListener('contextmenu', ['$event'])
  clickContextMenu(e) {
    e.stopImmediatePropagation();
    e.preventDefault();

    const {pageX, pageY} = e;

    console.log('context content:', this.content);

    this.contentevents.emitContextMenuOpen({
      contentContextHash: this.context,
      contentHash: this.content.contentHash,
      x: pageX,
      y: pageY,
    });
  }

  get commentsActionText(): string {
    const commentCount = this.content.commentCount;

    if(commentCount == 1) {
      return `${commentCount} Comment`;
    } else {
      return `${commentCount} Comments`;
    }
  }

  @HostBinding('attr.is-future-content')
  get isFutureContent(): boolean {
    if(!this.content || !this.content.timestamp) return false;

    const now = Date.now();
    return this.content.timestamp > now;
  }

  _isFutureContent(timestamp: number) {
    if(!timestamp) return false;

    const now = Date.now();
    return timestamp > now;
  }

  get timestampText(): string {
    if(!this.content.timestamp) {
      return '';
    }

    const now = Date.now();
    const timestamp = this.content.timestamp;

    if(timestamp < now) {
      return day(timestamp).fromNow();
    } else {
      return `Scheduled for <strong>${
        day(timestamp).fromNow(true)}</strong> from now`;
    }
  }

  get imageUrl(): string {
    if(!this.content) return '';

    let imageInfo: ImageInfo.AsObject = this.content.featuredImage;

    if(!imageInfo) return '';

    return mgResolveImageUrl(imageInfo, ['preview', 'cardbanner', 'raw']);
  }

  get displayTitle(): string {
    if(!this.content) return '';

    let title = '';

    if(this.content.title) {
      title = textClipper(this.content.title, 80, {
        html: true,
        indicator: `...`,
      });
    } else if(this.content.body) {
      // strip html if we're taking a title from body text
      const div = document.createElement('div');
      div.innerHTML = this.content.body;
      title = div.textContent || '';

      if(title.length > 80) {
        title = this.content.body.slice(0, 80) + '...';
      }
    }

    return title;
  }

  get displayTitleClasses(): string {
    if(!this.content) return '';

    return !!this.content.title ? 'mg-headline2' : 'mg-bodycopy';
  }

  commentActionClick(e) {
    if(this.contentstate) {
      this.contentstate.wants(this.context, 'commentInputFocus');
    }
  }

  _onContentUpated(ev: any /*IShortCardViewUpdateEvent*/) {
    // update short card content, currently only updates comment data
    if(ev.contentContextHash == this.context) {
      if(typeof ev.totalCommentCount === 'number') {
        this.content.totalCommentCount = ev.totalCommentCount;
      }

      if(typeof ev.commentCount === 'number') {
        this.content.commentCount = ev.commentCount;
      }

      if(ev.imageList) {
        let featuredImage: ImageInfo.AsObject = null;
        this.content.featuredImage = featuredImage;

        if(ev.imageList[0]) {
          featuredImage = _.cloneDeep(ev.imageList[0]);
        }

        this.content.featuredImage = featuredImage;
        this.content.imageCount = ev.imageList.length;
      }
    }
  }
}
