import { Action } from '@ngrx/store';
import { GroupDetails } from '../models/GroupDetails';

export namespace GroupDetailsActions {
  export enum TypeEnum {
    LoadGroupDetails = '[Group Details] Load Group Details',
    LoadGroupDetailsFailure = '[Group Details] Load Group Details Failure',
    LoadGroupDetailsSuccess = '[Group Details] Load Group Details Success',

    UpdateGroupDetailsLocally = '[Group Details] Update group details locally',
  }

  export interface GroupDetailsAction extends Action {
    readonly type: TypeEnum;
  }

  export class LoadGroupDetailsSuccess implements GroupDetailsAction {
    readonly type = TypeEnum.LoadGroupDetailsSuccess;

    constructor(public payload: GroupDetails) {}
  }

  export class LoadGroupDetailsFailure implements GroupDetailsAction {
    readonly type = TypeEnum.LoadGroupDetailsFailure;

    constructor(public payload: any) {}
  }

  export class LoadGroupDetails implements GroupDetailsAction {
    readonly type = TypeEnum.LoadGroupDetails;
    constructor(public groupHash: string) {}
  }

  export class UpdateGroupDetailsLocally implements GroupDetailsAction {
    readonly type = TypeEnum.UpdateGroupDetailsLocally;
    constructor(public groupDetails: GroupDetails) {}
  }

  export type TypeUnion =
    | LoadGroupDetails
    | LoadGroupDetailsFailure
    | LoadGroupDetailsSuccess
    | UpdateGroupDetailsLocally;
}
