export const HOME_BUTTON_IPHONE_MODEL_CODES = [
  'iPhone7,1', // iPhone 6 Plus
  'iPhone7,2', // iPhone 6
  'iPhone8,1', // iPhone 6s
  'iPhone8,2', // iPhone 6s Plus
  'iPhone8,4', // iPhone SE
  'iPhone9,1', // iPhone 7
  'iPhone9,2', // iPhone 7 Plus
  'iPhone9,3', // iPhone 7
  'iPhone9,4', // iPhone 7 Plus
  'iPhone10,1', // iPhone 8 Verizon
  'iPhone10,2', // iPhone 8 Plus
  'iPhone10,4', // iPhone 8 AT&T/T-mobile
];

export const HOME_BUTTON_IPAD_MODEL_CODES = [
  // iPad
  'iPad1,1', // iPad
  'iPad1,2', // iPad 3G
  'iPad2,1', // iPad 2 (WiFi)
  'iPad2,2', // iPad 2 (GSM)
  'iPad2,3', // iPad 2 (CDMA)
  'iPad2,4', // iPad 2 New Revision
  'iPad3,1', // iPad 3 (WiFi)
  'iPad3,2', // iPad 3 (4G) CDMA
  'iPad3,3', // iPad 3 (4G) GSM
  'iPad3,4', // iPad 4 (WiFi)
  'iPad3,5', // iPad 4 (4G) GSM
  'iPad3,6', // iPad 4 (4G) CDMA
  'iPad6,11', // iPad 5 (WiFi)
  'iPad6,12', // iPad 5 (4G)
  'iPad7,5', // iPad 6 (WiFi)
  'iPad7,6', // iPad 6 (4G)
  'iPad7,11', // iPad 7 (WiFi)
  'iPad7,12', // iPad 7 (4G)
  'iPad11,6', // iPad 8 (WiFi)
  'iPad11,7', // iPad 8 (4G)
  'iPad12,1', // iPad 9 (WiFi)
  'iPad12,2', // iPad 9 (4G)

  // iPad Mini
  'iPad2,5', // iPad Mini (WiFi)
  'iPad2,6', // iPad Mini (4G)
  'iPad2,7', // iPad Mini (4G)
  'iPad4,4', // iPad Mini 2 (WiFi)
  'iPad4,5', // iPad Mini 2 (4G)
  'iPad4,6', // iPad Mini 2 (4G)
  'iPad4,7', // iPad Mini 3 (WiFi)
  'iPad4,8', // iPad Mini 3 (4G)
  'iPad4,9', // iPad Mini 3 (4G)
  'iPad5,1', // iPad Mini 4 (WiFi)
  'iPad5,2', // iPad Mini 4 (4G)
  'iPad11,1', // iPad Mini 5 (WiFi)
  'iPad11,2', // iPad Mini 5 (4G)

  // iPad Air
  'iPad4,1', // iPad Air (WiFi)
  'iPad4,2', // iPad Air (4G)
  'iPad4,3', // iPad Air (4G)
  'iPad5,3', // iPad Air 2 (WiFi)
  'iPad5,4', // iPad Air 2 (4G)
  'iPad11,3', // iPad Air 3 (WiFi)
  'iPad11,4', // iPad Air 3 (4G)

  // iPad Pro
  'iPad6,3', // iPad Pro 9.7-inch (WiFi)
  'iPad6,4', // iPad Pro 9.7-inch (4G)
  'iPad6,3', // iPad Pro 12.9-inch (WiFi)
  'iPad6,4', // iPad Pro 12.9-inch (4G)
  'iPad6,7', // iPad Pro 12.9-inch 2nd-gen (WiFi)
  'iPad6,8', // iPad Pro 12.9-inch 2nd-gen (4G)
  'iPad7,3', // iPad Pro 10.5-inch (WiFi)
  'iPad7,4', // iPad Pro 10.5-inch (4G)
  'iPad8,1', // iPad Pro 11-inch (WiFi)
  'iPad8,2', // iPad Pro 11-inch (WiFi)
  'iPad8,3', // iPad Pro 11-inch (4G)
  'iPad8,4', // iPad Pro 11-inch (4G)
  'iPad8,9', // iPad Pro 11-inch 2nd-gen (WiFi)
  'iPad8,10', // iPad Pro 11-inch 2nd-gen (WiFi)
  'iPad8,11', // iPad Pro 11-inch 2nd-gen (4G)
  'iPad8,12', // iPad Pro 11-inch 2nd-gen (4G)
];

export const HOME_BUTTON_IOS_MODEL_CODES = [
  ...HOME_BUTTON_IPHONE_MODEL_CODES,
  ...HOME_BUTTON_IPAD_MODEL_CODES,
];
