import { MatIconRegistry } from '@angular/material/icon';
import { Observable } from 'rxjs';

let __iconRegistry: MatIconRegistry | null = null;

export function __setMatIconRegistry(iconRegistry: MatIconRegistry) {
  __iconRegistry = iconRegistry;
}

export function __mingaGetIcon(name: string): Observable<SVGElement> {
  if (!__iconRegistry) {
    throw new Error('__setMatIconRegistry not called');
  }

  return __iconRegistry.getNamedSvgIcon(name, 'minga');
}
