import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { MgButtonModule } from 'minga/app/src/app/button';
import { MgIconModule } from 'minga/app/src/app/icon';

import { XlsListUploaderModule } from '@shared/components/XlsListUploader';
import { XlsListUploaderDialogComponent } from '@shared/components/XlsListUploader/XlsListUploaderDialog/XlsListUploaderDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgIconModule,
    XlsListUploaderModule,

    // External dependencies
    CommonModule,
    MatDialogModule,
  ],
  declarations: [XlsListUploaderDialogComponent],
  exports: [XlsListUploaderDialogComponent],
})
export class XlsListUploaderDialogModule {}
