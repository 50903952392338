export interface IBarcodeScannerResult {
  readonly cancelled: boolean;
  readonly text: string;
}

export type BarcodeScannerReaders = 'barcode' | 'qrcode';

export abstract class BarcodeScanner {
  abstract scan(
    readers?: BarcodeScannerReaders[],
    title?: string,
  ): Promise<IBarcodeScannerResult>;
  abstract isAvailable(): boolean;
}
