<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  overlayTitle="{{ MESSAGES.MODAL_TITLE }}"
  [isLoading]="isLoading$ | async">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Template -->
<ng-template #mainTemplate>
  <div [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <!-- Student Information -->
    <ng-container *ngTemplateOutlet="studentTemplate"></ng-container>
    <!-- Reward Select Field -->
    <mg-form-select
      *ngIf="!dialogData?.rewardItemIds[0]"
      placeholder="{{ MESSAGES.REWARD_SELECT_PLACEHOLDER }}"
      appendTo="body"
      [floatingLabel]="true"
      [closeOnSelect]="true"
      [control]="form.controls[FORM_FIELD.ADD_REWARD]"
      [multiple]="false"
      [searchable]="true"
      [hideSelected]="false"
      [options]="rewardOptions$ | async"
      (selectionChanged)="addRewardItem($any($event), true)">
    </mg-form-select>
    <!-- Data Table -->
    <ng-container *ngTemplateOutlet="rewardTableTemplate"></ng-container>
    <!-- Total Points -->
    <div
      fxLayout="row"
      fxLayoutAlign="center center">
      <div
        class="total-points"
        fxFlex="nogrow"
        [class.inadequate]="(hasAdequatePoints$ | async) === false">
        {{ MESSAGES.TOTAL_POINTS }} <mg-icon>star-o</mg-icon>
        {{ (totalCost$ | async) || 0 }}
      </div>
    </div>
    <div
      *ngIf="(hasAdequatePoints$ | async) === false"
      class="insufficient-error">
      <mg-text
        variant="header-sm"
        textTransform="none"
        color="error">
        {{ MESSAGES.INSUFFICIENT_POINTS_ERROR }}
      </mg-text>
    </div>
  </div>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-generic-button
        colorTheme="red"
        variant="clear"
        paddingSize="none"
        [hasRipples]="false"
        [isRounded]="false"
        (onClick)="cancel()">
        {{ MESSAGES.BUTTON_LABEL_CANCEL }}
      </mg-generic-button>
    </div>
    <div>
      <mg-generic-button
        colorTheme="pink"
        [isDisabled]="(hasAdequatePoints$ | async) === false || !canSubmit"
        (click)="submit()">
        {{ MESSAGES.BUTTON_CHECKOUT }}
      </mg-generic-button>
    </div>
  </div>
</ng-template>

<!-- Reward Item Table Template -->
<ng-template #rewardTableTemplate>
  <div class="table-container">
    <mat-table
      *ngIf="(selectedRewardItems$ | async)?.length"
      [dataSource]="selectedRewardItems$">
      <!-- Description Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>
          {{ MESSAGES.COLUMN_NAME }}
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{ item.name }}
        </mat-cell>
      </ng-container>
      <!-- Price Column -->
      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef>
          {{ MESSAGES.COLUMN_PRICE }}
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          {{ item.pointCost }}
        </mat-cell>
      </ng-container>
      <!-- Quantity Column -->
      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef>
          {{ MESSAGES.COLUMN_QUANTITY }}
        </mat-header-cell>
        <mat-cell *matCellDef="let item; let i = index">
          <mg-form-text-input
            *ngIf="rewardItemsForm.at(i) as control"
            inputType="number"
            [control]="control">
          </mg-form-text-input>
        </mat-cell>
      </ng-container>
      <!-- Total Column -->
      <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef>
          {{ MESSAGES.COLUMN_TOTAL }}
        </mat-header-cell>
        <mat-cell *matCellDef="let item; let i = index">
          <ng-container *ngIf="rewardItemsForm.at(i) as quantityControl">
            {{ quantityControl.value * item.pointCost }}
          </ng-container>
        </mat-cell>
      </ng-container>
      <!-- Delete Column -->
      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let item; let i = index">
          <mg-generic-button
            colorTheme="navy"
            size="small"
            paddingSize="none"
            variant="clear"
            iconLeft="trash"
            [hasRipples]="false"
            [isRounded]="false"
            (onClick)="deleteRewardItem(item, i)">
          </mg-generic-button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
      <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS"></mat-row>
    </mat-table>
    <!-- Empty State -->
    <mg-empty-state
      *ngIf="!(selectedRewardItems$ | async)?.length"
      [title]="MESSAGES.EMPTY_STATE_TITLE"
      [subtitle]="MESSAGES.EMPTY_STATE_SUBTITLE">
    </mg-empty-state>
  </div>
</ng-template>

<!-- Student Template -->
<ng-template #studentTemplate>
  <div
    *ngIf="student$ | async as student; else selectPersonTemplate"
    class="student">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px">
      <div fxFlex>
        <div class="student-name">
          <span>{{ MESSAGES.STUDENT_NAME }}</span>
          {{ student.displayName }}
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="20px">
          <div
            *ngIf="student.studentId"
            class="student-details"
            fxFlex>
            <span>{{ MESSAGES.STUDENT_ID }}</span>
            {{ student.studentId }}
          </div>
          <div
            *ngIf="student.grade"
            class="student-details"
            fxFlex>
            <span>{{ MESSAGES.STUDENT_GRADE }}</span>
            {{ student.grade }}
          </div>
        </div>
      </div>
      <!-- Total Points -->
      <div
        class="student-points"
        fxFlex="nogrow">
        <span>{{ MESSAGES.AVAILABLE_POINTS }}</span>
        <mg-icon>star-o</mg-icon> {{ student.points }}
      </div>
    </div>
  </div>
</ng-template>

<!-- Select Person Template -->
<ng-template #selectPersonTemplate>
  <div class="select-person">
    <div
      fxLayout="row"
      fxLayoutAlign="center center">
      <div>
        <mg-generic-button
          colorTheme="pink"
          (click)="openPersonSelector()">
          {{ MESSAGES.BUTTON_SELECT_PERSON }}
        </mg-generic-button>
      </div>
    </div>
  </div>
</ng-template>
