import { Action } from '@ngrx/store';
import { Group } from '../models/Group';

export namespace GroupCollectionActions {
  /**
   * Actions to do with collections of groups/lists of groups
   */
  export enum TypeEnum {
    LoadGroupsSuccess = '[GroupCollection] Load Groups Success',
    LoadGroupsFailure = '[GroupCollection] Load Groups Failure',
    LoadGroupsCollection = '[GroupCollection Page] Load Groups Collection',
    SelectGroup = '[View Group Page] Select Group',
    UpdateGroupLocally = '[GroupCollection] Local update',
    AddGroupLocally = '[GroupCollection] Local add',
    LoadAllGroupsInfo = '[GroupCollection Page] Load All Groups Info',
    DeleteGroup = '[GroupCollection Page] Delete Group',
    DeleteGroupSuccess = '[GroupCollection Page] Delete Group Success',
    DeleteGroupFailure = '[GroupCollection Page] Delete Group Failure',
    InvalidateGroupsCollection = '[GroupCollection] Invalidate Groups Collection',
  }

  export interface GroupCollectionAction extends Action {
    readonly type: TypeEnum;
  }

  /**
   * Load Collection Actions
   */
  export class LoadGroupsSuccess implements GroupCollectionAction {
    readonly type = TypeEnum.LoadGroupsSuccess;

    constructor(public payload: Group[], public expiry: number) {}
  }

  export class UpdateGroupLocally implements GroupCollectionAction {
    readonly type = TypeEnum.UpdateGroupLocally;
    constructor(public payload: Group) {}
  }

  export class LoadGroupsFailure implements GroupCollectionAction {
    readonly type = TypeEnum.LoadGroupsFailure;

    constructor(public payload: any) {}
  }

  export class LoadGroupsCollection implements GroupCollectionAction {
    readonly type = TypeEnum.LoadGroupsCollection;
  }

  export class LoadAllGroupsInfo implements Action {
    readonly type = TypeEnum.LoadAllGroupsInfo;
  }

  export class SelectGroup implements GroupCollectionAction {
    readonly type = TypeEnum.SelectGroup;

    constructor(public payload: string) {}
  }

  export class AddGroupLocally implements GroupCollectionAction {
    readonly type = TypeEnum.AddGroupLocally;
    constructor(public payload: Group) {}
  }

  export class DeleteGroupSuccess implements GroupCollectionAction {
    readonly type = TypeEnum.DeleteGroupSuccess;

    constructor(public payload: Group) {}
  }

  export class DeleteGroupFailure implements GroupCollectionAction {
    readonly type = TypeEnum.DeleteGroupFailure;

    constructor(public payload: Group, error: any) {}
  }

  export class DeleteGroup implements GroupCollectionAction {
    readonly type = TypeEnum.DeleteGroup;
    constructor(public payload: Group) {}
  }

  export class InvalidateGroupsCollection implements GroupCollectionAction {
    readonly type = TypeEnum.InvalidateGroupsCollection;
    constructor() {}
  }

  export type TypeUnion =
    | LoadGroupsSuccess
    | LoadGroupsFailure
    | LoadGroupsCollection
    | SelectGroup
    | UpdateGroupLocally
    | AddGroupLocally
    | LoadAllGroupsInfo
    | DeleteGroup
    | DeleteGroupFailure
    | DeleteGroupSuccess
    | InvalidateGroupsCollection;
}
