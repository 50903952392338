import flatten from 'lodash/flatten';

import { getIcon } from '../constants/icon-mapper';
import {
  ActionGroup,
  ActionItem,
  AssignmentType,
} from '../types/tt-my-class.types';

export const countSort = (a: ActionItem, b: ActionItem) => b.count - a.count;

const actionItemMapper = assignType => p => ({
  data: p,
  value: p.id,
  label: p.name,
  count: p.count,
  assignmentType: assignType,
  ...getIcon(assignType, p),
});

export const hallPassMapper = actionItemMapper(AssignmentType.HALLPASS);
export const behaviorMapper = actionItemMapper(AssignmentType.BEHAVIOR);
export const consequenceMapper = actionItemMapper(AssignmentType.CONSEQUENCE);

export const mapActionGroupsToItems = (
  actionGroups: ActionGroup[],
): ActionItem[] => {
  const actions = (actionGroups || []).map(g => {
    const { items, ...category } = g;

    return items.map(item => ({
      ...item,
      category,
    }));
  });

  return flatten(actions);
};

export const groupAndCount = (types: ActionItem[]) => {
  const grouped = types.reduce((acc, item) => {
    const id = item.value;

    if (!acc[id]) {
      acc[id] = {
        items: [],
        count: 0,
      };
    }

    acc[id].items.push(item);
    acc[id].count++;

    return acc;
  }, {});

  return Object.values(grouped).map(
    (value: { count: number; items: ActionItem[] }) => {
      const { items, count } = value;

      return {
        ...items[0],
        count,
      };
    },
  );
};
