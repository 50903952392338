import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MgLikeModule } from 'minga/app/src/app/elements/MgLike';

import { MgFullGraphicMiniCardElement } from './MgFullGraphicMiniCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgLikeModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgFullGraphicMiniCardElement],
  exports: [MgFullGraphicMiniCardElement],
})
export class MgFullGraphicMiniCardModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-full-graphic-mini-card')) {
      //       //   MgFullGraphicMiniCardElement,
      //   {injector},
      // ));
    }
  }
}
