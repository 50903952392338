import { Injectable } from '@angular/core';

import { FtueHighlightKey } from 'minga/app/src/app/ftue/Highlight';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export class FtueHighlightInstance {
  /** @internal */
  private _highlighted: boolean = false;

  /** @internal - Not intended for direct use */
  constructor(
    /** @internal */ private _onHighlight: () => void,
    /** @internal */ private _onUnhighlight: () => void,
  ) {}

  /**
   * Trigger a highlight. An `unhighlight` must be called eventually during
   * the lifecycle of `FtueHighlightInstance` after this is called.
   */
  highlight() {
    if (!this._highlighted) {
      this._highlighted = true;
      this._onHighlight();
    }
  }

  unhighlight() {
    if (this._highlighted) {
      this._highlighted = false;
      this._onUnhighlight();
    }
  }

  toggle() {
    this._highlighted = !this._highlighted;

    if (this._highlighted) {
      this._onHighlight();
    } else {
      this._onUnhighlight();
    }
  }
}

/**
 * Facilitates loading/storing a 'highlighted' state in the app.
 *
 * Usually this service is not used directly and instead the various pipes and
 * components are used instead. If one of the existing pipes/directives do not
 * suite your needs it is recommended that a new pipe, directive or component is
 * created instead of relying on this service.
 *
 * @SEE FtueHighlight.pipe.ts
 * @SEE FtueHighlight.directive.ts
 */
@Injectable({ providedIn: 'root' })
export class FtueHighlightService {
  private _highlightCount = new Map<Symbol, BehaviorSubject<number>>();

  /**
   * Get a highlight instance. See `FtueHighlightInstance` for details
   */
  get(key: FtueHighlightKey): FtueHighlightInstance {
    const inst = new FtueHighlightInstance(
      () => this._increaseHighlightCount(key),
      () => this._decreaseHighlightCount(key),
    );

    return inst;
  }

  /**
   * Get an observable for the FtueHighlightKey.
   * @returns `true` when it should highlight and `false` when it shouldn't
   */
  observeHighlight(key: FtueHighlightKey): Observable<boolean> {
    const subj = this._ensureSubject(key);
    return subj.pipe(map(count => count > 0));
  }

  private _increaseHighlightCount(key: FtueHighlightKey) {
    const subj = this._ensureSubject(key);
    subj.next(subj.getValue() + 1);
  }

  private _decreaseHighlightCount(key: FtueHighlightKey) {
    const subj = this._ensureSubject(key);
    subj.next(subj.getValue() - 1);
  }

  private _ensureSubject(key: FtueHighlightKey): BehaviorSubject<number> {
    let subj = this._highlightCount.get(key._ftueHighlightKey!);
    if (!subj) {
      subj = new BehaviorSubject<number>(0);
      this._highlightCount.set(key._ftueHighlightKey!, subj);
    }

    return subj;
  }
}
