<div
  #carousel
  [ngClass]="{
    'no-slides': pointNumbers.length <= 1
  }"
  class="carousel"
  [attr.data-analytics]="id"
  [attr.data-test]="id">
  <div
    #touchContainer
    class="touch-container">
    <div
      #itemsContainer
      class="carousel-items">
      <ng-container mgCarouselOutlet></ng-container>
    </div>
  </div>
</div>
<ng-container *ngIf="showPagination && pointNumbers.length > 1">
  <ul class="pagination">
    <li
      *ngFor="let i of pointNumbers; let j = index"
      [class.active]="i == activePoint"
      (click)="moveTo(i)"></li>
  </ul>
</ng-container>

<ng-container *ngIf="showNavigation && pointNumbers.length > 1">
  <button
    type="button"
    class="nav prev"
    (click)="prev()"
    [disabled]="this.isFirst"
    [ngClass]="{ disabled: this.isFirst }">
    <mg-icon class="nav-icon">left-arrow-active</mg-icon>
  </button>
  <button
    type="button"
    class="nav next"
    (click)="next()"
    [disabled]="this.isLast"
    [ngClass]="{ disabled: this.isLast }">
    <mg-icon class="nav-icon">right-arrow-active</mg-icon>
  </button>
</ng-container>
