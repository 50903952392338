import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mg-top-nav-left',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavSlot_Left {}

@Component({
  selector: 'mg-top-nav-mid',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavSlot_Mid {}

@Component({
  selector: 'mg-top-nav-right',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavSlot_Right {}

@Component({
  selector: 'mg-top-nav',
  templateUrl: './TopNav.component.html',
  styleUrls: ['./TopNav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavComponent {}
