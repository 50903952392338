import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { PeopleManagerService } from 'src/app/services/PeopleManager';

import { LayoutService } from '@modules/layout/services';
import { BreadcrumbService } from '@modules/layout/services/breadcrumb.service';
import { PeopleRoute } from '@modules/people/types';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { ProfilePersonInfo } from './user-picture-overlay.types';

@Component({
  selector: 'mg-user-picture-overlay',
  templateUrl: './user-picture-overlay.component.html',
  styleUrls: ['./user-picture-overlay.component.scss'],
})
export class UserPictureOverlayComponent implements OnInit {
  private _personSubject = new BehaviorSubject<ProfilePersonInfo | undefined>(
    undefined,
  );
  public person$ = this._personSubject.asObservable();

  constructor(
    public layout: LayoutService,
    private _route: ActivatedRoute,
    private _snackbarService: SystemAlertSnackBarService,
    private _authInfoService: AuthInfoService,
    private _peopleManager: PeopleManagerService,
    private _breadCrumbsService: BreadcrumbService,
  ) {}

  async ngOnInit() {
    const personHash = this._route.snapshot.paramMap.get('hash');
    if (!personHash) {
      const { avatarUrl, firstName, lastName, badgeRoleName } =
        this._authInfoService.authPerson;
      return this._personSubject.next({
        profileImageUrl: avatarUrl,
        firstName,
        lastName,
        badgeRoleName,
      });
    }

    try {
      const people = await this._peopleManager.readPeople(
        [personHash],
        false,
        true,
      );

      this._setBreadCrumbs(personHash);
      this._personSubject.next(people[0] as ProfilePersonInfo);
    } catch (e) {
      this._snackbarService.open({
        message: 'Person not found: ' + e.message,
        type: 'error',
      });
    }
  }

  private _setBreadCrumbs(hash) {
    this._breadCrumbsService.setBreadcrumb([
      {
        title: 'Manage People',
        path: `${PeopleRoute.ROOT}`,
      },
      {
        title: 'Profile',
        path: `${PeopleRoute.ROOT}/profile/${hash}`,
      },
    ]);
  }
}
