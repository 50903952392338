<mg-search-active-route
  (searchQueryChange)="onSearchText($event)"
  searchPlaceholder="Search Messages by person"></mg-search-active-route>

<p
  *ngIf="layout.useLegacyLayout$ | async"
  class="mg-headline page-header">
  <mg-icon class="default-icon">moderation-o</mg-icon>
  Direct Messages
</p>

<div class="feed-items">
  <ng-container
    *ngIf="
      peopleConversations$ | async as peopleConversations;
      else loadingTpl
    ">
    <ng-container *ngIf="peopleConversations.length > 0; else noConversations">
      <div
        *ngIf="searchText$ | async"
        class="results-header-text mg-smallcopy"
        [@inOutAnimation]>
        {{ getResultsHeaderText(peopleConversations.length) }}
      </div>
      <div
        *ngFor="let row of peopleConversations; trackBy: $any(trackByFn)"
        class="item">
        <mg-dm-list-item
          [data]="row.item"
          [readStatus]="true"
          (click)="
            onClickConversationPreview({ event: $event, data: row.item })
          ">
        </mg-dm-list-item>
      </div>
    </ng-container>

    <ng-template #noConversations>
      <ng-container
        *ngIf="searchText$ | async as searchText; else nonSearchEmpty">
        <mg-empty-state
          [title]="'No conversations found!'"
          [subtitle]="'When searching for &quot;' + searchText + '&quot;'">
        </mg-empty-state>
      </ng-container>
      <ng-template #nonSearchEmpty>
        <mg-empty-state [title]="'No conversations yet!'"> </mg-empty-state>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-template #loadingTpl>
    <mg-spinner></mg-spinner>
  </ng-template>
</div>
