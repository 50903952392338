<mg-legacy-overlay-nav
  [component]="this"
  [showTopNav]="showTopNav">
</mg-legacy-overlay-nav>

<ng-template #debugTemplate>
  <strong>Data Object</strong>
  <pre style="white-space: pre-wrap; max-height: 50vh">{{
    content | json
  }}</pre>
</ng-template>

<ng-template #imageLightBox>
  <div
    class="image-light-box"
    fxLayout="column">
    <div fxLayout="row">
      <mg-icon (click)="closeLightBox()">close</mg-icon>
      <div fxFlex></div>
      <mg-icon
        *ngIf="contextHash && isCurrentAuthor()"
        (click)="deleteImageConfirm()"
        >delete</mg-icon
      >
    </div>
    <mg-swipe-container
      fxFlex
      #lightBoxSwipeContainer="mgSwipeContainer"
      class="images image-light-box-swipe-container"
      [spaceBetween]="20"
      [(activeIndex)]="activeImageIndex">
      <div
        *ngFor="let img of displayImages; let i = index"
        class="img-src"
        mgPinchZoom
        [style.background-image]="'url(' + img.url + ')'"
        [style.width.px]="lightBoxImageWidth(img)"
        [style.height.px]="lightBoxImageHeight(img)">
        <div
          class="img-src-blurry"
          [class.img-loading]="img.loading"
          [style.background-image]="'url(' + img.url + ')'"></div>
        <mg-progressive-image
          *ngIf="shouldLoadImage(img.index)"
          mode="sequential"
          (loading-changed)="img.loading = $event.detail.value"
          (image-changed)="img.url = $event.detail.value"
          [images]="img.progressiveUrls">
        </mg-progressive-image>
      </div>
    </mg-swipe-container>

    <div class="mg-centered lightbox-count">
      {{ lightBoxSwipeContainer.activeIndex + 1 }} of
      {{ lightBoxSwipeContainer.count() }}
    </div>
  </div>
</ng-template>

<ng-template #noVideoData>
  <div class="images-container">
    <mg-logo-anchored
      [anchor]="content?.logoAnchor || 0"
      [customLogo]="content?.customLogoImage || ''"></mg-logo-anchored>
    <ng-container *ngIf="designedSvg as designedSvg; else noDesignedSvg">
      <mg-designed-svg-viewer
        [predictedViewboxWidth]="1"
        [predictedViewboxHeight]="1"
        [designedSvgSrc]="designedSvg.svgUrl"
        [designedSvgData]="designedSvg.svgData"
        [designedSvgManipulations]="designedSvg.svgManipulations">
      </mg-designed-svg-viewer>
    </ng-container>
  </div>
</ng-template>

<div *ngIf="videoData as videoData; else noVideoData">
  <mg-video-thumbnail
    [videoData]="videoData"
    [autoplay]="!noVideoAutoPlay"
    [processingText]="videoProcessingText"></mg-video-thumbnail>
</div>

<ng-template #noDesignedSvg>
  <div *ngIf="!videoData">
    <mg-swipe-container
      #swipeContainer="mgSwipeContainer"
      (click)="openImageLightBox($event)"
      class="images inline-images"
      [spaceBetween]="20"
      (containerWidth)="imagesContainerWidth = $event"
      [(activeIndex)]="activeImageIndex">
      <div
        *mgConform="
          displayImages;
          maxWidth: imagesContainerWidth;
          maxHeight: '75vh';
          let img = item
        "
        class="img-src"
        [style.background-image]="'url(' + img.url + ')'">
        <div
          class="img-src-blurry"
          [class.img-loading]="img.loading"
          [style.background-image]="'url(' + img.url + ')'"></div>
        <mg-progressive-image
          *ngIf="shouldLoadImage(img.index)"
          mode="sequential"
          (loading-changed)="img.loading = $event.detail.value"
          (image-changed)="img.url = $event.detail.value"
          [images]="img.progressiveUrls">
        </mg-progressive-image>
      </div>
    </mg-swipe-container>

    <div
      *ngIf="content.imageList.length > 1"
      class="swipe-control"
      fxLayout="row"
      fxLayoutAlign="center center">
      <mg-icon
        block
        [class.disabled]="swipeContainer.hasPrev()"
        (click)="swipeContainer.prev()"
        >left-arrow-active</mg-icon
      >
      <div
        fxFlex
        class="mg-centered">
        {{ swipeContainer.activeIndex + 1 }} of {{ swipeContainer.count() }}
      </div>
      <mg-icon
        block
        [class.disabled]="swipeContainer.hasNext()"
        (click)="swipeContainer.next()"
        >right-arrow-active</mg-icon
      >
    </div>
  </div>
</ng-template>
<div
  class="group-link-container"
  (click)="groupLinkClick($event)"
  *ngIf="content?.ownerGroupHash">
  <span class="group-icon"></span>
  <span class="group-link-text"
    >Posted in
    <span class="group-link">{{ (group$ | async)?.name }}</span></span
  >
</div>
<div
  class="content-block title mg-headline"
  *ngIf="content.title"
  [innerHTML]="content.title | mgSafeHtml"></div>

<div
  *ngIf="content.authorPersonView"
  class="content-block byline"
  fxLayout="row"
  fxLayoutAlign="top">
  <div
    class="author-avatar-wrap"
    *ngIf="content.authorPersonView.profileImageUrl"
    [class.archived-true]="content.authorPersonView?.archived">
    <mg-avatar
      [src]="content?.authorPersonView?.profileImageUrl"
      [color]="content?.authorPersonView?.badgeIconColor"
      [personHash]="content?.authorPersonView?.personHash"></mg-avatar>
  </div>
  <div
    class="byline-info"
    fxFlex>
    <mg-person-minimal [person]="content.authorPersonView"></mg-person-minimal>
    <div
      class="timestamp"
      [class.archived-true]="content.authorPersonView?.archived">
      {{ humanize(content.timestamp) }}
    </div>
  </div>

  <div *ngIf="contextHash">
    <mg-ellipsis
      [mgContentContextMenuClick]="{
        contentContextHash: contextHash,
        overlay: true
      }"></mg-ellipsis>
  </div>
</div>

<div
  class="content-block body-content mg-bodycopy"
  [innerHTML]="content.fullBody | ugcLinksContent | mgSafeHtml"></div>

<div
  *ngIf="!hideActions && !disableActions"
  class="content-block actions"
  [class.has-view-likes]="getLikeCount() > 0 && canViewLikes"
  fxLayout="row"
  fxLayoutAlign="middle center">
  <div
    class="action like"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="middle center"
    [ngClass]="{ liked: isLiked, clicked: likeClickAnimation }">
    <div
      class="action-hit-area"
      (click)="contextHash && like()"></div>
    <mg-icon>like</mg-icon>
    <mg-icon class="like-active">like-active</mg-icon>
    <span>{{ numberize(getLikeCount()) }}</span>
  </div>
  <div
    class="action"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="middle center"
    (click)="contextHash && replyClick()">
    <mg-icon>comments</mg-icon>
    <span>{{ numberize(commentCount || 0) }}</span>
  </div>
</div>
<div
  class="content-block view-likes actions action"
  *ngIf="
    !hideActions &&
    !disableActions &&
    contextHash &&
    content.likeCount > 0 &&
    canViewLikes
  "
  (click)="viewLikes()">
  {{ 'content.viewLikes' | translate }}
</div>

<mg-comment-list-view
  #commentListViewEl
  [comments]="comments"
  [canComment]="canComment$ | async"
  [commentCount]="commentCount"
  [contextHash]="contextHash"
  [disableActions]="disableActions"
  (commentCountUpdate)="onCommentCountUpdate($event)">
</mg-comment-list-view>

<div class="footer-iphonex"></div>
