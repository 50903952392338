import { NgModule } from '@angular/core';

import { SwipeContainerComponent } from './SwipeContainer.component';

@NgModule({
  imports: [],
  declarations: [SwipeContainerComponent],
  exports: [SwipeContainerComponent],
})
export class SwipeContainerModule {}
