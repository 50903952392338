import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OverlayModule } from '@shared/components/modal-overlay';
import { CommonFormModules, CommonUIModules } from '@shared/modules';

import { KioskBtnComponent } from './components/kiosk-btn/kiosk-btn.component';
import { KioskDisclaimerComponent } from './components/kiosk-disclaimer/kiosk-disclaimer.component';
import { KioskModeModalComponent } from './components/kiosk-mode-modal/kiosk-mode-modal.component';

@NgModule({
  imports: [CommonModule, CommonUIModules, CommonFormModules, OverlayModule],
  declarations: [
    KioskBtnComponent,
    KioskDisclaimerComponent,
    KioskModeModalComponent,
  ],
  exports: [KioskBtnComponent, KioskDisclaimerComponent],
})
export class KioskUiModule {}
