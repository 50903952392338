import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RolesService } from 'minga/app/src/app/roles/services';
import { TypeEnum, TypeUnion } from 'src/app/store/root/rootActions';

@Injectable({ providedIn: 'root' })
export class RolesEffects {
  @Effect({ dispatch: false })
  rolesInvalidate$: Observable<void> = this._actions$.pipe(
    ofType(TypeEnum.ChangeMinga, TypeEnum.Logout),
    map(() => this._rolesService.clear()),
  );

  constructor(
    private _rolesService: RolesService,
    private _actions$: Actions<TypeUnion>,
  ) {}
}
