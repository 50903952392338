<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="20px">
  <mg-text
    class="title"
    fontWeight="900"
    textAlign="center"
    >{{ MSG.ERROR_404_TITLE }}</mg-text
  >
  <img [src]="MSG.ERROR_404_IMAGE" />
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="12px">
    <mg-text
      variant="header-md"
      textAlign="center">
      {{ MSG.ERROR__404_QUOTE }}
    </mg-text>
    <mg-text
      variant="body"
      textAlign="center"
      >{{ MSG.ERROR_404_DESCRIPTION }}</mg-text
    >
  </div>
</div>
