import { Validators } from '@angular/forms';

import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import { Chart, ChartOptions, Tooltip } from 'chart.js';

import { ManagerReportDisplayColumns } from 'minga/app/src/app/components/manager-report/components/manager-report-data-table';
import {
  ConsStatus,
  ConsequenceTypesEnum,
  IConsequence,
  autoCompleteTypes,
} from 'minga/domain/consequences';
import { IPbisBehavior } from 'minga/domain/pbis';
import { ReportTypes } from 'minga/domain/reportTypes';
import { day } from 'minga/shared/day';
import { PbisCategory } from 'minga/shared/pbis/constants';
import { filterPersonEmitterTriggers } from 'src/app/components/manager-report/services/report-filter.service';
import { BehaviorManagerRoutes } from 'src/app/modules/behavior-manager/constants';

import { FormConfig } from '@shared/components/crud-form-base/crud-form-base.types';
import { dictToReportFilter } from '@shared/utils/convert-dict-to-report-filter';

import {
  BmBehaviorEditType,
  BmConsequenceEditType,
  BmReportType,
  BmSearchType,
} from '../types';

Chart.register(Tooltip);
day.extend(weekOfYear);

export enum BmReportsMessages {
  LABEL_REPORT_SUMMARY = 'Behavior Summary Report',
  LABEL_REPORT_TYPES = 'Behavior Types Report',
  LABEL_REPORT_STAFF = 'Staff Report',
  LABEL_REPORT_STUDENT = 'Students Report',
  LABEL_REPORT_HISTORY = 'Behavior History Report',
  LABEL_REPORT_CONS_HISTORY = 'Consequence History Report',
  LABEL_REPORT_CONS_TYPES = 'Consequence Type Report',
  LABEL_REPORT_CONS_ID = 'Consequence ID',
  LABEL_REPORT_CONS_OVERDUE = 'Overdue Consequences Report',
  LABEL_REPORT_AUTO_COUNT = 'Automation Counter Report',
  INPUT_PLACEHOLDER_SEARCH = 'Search by name, ID or email',
  SELECT_LABEL_TYPE = 'Behavior name',
  SELECT_LABEL_GRADE = 'Grade',
  SELECT_LABEL_SEARCH_TYPE = 'Search by',
  SELECT_LABEL_STAFF = 'Staff',
  SELECT_LABEL_STUDENTS = 'Students',
  SELECT_LABEL_CONS_TYPE = 'Consequence name',
  SELECT_LABEL_USER_LIST = 'User list',
  SELECT_OPTION_NONE = 'None',
  SELECT_OPTION_AUTO_GROUP = 'Automation group',
  BUTTON_EXPORT = 'Export list',
  BUTTON_APPLY_FILTER = 'Apply filters',
  BUTTON_CLEAR_FILTER = 'Clear filters',

  SCHEDULED_REPORT_CREATED_SUCCESS = 'Scheduled report created',
  SCHEDULED_REPORT_CREATED_BTN = 'View',

  SHOW_FILTERS = 'Show filters',
  HIDE_FILTERS = 'Hide filters',

  /** Report Actions */
  CONSEQUENCE_REACTIVATE_HEADING = 'Are you sure you want to reactivate the consequence(s)?',
  CONSEQUENCE_REACTIVATE_MESSAGE = 'The assigned student(s) will need to complete the consequence(s) again.',
  CONSEQUENCE_REACTIVATE_LABEL = 'Reactivate',
  CONSEQUENCE_ARCHIVE_HEADING = 'Are you sure you want to archive the consequence(s)?',
  CONSEQUENCE_EDIT_CONSEQUENCE = 'Consequence',
  CONSEQUENCE_EDIT_NOTE = 'Note (optional)',
  CONSEQUENCE_EDIT_ASSIGN_DATE = 'Assigned date',
  CONSEQUENCE_EDIT_ASSIGN_TIME = 'Assigned time',
  CONSEQUENCE_EDIT_COMPLETED_DATE = 'Completed date',
  CONSEQUENCE_EDIT_COMPLETED_TIME = 'Completed time',
  CONSEQUENCE_EDIT_COMPLETE_BY_DATE = 'Due by date',

  BEHAVIOR_ARCHIVE_HEADING = 'Are you sure you want to archive the behavior(s)?',
  BEHAVIOR_ARCHIVE_MESSAGE = 'Archiving a behavior will not affect any consequences or automations that may have triggered.',
  BEHAVIOR_ARCHIVE_LABEL = 'Archive',

  RECORD_EDIT_LABEL = 'Edit record',
  RECORD_EDIT_BEHAVIOR = 'Behavior',
  RECORD_EDIT_NOTE = 'Note (optional)',
  RECORD_EDIT_DATE = 'Date',
  RECORD_EDIT_TIME = 'Time',
  RECORD_EDIT_FORM_ERROR = 'Please fix the errors in the form to continue',
}

export enum BmSearchTypes {
  STAFF = 'staff',
  STUDENTS = 'students',
}

export const REPORT_TYPES: BmReportType[] = [
  {
    label: BmReportsMessages.LABEL_REPORT_SUMMARY,
    value: ReportTypes.PBIS_SUMMARY,
  },
  {
    label: BmReportsMessages.LABEL_REPORT_TYPES,
    value: ReportTypes.PBIS_TYPES,
  },
  {
    label: BmReportsMessages.LABEL_REPORT_HISTORY,
    value: ReportTypes.PBIS_HISTORY,
  },
  {
    label: BmReportsMessages.LABEL_REPORT_STUDENT,
    value: ReportTypes.PBIS_STUDENT,
  },
  {
    label: BmReportsMessages.LABEL_REPORT_STAFF,
    value: ReportTypes.PBIS_STAFF,
  },
  {
    label: BmReportsMessages.LABEL_REPORT_AUTO_COUNT,
    value: ReportTypes.PBIS_AUTOMATION_COUNT,
  },
];

export const CONS_REPORT_TYPES: BmReportType[] = [
  {
    label: BmReportsMessages.LABEL_REPORT_CONS_TYPES,
    value: ReportTypes.PBIS_CONS_TYPES,
  },
  {
    label: BmReportsMessages.LABEL_REPORT_CONS_HISTORY,
    value: ReportTypes.PBIS_CONS_HISTORY,
  },
  {
    label: BmReportsMessages.LABEL_REPORT_CONS_OVERDUE,
    value: ReportTypes.PBIS_CONS_OVERDUE,
  },
];

export const CONS_REPORT_CAT_TYPES: BmReportType[] =
  dictToReportFilter(ConsequenceTypesEnum);

export const CONS_REPORT_STATUS: BmReportType[] =
  dictToReportFilter(ConsStatus);

export const SEARCH_TYPES: BmSearchType[] = [
  {
    label: BmReportsMessages.SELECT_LABEL_STAFF,
    value: BmSearchTypes.STAFF,
  },
  {
    label: BmReportsMessages.SELECT_LABEL_STUDENTS,
    value: BmSearchTypes.STUDENTS,
  },
];

export const CONS_CATEGORIES: BmReportType[] = dictToReportFilter(PbisCategory);

export enum BmReportsFilterType {
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  TYPE = 'type',
  ISSUED_TO = 'issued_to',
  ISSUED_BY = 'issued_by',
  GRADE = 'grade',
  CONS_TYPE = 'consequence_type',
  CONS_CAT_TYPE = 'consequence_category_type',
  CONS_STATUS = 'consequence_status',
  AUTO_TYPE = 'automation_type',
  CONS_ID = 'consequence_id',
  AUTO_GROUP = 'automation_group',
  CONS_CAT = 'consequence_category',
  USER_LIST = 'user_list',
}

export const BM_REPORTS_FILTER_INIT_STATE = {
  startDate: day().subtract(7, 'd').startOf('day'),
  endDate: day().endOf('day'),
  issuedTo: [],
  issuedBy: [],
  types: [],
  grades: [],
  automationTypes: [],
  consID: undefined,
  consequenceCategory: undefined,
  userList: [],
};

export const BM_REPORTS_FILTER_NON_ARRAY_TYPES = [
  BmReportsFilterType.START_DATE,
  BmReportsFilterType.END_DATE,
  BmReportsFilterType.CONS_ID,
  BmReportsFilterType.AUTO_GROUP,
  BmReportsFilterType.CONS_CAT,
];

export const BM_REPORTS_PERSON_EMITTER_ACCESSORS: Record<
  string,
  filterPersonEmitterTriggers
> = {
  [BmReportsFilterType.ISSUED_TO]: {
    accessor: 'issuedTo',
    fieldsToReset: ['issuedBy'],
  },
  [BmReportsFilterType.ISSUED_BY]: {
    accessor: 'issuedBy',
    fieldsToReset: ['issuedTo'],
  },
};

export enum BmReportsSummaryMessages {
  SECTION_TITLE_STUDENTS = 'Top Students',
  SECTION_TITLE_STAFF = 'Top Staff',
  SECTION_TITLE_BEHAVIORS = 'Top Behaviors',

  SECTION_BUTTON_SEE_ALL = 'See all',

  METRIC_TITLE_TRACKED_BEHAVIORS = 'Total Behaviors',

  PRAISE = 'Praise',
  GUIDANCE = 'Guidance',
}

export enum GraphDisplay {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const BM_REPORTS_BAR_CHART_OPTIONS: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: true,
      titleFont: {
        family: 'Muli',
        size: 14,
      },
      bodyFont: {
        family: 'Muli',
        size: 14,
      },
      titleMarginBottom: 10,
      padding: 10,
      callbacks: {
        label: tooltipItem => {
          return ' ' + tooltipItem.dataset.label + ' : ' + tooltipItem.raw;
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: '#B2B2B2',
        font: {
          family: 'Muli',
          size: 16,
          weight: 'bold',
        },
      },
      grid: {
        display: true,
        lineWidth: 0,
        color: 'transparent',
        drawBorder: true,
        borderColor: '#70A5CA',
        borderWidth: 2,
      },
    },
    y: {
      ticks: {
        color: '#B2B2B2',
        font: {
          family: 'Muli',
          size: 14,
          weight: 'bold',
        },
        maxTicksLimit: 3,
        callback: (val: number) => val,
      },
      beginAtZero: true,
      grid: {
        display: true,
        lineWidth: 0,
        color: 'transparent',
        drawBorder: true,
        borderColor: '#70A5CA',
        borderWidth: 2,
      },
      title: {
        display: true,
        font: {
          family: 'Muli',
          size: 14,
          weight: 'bold',
        },
        color: '#B2B2B2',
        text: 'Amount',
      },
    },
  },
};

export const BM_REPORTS_TYPES_PRAISE_COLUMNS: ManagerReportDisplayColumns = [
  'behaviorName',
  'praiseCount',
  'summary',
  'history',
];

export const BM_REPORTS_TYPES_GUIDANCE_COLUMNS: ManagerReportDisplayColumns = [
  'behaviorName',
  'guidanceCount',
  'summary',
  'history',
];

export enum BM_BEHAVIOR_EDIT_FORM_FIELDS {
  TYPE = 'pbisTypeId',
  DATE = 'createdAt',
  TIME = 'createdAtTime',
  NOTE = 'message',
}

export const BM_BEHAVIOR_EDIT_FORMGROUP: FormConfig<
  BM_BEHAVIOR_EDIT_FORM_FIELDS,
  BmBehaviorEditType
> = {
  [BM_BEHAVIOR_EDIT_FORM_FIELDS.TYPE]: [null, [Validators.required]],
  [BM_BEHAVIOR_EDIT_FORM_FIELDS.NOTE]: [null, [Validators.maxLength(500)]],
  [BM_BEHAVIOR_EDIT_FORM_FIELDS.DATE]: {
    formControl: [null, [Validators.required]],
    key: 'createdAt',
    set: (data: IPbisBehavior) => day(data.createdAt),
    get: (formData: any) => {
      const times = formData.createdAtTime.split(':');
      formData.createdAt = formData.createdAt.set('hour', times[0]);
      formData.createdAt = formData.createdAt.set('minute', times[1]);
      return formData.createdAt.toDate();
    },
  },
  [BM_BEHAVIOR_EDIT_FORM_FIELDS.TIME]: {
    formControl: [null, [Validators.required]],
    key: BM_BEHAVIOR_EDIT_FORM_FIELDS.TIME,
    set: (data: IPbisBehavior) => day(data.createdAt).format('HH:mm'),
  },
};

export enum BM_CONSEQUENCE_EDIT_FORM_FIELDS {
  TYPE = 'typeId',
  ASSIGN_DATE = 'issuedAt',
  ASSIGN_TIME = 'issuedAtTime',
  COMPLETED_DATE = 'completedAt',
  COMPLETED_TIME = 'completedAtTime',
  COMPLETE_BY_DATE = 'completeBy',
  NOTE = 'note',
}

export const BM_CONSEQUENCE_EDIT_FORMGROUP: FormConfig<
  BM_CONSEQUENCE_EDIT_FORM_FIELDS,
  BmConsequenceEditType
> = {
  [BM_CONSEQUENCE_EDIT_FORM_FIELDS.TYPE]: [null, [Validators.required]],
  [BM_CONSEQUENCE_EDIT_FORM_FIELDS.NOTE]: [null, [Validators.maxLength(500)]],
  [BM_CONSEQUENCE_EDIT_FORM_FIELDS.ASSIGN_DATE]: {
    formControl: [null, [Validators.required]],
    key: BM_CONSEQUENCE_EDIT_FORM_FIELDS.ASSIGN_DATE,
    set: (data: IConsequence) => day(data.issuedAt),
    get: (formData: any) => {
      const times = formData.issuedAtTime.split(':');
      formData.issuedAt = formData.issuedAt.set('hour', times[0]);
      formData.issuedAt = formData.issuedAt.set('minute', times[1]);
      return formData.issuedAt.toDate();
    },
  },
  [BM_CONSEQUENCE_EDIT_FORM_FIELDS.ASSIGN_TIME]: {
    formControl: [null, [Validators.required]],
    key: BM_CONSEQUENCE_EDIT_FORM_FIELDS.ASSIGN_TIME,
    set: (data: IConsequence) => day(data.issuedAt).format('HH:mm'),
  },
  [BM_CONSEQUENCE_EDIT_FORM_FIELDS.COMPLETED_DATE]: {
    formControl: [null],
    key: BM_CONSEQUENCE_EDIT_FORM_FIELDS.COMPLETED_DATE,
    set: (data: IConsequence) =>
      data?.completedAt && !autoCompleteTypes.includes(data?.type?.type ?? 0)
        ? day(data.completedAt)
        : null,
    get: (formData: any) => {
      if (formData.completedAtTime) {
        const times = formData.completedAtTime.split(':');
        formData.completedAt = formData.completedAt.set('hour', times[0]);
        formData.completedAt = formData.completedAt.set('minute', times[1]);
      }
      return formData.completedAt?.toDate();
    },
  },
  [BM_CONSEQUENCE_EDIT_FORM_FIELDS.COMPLETED_TIME]: {
    formControl: [null],
    key: BM_CONSEQUENCE_EDIT_FORM_FIELDS.COMPLETED_TIME,
    set: (data: IConsequence) =>
      data.completedAt && !autoCompleteTypes.includes(data?.type?.type ?? 0)
        ? day(data.completedAt).format('HH:mm')
        : null,
  },
  [BM_CONSEQUENCE_EDIT_FORM_FIELDS.COMPLETE_BY_DATE]: {
    formControl: [null],
    key: BM_CONSEQUENCE_EDIT_FORM_FIELDS.COMPLETE_BY_DATE,
    set: (data: IConsequence) =>
      data.completeBy ? day(data.completeBy) : null,
    get: (formData: any) => {
      return formData?.completeBy?.toDate();
    },
  },
};

export const BHStudentSummaryInfo = {
  filter: BmReportsFilterType.ISSUED_TO,
  url: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${ReportTypes.PBIS_SUMMARY}`,
};

export const BHStudentHistoryInfo = {
  filter: BmReportsFilterType.ISSUED_TO,
  url: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${ReportTypes.PBIS_HISTORY}`,
};

export const BHStaffSummaryInfo = {
  filter: BmReportsFilterType.ISSUED_BY,
  url: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${ReportTypes.PBIS_SUMMARY}`,
};

export const BHStaffHistoryInfo = {
  filter: BmReportsFilterType.ISSUED_BY,
  url: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${ReportTypes.PBIS_HISTORY}`,
};

export const BHTypeSummaryInfo = {
  filter: BmReportsFilterType.TYPE,
  url: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${ReportTypes.PBIS_SUMMARY}`,
};

export const BHTypeHistoryInfo = {
  filter: BmReportsFilterType.TYPE,
  url: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${ReportTypes.PBIS_HISTORY}`,
};
export const BHStudentConsequenceHistoryInfo = {
  filter: BmReportsFilterType.ISSUED_TO,
  url: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${ReportTypes.PBIS_CONS_HISTORY}`,
};

export const BHConsequenceTypeHistoryInfo = {
  filter: BmReportsFilterType.CONS_TYPE,
  url: `${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${ReportTypes.PBIS_CONS_HISTORY}`,
};

export const BM_AUTO_FILTER_STATE_KEY = 'bmAutomationFilterState';
