import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { HP_TYPE_ICON_CONFIG } from '@shared/constants';

import { __setMatIconRegistry } from './util/icon';

@NgModule({
  imports: [HttpClientModule],
})
export class IconModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    __setMatIconRegistry(iconRegistry);
    iconRegistry.addSvgIconSetInNamespace(
      'minga',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/minga_platform_ui_icons.svg',
      ),
    );
    iconRegistry.addSvgIconSetInNamespace(
      'minga.profile',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/minga_platform_ui_links_icons.svg',
      ),
    );
    Object.entries(HP_TYPE_ICON_CONFIG).forEach(([iconName, path]) => {
      iconRegistry.addSvgIconInNamespace(
        'minga',
        iconName,
        sanitizer.bypassSecurityTrustResourceUrl(path),
      );
    });
    iconRegistry.addSvgIconSetInNamespace(
      'minga',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/dist/sprite.svg'),
    );

    iconRegistry.addSvgIconSetInNamespace(
      'minga.behaviors',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/behavior/type-icons/dist/sprite.svg',
      ),
    );
  }
}
