import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LayoutLegacyContentComponent } from './layout-legacy-content.component';

@NgModule({
  declarations: [LayoutLegacyContentComponent],
  exports: [LayoutLegacyContentComponent],
  imports: [CommonModule],
})
export class LayoutLegacyContentModule {}
