import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { CalendarScheduleNavModule } from 'minga/app/src/app/components/CalendarScheduleNav';
import { CalendarScheduleOptionsModule } from 'minga/app/src/app/components/CalendarScheduleOptions';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgIconModule } from 'minga/app/src/app/icon';

import { EventsHomeComponent } from './EventsHome.component';

@NgModule({
  imports: [
    // Minga dependencies
    MatSelectModule,
    CalendarScheduleOptionsModule,
    MgFormFieldModule,
    MgIconModule,
    CalendarScheduleNavModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    FormsModule,
  ],
  declarations: [EventsHomeComponent],
  exports: [EventsHomeComponent],
})
export class EventsHomeModule {}
