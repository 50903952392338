import {
  ApplicationRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[mgFixed]',
  exportAs: 'mgFixed',
})
export class MgFixedDirective implements OnDestroy, OnInit {
  private _viewRef: any;
  private _dummyElement?: HTMLElement;
  private _requestAnimationFrame: any;
  private _onResize: (e) => any;

  constructor(
    private appRef: ApplicationRef,
    private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    this._onResize = e => this.onWindowResize(e);
  }

  private _calcDummyHeight() {
    if (!this._dummyElement || !this._viewRef) {
      return;
    }

    let element = <HTMLElement>this._viewRef.rootNodes[0];

    this._dummyElement.style.height = element.clientHeight + 'px';
  }

  onWindowResize(e) {
    cancelAnimationFrame(this._requestAnimationFrame);

    this._requestAnimationFrame = requestAnimationFrame(() => {
      this._calcDummyHeight();
    });
  }

  @Input()
  set mgFixed(value: string) {
    this.clearViewRef();

    value = '' + value;

    this._viewRef = this.viewContainer.createEmbeddedView(this.template);

    let element = <HTMLElement>this._viewRef.rootNodes[0];

    element.classList.add('mg-fixed');
    element.style.zIndex = '100';
    element.style.position = 'fixed';
    switch (value) {
      default:
      case 'bottom':
        element.style.bottom = '0';
        break;
      case 'top':
        element.style.top = '0';
        break;
      case 'right':
        element.style.right = '0';
        break;
      case 'left':
        element.style.left = '0';
        break;
    }

    document.body.appendChild(element);

    this._dummyElement = document.createElement('div');
    this._dummyElement.classList.add('mg-fixed-dummy-padding-element');

    document.body.appendChild(this._dummyElement);

    this._requestAnimationFrame = requestAnimationFrame(() => {
      this._calcDummyHeight();
    });
  }

  clearViewRef() {
    if (!this._viewRef) {
      return;
    }

    this.appRef.detachView(this._viewRef);

    if (this._dummyElement) {
      this._dummyElement.remove();
      delete this._dummyElement;
    }
  }

  ngOnInit() {
    window.addEventListener('resize', this._onResize);
  }

  ngOnDestroy() {
    this.clearViewRef();
    cancelAnimationFrame(this._requestAnimationFrame);
    window.removeEventListener('resize', this._onResize);
  }
}
