import { GroupMemberInfo } from 'minga/proto/gateway/mingaGroup_pb';

export function isGroupMemberInfoAMember(
  rank: number,
): rank is GroupMemberInfo.Rank.MEMBER | GroupMemberInfo.Rank.OWNER {
  switch (rank) {
    case GroupMemberInfo.Rank.MEMBER:
    case GroupMemberInfo.Rank.OWNER:
      return true;
    default:
      return false;
  }
}
