const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms));

// Utility function to retry a handler with backoff
export const retryWithBackoff = async <T>(
  handler: () => Promise<T>,
  maxRetries: number,
  backoffDelayMs: number,
): Promise<T> => {
  let retries = 0;

  const attempt = async (): Promise<T> => {
    try {
      return await handler();
    } catch (error) {
      retries++;
      if (retries <= maxRetries) {
        const backoff = backoffDelayMs * Math.pow(2, retries - 1);
        await sleep(backoff);
        return await attempt();
      } else {
        throw error;
      }
    }
  };

  return await attempt();
};
