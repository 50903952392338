import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';

import { RoleSelectComponent } from './RoleSelect.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgFormFieldModule,

    // External dependencies
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    TranslateModule,
  ],
  declarations: [RoleSelectComponent],
  exports: [RoleSelectComponent],
})
export class RoleSelectModule {}
