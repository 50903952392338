import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinct, filter, map, tap } from 'rxjs/operators';

import {
  selectAuthPerson,
  selectAuthResponse,
  selectAuthTokenPayload,
  selectDetachedSsoProviderInfo,
  selectLinkedSsoProviderInfo,
  selectSsoError,
} from 'minga/app/src/app/store/AuthStore/selectors';
import {
  IAuthPerson,
  IAuthTokenMingaPayload,
  ISsoAuthError,
  ISsoProviderInfo,
} from 'minga/domain/auth';

/**
 * Service for providing current auth information.
 */
@Injectable({ providedIn: 'root' })
export class AuthInfoService {
  /** @internal */
  private _authInfo: IAuthTokenMingaPayload | null = null;
  /** @internal */
  private _parentGroupHashes: readonly string[] = [];
  /** @internal */
  private _dmPersonalPreference: boolean = false;
  /** @internal */
  private _dmDisabled: boolean = false;

  /** @internal */
  private _authPerson: IAuthPerson | null = null;

  /**
   * Auth information associated with the current users auth token
   */
  readonly authInfo$: Observable<Readonly<IAuthTokenMingaPayload> | null>;

  /**
   * Currently authenticated users person hash
   */
  readonly authPersonHash$: Observable<string>;

  /**
   * SSO provider info that was _just_ successfully logged into. Not kept
   * between sessions.
   */
  readonly detachedSsoProviderInfo$: Observable<ISsoProviderInfo | null>;

  /**
   * SSO provider info that is linked.
   */
  readonly linkedSsoProviderInfo$: Observable<ISsoProviderInfo | null>;

  /**
   * Error resulting from an sso auth error. Not kept between sessions.
   */
  readonly ssoError$: Observable<ISsoAuthError | null>;

  /**
   * Convenience observable to subscribe to both `detachedSsoProviderInfo$` and
   * `linkedSsoProviderInfo$`
   */
  readonly ssoProviderInfo$: Observable<{
    ssoProviderInfo: ISsoProviderInfo;
    detached: boolean;
  }>;

  /**
   * Parent group hashes the currently authenticated user is in
   */
  readonly parentGroupHashes$: Observable<readonly string[]>;

  /**
   * The currently authenticated users dm personal preference setting
   */
  readonly dmPersonalPreference$: Observable<boolean>;

  /**
   * Whether the current user has had dm disabled by minga admin.
   */
  readonly dmDisabled$: Observable<boolean>;

  /**
   * Currently authenticated person info. Use instead of authInfo$, as that is
   * only the info originally returned.
   */
  readonly authPerson$: Observable<IAuthPerson>;

  /**
   * Synchronous version of `authInfo$`. Use `authInfo$` when possible instead.
   */
  get authInfo(): Readonly<IAuthTokenMingaPayload> | null {
    return this._authInfo;
  }

  get authPersonHash(): string {
    return this._authInfo?.person?.personHash || '';
  }

  get authPerson(): IAuthPerson | null {
    return this._authPerson;
  }

  /**
   * Synchronous version of `parentGroupHashes$`. Use `parentGroupHashes$` when
   * possible instead.
   */
  get parentGroupHashes(): readonly string[] {
    return this._parentGroupHashes;
  }

  /**
   * Synchronous version of `dmPersonalPreference$` Use `dmPersonalPreference$`
   * when possible instead.
   */
  get dmPersonalPreference(): boolean {
    return this._dmPersonalPreference;
  }

  /**
   * Synchronous version of `dmDisabled$` Use `dmDisabled$`
   * when possible instead.
   */
  get dmDisabled(): boolean {
    return this._dmDisabled;
  }

  constructor(store: Store<any>) {
    const authResponse$ = store.pipe(select(selectAuthResponse));

    this.dmPersonalPreference$ = authResponse$.pipe(
      map(authResponse => authResponse?.dmPersonalPreference || false),
      tap(value => (this._dmPersonalPreference = value)),
    );

    this.dmDisabled$ = authResponse$.pipe(
      map(authResponse => authResponse?.dmDisabled || false),
      tap(value => (this._dmDisabled = value)),
    );

    this.authInfo$ = store.pipe(
      select(selectAuthTokenPayload),
      tap(authInfo => (this._authInfo = authInfo)),
    );

    this.authPerson$ = store.pipe(
      select(selectAuthPerson),
      tap(authPerson => (this._authPerson = authPerson)),
    );

    this.authPersonHash$ = this.authInfo$.pipe(
      map(info => info?.person?.personHash || ''),
      distinct(),
    );

    this.detachedSsoProviderInfo$ = store.pipe(
      select(selectDetachedSsoProviderInfo),
    );

    this.linkedSsoProviderInfo$ = store.pipe(
      select(selectLinkedSsoProviderInfo),
    );

    this.ssoError$ = store.pipe(select(selectSsoError));

    const linkedAndDetached$ = combineLatest([
      this.detachedSsoProviderInfo$,
      this.linkedSsoProviderInfo$,
    ]);

    this.ssoProviderInfo$ = linkedAndDetached$.pipe(
      filter(([detachedInfo, linkedInfo]) => !!detachedInfo || !!linkedInfo),
      map(([detachedInfo, linkedInfo]) => ({
        ssoProviderInfo: detachedInfo! || linkedInfo!,
        detached: !!detachedInfo,
      })),
    );
  }
}
