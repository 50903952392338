import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { take } from 'rxjs/operators';
import Swiper from 'swiper';

// import * as Swipe from 'swipejs/swipe';

// @SEE: https://swiperjs.com/swiper-api#mousewheel-control
export interface ISwiperMouseWheelOptions {
  eventsTarget?: 'container' | 'wrapper' | HTMLElement;
  forceToAxis?: boolean;
  invert?: boolean;
  releaseOnEdges?: boolean;
  sensitivity?: number;
  thresholdDelta?: number | null;
  thresholdTime?: number | null;
}

export type SwiperMouseWheelOptions = boolean | ISwiperMouseWheelOptions;

@Component({
  selector: 'mg-swipe-container',
  exportAs: 'mgSwipeContainer',
  templateUrl: './SwipeContainer.component.html',
  styleUrls: ['./SwipeContainer.component.scss'],
})
export class SwipeContainerComponent implements OnInit, OnDestroy, OnChanges {
  private _activeIndex: number = 0;
  private _swiper?: Swiper;

  /** @deprecated Use `getSwiperInstance()` instead */
  get swiper() {
    return this._swiper;
  }

  @ViewChild('swipeArea', { static: true })
  swipeAreaEl?: ElementRef;

  @ViewChild('swipeContentContainer', { static: true })
  swipeContentContainerEl?: ElementRef;

  @Input()
  swipeContainerAlign: 'top' | 'middle' | 'bottom' = 'middle';

  @Output()
  containerWidth: EventEmitter<number>;

  @Output()
  activeIndexChange: EventEmitter<number>;

  @Output()
  readonly swiperReady: EventEmitter<Swiper>;

  /**
   * Space between each item (in px)
   *
   * @NOTE Must be set initialy. Changes to this value will not be tracked
   */
  @Input()
  spaceBetween: number = 0;

  /**
   * Mouse wheel options to pass to swiper instance
   *
   * @NOTE Must be set initialy. Changes to this value will not be tracked
   */
  @Input()
  mousewheel: SwiperMouseWheelOptions = false;

  /**
   * Number of swipe items to have per view
   *
   * @NOTE Must be set initialy. Changes to this value will not be tracked
   */
  @Input()
  itemsPerView: number = 1;

  @Input()
  get activeIndex() {
    return this._activeIndex;
  }

  set activeIndex(value: number) {
    this._activeIndex = value;

    if (this._swiper) {
      this._swiper.slideTo(this._activeIndex);
    }
  }

  private _itemWrapElements: HTMLElement[] = [];

  private contentMutationObsever: MutationObserver;

  constructor(
    private element: ElementRef,
    private viewContainer: ViewContainerRef,
  ) {
    this.containerWidth = new EventEmitter();
    this.activeIndexChange = new EventEmitter();
    this.swiperReady = new EventEmitter();
    this.contentMutationObsever = new MutationObserver(mutations => {
      this.onContentMutation(mutations);
    });
  }

  @HostListener('window:resize', [])
  onWindowResize() {
    this.updateWrapsStyles();
  }

  async getSwiperInstance(): Promise<Swiper> {
    if (this._swiper) {
      return this._swiper;
    } else {
      return this.swiperReady.pipe(take(1)).toPromise();
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  private getContainerEl() {
    let containerEl: HTMLElement = this.element.nativeElement;
    return containerEl;
  }

  private clearWrapsSize() {
    for (let wrapEl of this._itemWrapElements) {
      wrapEl.style.width = ``;
      wrapEl.style.height = ``;
    }
  }

  private doWrapsSizeStyles(
    wrapEl: HTMLElement,
    containerWidth: number,
    containerHeight: number,
  ) {
    // wrapEl.style.overflow = 'hidden';
    // wrapEl.style.maxWidth = containerWidth + 'px';
  }

  private updateWrapsStyles() {
    const containerEl = this.getContainerEl();
    const containerRect = containerEl.getBoundingClientRect();

    for (let wrapEl of this._itemWrapElements) {
      this.doWrapsSizeStyles(wrapEl, containerRect.width, containerRect.height);
    }

    this.containerWidth.emit(containerRect.width);
  }

  private fitWrapsToContainer() {
    const containerEl = this.getContainerEl();
    const containerRect = containerEl.getBoundingClientRect();

    for (let wrapEl of this._itemWrapElements) {
      wrapEl.style.width = `${containerRect.width}px`;
      this.doWrapsSizeStyles(wrapEl, containerRect.width, containerRect.height);
    }

    this.containerWidth.emit(containerRect.width);
  }

  private fitWrapElToContainer(wrapEl: HTMLElement) {
    const containerEl = this.getContainerEl();
    const containerRect = containerEl.getBoundingClientRect();

    wrapEl.style.width = `${containerRect.width}px`;
  }

  initContentContainerItemWrap(wrapEl: HTMLElement) {}

  initContentNodes(nodes: NodeList) {
    let pendingRemovalNodes = [];
    let reparentedNodes = [];

    for (let i = 0; nodes.length > i; i++) {
      const node = nodes[i];

      if (node instanceof HTMLElement) {
        if (!node.classList.contains('mg-swipe-container-item-wrap')) {
          reparentedNodes.push(node);
        } else {
          this.initContentContainerItemWrap(node);
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        pendingRemovalNodes.push(node);
      }
    }

    for (let node of reparentedNodes) {
      let wrapEl = document.createElement('div');

      // wrapEl.style.display = 'inline-block';
      // wrapEl.style.verticalAlign = 'middle';
      wrapEl.classList.add('mg-swipe-container-item-wrap');
      wrapEl.classList.add(
        'mg-swipe-container-item-align-' + this.swipeContainerAlign,
      );
      wrapEl.classList.add('swiper-slide');

      if (node.parentElement) {
        node.parentElement.insertBefore(wrapEl, node);
      }
      wrapEl.appendChild(node);

      this._itemWrapElements.push(wrapEl);
    }

    for (let removalNode of pendingRemovalNodes) {
      if (removalNode.parentElement) {
        removalNode.parentElement.removeChild(removalNode);
      }
    }

    this.clearEmptyWrapElements();
  }

  private clearEmptyWrapElements() {
    for (let wrapEl of this._itemWrapElements) {
      if (wrapEl.children.length === 0) {
        this.cleanWrapElement(wrapEl);
      }
    }
  }

  private cleanWrapElement(wrapEl: HTMLElement) {
    let index = this._itemWrapElements.findIndex(el => {
      return el.isSameNode(wrapEl);
    });

    if (index > -1) {
      this._itemWrapElements.splice(index, 1);
      if (wrapEl.parentElement) {
        wrapEl.parentElement.removeChild(wrapEl);
      }
    }
  }

  private cleanContentNodes(nodes: NodeList) {
    for (let i = 0; nodes.length > i; i++) {
      const node = nodes[i];

      if (node instanceof HTMLElement) {
        if (node.classList.contains('mg-swipe-container-item-wrap')) {
          this.cleanWrapElement(node);
        }
      }
    }
  }

  onContentMutation(mutations: MutationRecord[]) {
    for (let mutation of mutations) {
      this.initContentNodes(mutation.addedNodes);
      this.cleanContentNodes(mutation.removedNodes);
    }

    this.update();
  }

  ngOnInit() {
    const swipeAreaEl: HTMLElement = this.swipeAreaEl!.nativeElement;
    const swipeContentEl: HTMLElement =
      this.swipeContentContainerEl!.nativeElement;

    this.contentMutationObsever.observe(swipeContentEl, {
      subtree: false,
      childList: true,
    });

    this.initContentNodes(swipeContentEl.childNodes);

    requestAnimationFrame(() => {
      const containerEl = this.getContainerEl();
      const containerRect = containerEl.getBoundingClientRect();
      this.containerWidth.emit(containerRect.width);

      this._swiper = new Swiper(swipeAreaEl, {
        loop: false,
        spaceBetween: this.spaceBetween,
        initialSlide: this.activeIndex,
        slidesPerView: this.itemsPerView,
        mousewheel: this.mousewheel,
      });

      this._swiper.on('slideChange', () => {
        this.activeIndex = this._swiper!.activeIndex;
        this.activeIndexChange.emit(this.activeIndex);
      });

      this.swiperReady.emit(this._swiper);
    });
  }

  update() {
    if (this._swiper) {
      this._swiper.update();
    }
  }

  ngOnDestroy() {
    this.contentMutationObsever.disconnect();
    this.swiperReady.complete();
  }

  getActiveIndex() {
    if (!this._swiper) {
      return 0;
    }

    return this._swiper.activeIndex;
  }

  setActiveIndex(activeIndex: number) {
    // this._swiper.activeIndex = activeIndex;
    this.activeIndex = activeIndex;
    this._swiper!.slideTo(activeIndex);
  }

  count() {
    if (!this._swiper) {
      return 0;
    }

    return this._swiper.slides.length;
  }

  hasNext() {
    if (!this._swiper) {
      return false;
    }

    return this._swiper.isEnd;
  }

  hasPrev() {
    if (!this._swiper) {
      return false;
    }

    return this._swiper.isBeginning;
  }

  next() {
    return this._swiper!.slideNext();
  }

  prev() {
    return this._swiper!.slidePrev();
  }
}
