import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';

import { IUrlMetadata, UrlMetadataService } from '../../services';

export interface ILinkDetailComponentProperties {
  href: string;
  readonly loading: boolean;
  readonly logo: string;
  readonly image: string;
  readonly publisher: string;
  readonly title: string;
  readonly url: string;
}

@Component({
  selector: 'mg-link-detail-element',
  templateUrl: './LinkDetail.component.html',
  styleUrls: ['./LinkDetail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkDetailComponent implements ILinkDetailComponentProperties {
  private _href: string = '';
  private _metadata: IUrlMetadata | null = null;
  private _loading: boolean = false;

  get image() {
    return this._metadata?.image || '';
  }

  get logo() {
    return this._metadata?.logo || '';
  }

  get publisher() {
    return this._metadata?.publisher || '';
  }

  get title() {
    return this._metadata?.title || '';
  }

  get url() {
    return this._metadata?.url || this.href || '';
  }

  /**
   * Set the `href` directly. If `href` is set then the `target` input will be
   * cleared.
   */
  @Input()
  set href(value: string) {
    if (value !== this.href) {
      this._href = value;
      this._getHrefMetadata(this._href);
    }
  }

  get href() {
    return this._href;
  }

  get loading() {
    return this._loading;
  }

  @HostBinding('class.hidden')
  get hidden() {
    return this._metadata?.hidden || false;
  }

  constructor(
    private urlMetadataService: UrlMetadataService,
    private _cdr: ChangeDetectorRef,
  ) {}

  private async _getHrefMetadata(href: string) {
    this._loading = true;
    this._cdr.markForCheck();
    const metadata = await this.urlMetadataService.get(href).catch(err => {
      if (this.href === href) {
        this._loading = false;
        this._cdr.markForCheck();
      }

      throw err;
    });

    if (this.href !== href) {
      return;
    }

    this._metadata = metadata;
    this._loading = false;
    this._cdr.markForCheck();
  }
}

export namespace LinkDetailComponent {
  export type Element = NgElement &
    WithProperties<ILinkDetailComponentProperties>;
}

declare global {
  interface HTMLElementTagNameMap {
    'mg-link-detail': LinkDetailComponent.Element;
  }
}
