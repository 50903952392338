import { CameraPermissionStatus } from './camera.constants';
import {
  ICameraActionSheetOptions,
  ICameraActionSheetResult,
} from './camera.types';

const convertDiagnosticStatus = (status: any) => {
  const cordova = (window as any).cordova;

  switch (status) {
    case cordova.plugins.diagnostic.permissionStatus.GRANTED:
      return CameraPermissionStatus.GRANTED;
    case cordova.plugins.diagnostic.permissionStatus.DENIED:
      return CameraPermissionStatus.DENIED;
    case cordova.plugins.diagnostic.permissionStatus.NOT_DETERMINED:
      return CameraPermissionStatus.NOT_DETERMINED;
    default:
      console.warn('Unhandled camera authorization status:', status);
      return CameraPermissionStatus.UNKNOWN;
  }
};

export const getCameraPermissionStatus =
  async (): Promise<CameraPermissionStatus> => {
    const cordova = (window as any).cordova;
    if (!cordova || !cordova.plugins || !cordova.plugins.diagnostic) {
      return CameraPermissionStatus.NO_CORDOVA;
    }
    return new Promise<CameraPermissionStatus>((resolve, reject) => {
      cordova.plugins.diagnostic.getCameraAuthorizationStatus(
        (status: any) => {
          const permissionStatus = convertDiagnosticStatus(status);
          setTimeout(() => resolve(permissionStatus));
        },
        (err: any) => {
          setTimeout(() => reject(err));
        },
      );
    });
  };

export const requestCameraPermission =
  async (): Promise<CameraPermissionStatus> => {
    const cordova = (window as any).cordova;
    if (!cordova || !cordova.plugins || !cordova.plugins.diagnostic) {
      return CameraPermissionStatus.NO_CORDOVA;
    }
    return new Promise<CameraPermissionStatus>((resolve, reject) => {
      cordova.plugins.diagnostic.requestCameraAuthorization(
        (status: any) => {
          const permissionStatus = convertDiagnosticStatus(status);
          resolve(permissionStatus);
        },
        (err: any) => {
          reject(err);
        },
        true,
      );
    });
  };

export const openCameraActionSheet = async (
  options: ICameraActionSheetOptions,
): Promise<ICameraActionSheetResult> => {
  const plugins = (window as any).plugins;
  const camera = (window as any).navigator.camera;
  const Camera = (window as any).Camera;
  const capture = (window as any).navigator.device?.capture;

  if (!camera || !plugins || !plugins.actionsheet) {
    console.error(
      'Cannot open camera action sheet. Missing either cordova camera plugin or cordova actionsheet plugin',
    );
    return {
      action: 'cancelled',
      files: [],
    };
  }
  const actionSheetPlugin = (window as any).plugins.actionsheet;
  const TAKE_PHOTO_LABEL = 'Take Photo';
  const TAKE_VIDEO_LABEL = 'Take Video';
  const PHOTO_LIBRAY_LABEL = 'Photo Library';
  const buttonOptions: string[] = [];

  if (options.takePhoto) {
    buttonOptions.push(TAKE_PHOTO_LABEL);
  }
  if (options.takeVideo) {
    buttonOptions.push(TAKE_VIDEO_LABEL);
  }
  if (options.fromLibrary) {
    buttonOptions.push(PHOTO_LIBRAY_LABEL);
  }

  const actionSheetConfig = {
    title: '',
    subtitle: '',
    androidEnableCancelButton: true,
    buttonLabels: buttonOptions,
  };

  return new Promise<ICameraActionSheetResult>((resolve, reject) => {
    const handleError = (err: any) => {
      setTimeout(() => reject(err));
    };

    const handleGetPictureResult = async (file: any) => {
      try {
        resolve({
          action: 'takePhoto',
          files: await cameraFilePathsToFiles([file]),
        });
      } catch (err) {
        reject(err);
      }
    };

    const handleVideoCaptureResult = async (mediaFiles: any) => {
      try {
        resolve({
          action: 'takeVideo',
          files: await cameraFilePathsToFiles(
            mediaFiles.map((mediaFile: any) => mediaFile.fullPath),
          ),
        });
      } catch (err) {
        reject(err);
      }
    };

    const handleGetPictureError = (err: any) => {
      setTimeout(() => reject(err));
    };

    const handleImagePickerResult = (files: any) => {
      setTimeout(async () => {
        try {
          resolve({
            action: 'fromLibrary',
            files: await cameraFilePathsToFiles(files),
          });
        } catch (err) {
          reject(err);
        }
      });
    };

    const cameraActionSelect = () => {
      const cameraOptions = {
        sourceType: Camera.PictureSourceType.CAMERA,
        encodingType: Camera.EncodingType.JPEG,
        destinationType: Camera.DestinationType.FILE_URI,
        mediaType: Camera.MediaType.PICTURE,
        correctOrientation: true,
      };

      camera.getPicture(
        (file: any) => setTimeout(() => handleGetPictureResult(file), 0),
        handleGetPictureError,
        cameraOptions,
      );
    };

    const videoActionSelect = async () => {
      try {
        await requestCameraPermission();
      } catch (err) {
        reject(err);
        return;
      }

      const captureOptions = {
        limit: 1,
      };

      capture.captureVideo(
        (mediaFiles: any) =>
          setTimeout(() => handleVideoCaptureResult(mediaFiles), 0),
        handleGetPictureError,
        captureOptions,
      );
    };

    const photoLibrarySelect = () => {
      const cameraOptions = {
        sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
        encodingType: Camera.EncodingType.JPEG,
        destinationType: Camera.DestinationType.FILE_URI,
        mediaType: Camera.MediaType.PICTURE,
        correctOrientation: true,
      };

      camera.getPicture(
        (file: any) => setTimeout(() => handleImagePickerResult(file), 0),
        handleGetPictureError,
        cameraOptions,
      );
    };

    const actionSheetHandler = (buttonIndex: number) => {
      if (buttonIndex === buttonOptions.length + 1) {
        resolve({ action: 'cancelled', files: [] });
        return;
      }

      switch (buttonOptions[buttonIndex - 1]) {
        case TAKE_PHOTO_LABEL:
          cameraActionSelect();
          break;
        case TAKE_VIDEO_LABEL:
          videoActionSelect();
          break;
        case PHOTO_LIBRAY_LABEL:
          if (!options.ignoreFromLibrarySelection) {
            photoLibrarySelect();
          } else {
            resolve({ action: 'fromLibrary', files: [] });
          }
          break;
        default:
          reject(new Error('Unhandled camera action index: ' + buttonIndex));
      }
    };

    actionSheetPlugin.show(actionSheetConfig, (index: number) =>
      setTimeout(() => actionSheetHandler(index)),
    );
  });
};

const cameraFilePathsToFiles = async (filePaths: string[]): Promise<File[]> => {
  if (!(window as any).resolveLocalFileSystemURL) {
    throw new Error(`Missing 'window.resolveLocalFileSystemURL'`);
  }

  const entries = await Promise.all(
    filePaths.map(filepath => {
      return new Promise<any>((resolve, reject) => {
        (window as any).resolveLocalFileSystemURL(filepath, resolve, reject);
      });
    }),
  );

  const fileEntries = entries.filter(entry => entry.isFile);

  const files = await Promise.all(
    fileEntries.map(async fileEntry => {
      return new Promise<File>((resolve, reject) => {
        fileEntry.file(resolve, reject);
      });
    }),
  );

  const blobs = await Promise.all(
    files.map(async file => {
      const buffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = err => reject(err);
        reader.readAsArrayBuffer(file);
      });
      const blob = new Blob([buffer], { type: file.type });
      (blob as any).name = file.name;
      (blob as any).lastModified = file.lastModified;
      return blob as File;
    }),
  );
  return blobs;
};
