import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { FormErrorMessages } from '../constants';

@Pipe({ name: 'makeInputErrorMessage' })
export class MakeInputErrorMessagePipe implements PipeTransform {
  transform(error: ValidationErrors | null): any {
    if (!error) {
      return;
    }

    const [errorType, errorData] = Object.entries(error)[0];
    const errorMessage = FormErrorMessages[errorType];
    const regexPattern = new RegExp(/{([^}]+)}/);

    let updatedMessage = '';
    if (errorMessage) {
      updatedMessage = errorMessage.slice(0);
      for (const key in errorData) {
        if (Object.prototype.hasOwnProperty.call(errorData, key)) {
          updatedMessage = updatedMessage.replace(regexPattern, errorData[key]);
        }
      }
    } else if (typeof errorData === 'string') {
      // return custom validation error message
      updatedMessage = errorData;
    }

    return updatedMessage;
  }
}
