<section>
  <div class="form-content">
    <div class="inputs-wrap">
      <mg-form-text-input
        *ngIf="(activeSelector$ | async) === 'checkin'"
        labelBackground="grey"
        [id]="'checkin-assign-click-note'"
        [control]="formGroup.get(FORM_FIELDS.NOTE)"
        [label]="ASSIGNMENT_LABELS.NOTE_LABEL">
      </mg-form-text-input>
    </div>
  </div>
</section>
