import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Group } from '../models/Group';

export interface GroupCollectionState extends EntityState<Group> {
  loaded: boolean;
  loading: boolean;
  selectedGroupId: string | null;
  expires: number;
}

export namespace GroupCollectionState {
  export const entities: EntityAdapter<Group> = createEntityAdapter<Group>({
    selectId: (group: Group) => group.hash,
    sortComparer: (group1: Group, group2: Group) =>
      group1.name.localeCompare(group2.name),
  });

  export const initialState: GroupCollectionState = entities.getInitialState({
    selectedGroupId: null,
    loaded: false,
    loading: false,
    expires: 0,
  });
}
