import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MgPollShortCardElement } from 'minga/app/src/app/elements/MgShortCard/MgPollShortCard';
import { Group } from 'minga/app/src/app/groups/models';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { PollAnswerStateService } from 'minga/app/src/app/minimal/services/PollAnswerState';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { LongPollCardView } from 'minga/proto/gateway/content_views_pb';

import { LongCardBaseClass } from '../LongCardBase/LongCardBaseClass';

@Component({
  selector: 'mg-poll-long-card',
  templateUrl: './PollLongCard.component.html',
  styleUrls: ['./PollLongCard.component.scss'],
})
export class PollLongCardComponent extends LongCardBaseClass implements OnInit {
  @ViewChild('pollShortCard', { static: true })
  pollShortCard?: MgPollShortCardElement;

  content?: LongPollCardView.AsObject;

  @Input()
  ignorePermissions: boolean = false;

  constructor(
    public pollAnswerService: PollAnswerStateService,
    public contentEvents: ContentEvents,
    groupFacade: GroupsFacadeService,
    contentState: ContentState,
    router: Router,
  ) {
    super(contentEvents, groupFacade, contentState, router);
  }
  ngOnInit(): void {
    if (this.content && this.content.ownerGroupHash) {
      this.ownerGroupHash.next(this.content.ownerGroupHash);
    }

    if (this.content.hasResponded != -1) {
      this.pollAnswerService.setAnswer(
        this.contextHash,
        this.content.hasResponded,
      );
    }
  }

  ngAfterViewInit() {
    this.pollShortCard._applyCurrentAnswerState();
  }

  isOwner(group: Group) {
    return Group.isOwner(group);
  }
}
