export enum ManagerReportDataTableMessages {
  TABLE_HEADING_ROLE = 'Role',
  TABLE_HEADING_PASS_TYPE = 'Pass Type',
  TABLE_HEADING_NAME = 'Assigned To',
  TABLE_HEADING_ASSIGNED_BY_NAME = 'Name',
  TABLE_HEADING_STUDENT_ID = 'ID Number',
  TABLE_HEADING_GRADE = 'Grade',
  TABLE_HEADING_PASS_COUNT = '# of Passes',
  TABLE_HEADING_PASS_TIME = 'Total Time',
  TABLE_HEADING_SUMMARY = 'Summary',
  TABLE_HEADING_HALL_PASS_HISTORY = 'History',
  TABLE_HEADING_ENDED_BY = 'Ended By',

  TABLE_HEADING_PBIS_HISTORY = 'History',
  TABLE_HEADING_PBIS_SUMMARY = 'Summary',
  TABLE_HEADING_PBIS_PRAISE_COUNT = '# of Praise',
  TABLE_HEADING_PBIS_GUIDANCE_COUNT = '# of Guidance',
  TABLE_HEADING_PBIS_CONSEQUENCE_COUNT = '# of Consequence',
  TABLE_HEADING_PBIS_CONSEQUENCE_OVERDUE_COUNT = '# of Overdue Consequence',
  TABLE_HEADING_BEHAVIOR_NAME = 'Behavior',
  TABLE_HEADING_BEHAVIOR_CATEGORY = 'Category',
  TABLE_HEADING_BEHAVIOR_TOTAL = 'Total Given',

  TABLE_HEADING_ASSIGNED_BY = 'Assigned By',
  TABLE_HEADING_TIME = 'Time',
  TABLE_HEADING_DATE = 'Date',
  TABLE_HEADING_START_DATE = 'Start Date',
  TABLE_HEADING_END_DATE = 'End Date',
  TABLE_HEADING_DELETE = 'Delete',

  NO_RESULTS_TITLE = 'No results found',
  NO_RESULTS_SUBTITLE = 'Try changing your search or filtering and try again.',

  TABLE_HEADING_POINTS = 'Points',
  TABLE_HEADING_POINTS_REASON = 'Reason',

  TABLE_HEADING_CONSEQUENCE_NAME = 'Consequence',
  TABLE_HEADING_ASSIGNED_AT = 'Assigned At',
  TABLE_HEADING_ASSIGNED_TO = 'Assigned To',
  TABLE_HEADING_COMPLETE_BY = 'Complete By',
  TABLE_HEADING_COMPLETED_AT = 'Completed At',
  TABLE_HEADING_STATUS = 'Status',
  TABLE_HEADING_COMPLETE = 'Complete',
  TABLE_HEADING_OUTSTANDING = 'Outstanding',
  TABLE_HEADING_ASSIGNED = 'Assigned',
  TABLE_HEADING_SERVED = 'Served',
  TABLE_HEADING_CATEGORY_TYPE = 'Type',
}
