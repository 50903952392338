<div class="toggle-container">
  <div
    class="toggle-button"
    [class.active-button]="active"
    [class.unselected-button-hover]="!active"
    (click)="!active && onToggleStateClick()">
    <p>{{ activeText }}</p>
  </div>
  <div
    class="toggle-button"
    [class.inactive-button]="!active"
    [class.unselected-button-hover]="active"
    (click)="active && onToggleStateClick()">
    <p>{{ inactiveText }}</p>
  </div>
</div>
