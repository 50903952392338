import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Get the current value from an observable
 * @param obs
 */
export async function obsValueToPromise<T>(obs: Observable<T>): Promise<T> {
  return obs.pipe(take(1)).toPromise();
}
