<div
  class="mg-video-thumbnail video-player-container"
  [ngClass]="{
    'custom-thumbnail':
      videoThumbnailFile || (videoThumbnail && videoThumbnail.custom),
    'no-thumbnail': !videoThumbnail,
    'upload-complete': videoThumbnailFileUploaded,
    playing: videoPlaying
  }"
  (click)="playVideo($event)">
  <div
    class="video-player"
    #youtubePlayer></div>
  <div
    class="cloudflare-container"
    *ngIf="videoData?.cloudflareUid"
    [style.height]="
      videoData?.cloudflareReady
        ? 'auto'
        : (videoThumbnail.height || videoData.height) + 'px'
    ">
    <cloudflare-stream
      *ngIf="videoData?.cloudflareReady"
      [controls]="true"
      [autoplay]="autoplay || videoPlaying"
      [src]="videoData.cloudflareUid"
      [height]="videoThumbnail.height"
      preload="auto"></cloudflare-stream>
  </div>
  <div
    *ngIf="
      !!videoThumbnail &&
      (!videoData?.cloudflareUid ||
        (videoData?.cloudflareUid && !videoData?.cloudflareReady))
    "
    class="thumbnail-container"
    [class.processing]="videoData?.cloudflareUid && !videoData?.cloudflareReady"
    [class.not-playing-cloudflare]="
      videoData?.cloudflareUid && !autoplay && !videoPlaying
    "
    #thumbnailElement>
    <div
      class="video-thumbnail"
      [ngStyle]="{
        'background-image': 'url(' + videoThumbnail.url + ')',
        height: (videoThumbnail.height || videoData.height) + 'px'
      }">
      <div class="processing-container">
        <mg-spinner
          color="primary"
          thickness="4"
          diameter="25">
        </mg-spinner>
        <p>{{ processingText }}</p>
      </div>
    </div>
    <mg-video-player-icon></mg-video-player-icon>

    <div class="cancel-thumbnail-container">
      <mg-button-icon
        class="cancel-thumbnail"
        icon="close"
        (click)="cancelThumbnail($event)"></mg-button-icon>
    </div>
  </div>
</div>
