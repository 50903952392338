<section class="kiosk">
  <div class="kiosk-content">
    <img
      class="logo"
      src="assets/logo/m-logo.svg"
      alt="Minga Logo" />

    <div class="kiosk-form">
      <form
        id="kiosk-login"
        [formGroup]="form"
        (ngSubmit)="onSubmit()">
        <div
          fxLayout="column"
          fxLayoutAlign="start center"
          fxLayoutGap="24px">
          <fieldset>
            <img
              class="kiosk-logo"
              src="assets/logo/kiosk-logo.svg"
              alt="Kiosk Logo" />
          </fieldset>
          <fieldset *ngIf="error$ | async">
            <mg-system-alert
              *ngIf="error$ | async"
              type="error"
              [message]="error$ | async">
            </mg-system-alert>
          </fieldset>
          <fieldset
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mg-text
              variant="body-sm"
              textAlign="center"
              >{{ MESSAGES.TITLE }}</mg-text
            >
            <mg-form-text-input
              [label]="MESSAGES.EMAIL_LABEL"
              inputType="email"
              [control]="form.get(FORM_FIELDS.EMAIL)"></mg-form-text-input>
            <div>
              <mg-form-text-input
                [label]="MESSAGES.PIN_LABEL"
                inputType="password"
                autoComplete="new-password"
                [control]="form.get(FORM_FIELDS.PIN)"></mg-form-text-input>
            </div>

            <div>
              <mg-btn
                attrFormId="kiosk-login"
                attrType="submit"
                variant="outlined"
                [id]="'landing-kiosk-login-btn'"
                [wide]="true"
                [disabled]="form.invalid">
                {{ MESSAGES.SIGN_IN_BTN }}
              </mg-btn>
            </div>
          </fieldset>
          <fieldset>
            <mg-btn
              [routerLink]="['/', LANDING_ROUTE.ROOT, LANDING_ROUTE.EMAIL]"
              variant="text"
              iconSet="minga"
              icon="mg-arrow-left-alt">
              {{ MESSAGES.BACK_BTN }}
            </mg-btn>
          </fieldset>
        </div>
      </form>
      <p class="kiosk-terms">
        By accessing and using the Minga Services, you agree to the
        <a [routerLink]="['', { outlets: { o: ['page', 'tos'] } }]">
          Terms of Service
        </a>
        &
        <a [routerLink]="['', { outlets: { o: ['page', 'privacy-policy'] } }]">
          Privacy Policy
        </a>
      </p>
    </div>
  </div>
</section>
