<ng-container *ngIf="showTopNav">
  <div *mgOverlayStructure="true; region: 'top'">
    <mg-overlay-nav-item
      class="mg-align-left"
      icon="back"
      (click)="onClose()">
      Close
    </mg-overlay-nav-item>
  </div>
</ng-container>

<mg-sms-message-short-card
  [message]="message"
  [authorName]="authorName"
  [publishDate]="publishDate">
</mg-sms-message-short-card>
