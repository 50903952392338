import { Input, Directive } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Group } from 'minga/app/src/app/groups/models';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { ImageInfo } from 'minga/proto/image/image_pb';
import { day } from 'minga/shared/day';

@Directive()
export abstract class LongCardBaseClass {
  ownerGroupHash: BehaviorSubject<string> = new BehaviorSubject('');
  changesSubject: Subject<any> = new Subject();
  group$: Observable<Group>;

  constructor(
    protected contentEvents: ContentEvents,
    protected groupFacade: GroupsFacadeService,
    protected contentState: ContentState,
    protected router: Router,
  ) {
    this.group$ = this.ownerGroupHash.asObservable().pipe(
      switchMap(groupHash => {
        return this.groupFacade.getGroup(groupHash);
      }),
    );
  }

  /**
   * If only the contextHash is supplied the entire long card will wait until
   * it is loaded from the server before displaying.
   */
  @Input()
  contextHash?: string;

  /**
   * Used for editing.
   */
  @Input()
  contentHash?: string;

  /**
   * Used to disable comments and likes
   */
  @Input()
  disableActions: boolean = false;

  /**
   * The long card view. This will get populated by contextHash if available
   * and will be partially filled in by shortCardContent if available. It can
   * also be set directly here.
   */
  @Input()
  content?: any; // @TODO: Set this type to a common base content type

  humanize(timestamp: number | string) {
    let now = day();

    if (typeof timestamp == 'string') {
      timestamp = parseInt(timestamp);
    }

    return day(timestamp).fromNow();
  }

  getImageUrl(image: ImageInfo.AsObject, keys: string[] = ['banner']): string {
    const FALLBACK_KEY = 'raw';
    let sizesMap: any = {};
    image.sizeMap.forEach(entry => {
      sizesMap[entry[0]] = entry[1];
    });

    let imageSize = null;
    for (let key of keys.concat([FALLBACK_KEY])) {
      imageSize = sizesMap[key];
      if (imageSize) {
        break;
      }
    }

    let imageUrl = window.MINGA_ASSET_URL_PREFIX + imageSize.path || '';
    return imageUrl;
  }

  groupLinkClick(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();
    if (this.ownerGroupHash) {
      this.router
        .navigate(['', { outlets: { o: null } }])
        .then(() => this.contentState.openGroup(this.ownerGroupHash.value));
    }
  }
}
