import { KioskActionConfigMap, KioskCategoryOption } from '../types';

export enum KioskRoute {
  ROOT = 'kiosk',
  HALL_PASS = 'hall-pass',
  CHECK_IN = 'check-in',

  TYPE = ':type',

  SETUP = 'setup',
  ASSIGN = 'assign',
}

export enum KioskCategoryMessage {
  HALLPASS = 'hall-pass',
  CHECKIN = 'check-in',
  FLEXTIME = 'flextime',
}

export enum KioskMessage {
  CATEGORY_SELECTION_TITLE = 'Kiosk set up',
  CATEGORY_SELECTION_SUBTITLE = 'Select a kiosk type',

  SYSTEM_ALERT_MODAL_LOGOUT_ERROR_TITLE = 'PIN not valid, try again',

  SYSTEM_ALERT_MODAL_LOGOUT_ERROR_SUBTITLE = 'You can access your PIN in "My tools"',

  SYSTEM_ALERT_MODAL_PIN_SUCCESS_TITLE = 'Your PIN was sent to your email',

  SYSTEM_ALERT_MODAL_EMAIL_PIN = 'Request PIN to Email',

  NO_TYPES_TITLE = 'Kiosk is disabled',
  NO_TYPES_SUB_TITLE = 'Kiosk must be enabled in hall pass or check in settings',

  EMAIL_LABEL = 'Email',
  PIN_LABEL = 'PIN',

  LOGOUT = 'Log out',
  EDIT = 'Edit kiosk',
  RESET = 'Reset kiosk',
}

export enum KioskModalMessage {
  ENTER_TITLE = 'Enter kiosk mode',
  ENTER_BODY = "You'll be logged out of your account and will automatically log in to your Kiosk account",
  LOGIN_TITLE = 'Login credential',

  LOGIN_EMAIL_LABEL = 'Email',
  LOGIN_PIN_LABEL = 'PIN',

  ENTER_KIOSK_LABEL = 'Enter kiosk mode',
  LOGIN_GENERATE_PIN_LABEL = 'Generate new PIN',
}

export enum KioskLayoutPortalSection {
  FOOTER = 'footer',
}

export const KIOSK_SUMMARY_AUTO_RESET_TIMEOUT = 15000; // in miliseconds

export enum KioskDisclaimerMessage {
  WARNING_TITLE = 'WARNING',
  DEVICE_SECURITY = 'Device Security. Enabling Kiosk Mode through Minga DOES NOT configure or secure the device to prevent users from accessing other systems, settings, or applications. Please refer to documentation for your device to restrict students from opening other browser tabs or accessing system settings or other applications.',
  PHYSICAL_SECURITY = 'Physical Security. Position the kiosk in a secure location that can be monitored and maintained by an authorized staff member.',
  USER_TRAINING = 'User Training. Educate staff and students on the proper use of a Minga Kiosk and how to properly report problems or issues.',
  DATA_PRIVACY = 'Data Privacy. Ensure students or staff keep data private such as student ID numbers or other sensitive information and do not share it.',

  DISCLAIMER_TITLE = 'DISCLAIMER',
  DISCLAIMER_BODY = `By enabling Kiosk Mode you agree to hold Minga Solutions Inc, its subsidiaries and employees harmless for any and all risks or harm associated.`,

  CANCEL_BUTTON = 'Cancel',
  ACCEPT_BUTTON = 'Agree and enable',
}

export const KIOSK_OPTIONS: {
  [key in KioskCategoryMessage]?: KioskCategoryOption;
} = {
  [KioskCategoryMessage.HALLPASS]: {
    label: 'Hall Pass',
    value: KioskCategoryMessage.HALLPASS,
    icon: 'mg-hallpass-menu-o',
  },
  [KioskCategoryMessage.CHECKIN]: {
    label: 'Check in',
    value: KioskCategoryMessage.CHECKIN,
    icon: 'mg-checkin-menu-o',
  },
};

export enum KioskMenuAction {
  LOGOUT = 'logout',
  EDIT = 'edit',
  RESET = 'reset',
}

export const KIOSK_ACTION_CONFIG: KioskActionConfigMap = {
  [KioskMenuAction.LOGOUT]: {
    heading: 'Enter your PIN to exit kiosk mode',
    confirmActionBtn: 'Sign out',
  },
  [KioskMenuAction.EDIT]: {
    heading: 'Enter your PIN to edit your kiosk settings',
    confirmActionBtn: 'Continue',
  },
  [KioskMenuAction.RESET]: {
    heading: 'Enter your PIN to reset your kiosk',
    confirmActionBtn: 'Reset',
  },
};
