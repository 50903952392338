import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';
import {
  MatCalendar,
  MatCalendarHeader,
  MatCalendarView,
  MatDatepickerIntl,
  MatDatepickerModule,
} from '@angular/material/datepicker';

import { day } from 'minga/shared/day';

import { Calendar } from './Calendar.component';

@Component({
  moduleId: MatDatepickerModule.name,
  selector: 'mg-calendar-header',
  templateUrl: './CustomCalendarHeader.component.html',
  styleUrls: ['./CustomCalendarHeader.component.scss'],
  exportAs: 'matCalendarHeader',
  host: {
    class: 'mat-calendar-header',
    role: 'grid',
    'aria-readonly': 'true',
  },
})
export class CustomCalendarHeader<D> extends MatCalendarHeader<D> {
  @Input()
  currentView?: MatCalendarView;

  constructor(
    _intl: MatDatepickerIntl,
    public calendar: Calendar<D>,
    @Optional() _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) _dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(_intl, calendar, _dateAdapter, _dateFormats, changeDetectorRef);
  }

  // Return custom formatted header date text
  get periodButtonText(): string {
    if (this.currentView == 'month') {
      const newDateFormatText = day(this.calendar.activeDate as any).format(
        'MMMM YYYY',
      );

      if (newDateFormatText) {
        return newDateFormatText;
      }
    }
    return super.periodButtonText;
  }
}
