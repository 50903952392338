import { Validators } from '@angular/forms';

export enum MingaProfileRoute {
  ROOT = 'minga-profile',
}
export enum MingaProfileMessage {
  MEMBERS = 'Members',
  MINGA_ADMIN = 'Minga Administrator',
  SUBSCRIPTION = 'Subscription',
  SUB_START = 'Start:',
  SUB_END = 'End:',

  MINGA_NAME_INPUT_LABEL = 'Name',
  MINGA_ADDRESS_INPUT_LABEL = 'Address',

  ADD_PHONE_LABEL = 'Add phone',
  ADD_WEBSITE_LABEL = 'Add website',
  ADD_EMAIL_LABEL = 'Add email',

  ADD_NEW_LINK_BUTTON_LABEL = 'Add new link',

  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_SAVE = 'Save',
}

export enum MingaProfileFormFields {
  NAME = 'name',
  ADDRESS = 'placeId',
  PHONE = 'phone',
  WEBSITE = 'websiteUrl',
  EMAIL = 'email',
}

export const MINGA_PROFILE_FORM_GROUP = {
  [MingaProfileFormFields.NAME]: ['', Validators.required],
  [MingaProfileFormFields.ADDRESS]: ['', Validators.required],
  [MingaProfileFormFields.PHONE]: [''],
  [MingaProfileFormFields.WEBSITE]: [''],
  [MingaProfileFormFields.EMAIL]: [''],
};
