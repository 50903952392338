import {
  Directive,
  Host,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { IStreamSwipeStackLoading } from './IStreamSwipeStackItem';
import { StreamSwipeStackComponent } from './StreamSwipeStack.component';

@Directive({
  selector: '[mgStreamSwipeStackLoading]',
})
export class StreamSwipeStackLoadingDirective
  implements IStreamSwipeStackLoading, OnDestroy
{
  @Input()
  mgStreamSwipeStackLoading: any;

  constructor(
    @Host() private streamSwipeStack: StreamSwipeStackComponent<any>,
    public templateRef: TemplateRef<any>,
    public viewContainer: ViewContainerRef,
  ) {
    this.streamSwipeStack.registerLoading(this);
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.streamSwipeStack.unregisterLoading(this);
  }
}
