import { Observable, Subject } from 'rxjs';

import { MENTION_CHAR } from '../../constants';

import {
  IMention,
  IMentionSearchChangeEvent,
  IMentionSearchCommitEvent,
  IMentionSearchEndEvent,
  IMentionSearchStartEvent,
  MentionStrategy,
} from './MentionStrategy';

export class TextAreaMentionStrategy implements MentionStrategy {
  private element: HTMLTextAreaElement;
  private _mentionActive: boolean = false;
  private _mentionCharIndex: number = -1;
  private _onKeydown: (e: KeyboardEvent) => void;
  private _onKeypress: (e: KeyboardEvent) => void;
  private _onKeyup: (e: KeyboardEvent) => void;
  private _onInput: (e: Event) => void;
  private _mentions: IMention[] = [];

  private _mentionSearchStartSubject: Subject<IMentionSearchStartEvent>;
  private _mentionSearchEndSubject: Subject<IMentionSearchEndEvent>;
  private _mentionSearchCommitSubject: Subject<IMentionSearchCommitEvent>;
  private _mentionSearchChangeSubject: Subject<IMentionSearchChangeEvent>;
  private _mentionSearch: string = '';

  get onMentionSearchStart(): Observable<IMentionSearchStartEvent> {
    return this._mentionSearchStartSubject.asObservable();
  }

  get onMentionSearchEnd(): Observable<IMentionSearchEndEvent> {
    return this._mentionSearchEndSubject.asObservable();
  }

  get onMentionSearchCommit(): Observable<IMentionSearchCommitEvent> {
    return this._mentionSearchCommitSubject.asObservable();
  }

  get onMentionSearchChange(): Observable<IMentionSearchChangeEvent> {
    return this._mentionSearchChangeSubject.asObservable();
  }

  get searchStarted(): boolean {
    return this._mentionActive;
  }

  constructor() {
    this._mentionSearchStartSubject = new Subject();
    this._mentionSearchEndSubject = new Subject();
    this._mentionSearchCommitSubject = new Subject();
    this._mentionSearchChangeSubject = new Subject();

    this._onKeydown = e => this.onKeydown(e);
    this._onKeypress = e => this.onKeypress(e);
    this._onKeyup = e => this.onKeyup(e);
    this._onInput = e => this.onInput(e);
  }

  private _startMention() {
    this._mentionActive = true;
    this._mentionCharIndex = this.element.selectionStart || 0;
    this._mentionSearchStartSubject.next({});
  }

  private _endMention() {
    this._mentionActive = false;
    this._mentionSearch = '';
    this._mentionCharIndex = -1;
    this._mentionSearchEndSubject.next({});
  }

  getMentionSearchPos() {
    const pos = { x: 0, y: 0 };

    return pos;
  }

  private getMentionSearchString() {
    let mentionSearch = this.element.value.substr(this._mentionCharIndex);

    return mentionSearch;
  }

  private onKeyup(e) {}

  private onInput(e: Event) {
    if (!this._mentionActive) {
      return;
    }

    const mentionSearch = this.getMentionSearchString();

    if (!mentionSearch) {
      this._endMention();
    } else if (this._mentionSearch != mentionSearch) {
      this._mentionSearch = mentionSearch;
      this._mentionSearchChangeSubject.next({
        searchString: mentionSearch.substr(MENTION_CHAR.length),
      });
    }
  }

  private onKeydown(e: KeyboardEvent) {
    if (this._mentionActive) {
      if (e.key === 'Escape' || e.key === ' ') {
        this._endMention();
      }

      if (e.key === 'Enter') {
        e.preventDefault();
        this.commitMentionSearch();
      }
    }

    if (!this._mentionActive && e.key === MENTION_CHAR) {
      this._startMention();
    }
  }

  private onKeypress(e: KeyboardEvent) {}

  private _initListeners() {
    this.element.addEventListener('input', this._onInput, false);
    this.element.addEventListener('keyup', this._onKeyup, false);
    this.element.addEventListener('keydown', this._onKeydown, false);
    this.element.addEventListener('keypress', this._onKeypress, false);
  }

  connect(targetElement: HTMLElement) {
    if (!(targetElement instanceof HTMLTextAreaElement)) {
      throw new Error('Element is not a textarea element');
    }

    if (this.element) {
      this.disconnect();
    }

    this.element = targetElement;
    this._initListeners();

    throw new Error('@TODO: TextArea mention strategy');
  }

  disconnect() {
    if (this.element) {
      this.element.removeEventListener('input', this._onInput);
      this.element.removeEventListener('keyup', this._onKeyup);
      this.element.removeEventListener('keydown', this._onKeydown);
      this.element.removeEventListener('keypress', this._onKeypress);
    }
  }

  collapseSelectionAfterMention(mentionIndex: number) {}

  collapseSelectionBeforeMention(mentionIndex: number) {}

  commitMentionSearch() {
    if (!this.searchStarted) {
      throw new Error(
        'Text area mention strategy search was not started on commitMentionSearch',
      );
    }

    this._endMention();
  }

  getMentions() {
    return [].concat(this._mentions);
  }

  removeMention(mentionIndex: number) {}

  selectionEndAfterMention(mentionIndex: number) {}

  selectionEndBeforeMention(mentionIndex: number) {}

  selectionStartAfterMention(mentionIndex: number) {}

  selectionStartBeforeMention(mentionIndex: number) {}
}
