import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';

import { MgPersonMinimalElement } from './MgPersonMinimal.element';

@NgModule({
  imports: [CommonModule],

  declarations: [MgPersonMinimalElement],
  exports: [MgPersonMinimalElement],
})
export class MgPersonMinimalModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-person-minimal')) {
      //       //   MgPersonMinimalElement,
      //   {injector},
      // ));
    }
  }
}
