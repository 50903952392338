<div
  class="mg-avatar"
  [ngClass]="{
    overflow: overflow,
    square: square,
    borderless: borderless
  }">
  <div class="avatar">
    <ng-content></ng-content>
    <div
      *ngIf="!contentChild"
      class="avatar-img"
      [style.background-image]="'url(' + src + ')'"></div>
  </div>
  <div class="avatar placeholder"><div class="avatar-img"></div></div>
</div>
