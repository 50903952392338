export enum HallpassManagerMessages {
  HEADER_TITLE = 'Hall Pass Manager',
}

export enum HpmRoutes {
  ROOT = 'hall-pass',
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  TYPES = 'types',
  REPORTS = 'reports',
  RESTRICTIONS = 'restrictions',
}
