import { Component, Input, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';

import { StickerBannersService } from '@modules/id/components/id-stickers/services';
import { ToolsMessages } from '@modules/tools/tools.constants';

import { CarouselConfig } from '@shared/components/carousel/carousel/carousel';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MediaService } from '@shared/services/media';
import { StickersService } from '@shared/services/stickers';

@Component({
  selector: 'mg-tools-stickers',
  templateUrl: './tools-stickers.component.html',
  styleUrls: ['./tools-stickers.component.scss'],
})
export class ToolsStickersComponent implements OnInit {
  public MESSAGES = ToolsMessages;
  public carouselConfig: CarouselConfig = {
    grid: { xs: 4, sm: 5, md: 6, lg: 6, xl: 8, all: 0 },
    showNavigation: true,
    showPagination: false,
  };

  @Input()
  personHash: string;

  private _stickersSubject = new BehaviorSubject<IMembershipList[]>([]);
  public readonly stickers$ = this._stickersSubject.asObservable();
  private _loading = new BehaviorSubject(false);
  public loading$ = this._loading.asObservable();

  constructor(
    private _stickerBannerService: StickerBannersService,
    private _stickerService: StickersService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    public mediaService: MediaService,
  ) {}
  ngOnInit(): void {
    this._fetchStickers();
  }

  public getStickerPath(item: IMembershipList) {
    return this._stickerBannerService.getStickerPath(item);
  }

  private async _fetchStickers() {
    try {
      if (!this.personHash) {
        return;
      }

      this._loading.next(true);
      const response = await this._stickerService.getStickersByPerson(
        this.personHash,
      );
      response.sort((a, b) =>
        a.listType === MembershipListType.NO_ACCESS
          ? -1
          : b.listType === MembershipListType.NO_ACCESS
          ? 1
          : 0,
      );
      this._stickersSubject.next(response);
    } catch (e) {
      this._systemAlertSnackBar.error(this.MESSAGES.ERROR_LOADING_STICKERS);
    } finally {
      this._loading.next(false);
    }
  }
}
