import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IPointsRedeemedResult } from 'minga/domain/points';
import { MingaPermission } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';

import { PmReportsService } from '../../services';
import { PmReportsRedemptionHistoryDatasourceService } from '../../services/pm-redemption-history-datasource.service';
import { PmReportsHistoryActionService } from '../../services/pm-reports-history-action.service';
import { PmReportsRedemptionHistoryMessages } from './pm-reports-redemption-history.constants';

@Component({
  selector: 'mg-pm-reports-redemption-history',
  templateUrl: './pm-reports-redemption-history.component.html',
  styleUrls: [
    '../pm-reports.styles.scss',
    './pm-reports-redemption-history.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PmReportsRedemptionHistoryDatasourceService,
    PmReportsHistoryActionService,
  ],
})
export class PmReportsRedemptionHistoryComponent implements OnInit, OnDestroy {
  public readonly MESSAGES = PmReportsRedemptionHistoryMessages;
  public readonly canMultiSelect$ = this._permissions.observePermission(
    MingaPermission.POINTS_MANAGE,
  );

  private _destroyedSubject = new ReplaySubject<void>(1);
  private _selectableItems: IPointsRedeemedResult[] = [];

  constructor(
    public ds: PmReportsRedemptionHistoryDatasourceService,
    public actionService: PmReportsHistoryActionService,
    private _permissions: PermissionsService,
    private _pmReportService: PmReportsService,
  ) {}

  ngOnInit(): void {
    this.ds.items$.pipe(takeUntil(this._destroyedSubject)).subscribe(items => {
      this.actionService.clearSelection();
      this.actionService.setTotalSelectable(items.length);
      this._selectableItems = items;
    });

    this._pmReportService.filter$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => this.actionService.clearSelection());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public selectAllTableDataRows() {
    this.actionService.masterToggle(this._selectableItems);
  }
}
