<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="isLoading$ | async"
  [overlayTitle]="modalTitle$ | async">
  <ng-container>
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  </ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <!-- Asset -->
    <div
      fxLayout="row"
      fxLayoutAlign="center center">
      <div class="asset-container">
        <mg-file-input-upload
          #fileInputUpload
          accept="image/*"
          customTitleText="Upload A Photo"
          previewAspectRatio="1:1"
          name="bannerUpload"
          [fallback]="assetPath"
          [class.file-uploaded]="!!assetPath"
          [showFileDetails]="false"
          [radius]="true"
          [(ngModel)]="assetPath"
          (ngModelChange)="changeAsset($event)">
        </mg-file-input-upload>
      </div>
    </div>
    <!-- Name Field -->
    <mg-form-text-input
      label="{{ MESSAGES.FIELD_LABEL_NAME }}"
      [control]="form.get(FORM_FIELD.NAME)">
    </mg-form-text-input>
    <!-- Color Field -->
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <mg-text variant="body-md">
        {{ MESSAGES.FIELD_LABEL_COLOR }}
      </mg-text>
      <mg-form-text-input
        inputType="color"
        widthSize="large"
        [condensed]="true"
        [control]="form.get(FORM_FIELD.COLOR)">
      </mg-form-text-input>
    </div>
    <!-- Membership Administrator -->
    <div *ngIf="(isNew$ | async) === false">
      <mg-membership-list-table
        [hideHeader]="true"
        [lists]="membershipLists$ | async">
      </mg-membership-list-table>
    </div>
  </div>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [variant]="(isNew$ | async) ? 'text' : 'destructive'"
        (pressed)="delete()">
        {{
          (isNew$ | async)
            ? MESSAGES.BUTTON_LABEL_CANCEL
            : MESSAGES.BUTTON_LABEL_DELETE
        }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        variant="filled"
        [disabled]="!canSubmit"
        (pressed)="submit()">
        {{
          (isNew$ | async)
            ? MESSAGES.BUTTON_LABEL_CREATE
            : MESSAGES.BUTTON_LABEL_SAVE
        }}
      </mg-btn>
    </div>
  </div>
</ng-template>
