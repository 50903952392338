import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { IEmptyStateIcon } from 'minga/app/src/app/components/EmptyState';
import {
  IShortCardChallengeClickEvent,
  IShortCardChallengeResponseClickEvent,
} from 'minga/app/src/app/elements/MgShortCard/MgChallengeShortCard';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { PollAnswerStateService } from 'minga/app/src/app/minimal/services/PollAnswerState';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { ContentFeed } from 'minga/proto/gateway/feed_ng_grpc_pb';
import { MingaPermission } from 'minga/util';

import { LayoutService } from '@modules/layout/services';

@Component({
  selector: 'mg-home-challenges',
  templateUrl: './home-challenges.component.html',
  styleUrls: ['./home-challenges.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeChallengesComponent implements OnDestroy {
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  emptyStateIcons: IEmptyStateIcon[] = [];
  canCreateChallenges = false;

  feedFilter: any = {
    contentType: 'CHALLENGE',
  };

  /** Component constructor */
  constructor(
    public layout: LayoutService,
    public pollAnswerService: PollAnswerStateService,
    public contentEvents: ContentEvents,
    public groupsFacade: GroupsFacadeService,
    public contentState: ContentState,
    public contentFeed: ContentFeed,
    private _permissions: PermissionsService,
  ) {
    // load groups for group feed content
    groupsFacade.dispatchLoadAll();

    this.emptyStateIcons = [
      {
        icon: 'post',
        text: 'Add',
      },
      {
        icon: 'challenges-o',
        text: 'Challenge',
      },
    ];

    this.canCreateChallenges = this._permissions.hasPermission(
      MingaPermission.CONTENT_CHALLENGE_CREATE,
    );
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  onChallengeClick(ev: IShortCardChallengeClickEvent) {
    this.contentEvents.emitContentOpen({
      contentContextHash: ev.challengeContextHash,
    });
  }

  onChallengeResponseClick(ev: IShortCardChallengeResponseClickEvent) {
    this.contentEvents.emitContentOpen({
      contentContextHash: ev.responseContextHash,
    });
  }
}
