import { Injectable } from '@angular/core';

import { QRCodeToDataURLOptions, toDataURL } from 'qrcode';

import { QrCodeType } from './qrcode.constants';
import { CheckinReasonQrData, QrData } from './qrcode.types';

@Injectable({ providedIn: 'root' })
export default class QrCodeService {
  /** Service Constructor */
  constructor() {}

  public async createCheckinReasonQrCode(reasonId: number) {
    if (!reasonId) return;
    const qrData: QrData<CheckinReasonQrData> = {
      type: QrCodeType.CHECKIN_REASON,
      data: {
        reasonId,
      },
    };
    const asString = JSON.stringify(qrData);
    const uriData = await this._generateQrCodeAsUrl(asString);
    return uriData;
  }

  public readCheckinReasonQrCode(dataString: string): CheckinReasonQrData {
    const parsedData = JSON.parse(dataString) as QrData<CheckinReasonQrData>;
    if (!parsedData.type) throw new Error('Failed to read QR string');
    return parsedData.data;
  }

  private async _generateQrCodeAsUrl(
    text: string,
    options?: QRCodeToDataURLOptions,
  ): Promise<string> {
    try {
      const result = await toDataURL(text, options);
      return result;
    } catch (err) {
      console.error(err);
    }
  }
}
