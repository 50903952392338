<div
  fxLayout="column"
  class="comment-row"
  #commentRowEl
  (swipeleft)="onSwipeLeft($event, comment.commentContextHash)"
  (swiperight)="onSwipeRight($event, comment.commentContextHash)">
  <div
    class="comment-row-container"
    fxLayout="row"
    [class.deleted]="showDeletedStatus">
    <div
      class="deleted-cover"
      *ngIf="showDeletedStatus">
      DELETED
    </div>
    <div
      class="avatar-wrap"
      *ngIf="!hideAvatar && comment.authorPersonView?.profileImageUrl">
      <mg-avatar
        [src]="comment?.authorPersonView?.profileImageUrl"
        [color]="comment?.authorPersonView?.badgeIconColor"
        [personHash]="comment?.authorPersonView"></mg-avatar>
    </div>

    <div
      class="comment-body"
      fxFlex
      fxLayout="column">
      <div class="comment-content mg-bodycopy">
        <mg-clipped-html
          [fullHtml]="commentBody | mgSafeHtml"
          [alwaysFullHtml]="true">
          <mg-clipped-html-head>
            <mg-person-minimal
              *ngIf="comment?.authorPersonView"
              [person]="comment?.authorPersonView"></mg-person-minimal>
          </mg-clipped-html-head>
          <mg-clipped-html-tail></mg-clipped-html-tail>
        </mg-clipped-html>
      </div>

      <div class="action-row">
        <div class="timestamp">{{ humanize(comment?.timestamp) }}</div>
        <mg-like
          [class.light-content]="lightContent"
          class="action no-before"
          *ngIf="!comment.deleted && !disableActions"
          [count]="comment.likeCount"
          [context]="comment.commentContextHash"
          [content]="comment"></mg-like>
        <div
          *ngIf="!hideReply && !comment.deleted && !disableActions"
          class="action reply"
          (click)="replyClick(getResponseObject())"
          [ngSwitch]="!!replyTextOnly">
          <span
            *ngSwitchCase="true"
            class="reply-text"
            >Reply</span
          >
          <ng-container *ngSwitchDefault>
            <span class="reply-text"
              >Reply
              <ng-container *ngIf="comment?.commentCount > 0">
                ({{ comment?.commentCount }})
              </ng-container>
            </span>
          </ng-container>
        </div>
        <div
          class="action delete"
          [class.align-right]="!isReply"
          (click)="deleteClick()"
          *ngIf="canDelete && !canRipple">
          <span>Delete</span>
        </div>
      </div>
    </div>
  </div>
  <div
    fxFlex
    class="replies-container">
    <div class="replies-list">
      <div
        class="replies-link"
        *ngIf="comments.length > 0 && comment.commentCount <= comments.length"
        (click)="toggleShowingReplies()"
        [ngSwitch]="showComments">
        <span *ngSwitchCase="true"
          >Hide {{ comment.commentCount == 1 ? 'reply' : 'replies' }}</span
        >
        <span *ngSwitchDefault
          >Show {{ comment.commentCount == 1 ? 'reply' : 'replies' }}</span
        >
      </div>
      <mg-spinner
        *ngIf="loadingComments"
        [diameter]="12"
        [thickness]="2"></mg-spinner>
      <div
        class="list-container"
        *ngIf="comments.length"
        [class.hide]="!showComments"
        (panstart)="onPanReplies($event)"
        (pan)="onPanReplies($event)"
        (panend)="onPanRepliesEnd($event)">
        <mg-comment-view
          *ngFor="let _comment of comments; trackBy: _trackByHash"
          isReply="true"
          [hideReply]="hideReply"
          [ngClass]="'anim anim-' + (_comment.anim || 'none')"
          (onReplyClick)="replyClick($event)"
          hideAvatar="true"
          replyTextOnly="true"
          [disableActions]="disableActions"
          [lightContent]="lightContent"
          [parentContextHash]="parentContextHash"
          (onCommentDeleted)="_onContentDeleted($event)"
          [commentList]="_comment.commentList"
          [comment]="_comment.obj"></mg-comment-view>
      </div>
    </div>
  </div>
</div>
<div
  class="swipe-reveal-actions-left"
  #swipeLeftOptions
  [class.mobile]="canRipple"
  matRipple
  [matRippleDisabled]="!canRipple">
  <div
    class="swipe-action report"
    *ngIf="canReport"
    (click)="reportClick($event)">
    <mg-icon>warning-o-active</mg-icon>
  </div>
  <div
    class="swipe-action resolve"
    *ngIf="canResolve"
    (click)="resolveClick($event)">
    <mg-icon>resolved-o-active</mg-icon>
  </div>
  <div
    class="swipe-action delete"
    (click)="deleteClick($event)"
    *ngIf="canDelete">
    <mg-icon>trash-o-active</mg-icon>
  </div>
</div>
<div
  class="swipe-reveal-actions-right"
  #swipeRightOptions
  [class.mobile]="canRipple"
  matRipple
  [matRippleDisabled]="!canRipple">
  <div
    class="swipe-action report"
    *ngIf="canReport"
    (click)="reportClick($event)">
    <mg-icon>warning-o-active</mg-icon>
  </div>
  <div
    class="swipe-action resolve"
    *ngIf="canResolve"
    (click)="resolveClick($event)">
    <mg-icon>resolved-o-active</mg-icon>
  </div>
  <div
    class="swipe-action delete"
    (click)="deleteClick($event)"
    *ngIf="canDelete">
    <mg-icon>trash-o-active</mg-icon>
  </div>
</div>
