import { Component } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ReportTypes } from 'minga/domain/reportTypes';

import {
  BmReportsFilterType,
  BHStudentConsequenceHistoryInfo,
  BHStudentHistoryInfo,
} from '../../constants';
import { BmReportsAutoCounterDatasourceService } from '../../services';
import { BmReportActionService } from '../../services/bm-reports-action.service';
import { BmReportsAutoCounterMessages } from './bm-reports-auto-counter.constants';

@Component({
  selector: 'mg-bm-reports-auto-counter',
  templateUrl: './bm-reports-auto-counter.component.html',
  styleUrls: ['./bm-reports-auto-counter.component.scss'],
})
export class BmReportsAutomationCounterComponent {
  /** Constants */
  public readonly MESSAGES = BmReportsAutoCounterMessages;
  public historyDropDown = [
    {
      label: 'Behavior History',
      ...BHStudentHistoryInfo,
    },
    {
      label: 'Consequence History',
      ...BHStudentConsequenceHistoryInfo,
    },
  ];

  /** Behavior subjects and observables */
  private _destroyedSubject = new ReplaySubject<void>(1);

  constructor(
    public ds: BmReportsAutoCounterDatasourceService,
    public actionService: BmReportActionService,
  ) {
    this.ds.items$.pipe(takeUntil(this._destroyedSubject)).subscribe(items => {
      this.actionService.clearSelection();
      this.actionService.setTotalSelectable(items.length);
    });
  }

  async resetAutomationGroupCounter() {
    const didUpdate = await this.actionService.resetAutomationGroupCounter();
    if (didUpdate) {
      await this.ds.fetchReportData();
    }
  }
}
