import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MgButtonModule } from 'minga/app/src/app/button';
import { FileInputModule } from 'minga/app/src/app/components/Input/FileInput';
import { FileUploadModule } from 'minga/app/src/app/components/Input/FileUpload';
import { MgIconModule } from 'minga/app/src/app/icon';

import { TextModule } from '@shared/components/text';

import { FileInputUploadComponent } from './FileInputUpload.component';

@NgModule({
  imports: [
    // Minga dependencies
    FileUploadModule,
    FileInputModule,
    MgIconModule,
    MgButtonModule,
    TextModule,

    // External dependencies
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [FileInputUploadComponent],
  exports: [FileInputUploadComponent],
})
export class FileInputUploadModule {}
