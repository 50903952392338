import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { HallPassWithType } from 'minga/proto/hall_pass/hall_pass_pb';

@Injectable({ providedIn: 'root' })
export class HallPassEvents {
  private _onHallPassCreateSuccess: Subject<HallPassWithType.AsObject[]>;

  constructor() {
    this._onHallPassCreateSuccess = new Subject();
  }

  get onHallPassCreateSuccess(): Observable<HallPassWithType.AsObject[]> {
    return this._onHallPassCreateSuccess.asObservable();
  }

  emitHallPassCreateSuccess(passes: HallPassWithType.AsObject[]) {
    this._onHallPassCreateSuccess.next(passes);
  }
}
