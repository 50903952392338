import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmationDialogMessages } from './confirmation-dialog.constants';
import {
  ConfirmationDialogData,
  ConfirmationDialogText,
} from './confirmation-dialog.types';

/**
 * Confirmation Dialog
 *
 * General purpsoe dialog component used to confirm actions
 */
@Component({
  selector: 'mg-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  /** Messages */
  MESSAGES: typeof ConfirmationDialogMessages = ConfirmationDialogMessages;

  text: ConfirmationDialogText = {
    description: '',
    deleteBtn: '',
  };

  /** Component Constructor */
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {
    if (data.text.description) {
      this.text.description = data.text.description;
    }
    if (data.text.deleteBtn) {
      this.text.deleteBtn = data.text.deleteBtn;
    }
  }

  /** Component Lifecycle: On Mount */
  ngOnInit(): void {}

  /**
   * Handle On Click Cancel
   */
  public async handleOnClickCancel(): Promise<void> {
    this.dialogRef.close({
      cancelled: true,
    });
  }

  /**
   * Handle On Click Confirmed
   */
  public async handleOnClickConfirmed(): Promise<void> {
    this.dialogRef.close({
      confirmed: true,
    });
  }
}
