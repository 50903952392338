import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import * as Color from 'color';

import { ImageInfo } from 'minga/proto/image/image_pb';

import { CountTileBackgroundColor, CountTileSizes } from './count-tile.types';

/**
 * Count Tile Component
 */
@Component({
  selector: 'mg-count-tile',
  templateUrl: './count-tile.component.html',
  styleUrls: ['./count-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountTileComponent {
  /** Constants */
  public readonly IMAGE_SIZES = ['cardbanner'];

  /** Color */
  private _color: string;
  private _shouldDarkenAsset: boolean;

  /** Inputs */
  @Input() icon = 'aboutminga-o';
  @Input() label: string | number = 'Tile';
  @Input() subtitle: string;
  @Input() count: string | number;
  @Input() isActive = false;
  @Input() size: CountTileSizes = 'medium';
  @Input() fixedHeight = false;
  @Input() image?: ImageInfo.AsObject | any;
  @Input() typeIcon?: string;
  @Input() onDisableHover = false;
  @Input() set color(value: string) {
    if (this._color === value) return;
    const colorInput = Color(value);
    if (Color('white').contrast(colorInput) < 2) {
      this._color = colorInput.darken(0.5).hex();
      this._shouldDarkenAsset = true;
    } else this._color = value;
  }
  @Input() staticImage: string;
  @Input() backgroundColor: CountTileBackgroundColor = 'grey';
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  /** Computed */
  get tileStyleClasses(): Record<string, boolean> {
    return {
      [this.size]: true,
      fixedHeight: this.fixedHeight,
    };
  }
  get tileContentStyles(): any {
    return {
      [this.backgroundColor]: true,
      active: this.isActive,
    };
  }
  get tileStyles(): any {
    return {
      ['--mg-count-tile-color']: this._color,
    };
  }
  get assetStyleClasses(): any {
    if (!this.image) return;
    return {
      tooBright: this._shouldDarkenAsset,
    };
  }

  /** Outputs */
  @Output() tileClicked: EventEmitter<any> = new EventEmitter();

  /** Component Constructor */
  constructor(public element: ElementRef) {}

  public handleOnClickTile(): void {
    this.tileClicked.emit();
  }
}
