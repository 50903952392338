import { Directive, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MgModalService } from 'minga/app/src/app/minimal/services/MgModal/';

@Directive({
  selector: '[mgModalContainer]',
  exportAs: 'mgModalContainer',
})
export class MgModalContainerDirective implements OnInit, OnDestroy {
  constructor(
    private modalService: MgModalService,
    private viewContainer: ViewContainerRef,
  ) {}

  isOpen() {
    return this.viewContainer.get(0) !== null;
  }

  ngOnInit() {
    this.modalService.setModalContainer(this.viewContainer);
  }

  ngOnDestroy() {
    this.modalService.clearModalContainer(this.viewContainer);
  }
}
