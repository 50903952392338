import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LandingService } from '../services';
import { hasLandingProperty } from '../utils';

@Injectable()
export class LandingHasPin extends hasLandingProperty('pin', [
  '/landing/email',
]) {
  constructor(landing: LandingService, router: Router) {
    super(landing, router);
  }
}
