import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LogoAnchor } from 'minga/proto/content/common_pb';

@Component({
  selector: 'mg-logo-anchored',
  templateUrl: './LogoAnchored.component.html',
  styleUrls: ['./LogoAnchored.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoAnchoredComponent {
  readonly _imageSizes = [
    // Use blur image immediately while loading
    'blurloading1',
    // Use whichever of these are available for final rendering
    ['logo', 'bannerlibpreview', 'cardbanner'],
  ];

  @Input()
  anchor: LogoAnchor = LogoAnchor.LA_NONE;

  /**
   * Custom logo. If set a custom logo will be displayed instead of the default
   * minga logo. This type is `any` on purpose because it is passed to the
   * image url resolve pipe.
   */
  @Input()
  customLogo: any;
}
