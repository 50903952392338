<div
  class="close-container"
  (click)="clickClose()"
  mat-dialog-title>
  <mg-button-icon
    [disabled]="sending"
    icon="close"></mg-button-icon>
  Close
</div>

<div mat-dialog-content>
  <div
    class="choices-container"
    *ngIf="selection == 'CHOICES'">
    <ng-container *ngIf="isDistrictEnabled$ | async; else mailAdminTmpl">
      <div
        *ngIf="admins$ | async as admins"
        class="choice-container"
        (click)="messageAdmins(admins)">
        <ng-container
          [ngTemplateOutlet]="adminChoiceTmpl"
          [ngTemplateOutletContext]="{ admintext: adminsText$ | async }">
        </ng-container>
      </div>
    </ng-container>
    <div
      class="choice-container"
      (click)="changeSelection('MINGA')">
      <div class="choice-icon m-logo"></div>
      <div class="mg-bodycopy">Message the Minga team.</div>
      <div class="sub mg-minicopy">
        See a bug or problem with the Minga app?
      </div>
    </div>

    <ng-template #mailAdminTmpl>
      <div
        class="choice-container"
        (click)="mailAdmin()"
        *ngIf="mingaProfile?.adminEmail || mingaProfile?.email">
        <ng-container
          [ngTemplateOutlet]="adminChoiceTmpl"
          [ngTemplateOutletContext]="{ admintext: adminProfileAdminText }">
        </ng-container>
      </div>
    </ng-template>

    <ng-template
      #adminChoiceTmpl
      let-admintext="admintext">
      <div class="choice-icon person">
        <mg-icon>person</mg-icon>
      </div>
      <div class="mg-bodycopy">
        Your Minga Administrator{{ admintext }}. Message them.
      </div>
      <div class="sub mg-minicopy">
        Have a question about accessing people within your Minga?
      </div>
    </ng-template>
  </div>

  <div
    class="active-choice"
    *ngIf="selection == 'MINGA'">
    <div class="m-logo"></div>

    <p>
      We love to hear how we’re doing. If you are having an issue with the app
      functioning, or want to request a new feature, then let us know below!
    </p>

    <mat-form-field
      floatLabel="never"
      class="feedback-textarea"
      mgFormField
      [mgNoHintMargin]="true">
      <textarea
        matInput
        [(ngModel)]="feedbackText"
        placeholder="How could we make Minga better?"
        matAutosizeMinRows="1"
        matTextareaAutosize
        matAutosizeMaxRows="10"
        [disabled]="sending"></textarea>
    </mat-form-field>

    <mg-button
      type="outline"
      (click)="submitMinga()"
      [disabled]="!feedbackText || sending">
      Send Feedback
    </mg-button>
  </div>
</div>

<ng-template #successOverlayTemplate>
  <div class="success-overlay">
    <div class="checkmark-wrap">
      <mg-success-checkmark></mg-success-checkmark>
      <div class="mg-bodycopy">Feedback Sent!</div>
    </div>
  </div>
</ng-template>
