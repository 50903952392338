import { isEqual } from 'lodash';

import { SelectGroupOption } from './form-grouped-select.types';

export const areEqual = (
  current: SelectGroupOption | SelectGroupOption[],
  next: string | number | Array<string | number>,
): boolean => {
  if (
    (current === null && next === null) ||
    (Array.isArray(next) &&
      Array.isArray(current) &&
      current.length === 0 &&
      next.length === 0)
  ) {
    return true;
  }
  const prevValue = normalizeValue(current);
  const nextValue = Array.isArray(next) ? next : [next];

  if (prevValue.length !== nextValue.length) {
    return false;
  }

  return isEqual(prevValue, nextValue);
};

const normalizeValue = (
  option: SelectGroupOption | null | SelectGroupOption[],
): Array<string | number> => {
  if (Array.isArray(option)) {
    return option.map(o => o?.value).filter(o => !!o);
  }

  return option?.value ? [option.value] : [];
};
