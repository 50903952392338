import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { FileInputUploadModule } from 'minga/app/src/app/components/Input/FileInputUpload';
import { MgIconModule } from 'minga/app/src/app/icon';
import { ActivateDeactivateToggleButtonModule } from 'src/app/components/ActivateDeactivateToggleButton';
import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgImageModule } from 'src/app/components/MgImage';
import { MgFormFieldModule } from 'src/app/form-field';
import { MgPipesModule } from 'src/app/pipes';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { OverlayModule } from '@shared/components/modal-overlay';
import { MobileCardModule } from '@shared/components/tables/mobile-card/mobile-card.module';
import { TextModule } from '@shared/components/text';
import { ArrayPipesModule } from '@shared/pipes';

import { PmRewardsEditComponent } from './components/pm-rewards-edit/pm-rewards-edit.component';
import { PmRewardsComponent } from './pm-rewards.component';
import { PmRewardsService } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    MgEmptyStateModule,
    ManagerOverlayModule,
    MgImageModule,
    MgPipesModule,
    ActivateDeactivateToggleButtonModule,
    ArrayPipesModule,
    MgIconModule,
    MgFormFieldModule,
    OverlayModule,
    FormModule,
    FileInputUploadModule,
    TextModule,
    GenericModule,
    MobileCardModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [PmRewardsComponent, PmRewardsEditComponent],
  exports: [PmRewardsComponent],
  providers: [PmRewardsService],
})
export class PmRewardsModule {}
