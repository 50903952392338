import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import * as mingaProto from 'minga/proto/gateway/minga_pb';
import { UpsertMingaPartner } from 'minga/domain/minga';
import { MingaManager } from 'minga/proto/gateway/minga_ng_grpc_pb';
import { MingaPermission } from 'minga/util';
import { RootService } from 'src/app/minimal/services/RootService';
import { PermissionsService } from 'src/app/permissions';
import { MingaManagerService as MingaService } from 'src/app/services/MingaManager';
import { ChangeMingaAction } from 'src/app/store/root/rootActions';

import { MINGA_STATUS_FILTER_MAP } from '@modules/minga-manager/constants';
import { MingaManagerService } from '@modules/minga-manager/services';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { getPercents } from '@shared/utils/get-percents';

import { MmDistrictsService } from '../../mm-districts/services/mm-districts.service';
import { MmDashboardEditComponent } from '../components/mm-dashboard-edit/mm-dashboard-edit.component';
import { MmDashboardEditModalType } from '../constants';
import {
  MmDashboardEditData,
  MmDashboardEditResponse,
  MmDashboardMingaInfo,
} from '../types/mm-dashboard.types';

@Injectable()
export class MmDashboardService implements OnDestroy {
  /** General Observables */
  private readonly _isLoading = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading
    .asObservable()
    .pipe(shareReplay());

  /** All Mingas */
  private readonly _data$ = new BehaviorSubject<MmDashboardMingaInfo[]>([]);
  public readonly data$ = this._data$.asObservable().pipe(shareReplay());
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Other */
  private _isSuperAdmin: boolean;

  /** Service Constructor */
  constructor(
    private _mingaManager: MingaManager,
    private _mmService: MingaManagerService,
    private _mingaService: MingaService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _store: Store<any>,
    private _router: Router,
    private _rootService: RootService,
    private _permissionsService: PermissionsService,
    private _modalService: ModalOverlayService<
      MmDashboardEditResponse,
      MmDashboardEditData
    >,
    private _districtsService: MmDistrictsService,
  ) {
    this._isSuperAdmin = this._permissionsService.hasPermission(
      MingaPermission.SUPERADMIN,
    );
    this._mingaService.onMingaChange
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this._mingaService.showMingaChangeSuccess();
      });
  }

  ngOnDestroy(): void {
    this._isLoading.complete();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public async openMingaPartnerEditModal(): Promise<void> {
    this._modalService.open(MmDashboardEditComponent, {
      data: {
        type: MmDashboardEditModalType.PARTNER,
      },
    });
  }

  public async openMingaEditModal(hash?: string): Promise<void> {
    if (!this._isSuperAdmin) return;
    this._modalService.open(MmDashboardEditComponent, {
      data: {
        type: MmDashboardEditModalType.MINGA,
        hash,
      },
    });
  }

  public async openSelectedMinga(mingaHash: string) {
    this._rootService.addLoadingPromise(this._openMinga(mingaHash));
  }

  public async fetchAllData(): Promise<void> {
    this._isLoading.next(true);
    await this.fetchMingas();
    await this._districtsService.fetchAll();
    this._isLoading.next(false);
  }

  public async fetchMingas(): Promise<void> {
    try {
      const result = await this._listMingas();
      this._data$.next(result);
    } catch (error) {
      this._systemAlertSnackBar.error('failed to fetch mingas');
    }
  }

  public async upsertMingaPartner(
    mingaPartner: UpsertMingaPartner,
  ): Promise<Error | undefined> {
    try {
      await this._upsertMingaPartner(mingaPartner);
      await this._mmService.fetchMingaPartners();
    } catch (err) {
      const errorMessage = 'failed to create/update minga partner';
      this._systemAlertSnackBar.error(errorMessage);
      return Error(errorMessage);
    }
  }

  private async _listMingas(): Promise<MmDashboardMingaInfo[]> {
    const request = new mingaProto.MingaDashboardRequest2();
    const response = await this._mingaManager.getMingaDashboard2(request);
    const mingas = response.getMingaList().map(protoMinga => {
      const minga: any = protoMinga.toObject();
      minga.status = MINGA_STATUS_FILTER_MAP[minga.status];
      const createDate = minga.createdAt;
      if (createDate) {
        minga.createdAt =
          createDate.year +
          '-' +
          String(createDate.month).padStart(2, '0') +
          '-' +
          String(createDate.day).padStart(2, '0');
      }
      const subStartDate = minga.subscriptionStartDate;
      if (subStartDate) {
        minga.subscriptionStartDate =
          subStartDate.year +
          '-' +
          String(subStartDate.month).padStart(2, '0') +
          '-' +
          String(subStartDate.day).padStart(2, '0');
      }
      const subEndDate = minga.subscriptionEndDate;
      if (subEndDate) {
        minga.subscriptionEndDate =
          subEndDate.year +
          '-' +
          String(subEndDate.month).padStart(2, '0') +
          '-' +
          String(subEndDate.day).padStart(2, '0');
      }
      const regStaff = minga.registeredCount - minga.registeredStudents;
      minga.registeredStaff = regStaff || 0;

      const unregStaff =
        minga.totalCount - minga.billableCount - minga.registeredStaff;
      minga.unregisteredStaff = unregStaff || 0;
      minga.totalStaff = regStaff + unregStaff;
      minga.totalStudents =
        minga.registeredStudents + minga.unregisteredStudents;
      minga.unregistered = minga.totalCount
        ? minga.totalCount - minga.registeredCount
        : 0;
      [minga.registeredStudentsPct, minga.unregisteredStudentsPct] =
        getPercents(minga.registeredStudents, minga.totalStudents, true);
      [minga.registeredStaffPct, minga.unregisteredStaffPct] = getPercents(
        minga.registeredStaff,
        minga.totalStaff,
        true,
      );
      [minga.registeredPct, minga.unregisteredPct] = getPercents(
        minga.registeredCount,
        minga.totalCount,
        true,
      );
      [minga.dailyActivePct] = getPercents(
        minga.dailyActiveCount,
        minga.totalCount,
      );
      [minga.weeklyActivePct] = getPercents(
        minga.weeklyActiveCount,
        minga.totalCount,
      );
      [minga.monthlyActivePct] = getPercents(
        minga.monthlyActiveCount,
        minga.totalCount,
      );
      minga.mingaHealthCheckStats = minga.healthScoreStatsEntriesList?.length
        ? minga.healthScoreStatsEntriesList.reduce(
            (acc, { key, intValue, scoreValue }) => {
              acc[key] = scoreValue || intValue;
              return acc;
            },
            {},
          )
        : {};

      if (minga.smsRemaining) {
        minga.smsRemaining = minga.smsRemaining.value;
      }
      return minga;
    }) as any;
    return mingas;
  }

  private async _upsertMingaPartner(mingaPartner: UpsertMingaPartner) {
    const request = new mingaProto.UpsertMingaPartnerRequest();
    const message = new mingaProto.MingaPartner();

    if (mingaPartner.id) message.setId(mingaPartner.id);
    message.setName(mingaPartner.name);

    request.setPartner(message);
    await this._mingaManager.upsertMingaPartner(request);
  }

  private async _openMinga(mingaHash: string) {
    try {
      await this._mingaService.openMinga(mingaHash);

      this._store.dispatch(new ChangeMingaAction(mingaHash));
      await this._router.navigate(['/home', { outlets: { o: null } }]);
    } catch (e) {
      this._systemAlertSnackBar.error('Failed to change Minga');
    }
  }
}
