import { Pipe, PipeTransform } from '@angular/core';

import * as day from 'dayjs';

@Pipe({ name: 'formatTimeRange' })
export class MgFormatTimeRangePipe implements PipeTransform {
  transform(item: { startTime: string; endTime: string }): string {
    const { startTime, endTime } = item;

    const formattedStart = this._getTimeFormatted(startTime);
    const formattedEnd = ` - ${this._getTimeFormatted(endTime)}`;

    return `${formattedStart}${formattedEnd}`;
  }

  private _getTimeFormatted(time: string): string {
    if (!time) return '';
    const [hr = '00', min = '00', sec = '00'] = time.split(':');
    return day(`${hr}:${min}:${sec}`, 'HH:mm:ss').format('h:mm a');
  }
}
