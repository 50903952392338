<div
  fxLayout="row"
  fxLayout.lt-md="column-reverse"
  fxLayoutAlign="space-between start"
  fxLayoutAlign.lt-md="start stretch"
  fxLayoutGap="12px">
  <div
    *ngIf="!loading"
    fxLayout="column"
    class="file-list">
    <div
      *ngFor="let groupFile of files; let i = index"
      class="file-container">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        (mouseenter)="hoverRow = i"
        (mouseleave)="hoverRow = -1">
        <a
          fxLayout="row"
          fxLayoutGap="8px"
          class="file-details"
          [href]="getHrefValue(groupFile)"
          (click)="onAnchorClick($event, groupFile)"
          [title]="getTitleValue(groupFile)">
          <mg-file-extension-box
            [extension]="groupFile.file.fileExtension"></mg-file-extension-box>
          <div>
            <mg-text variant="body-md-secondary">
              {{ groupFile.file.name }}
            </mg-text>
            <div
              *ngIf="groupFile.file.authorPersonView"
              fxLayoutGap="2px">
              <mg-text
                *ngIf="groupFile.file.url"
                variant="label-sm">
                {{ MESSAGES.FILE_SHARED_LABEL }}
              </mg-text>
              <mg-text
                *ngIf="!groupFile.file.url"
                variant="label-sm">
                {{ MESSAGES.FILE_UPLOADED_LABEL }}
              </mg-text>
              <mg-text variant="label-sm-secondary">
                {{ groupFile.file.authorPersonView.displayName }}
              </mg-text>
            </div>
            <mg-text variant="label-sm">
              Uploaded {{ getFileDateString(groupFile.file.timestamp) }}
            </mg-text>
          </div>
        </a>
        <mg-btn
          *ngIf="enableDelete"
          variant="icon-destructive"
          iconSet="minga"
          icon="mg-delete"
          (pressed)="deleteFileIndex.emit(i)">
        </mg-btn>
      </div>
      <div *ngIf="hasStatus(groupFile) && !groupFile.status.complete">
        <mat-progress-bar
          mode="determinate"
          [value]="groupFile.status.progress"></mat-progress-bar>
      </div>
    </div>
    <mg-empty-state
      *ngIf="!files || !files.length"
      [title]="'emptyState.mingaFilesTitle' | translate"
      [subtitle]="
        enableCreateFile ? ('emptyState.mingaFilesSubtitle' | translate) : ''
      ">
    </mg-empty-state>
  </div>
  <mg-file-input
    *ngIf="enableCreateFile && !enableCreateURL"
    [customTitleText]="MESSAGES.ADD_FILE_LABEL"
    accept="*/*"
    [multiple]="true"
    [displayStyle]="fileInputDisplayType"
    [(ngModel)]="pendingFiles"
    (ngModelChange)="onFileChange($event)">
  </mg-file-input>
  <div
    *ngIf="enableCreateURL"
    class="file-btn">
    <mg-btn
      variant="filled"
      iconRight="down-toggle"
      iconSet="minga"
      [wide]="true"
      [responsive]="true"
      [matMenuTriggerFor]="dropdownMenu">
      {{ MESSAGES.ADD_FILE_LABEL }}
    </mg-btn>
    <mat-menu
      #dropdownMenu="matMenu"
      xPosition="before">
      <button
        mat-menu-item
        (click)="uploadUrl.emit($event)">
        {{ MESSAGES.ADD_URL_LABEL }}
      </button>
      <button mat-menu-item>
        <mg-file-input
          *ngIf="enableCreateFile"
          [customTitleText]="MESSAGES.UPLOAD_FILE_LABEL"
          accept="*/*"
          [multiple]="true"
          displayStyle="TEXT"
          [(ngModel)]="pendingFiles"
          (ngModelChange)="onFileChange($event)">
        </mg-file-input>
      </button>
    </mat-menu>
  </div>
  <div *ngIf="loading">
    <mg-spinner></mg-spinner>
  </div>
</div>
