import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { Observable, combineLatest, from } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';
import { mingaSettingTypes } from 'minga/util';
import { ListMembershipService } from 'src/app/services/ListMembership';
import { PointsManagerService } from 'src/app/services/PointsManager';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { LayoutService } from '@modules/layout/services';

import {
  BMSettingsFriendlyListTypeName,
  BmSettingsMessages,
} from './constants';
import { IManagePointsAction } from './types';

@Component({
  selector: 'mg-bm-settings',
  templateUrl: './bm-settings.component.html',
  styleUrls: ['./bm-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmSettingsComponent implements OnInit {
  /** Messages */
  MESSAGES = BmSettingsMessages;

  lists$: Observable<IMembershipList[]>;

  public canShowAutoAssignTardy$: Observable<boolean>;
  public isConsequencesEnabled$: Observable<boolean>;

  public readonly LIST_TYPES = [
    MembershipListType.BEHAVIOR_MANAGER,
    MembershipListType.BEHAVIOR_REPORT_VIEWER,
    MembershipListType.TRACK,
    MembershipListType.NO_TRACK,
  ];

  mingaSettings = mingaSettingTypes;
  canAnimateNewItem = false;

  constructor(
    public settingService: MingaSettingsService,
    public layout: LayoutService,
    private _router: Router,
    private _listService: ListMembershipService,
    private _pointsManager: PointsManagerService,
    private _cdr: ChangeDetectorRef,
  ) {
    this.isConsequencesEnabled$ = this.settingService.getSettingValueObs(
      this.mingaSettings.BM_CONSEQUENCE_ENABLE,
    );
    this.canShowAutoAssignTardy$ = combineLatest([
      this.settingService.getSettingValueObs(this.mingaSettings.PBIS_HP_TARDY),
      this.settingService.isHallPassModuleEnabled(),
    ]).pipe(
      map(([isAutoTardyEnabled, isHallPassEnabled]) => {
        return isAutoTardyEnabled && isHallPassEnabled;
      }),
    );
  }

  async ngOnInit(): Promise<void> {
    this.initPbisSettingsLists();
  }

  initPbisSettingsLists() {
    const types = [
      MembershipListType.TRACK,
      MembershipListType.NO_TRACK,
      MembershipListType.BEHAVIOR_MANAGER,
      MembershipListType.BEHAVIOR_REPORT_VIEWER,
    ];
    this.lists$ = from(this._listService.getOrCreateMembershipLists(types));
    this._cdr.markForCheck();
  }

  async updateMembershipList(list: IMembershipList) {
    await this._listService.updateMembershipList(list);
  }

  public launchPointsManagerOverlay(action: IManagePointsAction) {
    this._pointsManager.setTempPointsPeopleState(action.action);
    this._pointsManager.setTempPointsPeopleType(action.type);
    this._router.navigate(['', { outlets: { o: ['points-manager'] } }]);
  }

  public async onConsequencesEnabledChange(isEnabled: boolean) {
    if (!isEnabled) {
      await this.settingService.updateSetting(
        this.mingaSettings.BM_CONSEQUENCE_TEACHER,
        false,
      );

      await this.settingService.updateSetting(
        this.mingaSettings.BM_CONSEQUENCE_STAFF,
        false,
      );
    }
  }

  public reloadLists() {
    this.initPbisSettingsLists();
  }

  public getFriendlyListName(list: IMembershipList) {
    if (list.listType in BMSettingsFriendlyListTypeName) {
      return BMSettingsFriendlyListTypeName[list.listType];
    } else {
      return list.name;
    }
  }
}
