import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { $enum } from 'ts-enum-util';

import { NotificationActions } from 'minga/app/src/app/store/Notifications/actions';
import { NotificationStoreModel } from 'minga/app/src/app/store/Notifications/model';
import { INotificationType } from 'minga/domain/notification';
import { Notification } from 'minga/proto/gateway/notification_pb';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';

@Injectable({ providedIn: 'root' })
export class BehaviorsNotificationHandlerService {
  constructor(
    private _systemAlertModal: SystemAlertModalService,
    private _store: Store<any>,
  ) {}

  public handleBehaviorNotification(msg: Notification.AsObject) {
    const notificationType = $enum(INotificationType).asValueOrThrow(
      msg.notificationType,
    );

    switch (notificationType) {
      case INotificationType.BM_PRAISE:
        this._openPraiseNotificationOverlay(msg);
        break;
      case INotificationType.BM_GUIDANCE:
        this._openGuidanceNotificationOverlay(msg);
        break;
      case INotificationType.BM_CONSEQUENCE_PRAISE:
        this._openPraiseConsequenceNotificationOverlay(msg);
        break;
      case INotificationType.BM_CONSEQUENCE_GUIDANCE:
        this._openGuidanceConsequenceNotificationOverlay(msg);
        break;
      default:
        break;
    }

    const notification: NotificationStoreModel = { id: msg.notificationId };
    this._store.dispatch(
      new NotificationActions.MarkNotificationAsReadAction(notification),
    );
  }

  private _openPraiseNotificationOverlay(msg: Notification.AsObject) {
    this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: 'Praise',
      iconColor: 'behavior-praise',
      message: msg.title,
      subMessage: msg.body,
      icon: msg.iconUrl,
    });
  }

  private _openGuidanceNotificationOverlay(msg: Notification.AsObject) {
    this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: 'Guidance',
      iconColor: 'behavior-consequence',
      message: msg.title,
      subMessage: msg.body,
      icon: msg.iconUrl,
    });
  }

  private _openPraiseConsequenceNotificationOverlay(
    msg: Notification.AsObject,
  ) {
    this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: 'Consequence',
      iconColor: 'behavior-praise',
      message: msg.title,
      subMessage: msg.body,
      icon: msg.iconUrl,
    });
  }

  private _openGuidanceConsequenceNotificationOverlay(
    msg: Notification.AsObject,
  ) {
    this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: 'Consequence',
      iconColor: 'behavior-consequence',
      message: msg.title,
      subMessage: msg.body,
      icon: msg.iconUrl,
    });
  }
}
