<ng-container>
  <ng-content select="mg-short-card-byline"></ng-content>
  <ng-container *ngIf="!bylineSlot">
    <div class="post-info">
      <ng-container
        *ngIf="authorPersonView && authorPersonView.profileImageUrl">
        <mg-avatar
          class="author-avatar"
          [ngClass]="'archived-' + authorPersonView.archived"
          [src]="authorPersonView.profileImageUrl"
          [color]="authorPersonView.badgeIconColor"
          [personHash]="authorPersonView.personHash"></mg-avatar>
      </ng-container>

      <div
        class="post-byline-wrap"
        *ngIf="authorPersonView">
        <mg-person-minimal [person]="authorPersonView"></mg-person-minimal>
        <div
          class="date-posted"
          [ngClass]="'archived-' + authorPersonView.archived">
          {{humanize(timestamp)}}
        </div>
      </div>

      <mg-ellipsis [mgContentContextMenuClick]="context"></mg-ellipsis>

      <ng-container *ngIf="pinned">
        <mge-icon
          class="pinned-icon"
          icon="pin-o"></mge-icon>
      </ng-container>
    </div>
  </ng-container>

  <ng-content select="mg-short-card-featured-image"></ng-content>
  <ng-container *ngIf="!featuredImageSlot && hasFeaturedImage">
    <div
      class="banner"
      #bannerContainer>
      <mg-banner-headline
        *ngIf="hasBannerHeadline()"
        [headlineOptions]="banner?.headlineOptions"
        [headlineContainer]="bannerContainer">
        <span [innerHTML]="title | mgSafeHtml"></span>
      </mg-banner-headline>

      <mg-image
        [srcs]="featuredImage | mgImageUrls:imageSizes"
        [style.paddingBottom]="_presentCalcBannerSize()"></mg-image>

      <div
        class="banner-count-display"
        [ngClass]="'banner-count-' + moreImageCount()">
        +{{moreImageCount()}}
      </div>
      <div
        class="banner-gradient"
        [ngStyle]="_bannerGradientStyle(1)"></div>
      <div
        class="banner-gradient banner-back-gradient"
        [ngStyle]="_bannerGradientStyle(0.85)"></div>
    </div>
  </ng-container>

  <ng-content select="mg-short-card-group-name"></ng-content>
  <ng-container *ngIf="!groupNameSlot">
    <div
      *ngIf="groupName"
      class="group-link-container"
      (click)="groupLinkClick($event)">
      <span class="group-icon"></span>
      <span class="group-link-text"
        >Posted in <span class="group-link">{{groupName}}</span></span
      >
    </div>
  </ng-container>

  <ng-content select="mg-short-card-title"></ng-content>
  <ng-container *ngIf="!titleSlot">
    <h1
      class="title content-block"
      *ngIf="!hasBannerHeadline()"
      [ngClass]="'group-content-' + shouldShowGroupLinkSlot()"
      [innerHTML]="title | mgSafeHtml"></h1>
  </ng-container>

  <ng-content select="mg-short-card-body"></ng-content>
  <ng-container *ngIf="!bodySlot">
    <div class="body-contents content-block">
      <mg-clipped-html
        #clippedBodyHtml
        [fullHtml]="body"
        [clippedLength]="160">
        <mg-clipped-html-head></mg-clipped-html-head>
        <mg-clipped-html-tail *ngIf="!clippedBodyHtml.shouldShowFullHtml">
          <a
            href="javascript:;"
            (click)="clippedBodyHtml.showFullHtmlClick($event)"
            >...More</a
          >
        </mg-clipped-html-tail>
      </mg-clipped-html>
    </div>
  </ng-container>

  <div
    class="content-block actions icons"
    [ngClass]="'icons-pd-' + devicePixelRatio + ' ' + actionsclass">
    <ng-content select="mg-short-card-action-bar"></ng-content>
    <ng-container *ngIf="!actionBarSlot">
      <mg-like
        class="action no-before mg-cancel-content-link"
        [class.light-content]="lightContent"
        (click)="allowClick($event)"
        [count]="likeCount"
        [context]="getContext(context)">
      </mg-like>
      <div
        class="action icon comment"
        [mgContentLink]="context"
        [mgContentLinkFocusCommentInput]="true"
        mgContentLinkFragment="comments">
        {{numberize(totalCommentCount)}}
      </div>
    </ng-container>
  </div>

  <ng-content select="mg-short-card-comment-list"></ng-content>
  <ng-container *ngIf="!commentListSlot && commentList?.length">
    <div class="comment-list-title-wrap">
      <ng-container *ngIf="showViewAllComments">
        <a
          class="content-block"
          href="javascript:;"
          [mgContentLink]="context"
          mgContentLinkFragment="comments">
          <ng-container *ngIf="previousCommentCount === 1">
            View {{previousCommentCount}} previous comment
          </ng-container>
          <ng-container *ngIf="previousCommentCount !== 1">
            View {{previousCommentCount}} previous comments
          </ng-container>
        </a>
      </ng-container>

      <ng-container *ngIf="!showViewAllComments">
        <a
          href="javascript:;"
          class="disabled content-block"
          >Comments</a
        >
      </ng-container>
    </div>

    <div class="content-block">
      <ng-container *ngFor="let item of commentList; let i=index">
        <mg-feed-comment-view
          [disableActions]="!(canComment$|async)"
          [comment]="item"
          [contentContext]="context"
          [lightContent]="lightContent"></mg-feed-comment-view>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
