import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';

export interface IMgAvatarElementProperties {
  src: string;
  overflow: boolean;
  color: string;
  square: boolean;
  borderless: boolean;
}

@Component({
  selector: 'mg-avatar',
  templateUrl: './MgAvatar.element.html',
  styleUrls: ['./MgAvatar.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgAvatarElement implements IMgAvatarElementProperties {
  @ContentChild(ElementRef, { static: false })
  contentChild?: ElementRef;

  @Input() src: string;
  @Input() overflow: boolean;
  @Input() color: string;
  @Input() square: boolean;
  @Input() borderless: boolean;
  @Input() personHash: string;

  @HostBinding('style.border-color')
  get borderColor() {
    return this.color;
  }

  @HostBinding('class.mg-cancel-content-link')
  get cancelContentLink() {
    // only apply if there's a person hash to go to a profile with
    return !!this.personHash;
  }

  constructor() {}
}
