import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ColorPickerModule } from 'ngx-color-picker';

import { MgEmptyStateModule } from 'minga/app/src/app/components/EmptyState';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { ActivateDeactivateToggleButtonModule } from 'src/app/components/ActivateDeactivateToggleButton';
import { MgFormFieldModule } from 'src/app/form-field';
import { MgPipesModule } from 'src/app/pipes';

import { FormModule } from '@shared/components/form';
import { FormRestrictionModule } from '@shared/components/form-restriction-input/form-restriction-input.module';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { MembershipListTableModule } from '@shared/components/membership-list-table';
import { OverlayModule } from '@shared/components/modal-overlay';
import { MobileCardModule } from '@shared/components/tables/mobile-card/mobile-card.module';
import { TextModule } from '@shared/components/text';
import { TooltipModule } from '@shared/components/tooltip';

import { HpmTypesEditComponent, HpmTypesTableComponent } from './components';
import { MinValueInputValidator } from './directives';
import { HpmTypesComponent } from './hpm-types.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgEmptyStateModule,
    OverlayModule,
    MgPipesModule,
    MgFormFieldModule,
    MgImageModule,
    ManagerOverlayModule,
    ActivateDeactivateToggleButtonModule,
    TooltipModule,
    MembershipListTableModule,
    FormModule,
    MgSpinnerModule,
    TextModule,
    GenericModule,
    MobileCardModule,
    FormRestrictionModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    ColorPickerModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTooltipModule,
  ],
  exports: [
    HpmTypesComponent,
    HpmTypesTableComponent,
    HpmTypesEditComponent,
    MinValueInputValidator,
  ],
  declarations: [
    HpmTypesComponent,
    HpmTypesTableComponent,
    HpmTypesEditComponent,
    MinValueInputValidator,
  ],
})
export class HpmTypesModule {}
