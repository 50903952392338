import { Injectable } from '@angular/core';
import { FirebaseMessaging } from 'minga/app/src/app/firebase/messaging';
import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { NotificationToken } from 'minga/proto/gateway/notification_ng_grpc_pb';
import { UpdateDeviceRequest } from 'minga/proto/gateway/notification_pb';
import { filter, withLatestFrom } from 'rxjs/operators';
import { SentryService } from 'src/app/minimal/services/Sentry/Sentry.service';

@Injectable({ providedIn: 'root' })
export class DeviceUpdaterService {
  private _startupCalled: boolean = false;
  private _lastFirebaseToken: string = '';
  constructor(
    private notificationToken: NotificationToken,
    private firebaseMessaging: FirebaseMessaging,
    private authInfo: AuthInfoService,
    private sentryService: SentryService,
  ) {}

  startup() {
    if (this._startupCalled) {
      throw new Error('DeviceUpdaterService.startup() may only be called once');
    }
    this._startupCalled = true;

    this.authInfo.authInfo$
      .pipe(filter(info => !!info))
      .subscribe(() => this.updateDevice());

    this.firebaseMessaging.tokenRefresh$
      .pipe(
        withLatestFrom(this.authInfo.authInfo$),
        filter(([, authInfo]) => !!authInfo),
      )
      .subscribe(() => this.updateDevice());
  }

  async updateDevice() {
    let request = new UpdateDeviceRequest();
    const deviceId = await this.firebaseMessaging.getDeviceId();
    const firebaseToken = await this.firebaseMessaging.getToken();

    if (this._lastFirebaseToken == firebaseToken) {
      return;
    }

    this._lastFirebaseToken = firebaseToken;

    if (!firebaseToken) {
      return;
    }

    request.setDeviceId(deviceId);
    request.setFirebaseRegistrationToken(firebaseToken);
    const response = await this.notificationToken.updateDeviceToken(request);

    const status = response.getStatus();
    if (status != StatusCode.OK) {
      await this.sentryService.captureMessageAsError(
        `Notification.service.updateDevice() failed`,
      );
      console.error(`updateDevice() failed`);
    }
  }
}
