import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { ErrorPagesMessage } from '@modules/layout/constants';

@Component({
  selector: 'mg-layout-page-not-found',
  templateUrl: './layout-page-not-found.component.html',
  styleUrls: ['./layout-page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutPageNotFoundComponent implements OnDestroy {
  // Constants
  public readonly MSG = ErrorPagesMessage;

  // Cleanup
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Component Constructor */
  constructor() {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
