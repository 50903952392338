import { NgModule } from '@angular/core';

import { FitGridComponent } from './FitGrid.component';
import { FitGridTileDirective } from './FitGridTile.directive';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [FitGridComponent, FitGridTileDirective],
  exports: [FitGridComponent, FitGridTileDirective],
})
export class FitGridModule {}
