import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {
  IOverlayComponent,
  IOverlayConfig,
  IOverlayStepNav,
  OverlayStepInfo,
} from 'minga/app/src/app/misc/overlay';
import {
  OverlayStructureRegionId,
  OverlayStructureRegistry,
} from 'minga/app/src/app/overlay/services/OverlayStructureRegistry';
import {
  isOverlayFullscreen,
  isOverlayUsingNestedScroll,
} from 'minga/app/src/app/overlay/utility';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// @TODO: Bazel - Move these somewhere else
const PULL_DOWN_THRESHOLD = 80;

@Component({
  selector: 'mg-overlay',
  templateUrl: './MgOverlay.component.html',
  styleUrls: ['./MgOverlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgOverlayComponent implements OnDestroy {
  /** @internal */
  private _overlayTopNavTopSentinel: ElementRef<HTMLDivElement> | null = null;
  private _overlayTopNavBtmSentinel: ElementRef<HTMLDivElement> | null = null;
  private _overlayBottomNavTopSentinel: ElementRef<HTMLDivElement> | null =
    null;
  private _overlayBottomNavBtmSentinel: ElementRef<HTMLDivElement> | null =
    null;

  // Unset on platforms that don't support `IntersectionObserver`
  private _overlayNavStickyObs?: IntersectionObserver;
  private _overlayTopNavTopIntersecting: boolean = false;
  private _overlayTopNavBottomIntersecting: boolean = false;
  private _overlayBottomNavTopIntersecting: boolean = false;
  private _overlayBottomNavBottomIntersecting: boolean = false;
  private _overlayAnimateNav: boolean = false;

  private _destroyed$ = new ReplaySubject<void>(1);
  private _headerHeight: number = 0;
  private _contentBottomNavHeight: number = 0;
  mqAliases: string[] = [];

  @HostListener('window:resize', [])
  _onWindowResize() {
    // reset height variables
    this._headerHeight = 0;
    this._contentBottomNavHeight = 0;
  }

  @ViewChild('overlayTopNavTopSentinel', { static: false })
  set overlayTopNavTopSentinel(el: ElementRef<HTMLDivElement> | null) {
    if (this._overlayTopNavTopSentinel && this._overlayNavStickyObs) {
      this._overlayNavStickyObs.unobserve(
        this._overlayTopNavTopSentinel.nativeElement,
      );
    }

    this._overlayTopNavTopSentinel = el;

    if (this._overlayTopNavTopSentinel && this._overlayNavStickyObs) {
      this._overlayNavStickyObs.observe(
        this._overlayTopNavTopSentinel.nativeElement,
      );
    }
  }

  get overlayTopNavTopSentinel() {
    return this._overlayTopNavTopSentinel;
  }

  @ViewChild('overlayTopNavBtmSentinel', { static: false })
  set overlayTopNavBtmSentinel(el: ElementRef<HTMLDivElement> | null) {
    if (this._overlayTopNavBtmSentinel && this._overlayNavStickyObs) {
      this._overlayNavStickyObs.unobserve(
        this._overlayTopNavBtmSentinel.nativeElement,
      );
    }

    this._overlayTopNavBtmSentinel = el;

    if (this._overlayTopNavBtmSentinel && this._overlayNavStickyObs) {
      this._overlayNavStickyObs.observe(
        this._overlayTopNavBtmSentinel.nativeElement,
      );
    }
  }

  get overlayTopNavBtmSentinel() {
    return this._overlayTopNavBtmSentinel;
  }

  @ViewChild('overlayBottomNavTopSentinel', { static: false })
  set overlayBottomNavTopSentinel(el: ElementRef<HTMLDivElement> | null) {
    if (this._overlayBottomNavTopSentinel && this._overlayNavStickyObs) {
      this._overlayNavStickyObs.unobserve(
        this._overlayBottomNavTopSentinel.nativeElement,
      );
    }

    this._overlayBottomNavTopSentinel = el;

    if (this._overlayBottomNavTopSentinel && this._overlayNavStickyObs) {
      this._overlayNavStickyObs.observe(
        this._overlayBottomNavTopSentinel.nativeElement,
      );
    }
  }

  get overlayBottomNavTopSentinel() {
    return this._overlayBottomNavTopSentinel;
  }

  @ViewChild('overlayBottomNavBtmSentinel', { static: false })
  set overlayBottomNavBtmSentinel(el: ElementRef<HTMLDivElement> | null) {
    if (this._overlayBottomNavBtmSentinel && this._overlayNavStickyObs) {
      this._overlayNavStickyObs.unobserve(
        this._overlayBottomNavBtmSentinel.nativeElement,
      );
    }

    this._overlayBottomNavBtmSentinel = el;

    if (this._overlayBottomNavBtmSentinel && this._overlayNavStickyObs) {
      this._overlayNavStickyObs.observe(
        this._overlayBottomNavBtmSentinel.nativeElement,
      );
    }
  }

  @ViewChild('headerContainerEl', { static: false })
  headerContainer?: ElementRef<HTMLDivElement | null>;

  @ViewChild('contentBottomNavEl', { static: false })
  contentBottomNavEl?: ElementRef<HTMLDivElement | null>;

  get overlayBottomNavBtmSentinel() {
    return this._overlayTopNavBtmSentinel;
  }

  get headerElementHeight(): number {
    if (!this.headerContainer?.nativeElement) {
      // reset when element not present
      this._headerHeight = 0;
    } else if (!this._headerHeight) {
      this._headerHeight = this.headerContainer.nativeElement.clientHeight;
    }

    return this._headerHeight;
  }

  get contentBottomNavElementHeight(): number {
    if (!this.contentBottomNavEl?.nativeElement) {
      // reset when element not present
      this._contentBottomNavHeight = 0;
    } else if (!this._contentBottomNavHeight) {
      this._contentBottomNavHeight =
        this.contentBottomNavEl.nativeElement.clientHeight;
    }

    return this._contentBottomNavHeight;
  }

  @Input()
  overlayActive: boolean = false;

  @Input()
  overlayComponent?: IOverlayComponent;

  @Output()
  overlayClose = new EventEmitter<void>();

  @Output()
  overlayNext = new EventEmitter<any>();

  @Output()
  overlayPrevious = new EventEmitter<any>();

  @Input()
  overlayPreviousInfo?: OverlayStepInfo;

  @Input()
  overlayNextInfo?: OverlayStepInfo;

  @Input()
  overlayStepNav?: IOverlayStepNav;

  @Input()
  overlayConfig: IOverlayConfig = {};

  @HostBinding('class.transparent-top-nav')
  get overlayTransparentMobileNav(): boolean {
    return !!this.overlayConfig.mobileTransparentNav;
  }

  get overlayBg(): string {
    return this.overlayConfig.background || '';
  }

  get overlayContentBg(): string {
    return this.overlayConfig.contentBackground || '#FFFFFF';
  }

  get overlayFullContentBg(): string {
    return this.overlayConfig.fullContentBackground || '';
  }

  get overlayBorderRadius(): string {
    return this.overlayConfig.borderRadius || '';
  }

  get overlayBoxShadow(): string {
    return this.overlayConfig.boxShadow || '';
  }

  get overlayTopNavSticky() {
    return !this._overlayTopNavBottomIntersecting;
  }

  get overlayBottomNavSticky() {
    return !this._overlayBottomNavBottomIntersecting;
  }

  @HostBinding('class.mg-overlay-wide')
  get overlayWide() {
    return this.overlayConfig.wide || false;
  }

  @HostBinding('class.mg-contain-within-viewport')
  get overlayContainWithinViewport() {
    if (!this.overlayConfig.wide) return false;

    return this.overlayConfig.containWithinViewport || false;
  }

  @HostBinding('class.has-split-content')
  get hasSplitContent() {
    return this.hasStructureTemplate('split-content');
  }

  @HostBinding('class.has-header')
  get hasHeader() {
    return this.hasStructureTemplate('header');
  }

  @HostBinding('attr.style')
  get overlayStyleVariables() {
    const styles: string[] = [
      `--overlay-header-height: ${this.headerElementHeight}px`,
      `--overlay-content-bottom-nav-height: ${this.contentBottomNavElementHeight}px`,
    ];

    if (this.overlayBg) {
      styles.push(`--mg-overlay-background: ${this.overlayBg}`);
    }

    if (this.overlayContentBg) {
      styles.push(`--mg-overlay-content-background: ${this.overlayContentBg}`);
    }

    if (this.overlayFullContentBg) {
      styles.push(
        `--mg-overlay-full-content-background: ${this.overlayFullContentBg}`,
      );
    }

    if (!this.mqAliases.includes('xs')) {
      if (this.overlayBorderRadius) {
        styles.push(`--overlay-border-radius: ${this.overlayBorderRadius}`);
      }
      if (this.overlayBoxShadow) {
        styles.push(`--overlay-box-shadow: ${this.overlayBoxShadow}`);
      }
    }

    return this.getSafeStyle(styles.join(';'));
  }

  get usingNestedScroll(): boolean {
    return isOverlayUsingNestedScroll({
      wide: this.overlayWide,
      containWithinViewport: this.overlayContainWithinViewport,
      hasSplitContent: this.hasSplitContent,
    });
  }

  constructor(
    public element: ElementRef,
    private cdr: ChangeDetectorRef,
    private overlayStructureRegistry: OverlayStructureRegistry,
    private sanitizer: DomSanitizer,
    media: MediaObserver,
  ) {
    if ((window as any).IntersectionObserver) {
      const options: IntersectionObserverInit = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      const callback: IntersectionObserverCallback = entries =>
        this._onOverlayNavSticky(entries);
      this._overlayNavStickyObs = new IntersectionObserver(callback, options);
    } else {
      console.warn(
        'IntersectionObserver is unavailable on this platform. Overlay navigation sticky behaviour will not work.',
      );
    }

    media
      .asObservable()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(mediaChanges => {
        this.mqAliases = mediaChanges.map(mc => mc.mqAlias);
        this.cdr.markForCheck();
      });
  }

  hasStructureTemplate(regionId: OverlayStructureRegionId): boolean {
    return this.overlayStructureRegistry.hasStructureTemplate(regionId);
  }

  ngOnDestroy() {
    this._overlayNavStickyObs?.disconnect();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _onOverlayNavSticky(entries: IntersectionObserverEntry[]) {
    let topBottomIntersecting = false;
    let topTopIntersecting = false;
    let bottomBottomIntersecting = false;
    let bottomTopIntersecting = false;

    for (const entry of entries) {
      if (entry.target.classList.contains('overlay-top-nav-sentinel')) {
        if (entry.target.classList.contains('overlay-nav-sentinel-bottom')) {
          topBottomIntersecting = entry.isIntersecting;
        } else if (
          entry.target.classList.contains('overlay-nav-sentinel-top')
        ) {
          topTopIntersecting = entry.isIntersecting;
        }
      }

      if (entry.target.classList.contains('overlay-bottom-nav-sentinel')) {
        if (entry.target.classList.contains('overlay-nav-sentinel-bottom')) {
          bottomBottomIntersecting = entry.isIntersecting;
        } else if (
          entry.target.classList.contains('overlay-nav-sentinel-top')
        ) {
          bottomTopIntersecting = entry.isIntersecting;
        }
      }
    }

    this._overlayTopNavStickyHandle(topTopIntersecting, topBottomIntersecting);

    this._overlayBottomNavStickyHandle(
      bottomTopIntersecting,
      bottomBottomIntersecting,
    );
  }

  private _overlayTopNavStickyHandle(
    topIntersecting: boolean,
    bottomIntersecting: boolean,
  ) {
    const intersectingChanged =
      this._overlayTopNavTopIntersecting != topIntersecting ||
      this._overlayTopNavBottomIntersecting != bottomIntersecting;

    if (intersectingChanged) {
      this._overlayTopNavTopIntersecting = topIntersecting;
      this._overlayTopNavBottomIntersecting = bottomIntersecting;
      this.cdr.markForCheck();
    }
  }

  private _overlayBottomNavStickyHandle(
    topIntersecting: boolean,
    bottomIntersecting: boolean,
  ) {
    const intersectingChanged =
      this._overlayBottomNavTopIntersecting != topIntersecting ||
      this._overlayBottomNavBottomIntersecting != bottomIntersecting;

    if (intersectingChanged) {
      this._overlayBottomNavTopIntersecting = topIntersecting;
      this._overlayBottomNavBottomIntersecting = bottomIntersecting;
      this.cdr.markForCheck();
    }
  }

  async defaultOverlayPrevious() {
    this.closeOverlay();
  }

  async componentOverlayPrevious(overlayComponent: IOverlayComponent) {
    if (typeof overlayComponent.onOverlayNext === 'function') {
    } else {
      await this.defaultOverlayPrevious();
    }
  }

  closeOverlay() {
    this.overlayClose.emit();
  }

  overlayBgClick(ev: MouseEvent) {
    if (!isOverlayFullscreen({ wide: this.overlayWide })) {
      this.closeOverlay();
    }
  }

  overlayOutletWrapClick(ev: MouseEvent) {
    const target: any = ev.target;

    if (target.classList) {
      if (target.classList.contains('overlay-outlet-wrap')) {
        ev.preventDefault();
        ev.stopImmediatePropagation();
        ev.stopPropagation();
        this.overlayBgClick(ev);
      }
    }
  }

  goOverlayPrevious() {
    if (this.overlayComponent) {
      this.componentOverlayPrevious(this.overlayComponent);
    } else {
      this.defaultOverlayPrevious();
    }
  }

  async goOverlayNext() {
    if (!this.overlayComponent) {
      return;
    }

    if (typeof this.overlayComponent.onOverlayNext === 'function') {
      let result = this.overlayComponent.onOverlayNext();
      await result;
    }
  }

  getSafeStyle(key: string, value?: string) {
    const style = !!value ? `${key}: ` + value : key;
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
