<mg-overlay-primary
  size="auto"
  [isLoading]="datePresetsStore.isLoading$ | async"
  [overlayTitle]="MESSAGES.LIST_MODAL_TITLE">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<ng-template #mainTemplate>
  <main
    class="modal-form"
    fxLayout="column"
    fxLayoutGap="16px">
    <mat-table
      class="list-table no-explicit-mobile-layout"
      [dataSource]="dataSource"
      [trackBy]="trackByFn">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>
          <mg-text
            class="column-header"
            variant="body-xs"
            color="grey-300">
            {{ MESSAGES.LIST_TABLE_TITLE }}
          </mg-text>
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <mg-text variant="body-sm">
            {{ getNameLabel(item) }}
          </mg-text>
        </mat-cell>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="edit">
        <mat-header-cell
          *matHeaderCellDef
          class="w-12 text-center">
          <mg-text
            class="column-header"
            variant="body-xs"
            color="grey-300">
            {{ MESSAGES.LIST_TABLE_EDIT }}
          </mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let item"
          class="w-12 text-center">
          <mg-btn
            *ngIf="!item.isDefaultPreset"
            icon="mg-edit"
            variant="icon"
            iconSet="minga"
            tooltip="Edit"
            (pressed)="openEditModal(item.id)">
          </mg-btn>
        </mat-cell>
      </ng-container>

      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <mat-header-cell
          *matHeaderCellDef
          class="w-12 text-center">
          <mg-text
            class="column-header"
            variant="body-xs"
            color="grey-300">
            {{ MESSAGES.LIST_TABLE_ACTIVE }}
          </mg-text>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let item"
          class="w-12 text-right">
          <div class="active-toggle">
            <mg-form-toggle
              [disabled]="(togglesInFlight$ | async).has(item.id)"
              [value]="item?.active"
              (valueChange)="clickToggleActive(item)"></mg-form-toggle>
            <div
              class="active-toggle-spinner"
              *ngIf="(togglesInFlight$ | async).has(item.id)">
              <mg-spinner
                [diameter]="20"
                [thickness]="2">
              </mg-spinner>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="TABLE_COLUMNS"> </mat-header-row>
      <mat-row *matRowDef="let row; columns: TABLE_COLUMNS"> </mat-row>
    </mat-table>
    <div
      fxLayout="row"
      fxLayoutAlign="flex-end center">
      <mg-btn
        variant="outlined"
        (pressed)="openEditModal()">
        {{ MESSAGES.LIST_ADD_BTN }}
      </mg-btn>
    </div>
  </main>
</ng-template>

<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [variant]="'text'"
        (pressed)="cancel()">
        {{ MESSAGES.LIST_MODAL_BACK }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
