import { Action } from '@ngrx/store';
import { Group, MingaGroupMemberRank } from '../models/Group';
import { GroupDetails } from '../models/GroupDetails';

export interface IGroupEffectPayload {
  hash?: string;
  message: string;
}

export namespace GroupMemberActions {
  export enum TypeEnum {
    JoinGroup = '[Group Member] Join Group',
    JoinGroupNoConfirm = '[Group Member] Join Group No Confirmation',
    JoinGroupSuccess = '[View Group Page] Join Group Success',
    JoinGroupFailure = '[View Group Page] Join Group Failure',
    CancelJoinGroup = '[Group Member] Cancel Join Group',
    CancelJoinGroupSuccess = '[View Group Page] Cancel Join Group Success',
    CancelJoinGroupFailure = '[View Group Page] Cancel Join Group Failure',
    LeaveGroup = '[Group Member] Leave Group',
    AcceptGroupMember = '[Group Member] Accept Group Member',
    AcceptGroupMemberFailure = '[Group Member] Accept Group Member Failure',
    AcceptGroupMemberSuccess = '[Group Member] Accept Group Member Success',
    DeclineGroupMember = '[Group Member] Decline Group Member',
    DeclineGroupMemberFailure = '[Group Member] Decline Group Member Failure',
    DeclineGroupMemberSuccess = '[Group Member] Decline Group Member Success',
    RemoveGroupMember = '[Group Member] Remove Group Member',
    RemoveGroupMemberSuccess = '[Group Member] Remove Group Member Success',
    RemoveGroupMemberFailure = '[Group Member] Remove Group Member Failure',
    UpdateGroupMembers = '[Group Members] Update Group Members',
    UpdateGroupMembersSuccess = '[Group Members] Update Group Members Success',
    UpdateGroupMembersFailure = '[Group Members] Update Group Members Failure',
    AddGroupMembers = '[Group Members] Add Group Members',
    AddGroupMembersSuccess = '[Group Members] Add Group Members Success',
    AddGroupMembersFailure = '[Group Members] Add Group Members Failure',
    RemoveGroupMembers = '[Group Members] Remove Group Members',
  }

  export interface GroupMemberAction extends Action {
    readonly type: TypeEnum;
  }

  export class JoinGroup implements GroupMemberAction {
    readonly type = TypeEnum.JoinGroup;

    constructor(public payload: Group) {}
  }

  export class JoinGroupNoConfirm implements GroupMemberAction {
    readonly type = TypeEnum.JoinGroupNoConfirm;

    constructor(public payload: Group) {}
  }

  export class JoinGroupSuccess implements GroupMemberAction {
    readonly type = TypeEnum.JoinGroupSuccess;

    constructor(public payload: Group) {}
  }

  export class JoinGroupFailure implements GroupMemberAction {
    readonly type = TypeEnum.JoinGroupFailure;

    constructor(public payload: IGroupEffectPayload) {}
  }

  export class CancelJoinGroup implements GroupMemberAction {
    readonly type = TypeEnum.CancelJoinGroup;

    constructor(public payload: Group) {}
  }

  export class CancelJoinGroupSuccess implements GroupMemberAction {
    readonly type = TypeEnum.CancelJoinGroupSuccess;

    constructor(public payload: Group) {}
  }

  export class CancelJoinGroupFailure implements GroupMemberAction {
    readonly type = TypeEnum.CancelJoinGroupFailure;

    constructor(public payload: IGroupEffectPayload) {}
  }

  export class LeaveGroup implements GroupMemberAction {
    readonly type = TypeEnum.LeaveGroup;

    constructor(public payload: Group, public noSuccessInterstitial: boolean) {}
  }

  export class AcceptGroupMember implements GroupMemberAction {
    readonly type = TypeEnum.AcceptGroupMember;

    constructor(public payload: Group, public memberPersonHash: string) {}
  }

  export class AcceptGroupMemberSuccess implements GroupMemberAction {
    readonly type = TypeEnum.AcceptGroupMemberSuccess;

    constructor(public payload: GroupDetails) {}
  }

  export class AcceptGroupMemberFailure implements GroupMemberAction {
    readonly type = TypeEnum.AcceptGroupMemberFailure;

    constructor(public payload: IGroupEffectPayload) {}
  }

  export class DeclineGroupMember implements GroupMemberAction {
    readonly type = TypeEnum.DeclineGroupMember;

    constructor(public payload: Group, public memberPersonHash: string) {}
  }

  export class DeclineGroupMemberSuccess implements GroupMemberAction {
    readonly type = TypeEnum.DeclineGroupMemberSuccess;

    constructor(public payload: GroupDetails) {}
  }

  export class DeclineGroupMemberFailure implements GroupMemberAction {
    readonly type = TypeEnum.DeclineGroupMemberFailure;

    constructor(public payload: IGroupEffectPayload) {}
  }

  export class RemoveGroupMember implements GroupMemberAction {
    readonly type = TypeEnum.RemoveGroupMember;

    constructor(
      public group: Group,
      public memberPersonHash: string,
      public noSuccessInterstitial: boolean,
    ) {}
  }

  export class RemoveGroupMemberSuccess implements GroupMemberAction {
    readonly type = TypeEnum.RemoveGroupMemberSuccess;

    constructor(
      public payload: GroupDetails,
      public noSuccessInterstitial: boolean,
    ) {}
  }
  export class RemoveGroupMemberFailure implements GroupMemberAction {
    readonly type = TypeEnum.RemoveGroupMemberFailure;

    constructor(public payload: IGroupEffectPayload) {}
  }

  export class UpdateGroupMembers implements GroupMemberAction {
    readonly type = TypeEnum.UpdateGroupMembers;

    constructor(
      public groupHash: string,
      public memberUpdates: Map<string, MingaGroupMemberRank | null>,
    ) {}
  }
  export class UpdateGroupMembersSuccess implements GroupMemberAction {
    readonly type = TypeEnum.UpdateGroupMembersSuccess;

    constructor(public payload: GroupDetails) {}
  }
  export class UpdateGroupMembersFailure implements GroupMemberAction {
    readonly type = TypeEnum.UpdateGroupMembersFailure;

    constructor(public payload: IGroupEffectPayload) {}
  }

  export class AddGroupMembers implements GroupMemberAction {
    readonly type = TypeEnum.AddGroupMembers;

    constructor(public groupHash: string, public memberHashes: string[]) {}
  }
  export class AddGroupMembersSuccess implements GroupMemberAction {
    readonly type = TypeEnum.AddGroupMembersSuccess;

    constructor(public payload: GroupDetails) {}
  }
  export class AddGroupMembersFailure implements GroupMemberAction {
    readonly type = TypeEnum.AddGroupMembersFailure;

    constructor(public payload: IGroupEffectPayload) {}
  }

  export class RemoveGroupMembers implements GroupMemberAction {
    readonly type = TypeEnum.RemoveGroupMembers;

    constructor(public groupHash: string, public memberHashes: string[]) {}
  }

  export type TypeUnion =
    | JoinGroup
    | CancelJoinGroup
    | LeaveGroup
    | AcceptGroupMember
    | DeclineGroupMember
    | RemoveGroupMember
    | JoinGroupSuccess
    | JoinGroupFailure
    | CancelJoinGroupFailure
    | CancelJoinGroupSuccess
    | AcceptGroupMemberFailure
    | AcceptGroupMemberSuccess
    | RemoveGroupMemberFailure
    | RemoveGroupMemberSuccess
    | DeclineGroupMember
    | DeclineGroupMemberFailure
    | DeclineGroupMemberSuccess
    | UpdateGroupMembers
    | UpdateGroupMembersSuccess
    | UpdateGroupMembersFailure
    | AddGroupMembers
    | AddGroupMembersSuccess
    | AddGroupMembersFailure
    | RemoveGroupMembers
    | JoinGroupNoConfirm;
}
