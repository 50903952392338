<mat-drawer-container
  class="mg-sidenav-container"
  [class.fixed]="fixed"
  [class.mobile]="mobileFlag">
  <mat-drawer-content class="mg-sidenav-content">
    <ng-content></ng-content>
  </mat-drawer-content>

  <mat-drawer
    #sidenav
    class="mg-sidenav"
    [class.fixed]="fixed"
    [class.disabled]="disabled"
    [mode]="mode"
    [opened]="!fixed || open"
    [position]="'end'"
    (keydown.escape)="(escapeKeyCloseNav)"
    (openedChange)="onOpenedChange($event)">
    <div
      class="close-icon-wrap"
      (click)="closeSideNav($event)">
      <div
        class="close-icon"
        [class.open]="!fixed || open">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <mg-navigation-drawer
      #navDrawerEl
      *ngIf="!disabled"
      [showProfile]="fixed"
      [mingaName]="mingaName"
      [mingaLogo]="mingaLogo"
      [person]="person$ | async"
      (closeNavigation)="onOpenedChange(!$event)"
      (swiperight)="onSwipeRight()">
    </mg-navigation-drawer>
  </mat-drawer>
</mat-drawer-container>
