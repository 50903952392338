<div class="container">
  <button
    *ngFor="let option of options$ | async"
    class="button"
    matRipple
    [matRippleDisabled]="readonly"
    [matRippleColor]="'rgba(255, 255, 255, .10)'"
    [title]="option"
    [class.isActive]="activeOptions$ | async | includes: option"
    [class.isDisabled]="readonly"
    (click)="toggleOption(option)">
    {{ option | substring: 0:1 }}
  </button>
</div>
