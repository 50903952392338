import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgIconModule } from 'minga/app/src/app/icon';
import { ScrollTargetDirectiveModule } from 'minga/app/src/app/misc/ScrollTarget/directive';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import {
  SelectFieldsCheckinReasonsComponent,
  SelectFieldsGenericComponent,
  SelectFieldsGradeComponent,
  SelectFieldsRoleComponent,
} from './components';

/**
 * Select Fields Module
 *
 * A bunch of reusable components to be used for the manager-overlay reports.
 */
@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    MgFormFieldModule,
    ScrollTargetDirectiveModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  exports: [
    SelectFieldsGradeComponent,
    SelectFieldsCheckinReasonsComponent,
    SelectFieldsRoleComponent,
    SelectFieldsGenericComponent,
  ],
  declarations: [
    SelectFieldsGradeComponent,
    SelectFieldsCheckinReasonsComponent,
    SelectFieldsRoleComponent,
    SelectFieldsGenericComponent,
  ],
})
export class SelectFieldsModule {}
