import { Location } from '@angular/common';
import { Router } from '@angular/router';

let locationPath = '';

export const startMultiOutletHack = (location: Location) => {
  const currentLocationPath = location.path();
  if (currentLocationPath.startsWith('/(')) {
    locationPath = currentLocationPath;
    location.go('/');
    console.warn(
      `(FIXME) Removing initially active outlets from path: ${locationPath}`,
    );
  }
};

export const finishMultiOutletHack = (location: Location, router: Router) => {
  if (!location.path().startsWith('/(') && locationPath) {
    router.navigateByUrl(locationPath, {
      replaceUrl: true,
    });
    return true;
  } else if (locationPath) {
    console.error(
      `Could not restore initially active outlet because another outlet was already active.`,
    );
  }
  return false;
};
