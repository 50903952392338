<div class="settings">
  <!-- Membership List Settings -->
  <section
    *ngIf="'MINGA_PEOPLE_MANAGE' | hasPermission"
    class="section">
    <mg-membership-list-table
      [types]="LIST_TYPES"
      [hideHeader]="true"
      [autoCreate]="true">
    </mg-membership-list-table>
  </section>
  <!-- Enable Tools Settings -->
  <section class="section">
    <mg-text variant="header-md-secondary">
      {{ MESSAGES.SUBHEADING_CHECK_IN_TITLE }}
    </mg-text>
    <div
      fxLayout="column"
      fxLayoutGap="12px">
      <ng-container *ngFor="let setting of checkinToggleSettings">
        <mg-setting-toggle
          *ngIf="
            setting.settingName !== mingaSettingTypes.CHECKIN_KIOSK ||
            kioskPermissions.isKioskFeatureEnabled()
          "
          [setting]="setting.settingName"
          [label]="setting.name"
          (valueChange)="openDisclaimer($event, setting)">
        </mg-setting-toggle>
      </ng-container>
    </div>
  </section>
</div>
