import { MediaBreakpoints } from '@shared/services/media';

export enum FtmPeriodsMessages {
  BUTTON_LABEL_NEW = 'Create period',

  COLUMN_LABEL_TITLE = 'Title',
  COLUMN_LABEL_DATE = 'Date',
  COLUMN_LABEL_TIME = 'Time',
  COLUMN_LABEL_STATUS = 'Status',
  COLUMN_LABEL_ALL_ACTIVITIES = 'All Activities',
  COLUMN_LABEL_MY_ACTIVITIES = 'My Activities',
  COLUMN_LABEL_TOTAL_REGISTERED = 'Total Registered',
  COLUMN_LABEL_CLONE = 'Clone',
  COLUMN_LABEL_EDIT = 'Edit',

  BUTTON_LABEL_ADD_ACTIVITY = 'Add my activity',

  FIELD_LABEL_SEARCH = 'Search by title',

  EMPTY_STATE_TITLE = 'No results found',
  EMPTY_STATE_SUBTITLE = 'You have no flex periods yet or your search did not match any results.',
}

export const FTM_PERIODS_DISPLAY_COLUMNS = [
  'title',
  'date',
  'time',
  'status',
  'activities',
  'myActivities',
];

export enum PeriodLockingIcons {
  ADD_LOCKED = 'lock-icon',
  TEACHER_LOCKED = 'lock-icon-t',
  STUDENT_LOCKED = 'lock-icon-s',
  ADD_UNLOCKED = 'unlock-icon',
  TEACHER_UNLOCKED = 'unlock-t-icon',
  STUDENT_UNLOCKED = 'unlock-s-icon',
}

export enum PeriodLockingIconSizes {
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const MOBILE_BREAKPOINTS: MediaBreakpoints[] = [
  'xsmall',
  'small',
  'medium',
  'medium-large',
];
