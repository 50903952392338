import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { IdComponent } from './id.component';
import { ID_ROUTES } from './id.routes';

@NgModule({
  imports: [
    // Routing
    RouterModule.forChild(ID_ROUTES),

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
  ],
  declarations: [IdComponent],
  exports: [RouterModule],
})
export class IdModule {}
