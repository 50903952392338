<div class="setting-row">
  <div>
    <mg-text
      element="span"
      [variant]="variant"
      [color]="disabled ? 'on-disabled-bg' : labelColor">
      {{ label }}
    </mg-text>
    <mg-tooltip
      *ngIf="helpTooltip"
      [label]="helpTooltip">
    </mg-tooltip>
  </div>

  <mat-slide-toggle
    [disabled]="disabled"
    [attr.data-analytics]="id"
    [attr.data-test]="id"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)">
  </mat-slide-toggle>
</div>
