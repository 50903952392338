import { Pipe, PipeTransform } from '@angular/core';

import { HallPassStatusEnum } from 'minga/domain/hallPass';

import { HpmDashboardTableItem } from '../types';

@Pipe({ name: 'hpmEndTime' })
export class HpmDashboardEndTimePipe implements PipeTransform {
  transform(value: HpmDashboardTableItem) {
    if (value.status.state === HallPassStatusEnum.SCHEDULED) {
      return value.status.expire;
    } else return value.status.end;
  }
}
