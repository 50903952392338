import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {
  IPageInfo,
  VirtualScrollerComponent,
} from '@minga/ngx-virtual-scroller';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PagedScrollerDatasource } from 'src/app/components/mg-paged-scroller/PagedScrollerDatasource';
import { ScrollTargetService } from 'src/app/misc/ScrollTarget/service';
import { IMgStreamFilter, IMgStreamItem } from 'src/app/util/stream';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { MgPagedScrollerMessages } from './mg-paged-scroller.constants';

/**
 * Paged Scroller
 */
@Component({
  selector: 'mg-paged-scroller',
  templateUrl: './mg-paged-scroller.component.html',
  styleUrls: ['./mg-paged-scroller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPagedScrollerComponent<T> implements OnInit, OnDestroy {
  @ViewChild('virtualScroller', { static: false })
  virtualScroller?: VirtualScrollerComponent;

  @ContentChild('streamItemTemplate', { static: true })
  streamItemTemplate: TemplateRef<any>;

  /** Messages */
  MESSAGES: typeof MgPagedScrollerMessages = MgPagedScrollerMessages;

  /** Subscription Cleanup Helper */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Items */
  private readonly _items = new BehaviorSubject<IMgStreamItem<T>[]>([]);
  readonly items$: Observable<IMgStreamItem<T>[]> = this._items.asObservable();

  /** Filters */
  private _filterSubj = new BehaviorSubject<IMgStreamFilter | null>(null);
  private _filter$ = this._filterSubj.asObservable();

  /** Loading */
  public loading$: Observable<boolean>;

  @Input()
  dataSource: PagedScrollerDatasource<T> | null = null;

  @Input()
  filter: IMgStreamFilter | null = null;

  /**
   * The number of elements to be rendered above & below the current virtual
   * scroller viewport. Defaults to `5`, you may need to increase this for
   * smaller elements.
   */
  @Input()
  bufferAmount: number = 5;

  /**
   * How many items close to the top or bottom should a load trigger
   */
  @Input()
  loadThreshold: number = 5;

  @HostBinding('class.mg-horizontal')
  @Input()
  horizontal: boolean = false;

  get parentScroll() {
    return this.horizontal
      ? undefined
      : this.scrollTargetService.getScrollTarget();
  }

  isLoading: boolean = true;

  /** Component Consturctor */
  constructor(
    private scrollTargetService: ScrollTargetService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _cdr: ChangeDetectorRef,
  ) {
    this.scrollTargetService.scrollTargetChange$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => this.virtualScroller?.refresh());
  }

  /** Component Lifecycle: On Mount */
  ngOnInit(): void {
    /** Data Source Observable */
    this.dataSource
      .connect(this._filter$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(items => {
        this._items.next(items);
      });
    /** Loading Observable */
    this.loading$ = this.dataSource.loading$;
    /** Error Observable */
    this.dataSource.error$.pipe(takeUntil(this._destroyed$)).subscribe(err => {
      if (err) {
        console.error(err);
        this._systemAlertSnackBar.error(MgPagedScrollerMessages.FETCH_ERROR);
        this._cdr.markForCheck();
      }
    });
  }

  /** Component Lifecycle: On Changes */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter) {
      this._updateFilter();
    }
  }

  /** Component Lifecycle: On Unmounting */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.dataSource?.disconnect();
  }

  private _updateFilter() {
    this._filterSubj.next(this.filter);
  }

  _onVsEnd(pageInfo: IPageInfo) {
    this.dataSource.viewedIndexChange(pageInfo.endIndex);
  }

  trackBy = (index: number, item: IMgStreamItem<any>) => {
    return item.itemId;
  };
}
