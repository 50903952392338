import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { MgIconModule } from '../icon/MgIcon.module';

import { BlockButton } from './BlockButton/BlockButton.component';
import { MgButtonComponent } from './Button/Button.component';
import { ClearButton } from './ClearButton/ClearButton.component';
import { IconButton } from './IconButton/IconButton.component';
import { OutlineButton } from './OutlineButton/OutlineButton.component';

@NgModule({
  imports: [MatButtonModule, MgIconModule],
  declarations: [
    MgButtonComponent,
    BlockButton,
    ClearButton,
    IconButton,
    OutlineButton,
  ],
  exports: [
    MgButtonComponent,
    BlockButton,
    ClearButton,
    IconButton,
    OutlineButton,
  ],
})
export class MgButtonModule {}
