<div class="mg-file-upload">
  <div
    class="preview-file"
    *ngIf="!correctPreviewAspectRatio || !previewUrl"
    [style.paddingBottom]="previewAspectRatioPadding"
    [style.backgroundImage]="'url(' + previewUrl + ')'"></div>

  <div *ngIf="correctPreviewAspectRatio && previewUrl">
    <img
      width="100%"
      [src]="previewUrl" />
  </div>

  <div
    class="file-upload-overlay"
    *ngIf="
      uploadStatus != FileUploadStatus.DONE &&
      uploadStatus != FileUploadStatus.ERROR_UNKNOWN
    ">
    <span class="percent-value">{{ progress / 100 | percent: '1.0-0' }}</span>
  </div>
  <div
    class="file-progress"
    [ngSwitch]="uploadStatus">
    <mat-progress-spinner
      *ngSwitchCase="FileUploadStatus.UPLOADING"
      [diameter]="spinnerSize"
      mode="determinate"
      [value]="progress">
    </mat-progress-spinner>
  </div>

  <div
    class="preview-pending-overlay"
    *ngIf="!previewUrl"></div>

  <div
    class="file-upload-errorlay"
    *ngIf="uploadStatus == FileUploadStatus.ERROR_UNKNOWN"
    (click)="displayError()">
    <mat-icon>error</mat-icon>
  </div>
</div>
