import { NgModule } from '@angular/core';

import { ArrayIncludesPipe } from './array-includes.pipe';
import { ArraySomePipe } from './array-some.pipe';

@NgModule({
  declarations: [ArrayIncludesPipe, ArraySomePipe],
  exports: [ArrayIncludesPipe, ArraySomePipe],
})
export class ArrayPipesModule {}
