import { Pipe, PipeTransform } from '@angular/core';

import * as dayjs from 'dayjs';
import 'dayjs/plugin/duration';

@Pipe({
  name: 'secondsToTime',
})
export class MgSecondsToTimePipe implements PipeTransform {
  transform(value: number): string {
    const seconds = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(seconds)) return '00:00:00';

    const durationObject = dayjs.duration(Math.abs(seconds), 'seconds');
    const formattedTime = durationObject.format('HH:mm:ss');

    return seconds < 0 ? `+${formattedTime}` : formattedTime;
  }
}
