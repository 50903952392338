import { ChangeDetectionStrategy, Component } from '@angular/core';

export interface IMgVideoPlayerIconElementProperties {}

@Component({
  selector: 'mg-video-player-icon',
  templateUrl: './MgVideoPlayerIcon.element.html',
  styleUrls: ['./MgVideoPlayerIcon.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgVideoPlayerIconElement {}
