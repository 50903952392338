import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'mg-points-banner',
  templateUrl: './PointsBanner.component.html',
  styleUrls: ['./PointsBanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointsBannerComponent {
  @Input()
  points: number = 0;

  constructor() {}
}
