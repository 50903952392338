import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { DateFeedSeparator } from 'minga/app/src/app/services/DateFeedSeparator';

@Component({
  selector: 'mg-date-separator',
  templateUrl: './MgDateSeparator.element.html',
  styleUrls: ['./MgDateSeparator.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgDateSeparatorElement implements OnChanges {
  @Input()
  timestamp?: number;

  @Input()
  identifier?: string;

  @Input()
  text: string = '';

  @Input()
  streamName: string = '';

  constructor(public dateSeparatorService: DateFeedSeparator) {}

  get displayText() {
    if (this.text) {
      return this.text;
    } else if (this.timestamp) {
      return this.dateSeparatorService.getSeparatorDate(
        this.timestamp || 0,
        this.identifier || `${this.timestamp}`,
        this.streamName,
      );
    } else {
      return '';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.timestamp || changes.identifier || changes.streamName) {
      if (this.timestamp) {
        this.dateSeparatorService.initSeparatorItem(
          this.timestamp || 0,
          this.identifier || `${this.timestamp}`,
          this.streamName,
        );
      }
    }
  }
}
