<div
  *ngIf="resolvedImage; else placeholder"
  class="activities-image">
  <mg-image
    [style.aspect-ratio]="aspectRatio"
    [srcs]="
      resolvedImage
        | mgImageUrls
          : ['blurloading1', ['studio', 'bannerlibpreview', 'cardbanner']]
    ">
  </mg-image>
</div>
<ng-template #placeholder>
  <div
    [style.aspect-ratio]="aspectRatio"
    class="asset-placeholder">
    <mg-icon>FlexTime-o</mg-icon>
  </div>
</ng-template>
