import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { NotificationManager } from 'minga/proto/gateway/notification_ng_grpc_pb';
import { MarkNotificationsReadRequest } from 'minga/proto/gateway/notification_pb';
import {
  MarkNotificationsViewedRequest,
  Notification,
} from 'minga/proto/gateway/notification_pb';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { NotificationActions } from '../actions/notifications.actions';
import { NotificationsState } from '../selectors';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions<NotificationActions.TypeUnion>,
    private notificationManager: NotificationManager,
    private store: Store<any>,
  ) {}

  @Effect()
  viewedNotifications$: Observable<NotificationActions.RemoveNotificationsAction> =
    this.actions$.pipe(
      ofType(NotificationActions.TypeEnum.ViewedNotifications),
      withLatestFrom(this.store.pipe(select(NotificationsState.selectAll))),
      map(([action, notifications]) => {
        const notificationIds = <string[]>notifications.map(item => item.id);
        const markViewedRequest = new MarkNotificationsViewedRequest();
        markViewedRequest.setNotificationIdList(notificationIds);
        this.notificationManager.markViewed(markViewedRequest);

        // remove the notifications from the store, because those should
        // only be unviewed notifications.
        return new NotificationActions.RemoveNotificationsAction(
          notificationIds,
        );
      }),
    );

  /**
   * Mark a notification as read.
   */

  @Effect({ dispatch: false })
  markNotificationAsRead$: Observable<void> = this.actions$.pipe(
    ofType(NotificationActions.TypeEnum.MarkNotificationAsRead),
    map(
      (action: NotificationActions.MarkNotificationAsReadAction) =>
        action.payload,
    ),
    withLatestFrom(this.store.pipe(select(NotificationsState.selectAll))),
    map(([notification, notifications]) => {
      let notificationsToRemove: any[] = [];
      if (notification.id) {
        const readNotification = notifications.find(
          e => e.groupHash === notification.groupHash,
        );
        if (readNotification) {
          notification.groupHash = readNotification.groupHash;
        }
      }

      if (notification.groupHash) {
        // if there is a group hash, let's remove any notifications to do with
        // that group ie clicking one 'new content' notification for a group,
        // marks all new content for that group as read.
        notificationsToRemove = notifications.filter(
          e => e.groupHash === notification.groupHash,
        );
      }

      notificationsToRemove.push(notification);

      if (notificationsToRemove.length > 0) {
        // update the store to remove any notifications that were read.
        const notificationIds = notificationsToRemove.map(n => n.id);
        this.setNotificationAsRead(notificationIds);
        this.store.dispatch(
          new NotificationActions.RemoveNotificationsAction(notificationIds),
        );
      }
    }),
  );

  setNotificationAsRead(ids: string[]) {
    const markReadRequest = new MarkNotificationsReadRequest();
    markReadRequest.setNotificationIdList(ids);
    this.notificationManager.markRead(markReadRequest);
  }
}
