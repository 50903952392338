import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class HybridOnlyGuard implements CanActivate {
  constructor() {}

  canActivate(): boolean {
    return !window.MINGA_APP_BROWSER;
  }
}
