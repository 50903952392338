import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IActiveLayoutComponentService } from './IActiveLayoutComponentService';
import { LayoutComponentBase } from './LayoutComponentBase';

@Injectable({ providedIn: 'root' })
export class ActiveLayoutService implements IActiveLayoutComponentService {
  private _layoutComponents: { [keyname: string]: LayoutComponentBase[] };
  private _activeLayoutsSubject = new BehaviorSubject<string[]>([]);

  activeLayouts$: Observable<string[]>;

  get activeLayouts(): string[] {
    return Object.keys(this._layoutComponents);
  }

  constructor() {
    this._layoutComponents = {};
    this.activeLayouts$ = this._activeLayoutsSubject.asObservable();
  }

  /**
   * Create observable that returns true when layout with `layoutName` is active
   * and `false` when it is not.
   * @param layoutName Name of layout
   */
  observeLayoutActive(layoutName: string): Observable<boolean> {
    return this.activeLayouts$.pipe(
      map(layouts => layouts.includes(layoutName)),
    );
  }

  private getLayoutComponents(layoutName: string): LayoutComponentBase[] {
    if (!this._layoutComponents[layoutName]) {
      this._layoutComponents[layoutName] = [];
    }

    return this._layoutComponents[layoutName];
  }

  private updateActiveLayoutsObservable() {
    const layoutClassPrefix = 'mg-layout-';
    const activeLayouts = this.activeLayouts;
    const layoutClasses = this.activeLayouts.map(l => layoutClassPrefix + l);
    const body = document.body;

    this._activeLayoutsSubject.next(activeLayouts);

    body.classList.forEach(c => {
      if (c.startsWith(layoutClassPrefix)) {
        body.classList.remove(c);
      }
    });

    layoutClasses.forEach(c => body.classList.add(c));
  }

  /**
   * INTERNAL - should only be used by `LayoutComponentBase`
   */
  _layoutComponentInit(comp: LayoutComponentBase) {
    const layoutComponents = this.getLayoutComponents(comp.layoutName);
    layoutComponents.push(comp);

    this.updateActiveLayoutsObservable();
  }

  /**
   * INTERNAL - should only be used by `LayoutComponentBase`
   */
  _layoutComponentDestroy(comp: LayoutComponentBase) {
    const layoutComponents = this.getLayoutComponents(comp.layoutName);
    const index = layoutComponents.indexOf(comp);
    if (index !== -1) {
      layoutComponents.splice(index, 1);
    }

    if (layoutComponents.length === 0) {
      delete this._layoutComponents[comp.layoutName];
    }

    this.updateActiveLayoutsObservable();
  }
}
