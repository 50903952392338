import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { $enum } from 'ts-enum-util';

import { INotificationType } from 'minga/domain/notification';
import { Notification } from 'minga/proto/gateway/notification_pb';
import { NotificationActions } from 'src/app/store/Notifications/actions';
import { NotificationStoreModel } from 'src/app/store/Notifications/model';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';

@Injectable({ providedIn: 'root' })
export class FlexTimeNotificationHandlerService {
  constructor(
    private _systemAlertModal: SystemAlertModalService,
    private _store: Store<any>,
  ) {}

  public isFlexTimeNotification(type: string) {
    if (!type) return false;
    const flexTimeNotificationTypes = [
      INotificationType.FLEX_TIME_ACTIVITY_ASSIGNED,
      INotificationType.FLEX_TIME_ACTIVITY_REGISTERED,
      INotificationType.FLEX_TIME_ACTIVITY_UNASSIGNED,
      INotificationType.FLEX_TIME_ACTIVITY_REMOVED,
    ];

    return flexTimeNotificationTypes.includes(
      $enum(INotificationType).asValueOrDefault(type, null),
    );
  }

  public handleFlexTimeNotification(msg: Notification.AsObject) {
    this._openNotificationOverlay(msg);

    const notification: NotificationStoreModel = { id: msg.notificationId };
    this._store.dispatch(
      new NotificationActions.MarkNotificationAsReadAction(notification),
    );
  }

  private _openNotificationOverlay(msg: Notification.AsObject) {
    this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: msg.title,
      iconColor: 'flextime',
      message: msg.body,
      icon: msg.iconUrl,
      hangTime: SystemAlertModalHangTime.LONG,
    });
  }
}
