export enum Messages {
  TITLE_TOOLS = 'My Tools',

  // Section Titles
  TITLE_ID_TOOLS = 'Digital ID',
  TITLE_HALL_PASS_TOOLS = 'Hall Pass',
  TITLE_PBIS_TOOLS = 'Behavior Tools',
  TITLE_PBIS_REWARD_TOOLS = 'Behavior & Rewards',
  TITLE_CHECKIN_TOOLS = 'Check in',
  TITLE_FLEXTIME_TOOLS = 'FlexTime',
  TITLE_MY_GROUPS = 'My Groups',
  TITLE_KIOSK = 'Kiosk',

  // ID Tools Strings
  ID_TOOLS_YOUR_ID = 'View Your ID',
  ID_TOOLS_DASHBOARD = 'View ID Dashboard',

  // Hall Pass Tools Strings
  HALL_PASS_SCAN = 'Scan An ID',
  HALL_PASS_SEARCH = 'Search User',
  HALL_PASS_ACTIVE = 'View Active Passes',
  HALL_PASS_CREATE = 'Assign a Hall Pass',
  HALL_PASS_VIEW = 'View Pass Dashboard',

  // Behavior Tools Strings
  PBIS_PRAISE = 'Assign a Praise',
  PBIS_GUIDANCE = 'Assign a Guidance',
  PBIS_CONSEQUENCE = 'Assign a Consequence',
  PBIS_REDEEM_POINTS = 'Redeem Points',
  PBIS_MANAGE_POINTS = 'Manage Points',

  // Check In Tools Strings
  REASONS_CHECKIN = 'Assign a Check In',

  // Flex Time Tools
  FLEX_TIME_NAVIGATE = 'FlexTime Dashboard',
  FLEX_TIME_ASSIGN = 'Assign an Activity',
  ASSIGN_SUCCESS_TITLE = 'Activity Assigned!',
  REGISTRATION_SUCCESS_TITLE = 'Activity Registered!',
  REGISTRATION_ERROR = 'There was a problem assigning this activity, please try again',

  // Kiosk Strings
  KIOSK_MODE = 'Kiosk mode',
  KIOSK_BETA = 'Beta',

  // GROUP STRINGS
  GROUP_DESCRIPTION = 'See new content in my groups',
  GROUP_VIEW_ALL = 'Go to groups',

  // SNACK BAR MESSAGES
  SNACKBAR_FAILED_URL = 'Failed to open page',

  NOTIFICATIONS_TITLE = 'Notifications',

  NAV_MY_TOOLS = 'My Tools',
  NAV_NOTIFICATIONS = 'Notifications',
  NAV_POINTS = 'Leaderboard',
}

export enum PBIS_CHECKIN_TYPES {
  PRAISE = 'praise',
  GUIDANCE = 'guidance',
  CONSEQUENCE = 'consequence',
  REWARDS = 'rewards',
  MANAGE_POINTS = 'manage_points',
}
