import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  HostListener,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

/**
 * Convenience component for creating a dialog/prompt with our default styles
 * and slots pre-setup.
 *
 * @example
 * ```HTML
 * <mg-dialog #myDialog>
 *   <mg-dialog-title>My Dialog Title<mg-dialog-title>
 *   <mg-dialog-content>My dialog content!</mg-dialog-content>
 *  <button [mg-dialog-action]="'hello'">Close with 'hello'</button>
 *   <button [mg-dialog-action]="true">Close with true</button>
 * </mg-dialog>
 *
 * <button (click)="myDialog.open()">Open Dialog</button>
 * ```
 */
@Component({
  selector: 'mg-dialog',
  templateUrl: './Dialog.component.html',
  styleUrls: ['./Dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  private _dialogRef: MatDialogRef<any> | null = null;

  @ViewChild('matDialogTemplate', { static: true })
  matDialogTemplate?: TemplateRef<any>;

  constructor(private matDialog: MatDialog) {}

  open(): MatDialogRef<any> {
    if (!this.matDialogTemplate) {
      throw new Error('mg-dialog template unset');
    }

    this._dialogRef = this.matDialog.open(this.matDialogTemplate, {
      panelClass: 'mg-normal-padding-dialog',
    });

    this._dialogRef.afterClosed().subscribe(() => {
      this._dialogRef = null;
    });

    return this._dialogRef;
  }

  close(value: any) {
    if (this._dialogRef) {
      this._dialogRef.close(value);
    } else {
      console.warn('Tried to close dialog before opening');
    }
  }
}

@Component({
  selector: 'mg-dialog-content',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogSlot_Content {
  constructor(private parentDialogComponent: DialogComponent) {}
}

@Component({
  selector: 'mg-dialog-title',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogSlot_Title {
  constructor(private parentDialogComponent: DialogComponent) {}
}

@Directive({
  selector: '[mg-dialog-action]',
})
export class DialogSlot_Action {
  /** @internal */
  private _actionValue: any;

  @Input('mg-dialog-action')
  set actionValue(value: any) {
    this._actionValue = value;
  }

  constructor(private parentDialogComponent: DialogComponent) {}

  @HostListener('click', ['$event'])
  _onClick(ev: MouseEvent) {
    if (ev.defaultPrevented) return;

    this.parentDialogComponent.close(this._actionValue);
  }
}
