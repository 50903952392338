import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { PillCheckboxComponent } from './PillCheckbox.component';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    FormsModule,
    CommonModule,
    MatIconModule,
  ],
  declarations: [PillCheckboxComponent],
  exports: [PillCheckboxComponent],
})
export class PillCheckboxModule {}
