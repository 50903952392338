import { Injectable } from '@angular/core';
import { Data, Router } from '@angular/router';

import { NavPermissions } from 'minga/app/src/app/minimal/services/NavPermissions';

/**
 * Hall Pass Manager Routes
 */
@Injectable({ providedIn: 'root' })
export class HpmRoutesService {
  private _originPath: string = '';

  /** Service Constructor */
  constructor(public router: Router, private _navPermissions: NavPermissions) {}

  /**
   * Return From Hall Pass Manager Route
   */
  returnFromHallpassManagerRoute() {
    if (this._originPath == this.router.url) {
      this.router.navigateByUrl('/');
    } else {
      this.router.navigateByUrl(this._originPath || '/');
    }
  }
}
