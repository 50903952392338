<!-- Modal Container -->
<mg-overlay-primary
  #overlayElement
  size="full"
  [isLoading]="isLoading$ | async"
  [overlayTitle]="form.formTitle || form.config.title"
  [skipLoadCheck]="true">
  <ng-container *ngTemplateOutlet="mainContent"></ng-container>
  <ng-template #overlayFooter>
    <ng-container
      *ngIf="!form.isReadOnly && !(form.pages.activePage$ | async).isReadOnly"
      [ngTemplateOutlet]="footerContent">
    </ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainContent>
  <ng-container *ngTemplateOutlet="sideBarTemplate"></ng-container>
  <main
    fxFlex="grow"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="16px"
    fxLayoutGap.xs="0px"
    class="container-body">
    <!-- Quick Actions -->
    <ng-container
      *ngIf="showQuickActions$ | async"
      [ngTemplateOutlet]="quickActionsTemplate">
    </ng-container>
    <!-- Data Table -->
    <mg-ps-table
      fxFlex="grow"
      [form]="form">
    </mg-ps-table>
  </main>
</ng-template>

<!-- SideBar Template -->
<ng-template #sideBarTemplate>
  <aside
    class="container-sidebar"
    fxFlex="nogrow">
    <ng-container *ngIf="(form.pages.isMultiPage$ | async) === true">
      <mg-text
        variant="body-md-secondary"
        textAlign="left"
        spacing="3">
        {{ MSG.ACTION_LABEL }}
      </mg-text>
      <mat-button-toggle-group
        [value]="form.pages.activePath$ | async"
        [disabled]="form.searchResults.isLoading$ | async"
        (change)="changeActivePage($event.value)">
        <mat-button-toggle
          *ngFor="let page of form.pages.pages$ | async; index as i"
          [value]="page.path"
          [disabled]="form.pages.disabledPages$ | async | includes: page.path">
          {{ page.text.title }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </ng-container>
    <mg-ps-search [form]="form"></mg-ps-search>
  </aside>
</ng-template>

<!-- Footer Content Template -->
<ng-template #footerContent>
  <footer
    class="overlay-footer"
    fxLayout="row"
    fxLayout.lt-sm="column-reverse"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-sm="space-between stretch"
    fxLayoutGap="8px">
    <mg-btn
      variant="text"
      size="large"
      [wide]="true"
      (pressed)="back()">
      Back
    </mg-btn>
    <mg-btn
      [disabled]="(form.canSubmit$ | async) === false"
      (pressed)="form.submit()">
      {{ (form.pages.activePage$ | async)?.text?.submit }}
    </mg-btn>
  </footer>
</ng-template>

<!-- Quick Actions Template -->
<ng-template #quickActionsTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="20px"
    class="quick-action-container">
    <mg-btn
      *ngIf="(form.pages.activePageType$ | async) === 'list'"
      variant="text"
      size="small"
      iconSet="minga"
      icon="mg-export"
      (pressed)="form.exportData()">
      {{ MSG.BUTTON_LABEL_EXPORT }}
    </mg-btn>
  </div>
</ng-template>
