<menu class="nav-menu">
  <!-- Dynamic menu items -->
  <li
    *ngFor="let menuItem of sortedNavigationItems$ | async | slice: 0:4"
    class="nav-menu-item"
    matRipple
    matRippleColor="rgba(53, 138, 181, 0.12)">
    <a
      #rla="routerLinkActive"
      routerLinkActive="active"
      [routerLink]="
        menuItem.routerOutlet
          ? ['', { outlets: { o: [menuItem.path] } }]
          : menuItem.path
      "
      [routerLinkActiveOptions]="{ exact: false }"
      [title]="menuItem.title"
      [attr.aria-label]="menuItem?.description">
      <div class="icon-container">
        <!-- Menu icon -->
        <mg-icon
          *ngIf="menuItem.title !== 'My Tools'; else avatarTemplate"
          [iconName]="rla.isActive ? menuItem?.iconActive : menuItem?.icon">
        </mg-icon>
        <!-- Avatar template -->
        <ng-template #avatarTemplate>
          <mg-person-avatar
            *ngIf="menuItem.title === 'My Tools'"
            [title]="menuItem?.title"
            [noDisplayName]="true">
          </mg-person-avatar>
        </ng-template>
      </div>
      <span>{{ menuItem.title }}</span>
    </a>
  </li>
  <!-- More menu options button -->
  <li
    class="nav-menu-item"
    matRipple
    matRippleColor="rgba(53, 138, 181, 0.12)">
    <button
      title="More"
      aria-label="More"
      (click)="handleToggleDrawerPressed()">
      <div class="icon-container">
        <!-- Menu icon -->
        <mg-icon>mg-more-vert</mg-icon>
      </div>
      <span>More</span>
    </button>
  </li>
</menu>
