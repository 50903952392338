<mg-short-card
  *ngIf="content as response"
  #shortCard
  [mgContentContextMenu]="response.contextHash"
  [featuredImage]="response.featuredImage"
  [timestamp]="response.timestamp"
  [body]="response.body"
  [likeCount]="response.likeCount"
  [ownerGroupHash]="response.ownerGroupHash"
  [commentList]="response.commentList"
  [commentCount]="response.commentCount"
  [totalCommentCount]="response.totalCommentCount"
  [pinned]="pinned"
  [markKeywords]="markKeywords"
  [commentRoles]="commentRoles"
  [context]="content.contextHash"
  [class.no-bg]="hasNoBackground"
  [ngClass]="themeColorClass"
  [content]="content">
  <mg-short-card-byline>
    <ng-container *ngIf="pinned">
      <mge-icon
        class="pinned-icon"
        icon="pin-o"></mge-icon>
    </ng-container>
  </mg-short-card-byline>
  <mg-short-card-featured-image>
    <div
      class="image-container"
      [class.no-bg]="hasNoBackground"
      [style.background-color]="response.backgroundColor">
      <mg-image
        [srcs]="shortCard.featuredImage | mgImageUrls:shortCard.imageSizes"
        [background-size]="featuredImageBackgroundSize"></mg-image>
      <div
        class="image-content"
        #bodyOverImage>
        <p
          class="body"
          *ngIf="content.type == 'TEXT'"
          [innerHTML]="response.body | mgSafeHtml"
          [style.font-size.px]="bodyTextSize"></p>
      </div>
    </div>
    <div class="image-footer">
      <div class="post-info">
        <div class="person-info">
          <ng-container
            *ngIf="response.authorPersonView && response.authorPersonView.profileImageUrl">
            <mg-avatar
              class="author-avatar"
              [ngClass]="'archived-' + response.authorPersonView.archived"
              [src]="response.authorPersonView.profileImageUrl"
              [color]="response.authorPersonView.badgeIconColor"></mg-avatar>
          </ng-container>

          <div
            class="post-byline-wrap"
            *ngIf="response.authorPersonView">
            <mg-person-minimal
              [person]="response.authorPersonView"></mg-person-minimal>
          </div>
        </div>
        <div
          class="ellipsis"
          [mgContentContextMenuClick]="response.contextHash">
          <mg-ellipsis></mg-ellipsis>
        </div>
      </div>
      <p
        *ngIf="!response.backgroundImage && response.featuredImage"
        class="body">
        {{ response.body | mgStripHtml | mgTextClipper:{clipAmount:
        responsePreviewLength} }}
        <span
          class="see-more"
          *ngIf="response.body.length > responsePreviewLength"
          >See More</span
        >
      </p>
    </div>
    <div class="divider"></div>
  </mg-short-card-featured-image>
  <mg-short-card-title></mg-short-card-title>
  <mg-short-card-body></mg-short-card-body>
  <mg-short-card-action-bar>
    <mg-like
      class="action no-before mg-cancel-content-link"
      [class.light-content]="shortCard.lightContent"
      (click)="shortCard.allowClick($event)"
      [count]="shortCard.likeCount"
      [context]="response.contextHash">
    </mg-like>
    <div class="action icon comment">
      {{shortCard.numberize(shortCard.totalCommentCount)}}
    </div>
  </mg-short-card-action-bar>
  <mg-short-card-comment-list></mg-short-card-comment-list>
</mg-short-card>
