/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { grpc } from '@improbable-eng/grpc-web';

import { StatusCode } from 'minga/proto/common/legacy_pb';
import {
  LoginValidationRequest,
  LoginValidationResponse,
} from 'minga/proto/gateway/login_pb';
import { LoginManager } from 'minga/proto/gateway/login_pb_service';
import {
  EmailUniqueRequest,
  EmailUniqueResponse,
} from 'minga/proto/gateway/people_pb';
import { PersonValidation } from 'minga/proto/gateway/people_pb_service';
import { CustomOauthParams } from 'src/app/minimal/services/Auth';

interface IMultiInvokeOptions<
  TRequest extends grpc.ProtobufMessage,
  TResponse extends grpc.ProtobufMessage,
> {
  request: TRequest;
  hosts: string[];

  /**
   * Callback to check if we should resolve early. Useful for creating a race
   * call Defaults to always return `false`.
   */
  shouldResolve?: (res: TResponse) => boolean;
}

interface IMultiInvokeResult<TResponse extends grpc.ProtobufMessage> {
  /**
   * The response that won the race
   */
  response: TResponse;

  /**
   * The host responsible for the won race
   */
  host: string;

  /**
   * If the multi invoke resulted in an early response i.e. `shouldResolve`
   * in the `IMultiInvokeOptions` returned true for this response
   */
  early: boolean;
}

async function _multiInvoke<
  TRequest extends grpc.ProtobufMessage,
  TResponse extends grpc.ProtobufMessage,
  M extends grpc.MethodDefinition<TRequest, TResponse>,
>(
  methodDefn: M,
  options: IMultiInvokeOptions<TRequest, TResponse>,
): Promise<IMultiInvokeResult<TResponse>> {
  const { hosts, request } = options;
  const calls: grpc.Request[] = [];
  let earlyResponse: [TResponse, string] | null = null;
  const responses: ([TResponse, string] | null)[] = [];
  const errors: (any | null)[] = [];

  const shouldResolve = options.shouldResolve || (() => false);

  const closeCalls = () => {
    for (const call of calls) {
      try {
        call.close();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const isLastResponse = () => responses.length == hosts.length;

  await new Promise<void>((resolve, reject) => {
    const onEnd = (code: grpc.Code, message: string) => {
      if (code != grpc.Code.OK) {
        const err = new Error(message);
        (<any>err).code = code;
        responses.push(null);
        errors.push(err);
      }

      if (isLastResponse() && !earlyResponse) {
        resolve();
      }
    };

    const onHeaders = (headers: grpc.Metadata) => {
      // console.debug('onHeaders', headers);
    };

    const onMessage = (response: TResponse, host: string) => {
      responses.push([response, host]);
      errors.push(null);

      if (shouldResolve(response)) {
        earlyResponse = [response, host];
        resolve();
        closeCalls();
      }
    };

    for (const host of hosts) {
      try {
        const metadata = new grpc.Metadata();
        // Explicitly set to blank so the request is not authenticated
        metadata.set('token', '');

        const call = grpc.invoke(methodDefn, {
          host,
          request,
          onEnd,
          onHeaders,
          onMessage: res => onMessage(res as TResponse, host),
          metadata,
        });

        calls.push(call);
      } catch (err) {
        return reject(err);
      }
    }
  });

  if (earlyResponse) {
    return {
      response: earlyResponse[0],
      host: earlyResponse[1],
      early: true,
    };
  }

  const validErrors = errors.filter(err => !!err);
  {
    const firstValidError = validErrors[0];
    if (firstValidError) {
      return Promise.reject(firstValidError);
    }
  }

  const validResponses = responses.filter(r => !!r);
  {
    const firstValidResponse = validResponses[0];
    if (firstValidResponse) {
      return {
        response: firstValidResponse[0],
        host: firstValidResponse[1],
        early: false,
      };
    }
  }

  // This should not happen.
  return Promise.reject(new Error('No responses or errors'));
}

/**
 * Checks if an email is unique across all `apiUrls`. First one to return unique
 * false is returned.
 */
export async function uniqueEmailRace(
  email: string,
  apiUrls: string[],
  mingaHash: string = '',
) {
  const request = new EmailUniqueRequest();
  request.setEmailAddress(email);
  if (mingaHash) {
    request.setMingaHash(mingaHash);
  }

  return _multiInvoke(PersonValidation.emailUnique, {
    hosts: apiUrls,
    request,
    shouldResolve: (res: EmailUniqueResponse) => !res.getUnique(),
  });
}

type TokenFlow = {
  idToken: string;
};

type CustomOauthFlow = {
  customOAuth: CustomOauthParams;
};

/**
 * Validates id token across all `apiUrls`. First one to return a response to
 * indicate that the id is valid or a response that indicates that the id token
 * could be valid if the user were to act on something (such as connecting an
 * sso provider) would cause it to be valid is returned.
 */
export async function validateIdTokenRace(
  options: TokenFlow | CustomOauthFlow,
  apiUrls: string[],
) {
  const request = new LoginValidationRequest();
  const { idToken, customOAuth } = options as any;

  if (idToken) {
    request.setIdentityToken(idToken);
  }

  if (customOAuth?.token && customOAuth.provider) {
    request.setCustomOauthToken(customOAuth?.token);
    request.setCustomOauthProvider(customOAuth.provider);
  }

  return _multiInvoke(LoginManager.Validate, {
    hosts: apiUrls,
    request,
    shouldResolve: (res: LoginValidationResponse) =>
      res.getStatus() === StatusCode.OK,
  });
}
