import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgIconModule } from 'minga/app/src/app/icon';

import { MgSmsMessageShortCardComponent } from './MgSmsMessageShortCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgContentLinkModule,
    MgEllipsisModule,
    MgIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgSmsMessageShortCardComponent],
  exports: [MgSmsMessageShortCardComponent],
})
export class MgSmsMessageShortCardModule {}
