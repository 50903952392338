import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export interface IUserPreferenceInputComponent<T> {
  value: T;
  valueChange: EventEmitter<T>;
}

@Component({
  selector: 'mg-user-preference-input-label',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPreferenceInputLabelComponent {}

@Component({
  selector: 'mg-user-preference-input',
  templateUrl: './UserPreferenceInput.component.html',
  styleUrls: ['./UserPreferenceInput.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPreferenceInputComponent {
  @Input()
  value: string | boolean | null = null;

  @Output()
  valueChange: EventEmitter<string | boolean | null>;

  constructor() {
    this.valueChange = new EventEmitter();
  }

  isBool(): this is UserPreferenceInputComponent &
    IUserPreferenceInputComponent<boolean> {
    return typeof this.value === 'boolean';
  }

  isString(): this is UserPreferenceInputComponent &
    IUserPreferenceInputComponent<string> {
    return typeof this.value === 'string';
  }
}
