import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { Color } from 'minga/app/src/app/util/color';
import { ShortInspirationCardView } from 'minga/proto/gateway/content_views_pb';

import { MgMiniCardBaseElement } from '../MgMiniCardBaseElement';

@Component({
  selector: 'mg-full-graphic-mini-card',
  templateUrl: './MgFullGraphicMiniCard.element.html',
  styleUrls: ['./MgFullGraphicMiniCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgFullGraphicMiniCardElement extends MgMiniCardBaseElement {
  @Input()
  content?: ShortInspirationCardView.AsObject;

  @Input()
  context: string = '';

  @HostBinding('attr.light-content')
  get lightContent() {
    if (!this.content) return false;
    return !!this.content.lightContent;
  }

  @HostBinding('style.backgroundColor')
  get backgroundColor(): string {
    if (!this.content) return '';
    return this.content.backgroundColor;
  }

  @ViewChild('gradientElement', { static: true })
  gradientElement?: ElementRef;

  constructor(
    private _elementRef: ElementRef,
    private renderer2: Renderer2,
    public contentevents: ContentEvents,
    public contentstate: ContentState,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      this.updateBackground();
    }
  }

  private _gradientSize(size: number) {
    const gradientSize = this.content.gradientSize || 0;
    return (gradientSize * size - 1) * -1 * 100 + '%';
  }

  private updateBackground() {
    this._bannerGradientStyle(this.gradientElement, 1);
  }

  _bannerGradientStyle(element: any, size: number) {
    const hasBgColor = this.content && !!this.content.backgroundColor;
    const hasGradientOverlap = hasBgColor && !!this.content.gradientOverlap;

    let bgColor = '';
    if (hasGradientOverlap) {
      const bgColorObj = new Color(this.content.backgroundColor);
      const transBgColor = bgColorObj.alpha(0).toString();

      bgColor = `linear-gradient(${transBgColor}, ${this.content.backgroundColor})`;
    }

    this.renderer2.setStyle(element, 'background', bgColor);
    this.renderer2.setStyle(element, 'top', this._gradientSize(size));
  }
}
