import * as DOMPurify from 'dompurify';

export function sanitizeHtml(dirtyHtml: string): string {
  return DOMPurify.sanitize(dirtyHtml, {
    FORBID_ATTR: ['style'],
  });
}

export function stripTagsWithNewlines(html: string) {
  return addNewLinesAtTags(html, true);
}

export function addNewLinesAtTags(html: string, stripTags: boolean = false) {
  let div = window.document.createElement('div');
  div.innerHTML = html;
  let result: string = '';
  const childNodes = div.childNodes;

  let handleChildren = (childNodes: any, numberList: boolean = false) => {
    for (let i = 0; i < childNodes.length; i++) {
      const element: HTMLElement = <HTMLElement>childNodes[i];

      if (element.nodeName == 'OL' || element.nodeName == 'UL') {
        handleChildren(element.childNodes, element.nodeName == 'OL');
        continue;
      }
      if (element.nodeName == 'LI' && stripTags) {
        result += numberList ? `  ${i + 1}. ` : '  • ';
      }
      if (stripTags) {
        result += element.textContent || '';
      } else {
        result += element.outerHTML;
      }
      if (
        element.nodeName == 'DIV' ||
        element.nodeName == 'P' ||
        element.nodeName == 'LI' ||
        element.nodeName == 'BR'
      ) {
        // add new lines where needed
        result += '\n';
      }
    }
  };

  handleChildren(childNodes);

  return result;
}
