import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as gallery_pb from 'minga/proto/gateway/gallery_pb';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { ReportService } from 'minga/app/src/app/services/Report';
import { MingaGallery } from 'minga/proto/gateway/gallery_ng_grpc_pb';

import { IAddGalleryPhotoOptions, IDeleteGalleryPhotoOptions } from '../types';
import { GalleryServiceAbstract } from '../utils';

@Injectable({ providedIn: 'root' })
export class MingaGalleryService extends GalleryServiceAbstract {
  public _loading: BehaviorSubject<boolean>;
  public _summary: BehaviorSubject<gallery_pb.MingaGallerySummaryResponse.AsObject | null>;

  readonly loading$: Observable<boolean>;
  readonly summary$: Observable<gallery_pb.MingaGallerySummaryResponse.AsObject>;

  constructor(
    public mingaGalleryProto: MingaGallery,
    public reportService: ReportService,
    public groupsFacade: GroupsFacadeService,
  ) {
    super(mingaGalleryProto, reportService);
    this._loading = new BehaviorSubject<boolean>(false);
    this._summary =
      new BehaviorSubject<gallery_pb.MingaGallerySummaryResponse.AsObject | null>(
        null,
      );

    this.loading$ = this._loading.asObservable();
    this.summary$ = this._summary.pipe(
      filter(summary => !!summary),
      map(
        summary => summary as gallery_pb.MingaGallerySummaryResponse.AsObject,
      ),
    );
  }

  async fetchSummary() {
    const request = new gallery_pb.MingaGallerySummaryRequest();
    const wasLoading = this._loading.getValue();

    const parentHashes = await this.groupsFacade.getParentGroupHashes();
    if (parentHashes && parentHashes.length > 0) {
      /* TODO: support all groups, not just first */
      request.setGroupHash(parentHashes[0]);
    }

    try {
      if (!wasLoading) this._loading.next(true);
      const response = await this.mingaGalleryProto.summary(request);
      this._summary.next(response.toObject());
    } finally {
      if (!wasLoading) this._loading.next(false);
    }
  }

  async getSearchPhotoTotal(search: string): Promise<number> {
    const request = new gallery_pb.MingaGalleryGetPhotoTotalRequest();
    request.setSearchQuery(search);

    const parentHashes = await this.groupsFacade.getParentGroupHashes();
    if (parentHashes && parentHashes.length > 0) {
      request.setGroupHash(parentHashes[0]);
    }

    const response = await this.mingaGalleryProto.getPhotoTotal(request);
    return response.getPhotoTotal();
  }

  async addPhoto(options: IAddGalleryPhotoOptions) {
    return await this._addPhoto(this._summary, options);
  }

  async deletePhoto(options: IDeleteGalleryPhotoOptions): Promise<boolean> {
    return await this._deletePhoto(this._summary, options);
  }

  async resolvePhoto(galleryPhotoUuid: string): Promise<boolean> {
    return await this._resolvePhoto(galleryPhotoUuid);
  }
}
