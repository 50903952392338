<div class="profile-image"></div>
<div class="minga-logo-container">
  <div class="minga-logo landing-content-bg">
    <div
      class="image"
      [style.backgroundImage]="mingaLogo"></div>
  </div>
</div>

<div
  fxLayout="column"
  class="joining-content mg-centered">
  <h1 class="mg-headline">Hi, {{ personName }}</h1>

  <p>
    You are joining the<br />
    <strong>{{ landing.mingaName }}</strong>
  </p>

  <div class="mg-centered">
    <mg-spinner></mg-spinner>
  </div>
</div>
