import { Component, Input } from '@angular/core';

export interface IAvatarClusterItem {
  src: string;
  color?: string;
  personHash?: string;
}

@Component({
  selector: 'mg-avatar-cluster',
  templateUrl: './AvatarCluster.component.html',
  styleUrls: ['./AvatarCluster.component.scss'],
})
export class AvatarClusterComponent {
  @Input()
  items: IAvatarClusterItem[] = [];
}
