import { Observable } from 'rxjs';

import { PBIS_CHECKIN_TYPES } from './constants/tt-view.constants';

export type ITeacherToolsMenu<T = any> = {
  label: string;
  onClick?: (data?: any) => void;
  type?: T;
  hidden$?: Observable<boolean>;
} & ({ icon: string } | { asset: string });

export type ITeacherToolsPbis = ITeacherToolsMenu<PBIS_CHECKIN_TYPES>;

export interface TeacherToolsNavigationState {
  openHallPassScanner?: boolean;
}

export enum VIEW_YOUR_ID_MENU_OPTIONS {
  VIEW = 'view',
}

export enum VIEW_ID_MENU_OPTIONS {
  VIEW = 'view',
}

export enum HALLPASS_MENU_OPTIONS {
  ASSIGN = 'assign',
  VIEW = 'view',
}
