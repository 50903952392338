import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';

import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';
import { MembershipListManager } from 'minga/proto/membership_list/membership_list_ng_grpc_pb';
import { ListMembershipService } from 'src/app/services/ListMembership';

/**
 * Stickers Service
 *
 * Manages requests related to the stickers membership list
 *
 * @todo replace all places that need to get sticker information to fetch it
 * from this service
 * @todo add additional methods here to add/remove stickers and anything else
 * related to stickers membership list
 */
@Injectable({ providedIn: 'root' })
export class StickersService {
  /** Constructor */
  constructor(
    private membershipListManager: MembershipListManager,
    private _listService: ListMembershipService,
  ) {}

  /**
   * Get Stickers List
   */
  public getStickersList(): Observable<IMembershipList[]> {
    return from(this._handleGetStickers());
  }

  /**
   * Get Stickers List for Sticker Manager Page
   */
  public async getStickersAndNoAccessList(): Promise<IMembershipList[]> {
    const list = await this._listService.getMembershipListByType([
      MembershipListType.STICKER,
      MembershipListType.NO_ACCESS,
    ]);
    return list;
  }

  /**
   * Get a list of sticker for a person
   */
  public async getStickersByPerson(
    personHash: string,
  ): Promise<IMembershipList[]> {
    const list = await this._listService.getListsByPerson({
      personHash,
      types: [MembershipListType.STICKER, MembershipListType.NO_ACCESS],
    });
    return list;
  }

  /**
   * Handle Get Stickers
   *
   * This is a private async promise so we can use await here
   */
  private async _handleGetStickers(): Promise<IMembershipList[]> {
    const list = await this._listService.getMembershipListByType([
      MembershipListType.STICKER,
    ]);
    return list;
  }
}
