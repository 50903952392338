import { Injectable } from '@angular/core';

import { HallPassStatusEnum, IHallPassType } from 'minga/domain/hallPass';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { RootService } from 'src/app/minimal/services/RootService';
import { HallPassService } from 'src/app/services/HallPass';

import { SystemAlertCloseEvents } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';

import { HpmActionMessages } from '../constants';
import { HpmDashboardTableItem } from '../types';
import { HpmDashboardService } from './hpm-dashboard.service';

@Injectable()
export class HpmDashboardPassService {
  constructor(
    private _root: RootService,
    private _hpService: HallPassService,
    private _hpmDashboard: HpmDashboardService,
    private _systemSnackBar: SystemAlertSnackBarService,
    private _hallPassActions: HallPassActionsService,
    private _authInfo: AuthInfoService,
  ) {}

  public async start(id: number): Promise<void> {
    try {
      await this._root.addLoadingPromise(this._hpService.startHallPass(id));
      this._systemSnackBar.open({
        type: 'success',
        message: HpmActionMessages.START_SUCCESS,
      });
      this._hpmDashboard.refetchPasses();
    } catch (err) {
      this._systemSnackBar.open({
        type: 'error',
        message: HpmActionMessages.START_ERROR,
      });
    }
  }

  public async cancel(id: number): Promise<void> {
    const response = await this._hallPassActions.cancelConfirm(id);

    if (response === 'success') {
      this._hpmDashboard.removePass(id);
    }
  }

  public async end(
    pass: HpmDashboardTableItem,
    type: IHallPassType,
  ): Promise<void> {
    const response = await this._hallPassActions.endConfirm(pass, type.name);

    if (response === 'success') {
      this._hpmDashboard.updatePassStatus(pass.id, HallPassStatusEnum.ENDED, {
        endedByPersonView: this._authInfo.authPerson as any,
      });
      this._hpmDashboard.setPassEndTime(pass.id);
    }
  }

  public async showPendingApprovalDialog(
    pass: HpmDashboardTableItem,
    type: IHallPassType,
  ): Promise<void> {
    const response = await this._hallPassActions.pendingApprovalDialogConfirm(
      pass,
      type,
    );

    if (response === SystemAlertCloseEvents.CONFIRM) {
      this._hpmDashboard.refetchPasses();
    }

    if (response === SystemAlertCloseEvents.CLOSE) {
      this._hpmDashboard.removePass(pass.id);
    }
  }
}
