import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formTextAreaPreview' })
export class FormTextAreaPreviewPipe implements PipeTransform {
  transform(
    originalText: string,
    templateVariables: Record<string, string | number>,
  ): string {
    let replacedString = String(originalText);
    Object.entries(templateVariables || {}).forEach(entry => {
      const [key, value] = entry as any;
      const placeholder = new RegExp(`\\$\\{${key}\\}`, 'g');
      replacedString = replacedString.replace(placeholder, value ?? '');
    });
    return replacedString;
  }
}
