import { Pipe, PipeTransform } from '@angular/core';
import { mgResolveImageUrl } from '../util/asset/imageAssetResolve';

@Pipe({ name: 'mgAssetUrl' })
export class MgAssetUrlPipe implements PipeTransform {
  constructor() {}

  /** @deprecated - Just use `mgResolveImageUrl` */
  static transform(value: any, options?: string | string[]): string {
    return mgResolveImageUrl(value, options);
  }

  transform(value: any, options?: any): string {
    return mgResolveImageUrl(value, options);
  }
}
