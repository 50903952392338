<div
  *ngIf="anchor"
  class="logo-anchored-container"
  [ngClass]="'logo-anchored-container-anchor-' + anchor">
  <div class="logo-anchored-wrap">
    <mg-image
      *ngIf="customLogo; else defaultLogo"
      class="logo-anchored mg-image-size-contain"
      [srcs]="customLogo | mgImageUrls: _imageSizes">
    </mg-image>
    <ng-template #defaultLogo>
      <mg-logo class="logo-anchored"></mg-logo>
    </ng-template>
  </div>
</div>
