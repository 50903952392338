<span [ngClass]="classes">
  <span class="inner-content">
    <ng-content> </ng-content>
    <mat-icon
      *ngIf="type === 'button'"
      [style.color]="iconColor"
      inline="true"
      aria-hidden="false">
      chevron_right
    </mat-icon>
  </span>
</span>
