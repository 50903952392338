<mg-tools-two-col-layout>
  <ng-template #leftCol>
    <ng-container *ngIf="isPbisEnabled$ | async; else noAccess">
      <div
        *ngIf="loading$ | async; else behaviours"
        class="loading-container">
        <mg-spinner
          [diameter]="40"
          [thickness]="3">
        </mg-spinner>
      </div>

      <ng-template #behaviours>
        <ng-container *ngIf="(activeBehaviours$ | async).length > 0">
          <div class="section-wrap">
            <mg-text
              variant="header-sm"
              spacing="3"
              textTransform="uppercase">
              {{ MESSAGES.ACTIVE_BEHAVIORS }}
            </mg-text>
            <mg-st-behaviour-item
              *ngFor="let behaviour of activeBehaviours$ | async"
              [behaviour]="behaviour"
              [isActive]="true"></mg-st-behaviour-item>
          </div>
        </ng-container>
        <ng-container *ngIf="(historicBehaviours$ | async).length > 0">
          <div class="section-wrap">
            <mg-text
              variant="header-sm"
              spacing="3"
              textTransform="uppercase">
              {{ MESSAGES.HISTORIC_BEHAVIORS }}
            </mg-text>
            <mg-st-behaviour-item
              *ngFor="let behaviour of historicBehaviours$ | async"
              [behaviour]="behaviour"></mg-st-behaviour-item>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            (activeBehaviours$ | async).length === 0 &&
            (historicBehaviours$ | async).length === 0
          ">
          <div class="empty-groups">
            <mg-empty-state
              mg-stream-empty
              [title]="MESSAGES.EMPTY_BEHAVIORS">
            </mg-empty-state>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-template #noAccess></ng-template>
  </ng-template>
</mg-tools-two-col-layout>
