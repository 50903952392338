import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { IEmailContentSend } from 'minga/app/src/app/content-common/services/EmailContent/types';
import { IMingaFile } from 'minga/app/src/app/models/MingaFiles';
import { EventStatus } from 'minga/domain/event';
import { IModerationDetailTags } from 'minga/domain/moderation';
import {
  FeedComment,
  LongCardView,
  LongEventCardView,
  ShortEventCardView,
} from 'minga/proto/gateway/content_views_pb';
import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';
import { ImageInfo } from 'minga/proto/image/image_pb';

export interface ICommentEvent {
  parentContentContextHash: string;
}

export interface ICommentSuccessEvent extends ICommentEvent {
  success: true;
  replyContentContextHash: string;
  newCommentContextHash: string;
  newCommentBody: string;
  newCommentPersonView: PersonViewMinimal.AsObject;
}

export interface ICommentErrorEvent extends ICommentEvent {
  error: Error;
}

export interface IContextMenuOpenEvent {
  contentContextHash: string;
  contentHash: string;
  x: number;
  y: number;
  content?: ShortEventCardView.AsObject | LongEventCardView.AsObject;
  disableDelete?: boolean;
}

export interface IBlockedContextMenuOpenEvent extends IContextMenuOpenEvent {
  blockedReasons: string[];
}

export interface IContentDeletedEvent {
  contentContextHash?: string;
  contentHash?: string;
  typeString?: string;
  date?: Date;
  parentContextHash?: string;
  childCount?: number;
}

export interface IContentEventGoingEvent {
  contentContextHash: string;
  going: boolean;
  date?: Date;
  checkedIn?: boolean;
  hasCode?: boolean;
  contentTitle?: string;
  endDate?: Date;
  color?: string;
  status: EventStatus;
  eventReasonId?: number;
}

export interface IContentEventCodeCheckInEvent {
  contentContextHash: string;
  contentTitle: string;
  date?: Date;
  color?: string;
  eventReasonId: number;
}

export interface IContentEventCodeCheckInSuccessEvent {
  contentContextHash: string;
  date?: Date;
  endDate?: Date;
  color?: string;
}

export interface IContentCreateSuccessEvent {
  typeString: string;
  // Date relavent to the type. ie: start for events
  date?: Date;
  endDate?: Date;
  color?: string;
}

export interface IFailedModerationEvent {
  textFailed?: boolean;
  imageFailed?: boolean;
  imageAndTextFailed?: boolean;
  imageTextFailed?: boolean;
  tags?: Map<number, IModerationDetailTags>;
  imageTags?: Map<number, IModerationDetailTags>;
  contextHash?: string;
  contentHash?: string;
  galleryPhotoUuid?: string;

  /**
   * Set if an email was wanted to be sent on the original request. `null`
   * otherwise.
   */
  emailContentSend: IEmailContentSend | null;
}

export interface ISuccessModerationEvent {}

export interface IContentDeleteEvent {
  typeString: string;
  contentContextHash: string;
  date?: Date;
}

export interface IContentOpenEvent {
  contentContextHash: string;
}

export interface ILikesPeopleEvent {
  contextHash: string;
  content?:
    | ShortEventCardView.AsObject
    | LongEventCardView.AsObject
    | LongCardView.AsObject;
}

export interface IContentEventGoingSuccessEvent
  extends IContentEventGoingEvent {}

export interface IContentEventGoingErrorEvent extends IContentEventGoingEvent {}

export interface IMingaUpdateEvent {
  name?: string;
  logo?: string;
}

// @TODO: expand implementation to more than just comments...
export interface IShortCardViewUpdateEvent {
  contentContextHash: string;
  commentList?: FeedComment.AsObject[];
  totalCommentCount?: number;
  commentCount?: number;
  likeCount?: number;
  imageList?: ImageInfo.AsObject[];
}

export interface IContentUnpinEvent {
  contentContextHash: string;
}

export interface IFileUpdateEvent {
  groupHash?: string;
  mingaFileUpdate?: boolean;
  files: IMingaFile[];
}

export interface IGalleryPhotoOpenEvent {
  galleryPhotoUuid: string;
}

@Injectable({ providedIn: 'root' })
export class ContentEvents {
  private _onCommentSuccess: Subject<ICommentSuccessEvent>;
  private _onCommentError: Subject<ICommentErrorEvent>;
  private _onContextMenuOpen: Subject<IContextMenuOpenEvent>;
  private _onContentDeleted: Subject<IContentDeletedEvent>;
  private _onContentEventGoing: Subject<IContentEventGoingEvent>;
  private _onContentEventGoingSuccess: Subject<IContentEventGoingSuccessEvent>;
  private _onContentEventGoingError: Subject<IContentEventGoingErrorEvent>;
  private _onPostCreateSuccess: Subject<IContentCreateSuccessEvent>;
  private _onFailedModeration: Subject<IFailedModerationEvent>;
  private _onSuccessModeration: Subject<ISuccessModerationEvent>;
  private _onShortCardUpdate: Subject<IShortCardViewUpdateEvent>;
  private _onBlockedContextMenuOpen: Subject<IBlockedContextMenuOpenEvent>;
  private _onGalleryPhotoOpenEvent: Subject<IGalleryPhotoOpenEvent>;
  private _onAddLike: Subject<string>;
  private _onPollAnswer: Subject<string>;

  // private _onPostUpdateSuccess: Subject<IContentCreateSuccessEvent>;
  private _onContentEventCheckIn: Subject<IContentEventGoingEvent>;
  private _onEventCodeCheckIn: Subject<IContentEventCodeCheckInEvent>;
  private _onEventCodeCheckInSuccess: Subject<IContentEventCodeCheckInSuccessEvent>;

  private _onContentOpenEvent: Subject<IContentOpenEvent>;
  private _onMingaUpdateEvent: BehaviorSubject<IMingaUpdateEvent>;
  private _onSeePeopleLikes: Subject<ILikesPeopleEvent>;

  private _onContentUnpin: Subject<IContentUnpinEvent>;

  private _onFileUpdate: Subject<IFileUpdateEvent>;
  private _onContentFeedsUpdate: Subject<void>;

  constructor() {
    this._onCommentError = new Subject();
    this._onCommentSuccess = new Subject();
    this._onContextMenuOpen = new Subject();
    this._onBlockedContextMenuOpen = new Subject();
    this._onContentDeleted = new Subject();
    this._onContentEventGoing = new Subject();
    this._onContentEventGoingSuccess = new Subject();
    this._onContentEventGoingError = new Subject();
    this._onPostCreateSuccess = new Subject();
    this._onShortCardUpdate = new Subject();
    // this._onPostUpdateSuccess = new Subject();
    this._onContentEventCheckIn = new Subject();
    this._onEventCodeCheckIn = new Subject();
    this._onEventCodeCheckInSuccess = new Subject();
    this._onContentOpenEvent = new Subject();
    this._onFailedModeration = new Subject();
    this._onSuccessModeration = new Subject();
    this._onGalleryPhotoOpenEvent = new Subject();
    this._onContentFeedsUpdate = new Subject();
    this._onAddLike = new Subject();
    this._onPollAnswer = new Subject();

    this._onMingaUpdateEvent = new BehaviorSubject<IMingaUpdateEvent>({
      name: '',
      logo: '',
    });

    this._onSeePeopleLikes = new Subject();

    this._onContentUnpin = new Subject();

    this._onFileUpdate = new Subject();
  }

  emitCommentSuccess(ev: ICommentSuccessEvent) {
    this._onCommentSuccess.next(ev);
  }

  emitCommentError(ev: ICommentErrorEvent) {
    this._onCommentError.next(ev);
  }

  emitContextMenuOpen(ev: IContextMenuOpenEvent) {
    this._onContextMenuOpen.next(ev);
  }

  emitBlockedContextMenuOpen(ev: IBlockedContextMenuOpenEvent) {
    this._onBlockedContextMenuOpen.next(ev);
  }

  emitContentDeleted(ev: IContentDeletedEvent) {
    this._onContentDeleted.next(ev);
  }

  emitContentEventGoing(ev: IContentEventGoingEvent) {
    this._onContentEventGoing.next(ev);
  }

  emitContentEventGoingSuccess(ev: IContentEventGoingSuccessEvent) {
    this._onContentEventGoingSuccess.next(ev);
  }

  emitContentEventGoingError(ev: IContentEventGoingErrorEvent) {
    this._onContentEventGoingError.next(ev);
  }

  emitPostCreateSuccess(ev: IContentCreateSuccessEvent) {
    this._onPostCreateSuccess.next(ev);
  }

  emitPostUpdateSuccess(ev: IContentCreateSuccessEvent) {
    // @TODO: when update requires different behavior than listening, make
    // update its own specific event
    this._onPostCreateSuccess.next(ev);
  }

  emitContentEventCheckIn(ev: IContentEventGoingEvent) {
    this._onContentEventCheckIn.next(ev);
  }

  emitContentEventCodeCheckIn(ev: IContentEventCodeCheckInEvent) {
    this._onEventCodeCheckIn.next(ev);
  }

  emitContentEventCodeCheckInSuccess(ev: IContentEventCodeCheckInSuccessEvent) {
    this._onEventCodeCheckInSuccess.next(ev);
  }

  emitContentOpen(ev: IContentOpenEvent) {
    this._onContentOpenEvent.next(ev);
  }

  emitFailedModeration(ev: IFailedModerationEvent) {
    this._onFailedModeration.next(ev);
  }

  emitMingaUpdateEvent(ev: IMingaUpdateEvent) {
    this._onMingaUpdateEvent.next(ev);
  }

  emitOnSeePeopleLikes(ev: ILikesPeopleEvent) {
    this._onSeePeopleLikes.next(ev);
  }

  emitShortCardViewUpdateEvent(ev: IShortCardViewUpdateEvent) {
    this._onShortCardUpdate.next(ev);
  }

  emitContentUnpin(ev: IContentUnpinEvent) {
    this._onContentUnpin.next(ev);
  }

  emitFileUpdateEvent(ev: IFileUpdateEvent) {
    this._onFileUpdate.next(ev);
  }

  emitGalleryPhotoOpenEvent(ev: IGalleryPhotoOpenEvent) {
    this._onGalleryPhotoOpenEvent.next(ev);
  }

  emitContentFeedsUpdate() {
    this._onContentFeedsUpdate.next();
  }

  emitSuccessModeration(ev: ISuccessModerationEvent) {
    this._onSuccessModeration.next(ev);
  }

  emitAddLike(contextHash: string) {
    this._onAddLike.next(contextHash);
  }

  emitPollAnswer(contextHash: string) {
    this._onPollAnswer.next(contextHash);
  }

  get onCommentSuccess(): Observable<ICommentSuccessEvent> {
    return this._onCommentSuccess.asObservable();
  }

  get onCommentError(): Observable<ICommentErrorEvent> {
    return this._onCommentError.asObservable();
  }

  get onContextMenuOpen(): Observable<IContextMenuOpenEvent> {
    return this._onContextMenuOpen.asObservable();
  }

  get onBlockedContextMenuOpen(): Observable<IBlockedContextMenuOpenEvent> {
    return this._onBlockedContextMenuOpen.asObservable();
  }

  get onContentDeleted(): Observable<IContentDeletedEvent> {
    return this._onContentDeleted.asObservable();
  }

  get onContentEventCheckIn(): Observable<IContentEventGoingEvent> {
    return this._onContentEventCheckIn.asObservable();
  }

  get onContentEventGoing(): Observable<IContentEventGoingEvent> {
    return this._onContentEventGoing.asObservable();
  }

  get onContentEventGoingSuccess() {
    return this._onContentEventGoingSuccess.asObservable();
  }

  get onContentEventGoingError(): Observable<IContentEventGoingErrorEvent> {
    return this._onContentEventGoingError.asObservable();
  }

  get onPostCreateSuccess(): Observable<IContentCreateSuccessEvent> {
    return this._onPostCreateSuccess.asObservable();
  }

  get onContentEventCodeCheckIn(): Observable<IContentEventCodeCheckInEvent> {
    return this._onEventCodeCheckIn.asObservable();
  }

  get onContentEventCodeCheckInSuccess(): Observable<IContentEventCodeCheckInSuccessEvent> {
    return this._onEventCodeCheckInSuccess.asObservable();
  }

  get onContentOpen(): Observable<IContentOpenEvent> {
    return this._onContentOpenEvent.asObservable();
  }

  get onFailedModeration(): Observable<IFailedModerationEvent> {
    return this._onFailedModeration.asObservable();
  }

  get onMingaUpdate(): Observable<IMingaUpdateEvent> {
    return this._onMingaUpdateEvent.asObservable();
  }

  get onSeePeopleLikes(): Observable<ILikesPeopleEvent> {
    return this._onSeePeopleLikes.asObservable();
  }

  get onShortCardUpdate(): Observable<IContentUnpinEvent> {
    return this._onShortCardUpdate.asObservable();
  }

  get onContentUnpin(): Observable<IContentUnpinEvent> {
    return this._onContentUnpin.asObservable();
  }

  get onFileUpdate(): Observable<IFileUpdateEvent> {
    return this._onFileUpdate.asObservable();
  }

  get onGalleryPhotoOpenEvent(): Observable<IGalleryPhotoOpenEvent> {
    return this._onGalleryPhotoOpenEvent.asObservable();
  }

  get onContentFeedsUpdate(): Observable<void> {
    return this._onContentFeedsUpdate.asObservable();
  }

  get onSuccessModeration(): Observable<ISuccessModerationEvent> {
    return this._onSuccessModeration.asObservable();
  }

  get onPollAnswer(): Observable<string> {
    return this._onPollAnswer.asObservable();
  }

  get onAddLike(): Observable<string> {
    return this._onAddLike.asObservable();
  }
}
