import { Injectable } from '@angular/core';

import * as day from 'dayjs';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FormRangeService {
  private _rangeChangeSubject = new Subject<{
    start: day.Dayjs;
    end?: day.Dayjs;
  }>();

  public rangeChange$ = this._rangeChangeSubject.asObservable();
  constructor() {}

  public rangeChange(change: { start: day.Dayjs; end?: day.Dayjs }) {
    this._rangeChangeSubject.next(change);
  }
}
