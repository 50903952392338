<div
  *ngIf="!hideNavItem"
  [ngClass]="['nav-container', 'hide-nav-item-' + hideNavItem]">
  <mg-overlay-nav-item
    class="mg-align-left"
    icon="close"
    (click)="onClose()">
    {{ MESSAGES.CLOSE_LABEL }}
  </mg-overlay-nav-item>
  <mg-generic-button
    *ngIf="records.length > 0"
    colorTheme="blue"
    form="personImportForm"
    (onClick)="triggerSubmit()">
    {{ MESSAGES.IMPORT_LABEL }}
  </mg-generic-button>
</div>

<form
  [formGroup]="formGroup"
  id="personImportForm"
  (submit)="triggerSubmit()">
  <div class="setting-container">
    <mg-text
      *ngIf="!hideNavItem"
      variant="header-md"
      textAlign="center"
      fontWeight="700"
      spacing="3">
      {{ title }}
    </mg-text>

    <div class="option-container">
      <div class="additional-form-fields">
        <ng-content></ng-content>
      </div>
    </div>

    <div class="checkbox-container">
      <mat-checkbox (change)="onHasHeadersChange($event.checked)">
        {{ MESSAGES.FIRST_ROW_LABEL }}
      </mat-checkbox>
    </div>

    <ng-container *ngIf="rowErrors.length > 0">
      <mg-system-alert
        type="error"
        [message]="MESSAGES.FILE_PARSE_ERROR_MSG"
        [dismissable]="true"
        [buttonText]="'Resolve (' + rowErrors.length + ')'"
        (dismissSnackbar)="openErrorsDialog()">
      </mg-system-alert>
    </ng-container>

    <ng-container *ngIf="formatErrors.length > 0">
      <div
        *ngFor="let error of formatErrors"
        class="error-msg">
        <mg-system-alert
          [message]="error"
          type="error">
        </mg-system-alert>
      </div>
    </ng-container>
  </div>

  <div fxLayout="row">
    <div
      class="control"
      (click)="scrollLeft()">
      <mg-icon>left-arrow-active</mg-icon>
    </div>

    <div
      class="table-container"
      #tableSlider>
      <div
        #personList
        fxLayout="column"
        fxLayout.xs="column"
        *ngIf="headers">
        <div
          fxLayout="row"
          formArrayName="headers">
          <div *ngFor="let header of headers?.controls; index as idx">
            <mat-form-field class="header">
              <mat-select
                [formControl]="header"
                (selectionChange)="onColumnHeaderChange(idx, $event)">
                <mat-option
                  *ngFor="let field of columnOptions"
                  [value]="field.value">
                  {{ field.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div
          *ngFor="let record of records | slice: 0:rowsToDisplay; let i = index"
          class="person-record-wrap"
          fxLayout="column"
          [class.person-highlight]="">
          <div
            fxLayout="row"
            fxLayout.xs="column">
            <div
              fxLayout="row"
              fxFlex>
              <div
                *ngFor="let column of record; let i = index"
                class="record-column">
                {{ column }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="control"
      (click)="scrollRight()">
      <mg-icon>right-arrow-active</mg-icon>
    </div>
  </div>
</form>

<div
  fxLayout="row"
  fxLayoutAlign="end center"
  class="showing-counter"
  *ngIf="records.length > 0 && records.length > rowsToDisplay">
  Showing {{ rowsToDisplay }} of {{ records.length }}
  {{ records.length == 1 ? 'row' : 'rows' }}
</div>
<div
  fxLayout="row"
  fxLayoutAlign="end center"
  class="showing-counter"
  *ngIf="records.length < rowsToDisplay">
  Showing {{ records.length }} of {{ records.length }}
  {{ records.length == 1 ? 'row' : 'rows' }}
</div>
