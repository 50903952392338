import { Injectable } from '@angular/core';

import { ErrorHandlerService } from '@shared/services/error-handler';

@Injectable()
export class KioskCheckInService {
  // Service constructor

  constructor(private _errorHandler: ErrorHandlerService) {}
}
