<div class="header">
  <mg-btn
    variant="icon"
    iconSet="minga"
    icon="mg-chevron-left"
    size="medium"
    (pressed)="previousClicked()">
  </mg-btn>
  <button
    class="period"
    (click)="periodClicked()">
    <mg-text variant="body-md-secondary">{{ periodLabel }}</mg-text>
  </button>
  <mg-btn
    variant="icon"
    iconSet="minga"
    icon="mg-chevron-right"
    size="medium"
    (pressed)="nextClicked()">
  </mg-btn>
</div>
