import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { GroupDetails } from '../models/GroupDetails';

export interface GroupDetailState extends EntityState<GroupDetails> {}

export namespace GroupDetailState {
  export const entities: EntityAdapter<GroupDetails> = createEntityAdapter({
    selectId: (groupDetails: GroupDetails) => groupDetails.hash,
    sortComparer: false,
  });

  export const initialState = entities.getInitialState();
}
