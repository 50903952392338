import { Action, createReducer, on, select } from '@ngrx/store';

import {
  sendContentEmail,
  sendContentEmailFailed,
  sendContentEmailSucceeded,
} from '../actions';
import { IContentStoreState } from '../state';

const _contentReducer = createReducer(
  IContentStoreState.initialState,
  on(sendContentEmail, state => ({
    ...state,
    contentEmailSending: true,
  })),
  on(sendContentEmailSucceeded, state => ({
    ...state,
    contentEmailSending: false,
  })),
  on(sendContentEmailFailed, state => ({
    ...state,
    contentEmailSending: false,
  })),
);

export function contentReducer(
  state: IContentStoreState | undefined,
  action: Action,
) {
  return _contentReducer(state, action);
}
