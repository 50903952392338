import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MgIconModule } from 'minga/app/src/app/icon';

import { MgLikeElement } from './MgLike.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgLikeElement],
  exports: [MgLikeElement],
})
export class MgLikeModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-like')) {
      //       //   MgLikeElement,
      //   {injector},
      // ));
    }
  }
}
