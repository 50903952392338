import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IHallPassStatsPeopleResult } from 'minga/domain/hallPass';
import { HpmReportsFilter } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import {
  TemplateColumnKeys,
  HallPassReportColumns,
} from 'minga/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';

import { HpmReportsService } from '../services';

@Injectable()
export class HpmReportsUsersService extends ReportDatasourceService<IHallPassStatsPeopleResult> {
  historyInfo = {
    filter: HpmReportsFilter.ISSUED_TO,
    url: '/hall-pass/reports/' + ReportTypes.HALL_PASS_HISTORY,
  };
  summaryInfo = {
    filter: HpmReportsFilter.ISSUED_TO,
    url: '/hall-pass/reports/' + ReportTypes.HALL_PASS_SUMMARY,
  };

  /** Service Constructor */
  constructor(private _hpmReports: HpmReportsService, _router: Router) {
    super(_router, _hpmReports, HpmReportsUsersService.name);
    const displayCol = [
      {
        header: '',
        key: TemplateColumnKeys.SUMMARY,
        columnClasses: ['w-16'],
      },
      {
        header: '',
        key: TemplateColumnKeys.HISTORY,
        columnClasses: ['w-16'],
      },
    ];
    super.setDisplayColumns(HallPassReportColumns.STUDENTS, displayCol);
  }

  async fetch(offset: number, limit: number) {
    return await this._hpmReports.getUsers(offset, limit, this._currentSort);
  }
}
