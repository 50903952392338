import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ModerationModule } from '@shared/components/moderation';

import { HomeChallengesModule } from './components/home-challenges/home-challenges.module';
import { HomeContentModule } from './components/home-content/home-content.module';
import { HomeFeedModule } from './components/home-feed/home-feed.module';
import { HomeFilesModule } from './components/home-files/home-files.module';
import { HomeGalleryModule } from './components/home-gallery/home-gallery.module';
import { HomePollsModule } from './components/home-polls/home-polls.module';
import { HomeComponent } from './home.component';
import { HOME_ROUTES } from './home.routes';

@NgModule({
  imports: [
    // Routing
    RouterModule.forChild(HOME_ROUTES),

    // Minga dependencies
    HomeFeedModule,
    HomeFilesModule,
    HomeChallengesModule,
    HomeGalleryModule,
    HomePollsModule,
    HomeContentModule,
    ModerationModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [HomeComponent],
  exports: [RouterModule],
})
export class HomeModule {}
