<ng-container
  *ngIf="
    schedule && schedule.flexTimePeriodWithRegistration?.length > 0;
    else noResults
  ">
  <div
    *ngFor="let period of schedule.flexTimePeriodWithRegistration"
    class="period"
    fxLayout="column">
    <div
      class="period-header"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div>
        <mg-text
          variant="label-sm-secondary"
          element="p"
          textTransform="uppercase"
          spacing="0-half"
          >{{ period.date | date: 'EEEE, MMM d' }}</mg-text
        >
        <mg-text
          variant="label-sm-secondary"
          element="p"
          textTransform="uppercase"
          >{{ period | formatTimeRange }}</mg-text
        >
      </div>
      <div>
        <mg-text variant="label-md-secondary">{{ period.title }}</mg-text>
      </div>
    </div>
    <div
      class="period-activity"
      fxLayout="column"
      fxLayoutAlign="center center">
      <div
        *ngIf="(periodRegistrationInProgess$ | async) === period.id"
        class="period-registering-overlay">
        <mg-spinner
          [diameter]="40"
          [thickness]="3">
        </mg-spinner>
      </div>
      <mg-ftm-activity-card
        *ngIf="period.myRegistration; else notRegistered"
        [activity]="period.myRegistration.registration"
        [visibleFields]="CARD_FIELDS">
        <ng-template #actions>
          <div
            class="activity-status"
            [ngClass]="{
              unlocked:
                period.enableStudentSelfManagement &&
                !period.myRegistration.cannotUnregister
            }"
            fxLayout="row"
            fxLayoutAlign="center center">
            <mg-text
              variant="label-sm-secondary"
              textTransform="uppercase"
              [color]="
                period.enableStudentSelfManagement &&
                !period.myRegistration.cannotUnregister
                  ? 'flextime'
                  : undefined
              "
              >{{
                period.myRegistration.cannotUnregister
                  ? MESSAGES.ASSIGNED_LABEL
                  : MESSAGES.REGISTERED_LABEL
              }}</mg-text
            >
            <mg-icon
              *ngIf="
                period.enableStudentSelfManagement &&
                !period.myRegistration.cannotUnregister
              "
              >check-mark</mg-icon
            >
            <mg-icon
              *ngIf="
                period.myRegistration.cannotUnregister ||
                !period.enableStudentSelfManagement
              "
              >lock-icon</mg-icon
            >
          </div>
          <mg-btn
            *ngIf="
              ('FLEX_TIME_MANAGE' | hasPermission) ||
              (('FLEX_TIME_VIEW_MY_ACTIVITY' | hasPermission) === false &&
                period.enableStudentSelfManagement &&
                !period.myRegistration.cannotUnregister &&
                (isProfileView$ | async) === false)
            "
            variant="outlined"
            size="small"
            (pressed)="
              confirmChange(period.id, period.myRegistration.registration)
            ">
            {{ MESSAGES.CHANGE_BTN }}
          </mg-btn>
        </ng-template>
      </mg-ftm-activity-card>
      <ng-template #notRegistered>
        <ng-container
          *ngIf="period.activityInstances?.length === 0; else register">
          <mg-empty-state
            class="empty-state"
            [title]="MESSAGES.NO_ACTIVITIES_AVAILABLE">
          </mg-empty-state>
        </ng-container>

        <ng-template #register>
          <div class="activity-register">
            <mg-btn
              *ngIf="
                ('FLEX_TIME_MANAGE' | hasPermission) ||
                  (('FLEX_TIME_VIEW_MY_ACTIVITY' | hasPermission) === false &&
                    period.enableStudentSelfManagement &&
                    (isProfileView$ | async) === false);
                else locked
              "
              variant="filled"
              size="small"
              (pressed)="openActivities(period.id)">
              {{ MESSAGES.REGISTER_BTN }}
            </mg-btn>
            <ng-template #locked>
              <mg-empty-state
                class="empty-state"
                [title]="MESSAGES.PERIOD_LOCKED">
              </mg-empty-state>
            </ng-template>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #noResults>
  <mg-empty-state
    class="empty-state"
    [title]="MESSAGES.NO_ACTIVITIES_AVAILABLE">
  </mg-empty-state>
</ng-template>
