import {
  IListUploadField,
  ListUploaderRow,
} from '@shared/components/XlsListUploader';

export const arrayToListUploadRow = (
  record: string[],
  selectedFields: (IListUploadField | null)[],
): ListUploaderRow => {
  const row: ListUploaderRow = {};
  for (let index = 0; index < selectedFields.length; index++) {
    const element = selectedFields[index];
    const value = record[index].trim();
    if (element && element.id) {
      const currentVal = row[element.id];
      if (currentVal && element.allowMultiple) {
        if (Array.isArray(currentVal)) {
          currentVal.push(value);
        }
      } else {
        if (element.allowMultiple) {
          const values: string[] = [];
          values.push(value);
          row[element.id] = values;
        } else {
          row[element.id] = value;
        }
      }
    }
  }
  return row;
};
