<mg-manager-overlay-reports-subpage
  [scheduleReportsButton]="false"
  [dataService]="dataService$ | async"
  (exportListPressed)="ftmReports.exportReport(this.reportType.active)">
  <!-- Header -->
  <ng-template #header>
    <ng-container
      *ngIf="
        (media$ | async) !== 'xs' && (media$ | async) !== 'sm';
        else mobileHeaderTemplate
      ">
      <div class="header-container">
        <div class="filters-group">
          <div class="filters-group-row">
            <ng-container
              *ngTemplateOutlet="reportTypeFilterTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
            <ng-container
              *ngTemplateOutlet="peopleSearchTemplate"></ng-container>
          </div>
          <div class="filters-group-row">
            <ng-container
              *ngIf="reportType.active === REPORT_PAGES.FLEX_PERIOD_REGISTERED"
              [ngTemplateOutlet]="activitiesTemplate"></ng-container>
            <ng-container
              *ngIf="
                reportType.active === REPORT_PAGES.FLEX_PERIOD_REGISTERED;
                else singlePeriodTemplate
              "
              [ngTemplateOutlet]="multiplePeriodTemplate"></ng-container>
            <ng-container
              *ngIf="reportType.active === REPORT_PAGES.FLEX_PERIOD_REGISTERED"
              [ngTemplateOutlet]="checkinStatus"></ng-container>
            <ng-container [ngTemplateOutlet]="userListTemplate"></ng-container>

            <ng-container
              *ngTemplateOutlet="filterButtonTemplate"></ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Filter Template -->
<ng-template #reportTypeFilterTemplate>
  <div
    class="filters-field full-width"
    fxFlex.gt-sm="329px">
    <mg-form-select
      placeholder="Report Type"
      labelBackground="white"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="reportType.active"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="reportType.options"
      [floatingLabel]="false"
      (selectionChanged)="onChangeReportType($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Template -->
<ng-template #datePickerTemplate>
  <div class="filters-field full-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>

<!-- People Search Template -->
<ng-template #peopleSearchTemplate>
  <div
    class="filters-field full-width"
    fxFlex.gt-sm="300px">
    <mg-multi-person-search
      [id]="'report-report-view-click-filter-search'"
      (selectionChanged)="onPersonSelected($event)">
    </mg-multi-person-search>
  </div>
</ng-template>

<!-- Activities Types Template -->
<ng-template #activitiesTemplate>
  <div class="full-width">
    <mg-form-select
      #typeFilterSelect
      [placeholder]="MESSAGES.SELECT_ACTIVITY"
      labelBackground="white"
      [value]="activities$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="activityOptions$ | async"
      (selectionChanged)="onChangeActivity($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Periods Template -->
<ng-template #multiplePeriodTemplate>
  <div class="full-width">
    <mg-form-select
      #typeFilterSelect
      [placeholder]="MESSAGES.SELECT_PERIOD"
      labelBackground="white"
      [value]="periods$ | async"
      [closeOnSelect]="false"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [options]="periodOptions$ | async"
      (selectionChanged)="onChangePeriod($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Period Template -->
<ng-template #singlePeriodTemplate>
  <div
    class="full-width"
    fxFlex.gt-sm="200px">
    <mg-form-select
      #typeFilterSelect
      [placeholder]="MESSAGES.SELECT_PERIOD"
      labelBackground="white"
      [value]="(periods$ | async)[0]"
      [closeOnSelect]="false"
      [multiple]="false"
      [searchable]="true"
      [isClearable]="true"
      [options]="periodOptions$ | async"
      (selectionChanged)="onChangePeriod($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Check in Status Template -->
<ng-template #checkinStatus>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      #typeFilterSelect
      [placeholder]="MESSAGES.SELECT_CHECKIN_STATUS"
      labelBackground="white"
      [value]="checkinStatus$ | async"
      [closeOnSelect]="false"
      [multiple]="false"
      [searchable]="true"
      [isClearable]="true"
      [options]="checkinStatusOptions"
      (selectionChanged)="onChangeCheckinStatus($event)">
    </mg-form-select>
  </div>
</ng-template>

<ng-template #userListTemplate>
  <div class="half-width">
    <mg-user-list-filter
      [id]="'report-report-view-click-filter-userlist'"
      [placeholder]="MESSAGES.SELECT_LABEL_USER_LIST"
      [value]="(ftmReports.filter$ | async).userList"
      (selectChanged)="changeUserList($any($event))">
    </mg-user-list-filter>
  </div>
</ng-template>

<!-- Filter Buttons Template -->
<ng-template #filterButtonTemplate>
  <div
    fxFlex="noshrink"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="10px">
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-apply'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'filled'
            : 'outlined'
        "
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [responsive]="true"
        [wide]="true"
        (pressed)="applyFilter()">
        {{ MESSAGES.BUTTON_APPLY_FILTER }}
      </mg-btn>
    </div>
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-clear'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'outlined'
            : 'text'
        "
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [responsive]="true"
        [wide]="true"
        (pressed)="clearFilter()">
        {{ MESSAGES.BUTTON_CLEAR_FILTER }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<ng-template #mobileHeaderTemplate>
  <div class="header-container">
    <div class="filters-group">
      <div class="filters-group-row">
        <ng-container
          *ngTemplateOutlet="reportTypeFilterTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="peopleSearchTemplate"></ng-container>
      </div>
      <mg-collapsible
        [collapseText]="MESSAGES.HIDE_FILTERS"
        [expandText]="MESSAGES.SHOW_FILTERS">
        <div class="filters-group-row">
          <ng-container
            *ngIf="reportType.active === REPORT_PAGES.FLEX_PERIOD_REGISTERED"
            [ngTemplateOutlet]="activitiesTemplate"></ng-container>
          <ng-container
            *ngIf="
              reportType.active === REPORT_PAGES.FLEX_PERIOD_REGISTERED;
              else singlePeriodTemplate
            "
            [ngTemplateOutlet]="multiplePeriodTemplate"></ng-container>
          <ng-container
            *ngIf="reportType.active === REPORT_PAGES.FLEX_PERIOD_REGISTERED"
            [ngTemplateOutlet]="checkinStatus"></ng-container>
          <ng-container [ngTemplateOutlet]="userListTemplate"></ng-container>
        </div>
      </mg-collapsible>
      <div class="filters-group-row">
        <ng-container *ngTemplateOutlet="filterButtonTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
