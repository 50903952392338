import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  GlobalFloatingPortalService,
  IGlobalFloatingPortalWrapper,
} from 'minga/app/src/app/GlobalFloatingPortal/services';
import { DialogComponent } from 'minga/app/src/app/components/Dialog';
import { MgModalService } from 'minga/app/src/app/minimal/services/MgModal';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import { MgOverlayComponent } from 'minga/app/src/app/overlay';
import { MgOverlayRouterOutletDirective } from 'minga/app/src/app/overlay/MgOverlayRouterOutlet.directive';
import { LinkOpenerService } from 'minga/app/src/app/services/LinkOpener';

import {
  APP_LINKING_ALLOWED_ORIGINS,
  APP_LINKING_ALLOWED_PROTOCOLS,
  AppMessage,
  GLOBAL_FLOATING_PORTAL_ZINDEX,
  MULTI_OUTLET_HACK_FINISH_TIMEOUT,
} from './constants/app.constants';
import { finishMultiOutletHack } from './util/app-outlet.utils';

/**
 * App component
 *
 * Main entry component. Responsible for setting up app
 * run-time environment and layout.
 */
@Component({
  selector: 'mg-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  // Children
  @ViewChild('overlayOutlet', { static: true })
  overlayOutlet?: MgOverlayRouterOutletDirective;
  @ViewChild('primaryOutletWrap', { static: true })
  primaryOutletWrap?: ElementRef;
  @ViewChild(MgOverlayComponent, { static: true })
  overlayComponent?: MgOverlayComponent;
  @ViewChild('externalLinkDialog', { static: true })
  externalLinkDialog?: DialogComponent;

  // Constants
  public readonly MSG = AppMessage;

  // Cleanup
  private readonly _destroyedSubject$ = new ReplaySubject<void>(1);

  // Refs
  private _externalLinkDialogRef: MatDialogRef<any> | null = null;

  // State
  public activeOverlayComponent: any | null = null;
  private _snappedScrollPosition = 0;

  /** Global floating portals */
  public readonly globalFloatingPortals$ =
    this.globalFloatingPortalService.globalFloatingPortals$;
  public readonly globalFloatingPortalBaseZindex =
    GLOBAL_FLOATING_PORTAL_ZINDEX;

  /** Modal background color */
  public readonly modalBgColor$ = this.modalService.onOpened.pipe(
    map(ev => ev.options.backgroundColor || ''),
  );

  // Computed values
  @HostBinding('class.mg-overlay-opened')
  get hasMgOverlayOpenedClass() {
    return this.overlayOutlet?.isActivated ?? false;
  }

  /** Component constructor */
  constructor(
    public router: Router,
    public rootService: RootService,
    public modalService: MgModalService,
    public location: Location,
    public linkOpener: LinkOpenerService,
    public globalFloatingPortalService: GlobalFloatingPortalService,
    public translate: TranslateService,
    public titleService: Title,
    private _ngZone: NgZone,
  ) {
    this._configTranslation();
    this._initKeyboardListeners();
  }

  ngOnInit() {
    this._multiOutletHack();
  }

  ngOnDestroy() {
    this._destroyedSubject$.next();
    this._destroyedSubject$.complete();
  }

  @HostListener('document:click', ['$event'])
  protected globalClickHandler(ev: MouseEvent) {
    if (ev.defaultPrevented) return true;
    let node: Node | null = ev.target as Node;
    let anchorEl: HTMLAnchorElement | null = null;
    for (; node?.parentNode; node = node?.parentNode) {
      if (node?.nodeName === 'A') {
        anchorEl = node as HTMLAnchorElement;
        break;
      }
    }
    if (!anchorEl?.href) return true;
    const anchorUrl = new URL(anchorEl.href);
    if (anchorUrl.origin === window.location.origin) return true;
    if (APP_LINKING_ALLOWED_ORIGINS.includes(anchorUrl.origin as any)) {
      this.linkOpener.open(anchorUrl.href);
      return false;
    }
    if (APP_LINKING_ALLOWED_PROTOCOLS.includes(anchorUrl.protocol as any))
      return true;
    const externalLinkDialog = this.externalLinkDialog;
    if (!externalLinkDialog) {
      console.warn('No external link dialog');
      return true;
    }
    if (this._externalLinkDialogRef) return false;
    this._ngZone.run(() => {
      this._externalLinkDialogRef = externalLinkDialog.open();
      this._externalLinkDialogRef.beforeClosed().subscribe(value => {
        if (value === true) this.linkOpener.open(anchorEl.href);
      });
      this._externalLinkDialogRef.afterClosed().subscribe(() => {
        this._externalLinkDialogRef = null;
      });
    });
    return false;
  }

  /**
   * Initialization on individual portals of `globalFloatingPortals$`. Used for
   * notifying when the portal outlet has actually attached the portal in the
   * root components view.
   *
   * This allows users (such as directives) to trigger change detection after
   * portal is attached.
   */
  public initPortalOutlet(portal: IGlobalFloatingPortalWrapper) {
    const notified = portal.viewAttachNotify.getValue();
    if (!notified) setTimeout(() => portal.viewAttachNotify.next(true));
    return portal.portal;
  }

  public activateOverlay(overlayComponent: any) {
    if (!overlayComponent) return;
    this.activeOverlayComponent = overlayComponent;
    this._snapshotPrimaryOutletWrap();
  }

  public deactivateOverlay(overlayComponent: any) {
    if (this.activeOverlayComponent === overlayComponent)
      this.activeOverlayComponent = null;
    requestAnimationFrame(() => {
      if (this.activeOverlayComponent) return;
      this._restorePrimaryOutletWrap();
    });
  }

  private _snapshotPrimaryOutletWrap() {
    if (!this.primaryOutletWrap) return;

    const el = this.primaryOutletWrap.nativeElement;
    const rect = el.getBoundingClientRect();

    this._snappedScrollPosition = window.scrollY;
    el.style.position = 'fixed';
    el.style.top = rect.top + 'px';
    el.style.left = rect.left + 'px';
    el.style.right = '0px';
  }

  private _initKeyboardListeners() {
    window.addEventListener('keyboardWillHide', ev => {
      document.body.classList.remove('keyboard-shown');
      document.body.classList.add('keyboard-hidden');
    });

    window.addEventListener('keyboardWillShow', ev => {
      document.body.classList.remove('keyboard-hidden');
      document.body.classList.add('keyboard-shown');
    });
  }

  private _configTranslation() {
    this.translate.setDefaultLang('en-US');
    this.translate.use('en-US');
  }

  private _restorePrimaryOutletWrap(attempts: number = 0) {
    if (!this.primaryOutletWrap) return;

    const el = this.primaryOutletWrap.nativeElement;

    el.style.position = '';
    el.style.top = '';
    el.style.left = '';
    el.style.right = '';

    window.scroll(0, this._snappedScrollPosition);

    if (attempts < 100 && window.scrollY !== this._snappedScrollPosition) {
      document.body.classList.add('restore-scroll');
      requestAnimationFrame(() => {
        this._restorePrimaryOutletWrap(++attempts);
      });
    } else document.body.classList.remove('restore-scroll');
  }

  private _multiOutletHack() {
    setTimeout(async () => {
      while (document.visibilityState === 'hidden') {
        await new Promise(resolve =>
          setTimeout(resolve, MULTI_OUTLET_HACK_FINISH_TIMEOUT),
        );
      }
      finishMultiOutletHack(this.location, this.router);
    }, MULTI_OUTLET_HACK_FINISH_TIMEOUT);
  }
}
