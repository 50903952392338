import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import * as day from 'dayjs';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  from,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  take,
  takeUntil,
} from 'rxjs/operators';

import { ManagerReportHeaderFilterReportType } from 'minga/app/src/app/components/manager-report/components/manager-report-header';
import { Person } from 'minga/app/src/app/people';
import { GradesService } from 'minga/app/src/app/services/Grades';
import { ReportTypes } from 'minga/domain/reportTypes';
import { MingaPermission, mingaSettingTypes } from 'minga/util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { LayoutService } from '@modules/layout/services';
import { MmScheduledReportsService } from '@modules/minga-manager/components/mm-scheduled-reports/services';
import {
  SUPPORTED_SCHEDULE_TYPES,
  ScheduledReportType,
} from '@modules/minga-manager/components/mm-scheduled-reports/types';

import { FormSelectComponent } from '@shared/components/form';
import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import { FormSelectOption } from '@shared/components/form/types';
import { MultiPersonSearchComponent } from '@shared/components/multi-person-search';
import { AutomationService } from '@shared/services/automation';
import { PbisService } from '@shared/services/pbis';

import { BehaviorManagerRoutes } from '../../constants';
import {
  BmReportsFilterType,
  BmReportsMessages,
  CONS_CATEGORIES,
  CONS_REPORT_CAT_TYPES,
  CONS_REPORT_STATUS,
  CONS_REPORT_TYPES,
  REPORT_TYPES,
  BM_AUTO_FILTER_STATE_KEY,
  BM_REPORTS_FILTER_INIT_STATE,
} from './constants';
import { BmReportsService } from './services';
import { peopleToIssuedFilters } from './utils';

/**
 * Behavior Manager Reports
 */
@Component({
  selector: 'mg-bm-reports',
  templateUrl: './bm-reports.component.html',
  styleUrls: ['./bm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmReportsComponent implements OnDestroy {
  @ViewChild(MultiPersonSearchComponent)
  private _peopleSearchComponent: MultiPersonSearchComponent;
  @ViewChildren(FormSelectComponent)
  private _selectComponents: QueryList<FormSelectComponent>;

  /** Enums & Constants */
  MESSAGES = BmReportsMessages;

  /** Subscription Cleanup Helper */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Table Data Service */
  private _dataServiceSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public readonly dataService$: Observable<any> =
    this._dataServiceSubj.asObservable();

  /** Behavior Types */
  public readonly typesOptions$: Observable<FormSelectOption<number>[]>;
  public readonly consTypesOptions$: Observable<FormSelectOption<number>[]>;
  public readonly automationGroupOptions$: Observable<
    FormSelectOption<number>[]
  >;

  /** Filters */
  public readonly grades$: Observable<string[]>;
  public readonly gradeOptions$: Observable<FormSelectOption<any>[]>;
  public readonly behaviorTypes$: Observable<number[]>;
  public readonly consequenceTypes$: Observable<number[]>;
  public readonly consCatTypes$: Observable<number[]>;
  public readonly consStatus$: Observable<string[]>;
  public readonly automationGroup$: Observable<number>;
  public readonly consCategories$: Observable<number>;
  public readonly canScheduleReport$ = this._permissions.observePermission(
    MingaPermission.PBIS_TYPE_MANAGE,
  );
  public readonly automationTypes$: Observable<number>;
  public readonly automationTypeOptions$: Observable<FormSelectOption<any>[]>;
  public readonly userList$: Observable<number[]>;
  public readonly userListOptions$: Observable<FormSelectOption<number>[]>;

  public filters$ = this._trackingReports.filter$;
  public SUPPPORTED_SCHEDULE_TYPES: ScheduledReportType[] =
    SUPPORTED_SCHEDULE_TYPES;

  public readonly media$: Observable<any>;

  /** Search */
  setPeopleSearchValue$: Subject<string[]> = new Subject<string[]>();
  public readonly consequenceIDFC = new FormControl(undefined);

  /** General Variables */
  reportType: ManagerReportHeaderFilterReportType = {
    active: ReportTypes.PBIS_SUMMARY,
    options: REPORT_TYPES,
  };
  noDateFilterTypes = [
    ReportTypes.PBIS_AUTOMATION_COUNT,
    ReportTypes.PBIS_CONS_OVERDUE,
  ];
  consequenceReports = CONS_REPORT_TYPES.map(option => option.value);
  consHistType = ReportTypes.PBIS_CONS_HISTORY;
  autoCounterType = ReportTypes.PBIS_AUTOMATION_COUNT;
  consStatusOptions = CONS_REPORT_STATUS;
  consCatTypes = CONS_REPORT_CAT_TYPES;
  consCat = CONS_CATEGORIES;

  public range: FormGroup;

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    public layout: LayoutService,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _pbisService: PbisService,
    private _trackingReports: BmReportsService,
    private _gradesService: GradesService,
    private _settingService: MingaSettingsService,
    private _route: ActivatedRoute,
    private _analytics: AnalyticsService,
    private _mmScheduledReportService: MmScheduledReportsService,
    private _permissions: PermissionsService,
    private _automationService: AutomationService,
  ) {
    // have to do this so that we are updated whenever the url
    // is changed.
    this._route.url.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      if (this._route.children[0]) {
        this._route.children[0].url
          .pipe(takeUntil(this._destroyed$))
          .subscribe(url => {
            if (url[0]) {
              const report = url[0].path;
              const possiblePaths = this.reportType.options
                .map(type => type.value)
                .concat(this.consequenceReports);
              if (possiblePaths.includes(report)) {
                this.reportType.active = report;
                this._cdr.markForCheck();
              }
            }
          });
      }
    });

    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );

    this._gradesService.fetchIfNeeded();

    /** Filters */
    this.grades$ = this._trackingReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => curFilter.grades),
    );
    this.gradeOptions$ = this._gradesService.grades$.pipe(
      takeUntil(this._destroyed$),
      map(grades => grades.map(grade => ({ label: grade, value: grade }))),
    );
    this.behaviorTypes$ = this._trackingReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => {
        return curFilter.types;
      }),
    );
    this.consequenceTypes$ = this._trackingReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => curFilter.consTypes),
    );
    this.consCatTypes$ = this._trackingReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => curFilter.consCatTypes),
    );
    this.consStatus$ = this._trackingReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => curFilter.consStatus),
    );
    this.automationGroup$ = this._trackingReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => curFilter.autoGroup),
    );
    this.consCategories$ = this._trackingReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(curFilter => curFilter.consequenceCategory),
    );
    this.typesOptions$ = this._pbisService.getTypes(true).pipe(
      takeUntil(this._destroyed$),
      map(types =>
        types.map(type => ({
          label: type.name,
          value: type.id,
        })),
      ),
    );
    this.consTypesOptions$ = this._pbisService.getConsTypes(true).pipe(
      takeUntil(this._destroyed$),
      map(types => types.map(type => ({ label: type.name, value: type.id }))),
    );
    this.automationGroupOptions$ = from(
      this._automationService.fetchAutomations(),
    ).pipe(
      map(automation => {
        return automation.map(auto => ({
          label: auto.name,
          value: auto.id,
        }));
      }),
    );
    this.automationTypeOptions$ = from(
      this._automationService.fetchAutomations(),
    ).pipe(
      takeUntil(this._destroyed$),
      map(types => types.map(type => ({ label: type.name, value: type.id }))),
    );
    const automationFilterState = JSON.parse(
      localStorage.getItem(BM_AUTO_FILTER_STATE_KEY),
    );
    if (automationFilterState) {
      this._trackingReports.setAndApplyFilter(
        BmReportsFilterType.AUTO_TYPE,
        automationFilterState,
      );
    }
    this.automationTypes$ = combineLatest([
      this._trackingReports.filter$,
      this.automationTypeOptions$,
    ]).pipe(
      takeUntil(this._destroyed$),
      map(([curFilter, options]) => {
        if (curFilter.automationTypes?.[0]) return curFilter.automationTypes[0];
        if (options.length > 0) {
          this._trackingReports.setFilter(
            BmReportsFilterType.AUTO_TYPE,
            options[0].value,
          );
          this.applyFilter();
          return options[0].value;
        }
      }),
    );
    this.userList$ = this._trackingReports.filter$.pipe(
      takeUntil(this._destroyed$),
      map(state => state.userList),
    );

    this._route.queryParams
      .pipe(takeUntil(this._destroyed$))
      .subscribe(params => {
        if (params.automationType) {
          this._trackingReports.setFilter(BmReportsFilterType.AUTO_TYPE, [
            +params.automationType,
          ]);
        }

        if (params.consequenceId) {
          this._filterByConsequenceId(+params.consequenceId);
        }
      });
    this._trackingReports.onPersonSelectedFromTable
      .pipe(takeUntil(this._destroyed$))
      .subscribe(selected => {
        this._peopleSearchComponent.setHashValue([selected], true);
        this._cdr.markForCheck();
      });
    this._settingService
      .getSettingValueObs(mingaSettingTypes.BM_CONSEQUENCE_ENABLE)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(enabled => {
        if (enabled) {
          this.reportType.options = [...REPORT_TYPES, ...CONS_REPORT_TYPES];
        } else {
          this.reportType.options = REPORT_TYPES.filter(
            report => report.value !== ReportTypes.PBIS_AUTOMATION_COUNT,
          );
        }
      });

    this._trackingReports.filter$
      .pipe(
        takeUntil(this._destroyed$),
        map(filter => filter.consID),
      )
      .subscribe(id => this.consequenceIDFC.setValue(id));
    this.consequenceIDFC.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        debounceTime(200),
        distinctUntilChanged(),
      )
      .subscribe(ID =>
        this._trackingReports.setFilter(BmReportsFilterType.CONS_ID, ID),
      );

    const currentFilter = this._trackingReports.getCurrentFilterState();

    this.range = initializeRange({
      start: {
        value: currentFilter.startDate,
      },
      end: {
        value: currentFilter.endDate,
      },
    });

    this.range.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(range => {
        this._trackingReports.setFilter(
          BmReportsFilterType.START_DATE,
          range.start,
        );
        this._trackingReports.setFilter(
          BmReportsFilterType.END_DATE,
          range.end,
        );
      });
  }

  ngOnDestroy(): void {
    this._trackingReports.clearFilter();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  async onChangeReportType(value: string): Promise<void> {
    await this._router.navigateByUrl(
      `/${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.REPORTS}/${value}`,
    );
    return;
  }

  async onChangeType(value: any, consequence?: boolean): Promise<void> {
    let filter = BmReportsFilterType.TYPE;
    if (consequence) filter = BmReportsFilterType.CONS_TYPE;
    this._trackingReports.setFilter(filter, value);
    return;
  }

  async onChangeCatType(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.CONS_CAT_TYPE, value);
    return;
  }

  async onChangeConsStatus(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.CONS_STATUS, value);
    return;
  }

  async onChangeGrade(value): Promise<void> {
    this._trackingReports.setFilter(BmReportsFilterType.GRADE, value);
    return;
  }

  async onPersonSelected(people: Person[]): Promise<void> {
    const { issuedTo, issuedBy } = peopleToIssuedFilters(
      people,
      this.reportType.active,
    );

    this._trackingReports.setFilter(BmReportsFilterType.ISSUED_TO, issuedTo);
    this._trackingReports.setFilter(BmReportsFilterType.ISSUED_BY, issuedBy);
    return;
  }

  async onAutomationTypeChange(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.AUTO_TYPE, value);
    localStorage.setItem(BM_AUTO_FILTER_STATE_KEY, JSON.stringify(value));
    return;
  }

  public async onAutomationGroupChange(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.AUTO_GROUP, value);
    return;
  }

  public async onConsequenceCategoryChange(value: any) {
    this._trackingReports.setFilter(BmReportsFilterType.CONS_CAT, value);
    return;
  }

  public onChangeUserList(value: number[]): void {
    this._trackingReports.setFilter(BmReportsFilterType.USER_LIST, value);
  }

  applyFilter() {
    this._trackingReports.applyFilter();
    this._analytics.sendManagerReport({
      managerType: 'pbis',
      reportType: this.reportType.active,
    });
  }

  onClickExport() {
    this._trackingReports.exportReport(this.reportType.active);
  }

  clearFilter() {
    this._peopleSearchComponent.setValue([]);
    this._trackingReports.clearFilter();
    this._selectComponents.forEach((component, _) => {
      if (component.placeholder === 'Report Type') return;

      component.control.setValue(undefined);
    });

    this.range.patchValue({
      start: BM_REPORTS_FILTER_INIT_STATE.startDate,
      end: BM_REPORTS_FILTER_INIT_STATE.endDate,
    });
  }

  public async onScheduleReport() {
    const filters = await this.filters$.pipe(take(1)).toPromise();

    await this._mmScheduledReportService.scheduleReport(
      this.reportType.active,
      filters,
    );
  }

  public onActivate(componentRef: any) {
    this._dataServiceSubj.next(componentRef.ds);
  }

  private async _filterByConsequenceId(id: number) {
    this._trackingReports.clearFilter();

    await this._trackingReports._handleSetFilter(
      BmReportsFilterType.CONS_ID,
      id,
    );
    const today = day();
    // we need start of school year since we don't know when this consequence was issued
    const startOfSchoolYear = today.isAfter(`${today.format('YYYY')}-08-01`)
      ? day().month(7).startOf('month')
      : day().subtract(1, 'years').month(7).startOf('month');

    await this._trackingReports._handleSetFilter(
      BmReportsFilterType.START_DATE,
      startOfSchoolYear,
    );
    await this._trackingReports._handleSetFilter(
      BmReportsFilterType.END_DATE,
      today,
    );
    this._trackingReports.applyFilter();
  }
}
