<section
  class="sheet"
  [ngClass]="classes">
  <div class="sheet-header">
    <div class="sheet-header-wrap">
      <mg-btn
        *ngIf="showBackBtn"
        class="sheet-back-btn"
        variant="icon-secondary"
        icon="mg-arrow-left-alt"
        size="large"
        iconSet="minga"
        (pressed)="back()"></mg-btn>

      <mg-text
        *ngIf="title"
        variant="header-lg-secondary"
        >{{ title }}</mg-text
      >

      <mg-btn
        *ngIf="showCloseBtn"
        class="sheet-close-btn"
        variant="icon-secondary"
        icon="mg-cross"
        iconSet="minga"
        size="large"
        (pressed)="close()"></mg-btn>
    </div>
  </div>

  <div class="sheet-content">
    <ng-content></ng-content>
  </div>

  <div
    *ngIf="actions"
    class="sheet-actions">
    <ng-container *ngTemplateOutlet="actions"></ng-container>
  </div>
</section>
