<!-- Overlay Modal Container -->
<mg-overlay-primary
  size="auto"
  [overlayTitle]="dialogData?.title || defaultModalTitle">
  <!-- Main content -->
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <!-- Footer -->
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main content template -->
<ng-template #mainTemplate>
  <main [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <!-- Errors -->
    <div
      *ngIf="webcamErrorState$ | async"
      class="error-container">
      {{ (webcamErrorState$ | async)?.message }}
    </div>
    <!-- Loading state -->
    <div
      *ngIf="isLoading$ | async"
      class="loading-container">
      <mg-spinner
        [diameter]="40"
        [thickness]="4">
      </mg-spinner>
    </div>
    <!-- Instructions -->
    <mg-text
      *ngIf="(isLoading$ | async) === false && dialogData?.title"
      textAlign="left"
      variant="header-md-secondary"
      spacing="2-half">
      {{ defaultModalTitle }}
    </mg-text>
    <!-- Webcam Container -->
    <div
      *ngIf="(webcamImage$ | async) === undefined; else reviewImageTemplate"
      class="webcam-container"
      [class.hidden]="webcamErrorState$ | async">
      <div
        #quaggaContainer
        id="interactive"
        class="viewport"
        [class.hidden]="isLoading$ | async"></div>
    </div>
  </main>
</ng-template>

<!-- Footer content template -->
<ng-template #footerTemplate>
  <footer
    class="overlay-footer"
    fxLayout="row"
    [fxLayoutAlign]="showDoneButton ? 'space-between center' : 'start center'">
    <div>
      <mg-btn
        variant="text"
        (pressed)="cancel()">
        {{ MSG.BUTTON_CANCEL }}
      </mg-btn>
    </div>
    <div *ngIf="showDoneButton">
      <mg-generic-button
        colorTheme="blue"
        (onClick)="submit()">
        {{ MSG.BUTTON_DONE }}
      </mg-generic-button>
    </div>
  </footer>
</ng-template>

<!-- Review Image Template -->
<ng-template #reviewImageTemplate>
  <div class="webcam-image-review">
    <img [src]="(webcamImage$ | async)?.imageAsDataUrl" />
  </div>
</ng-template>
