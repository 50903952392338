import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { NavLinkIconColors } from 'src/app/navigation/components/NavLink/NavLink.constants';

@Component({
  selector: 'mg-nav-link',
  templateUrl: './NavLink.component.html',
  styleUrls: ['./NavLink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavLinkComponent {
  /** Inputs */
  @Input() text: string;
  @Input() icon: string;
  @Input() iconColor: NavLinkIconColors = NavLinkIconColors.OTHER;
  @Input() showBadge: boolean;
  @Input() highlightKey: string;
  @Input() imageUrl: string;
  @Input() template: TemplateRef<any> | null = null;
  @Input() titleText: string;
  @Input() routerLink: string | Array<string> = '';
  @HostBinding('class.disabled')
  @Input()
  disabled: boolean;

  /** Outputs */
  // this is required just to make it accessible to other components.
  @Output()
  click = new EventEmitter<MouseEvent>();

  get classList(): DOMTokenList[] {
    return this._element.nativeElement.classList;
  }

  constructor(private _element: ElementRef) {}
}
