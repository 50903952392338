import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TrackByFunction,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as profile_pb from 'minga/proto/gateway/profile_pb';
import { SuccessDialog } from 'minga/app/src/app/dialog';
import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import { PeopleManagerService } from 'minga/app/src/app/services/PeopleManager';
import {
  MingaSettingsService,
  MingaStoreFacadeService,
} from 'minga/app/src/app/store/Minga/services';
import { ProfileService } from 'minga/proto/gateway/profile_ng_grpc_pb';
import { mingaSettingTypes } from 'minga/util';
import { SaveCancelDialog } from 'src/app/dialog';
import { PermissionsService } from 'src/app/permissions';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { IUserPreference, UserPreferencesService } from '../../services';

interface IGroupedUserPreference {
  category: string;
  preferences: IUserPreference[];
}

@Component({
  templateUrl: './UserPreferencesRoute.component.html',
  styleUrls: ['./UserPreferencesRoute.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPreferencesRouteComponent implements OnInit {
  readonly person$ = this._authInfo.authPerson$;
  readonly groupedUserPreferences$: Observable<IGroupedUserPreference[]>;

  readonly ssoInfo$ = this._authInfo.linkedSsoProviderInfo$;

  public newPasswordField = '';
  public oldPasswordField = '';

  readonly districtFeatureEnabled$: Observable<boolean>;

  public readonly canUserDeleteAccount$ =
    this._settingService.getSettingValueObs(
      mingaSettingTypes.FEATURE_ALLOW_ACCOUNT_DELETION,
    );

  groupTrackBy: TrackByFunction<IGroupedUserPreference> = (
    index: number,
    group: IGroupedUserPreference,
  ) => group.category;
  preferenceTrackBy: TrackByFunction<IUserPreference> = (
    index: number,
    preference: IUserPreference,
  ) => preference.id;

  constructor(
    private _userPreferences: UserPreferencesService,
    private _rootService: RootService,
    private _authService: AuthService,
    private _authInfo: AuthInfoService,
    mingaStore: MingaStoreFacadeService,
    private _dialog: MatDialog,
    private _permissions: PermissionsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _cdr: ChangeDetectorRef,
    private _settingService: MingaSettingsService,
    public profileService: ProfileService,
    private _peopleManager: PeopleManagerService,
  ) {
    this.districtFeatureEnabled$ = mingaStore.observeDistrictFeatureEnabled();
    this.groupedUserPreferences$ = this._userPreferences.userPreferences$.pipe(
      map(preferences => {
        const groupPrefs: IGroupedUserPreference[] = [];
        const groupPrefMap = new Map<string, IUserPreference[]>();

        // group preferences by category
        for (const pref of preferences) {
          const currentCat = groupPrefMap.get(pref.category);
          // add new category group if doesn't yet exist
          if (!currentCat) {
            groupPrefMap.set(pref.category, [pref]);
          } else {
            // add pref to existing category
            currentCat.push(pref);
            groupPrefMap.set(pref.category, currentCat);
          }
        }
        // convert to array
        groupPrefMap.forEach((preferences, category) => {
          groupPrefs.push({ category, preferences });
        });

        return groupPrefs;
      }),
    );
  }

  async ngOnInit() {
    await this._userPreferences.fetchIfNeeded();
  }

  public async updatePassword() {
    try {
      await this._rootService.addLoadingPromise(
        this._updatePassword({
          newPassword: this.newPasswordField,
          oldPassword: this.oldPasswordField,
        }),
      );
      this._systemAlertSnackBar.success('Successfully changed password');
    } catch (err) {}
  }

  private async _updatePassword({
    newPassword,
    oldPassword,
  }: {
    newPassword: string;
    oldPassword: string;
  }) {
    try {
      const request = new profile_pb.UpdateProfileRequest();
      request.setNewPassword(newPassword);
      request.setOldPassword(oldPassword);
      request.setPersonHash(this._authInfo.authPersonHash);
      await this.profileService.updateProfile(request);
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to update password: ' + error);
    }
  }

  private async _updatePreference(
    preference: IUserPreference,
    value: string | boolean,
  ) {
    await this._userPreferences.updatePreference(preference.id, value);
  }

  preferenceValueChange(preference: IUserPreference, value: string | boolean) {
    this._updatePreference(preference, value);
  }

  async unlinkAccounts() {
    const wasUnlinked = await this._authService.unlinkAllAccounts();
    if (wasUnlinked) {
      this._dialog.open(SuccessDialog, {
        data: { text: `Successfully unlinked your accounts` },
      });
    } else {
      this._systemAlertSnackBar.error(
        'Oops! We had an issue unlinking your account. Please try again later.',
      );
    }
  }

  public launchArchiveAccountConfDialog() {
    const options = {
      data: {
        text: 'Are you sure you want to delete this account?',
        saveButtonLocale: 'button.delete',
      },
    };

    const _launch2ndConfirmation = () => {
      this._launch2ndAccountArchiveConfDialog();
    };

    this._launchConfirmationDialog(options, _launch2ndConfirmation);
  }

  // Make sure users are really sure they want to delete account
  private _launch2ndAccountArchiveConfDialog() {
    const options = {
      data: {
        text: 'Are you ABSOLUTELY sure you want to delete this account?',
        saveButtonLocale: 'button.delete',
      },
    };

    const _archiveAccount = () => {
      if (this._authInfo.authPersonHash) {
        this._peopleManager.selfArchivePerson(this._authInfo.authPersonHash);
      }
    };

    this._launchConfirmationDialog(options, _archiveAccount);
  }

  private _launchConfirmationDialog(options: any, callback: () => void) {
    const dialog = this._dialog.open(SaveCancelDialog, options);
    dialog.afterClosed().subscribe(async result => {
      if (result) {
        callback();
      }
    });
  }
}
