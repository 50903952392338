import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import * as gallery_pb from 'minga/proto/gateway/gallery_pb';
import { Group } from 'minga/app/src/app/groups/models/Group';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { ReportService } from 'minga/app/src/app/services/Report';
import { MingaGallery } from 'minga/proto/gateway/gallery_ng_grpc_pb';

import { IAddGalleryPhotoOptions, IDeleteGalleryPhotoOptions } from '../types';
import { GalleryServiceAbstract } from '../utils';

@Injectable({ providedIn: 'root' })
export class GroupGalleryService extends GalleryServiceAbstract {
  public _group$?: Observable<Group | null>;
  public _groupHashSubj = new BehaviorSubject<string>('');
  public _groupHash$: Observable<string>;

  constructor(
    public mingaGalleryProto: MingaGallery,
    public reportService: ReportService,
    private _groupsFacade: GroupsFacadeService,
  ) {
    super(mingaGalleryProto, reportService);
    this.setGroupFromHash('');
    this._groupHash$ = this._groupHashSubj.asObservable();
    this._group$ = this._groupHash$.pipe(
      switchMap(group => this._groupsFacade.getGroup(group)),
    );
  }

  async setGroupFromHash(hash: string) {
    this._groupHashSubj.next(hash);
  }

  public async _getGroup(): Promise<Group> {
    if (!this._group$) {
      throw new Error(`GroupGalleryService called without a group`);
    }
    const group = await this._group$.pipe(first()).toPromise();

    if (!group) {
      throw new Error(
        `GroupGalleryService could not find group from the given group hash`,
      );
    } else {
      return group;
    }
  }

  async fetchSummary() {
    if (this._group$) {
      this._group$
        .pipe(first(group => group != null && group.hash != null))
        .subscribe(group => {
          this._fetchSummary();
        });
    }
  }

  async _fetchSummary() {
    const group = await this._getGroup();

    const request = new gallery_pb.MingaGallerySummaryRequest();
    const wasLoading = this._loading.getValue();
    request.setGroupHash(group.hash);

    try {
      if (!wasLoading) this._loading.next(true);
      const response = await this.mingaGalleryProto.summary(request);
      this._summary.next(response.toObject());
    } finally {
      if (!wasLoading) this._loading.next(false);
    }
  }

  async getSearchPhotoTotal(search: string): Promise<number> {
    const group = await this._getGroup();

    const request = new gallery_pb.MingaGalleryGetPhotoTotalRequest();
    request.setSearchQuery(search);
    request.setGroupHash(group.hash);

    const response = await this.mingaGalleryProto.getPhotoTotal(request);

    return response.getPhotoTotal();
  }

  async addPhoto(options: IAddGalleryPhotoOptions) {
    return await this._addPhoto(this._summary, options);
  }

  async deletePhoto(options: IDeleteGalleryPhotoOptions): Promise<boolean> {
    return await this._deletePhoto(this._summary, options);
  }

  async resolvePhoto(galleryPhotoUuid: string): Promise<boolean> {
    return await this._resolvePhoto(galleryPhotoUuid);
  }
}
