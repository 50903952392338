import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MgIconModule } from 'src/app/icon';
import { MgSpinnerModule } from 'src/app/spinner';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { MembershipListTableModule } from '@shared/components/membership-list-table';
import { OverlayModule } from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text';
import { TooltipModule } from '@shared/components/tooltip';

import { PeopleUserListsEditComponent } from './people-userlists-edit.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    GenericModule,
    FormModule,
    MgIconModule,
    MembershipListTableModule,
    TextModule,
    MgSpinnerModule,
    TooltipModule,
    OverlayModule,
  ],
  declarations: [
    // Components
    PeopleUserListsEditComponent,
  ],
  exports: [PeopleUserListsEditComponent],
  providers: [],
})
export class PeopleUserListEditModule {}
