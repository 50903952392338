import { Pipe, PipeTransform } from '@angular/core';

import * as day from 'dayjs';

import { GenericDataButtonColor } from '@shared/components/generic';

import { FlexTimePeriodTile } from '../constants';
import { FlexInstanceTile } from '../services';

@Pipe({ name: 'getButtonStatusColor' })
export class GetButtonStatusColorPipe implements PipeTransform {
  transform(
    data: FlexTimePeriodTile | FlexInstanceTile,
  ): GenericDataButtonColor {
    const currentTime = day().format('HH:mm:ss');
    const startTime = data.startTime;

    return data.absentees > 0 && startTime < currentTime ? 'yellow' : 'blue';
  }
}
