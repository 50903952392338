import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { GenericButtonComponent } from './generic-button.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,

    // External dependencies
    CommonModule,
    MatRippleModule,
  ],
  exports: [GenericButtonComponent],
  declarations: [GenericButtonComponent],
})
export class GenericButtonModule {}
