<ng-container *ngIf="showCalendarSettings">
  <div class="mat-form-field-margin-wrapper">
    <mat-button-toggle-group
      [value]="calendarView"
      [ngClass]="['status-button-group']"
      (change)="onCalendarViewChanged($event.value)">
      <mat-button-toggle [value]="'month'">Month</mat-button-toggle>
      <mat-button-toggle [value]="'week'">Week</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-checkbox
    [(ngModel)]="includeWeekends"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="includeWeekendsChange.emit($event)">
    Include Weekends
  </mat-checkbox>
</ng-container>
<ng-container *ngIf="!showCalendarSettings">
  <div class="mat-form-field-margin-wrapper">
    <mat-button-toggle-group
      [value]="showPastEvents"
      [ngClass]="['status-button-group']"
      (change)="onPastEventsChanged($event.value)">
      <mat-button-toggle [value]="true">Past events</mat-button-toggle>
      <mat-button-toggle [value]="false">Upcoming events</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ng-container>
<ng-container *ngIf="!exclusiveGroupHashes?.length">
  <h1 class="calendar-settings-title">Show By Groups:</h1>
  <div class="mat-form-field-margin-wrapper">
    <mat-form-field
      mgFormField
      [mgHideOptionalMarker]="true"
      [mgNoHintMargin]="true"
      [mgResponsiveHeight]="true"
      [mgNoFloatTop]="true"
      appearance="legacy"
      floatLabel="never">
      <mat-select
        [ngModel]="groupHashes?.length > 0 ? groupHashes[0] : ''"
        (ngModelChange)="groupHashesChange.emit($event)"
        [ngModelOptions]="{ standalone: true }">
        <mat-option [value]="''">ALL</mat-option>
        <mat-option
          *ngFor="let group of groups$ | async"
          [value]="group.hash">
          {{ group.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
<ng-container>
  <h1 class="calendar-settings-title">Show By Category:</h1>
  <div class="mat-form-field-margin-wrapper">
    <mat-form-field
      mgFormField
      [mgHideOptionalMarker]="true"
      [mgNoHintMargin]="true"
      [mgResponsiveHeight]="true"
      [mgNoFloatTop]="true"
      appearance="legacy"
      floatLabel="never">
      <mat-select
        [ngModel]="selectedCategory ? selectedCategory : ''"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="eventCategoryChange.emit($event)">
        <mat-option [value]="''">ALL</mat-option>
        <mat-option
          *ngFor="let category of eventCategoryKeys"
          [value]="category">
          <span
            [style.background-color]="getEventCategoryColor(category)"
            class="event-category-circle"></span
          >{{ getEventCategoryPrettyString(category) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>

<button
  mgBubbleBtn
  class="apply-btn"
  (click)="submitClick()">
  Apply
</button>
