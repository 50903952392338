import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { MgIconModule } from 'minga/app/src/app/icon';

import { ListUploaderSummaryComponent } from '@shared/components/XlsListUploader/ListUploaderSummary/ListUploaderSummary.component';
import { GenericButtonModule } from '@shared/components/button';

@NgModule({
  imports: [
    // Minga dependencies
    GenericButtonModule,
    MgIconModule,

    // External dependencies
    CommonModule,
    MatDialogModule,
  ],
  declarations: [ListUploaderSummaryComponent],
  exports: [ListUploaderSummaryComponent],
})
export class ListUploaderSummaryModule {}
