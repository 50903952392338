import { PsDialogData } from '../types';

export enum PsDialogMessage {
  DIALOG_TITLE_SUCCESS = 'Success',
  DIALOG_TITLE_ERROR = 'Error',
  DIALOG_TITLE_WARNING = 'Warning',

  BUTTON_LABEL_CLOSE = 'Close',
  BUTTON_LABEL_CONTINUE = 'Check in anyway',
}

export const PS_DIALOG_DEFAULT_DATA: PsDialogData = {
  type: 'success',
  audioAlerts: false,
};

export const PS_DIALOG_AUTOCLOSE_TIMEOUT = 1500;
