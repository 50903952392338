<!-- Form step UI switcher -->
<ng-container [ngSwitch]="formStep$ | async">
  <!-- Setup -->
  <mg-kiosk-type-selector
    *ngSwitchCase="'setup'"
    title="Select available hall passes"
    emptyStateTitle="No hall pass types available"
    emptyStateSubTitle="Enable Kiosk ready passes from the hall pass types page"
    submitLabel="Enter kiosk mode"
    [showBackButton]="showBackButton$ | async"
    [isLoading]="isLoading$ | async"
    [allowAudioNotifications]="true"
    [types]="allTypes$ | async"
    (formSubmit)="setAvailableTypes($event)"
    (back)="goBack()">
    <div
      additionalToggles
      class="toggle-container">
      <mat-slide-toggle
        labelPosition="before"
        [attr.data-analytics]="'kiosk-hallpass-table-toggle'"
        [checked]="kioskHallPassTable.enableTable$ | async"
        (change)="kioskHallPassTable.toggleEnableTable()">
        {{ MSG.SHOW_TABLE_TOGGLE_LABEL }}
      </mat-slide-toggle>
    </div>
  </mg-kiosk-type-selector>

  <!-- Assigner -->
  <mg-kiosk-type-assigner
    *ngSwitchCase="'assigner'"
    cardTitle="Hall pass kiosk"
    typeIcon="mg-hallpass-menu"
    [types]="availableTypes$ | async"
    (response)="assign($event)">
    <ng-container *ngIf="kioskHallPassTable.enableTable$ | async">
      <div
        class="hall-table-loader"
        *ngIf="loadingHallPassTable$ | async; else hallPassTable">
        <mg-spinner> </mg-spinner>
      </div>

      <ng-template #hallPassTable>
        <mg-kiosk-hall-pass-table [hallPasses]="activeHallPasses$ | async">
        </mg-kiosk-hall-pass-table>
      </ng-template>
    </ng-container>
  </mg-kiosk-type-assigner>

  <!-- Type selector -->
  <mg-kiosk-type-selector
    *ngSwitchCase="'type-selector'"
    title="Select a hall pass"
    emptyStateTitle="No hall pass types available"
    emptyStateSubTitle="Contact your school administrator"
    submitLabel="Create hall pass"
    [maxSelection]="1"
    [subTitle]="'Hi, ' + (person$ | async)?.displayName"
    [allowAudioNotifications]="false"
    [types]="availableTypes$ | async"
    (formSubmit)="handleUserTypeSelection($event)"
    (back)="setFormStep('assigner')"
    [disableSubmit]="assignmentInProgress$ | async">
  </mg-kiosk-type-selector>

  <!-- Summary -->
  <mg-kiosk-summary
    *ngSwitchCase="'summary'"
    [cardIconName]="(summaryData$ | async)?.iconName"
    [cardIconColor]="(summaryData$ | async)?.iconColor"
    [title]="MSG.TITLE_PASS_CREATED"
    [cardTitle]="(summaryData$ | async)?.name"
    [autoDone]="30"
    (done)="reset()">
    <!-- Details -->
    <ng-container
      [ngTemplateOutlet]="summaryDetailsTemplate"
      [ngTemplateOutletContext]="{
        $implicit: (summaryData$ | async)
      }">
    </ng-container>
  </mg-kiosk-summary>

  <!-- Existing pass -->
  <mg-kiosk-summary
    *ngSwitchCase="'existing-pass'"
    [cardIconName]="(existingPass$ | async)?.iconName"
    [cardIconColor]="(existingPass$ | async)?.iconColor"
    [title]="MSG.TITLE_ACTIVE_PASS"
    [showSuccessIcon]="false"
    [cardTitle]="(existingPass$ | async)?.name"
    [autoDone]="30"
    [hasAdditionalButtons]="true"
    (done)="reset()">
    <!-- Details -->
    <ng-container
      [ngTemplateOutlet]="existingPassDetailsTemplate"
      [ngTemplateOutletContext]="{
        $implicit: (existingPass$ | async)
      }">
    </ng-container>
    <!-- Additional buttons -->
    <div additionalButtons>
      <mg-btn
        [responsive]="true"
        [size]="(media.isMobileView$ | async) ? 'large' : 'kiosk'"
        (pressed)="end()">
        {{ MSG.BUTTON_END_PASS }}
      </mg-btn>
    </div>
  </mg-kiosk-summary>

  <!-- Pass ended -->
  <mg-kiosk-summary
    *ngSwitchCase="'pass-ended'"
    [title]="MSG.TITLE_PASS_ENDED"
    [cardTitle]="(existingPass$ | async)?.name"
    [cardIconName]="(existingPass$ | async)?.iconName"
    [cardIconColor]="(existingPass$ | async)?.iconColor"
    (done)="reset()">
    <!-- Details -->
    <ng-container
      [ngTemplateOutlet]="endedPassDetailsTemplate"
      [ngTemplateOutletContext]="{
        $implicit: (existingPass$ | async)
      }">
    </ng-container>
  </mg-kiosk-summary>

  <!-- Default case -->
  <ng-container *ngSwitchDefault></ng-container>
</ng-container>

<!-- Existing pass details template -->
<ng-template
  #summaryDetailsTemplate
  let-data>
  <!-- Student name -->
  <li>
    <mg-text color="surface-alt">Student</mg-text>
    <div>{{ data?.studentName }}</div>
  </li>
  <!-- Status -->
  <ng-container *ngIf="data | kioskHallPassTimer as status$">
    <li>
      <mg-text color="surface-alt">Status</mg-text>
      <div>
        <mg-tag [color]="(status$ | async)?.tagColor">
          {{ (status$ | async)?.status }}
        </mg-tag>
      </div>
    </li>
    <li>
      <mg-text color="surface-alt">Timer</mg-text>
      <div>{{ (status$ | async)?.time }}</div>
    </li>
  </ng-container>
  <!-- Ending method -->
  <li>
    <mg-text color="surface-alt">Ending method</mg-text>
    <div>
      {{ data?.endingMethod }}
    </div>
  </li>
</ng-template>

<!-- Existing pass details template -->
<ng-template
  #existingPassDetailsTemplate
  let-data>
  <!-- Student name -->
  <li>
    <mg-text color="surface-alt">Student</mg-text>
    <div>{{ data?.studentName }}</div>
  </li>
  <!-- Status -->
  <ng-container *ngIf="data | kioskHallPassTimer as status$">
    <li>
      <mg-text color="surface-alt">Status</mg-text>
      <div>
        <mg-tag [color]="(status$ | async)?.tagColor">
          {{ (status$ | async)?.status }}
        </mg-tag>
      </div>
    </li>
    <li>
      <mg-text color="surface-alt">Timer</mg-text>
      <div>{{ (status$ | async)?.time }}</div>
    </li>
  </ng-container>
  <!-- Ending method -->
  <li>
    <mg-text color="surface-alt">Ending method</mg-text>
    <div>
      {{ data?.endingMethod }}
    </div>
  </li>
</ng-template>

<!--Ended pass details template -->
<ng-template
  #endedPassDetailsTemplate
  let-data>
  <li>
    <mg-text color="surface-alt">Student</mg-text>
    <div>{{ data?.studentName }}</div>
  </li>
  <li>
    <mg-text color="surface-alt">Status</mg-text>
    <div><mg-tag color="grey">Ended</mg-tag></div>
  </li>
</ng-template>
