import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MgButtonModule } from 'minga/app/src/app/button';
import { MgIconModule } from 'minga/app/src/app/icon';

import { MessageDialogComponent } from './MessageDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgButtonModule,

    // External dependencies
    CommonModule,
    TranslateModule,
    MatIconModule,
  ],
  declarations: [MessageDialogComponent],

  exports: [MessageDialogComponent],
})
export class MessageDialogModule {}
