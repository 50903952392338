import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EventManagementService {
  private _eventContextHash: string = '';

  setEventContextHash(hash: string) {
    this._eventContextHash = hash;
  }

  getEventContextHash() {
    return this._eventContextHash;
  }
}
