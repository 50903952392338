import { NgModule } from '@angular/core';
import { SmsMessageLongCardModule } from 'minga/app/src/app/content-views/components/SmsMessageLongCard';

import { ContentViewComponent } from './ContentView.component';

@NgModule({
  imports: [
    // Minga dependencies
    SmsMessageLongCardModule,

    // External dependencies
  ],
  declarations: [ContentViewComponent],
  exports: [ContentViewComponent],
})
export class ContentViewModule {}
