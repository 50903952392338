<mg-overlay-primary
  size="auto"
  [isLoading]="(formState$ | async) === 'loading'"
  [overlayTitle]="
    (isNew$ | async) ? MESSAGES.MODAL_TITLE_ADD : MESSAGES.MODAL_TITLE_EDIT
  ">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainTemplate>
  <main
    class="modal-form"
    #formWrap>
    <form
      id="presets-form"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <fieldset *ngIf="(formState$ | async) === 'invalid'">
          <mg-system-alert
            type="error"
            [message]="MESSAGES.ERROR_GENERIC"></mg-system-alert>
        </fieldset>
        <fieldset>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mg-form-text-input
              [label]="MESSAGES.LABEL_TITLE"
              [control]="form.get(FORM_FIELDS.NAME)">
            </mg-form-text-input>

            <mg-form-date-range
              [hidePresets]="true"
              [endPlaceholder]="
                form.get(FORM_FIELDS.END_DATE_IS_CURRENT_DAY).value
                  ? 'Today'
                  : 'End date'
              "
              [range]="
                $any(form.get(FORM_FIELDS.DATE_RANGE))
              "></mg-form-date-range>

            <mg-form-checkbox
              [disabled]="isStartDateInTheFuture()"
              [checked]="form.get(FORM_FIELDS.END_DATE_IS_CURRENT_DAY).value"
              (changed)="setTodayAsEndDate($event)">
              <div class="checkbox">
                <div fxLayout="row">
                  <mg-text
                    variant="body-md"
                    [color]="
                      !isStartDateInTheFuture() ? 'surface' : 'on-disabled-bg'
                    "
                    >{{ MESSAGES.LABEL_END_DATE_TITLE }}</mg-text
                  >
                  <mg-tooltip [label]="MESSAGES.LABEL_END_DATE_TOOLTIP">
                  </mg-tooltip>
                </div>
                <mg-text
                  variant="body-xs"
                  [color]="
                    !isStartDateInTheFuture() ? 'surface-alt' : 'on-disabled-bg'
                  "
                  >{{ MESSAGES.LABEL_END_DATE_DESC }}</mg-text
                >
              </div>
            </mg-form-checkbox>
          </div>
        </fieldset>
      </div>
    </form>
  </main>
</ng-template>

<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [variant]="(isNew$ | async) === true ? 'text' : 'destructive'"
        (pressed)="confirmDelete()">
        {{ deleteLabel }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="presets-form"
        variant="filled"
        [disabled]="!canSubmit">
        {{ submitLabel }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
