<label
  class="add-button-container"
  [attr.data-analytics]="id"
  [attr.data-test]="id"
  [class.show-overflow]="customBottomText"
  [class.upload-file-button-label]="
    displayStyle == FileInputDisplay.BUTTON ||
    displayStyle == FileInputDisplay.ICON ||
    displayStyle == FileInputDisplay.TEXT
  "
  [class.drop-area]="
    displayStyle == FileInputDisplay.DROP_AREA ||
    displayStyle == FileInputDisplay.CAMERA_IMAGE
  "
  (click)="inputClick($event)">
  <input
    #inputEl
    type="file"
    [multiple]="multiple"
    [class.file-input-hidden]="
      displayStyle == FileInputDisplay.BUTTON ||
      displayStyle == FileInputDisplay.ICON ||
      displayStyle == FileInputDisplay.COMPACT_IMAGE ||
      displayStyle == FileInputDisplay.TEXT
    "
    [accept]="accept" />
  <div
    #infoEl
    class="drop-info"
    *ngIf="
      displayStyle == FileInputDisplay.DROP_AREA ||
      displayStyle == FileInputDisplay.CAMERA_IMAGE
    ">
    <mg-icon
      class="icon-o"
      [icon-namespace]="iconNamespace"
      >{{ icon }}</mg-icon
    >
    <mg-icon
      class="compact-icon"
      [icon-namespace]="iconNamespace"
      >{{ compactIcon }}</mg-icon
    >
    <div
      class="text-container"
      [class.display-column]="customBottomText">
      <h2
        class="text"
        *ngIf="customTitleText">
        {{ customTitleText }}
      </h2>
      <h2
        class="text"
        *ngIf="!customTitleText">
        Upload <span *ngIf="hasDragDrop">or drag</span> {{ friendlyName }} here.
      </h2>
      <mg-text
        variant="body-sm"
        *ngIf="helperText">
        {{ helperText }}
      </mg-text>
      <mg-text variant="body-sm">{{ customBottomText }}</mg-text>
    </div>
    <p
      *ngIf="showFileDetails && fileDetailText"
      class="text file-details">
      {{ fileDetailText }}
    </p>
  </div>

  <mg-btn
    *ngIf="displayStyle == FileInputDisplay.BUTTON"
    variant="filled"
    [wide]="true"
    [responsive]="true"
    (pressed)="buttonClick()">
    {{ customTitleText || 'Add file' }}
  </mg-btn>

  <mg-text
    *ngIf="displayStyle == FileInputDisplay.TEXT"
    variant="body-md"
    textAlign="left">
    {{ customTitleText || 'Add file' }}
  </mg-text>

  <mg-icon *ngIf="displayStyle == FileInputDisplay.ICON">{{ icon }}</mg-icon>

  <div
    *ngIf="displayStyle == FileInputDisplay.COMPACT_IMAGE"
    class="upload-image"></div>

  <div
    *ngIf="displayStyle == FileInputDisplay.CAMERA_IMAGE"
    class="upload-image-camera"></div>
</label>
