import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ReportTypes } from 'minga/domain/reportTypes';
import { MingaPermission, mingaSettingTypes } from 'minga/util';
import { MingaAppRoutes } from 'src/app/types';

import { AccessRestrictionsGuard } from '@shared/guards/access-restrictions.guard';

import { BehaviorManagerComponent } from './behavior-manager.component';
import { BehaviorManagerModule } from './behavior-manager.module';
import { BmDashboardComponent } from './components/bm-dashboard/bm-dashboard.component';
import { BmDashboardModule } from './components/bm-dashboard/bm-dashboard.module';
import { BmReportsComponent } from './components/bm-reports/bm-reports.component';
import { BmReportsModule } from './components/bm-reports/bm-reports.module';
import { BmReportsAutomationCounterComponent } from './components/bm-reports/components/bm-reports-auto-counter/bm-reports-auto-counter.component';
import { BmReportsConsHistoryComponent } from './components/bm-reports/components/bm-reports-cons-history/bm-reports-cons-history.component';
import { BmReportsConsOverdueComponent } from './components/bm-reports/components/bm-reports-cons-overdue/bm-reports-cons-overdue.component';
import { BmReportsConsTypesComponent } from './components/bm-reports/components/bm-reports-cons-types/bm-reports-cons-types.component';
import { BmReportsHistoryComponent } from './components/bm-reports/components/bm-reports-history/bm-reports-history.component';
import { BmReportsStaffComponent } from './components/bm-reports/components/bm-reports-staff/bm-reports-staff.component';
import { BmReportsStudentComponent } from './components/bm-reports/components/bm-reports-student/bm-reports-student.component';
import { BmReportsSummaryComponent } from './components/bm-reports/components/bm-reports-summary/bm-reports-summary.component';
import { BmReportsTypesComponent } from './components/bm-reports/components/bm-reports-types/bm-reports-types.component';
import { BmSettingsComponent } from './components/bm-settings/bm-settings.component';
import { BmSettingsModule } from './components/bm-settings/bm-settings.module';
import { BmTypesModule } from './components/bm-types/bm-types.module';
import { BmTypesAutomationStepEditComponent } from './components/bm-types/components/bm-types-automation-step-edit/bm-types-automation-step-edit.component';
import { BmTypesAutomationsComponent } from './components/bm-types/components/bm-types-automations/bm-types-automations.component';
import { BmTypesBehaviorsComponent } from './components/bm-types/components/bm-types-behaviors/bm-types-behaviors.component';
import { BmTypesConsequencesComponent } from './components/bm-types/components/bm-types-consequences/bm-types-consequences.component';
import {
  BehaviorManagerMessages,
  BehaviorManagerRoutes,
  BehaviorsSubRoutes,
} from './constants';

export const BEHAVIOR_MANAGER_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: BehaviorManagerComponent,
    children: [
      {
        path: '',
        canActivate: [AccessRestrictionsGuard],
        component: BmDashboardComponent,
        data: {
          title: 'Dashboard',
          enableAutoRedirect: true,
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.PBIS_TYPE_MANAGE,
                MingaPermission.PBIS_VIEW_REPORTS,
              ],
            },
          },
        },
      },
      {
        path: BehaviorManagerRoutes.REPORTS,
        canActivate: [AccessRestrictionsGuard],
        component: BmReportsComponent,
        data: {
          title: 'Reports',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.PBIS_VIEW_REPORTS],
            },
          },
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: ReportTypes.PBIS_SUMMARY,
          },
          {
            path: ReportTypes.PBIS_SUMMARY,
            component: BmReportsSummaryComponent,
            data: { title: 'Summary' },
          },
          {
            path: ReportTypes.PBIS_TYPES,
            component: BmReportsTypesComponent,
            data: { title: 'Behavior Types' },
          },
          {
            path: ReportTypes.PBIS_STAFF,
            component: BmReportsStaffComponent,
            data: { title: 'Trackers' },
          },
          {
            path: ReportTypes.PBIS_STUDENT,
            component: BmReportsStudentComponent,
            data: { title: 'Receivers' },
          },
          {
            path: ReportTypes.PBIS_HISTORY,
            component: BmReportsHistoryComponent,
            data: { title: 'History' },
          },
          {
            path: ReportTypes.PBIS_CONS_HISTORY,
            component: BmReportsConsHistoryComponent,
            data: { title: 'Consequence History' },
          },
          {
            path: ReportTypes.PBIS_CONS_TYPES,
            component: BmReportsConsTypesComponent,
            data: { title: 'Consequence Types' },
          },
          {
            path: ReportTypes.PBIS_AUTOMATION_COUNT,
            component: BmReportsAutomationCounterComponent,
            data: { title: 'Automation Counter' },
          },
          {
            path: ReportTypes.PBIS_CONS_OVERDUE,
            component: BmReportsConsOverdueComponent,
            data: { title: 'Consequence Overdue' },
          },
        ],
      },
      {
        path: BehaviorManagerRoutes.BEHAVIORS,
        canActivate: [AccessRestrictionsGuard],
        component: BmTypesBehaviorsComponent,
        data: {
          title: `Behaviors`,
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.PBIS_TYPE_MANAGE],
            },
          },
        },
      },
      {
        path: BehaviorManagerRoutes.CONSEQUENCES,
        canActivate: [AccessRestrictionsGuard],
        component: BmTypesConsequencesComponent,
        data: {
          title: `Consequences`,
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.PBIS_TYPE_MANAGE],
            },
          },
        },
      },
      {
        path: BehaviorManagerRoutes.AUTOMATIONS,
        canActivate: [AccessRestrictionsGuard],
        component: BmTypesAutomationsComponent,
        data: {
          title: 'Automations',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.PBIS_TYPE_MANAGE],
            },
          },
        },
        children: [
          {
            path: `${BehaviorsSubRoutes.AUTOMATIONS_STEP_EDIT}/:groupId`,
            component: BmTypesAutomationStepEditComponent,
            data: {
              title: 'Add Automation Step',
            },
          },
          {
            path: `${BehaviorsSubRoutes.AUTOMATIONS_STEP_EDIT}/:groupId/:stepId`,
            component: BmTypesAutomationStepEditComponent,
            data: {
              title: 'Edit Automation Step',
            },
          },
        ],
      },
      {
        path: BehaviorManagerRoutes.SETTINGS,
        canActivate: [AccessRestrictionsGuard],
        component: BmSettingsComponent,
        data: {
          title: `${BehaviorManagerMessages.PAGE_TITLE_SETTINGS}`,
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_ACCOUNT_MANAGE],
            },
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    BehaviorManagerModule,
    BmDashboardModule,
    BmReportsModule,
    BmTypesModule,
    BmSettingsModule,
    RouterModule.forChild(BEHAVIOR_MANAGER_ROUTES),
  ],
})
export class BehaviorManagerRoutesModule {}
