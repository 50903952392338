<mg-basic-mini-card
  *ngIf="basicContent"
  [content]="basicContent"
  [context]="context">
</mg-basic-mini-card>

<mg-poll-mini-card
  *ngIf="pollContent"
  [content]="pollContent"
  [context]="context">
</mg-poll-mini-card>

<mg-event-mini-card
  *ngIf="eventContent"
  [markKeywords]="markKeywords"
  [showExactDate]="true"
  [content]="eventContent"
  [context]="context">
</mg-event-mini-card>

<mg-full-graphic-mini-card
  *ngIf="fullGraphicContent"
  [content]="fullGraphicContent"
  [context]="context">
</mg-full-graphic-mini-card>

<mg-announcement-mini-card
  *ngIf="announcementContent"
  [content]="announcementContent"
  [context]="context">
</mg-announcement-mini-card>

<mg-file-mini-card
  *ngIf="fileContent"
  [content]="fileContent">
</mg-file-mini-card>

<mg-challenge-mini-card
  *ngIf="challengeContent"
  [content]="challengeContent"
  [context]="context"></mg-challenge-mini-card>

<mg-sms-message-mini-card
  *ngIf="smsMessageContent as smsMessageContent"
  [message]="smsMessageContent.message"
  [context]="context">
</mg-sms-message-mini-card>
