import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgButtonModule } from 'minga/app/src/app/button';

import { MingaSnackIconComponent } from './MingaSnackIcon.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MingaSnackIconComponent],
  exports: [MingaSnackIconComponent],
})
export class MingaSnackIconModule {}
