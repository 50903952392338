import { MingaAppRoutes } from 'src/app/types';

import { MingaProfileComponent } from './minga-profile.component';

export const MINGA_PROFILE_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: MingaProfileComponent,
  },
];
