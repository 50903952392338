import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  // mg-expansion-panel is an extension of mat-expansion-panel. Do not add other
  // selectors
  // here without thinking.
  selector: 'mat-expansion-panel[mgExpansionPanel]',
})
export class ExpansionPanelDirective {
  @Input('mgExpansionPanelStyles')
  @HostBinding('class.mg-expansion-panel')
  enableExpansionPanelStyles: boolean = true;

  constructor() {}
}
