import { SelectFieldsOption } from '@shared/components/select-fields';

export enum HpmSettingsMessages {
  PAGE_TITLE = 'Hall Pass Settings',

  SECTION_TITLE_GENERAL = 'General Settings',
  SECTION_TITLE_PASS_LIMIT = 'Hall Pass Limit',
  SECTION_TITLE_REQUEST_TIMEOUT = 'Hall Pass Request Timeout Duration',
  SECTION_TITLE_TEACHER_STAFF = 'Teacher / Staff Settings',
  SECTION_TITLE_STUDENT = 'Student Settings',

  SETTING_TEACHER_VIEW_REPORTS = 'Allow Teachers / Staff to view reports for their hall passes',
  SETTING_MUST_MANUALLY_END = 'Default hall pass ending method',
  SETTING_TIMEOUT = 'Overdue hall pass timeout (mins)',
  SETTING_TEACHERS_GRANT = 'Allow Teachers to assign hall passes',
  SETTING_STAFF_GRANT = 'Allow Staff to assign hall passes',
  SETTING_ALLOW_NOTE = 'Allow notes on hall passes',
  SETTING_ENABLE_KIOSK = 'Enable hall pass kiosk',
  SETTING_MAX_PASSES = 'Maximum hall passes per student per day',
  SETTING_MAX_ACTIVE_PASSES = 'Maximum number of active hall passes',
  SETTING_REQUEST_DURATION_STUDENT = 'Student created hall pass (mins)',
  SETTING_REQUEST_DURATION_STAFF = 'Staff created hall pass (mins)',
  SETTING_STUDENTS_GRANT = 'Allow students to create hall passes',
  SETTING_ASSIGN_STAFF = 'Must assign a teacher to hall passes',
  SETTING_STUDENTS_END_PASSES = 'Allow students to manually end their hall passes',
  SETTING_STUDENTS_END_PASSES_FROM_MOBILE = 'Allow students to end hall passes on mobile devices',
  SETTING_MAX_STUDENT_PASSES = 'Maximum student created hall passes per day',
  SETTING_MAX_ACTIVE_STUDENT_PASSES = 'Maximum number of active student created hall passes',
  SETTING_DEFAULT_STUDENT_PASS_DURATION = 'Default student created hall pass duration (mins)',
  SETTING_MIN_WAIT_BETWEEN_PASSES = 'Wait time between student created hall passes (mins)',
  SETTING_TEACHERS_BYPASS_BLACKOUT = 'Allow Teachers / Staff to bypass blackout schedules',
  SETTING_SHOW_RECENTLY_ENDED = 'Show recently ended hall passes',
  SETTING_RECENTLY_ENDED_FILTER = 'Display duration (mins)',
  SETTING_ENABLE_HALLPASS = 'Enable Hall Passes',

  SETTING_ENABLE_HALLPASS_TITLE_ENABLING = 'Are you sure you want to enable hall passes?',
  SETTING_ENABLE_HALLPASS_TITLE_DISABLING = 'Are you sure you want to disable hall passes?',
  SETTING_ENABLE_HALLPASS_BODY_ENABLING = 'This will allow users to request new hall passes',
  SETTING_ENABLE_HALLPASS_BODY_DISABLING = 'This will prevent anyone from requesting new hall passes',

  HELP_TIMEOUT = 'Time, in minutes, after which the hall pass will automatically expire if not ended manually',
  HELP_SHOW_RECENTLY_ENDED = 'Recently ended hall pass will be displayed alongside active passes for the selected duration',
  HELP_MAX_ACTIVE_PASSES = 'Total number of active passes at any one time',
}

// Select Field Setting Options:
export const EndHallPassOptions: SelectFieldsOption<boolean>[] = [
  { value: false, label: 'Automatic' },
  { value: true, label: 'Manual' },
];
