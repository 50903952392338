import { InjectionToken } from '@angular/core';

import {
  ModalOverlayBeforeClosedSuccess,
  ModalOverlayServiceConfig,
} from './modal-overlay.types';

export enum ModalOverlayPrimaryHeaderBackground {
  BLUE = 'blue',
  GREEN = 'green',
  NAVY = 'navy',
  ORANGE = 'orange',
  PINK = 'pink',
  TEAL = 'teal',
  PEOPLE_BLUE = 'people-blue',
  PURPLE = 'purple',
  ALT_TEAL = 'alt-teal', // Flex Module Primary

  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum ModalOverlayServiceCloseEventType {
  BACKDROP_CLICK = 'backdropClick',
  CLOSE = 'close',
  ESCAPE = 'escape',
  SUBMIT = 'submit',
  CREATE = 'create',
  DELETE = 'delete',
  NAVIGATE = 'navigate',
}

export enum BackDropColor {
  BLACK = 'primary-overlay-backdrop-black',
  DARK_BLUE = 'primary-overlay-backdrop-dark-blue',
  ORANGE = 'primary-overlay-backdrop-orange',
  LIME = 'primary-overlay-backdrop-lime',
}

export type ModalOverlayBeforeClosedSuccessOption =
  | 'hallPassCreated'
  | 'blackOutScheduleCreated'
  | 'blackOutScheduleUpdated';

export const MODAL_OVERLAY_BEFORE_CLOSED_SUCCESS: Record<
  ModalOverlayBeforeClosedSuccessOption,
  ModalOverlayBeforeClosedSuccess
> = {
  hallPassCreated: {
    iconName: 'hallpass',
    iconColor: '#db6578',
    message: 'Hall Pass Created',
    showConfetti: true,
    hangTime: 1500,
  },
  blackOutScheduleCreated: {
    iconName: 'checkmark',
    iconColor: '#2C8DC3',
    message: "You've created a new schedule!",
    showConfetti: true,
    hangTime: 1500,
  },
  blackOutScheduleUpdated: {
    iconName: 'checkmark',
    iconColor: '#2C8DC3',
    message: 'Schedule Saved',
    showConfetti: false,
    hangTime: 1500,
  },
};

export const DEFAULT_CONFIG: ModalOverlayServiceConfig<any> = {
  hasBackdrop: true,
  backdrop: BackDropColor.BLACK,
  disposeOnNavigation: true,
  minHeight: 'auto',
};

export const MODAL_OVERLAY_DATA = new InjectionToken<any>('MODAL_OVERLAY_DATA');
