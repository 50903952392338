import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { EventManagementService } from 'minga/app/src/app/events/services/EventManagement/EventManagement.service';

@Injectable({ providedIn: 'root' })
export class EventManagementResolver implements Resolve<any> {
  constructor(private eventManagementService: EventManagementService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hash = route.paramMap.get('hash');
    if (hash) {
      this.eventManagementService.setEventContextHash(hash);
    }
  }
}
