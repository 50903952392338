import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, ReplaySubject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MingaColors } from '@shared/constants/mingaColors';

import {
  MODAL_TYPE_DEFAULTS,
  SystemAlertModalIconType,
  SystemAlertModalType,
} from './constants';
import {
  SystemAlertCloseEvents,
  SystemAlertModalData,
  SystemAlertModalIconConfig,
  SystemAlertModalResponse,
} from './types';

@Component({
  selector: 'mg-system-alert-modal',
  templateUrl: './system-alert-modal.component.html',
  styleUrls: ['./system-alert-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemAlertModalComponent implements OnDestroy {
  /** Enums & Constants */
  public readonly ICON_TYPE = SystemAlertModalIconType;
  public readonly MODAL_TYPE = SystemAlertModalType;

  /** Behavior Subjects & Observables */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);
  private _dataSubject = new BehaviorSubject<SystemAlertModalData>(undefined);
  public data$ = this._dataSubject.asObservable();
  private _iconDataSubject = new BehaviorSubject<SystemAlertModalIconConfig>(
    undefined,
  );
  public iconData$ = this._iconDataSubject.asObservable();

  public buttonColor = MingaColors.NAVY;

  private _remainingTimeSubject = new BehaviorSubject<number>(null);
  public remainingTime$ = this._remainingTimeSubject.asObservable();
  public secondCounter: number;

  public inputControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: SystemAlertModalData,
    private _dialogRef: MatDialogRef<
      SystemAlertModalComponent,
      SystemAlertModalResponse
    >,
  ) {
    this._setupNotification();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public onConfirmClick() {
    if (this.inputControl.valid) {
      this._dialogRef.close({
        inputValue: this.inputControl.value,
        type: SystemAlertCloseEvents.CONFIRM,
      });
    }
  }

  public onSecondaryConfirmClick() {
    this._dialogRef.close({
      inputValue: this.inputControl.value,
      type: SystemAlertCloseEvents.SECONDARY_CONFIRM,
    });
  }

  public onCloseClick() {
    this._dialogRef.close({
      inputValue: this.inputControl.value,
      type: SystemAlertCloseEvents.CLOSE,
    });
  }

  public onCloseIconClick() {
    this._dialogRef.close({
      type: SystemAlertCloseEvents.CLOSE_ICON,
    });
  }

  private _setupNotification() {
    const defaults =
      MODAL_TYPE_DEFAULTS[
        this.dialogData?.modalType || SystemAlertModalType.DEFAULT
      ];
    const mergedData = {
      ...defaults,
      ...(this.dialogData || {}),
    };

    this._dataSubject.next(mergedData);
    const data = this._dataSubject.value;

    if ('icon' in data) {
      this._iconDataSubject.next({
        iconType: SystemAlertModalIconType.ICON,
        iconSource: data.icon,
        iconColor: data.iconColor || 'primary',
      });
    } else if ('asset' in data) {
      this._iconDataSubject.next({
        iconType: SystemAlertModalIconType.ASSET,
        iconSource: data.asset,
      });
    }

    if ('input' in data) {
      this.inputControl.setValidators(data.input?.validators || []);
    }

    // disabled auto close if confirmActionBtn has been set
    if (data?.hangTime && !data?.confirmActionBtn) {
      setTimeout(
        () =>
          this._dialogRef.close({
            type: SystemAlertCloseEvents.AUTO_DISMISS,
          }),
        +data?.hangTime,
      );
    }

    if (data?.timer) {
      this._setupTimer(data?.timer);
    }
  }

  private _setupTimer(time) {
    this.secondCounter = time;
    interval(1000)
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        if (this.secondCounter === 0) {
          this.dialogData?.onTimerEnd?.();
        }
        this.secondCounter--;
        this._remainingTimeSubject.next(this.secondCounter);
      });
  }
}
