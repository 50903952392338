import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { FormModule } from '@shared/components/form';
import { OverlayModule } from '@shared/components/modal-overlay';
import { PaginatorModule } from '@shared/components/paginator';
import { CommonUIModules } from '@shared/modules';

import { MmDistrictsEditComponent } from './components/mm-districts-edit/mm-districts-edit.component';
import { MmDistrictsTableComponent } from './components/mm-districts-table/mm-districts-table.component';
import { MmDistrictsComponent } from './mm-districts.component';

@NgModule({
  declarations: [
    MmDistrictsComponent,
    MmDistrictsEditComponent,
    MmDistrictsTableComponent,
  ],
  exports: [
    MmDistrictsComponent,
    MmDistrictsEditComponent,
    MmDistrictsTableComponent,
  ],
  imports: [
    CommonModule,
    CommonUIModules,
    FormModule,
    MatTableModule,
    MatSortModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    PaginatorModule,
  ],
})
export class MmDistrictsModule {}
