import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { NotificationEffects, NotificationEffectsModule } from './effects';

@NgModule({
  imports: [
    NotificationEffectsModule,
    EffectsModule.forFeature([NotificationEffects]),
  ],
  declarations: [],

  providers: [],
})
export class NotificationStoreModule {}
