<!-- <canvas
  id="hall-pass-report-chart"
  baseChart
  [datasets]="chartDataSets"
  [labels]="chartLabels"
  [options]="HPM_CHART.options"
  [plugins]="HPM_CHART.plugins"
  [legend]="HPM_CHART.legend"
  [chartType]="HPM_CHART.type">
</canvas> -->

<div class="chart-container">
  <canvas
    *ngIf="chartOptions$ | async"
    baseChart
    [options]="chartOptions$ | async"
    [legend]="legend"
    [plugins]="plugins"
    [labels]="chartLabels$ | async"
    [datasets]="data"
    [chartType]="'bar'">
  </canvas>
</div>
