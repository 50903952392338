import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EditableCheckin } from 'minga/domain/checkin';
import {
  CheckInReportColumns,
  ColumnInfo,
  TemplateColumnKeys,
} from 'minga/shared/reports_columns';
import { MingaPermission } from 'minga/util';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { PermissionsService } from 'src/app/permissions';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { CheckinManagerReportsService } from '../checkin-manager-reports.service';
import { CheckinManagerHistoryEditComponent } from '../components/checkin-manager-report-history/checkin-manager-history-edit.component';

@Injectable()
export class CheckinManagerHistoryDatasourceService extends ReportDatasourceService<EditableCheckin> {
  protected _editForm = CheckinManagerHistoryEditComponent;

  constructor(
    private _checkinManagerReportsService: CheckinManagerReportsService,
    _router: Router,
    private _permissions: PermissionsService,
    _alertModal: SystemAlertModalService,
    _snackBar: SystemAlertSnackBarService,
    _modalOverlay: ModalOverlayService,
  ) {
    super(
      _router,
      _checkinManagerReportsService,
      CheckinManagerHistoryDatasourceService.name,
      _alertModal,
      _snackBar,
      _modalOverlay,
    );
    this._archiveFn = this._checkinManagerReportsService.archiveCheckin.bind(
      this._checkinManagerReportsService,
    );
    let displayCol: ColumnInfo[] = [];
    if (this._permissions.hasPermission(MingaPermission.CHECKIN_TYPE_MANAGE))
      displayCol = [
        { key: TemplateColumnKeys.EDIT, header: '', columnClasses: ['w-16'] },
        {
          header: '',
          key: TemplateColumnKeys.ARCHIVE,
          columnClasses: ['w-16'],
        },
      ];
    super.setDisplayColumns(CheckInReportColumns.REASON_HISTORY, displayCol);
  }

  async fetch(offset: number, limit: number) {
    return await this._checkinManagerReportsService.getCheckinReport(
      offset,
      limit,
      this._currentSort,
    );
  }
}
