import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { StreamManager } from 'minga/app/src/app/minimal/services/StreamManager';
import { IMgStreamControl } from 'minga/app/src/app/util/stream';

import {
  IMgStreamScrollerInstance,
  MG_STREAM_SCROLLER_INSTANCE,
} from './MgStreamScrollerInstance';

@Directive({
  selector:
    'mg-stream-scroller[mgStreamControl], mg-table-stream-scroller[mgStreamControl]',
})
export class StreamControlDirective implements OnChanges, OnDestroy {
  @Input('mgStreamControl')
  streamControlName: string = '';

  constructor(
    private element: ElementRef,
    private streamManager: StreamManager,
    @Inject(MG_STREAM_SCROLLER_INSTANCE)
    private streamComponent: IMgStreamScrollerInstance,
  ) {}

  get stream(): IMgStreamControl<any> | null {
    return this.streamComponent.stream;
  }

  ngOnDestroy() {
    if (this.streamControlName) {
      this.streamManager.unregister(this.streamControlName);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let nameChange = changes.streamControlName;
    if (nameChange) {
      if (typeof nameChange.currentValue !== 'string') {
        console.warn(
          'Attempted to create streamControl directive with non string value:',
          nameChange.currentValue,
        );
        return;
      }

      if (this.streamManager.isRegistered(nameChange.currentValue)) {
        if (!nameChange.isFirstChange()) {
          console.error(
            `Stream with name '${nameChange.currentValue}' is already registered`,
          );
        }
      } else {
        const streamControlRef: any = {};
        Object.defineProperty(streamControlRef, 'streamControl', {
          get: () => this.streamComponent.stream,
        });

        this.streamManager.register(nameChange.currentValue, streamControlRef);

        if (!nameChange.isFirstChange()) {
          this.streamManager.unregister(nameChange.previousValue);
        }
      }
    }
  }
}
