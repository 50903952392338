import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { dateObjectToDateMessageUTC } from 'minga/app/src/app/util/date';
import { DateDelta, StringDelta } from 'minga/proto/common/delta_pb';
import { PeopleAdminManager } from 'minga/proto/gateway/people_ng_grpc_pb';
import {
  ArchivePeopleBulkRequest,
  BulkPersonFields,
  SyncPeopleBulkRequest,
  SyncPeopleBulkResponse,
} from 'minga/proto/gateway/people_pb';

import {
  BirthdateListUploadField,
  DisplayNameListUploadField,
  EmailListUploadField,
  FirstNameListUploadField,
  GradeListUploadField,
  GroupListUploadField,
  IdField1UploadField,
  IdField2UploadField,
  LastNameListUploadField,
  ListUploaderRow,
  NameListUploadField,
  ParentEmailUploadField,
  ParentPhoneUploadField,
  PasswordUploadField,
  PhoneNumberListUploadField,
  RoleListUploadField,
  StudentNumberListUploadField,
} from '@shared/components/XlsListUploader';

import { UpdatePeopleListState } from '../update-people-list-state';

export type UploadPeopleObservable = {
  cancel(): void;
  close(): void;
} & Observable<SyncPeopleBulkResponse>;

@Injectable({ providedIn: 'root' })
export class UpdatePeopleByListService {
  constructor(private _peopleAdminManager: PeopleAdminManager) {}

  uploadList(
    state: UpdatePeopleListState,
    rows: ListUploaderRow[],
  ): UploadPeopleObservable {
    const request = new SyncPeopleBulkRequest();
    request.setSendPinEmails(state.sendPinEmails);
    request.setArchivePeople(state.archiveUsers);
    request.setUseRole(state.defaultRole);
    request.setSyncRoster(state.updateOrSkip === 'update');
    request.setUseStudentId(state.useStudentIdAsId);

    const emailField = new EmailListUploadField();
    const studentIdField = new StudentNumberListUploadField();
    const roleField = new RoleListUploadField();
    const gradeField = new GradeListUploadField();
    const nameField = new NameListUploadField();
    const firstNameField = new FirstNameListUploadField();
    const lastNameField = new LastNameListUploadField();
    const phoneField = new PhoneNumberListUploadField();
    const groupField = new GroupListUploadField();
    const birthdayField = new BirthdateListUploadField();
    const displayNameField = new DisplayNameListUploadField();
    const passwordField = new PasswordUploadField();
    const idField1Field = new IdField1UploadField();
    const idField2Field = new IdField2UploadField();
    const parentEmailField = new ParentEmailUploadField();
    const parentPhoneField = new ParentPhoneUploadField();

    for (const row of rows) {
      const msg = new BulkPersonFields();
      const email = emailField.getValue(row);
      if (email) {
        msg.setEmail(email);
      }

      const studentId = studentIdField.getValue(row);
      if (studentId) {
        const delta = new StringDelta();
        delta.setNewString(studentId);
        msg.setStudentId(delta);
      }

      const role = roleField.getValue(row);
      if (role) {
        const delta = new StringDelta();
        delta.setNewString(role);
        msg.setRoleType(delta);
      }

      const grade = gradeField.getValue(row);
      if (grade) {
        // handle leading 0's
        let formatted = grade;
        if (grade !== 'K' && !Number.isNaN(+grade)) {
          formatted = +grade + '';
        }
        const delta = new StringDelta();
        delta.setNewString(formatted);
        msg.setGrade(delta);
      }

      const firstName = firstNameField.getValue(row);
      if (firstName) {
        const delta = new StringDelta();
        delta.setNewString(firstName);
        msg.setFirstName(delta);
      }

      const lastName = lastNameField.getValue(row);
      if (lastName) {
        const delta = new StringDelta();
        delta.setNewString(lastName);
        msg.setLastName(delta);
      }

      const name = nameField.getValue(row);
      if (name && !firstName && !lastName && name.includes(' ')) {
        // no separate first name and last name fields, so we better try and
        // split this into first and last.
        const splitName = name.split(' ');
        const firstName = new StringDelta();
        firstName.setNewString(splitName[0]);
        const lastName = new StringDelta();
        lastName.setNewString(splitName[splitName.length - 1]);
        msg.setFirstName(firstName);
        msg.setLastName(lastName);
      }
      const displayName = displayNameField.getValue(row);

      if (displayName) {
        const delta = new StringDelta();
        delta.setNewString(displayName);
        msg.setDisplayName(delta);
      }

      const phone = phoneField.getValue(row);
      if (phone) {
        const delta = new StringDelta();
        delta.setNewString(phone);
        msg.setPhoneNumber(delta);
      }

      const birthday = birthdayField.getValue(row);
      if (birthday) {
        const birthdate = dateObjectToDateMessageUTC(new Date(birthday));
        const delta = new DateDelta();
        delta.setNewDate(birthdate);
        msg.setBirthdate(delta);
      }

      const groups = groupField.getValues(row);
      if (groups.length > 0) {
        msg.setGroupList(groups);
      }

      const password = passwordField.getValue(row);
      if (password) {
        const delta = new StringDelta();
        delta.setNewString(password);
        msg.setPassword(delta);
      }

      const idField1 = idField1Field.getValue(row);
      if (idField1) {
        const delta = new StringDelta();
        delta.setNewString(idField1);
        msg.setIdField1(delta);
      }

      const idField2 = idField2Field.getValue(row);
      if (idField2) {
        const delta = new StringDelta();
        delta.setNewString(idField2);
        msg.setIdField2(delta);
      }

      const parentEmail = parentEmailField.getValue(row);
      if (parentEmail) {
        const emailArray = parentEmail.split(',').map(item => item.trim());
        for (const mail of emailArray) {
          const delta = new StringDelta();
          delta.setNewString(mail);
          msg.addParentEmail(delta);
        }
      }
      const parentPhone = parentPhoneField.getValue(row);
      if (parentPhone) {
        const phoneArray = parentPhone.split(',').map(item => item.trim());
        for (const phoneNumber of phoneArray) {
          const delta = new StringDelta();
          delta.setNewString(phoneNumber);
          msg.addParentPhone(delta);
        }
      }

      request.addPersonFields(msg);
    }
    const createObs = this._peopleAdminManager.syncPeopleBulk(request);
    return createObs;
  }

  archivePeople(emails: string[]) {
    const request = new ArchivePeopleBulkRequest();
    request.setEmailList(emails);
    return this._peopleAdminManager.archivePeopleBulk(request);
  }
}
