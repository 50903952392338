import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgSmsMessageShortCardModule } from 'minga/app/src/app/elements/MgShortCard/MgSmsMessageShortCard';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';

import { SmsMessageLongCardComponent } from './SmsMessageLongCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSmsMessageShortCardModule,
    MgOverlayToolsModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [SmsMessageLongCardComponent],
  exports: [SmsMessageLongCardComponent],
})
export class SmsMessageLongCardModule {}
