import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { DatePresetsModule } from '@shared/components/date-presets/date-presets.module';
import { OverlayModule } from '@shared/components/modal-overlay';
import { CommonFormModules, CommonUIModules } from '@shared/modules';

import { MmDatePresetsComponent } from './mm-date-presets.component';

@NgModule({
  imports: [
    CommonModule,
    CommonUIModules,
    CommonFormModules,
    DatePresetsModule,
    OverlayModule,
    MatTableModule,
  ],
  declarations: [MmDatePresetsComponent],
  exports: [MmDatePresetsComponent],
})
export class MmDatePresetsModule {}
