import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';

import { AsyncSubject, Subject } from 'rxjs';
import { concatMapTo, takeUntil } from 'rxjs/operators';

import {
  IMingaFeatureToggle,
  IMingaFeatureToggleKeys,
} from 'minga/domain/featureToggle';

import { MingaSettingsService } from '../store/Minga/services';

@Directive({
  selector: '[mgModuleEnabled]',
})
export class MgModuleEnabledDirective implements OnDestroy, OnInit {
  @Input() set mgModuleEnabled(condition: IMingaFeatureToggleKeys) {
    if (!condition) {
      return;
    }
    this._unsubscribe$.next(true);
    this._viewContainer.clear();

    this._init
      .pipe(
        concatMapTo(this._mingaSettingService.getModuleEnabledObs(condition)),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((value: boolean) => {
        this._viewContainer.clear();
        if (value) {
          this._viewContainer.createEmbeddedView(this._templateRef);
        }
        this._cdr.markForCheck();
      });
  }

  private _unsubscribe$ = new Subject<boolean>();
  private _init = new AsyncSubject<boolean>();

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _mingaSettingService: MingaSettingsService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this._init.next(true);
    this._init.complete();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }
}
