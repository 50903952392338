<mg-legacy-overlay-nav [component]="this"></mg-legacy-overlay-nav>

<mg-announcement-short-card
  *ngIf="content"
  [ngClass.xs]="'overlay-view'"
  [context]="contextHash"
  [forceOnlyFullBody]="true"
  [content]="content"
  [body]="content.body | ugcLinksContent">
</mg-announcement-short-card>
