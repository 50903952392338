import {
  IAuthPerson,
  IAuthResponse,
  IAuthTokenMingaPayload,
  ISsoAuthError,
  ISsoProviderInfo,
} from 'minga/domain/auth';

export const authStoreKeyname = 'auth';

export interface AuthStoreState {
  authenticated: boolean;
  authTokenPayload: IAuthTokenMingaPayload | null;
  authResponse: IAuthResponse | null;

  authPerson: IAuthPerson | null;

  /**
   * SSO provider info that was _just_ successfully logged into. Not kept
   * between sessions.
   */
  detachedSsoProviderInfo: ISsoProviderInfo | null;

  /**
   * SSO provider info that was _just_ linked. Not kept between sessions.
   */
  linkedSsoProviderInfo: ISsoProviderInfo | null;

  /**
   * Error resulting from an sso auh error. Not kept between sessions.
   */
  ssoError: ISsoAuthError | null;

  customSsoError: { message: string } | null;

  preAuthUrl?: string;
}
