import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

import { CssSpacing } from '@shared/utils';

@Component({
  selector: 'mg-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericCardComponent {
  // Host element

  @HostBinding('class') get paddingClasses() {
    return `mg-py-${this.paddingY} mg-px-${this.paddingX}`;
  }

  // Inputs

  @Input() paddingX: CssSpacing = '3';
  @Input() paddingY: CssSpacing = '3';

  /** Component Constructor */
  constructor() {}
}
