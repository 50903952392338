import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { ArrayPipesModule, StringPipeModule } from '@shared/pipes';

import { WeekdayToggleComponent } from './weekday-toggle.component';

@NgModule({
  declarations: [WeekdayToggleComponent],
  imports: [
    // Minga dependencies
    ArrayPipesModule,
    StringPipeModule,

    // External dependencies
    CommonModule,
    MatRippleModule,
  ],
  exports: [WeekdayToggleComponent],
})
export class WeekdayToggleModule {}
