import * as localforage from 'localforage';

export async function setAnalyticsAndACDisabled(disabled: boolean) {
  await localforage
    .setItem<boolean>('minga.analyticsDisabled', disabled)
    .catch(e => console.log('Problem saving to local storage'));
}

export async function getAnalyticsAndACDisabled() {
  return localforage
    .getItem<boolean>('minga.analyticsDisabled')
    .catch(e => false);
}

export async function isActiveCampaignEnabled(): Promise<boolean> {
  const disabled = await getAnalyticsAndACDisabled();
  return !disabled;
}

export async function isAnalyticsEnabled(): Promise<boolean> {
  return isActiveCampaignEnabled();
}
