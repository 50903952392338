import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
import { HammerModule } from '@angular/platform-browser';

import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { CountTileModule } from '@shared/components/count-tile';
import { ArrayPipesModule } from '@shared/pipes';

import { CountTileCarouselComponent } from './count-tile-carousel.component';

@NgModule({
  imports: [
    ArrayPipesModule,
    MgIconModule,
    MgSpinnerModule,
    CountTileModule,
    CommonModule,
    FlexLayoutModule,
    HammerModule,
    MatTabsModule,
  ],
  declarations: [CountTileCarouselComponent],
  exports: [CountTileCarouselComponent],
})
export class CountTileCarouselModule {}
