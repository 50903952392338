import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ImageInfo } from 'minga/proto/image/image_pb';

@Component({
  selector: 'mg-ftm-activity-img',
  templateUrl: './ftm-activity-img.component.html',
  styleUrls: ['./ftm-activity-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmActivityImgComponent {
  public resolvedImage: ImageInfo | string;
  @Input() set bannerImage(image: ImageInfo) {
    if (image) {
      this.resolvedImage = image;
    }
  }
  @Input() set imagePath(imgPath: string) {
    if (imgPath) {
      this.resolvedImage = imgPath;
    }
  }
  @Input() aspectRatio = 1;
}
