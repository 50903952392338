import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MgButtonModule } from 'minga/app/src/app/button';

import { DestroyMingaDialogComponent } from './DestroyMingaDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,

    // External dependencies
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
  ],
  declarations: [DestroyMingaDialogComponent],

  exports: [DestroyMingaDialogComponent],
})
export class DestroyMingaDialogModule {}
