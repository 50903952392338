import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { mingaSettingTypes } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { Color, Variant } from '@shared/components/text';

@Component({
  selector: 'mg-setting-toggle',
  templateUrl: './setting-toggle.component.html',
  styleUrls: ['./setting-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingToggleComponent implements OnInit, OnDestroy {
  private _destroyed$ = new ReplaySubject<void>(1);

  @Input() label: string;
  @Input() labelColor: Color = 'surface';
  @Input() variant: Variant = 'body-md';
  @Input() setting: mingaSettingTypes;
  @Input() disabled: boolean;
  @Input() helpTooltip: string;
  @Input() showWarning: 'onEnable' | 'onDisable' | boolean = false;
  @Input() warningTitle: string;
  @Input() warningBody: string;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  @Output()
  valueChange: EventEmitter<boolean> = new EventEmitter();

  public value = false;

  constructor(
    private _mingaSettingsService: MingaSettingsService,
    private _cdr: ChangeDetectorRef,
    private _systemAlertModal: SystemAlertModalService,
  ) {}

  ngOnInit(): void {
    this._mingaSettingsService
      .getSettingValueObs(this.setting)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => {
        if (value !== undefined) {
          this.value = value;
          this._cdr.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public async onValueChange(event: MatSlideToggleChange) {
    if (this._showWarning()) {
      await this.launchWarning(event);
    } else {
      this._mingaSettingsService.updateSetting(this.setting, this.value);
      this.valueChange.emit(this.value);
    }
  }

  public async launchWarning(event: MatSlideToggleChange) {
    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.WARNING,
      heading: this.warningTitle,
      message: this.warningBody,
      confirmActionBtn: this.value ? 'Enable' : 'Disable',
      closeBtn: 'Cancel',
    });

    const response = await modalRef.afterClosed().toPromise();
    if (response?.type === SystemAlertCloseEvents.CONFIRM) {
      this._mingaSettingsService.updateSetting(this.setting, this.value);
      this.valueChange.emit(this.value);
    } else {
      this.value = !event;
    }

    this._cdr.markForCheck();
  }

  private _showWarning(): boolean {
    return (
      this.showWarning === true ||
      (this.showWarning === 'onEnable' && this.value) ||
      (this.showWarning === 'onDisable' && !this.value)
    );
  }
}
