<p
  *ngIf="layout.useLegacyLayout$ | async"
  class="mg-headline page-header">
  <mg-icon class="default-icon">warning-o</mg-icon>
  Moderation
</p>

<mat-button-toggle-group
  *ngIf="(layout.useLegacyLayout$ | async) === false"
  class="filter-toggle-group"
  name="tabToggle"
  [multiple]="false">
  <mat-button-toggle
    *ngFor="let stat of primaryStatFilters"
    class="toggle-button"
    routerLinkActive="mat-button-toggle-checked"
    (click)="toggleFilter(stat)">
    <div
      fxLayout="row"
      fxLayoutGap="4px">
      <mg-text variant="body-sm-secondary">
        {{ stat.title }}
      </mg-text>
      <mg-text
        *ngIf="!globalContent && stat.searchFilter !== SEARCH_FILTER_TYPE.ALL"
        variant="body-sm-secondary"
        fontWeight="900">
        · {{ stat.countText || stat.total }}
      </mg-text>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>

<div
  *ngIf="layout.useLegacyLayout$ | async"
  class="filter-blocks large"
  fxLayoutAlign="stretch"
  fxLayout.xs="row wrap"
  [ngClass.xs]="'use-outline'">
  <div
    *ngFor="let stat of primaryStatFilters"
    fxFlex="auto"
    class="filter-block has-filter"
    [class.active]="stat.filterActive"
    [class.title-only]="!stat.countText"
    [class.mg-bodycopy]="!stat.countText"
    (click)="toggleFilter(stat)">
    <div class="filter-block-bg"></div>
    <div class="filter-block-content">
      <div class="filter-block-title">{{ stat.title }}</div>
      <div
        class="filter-block-count"
        *ngIf="stat.countText">
        <span class="filter-block-count-text">{{ stat.countText }}</span>
        <!-- <span class="stat-change filter-block-stat-change-indicator"
          [class.stat-inc]="stat.statInc"
          [class.stat-dec]="stat.statDec">↑</span> -->
      </div>
      <div
        class="filter-block-total"
        *ngIf="stat.total">
        {{ stat.total }}
      </div>
      <div
        *ngIf="stat.statChangeMsg"
        class="filter-block-stat-change stat-change"
        [class.stat-inc]="stat.statInc"
        [class.stat-dec]="stat.statDec">
        {{ stat.statChangeMsg }}
      </div>
    </div>
  </div>
</div>

<div class="feed-items">
  <mg-stream-scroller
    *ngIf="contentFilter"
    [filter]="contentFilter"
    [service]="adminModerationManager"
    rpcStream="streamDetailedModerationResults"
    rpcControl="streamDetailedModerationResultsControl"
    mgStreamControl="MingaModerationFeed"
    [clearOnRestart]="true"
    [bufferAmount]="20">
    <ng-template
      let-item
      let-itemId="itemId"
      mgStreamItem>
      <mg-detailed-moderation-item
        [content]="item"
        [showMinga]="globalContent"
        (openContentCallback)="onOpenContent($event)"
        (openImagesCallback)="onOpenImages($event)"
        [showLongDateFormat]="showLongFormatDateComment">
      </mg-detailed-moderation-item>
    </ng-template>

    <p
      mg-stream-empty
      class="mg-centered mg-minicopy">
      {{ noResultsText }}
    </p>
  </mg-stream-scroller>
</div>
