import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IMentionableResultItem } from 'minga/app/src/app/mentions/constants';

import { IMentionable } from '../../services/Mentions.service';

export let _mentionUiCount = 0;

export function isMentionUiVisible() {
  return _mentionUiCount > 0;
}

@Component({
  selector: 'mg-mentionable-ui',
  templateUrl: './MentionableUI.component.html',
  styleUrls: ['./MentionableUI.component.scss'],
})
export class MentionableUIComponent implements OnInit, OnDestroy {
  input: string = '';
  results: IMentionableResultItem[] = [];
  private _selectedMention?: IMentionableResultItem;
  _loading: boolean = false;

  @Output()
  resultSelect: EventEmitter<IMentionableResultItem>;

  constructor() {
    this.resultSelect = new EventEmitter();
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(e: KeyboardEvent) {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.highlightUp();
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.highlightDown();
    } else if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      const index = this.findHighlightedResultIndex();
      this.selectMention(index);
    }
  }

  private highlightUp() {
    let [result, index] = this.findHighlightedResult();
    let nextIndex = index - 1;
    if (!result) {
      index = this.results.length - 1;
      result = this.results[index];
      nextIndex = index;
    }

    if (this.results[nextIndex]) {
      result.highlighted = false;
      this.results[nextIndex].highlighted = true;
    }
  }

  private highlightDown() {
    let [result, index] = this.findHighlightedResult();
    let nextIndex = index + 1;
    if (!result) {
      index = 0;
      result = this.results[index];
      nextIndex = index;
    }

    if (this.results[nextIndex]) {
      result.highlighted = false;
      this.results[nextIndex].highlighted = true;
    }
  }

  private findHighlightedResult(): [IMentionableResultItem, number] {
    let index = this.findHighlightedResultIndex();
    return [this.results[index] || null, index];
  }

  findHighlightedResultIndex(): number {
    let index = -1;

    for (let i = 0; this.results.length > i; i++) {
      const result = this.results[i];

      if (result.highlighted) {
        index = i;
        break;
      }
    }

    return index;
  }

  setSearchInput(value: string) {
    const wsIndex = value.search(/\s/);

    if (wsIndex > -1) {
      this.input = value.substr(0, wsIndex);
    } else {
      this.input = value;
    }

    this.highlightInput();

    this._loading = true;
  }

  highlightInput() {
    let newOrderIndicies = [];

    for (let i = 0; this.results.length > i; i++) {
      const result = this.results[i];
      let displayNameHtml = result.displayName;
      const inputIndex = displayNameHtml
        .toLowerCase()
        .indexOf(this.input.toLowerCase());

      const namePortion = displayNameHtml.substr(inputIndex, this.input.length);
      newOrderIndicies.push([namePortion.length, i]);

      if (inputIndex > -1) {
        displayNameHtml =
          displayNameHtml.substring(0, inputIndex) +
          `<strong>` +
          namePortion +
          `</strong>` +
          displayNameHtml.substr(inputIndex + this.input.length);
      }

      result.displayNameHtml = displayNameHtml;
    }

    newOrderIndicies = newOrderIndicies.sort((a, b) => {
      if (a[0] > b[0]) return 1;
      else if (a[0] < b[0]) return -1;

      return 0;
    });

    this.results = newOrderIndicies.map(([len, index]) => {
      return this.results[index];
    });
  }

  setResults(results: IMentionable[]) {
    this._loading = false;
    this.results = results.map((item, index) => {
      const existingResult = this.results[index];

      let resultItem = <IMentionableResultItem>{
        displayImage: item.imageUrl,
        displayName: item.displayName,
        displayNameHtml: item.displayName,
        hash: item.hash,
        badgeIconColor: item.badgeIconColor,
      };

      if (existingResult && resultItem.hash == existingResult.hash) {
        resultItem.highlighted = existingResult.highlighted;
      }

      return resultItem;
    });

    this.results = this.results.slice(0, 5);

    this.highlightInput();
  }

  setResultsError(error?: Error) {
    this._loading = false;
  }

  getSelectedMention() {
    if (this._selectedMention) {
      return this._selectedMention;
    }

    let highlightIndex = this.findHighlightedResultIndex();

    if (highlightIndex > -1) {
      return this.results[highlightIndex];
    }

    return this.results[this.results.length - 1] || null;
  }

  selectMentionClick(e: MouseEvent, index: number) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    this.selectMention(index);
  }

  selectMention(index: number) {
    const result = this.results[index];

    this._selectedMention = result ? result : null;
    this.resultSelect.emit(this._selectedMention);
  }

  ngOnInit() {
    _mentionUiCount += 1;
  }

  ngOnDestroy() {
    _mentionUiCount -= 1;
  }
}
