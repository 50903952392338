import { NgModule } from '@angular/core';

import { MgAnnouncementMiniCardModule } from './MgAnnouncementMiniCard';
import { MgBasicMiniCardModule } from './MgBasicMiniCard';
import { MgFileMiniCardModule } from './MgFileMiniCard';
import { MgFullGraphicMiniCardModule } from './MgFullGraphicMiniCard';
import { MgPollMiniCardModule } from './MgPollMiniCard';

@NgModule({
  exports: [
    MgAnnouncementMiniCardModule,
    MgBasicMiniCardModule,
    MgFileMiniCardModule,
    MgPollMiniCardModule,
    MgFullGraphicMiniCardModule,
  ],
})
export class MgMiniCardModule {}
