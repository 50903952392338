<div
  class="mg-snackbar-icon"
  [ngSwitch]="data.iconPlacement">
  <mg-button-icon
    *ngSwitchCase="'before'"
    [icon]="data.icon"
    class="before"
    [ngClass]="data.iconClass"></mg-button-icon>
  <span class="mg-minicopy">{{ data.text }}</span>
  <mg-button-icon
    *ngSwitchDefault
    [icon]="data.icon"
    [ngClass]="data.iconClass"></mg-button-icon>
  <button
    class="mat-simple-snackbar-action"
    *ngIf="data.simpleAction">
    {{ data.simpleAction }}
  </button>
</div>
