import { SubscriptionInfo } from '../types';

export enum MingaManagerSubscriptionMessage {
  SUBSCRIPTION_DETAILS_TITLE = 'My Subscription Details',
  MINGA_MODULES_TITLE = 'My Minga Modules',
  ADD_ON_MODULES_TITLE = 'Add-on Modules',
  LEARN_MORE_LABEL = 'Learn more',
  UPGRADE_LABEL = 'Request upgrade',
}

export enum SubscriptionInfoType {
  STUDENT_TIER = 'studentTier',
  SMS_MESSAGES = 'smsMessages',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export const subscriptionInfoConfig: SubscriptionInfo[] = [
  {
    title: 'Student Tier',
    icon: 'mg-group-menu',
    type: SubscriptionInfoType.STUDENT_TIER,
  },
  {
    title: 'SMS Sent',
    icon: 'mg-sms',
    type: SubscriptionInfoType.SMS_MESSAGES,
  },
  {
    title: 'Agreement Start Date',
    icon: 'mg-event-menu',
    type: SubscriptionInfoType.START_DATE,
  },
  {
    title: 'Agreement End Date',
    icon: 'mg-event-busy',
    type: SubscriptionInfoType.END_DATE,
  },
];
