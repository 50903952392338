import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

export interface IMgFileExtensionBoxElementProperties {
  extension: string;
  previewUrl: string;
}

@Component({
  selector: 'mg-file-extension-box',
  templateUrl: './MgFileExtensionBox.element.html',
  styleUrls: ['./MgFileExtensionBox.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgFileExtensionBoxElement
  implements IMgFileExtensionBoxElementProperties
{
  @HostBinding('attr.extension')
  @Input()
  extension: string;

  @Input()
  previewUrl: string;

  getExtensionString(extension: string) {
    extension = extension.toUpperCase();

    // mpeg or jpeg to mpg/jpg
    if (extension.length == 4 && extension.includes('PEG')) {
      extension = extension.replace('E', '');
    } else if (extension.length > 3) {
      return extension.substr(0, 3);
    }

    return extension || '?';
  }

  isUrlLink(input: string) {
    const extension = this.getExtensionString(input);
    return extension == 'URL';
  }

  hasPreviewUrl(previewUrl) {
    return !!previewUrl;
  }
}
