import { Pipe, PipeTransform } from '@angular/core';

/**
 * Array Includes
 */
@Pipe({ name: 'includes' })
export class ArrayIncludesPipe implements PipeTransform {
  // the type of str should be one of the types of the array
  transform<T extends any[], K extends T[number]>(value: T, str: K): boolean {
    return (value || []).includes(str);
  }
}
