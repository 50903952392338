import { NgModule } from '@angular/core';

import { MgModalContainerDirective } from './MgModalContainer.directive';
import { MgModalContainerWrapComponent } from './MgModalContainerWrap.component';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [MgModalContainerDirective, MgModalContainerWrapComponent],
  exports: [MgModalContainerDirective, MgModalContainerWrapComponent],
})
export class MgModalContainerModule {}
