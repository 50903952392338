<div class="input">
  <label
    #labelElement
    *ngIf="formControl && label?.length"
    class="input-label"
    [ngClass]="formClasses"
    [class.focused]="
      hasValue ||
      (inputIsFocused$ | async) ||
      (inputType === 'textarea' && templateVariables)
    "
    [class.hasError]="hasError"
    [class.disabled]="inputDisabled"
    [class.has-icon-left]="iconLeft">
    {{ label }}
  </label>

  <span
    *ngIf="iconLeft"
    class="icon-left"
    [class.disabled]="inputDisabled">
    <mg-icon>{{ iconLeft }}</mg-icon>
  </span>

  <span
    *ngIf="iconRight && inputType !== 'number'"
    class="icon-right"
    [class.disabled]="inputDisabled"
    (click)="iconRightClicked.emit()">
    <mg-icon>{{ iconRight }}</mg-icon>
  </span>

  <span
    *ngIf="(showPassword$ | async) !== null"
    class="icon-right password-icon"
    (click)="toggleVisibility()">
    <mat-icon
      >{{ (showPassword$ | async) ? 'visibility_off' : 'visibility' }}
    </mat-icon>
  </span>

  <button
    *ngIf="showClearButton"
    class="clear-button"
    type="button"
    [class.disabled]="inputDisabled"
    (click)="clearInput()">
    <mat-icon>close</mat-icon>
  </button>

  <div class="input-sizer">
    <div
      #prefixElement
      *ngIf="prefix"
      class="left-text"
      fxLayoutAlign="center center"
      [class.disabled]="inputDisabled">
      <mg-text
        variant="body-sm"
        [color]="inputDisabled ? 'on-disabled-bg' : 'surface-alt'">
        {{ prefix }}
      </mg-text>
    </div>

    <ng-container
      *ngIf="inputType == 'textarea' && templateVariables"
      [ngTemplateOutlet]="textAreaToolbarTemplate">
    </ng-container>

    <input
      #inputElement
      *ngIf="inputType !== 'textarea'; else textAreaContainer"
      class="input-element"
      [autofocus]="autofocus"
      [type]="inputType"
      [class.error]="hasError"
      [class.valid]="formControl.valid && formControl.dirty"
      [class.reset-line-height]="isIOS"
      [class.disabled]="inputDisabled"
      [class.has-icon-left]="iconLeft"
      [class.has-icon-right]="iconRight || isClearable"
      [class.has-left-text]="prefix"
      [class.has-right-text]="suffix"
      [class.condensed]="condensed"
      [ngClass]="formClasses"
      [ngStyle]="formStyleClasses"
      [placeholder]="label && !hasValue ? '' : placeholder"
      [step]="
        inputType === 'number' || (inputType === 'time' && step) ? step : 1
      "
      [formControl]="formControl"
      [attr.inputmode]="inputMode"
      [attr.autocomplete]="autoComplete"
      [attr.list]="list"
      [attr.data-analytics]="id"
      [attr.data-test]="id"
      [min]="min"
      [max]="max"
      [maxlength]="maxlength"
      (focus)="inputGainedFocus()"
      (blur)="inputLostFocus()"
      (keyup.enter)="returnPressed.emit()"
      (wheel)="preventScroll($event)" />

    <div
      *ngIf="suffix"
      class="right-text"
      fxLayoutAlign="center center"
      [class.disabled]="inputDisabled">
      <mg-text
        variant="body-sm"
        [color]="inputDisabled ? 'on-disabled-bg' : 'surface-alt'">
        {{ suffix }}
      </mg-text>
    </div>
  </div>
  <mg-form-error *ngIf="formControl.invalid && formControl.touched">
    {{ formControl?.errors | makeInputErrorMessage }}
  </mg-form-error>

  <div
    *ngIf="hint"
    class="hint">
    {{ hint }}
  </div>
</div>

<!-- Textarea container -->
<ng-template #textAreaContainer>
  <!-- Preview -->
  <div
    *ngIf="previewMode$ | async"
    class="input-element text-area preview-element"
    [class.has-template-variables]="templateVariables"
    [style.height]="textAreaHeight$ | async">
    {{ this.formControl.value | formTextAreaPreview: templateVariables }}
  </div>
  <!-- Textarea element -->
  <textarea
    #textareaElement
    class="input-element text-area"
    rows="4"
    [autofocus]="autofocus"
    [fxHide]="previewMode$ | async"
    [class.error]="hasError"
    [class.valid]="formControl.valid && formControl.dirty"
    [class.disabled]="inputDisabled"
    [class.has-template-variables]="templateVariables"
    [ngClass]="formClasses"
    [placeholder]="label && !hasValue && !placeholder ? '' : placeholder"
    [formControl]="formControl"
    (keyup)="adjustTextareaHeight()"
    (focus)="inputGainedFocus()"
    (blur)="inputLostFocus()">
  </textarea>
</ng-template>

<!-- Textarea toolbar template -->
<ng-template #textAreaToolbarTemplate>
  <div
    class="textarea-toolbar"
    [ngClass]="this.labelBackground">
    <ng-container
      *ngIf="templateVariables"
      [ngTemplateOutlet]="templateVariableMenuTemplate">
    </ng-container>
  </div>

  <div
    class="textarea-bottom-toolbar"
    [class.has-error]="formControl.invalid && formControl.touched">
    <button
      class="variable-button"
      type="button"
      (click)="togglePreviewMode()">
      <mg-text
        variant="body-xs-secondary"
        color="outline">
        {{ (previewMode$ | async) ? 'End preview' : 'Preview' }}
      </mg-text>
    </button>
  </div>
</ng-template>

<!-- Template variable template -->
<ng-template #templateVariableMenuTemplate>
  <button
    class="variable-button"
    type="button"
    [matMenuTriggerFor]="templateVariablseMenu">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="4px">
      <mg-text
        variant="body-xs-secondary"
        color="outline">
        [Insert variables]
      </mg-text>
      <mg-icon class="dropdown-arrow"> mg-drop-down-arrow </mg-icon>
    </div>
  </button>
  <mat-menu #templateVariablseMenu="matMenu">
    <button
      *ngFor="let variable of templateVariables | keyvalue"
      class="variable-menu-item"
      mat-menu-item
      (click)="addTemplateVariable(variable.key)">
      <span>{{ variable.key }}</span>
    </button>
  </mat-menu>
</ng-template>
