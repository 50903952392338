export enum EventMessages {
  GOING = 'Going',
  INTERESTED = 'Interested',
  INVITED = 'Invited',
  CHECKED_IN = 'Checked in',
  CHECKED_OUT = 'Checked out',
  CANT_GO = "Can't go",
  CHECK_IN = 'Check in',
  CHECK_OUT = 'Check out',
  NOT_CHECKED_IN = 'Not checked in',
  TICKETS = 'Tickets',
  RESPONDED = 'Responded',
  EVENT_MANAGEMENT = 'Event Management',
  TICKETED = 'Ticketed',
}
