<div
  *ngIf="hasItems()"
  class="subnav-container"
  [ngClass]="{
    'ftue-highlight': highlightKey && (highlightKey | mgFtueHighlight | async)
  }">
  <div
    class="nav-item-title"
    *ngIf="header"
    fxHide
    fxShow.gt-sm>
    {{ header }}
  </div>
  <div
    fxHide
    fxShow.lt-md
    class="nav-item-ripple"
    (mousedown)="toggleMenu()"
    matRipple>
    <div
      #matMenuTriggerContainer
      class="mat-menu-trigger-container">
      <div
        #navMatMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="navMatMenu"></div>
    </div>
  </div>

  <div
    fxHide
    fxShow.lt-md
    class="navitem-contents no-ftue mg-button-hover-harbour">
    <mg-navicon [icon]="icon"></mg-navicon>
    <span>{{ title }}</span>
  </div>
  <mat-expansion-panel
    fxHide
    fxShow.gt-sm
    mg-nav-item-exp-panel
    [hideToggle]="true"
    class="no-material mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title class="mg-button-hover-harbour">
        <mg-navicon [icon]="icon"></mg-navicon>
        <span mg-nav-item-text>{{ title }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-list class="nav-subnav-list">
        <ng-container *ngFor="let item of items">
          <ng-container
            [ngTemplateOutlet]="expansionPanelNavLinks"
            [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-container>
      </mat-list>
    </ng-template>
  </mat-expansion-panel>
  <mat-menu
    #navMatMenu="matMenu"
    [overlapTrigger]="false"
    x-position="before"
    class="nav-mat-menu"
    style="display: block">
    <div
      class="nav-item-title mat-menu-item"
      *ngIf="header">
      {{ header }}
    </div>
    <ng-container *ngFor="let item of items">
      <ng-container
        [ngTemplateOutlet]="matMenuNavLinks"
        [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
    </ng-container>
  </mat-menu>

  <ng-template
    #expansionPanelNavLinks
    let-item>
    <mat-list-item
      [routerLink]="item.routerLink"
      [ngClass]="item.classList.value"
      (click)="item.click.emit($event)"
      class="mg-minicopy mg-button-hover-harbour">
      <mg-navicon
        class="sub-navicon"
        *ngIf="item.icon"
        [icon]="item.icon"
        [title]="item.titleText"></mg-navicon>
      <span
        mg-nav-item-text
        *ngIf="item.text"
        [title]="item.titleText"
        >{{ item.text }}</span
      >
      <img
        *ngIf="item.imageUrl"
        mg-nav-item-image
        [src]="item.imageUrl"
        [title]="item.titleText" />
    </mat-list-item>
  </ng-template>

  <ng-template
    #matMenuNavLinks
    let-item>
    <button
      mat-menu-item
      class="mg-subnav-mat-menu-item"
      (click)="item.click.emit($event)"
      [routerLink]="item.routerLink"
      [ngClass]="item.classList.value">
      <div class="mg-subnav-mat-menu-item-container mg-button-hover-harbour">
        <mg-navicon
          *ngIf="item.icon"
          [icon]="item.icon"></mg-navicon>
        <span *ngIf="item.text">{{ item.text }}</span>
        <img
          *ngIf="item.imageUrl"
          mg-nav-item-image
          [src]="item.imageUrl" />
      </div>
    </button>
  </ng-template>
</div>
