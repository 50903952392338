import { Injectable, OnDestroy, TemplateRef } from '@angular/core';

import { PortalLayoutSection } from '../constants/portal-layout.constants';
import { PortalLayoutService } from '../services/portal-layout.service';

@Injectable()
export abstract class PortalLayoutAbstract implements OnDestroy {
  // Computed getters

  get nameAsTitleCase() {
    return this.name.charAt(0).toUpperCase() + this.name.slice(1);
  }

  constructor(
    public name: PortalLayoutSection,
    public portalLayout: PortalLayoutService,
  ) {}

  public init(content: TemplateRef<unknown>) {
    this.portalLayout.setContent(this.name, content);
  }

  ngOnDestroy() {
    this.portalLayout.clearSectionContent(this.name);
  }
}
