/**
 * Simple function for getting percents from a numerator and demoninator.
 *
 * @param numerator
 * @param denominator
 * @param both toggles return of percent for and against in output array
 * @returns array of numbers
 */
export const getPercents = (
  numerator: number,
  denominator: number,
  both = false,
): number[] => {
  let percent_for = 0;
  let percent_against = 0;
  if (denominator > 0) {
    percent_for = Math.round((numerator / denominator) * 100);
    if (both) {
      percent_against = 100 - percent_for;
    }
  }
  const percents = both ? [percent_for, percent_against] : [percent_for];
  return percents;
};
