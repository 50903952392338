import { Injectable } from '@angular/core';

import * as flex_time_pb from 'minga/proto/flex_time/flex_time_pb';
import {
  FlexTimeActivity,
  FlexTimeActivityPayload,
} from 'minga/domain/flexTime';
import { FlexTimeManager } from 'minga/proto/flex_time/flex_time_ng_grpc_pb';
import { FlexTimeActivityMapper } from 'minga/shared-grpc/flex_time';

import { ErrorHandlerService } from '@shared/services/error-handler';

@Injectable()
export class FlexTimeActivityService {
  /** Service Constructor */
  constructor(
    private _flexTimeManager: FlexTimeManager,
    private _errorHandler: ErrorHandlerService,
  ) {}

  public async fetchAll(personHash?: string): Promise<FlexTimeActivity[]> {
    try {
      const request = new flex_time_pb.ListFlexTimeActivitiesRequest();
      if (personHash) request.setPersonHash(personHash);
      const response = await this._flexTimeManager.listFlexTimeActivities(
        request,
      );
      return response
        .getFlexTimeActivitiesList()
        .map(FlexTimeActivityMapper.fromProto);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch all flex time activities',
        error,
        true,
      );
    }
  }

  public async create(
    activity: FlexTimeActivityPayload,
  ): Promise<FlexTimeActivity> {
    try {
      const request = new flex_time_pb.UpsertFlexTimeActivityRequest();
      request.setFlexTimeActivity(
        FlexTimeActivityMapper.toProto(activity as any),
      );
      const response = await this._flexTimeManager.upsertFlexTimeActivity(
        request,
      );
      return FlexTimeActivityMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to create new flex time activity',
        error,
        true,
      );
    }
  }

  public async update(
    activity: FlexTimeActivityPayload,
  ): Promise<FlexTimeActivity> {
    try {
      const request = new flex_time_pb.UpsertFlexTimeActivityRequest();
      request.setFlexTimeActivity(
        FlexTimeActivityMapper.toProto(activity as any),
      );
      const response = await this._flexTimeManager.upsertFlexTimeActivity(
        request,
      );
      return FlexTimeActivityMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to update flex time activity',
        error,
        true,
      );
    }
  }

  public async delete(id: number): Promise<void> {
    try {
      const request = new flex_time_pb.DeleteFlexTimeActivityRequest();
      request.setId(id);
      await this._flexTimeManager.deleteFlexTimeActivity(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete flex time activity',
        error,
        true,
      );
    }
  }
}
