import { Component, Inject } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import PrintService from '@shared/services/print';

import { ImageViewerModalMessages } from './constants';
import { ImageViewerModalData, ImageViewerModalResponse } from './types';

@Component({
  selector: 'mg-image-viewer-modal',
  templateUrl: './image-viewer-modal.component.html',
  styleUrls: ['./image-viewer-modal.component.scss'],
})
export class ImageViewerModalComponent {
  private _dataSubj = new BehaviorSubject<ImageViewerModalData>(undefined);
  public data$ = this._dataSubj.asObservable();

  MESSAGES: typeof ImageViewerModalMessages = ImageViewerModalMessages;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public modalData: ImageViewerModalData,
    private _modalRef: ModalOverlayRef<
      ImageViewerModalData,
      ImageViewerModalResponse
    >,
    private _printService: PrintService,
    private _mingaSnackBar: SystemAlertSnackBarService,
  ) {}

  public closeOverlay() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  public printImage() {
    this._dataSubj.next(this.modalData);
    const data = this._dataSubj.value;
    try {
      this._printService.printImage(data.imageUrl, '30%');
    } catch (error) {
      this._mingaSnackBar.open({
        type: 'error',
        message: error,
      });
    }
  }
}
