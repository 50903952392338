<div
  class="mg-country"
  [title]="country"
  [class.active]="active"
  [class.no-text]="hideText">
  <div
    class="country-image"
    [ngClass]="country"></div>
  <span class="country-text">{{ country }}</span>
</div>
