<video
  #video
  autoplay="autoplay"
  muted="true"
  playsinline
  preload
  disablePictureInPicture
  disableRemotePlayback
  poster="{{ videoSrcPrefix }}{{ videoName }}.jpg">
  <source
    type="video/webm"
    src="{{ videoSrcPrefix }}{{ videoName }}.webm" />
  <source
    type="video/mp4"
    src="{{ videoSrcPrefix }}{{ videoName }}.mp4" />
</video>
