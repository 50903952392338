import { ChartDataset } from 'chart.js';
import { Label } from 'ng2-charts';

import { GroupType } from 'minga/domain/timeManipulation';
import { day } from 'minga/shared/day';

import { HpmReportsSortBy } from './hpm-reports.constants';
import { HpmChartData } from './hpm-reports.types';

export const generateGraphData = (
  { types, summary, startDate, groupBy, sort }: HpmChartData,
  labels: Label[],
): ChartDataset[] => {
  if (!types || !summary.length) {
    return [{ data: [], label: '', stack: '1' }];
  }
  let sortByField = 'totalTime';
  let sortUnits = 'time';
  if (sort === HpmReportsSortBy.PASSES) {
    sortByField = 'totalPasses';
    sortUnits = 'passes';
  }

  const result: ChartDataset[] = [];
  for (const hallPass of types) {
    const hpStats = summary.filter(d => d.typeId === hallPass.id);
    const rowData: number[] = [];
    const loopLimit = labels.length;
    const date = day(startDate);

    for (let i = 0; i < loopLimit; i++) {
      switch (groupBy) {
        case GroupType.BY_WEEK:
          rowData[i] = hpStats
            .filter(stat => {
              const newDate = day(date).add(i, 'week');
              return newDate.week() === stat.week;
            })
            .reduce((acc, stat) => acc + stat[sortByField], 0);
          break;
        case GroupType.BY_DAY: {
          rowData[i] = hpStats
            .filter(stat => {
              const newDate = day(date).add(i, 'd');
              return day(stat.date).isSame(newDate, 'date');
            })
            .reduce((acc, stat) => acc + stat[sortByField], 0);
          break;
        }

        case GroupType.BY_HOUR:
          rowData[i] = hpStats
            .filter(stat => stat.hour - 1 === i)
            .reduce((acc, stat) => acc + stat[sortByField], 0);
          break;
        default:
          rowData[i] = hpStats
            .filter(stat => {
              const newDate = day(date).add(i, 'month');
              return (
                newDate.format('M') === (stat.month + 1).toString() &&
                newDate.format('YYYY') === stat.year.toString()
              );
            })
            .reduce((acc, stat) => acc + stat[sortByField], 0);
      }
    }
    result.push({
      label: hallPass.name + ' (' + sortUnits + ')',
      backgroundColor: hallPass.color,
      stack: '1',
      data: rowData,
    });
  }
  return result;
};

export const generateLabels = ({
  groupBy,
  startDate,
  endDate,
}: HpmChartData): Label[] => {
  const results: string[] = [];
  if (!startDate) {
    return [];
  }
  if (!endDate) {
    endDate = startDate.clone();
  }
  if (groupBy === GroupType.BY_WEEK) {
    let start = day(startDate);
    while (endDate > start || start.format('ww') === endDate.format('ww')) {
      const startofWeek = start.clone().startOf('week');
      const endofWeek = start.clone().endOf('week');
      results.push(
        startofWeek.format('MMM D') + ' - ' + endofWeek.format('MMM D'),
      );
      start = start.add(1, 'week');
    }
  } else if (groupBy === GroupType.BY_HOUR) {
    const totalHours = 24;
    for (let i = 1; i <= totalHours; i++) {
      if (i < 12) {
        results.push(`${i} AM`);
      } else if (i > 12 && i < 24) {
        results.push(`${i - 12} PM`);
      } else if (i === 12) {
        results.push(`${i} PM`);
      } else {
        results.push(`${i - 12} AM`);
      }
    }
  } else if (groupBy === GroupType.BY_DAY) {
    if (!startDate) {
      startDate = day().subtract(7, 'd');
    }
    if (!endDate) {
      endDate = day();
    }
    let date = day(startDate);

    results.push(date.format('MMM D'));
    const totalDays = endDate.diff(startDate, 'days');
    for (let i = 1; i <= totalDays; i++) {
      date = date.add(1, 'day');
      results.push(date.format('MMM D'));
    }
  } else {
    let start = day(startDate);
    while (endDate > start || start.format('M') === endDate.format('M')) {
      results.push(start.format('MMMM'));
      start = start.add(1, 'month');
    }
  }
  return results;
};
