import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MingaEffects, MingaEffectsModule } from './effects';
import { mingaReducer } from './reducers/minga.reducer';

@NgModule({
  imports: [
    MingaEffectsModule,
    StoreModule.forFeature('mingaStore', { mingaCollection: mingaReducer }),
    EffectsModule.forFeature([MingaEffects]),
  ],
  declarations: [],

  providers: [],
})
export class MingaStoreModule {}
