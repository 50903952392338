import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { TooltipSizes } from './tooltip.types';

@Component({
  selector: 'mg-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  /** Inputs */
  @Input() public icon = 'mg-help-center';
  @Input() public label = 'tooltip';
  @Input() public size: TooltipSizes = 'small';

  @ViewChild(MatTooltip, { static: true })
  popover: MatTooltip;

  /** Computed */
  get tooltipStyleClasses(): Record<string, boolean> {
    return {
      [this.size]: true,
    };
  }

  /** Component Constructor */
  constructor() {}

  toggle() {
    if (this.popover) {
      this.popover.toggle();
    }
  }
}
