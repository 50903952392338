<mg-button-icon
  (click)="cancel()"
  class="top left"
  icon="close"></mg-button-icon>

<p
  class="mg-dialog message dialog-title"
  *ngIf="title">
  {{ title }}
</p>
<p class="mg-dialog message">{{ text }}</p>

<div class="btn-wrap footer-button-container">
  <mg-button
    type="text"
    (click)="cancel()"
    >{{ 'button.cancel' | translate }}</mg-button
  >
  <mg-button-outline (click)="confirm()">
    {{ 'button.confirm' | translate }}
  </mg-button-outline>
</div>
