import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IEmptyStateIcon } from 'minga/app/src/app/components/EmptyState';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { PollAnswerStateService } from 'minga/app/src/app/minimal/services/PollAnswerState';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { Poll } from 'minga/proto/content/poll_ng_grpc_pb';
import { MingaPermission } from 'minga/util';
import { StreamManager } from 'src/app/minimal/services/StreamManager';

import { LayoutService } from '@modules/layout/services';

@Component({
  selector: 'mg-home-polls',
  templateUrl: './home-polls.component.html',
  styleUrls: ['./home-polls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePollsComponent implements OnDestroy {
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  emptyStateIcons: IEmptyStateIcon[] = [];
  canCreatePolls = false;

  /** Component constructor */
  constructor(
    public layout: LayoutService,
    public pollAnswerService: PollAnswerStateService,
    public _contentEvents: ContentEvents,
    public groupsFacade: GroupsFacadeService,
    public contentState: ContentState,
    public pollService: Poll,
    private _permissions: PermissionsService,
    private _streamManager: StreamManager,
  ) {
    // load groups for group feed content
    groupsFacade.dispatchLoadAll();

    this.emptyStateIcons = [
      {
        icon: 'post',
        text: 'Add',
      },
      {
        icon: 'polls-o',
        text: 'Poll',
      },
    ];

    this.canCreatePolls = this._permissions.hasPermission(
      MingaPermission.CONTENT_POLL_CREATE,
    );

    this._contentEvents.onContentDeleted
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        this._streamManager.restartStream('AdminPolls');
      });
    this._contentEvents.onContentFeedsUpdate
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => {
        this._streamManager.restartStream('AdminPolls');
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  onItemClick(e) {
    const contentContextHash = e.model.item.id;
    if (contentContextHash) {
      this._contentEvents.emitContentOpen({ contentContextHash });
    }
  }
}
