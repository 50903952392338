<div
  fxLayout="row"
  fxLayoutAlign.gt-sm="end end"
  [style.marginBottom]="'16px'">
  <mg-btn
    variant="filled"
    [id]="'hallpass-types-click-create'"
    [responsive]="true"
    (pressed)="onClickCreateNewType()">
    {{ MSG.BUTTON_LABEL_CREATE }}
  </mg-btn>
</div>
<ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>

<!-- Main template content -->
<ng-template #mainContentTemplate>
  <mg-hpm-types-table [onNewTypeCreated]="newType$"></mg-hpm-types-table>
</ng-template>
