import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';

import { Observable, of, timer } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';
import { uniqueEmailRace } from 'minga/app/src/app/util/grpc-multi-invoke';
import { EmailUniqueResponse } from 'minga/proto/gateway/people_pb';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';

export interface IMgUniqueEmailOptions {
  locallyUsedEmails: string[];
}

@Directive({
  selector: '[mgUniqueEmail]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: MgUniqueEmailDirective,
      multi: true,
    },
  ],
})
export class MgUniqueEmailDirective implements AsyncValidator {
  private _enabled: boolean = true;
  private _ignoreEmails: string[] = [];

  @Input()
  set mgUniqueEmail(value: any) {
    this._enabled = !!value;
    this._ignoreEmails = [];

    if (value) {
      if (typeof value == 'string') {
        this._ignoreEmails = [value];
      } else if (Array.isArray(value)) {
        this._ignoreEmails = value;
      }
    }
  }

  @Input()
  mgUniqueEmailOptions?: IMgUniqueEmailOptions;

  constructor(
    private appConfig: AppConfigService,
    private mingaStore: MingaStoreFacadeService,
  ) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!this._enabled) {
      return of(null);
    }

    const localChecks = (email: string) => {
      if (typeof email !== 'string') {
        return of(null);
      }

      if (this._ignoreEmails.includes(email)) {
        return of(null);
      }

      if (this.mgUniqueEmailOptions) {
        const { locallyUsedEmails } = this.mgUniqueEmailOptions;

        if (locallyUsedEmails.includes(email)) {
          return of({ emailTaken: true });
        }
      }

      return null;
    };

    {
      const result = localChecks(control.value);
      if (result) {
        return result;
      }
    }

    return timer(1000).pipe(
      withLatestFrom(this.mingaStore.getMingaAsObservable()),
      switchMap(([x, minga]) => {
        const email = control.value;
        let mingaHash = '';
        if (minga) {
          mingaHash = minga.hash;
        }

        {
          const result = localChecks(email);
          if (result) {
            return result;
          }
        }

        const returnUniqueValidationErr = (res: EmailUniqueResponse) => {
          let isUnique = res.getUnique();
          if (!isUnique) {
            return {
              emailTaken: true,
            };
          } else {
            return null;
          }
        };

        return this.appConfig
          .getAllApiUrls()
          .then(apiUrls => uniqueEmailRace(email, apiUrls, mingaHash))
          .then(({ response }) => returnUniqueValidationErr(response));
      }),
    );
  }
}
