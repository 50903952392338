<mat-dialog-content class="dialog-content">
  <ng-container *ngIf="isUserRole('OWNER') || isUserRole('SUPERADMIN')">
    <mg-welcome-owner
      (done)="close()"
      [mingaName]="mingaName"></mg-welcome-owner>
  </ng-container>
  <ng-container
    *ngIf="
      isUserRole('STUDENT') ||
      isUserRole('STUDENT_LEADER') ||
      isUserRole('MEMBER') ||
      isUserRole('PARENT')
    ">
    <mg-welcome-student
      (done)="close()"
      [mingaName]="mingaName"
      [enableGroups]="enableGroups"></mg-welcome-student>
  </ng-container>
  <ng-container
    *ngIf="
      isUserRole('TEACHER') || isUserRole('MANAGER') || isUserRole('STAFF')
    ">
    <mg-welcome-teacher-manager
      (done)="close()"
      [mingaName]="mingaName"
      [enableGroups]="enableGroups"></mg-welcome-teacher-manager>
  </ng-container>
</mat-dialog-content>
