import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import Quagga, { QuaggaJSResultObject } from '@ericblade/quagga2';
import _ from 'lodash';
import { WebcamComponent, WebcamImage, WebcamInitError } from 'ngx-webcam';
import QrCodeReader from 'quagga2-reader-qr';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { bufferCount, map, takeUntil } from 'rxjs/operators';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';

import { SystemAlertSnackBarService } from '../system-alert-snackbar';
import {
  MODAL_TITLE_MAP,
  WEBCAM_MODAL_SCAN_SAME_ID_GAP,
  WebcamModalMessages,
} from './constants';
import {
  WebcamErrorState,
  WebcamModalData,
  WebcamModalResponse,
} from './types';
import { getQuaggaConfig } from './utils';

Quagga.registerReader('qrcode', QrCodeReader);

const lastScannedValueSubject = new BehaviorSubject<{
  value: string;
  timestamp: number;
} | null>(null);

/**
 * Webcam Dialog Component
 *
 * Wrapper component for both ngx-webcam and quagga.
 *
 * @todo implement video recording capabilities, ngx-webcam does not include
 * video recording straight out of the box, will need to use MediaRecorder
 * @link https://github.com/basst314/ngx-webcam
 * @link https://github.com/ericblade/quagga2
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder
 */
@Component({
  selector: 'mg-webcam-modal',
  templateUrl: './webcam-modal.component.html',
  styleUrls: ['./webcam-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebcamModalComponent implements OnInit, AfterViewInit, OnDestroy {
  // Children

  @ViewChild(WebcamComponent, { static: false })
  webcamComponent!: WebcamComponent;

  @ViewChild('quaggaContainer')
  quaggaContainer: ElementRef<HTMLDivElement>;

  // Constants

  public readonly MSG = WebcamModalMessages;

  // Clean up helpers

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State

  public showDoneButton = this.dialogData.type !== 'scanner';

  /** Webcam capture result */
  private readonly _webcamImage = new BehaviorSubject(undefined);
  public readonly webcamImage$ = this._webcamImage
    .asObservable()
    .pipe(takeUntil(this._destroyedSubject));

  /** Scanned value data */
  private readonly _quaggaScannedValueSubject = new Subject<string>();

  /** Webcam Error State */
  private readonly _webcamErrorState = new BehaviorSubject<
    WebcamInitError | undefined
  >(undefined);
  public readonly webcamErrorState$ = this._webcamErrorState
    .asObservable()
    .pipe(takeUntil(this._destroyedSubject));

  /** Loading state */
  private readonly _isLoadingSubject = new BehaviorSubject<boolean>(true);
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  /** Quagga Error State */
  private readonly _quaggaErrorState = new BehaviorSubject<WebcamErrorState>({
    hasErrored: false,
    message: WebcamModalMessages.WEBCAM_ERROR_GENERAL,
  });
  public readonly quaggaErrorState$ = this._quaggaErrorState
    .asObservable()
    .pipe(takeUntil(this._destroyedSubject));

  /** Webcam Capture Trigger Emitter */
  private readonly _trigger: Subject<void> = new Subject<void>();
  public readonly trigger$ = this._trigger
    .asObservable()
    .pipe(takeUntil(this._destroyedSubject));

  // Computed getters

  get defaultModalTitle() {
    return MODAL_TITLE_MAP[this.dialogData.type];
  }

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: WebcamModalData,
    private _modalRef: ModalOverlayRef<WebcamModalResponse, WebcamModalData>,
    private _systemSnackbar: SystemAlertSnackBarService,
  ) {}

  ngOnInit(): void {
    this._quaggaScannedValueSubject
      .asObservable()
      .pipe(
        takeUntil(this._destroyedSubject),
        bufferCount(5),
        // find the most used value in array
        map(v => String(_.head(_(v).countBy().entries().maxBy(_.last)))),
      )
      .subscribe(v => this._handleQuaggaDetection(v));
  }

  ngAfterViewInit() {
    this._initQuagga();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._trigger.complete();
    this._quaggaErrorState.complete();
    this._webcamImage.complete();
    this._webcamErrorState.complete();
    this._isLoadingSubject.complete();
    this._quaggaScannedValueSubject.complete();
    if (this.dialogData.type === 'scanner') this._cleanUpQuagga();
  }

  public submit() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT);
  }

  public cancel() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  public onClickCaptureImage() {
    const image = this._webcamImage.getValue();
    if (image) {
      this._webcamImage.next(undefined);
    } else {
      this._trigger.next();
    }
  }

  public handleCaptureImage(webcamImage: WebcamImage): void {
    this._webcamImage.next(webcamImage);
  }

  public handleInitError(error: WebcamInitError): void {
    this._webcamErrorState.next(error);
  }

  private async _initQuagga() {
    if (this.dialogData.type !== 'scanner') return;
    try {
      const target = this.quaggaContainer.nativeElement;
      if (!target) throw new Error('Quagga container not found');

      const quaggaConfig = getQuaggaConfig(target, this.dialogData?.readers);

      Quagga.init(quaggaConfig, e =>
        e == null ? this._startQuagga() : this._handleQuaggaError(e),
      );
      Quagga.onDetected(r => {
        const value = r.codeResult.code;
        if (!value) return;
        this._quaggaScannedValueSubject.next(value);
      });
      Quagga.onProcessed(r => this._handleQuaggaProcessed(r));
    } catch (err) {
      this._systemSnackbar.error(err?.message || this.MSG.WEBCAM_ERROR_GENERAL);
    }
  }

  private _startQuagga() {
    Quagga.start();
    this._isLoadingSubject.next(false);
  }

  private _handleQuaggaError(err: any) {
    this._quaggaErrorState.next({
      hasErrored: true,
      message: err.message,
    });
  }

  private _handleQuaggaDetection(value: string) {
    const lastScannedValue = lastScannedValueSubject.getValue();
    const currentTimeStamp = new Date().getTime();
    const saveAndSubmit = () => {
      lastScannedValueSubject.next({ value, timestamp: currentTimeStamp });
      this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
        value,
      });
    };
    if (lastScannedValue == null) saveAndSubmit();
    else if (lastScannedValue?.value !== value) saveAndSubmit();
    else {
      const timeDiff = currentTimeStamp - lastScannedValue?.timestamp;
      if (timeDiff > WEBCAM_MODAL_SCAN_SAME_ID_GAP) saveAndSubmit();
    }
  }

  private _handleQuaggaProcessed(result: QuaggaJSResultObject) {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    if (result) {
      if ((result as any)?.location) {
        drawingCtx.clearRect(
          0,
          0,
          parseInt(drawingCanvas.getAttribute('width'), 10),
          parseInt(drawingCanvas.getAttribute('height'), 10),
        );

        const {
          topLeftCorner,
          topRightCorner,
          bottomLeftCorner,
          bottomRightCorner,
        } = (result as any)?.location;
        const cords = [
          [topLeftCorner.x, topLeftCorner.y],
          [topRightCorner.x, topRightCorner.y],
          [bottomRightCorner.x, bottomRightCorner.y],
          [bottomLeftCorner.x, bottomLeftCorner.y],
        ];
        Quagga.ImageDebug.drawPath(cords, { x: 0, y: 1 }, drawingCtx, {
          color: 'white',
          lineWidth: 4,
        });
      } else {
        if (result?.box) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute('width'), 10),
            parseInt(drawingCanvas.getAttribute('height'), 10),
          );
          Quagga.ImageDebug.drawPath(result?.box, { x: 0, y: 1 }, drawingCtx, {
            color: 'white',
            lineWidth: 3,
          });
        }
      }
    }
  }

  private _cleanUpQuagga() {
    Quagga.stop();
  }
}
