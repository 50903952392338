import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as __rxjs from 'rxjs';
//TS2742
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MessagingSettings as MessagingSettingsProto } from 'minga/proto/messaging_settings/messaging_settings_ng_grpc_pb';
import {
  MessagingSettingsGetRoleMessagingRequest,
  MessagingSettingsGetRoleMessagingResponse,
  MessagingSettingsSetRoleMessagingRequest,
  MessagingSettingsSetRoleMessagingResponse,
  RoleTypeListWrapper,
} from 'minga/proto/messaging_settings/messaging_settings_pb';

export interface IMessagingSettingsItem {
  enabled: boolean;
  roleTypeGroups: string[][];
  localeTitle: string;
  immutable: boolean;
}

function protoToInterface(
  msg: MessagingSettingsGetRoleMessagingResponse.Settings,
): IMessagingSettingsItem {
  return {
    enabled: msg.getEnabled(),
    roleTypeGroups: msg.getRoleTypeListList().map(m => m.getRoleTypeList()),
    localeTitle: msg.getLocaleTitleKey(),
    immutable: msg.getImmutable(),
  };
}

function toRoleTypeListWrapper(roleTypes: string[]): RoleTypeListWrapper {
  const wrapper = new RoleTypeListWrapper();
  wrapper.setRoleTypeList(roleTypes);
  return wrapper;
}

function sortRoleGroups(roleGroups: string[][]) {
  return roleGroups.map(roles => _.sortBy(roles));
}

@Injectable({ providedIn: 'root' })
export class MessagingSettingsService {
  /** @internal */
  private readonly _roleSettings = new BehaviorSubject<
    IMessagingSettingsItem[] | null
  >(null);

  readonly roleSettings$: Observable<IMessagingSettingsItem[]>;

  constructor(private messagingSettingsProto: MessagingSettingsProto) {
    this._roleSettings = new BehaviorSubject<IMessagingSettingsItem[] | null>(
      null,
    );
    this.roleSettings$ = this._roleSettings.asObservable().pipe(
      filter(items => !!items),
      map(items => items as IMessagingSettingsItem[]),
    );
  }

  /**
   * Sets the role messaging settings. This updates the underlying observable.
   */
  async setRoleMessagingSettings(roleGroups: string[][], enabled: boolean) {
    const request = new MessagingSettingsSetRoleMessagingRequest();
    request.setRoleTypeListList(roleGroups.map(toRoleTypeListWrapper));
    request.setEnabled(enabled);

    const existingItems = this._roleSettings.getValue();
    if (existingItems) {
      let mutatedArrayItems = false;
      const sortedRoleGroups = sortRoleGroups(roleGroups);
      for (const existingItem of existingItems) {
        const sortedExistingRoleGroups = sortRoleGroups(
          existingItem.roleTypeGroups,
        );
        if (_.isEqual(sortedExistingRoleGroups, sortedRoleGroups)) {
          existingItem.enabled = enabled;
          mutatedArrayItems = true;
        }
      }

      if (mutatedArrayItems) {
        this._roleSettings.next(existingItems);
      }
    }

    await this.messagingSettingsProto.setRoleMessaging(request);
  }

  /**
   * Get an observable to the messaging settings. This also invokes a fetch so
   * the latest settings will be retrieved.
   */
  getRoleMessagingSettings(): Observable<IMessagingSettingsItem[]> {
    this.fetchRoleMessagingSettings();
    return this.roleSettings$;
  }

  /**
   * Fetches the role settings and returns them. This will update the underlying
   * observable.
   * @NOTE `getRoleMessagingSettings()` or `roleSettings$` should be preferred
   *       over the return value of this function. Only use the return if there
   *       is no other option.
   */
  async fetchRoleMessagingSettings(): Promise<IMessagingSettingsItem[]> {
    const request = new MessagingSettingsGetRoleMessagingRequest();
    const response = await this.messagingSettingsProto.getRoleMessaging(
      request,
    );

    const items = response.getSettingList().map(protoToInterface);
    this._roleSettings.next(items);
    return items;
  }
}
