import { NgModule } from '@angular/core';

import { SortHeaderDirective } from './sort-header.directive';

@NgModule({
  imports: [],
  declarations: [SortHeaderDirective],
  exports: [SortHeaderDirective],
})
export class SortHeaderModule {}
