<div
  *ngIf="props$ | async as props"
  class="message-container">
  <ng-container *ngIf="!props.hideAvatar">
    <mg-avatar
      [src]="props.avatarSrc"
      [color]="props.avatarColor"></mg-avatar>
    <div class="avatar-padding"></div>
  </ng-container>

  <div class="mg-smallcopy content-bubble">
    <div
      class="content"
      #contentEl>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="content-bubble-padding"></div>
</div>
