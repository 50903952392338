import { Injectable } from '@angular/core';

import { completeUrl } from 'minga/app/src/app/util/link';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface IMgModalConfig {
  full?: boolean;
  animation?: 'fade';
}

export interface IMgModal {
  close(): void;
  readonly closed: boolean;
}

export interface IModalOpenedEvent {
  modal: IMgModal;
}

@Injectable({ providedIn: 'root' })
export class LinkOpenerService {
  constructor(private _systemAlertSnackBar: SystemAlertSnackBarService) {}

  open(
    link: string,
    target = '_blank',
    systemMobile = true,
    fileLink = false,
  ): void {
    // add http is incomplete url
    link = completeUrl(link);

    if (window.MINGA_APP_BROWSER || !systemMobile) {
      if (target == '_self') {
        window.location.href = link;
      } else {
        window.open(link, target);
      }
    } else if ((<any>window).cordova['InAppBrowser']) {
      let webRef: any;
      if (fileLink) {
        webRef = (<any>window).cordova['InAppBrowser'].open(
          link,
          '_system',
          'location=no,enableViewportScale=yes',
        );
      } else {
        webRef = (<any>window).cordova['InAppBrowser'].open(link, '_system');
      }
      webRef.addEventListener(
        'loaderror',
        this._openUrlErrorCallBack.bind(this),
      );
    }
  }

  openFile(link: string) {
    this.open(encodeURI(link), '_blank', true, true);
  }

  private _openUrlErrorCallBack(webRef: any) {
    let executeScriptCallBack = () => {
      this._systemAlertSnackBar.error(`Sorry we cannot open that page.`);
    };

    let scriptErrorMesssage = "alert('Sorry we cannot open that page.');";

    webRef.executeScript(
      { code: scriptErrorMesssage },
      executeScriptCallBack.bind(this),
    );
    webRef.close();
    webRef = undefined;
  }
}
