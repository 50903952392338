<!-- Instructions -->
<mg-text
  variant="header-xl"
  textAlign="center"
  spacing="1"
  color="surface-alt">
  {{ MSG.CATEGORY_SELECTION_TITLE }}
</mg-text>
<mg-text
  variant="header-xxl-secondary"
  textAlign="center"
  [spacing]="(media.isMobileView$ | async) ? '6' : '8'">
  {{ MSG.CATEGORY_SELECTION_SUBTITLE }}
</mg-text>

<!-- Category selection buttons -->
<div
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="24px"
  fxLayoutGap.lt-md="8px"
  class="category-options">
  <mg-btn
    *ngFor="let category of kiosk.categoryOptions$ | async"
    iconSet="minga"
    [size]="(media.isMobileView$ | async) ? 'large' : 'kiosk'"
    [responsive]="true"
    [id]="'kiosk-category-' + category.value"
    [icon]="category.icon"
    (pressed)="kiosk.setCategory(category.value)">
    {{ category.label }}
  </mg-btn>
</div>
