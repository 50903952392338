import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BmReportsBehaviorsDatasourceService } from '../../services/bm-reports-types-data.service';

@Component({
  selector: 'mg-bm-reports-types',
  templateUrl: './bm-reports-types.component.html',
  styleUrls: ['./bm-reports-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BmReportsBehaviorsDatasourceService],
})
export class BmReportsTypesComponent {
  constructor(public ds: BmReportsBehaviorsDatasourceService) {}
}
