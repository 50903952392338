import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BmReportsConsequenceTypesDatasourceService } from '../../services/bm-reports-cons-types-data.service';

@Component({
  selector: 'mg-bm-reports-cons-types',
  templateUrl: './bm-reports-cons-types.component.html',
  styleUrls: ['./bm-reports-cons-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BmReportsConsequenceTypesDatasourceService],
})
export class BmReportsConsTypesComponent {
  constructor(public ds: BmReportsConsequenceTypesDatasourceService) {}
}
