<div
  *ngIf="group as group"
  fxLayout="row"
  fxLayoutAlign="end center"
  class="preview-actions"
  [ngSwitch]="group.rank">
  <mg-button
    *ngSwitchCase="'PENDING'"
    class="pending mg-minicopy"
    [ngClass]="buttonClass"
    [ngClass.xs]="buttonClass || 'small'"
    type="outline"
    color="light"
    (click)="noClick($event); cancelRequestToJoin.emit(group)"
    >Cancel Request</mg-button
  >
  <ng-container *ngSwitchCase="'OWNER'">
    <mg-button
      class="join mg-minicopy"
      [ngClass]="buttonClass"
      [ngClass.xs]="buttonClass || 'small'"
      type="outline"
      (click)="noClick($event); onleaveGroup(group)"
      >Leave</mg-button
    >
  </ng-container>
  <ng-container *ngSwitchCase="'MEMBER'">
    <mg-button
      class="join mg-minicopy"
      [ngClass]="buttonClass"
      [ngClass.xs]="buttonClass || 'small'"
      type="outline"
      (click)="noClick($event); onleaveGroup(group)"
      >Leave</mg-button
    >
  </ng-container>
  <ng-container *ngSwitchDefault>
    <mg-button
      *ngIf="group.isPrivate"
      class="join private mg-minicopy"
      [ngClass]="buttonClass"
      [ngClass.xs]="buttonClass || 'small'"
      type="outline"
      (click)="noClick($event); requestToJoin.emit(group)"
      >Request to Join</mg-button
    >
    <mg-button
      *ngIf="!group.isPrivate"
      class="join public mg-minicopy"
      [ngClass]="buttonClass"
      [ngClass.xs]="buttonClass || 'small'"
      type="outline"
      (click)="noClick($event); requestToJoin.emit(group)"
      >Join</mg-button
    >
  </ng-container>
  <mg-spinner
    *ngSwitchCase="'LOADING'"
    color="primary"
    thickness="4"
    diameter="25"></mg-spinner>
</div>
