import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { isMentionUiVisible } from 'minga/app/src/app/mentions/components/MentionableUI';
import { linkify } from 'minga/app/src/app/util/linkify';
import { linkifyHtml } from 'minga/app/src/app/util/linkify-html';
import { Observable, Subject } from 'rxjs';

export const MG_TEXTAREA_MAT_FORM_FIELD_CONTROl: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => MgTextareaComponent),
};

type SavedSelection = {
  start: number;
  end: number;
};

/**
 * CSS class used to denote an element as temporary link. Used for display
 * purposes only in the textarea contenteditable.
 */
const TEMP_LINK_CLASS = 'mg-textarea-tmp-link';

const ALLOWED_MAXLENGTH_KEYS = [
  'Backspace',
  'Delete',
  'ArrowRight',
  'ArrowLeft',
  'ArrowUp',
  'ArrowDown',
  'Shift',
  'Control',
];

/**
 * Stolen from:
 * https://stackoverflow.com/questions/13949059/persisting-the-changes-of-range-objects-after-selection-in-html/13950376#13950376
 */
function saveSelection(containerEl: HTMLElement): SavedSelection {
  var range = window.getSelection().getRangeAt(0);
  var preSelectionRange = range.cloneRange();
  preSelectionRange.selectNodeContents(containerEl);
  preSelectionRange.setEnd(range.startContainer, range.startOffset);
  var start = preSelectionRange.toString().length;

  return { start: start, end: start + range.toString().length };
}

/**
 * Stolen from:
 * https://stackoverflow.com/questions/13949059/persisting-the-changes-of-range-objects-after-selection-in-html/13950376#13950376
 */
function restoreSelection(containerEl: HTMLElement, savedSel: SavedSelection) {
  var charIndex = 0,
    range = document.createRange();
  range.setStart(containerEl, 0);
  range.collapse(true);
  var nodeStack = [containerEl],
    node,
    foundStart = false,
    stop = false;

  while (!stop && (node = nodeStack.pop())) {
    if (node.nodeType == 3) {
      var nextCharIndex = charIndex + node.length;
      if (
        !foundStart &&
        savedSel.start >= charIndex &&
        savedSel.start <= nextCharIndex
      ) {
        range.setStart(node, savedSel.start - charIndex);
        foundStart = true;
      }
      if (
        foundStart &&
        savedSel.end >= charIndex &&
        savedSel.end <= nextCharIndex
      ) {
        range.setEnd(node, savedSel.end - charIndex);
        stop = true;
      }
      charIndex = nextCharIndex;
    } else {
      var i = node.childNodes.length;
      while (i--) {
        nodeStack.push(node.childNodes[i]);
      }
    }
  }

  var sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

/**
 * Run linkify on a single node (usually a text node)
 */
function linkifyNode(node: Node) {
  const parentElement = node.parentElement;
  if (!parentElement) {
    return;
  }

  const newValue = linkifyHtml(node.nodeValue || '', {
    attributes: { spellcheck: 'false' },
    className: TEMP_LINK_CLASS,
  });

  if (node.nodeValue !== newValue) {
    const savedSel = saveSelection(parentElement);
    const sib = node.nextSibling;

    var div = document.createElement('div');
    div.innerHTML = newValue;

    Array.from(div.childNodes).forEach(childNode => {
      if (sib) {
        parentElement.insertBefore(childNode, sib);
      } else {
        parentElement.appendChild(childNode);
      }
    });

    parentElement.removeChild(node);
    restoreSelection(parentElement, savedSel);
  }
}

/**
 * Linkify a node or update it if it's already linkified
 * @param oldValue
 *   The previous value of the node before it's change. This is required to
 *   check for trim changes. You can usually optain the old value from a
 *   mutation observer.
 */
function linkifyOrUpdateLinkifiedNode(node: Node, oldValue: string | null) {
  const parentElement = node.parentElement;
  const prevSib: any = node.previousSibling;
  const nextSib: any = node.nextSibling;

  if (!parentElement) {
    return;
  }

  const isParentTmp = parentElement.classList.contains(TEMP_LINK_CLASS);

  const isPrevSibTmp = prevSib?.classList?.contains(TEMP_LINK_CLASS);
  const isNextSibTmp = nextSib?.classList?.contains(TEMP_LINK_CLASS);

  if (isParentTmp) {
    const parentParent = parentElement.parentElement;
    const nodeValue = node.nodeValue || '';

    if (parentParent && !linkify.test(nodeValue)) {
      const changeIsTrim = oldValue !== null && nodeValue.trim() === oldValue;
      const savedSel = saveSelection(parentParent);

      if (changeIsTrim) {
        parentElement.textContent = oldValue;

        const nonWsStart = nodeValue.search(/\S/);
        const nonWsEnd = nodeValue.search(/\s+$/);

        if (nonWsStart > 0) {
          const wsStartTxtNode = document.createTextNode(
            nodeValue.substr(0, nonWsStart),
          );
          parentParent.insertBefore(wsStartTxtNode, parentElement);
        }

        if (nonWsEnd !== -1) {
          const wsEndTxtNode = document.createTextNode(
            nodeValue.substr(nonWsEnd),
          );

          if (parentElement.nextSibling) {
            parentParent.insertBefore(wsEndTxtNode, parentElement.nextSibling);
          } else {
            parentParent.appendChild(wsEndTxtNode);
          }
        }
      } else {
        const txtNode = document.createTextNode(nodeValue);
        parentParent.replaceChild(txtNode, parentElement);
      }

      restoreSelection(parentParent, savedSel);
    }
  } else if (isPrevSibTmp) {
    const txt = prevSib.textContent + node.nodeValue;
    if (linkify.test(txt)) {
      const savedSel = saveSelection(parentElement);
      prevSib.textContent = txt;
      parentElement.removeChild(node);
      restoreSelection(parentElement, savedSel);
    }
  } else if (isNextSibTmp) {
    const txt = node.nodeValue + nextSib.textContent;
    if (linkify.test(txt)) {
      const savedSel = saveSelection(parentElement);
      nextSib.textContent = txt;
      parentElement.removeChild(node);
      restoreSelection(parentElement, savedSel);
    }
  } else {
    linkifyNode(node);
  }
}

@Component({
  providers: [MG_TEXTAREA_MAT_FORM_FIELD_CONTROl],
  selector: 'mg-textarea',
  templateUrl: './Textarea.component.html',
  styleUrls: ['./Textarea.component.scss'],
})
export class MgTextareaComponent
  implements
    OnInit,
    OnDestroy,
    ControlValueAccessor,
    MatFormFieldControl<string>
{
  @ViewChild('textarea', { static: true, read: ElementRef })
  textarea: ElementRef;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this._stateChanges.next();
  }
  private _placeholder: string = '';

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this._stateChanges.next();
  }
  private _required = false;

  @Input()
  allowedTags: string | string[] = [];

  @Input()
  multiline: boolean = true;

  @Input()
  maxlength?: number;

  @Input()
  minHeight: string = 'auto';

  readonly controlType: string = 'textarea';

  @Output('focus')
  focusOutput = new EventEmitter<any>();

  @Output('blur')
  blurOutput = new EventEmitter<any>();

  focused: boolean = false;

  private onChange?: (value: any) => void;
  private onTouched?: () => void;
  private _mutationObserver: MutationObserver;
  private _stateChanges = new Subject<void>();
  private _ngControl: NgControl | null = null;
  private _mutationObserverTimeout: any;

  constructor(
    private _injector: Injector,
    private _focusMonitor: FocusMonitor,
    @Optional() private _matFormField: MatFormField,
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }

    this._mutationObserver = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.type === 'characterData') {
          linkifyOrUpdateLinkifiedNode(mutation.target, mutation.oldValue);
        }
      }

      const checkAndWrite = () => {
        this.checkChanges();
        this.writeChanges();
      };

      // On iOS this is a huge performance hit while typings/backspacing when
      // there is a lot of text.
      if (window.MINGA_DEVICE_IOS) {
        clearTimeout(this._mutationObserverTimeout);
        this._mutationObserverTimeout = setTimeout(() => checkAndWrite(), 300);
      } else {
        checkAndWrite();
      }
    });
  }

  get ngControl(): NgControl | null {
    return this._ngControl || null;
  }

  get value(): string {
    return this.getTextareaContent();
  }

  set value(value: string) {
    this.setTextareaContent(value || '');
  }

  get errorState(): boolean {
    if (!this.ngControl || !this.ngControl.touched) {
      return false;
    }

    if (this.ngControl.invalid) {
      return true;
    }

    if (this.required && this.empty) {
      return true;
    }

    const value = this.value || '';

    if (this.maxlength && value.length > this.maxlength) {
      return true;
    }

    return false;
  }

  get stateChanges(): Observable<void> {
    return this._stateChanges.asObservable();
  }

  get empty(): boolean {
    return this.value.length == 0;
  }

  get id(): string {
    if (this.textarea) {
      const el: HTMLElement = this.textarea.nativeElement;
      return el.id || '';
    }

    return '';
  }

  get shouldLabelFloat(): boolean {
    return !this.empty || this.focused;
  }

  /** Sets the list of element IDs that currently describe this control. */
  setDescribedByIds(ids: string[]): void {}

  /** Handles a click on the control's container. */
  onContainerClick(event: MouseEvent): void {}

  onKeydown(e: KeyboardEvent) {
    if (this.disabled) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      return false;
    }

    if (e.ctrlKey) {
      switch (e.key) {
        case 'b':
        case 'u':
        case 'i':
          e.preventDefault();
          break;
      }
    } else if (this.maxlength && !ALLOWED_MAXLENGTH_KEYS.includes(e.key)) {
      const plainText = this.getTextareaPlainText();

      if (plainText.length >= this.maxlength) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        return false;
      }
    }

    if (e.key === 'Enter') {
      e.preventDefault();

      if (!e.ctrlKey && !isMentionUiVisible() && this.multiline) {
        this.insertNewLineAtSelection();
      }
    }
  }

  onPaste(ev: any) {
    if (
      this.maxlength &&
      this.getTextareaPlainText().length >= this.maxlength
    ) {
      ev.preventDefault();
    }
  }

  insertNewLineAtSelection() {
    const sel = window.getSelection();

    let focusNode = sel.focusNode;

    if (focusNode == this.textarea.nativeElement) {
      focusNode = document.createTextNode(focusNode.textContent);
      this.textarea.nativeElement.appendChild(focusNode);
    }

    let textContent = focusNode.textContent;

    const before = textContent.substr(0, sel.focusOffset);
    const after = textContent.substr(sel.focusOffset);

    const focusNextSib = focusNode.nextSibling;

    if (focusNode.textContent[0] !== '\n') {
      if (!focusNextSib || focusNextSib.nodeType != document.TEXT_NODE) {
        const nlNextSib = document.createTextNode('\n');

        if (focusNextSib) {
          focusNode.parentNode.insertBefore(nlNextSib, focusNextSib);
        } else {
          focusNode.parentNode.appendChild(nlNextSib);
        }
      }
    }

    focusNode.textContent = before + '\n' + after;
    sel.setPosition(focusNode, before.length + 1);
  }

  getAllowedTags(): string[] {
    let allowedTags: string[] = [];

    if (typeof this.allowedTags === 'string') {
      allowedTags = this.allowedTags.split(',');
    } else {
      allowedTags = this.allowedTags;
    }

    return allowedTags.map(a => a.trim().toLowerCase());
  }

  ngOnInit() {
    // This is the alternative to the ControlValueAccessor provider. We cannot
    // provde ControlValueAccessor _and_ inject the `NgControl` while providing
    // the MatFormFieldControl or else we get a cyclic dependency.
    this._ngControl = this._injector.get(NgControl);
    if (this._ngControl != null) {
      this._ngControl.valueAccessor = this;
    }

    const elRef = this.textarea.nativeElement;

    this._mutationObserver.observe(elRef, {
      childList: true,
      subtree: true,
      characterData: true,
      characterDataOldValue: true,
    });

    this._focusMonitor.monitor(elRef, true).subscribe(origin => {
      this.focused = !!origin;
      this._stateChanges.next();
    });
  }

  ngOnDestroy() {
    const elRef = this.textarea.nativeElement;
    this._mutationObserver.disconnect();
    this._focusMonitor.stopMonitoring(elRef.nativeElement);
  }

  private _sanitizeNode(node: Node) {
    const allowedTags = this.getAllowedTags();

    if (node.nodeType === document.ELEMENT_NODE) {
      const element = <Element>node;
      if (!element.classList.contains(TEMP_LINK_CLASS)) {
        const tagName = element.tagName.toLowerCase();
        if (!allowedTags.includes(tagName)) {
          const textNode = document.createTextNode(element.textContent);
          element.parentElement.replaceChild(textNode, element);
        }
      }
    }
  }

  checkChanges() {
    const nativeElement: HTMLElement = this.textarea.nativeElement;
    const childNodes = nativeElement.childNodes;

    for (let i = 0; childNodes.length > i; i++) {
      const child = childNodes[i];

      this._sanitizeNode(child);
    }
  }

  writeChanges() {
    if (this.onTouched) {
      this.onTouched();
    }

    if (this.onChange) {
      this.onChange(this.getTextareaContent());
      this._stateChanges.next();
    }
  }

  getTextareaPlainText() {
    if (!this.textarea) {
      return '';
    }

    return this.textarea.nativeElement.textContent;
  }

  getTextareaContent() {
    if (!this.textarea) {
      return '';
    }

    const clone: HTMLElement = this.textarea.nativeElement.cloneNode(true);

    const tmpEls = clone.querySelectorAll(TEMP_LINK_CLASS);

    tmpEls.forEach(tmpEl => {
      if (tmpEl.parentElement) {
        var txtNode = document.createTextNode(tmpEl.textContent || '');
        tmpEl.parentElement.replaceChild(txtNode, tmpEl);
      }
    });

    return clone.innerHTML;
  }

  setTextareaContent(content: string) {
    const nativeElement: HTMLElement = this.textarea.nativeElement;
    nativeElement.innerHTML = linkifyHtml(content, {
      attributes: { spellcheck: 'false' },
      className: TEMP_LINK_CLASS,
    });
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (typeof value === 'string') {
      this.setTextareaContent(value);
    } else if (value === null) {
      this.setTextareaContent('');
    }
  }

  focus() {
    const element = this.textarea.nativeElement;
    element.focus();

    if (element.innerText.length) {
      let sel = window.getSelection();
      sel.collapse(element.lastChild, element.lastChild.textContent.length);
    }
  }
}
