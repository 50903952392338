import { Pipe, PipeTransform } from '@angular/core';

import { FlexTimeActivityInstance } from 'minga/domain/flexTime';

import { FlexTimePeriodTile } from '../constants';

@Pipe({ name: 'getStudentRegistered' })
export class GetStudentRegisteredPipe implements PipeTransform {
  transform(
    data: FlexTimePeriodTile | FlexTimeActivityInstance,
    type: 'period' | 'activity',
  ): string {
    if (type === 'period') {
      return (data as FlexTimePeriodTile).count.toString() || '0';
    } else {
      return (data as FlexTimeActivityInstance).registered.toString() || '0';
    }
  }
}
