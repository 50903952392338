import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { MingaGalleryService } from 'minga/app/src/app/routes/gallery/services/MingaGallery';
import { MingaSettingsService } from 'src/app/store/Minga/services';

@Component({
  selector: 'mg-top-of-feed',
  templateUrl: './TopOfFeed.component.html',
  styleUrls: ['./TopOfFeed.component.scss'],
})
export class TopOfFeedComponent implements OnInit, OnDestroy {
  enableGallery: boolean = false;

  now = Date.now();

  private _mingaSubscription: Subscription = new Subscription();

  constructor(
    private _cdr: ChangeDetectorRef,
    public mingaGalleryService: MingaGalleryService,
    public mingaSettings: MingaSettingsService,
  ) {}

  async ngOnInit(forceUpdate: boolean = false) {
    this._cdr.markForCheck();
  }

  ngOnDestroy() {
    this._mingaSubscription.unsubscribe();
  }
}
