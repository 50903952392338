import * as webfontloader from 'webfontloader';

let fontsLoadedTracker: {
  [keyname: string]: Promise<true> | true | undefined;
} = {};

export function fontFamilyLoaded(fontFamily: string): boolean {
  return fontsLoadedTracker[fontFamily] === true;
}

export function fontFamiliesLoaded(fontFamilies: string[]): boolean {
  for (const fontFamily of fontFamilies) {
    if (!fontFamilyLoaded(fontFamily)) {
      return false;
    }
  }

  return true;
}

/**
 * Load font family. Resolves `false` if fontFamily is invalid `true`
 * otherwise. If the font family fails to load the promise is rejected
 */
export async function loadFontFamily(fontFamily: string): Promise<boolean> {
  if (!fontFamily) {
    return false;
  }

  if (fontsLoadedTracker[fontFamily]) {
    return fontsLoadedTracker[fontFamily]!;
  }

  const promise = new Promise<true>((resolve, reject) => {
    webfontloader.load({
      google: {
        families: [fontFamily],
      },
      active: () => {
        resolve(true);
        fontsLoadedTracker[fontFamily] = true;
      },
      inactive: () => {
        delete fontsLoadedTracker[fontFamily];
        console.warn('Could not load font: ' + fontFamily);
        reject();
      },
      fontactive: () => {
        resolve(true);
        fontsLoadedTracker[fontFamily] = true;
      },
      fontinactive: () => {
        delete fontsLoadedTracker[fontFamily];
        console.warn('Could not load font: ' + fontFamily);
        reject();
      },
    });
  });

  fontsLoadedTracker[fontFamily] = promise;

  return promise;
}
