import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { defaultIfEmpty, filter, map, scan } from 'rxjs/operators';

import {
  IConversationWithReadStatus,
  IMessage,
  IMessageAttachment,
} from 'minga/domain/messaging';
import { Messaging } from 'minga/proto/messaging/messaging_ng_grpc_pb';
import {
  MessagingCheckUnreadRequest,
  MessagingGetConversationRequest,
  MessagingGetConversationsRequest,
  MessagingGetMessagesRequest,
  MessagingMarkAsReadRequest,
  MessagingSendMessageRequest,
} from 'minga/proto/messaging/messaging_pb';
import {
  ConversationPreviewMapper,
  MessageMapper,
} from 'minga/shared-grpc/messaging';

export interface ISendMessagePayload {
  messageId: number;
  attachmentList: IMessageAttachment[];
}

@Injectable({ providedIn: 'root' })
export class MessagingService {
  constructor(private messagingProto: Messaging) {}

  /**
   * Sends messsage
   * @param conversationId
   * @param bodyText
   * @returns new message id
   */
  async sendMessage(
    conversationId: number,
    bodyText: string,
    assetPathList: string[],
  ): Promise<ISendMessagePayload> {
    const request = new MessagingSendMessageRequest();
    request.setConversationId(conversationId);
    request.setMessageBody(bodyText);
    request.setAssetPathList(assetPathList);
    const response = await this.messagingProto.sendMessage(request);

    return {
      messageId: response.getNewMessageId(),
      attachmentList:
        MessageMapper.fromProtoMessageAttachmentToIMessageAttachment(
          response.getAttachmentList(),
        ),
    };
  }

  /**
   * Mark messages as read.
   */
  async markMessagesAsRead(messageIds: number[]): Promise<void> {
    await this.markAsRead({ messageIds, conversationIds: [] });
  }

  /**
   * Mark conversations as read.
   */
  async markConversationsAsRead(conversationIds: number[]): Promise<void> {
    await this.markAsRead({ conversationIds, messageIds: [] });
  }

  private async markAsRead(options: {
    messageIds: number[];
    conversationIds: number[];
  }) {
    const request = new MessagingMarkAsReadRequest();
    request.setConversationIdList(options.conversationIds);
    request.setMessageIdList(options.messageIds);
    await this.messagingProto.markAsRead(request);
  }

  getMessages(conversationId: number): Observable<IMessage[]> {
    const request = new MessagingGetMessagesRequest();
    request.setConversationId(conversationId);

    const stream = this.messagingProto.getMessages(request);

    return stream.pipe(
      map(resp => MessageMapper.toIMessage(resp.getMessage())),
      scan((all, current) => [...all, current], [] as IMessage[]),
      defaultIfEmpty([] as IMessage[]),
    );
  }

  async getConversation(
    conversationId: number,
  ): Promise<IConversationWithReadStatus> {
    const request = new MessagingGetConversationRequest();
    request.setConversationId(conversationId);
    const response = await this.messagingProto.getConversation(request);
    return ConversationPreviewMapper.toIConversationWithReadStatus(
      response.getConversation(),
    );
  }

  getConversations(): Observable<IConversationWithReadStatus[]> {
    const request = new MessagingGetConversationsRequest();
    const stream = this.messagingProto.getConversations(request);

    return stream.pipe(
      map(resp => resp.getConversation()),
      filter(convMsg => !!convMsg),
      map(convMsg =>
        ConversationPreviewMapper.toIConversationWithReadStatus(convMsg),
      ),
      scan(
        (all, current) => [...all, current],
        [] as IConversationWithReadStatus[],
      ),
      defaultIfEmpty([] as IConversationWithReadStatus[]),
    );
  }

  getAllConversations(): Observable<IConversationWithReadStatus[]> {
    const request = new MessagingGetConversationRequest();
    const stream = this.messagingProto.getAllConversations(request);

    return stream.pipe(
      map(resp => resp.getConversation()),
      filter(convMsg => !!convMsg),
      map(convMsg =>
        ConversationPreviewMapper.toIConversationWithReadStatus(convMsg),
      ),
      scan(
        (all, current) => [...all, current],
        [] as IConversationWithReadStatus[],
      ),
      defaultIfEmpty([] as IConversationWithReadStatus[]),
    );
  }

  async checkForUnread() {
    const request = new MessagingCheckUnreadRequest();
  }
}
