import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgeIconModule } from 'minga/app/src/app/elements/MgeIcon';

import { MgDetailedModerationItemElement } from './MgDetailedModerationItem.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgeIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgDetailedModerationItemElement],
  exports: [MgDetailedModerationItemElement],
})
export class MgDetailedModerationItemModule {}
