import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PointsDialogService } from 'minga/app/src/app/dialog/PointsDialog/PointsDialog.service';

import { PointsDialog } from './PointsDialog.component';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    CommonModule,
    MatDialogModule,
  ],

  declarations: [PointsDialog],
  exports: [PointsDialog],
  providers: [PointsDialogService],
})
export class PointsDialogModule {}
