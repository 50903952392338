<mg-stream-scroller
  *ngIf="query"
  [filter]="streamFilter"
  [service]="searchManager"
  rpcStream="search"
  rpcControl="searchControl">
  <ng-template
    let-item
    let-itemId="itemId"
    mgStreamItem>
    <mg-content-feed-item
      [markKeywords]="query"
      [context]="itemId"
      [content]="item.feedItem"></mg-content-feed-item>
  </ng-template>

  <p
    mg-stream-empty
    class="mg-centered">
    No results
  </p>
  <p
    mg-stream-front-exhausted
    class="mg-centered">
    End of results
  </p>
</mg-stream-scroller>
