<!-- Portal layout search section content -->
<mg-portal-layout-search>
  <!-- Begin search text -->
  <mg-text
    *ngIf="!(globalSearch.value$ | async).length; else searchResultsTemplate"
    textAlign="center">
    {{ MSG.BEGIN_SEARCH }}
  </mg-text>

  <!-- Search results template -->
  <ng-template #searchResultsTemplate>
    <!-- No results -->
    <ng-container
      *ngIf="
        (filteredConversations$ | async).length === 0;
        else filteredConversationsTemplate
      ">
      <mg-text textAlign="center">
        No results for '{{ globalSearch.value$ | async }}'
      </mg-text>
    </ng-container>

    <!-- Results -->
    <ng-template #filteredConversationsTemplate>
      <virtual-scroller
        #virtualScroller
        [items]="filteredConversations$ | async"
        [bufferAmount]="5"
        [parentScroll]="virtualScroller.window">
        <div #container>
          <ng-template
            ngFor
            let-item
            [ngForOf]="virtualScroller.viewPortItems"
            [ngForTrackBy]="trackByFn">
            <mg-dm-list-item
              [data]="item"
              [readStatus]="item.readStatus"
              (click)="onConversationClick(item.id)">
            </mg-dm-list-item>
          </ng-template>
        </div>
      </virtual-scroller>
    </ng-template>
  </ng-template>
</mg-portal-layout-search>

<!-- Main content -->
<mg-layout-legacy-content>
  <ng-container *ngIf="showDirectMessaging$ | async; else messagingDisabled">
    <ng-template #action>
      <div
        class="mg-headline home-top"
        fxLayout="row"
        fxLayoutGap="8px"
        fxLayoutAlign="start center">
        <div fxFlex="auto"></div>
        <mg-btn
          variant="filled"
          (click)="newConversationClick()">
          New conversation
        </mg-btn>
      </div>
    </ng-template>

    <mg-dm-list-feed
      (clickConversationPreview)="onClickConversationPreview($event)">
    </mg-dm-list-feed>
  </ng-container>

  <ng-template #messagingDisabled>
    <p class="empty-state">
      Sorry, you do not have direct messaging enabled in your Minga.
    </p>
  </ng-template>
</mg-layout-legacy-content>
