export enum PmTeamsMessages {
  COLUMN_LABEL_ASSET = '',
  COLUMN_LABEL_NAME = 'Name',
  COLUMN_LABEL_MEMBERS = 'Members',
  COLUMN_LABEL_STATUS = 'Status',
  COLUMN_LABEL_EDIT = 'Edit',

  SECTION_LABEL_TEAM_POINTS = 'Points for Teams',
  SECTION_LABEL_INDIVIDUAL_POINTS = 'Points for Individuals',

  BUTTON_LABEL_NEW_TEAM = 'Create school team',
  BUTTON_LABEL_ADD = 'Add',
  BUTTON_LABEL_REMOVE = 'Remove',

  EMPTY_STATE_TITLE = 'No results found',
  EMPTY_STATE_SUBTITLE = "You haven't created any school teams yet",
}

export const PM_TEAMS_TABLE_COLUMNS: string[] = [
  'assetPath',
  'name',
  'members',
  'active',
  'edit',
];

export const DEFAULT_TEAM_COLORS = [
  '#85649e', // purple
  '#78efd9', // light green
  '#e67aa0', // pink
  '#d6e261', // yellow
  '#e2ab56', // orange
  '#1d47b9', // blue
  '#91bcad', // green
  '#db6578', // red
  '#1d9fb9', // teal
  '#daac64', // gold
  '#4ba0cb', // light blue
  '#003366', // navy
  '#2b55c3', // dark blue
  '#6FEF99', // lime
];
