import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MgButtonModule } from 'src/app/button';
import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgIconModule } from 'src/app/icon';
import { PermissionsModule } from 'src/app/permissions';
import { MgSpinnerModule } from 'src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { MembershipListTableModule } from '@shared/components/membership-list-table';
import { SelectFieldsModule } from '@shared/components/select-fields';
import { SettingsModule } from '@shared/components/settings/settings.module';
import { TextModule } from '@shared/components/text/text.module';
import { LoadingPipesModule } from '@shared/pipes';

import { HpmSettingsComponent } from './hpm-settings.component';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    GenericButtonModule,
    MgButtonModule,
    MgIconModule,
    PermissionsModule,
    MgEmptyStateModule,
    SettingsModule,
    SelectFieldsModule,
    MgSpinnerModule,
    LoadingPipesModule,
    MembershipListTableModule,
    TextModule,

    // External dependencies
    CommonModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
  ],
  exports: [HpmSettingsComponent],
  declarations: [HpmSettingsComponent],
})
export class HpmSettingsModule {}
