import { TemplatePortal } from '@angular/cdk/portal';
import {
  Injectable,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { KioskLayoutPortalSection } from '../constants';

@Injectable({ providedIn: 'root' })
export class KioskLayoutService implements OnDestroy {
  // State

  private readonly _layoutSectionContentMapSubject = new BehaviorSubject<
    Map<KioskLayoutPortalSection, TemplateRef<unknown>>
  >(new Map());

  private readonly _layoutSectionContentMap$ =
    this._layoutSectionContentMapSubject.asObservable();

  private readonly _footerContentSubject =
    new BehaviorSubject<TemplateRef<unknown> | null>(null);
  public readonly footerContent$ = this._footerContentSubject.asObservable();

  // Service constructor

  constructor() {}

  // Lifecycle
  ngOnDestroy(): void {
    this._layoutSectionContentMapSubject.complete();
    this._footerContentSubject.complete();
  }

  // Public methods
  public observeTemplate$(
    section: KioskLayoutPortalSection,
    viewContainer: ViewContainerRef,
  ) {
    return this._layoutSectionContentMap$.pipe(
      map(m => {
        const content = m?.get(section);
        if (!content) return;
        return new TemplatePortal(content, viewContainer);
      }),
    );
  }

  public setContent(
    section: KioskLayoutPortalSection,
    template: TemplateRef<unknown>,
  ) {
    try {
      if (section === KioskLayoutPortalSection.FOOTER) {
        this._footerContentSubject.next(template);
      } else throw new Error('Invalid section');
    } catch (error) {
      console.warn('Failed to set section content', error);
    }
  }

  public clearSectionContent(section: KioskLayoutPortalSection) {
    try {
      if (section === KioskLayoutPortalSection.FOOTER) {
        this._footerContentSubject.next(null);
      } else throw new Error('Invalid section');
    } catch (error) {
      console.warn('Failed to clear section content', error);
    }
  }
}
