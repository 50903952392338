import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PermissionsService } from 'minga/app/src/app/permissions';

@Component({
  selector: 'mg-dialog-welcome',
  templateUrl: './DistrictSchoolSelectDialog.component.html',
  styleUrls: ['./DistrictSchoolSelectDialog.component.scss'],
})
export class DistrictSchoolSelectDialog {
  @HostBinding('class.ios-browser')
  readonly iosBrowser = window.MINGA_DEVICE_IOS && window.MINGA_APP_BROWSER;

  constructor(
    private dialogRef: MatDialogRef<DistrictSchoolSelectDialog>,
    private permissions: PermissionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.close(true);
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  close(save: boolean = true) {
    // Delay on purpose to feel like you hit the correct option
    setTimeout(() => {
      this.dialogRef.close(save);
    }, 300);
  }
}
