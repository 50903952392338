<mg-short-card
  *ngIf="content as content"
  [featuredImage]="content.featuredImage"
  [videoUrl]="content.videoUrl"
  [authorPersonView]="content.authorPersonView"
  [timestamp]="content.timestamp"
  [title]="content.title"
  [body]="content.body | ugcLinksHighlight"
  [likeCount]="content.likeCount"
  [ownerGroupHash]="content.ownerGroupHash"
  [commentList]="content.commentList"
  [commentCount]="content.commentCount"
  [totalCommentCount]="content.totalCommentCount"
  [pinned]="pinned"
  [markKeywords]="markKeywords"
  [imageCount]="content.imageCount"
  [commentRoles]="commentRoles"
  [context]="context"
  [content]="content">
  <mg-short-card-featured-image *ngIf="designedSvg">
    <mg-logo-anchored
      [anchor]="content.logoAnchor"
      [customLogo]="content.customLogoImage || ''"></mg-logo-anchored>

    <mg-designed-svg-viewer
      [predictedViewboxWidth]="1"
      [predictedViewboxHeight]="1"
      [designedSvgSrc]="designedSvg.svgUrl"
      [designedSvgData]="designedSvg.svgData"
      [designedSvgManipulations]="designedSvg.svgManipulations">
    </mg-designed-svg-viewer>
  </mg-short-card-featured-image>
</mg-short-card>
