import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { MingaPermission, mingaSettingTypes } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { PsTableMessages } from '@modules/people-selector/constants';
import { PeopleSelectorFormService } from '@modules/people-selector/services';

import { PaginatorComponent } from '@shared/components/paginator';
import { MediaBreakpoints, MediaService } from '@shared/services/media';

import { PsData } from '../../types';

@Component({
  selector: 'mg-ps-table',
  templateUrl: './ps-table.component.html',
  styleUrls: ['./ps-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsTableComponent implements AfterViewInit, OnDestroy, OnInit {
  /** Child Components */
  @ViewChild(MatTable) matTable: MatTable<PsData>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  /** Constants */
  public readonly MSG = PsTableMessages;

  /** General Subjects */
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  /** Show Student ID */
  public readonly showStudentId$ = combineLatest([
    this._permissions.observePermission(MingaPermission.SUPERADMIN),
    this._settingService.getSettingValueObs(mingaSettingTypes.HIDE_ID_NUM),
    this._permissions.observePermission(MingaPermission.ADMINISTER_STUDENT_ID),
    this._permissions.observePermission(
      MingaPermission.VIEW_STUDENT_ID_DASHBOARD,
    ),
  ]).pipe(
    takeUntil(this._destroyedSubj),
    map(([isAdmin, hideStudentId, adminPermission, studentPermission]) => {
      if (isAdmin) return true;
      if (hideStudentId) return false;
      return adminPermission || studentPermission;
    }),
  );

  /** Selection */
  public masterToggleCheckedState$: Observable<boolean>;
  public canMakeMoreSelections$: Observable<boolean>;

  /** Displayed Columns */
  public displayedColumns$: Observable<string[]>;

  /** Inputs */
  @Input() form: PeopleSelectorFormService;

  /** Component Constructor */
  constructor(
    public media: MediaService,
    private _cdr: ChangeDetectorRef,
    private _permissions: PermissionsService,
    private _settingService: MingaSettingsService,
  ) {}

  ngOnInit(): void {
    if (!this.form) return;
    this.masterToggleCheckedState$ = this.form.selection.size$.pipe(
      map(selectionSize => {
        return !selectionSize
          ? false
          : selectionSize === this.form.searchResults.size;
      }),
    );
    this.displayedColumns$ = this._getDisplayedColumnsStream();
    this.canMakeMoreSelections$ = this._canSelectMoreStream();
  }

  ngAfterViewInit(): void {
    this._pageSwitchSub();
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
  }

  public selectAll(): void {
    const source =
      this.form.searchResults.dataSource.filteredData ||
      this.form.searchResults.dataSource.data;
    this.form.selection.select(source);
  }

  public isSelected(person: PsData): boolean {
    return this.form.selection.isSelected(person);
  }

  public masterToggle(): void {
    const selectionSize = this.form.selection.getCurrentSize();
    if (this.form.searchResults.size > selectionSize) this.selectAll();
    else this.form.selection.clear();
  }

  public toggleSelection(
    tableType: 'selected' | 'searchResults',
    person: PsData,
  ) {
    this.form.selection.toggle(person);
    this._cdr.markForCheck();
  }

  public trackById(index: number, item: PsData) {
    return item ? item.personHash : index;
  }

  public isAllSelected() {
    const source = this.form.searchResults.dataSource.data;
    const selectionSize = this.form.selection.getCurrentSize();

    return selectionSize > 0 && selectionSize < source.length;
  }

  private _getDisplayedColumnsStream() {
    return combineLatest([
      this.media.breakpoint$,
      this.showStudentId$,
      this.form.searchResults.availableColumn$,
    ]).pipe(
      takeUntil(this._destroyedSubj),
      map(([breakpoint, showStudentId, availableColumns]) => {
        const showMobileViewOn: MediaBreakpoints[] = ['xsmall', 'small'];
        if (showMobileViewOn.includes(breakpoint)) return ['select', 'mobile'];
        const displayedColumns = ['select', 'displayName'];
        if (showStudentId) displayedColumns.push('studentId');
        displayedColumns.push(...availableColumns);
        return displayedColumns;
      }),
    );
  }

  private async _pageSwitchSub() {
    return this.form.pages.activePath$
      .pipe(
        switchMap(() => this.form.pages.activePageType$),
        takeUntil(this._destroyedSubj),
      )
      .subscribe(async type => {
        if (type === 'list') await this.form.search();
        this.form.searchResults.dataSource.paginator =
          this.paginator.matPaginator;
        this.form.searchResults.dataSource.sort = this.sort;
        this._cdr.markForCheck();
      });
  }

  private _canSelectMoreStream() {
    return combineLatest([
      this.form.pages.activePage$,
      this.form.selection.selection$,
    ]).pipe(
      map(([{ maxSelection }, selection]) => {
        const selectionSize = selection.length;
        return maxSelection ? selectionSize < maxSelection : true;
      }),
    );
  }
}
