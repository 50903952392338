<!-- Filters -->
<section class="filters">
  <div
    *ngIf="!defaultValue?.periodId"
    class="filters-period filter">
    <mg-form-select
      *ngIf="periodOptions$ | async"
      [placeholder]="MESSAGES.PERIOD_FILTER_LABEL"
      [floatingLabel]="true"
      [value]="defaultValue?.periodId || null"
      [disabled]="!!defaultValue?.periodId"
      [options]="periodOptions$ | async"
      [control]="periodFilter">
    </mg-form-select>
  </div>

  <div
    *ngIf="!defaultValue?.teacher?.hash"
    class="filters-teacher filter">
    <mg-form-select
      *ngIf="teacherOptions$ | async"
      [placeholder]="MESSAGES.TEACHER_FILTER_LABEL"
      labelBackground="grey"
      [isClearable]="!defaultValue?.teacher?.hash"
      [searchable]="true"
      [floatingLabel]="true"
      [value]="defaultValue?.teacher?.hash || null"
      [disabled]="!!defaultValue?.teacher?.hash"
      [options]="teacherOptions$ | async"
      [control]="teacherFilter">
    </mg-form-select>
  </div>
  <div class="filters-text filter">
    <mg-form-text-input
      [label]="MESSAGES.TEXT_FILTER_LABEL"
      labelBackground="grey"
      [isClearable]="true"
      [control]="searchFilter">
    </mg-form-text-input>
  </div>
</section>

<!-- Activity Cards -->
<section
  *ngIf="activities?.length > 0"
  class="activities"
  fxLayout="column"
  fxLayoutGap="10px">
  <button
    matRipple
    *ngFor="let activity of activities"
    (click)="select(activity)"
    class="activities-select"
    type="button"
    [ngClass]="{ active: selectedId === activity.id }">
    <mg-ftm-activity-card
      [activity]="activity"
      [visibleFields]="activityCardFields">
    </mg-ftm-activity-card>
  </button>
</section>

<!-- Empty State -->
<div
  *ngIf="noResults && activities?.length === 0"
  class="no-results">
  <ng-container *ngTemplateOutlet="noResults"></ng-container>
</div>
