export enum KioskCheckInMessage {
  TITLE_PASS_CREATED = 'Hall pass created',
  FLEX_PERIOD_LABEL = 'FlexTime period',

  SELECTOR_TITLE = 'Select available check ins',
  SELECTOR_CHECK_IN_EMPTY_STATE_TITLE = 'No check in reasons available',
  SELECTOR_CHECK_IN_EMPTY_STATE_SUB = 'Enable kiosk ready passes from the check in reasons page',
  SELECTOR_FLEXTIME_EMPTY_STATE_TITLE = 'No activities available',
  SELECTOR_FLEXTIME_EMPTY_STATE_SUB = 'Activities designated for kiosk use will be visible here',
  SELECTOR_SUBMIT = 'Enter kiosk mode',

  TYPE_SELECTOR_TITLE = 'Select a check in',
  TYPE_SELECTOR_EMPTY_STATE_TITLE = 'No check in reasons available',
  TYPE_SELECTOR_EMPTY_STATE_SUB = 'Contact your school administrator',
  TYPE_SELECTOR_SUBMIT = 'Create check in',

  TAB_CHECKIN = 'Check in',
  TAB_FLEX = 'Flex activity',

  FLEX_ACTIVITY_LABEL = 'Activity filter',
  FLEX_ACTIVITY_MY_ACTIVITIES = 'My activities',
  FLEX_ACTIVITY_ALL_ACTIVITIES = 'All activities',
}
