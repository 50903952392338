<!-- Flex column wrapper -->
<div
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="12px">
  <!-- Selection title -->
  <mg-card
    class="card-container"
    paddingX="8">
    <!-- Single option  -->
    <div
      *ngIf="types?.length === 1; else multiSelectionHeaderTemplate"
      fxLayout="column"
      fxLayoutAlign="center center">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="12px"
        class="single-type-title">
        <mg-icon
          [style.color]="types[0].color"
          [icon-namespace]="types[0]?.iconNamespace">
          {{ types[0]?.icon ? types[0].icon : typeIcon }}
        </mg-icon>
        <mg-text variant="header-lg">
          {{ types[0]?.name || 'Type name' }}
        </mg-text>
      </div>

      <!-- Title -->
      <mg-text
        variant="header-xxl-secondary"
        textAlign="center"
        color="surface-alt">
        {{ cardTitle }}
      </mg-text>
    </div>
  </mg-card>

  <!-- Title -->
  <mg-text
    variant="header-xxl-secondary"
    textAlign="center"
    spacing="3">
    {{ title }}
  </mg-text>

  <div class="form-container">
    <!-- Text input -->
    <mg-form-text-input
      label="ID number"
      widthSize="large"
      inputMode="numeric"
      [setFocus]="focusSubject"
      [isClearable]="false"
      [control]="inputField"
      [id]="'kiosk-id-scan-text-field'"
      (returnPressed)="submitForm()">
    </mg-form-text-input>

    <!-- Submit button -->
    <mg-btn
      iconSet="minga"
      [size]="(media.isMobileView$ | async) ? 'large' : 'kiosk'"
      [wide]="true"
      [id]="'kiosk-id-scan-submit'"
      [disabled]="disableSubmit || (canSubmit$ | async) === false"
      (pressed)="submitForm()">
      {{ enterLabel }}
    </mg-btn>
  </div>
</div>

<!-- Projected content -->
<ng-content></ng-content>

<!-- Multi selection header template -->
<ng-template #multiSelectionHeaderTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="8px">
    <mg-icon
      class="multi-select-icon"
      [iconName]="typeIcon">
    </mg-icon>

    <!-- Title -->
    <div>
      <mg-text
        variant="header-xxl-secondary"
        textAlign="center"
        color="surface-alt">
        {{ cardTitle }}
      </mg-text>
    </div>
  </div>
</ng-template>
