import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgIconModule } from 'src/app/icon';

import { ManagerOverlayModule } from '@shared/components/manager-overlay';

import { FlexTimeManagerComponent } from './flex-time-manager.component';
import { FlexAdmin } from './guards/FlexAdmin.guard';
import { FlexReporting } from './guards/FlexReporting.guard';
import { FlexSelfManageOrHigher } from './guards/FlexSelfManageOrHigher.guard';
import { FlexTimeManagerService } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    ManagerOverlayModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [FlexTimeManagerComponent],
  exports: [FlexTimeManagerComponent],
  providers: [
    FlexTimeManagerService,
    FlexAdmin,
    FlexSelfManageOrHigher,
    FlexReporting,
  ],
})
export class FlexTimeManagerModule {}
