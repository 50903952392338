<mg-button-icon
  (click)="close()"
  class="top left check-in"
  icon="close"></mg-button-icon>

<p class="title-text top-title">
  Enter the event code to check in to the
  <b [innerHTML]="eventTitle | mgSafeHtml"></b> event.
</p>

<div
  class="code-input"
  [ngSwitch]="isIOS">
  <input
    *ngSwitchCase="false"
    #codeInput
    mgAutofocus
    class="post-step-spacing"
    name="code"
    autocomplete="off"
    [class.incorrect]="showErrorMessage"
    type="text"
    [(ngModel)]="eventCode"
    autocapitalize="none"
    (keydown)="onKeydown($event)"
    placeholder="Event Code" />
  <input
    *ngSwitchDefault
    #codeInput
    class="post-step-spacing"
    name="code"
    autocomplete="off"
    [class.incorrect]="showErrorMessage"
    type="text"
    [(ngModel)]="eventCode"
    autocapitalize="none"
    (keydown)="onKeydown($event)"
    placeholder="Event Code" />
</div>

<p
  class="error-message mg-minicopy"
  [class.show]="showErrorMessage">
  The code you entered is incorrect. Please try again.
</p>

<div fxLayout="row">
  <button
    mgBubbleBtn
    (click)="submit()"
    fxFlex
    [disabled]="!eventCode || checkingCode">
    <span
      *ngIf="checkingCode"
      class="checking-text">
      Checking
      <span class="checking-loader"></span>
    </span>
    <span *ngIf="!checkingCode">Check In</span>
  </button>
</div>

<ng-template #successOverlayTemplate>
  <div class="success-overlay">
    <div class="checkmark-wrap">
      <mg-success-checkmark></mg-success-checkmark>
      <div class="mg-bodycopy">Checked In!</div>
    </div>
  </div>
</ng-template>
