<div
  class="content-block comment-list"
  id="comments"
  #commentListEl
  [ngClass]="{ mobile: mqAlias == 'xs' }"
  *ngIf="commentCount > 0">
  <div
    class="view-more-comments"
    *ngIf="comments.length > 0 && commentCount <= comments.length">
    <a
      href="javascript:;"
      class="disabled">
      Comments
    </a>
  </div>
  <div
    class="view-more-comments"
    *ngIf="comments.length > 0 && remainingCommentCount > 0">
    <a
      href="javascript:;"
      (click)="nextComments()"
      [class.disabled]="loadingComments">
      View {{ remainingCommentCount }} previous
      {{ remainingCommentCount > 1 ? 'comments' : 'comment' }}
      <mg-spinner
        *ngIf="loadingComments"
        [diameter]="12"
        [thickness]="2"></mg-spinner>
    </a>
  </div>
  <mg-comment-view
    *ngFor="let comment of comments; trackBy: _trackByHash"
    [hideReply]="!('CONTENT_COMMENT_CREATE' | hasPermission) || !canComment"
    [ngClass]="'anim anim-' + (comment.anim || 'none')"
    (onReplyClick)="replyClick($event)"
    [parentContextHash]="contextHash"
    [disableActions]="disableActions"
    (onCommentDeleted)="_onContentDeleted($event)"
    [commentList]="comment.obj.commentList"
    [comment]="comment.obj"></mg-comment-view>
</div>

<ng-container *hasPermission="'CONTENT_COMMENT_CREATE'">
  <ng-container *ngIf="!disableActions && contextHash && canComment">
    <ng-container *ngIf="useFixedCommentInput">
      <div
        *mgFixed="'bottom'"
        class="comment-input-fixed">
        <div
          class="content-block comment-input-wrap"
          *ngIf="mqAlias == 'xs' && replyHash">
          <mg-comment-input
            #commentInputMobileEl
            [multiline]="true"
            [contentContext]="replyHash"
            [parentContext]="replyParent || contextHash"
            [placeholder]="replyPlaceholder"
            (onClearComment)="cancelComment($event)"
            [buttonText]="replyButtonText"
            [commentPrefix]="commentPrefix"></mg-comment-input>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!useFixedCommentInput">
      <div class="comment-input-fixed">
        <div
          class="content-block comment-input-wrap"
          *ngIf="mqAlias == 'xs' && replyHash">
          <mg-comment-input
            #commentInputMobileEl
            [multiline]="true"
            [contentContext]="replyHash"
            [parentContext]="replyParent || contextHash"
            [placeholder]="replyPlaceholder"
            (onClearComment)="cancelComment($event)"
            [buttonText]="replyButtonText"
            [commentPrefix]="commentPrefix"></mg-comment-input>
        </div>
      </div>
    </ng-container>

    <div [ngClass.xs]="'comment-input-hidden'">
      <div
        class="content-block comment-input-wrap"
        *ngIf="replyHash">
        <mg-comment-input
          #commentInputDesktopEl
          [multiline]="true"
          [contentContext]="replyHash"
          [parentContext]="replyParent || contextHash"
          [placeholder]="replyPlaceholder"
          (onClearComment)="cancelComment($event)"
          [buttonText]="replyButtonText"
          [commentPrefix]="commentPrefix"></mg-comment-input>
      </div>
    </div>
  </ng-container>
</ng-container>
