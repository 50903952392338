import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as hall_pass_pb from 'minga/proto/hall_pass/hall_pass_pb';
import { IHallPassType } from 'minga/domain/hallPass';
import { HallPassManager } from 'minga/proto/hall_pass/hall_pass_ng_grpc_pb';
import { HallPassTypeMapper } from 'minga/shared-grpc/hall_pass';
import { HallPassService } from 'src/app/services/HallPass';

import {
  BackDropColor,
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import { HpmTypesEditComponent } from '../hpm-types-edit';
import { newTypeRow } from './hpm-types-table.animations';
import {
  HP_TYPES_TABLE_COLUMNS,
  HpmTypesTableMessages,
} from './hpm-types-table.contants';

/**
 * Hall Pass Manager Types Table
 *
 * Uses v9 angular material table and sort
 *
 * @link https://v9.material.angular.io/components/table/overview
 * @link https://v9.material.angular.io/components/sort/overview
 */
@Component({
  selector: 'mg-hpm-types-table',
  templateUrl: './hpm-types-table.component.html',
  styleUrls: ['./hpm-types-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [newTypeRow],
})
export class HpmTypesTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /** Mat Table Component */
  @ViewChild(MatTable)
  table: MatTable<any>;

  /** Mat Sort Component */
  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  /** Messages */
  public readonly MESSAGES = HpmTypesTableMessages;

  /** I don't know */
  readonly bannerImageSizes: ReadonlyArray<string | string[]> = [
    'blurloading1',
    ['longcardbanner', 'cardbanner', 'banner'],
  ];

  /** Loading State */
  private readonly _isLoadingSubject = new BehaviorSubject(false);
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  /** Subscription Cleanup Helper */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Displayed Columns */
  public DISPLAYED_COLUMNS = HP_TYPES_TABLE_COLUMNS;

  /** Media Subscription */
  private readonly _mediaSub$ = this.mediaObserver.asObservable();

  /** Data Source */
  dataSource = new MatTableDataSource<IHallPassType>([]);

  /** New Type ID */
  newTypeId: number;

  /** Animation State */
  animationState = 'initial';

  @Input()
  onNewTypeCreated: Observable<IHallPassType>;
  _onNewTypeCreated$: Subscription;

  @Output()
  refetchTypes: EventEmitter<string> = new EventEmitter();

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    private _cdr: ChangeDetectorRef,
    private _modalOverlay: ModalOverlayService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _hallPassManager: HallPassManager,
    private _hpm: HallPassService,
    public kioskPermissions: KioskPermissionsService,
  ) {
    this.fetchTypes();
    this._mediaSubscription();
  }

  /** Component Lifecycle: On Mount */
  ngOnInit(): void {
    /** On New Type Created */
    this._onNewTypeCreated$ = this.onNewTypeCreated
      .pipe(takeUntil(this._destroyed$))
      .subscribe(d => this._handleOnNewTypeCreated(d));
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this._cdr.markForCheck();
  }

  /** Component Lifecycle: On Unmount */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Handle On Click Edit Type
   */
  public async onClickEditType(
    item: IHallPassType,
    isNewItem?: boolean,
  ): Promise<void> {
    if (!item) return;
    const { id } = item;
    const overlayData = {
      type: isNewItem ? 'new' : 'existing',
      data: isNewItem ? item : id,
    };
    const overlayRef = this._modalOverlay.open(HpmTypesEditComponent, {
      backdrop: BackDropColor.LIME,
      data: overlayData,
      disposeOnNavigation: false,
    });

    // After Overlay Has Closed
    overlayRef.afterClosed.subscribe(async d => {
      if (!d) return;
      const { type, data } = d;
      if (type === ModalOverlayServiceCloseEventType.SUBMIT) {
        if (data?.isNewType) {
          this._systemAlertSnackBar.success(
            HpmTypesTableMessages.SNACK_CREATE_SUCCESS,
          );
          const hallpassType = data?.type;
          this.newTypeId = hallpassType.id;
          this.animationState = 'glow';
          this.dataSource.data = [hallpassType, ...this.dataSource.data];
          this._cdr.markForCheck();
          setTimeout(() => {
            this.newTypeId = undefined;
            this.animationState = 'initial';
            this._cdr.markForCheck();
          }, 1000);
        }
        this._systemAlertSnackBar.success(
          HpmTypesTableMessages.SNACK_UPDATE_SUCCESS,
        );
        await this.fetchTypes();
      }
      if (type === ModalOverlayServiceCloseEventType.DELETE) {
        this._systemAlertSnackBar.success(
          HpmTypesTableMessages.SNACK_DELETE_SUCCESS,
        );
        await this.fetchTypes();
      }
    });
  }

  public async fetchTypes(): Promise<void> {
    this._isLoadingSubject.next(true);
    try {
      const request = new hall_pass_pb.GetMingaHallPassTypesRequest();
      request.setGetActiveOnly(false);
      const response = await this._hallPassManager.getMingaHallPassTypes(
        request,
      );
      const types = response
        .getHallPassTypeList()
        .map(HPProto => HallPassTypeMapper.fromProto(HPProto));
      this.dataSource.data = types;
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to fetch hall pass types');
    } finally {
      this._isLoadingSubject.next(false);
    }
  }

  public async onStatusChange(item: IHallPassType, value: boolean) {
    try {
      const hallPassType: IHallPassType = item;
      hallPassType.active = value;

      // restrictions are unaffected so there is no need to pass them along and upsert them
      hallPassType.restriction = undefined;

      await this._hpm.updateHallPassType(item);
      this._systemAlertSnackBar.success(
        HpmTypesTableMessages.SNACK_UPDATE_SUCCESS,
      );
      this._cdr.markForCheck();
    } catch (error) {
      this._systemAlertSnackBar.error(HpmTypesTableMessages.SNACK_UPDATE_FAIL);
      this._cdr.markForCheck();
    }
  }

  private async _handleOnNewTypeCreated(type: IHallPassType): Promise<void> {
    this.onClickEditType(type, true);
  }

  public trackById(index: number, item: IHallPassType) {
    return item ? item.id : index;
  }

  private _mediaSubscription() {
    return this._mediaSub$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(mediaChanges => {
        this._cdr.markForCheck();
      });
  }
}
