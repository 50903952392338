import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgPersonMinimalModule } from 'minga/app/src/app/elements/MgPersonMinimal';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';

import { LongCardBaseModule } from '../LongCardBase';

import { BirthdayLongCardComponent } from './BirthdayLongCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    LongCardBaseModule,
    MgAvatarModule,
    MgPersonMinimalModule,
    MgOverlayToolsModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [BirthdayLongCardComponent],
  exports: [BirthdayLongCardComponent],
})
export class BirthdayLongCardModule {}
