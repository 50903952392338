import { ConsequenceTypesEnum } from 'minga/domain/consequences';

import { FormSelectOption } from '@shared/components/form';

export enum BmConsequencesMessages {
  TABLE_TITLE_CONSEQUENCES = 'Consequences',

  COLUMN_TITLE_NAME = 'Name',
  COLUMN_TITLE_STATUS = 'Status',
  COLUMN_TITLE_EDIT = 'Edit',

  EMPTY_STATE_TITLE = 'No consequences exist',
  EMPTY_STATE_SUBTITLE = 'Create a new consequence type to get started.',

  EMPTY_PRAISE_TITLE = 'No praise consequences exist',
  EMPTY_PRAISE_DESC = 'Create a new praise consequense to get started',
  EMPTY_GUIDANCE_TITLE = 'No guidance consequences exist',
  EMPTY_GUIDANCE_DESC = 'Create a new guidance consequense to get started',
  EMPTY_DISABLED_TITLE = 'Consequences are disabled',
  EMPTY_DISABLED_DESC = 'You must enable consequences in settings in order to use them',

  PRAISE_TITLE = 'Consequences for a Praise',
  GUIDANCE_TITLE = 'Consequences for a Guidance',

  TOOLTIP = 'Allows you to create a reward or discipline to be given to a student.',

  BUTTON_CREATE = 'Create consequence',
}

export enum ConsequenceTypeMessages {
  MODAL_TITLE_CREATE = 'Create Consequence',
  MODAL_TITLE_EDIT = 'Edit Consequence',

  FIELD_LABEL_NAME = 'Consequence name',
  FIELD_LABEL_POINTS = 'Points (optional)',
  FIELD_LABEL_DESCRIPTION = 'Internal description',
  FIELD_LABEL_ADMIN_EMAIL = 'Admin email',
  FIELD_LABEL_PARENT_EMAIL_SUBJECT = 'Email subject',
  FIELD_LABEL_PARENT_EMAIL_BODY = 'Email body',
  FIELD_LABEL_SMS_TEMPLATE = 'SMS message',

  NOTES_MSG = 'Add notes to consequence',
  PARENT_EMAIL_MSG = 'Send parent notification email',
  PARENT_SMS_MSG = 'Send parent notification SMS',
  PARENT_SMS_MSG_TOOLTIP = 'SMS needs to be enabled in your Minga settings',
  ADMIN_EMAIL_MSG = 'Send admin notification email',

  BUTTON_DELETE_LABEL = 'Delete',
  BUTTON_CANCEL_LABEL = 'Cancel',
  BUTTON_SAVE_LABEL = 'Save',
  BUTTON_CREATE_LABEL = 'Create',

  QUILL_EDITOR_INSTRUCTIONS = "${firstName} = Student's first name\n${lastName} = Student's last name\n${typeName} = Name of consequence assigned to student\n${mingaName} = School name\n${note} = Custom note from teacher to student\n${assigner} = The person who assigned the consequence\n${points} = How many points were awarded",
}

export enum BehaviorMsgCategory {
  PRAISE = 0,
  GUIDANCE,
}

export const ConsequenceTypeOptionsMapper: Record<
  BehaviorMsgCategory,
  FormSelectOption[]
> = {
  [BehaviorMsgCategory.PRAISE]: [
    {
      label: 'Notification',
      value: ConsequenceTypesEnum.NOTIFICATION,
    },
    {
      label: 'Other',
      value: ConsequenceTypesEnum.OTHER,
    },
  ],
  [BehaviorMsgCategory.GUIDANCE]: [
    {
      label: 'Detention',
      value: ConsequenceTypesEnum.DETENTION,
    },
    {
      label: 'Referral',
      value: ConsequenceTypesEnum.REFERRAL,
    },
    {
      label: 'Warning',
      value: ConsequenceTypesEnum.WARNING,
    },
    {
      label: 'Other',
      value: ConsequenceTypesEnum.OTHER,
    },
  ],
};

export const BM_TYPES_CONSEQUENCES_TABLE_COLUMNS: string[] = [
  'icon',
  'name',
  'active',
  'edit',
];

export enum BmTypesConsequenceEditFormFields {
  NAME = 'NAME',
  POINTS = 'POINTS',
  CATEGORY_ID = 'CATEGORY_ID',
  DESCRIPTION = 'DESCRIPTION',
  ADD_NOTES = 'ADD_NOTES',
  PARENT_EMAIL_SUBJECT = 'PARENT_EMAIL_SUBJECT',
  PARENT_EMAIL_BODY = 'PARENT_EMAIL_BODY',
  SEND_PARENT_EMAIL = 'SEND_PARENT_EMAIL',
  SMS_TEMPLATE = 'SMS_TEMPLATE',
  SEND_PARENT_SMS = 'SEND_PARENT_SMS',
  SEND_ADMIN_EMAIL = 'SEND_ADMIN_EMAIL',
  ADMIN_EMAIL = 'ADMIN_EMAIL',
  TYPE = 'TYPE',
  STICKER = 'STICKER',
}
