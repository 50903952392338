<ng-container *ngIf="layout.useLegacyLayout$ | async; else newLayoutTemplate">
  <div class="manager-overlay-subpage-header">
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-sm="start stretch"
      fxLayoutGap.lt-sm="15px">
      <div fxFlex="grow">
        <h2>{{ MESSAGES.PAGE_TITLE }}</h2>
      </div>
      <div
        fxFlex="noshrink"
        fxFlex.lt-sm="grow"
        fxFlexAlign="center">
        <mg-btn
          variant="filled"
          (pressed)="handleOnClickCreateNew()">
          {{ MESSAGES.BUTTON_CREATE_NEW }}
        </mg-btn>
      </div>
    </div>
  </div>

  <div class="manager-overlay-subpage-content white-bg">
    <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
  </div>
</ng-container>

<!-- New Layout -->
<ng-template #newLayoutTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign.gt-sm="end end"
    [style.marginBottom]="'16px'">
    <mg-btn
      fxFlex="nogrow"
      variant="filled"
      [id]="'checkin-types-click-create'"
      [responsive]="true"
      (pressed)="handleOnClickCreateNew()">
      {{ MESSAGES.BUTTON_CREATE_NEW }}
    </mg-btn>
  </div>
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</ng-template>

<!-- Main template content -->
<ng-template #mainContentTemplate>
  <ng-container *ngIf="loadingTypes; else tableTemplate">
    <div class="loading-container">
      <mg-spinner
        [diameter]="40"
        [thickness]="3">
      </mg-spinner>
    </div>
  </ng-container>
  <ng-template #tableTemplate>
    <mg-checkin-manager-reasons-table
      [data]="types$ | async"
      [onNewReasonCreated]="onNewReasonCreated.asObservable()"
      (refetchReasons)="fetchReasons($event)">
    </mg-checkin-manager-reasons-table>
  </ng-template>
</ng-template>
