export enum FtmPeriodsActivityEditMessage {
  MODAL_TITLE = 'Change Activity',
  MODAL_TITLE_NEW = 'Add Activity',

  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_ASSIGN = 'Assign',
  BUTTON_LABEL_ADD = 'Add',
  BUTTON_LABEL_REMOVE = 'Remove',
  BUTTON_ACTIVITY_TEMPLATES = 'Go there',

  FORM_LABEL_PERIOD = 'FlexTime Period',

  COLUMN_LABEL_ASSET = '',
  COLUMN_LABEL_TITLE = 'Title',
  COLUMN_LABEL_TYPE = 'Activity Type',

  ITEM_DESCRIPTION = 'Description',
  ITEM_TEACHER = 'Teacher',
  ITEM_LOCATION = 'Location',

  EMPTY_STATE_TITLE = 'No activities found',
  EMPTY_STATE_SUBTITLE = 'To add an activity, you must first create an activity template',
}

export const FTM_PERIODS_ACTIVITY_EDIT_DISPLAY_COLUMNS = [
  'asset',
  'name',
  'type',
];
