<div class="banner">
  <ng-container *ngIf="!!content?.featuredImage">
    <mg-image
      [srcs]="content?.featuredImage | mgImageUrls:bannerImageSizes"></mg-image>
  </ng-container>
</div>

<div class="right-col-content">
  <h1
    class="title content-block mg-bodycopy-bold"
    [innerHTML]="content?.title || ''"></h1>
  <ng-container *ngIf="!content?.cancelled">
    <div class="content-block event-info">
      <div class="event-info-text">{{displayEventDatesSingleLine()}}</div>
    </div>
    <div
      class="content-block actions icons"
      [ngClass]="'icons-pd-' + devicePixelRatio + ' '">
      <div
        class="event-location"
        [class.button-width]="!isEventOver()">
        {{content.location}}
      </div>
    </div>
  </ng-container>
  <div
    *ngIf="content?.cancelled"
    class="cancelled content-block event-info">
    This event is CANCELLED.
  </div>
</div>
