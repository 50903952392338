import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { Observable, ReplaySubject, Subject } from 'rxjs';

import { IHallPassType } from 'minga/domain/hallPass';

import { LayoutService } from '@modules/layout/services';

import { HpmTypesMessages } from './hpm-types.constants';

/**
 * Hallpass Manager Types
 */
@Component({
  selector: 'mg-hpm-types',
  templateUrl: './hpm-types.component.html',
  styleUrls: ['./hpm-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmTypesComponent implements OnDestroy {
  /** Messages */
  public readonly MSG = HpmTypesMessages;

  /** Subscription Cleanup Helper */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** On New Type Created Event */
  private readonly _newTypeSubject: Subject<IHallPassType> =
    new Subject<IHallPassType>();
  public readonly newType$: Observable<IHallPassType> =
    this._newTypeSubject.asObservable();

  /** Component Constructor */
  constructor(public layout: LayoutService) {}

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._newTypeSubject.complete();
  }

  public async onClickCreateNewType(): Promise<void> {
    const newHallPassType: IHallPassType = {
      name: '',
      bannerHash: 'hallpass',
      color: '#ea4335',
      active: false,
      defaultHallPassTime: 0,
      maxSimultaneousPasses: 0,
    };
    this._newTypeSubject.next(newHallPassType);
  }
}
