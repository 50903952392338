<span
  #svgIconName
  hidden
  aria-hidden="true"
  [ngStyle]="{ color: iconColor }">
  <ng-content></ng-content>
</span>
<mat-icon
  [svgIcon]="getSvgIconName()"
  [ngStyle]="{ color: iconColor }">
</mat-icon>
