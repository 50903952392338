<div class="mg-announcement-mini-card">
  <div class="image">
    <mge-icon icon="announcement"></mge-icon>
  </div>
  <div class="content">
    <div>
      <div
        class="title"
        [ngClass]="displayTitleClasses">
        <mg-ellipsis (click)="clickContextMenu($event)"></mg-ellipsis>
        <span [innerHTML]="displayTitle | mgSafeHtml"></span>
      </div>
    </div>

    <div
      class="subtitle"
      [innerHTML]="timestampText"></div>
  </div>
</div>
