<div
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="10px"
  class="carousel">
  <!-- Left Arrow -->
  <div fxFlex="nogrow">
    <button
      *ngIf="numOfPanels > 1; else spacerTemplate"
      class="carousel-arrow-button carousel-arrow-button-left"
      [class.carousel-arrow-button-disabled]="disablePrevious"
      (click)="moveTilesLeft()">
      <mg-icon>left-arrow-active</mg-icon>
    </button>
  </div>

  <!-- Content Container -->
  <div
    #carouselContainer
    fxFlex="grow"
    class="carousel-content-container">
    <ng-container *ngIf="isLoading; else panelContainerTemplate">
      <div class="carousel-loading">
        <mg-spinner></mg-spinner>
      </div>
    </ng-container>
  </div>

  <!-- Tab Panel Container -->
  <ng-template #panelContainerTemplate>
    <mat-tab-group
      #matTabGroup
      [animationDuration]="animationDuration"
      [selectedIndex]="selectedPanelIndex">
      <ng-container
        *ngFor="let content of listOfContentPerPanel; let index = index">
        <mat-tab>
          <!-- append tile content to this div -->
          <div
            #tileContent
            class="carousel-panel"
            [class.carousel-panel-grid]="isGrid"
            [class.align-middle]="alignMiddle"
            [class.last-panel]="numOfPanels > 1 && index == numOfPanels - 1"
            [style]="{
              '--mg-tiles-carousel-flex-direction':
                layoutStyleSubj.value === PRESET.VERT_LIST ? 'column' : 'row',
              '--tiles-carousel-num-columns': gridLayoutValues[0],
              '--tiles-carousel-num-rows': gridLayoutValues[1]
            }"
            (swipeleft)="carouselSwipeLeft($event)"
            (swiperight)="carouselSwipeRight($event)"></div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </ng-template>

  <!-- Right Arrow -->
  <div fxFlex="nogrow">
    <button
      *ngIf="numOfPanels > 1; else spacerTemplate"
      class="carousel-arrow-button carousel-arrow-button-right"
      [class.carousel-arrow-button-disabled]="disableNext"
      (click)="moveTilesRight()">
      <mg-icon>right-arrow-active</mg-icon>
    </button>
  </div>
</div>

<!-- Pagination -->
<ul
  *ngIf="showPagination && numOfPanels > 1"
  class="carousel-pagination">
  <ng-container
    *ngFor="let content of listOfContentPerPanel; let index = index">
    <li [class.active]="selectedPanelIndex === index">
      <button></button>
    </li>
  </ng-container>
</ul>

<!-- Content Template -->
<div
  #tilesContainer
  class="carousel-content"
  [fxLayoutAlign]="fxLayoutAlign"
  [fxLayoutGap]="fxLayoutGap"
  cdkObserveContent
  (cdkObserveContent)="onNgContentChange($event)">
  <ng-content></ng-content>
</div>

<!-- Spacer Template -->
<ng-template #spacerTemplate>
  <div class="spacer"></div>
</ng-template>
