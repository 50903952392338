import { Injectable } from '@angular/core';

import * as localforage from 'localforage';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import {
  ContentEvents,
  IContentCreateSuccessEvent,
} from 'minga/app/src/app/minimal/services/ContentEvents';
import { IContentEventMinimal } from 'minga/domain/content';
import { EventCollection } from 'minga/proto/content/eventCollection/event_collection_ng_grpc_pb';
import {
  GetEventCollectionRequest,
  GetEventCollectionResponse,
} from 'minga/proto/content/eventCollection/event_collection_pb';
import { EventViewMinimalMapper } from 'minga/shared-grpc/content';

const eventCollection = localforage.createInstance({
  name: 'EventsCollection',
});

const eventCollectionSubject = new BehaviorSubject<IContentEventMinimal[]>([]);
let storageTimeout: any;
let notifyTimeout: any;

@Injectable({ providedIn: 'root' })
export class EventsFacadeService {
  private _fetchPromise?: Promise<void>;
  private _lastFetch: number = 0;
  private _contentEventCreateSub?: Subscription;

  constructor(
    private eventCollectionService: EventCollection,
    private contentEvents: ContentEvents,
    private authInfoService: AuthInfoService,
  ) {
    this._contentEventCreateSub =
      this.contentEvents.onPostCreateSuccess.subscribe(ev => {
        this._onContentEvent(ev);
      });
  }

  private _getCurrentUserHash(): string {
    const info = this.authInfoService.authInfo;
    if (info) {
      return info.person.personHash;
    }
    return '';
  }

  private async _fetchEventCollection(token: string) {
    const currentUserHash = this._getCurrentUserHash();
    const eventsKey = `events_${currentUserHash}`;
    const tokenKey = `token_${currentUserHash}`;

    const request = new GetEventCollectionRequest();
    request.setToken(token);
    const obs: { close(): void } & Observable<GetEventCollectionResponse> =
      this.eventCollectionService.getEventCollection(request);

    let hasHandledFirstResponse = false;

    if (token && eventCollectionSubject.getValue().length === 0) {
      const events = await eventCollection.getItem<IContentEventMinimal[]>(
        eventsKey,
      );

      if (events) {
        eventCollectionSubject.next(events);
      }
    }

    obs.subscribe(
      response => {
        if (!hasHandledFirstResponse) {
          if (!token) {
            eventCollectionSubject.next([]);
            eventCollection.setItem(eventsKey, []);
          }

          hasHandledFirstResponse = true;
        }

        const collection = eventCollectionSubject.getValue();

        const newToken = response.getToken();
        if (newToken) {
          eventCollection.setItem(tokenKey, newToken);
          token = newToken;
        }

        let notifyEventCollectionUpdate = false;

        if (response.hasEvent()) {
          const event = response.getEvent();
          const index = _.findIndex(collection, function (row) {
            return row.hash === event.getContentHash();
          });
          if (index !== -1) {
            collection[index] =
              EventViewMinimalMapper.toIContentEventMinimal(event);
          } else {
            collection.push(
              EventViewMinimalMapper.toIContentEventMinimal(event),
            );
          }

          notifyEventCollectionUpdate = true;
        }

        const removeEventHash = response.getRemoveEvent();
        if (removeEventHash) {
          const rmvIdx = collection.findIndex(
            event => event.hash == removeEventHash,
          );
          if (rmvIdx !== -1) {
            collection.splice(rmvIdx, 1);
            notifyEventCollectionUpdate = true;
          }
        }

        if (notifyEventCollectionUpdate) {
          clearTimeout(notifyTimeout);
          clearTimeout(storageTimeout);
          notifyTimeout = setTimeout(() => {
            eventCollectionSubject.next(collection);
          }, 300);
          storageTimeout = setTimeout(() => {
            eventCollection.setItem(eventsKey, collection);
          }, 1000);
        }
      },
      err => {
        console.error(err);
        eventCollection.setItem(tokenKey, '');
      },
    );

    await obs.toPromise();
  }

  private _fetchIsOld() {
    return this._lastFetch && this._lastFetch + 1000 * 360 < Date.now();
  }

  async fetchEventCollectionIfNeeded(force?: boolean) {
    if (!this._fetchPromise || this._fetchIsOld() || force) {
      this._lastFetch = Date.now();

      const currentUserHash = this._getCurrentUserHash();
      const tokenKey = `token_${currentUserHash}`;

      if (this._fetchPromise) {
        await this._fetchPromise;
      }

      this._fetchPromise = eventCollection
        .getItem<string>(tokenKey)
        .then(token => token || '')
        .catch(err => {
          console.error('Could not get event collection token:', err);
          return '';
        })
        .then(token => this._fetchEventCollection(token))
        .catch(err => {
          delete this._fetchPromise;
          this._lastFetch = 0;
          throw err;
        });

      await this._fetchPromise;
    }
  }

  /**
   * Wait for a potential fetch to finish before returning event if found in
   * event collection found by content hash.
   * @NOTE: use the getEventByContentHash() unless this is needed.
   * @param eventContentHash
   */
  async fetchEventByContentHash(
    eventContentHash: string,
  ): Promise<IContentEventMinimal | undefined> {
    await this.fetchEventCollectionIfNeeded();
    return eventCollectionSubject
      .asObservable()
      .pipe(map(events => events.find(event => event.hash == eventContentHash)))
      .pipe(first())
      .toPromise();
  }

  /**
   * Wait for a potential fetch to finish before returning event if found in
   * event collection found by context hash.
   * @NOTE: use the getEventByContextHash() unless this is needed.
   * @param eventContextHash
   */
  async fetchEventByContextHash(
    eventContextHash: string,
  ): Promise<IContentEventMinimal | undefined> {
    await this.fetchEventCollectionIfNeeded();
    return eventCollectionSubject
      .asObservable()
      .pipe(
        map(events =>
          events.find(event => event.contextHash == eventContextHash),
        ),
      )
      .pipe(first())
      .toPromise();
  }

  getEventByContentHash(
    eventContentHash: string,
  ): Observable<IContentEventMinimal | undefined> {
    // @TODO: Optimize this
    return this.getAllEvents().pipe(
      map(events => events.find(event => event.hash == eventContentHash)),
    );
  }

  getEventByContextHash(
    eventContextHash: string,
  ): Observable<IContentEventMinimal | undefined> {
    // @TODO: Optimize this
    return this.getAllEvents().pipe(
      map(events =>
        events.find(event => event.contextHash == eventContextHash),
      ),
    );
  }

  getAllEvents(): Observable<IContentEventMinimal[]> {
    return eventCollectionSubject.asObservable();
  }

  private _onContentEvent(ev: IContentCreateSuccessEvent) {
    if (ev.typeString === 'event') {
      this.fetchEventCollectionIfNeeded(true);
    }
  }
}
