import { Injectable } from '@angular/core';

import { ModalOverlayService } from '@shared/components/modal-overlay';

import { MmDatePresetsEditComponent } from './mm-date-presets-edit.component';
import {
  MmDatePresetsEditData,
  MmDatePresetsEditResponse,
} from './mm-date-presets.types';

@Injectable({
  providedIn: 'root',
})
export class DatePresetsEditService {
  constructor(
    private _modalOverlay: ModalOverlayService<
      MmDatePresetsEditResponse,
      MmDatePresetsEditData
    >,
  ) {}

  public openEditModal(data?: MmDatePresetsEditData) {
    return this._modalOverlay.open<MmDatePresetsEditData>(
      MmDatePresetsEditComponent,
      {
        data,
        disposeOnNavigation: false,
      },
    );
  }
}
