import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MessageBoxComponent,
  MgMessageBox_Content,
} from './MessageBox.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MessageBoxComponent, MgMessageBox_Content],
  exports: [MessageBoxComponent, MgMessageBox_Content],
})
export class MgMessageBoxModule {}
