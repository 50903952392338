<mat-form-field
  class="mg-pin"
  [ngClass]="{ 'hidden-required': hiddenRequired }"
  [mgReadOnly]="readonly"
  [mgNoHintMargin]="mgNoHintMargin"
  mgFormField>
  <mat-label>{{ placeholder || 'PIN' }}</mat-label>
  <input
    matInput
    type="text"
    [formControl]="outerErrors ? outerControl : inputControl"
    [required]="inputRequired"
    (ngModelChange)="change($event)"
    min="0"
    inputmode="numeric"
    pattern="[0-9]*"
    #pinInput
    [readonly]="readonly" />

  <div
    class="input-icon"
    mat-icon-button
    matSuffix>
    <mat-icon *ngIf="statusDone && outerControl.asyncValidator">done</mat-icon>
    <mat-icon *ngIf="statusInvalid && outerControl.asyncValidator"
      >error_outline</mat-icon
    >
    <mg-spinner
      color="primary"
      mode="indeterminate"
      diameter="25"
      *ngIf="statusPending">
    </mg-spinner>
  </div>

  <mat-hint *ngIf="hint">
    <!-- Pins must be unique within your Minga -->
    {{ hint | translate }}
  </mat-hint>
  <mat-error *ngIf="statusTouched">
    <ng-container [ngSwitch]="inputControlErrorKey">
      <span *ngSwitchCase="'pattern'">
        <!-- The pin you provided contains invalid characters -->
        {{ 'input.pin.error.pattern' | translate }}
      </span>
      <span *ngSwitchCase="'pinTaken'">
        <!-- Sorry, that pin is already in use -->
        {{ 'input.pin.error.pinTaken' | translate }}
      </span>
      <span *ngSwitchCase="'max'">
        <!-- A pin must be less than 2147483647 -->
        {{ 'input.pin.error.max' | translate }}
      </span>
      <span *ngSwitchCase="'incorrect'">
        {{ 'input.pin.error.incorrect' | translate }}
      </span>
      <span *ngSwitchDefault>
        <!-- You must provide a pin -->
        {{ 'input.pin.error.required' | translate }}
      </span>
    </ng-container>
  </mat-error>
</mat-form-field>
