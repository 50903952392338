import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

import { EventConfirmationDialogComponent } from './EventConfirmationDialog.component';
import {
  IEventConfirmationDialogData,
  IEventIdScannerDialogResult,
} from './types';

export type EventConfirmationDialogRef = MatDialogRef<
  EventConfirmationDialogComponent,
  IEventIdScannerDialogResult
>;
export type EventConfirmationDialogConfig =
  MatDialogConfig<IEventConfirmationDialogData>;

/**
 * Service to simply open up the student id scanner dialog.
 */
@Injectable({ providedIn: 'root' })
export class EventConfirmationDialogService {
  constructor(private matDialog: MatDialog) {}

  open(config: EventConfirmationDialogConfig): EventConfirmationDialogRef {
    return this.matDialog.open(EventConfirmationDialogComponent, {
      ...config,
      // We do not allow the user to close the dialog. If the close behaviour
      // is needed to be changed, it should be done in the component itself.
      disableClose: true,
    });
  }
}
