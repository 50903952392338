import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mg-destroy-minga-dialog',
  templateUrl: './DestroyMingaDialog.component.html',
  styleUrls: ['./DestroyMingaDialog.component.scss'],
})
export class DestroyMingaDialogComponent {
  confirmDestroyInput: string = '';
  confirmDestroy: string = '';
  mingaName: string = '';

  constructor(
    private dialogRef: MatDialogRef<DestroyMingaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.confirmDestroy = data.confirmDestroy || 'destroy';

    if (data.confirmDestroy) {
      this.mingaName = data.confirmDestroy;
    } else {
      this.mingaName = 'this minga';
    }
  }

  submit() {
    if (this.confirmDestroyInput !== this.confirmDestroy) return;
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
