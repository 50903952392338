import { MembershipListType } from 'minga/domain/membershipList';

export enum BmSettingsMessages {
  SECTION_TITLE = 'Manage school teams',

  SUBHEADING_BEHAVIORS_TITLE = 'Behaviors Settings',
  SUBHEADING_CONSEQUENCES_TITLE = 'Consequences Settings',

  COLUMN_LABEL_NAME = 'Party group name',
  COLUMN_LABEL_MEMBERS = 'Members',
  COLUMN_LABEL_DELETE = 'Delete',

  BUTTON_LABEL_CREATE_TEAM = 'Create team',

  TABLE_VIEW_MORE = 'View More',
  TABLE_VIEW_LESS = 'View Less',

  TOOLTIP_POINTS = 'Allows you to manage points for teams or individual students',
  TOOLTIP_TEAMS = 'Create and manage your school teams below',
  SETTING_TEACHERS_CAN_ASSIGN = 'Allow Teachers to assign behaviors',
  SETTING_STAFF_CAN_ASSIGN = 'Allow Staff to assign behaviors',
  SETTING_STUDENT_LEADERS_CAN_ASSIGN = 'Allow Student Leaders to assign behaviors',
  SETTING_AUTO_SEND_TARDY = 'Auto send tardy hall pass with behavior',
  SETTING_ENABLE_CONSEQUENCES = 'Enable consequences',
  SETTING_TEACHERS_ASSIGN_CONSEQUENCE = 'Allow Teachers to assign consequences',
  SETTING_STAFF_ASSIGN_CONSEQUENCE = 'Allow Staff to assign consequences',
}

export const BMSettingsFriendlyListTypeName: Partial<
  Record<MembershipListType, string>
> = {
  [MembershipListType.TRACK]: 'Can Assign Behaviors',
  [MembershipListType.NO_TRACK]: 'Cannot Assign Behaviors',
};
