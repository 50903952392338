import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ToolsService } from '@modules/tools/services';

@Component({
  selector: 'mg-tools-flex-time',
  templateUrl: './tools-flex-time.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsFlexTimeComponent {
  public toolType$ = this._toolsService.toolType$;
  constructor(private _toolsService: ToolsService) {}
}
