import { Pipe, PipeTransform } from '@angular/core';

import { map, startWith } from 'rxjs/operators';

import { GenericTagColor } from '@shared/components/generic';

import { KioskService } from '../services';
import { KioskHallPassSummaryData } from '../types';

@Pipe({ name: 'kioskHallPassTimer' })
export class KioskHallPassTimerPipe implements PipeTransform {
  constructor(public kiosk: KioskService) {}
  private _expireTime: number;
  transform(value: KioskHallPassSummaryData) {
    this._expireTime = value.expire.getTime();
    return this.kiosk.tickerInSeconds$.pipe(
      startWith(0),
      map(() => {
        const now = new Date().getTime();
        const hasPassed = now > this._expireTime;
        const totalSeconds = Math.floor(
          (hasPassed ? now - this._expireTime : this._expireTime - now) / 1000,
        );
        const minutes = Math.floor(totalSeconds / 60) % 60;
        const seconds = totalSeconds % 60;
        const formatted = `${hasPassed ? '+' : ''}${minutes + ':'}${seconds
          .toString()
          .padStart(2, '0')}`;
        return {
          status: hasPassed ? 'Overdue' : 'Active',
          tagColor: (hasPassed ? 'red' : 'blue') as GenericTagColor,
          time: formatted,
        };
      }),
    );
  }
}
