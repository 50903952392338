<!-- Overlay Modal Container -->
<section class="dialog">
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="24px">
    <ng-container *ngTemplateOutlet="mainContent"></ng-container>
    <ng-container *ngTemplateOutlet="footerContent"></ng-container>
  </div>
  <div
    *ngIf="!(data$ | async)?.disableClose"
    class="close-wrap">
    <mg-btn
      variant="icon-secondary"
      iconSet="minga"
      icon="close"
      (pressed)="onCloseIconClick()">
    </mg-btn>
  </div>
</section>

<!-- Main Content Template -->
<ng-template #mainContent>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="notification-container"
    fxLayoutGap="8px">
    <ng-container *ngIf="data$ | async as data">
      <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="messageTemplate"></ng-container>
      <ng-container
        *ngIf="data.templateRef"
        [ngTemplateOutlet]="data.templateRef"></ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- Footer Content Template -->
<ng-template #footerContent>
  <ng-container *ngIf="data$ | async as data">
    <div class="dialog-footer">
      <div
        *ngIf="
          data?.confirmActionBtn ||
          data?.closeBtn ||
          data?.secondaryConfirmActionBtn
        "
        fxLayout="row"
        [fxLayoutAlign]="
          (data?.confirmActionBtn || data?.secondaryConfirmActionBtn) &&
          data?.closeBtn
            ? 'space-between center'
            : 'flex-end center'
        ">
        <mg-btn
          *ngIf="data?.closeBtn"
          variant="text"
          (pressed)="onCloseClick()">
          {{ data?.closeBtn }}
        </mg-btn>

        <div
          fxLayout="row"
          fxLayoutGap="5px">
          <mg-btn
            *ngIf="data?.secondaryConfirmActionBtn"
            variant="text"
            (pressed)="onSecondaryConfirmClick()">
            {{ data?.secondaryConfirmActionBtn }}
          </mg-btn>
          <mg-btn
            *ngIf="data?.confirmActionBtn"
            variant="filled"
            (pressed)="onConfirmClick()">
            {{ data?.confirmActionBtn }}
          </mg-btn>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- Icon Template -->
<ng-template #iconTemplate>
  <ng-container *ngIf="iconData$ | async as iconData">
    <ng-container *ngIf="iconData.iconType === ICON_TYPE.ICON">
      <mg-icon
        class="notification-type-icon"
        [ngClass]="'app-color-' + iconData.iconColor">
        {{ iconData.iconSource }}
      </mg-icon>
    </ng-container>
    <ng-container *ngIf="iconData.iconType === ICON_TYPE.ASSET">
      <img
        class="notification-asset"
        [src]="iconData.iconSource" />
    </ng-container>
  </ng-container>
</ng-template>

<!-- Message Template -->
<ng-template #messageTemplate>
  <ng-container *ngIf="data$ | async as data">
    <mg-text
      *ngIf="data.heading"
      variant="header-lg"
      textAlign="center">
      {{ data.heading }}
    </mg-text>
    <mg-text
      *ngIf="data.message"
      class="message"
      variant="body-sm"
      textAlign="center">
      {{ data.message }}
    </mg-text>

    <mg-text
      *ngIf="data.subMessage"
      class="sub-message"
      variant="body-sm"
      textAlign="center">
      <span class="italic">{{ data.subMessage }}</span>
    </mg-text>
    <!-- Detailed View -->
    <ng-container
      *ngIf="data.detailedMessage"
      [ngTemplateOutlet]="detailedMessageTemplate"
      [ngTemplateOutletContext]="{ $implicit: data.detailedMessage }">
    </ng-container>

    <!-- timer -->
    <ng-container *ngIf="(remainingTime$ | async) !== null">
      <div class="timer-wrap">
        <mg-text
          variant="body-md-secondary"
          textAlign="center">
          {{ remainingTime$ | async | secondsToTime }}
        </mg-text>
      </div>
    </ng-container>

    <ng-container
      *ngIf="data.input?.placeholder"
      [ngTemplateOutlet]="inputTemplate"
      [ngTemplateOutletContext]="{ $implicit: data.input }">
    </ng-container>
  </ng-container>
</ng-template>

<!-- Detailed Message Template -->
<ng-template
  #detailedMessageTemplate
  let-data>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="4px"
    class="detailed-message">
    <div
      *ngFor="let row of data; let i = index"
      fxLayout="row"
      fxLayoutAlign="space-between start"
      fxLayoutGap="16px">
      <mg-text
        variant="body-sm"
        color="surface-alt">
        {{ row[0] }}
      </mg-text>
      <mg-text variant="body-sm">
        {{ row[1] }}
      </mg-text>
    </div>
  </div>
</ng-template>

<ng-template
  #inputTemplate
  let-data>
  <mg-form-text-input
    [inputType]="data.inputType || 'text'"
    [label]="data.placeholder"
    [control]="inputControl">
  </mg-form-text-input>
</ng-template>
