import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

import { PendingInvitationsDialog } from './PendingInvitationsDialog.component';
import { IPendingInvitationsDialogOptions } from './types';

export type PendingInvitationsDialogRef =
  MatDialogRef<PendingInvitationsDialog>;
export type PendingInvitationsDialogConfig =
  MatDialogConfig<IPendingInvitationsDialogOptions>;

/**
 * Service to simply open up the points dialog.
 */
@Injectable({ providedIn: 'root' })
export class PendingInvitationsDialogService {
  constructor(private matDialog: MatDialog) {}

  open(config: PendingInvitationsDialogConfig): PendingInvitationsDialogRef {
    return this.matDialog.open(PendingInvitationsDialog, {
      ...config,
    });
  }
}
