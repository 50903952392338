import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[mgText]',
  exportAs: 'mgText',
})
export class MgTextDirective {
  constructor(private element: ElementRef) {}

  get text() {
    if (this.element) {
      if (this.element.nativeElement) {
        return this.element.nativeElement.textContent.trim();
      }
    }

    return '';
  }
}
