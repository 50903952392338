import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

import { NavTabsComponent } from './NavTabs.component';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    MatTabsModule,
    CommonModule,
  ],
  declarations: [NavTabsComponent],
  exports: [NavTabsComponent],
})
export class NavTabsModule {}
