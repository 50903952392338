<mat-table
  matSort
  class="list-table no-explicit-mobile-layout list-table-relaxed"
  [dataSource]="dataSource"
  [trackBy]="trackById">
  <!-- Icon Column -->
  <ng-container matColumnDef="icon">
    <mat-header-cell
      *matHeaderCellDef
      class="w-7"></mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="w-7 text-center">
      <ng-container
        [ngTemplateOutlet]="assetTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }">
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      {{ MESSAGES.COLUMN_LABEL_TYPE_NAME }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item">
      <mg-text variant="header-sm">
        {{ item.name }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let item">
      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="12px">
        <mg-icon
          *ngIf="kioskPermissions.isKioskFeatureEnabled()"
          iconName="mg-kiosk"
          matTooltipClass="mg-tooltip"
          [matTooltip]="
            item.availableInKiosk ? 'Enabled in kiosk' : 'Disabled in kiosk'
          "
          [class.disabled]="!item.availableInKiosk">
        </mg-icon>
        <mg-icon
          iconName="mg-auto-end"
          matTooltipClass="mg-tooltip"
          [matTooltip]="
            item.manuallyEndPass ? 'Manually ended' : 'Automatically ended'
          "
          [class.disabled]="item.manuallyEndPass">
        </mg-icon>
        <mg-icon
          iconName="mg-student-filled"
          matTooltipClass="mg-tooltip"
          [matTooltip]="
            item.selfIssue ? 'Student created' : 'Not student created'
          "
          [class.disabled]="!item.selfIssue">
        </mg-icon>
      </div>
    </mat-cell>
  </ng-container>

  <!-- Active Column -->
  <ng-container matColumnDef="active">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="w-16 text-center">
      {{ MESSAGES.COLUMN_LABEL_STATUS }}
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="w-16 text-center">
      <ng-container
        [ngTemplateOutlet]="statusTemplate"
        [ngTemplateOutletContext]="{ $implicit: item }">
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <mat-header-cell
      *matHeaderCellDef
      class="w-12 text-center">
      {{ MESSAGES.COLUMN_LABEL_EDIT }}
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="w-12 text-center">
      <mg-btn
        variant="icon"
        iconSet="minga"
        icon="mg-edit"
        [id]="'hallpass-types-click-edit'"
        (pressed)="onClickEditType(item)"></mg-btn>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
  <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS"></mat-row>
</mat-table>

<!-- Asset Template -->
<ng-template
  #assetTemplate
  let-item>
  <div class="asset-container">
    <ng-container *ngIf="item?.bannerHash; else defaultIcon">
      <mg-icon
        iconName="{{ item.bannerHash }}"
        [style.color]="item?.color">
      </mg-icon>
    </ng-container>

    <ng-template #defaultIcon>
      <mg-icon [style.color]="item?.color">hallpass</mg-icon>
    </ng-template>
  </div>
</ng-template>

<!-- Status Toggle Template -->
<ng-template
  #statusTemplate
  let-item>
  <mg-form-toggle
    [id]="'hallpass-types-click-toggle'"
    [value]="item?.active"
    (valueChange)="onStatusChange(item, $event)">
  </mg-form-toggle>
</ng-template>

<div
  *ngIf="isLoading$ | async"
  class="loading-container">
  <mg-spinner
    [diameter]="25"
    [thickness]="3">
  </mg-spinner>
</div>

<!-- Template: No Results -->
<mg-empty-state
  *ngIf="(isLoading$ | async) === false && dataSource.data?.length === 0"
  [title]="MESSAGES.EMPTY_STATE_TITLE"
  [subtitle]="MESSAGES.EMPTY_STATE_SUBTITLE">
</mg-empty-state>
