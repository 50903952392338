import { ChangeDetectionStrategy, Component } from '@angular/core';

import { HpmReportsUsersService } from '../../services';

@Component({
  selector: 'mg-hpm-reports-user',
  template: `
    <mg-report-table
      matSort
      [dataSourceService]="ds"
      (matSortChange)="ds.sort($event)">
      <mg-role-column [datasource]="ds"></mg-role-column>
      <mg-history-column
        [datasource]="ds"
        colorTheme="navy">
      </mg-history-column>
      <mg-summary-column
        [datasource]="ds"
        colorTheme="navy">
      </mg-summary-column>
    </mg-report-table>
  `,
  styleUrls: ['../hpm-reports.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HpmReportsUsersService],
})
export class HpmReportsUserComponent {
  constructor(public ds: HpmReportsUsersService) {}
}
