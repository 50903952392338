<mg-button-icon
  (click)="cancel()"
  class="top left"
  icon="close"></mg-button-icon>

<p class="mg-dialog message dialog-title">
  Would you like to add the program <strong>{{ programName }}</strong> to
  <strong>{{ mingaName }}</strong
  >?
</p>

<mg-date-time-picker
  datePlaceholder="Content Start Date"
  timePlaceholder="Start Time"
  class="post-step-spacing post-input event-start"
  [(ngModel)]="startDate"
  (ngModelChange)="onStartDateChange()"
  (onDateOnlyChange)="onAllDayChange()"
  [mgFormStyles]="false"
  [dateOnly]="true"></mg-date-time-picker>

<div class="footer-button-container">
  <mg-button
    type="text"
    (click)="cancel()"
    >{{ 'button.cancel' | translate }}</mg-button
  >
  <mg-button
    type="outline"
    (click)="add()"
    >Add</mg-button
  >
</div>
