<ng-container *ngIf="groupsLists$ | async as groupsLists; else loadingTpl">
  <ng-container
    *ngIf="
      groupsLists.available.length > 0 || groupsLists.picked.length > 0;
      else noGroups
    ">
    <ng-container *ngIf="groupsLists.picked.length > 0; else noPickedGroups">
      <ng-content select="[pickedTitle]"></ng-content>
      <mg-group-list
        [clickMode]="clickMode"
        [noSuccessInterstitial]="noSuccessInterstitial"
        [groups]="groupsLists.picked"></mg-group-list>
    </ng-container>

    <ng-container *ngIf="groupsLists.available.length > 0; else noAvailGroups">
      <ng-content select="[availableTitle]"></ng-content>
      <mg-group-list
        [clickMode]="clickMode"
        [noSuccessInterstitial]="noSuccessInterstitial"
        [groups]="groupsLists.available"></mg-group-list>
    </ng-container>

    <ng-template #noAvailGroups>
      <ng-content select="[noAvailableGroups]"></ng-content>
    </ng-template>

    <ng-template #noPickedGroups>
      <ng-content select="[noPickedGroups]"></ng-content>
    </ng-template>
  </ng-container>

  <ng-template #noGroups>
    <ng-content select="[noGroups]"></ng-content>
  </ng-template>
</ng-container>

<ng-template #loadingTpl>
  <div class="mg-centered">
    <mg-spinner
      diameter="30"
      thickness="4"></mg-spinner>
  </div>
</ng-template>
