import * as day from 'dayjs';
import * as dayOfYear from 'dayjs/plugin/dayOfYear';

day.extend(dayOfYear);

export function displayTime(time: number): string {
  if (time === 0) return '';
  return day(time).format('h:mm A');
}

export function displayEventDatesLine1(
  startInput: any,
  endInput: any,
  shortDayFormat: boolean = false,
) {
  const start = day(startInput || null);
  const end = day(endInput || null);

  if (!start.isValid()) {
    return '';
  }

  const dayFormat = shortDayFormat ? 'ddd' : 'dddd';
  if (!end.isValid()) {
    return '';
  } else if (start.dayOfYear() === end.dayOfYear()) {
    return start.format(`${dayFormat}, MMM D`);
  } else {
    return start.format(`${dayFormat}, MMM D h:mm A`);
  }
}

export function displayEventDatesLine2(
  startInput: any,
  endInput: any,
  shortDayFormat: boolean = false,
  allDay: boolean = false,
) {
  const start = day(startInput || null);
  const end = day(endInput || null);

  if (!start.isValid()) {
    return '';
  }

  const dayFormat = shortDayFormat ? 'ddd' : 'dddd';
  if (start.dayOfYear() === end.dayOfYear()) {
    return allDay
      ? `All Day Event`
      : start.format('h:mm A') + ' - ' + end.format('h:mm A');
  } else if (end.isValid()) {
    let endFormat = allDay
      ? `${dayFormat}, MMM D`
      : `${dayFormat}, MMM D h:mm A`;
    return 'until ' + end.format(endFormat);
  }

  return '';
}

export function displayEventDates(startInput: any, endInput: any) {
  const start = day(startInput || null);
  const end = day(endInput || null);
  if (!start.isValid()) {
    return '';
  }

  const startString =
    start.minute() == 0
      ? start.format('MMM D h A ')
      : start.format('MMM D h:mm A ');

  if (!end.isValid()) {
    return startString;
  } else if (start.dayOfYear() === end.dayOfYear()) {
    const endString =
      end.minute() == 0 ? end.format('h A ') : end.format('h:mm A ');
    return startString + ' - ' + endString;
  } else {
    return startString + ' - ' + end.format('MMM D h:mm A');
  }
}

export function displayEventDatesSingleLine(startInput: any, endInput: any) {
  const start = day(startInput || null);
  const end = day(endInput || null);
  if (!start.isValid()) {
    return '';
  }

  const startString =
    start.minute() == 0 ? start.format('h A ') : start.format('h:mm A ');

  if (!end.isValid()) {
    return startString;
  } else if (start.dayOfYear() === end.dayOfYear()) {
    const endString =
      end.minute() == 0 ? end.format('h A ') : end.format('h:mm A ');
    return startString + ' - ' + endString;
  } else {
    return startString + ' - ' + end.format('MMM D h:mm A');
  }
}

export function isEventOver(startInput: any, endInput: any, allDay?: boolean) {
  let end = day(endInput || null);
  if (!end.isValid()) {
    end = day(startInput || null);
  }

  const now = day(new Date());
  const diff = now.diff(end);

  return diff > 0;
}

export function isEventHappening(startInput: any, endInput: any) {
  const now = day(new Date());

  // if no end date, set it to be happening still
  let endDiff = -1;
  if (endInput) {
    let end = day(endInput || null);
    if (end.isValid()) {
      endDiff = now.diff(end);
    }
  }

  // let them check in an hour before start
  let start = day(startInput || null).subtract(1, 'hour');
  const startDiff = now.diff(start);

  return startDiff > 0 && endDiff < 0;
}
