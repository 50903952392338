export enum CarouselStylePresets {
  LIST = 0,
  TWO_X_THREE,
  THREE_X_THREE,
  SIX_X_TWO,
  THREE_X_FOUR,
  VERT_LIST,
}

export const presetGridArrayValues: Record<CarouselStylePresets, number[]> = {
  [CarouselStylePresets.LIST]: [0, 1],
  [CarouselStylePresets.TWO_X_THREE]: [2, 3],
  [CarouselStylePresets.THREE_X_THREE]: [3, 3],
  [CarouselStylePresets.SIX_X_TWO]: [6, 2],
  [CarouselStylePresets.THREE_X_FOUR]: [3, 4],
  [CarouselStylePresets.VERT_LIST]: [1, 0],
};

export const DEFAULT_VERT_LIST_TILES = 6;
