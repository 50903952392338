import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';

import { MgEmptyStateModule } from 'minga/app/src/app/components/EmptyState';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgIconModule } from 'minga/app/src/app/icon';
import { ScrollTargetDirectiveModule } from 'minga/app/src/app/misc/ScrollTarget/directive';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { PeopleSearchInputModule } from 'src/app/components/people-search-input';
import { MgDirectivesModule } from 'src/app/directives';
import { PermissionsModule } from 'src/app/permissions';

import { GenericButtonModule } from '@shared/components/button';
import { CollapsibleModule } from '@shared/components/collapsible';
import { CountTileModule } from '@shared/components/count-tile';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { OverlayModule } from '@shared/components/modal-overlay';
import { MultiPersonSearchModule } from '@shared/components/multi-person-search';
import { ReportTableModule } from '@shared/components/report-table';
import { SelectFieldsModule } from '@shared/components/select-fields';
import { SystemAlertModule } from '@shared/components/system-alert';
import { TextModule } from '@shared/components/text';
import { UserListFilterModule } from '@shared/components/user-list-filter/user-list-filter.module';
import { ArrayPipesModule } from '@shared/pipes';

import {
  HpmReportsCreatorComponent,
  HpmReportsHistoryComponent,
  HpmReportsLateComponent,
  HpmReportsSummaryComponent,
  HpmReportsTypesComponent,
  HpmReportsUserComponent,
} from './components';
import { HpmReportsHistoryEditComponent } from './components/hpm-reports-history/hpm-reports-history-edit.component';
import { HpmReportsComponent } from './hpm-reports.component';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    MgSpinnerModule,
    PeopleSearchInputModule,
    SelectFieldsModule,
    MgEmptyStateModule,
    GenericButtonModule,
    MgIconModule,
    MgFormFieldModule,
    ScrollTargetDirectiveModule,
    FormModule,
    CountTileModule,
    MultiPersonSearchModule,
    NgSelectModule,
    CollapsibleModule,
    MgDirectivesModule,
    ReportTableModule,
    GenericModule,
    TextModule,
    ArrayPipesModule,
    OverlayModule,
    SystemAlertModule,
    UserListFilterModule,
    // external dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatButtonToggleModule,
    ChartsModule,
    RouterModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSortModule,
    PermissionsModule,
  ],
  exports: [HpmReportsComponent],
  declarations: [
    HpmReportsComponent,
    HpmReportsSummaryComponent,
    HpmReportsTypesComponent,
    HpmReportsUserComponent,
    HpmReportsHistoryComponent,
    HpmReportsCreatorComponent,
    HpmReportsLateComponent,
    HpmReportsHistoryEditComponent,
  ],
})
export class HpmReportsModule {}
