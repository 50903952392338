import { Pipe, PipeTransform } from '@angular/core';
import { Html5Entities } from 'html-entities';

@Pipe({ name: 'mgEscapedHtml' })
export class MgEscapedHtmlPipe implements PipeTransform {
  transform(value: any): string {
    const entities = new Html5Entities();
    return entities.encode(value);
  }
}
