import { NgModule } from '@angular/core';

import { HasPermissionDirective } from './HasPermission.directive';
import { HasPermissionPipe } from './HasPermission.pipe';

@NgModule({
  providers: [],
  declarations: [HasPermissionDirective, HasPermissionPipe],
  exports: [HasPermissionDirective, HasPermissionPipe],
})
export class PermissionsModule {}
