import * as localforage from 'localforage';

export interface IStorageInstanceOptions {
  /** Name of the storage instance. Used to identify local storage */
  name: string;

  /** Duration in milliseconds when memory storage gets automatically deleted */
  memoryStorageTimeout: number;

  /** @SEE LocalForageOptions */
  storeName?: string;

  /** @SEE LocalForageOptions */
  driver?: string | string[];

  /** @SEE LocalForageOptions */
  size?: number;

  /** @SEE LocalForageOptions */
  version?: number;

  /** @SEE LocalForageOptions */
  description?: string;
}

export interface IStorageInstance<T> {
  getItem(key: string): Promise<T | null>;
  setItem(key: string, item: T): Promise<T>;
  removeItem(key: string): Promise<void>;
}

class StorageInstance<T> implements IStorageInstance<T> {
  /** @internal */
  private memMap = new Map<string, { value: T; timeout: any }>();

  /** @internal */
  private inst: LocalForage;

  /** @internal */
  private readonly memoryStorageTimeout: number;

  constructor(inst: LocalForage, timeout: number) {
    this.inst = inst;
    this.memoryStorageTimeout = timeout;
  }

  /** @internal */
  private setMemStorageItem(key: string, value: T) {
    const memStored = this.memMap.get(key);
    if (memStored) {
      clearTimeout(memStored.timeout);
    }

    const timeout = setTimeout(
      () => this.memMap.delete(key),
      this.memoryStorageTimeout,
    );
    this.memMap.set(key, { value, timeout });
  }

  async setItem(key: string, value: T) {
    this.setMemStorageItem(key, value);
    return await this.inst.setItem<T>(key, value);
  }

  async getItem(key: string): Promise<T | null> {
    const memStored = this.memMap.get(key);
    if (memStored) {
      return memStored.value;
    }

    const value = (await this.inst.getItem<T>(key)) || null;
    if (value !== null) {
      this.setMemStorageItem(key, value);
    }
    return value;
  }

  async removeItem(key: string): Promise<void> {
    const memStored = this.memMap.get(key);
    if (memStored) {
      clearTimeout(memStored.timeout);
      this.memMap.delete(key);
    }
    return await this.inst.removeItem(key);
  }
}

/**
 * Creates an IStorageInstance that stores values in local storage as well as in
 * memory for quick access. The memory storage is cleared by a timeout specified
 * by `options.memoryStorageTimeout`
 */
export function createStorageInstance<T>(
  options: IStorageInstanceOptions,
): IStorageInstance<T> {
  const { memoryStorageTimeout } = options;
  const localForageOptions: LocalForageOptions = {
    name: options.name,
  };

  if ('storeName' in options) {
    localForageOptions.storeName = options.storeName;
  }

  if ('driver' in options) {
    localForageOptions.driver = options.driver;
  }

  if ('size' in options) {
    localForageOptions.size = options.size;
  }

  if ('version' in options) {
    localForageOptions.version = options.version;
  }

  if ('description' in options) {
    localForageOptions.description = options.description;
  }

  const inst = localforage.createInstance(localForageOptions);
  return new StorageInstance<T>(inst, memoryStorageTimeout);
}
