import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { MgeIconModule } from 'minga/app/src/app/elements/MgeIcon';
import { MgIconModule } from 'minga/app/src/app/icon';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { UgcLinksHighlightPipeModule } from 'minga/app/src/app/ugc-links/pipes/UgcLinksHighlight';

import { MgChallengeMiniCardElement } from './MgChallengeMiniCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    UgcLinksHighlightPipeModule,
    MgPipesModule,
    MgeIconModule,
    MgContentLinkModule,
    PermissionsModule,
    MgIconModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [MgChallengeMiniCardElement],
  exports: [MgChallengeMiniCardElement],
})
export class MgChallengeMiniCardModule {}
