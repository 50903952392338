<div class="input-group vmargin-only">
  <div *hasPermission="'CONTENT_PUBLISH_DATES_SET'">
    <mg-date-time-picker
      [hideOptionalMarker]="true"
      name="publishDate"
      [canReset]="true"
      datePlaceholder="Publish date"
      timePlaceholder="Publish time"
      [stackInputs]="stackInputs"
      [(ngModel)]="publishDate"
      (ngModelChange)="onPublishDateChange($event)">
    </mg-date-time-picker>
  </div>

  <div *hasPermission="'CONTENT_PUBLISH_DATES_SET'">
    <mg-date-time-picker
      [hideOptionalMarker]="true"
      name="unpublishDate"
      [canReset]="true"
      [stackInputs]="stackInputs"
      [datePlaceholder]="'Unpublish date'"
      dateHintLocale="content.contentControl.unpublishHint"
      timePlaceholder="Unpublish time"
      [(ngModel)]="unpublishDate"
      (ngModelChange)="onUnpublishDateChange($event)">
    </mg-date-time-picker>
  </div>

  <div *hasPermission="'MINGA_CONTENT_PIN'">
    <mat-slide-toggle
      class="mg-minicopy"
      [(ngModel)]="pinned"
      (ngModelChange)="onPinnedChange($event)">
      <strong>Pin this post?</strong>
    </mat-slide-toggle>
    <mat-hint
      >This will stick this piece of content to the top of the feed for the
      selected number of days.</mat-hint
    >
    <div *ngIf="pinned">
      <mat-form-field
        mgFormField
        *ngIf="!readonlyPinDays; else showRemainingPinTime">
        <span matPrefix>For&nbsp;</span>
        <mat-select
          name="pinDays"
          [(ngModel)]="pinDays"
          (ngModelChange)="onPinnedChange(pinned)">
          <mat-option [value]="1">1 day</mat-option>
          <mat-option [value]="2">2 days</mat-option>
          <mat-option [value]="3">3 days</mat-option>
          <mat-option [value]="4">4 days</mat-option>
          <mat-option [value]="5">5 days</mat-option>
          <mat-option [value]="6">6 days</mat-option>
          <mat-option [value]="7">7 days</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-template #showRemainingPinTime>
        <div>{{ pinRemainingTime(endPinDate) }}</div>
      </ng-template>
    </div>
  </div>

  <div *ngIf="enableEmailContentOption">
    <mat-slide-toggle
      class="mg-minicopy"
      [(ngModel)]="emailContent"
      (ngModelChange)="emailContentChange.next($event)">
      <strong>Send as email?</strong>
    </mat-slide-toggle>
    <mat-hint
      >If this content is scheduled to be published at a later date the email
      will not be sent until then.</mat-hint
    >

    <div
      class="sub-options"
      *ngIf="emailContent">
      <div *ngIf="emailContentRoleOptions.length > 0">
        <strong>Send email to specific roles only?</strong>
        <mg-multi-select-role
          [defaultSelected]="true"
          [disallowDeselect]="emailContentRoles.length <= 1"
          [roles]="emailContentRoleOptions"
          [(selectedRoles)]="emailContentRoles"
          (selectedRolesChange)="emailContentRolesChange.emit($event)">
        </mg-multi-select-role>
      </div>

      <mat-slide-toggle
        class="mg-minicopy"
        [(ngModel)]="emailContentIncludeUnregistered"
        (ngModelChange)="emailContentIncludeUnregisteredChange.next($event)">
        <strong>Include unregistered</strong>
      </mat-slide-toggle>
      <mat-hint
        >Send content email to unregistered (never logged in) users as
        well.</mat-hint
      >
    </div>
  </div>
  <div *ngIf="enableCommentingRoles">
    <div *hasPermission="'CONTENT_MANAGE_COMMENT_SETTINGS'">
      <mg-comment-settings-selection
        (commentingRolesChange)="onCommentingRolesChange($event)"
        [(commentingRoles)]="commentingRoles"></mg-comment-settings-selection>
    </div>
  </div>
</div>
