<!-- @HACK: to get styles for calendar this element is here... -->
<mat-calendar [style.display]="'none'"></mat-calendar>

<div
  class="mat-calendar"
  [class.weekView]="weekView"
  [class.weekViewHideAll]="weekViewHideAll"
  [ngClass]="getCalendarClasses()">
  <mg-calendar-header [currentView]="currentView"></mg-calendar-header>

  <!-- @TODO: Bazel - I moved this out. It doesn't exist on MatCalendar anymore. Need to find out what to do with this. -->
  <!-- (keydown)="_handleCalendarBodyKeydown($event)" -->
  <div
    class="mat-calendar-content"
    [ngSwitch]="currentView"
    cdkMonitorSubtreeFocus
    tabindex="-1">
    <mg-month-view
      *ngSwitchCase="'month'"
      [multiSelectDays]="selectedDays"
      [(activeDate)]="activeDate"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [filteringDays]="filteringDays"
      (selectedChange)="onSelectedChange($event)"
      (_userSelection)="_userSelected()"
      (onLastRowClick)="toggleExpansion()"></mg-month-view>

    <mat-year-view
      *ngSwitchCase="'year'"
      [activeDate]="activeDate"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [maxDate]="maxDate"
      [minDate]="minDate"
      (selectedChange)="_goToDateInView($event, 'month')">
    </mat-year-view>

    <mat-multi-year-view
      *ngSwitchCase="'multi-year'"
      [activeDate]="activeDate"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [maxDate]="maxDate"
      [minDate]="minDate"
      (selectedChange)="_goToDateInView($event, 'year')">
    </mat-multi-year-view>
  </div>

  <div
    class="calendar-collapse-toggle"
    (click)="toggleExpansion()"
    matRipple>
    <div class="calendar-collapse-toggle-bg"></div>
    <button
      mat-icon-button
      class="mat-calendar-previous-button toggle"
      [disableRipple]="true"></button>
    <!-- <mg-icon>up-toggle</mg-icon> -->
  </div>
</div>
