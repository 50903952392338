<div
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  class="container">
  <div>
    <!-- Max Selection Instructions -->
    <ng-container
      *ngIf="(form.pages.activePage$ | async).maxSelection as maxSelection">
      <mg-system-alert
        [type]="'default'"
        [message]="
          maxSelection > 1
            ? 'You can select up to ' + maxSelection + ' people'
            : 'You can select only one person'
        ">
      </mg-system-alert>
    </ng-container>

    <!-- Tooltip -->
    <ng-container *ngIf="(form.pages.activePage$ | async).tooltip as tooltip">
      <mg-system-alert
        [type]="'default'"
        [message]="tooltip">
      </mg-system-alert>
    </ng-container>

    <!-- Selection Table -->
    <ng-container
      *ngIf="form.selection.hasSelection$ | async"
      [ngTemplateOutlet]="tableTemplate"
      [ngTemplateOutletContext]="{
        $implicit: form.selection.selection$ | async,
        type: 'selected',
        showHeaders: true,
        subHeaderTitle: 'Selected'
      }">
    </ng-container>
    <!-- Search Results Table -->
    <ng-container
      [ngTemplateOutlet]="tableTemplate"
      [ngTemplateOutletContext]="{
        $implicit: form.searchResults.dataSource,
        type: 'searchResults',
        showHeaders: (form.selection.hasSelection$ | async) === false,
        subHeaderTitle: 'Search Results'
      }">
    </ng-container>
    <!-- Loading Spinner -->
    <div
      *ngIf="form.searchResults.isLoading$ | async; else emptyStateTemplate"
      class="loading-spinner-container">
      <mg-spinner
        [thickness]="2"
        [diameter]="50">
      </mg-spinner>
    </div>
    <!-- Empty State -->
    <ng-template #emptyStateTemplate>
      <ng-container
        *ngIf="
          (form.pages.activePageType$ | async) === 'search';
          else listEmptyStateTemplate
        ">
        <mg-empty-state
          *ngIf="form.searchResults.size === 0"
          [title]="
            (form.searchResults.noResults$ | async)
              ? MSG.NO_RESULTS_TITLE
              : MSG.START_SEARCH_TITLE
          "
          [subtitle]="
            (form.searchResults.noResults$ | async)
              ? MSG.NO_RESULTS_SUBTITLE
              : MSG.START_SEARCH_SUBTITLE
          ">
        </mg-empty-state>
      </ng-container>
      <ng-template #listEmptyStateTemplate>
        <mg-empty-state
          *ngIf="
            (form.selection.hasSelection$ | async) === false &&
            form.searchResults.dataSource.filteredData.length === 0
          "
          [title]="MSG.NO_RESULTS_TITLE"
          [subtitle]="MSG.NO_RESULTS_SUBTITLE">
        </mg-empty-state>
      </ng-template>
    </ng-template>
  </div>

  <!-- Pagination -->
  <div
    fxLayout="row"
    fxLayoutAlign="center center">
    <mg-paginator
      class="paginator"
      [pageSizeOptions]="[100, 50, 20]">
    </mg-paginator>
  </div>
</div>

<ng-template
  #tableTemplate
  let-data
  let-type="type"
  let-showHeader="showHeaders"
  let-title="subHeaderTitle">
  <mat-table
    matSort
    [dataSource]="data"
    [trackBy]="trackById">
    <!-- Select Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mg-form-checkbox
          class="checkbox"
          [checked]="masterToggleCheckedState$ | async"
          [indeterminate]="isAllSelected()"
          [disabled]="
            (form.pages.activePage$ | async).maxSelection >= 1 ||
            ((form.selection.hasSelection$ | async) === false &&
              form.searchResults.size === 0)
          "
          (changed)="masterToggle()">
        </mg-form-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let person">
        <mg-form-checkbox
          class="checkbox"
          [checked]="isSelected(person)"
          [disabled]="
            type !== 'selected' && (canMakeMoreSelections$ | async) === false
          "
          (onClick)="$event.stopPropagation()"
          (changed)="$event ? toggleSelection(type, person) : null">
        </mg-form-checkbox>
      </mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="displayName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_NAME }}
      </mat-header-cell>
      <mat-cell *matCellDef="let person">
        <div
          class="multiline-ellipsis display-name"
          [title]="person.displayName">
          {{ person.displayName }}
        </div>
        <span
          *ngIf="person?.badge as badgeImgUrl"
          class="badgeIcon"
          [style.background-image]="'url(' + badgeImgUrl + ')'">
        </span>
      </mat-cell>
    </ng-container>
    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_EMAIL }}
      </mat-header-cell>
      <mat-cell *matCellDef="let person">
        <div
          class="multiline-ellipsis"
          [title]="person.email">
          {{ person.email }}
        </div>
        <span
          *ngIf="!person.email"
          class="no-data">
          {{ MSG.NO_CONTENT_PLACEHOLDER }}
        </span>
      </mat-cell>
    </ng-container>
    <!-- Student ID Column -->
    <ng-container matColumnDef="studentId">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_STUDENT_ID }}
      </mat-header-cell>
      <mat-cell *matCellDef="let person">
        <div
          class="multiline-ellipsis"
          [title]="person.studentId">
          {{ person.studentId }}
        </div>
        <span
          *ngIf="!person.studentId"
          class="no-data">
          {{ MSG.NO_CONTENT_PLACEHOLDER }}
        </span>
      </mat-cell>
    </ng-container>
    <!-- School Column -->
    <ng-container matColumnDef="school">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_SCHOOL }}
      </mat-header-cell>
      <mat-cell *matCellDef="let person">
        {{ person.school }}
        <span
          *ngIf="!person.school"
          class="no-data">
          {{ MSG.NO_CONTENT_PLACEHOLDER }}
        </span>
      </mat-cell>
    </ng-container>
    <!-- Grade Column -->
    <ng-container matColumnDef="grade">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_GRADE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let person">
        {{ person.grade }}
        <span
          *ngIf="!person.grade"
          class="no-data">
          {{ MSG.NO_CONTENT_PLACEHOLDER }}
        </span>
      </mat-cell>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_STATUS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let person">
        {{ person.status }}
        <span
          *ngIf="!person.status"
          class="no-data">
          {{ MSG.NO_CONTENT_PLACEHOLDER }}
        </span>
      </mat-cell>
    </ng-container>
    <!-- SubHeader Column -->
    <ng-container matColumnDef="subHeader">
      <mat-cell *matHeaderCellDef>
        {{ title }} ({{
          (type === 'selected' ? data?.length : data.filteredData.length) || 0
        }})
      </mat-cell>
    </ng-container>
    <!-- Mobile Column -->
    <ng-container matColumnDef="mobile">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_MOBILE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let person">
        <div fxLayout="column">
          <!-- Name and badge -->
          <div fxLayout="row">
            <mg-text
              class="multiline-ellipsis nameRow"
              variant="header-sm-secondary"
              [title]="person.displayName">
              {{ person.displayName }}
            </mg-text>
            <span
              *ngIf="person?.badge as badgeImgUrl"
              class="badgeIcon"
              [style.background-image]="'url(' + badgeImgUrl + ')'">
            </span>
          </div>
          <!-- Student Id -->
          <div fxLayout="row">
            <mg-text
              class="multiline-ellipsis"
              variant="body-xs"
              color="surface-alt">
              {{ 'ID number:' }}
            </mg-text>
            <mg-text
              class="multiline-ellipsis"
              variant="body-xs">
              <span class="ml-small">
                {{ person.studentId }}
              </span>
            </mg-text>
            <span
              *ngIf="!person.studentId"
              class="no-data">
              <mg-text
                class="multiline-ellipsis"
                variant="body-xs"
                color="on-disabled-bg">
                {{ MSG.NO_CONTENT_PLACEHOLDER }}
              </mg-text>
            </span>
          </div>
          <!-- Grade -->
          <div fxLayout="row">
            <mg-text
              class="multiline-ellipsis"
              variant="body-xs"
              color="surface-alt">
              {{ 'Grade:' }}
            </mg-text>
            <mg-text
              class="multiline-ellipsis"
              variant="body-xs">
              <span class="ml-small">
                {{ person.grade }}
              </span>
            </mg-text>
            <span
              *ngIf="!person.grade"
              class="no-data">
              <mg-text
                class="multiline-ellipsis"
                variant="body-xs"
                color="on-disabled-bg">
                {{ MSG.NO_CONTENT_PLACEHOLDER }}
              </mg-text>
            </span>
          </div>
          <!-- Status -->
          <div
            fxLayout="row"
            *ngIf="person.status">
            <mg-text
              class="multiline-ellipsis"
              variant="body-xs"
              color="surface-alt">
              {{ 'Status:' }}
            </mg-text>
            <mg-text
              class="multiline-ellipsis"
              variant="body-xs">
              <span class="ml-small">
                {{ person.status }}
              </span>
            </mg-text>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="showHeader ? (displayedColumns$ | async) : []"
      [fxHide]="!showHeader">
    </mat-header-row>

    <mat-header-row
      *matHeaderRowDef="['subHeader']"
      class="table-header"
      [fxHide]="
        type === 'selected' ? !data?.length : !data.filteredData.length
      ">
    </mat-header-row>

    <mat-row
      *matRowDef="let row; columns: displayedColumns$ | async; let i = index"
      [class.disable-selection]="
        type !== 'selected' && (canMakeMoreSelections$ | async) === false
      "
      (click)="toggleSelection(type, row)">
    </mat-row>
  </mat-table>
</ng-template>
