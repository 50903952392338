import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgImageModule } from 'src/app/components/MgImage';
import { MgIconModule } from 'src/app/icon';

import { TextModule } from '@shared/components/text';

import { SaTileComponent } from './components/sa-tile/sa-tile.component';

@NgModule({
  declarations: [SaTileComponent],
  exports: [SaTileComponent],
  imports: [
    CommonModule,
    TextModule,
    MgImageModule,
    MgIconModule,
    FlexLayoutModule,
  ],
})
export class SaSharedModule {}
