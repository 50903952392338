import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { ListAutomationActionPayload } from 'minga/domain/actions';

import { AutomationActionsService } from '@shared/services/automation';

import { BehaviorMsgCategory } from '../../constants';
import { BmTypesAutomationService } from '../../services';
import { ICON_CONFIG_DEFAULTS } from '../bm-types-consequence-edit/bm-types-consequence-edit.constants';

@Component({
  selector: 'mg-bm-types-automation-step',
  templateUrl: './bm-types-automation-step.component.html',
  styleUrls: ['./bm-types-automation-step.component.scss'],
})
export class BmTypesAutomationStepComponent implements OnInit, OnDestroy {
  /** General subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Loading State */
  private readonly _loadingSubject = new BehaviorSubject<boolean>(true);
  public readonly loading$ = this._loadingSubject.asObservable();

  /** Steps Data */
  private readonly _stepsDataSubject = new BehaviorSubject<
    ListAutomationActionPayload[]
  >([]);
  public readonly stepsData$ = this._stepsDataSubject.asObservable();

  private _refetchSubscription: Subscription;

  /** Inputs */
  @Input()
  readonly automationGroupId: number;

  /** Outputs */
  @Output()
  readonly stepPressed = new EventEmitter<number>();

  public fallbackIcons = {
    [BehaviorMsgCategory.PRAISE]:
      ICON_CONFIG_DEFAULTS[BehaviorMsgCategory.PRAISE],
    [BehaviorMsgCategory.GUIDANCE]:
      ICON_CONFIG_DEFAULTS[BehaviorMsgCategory.GUIDANCE],
  };

  /** Component Constructor */
  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    private _automationSteps: AutomationActionsService,
    private _bmAutomation: BmTypesAutomationService,
  ) {}

  ngOnInit(): void {
    this._fetchData();
    this._bmAutomation.automationGroupUpdated$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(({ id }) => {
        if (id === this.automationGroupId) this._fetchData();
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._stepsDataSubject.complete();
    this._loadingSubject.complete();
    this._refetchSubscription?.unsubscribe();
  }

  private async _fetchData(): Promise<void> {
    this._loadingSubject.next(true);
    this._stepsDataSubject.next([]);
    const steps = await this._automationSteps.fetchAll(this.automationGroupId);
    this._stepsDataSubject.next(steps);
    this._loadingSubject.next(false);
  }
}
