import { IMingaFeatureToggleKeys } from 'minga/domain/featureToggle';

export enum MingaManagerMessages {
  PAGE_TITLE = 'Minga Manager',
  PAGE_TITLE_DASHBOARD = 'Dashboard',
  PAGE_TITLE_REPORTS = 'Reports',
  PAGE_TITLE_SCHEDULE_REPORTS = 'Scheduled Reports',
  PAGE_TITLE_SETTINGS = 'Settings',
  PAGE_HEADER_TITLE = 'Minga',

  SETTING_POSTING = 'Posting Preferences',
  SETTING_GALLERY = 'Photo Gallery Preferences',
  SETTING_GROUP = 'Group Preferences',
  SETTING_DATE_PRESETS = 'Date Range Presets',
  SETTING_MESSAGING = 'Direct Messages',
  SETTING_MODERATION = 'Moderation',
  SETTING_RESETS = 'Minga Resets',
  SETTING_PRIVACY = 'Data Privacy Settings',
  SETTING_SMS = 'SMS Settings',
  SETTING_FEATURE_ROLLOUT = 'App Feature Rollout',

  SETTING_FEATURE_BETA = 'Beta group',
  SETTING_FEATURE_CUSTOMER_COUNCIL = 'Customer council group',

  SETTING_ENABLE_SMS = 'Enable SMS',
  SETTING_ENABLE_SMS_TITLE_DISABLING = 'Are you sure you want to disable SMS?',
  SETTING_ENABLE_SMS_BODY_DISABLING = 'SMS will come from a new number next time you enable SMS again.',

  TOOLTIP_MONTHLY_DIGEST = 'When enabled, inactive users will receive a monthly digest email on the first of every month letting them know what they are missing.',
  TOOLTIP_PHOTO_GALLERY = 'Allow users in your Minga to add images to the Photo Gallery.',
  TOOLTIP_JOIN_CODE_SCHOOL = 'This will generate a 4 letter code that can be used to join your Minga. Choose if students, parents, or both can join. If you select both, users will self-select their role when they join.',
  TOOLTIP_JOIN_CODE = 'This will generate a 4 letter code that can be used to join your Minga - anyone with this code will be able to join.',
  TOOLTIP_DIRECT_MESSAGE = 'Enables direct messaging between people in your Minga.',
  TOOLTIP_MODERATION = 'Enables automatic checking of submitted content for inappropriate material.',
  TOOLTIP_MODERATION_BLOCK = 'If disabled, users will be warned of their content containing inappropriate material, but may submit anyway.',
  TOOLTIP_POINTS_RESET = 'Reset Leaderboard to wipe all users rankings.',
  TOOLTIP_POINTS_HISTORY_RESET = 'Reset Points and Leaderboard to wipe all users points and rankings.',
  TOOLTIP_GROUPS = 'Reset Groups and Members to delete all groups and their content, and to remove their members.',
  TOOLTIP_GROUP_MEMBER = 'Remove all Group Members except its owner(s)',
  TOOLTIP_CONTENT = 'Remove all content from all feeds.',
  TOOLTIP_SIS = 'Enable SIS integrations for this school.',
  TOOLTIP_HIDE_ID_NUMBER = 'Hide student ID numbers in ID Manager and on digital ID look up from Teachers and Staff.',
  TOOLTIP_DISTRICT = 'Enable School Groups to be created within a Minga to host multiple schools in a district.',
  TOOLTIP_DELETE_ACCOUNT = 'Allow individual users to delete their own account.',
  TOOLTIP_STUDENT_EMAIL_CHANGE = 'Allow students to change the email associated with their account.',
  TOOLTIP_AUTOMATION_RESET = 'Reset all counters for all automations.',
  TOOLTIP_USER_LISTS_RESET = 'Remove all lists from user lists.',

  //@TODO Update tooltip when design is out
  TOOLTIP_LEGACY_LAYOUT = 'Enable legacy layout. Currently in development',

  NAME_INPUT_LABEL = 'Search by minga name',
  NAME_INPUT_PLACEHOLDER = 'Search by minga name',

  STATE_FILTER_LABEL = 'State',

  BUTTON_RESET = 'Reset',
  BUTTON_SAVE = 'Save',

  DOWNLOAD_REPORT_TITLE = 'Download Past Download',
  DOWNLOAD_REPORT_CLOSE_BTN = 'Close',
  DOWNLOAD_REPORT_DOWNLOAD_BTN = 'Download',
  DOWNLOAD_REPORT_ERROR = 'There was a problem downloading your report',
}

export enum MingaManagerRoutes {
  ROOT = 'minga-manager',
  DASHBOARD = 'dashboard',
  DISTRICTS = 'districts',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  SCHEDULED_REPORTS = 'scheduled-reports',
  SUBSCRIPTION = 'subscription',
}

export enum MingaType {
  MINGA = 'MINGA',
  CONFERENCE = 'CONFERENCE',
}

export enum MingaStatus {
  DEMO = 'DEMO',
  EVAL = 'EVAL',
  TESTING = 'TESTING',
  LIVE = 'LIVE',
  EXPIRED = 'EXPIRED',
  EVAL_OVER = 'EVAL_OVER',
  PENDING_DELETION = 'PENDING_DELETION',
  TRIAL = 'TRIAL',
}

export enum SchoolType {
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
  ELEMENTARY = 'ELEMENTARY',
  K12 = 'K12',
  PRIVATE = 'PRIVATE',
  OTHER = 'OTHER',
}

export const MINGA_STATUS_LABEL_MAP: Record<MingaStatus, string> = {
  DEMO: 'Demo',
  EVAL: 'Evaluation',
  EVAL_OVER: 'EVAL_OVER',
  EXPIRED: 'Expired',
  LIVE: 'Live',
  PENDING_DELETION: 'Pending Deletion',
  TESTING: 'Testing',
  TRIAL: 'Trial',
};

export const MINGA_STATUS_FILTER_MAP: Record<number, MingaStatus> = {
  0: MingaStatus.DEMO,
  1: MingaStatus.EVAL,
  2: MingaStatus.TESTING,
  3: MingaStatus.LIVE,
  4: MingaStatus.EXPIRED,
  5: MingaStatus.EVAL_OVER,
  6: MingaStatus.PENDING_DELETION,
  7: MingaStatus.TRIAL,
};

export const MINGA_MODULE_LABEL_MAP: Record<IMingaFeatureToggleKeys, string> = {
  checkinEnabled: 'Check In',
  communityEnabled: 'Community',
  directMessagingEnabled: 'Direct Messaging',
  hallPassEnabled: 'Hall Pass',
  photoGalleryEnabled: 'Photo Gallery',
  studentIdEnabled: 'Digital ID',
  trackingEnabled: 'PBIS',
  flexTimeEnabled: 'Flex Time',
};

export const schoolTypes = {
  HIGH_SCHOOL: 'Highschool',
  MIDDLE_SCHOOL: 'Middle School',
  ELEMENTARY: 'Elementary',
  K12: 'K12',
  PRIVATE: 'Private',
  OTHER: 'Other',
};
