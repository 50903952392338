import { GroupDetailsActions, GroupMemberActions } from '../actions';
import { GroupDetailState } from '../state';

export function groupDetailReducer(
  state: GroupDetailState = GroupDetailState.initialState,
  action: GroupDetailsActions.TypeUnion | GroupMemberActions.TypeUnion,
): GroupDetailState {
  switch (action.type) {
    case GroupDetailsActions.TypeEnum.LoadGroupDetailsSuccess: {
      if (state.entities[action.payload.hash]) {
        return GroupDetailState.entities.updateOne(
          {
            changes: action.payload,
            id: action.payload.hash,
          },
          state,
        );
      } else {
        return GroupDetailState.entities.addOne(action.payload, state);
      }
    }

    case GroupDetailsActions.TypeEnum.UpdateGroupDetailsLocally: {
      return GroupDetailState.entities.updateOne(
        {
          changes: action.groupDetails,
          id: action.groupDetails.hash,
        },
        state,
      );
    }
    case GroupMemberActions.TypeEnum.AcceptGroupMemberSuccess:
      return GroupDetailState.entities.updateOne(
        {
          changes: action.payload,
          id: action.payload.hash,
        },
        {
          ...state,
          loaded: false,
          loading: true,
        },
      );

    case GroupMemberActions.TypeEnum.DeclineGroupMemberSuccess:
      return GroupDetailState.entities.updateOne(
        {
          changes: action.payload,
          id: action.payload.hash,
        },
        {
          ...state,
          loaded: false,
          loading: true,
        },
      );

    case GroupMemberActions.TypeEnum.RemoveGroupMemberSuccess:
      return GroupDetailState.entities.updateOne(
        {
          changes: action.payload,
          id: action.payload.hash,
        },
        {
          ...state,
          loaded: false,
          loading: true,
        },
      );

    case GroupMemberActions.TypeEnum.UpdateGroupMembersSuccess:
    case GroupMemberActions.TypeEnum.AddGroupMembersSuccess: {
      // update group details from server response
      return GroupDetailState.entities.updateOne(
        {
          changes: action.payload,
          id: action.payload.hash,
        },
        state,
      );
    }

    default:
      return state;
  }
}
