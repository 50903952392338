import { Injectable } from '@angular/core';

import * as ul_member_pb from 'minga/proto/user_list/user_list_member_pb';
import * as user_list_pb from 'minga/proto/user_list/user_list_pb';
import {
  DEFAULT_USER_LIST,
  UpdateUserListPayload,
  UserList,
} from 'minga/domain/userList';
import { UserListMinimal } from 'minga/domain/userList';
import { UserListsMember } from 'minga/proto/user_list/user_list_member_ng_grpc_pb';
import { UserLists } from 'minga/proto/user_list/user_list_ng_grpc_pb';
import { userListMapper } from 'minga/shared-grpc/user_list';

import { ErrorHandlerService } from '@shared/services/error-handler';

@Injectable({ providedIn: 'root' })
export class UserListService {
  /** Service Constructor */
  constructor(
    private _userLists: UserLists,
    private _ulMember: UserListsMember,
    private _errorHandler: ErrorHandlerService,
  ) {}

  public async fetchAll(
    showInactive = true,
    pageSize = 1000,
    pageToken = 0,
  ): Promise<{ lists: UserList[]; totalLists: number }> {
    try {
      const request = new user_list_pb.ListUserListsRequest();
      request.setPageSize(pageSize);
      request.setPageToken(pageToken);
      request.setShowInactive(showInactive);
      const response = await this._userLists.listUserLists(request);
      const userLists = response.getUserListsList();
      const totalLists = parseInt(response.getNextPageToken(), 10);
      const lists = await Promise.all(
        userLists.map(async e => await userListMapper.fromProto(e)),
      );
      return { lists, totalLists };
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch all user lists',
        error,
        true,
      );
    }
  }

  public async fetchAllMinimal(opts?: {
    onlyMyLists?: boolean;
  }): Promise<UserListMinimal[]> {
    try {
      const request = new user_list_pb.GetUserListMinimalsRequest();
      request.setOnlyMyLists(opts?.onlyMyLists || false);
      const response = await this._userLists.getUserListMinimals(request);
      const userLists = response.getUserListsList();
      return userLists.map(userListMapper.fromProtoMinimal);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch all user list minimals',
        error,
        true,
      );
    }
  }

  public async fetch(id: number): Promise<UserList> {
    try {
      const request = new user_list_pb.GetUserListRequest();
      request.setId(id);
      const response = await this._userLists.getUserList(request);
      const mapped = await userListMapper.fromProto(response);
      return mapped;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch user list',
        error,
        true,
      );
    }
  }

  public async create(list = DEFAULT_USER_LIST): Promise<UserList> {
    try {
      const request = new user_list_pb.CreateUserListRequest();
      request.setActive(list.active);
      request.setTitle(list.title);
      request.setDescription(list.description);
      request.setPublic(list.public);
      request.setUuid(list.uuid);
      request.setManagerListId(list.managerListId);
      const response = await this._userLists.createUserList(request);
      return await userListMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to create user list',
        error,
        true,
      );
    }
  }

  public async update(list: UpdateUserListPayload): Promise<UserList> {
    try {
      const request = new user_list_pb.UpdateUserListRequest();
      request.setList(await userListMapper.toProto(list));
      const response = await this._userLists.updateUserList(request);
      return await userListMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to update user list',
        error,
        true,
      );
    }
  }

  public async delete(id: number): Promise<boolean> {
    try {
      const request = new user_list_pb.DeleteUserListRequest();
      request.setId(id);
      await this._userLists.deleteUserList(request);
      return true;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to delete user list',
        error,
        true,
      );
    }
  }

  public async fetchAllMembers(listId: number) {
    try {
      const request = new ul_member_pb.ListMembersRequest();
      request.setUserListId(listId);
      const response = await this._ulMember.listUserListMembers(request);
      return await Promise.all(
        response.getMembersList().map(o => o?.toObject()),
      );
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch all members of the user list',
        error,
        true,
      );
    }
  }

  public async addMember(listId: number, hashes: string[]) {
    try {
      const request = new ul_member_pb.AddMemberRequest();
      request.setUserListId(listId);
      request.setHashList(hashes);
      const response = await this._ulMember.addUserListMember(request);
      const userList = await userListMapper.fromProto(response.getUserList());
      const members = await Promise.all(
        response.getMemberList().map(o => o?.toObject()),
      );
      return { userList, members };
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to add member(s) to user list',
        error,
        true,
      );
    }
  }

  public async removeMember(listId: number, hashes: string[]) {
    try {
      const request = new ul_member_pb.RemoveMemberRequest();
      request.setUserListId(listId);
      request.setHashList(hashes);
      const response = await this._ulMember.removeUserListMember(request);
      const userList = await userListMapper.fromProto(response.getUserList());
      const members = await Promise.all(
        response.getMemberList().map(o => o?.toObject()),
      );
      return { userList, members };
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to remove member(s) from user list',
        error,
        true,
      );
    }
  }
}
