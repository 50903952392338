import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { VideoThumbnailModule } from 'minga/app/src/app/components/Input/VideoThumbnail';
import { LogoAnchoredModule } from 'minga/app/src/app/components/LogoAnchored';
import { SwipeContainerModule } from 'minga/app/src/app/components/SwipeContainer';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { CommentListViewModule } from 'minga/app/src/app/content-views/components/CommentListView';
import { MgDirectivesModule } from 'minga/app/src/app/directives';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgPersonMinimalModule } from 'minga/app/src/app/elements/MgPersonMinimal';
import { MgProgressiveImageModule } from 'minga/app/src/app/elements/MgProgressiveImage';
import { MgIconModule } from 'minga/app/src/app/icon';
import { InputsModule } from 'minga/app/src/app/inputs';
import { DesignedSvgViewerModule } from 'minga/app/src/app/minga-designer/components/DesignedSvgViewer';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { UgcLinksContentPipeModule } from 'minga/app/src/app/ugc-links/pipes/UgcLinksContent';

import { CommentViewModule } from '../CommentView';

import { LongCardComponent } from './LongCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgAvatarModule,
    MgPersonMinimalModule,
    MgEllipsisModule,
    MgSpinnerModule,
    MgIconModule,
    MgProgressiveImageModule,
    MgDirectivesModule,
    MgPipesModule,
    SwipeContainerModule,
    VideoThumbnailModule,
    CommentViewModule,
    InputsModule,
    PermissionsModule,
    UgcLinksContentPipeModule,
    MgOverlayToolsModule,
    DesignedSvgViewerModule,
    LogoAnchoredModule,
    CommentListViewModule,
    MgContentLinkModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule,
  ],
  declarations: [LongCardComponent],
  exports: [LongCardComponent],
})
export class LongCardModule {}
