import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import {
  IConsequence,
  IConsequenceClassTypeCount,
  IConsequenceType,
} from 'minga/domain/consequences';
import { IHallPassClassTypeCount, IHallPassType } from 'minga/domain/hallPass';
import { IPbisClassTypeCount, IPbisType } from 'minga/domain/pbis';
import { HallPassWithType } from 'minga/proto/hall_pass/hall_pass_pb';
import { PbisCategory } from 'minga/shared/pbis/constants';
import { RootService } from 'src/app/minimal/services/RootService';

import { BehaviorIconListType } from '@modules/behavior-manager/components/bm-types/constants';
import { combineDateAndTime } from '@modules/selection-assigner/utils';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ConsequenceService } from '@shared/services/consequence';
import { CreateHallpassService } from '@shared/services/hall-pass';
import { MyActionsService } from '@shared/services/my-class/my-actions.service';
import { PbisService } from '@shared/services/pbis';

import {
  MOST_USED_COUNT,
  MyClassMessages,
} from '../constants/tt-my-class.constants';
import {
  ActionGroup,
  ActionItem,
  AssignmentType,
  BehaviorFormData,
  CategoryType,
  ConsequenceFormData,
  FormData,
  HallPassFormData,
} from '../types/tt-my-class.types';
import {
  behaviorMapper,
  consequenceMapper,
  countSort,
  hallPassMapper,
  mapActionGroupsToItems,
} from './my-class.utils';

@Injectable()
export class MyClassActionsService implements OnDestroy {
  private _destroyedSubject = new ReplaySubject<void>(1);

  private _pastAssignmentsSubject = new BehaviorSubject<
    Map<string, ActionItem[]>
  >(new Map());
  public pastAssignments$ = this._pastAssignmentsSubject.asObservable();

  private _actionsGroupsLoadingSubject = new BehaviorSubject<boolean>(false);
  public actionsGroupsLoading$ =
    this._actionsGroupsLoadingSubject.asObservable();

  private _actionGroupsSubject = new BehaviorSubject<ActionGroup[]>(null);
  public actionGroups$: Observable<ActionGroup[]> =
    this._actionGroupsSubject.asObservable();

  constructor(
    private _consequenceService: ConsequenceService,
    private _behaviorService: PbisService,
    private _createHpService: CreateHallpassService,
    private _rootService: RootService,
    private _myActionsService: MyActionsService,
    private _systemSnackBar: SystemAlertSnackBarService,
  ) {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async fetchMyActions(): Promise<void> {
    try {
      this._actionsGroupsLoadingSubject.next(true);
      const results = await this._myActionsService.fetchAll();

      this._setActionGroups(results);
    } catch (error) {
      this._systemSnackBar.open({
        type: 'error',
        message: MyClassMessages.FETCH_ACTIONS_ERROR,
      });
    } finally {
      this._actionsGroupsLoadingSubject.next(false);
    }
  }

  public saveAssignment(data: FormData): void {
    const personHashes = data.selectedStudents;
    const actionItem = data.selectedAction;
    const existing = this._pastAssignmentsSubject.value;

    personHashes
      .filter(hash => hash)
      .forEach(hash => {
        const assignments = existing.get(hash) || [];

        this._pastAssignmentsSubject.next(
          existing.set(hash, [...assignments, actionItem]),
        );
      });
  }

  public async assign(
    data: FormData,
  ): Promise<IConsequence[] | HallPassWithType.AsObject[] | void> {
    const recipientHashes = data.selectedStudents;
    const type = data.selectedAction.assignmentType;

    if (type === AssignmentType.HALLPASS) {
      const typeData = data.selectedAction.data as IHallPassType;
      const subGroup = data[AssignmentType.HALLPASS] as HallPassFormData;
      const date = subGroup.date;
      const time = subGroup.time;
      const duration = subGroup.duration;
      const startDate =
        date && time ? combineDateAndTime(date, time).toDate() : null;
      const note = subGroup.note;
      const teacherAssignedHash = subGroup.approvedBy?.hash || null;

      const { passes } = await this._createHpService.createHallPass(
        false,
        typeData.id,
        recipientHashes,
        duration,
        startDate,
        teacherAssignedHash,
        note,
      );

      if (passes.length === 0) {
        const error = new Error('No hall passes created');
        error.name = type;
        throw error;
      }

      return passes;
    }

    if (type === AssignmentType.BEHAVIOR) {
      const typeData = data.selectedAction.data as IPbisType;
      const subGroup = data[AssignmentType.BEHAVIOR] as BehaviorFormData;
      const note = subGroup.note;

      return await this._rootService.addLoadingPromise(
        this._behaviorService.createBehavior({
          typeId: typeData.id,
          message: note,
          recipientIds: recipientHashes,
        }),
      );
    }

    if (type === AssignmentType.CONSEQUENCE) {
      const typeData = data.selectedAction.data as IConsequenceType;
      const subGroup = data[AssignmentType.CONSEQUENCE] as ConsequenceFormData;
      const note = subGroup.note;
      const completeBy = subGroup.date;

      return await this._rootService.addLoadingPromise(
        this._consequenceService.createConsequences({
          type: typeData.id,
          completeBy,
          issuedTo: recipientHashes,
          notes: note,
        }),
      );
    }
  }

  private _setActionGroups(types: {
    consequences: IConsequenceClassTypeCount[];
    behaviors: IPbisClassTypeCount[];
    hallPasses: IHallPassClassTypeCount[];
  }): void {
    const { consequences, behaviors, hallPasses } = types;

    const activeConsequences = consequences.filter(t => t.active);
    const activeBehaviors = behaviors.filter(t => t.active);
    const activeHallPasses = hallPasses.filter(t => t.active);

    const actionGroups: ActionGroup[] = [];

    if (activeHallPasses.length) {
      const hallpasses: ActionGroup = {
        value: CategoryType.HALLPASS,
        label: MyClassMessages.SELECT_CATEGORY_HALLPASS,
        icon: 'mg-hallpass-menu',
        items: activeHallPasses.map(hallPassMapper).sort(countSort),
      };

      actionGroups.push(hallpasses);
    }

    if (activeBehaviors.length) {
      const praisesTypes = activeBehaviors.filter(
        b => b.categoryId === PbisCategory.PRAISE,
      );

      if (praisesTypes.length) {
        const praises: ActionGroup = {
          value: CategoryType.PRAISE,
          label: MyClassMessages.SELECT_CATEGORY_PRAISE,
          icon: BehaviorIconListType.BEHAVIOR_PRAISE,
          iconNamespace: 'minga.behaviors',
          items: praisesTypes.map(behaviorMapper).sort(countSort),
        };

        actionGroups.push(praises);
      }

      const guidanceTypes = activeBehaviors.filter(
        b => b.categoryId === PbisCategory.GUIDANCE,
      );

      if (guidanceTypes.length) {
        const guidances: ActionGroup = {
          value: CategoryType.GUIDANCE,
          label: MyClassMessages.SELECT_CATEGORY_GUIDANCE,
          icon: BehaviorIconListType.BEHAVIOR_GUIDANCE,
          iconNamespace: 'minga.behaviors',
          items: guidanceTypes.map(behaviorMapper).sort(countSort),
        };

        actionGroups.push(guidances);
      }
    }

    if (activeConsequences.length) {
      const consequencesGroup: ActionGroup = {
        value: CategoryType.CONSEQUENCE,
        label: MyClassMessages.SELECT_CATEGORY_CONSEQUENCE,
        icon: 'mg-behavior-menu',
        items: activeConsequences.map(consequenceMapper).sort(countSort),
      };

      actionGroups.push(consequencesGroup);
    }

    let mostUsedItems = mapActionGroupsToItems(actionGroups);
    mostUsedItems.sort(countSort);

    if (mostUsedItems.length > MOST_USED_COUNT) {
      mostUsedItems = mostUsedItems.slice(0, MOST_USED_COUNT);
    }

    const mostUsed: ActionGroup = {
      value: CategoryType.MOST_USED,
      label: MyClassMessages.SELECT_CATEGORY_MOST_USED,
      icon: 'star',
      iconNamespace: 'minga.behaviors',
      items: mostUsedItems,
    };

    this._actionGroupsSubject.next([mostUsed, ...actionGroups]);
  }
}
