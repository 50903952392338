import { NgModule } from '@angular/core';

import { SwipeStackComponent } from './SwipeStack.component';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [SwipeStackComponent],
  exports: [SwipeStackComponent],
})
export class SwipeStackModule {}
