import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IPbisBehavior } from 'minga/domain/pbis';
import { MingaPermission } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';

import {
  BmReportsHistoryDatasourceService,
  BmReportsService,
} from '../../services';
import { BmReportActionService } from '../../services/bm-reports-action.service';
import { BmReportsHistoryMessages } from './bm-reports-history.constants';

@Component({
  selector: 'mg-bm-reports-history',
  templateUrl: './bm-reports-history.component.html',
  styleUrls: ['./bm-reports-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BmReportsHistoryDatasourceService, BmReportActionService],
})
export class BmReportsHistoryComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = BmReportsHistoryMessages;

  /** General Observables and Subject Behaviors */
  private _destroyedSubject = new ReplaySubject<void>(1);
  public readonly canMultiSelect$ = this._permissions.observePermission(
    MingaPermission.PBIS_TYPE_MANAGE,
  );

  /** Other */
  private _selectableItems: IPbisBehavior[] = [];

  constructor(
    public ds: BmReportsHistoryDatasourceService,
    public actionService: BmReportActionService,
    private _bmReportService: BmReportsService,
    private _permissions: PermissionsService,
  ) {
    this.ds.items$.pipe(takeUntil(this._destroyedSubject)).subscribe(items => {
      this.actionService.clearSelection();
      this.actionService.setTotalSelectable(items.length);
      this._selectableItems = items;
    });

    this._bmReportService.filter$
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(() => this.actionService.clearSelection());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public selectAllTableDataRows() {
    this.actionService.masterToggle(this._selectableItems);
  }
}
