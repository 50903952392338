<div class="container">
  <div
    class="input-wrap"
    fxLayout="row"
    fxLayoutAlign="start center">
    <mg-chip
      iconRight="close-thin"
      *ngFor="let date of dates$ | async"
      (pressed)="remove(date.value)">
      {{ date.label }}
    </mg-chip>

    <div #addBtn>
      <mg-btn
        variant="outlined"
        iconSet="minga"
        icon="post"
        size="small"
        [disabled]="isDisabled$ | async"
        (pressed)="openCalendar()">
        Add date
      </mg-btn>
    </div>
  </div>
  <mg-form-error *ngIf="formControl.invalid && formControl.touched">
    {{ formControl?.errors | makeInputErrorMessage }}
  </mg-form-error>

  <ng-template #calendarTemplate>
    <div class="calendar-popup-container">
      <mg-calendar
        (dateChange)="setDate($event)"
        [value]="null"
        [allowPastDate]="allowPastDate">
      </mg-calendar>
    </div>
  </ng-template>
</div>
