import { Injectable } from '@angular/core';

import { xor } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { MingaPermission } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';

import {
  MmDashboardTableColumn,
  MM_DASHBOARD_TABLE_COLUMNS,
  MM_DASHBOARD_TABLE_STICKY_COLUMNS,
  MM_DASHBOARD_TABLE_SA_COLUMNS,
  MM_DASHBOARD_TABLE_HEALTH_COLUMNS,
  MmDashboardColumnPresets,
} from '../constants';

@Injectable()
export class MmDashboardTableService {
  /** Displayed Columns */
  private _columnTitles: MmDashboardTableColumn[] = [];
  private _displayColumnMap = new Map<MmDashboardTableColumn, boolean>();
  private readonly _displayedColumns$ = new BehaviorSubject<
    MmDashboardTableColumn[]
  >([]);
  public readonly displayedColumn$ = this._displayedColumns$
    .asObservable()
    .pipe(shareReplay());
  public columnPreset = MmDashboardColumnPresets.ALL;

  /** Service Constructor */
  constructor(private _permissionsService: PermissionsService) {
    this._columnTitles = [
      ...MM_DASHBOARD_TABLE_STICKY_COLUMNS,
      ...MM_DASHBOARD_TABLE_COLUMNS,
    ];

    if (!this._permissionsService.hasPermission(MingaPermission.SUPERADMIN)) {
      this._columnTitles = xor(
        this._columnTitles,
        MM_DASHBOARD_TABLE_SA_COLUMNS,
      );
    }
    this._displayedColumns$.next(this._columnTitles);

    for (const column of this._columnTitles) {
      this._displayColumnMap.set(column, true);
    }
  }

  public async toggleDisplayedColumn(
    columns: MmDashboardTableColumn[],
  ): Promise<void> {
    // if we are missing any columns, add them
    // if we have all columns, remove them
    let toggle = false;
    for (const column of columns) {
      if (
        this._displayColumnMap.has(column) &&
        !this._displayColumnMap.get(column)
      ) {
        toggle = true;
        break;
      }
    }
    for (const column of columns) {
      // prevent adding columns not already in the map
      if (this._displayColumnMap.has(column))
        this._displayColumnMap.set(column, toggle);
    }
    this._setColumns();
  }

  public async switchColumnPreset(preset: MmDashboardColumnPresets) {
    if (preset === MmDashboardColumnPresets.ALL) {
      this._displayedColumns$.next(this._columnTitles);
    } else if (preset === MmDashboardColumnPresets.HEALTH) {
      this._displayColumnMap.forEach((val, key) => {
        if (!MM_DASHBOARD_TABLE_HEALTH_COLUMNS.includes(key)) {
          this._displayColumnMap.set(key, false);
        } else {
          this._displayColumnMap.set(key, true);
        }
      });
      this._setColumns();
    }
    this.columnPreset = preset;
  }

  private _setColumns() {
    const columns = [];
    this._displayColumnMap.forEach((val, key) => {
      if (val) {
        columns.push(key);
      }
    });

    this._displayedColumns$.next(columns);
  }
}
