// This are taken directly from overlay sass
export const OVERLAY_SPLIT_CONTENT_SIDE_WIDTH = 375;
export const OVERLAY_MAX_WIDTH = 740;
export const OVERLAY_WIDE_MAX_WIDTH = 1140;

export function isOverlayFullscreen(options: { wide: boolean }): boolean {
  return (
    innerWidth <= OVERLAY_MAX_WIDTH ||
    (options.wide && innerWidth <= OVERLAY_WIDE_MAX_WIDTH)
  );
}

export function isOverlayUsingNestedScroll(options: {
  wide: boolean;
  containWithinViewport: boolean;
  hasSplitContent: boolean;
}): boolean {
  return (
    !isOverlayFullscreen(options) &&
    (options.containWithinViewport || options.hasSplitContent)
  );
}
