import { Router } from '@angular/router';

export const GROUP_OWNER_DISPLAY_ROLE_TYPE: string = 'GROUP_OWNER';
export const DEFAULT_GROUP_ALLOWED_ROLETYPES = [
  GROUP_OWNER_DISPLAY_ROLE_TYPE,
  'OWNER',
  'MANAGER',
  'TEACHER',
  'STAFF',
];

export async function gotoGroupPage(router: Router, groupHash: string) {
  await router.navigate([{ outlets: { search: null } }]);
  await router.navigate(['/groups', 'view', groupHash]);
}
