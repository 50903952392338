<!-- Form step UI switcher -->
<ng-container [ngSwitch]="formStep$ | async">
  <!-- Setup -->
  <mg-kiosk-type-selector
    *ngSwitchCase="'setup'"
    [title]="MESSAGES.SELECTOR_TITLE"
    [emptyStateTitle]="
      (selectedCheckInType$ | async) === 'check-in'
        ? MESSAGES.SELECTOR_CHECK_IN_EMPTY_STATE_TITLE
        : MESSAGES.SELECTOR_FLEXTIME_EMPTY_STATE_TITLE
    "
    [emptyStateSubTitle]="
      (selectedCheckInType$ | async) === 'check-in'
        ? MESSAGES.SELECTOR_CHECK_IN_EMPTY_STATE_SUB
        : MESSAGES.SELECTOR_FLEXTIME_EMPTY_STATE_SUB
    "
    [submitLabel]="MESSAGES.SELECTOR_SUBMIT"
    [isLoading]="isLoading$ | async"
    [allowAudioNotifications]="true"
    [types]="allTypes$ | async"
    [showBackButton]="showBackButton$ | async"
    [reset$]="reset$"
    (formSubmit)="setAvailableTypes($event)"
    (back)="goBack()">
    <section
      *ngIf="kioskFlexCheckInEnabled$ | async"
      class="filters"
      fxLayout="column"
      fxLayoutGap="12px"
      fxLayoutAlign="center center">
      <mat-button-toggle-group
        *ngIf="
          (kioskCheckInEnabled$ | async) && (kioskFlexCheckInEnabled$ | async)
        "
        class="filter-toggle-group"
        [attr.data-analytics]="'kiosk-checkin-flextime-selector'"
        [value]="selectedCheckInType$ | async"
        (change)="changeTab($event.value)">
        <mat-button-toggle [value]="CHECK_IN_TYPE.CHECK_IN">
          <div
            class="filter-toggle-group-item"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="4px">
            <mg-icon>mg-checkin-menu</mg-icon>
            <mg-text variant="body-sm-secondary">{{
              MESSAGES.TAB_CHECKIN
            }}</mg-text>
          </div>
        </mat-button-toggle>
        <mat-button-toggle [value]="CHECK_IN_TYPE.FLEX">
          <div
            class="filter-toggle-group-item"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="4px">
            <mg-icon>mg-flextime-menu</mg-icon>
            <mg-text variant="body-sm-secondary">{{
              MESSAGES.TAB_FLEX
            }}</mg-text>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <section
        *ngIf="(selectedCheckInType$ | async) === CHECK_IN_TYPE.FLEX"
        class="flex-filter">
        <div
          fxLayout="row"
          fxFlexAlign="center center"
          fxLayoutGap="12px">
          <mg-form-select
            class="flex-filter-period"
            [placeholder]="MESSAGES.FLEX_PERIOD_LABEL"
            [closeOnSelect]="true"
            [multiple]="false"
            [options]="flexPeriodsFilter$ | async"
            [control]="periodControl">
          </mg-form-select>

          <mg-form-select
            *ngIf="!isSelfManager"
            class="flex-filter-activity"
            [placeholder]="MESSAGES.FLEX_ACTIVITY_LABEL"
            [closeOnSelect]="true"
            [multiple]="false"
            [options]="flexActivitiesFilters"
            [control]="activityControl">
          </mg-form-select>
        </div>
      </section>
    </section>
  </mg-kiosk-type-selector>

  <!-- Assigner -->
  <mg-kiosk-type-assigner
    *ngSwitchCase="'assigner'"
    cardTitle="Check in kiosk"
    typeIcon="mg-checkin-menu"
    [types]="availableTypes$ | async"
    (response)="assign($event)">
  </mg-kiosk-type-assigner>

  <!-- Type selector -->
  <mg-kiosk-type-selector
    *ngSwitchCase="'type-selector'"
    [title]="MESSAGES.TYPE_SELECTOR_TITLE"
    [emptyStateTitle]="MESSAGES.TYPE_SELECTOR_EMPTY_STATE_TITLE"
    [emptyStateSubTitle]="MESSAGES.TYPE_SELECTOR_EMPTY_STATE_SUB"
    [submitLabel]="MESSAGES.TYPE_SELECTOR_SUBMIT"
    [maxSelection]="1"
    [subTitle]="'Hi, ' + (person$ | async)?.displayName"
    [allowAudioNotifications]="false"
    [types]="availableTypes$ | async"
    (formSubmit)="handleUserTypeSelection($event)"
    (back)="setFormStep('assigner')"
    [disableSubmit]="assignmentInProgress$ | async">
  </mg-kiosk-type-selector>

  <!-- Summary -->
  <mg-kiosk-summary
    *ngSwitchCase="'summary'"
    [cardIconName]="(summaryData$ | async)?.iconName"
    [cardIconColor]="(summaryData$ | async)?.iconColor"
    [title]="MSG.TITLE_PASS_CREATED"
    [cardTitle]="(summaryData$ | async)?.name"
    [autoDone]="30"
    (done)="reset()">
    <!-- Details -->
    <ng-container
      [ngTemplateOutlet]="summaryDetailsTemplate"
      [ngTemplateOutletContext]="{
        $implicit: (summaryData$ | async)
      }">
    </ng-container>
  </mg-kiosk-summary>
</ng-container>

<ng-template
  #summaryDetailsTemplate
  let-data>
  <!-- Student name -->
  <li>
    <mg-text>Student</mg-text>
    <div>{{ data?.studentName }}</div>
  </li>
  <!-- Status -->
  <ng-container *ngIf="data | kioskHallPassTimer as status$">
    <li>
      <mg-text>Status</mg-text>
      <div>
        <mg-tag [color]="(status$ | async)?.tagColor">
          {{ (status$ | async)?.status }}
        </mg-tag>
      </div>
    </li>
    <li>
      <mg-text>Timer</mg-text>
      <div>{{ (status$ | async)?.time }}</div>
    </li>
  </ng-container>
  <!-- Ending method -->
  <li>
    <mg-text>Ending method</mg-text>
    <div>
      {{ data?.endingMethod }}
    </div>
  </li>
</ng-template>
