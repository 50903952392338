<p class="heading">Resolve Errors ({{ data.errors.length }})</p>

<hr />

<p class="subheading">
  {{ data.dialogText }}
</p>

<div class="button-wrapper">
  <mg-button
    type="outline"
    fxFlex
    class="resolve-button"
    (click)="clearErrors()">
    Remove Rows
  </mg-button>
</div>

<hr />

<div class="button-wrapper">
  <mg-button
    type="outline"
    fxFlex
    class="download-button"
    (click)="downloadCsv()">
    Download csv
  </mg-button>
  <mg-button
    type="outline"
    fxFlex
    class="download-button"
    (click)="downloadXlsx()">
    Download xlsx
  </mg-button>
</div>

<div class="error-container">
  <div
    class="upload-error"
    [class.selectable]="error.recordIndex > -1"
    (click)="goToError(error.recordIndex)"
    *ngFor="let error of errors">
    <span fxFlex
      >Row {{ error.recordIndex + 1 }}: {{ error.errorMessage }}</span
    >
  </div>
</div>
