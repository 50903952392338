import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';

import { ReplaySubject, Subscription } from 'rxjs';
import { splashScreen } from 'src/splashscreen';

import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import { LandingRoute } from './constants';
import { LandingService } from './services';

@Component({
  selector: 'mg-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent implements OnInit, OnDestroy {
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  public LANDING_ROUTES = LandingRoute;

  // State
  title = '';
  slogan = '';
  logo = true;
  extraTopMargin = false;

  // Subscriptions
  routesSubscription: Subscription;

  /** Component Constructor */
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public landing: LandingService,
    public kioskPermissions: KioskPermissionsService,
  ) {}

  ngOnInit(): void {
    splashScreen.hide();
    this.routesSubscription = this.router.events.subscribe((e: Event) => {
      if (!(e instanceof NavigationEnd)) {
        return;
      }

      this.navDataHandler();
    });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this.routesSubscription?.unsubscribe();
  }

  navDataHandler() {
    let currentChildRoute: ActivatedRoute | null = this.route;

    while (currentChildRoute) {
      if (currentChildRoute && currentChildRoute.snapshot) {
        const routeData = currentChildRoute.snapshot.data;
        if ('landingTitle' in routeData) {
          this.title = routeData.landingTitle;
        }
        if ('landingSlogan' in routeData) {
          this.slogan = routeData.landingSlogan;
        }
        if ('landingLogo' in routeData) {
          this.logo = routeData.landingLogo;
        }
        if ('extraTopMargin' in routeData) {
          this.extraTopMargin = !!routeData.extraTopMargin;
        }
      }
      currentChildRoute = currentChildRoute.firstChild;
    }
  }
}
