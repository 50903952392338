import { Action } from '@ngrx/store';
import { NotificationStoreModel } from '../model/notification.model';

export namespace NotificationActions {
  export enum TypeEnum {
    LoadNotifications = '[Notifications] Load Notifications',
    AddNotification = '[Notifications] Add Notifications',
    MarkNotificationAsRead = '[Notifications] Mark as read',
    RemoveNotifications = '[Notifications] Remove Notifications',
    ViewedNotifications = '[Notifications Viewed Notifications',
  }

  export interface NotificationAction extends Action {
    readonly type: TypeEnum;
  }

  /**
   * Load a bunch of notifications into the store, replacing whatever was
   * previously in there.
   */
  export class LoadNotificationsAction implements NotificationAction {
    readonly type = TypeEnum.LoadNotifications;

    constructor(public payload: any) {}
  }

  /**
   * Add a new notification to the store.
   */
  export class AddNotificationAction implements NotificationAction {
    readonly type = TypeEnum.AddNotification;

    constructor(public payload: NotificationStoreModel) {}
  }

  /**
   * Signal that a notification was read
   * (or that a group was viewed)
   */
  export class MarkNotificationAsReadAction implements NotificationAction {
    readonly type = TypeEnum.MarkNotificationAsRead;

    constructor(public payload: NotificationStoreModel) {}
  }

  /**
   * Signals to remove all notifications whose hash is in the payload.
   */
  export class RemoveNotificationsAction implements NotificationAction {
    readonly type = TypeEnum.RemoveNotifications;

    constructor(public payload: string[]) {}
  }

  export class ViewedNotificationsAction implements NotificationAction {
    readonly type = TypeEnum.ViewedNotifications;

    constructor() {}
  }
  export type TypeUnion =
    | LoadNotificationsAction
    | AddNotificationAction
    | MarkNotificationAsReadAction
    | RemoveNotificationsAction
    | ViewedNotificationsAction;
}
