import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import {
  CameraPermissionStatus,
  getCameraPermissionStatus,
  openCameraActionSheet,
  requestCameraPermission,
} from '@lib/cordova/camera';

import { DEFAULT_IMAGE_ACCEPT } from 'minga/app/src/app/file';

export const MG_FILE_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileInputComponent),
  multi: true,
};

export enum FileInputDisplay {
  DROP_AREA = 'DROP_AREA',
  BUTTON = 'BUTTON',
  ICON = 'ICON',
  COMPACT_IMAGE = 'COMPACT_IMAGE',
  CAMERA_IMAGE = 'CAMERA_IMAGE',
  TEXT = 'TEXT',
}

// Only 1 drag and drop handler can existm at a time.
let activeDragDropHandler: FileInputComponent = null;
let pendingActiveDragDropHandlers: FileInputComponent[] = [];

let hasCameraPermission: boolean = false;

function dataUrltoBlob(dataurl) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

@Component({
  providers: [MG_FILE_INPUT_VALUE_ACCESSOR],
  selector: 'mg-file-input',
  templateUrl: './FileInput.component.html',
  styleUrls: ['./FileInput.component.scss'],
})
export class FileInputComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  public onChange;
  public onTouched;

  public files: File[] = [];
  FileInputDisplay = FileInputDisplay;

  @ViewChild('inputEl', { static: true })
  public inputEl: ElementRef;
  @ViewChild('infoEl', { static: true })
  public infoEl: ElementRef;

  @Input()
  customTitleText: string;

  @Input()
  multiple = true;

  @Input()
  transformToFit: string;

  @Input()
  accept = DEFAULT_IMAGE_ACCEPT;

  @Input()
  friendlyName = 'photo';

  @Input()
  icon = 'camera-o';

  @Input()
  compactIcon = 'camera';

  @Input()
  customBottomText = '';

  @Input()
  showFileDetails = true;

  @Input()
  fileDetailText = 'JPGs or PNGs, 20 MB max.';

  @Input()
  displayStyle: FileInputDisplay = FileInputDisplay.DROP_AREA;

  @Input('icon-namespace')
  iconNamespace = 'minga';

  @Input()
  helperText = '';

  /**
   * If this is set to true it allows multiple mg-file-input elements to exit
   * at one time, but requires users to drop specifically on the <label>
   * @NOTE: DO NOT CHANGE <LABEL> (label.drop-area) from being this.element's
   * first child or this will no longer work
   */
  @Input()
  dropOnElementOnly: boolean = false;

  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  private _onDropGlobalHandler: any;
  private _onDragoverGlobalHandler: any;

  hasDragDrop: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    if (this.transformToFit) {
      this.transformAndScaleDropInfo();
    }
  }

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private _cdr: ChangeDetectorRef,
  ) {
    this._onDropGlobalHandler = e => this.onDrop(e);
    this._onDragoverGlobalHandler = e => this.onDragover(e);

    document.addEventListener('drop', this._onDropGlobalHandler);
    document.addEventListener('dragover', this._onDragoverGlobalHandler);

    if (!activeDragDropHandler) {
      activeDragDropHandler = this;
    } else {
      pendingActiveDragDropHandlers.unshift(this);
    }

    // @TODO: Some android devices would actually have drag and drop
    this.hasDragDrop = !(
      window.MINGA_DEVICE_ANDROID || window.MINGA_DEVICE_IOS
    );

    getCameraPermissionStatus().then(
      status => {
        switch (status) {
          case CameraPermissionStatus.DENIED:
          case CameraPermissionStatus.NOT_DETERMINED:
            hasCameraPermission = false;
            break;
          case CameraPermissionStatus.GRANTED:
            hasCameraPermission = false;
            break;
          case CameraPermissionStatus.NO_CORDOVA:
            hasCameraPermission = true;
            break;
        }
      },
      err => console.error(err),
    );
  }

  private triggerChange() {
    if (this.onTouched) this.onTouched();
    if (this.onChange) this.onChange(this.files);
  }

  transformAndScaleDropInfo() {
    if (!this.infoEl) return;

    const containerEl: HTMLElement = this.element.nativeElement;
    const dropInfoEl: HTMLElement = this.infoEl.nativeElement;

    const containerRect = containerEl.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;

    const dropInfoRect = dropInfoEl.getBoundingClientRect();
    const dropInfoWidth = dropInfoRect.width;
    const dropInfoHeight = dropInfoRect.height;

    let scale = Math.min(
      containerWidth / dropInfoWidth,
      (containerHeight - 15) / dropInfoHeight, // -15 to match mg-icon margin-bottom
    );

    scale = scale >= 1 ? 1 : scale;
    const transformString = `${this.transformToFit} scale(${scale})`;
    // dropInfoEl.style['transform'] = `${transformString}`;
    this.renderer.setStyle(
      this.infoEl.nativeElement,
      'transform',
      `${transformString}`,
    );
  }

  public triggerInput() {
    this.inputEl.nativeElement.click();
  }

  private async _doActionSheet(takePhoto: boolean, takeVideo: boolean) {
    const result = await openCameraActionSheet({
      takePhoto,
      takeVideo,
      fromLibrary: true,
      ignoreFromLibrarySelection: true,
    });

    if (result.action === 'fromLibrary') {
      // Triggering native library selector
      try {
        this.inputEl.nativeElement.classList.add('use-native');
        this.inputEl.nativeElement.click();
      } finally {
        setTimeout(
          () => this.inputEl.nativeElement.classList.remove('use-native'),
          0,
        );
      }
    } else if (result.action !== 'cancelled') {
      const files = this.files.concat(result.files);

      this.files = this.files.concat(files);
      this.triggerChange();
    }
  }

  async inputClick(e: Event) {
    // Check if the event was bubbled from the child
    if (e.target === this.inputEl.nativeElement) {
      // If it was, stop the function execution
      return;
    }
    const acceptingImages = this.accept.indexOf('image') !== -1;
    const acceptingVideo = this.accept.indexOf('video') !== -1;

    const useNativeClassExists =
      this.inputEl.nativeElement.classList.contains('use-native');

    if (window.MINGA_APP_ANDROID && !useNativeClassExists) {
      e.preventDefault();
      this._doActionSheet(acceptingImages, acceptingVideo);
      return;
    }

    if (!hasCameraPermission) {
      e.preventDefault();
      await requestCameraPermission().then(
        status => {
          switch (status) {
            case CameraPermissionStatus.GRANTED:
              hasCameraPermission = true;
              if (this.inputEl) {
                this.inputEl.nativeElement.click();
              }
              break;
            case CameraPermissionStatus.DENIED:
            case CameraPermissionStatus.NOT_DETERMINED:
              hasCameraPermission = false;
              break;
          }
        },
        err => console.error(err),
      );
    }
  }

  buttonClick() {
    this.inputEl.nativeElement.click();
  }

  ngOnDestroy() {
    document.removeEventListener('drop', this._onDropGlobalHandler);
    document.removeEventListener('dragover', this._onDragoverGlobalHandler);

    if (activeDragDropHandler === this) {
      activeDragDropHandler = null;
      activeDragDropHandler = pendingActiveDragDropHandlers.pop();
    }

    pendingActiveDragDropHandlers = pendingActiveDragDropHandlers.filter(
      h => h !== this,
    );
  }

  ngOnInit() {
    let inputEl = this.inputEl.nativeElement;

    inputEl.onchange = () => {
      this.files = [];

      for (let i = 0; inputEl.files.length > i; i++) {
        this.files.push(inputEl.files[i]);
      }

      this.triggerChange();

      inputEl.blur();

      // reset input, safari (less than latest) may throw exception and need
      // to use a dom element replace approach...
      try {
        inputEl.files = null;
        inputEl.value = null;
      } catch (err) {
        inputEl.parentNode.replaceChild(inputEl.cloneNode(true), inputEl);
      }
    };

    if (this.transformToFit) {
      this.transformAndScaleDropInfo();
    }
  }

  onDrop(e) {
    if (this.dropOnElementOnly) {
      const labelElement = this.element.nativeElement.children[0];
      const targetMatches = e.target == labelElement;
      const srcElementMarches = e.srcElement == labelElement;
      // only when the drop event's targetting this element do we proceed
      if (!targetMatches && !srcElementMarches) {
        return;
      }
    } else if (activeDragDropHandler !== this) {
      return;
    }

    e.preventDefault();

    this.files = [];

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, ignore them
        if (e.dataTransfer.items[i].kind === 'file') {
          let file = e.dataTransfer.items[i].getAsFile();
          this.files.push(file);
        }
      }
    } else {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        let file = e.dataTransfer.files[i];
        this.files.push(file);
      }
    }

    this.triggerChange();
  }

  onDragover(e) {
    if (this.dropOnElementOnly) {
      const labelElement = this.element.nativeElement.children[0];
      const targetMatches = e.target == labelElement;
      const srcElementMarches = e.srcElement == labelElement;
      // only when the drop event's targetting this element do we proceed
      if (!targetMatches && !srcElementMarches) {
        return;
      }
    } else if (activeDragDropHandler !== this) {
      return;
    }

    e.preventDefault();
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value instanceof File || value instanceof Blob) {
      this.files = [<any>value];
      this._cdr.markForCheck();
    } else if (Array.isArray(value)) {
      this.files = [];
      value.forEach((v, i) => {
        if (v instanceof File || v instanceof Blob) {
          this.files.push(<any>v);
        } else {
          console.warn(
            `<mg-file-input> writeFile value[${i}] invalid value:`,
            value,
          );
        }
      });
      this._cdr.markForCheck();
    } else if (value === null) {
      this.files = [];
      this._cdr.markForCheck();
    } else {
      console.warn(
        '<mg-file-input> writeValue expected File or Blob. Got:',
        value,
      );
    }
  }

  trigger() {
    if (!this.inputEl) {
      return;
    }

    const el = this.inputEl.nativeElement;
    el.click();
  }
}
