<mg-notification-base
  [read]="notification?.read"
  [viewed]="notification?.viewed"
  [icon]="notification?.iconUrl"
  [iconColor]="notification?.iconColor"
  [avatarSrc]="notification?.image | mgAssetUrl:'preview'"
  [timestamp]="notification?.timestamp">
  <mg-notification-base-title>
    <div class="notification-title-text">
      {{titlePrefix}}
      <span class="person-title-text">{{displayName}}</span>
    </div>
  </mg-notification-base-title>

  <mg-notification-base-body>
    {{notification?.body | mgStripHtml}}
  </mg-notification-base-body>
</mg-notification-base>
