import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarScheduleOptionsComponent } from 'minga/app/src/app/components/CalendarScheduleOptions';
import { Group } from 'minga/app/src/app/groups/models';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import {
  EventCalendarService,
  IEventCalendarSettings,
} from 'minga/app/src/app/services/EventCalendar';

import { EventManagerRoutes } from '@modules/event-manager';

@Component({
  selector: 'mg-events-home',
  templateUrl: './EventsHome.component.html',
  styleUrls: ['./EventsHome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsHomeComponent {
  searchText: string = '';
  calendarScheduleOptionsOpened: boolean = false;

  readonly groups$: Observable<Group[]>;
  readonly settings$: Observable<IEventCalendarSettings>;

  public EVENT_ROUTES = EventManagerRoutes;

  constructor(
    groupsFacade: GroupsFacadeService,
    private eventCalendarService: EventCalendarService,
  ) {
    this.settings$ = eventCalendarService.settings$.pipe(
      map(settings => ({ ...settings })),
    );
    this.groups$ = groupsFacade.groups$;
  }

  openCalendarOptions() {
    this.calendarScheduleOptionsOpened = !this.calendarScheduleOptionsOpened;
  }

  applyOptions(
    options: CalendarScheduleOptionsComponent,
    currentSettings: IEventCalendarSettings,
  ) {
    const newSettings: IEventCalendarSettings = {
      ...currentSettings,
      excludeWeekend: !options.includeWeekends,
      calendarView: options.calendarView,
      showPastEvents: options.showPastEvents,
      showCalendarSettings: options.showCalendarSettings,
    };

    this.eventCalendarService.updateSettings(newSettings, true);
    this.calendarScheduleOptionsOpened = false;
  }

  _onGroupSelectChange(settings: IEventCalendarSettings, groupHash: string) {
    if (groupHash) {
      settings.groupHashes = [groupHash];
    } else {
      delete settings.groupHashes;
    }
  }

  _onCategorySelectChange(settings: IEventCalendarSettings, category: string) {
    if (category) {
      settings.category = category;
    } else {
      delete settings.category;
    }
  }
}
