/**
 * If all day event and time is NOT midnight then event origin is from another
 * timezone
 */
export function checkIfAllDayEventIsFromAnotherTimezone(date: Date): Boolean {
  // if all day event start time is not midnight, then event origin is from
  // another timezone
  if (
    date.getHours() != 0 ||
    date.getMinutes() != 0 ||
    date.getSeconds() != 0
  ) {
    return true;
  }

  return false;
}

/**
 * If an all day event from another timezone, use origin timezone date
 */
export function adjustAllDayEventForDifferentTimezone(date: Date): Date {
  const eventTimeHours = date.getHours();

  if (eventTimeHours >= 12) {
    date.setDate(date.getDate() + 1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
  }

  return date;
}
