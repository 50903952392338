import { Injectable } from '@angular/core';

import { ExistingConsequenceType } from 'minga/domain/consequences';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { BehaviorManagerService } from '../../../services';
import { BmTypesConsequenceEditComponent } from '../components/bm-types-consequence-edit/bm-types-consequence-edit.component';
import {
  BmTypesConsequenceEditModalData,
  BmTypesConsequenceEditModalResponse,
} from '../types';

@Injectable()
export class BmTypesConsequenceService {
  /** Service Constructor */
  constructor(
    private _systemSnackbar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService<
      BmTypesConsequenceEditModalResponse,
      BmTypesConsequenceEditModalData
    >,
    private _bmService: BehaviorManagerService,
  ) {}

  public async openEditModal(type?: ExistingConsequenceType): Promise<void> {
    const editModal = this._modalOverlay.open(BmTypesConsequenceEditComponent, {
      data: { consequenceType: type },
      disposeOnNavigation: false,
    });
    editModal.afterClosed.subscribe(async response => {
      if (!response) return;
      switch (response.type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          this._systemSnackbar.success(
            'Successfully created new consequence type',
          );
          return;
        }
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          await this._bmService.fetchConsequenceTypes();
          this._systemSnackbar.success('Successfully updated consequence type');
          return;
        }
        case ModalOverlayServiceCloseEventType.DELETE: {
          this._systemSnackbar.success('Successfully deleted consequence type');
          return;
        }
        default: {
          return;
        }
      }
    });
  }

  public async changeStatus(
    consequenceType: ExistingConsequenceType,
    status: boolean,
  ): Promise<void> {
    try {
      const type = { ...consequenceType };
      type.active = status;
      await this._bmService.updateConsequenceType(type);
      this._systemSnackbar.success(
        `Successfully set ${type.name} to ${status ? 'active' : 'inactive'}`,
      );
    } catch (error) {
      this._systemSnackbar.error(`Failed to update consequence status`);
    }
  }
}
