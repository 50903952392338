<!-- Layout template switcher -->
<ng-container [ngSwitch]="layoutTemplate$ | async">
  <!-- default -->
  <ng-container *ngSwitchCase="'default'">
    <!-- Global menu provider -->
    <mg-layout-global-menu [overlayIsActive]="overlayIsActive">
      <!-- Global search  -->
      <mg-global-search></mg-global-search>
      <!-- Legacy root stuff -->
      <mg-layout-legacy-root>
        <!-- Legacy layout -->
        <mg-layout-legacy
          *ngIf="layout.useLegacyLayout$ | async; else appLayout"
          [overlayEnabled]="overlayIsActive">
          <router-outlet></router-outlet>
        </mg-layout-legacy>
      </mg-layout-legacy-root>
    </mg-layout-global-menu>
  </ng-container>

  <!-- kiosk -->
  <ng-container *ngSwitchCase="'kiosk'">
    <main class="kiosk-layout-main">
      <router-outlet></router-outlet>
    </main>
  </ng-container>

  <!-- unauthenticated -->
  <ng-container *ngSwitchCase="'unauthenticated'">
    <main class="blank-layout-main">
      <router-outlet></router-outlet>
    </main>
  </ng-container>

  <!-- default -->
  <ng-container *ngSwitchDefault>
    <main>
      <router-outlet></router-outlet>
    </main>
  </ng-container>
</ng-container>

<!-- Layout template -->
<ng-template #appLayout>
  <!-- Layout container -->
  <div class="layout-container">
    <mat-drawer-container>
      <!-- Mobile primary navigation -->
      <mat-drawer
        #sidenav
        mode="over"
        position="end">
        <mg-layout-primary-nav
          *ngIf="media.isMobileView$ | async"
          (closeDrawer)="sidenav?.close()">
        </mg-layout-primary-nav>
      </mat-drawer>
      <mat-drawer-content
        [class.is-collapsed]="
          (layout.isPrimaryNavigationOpen$ | async) === false
        ">
        <!-- Desktop primary navigation -->
        <aside
          *ngIf="(media.isMobileView$ | async) === false"
          class="layout-sidebar"
          [class.is-collapsed]="
            (layout.isPrimaryNavigationOpen$ | async) === false
          ">
          <mg-layout-primary-nav></mg-layout-primary-nav>
        </aside>
        <section class="layout-stack">
          <!-- Header -->
          <header class="layout-header">
            <!-- Override -->
            <ng-container
              *ngIf="headerPortal as portal; else defaultHeaderTemplate">
              <ng-template [cdkPortalOutlet]="portal"></ng-template>
            </ng-container>
            <!-- Default header -->
            <ng-template #defaultHeaderTemplate>
              <mg-layout-header></mg-layout-header>
            </ng-template>
          </header>
          <!-- Main content -->
          <main
            class="layout-main"
            [mgScrollTarget]="true">
            <div class="layout-main-wrapper">
              <router-outlet></router-outlet>
            </div>
          </main>
          <!-- Footer -->
          <footer
            *ngIf="footerPortal as portal"
            class="layout-footer">
            <ng-template [cdkPortalOutlet]="portal"></ng-template>
          </footer>
        </section>
      </mat-drawer-content>
    </mat-drawer-container>
    <!-- Mobile navigation -->
    <mg-layout-primary-mobile-nav
      *ngIf="media.isMobileView$ | async"
      (toggleDrawer)="toggleDrawer()">
    </mg-layout-primary-mobile-nav>
  </div>
</ng-template>
