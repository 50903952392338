import { Pipe } from '@angular/core';
import { range } from 'lodash';

/**
 * Convenience pipe for wrapping lodash's `range` method
 */
@Pipe({ name: 'mgRange' })
export class MgRangePipe {
  constructor() {}

  transform(value: any, options?: any): number[] {
    if (typeof value !== 'number') {
      console.warn('mgRange requires number type. Got', value);
      return [];
    }

    let start = 0;
    let end = value;
    let step = 1;

    if (typeof options === 'object') {
      if (typeof options.step === 'number') {
        step = options.step;
      }

      if (typeof options.start === 'number') {
        start = options.start;
      }
    }

    return range(start, end, step);
  }
}
