import { Injectable } from '@angular/core';
import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { PostManager } from 'minga/proto/gateway/post_ng_grpc_pb';
import {
  LikePostRequest,
  UnlikePostRequest,
} from 'minga/proto/gateway/post_pb';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LikeService {
  // Flag to indiciate if setLikes has been called at least once
  private _likesHaveBeenSet: boolean = false;

  // Map of promises that resolve to the current network request
  private _likePromiseMap: Map<string, Promise<void>>;

  // Subjects for tracking when a liked state has changed and stores the local
  // like state
  private _likeChangeSubjectMap: Map<string, BehaviorSubject<boolean>>;

  // The liked states that are on their way to the server. These gets cleared
  // after the network request is done.
  private _pendingLikeState: Map<string, boolean>;

  // @NOTE: This service really shouldn't need any more dependencies. If you're
  //        thinking of adding more then think about making a different service.
  constructor(
    private postManager: PostManager,
    private contentEvents: ContentEvents,
    private analyticsService: AnalyticsService,
  ) {
    this._likeChangeSubjectMap = new Map();
    this._pendingLikeState = new Map();
    this._likePromiseMap = new Map();
  }

  async waitForPendingLikes(): Promise<void> {
    const NOOP = () => {};
    const promises: Promise<void>[] = [];

    this._likePromiseMap.forEach(p => {
      // We don't want to respond/catch errors in the waiting. Just ignore them
      // let whoever called initial addLike/removeLike handle them.
      promises.push(p.then(NOOP, NOOP));
    });

    await Promise.all(promises);
  }

  onLikedChanged(contextHash: string): Observable<boolean> {
    return this._ensureLikeChangeSubject(contextHash).asObservable();
  }

  onLikedChangedUnique(contextHash: string): Observable<boolean> {
    const subject = this._ensureLikeChangeSubject(contextHash);
    let oldValue = subject.value;

    return subject.asObservable().pipe(
      filter(value => {
        // Don't notify about liked changing until likes have been set. This was
        // added to prevent a race condition when initially loading the app.
        // Some `mg-like` instances were created before `setLikes()` was called
        // causing the like count to increase when the liked state changed from
        // false to true
        if (!this._likesHaveBeenSet) {
          return false;
        }

        if (value != oldValue) {
          oldValue = value;
          return true;
        }

        return false;
      }),
    );
  }

  private _ensureLikeChangeSubject(
    contextHash: string,
    defaultValue: boolean = false,
  ) {
    if (!this._likeChangeSubjectMap.has(contextHash)) {
      const subject = new BehaviorSubject(defaultValue);
      this._likeChangeSubjectMap.set(contextHash, subject);
    }

    return <BehaviorSubject<boolean>>(
      this._likeChangeSubjectMap.get(contextHash)
    );
  }

  setLikes(likedContextHashes: string[]) {
    this._likesHaveBeenSet = true;
    this._likeChangeSubjectMap.forEach(s => s.next(false));

    for (let likedContextHash of likedContextHashes) {
      const subject = this._ensureLikeChangeSubject(likedContextHash, true);
      subject.next(true);
    }
  }

  setLike(likedContextHash: string) {
    if (!this._likesHaveBeenSet) {
      this.setLikes([likedContextHash]);
    } else {
      const subject = this._ensureLikeChangeSubject(likedContextHash, true);
      subject.next(true);
    }
  }

  likeCount(contextHash: string, likeCount: number) {
    const isLiked = this.isLiked(contextHash);
    return Math.max(isLiked ? 1 : 0, likeCount);
  }

  isLiked(contextHash: string): boolean {
    if (this._pendingLikeState.has(contextHash)) {
      return this._pendingLikeState.get(contextHash) || false;
    } else {
      const subject = this._ensureLikeChangeSubject(contextHash);
      return subject.getValue();
    }
  }

  isLikedStatePending(contextHash: string) {
    return this._pendingLikeState.has(contextHash);
  }

  private async _addLike(contextHash: string) {
    const request = new LikePostRequest();
    request.setContextHash(contextHash);
    this._pendingLikeState.set(contextHash, true);
    const response = await this.postManager.likePost(request);
    const status = response.getStatus();

    if (status !== StatusCode.OK) {
      throw new Error(`StatusCode (${status})`);
    }
    this.contentEvents.emitAddLike(contextHash);
    this.analyticsService.sendLikeTriggered();
  }

  private async _removeLike(contextHash: string) {
    const request = new UnlikePostRequest();
    request.setContextHash(contextHash);
    this._pendingLikeState.set(contextHash, false);
    const response = await this.postManager.unlikePost(request);
    const status = response.getStatus();

    if (status !== StatusCode.OK) {
      throw new Error(`StatusCode (${status})`);
    }
  }

  async toggleLike(contextHash: string) {
    if (!contextHash) {
      throw new Error(`toggleLike() invalid context hash: ${contextHash}`);
    }

    if (this.isLiked(contextHash)) {
      return this.removeLike(contextHash);
    } else {
      return this.addLike(contextHash);
    }
  }

  async addLike(contextHash: string) {
    if (!contextHash) {
      throw new Error(`addLike() invalid context hash: ${contextHash}`);
    }

    // Already liked, ignore call.
    if (this.isLiked(contextHash)) {
      return;
    }

    // Like state is already pending. Ignore call.
    if (this.isLikedStatePending(contextHash)) {
      return;
    }

    const pendingLikeState = this._pendingLikeState;
    const likePromiseMap = this._likePromiseMap;

    pendingLikeState.set(contextHash, true);
    this._notifyLikeChanged(contextHash);

    const promise = this._addLike(contextHash).catch(err => {
      pendingLikeState.delete(contextHash);
      likePromiseMap.delete(contextHash);
      this._notifyLikeChanged(contextHash);
      throw err;
    });

    likePromiseMap.set(contextHash, promise);
    await promise;
    likePromiseMap.delete(contextHash);

    pendingLikeState.delete(contextHash);
    this._notifyLikeChanged(contextHash);
  }

  async removeLike(contextHash: string) {
    if (!contextHash) {
      throw new Error(`removeLike() invalid context hash: ${contextHash}`);
    }

    // Already not-liked, ignore call.
    if (!this.isLiked(contextHash)) {
      return;
    }

    // Like state is already pending. Ignore call.
    if (this.isLikedStatePending(contextHash)) {
      return;
    }

    const pendingLikeState = this._pendingLikeState;
    const likePromiseMap = this._likePromiseMap;

    pendingLikeState.set(contextHash, false);
    this._notifyLikeChanged(contextHash);
    const promise = this._removeLike(contextHash).catch(err => {
      pendingLikeState.delete(contextHash);
      likePromiseMap.delete(contextHash);
      this._notifyLikeChanged(contextHash);
      throw err;
    });

    likePromiseMap.set(contextHash, promise);
    await promise;
    likePromiseMap.delete(contextHash);

    pendingLikeState.delete(contextHash);
    this._notifyLikeChanged(contextHash);
  }

  private _notifyLikeChanged(contentContext: string) {
    const likedState = this.isLiked(contentContext);
    const subject = this._ensureLikeChangeSubject(contentContext);
    subject.next(likedState);
  }

  clear() {
    this._likeChangeSubjectMap = new Map();
    this._pendingLikeState = new Map();
    this._likePromiseMap = new Map();
    this._likesHaveBeenSet = false;
  }
}
