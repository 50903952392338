<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [overlayTitle]="modalConfig.title"
  [isLoading]="data.isLoading"
  [disableCloseErrorMsg]="disableCloseErrorMsg"
  [disableEscape]="true">
  <ng-container *ngIf="data.changes">
    <ng-container *ngTemplateOutlet="mainContent"></ng-container>
  </ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="overlayFooterTpl"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainContent>
  <div
    class="main-content"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [style.marginBottom.rem]="0.5">
    <!-- Name -->
    <mg-form-text-input
      inputType="text"
      [id]="'checkin-types-click-name'"
      [label]="MESSAGES.LABEL_NAME"
      [control]="nameControl"
      [isDisabled]="isDefaultReason">
    </mg-form-text-input>

    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between"
      fxLayoutGap="15px">
      <!-- Color -->
      <div
        fxFlex="45"
        fxFlex.xs="100">
        <mg-form-text-input
          [label]="MESSAGES.LABEL_COLOR"
          inputType="color"
          list="colorDatalist"
          [control]="colorControl"
          [isDisabled]="isDefaultReason">
        </mg-form-text-input>
        <datalist id="colorDatalist">
          <div *ngFor="let item of PRESET_COLORS">
            <option>{{ item }}</option>
          </div>
        </datalist>
      </div>

      <!-- Icon -->
      <div
        fxFlex="45"
        fxFlex.xs="100">
        <mat-form-field
          class="small-input-width-fix"
          fxFlex="noshrink"
          mgFormField
          hideRequiredMarker
          mgNoHintMargin="true"
          appearance="outline"
          [mgHideOptionalMarker]="true">
          <mat-label>{{ MESSAGES.LABEL_ICON }}</mat-label>
          <mat-select
            class="select-icon"
            [disabled]="isDefaultReason"
            [readonly]="isDefaultReason"
            [(ngModel)]="data.changes.icon"
            type="text"
            (ngModelChange)="onChange($event)">
            <mat-select-trigger>
              <mg-icon [iconName]="data.changes.icon"></mg-icon>
            </mat-select-trigger>
            <mat-option
              *ngFor="let icon of CHECKIN_ICONS"
              [value]="icon">
              <mg-icon class="select-icon-container">
                {{ icon }}
              </mg-icon>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Points -->
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <mg-text variant="body-md">{{ MESSAGES.LABEL_POINTS }}</mg-text>
      <div>
        <mg-form-text-input
          inputType="number"
          [id]="'checkin-types-click-points'"
          [control]="pointsControl">
        </mg-form-text-input>
      </div>
    </div>

    <div class="divider"></div>

    <ng-container *ngIf="kioskPermissions.isKioskFeatureEnabled()">
      <div
        fxLayout="column"
        fxLayoutAlign="center start">
        <mg-text
          variant="header-sm-secondary"
          spacing="1">
          {{ MESSAGES.KIOSK_TITLE }}
        </mg-text>
      </div>

      <mg-form-toggle
        [id]="'checkin-types-click-kiosk'"
        [label]="MESSAGES.LABEL_AVAILABLE_IN_KIOSK"
        [tooltip]="MESSAGES.TOOLTIP_KIOSK"
        [disabled]="(kioskEnabled$ | async) === false"
        [control]="kioskControl">
      </mg-form-toggle>

      <div class="divider"></div>
    </ng-container>

    <div
      fxLayout="column"
      fxLayoutAlign="center start">
      <mg-text
        variant="header-sm-secondary"
        spacing="1">
        {{ MESSAGES.RESTRICTION_TITLE }}
      </mg-text>
    </div>

    <!-- Eligble Users -->
    <mg-form-restriction-input
      [label]="MESSAGES.LABEL_RESTRICTION"
      [required]="true"
      [membershipListType]="membershipType"
      [value]="restrictionsControl.value"
      [control]="restrictionsControl">
    </mg-form-restriction-input>

    <div class="divider"></div>

    <!-- Automations -->
    <mg-form-select
      [placeholder]="MESSAGES.LABEL_ASSIGN_BEHAVIOR"
      [value]="data.changes.behaviorId"
      [multiple]="false"
      [searchable]="true"
      [options]="behaviors$ | async"
      [isClearable]="true"
      [disabled]="(hasPbis$ | async) !== true"
      (selectionChanged)="changeBehavior($event)">
    </mg-form-select>

    <!-- Assign Hall Pass -->
    <mg-form-select
      [placeholder]="MESSAGES.LABEL_ASSIGN_HALL_PASS"
      [value]="data.changes.hallPassId"
      [multiple]="false"
      [searchable]="true"
      [options]="hallPasses$ | async"
      [isClearable]="true"
      [disabled]="(hasHp$ | async) !== true"
      (selectionChanged)="changeHallPass($event)">
      <ng-template let-item="item">
        <mg-form-select-option [option]="item"></mg-form-select-option>
      </ng-template>
    </mg-form-select>

    <mg-form-select
      [id]="'checkin-types-click-complete-conseq'"
      [placeholder]="MESSAGES.LABEL_COMPLETE_CONSEQUENCE"
      [value]="data.changes.consequenceId"
      [multiple]="false"
      [options]="consequences$ | async"
      [isClearable]="true"
      [control]="consControl"
      [disabled]="(hasCons$ | async) !== true">
    </mg-form-select>

    <div class="divider"></div>

    <!-- Toggles -->
    <ng-container
      [ngTemplateOutlet]="toggleTemplate"
      [ngTemplateOutletContext]="{
        $implicit: MESSAGES.TRACK_ABSENTEES_MSG,
        control: absenteesControl,
        tooltip: '',
        id: 'checkin-types-click-track-absentees'
      }">
    </ng-container>
    <ng-container
      [ngTemplateOutlet]="toggleTemplate"
      [ngTemplateOutletContext]="{
        $implicit: MESSAGES.NO_ACCESS_MSG,
        control: noAccessControl,
        tooltip: MESSAGES.TOOLTIP_NO_ACCESS,
        id: 'checkin-types-click-no-access'
      }">
    </ng-container>

    <ng-container
      [ngTemplateOutlet]="toggleTemplate"
      [ngTemplateOutletContext]="{
        $implicit: MESSAGES.TOGGLE_ALLOW_MULTIPLE_CHECKINS,
        control: multipleCheckinsControl,
        tooltip: '',
        id: 'checkin-types-click-allow-multiple'
      }">
    </ng-container>

    <!-- Enable self check in / out -->
    <ng-container
      [ngTemplateOutlet]="toggleTemplate"
      [ngTemplateOutletContext]="{
        $implicit: MESSAGES.TOGGLE_LABEL_SELF_CHECKIN,
        control: qrCodeControl,
        tooltip: '',
        id: 'checkin-types-click-allow-self'
      }">
    </ng-container>

    <!-- View QR code -->
    <div
      *ngIf="!isNewReason"
      fxLayout="row"
      fxLayoutAlign="start center">
      <mg-btn
        variant="outlined"
        size="small"
        [id]="'checkin-types-click-qr-code'"
        [disabled]="!qrCodeControl.value"
        (pressed)="openQrViewer()">
        {{ MESSAGES.BUTTON_LABEL_PRINT_QR }}
      </mg-btn>
    </div>

    <!-- Enable student photo field -->
    <ng-container
      [ngTemplateOutlet]="toggleTemplate"
      [ngTemplateOutletContext]="{
        $implicit: MESSAGES.TOGGLE_LABEL_STUDENT_PHOTO,
        control: enableStudentPhotoControl,
        tooltip: MESSAGES.TOOLTIP_STUDENT_PHOTO,
        id: 'checkin-types-click-photo'
      }">
    </ng-container>
  </div>
</ng-template>

<!-- Template: Modal Footer -->
<ng-template #overlayFooterTpl>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [id]="'checkin-types-click-delete'"
        [variant]="isNewReason ? 'text' : 'destructive'"
        (pressed)="handleOnClickDelete()">
        {{
          isNewReason
            ? MESSAGES.BUTTON_LABEL_CANCEL
            : MESSAGES.BUTTON_LABEL_DELETE
        }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        variant="filled"
        [id]="'checkin-types-click-save'"
        [disabled]="!canSubmitForm"
        (pressed)="handleOnClickSave()">
        {{
          isNewReason
            ? MESSAGES.BUTTON_LABEL_CREATE
            : MESSAGES.BUTTON_LABEL_SAVE
        }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<!-- Sticker Item -->
<ng-template
  #stickerItem
  let-sticker>
  <div>
    <button
      class="sticker"
      [ngClass]="{ active: data.changes?.stickerIds?.includes(sticker.id) }"
      (click)="handleOnClickSticker(sticker.id)">
      <mg-image
        class="sticker-image"
        [srcs]="sticker.images"
        [background-size]="'contain'">
      </mg-image>
      <span
        class="sticker-name"
        [title]="sticker.name">
        {{ sticker.name }}
      </span>
    </button>
  </div>
</ng-template>

<!-- Sticker Select None -->
<ng-template #stickerItemNone>
  <div>
    <button
      class="sticker select-none"
      [ngClass]="{ active: !data.changes.stickerIds.length }"
      (click)="handleOnClickSticker()">
      <div class="sticker-image">
        <mg-icon>filtered-o</mg-icon>
      </div>
      <span class="sticker-name">
        {{ MESSAGES.LABEL_SELECT_NONE }}
      </span>
    </button>
  </div>
</ng-template>

<!-- Toggle Template  -->
<ng-template
  #toggleTemplate
  let-name
  let-control="control"
  let-tooltip="tooltip"
  let-id="id">
  <div class="toggle-row">
    <div>
      <mg-text
        element="span"
        variant="body">
        {{ name }}
      </mg-text>
      <mg-tooltip
        *ngIf="tooltip"
        [label]="tooltip">
      </mg-tooltip>
    </div>
    <mat-slide-toggle
      [attr.data-analytics]="id"
      [formControl]="control"></mat-slide-toggle>
  </div>
</ng-template>
