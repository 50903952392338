import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormModule } from '../form';
import { UserListFilterComponent } from './user-list-filter.component';

@NgModule({
  declarations: [UserListFilterComponent],
  exports: [UserListFilterComponent],
  imports: [CommonModule, FormModule],
})
export class UserListFilterModule {}
