import { WEBCAM_QUAGGA_CONFIG } from '../constants';
import { CameraScannerCommonReaders } from '../types';

export const getQuaggaConfig = (
  target: Element | string,
  enabledReaders?: CameraScannerCommonReaders[],
) => {
  const readers = [];
  if (enabledReaders?.includes('barcode'))
    readers.push('code_128_reader', 'code_39_reader');
  if (enabledReaders?.includes('qrcode')) readers.push('qrcode');
  return {
    ...WEBCAM_QUAGGA_CONFIG,
    target,
    decoder: {
      ...WEBCAM_QUAGGA_CONFIG.decoder,
      readers,
    },
  };
};
