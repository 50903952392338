import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ISelectRoleSetting } from 'minga/app/src/app/roles/components/MultiSelectRole';
import { IRoleInfo, RolesService } from 'minga/app/src/app/roles/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mg-comment-settings-selection',
  templateUrl: './CommentSettingsSelection.component.html',
  styleUrls: ['./CommentSettingsSelection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentSettingsSelectionComponent {
  @Input()
  commentingRoles: string[] = [];

  @Output()
  commentingRolesChange = new EventEmitter<string[] | null>();

  availableCommentRoles$: Observable<ISelectRoleSetting[]>;

  constructor(private rolesService: RolesService) {
    this.initRoles();
    this.availableCommentRoles$ =
      rolesService.availableCommentPermitableRoles$.pipe(
        map(roleInfos => {
          const roles = roleInfos.map(roleInfo => {
            const selectRoleSetting: ISelectRoleSetting = {
              disabled: roleInfo.immutableCommentPermittable,
              name: roleInfo.name,
              roleType: roleInfo.roleType,
              get tooltip() {
                return '';
              },
            };

            return selectRoleSetting;
          });
          return roles;
        }),
      );
  }

  async initRoles() {
    await this.rolesService.fetchIfNeeded();
  }

  onSelectingCommentingRolesChange(roleTypes: string[]) {
    this.commentingRoles = roleTypes;
  }

  onRoleSelect(roleType: string) {
    this.commentingRoles.push(roleType);
    this.commentingRolesChange.emit(this.commentingRoles);
  }

  onRoleDeselect(roleType: string) {
    this.commentingRoles = this.commentingRoles.filter(
      value => value != roleType,
    );
    this.commentingRolesChange.emit(this.commentingRoles);
  }
}
