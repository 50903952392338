import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import * as _ from 'lodash';
import { ContentPublishInfoEditComponent } from 'minga/app/src/app/content-common/services/ContentManager';
import { IMingaContentPublishInfo } from 'minga/app/src/app/content-common/services/ContentManager/minga';
import { CommentHandlerService } from 'minga/app/src/app/services/CommentHandler';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';

// 24 hours in milliseconds
const hr24 = 8.64e7;

// special diff checker function specifically tailored to this component
function isDiff(initialValue: any | undefined, value: any) {
  if (typeof initialValue !== 'undefined') {
    if (value?.getTime) value = value.getTime();
    if (initialValue?.getTime) initialValue = initialValue.getTime();

    return value !== initialValue;
  }

  return true;
}

@Component({
  selector: 'mg-minga-content-publish-info-editor',
  templateUrl: './MingaContentPublishInfoEditor.component.html',
  styleUrls: ['./MingaContentPublishInfoEditor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MingaContentPublishInfoEditorComponent extends ContentPublishInfoEditComponent<IMingaContentPublishInfo> {
  private _initialPublishInfo: Partial<IMingaContentPublishInfo> = {};
  private _publishInfoChange = new BehaviorSubject<
    Partial<IMingaContentPublishInfo>
  >({});

  readonly publishInfoChange$: Observable<Partial<IMingaContentPublishInfo>>;

  publishDate: Date | null = null;
  unpublishDate: Date | null = null;
  pinned: boolean = false;
  pinDays: number = 1;
  endPinDate: Date | null = null;
  allowedCommentingRoleTypes: string[] | null = [];
  publishInfoInited: boolean = false;

  get isPublishDateDifferent() {
    return isDiff(this._initialPublishInfo.publishDate, this.publishDate);
  }

  get isUnpublishDateDifferent() {
    return isDiff(this._initialPublishInfo.unpublishDate, this.unpublishDate);
  }

  get isPinDifferent() {
    return isDiff(this._initialPublishInfo.endPinDate, this.endPinDate);
  }

  get isCommentRoleTypesDifferent() {
    const initial = this._initialPublishInfo.commentRoleTypes;
    const newRoles = this.allowedCommentingRoleTypes;
    if (initial && newRoles) {
      return !_.isEqual(initial.sort(), newRoles.sort());
    } else return !(initial == newRoles);
  }

  constructor(
    private commentHandler: CommentHandlerService,
    private _cdr: ChangeDetectorRef,
  ) {
    super();
    this.publishInfoChange$ = this._publishInfoChange.asObservable();
  }

  triggerChange() {
    this._publishInfoChange.next(this.getPartialPublishInfo());
  }

  /**
   * Set the publish info. May be partial.
   */
  setPublishInfo(publishInfo: Partial<IMingaContentPublishInfo>): void {
    if (typeof publishInfo.publishDate !== 'undefined') {
      this.publishDate = publishInfo.publishDate;
    }

    if (typeof publishInfo.unpublishDate !== 'undefined') {
      this.unpublishDate = publishInfo.unpublishDate;
    }

    if (typeof publishInfo.endPinDate !== 'undefined') {
      this.endPinDate = publishInfo.endPinDate;

      // set today to last minute before midnight to match publish control pin
      // days's values
      const nowDate = new Date();
      nowDate.setHours(23);
      nowDate.setMinutes(59);
      nowDate.setSeconds(0);
      nowDate.setMilliseconds(0);

      const now = nowDate.getTime();
      this.endPinDate = publishInfo.endPinDate;

      if (this.endPinDate && this.endPinDate.getTime() > now) {
        this.pinned = true;
        this.pinDays = Math.round((this.endPinDate.getTime() - now) / hr24);
      } else {
        this.pinned = false;
      }
    }

    if (typeof publishInfo.commentRoleTypes !== 'undefined') {
      this.allowedCommentingRoleTypes = publishInfo.commentRoleTypes;
    } else {
      if (
        !this.allowedCommentingRoleTypes ||
        !(this.allowedCommentingRoleTypes.length > 0)
      ) {
        this.commentHandler
          .getDefaultCommentableRoles()
          .pipe(first())
          .toPromise()
          .then(roles => {
            if (
              !this.allowedCommentingRoleTypes ||
              !(this.allowedCommentingRoleTypes.length > 0)
            ) {
              this.allowedCommentingRoleTypes = roles;
              this._initialPublishInfo.commentRoleTypes = roles;
            }
          });
      }
    }

    if (!this.publishInfoInited) {
      this._initialPublishInfo = publishInfo;
      this.publishInfoInited = true;
    }
    this._cdr.markForCheck();
  }

  /**
   * Get the current publish info. Do not return partial data.
   */
  getPublishInfo(): IMingaContentPublishInfo {
    let commentRoles = this.allowedCommentingRoleTypes;
    // only return comment roles if they aren't using the default.
    if (!this.isCommentRoleTypesDifferent) {
      commentRoles = null;
    }
    return {
      publishDate: this.publishDate,
      unpublishDate: this.unpublishDate,
      endPinDate: this.endPinDate,
      commentRoleTypes: commentRoles,
    };
  }

  /**
   * Return the current publish info allowing for partial data to be returned
   */
  getPartialPublishInfo(): Partial<IMingaContentPublishInfo> {
    const info: Partial<IMingaContentPublishInfo> = {};

    if (this.isPublishDateDifferent) {
      info.publishDate = this.publishDate;
    }

    if (this.isUnpublishDateDifferent) {
      info.unpublishDate = this.unpublishDate;
    }

    if (this.isPinDifferent) {
      info.endPinDate = this.endPinDate;
    }

    if (this.isCommentRoleTypesDifferent) {
      info.commentRoleTypes = this.allowedCommentingRoleTypes;
    }
    return info;
  }
}
