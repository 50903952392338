import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';

import { PermissionsService } from 'minga/app/src/app/permissions';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { NotificationManager } from 'minga/proto/gateway/notification_ng_grpc_pb';
import { BroadcastNotificationRequest } from 'minga/proto/gateway/notification_pb';
import { MingaPermission } from 'minga/util';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface IBroadcastNotificationDialogResponse {
  canceled?: boolean;
  successOverlay?: TemplateRef<BroadcastNotificationDialog>;
}

@Component({
  selector: 'mg-broadcast-notification-dialog',
  templateUrl: './BroadcastNotification.component.html',
  styleUrls: ['./BroadcastNotification.component.scss'],
})
export class BroadcastNotificationDialog implements OnInit {
  public pushNoteTitle: string;
  public pushNoteBody: string;
  public translateParams: any;
  public loading: boolean;
  public isIOS: boolean = window.MINGA_DEVICE_IOS;
  public enableGlobalBroadcast: boolean;
  public broadcastGlobally: boolean;
  public currentMingaName: string;
  public confirmString = 'ALL';
  public confirmStringInput: string;
  public shouldSendAsEmail: boolean;

  public titleControl: FormControl;
  public bodyControl: FormControl;

  @ViewChild('successOverlayTemplate', { static: true })
  successOverlayTemplate: TemplateRef<BroadcastNotificationDialog>;

  @ViewChild('form', { read: NgForm, static: true })
  form: NgForm;

  constructor(
    @Inject(MODAL_OVERLAY_DATA) public data: any,
    private _modalRef: ModalOverlayRef<BroadcastNotificationDialog>,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _permissions: PermissionsService,
    private _notificationManager: NotificationManager,
  ) {
    if (data.mingaName) {
      this.currentMingaName = data.mingaName;
      this.translateParams = { value: data.mingaName };
    } else {
      this.currentMingaName = 'this Minga';
      this.translateParams = { value: 'this Minga' };
    }
  }

  ngOnInit() {
    this.initForm();
  }

  get titleControlErrorKey() {
    return this.titleControl.errors
      ? Object.keys(this.titleControl.errors)[0]
      : '';
  }

  get bodyControlErrorKey() {
    return this.bodyControl.errors
      ? Object.keys(this.bodyControl.errors)[0]
      : '';
  }

  onKeydown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submit();
    }
  }

  close() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  initForm() {
    const commonValidators = [
      Validators.minLength(1),
      // MgValidators.NoSymbols,
    ];

    this.titleControl = new FormControl(
      this.pushNoteTitle,
      commonValidators.concat([Validators.maxLength(120)]),
    );
    this.bodyControl = new FormControl(
      this.pushNoteBody,
      commonValidators.concat([Validators.maxLength(1024)]),
    );

    this.form.form.addControl('pushNoteTitle', this.titleControl);
    this.form.form.addControl('pushNoteBody', this.bodyControl);
    this.form.form.markAsPristine();
  }

  async initPermissions() {
    this.enableGlobalBroadcast = this._permissions.hasPermission(
      MingaPermission.SUPERADMIN,
    );
  }

  isSubmitDisabled() {
    return (
      (this.form && (this.form.pristine || !this.form.valid)) ||
      (!this.pushNoteTitle && !this.pushNoteBody) ||
      (this.broadcastGlobally &&
        this.confirmStringInput !== this.confirmString) ||
      this.loading
    );
  }

  async submit() {
    if (this.isSubmitDisabled()) {
      return false;
    }
    this.loading = true;

    const request = new BroadcastNotificationRequest();
    request.setNotificationTitle(this.pushNoteTitle);
    request.setNotificationBody(this.pushNoteBody);
    request.setAllMingasBroadcast(this.broadcastGlobally);
    request.setSendEmail(this.shouldSendAsEmail);

    const response = await this._notificationManager.sendBroadcast(request);
    const status = response.getStatus();

    if (status === StatusCode.OK) {
      this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT);
      this._systemAlertSnackBar.success('Alert Sent!');
    } else if (status === StatusCode.INSUFFICIENT_PERMISSIONS) {
      this._systemAlertSnackBar.error(
        `You have insufficient permissions for this content`,
      );
    }
  }

  onBroadcastToggleChange() {
    if (this.broadcastGlobally) {
      this.translateParams = { value: 'ALL Mingas' };
    } else {
      this.translateParams = { value: this.currentMingaName };
    }
  }
}
