import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgIconModule } from 'minga/app/src/app/icon';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { OverlayModule } from '@shared/components/modal-overlay';

import { GenericModule } from '../generic';
import { ImageViewerModalComponent } from './image-viewer-modal.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    MgOverlayToolsModule,
    OverlayModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [ImageViewerModalComponent],
  exports: [ImageViewerModalComponent],
})
export class ImageViewerModalModule {}
