<div
  class="container minga-ng-select-container"
  [attr.data-analytics]="id"
  [attr.data-test]="id">
  <!-- Arrow Icon -->
  <div class="search-icon">
    <mat-icon matSuffix>search</mat-icon>
  </div>
  <label
    *ngIf="showLabel"
    class="input-label focused"
    [ngClass]="labelBackground"
    [class.has-input]="!!(selected$ | async)">
    {{ placeholder }}
  </label>
  <ng-select
    bindLabel="displayName"
    [items]="searchResults$ | async"
    [closeOnSelect]="true"
    [ngModel]="selected$ | async"
    (ngModelChange)="updateSelected($event)"
    [multiple]="multiple"
    [hideSelected]="true"
    [minTermLength]="3"
    [loading]="isLoading$ | async"
    [typeToSearchText]="MESSAGES.LABEL"
    [placeholder]="displayPlaceholder"
    [typeahead]="input$"
    [inputAttrs]="inputAttrs"
    [clearable]="false"
    [openOnEnter]="false"
    (open)="setOpenState(true)"
    (close)="setOpenState(false)">
    <ng-template
      ng-label-tmp
      let-person="item"
      let-clear="clear">
      <div class="selected-option">
        <span
          class="badge-img"
          [style.backgroundImage]="'url(' + person.badgeIconUrl + ')'">
        </span>
        <div
          class="label"
          title="{{ person.firstName }} {{ person.lastName }}">
          <span>{{ person.firstName }} {{ person.lastName }}</span>
        </div>
        <span
          class="close"
          (click)="clear(person)">
          <mat-icon>close</mat-icon>
        </span>
      </div>
      <!-- Loading Template -->
      <ng-template ng-loadingspinner-tmp>
        <mg-spinner
          [diameter]="20"
          [thickness]="1">
        </mg-spinner>
      </ng-template>
    </ng-template>
    <ng-template
      ng-option-tmp
      let-person="item"
      let-search="searchTerm"
      let-index="index">
      <div
        class="label"
        title="{{ person.firstName }} {{ person.lastName }}">
        <span>{{ person.firstName }} {{ person.lastName }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
