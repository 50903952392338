import * as $angular_core from '@angular/core'; //TS2742

import { ContentManager } from './ContentManager';

/**
 * ContentManager that is context aware. The methods on ContextContentManager
 * should be indentical to ContentManager except the ContextInfo part is
 * removed because it is implied.
 */
export class ContextualContentManager<
  ContextInfo,
  PublishInfo,
  T extends ContentManager<ContextInfo, PublishInfo>,
> {
  /**
   * Name of the underlying ContentManager.
   * @SEE ContentManager.name
   */
  readonly name = this.contentManager.name;

  constructor(
    private contentManager: T,
    private contextInfoGetter: () => ContextInfo,
  ) {}

  /**
   * ContentManager.addContent with implied contextInfo
   * @SEE ContentManager.addContent()
   */
  addContent(contentHash: string, publishInfo: PublishInfo) {
    return this.contentManager.addContent(
      this.contextInfoGetter(),
      contentHash,
      publishInfo,
    );
  }

  /**
   * ContentManager.moveContent with implied contextInfo
   * @SEE ContentManager.moveContent()
   */
  moveContent(contentHash: string, publishInfoDelta: Partial<PublishInfo>) {
    return this.contentManager.moveContent(
      this.contextInfoGetter(),
      contentHash,
      publishInfoDelta,
    );
  }

  /**
   * ContentManager.getPublishInfo with implied contextInfo
   * @SEE ContentManager.getPublishInfo()
   */
  getPublishInfo(contentHash: string) {
    return this.contentManager.getPublishInfo(
      this.contextInfoGetter(),
      contentHash,
    );
  }

  /**
   * Forward call to underlying ContentManager.getDefaultPublishInfo()
   * @SEE ContentManager.getDefaultPublishInfo()
   */
  getDefaultPublishInfo() {
    return this.contentManager.getDefaultPublishInfo();
  }

  /**
   * Forward call to underlying ContentManager.getPublishInfoEditComponent()
   * @SEE ContentManager.getPublishInfoEditComponent()
   */
  getPublishInfoEditComponent() {
    return this.contentManager.getPublishInfoEditComponent();
  }
}
