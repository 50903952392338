import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';

import * as checkin_pb from 'minga/proto/checkin/checkin_pb';
import {
  CHECKIN_ICONS,
  CheckinReasonType,
  EditableCheckin,
  ICheckinReason,
  ICheckinReasonWithCounts,
} from 'minga/domain/checkin';
import { RestrictionErrorMinimal } from 'minga/domain/restrictions';
import { CheckinManager } from 'minga/proto/checkin/checkin_ng_grpc_pb';
import { CheckinReasonMapper, CheckinsMapper } from 'minga/shared-grpc/checkin';
import { RestrictionErrorMapper } from 'minga/shared-grpc/restriction';
import { RootService } from 'src/app/minimal/services/RootService';

export type PersonDetails = { name: string; hash: string };

export type CheckinResponseDetails = {
  successPeopleHashes: string[];
  errors: RestrictionErrorMinimal[];
};

/**
 * Checkin Service
 */
@Injectable({ providedIn: 'root' })
export class CheckinService {
  /** Constructor */
  constructor(
    private _checkInManager: CheckinManager,
    private _rootService: RootService,
  ) {}

  /**
   * Get Reasons
   */
  public getReasonsObs(
    getActiveOnly: boolean = true,
  ): Observable<ICheckinReason[]> {
    return from(this.getReasons(getActiveOnly));
  }

  /**
   * Handle Get Reasons
   *
   * This is the actual request to fetch reasons
   *
   * @todo fix hardcoded offset and limit
   */
  public async getReasons(
    getActiveOnly: boolean = true,
    includeCounts: boolean = false,
    getKioskOnly: boolean = false,
  ): Promise<ICheckinReasonWithCounts[]> {
    const req = new checkin_pb.GetMingaCheckinReasonsRequest();
    req.setGetActiveOnly(getActiveOnly);
    req.setKioskOnly(getKioskOnly);
    req.setOffset(0);
    req.setLimit(1000);
    req.setIncludeCounts(includeCounts);

    const res = await this._checkInManager.getMingaCheckinReasons(req);

    return res.getCheckinReasonsList().map(CheckinReasonMapper.fromProto);
  }

  /**
   * Get Reason
   *
   * Gets a single reason by ID, kept seperate from getReasons to keep methods
   * predictable and simple.
   */
  public getReason(id: number): Observable<ICheckinReason> {
    const req = new checkin_pb.GetMingaCheckinReasonRequest();

    req.setId(id);

    return from(this._handleGetReason(req));
  }

  private async _handleGetReason(
    req: checkin_pb.GetMingaCheckinReasonRequest,
  ): Promise<ICheckinReason> {
    const res = await this._checkInManager.getMingaCheckinReason(req);

    const reason = res.getCheckinReason();

    return CheckinReasonMapper.fromProto(reason);
  }

  /**
   * Create Type With Placeholder
   *
   * Creates a new type with placeholder values
   */
  public async createReasonWithPlaceholder(): Promise<ICheckinReason> {
    return await this.updateReason({
      name: '',
      mingaId: 0,
      active: false,
      pointReward: 0,
      icon: CHECKIN_ICONS[0],
      showAbsentees: false,
      selfCheckIn: false,
      stickerIds: [],
      checkinReasonType: CheckinReasonType.CHECKIN,
    });
  }

  /**
   * Update Type
   */
  public async updateReason(
    type: ICheckinReason,
    bypassRestrictions?: boolean,
  ): Promise<ICheckinReason> {
    const req = new checkin_pb.UpsertCheckinReasonRequest();

    req.setCheckinReason(CheckinReasonMapper.toProto(type));
    if (bypassRestrictions) req.setBypassRestrictions(bypassRestrictions);

    const res = await this._checkInManager.upsertCheckinReason(req);

    return CheckinReasonMapper.fromProto(res.getCheckinReason());
  }

  public async toggleCheckinReasonActive(reason: ICheckinReason) {
    const req = new checkin_pb.ToggleCheckinReasonActiveRequest();
    req.setCheckinReasonId(reason.id);

    await this._checkInManager.toggleCheckinReasonActive(req);
  }

  /**
   * Delete Reason
   */
  public async deleteReason(id: number): Promise<void> {
    const req = new checkin_pb.DeleteCheckinReasonRequest();

    req.setCheckinReasonId(id);

    await this._checkInManager.deleteCheckinReason(req);
  }

  /**
   * Add Default Checkin Reasons To Minga
   */
  public async addDefaultCheckinReasonsToMinga(mingaHash: string) {
    const req = new checkin_pb.AddDefaultCheckinReasonsRequest();

    req.setMingaHash(mingaHash);

    const res = await this._checkInManager.addDefaultCheckinReasonsToMinga(req);
    const err = res.getErrorMessage();

    return err;
  }

  /**
   * Create Checkin
   */
  public async createCheckin(
    personHashes: string[],
    reasonId: string | number,
    opts?: {
      note?: string;
      bypassMultiCheckin?: boolean;
      createdViaKiosk?: boolean;
    },
  ): Promise<CheckinResponseDetails> {
    const { note, createdViaKiosk, bypassMultiCheckin } = opts || {};

    try {
      const req = new checkin_pb.CheckinRequest();
      req.setPeopleHashesList(personHashes);
      req.setCheckinReasonId(reasonId as number);
      req.setBypassMultiCheck(bypassMultiCheckin ?? false);
      req.setNote(note);
      req.setIsKiosk(createdViaKiosk ?? false);

      const response =
        await this._rootService.addLoadingPromise<checkin_pb.CheckinResponse>(
          this._checkInManager.checkin(req),
        );
      return {
        errors: response.getErrorList().map(RestrictionErrorMapper.fromProto),
        successPeopleHashes: response.getSuccessPeopleHashesList(),
      };
    } catch (e) {
      throw new Error(e.message);
    }
  }

  /**
   * Create Checkout
   */
  public async createCheckout(
    personHashes: string[],
    reasonId: string | number,
    note?: string,
  ) {
    try {
      const req = new checkin_pb.CheckoutRequest();

      req.setPeopleHashesList(personHashes);
      req.setCheckinReasonId(reasonId as number);

      const response =
        await this._rootService.addLoadingPromise<checkin_pb.CheckoutResponse>(
          this._checkInManager.checkout(req),
        );

      const errors = response
        .getErrorList()
        .map(RestrictionErrorMapper.fromProto);

      return {
        errors,
        successPeopleHashes: response.getSuccessPeopleHashesList(),
      };
    } catch (e) {
      throw new Error(e.message);
    }
  }

  public async updateCheckin(checkin: EditableCheckin) {
    const req = new checkin_pb.UpdateCheckinRequest();
    req.setCheckin(CheckinsMapper.toProtoEditable(checkin));

    const response = await this._checkInManager.updateCheckin(req);
    checkin = CheckinsMapper.fromProtoEditable(response.getCheckin());
    return checkin;
  }
}
