import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { IdService } from './services';

@Component({
  selector: 'mg-id',
  templateUrl: './id.component.html',
  styleUrls: ['./id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IdService],
})
export class IdComponent implements OnDestroy {
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Component Constructor */
  constructor() {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
