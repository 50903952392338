import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';

import * as day from 'dayjs';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatePresetsService } from '@shared/services/date-presets/date-presets.service';

import { FormDateHeaderComponent } from '../form-date-header/form-date-header.component';

@Component({
  selector: 'mg-form-date-range-calendar',
  templateUrl: './form-date-range-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateRangeCalendarComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private _destroySubject = new ReplaySubject<void>(1);
  @ViewChild('calendar', { static: true }) calendar:
    | MatCalendar<Date>
    | undefined;
  public customHeader = FormDateHeaderComponent;

  @Input() public selectedRange: DateRange<Date> = new DateRange<Date>(
    null,
    null,
  );
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Output() public dateSelected = new EventEmitter<{
    start: Date;
    end: Date;
  }>();
  @Input() public pickerStyle: 'mobile' | 'desktop';

  public activeDate: Date | null = day().add(1, 'month').toDate();

  constructor(private _presetService: DatePresetsService) {}

  ngOnInit(): void {
    this._presetService.datePresetChange$
      .pipe(takeUntil(this._destroySubject))
      .subscribe(preset => {
        if (preset) {
          this._updateCalendarMonthView(preset.startDate.toDate());
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.selectedRange.start) {
      this._updateCalendarMonthView(this.selectedRange.start);
    }
  }

  ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  public onDateSelected(date: Date) {
    let start = null;
    let end = null;

    if (this._isSettingStartDate()) {
      start = date;
      end = null;
    } else {
      // if selected end date is before start lets reverse dates
      if (day(date).isBefore(day(this.selectedRange.start))) {
        start = date;
        end = this.selectedRange.start;
      } else {
        start = this.selectedRange.start;
        end = date;
      }
    }

    this.selectedRange = new DateRange<Date>(start, end);
    this.dateSelected.next({ start, end });
  }

  private _isSettingStartDate(): boolean {
    return !this.selectedRange.start || !!this.selectedRange.end;
  }

  // this will set the calendar to the correct month
  private _updateCalendarMonthView(date: Date) {
    this.calendar._goToDateInView(date, 'month');
  }
}
