<div
  class="sidebar"
  fxLayout="column"
  fxLayoutGap="12px">
  <mg-form-grouped-select
    [placeholder]="MESSAGES.FORM_LABEL_SEARCH_ACTION"
    [clearOnSelect]="true"
    [options]="groupedOptions$ | async"
    (selectChange)="searchActionChange($event)"></mg-form-grouped-select>

  <mg-form-select
    *ngIf="categories$ | async"
    [control]="form.get(FORM_FIELDS.CATEGORY)"
    [placeholder]="MESSAGES.FORM_LABEL_CATEGORY"
    [options]="categories$ | async"
    [closeOnSelect]="true"
    (selectionChanged)="onCategoryChange($event)">
    <ng-template let-item="item">
      <div
        class="option-category"
        [ngClass]="'myclass-category-' + item.label | slugify"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="8px">
        <mg-icon
          [iconName]="item.icon"
          [icon-namespace]="item?.iconNamespace"></mg-icon>
        <mg-text variant="body-md">
          {{ item.label }}
        </mg-text>
      </div>
    </ng-template>
  </mg-form-select>

  <ng-container *ngIf="assignmentActions$ | async as assignmentActions">
    <div class="sidebar-tiles">
      <div
        *ngIf="assignmentActions?.length > 0; else empty"
        class="sidebar-tiles-wrap"
        [ngClass]="tileClasses"
        fxLayout="column"
        fxLayoutGap="8px">
        <mg-sa-tile
          *ngFor="let item of assignmentActions"
          [ngClass]="
            'myclass-tile-' +
              item.action?.category.label +
              '-' +
              item.action.value | slugify
          "
          [tile]="item.tile"
          [selected]="
            form.get(FORM_FIELDS.SELECTED_ACTION).value?.value ===
            item.action.value
          "
          (pressed)="selectAction(item.action)"></mg-sa-tile>
      </div>

      <ng-template #empty>
        <mg-empty-state
          class="sidebar-empty-state"
          [title]="MESSAGES.MOST_USED_EMPTY_TITLE"
          [subtitle]="MESSAGES.MOST_USED_EMPTY_SUBTITLE"></mg-empty-state>
      </ng-template>

      <ng-container *ngIf="assignmentActions?.length > (tilesToShow$ | async)">
        <mg-btn
          variant="text"
          (pressed)="toggleShowMore()"
          >{{
            (showMoreActions$ | async)
              ? MESSAGES.SIDEBAR_SHOW_LESS_BTN
              : MESSAGES.SIDEBAR_SHOW_MORE_BTN
          }}</mg-btn
        >
      </ng-container>
    </div>
  </ng-container>

  <ng-content></ng-content>
</div>
