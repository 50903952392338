import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { ReplaySubject } from 'rxjs';

import { FileListStyleEnum } from 'minga/app/src/app/components/FileList';
import { IFileListDeleteEventInfo } from 'minga/app/src/app/components/FileListContainer';
import { FileUploadManager } from 'minga/app/src/app/file';
import { IMingaFile } from 'minga/app/src/app/models/MingaFiles';
import { MingaManagerService } from 'minga/app/src/app/services/MingaManager';
import { FileDetails } from 'minga/proto/gateway/file_pb';

import { LayoutService } from '@modules/layout/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Component({
  selector: 'mg-home-files',
  templateUrl: './home-files.component.html',
  styleUrls: ['./home-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeFilesComponent implements OnInit, OnDestroy {
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // State
  mingaFiles: IMingaFile[] = [];
  fileListStyle = FileListStyleEnum.CARDS;

  /** Component constructor */
  constructor(
    public layout: LayoutService,
    private _router: Router,
    private _mingaManager: MingaManagerService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _fileUploadManager: FileUploadManager,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.retrieveFiles();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public onFileChange(event: File[]) {
    this._fileUploadManager.addPendingFiles(`mingaFiles`, event);

    this._router.navigate(['', { outlets: { o: ['files~upload'] } }]);
  }

  public async retrieveFiles() {
    try {
      const result = await this._mingaManager.getFiles();
      this.mingaFiles = result;
      this._cdr.markForCheck();
    } catch (error) {
      console.log(`retrieve minga files error: `, error);
      this._systemAlertSnackBar.error(
        `unable to retrieve files, please try again later.`,
      );
    }
  }

  public onFileDelete(fileInfo: IFileListDeleteEventInfo) {
    this._removeFile(fileInfo.file, fileInfo.index);
  }

  private async _removeFile(file: FileDetails.AsObject, index: number) {
    // assume the file is going to delete successfully
    this.mingaFiles.splice(index, 1);

    await this._mingaManager
      .removeFile(file.hash)
      .then(responseFiles => {
        this.mingaFiles = responseFiles;
      })
      .catch(err => {
        console.error(`onFileIndexDelete() removeGroupFile error: `, err);

        // restore file that wasn't deleted
        this.mingaFiles.push({ file });

        this._systemAlertSnackBar.error(
          `uh oh, an error occurred, please try again later`,
        );
        this._cdr.markForCheck();
      });
  }
}
