<!--  Main Content -->
<div>
  <ng-container *ngTemplateOutlet="chartSectionTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="topUsersTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="topCreatorsTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="topPassesTemplate"></ng-container>
</div>

<!-- Chart Template-->
<ng-template #chartSectionTemplate>
  <section
    *ngIf="summaryData$ | async; let data"
    class="chart"
    fxLayout="row"
    fxLayout.lt-md="column-reverse"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="20px">
    <!-- Metrics Stats List -->
    <div fxFlex="nogrow">
      <div
        class="metrics-list"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="30px">
        <mg-count-tile
          size="large"
          [count]="formatTotalTime(data.totalTime, false)"
          [label]="MESSAGES.STAT_CARD_TOTAL_TIME"
          [color]="'#E67AA0'"
          [icon]="'hallpass'"
          [fixedHeight]="true"
          [isActive]="isActive(SORT_BY.TIME)"
          [backgroundColor]="
            (layout.useLegacyLayout$ | async) ? 'grey' : 'white'
          "
          (tileClicked)="chartSort.next(SORT_BY.TIME)">
        </mg-count-tile>
        <mg-count-tile
          size="large"
          [count]="data.totalPasses"
          [label]="MESSAGES.STAT_CARD_TOTAL_PASSES"
          [color]="'#E67AA0'"
          [icon]="'hallpass'"
          [fixedHeight]="true"
          [isActive]="isActive(SORT_BY.PASSES)"
          [backgroundColor]="
            (layout.useLegacyLayout$ | async) ? 'grey' : 'white'
          "
          (tileClicked)="chartSort.next(SORT_BY.PASSES)">
        </mg-count-tile>
      </div>
    </div>
    <!-- Graph -->
    <div fxFlex="grow">
      <div
        #chartContainerElement
        class="chart-container">
        <div
          *ngIf="isLoading$ | async"
          class="chart-loading">
          <mg-spinner
            [diameter]="100"
            [thickness]="5">
          </mg-spinner>
        </div>
        <div
          *ngIf="(isLoading$ | async) === false && !data?.totalPasses"
          class="chart-no-data">
          <span></span>
          <p>{{ MESSAGES.NO_DATA }}</p>
        </div>
        <div
          #chartElement
          class="canvas-container">
          <canvas
            baseChart
            [datasets]="chartDataSets"
            [labels]="chartLabels"
            [options]="HPM_CHART.options"
            [plugins]="HPM_CHART.plugins"
            [legend]="HPM_CHART.legend"
            [chartType]="HPM_CHART.type">
          </canvas>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<!-- Top Users Template -->
<ng-template #topUsersTemplate>
  <section class="section">
    <div class="section-table-container">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px">
        <mg-text variant="header-sm-secondary">
          {{ MESSAGES.SECTION_HEADING_USERS }}
        </mg-text>
        <mg-btn
          variant="outlined"
          size="small"
          [id]="'report-report-view-click-filter-student'"
          (pressed)="onChangeReportType(REPORT_TYPE.HALL_PASS_STUDENT)">
          {{ MESSAGES.BUTTON_SEE_ALL }}
        </mg-btn>
      </div>
      <mg-report-table
        matSort
        [dataSourceService]="studentDataSource"
        [showPaginator]="false"
        [disableMaxHeight]="true"
        [sortable]="false">
        <mg-role-column [datasource]="studentDataSource"></mg-role-column>
        <mg-history-column
          [datasource]="studentDataSource"
          colorTheme="navy">
        </mg-history-column>
        <mg-summary-column
          [datasource]="studentDataSource"
          colorTheme="navy">
        </mg-summary-column>
      </mg-report-table>
    </div>
  </section>
</ng-template>

<!-- Top Creators Template -->
<ng-template #topCreatorsTemplate>
  <section class="section">
    <div class="section-table-container">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px">
        <mg-text variant="header-sm-secondary">
          {{ MESSAGES.SECTION_HEADING_CREATORS }}
        </mg-text>
        <mg-btn
          variant="outlined"
          size="small"
          [id]="'report-report-view-click-filter-staff'"
          (pressed)="onChangeReportType(REPORT_TYPE.HALL_PASS_STAFF)">
          {{ MESSAGES.BUTTON_SEE_ALL }}
        </mg-btn>
      </div>
      <mg-report-table
        matSort
        [dataSourceService]="creatorDataSource"
        [showPaginator]="false"
        [disableMaxHeight]="true"
        [sortable]="false">
        <mg-role-column [datasource]="creatorDataSource"></mg-role-column>
        <mg-history-column
          [datasource]="creatorDataSource"
          colorTheme="navy">
        </mg-history-column>
        <mg-summary-column
          [datasource]="creatorDataSource"
          colorTheme="navy">
        </mg-summary-column>
      </mg-report-table>
    </div>
  </section>
</ng-template>

<!-- Top Passes Template -->
<ng-template #topPassesTemplate>
  <section class="section">
    <div class="section-table-container">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="12px">
        <mg-text variant="header-sm-secondary">
          {{ MESSAGES.SECTION_HEADING_HALL_PASSES }}
        </mg-text>
        <mg-btn
          variant="outlined"
          size="small"
          (pressed)="onChangeReportType(REPORT_TYPE.HALL_PASS_TYPES)">
          {{ MESSAGES.BUTTON_SEE_ALL }}
        </mg-btn>
      </div>
      <mg-report-table
        matSort
        [dataSourceService]="typesDataSource"
        [showPaginator]="false"
        [disableMaxHeight]="true"
        [sortable]="false">
        <mg-history-column
          [datasource]="typesDataSource"
          colorTheme="navy">
        </mg-history-column>
        <mg-summary-column
          [datasource]="typesDataSource"
          colorTheme="navy">
        </mg-summary-column>
      </mg-report-table>
    </div>
  </section>
</ng-template>
