<div class="card-container">
  <div
    class="mg-headline person-name"
    *ngIf="(person$ | async)?.displayName as displayName">
    {{ displayName }}
  </div>

  <div
    *ngFor="let group of groupedUserPreferences$ | async; trackBy: groupTrackBy"
    class="user-preference-input-wrap mg-minicopy">
    <mat-expansion-panel mgExpansionPanel>
      <mat-expansion-panel-header>
        <mat-panel-title class="mg-headline3">
          <strong>{{ group.category }}</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mg-user-preference-input
        [value]="preference.value"
        (valueChange)="preferenceValueChange(preference, $event)"
        *ngFor="
          let preference of group.preferences;
          trackBy: preferenceTrackBy
        ">
        <mg-user-preference-input-label>
          {{ preference.name }}
          <mg-tooltip
            *ngIf="preference.description"
            [label]="preference.description">
          </mg-tooltip>
        </mg-user-preference-input-label>
      </mg-user-preference-input>
    </mat-expansion-panel>
  </div>

  <div class="user-preference-input-wrap mg-minicopy">
    <!-- Change password -->
    <ng-container *ngTemplateOutlet="passwordExpansionPanelTemplate">
    </ng-container>

    <!-- Connect -->
    <mat-expansion-panel mgExpansionPanel>
      <mat-expansion-panel-header>
        <mat-panel-title class="mg-headline3">
          <strong>Connect</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="small-user-content-page">
        <p><strong>Connecting your Minga Account</strong></p>
        <p>
          Keep it simple and secure. Login using one of these services so you
          don’t have to use your password every time.
        </p>

        <mg-sso-ui></mg-sso-ui>

        <a
          href="javascript:;"
          *ngIf="ssoInfo$ | async"
          (click)="unlinkAccounts()"
          >Unlink all your accounts</a
        >
      </div>
    </mat-expansion-panel>

    <!-- Delete account -->
    <ng-container
      *ngIf="canUserDeleteAccount$ | async"
      [ngTemplateOutlet]="deleteAccountPanelTemplate">
    </ng-container>
  </div>
</div>

<mg-default-parent-group-picker *ngIf="districtFeatureEnabled$ | async">
</mg-default-parent-group-picker>

<!-- Password expansion panel template -->
<ng-template #passwordExpansionPanelTemplate>
  <mat-expansion-panel mgExpansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title class="mg-headline3">
        <strong>Change password</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- Old password -->
    <mg-password
      #password="ngModel"
      label="Old password"
      [(ngModel)]="oldPasswordField"
      [editMode]="false"
      [required]="true"
      [name]="'oldPersonFormPassword'">
    </mg-password>

    <!-- New password -->
    <mg-password
      #password="ngModel"
      label="New password"
      [(ngModel)]="newPasswordField"
      [editMode]="false"
      [required]="true"
      [name]="'newPersonFormPassword'">
    </mg-password>

    <div
      fxLayout="row"
      fxLayoutAlign="end center">
      <mg-btn
        variant="filled"
        (pressed)="updatePassword()">
        Save
      </mg-btn>
    </div>
  </mat-expansion-panel>
</ng-template>

<!-- Delete account panel template-->
<ng-template #deleteAccountPanelTemplate>
  <mat-expansion-panel mgExpansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title class="mg-headline3">
        <strong>Delete account</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mg-btn (pressed)="launchArchiveAccountConfDialog()">
      I want to delete this account
    </mg-btn>
  </mat-expansion-panel>
</ng-template>
