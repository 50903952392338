import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { MgFileExtensionBoxModule } from '../../MgFileExtensionBox';

import { MgFileMiniCardElement } from './MgFileMiniCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgFileExtensionBoxModule,
    MgPipesModule,
    MgEllipsisModule,
    MgContentLinkModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgFileMiniCardElement],
  exports: [MgFileMiniCardElement],
})
export class MgFileMiniCardModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-file-mini-card')) {
      //       //   MgFileMiniCardElement,
      //   {injector},
      // ));
    }
  }
}
