<button
  #button
  class="item"
  matRipple
  matRippleColor="rgba(0, 0, 0, .025)"
  [attr.data-analytics]="id"
  [attr.data-test]="id"
  [matRippleDisabled]="disabled || loading">
  <!-- Main Text -->
  <div fxFlex="auto">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="badgeLabel"
    fxFlex="nogrow">
    <mg-tag
      type="button"
      [color]="badgeColor"
      [size]="tagSize">
      {{ badgeLabel }}
    </mg-tag>
  </div>
</button>
