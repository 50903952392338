import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';

export interface IMgpersonMinimalElementProperties {
  person: PersonViewMinimal.AsObject;
}

@Component({
  selector: 'mg-person-minimal',
  templateUrl: './MgPersonMinimal.element.html',
  styleUrls: ['./MgPersonMinimal.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgPersonMinimalElement {
  @Input()
  person: PersonViewMinimal.AsObject;

  roleImageIcon(personView: PersonViewMinimal.AsObject) {
    if (!personView) {
      return {};
    }

    const style: any = {};
    if (personView.badgeIconUrl) {
      const badgeIconUrl = `url('${personView.badgeIconUrl}')`;
      style['-webkit-mask-box-image-source'] = badgeIconUrl;
      style['mask-image'] = badgeIconUrl;
      style['-webkit-mask-image'] = badgeIconUrl;
    }

    if (personView.badgeIconColor) {
      style['background-color'] = personView.badgeIconColor;
    } else if (personView.badgeIconUrl) {
      // from cardVars.scss
      style['background-color'] = '#33CCFF';
    }

    return style;
  }

  get displayName(): string {
    if (this.person.displayName) {
      return this.person.displayName;
    } else {
      return this.person.firstName + ' ' + this.person.lastName;
    }
  }
}
