import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label, ThemeService } from 'ng2-charts';

import {
  BAR_CHART_OPTIONS,
  ManagerReportChartMessages,
} from './manager-report-chart.constants';

@Component({
  selector: 'mg-manager-report-chart',
  templateUrl: './manager-report-chart.component.html',
  styleUrls: ['./manager-report-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagerReportChartComponent implements OnInit {
  /** Element Refs */
  @ViewChild(BaseChartDirective)
  chartCanvas: BaseChartDirective | undefined;
  /** Enums & Constants */
  MESSAGES: typeof ManagerReportChartMessages = ManagerReportChartMessages;
  /** General Variables */
  chart = {
    type: 'bar' as ChartType,
    legend: true,
    plugins: [],
  };

  @Input()
  chartOptions: ChartOptions = BAR_CHART_OPTIONS;

  @Input()
  xLabels: Label[];

  @Input()
  dataSets: ChartDataset[];

  @Input()
  noStats: boolean;

  constructor(
    private _cdr: ChangeDetectorRef,
    private themeService: ThemeService,
  ) {}

  ngOnInit(): void {}
}
