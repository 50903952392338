<mg-legacy-overlay-nav [component]="this"></mg-legacy-overlay-nav>

<mg-long-card-base
  [component]="this"
  class="view-likes-inherit-color">
  <div class="title">
    <div class="birthdate-top">
      {{ birthdateTopLine(content.birthdate) }}
    </div>
    <div class="birthdate-btm">
      <div class="birthdate-num">
        {{ birthdateBtmNum(content.birthdate) }}
        <span class="birthdate-num-suffix">
          {{ birthdateBtmNumSuffix(content.birthdate) }}
        </span>
      </div>
    </div>
  </div>

  <div
    class="insp-image-wrap"
    [class.has-gradient-overlap]="content.gradientOverlap">
    <div class="insp-image"></div>

    <div *ngIf="content.gradientOverlap">
      <div
        class="banner-gradient banner-back-gradient"
        [ngStyle]="{ background: bannerGradientStyle() }"></div>

      <div
        class="banner-gradient"
        [ngStyle]="{ background: bannerGradientStyle() }"></div>
    </div>
  </div>

  <div
    class="birthday-people content-block"
    (click)="openMemberListOverlay()">
    <div class="birthday-avatars">
      <ng-container *ngFor="let person of displayBirthdayList()">
        <mg-avatar
          [src]="person.profileImageUrl"
          [color]="person.badgeIconColor"
          [personHash]="person.personHash"></mg-avatar>
      </ng-container>
    </div>

    <div class="birthday-names">
      {{ birthdayPersonNames() }}
    </div>
  </div>
</mg-long-card-base>
