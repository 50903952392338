import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const MG_BODY_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BodyInputComponent),
  multi: true,
};

@Component({
  providers: [MG_BODY_INPUT_VALUE_ACCESSOR],
  selector: 'mg-body-input',
  templateUrl: './BodyInput.component.html',
  styleUrls: ['./BodyInput.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyInputComponent implements ControlValueAccessor {
  private _onChange: any;
  private _onTouched: any;
  value: string = '';

  constructor(private _cdr: ChangeDetectorRef) {}

  @Input()
  customToolbar: boolean = false;

  @Input()
  customElement: Record<string, any>;

  @Input()
  richContent: boolean = false;

  @Input()
  placeholder: string = '';

  @Input()
  maxlength?: number;

  @Input()
  required: boolean = false;

  @Input()
  float: string = 'auto';

  @Input()
  minHeight: string = 'auto';

  @Input()
  align?: string;

  @Input()
  requiredMessage: string | undefined = undefined;

  @Input()
  showRemainingCountTilMaxLength: boolean = false;

  @Input()
  resetParentScrollbarElement: string;

  triggerOnChange(value: string) {
    if (this._onChange) {
      this._onChange(value);
    }
  }

  triggerOnTouched() {
    if (this._onTouched) {
      this._onTouched();
    }
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  writeValue(value: any) {
    if (value === null) {
      this.value = '';
    } else if (typeof value === 'string') {
      this.value = value;
    } else {
      console.warn(
        `<mg-body-input> Invalid writeValue type (${typeof value}):`,
        value,
      );
    }

    this._cdr.markForCheck();
  }

  onValueChange(value: string) {
    this.triggerOnChange(value);
    this.triggerOnTouched();
  }
}
