import * as day from 'dayjs';

export enum FtmActivitiesMessages {
  PAGE_TITLE = 'Activities',
  COLUMN_LABEL_ASSET = '',
  COLUMN_LABEL_TITLE = 'Title',
  COLUMN_LABEL_TEACHER = 'Teacher',
  COLUMN_LABEL_PERIOD = 'Period',
  COLUMN_LABEL_DATE = 'Date',
  COLUMN_LABEL_TIME = 'Time',
  COLUMN_LABEL_START_TIME = 'Start Time',
  COLUMN_LABEL_END_TIME = 'End Time',
  COLUMN_LABEL_STUDENTS = 'Manage Students',
  COLUMN_LABEL_CHECKIN = 'Check In',
  COLUMN_LABEL_REMOVE = 'Remove',
  COLUMN_LABEL_LOCATION = 'Location',

  FIELD_LABEL_SEARCH = 'Search by title',
  FIELD_LABEL_FLEX_PERIOD = 'Flex period',
  FIELD_LABEL_FLEX_TEACHER = 'Teacher',

  ADD_ACTIVITY_SUCCESS = 'Successfully added activity to FlexTime period',

  BUTTON_LABEL_CHECKIN = 'Check in',
  BUTTON_ADD_ACTIVITY = 'Add activity',

  NO_ACTIVITIES_TITLE = 'No activities found',
  NO_ACTIVITIES_SUBTITLE = 'There were no activities for this period or your search did not match any results.',
  NO_PERIODS_TITLE = 'No periods found',
  NO_PERIODS_SUBTITLE = 'There are no periods for today or the date range selected',

  HIDE_FILTERS_LABEL = 'Hide filters',
  SHOW_FILTERS_LABEL = 'Show filters',

  BUTTON_ALL_ACTIVITIES = 'All activities',
  BUTTON_MY_ACTIVITIES = 'My activities',
}

export const FTM_ACTIVITIES_DEFAULT_FILTER = {
  people: [],
};

export const FTM_ACTIVITIES_DATE_FILTER = {
  startDate: day(),
  endDate: day().add(1, 'month'),
};

export const FTM_ACTIVITIES_DISPLAY_COLUMNS = [
  'asset',
  'flexTimeActivity.name',
  'flexTimeActivity.createdBy',
  'flexTimeActivity.location',
  'registeredCount',
  'checkInAction',
  'remove',
];
