import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DialogViewComponent, DialogViewModule } from './DialogView';

export const routes: Routes = [
  {
    path: 'broadcast',
    component: DialogViewComponent,
  },
  {
    path: 'feedback',
    component: DialogViewComponent,
  },
];

@NgModule({
  imports: [
    // Minga dependencies
    DialogViewModule,

    // External dependencies
    RouterModule.forChild(routes),
  ],
})
export class DialogViewOverlayRouteModule {}
