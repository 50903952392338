import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'mg-snackbar-icon',
  templateUrl: 'MingaSnackIcon.component.html',
})
export class MingaSnackIconComponent implements OnDestroy {
  snackBarEl: any;
  snackBarRef: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.initClickListener();
  }

  initClickListener() {
    const iconSnackBarEls = document.getElementsByClassName('mg-snackbar');
    const alertSnackEls = document.getElementsByClassName('mg-snackbar-alert');
    const snackBarEls = iconSnackBarEls.length
      ? iconSnackBarEls
      : alertSnackEls;

    if (snackBarEls && snackBarEls.length) {
      this.snackBarEl = snackBarEls[0];

      this.snackBarEl.addEventListener('click', this.onClick.bind(this));
    }
  }

  onClick(e: any) {
    if (this.snackBarRef) {
      this.snackBarRef.dismissWithAction();
    } else {
      console.error(
        `[MingaSnackIconComponent] unable to close as has no reference to self`,
      );
    }
  }

  ngOnDestroy() {
    if (this.snackBarEl) {
      this.snackBarEl.removeEventListener('click', this.onClick);
    }
  }
}
