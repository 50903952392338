export enum PmRewardsMessages {
  COLUMN_LABEL_ASSET = '',
  COLUMN_LABEL_NAME = 'Name',
  COLUMN_LABEL_MEMBERS = 'Members',
  COLUMN_LABEL_STATUS = 'Active',
  COLUMN_LABEL_EDIT = 'Edit',

  BUTTON_LABEL_NEW_REWARD = 'Create reward',

  EMPTY_STATE_TITLE = 'No results found',
  EMPTY_STATE_SUBTITLE = "You haven't created any rewards yet",

  SNACKBAR_SUCCESS_CREATE = 'Successfully created new reward',
  SNACKBAR_SUCCESS_SUBMIT = 'Successfully saved reward details',
  SNACKBAR_SUCCESS_DELETE = 'Successfully deleted rewarded',
}

export const PM_REWARDS_TABLE_COLUMNS: string[] = [
  'imagePath',
  'name',
  'edit',
  'active',
];
