import { getFileExtension } from 'minga/util/File';

export function renameFile(file: File, newName: string): File {
  const currentExtension = getFileExtension(newName);
  const extension = getFileExtension(file.name);

  if (!currentExtension) {
    newName = newName + '.' + extension;
  }
  // as the file name is read only, create a new file with new name
  const blob: any = file.slice(0, file.size, file.type);
  if (window.MINGA_DEVICE_IOS) {
    blob.name = newName;
    blob.lastModifiedDate = new Date();
    // @HACK: not the best solution, but as safari on mobile doesn't support
    // FormData and the File API is different than docs, this is the current
    // solution
    return <File>blob;
  }
  // creating new file from blob, as the FILE API is different for chrome
  // I'm using formdata...
  const fd = new FormData();
  fd.set('newFile', blob, newName);
  // make file refer to the new file with the new name
  return <File>fd.get('newFile');
}
