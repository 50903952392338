import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MgIconModule } from 'minga/app/src/app/icon';
import { ScrollTargetDirectiveModule } from 'minga/app/src/app/misc/ScrollTarget/directive';

import { MgOverlayComponent } from './MgOverlay.component';
import { MgOverlayRouterOutletDirective } from './MgOverlayRouterOutlet.directive';
import { MgOverlayStructureModule } from './directives/MgOverlayStructure';
import { MgOverlayStructureViewModule } from './directives/MgOverlayStructureView';

/**
 * @deprecated in favour of `OverlayModule`
 */
@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgOverlayStructureModule,
    MgOverlayStructureViewModule,
    ScrollTargetDirectiveModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [MgOverlayComponent, MgOverlayRouterOutletDirective],
  exports: [MgOverlayComponent, MgOverlayRouterOutletDirective],
})
export class MgOverlayModule {}
