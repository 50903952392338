<!-- IF CHANGED, UPDATE FTUEKEY TO MATCH APP VERSION -->
<ng-container *ngIf="screenIndex == 0">
  <div class="header">
    <mg-icon
      class="title"
      (click)="done.emit()"
      >close</mg-icon
    >
    <p class="title mg-headline3">What's New</p>
    <div class="divider"></div>
    <div class="step-image pbisTracking"></div>
    <p class="title mg-headline3">PBIS TRACKING</p>
    <p class="subtitle mg-smallcopy">
      PBIS Tracking is finally here! You can now send a Praise or Guidance to
      students, create your own custom Behaviors you want to track and view the
      reports!
    </p>
  </div>
</ng-container>

<ng-container *ngIf="screenIndex == 1">
  <div class="header">
    <mg-icon
      class="title"
      (click)="done.emit()"
      >close</mg-icon
    >
    <p class="title mg-headline3">What's New</p>
    <div class="divider"></div>
    <div class="step-image trackBehavior"></div>
    <p class="title mg-headline3">TRACK A BEHAVIOR</p>
    <p class="subtitle mg-smallcopy">
      Send a Praise or Guidance to a Student through our easy-to-use system. Get
      started through the Teacher Tools App.
    </p>
  </div>
</ng-container>

<ng-container *ngIf="screenIndex == 2">
  <div class="header">
    <mg-icon
      class="title"
      (click)="done.emit()"
      >close</mg-icon
    >
    <p class="title mg-headline3">What's New</p>
    <div class="divider"></div>
    <div class="step-image createBehavior"></div>
    <p class="title mg-headline3">CREATE NEW BEHAVIORS</p>
    <p class="subtitle mg-smallcopy">
      You can create a new Behavior in the PBIS tracking dashboard or use our
      pre-populated ones.
    </p>
  </div>
</ng-container>

<ng-container *ngIf="screenIndex == 3">
  <div class="header">
    <mg-icon
      class="title"
      (click)="done.emit()"
      >close</mg-icon
    >
    <p class="title mg-headline3">What's New</p>
    <div class="divider"></div>
    <div class="step-image viewData"></div>
    <p class="title mg-headline3">VIEW THE DATA</p>
    <p class="subtitle mg-smallcopy">
      You can review the Behavior data in the summary tab. Check out the top
      staff giving out Behaviors, top students and more!
    </p>
  </div>
</ng-container>

<ng-container *ngIf="screenIndex == 4">
  <div class="header">
    <mg-icon
      class="title"
      (click)="done.emit()"
      >close</mg-icon
    >
    <p class="title mg-headline3">What's New</p>
    <div class="divider"></div>
    <div class="step-image receivePraise"></div>
    <p class="title mg-headline3">RECEIVE A PRAISE OR GUIDANCE</p>
    <p class="subtitle mg-smallcopy">
      Your school can now send you a digital Praise or Guidance. You'll receive
      a notification in the app.
    </p>
  </div>
</ng-container>

<div class="footer">
  <button
    mgBubbleBtn
    class="mg-headline"
    (click)="next()">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="stepCount - 1">Done</ng-container>
      <ng-container *ngSwitchDefault>Next</ng-container>
    </ng-container>
  </button>

  <div class="mg-centered">
    <mg-step-indicator
      [allowStepClickNavigation]="true"
      [(activeStep)]="step"
      [stepCount]="stepCount"></mg-step-indicator>
  </div>
</div>
