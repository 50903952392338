import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { EventContentService } from 'minga/app/src/app/minimal/services/EventContent';
import { mgResolveImageUrls } from 'minga/app/src/app/util/asset';
import {
  displayEventDates,
  displayEventDatesLine1,
  displayEventDatesLine2,
  displayEventDatesSingleLine,
  isEventHappening,
  isEventOver,
} from 'minga/app/src/app/util/eventDates';
import { ShortEventCardView } from 'minga/proto/gateway/content_views_pb';

@Component({
  selector: 'mg-event-mini-card',
  templateUrl: './MgEventMiniCard.element.html',
  styleUrls: ['./MgEventMiniCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgEventMiniCardElement {
  @Input()
  content?: ShortEventCardView.AsObject;

  @Input()
  pinned: boolean = false;

  @Input()
  markKeywords: string = '';

  @Input()
  context: string = '';

  @Input()
  going: boolean = false;

  @Input()
  checkedIn: boolean = false;

  @Input()
  happening: boolean = false;

  @Input()
  showExactDate: boolean = false;

  eventscontentservice?: any /*EventContentService*/;

  readonly bannerImageSizes: ReadonlyArray<string | string[]> = [
    'blurloading1',
    ['cardbanner', 'banner'],
  ];

  trackingEnabled: boolean = false;

  canCheckIn: boolean = true;

  constructor(private eventContentService: EventContentService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  displayEventDatesSingleLine() {
    const startTimestamp = this.content.startTimestamp;
    const endTimestamp = this.content.endTimestamp;

    if (this.showExactDate) {
      if (this.content.allDay) {
        return (
          displayEventDatesLine1(startTimestamp, startTimestamp) +
          ' ' +
          displayEventDatesLine2(startTimestamp, endTimestamp, false, true)
        );
      } else {
        return displayEventDates(startTimestamp, endTimestamp);
      }
    } else {
      if (this.content.allDay) {
        return 'All Day Event';
      }
      return displayEventDatesSingleLine(
        this.content.startTimestamp,
        !!this.content.allDay
          ? this.content.startTimestamp
          : this.content.endTimestamp,
      );
    }
  }

  isEventOver() {
    return isEventOver(
      this.content.startTimestamp,
      this.content.endTimestamp,
      this.content?.allDay,
    );
  }

  goingCountClick(e) {
    this.eventContentService.emitSeeEventPeopleGoing({
      contextHash: this.context,
      content: this.content,
    });
  }

  get devicePixelRatio() {
    return Math.round(devicePixelRatio);
  }
}
