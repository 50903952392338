import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as reg_pb from 'minga/proto/registration/registration_pb';
import { FlexReportUnregisteredTableData } from 'minga/domain/flexTime';
import { RegistrationTypes } from 'minga/domain/registration';
import { RegistrationManager } from 'minga/proto/registration/registration_ng_grpc_pb';
import { day } from 'minga/shared/day';
import { FlexTimeReportColumns } from 'minga/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { PermissionsService } from 'src/app/permissions';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

import { FtmReportsService } from './ftm-reports.service';

@Injectable()
export class FtmReportsUnregisteredDatasourceService extends ReportDatasourceService<FlexReportUnregisteredTableData> {
  constructor(
    _router: Router,
    private _reportService: FtmReportsService,
    private _auth: AuthInfoService,
    private _flexActivityInstanceService: FlexTimeActivityInstanceService,
    private _flexPermissionService: FlexTimePermissionsService,
    private _registrationManager: RegistrationManager,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _permissions: PermissionsService,
  ) {
    super(
      _router,
      _reportService,
      FtmReportsUnregisteredDatasourceService.name,
    );
    super.setDisplayColumns(FlexTimeReportColumns.UNREGISTERED, []);
  }

  async fetch(offset: number, limit: number) {
    return await this._reportService.getUnregisteredReport(
      offset,
      limit,
      this._currentSort,
    );
  }

  async completeItem(item: FlexReportUnregisteredTableData): Promise<void> {
    const startDate = day();
    const endDate = day().add(1, 'month');
    const teacher = !this._flexPermissionService.isFlexTimeAdmin()
      ? this._auth.authPerson
      : null;
    const periodId = this._reportService.filter.periods[0];

    const activityInstance =
      await this._flexActivityInstanceService.openActivitySelector({
        periodId,
        startDate,
        endDate,
        selectorType: 'assign',
        teacher,
      });

    const request = new reg_pb.AssignRequest();
    request.setActivityInstanceId(activityInstance.id);
    request.setPersonHashList([item.hash]);
    request.setType(RegistrationTypes.FLEX_TIME);

    try {
      await this._registrationManager.assign(request);
    } catch (err) {
      this._systemAlertSnackBar.open({
        type: 'error',
        message: 'Error assigning activity: ' + err.message,
      });
    }

    this._reportService.applyFilter();
  }
}
