import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommentSettingsSelectionModule } from 'minga/app/src/app/components/CommentSettingsSelection';
import { MgDateTimePickerModule } from 'minga/app/src/app/date-time-picker';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MultiSelectRoleModule } from 'minga/app/src/app/roles/components/MultiSelectRole';

import { ContentPublishControlsComponent } from './ContentPublishControls.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgDateTimePickerModule,
    PermissionsModule,
    MgFormFieldModule,
    MultiSelectRoleModule,
    CommentSettingsSelectionModule,

    // External dependencies
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSelectModule,
  ],
  declarations: [ContentPublishControlsComponent],
  exports: [ContentPublishControlsComponent],
})
export class ContentPublishControlsModule {}
