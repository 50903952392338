import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'mg-tools-two-col-layout',
  templateUrl: './tools-two-col-layout.component.html',
  styleUrls: ['./tools-two-col-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsTwoColLayoutComponent {
  @ContentChild('leftCol', { read: TemplateRef })
  leftCol: TemplateRef<any>;
  @ContentChild('rightCol', { read: TemplateRef })
  rightCol: TemplateRef<any>;
  constructor() {}
}
