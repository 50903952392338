export enum EventManagerRoutes {
  ROOT = 'events',
  CALENDAR = 'calendar',
  SCHEDULE = 'schedule',
  QUICK_FILTER = 'schedule/:quickFilter',
}

export enum EventManagerMessages {
  PAGE_HEADER_TITLE = 'Event',
}
