<div
  class="link"
  [ngClass]="{ disabled: disabled }">
  <button
    fxLayout="column"
    fxLayout.lt-md="row"
    fxLayoutAlign="end center"
    fxLayoutAlign.lt-md="start center">
    <div
      fxLayout="column"
      fxLayout.lt-md="row"
      fxLayoutAlign="end center"
      fxLayoutGap="5px">
      <div class="link-img">
        <ng-container *ngIf="icon; else assetTpl">
          <mg-icon class="{{ icon }}">{{ icon }}</mg-icon>
        </ng-container>
        <ng-template #assetTpl>
          <div
            class="link-img-asset"
            [style.backgroundImage]="'url(' + asset + ')'"></div>
        </ng-template>
      </div>
      <mg-text
        class="link-text"
        variant="body-xs-secondary">
        {{ label }}
      </mg-text>
    </div>
  </button>
</div>
