<mg-notification-base
  [read]="notification?.read"
  [viewed]="notification?.viewed"
  [timestamp]="notification?.timestamp">
  <mg-notification-base-image>
    <mg-points-history-icon
      [type]="pointHistoryType"
      [disableBackground]="true"></mg-points-history-icon>
  </mg-notification-base-image>
  <mg-notification-base-title *ngIf="notification?.title as title">
    {{title | mgStripHtml}}
  </mg-notification-base-title>

  <mg-notification-base-body>
    {{notification?.body | mgStripHtml}}
  </mg-notification-base-body>
</mg-notification-base>
