import { Validators } from '@angular/forms';

export enum SaCheckInAssignmentLabels {
  NOTE_LABEL = 'Note (optional)',
}

export const CHECK_IN_MAX_NOTE_LENGTH = 180;

export enum SaCheckInFormFields {
  NOTE = 'note',
}

export const SaCheckInFormGroup = {
  [SaCheckInFormFields.NOTE]: ['', [Validators.maxLength(180)]],
};
