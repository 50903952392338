import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgModel,
} from '@angular/forms';
import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export const MG_NAVSEARCH_COMPONENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NavsearchComponent),
  multi: true,
};

@Component({
  providers: [MG_NAVSEARCH_COMPONENT_VALUE_ACCESSOR],
  selector: 'mg-navsearch',
  templateUrl: './Navsearch.component.html',
  styleUrls: ['./Navsearch.component.scss'],
})
export class NavsearchComponent
  implements OnDestroy, ControlValueAccessor, OnChanges
{
  value: string = '';

  @Input()
  placeholder: string = 'Search';

  @Output()
  onSearch: EventEmitter<string>;

  @Input()
  resetSearch?: Observable<void> = new Observable();

  @ViewChild('searchInput', { static: true })
  searchInput?: NgModel;

  @ViewChild('searchInputElement', { static: true })
  searchInputElement?: ElementRef;

  private earlyEmitValue: string = null;
  private onSearchSubject: Subject<string>;
  private onResetSearchSub?: Subscription;
  private onTouched: () => void;
  private onChange: (value: string) => void;

  constructor(private auth: AuthService) {
    this.onSearch = new EventEmitter();
    this.onSearchSubject = new Subject();

    this.onSearchSubject.pipe(debounceTime(600)).subscribe(value => {
      if (this.earlyEmitValue !== value) {
        this.onSearch.emit(value);
      }

      this.earlyEmitValue = null;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetSearch) {
      if (this.onResetSearchSub) {
        this.onResetSearchSub.unsubscribe();
      }
      if (this.resetSearch) {
        this.onResetSearchSub = this.resetSearch.subscribe(() => {
          this._onResetSearch();
        });
      }
    }
  }

  private earlyEmit(value: string) {
    this.earlyEmitValue = value;
    this.onSearch.emit(value);
    this.onSearchSubject.next(value);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (typeof value === 'string') {
      this.value = value;
    }
  }

  ngOnDestroy() {
    this.onSearchSubject.complete();
    this.onSearchSubject.unsubscribe();
    if (this.onResetSearchSub) {
      this.onResetSearchSub.unsubscribe();
    }
  }

  searchKeypress(e: KeyboardEvent) {
    if (e.which == 13) {
      this.earlyEmit(this.value);
    }
  }

  onValueChange(value) {
    value = value || '';

    if (!value) {
      this.clearSearch();
      return;
    }

    this.onSearchSubject.next(value);

    if (this.onChange) {
      this.onChange(value);
    }

    if (this.onTouched) {
      this.onTouched();
    }
  }

  clearSearch() {
    this.value = '';
    this.earlyEmit('');
    if (this.onChange) {
      this.onChange('');
    }
  }

  private _onResetSearch() {
    this.value = '';
    if (this.onChange) {
      this.onChange('');
    }
    // set the input as pristine
    if (this.searchInput) {
      this.searchInput.control.reset();
    }
  }

  focus() {
    if (this.searchInputElement) {
      this.searchInputElement.nativeElement.focus();
    }
  }
}
