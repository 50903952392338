import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ReplaySubject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { MingaAudio } from '@shared/constants';
import { AudioPlayerService } from '@shared/services/audio-player';
import { MediaService } from '@shared/services/media';

import { PS_DIALOG_AUTOCLOSE_TIMEOUT, PsDialogMessage } from '../../constants';
import { PsDialogData, PsDialogResponse } from '../../types';

/**
 * People Selector Dialog
 *
 * Used to show success or error messages during the submit function
 */
@Component({
  templateUrl: './ps-dialog.component.html',
  styleUrls: ['./ps-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('closeBtn', { read: ElementRef }) closeButtonRef!: ElementRef;

  /** Constants */
  public readonly MSG = PsDialogMessage;

  /** Config */
  private readonly _type = this.data.type;
  private readonly _audioAlerts = this.data.audioAlerts || false;
  private readonly _closeTimeout =
    this.data.closeTimeout || PS_DIALOG_AUTOCLOSE_TIMEOUT;

  /** General Subjects */
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  /** Component Constructor */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PsDialogData,
    public media: MediaService,
    public dialogRef: MatDialogRef<PsDialogComponent, PsDialogResponse>,
    private _audioPlayerService: AudioPlayerService<MingaAudio>,
  ) {
    if (this._audioAlerts) this._addAudio();
  }

  /**
   * Dismiss dialogs/modals via the keyboard or scanner if they are visible.
   * The barcode scanner submits a 'return' at the end of the scanning sequence
   * so it will also dismiss any error or success dialogs/modals.
   *
   * Using 'document:keydown' in the HostListener allows us to capture any keydown
   * events. This allows for super fast dismissal of the dialogs via keyboard and
   * also works regardless of the input having focus or not.
   */
  @HostListener('document:keydown', ['$event'])
  onKeydown(e: KeyboardEvent) {
    if (e.key === 'Enter' || e.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  ngAfterViewInit(): void {
    this._updateModalSizeSub();
    if (this._audioAlerts) this._playAudio();
    if (this._type !== 'error') this._autoCloseSub();

    // Focus primary button (close), if user hits enter we don't want default action to
    // secondary button (override)
    if (this.closeButtonRef) {
      const nativeButton =
        this.closeButtonRef.nativeElement.querySelector('button');
      if (nativeButton) {
        nativeButton.focus();
      }
    }
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
  }

  public close(action: PsDialogResponse['action'] = 'close'): void {
    this.dialogRef.close({
      action,
    });
  }

  private _autoCloseSub() {
    return this.dialogRef
      .afterOpened()
      .pipe(takeUntil(this._destroyedSubj), delay(this._closeTimeout))
      .subscribe(() => this.close());
  }

  private _updateModalSizeSub() {
    return this.media.breakpoint$
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(breakpoint =>
        this.dialogRef.updateSize(
          ...(breakpoint === 'xsmall' ? ['90vw', '75vh'] : ['450px', '']),
        ),
      );
  }

  private _addAudio(): void {
    this._audioPlayerService.addAudio([
      MingaAudio.POSITIVE_PING,
      MingaAudio.NEGATIVE_PING,
    ]);
  }

  private _playAudio(): void {
    switch (this._type) {
      case 'success': {
        this._audioPlayerService.playAudio(MingaAudio.POSITIVE_PING);
        break;
      }
      case 'error': {
        this._audioPlayerService.playAudio(MingaAudio.NEGATIVE_PING);
        break;
      }
      default: {
        break;
      }
    }
  }
}
