import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[mgOverlayHide.split-content-small]',
})
export class MgOverlayHideDirective {
  /** @internal */
  private _hideSplitContentSmall: boolean = false;

  /**
   * Hide content when an overlay has split content and is small. IE the 'split'
   * content is on top of the content
   */
  @Input('mgOverlayHide.split-content-small')
  @HostBinding('class.mg-overlay-split-content-small-hidden')
  set hideSplitContentSmall(value: boolean) {
    this._hideSplitContentSmall = coerceBooleanProperty(value);
  }

  get hideSplitContentSmall() {
    return this._hideSplitContentSmall;
  }
}
