<!-- Main Overlay -->
<mg-overlay-primary
  size="medium"
  [isLoading]="isLoading$ | async"
  [bodyNoPadding]="true"
  [overlayTitle]="
    dialogData.currentInstanceId ? MSG.MODAL_TITLE : MSG.MODAL_TITLE_NEW
  ">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainTemplate>
  <main>
    <mg-ftm-activity-selector-list
      [periods]="periods$ | async"
      [activities]="filteredActivities$ | async"
      [defaultValue]="listDefaultValues"
      [activityCardFields]="CARD_FIELDS"
      [selectedId]="ftaInstanceForm.controls.flexTimeActivityId.value"
      (filterChange)="onFilterChange($event)"
      (activitySelected)="setActive($event)">
      <ng-template #noResults>
        <!-- Empty State -->
        <mg-empty-state
          *ngIf="
            !(filteredActivities$ | async)?.length &&
            (isLoading$ | async) !== true
          "
          actionButtonLabel="Go There"
          [title]="MSG.EMPTY_STATE_TITLE"
          [subtitle]="MSG.EMPTY_STATE_SUBTITLE"
          (handleAction)="goToActivityTemplate()">
        </mg-empty-state>
      </ng-template>
    </mg-ftm-activity-selector-list>
  </main>
</ng-template>

<!-- Footer Content Templat -->
<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        *ngIf="dialogData.currentInstanceId; else cancelBtn"
        variant="destructive"
        (pressed)="remove()">
        {{ MSG.BUTTON_LABEL_REMOVE }}
      </mg-btn>
      <ng-template #cancelBtn>
        <mg-btn
          variant="text"
          (pressed)="cancel()">
          {{ MSG.BUTTON_LABEL_CANCEL }}
        </mg-btn>
      </ng-template>
    </div>
    <div>
      <mg-btn
        variant="filled"
        [disabled]="!ftaInstanceForm.canSubmit"
        (pressed)="submit()">
        {{ MSG.BUTTON_LABEL_ADD }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
