import { Action, createReducer, on } from '@ngrx/store';

import { IAuthResponse } from 'minga/domain/auth';

import {
  addAuthParentGroup,
  authCleared,
  authCustomSsoError,
  authInit,
  authPersonUpdated,
  authSsoDetached,
  authSsoError,
  authSsoLinked,
  authSsoUnlinked,
  authSuccess,
  authTokenUpdated,
  authUpdated,
  removeAuthParentGroup,
  setPreAuthRoute,
  updateDmPersonalPreference,
} from '../actions';
import { AuthStoreState } from '../state';

const initialState: AuthStoreState = {
  authenticated: false,
  authTokenPayload: null,
  authResponse: null,
  detachedSsoProviderInfo: null,
  linkedSsoProviderInfo: null,
  ssoError: null,
  customSsoError: null,
  authPerson: null,
};

const reducerOns: any[] = [
  on(authInit, (state: any) => ({ ...state, authenticated: true })),
  on(authSsoLinked, (state: any, { ssoProviderInfo }) => ({
    ...state,
    linkedSsoProviderInfo: ssoProviderInfo,
    detachedSsoProviderInfo: null,
    ssoError: null,
  })),
  on(authSsoError, (state: any, { ssoError }) => ({
    ...state,
    ssoError,
    detachedSsoProviderInfo: null,
    linkedSsoProviderInfo: null,
  })),
  on(authCustomSsoError, (state: any, { customSsoError }) => ({
    ...state,
    customSsoError,
  })),
  on(authSsoUnlinked, (state: any, {}) => ({
    ...state,
    ssoError: null,
    detachedSsoProviderInfo: null,
    linkedSsoProviderInfo: null,
  })),
  on(authSsoDetached, (state: any, { ssoProviderInfo }) => ({
    ...state,
    detachedSsoProviderInfo: ssoProviderInfo,
    linkedSsoProviderInfo: null,
    ssoError: null,
  })),
  on(authUpdated, (state: any) => ({ ...state, authenticated: true })),
  on(authCleared, (state: any) => ({
    ...state,
    authTokenPayload: null,
    authenticated: false,
  })),
  on(setPreAuthRoute, (state: any, action) => ({
    ...state,
    preAuthUrl: action.preAuthUrl,
  })),
  on(authTokenUpdated, (state: any, { minga }) => ({
    ...state,
    authTokenPayload: { ...minga },
  })),
  on(authSuccess, (state: any, { authResponse }) => ({
    ...state,
    authResponse,
  })),
  on(authPersonUpdated, (state: any, { authPerson }) => ({
    ...state,
    authPerson,
  })),
  on(updateDmPersonalPreference, (state: any, { dmPersonalPreference }) => ({
    ...state,
    authResponse: !state.authResponse
      ? null
      : {
          ...state.authResponse,
          dmPersonalPreference,
        },
  })),
];

const authStoreReducer = createReducer(initialState, ...reducerOns);

export function reducer(state: AuthStoreState | undefined, action: Action) {
  return authStoreReducer(state, action);
}
