import { Pipe, PipeTransform } from '@angular/core';

import { MEMBERSHIP_LISTS_DATA } from 'minga/shared/membership_list/constants';

import { MembershipListTableMessages } from '../constants';
import { MembershipListTableData } from '../types';

@Pipe({ name: 'membershipListDefaultName' })
export class MembershipListDefaultNamePipe implements PipeTransform {
  public readonly MSG = MembershipListTableMessages;

  transform({ listType }: MembershipListTableData): string {
    return (
      MEMBERSHIP_LISTS_DATA[listType].defaultConfig?.name ||
      this.MSG.LIST_NAME_PLACEHOLDER
    );
  }
}
