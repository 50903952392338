import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MgButtonModule } from 'minga/app/src/app/button';
import { MgIconModule } from 'minga/app/src/app/icon';

import { Navicon } from './Navicon.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgIconModule,

    // Common dependencies
    CommonModule,
  ],
  declarations: [Navicon],
  exports: [Navicon],
})
export class NaviconModule {}
