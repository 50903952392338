<div class="long-card-base-content">
  <ng-content></ng-content>
</div>

<div
  *ngIf="!disableActions"
  class="content-block actions"
  [class.has-view-likes]="getLikeCount() > 0 && canViewLikes"
  fxLayout="row"
  fxLayoutAlign="middle center">
  <div
    class="action like"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="middle center"
    [ngClass]="{ liked: isLiked, clicked: likeClickAnimation }">
    <div
      class="action-hit-area"
      (click)="contextHash && like()"></div>
    <mg-icon>like</mg-icon>
    <mg-icon class="like-active">like-active</mg-icon>
    <span>{{ numberize(getLikeCount()) }}</span>
  </div>
  <div
    class="action"
    fxFlex
    fxLayout="row"
    fxLayoutAlign="middle center"
    (click)="contextHash && replyClick()">
    <mg-icon>comments</mg-icon>
    <span>{{ numberize(commentCount || 0) }}</span>
  </div>
</div>
<div
  class="content-block view-likes actions action"
  *ngIf="
    contextHash && !disableActions && content.likeCount > 0 && canViewLikes
  "
  (click)="viewLikes()">
  {{ 'content.viewLikes' | translate }}
</div>

<mg-comment-list-view
  #commentListViewEl
  [comments]="comments"
  [canComment]="canComment$ | async"
  [commentCount]="commentCount"
  [contextHash]="contextHash"
  [disableActions]="disableActions"
  [useFixedCommentInput]="useFixedCommentInput"
  (commentCountUpdate)="onCommentCountUpdate($event)">
</mg-comment-list-view>

<div class="footer-iphonex"></div>
