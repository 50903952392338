import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { AppConfigService } from 'src/app/minimal/services/AppConfig';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { RootService } from 'src/app/minimal/services/RootService';

import { KioskModalMessage } from '@modules/kiosk/constants';
import { LandingRoute } from '@modules/landing';

import {
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { KioskService } from '@shared/services/kiosk/kiosk.service';

@Component({
  selector: 'mg-kiosk-mode-modal',
  templateUrl: './kiosk-mode-modal.component.html',
  styleUrls: ['./kiosk-mode-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskModeModalComponent implements OnInit {
  public readonly MESSAGES = KioskModalMessage;

  private _loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this._loadingSubject.asObservable();

  private _pinSubject = new BehaviorSubject<string>(null);
  public pin$ = this._pinSubject.asObservable();

  public form = this._fb.group({
    email: [''],
    pin: [''],
  });

  constructor(
    public authInfo: AuthInfoService,
    public appConfig: AppConfigService,
    private _fb: FormBuilder,
    private _kioskService: KioskService,
    private _snackBar: SystemAlertSnackBarService,
    private _systemAlertModal: SystemAlertModalService,
    private _rootService: RootService,
    private _modalRef: ModalOverlayRef,
  ) {
    this.form.get('email').disable();
    this.form.get('pin').disable();

    this.pin$.subscribe(pin => {
      this.form.get('pin').setValue(pin);
    });
  }

  async ngOnInit(): Promise<void> {
    await this._fetchPin();

    this.form.get('email').setValue(this.authInfo.authPerson.email);
  }

  public async enterKioskModeConfirm() {
    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.WARNING,
      heading: 'Enter kiosk mode?',
      message:
        'You will be logged out of your current session and logged in as the kiosk user.',
      closeBtn: 'Cancel',
      confirmActionBtn: 'Continue',
    });

    const response = await modalRef.afterClosed().toPromise();

    if (response?.type === SystemAlertCloseEvents.CONFIRM) {
      await this._enterKioskMode();
    }
  }

  public async generateNewPinConfirm() {
    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.WARNING,
      heading: 'Generate new pin?',
      message:
        'The current pin will no longer be valid and you will need to use the new pin to enter kiosk mode.',
      closeBtn: 'Cancel',
      confirmActionBtn: 'Continue',
    });

    const response = await modalRef.afterClosed().toPromise();

    if (response?.type === SystemAlertCloseEvents.CONFIRM) {
      await this._generateNewPin();
    }
  }

  public close() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  public getKioskLoginUrl(): string {
    return `${this.appConfig.getAppOrigin()}/${LandingRoute.ROOT}/${
      LandingRoute.KIOSK_LOGIN
    }`;
  }

  private async _enterKioskMode() {
    try {
      const email = this.authInfo.authPerson.email;
      const pin = this._pinSubject.value;

      await this._rootService.addLoadingPromise(
        this._kioskService.enterKioskMode(email, pin),
      );
    } catch (error) {
      this._snackBar.error('Error entering kiosk mode, please try again');
    }
  }
  private async _generateNewPin() {
    try {
      const pin = await this._rootService.addLoadingPromise(
        this._kioskService.generateNewPin(),
      );
      this._pinSubject.next(pin);
      this._snackBar.success('Successfully generated a new pin');
    } catch (error) {
      this._snackBar.error('Error generating a new pin, please try again');
    }
  }

  private async _fetchPin() {
    try {
      this._loadingSubject.next(true);
      const pin = await this._kioskService.fetchPin();
      this._pinSubject.next(pin);
    } catch (error) {
      this._snackBar.error(
        'There was a problem fetching your pin, please try again.',
      );
    } finally {
      this._loadingSubject.next(false);
    }
  }
}
