import { KioskState } from '../types';

const DEFAULT_KIOSK_STATE: KioskState = {
  selectedCategory: null,
  audioEnabled: true,
  hallPassTableEnabled: true,
  isKioskMode: false,
  availableTypes: null,
};

class KioskStateStorageUtil {
  private readonly _key = 'KIOSK_STATE';

  public get(): KioskState {
    const state = localStorage.getItem(this._key);
    let response = { ...DEFAULT_KIOSK_STATE };
    if (state) {
      const parsed = JSON.parse(state);
      response = { ...response, ...parsed };
    }
    return response;
  }

  public set<TypeData>(newState: Partial<KioskState<TypeData>>) {
    try {
      const savedState = this.get();
      const state = { ...savedState, ...newState };
      localStorage.setItem(this._key, JSON.stringify(state));
    } catch (error) {
      console.error('Error setting kiosk state in local storage', error);
    }
  }

  public clear() {
    try {
      localStorage.removeItem(this._key);
    } catch (error) {
      console.error('Error clearing kiosk state in local storage', error);
    }
  }
}

export const KioskStateStorage = new KioskStateStorageUtil();
