import {
  animate,
  sequence,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const newReasonRow = trigger('newReasonRow', [
  state(
    'initial',
    style({
      boxShadow: '0 0 6px rgb(0 0 0 / 15%)',
    }),
  ),
  state(
    'glow',
    style({
      boxShadow: '0 0 10px 0px #e2ab56',
    }),
  ),
  transition('initial => glow', [animate('500ms ease-in-out')]),
  transition('glow => initial', [animate('500ms ease-in-out')]),
]);
