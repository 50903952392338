import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';

import { PersonAvatarComponent } from './PersonAvatar.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgAvatarModule,

    // External dependencies
    FlexLayoutModule,
    CommonModule,
    RouterModule,
  ],
  declarations: [PersonAvatarComponent],
  exports: [PersonAvatarComponent],
})
export class PersonAvatarModule {}
