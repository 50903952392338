import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';

import { MingaSettingsService } from 'src/app/store/Minga/services';

@Injectable()
export class CanAccessGalleryGuard implements CanActivate {
  constructor(private _settingsService: MingaSettingsService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this._settingsService.isPhotoGalleryModuleEnabled();
  }
}
