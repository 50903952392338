import { Pipe, PipeTransform } from '@angular/core';

import { FlexTimeActivityInstance } from 'minga/domain/flexTime';

@Pipe({ name: 'activityRegistered' })
export class ActivityRegisteredPipe implements PipeTransform {
  constructor() {}

  transform(instance: FlexTimeActivityInstance, showRemaining = false): string {
    const registered = +instance.registered || 0;
    const spaces = +instance.spaces || 0;

    // if no value is set it's assumed to be unlimited
    const spacesAvailable = spaces || 'Unlimited';

    if (showRemaining && spaces) {
      const remaining = spaces - registered;
      return `${remaining} / ${spacesAvailable}`;
    }

    if (showRemaining && !spaces) {
      return spacesAvailable as string;
    }

    return `${registered} / ${spacesAvailable}`;
  }
}
