import { NgModule } from '@angular/core';

import { BrowserOnlyGuard } from './BrowserOnly.guard';
import { HybridOnlyGuard } from './HybridOnly.guard';
import { PreventNavigationGuard } from './PreventNavigationGuard.service';

@NgModule({
  providers: [PreventNavigationGuard, BrowserOnlyGuard, HybridOnlyGuard],
})
export class GuardsModule {}
