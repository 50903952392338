<button
  class="nav-icon"
  matRipple>
  <div class="icon-wrap">
    <div
      *ngIf="showBadge"
      class="notification-badge"
      [ngClass]="type"></div>
    <mg-icon [iconName]="icon"></mg-icon>
  </div>
  <mg-text
    variant="label-xs"
    fontWeight="900"
    element="p"
    >{{ label || '' }}</mg-text
  >
  <mg-text
    class="sub-label"
    variant="label-xs"
    element="p"
    >{{ subLabel || '' }}</mg-text
  >
</button>
