export enum ToolsMessages {
  TEACHER_TOOLS_PAGE_TITLE = 'Teacher Tools',
  STUDENT_TOOLS_PAGE_TITLE = 'Student Tools',

  STICKER_NO_NEW_CONTENT = "You don't have any stickers",
  GROUP_NO_NEW_CONTENT = "You haven't joined any groups",

  // Error Messages
  ERROR_LOADING_STICKERS = 'There was an error loading stickers',

  // Your Points
  EMPTY_POINTS = 'You have not earned any points yet!',

  PAGE_HEADER_TITLE = 'My Tools',
  PAGE_TITLE_DASHBOARD = 'Dashboard',
  PAGE_TITLE_POINTS = 'Points',
  PAGE_TITLE_ONLY_BEHAVIORS = 'Behaviors',
  PAGE_TITLE_FLEX_TIME = 'FlexTime',
  PAGE_TITLE_NOTIFICATIONS = 'Notifications',
  PAGE_TITLE_PREFENCES = 'Preferences',
}
