import {
  EmailListUploadField,
  FirstNameListUploadField,
  LastNameListUploadField,
  NameListUploadField,
  StudentNumberListUploadField,
} from '@shared/components/XlsListUploader';

export enum PsSearchMessages {
  TITLE = 'Search People',
  FILE_TITLE = 'Search people by file',
  LIST_TITLE = 'Search People',
  LABEL_SEARCH = 'Name, ID or Email',
  LABEL_BARCODE_SEARCH = 'Search by barcode',
  LABEL_STUDENT_ID = 'Search by student id',
  LABEL_FILTER = 'Filter your audience',
  LABEL_SHOW = 'Show filters',
  LABEL_HIDE = 'Hide filters',

  BUTTON_SEARCH = 'Apply filters',
  BUTTON_UPLOAD = 'Upload file',
  BUTTON_SCAN = 'Barcode scanner',
  BUTTON_CAMERA = 'Scan with camera',
  BUTTON_UPLOAD_MOBILE = 'Upload',
  BUTTON_SCAN_MOBILE = 'Scanner',
  BUTTON_CAMERA_MOBILE = 'Camera',
}

export interface CameraBarcodeInputEventResponse {
  value: string;
}

export interface BarcodeScannerInputEventResponse {
  value: string;
}

export enum BarcodeInputTypes {
  TEXT_INPUT = 'text_input',
  WEBCAM = 'webcam',
  MOBILE = 'mobile',
}

export const PS_ACCEPTED_FILE_TYPES: string[] = ['.xlsx', '.xls', '.csv'];

export const AUTOSEARCH_DEBOUNCE_TIME = 500;

export const PS_FILE_UPLOAD_FIELDS = [
  new EmailListUploadField(),
  new StudentNumberListUploadField(),
  new NameListUploadField(),
  new FirstNameListUploadField(),
  new LastNameListUploadField(),
] as const;

export const HYBRID_CAMERA_SCAN_SAME_ID_GAP = 10000;
