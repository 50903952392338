import { Injectable } from '@angular/core';

import {
  cordovaAccountGetCredentials,
  cordovaAccountInitWithKey,
  cordovaAccountRemoveAccount,
  cordovaAccountSetCredentials,
  cordovaAccountSetPassword,
  IMobileAccountCredentials,
} from '@lib/cordova/account-manager';

/**
 * Handle credentials with mobile devices os, uses iOS Keychain (@TODO Android
 * Account Manager)
 */
@Injectable({ providedIn: 'root' })
export class MobileAccountManagerService {
  constructor() {
    this._init();
  }

  private async _init() {
    if ((<any>window).MINGA_APP_IOS) {
      await cordovaAccountInitWithKey();
    } else if ((<any>window).MINGA_APP_ANDROID) {
      // @TODO
    }
  }

  async getCredentials(): Promise<IMobileAccountCredentials | null> {
    if (!window.MINGA_APP_IOS) {
      return null;
    }
    return await cordovaAccountGetCredentials();
  }

  async saveCredentials(email: string, password: string) {
    if (!window.MINGA_APP_IOS) {
      return;
    }

    const currentCredentials = await this.getCredentials().catch(err => {
      // catching failed to retrieve account, as need new account saved
      return null;
    });
    if (currentCredentials) {
      if (currentCredentials.email != email) {
        // remove old stored account with the new one
        await cordovaAccountRemoveAccount();
      } else if (currentCredentials.password != password) {
        // just update password, email is the same
        return await this.updatePassword(password);
      }
    }

    // store new credentials
    await this.setCredentials(email, password);
  }

  async setCredentials(email: string, password: string) {
    if (!window.MINGA_APP_IOS) {
      return;
    }
    await cordovaAccountSetCredentials(email, password);
  }

  async updatePassword(password: string) {
    if (!window.MINGA_APP_IOS) {
      return;
    }
    await cordovaAccountSetPassword(password);
  }

  async removeCredentials() {
    if (!window.MINGA_APP_IOS) {
      return;
    }
    return await cordovaAccountRemoveAccount();
  }
}
