<!-- Table -->
<div [ngClass]="classes">
  <mat-table
    matSort
    [class]="!hideHeader ? 'list-table' : null"
    [dataSource]="dataSource"
    [trackBy]="trackById">
    <!-- Image Column -->
    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_IMAGE }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let item"
        [class.no-asset]="!item.assetPath">
        <mg-icon
          *ngIf="checkForAsset(item); else assetTemplate"
          [style.color]="item.color"
          [iconName]="placeholderIcon">
        </mg-icon>
        <ng-template #assetTemplate>
          <ng-container
            *ngIf="assetOverrideTemplate; else normalAssetTemplate"
            [ngTemplateOutlet]="assetOverrideTemplate"
            [ngTemplateOutletContext]="{
              $implicit: item
            }">
          </ng-container>
          <ng-template #normalAssetTemplate>
            <mg-image
              *ngIf="item.assetPath; else imagePlaceholderTemplate"
              class="mobile-asset"
              [srcs]="
                item.assetPath
                  | mgImageUrls
                    : [
                        'blurloading1',
                        ['studio', 'bannerlibpreview', 'cardbanner']
                      ]
              ">
            </mg-image>
          </ng-template>
        </ng-template>
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_NAME }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-text
          [numberOfLines]="2"
          variant="body-md-secondary"
          textAlign="left"
          [title]="
            !useDefaultListName
              ? item?.name || MSG.LIST_NAME_PLACEHOLDER
              : (item | membershipListDefaultName)
          ">
          {{
            !useDefaultListName
              ? item?.name || MSG.LIST_NAME_PLACEHOLDER
              : (item | membershipListDefaultName)
          }}
        </mg-text>
        <mg-tooltip
          *ngIf="
            (config$ | async).showDescription && item
              | membershipListDescription as description
          "
          [label]="description">
        </mg-tooltip>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="deniedPasses">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        mgSortHeader>
        {{ MSG.COLUMN_DENIED_PASSES }}
        <mg-tooltip label="{{ MSG.COLUMN_DENIED_PASSES_TOOLTIP }}"></mg-tooltip>
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-btn
          variant="text"
          size="medium"
          iconSet="minga"
          iconRight="mg-expand-circle-right"
          (pressed)="openDeniedPassesReport(item)">
          {{ item.deniedPasses || 0 }}
        </mg-btn>
      </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell
        *matHeaderCellDef
        [class.can-remove-all]="canRemoveAll">
        {{ MSG.COLUMN_ACTIONS }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let item"
        [class.can-remove-all]="canRemoveAll">
        <ng-container
          [ngTemplateOutlet]="
            item?.memberCount > 0 ? manageButtonTemplate : addButtonTemplate
          "
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>
        <ng-container
          *ngIf="canRemoveAll && item?.memberCount > 0"
          [ngTemplateOutlet]="removeAllButtonTemplate"
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_EDIT }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-btn
          variant="icon"
          iconSet="minga"
          icon="mg-edit"
          (pressed)="editList(item)">
        </mg-btn>
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="active">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        mgSortHeader>
        {{ MSG.COLUMN_STATUS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <mg-form-toggle
          [id]="'membership-list-toggle-' + item.listType"
          [value]="item.active"
          [disabled]="item.listType === LIST_TYPES.NO_ACCESS"
          (valueChange)="changeStatus(item, $event)">
        </mg-form-toggle>
      </mat-cell>
    </ng-container>

    <!-- Mobile Column -->
    <ng-continer matColumnDef="mobile">
      <mat-header-cell
        *matHeaderCellDef
        [class]="'mobile-layout'">
      </mat-header-cell>
      <mat-cell
        *matCellDef="let item"
        [class]="'mobile-layout'">
        <ng-container
          [ngTemplateOutlet]="
            (displayedColumns$ | async).length > 1
              ? mobileCardTemplate
              : mobileRowTemplate
          "
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>
      </mat-cell>
    </ng-continer>

    <!-- Header Row -->
    <ng-container *ngIf="!hideHeader">
      <mat-header-row *matHeaderRowDef="displayedColumns$ | async">
      </mat-header-row>
    </ng-container>

    <!-- Body Rows -->
    <mat-row *matRowDef="let row; columns: displayedColumns$ | async"></mat-row>
  </mat-table>
</div>

<!-- Loading -->
<div
  *ngIf="isLoading$ | async; else emptyStateTemplate"
  class="loading-container">
  <mg-spinner
    [diameter]="25"
    [thickness]="2">
  </mg-spinner>
</div>

<!-- Empty State -->
<ng-template #emptyStateTemplate>
  <mg-empty-state
    *ngIf="!dataSource.data.length"
    [title]="MSG.EMPTY_STATE_TITLE"
    [subtitle]="MSG.EMPTY_STATE_SUBTITLE">
  </mg-empty-state>
</ng-template>

<!-- Pagination -->
<div [fxHide]="dataSource.data.length <= pageSizeOptions[0]">
  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>
</div>

<!-- Add Button Template -->
<ng-template
  #addButtonTemplate
  let-item="item">
  <mg-btn
    variant="outlined"
    size="small"
    [id]="'membership-list-add-' + item.listType"
    (pressed)="openPeopleSelector(item)">
    {{ MSG.BUTTON_LABEL_ADD }}
  </mg-btn>
</ng-template>

<!-- Manage Button Template -->
<ng-template
  #manageButtonTemplate
  let-item="item">
  <mg-btn
    variant="text"
    size="medium"
    iconSet="minga"
    icon="mg-user-list"
    (pressed)="openPeopleSelector(item)">
    {{ item?.memberCount || 0 }}
  </mg-btn>
</ng-template>

<!-- Remove All Button Template -->
<ng-template
  #removeAllButtonTemplate
  let-item="item">
  <mg-btn
    variant="destructive"
    size="small"
    (pressed)="openRemoveAllMembers(item)">
    {{ MSG.BUTTON_LABEL_REMOVE_ALL }}
  </mg-btn>
</ng-template>

<!-- Image Placeholder Template -->
<ng-template #imagePlaceholderTemplate>
  <div class="image-placeholder">
    <mg-icon>studio-image-active</mg-icon>
  </div>
</ng-template>

<!-- Mobile Card Template -->
<ng-template
  #mobileCardTemplate
  let-item="item">
  <mg-mobile-card [title]="item?.name || MSG.LIST_NAME_PLACEHOLDER">
    <div
      *ngIf="(displayedColumns$ | async).includes('deniedPasses')"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div
        fxLayout="row"
        fxLayoutAlign="center center">
        <mg-text
          variant="body-xs"
          color="outline">
          {{ MSG.COLUMN_DENIED_PASSES }}
        </mg-text>
        <mg-tooltip label="{{ MSG.COLUMN_DENIED_PASSES_TOOLTIP }}"></mg-tooltip>
      </div>
      <mg-btn
        variant="text"
        size="medium"
        iconSet="minga"
        iconRight="mg-expand-circle-right"
        (pressed)="openDeniedPassesReport(item)">
        {{ item.deniedPasses || 0 }}
      </mg-btn>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div *ngIf="(displayedColumns$ | async).includes('edit')">
        <mg-btn
          variant="icon"
          iconSet="minga"
          icon="mg-edit"
          (pressed)="editList(item)">
        </mg-btn>
      </div>
      <div>
        <ng-container
          [ngTemplateOutlet]="
            item?.memberCount > 0 ? manageButtonTemplate : addButtonTemplate
          "
          [ngTemplateOutletContext]="{ item: item }">
        </ng-container>
      </div>
      <div *ngIf="(displayedColumns$ | async).includes('active')">
        <mg-form-toggle
          [id]="'membership-list-toggle-' + item.listType"
          [value]="item.active"
          (valueChange)="changeStatus(item, $event)">
        </mg-form-toggle>
      </div>
    </div>
  </mg-mobile-card>
</ng-template>

<!-- Mobile Row Template -->
<ng-template
  #mobileRowTemplate
  let-item="item">
  <div
    class="w-full"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div
      fxLayout="row"
      fxLayoutAlign="left center">
      <mg-text
        class="multiline-ellipsis"
        [variant]="hideHeader ? 'body-md-secondary' : 'body-sm'"
        textAlign="left"
        [title]="
          !useDefaultListName
            ? item?.name || MSG.LIST_NAME_PLACEHOLDER
            : (item | membershipListDefaultName)
        ">
        {{
          !useDefaultListName
            ? item?.name || MSG.LIST_NAME_PLACEHOLDER
            : (item | membershipListDefaultName)
        }}
      </mg-text>
      <mg-tooltip
        *ngIf="
          (config$ | async).showDescription && item
            | membershipListDescription as description
        "
        [label]="description">
      </mg-tooltip>
    </div>
    <div>
      <ng-container
        [ngTemplateOutlet]="
          item?.memberCount > 0 ? manageButtonTemplate : addButtonTemplate
        "
        [ngTemplateOutletContext]="{ item: item }">
      </ng-container>
    </div>
  </div>
</ng-template>
