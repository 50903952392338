import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const INOUT_SHOW_STYLES = {
  'max-height': '100%',
  opacity: 1,
  padding: '6px',
};
export const INOUT_HIDDEN_STYLES = {
  'max-height': '0px',
  opacity: 0,
  padding: 0,
};
// taken from _transitions.scss
export const BEZIER_TRANSITION = '300ms cubic-bezier(0.55, 0, 0.55, 0.2)';

export const inOutAnination: AnimationTriggerMetadata = trigger(
  'inOutAnimation',
  [
    transition(':enter', [
      style(INOUT_HIDDEN_STYLES),
      animate(BEZIER_TRANSITION, style(INOUT_SHOW_STYLES)),
    ]),
    transition(':leave', [
      style(INOUT_SHOW_STYLES),
      animate(BEZIER_TRANSITION, style(INOUT_HIDDEN_STYLES)),
    ]),
  ],
);
