import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import dayjs, * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { share } from 'rxjs/operators';

import { FlexTimePeriod } from 'minga/domain/flexTime';
import { MgValidators } from 'src/app/input/validators';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  FlexTimePeriodService,
  flexCloneData,
} from '@shared/services/flex-time';

import { FtmPeriodsEditFormFields, FtmPeriodsMessage } from '../../constants';
import { FtmPeriodsService } from '../../services';
import {
  FtmPeriodsEditModalData,
  FtmPeriodsEditModalResponse,
} from '../../types';

@Component({
  selector: 'mg-ftm-periods-edit',
  templateUrl: './ftm-periods-edit.component.html',
  styleUrls: ['./ftm-periods-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmPeriodsEditComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly MESSAGES = FtmPeriodsMessage;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.ALT_TEAL,
  };
  public readonly FORM_FIELD = FtmPeriodsEditFormFields;

  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);

  /** Loading */
  private _isLoading = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading.asObservable();
  private _isSubmitting = new BehaviorSubject<boolean>(false);
  public readonly isSubmitting$ = this._isSubmitting.asObservable();

  /** Modal */
  public readonly modalTitle$ = of(
    this.dialogData.id && !this.dialogData.isClone
      ? 'Edit Period'
      : 'New Period',
  );

  /** Is New Period */
  private _isNew =
    this.dialogData.id >= 0 && !this.dialogData.isClone ? false : true;
  public isNew$ = of(this._isNew);
  public currentDate = day();

  // we define this at the component level so the start/end times are evaluated on component mount instead on bundle load (in constant file)
  public FORM_GROUP = {
    [FtmPeriodsEditFormFields.TITLE]: [
      '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(50)],
    ],
    [FtmPeriodsEditFormFields.DATE]: [
      null,
      [Validators.required, MgValidators.DateNotInPastValidator],
    ],
    [FtmPeriodsEditFormFields.START_TIME]: [
      day().add(1, 'h').format('HH:mm'),
      [Validators.required],
    ],
    [FtmPeriodsEditFormFields.END_TIME]: [
      day().add(2, 'h').format('HH:00'),
      [Validators.required],
    ],
    [FtmPeriodsEditFormFields.ACTIVITY_MANAGE]: [true],
    [FtmPeriodsEditFormFields.STUDENT_MANAGE]: [true],
    [FtmPeriodsEditFormFields.STUDENT_SELF_MANAGE]: [false],
    [FtmPeriodsEditFormFields.CLONE_REGISTERED]: [true],
    [FtmPeriodsEditFormFields.CLONE_ASSIGNED]: [true],
  };

  /** Form Controls */
  public readonly form = this._fb.group(this.FORM_GROUP, {
    validators: this._isNew
      ? [
          MgValidators.TimeComparisonValidator('startTime', 'endTime'),
          // lets only validate date/time not in past on new periods
          MgValidators.DateTimeNotInPastValidator(
            this.FORM_FIELD.DATE,
            this.FORM_FIELD.START_TIME,
          ),
        ]
      : [MgValidators.TimeComparisonValidator('startTime', 'endTime')],
  });

  /** Period */
  private readonly _period = new BehaviorSubject<FlexTimePeriod | undefined>(
    undefined,
  );
  public readonly period$ = this._period.asObservable().pipe(share());

  /** Computed Getters */
  get canSubmit(): boolean {
    return this.form.valid && (this.form.dirty || this.dialogData.isClone);
  }

  get minTime(): string {
    return this.form.get(this.FORM_FIELD.DATE) &&
      this.form
        .get(this.FORM_FIELD.DATE)
        .value?.isSameOrBefore(this.currentDate, 'day')
      ? this.currentDate.format('HH:mm')
      : null;
  }

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: FtmPeriodsEditModalData,
    private _modalRef: ModalOverlayRef<
      FtmPeriodsEditModalData,
      FtmPeriodsEditModalResponse
    >,
    private _dialog: MatDialog,
    private _fb: FormBuilder,
    private _flexPeriods: FtmPeriodsService,
    private _ftPeriods: FlexTimePeriodService,
  ) {
    if (!this.dialogData.isClone && !this.dialogData.id) {
      this.form.controls[this.FORM_FIELD.DATE].setValue(day());
    }
  }

  ngOnInit(): void {
    this._initPeriodData(this.dialogData?.id);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._isLoading.complete();
    this._isSubmitting.complete();
  }

  public async submit(): Promise<void> {
    if (!this.canSubmit) return;
    this._isSubmitting.next(true);
    const payload = this._getPayload();
    if (this._isNew) {
      let cloneData: flexCloneData;
      if (this.dialogData.isClone) {
        cloneData = {
          cloneRegistered: this.form.controls.cloneRegistered.value,
          cloneAssigned: this.form.controls.cloneAssigned.value,
        };
      }
      await this._flexPeriods.create(payload, cloneData);

      this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE);
    } else {
      await this._flexPeriods.update(payload);
      this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT);
    }
    this._isSubmitting.next(false);
  }

  public async delete(): Promise<void> {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: this._isNew
            ? `Are you sure you want to discard this new flex time period`
            : `Are you sure you want to delete this flex time period?`,
          deleteBtn: 'Delete',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || !response.confirmed || response.cancelled) return;
      if (!this._isNew) {
        const period = this._period.getValue();
        this._flexPeriods.delete(period.id);
        this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE);
      } else {
        this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
      }
    });
  }

  private async _initPeriodData(id?: number): Promise<void> {
    if (!id) return;
    try {
      this._isLoading.next(true);
      const result = await this._ftPeriods.fetch(id);
      if (this.dialogData.isClone) {
        result.title += ' Clone';
      }
      this._period.next({ ...result });
      this._setFormValues(result);
    } catch (error) {
      this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
    } finally {
      if (this.dialogData.isClone) this.form.markAsDirty();
      this._isLoading.next(false);
    }
  }

  private _setFormValues(period: FlexTimePeriod) {
    for (const key in period) {
      if (period.hasOwnProperty(key) && this.form.controls[key]) {
        if (key === 'date') {
          period[key] = day(period[key]) as any;
        }

        this.form.get(key).setValue(period[key]);
      }
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  private _getPayload(): FlexTimePeriod {
    const payload: FlexTimePeriod = { ...this._period.getValue() };
    for (const field in this.form.controls) {
      if (this.form.controls.hasOwnProperty(field)) {
        if (field === 'date') {
          const dateValue = this.form.controls[field].value;

          payload[field] = dayjs(dateValue).startOf('day').toDate();
        } else {
          payload[field] = this.form.controls[field].value;
        }
      }
    }
    return payload;
  }
}
