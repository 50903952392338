<div class="date-select-row">
  <mg-calendar-active-date-range
    [calendarView]="calendarView"
    (activeDateChange)="onActiveDateChange($event)"
    [date-change-observable]="dateChangeSubject.asObservable()"
    [excludeWeekend]="excludeWeekend">
  </mg-calendar-active-date-range>
  <mg-button
    type="outline"
    (click)="activeDateNavToday()"
    class="today-button">
    Go To Today
  </mg-button>
</div>

<div [gdColumns]="'repeat(' + _viewItems[0].length + ', 1fr)'">
  <div
    *ngFor="let viewItem of _viewItems[0]"
    class="weekday-text">
    {{ getWeekdayDisplay(viewItem) }}
  </div>
</div>
<div
  *ngFor="let viewItems of _viewItems"
  class="calendar-row"
  [gdColumns]="'repeat(' + _viewItems[0].length + ', minmax(0, 1fr))'">
  <div
    *ngFor="let viewItem of viewItems"
    class="calendar-cell"
    [class.week-view]="calendarView == 'week'"
    [class.active-calendar-cell]="isNow(viewItem)"
    [class.not-within-month-calendar-cell]="
      !isWithinMonth(viewItem) && calendarView != 'week'
    ">
    <div class="date-number-wrap">
      <span
        [routerLink]="['/events', 'schedule']"
        [queryParams]="{
          start: viewItem.year + '-' + viewItem.month + '-' + viewItem.day
        }"
        class="date-number"
        >{{ viewItem.day }}</span
      >
    </div>
    <div
      *ngFor="let event of viewItem.events; trackBy: _eventTrackBy"
      class="calendar-event-item"
      [class.calendar-event-item-clickable]="
        clickCalendarEventItem.observers.length > 0
      "
      [style.backgroundColor]="event.color"
      (tap)="clickCalendarEventItem.emit(event)">
      <h1
        *ngIf="event.title"
        [innerHTML]="event.title | mgSafeHtml"></h1>
      <p *ngIf="event.description">{{ event.description }}</p>
      <p
        *ngIf="getEventDisplayTime(event.date) as displayTime"
        class="event-time-text">
        {{ displayTime }}
      </p>
    </div>
  </div>
</div>
