import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';

import { TEXT_INPUT_CLEARABLE_TYPES, FormErrorMessages } from '../../constants';
import {
  FormAppearanceVariant,
  FormInputMode,
  FormInputTypes,
  FormSizes,
  LabelBackground,
} from '../../types';

@Component({
  selector: 'mg-form-text-input',
  templateUrl: './form-text-input.component.html',
  styleUrls: ['./form-text-input.component.scss'],
})
export class FormTextInputComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  /** Child Components */
  @ViewChild('inputElement') inputElement: ElementRef;
  @ViewChild('textareaElement') textareaElement: ElementRef;
  @ViewChild('prefixElement') prefixElement: ElementRef;
  @ViewChild('labelElement') labelElement: ElementRef;

  /** Constants */
  public readonly FORM_ERROR_MESSAGES = FormErrorMessages;

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Form Group */
  public formGroup: FormGroup;

  /** Form Control */
  public formControl: FormControl = new FormControl();
  public formControlName: string;
  statusChange$: Observable<any>;

  private readonly _formControlValueSubj = new BehaviorSubject<any>(null);
  public readonly formControlValue$ = this._formControlValueSubj.asObservable();

  /** UI Related */
  private readonly _inputIsFocused$ = new BehaviorSubject<boolean>(false);
  public readonly inputIsFocused$ = this._inputIsFocused$.asObservable();
  protected readonly isIOS: boolean = window.MINGA_APP_IOS;
  public initialWidth = 0;

  /** Textarea preview mode */
  private readonly _previewModeSubject = new BehaviorSubject<boolean>(false);
  public readonly previewMode$ = this._previewModeSubject.asObservable();

  private readonly _showPasswordSubject = new BehaviorSubject<boolean>(null);
  public readonly showPassword$ = this._showPasswordSubject.asObservable();

  /** Inputs */
  @Input() label: string;
  @Input() labelBackground: LabelBackground = 'white';
  @Input() widthSize: FormSizes = 'large';
  @Input() isClearable?: boolean;
  @Input() iconLeft?: string;
  @Input() iconRight?: string;
  @Input() autofocus?: boolean;
  @Input() appearance?: FormAppearanceVariant = 'primary';
  @Input() placeholder?: string = '';
  @Input() step: number;
  @Input() inputType: FormInputTypes = 'text';
  @Input() isDisabled: boolean;
  @Input() readonly: boolean;
  @Input() hint: string;
  @Input() list?: string;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() min: string;
  @Input() max: string;

  @Input()
  inputMode: FormInputMode;
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  @Input()
  autoComplete: string;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;
  @Input() maxlength: number;
  @Input()
  set control(formControl: FormControl | AbstractControl) {
    if (!formControl) return;
    this.formControl = formControl as FormControl;
    this.statusChange$ = this.formControl.statusChanges;
  }
  @Input()
  set formGroupRef(ref: FormGroup | AbstractControl) {
    if (!ref) return;
    this.formGroup = ref as FormGroup;
    this.statusChange$ = ref.statusChanges;
  }
  @Input() templateVariables?: Record<string, string | number>;
  @Input() condensed: boolean;
  @Input() externalError: boolean;

  /** Set Focus Event */
  @Input() setFocus: Observable<any>;
  private _setFocusEventSub;

  private readonly _textAreaHeight = new BehaviorSubject<string>('auto');
  public readonly textAreaHeight$ = this._textAreaHeight.asObservable();

  /** Outputs */
  @Output() returnPressed = new EventEmitter<void>();
  @Output() iconRightClicked = new EventEmitter<void>();

  /** Computed Getters */
  get isValid() {
    return (
      this.formControl.valid &&
      this.formControl.dirty &&
      this.formControl.touched
    );
  }
  get hasValue() {
    if (typeof this.formControl.value === 'number' || this.inputType === 'time')
      return true;
    return !!this.formControl.value;
  }
  get inputDisabled() {
    return (this.formControl.disabled || this.isDisabled) && !this.readonly;
  }

  get formClasses(): Record<string, boolean> {
    return {
      [this.widthSize]: true,
      [this.inputType]: true,
      [this.labelBackground]: true,
    };
  }

  get showClearButton() {
    const isClearableType = TEXT_INPUT_CLEARABLE_TYPES.includes(this.inputType);
    return (
      this.isClearable && this.hasValue && isClearableType && !this.iconRight
    );
  }

  get formStyleClasses(): Record<string, boolean> {
    return this.inputType === 'number' && this.inputElement?.nativeElement
      ? {
          ['width.ch']: this.inputElement.nativeElement.value.length + 4,
        }
      : {};
  }

  get hasError(): boolean {
    return (
      this.externalError ||
      (this.formControl.invalid && this.formControl.touched)
    );
  }

  /** Component Constructor */
  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._setFocusEventSub = this.setFocus?.subscribe(() => this._focusInput());
    this._focusTextAreaAfterPreviewClosedSubscription();
    this._setNumberInputValidations();
    if (this.inputType === 'time' && !this.iconRight && !this.iconLeft) {
      this.iconRight = 'mg-time-hollow';
      this.iconRightClicked.pipe(takeUntil(this._destroyed$)).subscribe(e => {
        this.inputElement.nativeElement.showPicker();
      });
    }

    if (this.inputType === 'password') {
      this._showPasswordSubject.next(false);
    }
  }

  ngAfterViewInit(): void {
    if (this.autofocus) this._focusInput();
    this._cdr.detectChanges();

    const prefixWidth = this.prefixElement?.nativeElement.offsetWidth;

    if (prefixWidth && this.labelElement?.nativeElement)
      this.labelElement.nativeElement.style.left = `${prefixWidth}px`;

    if (this.inputType === 'textarea') {
      this.adjustTextareaHeight();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._inputIsFocused$.complete();
    this._destroyed$.complete();
    this._setFocusEventSub?.unsubscribe();
    this._previewModeSubject.complete();
  }

  public async inputGainedFocus(): Promise<void> {
    this._inputIsFocused$.next(true);
  }

  public async inputLostFocus(): Promise<void> {
    this._inputIsFocused$.next(false);
  }

  public adjustTextareaHeight() {
    const newHeight =
      10 + this.textareaElement.nativeElement.scrollHeight + 'px';
    this.textareaElement.nativeElement.style.height = '1px';
    this.textareaElement.nativeElement.style.height = newHeight;
    this._textAreaHeight.next(newHeight);
  }

  public clearInput() {
    this.formControl.setValue('');
  }

  public preventScroll(event: WheelEvent) {
    event.preventDefault();
  }

  public togglePreviewMode() {
    const currentValue = this._previewModeSubject.getValue();
    this._previewModeSubject.next(!currentValue);
  }

  public addTemplateVariable(key: string) {
    const currentValue = this.formControl.value;
    this.formControl.setValue(`${currentValue} \${${key}}`);

    const nativeEl = this.textareaElement?.nativeElement;

    if (nativeEl) {
      setTimeout(() => {
        nativeEl?.focus();
        nativeEl.selectionStart = nativeEl.value.length;
      }, 100);
    }
  }

  private _focusTextAreaAfterPreviewClosedSubscription() {
    return this.previewMode$
      .pipe(
        takeUntil(this._destroyed$),
        skip(1),
        filter(v => v !== true),
      )
      .subscribe(() => {
        setTimeout(() => {
          this.inputElement?.nativeElement?.focus();
        }, 100);
      });
  }

  private _focusInput() {
    if (!this.inputElement) return;
    // magic fix for auto-focusing, change detection doesn't work without this
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    });
  }

  private _setNumberInputValidations() {
    if (this.inputType !== 'number' || !this.formControl) return;

    const existingValidators = this.formControl.validator
      ? [this.formControl.validator]
      : [];
    const validators: ValidatorFn[] = [...existingValidators];

    if (this.min) validators.push(Validators.min(+this.min));
    if (this.max) validators.push(Validators.max(+this.max));
    this.formControl.setValidators(validators);
    this.formControl.updateValueAndValidity();
  }

  public toggleVisibility() {
    const currentValue = this._showPasswordSubject.getValue();
    const updated = !currentValue;
    this._showPasswordSubject.next(!currentValue);
    this.inputType = updated ? 'text' : 'password';
  }
}
