<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="isLoading$ | async"
  [overlayTitle]="modalTitle$ | async">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <!-- Image -->
    <div
      fxLayout="row"
      fxLayoutAlign="center center">
      <div class="asset-container">
        <mg-file-input-upload
          #fileInputUpload
          accept="image/*"
          customTitleText="Upload A Photo"
          previewAspectRatio="1:1"
          name="imagePath"
          [ngModel]="(rewardType$ | async).imagePath"
          [showFileDetails]="false"
          [radius]="true"
          (ngModelChange)="changeImage($event)">
        </mg-file-input-upload>
      </div>
    </div>

    <!-- Name Field -->
    <mg-form-text-input
      label="{{ MESSAGES.FIELD_LABEL_NAME }}"
      [control]="form.controls[FORM_FIELD.NAME]">
    </mg-form-text-input>

    <!-- UUID Field -->
    <mg-form-text-input
      *ngIf="(isNew$ | async) === false"
      label="{{ MESSAGES.FIELD_LABEL_UUID }}"
      [control]="form.controls[FORM_FIELD.UUID]">
    </mg-form-text-input>

    <!-- Description Field -->
    <mg-form-text-input
      inputType="textarea"
      label="{{ MESSAGES.FIELD_LABEL_DESCRIPTION }}"
      [control]="form.controls[FORM_FIELD.DESCRIPTION]">
    </mg-form-text-input>

    <!-- Point Cost Field -->
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <mg-text variant="body-md">{{ MESSAGES.FIELD_LABEL_POINT_COST }}</mg-text>
      <div>
        <mg-form-text-input
          inputType="number"
          [control]="form.controls[FORM_FIELD.POINT_COST]">
        </mg-form-text-input>
      </div>
    </div>

    <!-- Membership Administrator -->
  </div>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [variant]="(isNew$ | async) ? 'text' : 'destructive'"
        (pressed)="delete()">
        {{
          (isNew$ | async)
            ? MESSAGES.BUTTON_LABEL_CANCEL
            : MESSAGES.BUTTON_LABEL_DELETE
        }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        variant="filled"
        [disabled]="!canSubmit"
        (pressed)="submit()">
        {{
          (isNew$ | async)
            ? MESSAGES.BUTTON_LABEL_CREATE
            : MESSAGES.BUTTON_LABEL_SAVE
        }}
      </mg-btn>
    </div>
  </div>
</ng-template>
