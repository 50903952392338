import { PsSearchMode } from '../types';

export abstract class PsFormBarcode {
  abstract onSearchScanNoResults(
    id: string,
    searchMode: PsSearchMode,
  ): Promise<void>;
  abstract onListScanNoResults(
    id: string,
    searchMode: PsSearchMode,
  ): Promise<void>;
}
