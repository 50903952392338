import { Component, EventEmitter, Output } from '@angular/core';
import { badgeRoleNameToMingaRoleType } from 'minga/shared/auth/util';
import { MingaRoleType } from 'minga/util';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

@Component({
  selector: 'mg-whats-new',
  templateUrl: './WhatsNew.component.html',
  styleUrls: ['./WhatsNew.component.scss'],
})
export class WhatsNewComponent {
  stepCount = 0;
  step: number = 0;
  _enableGroups: boolean = false;

  // custom props for showing welcome screens on a per role basis
  currentUserRole: MingaRoleType;
  screensForRole: number[] = [];
  screenIndex: number = 0;

  // screens to show for specific roles
  readonly adminSteps = [0, 1, 2, 3];
  readonly staffSteps = [1];
  readonly studentSteps = [4];

  constructor(private authInfo: AuthInfoService) {
    const roleName = authInfo.authPerson?.badgeRoleName;
    const roleType = badgeRoleNameToMingaRoleType(roleName);

    switch (roleType) {
      case MingaRoleType.SUPERADMIN:
      case MingaRoleType.OWNER:
      case MingaRoleType.MANAGER: {
        this.screensForRole = this.adminSteps;
        break;
      }
      case MingaRoleType.TEACHER:
      case MingaRoleType.STAFF: {
        this.screensForRole = this.staffSteps;
        break;
      }
      case MingaRoleType.STUDENT_LEADER:
      case MingaRoleType.STUDENT: {
        this.screensForRole = this.studentSteps;
        break;
      }
    }

    this.stepCount = this.screensForRole.length;
    this.screenIndex = this.screensForRole[0];
  }

  @Output()
  done: EventEmitter<void> = new EventEmitter();

  next() {
    if (this.step + 1 < this.stepCount) {
      // Remove screen index and replace with step when filtering is not needed
      this.screenIndex = this.screensForRole[++this.step];
    } else {
      this.done.emit();
    }
  }
}
