<ng-template
  mgSearchArea
  placeholder="Search"
  (searchText)="searchText = $event">
  <mg-search-content [query]="searchText"></mg-search-content>
</ng-template>
<mg-layout-legacy-content>
  <ng-container *ngIf="hasToken$ | async">
    <mg-top-of-feed *ngIf="communityEnabled"></mg-top-of-feed>

    <div class="feed-items">
      <mg-paged-scroller
        *ngIf="communityEnabled"
        [filter]="filter"
        [dataSource]="ds">
        <ng-container mg-empty-state>
          <mg-empty-state
            mg-stream-empty
            class="no-results"
            title="You haven't posted anything yet"
            subtitle="Add a post to share with your Minga"
            imageAsset="assets/empty-home/post-ftue-graphic.png">
          </mg-empty-state>
        </ng-container>
        <ng-template
          let-item
          let-itemId="itemId"
          #streamItemTemplate>
          <mg-content-feed-item
            [context]="itemId"
            [content]="item">
          </mg-content-feed-item>
        </ng-template>
      </mg-paged-scroller>

      <ng-container *ngIf="!communityEnabled && idEnabled">
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="16px">
          <img
            class="logo"
            src="assets/Minga_icon.svg" />
          <mg-text
            variant="header-xl-secondary"
            spacing="4"
            >Welcome to Minga</mg-text
          >
        </div>
        <div
          *ngIf="mingaRoleType$ | async as mingaRoleType"
          class="empty-state-card no-padding"
          fxLayout="column"
          fxLayoutGap="16px">
          <div
            class="header mg-centered account-details"
            [class.admin]="
              mingaRoleType == 'OWNER' ||
              mingaRoleType == 'MANAGER' ||
              mingaRoleType == 'SUPERADMIN'
            ">
            <img
              class="student-id"
              src="assets/student-id/student-id-placeholder.svg" />
            <mg-text variant="header-md-secondary">
              {{
                hallpassEnabled
                  ? 'You have a Digital ID & Hall Pass only account'
                  : 'You have a Digital ID only account'
              }}
            </mg-text>
            <button
              mgBubbleBtn
              *ngIf="
                mingaRoleType != 'OWNER' &&
                mingaRoleType != 'MANAGER' &&
                mingaRoleType != 'SUPERADMIN'
              "
              class="mg-bodycopy view-student-id-btn"
              [routerLink]="['', { outlets: { o: ['student-id'] } }]">
              View Now
            </button>
          </div>
          <div
            *ngIf="
              mingaRoleType == 'OWNER' ||
              mingaRoleType == 'MANAGER' ||
              mingaRoleType == 'SUPERADMIN'
            "
            class="body">
            <mg-text
              variant="header-sm-secondary"
              textAlign="center">
              Upgrade your Minga in order to:</mg-text
            >
            <div class="upgrade-section">
              <div class="upgrade-item">
                <img src="assets/empty-home/communicate-across.svg" />
                <mg-text
                  variant="body-sm"
                  textAlign="center"
                  >Communicate across your entire campus</mg-text
                >
              </div>
              <div class="upgrade-item">
                <img src="assets/empty-home/earn-spirit-points.png" />
                <mg-text
                  variant="body-sm"
                  textAlign="center"
                  >Earn spirit points & rewards</mg-text
                >
              </div>
            </div>
            <div class="upgrade-section">
              <div class="upgrade-item">
                <img src="assets/empty-home/track-involvement.svg" />
                <mg-text
                  variant="body-sm"
                  textAlign="center"
                  >Track involvement & participation</mg-text
                >
              </div>
              <div class="upgrade-item">
                <img src="assets/empty-home/manage-events.svg" />
                <mg-text
                  variant="body-sm"
                  textAlign="center"
                  >Manage your events & attendees</mg-text
                >
              </div>
            </div>
            <div class="upgrade-section">
              <div class="upgrade-item">
                <img src="assets/empty-home/create-challenges.png" />
                <mg-text
                  variant="body-sm"
                  textAlign="center"
                  >Create challenges & get your students involved</mg-text
                >
              </div>
              <div class="upgrade-item">
                <img src="assets/empty-home/organize-groups.svg" />
                <mg-text
                  variant="body-sm"
                  textAlign="center"
                  >Organize & manage your groups all in one place</mg-text
                >
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #action>
    <mg-home-action-button></mg-home-action-button>
  </ng-template>
</mg-layout-legacy-content>
