import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { IMingaDistrict } from 'minga/domain/minga';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { DistrictsService } from '@shared/services/districts/districts.service';

import { MmDistrictsEditComponent } from '../components/mm-districts-edit/mm-districts-edit.component';
import {
  MmDistrictEditData,
  MmDistrictEditResponse,
} from '../mm-districts.constants';

@Injectable({ providedIn: 'root' })
export class MmDistrictsService implements OnDestroy {
  private _destroyed$ = new ReplaySubject<void>(1);
  private _loadingSubj = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loadingSubj.asObservable();

  private _districtsSubj = new BehaviorSubject<IMingaDistrict[]>([]);
  public readonly districts$ = this._districtsSubj
    .asObservable()
    .pipe(shareReplay());

  /** Service Constructor */
  constructor(
    private _modalService: ModalOverlayService<
      MmDistrictEditResponse,
      MmDistrictEditData
    >,
    private _districtsService: DistrictsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._loadingSubj.next(false);
    this._loadingSubj.complete();
  }

  public async fetchAll(): Promise<void> {
    try {
      this._loadingSubj.next(true);
      const districts = await this._districtsService.fetchAll();
      this._set(districts);
    } catch (e) {
      this._systemAlertSnackBar.error('Failed to fetch districts');
    } finally {
      this._loadingSubj.next(false);
    }
  }

  public openModal(data?: MmDistrictEditData) {
    return this._modalService.open<MmDistrictEditData>(
      MmDistrictsEditComponent,
      {
        data,
        disposeOnNavigation: true,
      },
    );
  }

  public add(district: IMingaDistrict): void {
    const existing = this._districtsSubj.value;
    this._set([...existing, district]);
  }

  public delete(id: number): void {
    const existing = this._districtsSubj.value;
    this._set(existing.filter(d => d.id !== id));
  }

  public update(district: IMingaDistrict): void {
    const existing = this._districtsSubj.value;
    const updated = existing.map(d =>
      d.id === district.id ? { ...d, ...district } : d,
    );
    this._set(updated);
  }

  private _set(districts: IMingaDistrict[]): void {
    this._districtsSubj.next(districts);
    this._districtsService.updateCache(districts);
  }
}
