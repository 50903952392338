import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloudflareStreamModule } from '@cloudflare/stream-angular';
import { MgButtonModule } from 'minga/app/src/app/button';
import { MgVideoPlayerIconModule } from 'minga/app/src/app/elements/MgVideoPlayerIcon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { VideoThumbnailComponent } from './VideoThumbnail.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgVideoPlayerIconModule,
    MgSpinnerModule,

    // External dependencies
    CloudflareStreamModule,
    CommonModule,
    CloudflareStreamModule,
  ],
  declarations: [VideoThumbnailComponent],
  exports: [VideoThumbnailComponent],
})
export class VideoThumbnailModule {}
