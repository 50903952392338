<section *ngIf="behaviorTypeFC.valueChanges | async as behaviorType">
  <div
    class="form-center-content"
    *ngIf="
      (activeSelector$ | async) !== 'consequence' ||
      behaviorType?.canAddNote ||
      behaviorType?.enableDueDate
    ">
    <div class="fields">
      <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutAlign="center center"
        fxLayoutGap="16px">
        <div
          *ngIf="
            (activeSelector$ | async) !== 'consequence' ||
            behaviorType?.canAddNote
          "
          class="fields-note">
          <mg-form-text-input
            labelBackground="grey"
            [label]="ASSIGNMENT_LABELS.NOTE_LABEL"
            [control]="noteFC">
          </mg-form-text-input>
        </div>

        <div
          *ngIf="behaviorType?.enableDueDate"
          class="fields-date">
          <mg-form-date
            labelBackground="grey"
            [label]="ASSIGNMENT_LABELS.DUE_DATE_LABEL"
            [minDate]="today"
            [control]="dateFC"
            [makeEndOfDay]="true">
          </mg-form-date>
        </div>
      </div>
    </div>
  </div>
</section>
