import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { IMingaFeatureToggleKeys } from 'minga/domain/featureToggle';
import { IMingaDistrict, IMingaPartner } from 'minga/domain/minga';
import { MingaPermission } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';

import { MingaStatus } from '@modules/minga-manager/constants';

import { FormSelectOption } from '@shared/components/form';
import { US_STATES } from '@shared/constants/location';

import {
  MINGA_STATUS_LABEL_MAP,
  MINGA_MODULE_LABEL_MAP,
} from '../../../constants';
import { MingaManagerService } from '../../../services';
import { MmDistrictsService } from '../../mm-districts/services/mm-districts.service';
import {
  MmDashboardFilter,
  MM_DASHBOARD_FILTERS_INITIAL_STATE,
  STATUS_FILTER_VALUES,
  MM_DASHBOARD_FILTERS_INITIAL_STATE_NON_SA,
} from '../constants';
import { MmDashboardFilters } from '../types/mm-dashboard.types';

@Injectable()
export class MmDashboardFiltersService implements OnDestroy {
  /** General Observables */
  private readonly _destroyed$ = new ReplaySubject(1);

  /** Filters */
  public initFilters = this._permissionsService.hasPermission(
    MingaPermission.SUPERADMIN,
  )
    ? MM_DASHBOARD_FILTERS_INITIAL_STATE
    : MM_DASHBOARD_FILTERS_INITIAL_STATE_NON_SA;
  private readonly _filters$ = new BehaviorSubject<MmDashboardFilters>({
    ...this.initFilters,
  });
  public readonly filters$ = this._filters$.asObservable().pipe(shareReplay());

  /** Select Options */
  public readonly moduleOptions$: Observable<FormSelectOption<string>[]>;
  public readonly stateOptions$: Observable<FormSelectOption<string>[]>;
  public readonly districtOptions$: Observable<FormSelectOption<string>[]>;
  public readonly partnerOptions$: Observable<FormSelectOption<string>[]>;
  public readonly statusOptions$: Observable<FormSelectOption<string>[]>;
  public readonly subscriptionOptions$: Observable<any>;
  public readonly pausedOptions$: Observable<FormSelectOption<string>[]>;

  /** Form Controls */
  private readonly _searchQueryControl = new FormControl('', [
    Validators.maxLength(40),
  ]);
  public get searchQueryControl() {
    return this._searchQueryControl;
  }

  /** Service Constructor */
  constructor(
    private _mingaManagerService: MingaManagerService,
    private _permissionsService: PermissionsService,
    private _mmDistrictService: MmDistrictsService,
  ) {
    this.stateOptions$ = of(US_STATES).pipe(
      map(states =>
        states.map(state => ({
          label: state,
          value: state,
        })),
      ),
    );
    this.moduleOptions$ = of($enum(IMingaFeatureToggleKeys).getValues()).pipe(
      map(modules =>
        modules.map(option => ({
          label: MINGA_MODULE_LABEL_MAP[option],
          value: option,
        })),
      ),
    );

    this.districtOptions$ = this._mmDistrictService.districts$.pipe(
      map(districts =>
        districts.map(district => ({
          label: district.name,
          value: district.name,
        })),
      ),
    );
    this.partnerOptions$ = this._mingaManagerService.mingaPartners$.pipe(
      map(partners =>
        partners.map(partner => ({
          label: partner.name,
          value: partner.name,
        })),
      ),
    );
    this.subscriptionOptions$ = this._mingaManagerService.subscriptions$.pipe(
      map(subscriptions =>
        subscriptions
          .map(subscription => ({
            label: subscription.name ?? '',
            value: subscription.planId ?? '',
          }))
          .concat({ label: 'Paid', value: 'paid' }),
      ),
    );
    // label for this is actually type, but in the backend it's 'status'.
    this.statusOptions$ = of(MingaStatus as any).pipe(
      map(status =>
        Object.keys(status).map(option => ({
          label: MINGA_STATUS_LABEL_MAP[option],
          value: option,
        })),
      ),
    );
    this.pausedOptions$ = of(STATUS_FILTER_VALUES);
    this._searchQueryControl.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        debounceTime(250),
        distinctUntilChanged(),
      )
      .subscribe(val => this.setFilter(MmDashboardFilter.SEARCH_QUERY, val));
    this._filters$.next({ ...this.initFilters });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._filters$.complete();
    this._destroyed$.complete();
  }

  public async clearFilters(): Promise<void> {
    this._filters$.next({ ...this.initFilters });
    this._searchQueryControl.setValue('');
  }

  public async setFilter(filter: MmDashboardFilter, value: any): Promise<void> {
    const currentFilters = this._filters$.getValue();
    currentFilters[filter] = value;
    this._filters$.next({ ...currentFilters });
  }
}
