import { Injectable } from '@angular/core';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  WebcamModalComponent,
  WebcamModalData,
  WebcamModalResponse,
} from '@shared/components/webcam-modal';

import {
  BarcodeScanner,
  BarcodeScannerReaders,
  IBarcodeScannerResult,
} from '../BarcodeScanner';

@Injectable()
export class WebcamBarcodeScanner extends BarcodeScanner {
  constructor(
    private _modalOverlay: ModalOverlayService<
      WebcamModalResponse,
      WebcamModalData
    >,
  ) {
    super();
  }

  async scan(
    readers: BarcodeScannerReaders[] = ['barcode'],
    title?: string,
  ): Promise<IBarcodeScannerResult> {
    const modalRef = this._modalOverlay.open(WebcamModalComponent, {
      disposeOnNavigation: true,
      hasBackdrop: true,
      data: {
        title,
        type: 'scanner',
        readers,
      },
    });
    const { type, data } = await modalRef.afterClosed.toPromise();
    const cancelled = type === ModalOverlayServiceCloseEventType.CLOSE;
    return { cancelled, text: data?.value || '' };
  }

  isAvailable(): boolean {
    return true;
  }
}
