export enum PsSummaryMessage {
  COL_LABEL_STATUS = 'Status',
  COL_LABEL_NAME = 'Name',
  COL_LABEL_REASON = 'Reason',

  BUTTON_LABEL_EXPORT = 'Export list',
  BUTTON_LABEL_CONFIRM = 'Confirm',
  BUTTON_LABEL_DONE = 'Done',
  BUTTON_LABEL_CLOSE = 'Close',
}

export const PS_SUMMARY_TABLE_COLUMNS = ['select', 'status', 'name', 'reasons'];
