import * as day from 'dayjs';

import { DatePickerPreset } from './form-date-range.types';

export const DYNAMIC_DATES = {
  endOfToday: () => day().endOf('day'),
};

enum Labels {
  LABEL_TODAY = 'Today',
  LABEL_YESTERDAY = 'Yesterday',
  LABEL_LAST_WEEK = 'Last 7 Days',
  LABEL_THIS_WEEK = 'This week',
  LABEL_LAST_MONTH = 'Last 30 Days',
  LABEL_THIS_MONTH = 'This month',
  LABEL_CURRENT_SCHOOL_YEAR = 'Aug 1 to today',
}

/**
 * This is immutable since we save these keys in the db for active/inactive default presets
 */
export enum DatePickerDefaultPresetKey {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_WEEK = 'last_week',
  THIS_WEEK = 'next_week',
  LAST_MONTH = 'last_month',
  THIS_MONTH = 'next_month',
  CURRENT_SCHOOL_YEAR = 'current_school_year',
}

export const DATE_PICKER_DEFAULT_PRESETS: DatePickerPreset[] = [
  {
    label: Labels.LABEL_TODAY,
    value: DatePickerDefaultPresetKey.TODAY,
    start: () => day().startOf('day'),
    end: () => day().endOf('day'),
    order: 1,
  },
  {
    label: Labels.LABEL_YESTERDAY,
    value: DatePickerDefaultPresetKey.YESTERDAY,
    start: () => day().subtract(1, 'days').startOf('day'),
    end: () => day().subtract(1, 'days').endOf('day'),
    order: 2,
  },
  {
    label: Labels.LABEL_THIS_WEEK,
    value: DatePickerDefaultPresetKey.THIS_WEEK,
    start: () => day().startOf('week'),
    end: () => day().endOf('day'),
    order: 3,
  },
  {
    label: Labels.LABEL_THIS_MONTH,
    value: DatePickerDefaultPresetKey.THIS_MONTH,
    start: () => day().startOf('month'),
    end: () => day().endOf('day'),
    order: 4,
  },
  {
    label: Labels.LABEL_LAST_WEEK,
    value: DatePickerDefaultPresetKey.LAST_WEEK,
    start: () => day().subtract(1, 'weeks').startOf('day'),
    end: () => day().endOf('day'),
    order: 5,
  },

  {
    label: Labels.LABEL_LAST_MONTH,
    value: DatePickerDefaultPresetKey.LAST_MONTH,
    start: () => day().subtract(1, 'month').startOf('day'),
    end: () => day().endOf('day'),
    order: 6,
  },

  {
    label: Labels.LABEL_CURRENT_SCHOOL_YEAR,
    value: DatePickerDefaultPresetKey.CURRENT_SCHOOL_YEAR,
    start: () => {
      const todaysDate = day();
      const start = todaysDate.isAfter(`${todaysDate.format('YYYY')}-08-01`)
        ? day().month(7).startOf('month')
        : day().subtract(1, 'years').month(7).startOf('month');

      return start;
    },
    end: () => day().endOf('day'),
    order: 7,
  },
];

export enum DateMessages {
  DONE_BTN = 'Done',
  RESET_BTN = 'Reset',
}
