import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mg-remove-program-from-minga-dialog',
  templateUrl: './RemoveProgramFromMingaDialog.component.html',
  styleUrls: ['./RemoveProgramFromMingaDialog.component.scss'],
})
export class RemoveProgramFromMingaDialogComponent {
  programName: string = '';
  mingaName: string = '';

  constructor(
    private dialogRef: MatDialogRef<RemoveProgramFromMingaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.programName = data['programName'] || '';
    this.mingaName = data['mingaName'] || '';
  }

  remove() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
