import { BrowserSplashScreen } from './browser-splashscreen';
import { CordovaSplashscreen } from './cordova-splashscreen';
import { ISplashScreen } from './interface';

/**
 * Uses cordova splashscreen in cordova context and browser splashscreen in
 * browser context.
 */
export class PlatformSplashscreen implements ISplashScreen {
  private _cordovaSplashscreen = new CordovaSplashscreen();
  private _browserSplashscreen = new BrowserSplashScreen();

  constructor() {
    if (!window.MINGA_APP_BROWSER) {
      // hide cordova splashscreen to show animated browser splash
      this._cordovaSplashscreen.hide();
    }
  }

  async hide() {
    await this._browserSplashscreen.hide();
  }

  async show() {
    await this._browserSplashscreen.show();
  }
}
