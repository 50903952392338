<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between center"
  fxLayoutAlign.lt-md="center stretch"
  fxLayoutGap.lt-md="12px"
  class="calendar-nav">
  <div class="toggle-wrap">
    <mat-button-toggle-group
      class="filter-toggle-group"
      name="tabToggle"
      [multiple]="false">
      <mat-button-toggle
        *ngIf="calendarPath"
        class="toggle-button"
        routerLinkActive="mat-button-toggle-checked"
        [value]="calendarPath"
        [routerLink]="calendarPath">
        <mg-text variant="body-sm-secondary">Calendar</mg-text>
      </mat-button-toggle>
      <mat-button-toggle
        *ngIf="calendarPath"
        class="toggle-button"
        routerLinkActive="mat-button-toggle-checked"
        [value]="schedulePath"
        [routerLink]="schedulePath">
        <mg-text variant="body-sm-secondary">List</mg-text>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="4px">
    <mg-calendar-quick-filter
      (quickFilterChange)="onQuickFilterChange($event)"
      [quickFilter]="(settings$ | async)?.quickFilter">
    </mg-calendar-quick-filter>
    <div
      class="cog-wrapper"
      (click)="onClickOptions()">
      <mg-icon
        [class.options-opened]="calendarScheduleOptionsOpened"
        class="calendar-options-btn">
        settings-active
      </mg-icon>
    </div>
  </div>
</div>
<div class="calendar-schedule-options-top-border"></div>
