import { COLORS, ELEMENTS, SPACING, VARIANTS } from './text.constants';
import { Variant, Color, Spacing, TextElement } from './text.types';

const getSettingFromConfig =
  <T>(config) =>
  (setting: T, fallback?: T): T => {
    return config.includes(setting) ? setting : fallback || null;
  };

export const getVariant = getSettingFromConfig<Variant>(VARIANTS);
export const getElement = getSettingFromConfig<TextElement>(ELEMENTS);
export const getColor = getSettingFromConfig<Color>(COLORS);
export const getSpacing = getSettingFromConfig<Spacing>(SPACING);
