import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { ReplaySubject } from 'rxjs';
import {
  debounceTime,
  filter,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { PeopleFacadeService, Person } from 'minga/app/src/app/people';

import {
  DEBOUCE_TIME,
  PeopleSearchInputMessages,
} from './people-search-input.constants';

/**
 * People Select Input
 *
 * This component renders a dropdown list of people based on the search input.
 */
@Component({
  selector: 'mg-people-search-input',
  templateUrl: './people-search-input.component.html',
  styleUrls: ['./people-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(document:click)': 'onClickDocument($event)',
  },
})
export class PeopleSearchInputComponent implements OnInit {
  /** Element Refs */
  @ViewChild('searchEle')
  searchElement;

  /** Enums & Constants */
  MESSAGES: typeof PeopleSearchInputMessages = PeopleSearchInputMessages;

  /** General Variables */
  private _destroyed$ = new ReplaySubject<void>(1);
  isOpen = false;
  isPersonSelected = false;
  isLoading = true;
  formControl: FormControl = new FormControl('');
  searchResults: Person[];

  @Input()
  label: string = PeopleSearchInputMessages.DEFAULT_LABEL;

  @Input()
  allowedRoles: string[] | null = null;

  @Input()
  showAsChip = false;

  @Input() set defaultValue(val: string) {
    if (val) {
      this.formControl.setValue(val);
      this.isPersonSelected = true;
    }
  }

  @Output() onPersonSelected: EventEmitter<Person | null> = new EventEmitter();
  @Output() onBlurEvent: EventEmitter<any> = new EventEmitter();

  get showDropdown(): boolean {
    if (!this.formControl.value) return false;
    return this.formControl.value.length && this.isOpen ? true : false;
  }

  get showResults(): boolean {
    if (this.isLoading || !this.formControl.value) return false;
    if (this.formControl.value.length && this.searchResults.length) {
      return true;
    }
    return false;
  }

  get showNoResults(): boolean {
    if (this.isLoading) return false;
    return !this.searchResults.length ? true : false;
  }

  /** Component Constructor */
  constructor(
    private _cdr: ChangeDetectorRef,
    private _peopleFacade: PeopleFacadeService,
  ) {
    /** Value Change Subscription */
    this.formControl.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        tap(() => {
          this.isLoading = true;
          this.isOpen = true;
          this._cdr.markForCheck();
        }),
        debounceTime(DEBOUCE_TIME),
        filter(search => search),
        switchMap(search => {
          return this._peopleFacade.searchPeople(search);
        }),
      )
      .subscribe((searchResults: Person[]) => {
        if (this.allowedRoles && this.allowedRoles.length > 0) {
          this.searchResults = searchResults.filter(person =>
            this.allowedRoles.includes(person.roleType),
          );
        } else {
          this.searchResults = searchResults;
        }
        this.isLoading = false;
        this._cdr.markForCheck();
      });
  }

  /** Component Lifecycle: On Mount */
  ngOnInit(): void {}

  /** Component Lifecycle: On Unmount */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public setValue(value: string) {
    if (this.formControl.value !== value) {
      this.formControl.setValue(value);
      this._cdr.markForCheck();
    }
  }

  /**
   * Handle On Click Person
   *
   * Handle what happens when a person row item is clicked on the search input.
   * This method prepares the data to be passed to the parent component via an
   * event emitter.
   */
  async handleOnClickPerson(person: Person): Promise<void> {
    if (this.isOpen) this.isOpen = false;
    this.formControl.setValue(person.displayName);
    this.isPersonSelected = true;
    this.onPersonSelected.emit(person);
    return;
  }

  /**
   * On Click Clear Search
   *
   * Handle what happens when the clear icon is clicked on the search input.
   * also emits a null value to the onPersonSelected event.
   */
  async onClickClearSearch(): Promise<void> {
    this.isOpen = false;
    this.isPersonSelected = false;
    this.formControl.reset();
    this.searchResults = [];
    this.onPersonSelected.emit(null);
    this._cdr.markForCheck();
    return;
  }

  /**
   * On Click Document
   *
   * Close the dropdown if the user clicks outside of the search elemenet.
   */
  async onClickDocument(event): Promise<void> {
    if (!this.searchElement.nativeElement.contains(event.target)) {
      this.isOpen = false;
      this._cdr.markForCheck();
    }
    return;
  }
}
