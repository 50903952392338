import { Routes } from '@angular/router';

import { PeopleSelectorRoutes } from '@modules/people-selector';
import { SelectionAssignerRoutes } from '@modules/selection-assigner';

import { RouterOutletOption } from '@shared/constants/routerOutlet';
import { AuthGuard } from '@shared/guards';

export const MODAL_ROUTES: Routes = [
  {
    outlet: RouterOutletOption.MODAL,
    path: PeopleSelectorRoutes.ROOT,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/people-selector').then(
        m => m.PeopleSelectorRoutesModule,
      ),
  },
  {
    outlet: RouterOutletOption.MODAL,
    path: SelectionAssignerRoutes.ROOT,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/selection-assigner').then(
        m => m.SelectionAssignerRoutesModule,
      ),
  },
  // selection assigner redirects
  {
    path: `${SelectionAssignerRoutes.ROOT}/${SelectionAssignerRoutes.HALL_PASS}`,
    redirectTo: `/(${RouterOutletOption.MODAL}:${SelectionAssignerRoutes.ROOT}/${SelectionAssignerRoutes.HALL_PASS})`,
    pathMatch: 'full',
  },
  {
    path: `${SelectionAssignerRoutes.ROOT}/${SelectionAssignerRoutes.CHECK_IN}`,
    redirectTo: `/(${RouterOutletOption.MODAL}:${SelectionAssignerRoutes.ROOT}/${SelectionAssignerRoutes.CHECK_IN})`,
    pathMatch: 'full',
  },
  {
    path: `${SelectionAssignerRoutes.ROOT}/${SelectionAssignerRoutes.BEHAVIOR}`,
    redirectTo: `/(${RouterOutletOption.MODAL}:${SelectionAssignerRoutes.ROOT}/${SelectionAssignerRoutes.BEHAVIOR})`,
    pathMatch: 'full',
  },
];
