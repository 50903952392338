import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pageButtons' })
export class PageButtonsPipe implements PipeTransform {
  /** Config */
  private readonly _limit = 8;
  private readonly _threshold = 3;

  transform(totalPages: number, pageIndex: number) {
    const greaterThanLimit = totalPages > this._limit;
    let buttons = [];
    if (!greaterThanLimit)
      buttons = Array.from({ length: totalPages }, (_, i) => i);
    else {
      for (let i = 0; i < totalPages; i++) {
        if (pageIndex < this._limit) {
          if (i < this._limit) buttons.push(i);
        } else if (pageIndex > totalPages - this._limit) {
          if (i > totalPages - this._limit) buttons.push(i);
        } else {
          if (
            i > pageIndex - this._threshold &&
            i < pageIndex + this._threshold
          )
            buttons.push(i);
        }
      }
    }
    return {
      ellipsis: {
        starting: greaterThanLimit && pageIndex >= this._limit,
        ending: greaterThanLimit && pageIndex < totalPages - (this._limit - 1),
      },
      buttons,
    } as const;
  }
}
