<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="12px">
  <ng-container *ngIf="fieldsInitialized$ | async; else loading">
    <ng-content></ng-content>
  </ng-container>
</div>

<ng-template #loading>
  <div class="loading-container">
    <mg-text
      variant="body-sm-secondary"
      textAlign="center"
      spacing="default"
      >Loading...</mg-text
    >
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>
</ng-template>
