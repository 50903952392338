export enum GroupRoutes {
  ROOT = 'groups',
  MY_LIST = 'my~list',
  LIST = 'list',
  MY_GROUPS_LIST = 'mygroups/list',
  VIEW = 'view',
  GROUP_EVENTS = 'events',
  GROUP_EVENTS_CALENDAR = 'calendar',
  GROUP_EVENTS_SCHEDULE = 'schedule',
  GROUP_FEED = 'feed',
  GROUP_FILES = 'files',
  GROUP_POLLS = 'polls',
  GROUP_ABOUT = 'about',

  // overlay routes
  OVERLAY_NEW = 'new',
  OVERLAY_UPDATE = 'update',
  OVERLAY_MEMBERS = 'members',
  OVERLAY_FILE_UPLOAD = 'file~upload',
  OVERLAY_URL_UPLOAD = 'url~upload',
}

export enum GroupMessages {
  PAGE_HEADER_TITLE_GROUPS = 'Groups',
  PAGE_TITLE_FEED = 'Feed',
  PAGE_TITLE_EVENTS = 'Events',
  PAGE_TITLE_FILES = 'Files',
  PAGE_TITLE_POLL = 'Polls',
  PAGE_TITLE_ABOUT = 'About',
}
