<div class="notification">
  <ng-content select="mg-notification-base-image"></ng-content>
  <ng-container *ngIf="!imageSlot">
    <div
      class="notification-image"
      [ngClass]="{ 'has-avatar': hasAvatar }">
      <mg-avatar [src]="avatarSrc"></mg-avatar>
      <div
        class="notification-icon"
        [style.backgroundColor]="'#' + iconColor">
        <div class="notification-svg-wrap">
          <mg-icon>{{ icon }}</mg-icon>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="notification-content">
    <mg-text
      variant="header-sm"
      class="notification-title"
      *ngIf="titleSlot">
      <ng-content select="mg-notification-base-title"></ng-content>
    </mg-text>

    <mg-text
      variant="body-sm"
      class="notification-body"
      *ngIf="bodySlot">
      <ng-content select="mg-notification-base-body"></ng-content>
    </mg-text>
    <mg-text
      color="surface-alt"
      variant="body-xs"
      class="notification-timestamp">
      <ng-content select="mg-notification-base-timestamp"></ng-content>
      <ng-container *ngIf="!timestampSlot">
        {{ renderTimestamp(timestamp) }}
      </ng-container>
    </mg-text>

    <mg-text
      element="p"
      variant="body-sm"
      class="notification-footer"
      *ngIf="footerSlot">
      <ng-content select="mg-notification-base-footer"></ng-content>
    </mg-text>
  </div>
</div>
