import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

import { SearchManager } from 'minga/proto/gateway/search_ng_grpc_pb';

@Component({
  selector: 'mg-search-content',
  templateUrl: './SearchContent.component.html',
  styleUrls: ['./SearchContent.component.scss'],
})
export class SearchContentComponent {
  @Input()
  query: string = '';

  @Input()
  contentType: string = '';

  @Input()
  groupHashes: string = '';

  get streamFilter() {
    return {
      query: this.query,
      contentType: this.contentType,
      groupHashes: this.groupHashes,
    };
  }

  constructor(public searchManager: SearchManager) {}
}
