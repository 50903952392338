import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Tile } from '@modules/selection-assigner/types';

@Component({
  selector: 'mg-sa-tile',
  templateUrl: './sa-tile.component.html',
  styleUrls: ['./sa-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaTileComponent {
  @Input() public tile: Tile;
  @Input() public selected: boolean;
  @Output() pressed = new EventEmitter<Tile>();

  constructor() {}

  public onClick() {
    this.pressed.emit(this.tile);
  }
}
