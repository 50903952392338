import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute, Router } from '@angular/router';

import {
  BehaviorSubject,
  from,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { Checkin } from 'minga/domain/checkin';
import { ReportTypes } from 'minga/domain/reportTypes';
import { day } from 'minga/shared/day';
import { MingaPermission, MingaRoleType } from 'minga/util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { Person } from 'src/app/people';
import { PermissionsService } from 'src/app/permissions';
import { RolesService } from 'src/app/roles/services';
import { GradesService } from 'src/app/services/Grades';

import { CheckinManagerRoutes } from '@modules/checkin-manager/checkin-manager.constants';
import { MmScheduledReportsService } from '@modules/minga-manager/components/mm-scheduled-reports/services';
import { SUPPORTED_SCHEDULE_TYPES } from '@modules/minga-manager/components/mm-scheduled-reports/types';

import { FormSelectComponent } from '@shared/components/form';
import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import { MultiPersonSearchComponent } from '@shared/components/multi-person-search';
import { CheckinService } from '@shared/services/checkin';

import {
  CHECKIN_REPORT_FILTER_INITIAL_STATE,
  CheckinManagerReportFilterType,
  CheckinManagerReportsMessages,
  CheckinManagerReportType,
} from './checkin-manager-reports.constants';
import { CheckinManagerReportsService } from './checkin-manager-reports.service';
import { CHECKIN_REPORT_TYPES } from './constants';

/**
 * Checkin Reports
 */
@Component({
  selector: 'mg-checkin-manager-reports',
  templateUrl: './checkin-manager-reports.component.html',
  styleUrls: ['./checkin-manager-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinManagerReportsComponent implements OnDestroy {
  /** Element Refs */
  @ViewChild('bottom')
  bottomEl: ElementRef;

  @ViewChild(MultiPersonSearchComponent)
  private _multiPersonSearchComponent: MultiPersonSearchComponent;
  @ViewChildren(FormSelectComponent)
  private _selectComponents: QueryList<FormSelectComponent>;

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);
  public readonly media$: Observable<any>;

  /** Table Data Service */
  private _dataServiceSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public readonly dataService$: Observable<any> =
    this._dataServiceSubj.asObservable();

  /** People Search */
  setPeopleSearchValue$: Subject<string> = new Subject<string>();

  public readonly MESSAGES: typeof CheckinManagerReportsMessages =
    CheckinManagerReportsMessages;

  /** data */
  public data$: Observable<any>;
  public infLoading$: Observable<boolean>;
  public tableLoading$: Observable<boolean>;

  /** Filters */
  readonly serviceFilter$: Observable<any>;

  public readonly gradeOptions$: Observable<any>;
  public readonly roleOptions$: Observable<any>;
  public readonly reasonOptions$: Observable<any>;

  public readonly grade$: Observable<string[]>;
  public readonly role$: Observable<string[]>;
  public readonly reason$: Observable<number[]>;
  public readonly type$: Observable<CheckinManagerReportType>;
  public startDate: day.Dayjs = day().startOf('day');
  public disableAbsentee: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public filters$ = this._checkInReportsService.filter$;
  public SUPPPORTED_SCHEDULE_TYPES: any = SUPPORTED_SCHEDULE_TYPES;
  public readonly canScheduleReport$ = this._permissions.observePermission(
    MingaPermission.CHECKIN_TYPE_MANAGE,
  );

  public range = initializeRange({
    start: {
      value: CHECKIN_REPORT_FILTER_INITIAL_STATE.startDate,
    },
    end: {
      value: CHECKIN_REPORT_FILTER_INITIAL_STATE.endDate,
    },
  });

  /** General Variables */
  public reportType = {
    active: ReportTypes.CHECKIN_HISTORY,
    options: CHECKIN_REPORT_TYPES,
  };
  public readonly REPORT_TYPES = ReportTypes;
  public readonly REASON_TYPES = CheckinManagerReportType;

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    private _checkInReportsService: CheckinManagerReportsService,
    private _checkinService: CheckinService,
    private _gradesService: GradesService,
    private _rolesService: RolesService,
    private _analytics: AnalyticsService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _mmScheduledReportService: MmScheduledReportsService,
    private _permissions: PermissionsService,
  ) {
    this.reasonOptions$ = this._checkinService.getReasonsObs(false).pipe(
      tap(() => this._checkinService.getReasonsObs()),
      takeUntil(this._destroyed$),
      map(reasons =>
        reasons.map(reason => ({
          label: reason.name,
          value: reason.id,
        })),
      ),
    );

    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );

    /** Filters */
    this.serviceFilter$ = this._checkInReportsService.filter$;

    this.roleOptions$ = this._rolesService.roles$.pipe(
      tap(() => this._rolesService.fetchIfNeeded()),
      takeUntil(this._destroyed$),
      map(roles =>
        roles
          .filter(
            role =>
              ![MingaRoleType.SUPERADMIN].includes(
                role.roleType as MingaRoleType,
              ),
          )
          .map(role => ({
            value: role.roleType as MingaRoleType,
            label: role.name,
          })),
      ),
    );
    this.gradeOptions$ = this._gradesService.grades$.pipe(
      tap(() => this._gradesService.fetchIfNeeded()),
      takeUntil(this._destroyed$),
      map(grades =>
        grades.map(grade => ({
          label: grade,
          value: grade,
        })),
      ),
    );

    this.grade$ = this.serviceFilter$.pipe(map(filters => filters.grade));
    this.role$ = this.serviceFilter$.pipe(map(filters => filters.role));
    this.reason$ = this.serviceFilter$.pipe(map(filters => filters.reason));
    this.type$ = this.serviceFilter$.pipe(map(filters => filters.type));
    this._route.url.pipe(takeUntil(this._destroyed$)).subscribe(x => {
      if (this._route.children[0]) {
        this._route.children[0].url
          .pipe(takeUntil(this._destroyed$))
          .subscribe(url => {
            if (url[0]) {
              const report = url[0].path as ReportTypes;
              const possiblePaths = this.reportType.options.map(
                type => type.value,
              );
              if (possiblePaths.includes(report)) {
                this.reportType.active = report;
              }
            }
          });
      }
    });

    this.reason$.pipe(takeUntil(this._destroyed$)).subscribe(reason => {
      this.disableAbsentee.next(!reason.length);
    });

    this.range.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(range => {
        this._checkInReportsService.setFilter(
          CheckinManagerReportFilterType.START_DATE,
          range.start,
        );
        this._checkInReportsService.setFilter(
          CheckinManagerReportFilterType.END_DATE,
          range.end,
        );
      });
  }

  ngOnDestroy(): void {
    this._checkInReportsService.clearFilter();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  async handleOnChangeReportType(value: string) {
    if (value === ReportTypes.CHECKIN_HISTORY)
      this._checkInReportsService.updateEndDate();
    else this.handleOnChangeDate(this.startDate);
    await this._router.navigateByUrl(
      `/${CheckinManagerRoutes.ROOT}/${CheckinManagerRoutes.REPORTS}/${value}`,
    );
  }

  /**
   * Handle On Change Reason Type
   */
  handleOnChangeReasonType(event: MatButtonToggleChange) {
    const { value } = event;
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.TYPE,
      value,
    );
  }

  /**
   * Handle On Change Date
   */
  handleOnChangeDate(value: any) {
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.START_DATE,
      day(value),
    );
  }

  /**
   * Handle On Click Export
   */
  async handleOnClickExport(): Promise<void> {
    const reportType =
      this._checkInReportsService.filter.type ===
      CheckinManagerReportType.ABSENTEES
        ? ReportTypes.CHECKIN_ABSENTEES
        : ReportTypes.CHECKIN;
    this._checkInReportsService.exportReport(reportType);
  }

  /**
   * Handle On Person Selected
   */
  handleOnPersonSelected(people: Person[]) {
    const issuedTo: string[] = [];
    const issuedBy: string[] = [];
    for (const person of people) {
      if (
        person.roleType === MingaRoleType.STUDENT ||
        person.roleType === MingaRoleType.STUDENT_LEADER
      ) {
        issuedTo.push(person.hash);
      } else {
        issuedBy.push(person.hash);
      }
    }
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.ISSUED_BY,
      issuedBy,
    );

    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.ISSUED_TO,
      issuedTo,
    );
  }

  changeUserList(list: number[]) {
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.USER_LIST,
      list,
    );
  }

  handleOnChangeGradeFilter(value) {
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.GRADE,
      value,
    );
  }

  handleOnChangeReasonFilter(value) {
    if (!value.length) {
      this._checkInReportsService.setFilter(
        CheckinManagerReportFilterType.TYPE,
        this.REASON_TYPES.CHECKINS,
      );
      this.disableAbsentee.next(true);
    } else {
      this.disableAbsentee.next(false);
    }
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.REASON,
      value,
    );
  }

  handleOnChangeRoleFilter(value) {
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.ROLE,
      value,
    );
  }

  async handleOnClickHistory(item: Checkin): Promise<void> {
    let personHistory = [
      {
        hash: item.person.hash,
        displayName: item.person.displayName,
        badgeIconUrl: item.person.badgeIconUrl,
      },
    ];
    const blankPeople = [];
    const filterPersonHash = this._checkInReportsService.getPerson();
    if (filterPersonHash && filterPersonHash === personHistory[0].hash) {
      this._multiPersonSearchComponent.removePerson(personHistory[0].hash);
      personHistory = blankPeople;
    } else {
      this._multiPersonSearchComponent.updateSelected(personHistory, true);
    }
    this._checkInReportsService.setFilter(
      CheckinManagerReportFilterType.ISSUED_TO,
      [personHistory[0].hash],
    );
    this._checkInReportsService.applyFilter();
  }

  public applyFilter() {
    this._checkInReportsService.applyFilter();
    const reportType = this.reportType.active;
    this._analytics.sendManagerReport({
      managerType: 'checkin',
      reportType,
    });
  }

  public clearFilter() {
    this._multiPersonSearchComponent.setValue([]);
    this._checkInReportsService.clearFilter();
    if (this.reportType.active !== ReportTypes.CHECKIN_HISTORY) {
      this.startDate = day().startOf('day');
      this.handleOnChangeDate(this.startDate);
    }

    this._selectComponents.forEach((component, _) => {
      if (component.placeholder === 'Report Type') return;

      component.control.setValue(undefined);
    });

    this.range.patchValue({
      start: CHECKIN_REPORT_FILTER_INITIAL_STATE.startDate,
      end: CHECKIN_REPORT_FILTER_INITIAL_STATE.endDate,
    });
  }

  public async onScheduleReport() {
    const filters = await this.filters$.pipe(take(1)).toPromise();

    await this._mmScheduledReportService.scheduleReport(
      this.reportType.active,
      filters,
    );
  }

  public onActivate(componentRef: any) {
    this._dataServiceSubj.next(componentRef.ds);
  }
}
