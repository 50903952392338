export enum MltEditMessage {
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CANCEL = 'Cancel',

  FIELD_LABEL_NAME = 'Name',
  FIELD_LABEL_COLOR = 'Color',
  FIELD_LABEL_IMAGE = 'Select an image',

  FIELD_LABEL_PRIORITY = 'Priority (optional)',
  FIELD_LABEL_PRIORITY_HINT = 'Position the sticker further up/down the list. 0 represents the highest priority',
}
