import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input } from '@angular/core';

import { IFitGridTile } from './interface';

/**
 * Denotes and describes the element as a mg-fit-grid tile. See
 * FitGridComponent for details.
 */
@Directive({
  selector: '[mgFitGridTile]',
  exportAs: 'mgFitGridTile',
  host: { class: 'mg-fit-grid-tile' },
})
export class FitGridTileDirective implements IFitGridTile {
  private _tileWidth: number = 0;
  private _tileHeight: number = 0;
  private _tileRatioString: string = '';
  // wdith / height value
  private _tileRatio: number = 0;

  /**
   * Used with `tileHeight` to calculate the aspect tile aspect ratio
   */
  @Input()
  set tileWidth(value: number) {
    this._tileWidth = coerceNumberProperty(value);
    if (this._tileHeight && this._tileWidth) {
      this._setTileRatio(this._tileWidth, this._tileHeight);
    }
  }

  get tileWidth() {
    return this._tileWidth;
  }

  /**
   * Used with `tileWidth` to calculate the aspect tile aspect ratio
   */
  @Input()
  set tileHeight(value: number) {
    this._tileHeight = coerceNumberProperty(value);
    if (this._tileHeight && this._tileWidth) {
      this._setTileRatio(this._tileWidth, this._tileHeight);
    }
  }

  get tileHeight() {
    return this._tileHeight;
  }

  /**
   * The ratio is tile-width:tile-height, and must be passed in with a colon
   * (eg. 16:9)
   *
   * @NOTE Do not use both `tileWidth`, `tileHeight` _and_ `tileRatio`
   */
  @Input()
  set tileRatio(value: string) {
    if (typeof value !== 'string') {
      throw new Error('FitGridTileDirective - tileRatio must be a string');
    }

    const [width, height] = value.split(':');
    this._tileRatioString = value;
    this._setTileRatio(
      coerceNumberProperty(width),
      coerceNumberProperty(height),
    );
  }

  get tileRatio() {
    return this._tileRatioString;
  }

  get tileAspectRatio(): number {
    return this._tileRatio;
  }

  constructor(public elementRef: ElementRef) {}

  private _setTileRatio(tileWidth: number, tileHeight: number) {
    this._tileRatio = tileWidth / tileHeight;
  }
}
