<div
  fxLayout="column"
  fxLayoutGap="16px">
  <mg-text variant="header-md-secondary">
    My Stickers ({{ (stickers$ | async)?.length }})
  </mg-text>
  <div *ngIf="loading$ | async; else stickers">
    <mg-spinner
      color="primary"
      [diameter]="25"
      [thickness]="3">
    </mg-spinner>
  </div>
  <ng-template #stickers>
    <ng-container *ngIf="(stickers$ | async)?.length > 0; else empty">
      <div class="sticker-wrap">
        <mg-carousel
          [config]="carouselConfig"
          [dataSource]="stickers$ | async">
          <mg-carousel-item
            mgCarouselItem
            *mgCarouselDef="let item; let j = index">
            <div class="sticker">
              <mg-image
                *ngIf="getStickerPath(item)"
                class="sticker-img-wrap"
                [srcs]="
                  getStickerPath(item)
                    | mgImageUrls
                      : ['blurloading1', ['bannerlibpreview', 'cardbanner']]
                "
                background-size="contain">
              </mg-image>
              <mg-text
                [numberOfLines]="2"
                [variant]="
                  (mediaService.isMobileView$ | async) ? 'body-xs' : 'body-sm'
                "
                textAlign="center">
                {{ item.name }}
              </mg-text>
            </div>
          </mg-carousel-item>
        </mg-carousel>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #empty>
    <div class="empty-stickers">
      <mg-empty-state
        mg-stream-empty
        [title]="MESSAGES.STICKER_NO_NEW_CONTENT">
      </mg-empty-state>
    </div>
  </ng-template>
</div>
