import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentPublishControlsModule } from 'minga/app/src/app/components/ContentPublishControls';

import { MingaContentPublishInfoEditorComponent } from './MingaContentPublishInfoEditor.component';

@NgModule({
  imports: [
    // Minga dependencies
    ContentPublishControlsModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MingaContentPublishInfoEditorComponent],
  exports: [MingaContentPublishInfoEditorComponent],
})
export class MingaContentPublishInfoEditorModule {}
