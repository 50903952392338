import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BadgeNotificationHandlerService } from 'minga/app/src/app/notifications/badge';
import { Notification } from 'minga/proto/gateway/notification_pb';

import { NotificationListenerBaseService } from '../NotificationListenerBase.service';

/**
 * Listen to incoming notifications to look for badge notifications
 */
@Injectable({ providedIn: 'root' })
export class BadgeNotificationListenerService
  extends NotificationListenerBaseService
  implements OnDestroy
{
  private _destroyed$ = new ReplaySubject<void>(undefined);

  constructor(
    public store: Store<any>,
    private _notificationHandler: BadgeNotificationHandlerService,
  ) {
    super(store);

    this.notifications$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(notification => {
        this._handleNotification(notification);
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private async _handleNotification(msg: Notification.AsObject) {
    if (
      this._notificationHandler.isBadgeNotification(msg.notificationType || '')
    ) {
      return await this._notificationHandler.handleDisplayBadgeNotification(
        msg,
      );
    }
  }
}
