import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { NavigationDrawerModule } from 'minga/app/src/app/navigation/components/NavigationDrawer';

import { SideNavComponent } from './SideNav.component';

@NgModule({
  imports: [
    // Minga dependencies
    NavigationDrawerModule,

    // External dependencies
    CommonModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatSidenavModule,
    RouterModule,
  ],
  declarations: [SideNavComponent],
  exports: [SideNavComponent],
})
export class SideNavModule {}
