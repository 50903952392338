import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SelectionAssignerRoutes } from '@modules/selection-assigner';

import { RouterOutletOption } from '@shared/constants/routerOutlet';

@Injectable()
export class TeacherToolsService {
  constructor(private _router: Router) {}

  public openHallpassAssignmentForm(isStudent = false) {
    this._router.navigate(
      [
        '',
        {
          outlets: {
            ['o']: null,
            [RouterOutletOption.MODAL]: [
              SelectionAssignerRoutes.ROOT,
              SelectionAssignerRoutes.HALL_PASS,
            ],
          },
        },
      ],
      {
        state: {
          type: 'hall-pass',
          selfAssign: isStudent,
        },
      },
    );
  }

  public openPbisTrackingForm(type: string) {
    this._router.navigate(
      [
        '',
        {
          outlets: {
            ['o']: null,
            [RouterOutletOption.MODAL]: [
              SelectionAssignerRoutes.ROOT,
              SelectionAssignerRoutes.BEHAVIOR,
            ],
          },
        },
      ],
      {
        state: {
          type,
        },
      },
    );
  }

  public openCheckinForm() {
    this._router.navigate(
      [
        '',
        {
          outlets: {
            ['o']: null,
            [RouterOutletOption.MODAL]: [
              SelectionAssignerRoutes.ROOT,
              SelectionAssignerRoutes.CHECK_IN,
            ],
          },
        },
      ],
      {
        state: {
          type: 'checkin',
        },
      },
    );
  }

  public openAssignActivityForm() {
    this._router.navigate(
      [
        '',
        {
          outlets: {
            ['o']: null,
            [RouterOutletOption.MODAL]: [
              SelectionAssignerRoutes.ROOT,
              SelectionAssignerRoutes.CHECK_IN,
            ],
          },
        },
      ],
      {
        state: {
          type: 'flex-activity',
        },
      },
    );
  }
}
