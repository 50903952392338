import {
  ApplicationRef,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Input,
  IterableChanges,
  IterableDiffer,
  IterableDiffers,
  KeyValueChanges,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

export interface IMgConformValue {
  width: number;
  height: number;
}

interface IMgConformView {
  viewRef: EmbeddedViewRef<any>;
  conformValue: IMgConformValue;
}

function assertConformValue(value: any): IMgConformValue {
  if (typeof value.width !== 'number') {
    throw new Error('Non mgConform value. Missing width field');
  }

  if (typeof value.height !== 'number') {
    throw new Error('Non mgConform value. Missing height field');
  }

  return value;
}

function toPx(value: string | number) {
  if (typeof value === 'number') {
    return value;
  }

  let unit2 = value.substr(-2);
  let unitless2 = parseFloat(value.substr(0, value.length - 2));

  switch (unit2) {
    case 'px':
      return unitless2;
    case 'vh':
      return (unitless2 / 100) * window.innerHeight;
    case 'vw':
      return (unitless2 / 100) * window.innerWidth;
    default:
      throw new Error(`*mgConform Unsupported unit value '${value}'`);
  }
}

@Directive({
  selector: '[mgConform]',
  exportAs: 'mgConform',
})
export class MgConfirmDirective {
  private _values: IMgConformView[] = [];
  private _aspectRatio: number = 0;
  private _differ: IterableDiffer<IMgConformView>;
  private _elementDiffers: KeyValueDiffer<string, any>[] = [];

  @Input()
  mgConformMaxWidth: number = 0;

  @Input()
  mgConformMinWidth: number = 0;

  @Input()
  mgConformMinTotalWidth: number = 0;

  @Input()
  mgConformMaxHeight: number = 0;

  @Input()
  set mgConformAspectRatio(value: string | number) {
    let aspectRatio = 0;

    if (typeof value === 'string') {
      const colonIndex = value.indexOf(':');

      if (colonIndex > -1) {
        const [width, height] = value.split(':');
        aspectRatio = parseFloat(height) / parseFloat(width);
      } else {
        aspectRatio = parseFloat(value) || 0;
      }
    } else if (typeof value === 'number') {
      aspectRatio = value;
    }

    this._aspectRatio = aspectRatio;
  }

  constructor(
    private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private changeDetector: ChangeDetectorRef,
    private iterableDiffers: IterableDiffers,
    private keyValueDiffers: KeyValueDiffers,
  ) {}

  _applyChanges(changes: IterableChanges<IMgConformView>) {}

  _applyElementChanges(changes: KeyValueChanges<any, any>, index: number) {
    this.calcConformSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mgConform) {
      const value = changes.mgConform.currentValue;

      if (!this._differ) {
        this._differ = this.iterableDiffers.find(value).create(null);
      }
    }

    if (changes.mgConformMaxWidth) {
      this.calcConformSize();
    }
  }

  ngDoCheck() {
    if (this._differ) {
      const changes = this._differ.diff(this._values);
      if (changes) {
        this._applyChanges(changes);

        changes.forEachAddedItem(record => {
          let differ: KeyValueDiffer<any, any> = this.keyValueDiffers
            .find(record.item.conformValue)
            .create();
          this._elementDiffers[record.currentIndex] = differ;
        });
      }
    }

    let elementChanges: KeyValueChanges<any, any>[] = [];

    for (let index = 0; this._elementDiffers.length > index; ++index) {
      const elementDiffer = this._elementDiffers[index];
      const changes = elementDiffer.diff(this._values[index].conformValue);
      if (changes) {
        elementChanges.push(changes);
      }
    }

    if (elementChanges.length > 0) {
      // @TODO: Optimize by just adjusting for changes
      this.calcConformSize();
    }
  }

  clearValues() {
    for (let value of this._values) {
      const { viewRef } = value;
      viewRef.destroy();
      for (let rootNode of viewRef.rootNodes) {
        if (rootNode.parentElement) {
          rootNode.parentElement.removeChild(rootNode);
        }
      }
    }

    this._values = [];
  }

  calcConformSize() {
    const MAX_SAFE_INTEGER = 9007199254740991;

    let largestHeight = 0;
    let largestWidth = 0;
    let smallestHeight = 0;
    let smallestWidth = 0;

    for (const value of this._values) {
      const {
        conformValue: { width, height },
      } = value;

      let resizedWidth = width;
      let resizedHeight = height;

      if (this.mgConformMaxWidth) {
        const maxWidth = toPx(this.mgConformMaxWidth);

        if (width > maxWidth) {
          resizedWidth = maxWidth;
          resizedHeight = (maxWidth / width) * height;
        }
      }

      if (this.mgConformMinWidth) {
        const minWidth = toPx(this.mgConformMinWidth);

        if (resizedWidth < minWidth) {
          const originalWidth = resizedWidth;
          resizedWidth = minWidth;
          resizedHeight = (minWidth / originalWidth) * resizedHeight;
        }
      }

      if (this.mgConformMaxHeight) {
        const maxHeight = toPx(this.mgConformMaxHeight);

        if (resizedHeight > maxHeight) {
          const originalHeight = resizedHeight;

          resizedHeight = maxHeight;
          resizedWidth = (maxHeight / originalHeight) * resizedWidth;
        }
      }

      largestHeight = Math.max(largestHeight, resizedHeight);
      largestWidth = Math.max(largestWidth, resizedWidth);

      smallestHeight = Math.min(
        smallestHeight || MAX_SAFE_INTEGER,
        resizedHeight,
      );
      smallestWidth = Math.min(smallestWidth || MAX_SAFE_INTEGER, resizedWidth);
    }

    let sizedElements = [];

    for (const value of this._values) {
      const {
        viewRef,
        conformValue: { width, height },
      } = value;

      for (let rootNode of viewRef.rootNodes) {
        if (rootNode instanceof HTMLElement) {
          const aspectRatio = this._aspectRatio || smallestHeight / width;
          const nodeWidth = (smallestHeight / height) * width;
          rootNode.style.height = `${smallestHeight}px`;
          rootNode.style.width = `${nodeWidth}px`;

          sizedElements.push({
            element: rootNode,
            height: smallestHeight,
            width: nodeWidth,
          });
        }
      }
    }

    if (this.mgConformMinTotalWidth) {
      let totalWidth = 0;

      for (let sizedElement of sizedElements) {
        totalWidth += sizedElement.width;
      }

      if (totalWidth && totalWidth < this.mgConformMinTotalWidth) {
        let diff = this.mgConformMinTotalWidth - totalWidth;
        let diffEach = diff / sizedElements.length;

        for (let sizedElement of sizedElements) {
          const originalWidth = sizedElement.width;
          sizedElement.width += diffEach;
          sizedElement.height =
            (sizedElement.width / originalWidth) * sizedElement.height;

          sizedElement.element.style.width = `${sizedElement.width}px`;
          sizedElement.element.style.height = `${sizedElement.height}px`;
        }
      }
    }
  }

  @Input()
  set mgConform(valueArray: any) {
    this.clearValues();

    if (!Array.isArray(valueArray)) {
      return;
    }

    for (let value of valueArray) {
      const conformValue = assertConformValue(value);
      const viewRef = this.viewContainer.createEmbeddedView(this.template);
      const rootNode = viewRef.rootNodes[0];

      viewRef.context.item = conformValue;

      this._values.push({ viewRef, conformValue });
    }

    this.calcConformSize();
  }
}
