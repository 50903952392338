import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';

import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { IConversationWithReadStatus } from 'minga/domain/messaging';
import { day } from 'minga/shared/day';
import { IAvatarClusterItem } from 'src/app/components/AvatarCluster';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { Person, PeopleFacadeService } from 'src/app/people';

const avatarClusterItemFromPerson = (person: Person): IAvatarClusterItem => {
  return {
    src: person.profileImageUrl,
    color: person.badgeIconColor,
  };
};

@Component({
  selector: 'mg-dm-list-item',
  templateUrl: './dm-list-item.component.html',
  styleUrls: ['./dm-list-item.component.scss'],
})
export class DmListItemComponent {
  private _data$: BehaviorSubject<IConversationWithReadStatus | null>;

  readonly avatarClusterItems$: Observable<IAvatarClusterItem[]>;
  readonly participatingPeople$: Observable<Person[]>;

  readonly previewLimit: number = 2;
  readonly avatarLimit: number = 3;

  previewAmount = 0;

  @Input()
  set data(data: IConversationWithReadStatus | null) {
    this._data$.next(data || null);
  }

  get data() {
    return this._data$.getValue();
  }

  @ViewChild('participantsEl', { static: true })
  participantsEl?: ElementRef;

  @HostBinding('class.mg-read')
  @Input()
  readStatus = false;

  get lastMessageDateString(): string {
    if (this.data?.lastMessageDate) {
      return day(this.data.lastMessageDate).fromNow();
    }

    return '';
  }

  getPreviewAmount() {
    if (this.previewAmount) {
      return this.previewAmount;
    }

    let offset = 0;

    if (
      this.participantsEl &&
      this.participantsEl.nativeElement.children.length
    ) {
      const firstChild = this.participantsEl.nativeElement.children[0];
      const parentWidth = this.participantsEl.nativeElement.offsetWidth;
      // add 15 to be sure could see start of second child if there's room
      const childWidth = firstChild.offsetWidth + 15;

      if (childWidth >= parentWidth) {
        ++offset;
      }

      this.previewAmount = this.previewLimit - offset;
      return this.previewAmount;
    }

    // default to previewLimit
    return this.previewLimit;
  }

  constructor(
    private _authInfoService: AuthInfoService,
    private _peopleFacade: PeopleFacadeService,
  ) {
    this._data$ = new BehaviorSubject<IConversationWithReadStatus | null>(null);

    this.participatingPeople$ = combineLatest(
      this._data$,
      this._authInfoService.authPersonHash$,
    ).pipe(
      switchMap(([data, authPersonHash]) => {
        if (!data) return of([Person.defaultValue()]);

        // Participants without the authoring person
        const participantsHashes = data.participants.filter(
          p => p !== authPersonHash,
        );

        if (participantsHashes.length === 0) {
          return of([Person.defaultValue()]);
        }

        return this._peopleFacade.getPeople(participantsHashes);
      }),
    );

    this.avatarClusterItems$ = this.participatingPeople$.pipe(
      map(people => {
        // limit the number of avatars shown
        if (people.length > this.avatarLimit) {
          people.splice(this.avatarLimit - 1, people.length - this.avatarLimit);
        }
        return people.map(avatarClusterItemFromPerson);
      }),
    );
  }
}
