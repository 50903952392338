import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { BlockButton } from '../BlockButton/BlockButton.component';

@Component({
  selector: 'mg-button-clear',
  templateUrl: './ClearButton.component.html',
  styleUrls: ['./ClearButton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearButton extends BlockButton {
  typePostFix = 'clear';
  @Input()
  type = '';
  @Input()
  color: string = 'primary';

  constructor() {
    super();
  }
}
