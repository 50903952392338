import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
  SimpleSnackBar,
} from '@angular/material/snack-bar';

import * as _ from 'lodash';

import { MessageDialogComponent } from '../../components/MessageDialog';
import { MingaSnackIconComponent } from '../../components/MingaSnackIcon';

const DEFAULT_DURATION = 3000;

/**
 * Extending the Material SnackBar configuration options.
 */
export class MingaSnackBarConfig extends MatSnackBarConfig {
  // set this parameter to true to keep a snackBar open till the user makes
  // an action
  disableDuration?: boolean;
}
/**
 * @deprecated in favor of `SystemAlertSnackBarService`
 */

@Injectable({ providedIn: 'root' })
export class MingaSnackService {
  start: MatSnackBarHorizontalPosition = 'start';
  center: MatSnackBarHorizontalPosition = 'center';
  end: MatSnackBarHorizontalPosition = 'end';
  left: MatSnackBarHorizontalPosition = 'left';
  right: MatSnackBarHorizontalPosition = 'right';
  top: MatSnackBarVerticalPosition = 'top';
  bottom: MatSnackBarVerticalPosition = 'bottom';

  readonly defaultOptions = {
    duration: DEFAULT_DURATION,
    horizontalPosition: this.center,
    verticalPosition: this.top,
    panelClass: 'mg-snackbar',
  };

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  private _mergeOptions(
    input: number | MingaSnackBarConfig = this.defaultOptions,
  ): MatSnackBarConfig {
    let params: MatSnackBarConfig = {};
    const defaultOptions = _.clone(this.defaultOptions);

    if (typeof input == 'number') {
      params = defaultOptions;
      params.duration = input;
    } else {
      if (!!input && !_.isEqual(defaultOptions, input)) {
        params = _.merge({}, input, defaultOptions);
        // remove duration parameter if MingaSnackBar config param is set
        if (input.disableDuration) {
          delete params.duration;
        }
      } else {
        params = defaultOptions;
      }
    }
    return params;
  }

  open(
    message: string = ' ',
    action: string = 'dismiss',
    input: number | MingaSnackBarConfig = this.defaultOptions,
  ): MatSnackBarRef<SimpleSnackBar | MingaSnackIconComponent> {
    let params: MatSnackBarConfig = this._mergeOptions(input);

    if (action == 'dismiss') {
      params.data = {
        text: message,
        icon: 'close',
      };

      let snackBar = this.snackBar.openFromComponent(
        MingaSnackIconComponent,
        params,
      );
      // provide reference to self
      snackBar.instance.snackBarRef = snackBar;

      return snackBar;
    } else {
      return this.snackBar.open(message, action, params);
    }
  }

  openAlert(
    title: string = '',
    body: string = '',
    action: string = 'view alert',
    input: number | MatSnackBarConfig = this.defaultOptions,
  ) {
    let params: MatSnackBarConfig = this._mergeOptions(input);

    delete params.duration;

    params.panelClass = 'mg-snackbar-alert';
    params.data = {
      simpleAction: action,
      text: title || body,
      iconPlacement: 'before',
      icon: 'broadcast',
      iconClass: 'no-border',
    };

    let snackBar = this.snackBar.openFromComponent(
      MingaSnackIconComponent,
      params,
    );

    let openAlertBool = false;

    snackBar.onAction().subscribe(async () => {
      openAlertBool = true;
      this.snackBar.dismiss();
    });

    snackBar.afterDismissed().subscribe(() => {
      if (openAlertBool) {
        this._openAlertDialog(title, body);
      }
    });

    // provide reference to self
    snackBar.instance.snackBarRef = snackBar;

    return snackBar;
  }

  private _openAlertDialog(title: string, body: string = '') {
    let options = {
      data: {
        title: title,
        text: body,
        translate: false,
        'mg-icon': 'broadcast',
        iconColor: 'white',
        iconBackgound: '#de1717',
      },
      panelClass: 'mg-overflow-dialog',
    };
    let dialog = this.dialog.open(MessageDialogComponent, options);
  }
}
