import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

export interface IMgProgressiveImageElementProperties {
  mode: 'sequential' | 'parallel' | 'race';
  image: string;
  images: string[];
  loading: boolean;
}

const loadImageCache = new Set<string>();

function loadImage(src: string) {
  return new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      loadImageCache.add(src);
      requestAnimationFrame(() => resolve());
    };
    img.onerror = reject;
    img.src = src;
  });
}

@Component({
  selector: 'mg-progressive-image',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgProgressiveImageElement
  implements IMgProgressiveImageElementProperties, OnChanges
{
  @Input()
  mode: 'sequential' | 'parallel' | 'race' = 'sequential';

  @Input()
  image: string = '';

  @Output()
  imageChange: EventEmitter<string> = new EventEmitter();

  @Input()
  images: string[] = [];

  @Input()
  loading: boolean = false;

  @Output()
  loadingChange: EventEmitter<boolean> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.images) {
      this._imagesChanged(this.images);
    }
  }

  private _loadId: number = 0;

  private _setImage(value: string) {
    this.image = value;
    this.imageChange.emit(value);
  }

  private _setLoading(value: boolean) {
    this.loading = value;
    this.loadingChange.emit(value);
  }

  async _imagesChanged(images: any) {
    if (!Array.isArray(images)) {
      console.warn(
        '<mg-progressive-image> requires images to be an array. Got:',
        images,
      );
      return;
    }

    const loadId = ++this._loadId;

    const checkLoadId = () => loadId === this._loadId;

    const doSequentialImagesLoad = async () => {
      let hasSetImage = false;
      const setImage = (img: string) => {
        hasSetImage = true;
        this._setImage(img);
      };

      for (let src of images) {
        if (checkLoadId()) {
          if (!loadImageCache.has(src)) {
            await loadImage(src);
            setImage(src);
          }
        }
      }

      if (!hasSetImage) {
        setImage(images[images.length - 1] || '');
      }
    };

    const doRaceImagesLoad = async () => {
      const src = await Promise.race(
        images.map(async src => {
          if (checkLoadId()) {
            await loadImage(src);
          }

          return src;
        }),
      );

      if (checkLoadId()) {
        this._setImage(src);
      }
    };

    const doParallelImagesLoad = async () => {
      let activeIndex = -1;

      await Promise.all(
        images.map(async (src, index) => {
          if (!checkLoadId()) {
            return;
          }

          await loadImage(src);
          if (index > activeIndex) {
            this._setImage(src);
            activeIndex = index;
          }
        }),
      );
    };

    this._setLoading(true);

    try {
      switch (this.mode) {
        case 'parallel':
          await doParallelImagesLoad();
          break;
        case 'race':
          await doRaceImagesLoad();
          break;
        default:
          console.warn(
            `<mg-progressive-image> mode not set or invalid. The allowed modes are 'sequential', 'race', or 'parallel'. Defaulting to 'sequential'.`,
          );
        case 'sequential':
          await doSequentialImagesLoad();
          break;
      }

      if (checkLoadId()) {
        this._setLoading(false);
        // If we've reached the end and our checkLoadId passed we can re use
        // our existing load id.
        this._loadId = loadId;
      }
    } catch (err) {
      if (checkLoadId()) {
        this._setLoading(false);
      }
      throw err;
    }
  }
}
