import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlexTimeActivityInstance } from 'minga/domain/flexTime';

import { Schedule } from '@modules/tools/student-tools';
import { StudentToolsFlexTimeService } from '@modules/tools/student-tools/services';

import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import {
  FlexTimeActivityInstanceService,
  FlexTimePermissionsService,
} from '@shared/services/flex-time';
import { FlexTimeRegistrationValidationService } from '@shared/services/flex-time/flex-time-registration-validation.service';

import {
  ActivityCardFields,
  FtmSharedMessages,
} from '../../ftm-shared.constants';

@Component({
  selector: 'mg-ftm-activites-student-scheduler',
  templateUrl: './ftm-activites-student-scheduler.component.html',
  styleUrls: ['./ftm-activites-student-scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmActivitesStudentSchedulerComponent {
  @Input() schedule: Schedule;
  @Input() personHash: string;
  @Output() activityRegistered = new EventEmitter<FlexTimeActivityInstance>();
  public MESSAGES = FtmSharedMessages;
  public CARD_FIELDS = [
    ActivityCardFields.NAME,
    ActivityCardFields.DESCRIPTION,
    ActivityCardFields.LOCATION,
    ActivityCardFields.TEACHER,
  ];

  private _periodRegistrationInProgess = new BehaviorSubject<number>(null);
  public periodRegistrationInProgess$ =
    this._periodRegistrationInProgess.asObservable();
  public isProfileView$ = this._route.paramMap.pipe(
    map(params => params.has('hash')),
  );

  constructor(
    private _route: ActivatedRoute,
    private _systemAlertModal: SystemAlertModalService,
    private _stFlexTimeService: StudentToolsFlexTimeService,
    private _flexActivityInstanceService: FlexTimeActivityInstanceService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _flexTimePerms: FlexTimePermissionsService,
    private _flexRegService: FlexTimeRegistrationValidationService,
  ) {}

  public async openActivities(
    periodId: number,
    currentActivity?: FlexTimeActivityInstance,
  ) {
    const currentActivityId = currentActivity?.id || null;

    try {
      const activityInstance =
        await this._flexActivityInstanceService.openActivitySelector({
          periodId,
          currentActivityId,
          selectorType: 'register',
        });

      this._periodRegistrationInProgess.next(periodId);
      if (!this._flexTimePerms.isFlexTimeAdmin()) {
        // emit here bc _flexRegService has its own confirm modal
        await this._stFlexTimeService.registerForActivity(activityInstance.id);
        this.activityRegistered.emit(activityInstance);
      } else {
        await this._flexRegService.register({
          activityId: activityInstance.id,
          periodId,
          bypassRestrictions: false,
          hashes: [this.personHash],
        });
      }
    } catch (e) {
      const error = e as Error;
      const systemAlertModal = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.ERROR,
        heading: 'Cannot register for activity',
        message: error.message,
        closeBtn: 'Close',
      });
      await systemAlertModal.afterClosed().toPromise();
    } finally {
      this._periodRegistrationInProgess.next(null);
    }
  }

  public async confirmChange(
    periodId: number,
    currentActivity: FlexTimeActivityInstance,
  ) {
    const systemAlertModal = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: FtmSharedMessages.CHANGE_CONFIRM_TITLE,
      message: FtmSharedMessages.CHANGE_CONFIRM_DESC,
      confirmActionBtn: FtmSharedMessages.CHANGE_CONFIRM_BTN,
      closeBtn: FtmSharedMessages.CHANGE_CLOSE_BTN,
    });

    systemAlertModal.afterClosed().subscribe(response => {
      if (response?.type === SystemAlertCloseEvents.CONFIRM) {
        this.openActivities(periodId, currentActivity);
      }
    });
  }
}
