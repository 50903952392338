import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { PointReward } from 'minga/domain/points';

import { PeopleSelectorService, PsData } from '@modules/people-selector';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { PointsService } from '@shared/services/points';

import { RedeemPointsModalComponent } from '../redeem-points-modal.component';
import {
  RedeemPointsModalData,
  RedeemPointsModalResponse,
  RedeemPointsModalStudent,
} from '../types';

@Injectable({ providedIn: 'root' })
export class RedeemPointsModalService implements OnDestroy {
  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);
  private readonly _isLoading = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading
    .asObservable()
    .pipe(shareReplay());

  /** Student Profile */
  private readonly _student = new BehaviorSubject<RedeemPointsModalStudent>(
    undefined,
  );
  public readonly student$ = this._student.asObservable().pipe(shareReplay());

  /** Reward Types */
  private readonly _rewardTypes = new BehaviorSubject<PointReward[]>([]);
  public readonly rewardTypes$ = this._rewardTypes
    .asObservable()
    .pipe(shareReplay());

  /** Service Constructor */
  constructor(
    private _modalOverlay: ModalOverlayService<
      RedeemPointsModalResponse,
      RedeemPointsModalData
    >,
    private _points: PointsService,
    private _systemAlertModal: SystemAlertModalService,
    private _peopleSelector: PeopleSelectorService,
  ) {}

  ngOnDestroy(): void {
    this._isLoading.complete();
    this._student.complete();
    this._rewardTypes.complete();
    this._destroyed.complete();
  }

  public async fetchInitialData(person: PsData): Promise<void> {
    this._isLoading.next(true);
    const rewardTypes = await this._points.fetchRewardTypes(true);
    this._rewardTypes.next(rewardTypes);
    if (person.personHash) {
      const points = await this._getPoints(person.personHash);
      this._student.next({
        ...person,
        points,
      });
    }
    this._isLoading.next(false);
  }

  public getStudentDetails() {
    return this._student.getValue();
  }

  public openPeopleSelector() {
    this._peopleSelector.open('Points Reward', 'redeem', {
      data: {},
    });
  }

  private async _getPoints(
    personHash: string,
  ): Promise<RedeemPointsModalStudent['points']> {
    const response = await this._points.fetchRedeemablePoints(personHash);
    return response;
  }

  public async openModal(
    person?: PsData,
    rewardItemIds?: number[],
  ): Promise<void> {
    const redeemModal = await this._modalOverlay.open(
      RedeemPointsModalComponent,
      {
        data: { person, rewardItemIds },
        disposeOnNavigation: false,
      },
    );
    const result = await redeemModal.afterClosed.toPromise();
    if (!result) return;
    const { type, data } = result;
    if (type === ModalOverlayServiceCloseEventType.SUBMIT) {
      const systemAlertModal = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.SUCCESS,
        heading: 'Redeem Points',
        message: `Success! You've redeemed ${data?.pointsRedeemed} points for ${data?.personDisplayName}`,
        hangTime: SystemAlertModalHangTime.LONG,
      });
      await systemAlertModal.afterClosed().toPromise();
    }
  }
}
