import { Pipe, PipeTransform } from '@angular/core';

import { DELIVERY_TYPES } from '../constants';

@Pipe({ name: 'deliveryTypeLabel' })
export class DeliveryTypeLabelPipe implements PipeTransform {
  transform(deliveryType: string[]): string {
    return (deliveryType || [])
      .map(type => DELIVERY_TYPES[type])
      .filter(type => type)
      .join(', ');
  }
}
