import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import * as hammerjs from 'hammerjs';
import * as PinchZoomJs from 'pinch-zoom-js';

@Directive({
  selector: '[mgPinchZoom]',
  exportAs: 'mgPinchZoom',
})
export class MgPinchZoomDirective implements OnDestroy {
  private _hmjs: any;
  private _scale: number = 0;
  private _originX: number = 0;
  private _originY: number = 0;

  @Input('mgPinchZoomPinchTarget')
  pinchTarget: any;

  constructor(private element: ElementRef) {}

  delayedReset() {
    setTimeout(() => {}, 600);
  }

  reset() {
    this._scale = 1;
    this._originX = 0;
    this._originY = 0;
  }

  updateTransform() {
    const element: HTMLElement = this.element.nativeElement;
    element.style.transform = `
      translate(${this._originX}px, ${this._originY}px)
      scale(${this._scale})
    `;
  }

  private _getPinchTarget(): HTMLElement | null {
    if (this.pinchTarget) {
      if (this.pinchTarget.nativeElement) {
        return this.pinchTarget.nativeElement;
      } else if (this.pinchTarget.element) {
        return this.pinchTarget.element.nativeElement;
      }
    } else if (this.element) {
      return this.element.nativeElement;
    }

    return null;
  }

  private _initHammerJs() {
    const element = this._getPinchTarget();

    if (!element) {
      return;
    }

    const pinch = new hammerjs.Pinch({});

    this._hmjs = new hammerjs.Manager(element, {});

    this._hmjs.add([pinch]);

    const handlHmjsEvent = e => {
      this._scale = Math.min(4, Math.max(1, e.scale));
      this._originX = e.deltaX;
      this._originY = e.deltaY;
      this.updateTransform();
    };

    this._hmjs.on('pinch', handlHmjsEvent);
    this._hmjs.on('pinchcancel pinchend', () => {
      this.reset();
      this.updateTransform();
    });
  }

  ngAfterViewInit() {
    this._initHammerJs();
  }

  ngOnDestroy() {
    this._hmjs.destroy();
  }
}
