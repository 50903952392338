import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { UserListMinimal } from 'minga/domain/userList';

import { SelectGroupOption } from '@shared/components/form/components/form-grouped-select/form-grouped-select.types';
import { CacheService } from '@shared/services/cache/cache.service';
import { CacheKey } from '@shared/services/cache/cache.types';
import { ErrorHandlerService } from '@shared/services/error-handler';
import { UserListService } from '@shared/services/user-list';

import { mapListToOption } from '../utils/user-list.utils';

@Injectable({ providedIn: 'root' })
export class UserListFilterService implements OnDestroy {
  private _destroyedSubject = new ReplaySubject<void>(1);

  private _listsSubject = new BehaviorSubject<UserListMinimal[]>([]);
  public lists$ = this._listsSubject.asObservable();

  private _cachedMyLists = this._cacheService.create<
    SelectGroupOption<number, UserListMinimal>[]
  >(
    CacheKey.USER_LIST_MY_LISTS,
    async data => {
      const lists = await this._userListService.fetchAllMinimal({
        onlyMyLists: true,
      });
      return lists.map(mapListToOption);
    },
    {
      ttl: 60,
    },
  );

  private _cachedAllLists = this._cacheService.create<
    SelectGroupOption<number, UserListMinimal>[]
  >(
    CacheKey.USER_LIST_ALL_LISTS,
    async data => {
      const lists = await this._userListService.fetchAllMinimal({
        onlyMyLists: false,
      });
      return lists.map(mapListToOption);
    },
    {
      ttl: 60,
    },
  );

  constructor(
    private _cacheService: CacheService,
    private _userListService: UserListService,
    private _errorHandler: ErrorHandlerService,
  ) {}

  ngOnDestroy() {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public async getMyLists(): Promise<
    SelectGroupOption<number, UserListMinimal>[]
  > {
    return await this._fetchLists({ onlyMyLists: true });
  }

  public async getMyListsCurrentTerm(): Promise<
    SelectGroupOption<number, UserListMinimal>[]
  > {
    const list = await this._fetchLists({ onlyMyLists: true });
    return list.filter(list => list.data.currentTerm);
  }
  public async getAllListsCurrentTerm(): Promise<
    SelectGroupOption<number, UserListMinimal>[]
  > {
    const list = await this._fetchLists({ onlyMyLists: false });
    return list.filter(list => list.data.currentTerm);
  }
  public async getAllLists(): Promise<
    SelectGroupOption<number, UserListMinimal>[]
  > {
    return await this._fetchLists({ onlyMyLists: false });
  }

  public clearCache() {
    this._cachedMyLists.clear();
    this._cachedAllLists.clear();
  }

  private async _fetchLists(opts?: {
    revalidate?: boolean;
    onlyMyLists: boolean;
  }) {
    const cache = opts?.onlyMyLists
      ? this._cachedMyLists
      : this._cachedAllLists;

    try {
      return await cache
        .get({ onlyMyLists: opts?.onlyMyLists }, opts)
        .toPromise();
    } catch (e) {
      this._errorHandler.gateWayError('Failed to fetch user lists', e, true);
    }
  }
}
