<mg-report-table
  matSort
  statusReference="behavior"
  [dataSourceService]="ds"
  [selectable]="canMultiSelect$ | async"
  [actionService]="actionService"
  [selectionMessage]="actionService.getConsequenceSelectionMessage()"
  (matSortChange)="ds.sort($event)">
  <mg-role-column [datasource]="ds"></mg-role-column>
  <mg-archive-column [datasource]="ds"></mg-archive-column>
  <mg-edit-column [datasource]="ds"></mg-edit-column>
  <ng-template #actionBar>
    <mg-btn
      *ngIf="
        actionService.getConsNotComplete() > 0 &&
        actionService.isSelectionValid()
      "
      variant="text"
      icon="mg-checkmark"
      iconSet="minga"
      (click)="actionService.markAsComplete()">
      {{ MESSAGES.MARK_COMPLETE_LABEL }}
    </mg-btn>
    <mg-btn
      *ngIf="
        actionService.getConsComplete() > 0 && actionService.isSelectionValid()
      "
      variant="text"
      icon="mg-checkmark"
      iconSet="minga"
      (click)="actionService.markAsNotComplete()">
      {{ MESSAGES.MARK_NOT_COMPLETE_LABEL }}
    </mg-btn>
    <mg-btn
      *ngIf="actionService.isSelectionValid()"
      variant="text"
      icon="mg-archive"
      iconSet="minga"
      (click)="actionService.deleteConsequence()">
      {{ MESSAGES.ARCHIVE_LABEL }}
    </mg-btn>
  </ng-template>
</mg-report-table>
