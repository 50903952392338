<!-- Overlay Modal Container -->
<mg-overlay-primary
  size="auto"
  overlayTitle="{{ MESSAGES.MODAL_TITLE }}"
  [skipLoadCheck]="true">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainTemplate>
  <main [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <ng-container *ngIf="reason$ | async as reason">
      <span
        class="reason-icon"
        [style.color]="reason.color">
        <mg-icon [iconName]="reason.icon"></mg-icon>
      </span>
      <div
        class="reason-name"
        [style.color]="reason.color">
        {{ reason.name }}
      </div>
      <mg-text
        variant="header-md"
        fontWeight="400">
        {{ MESSAGES.DESCRIPTION }}
      </mg-text>
    </ng-container>
  </main>
</ng-template>

<!-- Footer Content Templat -->
<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-generic-button
        colorTheme="red"
        variant="clear"
        paddingSize="none"
        [hasRipples]="false"
        [isRounded]="false"
        (onClick)="cancel()">
        {{ MESSAGES.BUTTON_LABEL_CANCEL }}
      </mg-generic-button>
    </div>
    <div>
      <mg-generic-button
        colorTheme="blue"
        (onClick)="submit()">
        {{ MESSAGES.BUTTON_LABEL_CONFIRM }}
      </mg-generic-button>
    </div>
  </footer>
</ng-template>
