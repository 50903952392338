import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, shareReplay, takeUntil } from 'rxjs/operators';

import { mingaSettingTypes } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { BehaviorManagerService } from '@modules/behavior-manager/services';
import { LayoutService } from '@modules/layout/services';

import {
  BehaviorManagerMessages,
  BehaviorManagerRoutes,
  BehaviorsSubRoutes,
} from '../../constants';
import { BmTypesMessages } from './constants';
import {
  BmTypesAutomationService,
  BmTypesBehaviorService,
  BmTypesConsequenceService,
} from './services';

// can be removed when legacy theme support ends
const getConfigFromRoute = (route: string) => {
  const [, childSegment = ''] = route.split(
    `/${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.VIEW}`,
  );

  let key = BehaviorsSubRoutes.BEHAVIORS;
  if (childSegment.includes(BehaviorsSubRoutes.BEHAVIORS))
    key = BehaviorsSubRoutes.BEHAVIORS;
  if (childSegment.includes(BehaviorsSubRoutes.AUTOMATIONS))
    key = BehaviorsSubRoutes.AUTOMATIONS;
  if (childSegment.includes(BehaviorsSubRoutes.CONSEQUENCES))
    key = BehaviorsSubRoutes.CONSEQUENCES;

  return SUB_ROUTE_CONFIG[getRoute(key)];
};

// can be removed when legacy theme support ends
const getRoute = sub =>
  `/${BehaviorManagerRoutes.ROOT}/${BehaviorManagerRoutes.VIEW}/${sub}`;

const SUB_ROUTE_CONFIG = {
  [getRoute(BehaviorsSubRoutes.BEHAVIORS)]: {
    title: 'Behaviors',
  },
  [getRoute(BehaviorsSubRoutes.CONSEQUENCES)]: {
    title: 'Consequences',
  },
  [getRoute(BehaviorsSubRoutes.AUTOMATIONS)]: {
    title: 'Automations',
  },
};

@Component({
  selector: 'mg-bm-types',
  templateUrl: './bm-types.component.html',
  styleUrls: ['./bm-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmTypesComponent implements OnDestroy, OnInit {
  /** Constants */
  public readonly BM_MESSAGES = BehaviorManagerMessages;
  public readonly MESSAGES = BmTypesMessages;
  public readonly TAB_ROUTES = {
    [BehaviorsSubRoutes.BEHAVIORS]: {
      path: getRoute(BehaviorsSubRoutes.BEHAVIORS),
      tab: 'Behaviors',
    },
    [BehaviorsSubRoutes.CONSEQUENCES]: {
      path: getRoute(BehaviorsSubRoutes.CONSEQUENCES),
      tab: 'Consequences',
    },
    [BehaviorsSubRoutes.AUTOMATIONS]: {
      path: getRoute(BehaviorsSubRoutes.AUTOMATIONS),
      tab: 'Automations',
    },
  };
  public TYPES = BehaviorsSubRoutes;

  /** Active tab */
  public activeTab = getRoute(
    this._route.snapshot.firstChild?.url[0]?.path ||
      BehaviorsSubRoutes.BEHAVIORS,
  );

  /** Observables */
  private _destroyed$ = new ReplaySubject<void>(undefined);

  /** Settings */
  public readonly areConsequencesEnabled$ =
    this._settingsService.getSettingValueObs(
      mingaSettingTypes.BM_CONSEQUENCE_ENABLE,
    );

  /** Page title */
  private _pageTitle = new BehaviorSubject(
    SUB_ROUTE_CONFIG[
      getRoute(this._route.snapshot.firstChild?.url[0]?.path)
    ]?.title,
  );
  public pageTitle$ = this._pageTitle.asObservable().pipe(shareReplay());

  /** Component Constructor */
  constructor(
    public typesBehavior: BmTypesBehaviorService,
    public typesConsequence: BmTypesConsequenceService,
    public bmService: BehaviorManagerService,
    public automations: BmTypesAutomationService,
    private _settingsService: MingaSettingsService,
    private _router: Router,
    private _route: ActivatedRoute,
    public layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    // can be removed when legacy theme support ends
    this._router.events
      .pipe(
        takeUntil(this._destroyed$),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        this.activeTab = event.urlAfterRedirects;

        const pageTitle =
          getConfigFromRoute(event.urlAfterRedirects)?.title || 'Behaviors';
        this._pageTitle.next(pageTitle);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
