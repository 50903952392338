import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'mg-layout-legacy-content',
  templateUrl: './layout-legacy-content.component.html',
  styleUrls: ['./layout-legacy-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutLegacyContentComponent {
  @ContentChild('action', { read: TemplateRef })
  action: TemplateRef<any>;
}
