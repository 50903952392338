<mg-sr-filters-wrap
  [form]="form"
  [filters]="filters$ | async">
  <ng-container *ngIf="form.get(PARENT_FIELDS.REPORT_FILTERS)">
    <mg-multi-person-search
      *ngIf="
        form.get(getFilterGroupControlKey(FIELDS.ISSUED_TO)) &&
          defaultIssuedHashes$ | async
      "
      (selectionChanged)="onPersonSelected($event)"
      [defaultValue]="defaultIssuedHashes$ | async">
    </mg-multi-person-search>

    <mg-user-list-filter
      [prefetchData]="[UserListCategory.ALL]"
      [placeholder]="MESSAGES.USER_LIST"
      [value]="form.get(getFilterGroupControlKey(FIELDS.USER_LIST)).value"
      (selectChanged)="
        form.get(getFilterGroupControlKey(FIELDS.USER_LIST)).setValue($event)
      ">
    </mg-user-list-filter>

    <mg-form-select
      [options]="behaviorTypes$ | async"
      [isClearable]="true"
      [multiple]="true"
      [isFullWidth]="true"
      [control]="form.get(getFilterGroupControlKey(FIELDS.TYPE))"
      [value]="form.get(getFilterGroupControlKey(FIELDS.TYPE)).value"
      [placeholder]="MESSAGES.TYPE">
    </mg-form-select>
    <mg-form-select
      [isClearable]="true"
      [options]="gradeOptions$ | async"
      [multiple]="true"
      [isFullWidth]="true"
      [control]="form.get(getFilterGroupControlKey(FIELDS.GRADE))"
      [value]="form.get(getFilterGroupControlKey(FIELDS.GRADE)).value"
      [placeholder]="MESSAGES.GRADE">
    </mg-form-select>
  </ng-container>
</mg-sr-filters-wrap>
