import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'mg-icon',
  templateUrl: './MgIcon.component.html',
  styleUrls: ['./MgIcon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgIconComponent {
  _iconNamespace = 'minga';

  @ViewChild('svgIconName', { static: true })
  svgIconNameEl?: ElementRef;

  @Input() iconName: string;
  @Input() iconColor: string;

  @Input('icon-namespace')
  set namespace(value: string) {
    if (value) {
      this._iconNamespace = value;
    }
  }

  getSvgIconName() {
    if (this.iconName) {
      return `${this._iconNamespace}:${this.iconName}`;
    }
    if (this.svgIconNameEl) {
      const svgIconName = this.svgIconNameEl.nativeElement.textContent.trim();

      if (svgIconName) {
        return `${this._iconNamespace}:${svgIconName}`;
      }
    }

    return '';
  }
}
