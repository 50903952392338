import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { TooltipModule } from '@shared/components/tooltip';

import { MultiSelectRoleComponent } from './MultiSelectRole.component';

@NgModule({
  imports: [
    // Minga dependencies
    TooltipModule,

    // External dependencies
    CommonModule,
    FormsModule,
    MatCheckboxModule,
    FlexLayoutModule,
  ],
  declarations: [MultiSelectRoleComponent],
  exports: [MultiSelectRoleComponent],
})
export class MultiSelectRoleModule {}
