import { NgModule } from '@angular/core';
import { MgSuccessCheckmarkModule } from 'minga/app/src/app/components/SuccessCheckmark';

import { SuccessDialog } from './SuccessDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSuccessCheckmarkModule,

    // External dependencies
  ],
  declarations: [SuccessDialog],
  exports: [SuccessDialog],
})
export class SuccessDialogModule {}
