<mg-nav-link
  [text]="'Home'"
  [titleText]="'Home Feed'"
  [icon]="'home-o'"
  [routerLink]="'/'"
  routerLinkActive="active"
  (click)="restartStream('HomeFeed')"
  [routerLinkActiveOptions]="{ exact: true }">
</mg-nav-link>
<mg-nav-link
  [text]="'Events'"
  [titleText]="'Events Calendar'"
  [icon]="'calendar'"
  [routerLink]="isCommunityEnabled ? '/events' : null"
  [routerLinkActive]="isCommunityEnabled ? 'active' : ''"
  [disabled]="!isCommunityEnabled"
  (click)="restartStream(['UpcomingEvents', 'GoingEvents', 'PastEvents'])">
</mg-nav-link>

<mg-nav-link
  *ngIf="isGroupsEnabled$ | async"
  [text]="'Groups'"
  [titleText]="'Groups'"
  [icon]="'group'"
  [routerLink]="isCommunityEnabled ? '/groups' : null"
  [routerLinkActive]="isCommunityEnabled ? 'active' : ''"
  [disabled]="!isCommunityEnabled">
</mg-nav-link>
<mg-nav-link
  *ngIf="'TEACHER_TOOLS' | hasPermission"
  routerLinkActive="active"
  [icon]="'teacher-tools-o'"
  [text]="'Teacher Tools'"
  routerLink="/tools"
  [titleText]="'Teacher Tools'">
</mg-nav-link>
<mg-nav-link
  *ngIf="'STUDENT_TOOLS' | hasPermission"
  [icon]="'student-id-o'"
  routerLinkActive="active"
  routerLink="/tools"
  [text]="'Student Tools'"
  [titleText]="'Student Tools'">
</mg-nav-link>

<mg-sub-nav
  *ngIf="(canPostContent$ | async) && !currentGroup && isCommunityEnabled"
  [title]="'Add'"
  [icon]="'post'"
  [navIndex]="4"
  class="nav-item-inner nav-item-icon"
  [ngClass]="{
    'ftue-highlight': (ftueKeys.addContent | mgFtueHighlight | async)
  }"
  [highlightKey]="ftueKeys.addContent">
  <mg-nav-link
    *ngIf="'CONTENT_CHALLENGE_CREATE' | hasPermission"
    [text]="'Challenge'"
    [icon]="'challenges-o'"
    (click)="gotoChallengeAdmin()"
    [titleText]="'Create Challenge'">
  </mg-nav-link>
  <mg-nav-link
    *ngIf="'CONTENT_POST_CREATE' | hasPermission"
    [text]="'Post'"
    [icon]="'post'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: ['content', addContentLinkType, 'create', CONTENT_TYPES.POST]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create basic Post'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="'CONTENT_VIDEO_CREATE' | hasPermission"
    [text]="'Video'"
    [icon]="'play-o'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: ['content', addContentLinkType, 'create', CONTENT_TYPES.VIDEO]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create Video'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="
      ('GALLERY_ADD_PHOTO' | hasPermission) && (isPhotoGalleryEnabled$ | async)
    "
    [text]="'Photo to Gallery'"
    [icon]="'photo-gallery-o'"
    [routerLink]="['', { outlets: { o: ['gallery', 'add'] } }]"
    routerLinkActive="active"
    [titleText]="'Upload to Photo Gallery'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="'CONTENT_EVENT_CREATE' | hasPermission"
    [text]="'Event'"
    [icon]="'calendar'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: ['content', addContentLinkType, 'create', CONTENT_TYPES.EVENT]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create Event'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="'CONTENT_ANNOUNCEMENT_CREATE' | hasPermission"
    [text]="'Announcement'"
    [icon]="'announcement-o'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: [
            'content',
            addContentLinkType,
            'create',
            CONTENT_TYPES.ANNOUNCEMENT
          ]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create Announcement'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="'CONTENT_FULL_GRAPHIC_CREATE' | hasPermission"
    [text]="'Full Graphic'"
    [icon]="'post'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: [
            'content',
            addContentLinkType,
            'create',
            CONTENT_TYPES.FULL_GRAPHIC
          ]
        }
      }
    ]"
    routerLinkActive="active">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="'CONTENT_POLL_CREATE' | hasPermission"
    [text]="'Poll'"
    [icon]="'polls-o'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: ['content', addContentLinkType, 'create', CONTENT_TYPES.POLL]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create Poll'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="
      ('CONTENT_SMS_MESSAGE_CREATE' | hasPermission) && (isSMSEnabled$ | async)
    "
    [text]="'SMS Message'"
    [icon]="'polls-o'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: [
            'content',
            addContentLinkType,
            'create',
            CONTENT_TYPES.SMS_MESSAGE
          ]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create SMS Message'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="'APP_BROADCAST_SEND' | hasPermission"
    [text]="'Alert'"
    [icon]="'broadcast-o'"
    [routerLink]="['', { outlets: { o: ['dialog', 'broadcast'] } }]"
    routerLinkActive="active"
    [titleText]="'Create Alert'">
  </mg-nav-link>
</mg-sub-nav>

<mg-sub-nav
  *ngIf="(canPostContent$ | async) && currentGroup"
  [title]="'Add to Group'"
  [header]="currentGroup.name"
  [icon]="'post'"
  class="nav-item-inner nav-item-icon">
  <mg-nav-link
    *ngIf="
      ('GROUP_CONTENT_CHALLENGE_CREATE' | hasPermission) || (isOwner$ | async)
    "
    [text]="'Challenge'"
    [icon]="'challenges-o'"
    (click)="createChallenge('g')"
    routerLinkActive="active"
    [titleText]="'Create Challenge'">
  </mg-nav-link>
  <mg-nav-link
    *ngIf="('GROUP_CONTENT_POST_CREATE' | hasPermission) || (isOwner$ | async)"
    [text]="'Post'"
    [icon]="'post'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: ['content', addContentLinkType, 'create', CONTENT_TYPES.POST]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create a basic Post'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="('GROUP_CONTENT_VIDEO_CREATE' | hasPermission) || (isOwner$ | async)"
    [text]="'Video'"
    [icon]="'play-o'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: ['content', addContentLinkType, 'create', CONTENT_TYPES.VIDEO]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create Video'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="
      ('GROUP_CONTENT_EVENTS_CREATE' | hasPermission) || (isOwner$ | async)
    "
    [text]="'Event'"
    [icon]="'calendar'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: ['content', addContentLinkType, 'create', CONTENT_TYPES.EVENT]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create Event'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="
      ('GROUP_CONTENT_ANNOUNCEMENT_CREATE' | hasPermission) ||
      (isOwner$ | async)
    "
    [text]="'Announcement'"
    [icon]="'announcement-o'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: [
            'content',
            addContentLinkType,
            'create',
            CONTENT_TYPES.ANNOUNCEMENT
          ]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create Announcement'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="'GROUP_CONTENT_FULL_GRAPHIC_CREATE' | hasPermission"
    [text]="'Full Graphic'"
    [icon]="'post'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: [
            'content',
            addContentLinkType,
            'create',
            CONTENT_TYPES.FULL_GRAPHIC
          ]
        }
      }
    ]"
    routerLinkActive="active">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="('GROUP_CONTENT_POLL_CREATE' | hasPermission) || (isOwner$ | async)"
    [text]="'Poll'"
    [icon]="'polls-o'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: ['content', addContentLinkType, 'create', CONTENT_TYPES.POLL]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create Poll'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="
      (('GROUP_CONTENT_SMS_MESSAGE_CREATE' | hasPermission) ||
        (isOwner$ | async)) &&
      (isSMSEnabled$ | async)
    "
    [text]="'SMS Message'"
    [icon]="'polls-o'"
    [routerLink]="[
      '',
      {
        outlets: {
          o: [
            'content',
            addContentLinkType,
            'create',
            CONTENT_TYPES.SMS_MESSAGE
          ]
        }
      }
    ]"
    routerLinkActive="active"
    [titleText]="'Create SMS Message'">
  </mg-nav-link>

  <mg-nav-link
    *ngIf="
      (('MINGA_BLOCKORDER_UPLOAD' | hasPermission) || (isOwner$ | async)) &&
      (isDistrictEnabled$ | async) &&
      (inParentGroup$ | async)
    "
    [text]="'Daily Bulletin'"
    [icon]="'block-order-o'"
    [routerLink]="['', { outlets: { o: ['blockorder'] } }]"
    routerLinkActive="active"
    [titleText]="'Edit school bulletin'">
  </mg-nav-link>
</mg-sub-nav>
