import { ShortFileCardView } from 'minga/proto/gateway/content_views_pb';
import { FileDetails } from 'minga/proto/gateway/file_pb';
import { GroupFiles as GroupFilesMessage } from 'minga/proto/gateway/mingaGroup_pb';

import { IMingaFile, MingaFiles } from 'minga/app/src/app/models/MingaFiles';

export interface GroupFiles {
  hash: string;
  files: IMingaFile[];
}

export interface IGroupFile extends IMingaFile {}

export namespace GroupFiles {
  export function fromMessage(msg: GroupFilesMessage): GroupFiles {
    return {
      hash: msg.getHash(),
      files: msg.getFilesList().map(file => {
        const fileObj = file.toObject();
        return {
          file: fileObj,
          status: { fileKey: getStatusKey(fileObj) },
        };
      }),
    };
  }

  // complete file name with extension
  export function getStatusKey(
    groupFile: FileDetails.AsObject | ShortFileCardView.AsObject,
  ) {
    return MingaFiles.getStatusKey(groupFile);
  }
}
