import { Pipe, PipeTransform } from '@angular/core';

import { find } from 'lodash';

import {
  BehaviorMsgCategory,
  ConsequenceTypeOptionsMapper,
} from '../constants';

@Pipe({ name: 'getConsequenceTypeFromId' })
export class GetConsequenceTypeFromIdPipe implements PipeTransform {
  transform(consequences: any[], consequenceId: any): string | null {
    const matchedConsequence = find(consequences, { value: consequenceId });

    if (matchedConsequence) {
      let options;

      if (matchedConsequence.category === BehaviorMsgCategory.PRAISE) {
        options = ConsequenceTypeOptionsMapper[BehaviorMsgCategory.PRAISE];
      } else {
        options = ConsequenceTypeOptionsMapper[BehaviorMsgCategory.GUIDANCE];
      }

      const matchedOption = find(options, { value: matchedConsequence.type });

      return matchedOption ? matchedOption.label : null;
    }

    return null;
  }
}
