export enum FtmPeriodsMessage {
  FIELD_LABEL_TITLE = 'Title',
  FIELD_LABEL_DATE = 'Date',
  FIELD_LABEL_START_TIME = 'Start time',
  FIELD_LABEL_END_TIME = 'End time',

  FIELD_LABEL_ACTIVITY_MANAGE = 'Allow teachers / staff to add activities',
  FIELD_LABEL_STUDENT_MANAGE = 'Allow teachers / staff to unregister students',
  FIELD_LABEL_STUDENT_SELF_MANAGE = 'Allow students to self-register',
  FIELD_LABEL_STUDENT_SELF_MANAGE_TOOLTIP = 'Students can register to a different activity if they self-registered',
  FIELD_LABEL_CLONE_REGISTERED = 'Clone registered students',
  FIELD_LABEL_CLONE_ASSIGNED = 'Clone assigned students',

  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CANCEL = 'Cancel',
}

export enum FtmPeriodsEditFormFields {
  TITLE = 'title',
  DATE = 'date',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  ACTIVITY_MANAGE = 'enableActivityManagement',
  STUDENT_MANAGE = 'enableStudentManagement',
  STUDENT_SELF_MANAGE = 'enableStudentSelfManagement',
  CLONE_REGISTERED = 'cloneRegistered',
  CLONE_ASSIGNED = 'cloneAssigned',
}
