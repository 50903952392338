<div
  *ngIf="maxlength && value.length > maxlength"
  class="input-error"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px">
  <mg-icon>warning-o</mg-icon>
  <span>You wrote too much content.</span>
</div>

<div class="user-content">
  <mat-form-field
    *ngIf="!richContent"
    floatLabel="auto"
    mgFormField
    hideRequiredMarker>
    <mat-label>{{ placeholder }}</mat-label>
    <mg-textarea
      #bodyInputTextArea="ngModel"
      allowedTags="mg-mention"
      mgMentionable2="inline"
      [maxlength]="maxlength"
      [minHeight]="minHeight"
      [required]="required"
      (ngModelChange)="onValueChange($event)"
      [(ngModel)]="value"
      [ngModelOptions]="{ standalone: true }"></mg-textarea>
    <mat-error
      *ngIf="
        bodyInputTextArea.control?.errors?.required &&
        requiredMessage === undefined
      ">
      {{ 'input.title.error.required' | translate }}
    </mat-error>
    <mat-error
      *ngIf="
        bodyInputTextArea.control?.errors?.required &&
        requiredMessage !== undefined
      ">
      {{ requiredMessage | translate }}
    </mat-error>
    <mat-hint *ngIf="maxlength && showRemainingCountTilMaxLength">
      {{ value.length }} / {{ maxlength }}
    </mat-hint>
  </mat-form-field>

  <mat-form-field
    *ngIf="richContent"
    floatLabel="always"
    mgFormField
    hideRequiredMarker>
    <mat-label>{{ placeholder }}</mat-label>
    <mg-quill-editor
      #bodyInputQuillEditor="ngModel"
      [customToolbar]="customToolbar"
      [customElement]="customElement"
      [style.align-items]="align"
      [required]="required"
      name="body"
      [richContent]="richContent"
      [maxlength]="maxlength"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="value"
      (ngModelChange)="onValueChange($event)"
      [minHeight]="minHeight"
      [resetParentScrollbarElement]="resetParentScrollbarElement">
    </mg-quill-editor>
    <mat-error *ngIf="bodyInputQuillEditor.control?.errors?.required">{{
      'input.title.error.required' | translate
    }}</mat-error>
    <mat-hint *ngIf="maxlength && showRemainingCountTilMaxLength">
      {{ value.length }} / {{ maxlength }}
    </mat-hint>
  </mat-form-field>
</div>
