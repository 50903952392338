import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'mg-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCheckboxComponent {
  public checkedValue = false;
  public indeterminateValue = false;

  @Input()
  set checked(value: boolean) {
    this.checkedValue = value;
  }

  @Input()
  set indeterminate(value: boolean) {
    this.indeterminateValue = value;
  }

  @Input() disabled = false;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  @Output()
  changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('click') onClick() {
    if (this.disabled) return;

    this.changed.emit(!this.checkedValue);
  }

  constructor() {}
}
