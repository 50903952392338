<mg-overlay-primary
  size="small"
  [overlayTitle]="modalData?.title">
  <ng-container>
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  </ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="modalFooterTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<ng-template #mainTemplate> <img [src]="modalData?.imageUrl" /></ng-template>

<ng-template #modalFooterTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <mg-btn
      variant="text"
      (pressed)="closeOverlay()">
      {{ MESSAGES.CLOSE }}
    </mg-btn>
    <mg-btn
      variant="filled"
      (pressed)="printImage()">
      {{ MESSAGES.PRINT }}
    </mg-btn>
  </div>
</ng-template>
