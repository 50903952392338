<mg-legacy-overlay-nav [component]="this"></mg-legacy-overlay-nav>

<div class="poll-card-wrap">
  <mg-poll-short-card
    #pollShortCard
    [showVotes]="'MINGA_POLLS_MANAGE' | hasPermission"
    [showResultsAlways]="
      ignorePermissions
        ? false
        : ('MINGA_POLLS_MANAGE' | hasPermission) || isOwner(group$ | async)
    "
    [context]="contextHash"
    [content]="content">
  </mg-poll-short-card>
</div>
