export enum LandingRoute {
  ROOT = 'landing',
  EMAIL = 'email',
  COUNTRY = 'country',
  CLEVER = 'clever',
  PIN = 'pin',
  PIN_RESET = 'pin/:resetToken',
  JOINING = 'joining',
  PASSWORD = 'password',
  CREATE = 'create',
  CREATE_RESET = 'create/:resetToken',
  FORGOT = 'forgot',
  RESET = 'reset/:resetToken',
  KIOSK_LOGIN = 'kiosk-login',
}

export enum LOGIN_FORM_FIELDS {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export enum LOGIN_MESSAGES {
  SIGN_IN = 'Sign In',
  GOOGLE_SIGN_IN = 'Sign in with Google',
  MICROSOFT_SIGN_IN = 'Sign in with Microsoft',
  CLEVER_SIGN_IN = 'Sign in with Clever',
  SIGN_UP_EXPANDER = 'Sign in with email and password',
  EMAIL_LABEL = 'Email address',
  PASSWORD_LABEL = 'Password',
  SIGN_IN_BTN = 'Next',
  FORGOT_BTN = 'Forgot password?',
  WELCOME_BACK = 'Welcome Back!',
  LOGOUT = 'Logout',
}

export enum KIOSK_FORM_FIELDS {
  EMAIL = 'email',
  PIN = 'pin',
}

export enum KIOSK_MESSAGES {
  TITLE = 'Sign in to Minga kiosk with your minga email and your 6 digits password',
  EMAIL_LABEL = 'Email address',
  PIN_LABEL = '6 digit PIN',
  PIN_ERROR = 'Invalid PIN, you must enter a 6 digit number',
  SIGN_IN_BTN = 'Sign in',
  BACK_BTN = 'Back to Minga sign in',
}
