import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from '../../button';

import { CancelConfirmationDialogComponent } from './CancelConfirmation.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,

    // External dependencies
    CommonModule,
    TranslateModule.forChild(),
  ],
  declarations: [CancelConfirmationDialogComponent],
  exports: [CancelConfirmationDialogComponent],
})
export class CancelConfirmationDialogModule {}
