import {
  Directive,
  Host,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { IStreamSwipeStackItem } from './IStreamSwipeStackItem';
import { StreamSwipeStackComponent } from './StreamSwipeStack.component';

@Directive({
  selector: '[mgStreamSwipeStackItem]',
})
export class StreamSwipeStackItemDirective<T = any>
  implements IStreamSwipeStackItem<T>, OnDestroy
{
  @Input()
  mgStreamSwipeStackItem: any;

  @Input()
  item?: T;

  constructor(
    @Host() private streamSwipeStack: StreamSwipeStackComponent<T>,
    public templateRef: TemplateRef<any>,
    public viewContainer: ViewContainerRef,
  ) {
    this.streamSwipeStack.registerItem(this);
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.streamSwipeStack.unregisterItem(this);
  }
}
