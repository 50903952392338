import { Injectable } from '@angular/core';
import { BreakPoint, MediaMarshaller } from '@angular/flex-layout/core';

// @TODO remove once we get to angular v13+
// hack flex service resolves: https://github.com/angular/flex-layout/issues/1201
@Injectable({
  providedIn: 'root',
})
export class FlexLayoutWorkaround {
  private _appBreakpoints: BreakPoint[];

  constructor(private mediaMarshaller: MediaMarshaller) {}

  public initialize() {
    this._appBreakpoints = (this.mediaMarshaller as any).activatedBreakpoints;

    // listen to any active breakpoint changes and if print is active we need to reset
    // the breakpoints on leave
    // @ts-ignore
    this.mediaMarshaller.subject.subscribe(s => {
      if (
        // @ts-ignore
        this.mediaMarshaller.activatedBreakpoints.some(b => b.alias === 'print')
      ) {
        // @ts-ignore
        this.mediaMarshaller.activatedBreakpoints = [...this._appBreakpoints];
        // @ts-ignore
        this.mediaMarshaller.hook.collectActivations = () => {};
        // @ts-ignore
        this.mediaMarshaller.hook.deactivations = [...this._appBreakpoints];
      }
    });
  }
}
