<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxFlex="grow">
  <mg-text
    variant="body-md"
    [color]="disabled ? 'on-disabled-bg' : 'surface'">
    {{ label }}
  </mg-text>
  <mg-tooltip
    *ngIf="helpText"
    [label]="helpText">
  </mg-tooltip>
</div>
<div fxFlex="noshrink">
  <div
    class="select-wrap"
    [ngClass]="selectSize">
    <mg-form-select
      class="select"
      placeholder="Report Type"
      [value]="initialValue"
      [isFullWidth]="true"
      [disabled]="disabled"
      [closeOnSelect]="true"
      [options]="options"
      [floatingLabel]="false"
      (selectionChanged)="onValueSelect($event)">
    </mg-form-select>
  </div>
</div>
