<mg-overlay-primary
  [isLoading]="false"
  size="auto"
  [overlayTitle]="title$ | async"
  [disableClose]="dialogData?.disableClose">
  <div class="switcher">
    <ng-container *ngIf="mingas$ | async as mingas">
      <button
        *ngFor="let minga of mingas"
        matRipple
        (click)="changeMinga(minga)"
        class="minga">
        <section
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="20px">
          <div
            fxFlex="nogrow"
            class="minga-logo">
            <div
              class="avatar"
              *ngIf="minga?.logo; else mingaLogo"
              [style.background-image]="'url(' + minga.logo + ')'"></div>

            <ng-template #mingaLogo>
              <div
                class="default-avatar avatar"
                [style.background-image]="'url(/assets/Minga_icon.svg)'"></div>
            </ng-template>
          </div>
          <div>
            <mg-text variant="header-sm">{{ minga.name }}</mg-text>
          </div>
        </section>
      </button>
    </ng-container>
  </div>
</mg-overlay-primary>
