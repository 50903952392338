import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from 'minga/app/src/app/button';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { GenericModule } from '@shared/components/generic';

import { PasswordComponent } from './Password.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    MgIconModule,
    MgFormFieldModule,
    MgButtonModule,
    GenericModule,

    // External dependencies
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    CommonModule,
    TranslateModule,
  ],
  declarations: [PasswordComponent],
  exports: [PasswordComponent],
})
export class MgPasswordModule {}
