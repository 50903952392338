import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgButtonModule } from 'minga/app/src/app/button';
import { StepIndicatorModule } from 'minga/app/src/app/components/StepIndicator';
import { FtueVideoModule } from 'minga/app/src/app/ftue/common/FtueVideo';

import { WelcomeOwnerComponent } from './WelcomeOwner.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    FtueVideoModule,
    StepIndicatorModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [WelcomeOwnerComponent],
  exports: [WelcomeOwnerComponent],
})
export class WelcomeOwnerModule {}
