import { Injectable } from '@angular/core';

import * as points_pb from 'minga/proto/points/points_pb';
import { PointReward } from 'minga/domain/points';
import { PointsManager } from 'minga/proto/points/points_ng_grpc_pb';
import { PointRewardMapper } from 'minga/shared-grpc/points';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

@Injectable({ providedIn: 'root' })
export class PointsService {
  /** Service Constructor */
  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _pointsManager: PointsManager,
  ) {}

  public async fetchRewardTypes(activeOnly?: boolean): Promise<PointReward[]> {
    try {
      const result = await this._listRewardTypes(activeOnly);
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error(
        'Failed to fetch point reward types ' + error,
      );
    }
  }

  public async redeemPoints(
    personHash: string,
    points: number,
    rewardItemIds: number[],
  ): Promise<boolean> {
    try {
      const result = await this._redeemPoints(
        personHash,
        points,
        rewardItemIds,
      );
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to redeem points ' + error);
    }
  }

  public async fetchRedeemablePoints(personHash: string): Promise<number> {
    try {
      const result = await this._fetchRedeemablePoints(personHash);
      return result;
    } catch (error) {
      this._systemAlertSnackBar.error(
        'Failed to get Redeemable points ' + error,
      );
    }
  }

  private async _redeemPoints(
    personHash: string,
    points: number,
    rewardItemIds: number[],
  ): Promise<boolean> {
    const request = new points_pb.RedeemPointsRequest();
    request.setPersonHash(personHash);
    request.setPoints(points);
    request.setPointRewardIdsList(rewardItemIds);
    const response = await this._pointsManager.redeemPoints(request);
    return response.getSuccess();
  }

  private async _listRewardTypes(activeOnly?: boolean): Promise<PointReward[]> {
    const request = new points_pb.GetPointRewardsRequest();
    if (activeOnly) request.setActive(activeOnly);
    const response = await this._pointsManager.getPointRewards(request);
    const pointRewardsList = response.getPointRewardsList();
    return pointRewardsList.map(PointRewardMapper.fromProto) as PointReward[];
  }

  private async _fetchRedeemablePoints(personHash: string): Promise<number> {
    const request = new points_pb.GetRedeemablePointsRequest();
    request.setPersonHash(personHash);
    const response = await this._pointsManager.getRedeemablePoints(request);
    return response.getRedeemablePoints();
  }
}
