import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgIconModule } from 'minga/app/src/app/icon';

import { TextModule } from '@shared/components/text';

import {
  MgNotificationBaseElement,
  MgNotificationBaseSlot_Body,
  MgNotificationBaseSlot_Footer,
  MgNotificationBaseSlot_Image,
  MgNotificationBaseSlot_Timestamp,
  MgNotificationBaseSlot_Title,
} from './MgNotificationBase.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgAvatarModule,
    MgIconModule,
    TextModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [
    MgNotificationBaseElement,
    MgNotificationBaseSlot_Body,
    MgNotificationBaseSlot_Image,
    MgNotificationBaseSlot_Timestamp,
    MgNotificationBaseSlot_Title,
    MgNotificationBaseSlot_Footer,
  ],
  exports: [
    MgNotificationBaseElement,
    MgNotificationBaseSlot_Body,
    MgNotificationBaseSlot_Image,
    MgNotificationBaseSlot_Timestamp,
    MgNotificationBaseSlot_Title,
    MgNotificationBaseSlot_Footer,
  ],
})
export class MgNotificationBaseModule {}
