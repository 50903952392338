import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ChallengesRouteService {
  private _originPath = '';
  constructor(private _router: Router) {}

  gotoCreate(
    type: 'm' | 'g' | 'p' = 'm',
    groupHash: string | null,
    disableOrigin: boolean = false,
  ) {
    if (!disableOrigin) {
      this._originPath = this._router.url;
    }
    if (type === 'g' && groupHash) {
      groupHash = '/' + groupHash;
    }
    if (!groupHash) groupHash = '';
    this._router.navigate([
      '/challenge/' + type + groupHash + '/create/type',
      { outlets: { o: null } },
    ]);
  }

  gotoAdminSummary(disableOrigin: boolean = false) {
    if (!disableOrigin) {
      this._originPath = this._router.url;
    }
    this._router.navigate(['/challenge/admin/summary']);
  }

  gotoAdminResponses(challengeId: string) {
    this._router.navigate([
      '/challenge/admin/responses/' + challengeId,
      { outlets: { o: null } },
    ]);
  }

  openChallengePeopleOverlay(challengeId: string) {
    return this._router.navigate([
      '',
      {
        outlets: {
          o: ['challenge', 'challenge~people', challengeId],
        },
      },
    ]);
  }

  returnFromChallenges() {
    if (this._originPath === this._router.url) {
      this._router.navigateByUrl('/');
    } else {
      this._router.navigateByUrl(this._originPath || '/');
    }
  }
}
