<mat-tab-group
  animationDuration="0ms"
  [attr.data-tab-length]="tabLabels.length"
  [selectedIndex]="selectedIndex"
  (selectedIndexChange)="onSelectedIndexChange($event)">
  <mat-tab *ngFor="let tabLabel of tabLabels">
    <ng-template mat-tab-label>
      <span [innerHTML]="tabLabel"></span>
    </ng-template>
  </mat-tab>
</mat-tab-group>
