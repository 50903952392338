import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MingaPermission } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';

import { ManagerOverlayColorTheme } from '@shared/components/manager-overlay';

import { ScheduledReportsHistoryStore } from './components/mm-scheduled-reports/store/scheduled-reports-history.store';
import { ScheduledReportsStore } from './components/mm-scheduled-reports/store/scheduled-reports.store';
import { MingaManagerMessages } from './constants/minga-manager.constants';
import { MingaManagerService } from './services';

/**
 * Minga Manager
 *
 * Used by both Super admins and normal admins to manage Mingas
 */
@Component({
  selector: 'mg-minga-manager',
  templateUrl: './minga-manager.component.html',
  styleUrls: ['./minga-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ScheduledReportsStore, ScheduledReportsHistoryStore],
})
export class MingaManagerComponent implements OnInit {
  /** Constants */
  MESSAGES = MingaManagerMessages;
  colorTheme = ManagerOverlayColorTheme.MINGA_MANGER;

  /** Component Constructor */
  constructor(
    private _router: Router,
    private _mingaManagerService: MingaManagerService,
    private _permissions: PermissionsService,
  ) {}

  ngOnInit() {
    if (this._permissions.hasPermission(MingaPermission.SUPERADMIN)) {
      this._mingaManagerService.fetchData();
    }
  }

  public async returnFromManager() {
    this._router.navigateByUrl('/');
  }
}
