<ng-content select="[mgStreamItem]"></ng-content>
<ng-content select="[mgTableStreamHeader]"></ng-content>

<div class="before-items">
  <ng-content select="[mg-before-stream-items]"></ng-content>
</div>

<virtual-scroller
  #virtualScroller
  (vsEnd)="onLowerThreshold($event)"
  [items]="(items$ | async) || []"
  [bufferAmount]="bufferAmount"
  [enableUnequalChildrenSizes]="true"
  [modifyOverflowStyleOfParentScroll]="false"
  [parentScroll]="parentScroll">
  <table class="mg-table-stream-table">
    <thead>
      <ng-container *ngTemplateOutlet="tableHeaderTemplate"></ng-container>
    </thead>

    <tbody
      class="items mg-table-stream-tbody"
      #container>
      <ng-template
        ngFor
        let-item
        let-viewIndex="viewIndex"
        [ngForOf]="virtualScroller.viewPortItems"
        [ngForTrackBy]="streamItemTrackBy">
        <tr class="item">
          <ng-container
            *ngTemplateOutlet="
              streamItemTemplate;
              context: {
                $implicit: item.item,
                item: item.item,
                itemId: item.itemId,
                viewIndex: viewIndex
              }
            "></ng-container>
        </tr>
      </ng-template>

      <tr class="mg-table-stream-footer">
        <td colspan="999">
          <ng-container [ngSwitch]="stream?.error?.status">
            <div *ngSwitchCase="'GrpcError'">
              <p class="mg-centered">
                There was an issue loading this stream ({{
                  getStreamGrpcErrorCode()
                }})
              </p>
              <a
                href="javascript:;"
                (click)="restartStream()"
                >Retry</a
              >
            </div>
            <div
              *ngSwitchCase="'NetworkError'"
              class="user-content mg-centered network-error">
              <p class="mg-centered">Network Error</p>
              <a
                href="javascript:;"
                (click)="restartStream()"
                >Retry</a
              >
            </div>
          </ng-container>

          <div class="after-items">
            <ng-content select="[mg-after-stream-items]"></ng-content>
          </div>

          <mg-spinner
            *ngIf="loading$ | async"
            diameter="30"
            thickness="4"></mg-spinner>

          <div *ngIf="frontExhausted && !isStreamEmpty()">
            <ng-content select="[mg-stream-front-exhausted]"></ng-content>
          </div>

          <div *ngIf="isStreamEmpty()">
            <ng-content select="[mg-stream-empty]"></ng-content>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</virtual-scroller>
