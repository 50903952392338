import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { EventsFacadeService } from 'minga/app/src/app/events/services';
import {
  GalleryServiceBase,
  IAddGalleryPhotoOptions,
  IDeleteGalleryPhotoOptions,
  IGallerySummaryFetchOptions,
} from 'minga/app/src/app/routes/gallery/services/GalleryServiceBase';
import { ReportService } from 'minga/app/src/app/services/Report';
import { IContentEventMinimal } from 'minga/domain/content';
import { MingaGallery } from 'minga/proto/gateway/gallery_ng_grpc_pb';
import {
  MingaGalleryCreateRequest,
  MingaGalleryDeleteRequest,
  MingaGalleryGetPhotoTotalRequest,
  MingaGallerySummaryRequest,
  MingaGallerySummaryResponse,
  Tag,
} from 'minga/proto/gateway/gallery_pb';

@Injectable({ providedIn: 'root' })
export class EventGalleryService extends GalleryServiceBase {
  private _eventContent?: PromiseLike<IContentEventMinimal | undefined>;

  constructor(
    protected mingaGalleryProto: MingaGallery,
    protected reportService: ReportService,
    private eventsFacade: EventsFacadeService,
  ) {
    super(mingaGalleryProto, reportService);
  }

  setEventContent(eventContent: IContentEventMinimal | undefined) {
    this._eventContent = Promise.resolve(eventContent);
  }

  async setEventFromContextHash(contextHash: string) {
    this._eventContent = this.eventsFacade.fetchEventByContextHash(contextHash);
  }

  private async _getEventContentHash(): Promise<IContentEventMinimal> {
    if (!this._eventContent) {
      throw new Error(`EventGalleryService called without an event`);
    }
    const eventContent = await this._eventContent;

    // const eventContent = await eventContent$.pipe(first()).toPromise();

    if (!eventContent) {
      throw new Error(
        `EventGalleryService could not find content hash for the current event`,
      );
    } else {
      return eventContent;
    }
  }

  async fetchSummary() {
    const eventContent = await this._getEventContentHash();

    const request = new MingaGallerySummaryRequest();
    const wasLoading = this._loading.getValue();
    request.setEventContentHash(eventContent.hash);

    try {
      if (!wasLoading) this._loading.next(true);
      const response = await this.mingaGalleryProto.summary(request);
      this._summary.next(response.toObject());
    } finally {
      if (!wasLoading) this._loading.next(false);
    }
  }

  async getSearchPhotoTotal(search: string): Promise<number> {
    const eventContent = await this._getEventContentHash();

    const request = new MingaGalleryGetPhotoTotalRequest();
    request.setSearchQuery(search);
    request.setEventContentHash(eventContent.hash);

    const response = await this.mingaGalleryProto.getPhotoTotal(request);

    return response.getPhotoTotal();
  }

  async addPhoto(options: IAddGalleryPhotoOptions) {
    return await this._addPhoto(this._summary, options);
  }

  async deletePhoto(options: IDeleteGalleryPhotoOptions): Promise<boolean> {
    return await this._deletePhoto(this._summary, options);
  }

  async resolvePhoto(galleryPhotoUuid: string): Promise<boolean> {
    return await this._resolvePhoto(galleryPhotoUuid);
  }
}
