import { Observable } from 'rxjs';

export abstract class ContentPublishInfoEditComponent<PublishInfo> {
  abstract readonly publishInfoChange$: Observable<Partial<PublishInfo>>;

  /**
   * Set the publish info. May be partial.
   */
  abstract setPublishInfo(publishInfo: Partial<PublishInfo>): void;

  /**
   * Get the current publish info. Do not return partial data.
   */
  abstract getPublishInfo(): PublishInfo;

  /**
   * Return the current publish info allowing for partial data to be returned
   */
  abstract getPartialPublishInfo(): Partial<PublishInfo>;
}
