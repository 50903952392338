<div class="checkbox-wrapper">
  <mat-checkbox
    [indeterminate]="indeterminateValue"
    [disabled]="disabled"
    [checked]="checkedValue"
    [attr.data-analytics]="id"
    [attr.data-test]="id">
  </mat-checkbox>
  <ng-content></ng-content>
</div>
