import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

import { BadgeDialog } from './BadgeDialog.component';
import { IBadgeDialogOptions } from './types';

export type BadgeDialogRef = MatDialogRef<BadgeDialog>;
export type BadgeDialogConfig = MatDialogConfig<IBadgeDialogOptions>;

/**
 * Service to simply open up the badge dialog.
 */
@Injectable({ providedIn: 'root' })
export class BadgeDialogService {
  constructor(private matDialog: MatDialog) {}

  open(config: BadgeDialogConfig): BadgeDialogRef {
    return this.matDialog.open(BadgeDialog, {
      ...config,
    });
  }
}
