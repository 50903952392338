import { Pipe, PipeTransform } from '@angular/core';

import { ColumnInfo } from 'minga/shared/reports_columns';

@Pipe({ name: 'reportTableCellContent' })
export class ReportTableCellContentPipe implements PipeTransform {
  transform(item: any, column: ColumnInfo) {
    return column?.accessorFn
      ? column?.accessorFn(item) || column?.defaultValue || '—'
      : item[column.key] || column?.defaultValue || '—';
  }
}
