import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IConsequence } from 'minga/domain/consequences';
import { IPbisAutomationCounterResult, IPbisBehavior } from 'minga/domain/pbis';
import { RootService } from 'src/app/minimal/services/RootService';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { AutomationService } from '@shared/services/automation';
import { ReportActionService } from '@shared/services/reports';

import { BmReportsAutoCounterResetComponent } from '../components/bm-reports-auto-counter/components/bm-reports-auto-counter-reset/bm-reports-auto-counter-reset.component';
import { BmReportsMessages } from '../constants';
import { BmReportsService } from './bm-reports.service';

@Injectable()
export class BmReportActionService
  extends ReportActionService<
    IPbisBehavior | IConsequence | IPbisAutomationCounterResult
  >
  implements OnDestroy
{
  private _destroyed = new ReplaySubject<void>(1);

  private _toggleComplete = new BehaviorSubject<{
    ids: number[];
    complete: boolean;
  }>({ ids: [], complete: false });
  public readonly toggleComplete = this._toggleComplete.asObservable();

  constructor(
    public snackbar: SystemAlertSnackBarService,
    public alertModal: SystemAlertModalService,
    private _rootService: RootService,
    private _bmReportsService: BmReportsService,
    private _automationService: AutomationService,
    private _modalOverlayService: ModalOverlayService,
  ) {
    super(snackbar, alertModal);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  /** Abstract methods */
  public setTotalSelectable(total: number): void {
    this.totalSelectable = total;
  }

  /** Action methods */
  public async deleteBehavior(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    try {
      const confirmed = await this.openAlertModal(
        BmReportsMessages.BEHAVIOR_ARCHIVE_HEADING,
        BmReportsMessages.BEHAVIOR_ARCHIVE_MESSAGE,
        BmReportsMessages.BEHAVIOR_ARCHIVE_LABEL,
      );
      if (confirmed) {
        const data = this.getSelection() as IPbisBehavior[];
        await this._bmReportsService.deleteBehavior(data);
      }
      this._rootService.addLoadingPromise(this._clearSelectionAndRefetch());
      return true;
    } catch (e) {
      this.openSnackBar(`Unable to archive behavior(s): ${e}`, 'error');
      return false;
    }
  }

  public async deleteConsequence(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    try {
      const confirmed = await this.openAlertModal(
        BmReportsMessages.CONSEQUENCE_ARCHIVE_HEADING,
        '',
        BmReportsMessages.BEHAVIOR_ARCHIVE_LABEL,
      );

      if (confirmed) {
        const data = this.getSelection() as IConsequence[];
        await this._bmReportsService.deleteConsequence(data);
      }
      this._rootService.addLoadingPromise(this._clearSelectionAndRefetch());
      return true;
    } catch (e) {
      this.openSnackBar(`Unable to archive consequence(s): ${e}`, 'error');
      return false;
    }
  }

  public async markAsComplete(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    try {
      const data = this.getSelection() as IConsequence[];
      if (!data[0] || typeof data[0]?.id !== 'number') {
        this.openSnackBar('Failed to mark consequence(s) as complete', 'error');
        return false;
      }
      const consIds = data
        .filter(consequence => !consequence.complete)
        .map(consequence => consequence.id);
      await this._bmReportsService.completeConsequence(consIds, false);
      this._toggleComplete.next({ ids: consIds, complete: true });
      return true;
    } catch (e) {
      this.openSnackBar(
        `Failed to mark consequence(s) as complete: ${e}`,
        'error',
      );
      return false;
    }
  }

  public async markAsNotComplete(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    try {
      const data = this.getSelection() as IConsequence[];
      if (!data[0] || typeof data[0]?.id !== 'number') {
        this.openSnackBar(
          'Failed to mark consequence(s) as not complete',
          'error',
        );
        return false;
      }
      const confirmed = await this.openAlertModal(
        BmReportsMessages.CONSEQUENCE_REACTIVATE_HEADING,
        BmReportsMessages.CONSEQUENCE_REACTIVATE_MESSAGE,
        BmReportsMessages.CONSEQUENCE_REACTIVATE_LABEL,
      );
      if (confirmed) {
        const consIds = data
          .filter(consequence => consequence.complete)
          .map(consequence => consequence.id);
        await this._bmReportsService.completeConsequence(consIds, true);
        this._toggleComplete.next({ ids: consIds, complete: false });
      }
      return true;
    } catch (e) {
      this.openSnackBar(
        `Failed to mark consequence(s) as not complete: ${e}`,
        'error',
      );
      return false;
    }
  }

  public async resetAutomationGroupCounter(): Promise<boolean> {
    const ref = this._modalOverlayService.open(
      BmReportsAutoCounterResetComponent,
    );
    const response = await ref.afterClosed
      .pipe(takeUntil(this._destroyed))
      .toPromise();
    if (response.data) {
      const count = response.data.counterValue;
      const data = this.getSelection() as IPbisAutomationCounterResult[];
      await this._automationService.resetReportAutomations(data, count);
      return true;
    }
    return false;
  }

  public getConsComplete(): number {
    const data = this.getSelection() as IConsequence[];
    return data.filter(consequence => consequence.complete).length;
  }

  public getConsNotComplete(): number {
    const data = this.getSelection() as IConsequence[];
    return data.filter(consequence => !consequence.complete).length;
  }

  public getBehaviorSelectionMessage(): string {
    const data = this.getSelection() as IPbisBehavior[];
    return !this.isEmpty() ? `${data.length} selected` : '';
  }

  public getConsequenceSelectionMessage(): string {
    if (this.isEmpty) return '';
    const complete = this.getConsComplete();
    const notComplete = this.getConsNotComplete();

    let message = '';
    if (complete > 0 && notComplete > 0)
      message = `${complete} completed and ${notComplete} not completed selected`;
    else if (complete > 0) message = `${complete} completed selected`;
    else if (notComplete > 0) message = `${notComplete} not completed selected`;

    return message;
  }

  private async _clearSelectionAndRefetch() {
    this._bmReportsService.applyFilter();
    this.clearSelection();
  }
}
