import { RouterOutletOption } from '@shared/constants';

import { GlobalSearchPortalComponent } from '../components/global-search-portal.component';
import { GLOBAL_SEARCH_ROUTES } from '../constants';
import { SearchConfig, SearchRoute } from '../types';

export const getGlobalSearchRoutes = (
  routes: SearchConfig = GLOBAL_SEARCH_ROUTES,
) => {
  return Object.entries(routes).map(([scope, config]) => {
    const route = {
      ...(config?.routeConfig || {}),
      path: scope,
      outlet: RouterOutletOption.SEARCH,
      component:
        config.type === 'component'
          ? config.component
          : GlobalSearchPortalComponent,
      data: {
        ...(config?.routeConfig?.data || {}),
        title: scope,
        description: config.description,
        inputLabel: config.inputLabel,
        noResults: config?.noResults ?? undefined,
        navigationVisibility: false,
      },
    } as SearchRoute;
    return route;
  });
};
