<div class="mg-poll-mini-card">
  <div class="content content-block">
    <div
      class="poll-type"
      [ngClass]="'poll-layout-'+pollLayoutName"></div>
    <div
      class="poll-title-container"
      [ngClass]="'has-group-' + hasGroup">
      <div
        class="question mg-minicopy"
        [innerHTML]="content.question"></div>
      <div
        class="group-link-container"
        (click)="groupLinkClick($event)">
        <span class="group-link-text"
          >Posted in
          <span class="group-link">{{(group$ | async)?.name}}</span></span
        >
      </div>
    </div>
  </div>
  <div class="footer content-block">
    <div class="poll-dates">
      <ng-container *ngIf="pollClosed">
        <ng-container *ngIf="content.closeTimestamp">
          Closed: <strong>{{dateFormat(content.closeTimestamp)}}</strong>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!pollClosed">
        Open: {{dateFormat(content.timestamp)}}
        <ng-container *ngIf="content.closeTimestamp">
          - Closes:
          <strong>{{dateFormat(content.closeTimestamp)}}</strong>
        </ng-container>
      </ng-container>
    </div>
    <div class="poll-votes">
      <span class="poll-votes-count">{{content.pollResults?.totalCount}}</span
      >&nbsp;<span class="poll-votes-text">
        {{_votesText(content.pollResults?.totalCount)}}</span
      >
    </div>
    <div class="poll-answered">
      <mge-icon icon="checkmark"></mge-icon>
    </div>
    <div class="poll-status"></div>
  </div>
</div>
