<div class="user-content">
  <mg-button-icon
    icon="close"
    class="mg-no-padding"
    (click)="dialogRef.close(false)"></mg-button-icon>
  <div class="mg-headline2 confirm-title">Delete Image</div>
  <div class="mg-bodycopy">
    Are you sure you want to delete this image? (this action cannot be undone)
  </div>
  <div class="btns">
    <mg-button (click)="dialogRef.close(true)">Delete</mg-button>
    <mg-button
      type="outline"
      (click)="dialogRef.close(false)"
      >Cancel</mg-button
    >
  </div>
</div>
