import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mg-stay-leave-dialog',
  templateUrl: './StayLeaveDialog.component.html',
  styleUrls: ['./StayLeaveDialog.component.scss'],
})
export class StayLeaveDialogComponent {
  constructor(private dialogRef: MatDialogRef<StayLeaveDialogComponent>) {}

  stay() {
    this.dialogRef.close(true);
  }

  leave() {
    this.dialogRef.close(false);
  }
}
