import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgAvatarModule } from 'src/app/elements/MgAvatar';

import { TextModule } from '@shared/components/text';
import { CommonUIModules } from '@shared/modules';

import { UserProfileHeaderModule } from '../user-profile-header';
import { UserPictureOverlayComponent } from './user-picture-overlay.component';

@NgModule({
  imports: [
    // Common modules
    CommonUIModules,

    // Minga dependencies
    MgAvatarModule,
    TextModule,
    UserProfileHeaderModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [UserPictureOverlayComponent],
  exports: [RouterModule, UserPictureOverlayComponent],
})
export class UserPictureOverlayModule {}
