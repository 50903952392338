import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  ClippedHtmlComponent,
  ClippedHtmlSlot_Head,
  ClippedHtmlSlot_Tail,
} from './ClippedHtml.component';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    CommonModule,
  ],
  declarations: [
    ClippedHtmlComponent,
    ClippedHtmlSlot_Head,
    ClippedHtmlSlot_Tail,
  ],
  exports: [ClippedHtmlComponent, ClippedHtmlSlot_Head, ClippedHtmlSlot_Tail],
})
export class ClippedHtmlModule {}
