<ng-template
  #itemTemplate
  let-item
  let-dataObs="dataObs">
  <ng-template #loadingTpl>
    <ng-container *ngFor="let loading of _loadings">
      <ng-container *ngTemplateOutlet="loading.templateRef"></ng-container>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="dataObs | async as data; else loadingTpl">
    <ng-template #noDataTpl>
      <div>No Data</div>
    </ng-template>

    <ng-container *ngIf="data; else noDataTpl">
      <ng-container
        *ngTemplateOutlet="
          item.templateRef;
          context: { $implicit: data }
        "></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<mg-swipe-stack
  *ngIf="dataSource"
  [cyclic]="cyclic"
  [stackIndex]="stackIndex"
  (stackIndexChange)="onStackIndexChange($event)"
  [stackSize]="stackSize"
  (swipeStackTap)="swipeStackTap.emit($event)">
  <div class="item-container">
    <ng-template #currentTpl>
      <ng-container *ngFor="let item of _items">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              $implicit: item,
              dataObs: dataSource.currentItemData$
            }
          "></ng-container>
      </ng-container>
    </ng-template>

    <ng-container *ngIf="showingHead; else currentTpl">
      <ng-container *ngFor="let head of _heads">
        <ng-container *ngTemplateOutlet="head.templateRef"></ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div
    mgSwipeStackPreview
    class="item-container">
    <ng-template #previewTpl>
      <ng-template #shouldShowPreviewTpl>
        <ng-container *ngFor="let item of _items">
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: item,
                dataObs: dataSource.previewItemData$
              }
            "></ng-container>
        </ng-container>
      </ng-template>

      <ng-container *ngIf="showingHead; else shouldShowPreviewTpl">
        <ng-container *ngFor="let item of _items">
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: item,
                dataObs: dataSource.currentItemData$
              }
            "></ng-container>
        </ng-container>
      </ng-container>
    </ng-template>

    <ng-container *ngIf="showingHeadPreview; else previewTpl">
      <ng-container *ngFor="let head of _heads">
        <ng-container *ngTemplateOutlet="head.templateRef"></ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div
    mgSwipeStackPreviewPlaceholder
    class="item-container">
    <ng-template #previewPlaceholderTpl>
      <ng-container *ngFor="let item of _items">
        <ng-container
          *ngTemplateOutlet="
            itemTemplate;
            context: {
              $implicit: item,
              dataObs: dataSource?.previewPlaceholderItemData$
            }
          "></ng-container>
      </ng-container>
    </ng-template>

    <ng-container
      *ngIf="showingHeadPreviewPlaceholder; else previewPlaceholderTpl">
      <ng-container *ngFor="let head of _heads">
        <ng-container *ngTemplateOutlet="head.templateRef"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</mg-swipe-stack>
