import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { PeopleSearchInputModule } from 'src/app/components/people-search-input';
import { MgIconModule } from 'src/app/icon';
import { PermissionsModule } from 'src/app/permissions';
import { MgSpinnerModule } from 'src/app/spinner';

import { GenericButtonModule } from '@shared/components/button';
import { CollapsibleModule } from '@shared/components/collapsible';
import { FormModule } from '@shared/components/form';
import { FormRestrictionModule } from '@shared/components/form-restriction-input/form-restriction-input.module';
import { FormImageSelectModule } from '@shared/components/form/components/form-image-select/form-image-select.module';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { OverlayModule } from '@shared/components/modal-overlay';
import { SystemAlertModule } from '@shared/components/system-alert';
import { TextModule } from '@shared/components/text/text.module';
import { TooltipModule } from '@shared/components/tooltip';

import { FtmSharedModule } from '../ftm-shared/ftm-shared.module';
import { FtmAtEditComponent } from './components/ftm-at-edit/ftm-at-edit.component';
import { FtmActivityTemplatesComponent } from './ftm-activity-templates.component';

@NgModule({
  declarations: [FtmActivityTemplatesComponent, FtmAtEditComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MgSpinnerModule,
    MgEmptyStateModule,
    FlexModule,
    OverlayModule,
    FormModule,
    ReactiveFormsModule,
    ManagerOverlayModule,
    FormImageSelectModule,
    MgIconModule,
    MatSlideToggleModule,
    TextModule,
    PeopleSearchInputModule,
    FormRestrictionModule,
    FtmSharedModule,
    PermissionsModule,
    CollapsibleModule,
    GenericModule,
    GenericButtonModule,
    TooltipModule,
    SystemAlertModule,
  ],
})
export class FtmActivityTemplatesModule {}
