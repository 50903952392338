import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mg-keep-discard-dialog',
  templateUrl: './KeepDiscardDialog.component.html',
  styleUrls: ['./KeepDiscardDialog.component.scss'],
})
export class KeepDiscardDialogComponent {
  @Input()
  disableSaveButton: boolean = false;

  constructor(private dialogRef: MatDialogRef<KeepDiscardDialogComponent>) {}

  keep() {
    this.dialogRef.close('keep');
  }

  discard() {
    this.dialogRef.close('discard');
  }

  close() {
    this.dialogRef.close();
  }
}
