import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { BackDropColor } from '@shared/components/modal-overlay';

import { SystemAlertModalComponent } from '../system-alert-modal.component';
import { SystemAlertModalData, SystemAlertModalResponse } from '../types';

@Injectable({ providedIn: 'root' })
export class SystemAlertModalService {
  /** Service Constructor */
  constructor(private _dialog: MatDialog) {}

  public async open(options: SystemAlertModalData) {
    const ref = this._dialog.open<
      SystemAlertModalComponent,
      SystemAlertModalData,
      SystemAlertModalResponse
    >(SystemAlertModalComponent, {
      data: options,
      disableClose: options.disableClose ?? false,
      backdropClass: BackDropColor.DARK_BLUE,
    });

    // Close the alert modal after the specified period of time.
    if (options.closeTimeout) {
      setTimeout(() => ref.close(), options.closeTimeout);
    }

    return ref;
  }

  // closes all of the open dialogs even if they were opened in other components/routes
  public async closeAll() {
    this._dialog.closeAll();
  }
}
