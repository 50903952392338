<ng-container *ngIf="loading$ | async; else previewContentTpl">
  <div class="loading-wrap photo-gallery-style-container">
    <ng-content select="mg-photo-gallery-preview-loading"></ng-content>
  </div>
</ng-container>

<ng-template #previewContentTpl>
  <ng-container *ngIf="hasNewPhotos$ | async; else noPhotosTpl">
    <div class="photo-gallery-style-container photos-wrap">
      <div
        class="new-photos-count"
        *ngIf="newPhotosCountDisplay$ | async">
        {{ newPhotosCountDisplay$ | async }}
      </div>
      <mg-fit-grid
        [rowHeight]="_rowHeight"
        [maxAspectRatio]="maxAspectRatio$ | async"
        [minAspectRatio]="minAspectRatio$ | async">
        <div
          *ngFor="let tile of newestPhotosList$ | async; trackBy: trackBy"
          mgFitGridTile
          [tileWidth]="getTileWidth(tile)"
          [tileHeight]="getTileHeight(tile)">
          <mg-image
            class="tile-image"
            [srcs]="
              tile.imageInfo
                | mgImageUrls
                  : ['blurloading1', ['bannerlibpreview', 'cardbanner']]
            ">
          </mg-image>
        </div>
      </mg-fit-grid>
    </div>
  </ng-container>

  <ng-template #noPhotosTpl>
    <div
      class="empty-wrap photo-gallery-style-container"
      [style.max-height.px]="_rowHeight"
      [style.min-height.px]="_rowHeight">
      <ng-content select="mg-photo-gallery-preview-empty"></ng-content>
    </div>
  </ng-template>
</ng-template>
