import { IMingaFeatureToggleKeys } from 'minga/domain/featureToggle';
import { MingaPermission } from 'minga/util';

import { ModalOverlayPrimaryHeaderBackground } from '@shared/components/modal-overlay';

import { SaAssignOverlayConfig, SaSelectorTypes } from '../types';

export const DEFAULT_ROUTE = '/landing/email';

export const NAVIGATION_STATE_ASSIGN_TYPE_KEY =
  'selection-assigner-assign-type-state';
export const NAVIGATION_STATE_SELF_ASSIGN_KEY =
  'selection-assigner-self-assign-state';

export enum SelectionAssignerRoutes {
  ROOT = 'selection-assigner',
  HALL_PASS = 'hall-pass',
  BEHAVIOR = 'behavior',
  CHECK_IN = 'checkin',
}

// If using a button toggle group within the modal,
// define a tabbed header config (ex. behaviorModalConfig)
const behaviorModalConfig: SaAssignOverlayConfig = {
  formTitle: 'Assign Behavior',
  formHeaderBg: ModalOverlayPrimaryHeaderBackground.GREEN,
  tabbedHeader: true,
  data: [
    {
      carouselTitle: 'Select a behavior',
      selector: 'praise',
      title: 'Praise',
      icon: 'praise',
      color: '#4F9680', // @TODO: these colors should be pulled from our color system
      filterField: false,
      filterInfo: {
        filterPlaceholder: '',
        filterEmptyHeading: 'No Praises found',
        filterEmptyMsg: 'Active Praises will appear here',
      },
    },
    {
      carouselTitle: 'Select a behavior',
      selector: 'guidance',
      title: 'Guidance',
      icon: 'guidance',
      color: '#D45B6C',
      filterField: false,
      filterInfo: {
        filterPlaceholder: '',
        filterEmptyHeading: 'No Guidances found',
        filterEmptyMsg: 'Active Guidances will appear here',
      },
    },
    {
      carouselTitle: 'Select a consequence',
      selector: 'consequence',
      title: 'Consequence',
      icon: 'consequence',
      color: '#132C59',
      filterField: false,
      filterInfo: {
        filterPlaceholder: '',
        filterEmptyHeading: 'No Consequences found',
        filterEmptyMsg: 'Active Consequences will appear here',
      },
    },
  ],
};

const checkinModalConfig: SaAssignOverlayConfig = {
  formTitle: 'Assign Check In',
  formHeaderBg: ModalOverlayPrimaryHeaderBackground.TEAL,
  tabbedHeader: true,
  data: [
    {
      carouselTitle: 'Select a check in',
      selector: 'checkin',
      title: 'Check In',
      icon: 'mg-check-in',
      color: '#00A0BB',
      filterField: false,
      filterInfo: {
        filterPlaceholder: '',
        filterEmptyHeading: 'No Check in reasons found',
        filterEmptyMsg: 'Active Check in reasons will appear here',
      },
    },
    {
      carouselTitle: 'Select a flex activity',
      selector: 'flex-activity',
      title: 'Flex Activity',
      icon: 'mg-flex-activity',
      color: '#21a28e',
      filterField: true,
      filterInfo: {
        filterPlaceholder: 'FlexTime Period',
        filterEmptyHeading: 'No Activities found',
        filterEmptyMsg:
          "Looks like you don't have any activities to check in users to",
      },
      permissions: [
        MingaPermission.FLEX_TIME_MANAGE,
        MingaPermission.FLEX_TIME_REGISTER_FOR_ACTIVITY,
        MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
      ],
      requiredModule: IMingaFeatureToggleKeys.FLEX_TIME_ENABLED,
    },
  ],
};

/** Add any additional modal configurations to this list */
export const SELECTION_ASSIGNER_MODAL_CONFIG: Record<
  SaSelectorTypes,
  SaAssignOverlayConfig
> = {
  ['hall-pass']: {
    formTitle: 'Assign Hall Pass',
    selfAssignTitle: 'Create Hall Pass',
    formHeaderBg: ModalOverlayPrimaryHeaderBackground.PINK,
    tabbedHeader: false,
    data: {
      carouselTitle: 'Select a pass type',
      filterField: false,
      filterInfo: {
        filterPlaceholder: '',
        filterEmptyHeading: 'No Hall Pass available',
        filterEmptyMsg: 'Active Hall Pass types will appear here',
      },
    },
  },
  ['praise']: { ...behaviorModalConfig },
  ['guidance']: { ...behaviorModalConfig },
  ['consequence']: { ...behaviorModalConfig },
  ['checkin']: { ...checkinModalConfig },
  ['flex-activity']: { ...checkinModalConfig },
};
