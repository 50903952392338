import * as localforage from 'localforage';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { buildEnvironment } from 'minga/app/src/environment/build';
import { firebase } from 'minga/app/src/firebase';

import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import {
  FirebaseMessage,
  FirebaseMessaging,
  FirebaseMessagingPermissionStatus,
  IFirebaseNotificationMessage,
} from '../FirebaseMessaging';

function convertBrowserPermission(
  perm: NotificationPermission,
): FirebaseMessagingPermissionStatus {
  switch (perm) {
    case 'denied':
      return 'denied';
    case 'granted':
      return 'granted';
    case 'default':
      return 'unknown';
  }
}

export class FirebaseMessagingWeb extends FirebaseMessaging {
  /** @internal this token is purely for deleteCurrentToken() - do not use */
  private _lastToken: string = '';
  /** @internal */
  private readonly _messageReceived = new Subject<FirebaseMessage>();
  /** @internal */
  private readonly _tokenRefresh = new Subject<void>();
  private readonly _notificationClicked =
    new BehaviorSubject<IFirebaseNotificationMessage | null>(null);

  readonly name = 'Web';
  readonly messageReceived$ = this._messageReceived.asObservable();
  readonly tokenRefresh$ = this._tokenRefresh.asObservable();
  readonly notificationClicked$ = this._notificationClicked.pipe(
    filter(not => !!not),
    map(not => not as IFirebaseNotificationMessage),
  );

  constructor(private _kioskPermissions: KioskPermissionsService) {
    super();
  }

  async init() {
    if (!firebase.messaging.isSupported()) {
      throw new Error('Using FirebaseMessagingWeb on unsupported platform');
    }

    if (buildEnvironment.production) {
      navigator.serviceWorker.addEventListener('message', ev => {
        if (ev.data?.msgType === 'fbMsgWebNotificationClick') {
          this._notificationClicked.next(ev.data.payload);
        }
      });
      navigator.serviceWorker.ready.then(reg => {
        firebase.messaging().useServiceWorker(reg);
        navigator.serviceWorker.controller?.postMessage({
          msgType: 'fbMsgWebReady',
        });
      });
    }

    firebase.messaging().onMessage(msg => {
      console.log('received push notification', msg);
      this._messageReceived.next({
        ...msg,
        type: msg.notification ? 'notification' : 'data',
      });
    });

    firebase.messaging().onTokenRefresh(() => {
      this._tokenRefresh.next();
    });

    // we want to disable all notifications for users in kiosk mode
    this._kioskPermissions.isUserInKioskModeObs().subscribe(inKioskMode => {
      if (inKioskMode) {
        this.deleteCurrentToken();
      }
    });
  }

  clearLastNotificationClicked() {
    this._notificationClicked.next(null);
  }

  async deleteCurrentToken(): Promise<void> {
    const lastToken = this._lastToken;
    if (lastToken) {
      this._lastToken = '';
      await firebase.messaging().deleteToken(lastToken);
    }
  }

  async getToken(): Promise<string> {
    if (!window.Notification) return '';
    if (window.Notification.permission !== 'granted') return '';

    // There is an issue causing the first call to getToken to trigger an error
    // when notification permissions are reset.
    // https://github.com/firebase/firebase-js-sdk/issues/2364
    const { error, token } = await this.tryGetToken();
    if (error) {
      if (error.code === 'messaging/token-unsubscribe-failed') {
        const { error, token } = await this.tryGetToken();
        if (!error && token) return token;
      }
      throw error;
    } else {
      return token;
    }
  }

  async getDeviceId(): Promise<string> {
    const storedFauxDeviceId = await localforage.getItem<any>('fauxDeviceId');
    if (storedFauxDeviceId && typeof storedFauxDeviceId === 'string') {
      return storedFauxDeviceId;
    }

    const fauxDeviceId = uuidv4();
    await localforage.setItem('fauxDeviceId', fauxDeviceId);
    return fauxDeviceId;
  }

  async getPermissionStatus(): Promise<FirebaseMessagingPermissionStatus> {
    if (window.Notification) {
      return convertBrowserPermission(window.Notification.permission);
    }

    // @NOTE: If you're hitting here you probably shouldn't be using this
    // service on this platform.
    return 'denied';
  }

  async requestPermission(): Promise<FirebaseMessagingPermissionStatus> {
    if ((await this.getPermissionStatus()) === 'granted') {
      return 'granted';
    }

    if (window.Notification) {
      const perm = await window.Notification.requestPermission();
      if (perm === 'granted') {
        this._tokenRefresh.next();
      }
      return convertBrowserPermission(perm);
    }

    // @NOTE: If you're hitting here you probably shouldn't be using this
    // service on this platform.
    return 'denied';
  }

  private async tryGetToken(): Promise<{ error: any; token: string }> {
    try {
      const token = await firebase.messaging().getToken();
      this._lastToken = token;
      return { error: null, token };
    } catch (error) {
      return { error, token: '' };
    }
  }
}
