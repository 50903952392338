import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import {
  MingaActions,
  setActiveMingaSubscription,
  setCurrentMinga,
  setMingaCollection,
  updateSettings,
} from 'minga/app/src/app/store/Minga/actions';
import { MingaMinimalModel } from 'minga/domain';
import { IMingaSubscription } from 'minga/domain/minga';

export interface MingaState {
  mingaCollection: MingaCollectionState;
}
export interface MingaCollectionState extends EntityState<MingaMinimalModel> {
  currentMingaHash: string;
  activeMingaSubscription: IMingaSubscription | null;
}

export const adapter = createEntityAdapter<MingaMinimalModel>({
  selectId: (minga: MingaMinimalModel) => minga.hash,
});

export const initialState: MingaCollectionState = adapter.getInitialState({
  // additional entity state properties
  currentMingaHash: '',
  activeMingaSubscription: null,
});

const _mingaReducer = createReducer(
  initialState,
  on(setMingaCollection, (state, action) => ({
    ...adapter.upsertMany(action.payload, state),
  })),
  on(setCurrentMinga, (state, action) => ({
    ...state,
    currentMingaHash: action.payload.hash,
  })),
  on(setActiveMingaSubscription, (state, action) => ({
    ...state,
    activeMingaSubscription: action.mingaSubscription,
  })),
  on(updateSettings, (state, action) => ({
    ...adapter.updateOne(action.payload, state),
  })),
);

export function mingaReducer(
  state: MingaCollectionState | undefined,
  action: MingaActions,
): MingaCollectionState {
  return _mingaReducer(state, action);
}
