<div class="banner">
  <mg-icon class="star-icon">star-active</mg-icon>
  <div class="points-heading">
    +{{ points }}{{ points === 1 ? 'pt' : 'pts' }}
  </div>
</div>
<div class="ribbon">
  <div class="ribbon-left"></div>
  <div class="ribbon-right"></div>
</div>
