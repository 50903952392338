export enum CheckinManagerReasonsTableMessages {
  COLUMN_LABEL_REASON = 'Reason',
  COLUMN_LABEL_STATUS = 'Active',
  COLUMN_LABEL_EDIT = 'Edit',

  CATEGORY_CHECKIN = 'Check In',

  SNACK_UPDATE_SUCCESS = 'Reason Updated Successfully',
  SNACK_DELETE_SUCCESS = 'Reason Deleted Successfully',
  SNACK_CREATE_SUCCESS = 'Reason Created Successfully',
  SNACK_UPDATE_FAIL = 'Error Updating Reason',

  NO_RESULTS_TITLE = 'No check in / check out reasons exist',
  NO_RESULTS_MESSAGE = 'Create a new reason to get started.',
}

export const DISPLAYED_COLUMNS: string[] = ['icon', 'name', 'edit', 'active'];

export const MOBILE_DISPLAYED_COLUMNS: string[] = ['mobile', 'edit'];
