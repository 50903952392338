import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { MgButtonModule } from 'minga/app/src/app/button';
import { AddressModule } from 'minga/app/src/app/components/Input/Address';
import { MgEmailModule } from 'minga/app/src/app/components/Input/Email';
import { FileInputModule } from 'minga/app/src/app/components/Input/FileInput';
import { FileUploadModule } from 'minga/app/src/app/components/Input/FileUpload';
import { MingaNameModule } from 'minga/app/src/app/components/Input/MingaName';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgDateTimePickerModule } from 'minga/app/src/app/date-time-picker';
import { SaveCancelDialogModule } from 'minga/app/src/app/dialog/SaveCancel';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { DestroyMingaDialogModule } from 'minga/app/src/app/routes/sadmin/minga/DestroyMingaDialog';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgPipesModule } from 'src/app/pipes';

import { CollapsibleModule } from '@shared/components/collapsible';
import { FormModule } from '@shared/components/form';
import { MingaPartnerSearchDirective } from '@shared/components/form/directives/minga-partner-search.directive';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import {
  ModalOverlayService,
  OverlayModule,
} from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text';
import { ArrayPipesModule } from '@shared/pipes';

import { MmDashboardEditComponent } from './components/mm-dashboard-edit/mm-dashboard-edit.component';
import { MmDashboardModulesComponent } from './components/mm-dashboard-modules/mm-dashboard-modules.component';
import { MmDashboardTableComponent } from './components/mm-dashboard-table/mm-dashboard-table.component';
import { MmDashboardComponent } from './mm-dashboard.component';
import {
  MmDashboardFeatureToggleService,
  MmDashboardFiltersService,
  MmDashboardService,
  MmDashboardTableService,
} from './services';

@NgModule({
  imports: [
    // Minga dependencies
    MgEmptyStateModule,
    ManagerOverlayModule,
    CollapsibleModule,
    MgSpinnerModule,
    FormModule,
    MgIconModule,
    OverlayModule,
    PermissionsModule,
    MgPipesModule,
    MgImageModule,
    MgButtonModule,
    MgAvatarModule,
    MgDateTimePickerModule,
    MgEmailModule,
    AddressModule,
    MingaNameModule,
    FileUploadModule,
    FileInputModule,
    MgFormFieldModule,
    DestroyMingaDialogModule,
    SaveCancelDialogModule,
    MgOverlayToolsModule,
    ArrayPipesModule,
    GenericModule,
    TextModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
    MatTableModule,
    MatSortModule,
    FlexLayoutModule,
    MatPaginatorModule,
    NgScrollbarModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatExpansionModule,
    TranslateModule,
    MatFormFieldModule,
    MatIconModule,
    MatTooltipModule,
  ],
  declarations: [
    MmDashboardComponent,
    MmDashboardTableComponent,
    MmDashboardEditComponent,
    MmDashboardModulesComponent,
  ],
  providers: [
    MmDashboardService,
    MmDashboardFiltersService,
    MmDashboardTableService,
    ModalOverlayService,
    MingaPartnerSearchDirective,
    MmDashboardFeatureToggleService,
  ],
  exports: [MmDashboardComponent, MmDashboardModulesComponent],
})
export class MmDashboardModule {}
