import { Location } from '@angular/common';
import { Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import * as mgUtil from 'minga/util';
import { PreventNavigation } from 'minga/app/src/app/guards';
import { MgModalService } from 'minga/app/src/app/minimal/services/MgModal';
import {
  IOverlayPreviousStepper,
  OnOverlayClose,
} from 'minga/app/src/app/misc/overlay';
import { ReportService } from 'minga/app/src/app/services/Report';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export const DEFAULT_CUSTOM_REPORT_OPTION = 99;

@Component({
  selector: 'mg-report-content-form',
  templateUrl: './ReportForm.component.html',
  styleUrls: ['./ReportForm.component.scss'],
})
export class ReportFormComponent
  implements PreventNavigation, OnOverlayClose, IOverlayPreviousStepper
{
  contextHash: string;
  galleryPhotoUuid: string;

  @ViewChild('form', { read: NgForm, static: true })
  form: NgForm;

  customOption: number = DEFAULT_CUSTOM_REPORT_OPTION;
  customOptionText: string = mgUtil.ReportReason.toString(
    mgUtil.ReportReason.CUSTOM,
  );

  // Note: when submitting the indexing will start at 1
  reportMessages: string[] = mgUtil.ReportReason.getStringArray(false);

  optionSelected = 1;
  optionCustom: string;
  loading: boolean;

  @ViewChild('successOverlayTemplate', { static: true })
  successOverlayTemplate: TemplateRef<ReportFormComponent>;

  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _location: Location,
    private _mgModal: MgModalService,
    private _reportService: ReportService,
  ) {
    this.setupRouterListener();
  }

  setupRouterListener() {
    this._route.params.subscribe(params => {
      if ('hash' in params) {
        this.contextHash = params.hash;
      }
      if ('photoUuid' in params) {
        this.galleryPhotoUuid = params.photoUuid;
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(e: BeforeUnloadEvent) {
    const message = this.preventNavigation();

    if (message) {
      e.returnValue = message;
      return message;
    }
  }

  preventNavigation(): string {
    if (this.form.dirty || this.form.pending) {
      return 'You have unsaved changes. Are you sure you want to leave?';
    }

    return '';
  }

  hasOverlayPrevious() {
    return {
      icon: 'back',
      text: 'Cancel',
    };
  }

  async onOverlayPrevious() {
    await this.onOverlayClose();
  }

  async onOverlayClose() {
    this._location.back();

    return true;
  }

  onFormKeyPress(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.submit();
    }
  }

  async submit() {
    if (!this.optionSelected) {
      this._systemAlertSnackBar.warning(
        `You must provide a reason to report this content.`,
      );
      return;
    }
    this.loading = true;

    let customMessage = '';
    // grab any relevant comments
    if (
      this.optionSelected - 1 >= this.reportMessages.length &&
      this.optionCustom
    ) {
      customMessage = this.optionCustom;
    }

    // convert index to match message array
    const reasonIndex =
      this.optionSelected === this.customOption
        ? DEFAULT_CUSTOM_REPORT_OPTION
        : this.optionSelected;

    const success = await this._reportService.submitNew(
      {
        contextHash: this.contextHash,
        galleryPhotoUuid: this.galleryPhotoUuid,
      },
      reasonIndex,
      customMessage,
    );

    if (!success) {
      this._systemAlertSnackBar.error(
        `an error occurred while sending your report`,
      );

      this.loading = false;
    } else {
      await this._submitSuccess();
    }
  }

  private async _submitSuccess() {
    const dialogRef = this._mgModal.open(this.successOverlayTemplate, {
      full: true,
      animation: 'fade',
    });

    setTimeout(() => dialogRef.close(), 2000);
    // Wait for the animation to start a tiny bit before heading to the
    // create page.
    await new Promise(resolve => setTimeout(resolve, 1000));

    await this._router.navigate(['', { outlets: { o: null } }]);
  }
}
