import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

import { BackDropColor } from '@shared/components/modal-overlay';

import { BottomSheetConfig } from '../bottom-sheet.types';

const DEFAULT_CONFIG: MatBottomSheetConfig = {
  backdropClass: BackDropColor.DARK_BLUE,
};

@Injectable({ providedIn: 'root' })
export class BottomSheetService<
  Data extends object = any,
  ResponseData extends object = any,
> {
  constructor(private _bottomSheet: MatBottomSheet) {}

  public open<Component extends ComponentType<any>>(
    component: Component,
    config?: BottomSheetConfig<Data>,
  ): MatBottomSheetRef<Component, ResponseData> {
    const finalConfig = { ...DEFAULT_CONFIG, ...(config || {}) };
    return this._bottomSheet.open(component, finalConfig);
  }
}
