import { ICordovaSplashScreen } from './splashscreen.types';

const _getNavigatorSplashscreen = (): ICordovaSplashScreen | null => {
  return (navigator && (navigator as any).splashscreen) || null;
};

/**
 * Hides the cordova splashscreen from 'cordova-plugin-splashscreen'
 */
export const hideCordovaSplashscreen = () => {
  const splashscreen = _getNavigatorSplashscreen();
  if (splashscreen) splashscreen.hide();
  else if (!window.MINGA_APP_BROWSER) {
    console.warn(
      `hideCordovaSplashscreen called without navigator.splashscreen set`,
    );
  }
};

/**
 * Shows the cordova splashscreen from 'cordova-plugin-splashscreen'
 */
export const showCordovaSplashscreen = () => {
  const splashscreen = _getNavigatorSplashscreen();
  if (splashscreen) splashscreen.show();
  else if (!window.MINGA_APP_BROWSER) {
    console.warn(
      `showCordovaSplashscreen called without navigator.splashscreen set`,
    );
  }
};
