<mg-short-card
  #shortCard
  *ngIf="content"
  [featuredImage]="content?.featuredImage"
  [authorPersonView]="content?.authorPersonView"
  [timestamp]="content?.timestamp"
  [title]="content?.title"
  [body]="content?.body"
  [likeCount]="content?.likeCount"
  [ownerGroupHash]="content?.ownerGroupHash"
  [banner]="content?.banner"
  [commentList]="content?.commentList"
  [commentCount]="content?.commentCount"
  [totalCommentCount]="content?.totalCommentCount"
  [pinned]="pinned"
  [markKeywords]="markKeywords"
  [commentRoles]="commentRoles"
  [context]="context"
  (groupNameChange)="onGroupNameChange($event)"
  [content]="content">
  <mg-short-card-featured-image>
    <ng-container *ngIf="content.points && content.points > 0">
      <mg-points-banner [points]="content.points"></mg-points-banner>
    </ng-container>
    <div
      class="banner"
      #bannerContainer>
      <div
        class="date-container"
        [class.cancelled-text]="content.cancelled">
        <ng-container *ngIf="!isEventOver(); else pastDate">
          <p class="date-day-week">{{ getDateComponent({weekday: 'long'}) }}</p>
        </ng-container>
        <p class="date-month">{{ getDateComponent({month: 'short'}) }}</p>
        <p class="date-day {{longtime}}">{{time}}</p>
        <div class="date-divider"></div>
        <p class="date-time">{{ getStartTime() }}</p>
      </div>
      <ng-template #pastDate>
        <p class="past-event-text">PAST EVENT</p>
      </ng-template>
      <mg-image
        [srcs]="shortCard.featuredImage | mgImageUrls:shortCard.imageSizes"
        class="banner-image"></mg-image>
      <div
        class="banner-count-display"
        [ngClass]="'banner-count-' + shortCard.moreImageCount()">
        +{{shortCard.moreImageCount()}}
      </div>
      <div
        class="banner-gradient"
        [ngStyle]="shortCard._bannerGradientStyle(1)"></div>
      <div
        class="banner-gradient banner-back-gradient"
        [ngStyle]="shortCard._bannerGradientStyle(0.85)"></div>
    </div>
  </mg-short-card-featured-image>

  <mg-short-card-title></mg-short-card-title>
  <mg-short-card-byline></mg-short-card-byline>

  <mg-short-card-body>
    <div class="event-info-container">
      <div
        class="title"
        [innerHTML]="shortCard.title | mgSafeHtml"></div>
      <div class="location-ticket-row">
        <div class="location-container">
          <ng-container *ngIf="content.location">
            <mg-icon class="location-icon">location-icon</mg-icon>
            <p class="location">{{ content.location }}</p>
          </ng-container>
        </div>
        <div
          fxLayout="row"
          class="ticket-container">
          <ng-container *ngIf="content.hasTickets">
            <p class="ticket-text">TICKETS</p>
            <div class="ticket-icon"></div>
            <p
              class="ticket-price"
              *ngIf="!!content.ticketPrice">
              {{content.ticketPrice | currency:'USD'}}
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="content.allowGoing && !content.cancelled">
      <div class="going-wrapper">
        <ng-container *ngIf="!isEventOver(); else pastEvent">
          <mg-event-going-state
            *ngIf="context"
            [content]="content"
            [context]="context"></mg-event-going-state>
        </ng-container>
        <ng-template #pastEvent>
          <mg-button
            *ngIf="(isPhotoGalleryEnabled$ | async) && ('GALLERY_ADD_PHOTO' | hasPermission)"
            type="outline"
            class="past-event-button">
            <span class="status-text">Add Photos</span>
          </mg-button>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="content.cancelled">
      <div class="cancelled-wrapper">
        <p class="cancelled">Event Cancelled</p>
      </div>
    </ng-container>
  </mg-short-card-body>

  <mg-short-card-group-name></mg-short-card-group-name>
  <mg-short-card-comment-list></mg-short-card-comment-list>

  <mg-short-card-action-bar>
    <div
      class="group"
      *ngIf="groupName">
      <div class="group-icon"></div>
      <p class="group-text">Posted to: {{groupName}}</p>
    </div>
  </mg-short-card-action-bar>
</mg-short-card>
