import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CheckinManagerReasonsDatasourceService } from '../../services';

@Component({
  selector: 'mg-checkin-manager-report-reasons',
  template: `
    <mg-report-table
      matSort
      [dataSourceService]="ds"
      (matSortChange)="ds.sort($event)">
      <mg-role-column [datasource]="ds"></mg-role-column>
    </mg-report-table>
  `,
  styleUrls: ['../checkin-manager-report.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CheckinManagerReasonsDatasourceService],
})
export class CheckinManagerReportReasonsComponent {
  constructor(public ds: CheckinManagerReasonsDatasourceService) {}
}
