import { NgModule } from '@angular/core';

import { MgPipesModule } from 'minga/app/src/app/pipes';

import { MgNotificationBaseModule } from '../MgNotificationBase';
import { MgPersonTitleNotificationElement } from './MgPersonTitleNotification.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgNotificationBaseModule,
    MgPipesModule,

    // External dependencies
  ],
  declarations: [MgPersonTitleNotificationElement],
  exports: [MgPersonTitleNotificationElement],
})
export class MgPersonTitleNotificationModule {}
