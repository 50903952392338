import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, Observable } from 'rxjs';

import { MgModalService } from 'minga/app/src/app/minimal/services/MgModal';
import { confetti } from 'minga/app/src/app/util/canvasConfetti';

import { IPointsDialogOptions } from './types';

/**
 * This dialog will auto open and close a points overlay. Optionally you
 * can pass in text and/or showConfetti to customize the dialog's text &
 * confetti.
 */
@Component({
  selector: 'mg-points-dialog',
  templateUrl: './PointsDialog.component.html',
  styleUrls: ['./PointsDialog.component.scss'],
})
export class PointsDialog implements OnInit {
  pointsText: string = 'POINTS SENT';
  pointsBody: string = '';
  showConfetti: boolean = false;

  // observable passed in to register whether the animation is complete
  finished$: BehaviorSubject<boolean> | null = null;

  @ViewChild('pointsOverlayTemplate', { static: true })
  pointsOverlayTemplate?: TemplateRef<PointsDialog>;

  constructor(
    private dialogRef: MatDialogRef<PointsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IPointsDialogOptions,
    private modalService: MgModalService,
  ) {
    if (data['text']) {
      this.pointsText = data['text'];
    }
    if (data['body']) {
      this.pointsBody = data['body'];
    }
    this.showConfetti = !!data['showConfetti'];
    if (data['finished$']) {
      this.finished$ = data['finished$'];
    }
  }

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(() => {
      this.showPointsAnimation();
    });

    this.close();
  }

  async showPointsAnimation() {
    if (!this.pointsOverlayTemplate) {
      throw new Error('this.pointsOverlayTemplate unset');
    }

    let dialogRef = this.modalService.open(this.pointsOverlayTemplate, {
      full: true,
      animation: 'fade',
      backgroundColor: 'rgba(255,255,255,0.85)',
    });

    if (this.showConfetti) {
      this.createConfetti();
    }

    setTimeout(() => dialogRef.close(), 3000);

    // Wait for the animation to start a tiny bit
    await new Promise(resolve => setTimeout(resolve, 5000));
    // allows other components to track whether the animation is done yet.
    if (this.finished$) {
      this.finished$.complete();
    }
  }

  createConfetti() {
    const colors = ['#78EFD9', '#2C8DC3', '#E67AA0'];

    confetti({
      zIndex: 1001, // to be above the modal
      origin: {
        x: 0.5,
        y: -0.5,
      },
      particleCount: 500,
      angle: 270,
      spread: 180,
      colors,
    });
  }

  close() {
    this.dialogRef.close();
  }
}
