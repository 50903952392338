<div
  class="logo"
  routerLink="/landing/email"></div>

<form
  id="login"
  *ngIf="newSignIn || (person$ | async) === null"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="landing-form flex-spaced-content"
  fxLayout="column">
  <h1 class="mg-centered landing-title1">{{ MESSAGES.SIGN_IN }}</h1>

  <mg-sso-ui></mg-sso-ui>

  <button
    class="login-expander"
    type="button">
    <mg-collapsible
      [btnPosition]="'above'"
      [collapseText]="MESSAGES.SIGN_UP_EXPANDER"
      [expandText]="MESSAGES.SIGN_UP_EXPANDER"
      [initiallyCollapsed]="true">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <mg-system-alert
          *ngIf="errorMessage$ | async"
          type="error"
          [message]="errorMessage$ | async">
        </mg-system-alert>
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          fxLayoutGap="12px">
          <div>
            <mg-form-text-input
              [label]="MESSAGES.EMAIL_LABEL"
              inputType="email"
              [control]="form.get(FORM_FIELDS.EMAIL)"></mg-form-text-input>
          </div>
          <div>
            <mg-form-text-input
              [label]="MESSAGES.PASSWORD_LABEL"
              inputType="password"
              [control]="form.get(FORM_FIELDS.PASSWORD)"></mg-form-text-input>
          </div>

          <mg-btn
            attrFormId="login"
            attrType="submit"
            variant="outlined"
            [wide]="true"
            [disabled]="form.invalid">
            {{ MESSAGES.SIGN_IN_BTN }}
          </mg-btn>

          <mg-btn
            class="forgot-password"
            [routerLink]="['/', LANDING_ROUTE.ROOT, LANDING_ROUTE.FORGOT]"
            variant="text">
            {{ MESSAGES.FORGOT_BTN }}
          </mg-btn>
        </div>
      </div>
    </mg-collapsible>
  </button>
</form>

<form
  *ngIf="!newSignIn && (person$ | async)"
  fxLayout="column"
  class="landing-form flex-spaced-content welcome-back-form">
  <h1 class="mg-centered landing-title1">{{ MESSAGES.WELCOME_BACK }}</h1>

  <div
    class="welcome-back"
    (click)="gotoMain()">
    <div class="welcome-back-person">
      <mg-avatar
        [src]="profileImageUrl$ | async"
        [color]="(person$ | async)?.badgeIconColor"></mg-avatar>
      <mg-person-minimal [person]="$any(person$ | async)"></mg-person-minimal>
    </div>
    <mat-icon class="link-icon">arrow_right_alt</mat-icon>
  </div>

  <mg-btn
    variant="text"
    (pressed)="signout()">
    {{ MESSAGES.LOGOUT }}
  </mg-btn>
</form>

<div class="policy-links">
  <p>
    By accessing and using the Minga Services, you agree to the
    <a [routerLink]="['', { outlets: { o: ['page', 'tos'] } }]">
      Terms of Service
    </a>
    &
    <a [routerLink]="['', { outlets: { o: ['page', 'privacy-policy'] } }]">
      Privacy Policy
    </a>
  </p>
</div>
<div
  class="country"
  *ngIf="newSignIn || (person$ | async) === null">
  <mg-country
    [country]="country$ | async"
    (mousedown)="gotoCountrySelector($event)"
    [hideText]="true"></mg-country>
</div>
