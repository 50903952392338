import { Injectable } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';

import { routerReducer, RouterStateSerializer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { RootStoreConfig } from '@ngrx/store/src/store_module';

import { notificationsReducer } from 'minga/app/src/app/store/Notifications/reducers';
import { rootMetaReducer } from 'minga/app/src/app/store/root/rootMetaReducer';

export const appReducers: ActionReducerMap<any, any> = {
  router: routerReducer,
  notifications: notificationsReducer,
};

export const appRootStoreConfig: RootStoreConfig<any, any> = {
  metaReducers: [rootMetaReducer],
  runtimeChecks: {
    strictStateImmutability: false,
    strictActionImmutability: false,
  },
};

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}
