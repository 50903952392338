<mg-layout-legacy-content>
  <mg-text
    variant="header-lg"
    class="page-title">
    {{ (route.firstChild.data | async)?.title }}
  </mg-text>
  <router-outlet
    (activate)="onOverlayActivate($event)"
    (deactivate)="onOverlayDeactivate($event)"></router-outlet>
</mg-layout-legacy-content>
