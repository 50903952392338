import { Component } from '@angular/core';
import { MatColumnDef } from '@angular/material/table';

//** Simple template component for generic-report-data-table to access template columns */
@Component({
  template: '',
})
export class ReportTableColumnTemplateComponent {
  columnDef: MatColumnDef;
  constructor() {}
}
