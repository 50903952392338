import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChartsModule } from 'ng2-charts';

import { BarChartComponent } from './bar-chart.component';

@NgModule({
  imports: [
    // Minga dependencies
    ChartsModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [BarChartComponent],
  exports: [BarChartComponent],
})
export class BarChartModule {}
