import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

import { ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from 'rxjs/operators';

import { mingaSettingTypes } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

@Component({
  selector: 'mg-setting-number-textbox',
  templateUrl: './setting-number-textbox.component.html',
  styleUrls: ['./setting-number-textbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingNumberTextboxComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private _destroyed$ = new ReplaySubject<void>(1);
  public maxValue = '99';

  @Input() label: string;
  @Input() setting: mingaSettingTypes;
  @Input() min = 0;
  @Input() step = 1;
  @Input() disabled = false;

  @Input() required = true;
  @Input() maxWidth = 65;
  @Input() set maxNumDigits(value: number) {
    const one = '1'.repeat(value);
    this.maxValue = 9 * +one + '';
  }
  @Input() helpText: string;
  @Input() public control: FormControl;
  @Input() set noCap(value: boolean) {
    if (value) this.maxValue = undefined;
  }
  @Input() set max(value: number) {
    this.maxValue = value + '';
  }

  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  @Output()
  change: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatFormField, { static: true }) matForm!: MatFormField;

  constructor(
    private _mingaSettings: MingaSettingsService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.control) {
      this.control = new FormControl(0);
    }

    // set up validation
    const validators: ValidatorFn[] = [];
    if (this.min !== undefined) {
      validators.push(Validators.min(this.min));
    }
    if (this.maxValue !== undefined)
      validators.push(Validators.max(+this.maxValue));
    if (this.required) {
      validators.push(Validators.required);
    }

    this.control.setValidators(validators);

    // subscribe to the value of the setting from the store.
    this._mingaSettings
      .getSettingValueObs(this.setting)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => {
        if (value !== undefined) {
          this.control.setValue(value, { emitEvent: false });
          this._cdr.markForCheck();
        }
      });
  }

  ngAfterViewInit(): void {
    // Subscribe to valueChanges after the view has been initialized to avoid
    // the valueChanges being triggered before the initial value is set.
    this.control.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(value => {
        this.onValueChange(value);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onValueChange(event: string) {
    // if not valid, don't update.
    if (this.control.status === 'INVALID') {
      return;
    }
    event = this.control.value ? event : '0';
    this._mingaSettings.updateSetting(this.setting, event);
    this.change.emit(event);
  }
}
