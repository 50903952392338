import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { MgIconComponent } from './MgIcon.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [MgIconComponent],
  declarations: [MgIconComponent],
})
export class MgIconModule {}
