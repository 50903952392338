import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatDayjsDateModule } from '@tabuckner/material-dayjs-adapter';

import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { PermissionsModule } from 'src/app/permissions';

import { BottomSheetModule } from '@shared/components/bottom-sheet';
import { CalendarModule } from '@shared/components/calendar';
import { GenericModule } from '@shared/components/generic';
import { TextModule } from '@shared/components/text';
import { TooltipModule } from '@shared/components/tooltip';

import { FormDateHeaderComponent } from './components/form-date-header/form-date-header.component';
import { FormDateRangeCalendarComponent } from './components/form-date-range-calendar/form-date-range-calendar.component';
import { FormDateRangeDesktopComponent } from './components/form-date-range-desktop/form-date-range-desktop.component';
import { FormDateRangeMobileComponent } from './components/form-date-range-mobile/form-date-range-mobile.component';
import { FormDateRangePresetsComponent } from './components/form-date-range-presets/form-date-range-presets.component';
import { FormDateRangeComponent } from './form-date-range.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgSpinnerModule,
    CalendarModule,
    TooltipModule,
    GenericModule,
    BottomSheetModule,
    PermissionsModule,
    MatDayjsDateModule,

    // External dependencies
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TextModule,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  exports: [
    // Components
    FormDateRangeComponent,
    FormDateRangeMobileComponent,
    FormDateRangePresetsComponent,
    FormDateHeaderComponent,
    FormDateRangeCalendarComponent,
    FormDateRangeDesktopComponent,
  ],
  declarations: [
    FormDateRangeComponent,
    FormDateRangeMobileComponent,
    FormDateRangePresetsComponent,
    FormDateHeaderComponent,
    FormDateRangeCalendarComponent,
    FormDateRangeDesktopComponent,
  ],
})
export class FormDateRangeModule {}
