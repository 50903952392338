import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { FileInputUploadModule } from 'minga/app/src/app/components/Input/FileInputUpload';
import { MgIconModule } from 'minga/app/src/app/icon';
import { ActivateDeactivateToggleButtonModule } from 'src/app/components/ActivateDeactivateToggleButton';
import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgImageModule } from 'src/app/components/MgImage';
import { MgFormFieldModule } from 'src/app/form-field';
import { MgPipesModule } from 'src/app/pipes';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { MembershipListTableModule } from '@shared/components/membership-list-table';
import { OverlayModule } from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text/text.module';
import { TooltipModule } from '@shared/components/tooltip';
import { ArrayPipesModule } from '@shared/pipes';

import { PmTeamsEditComponent } from './components/pm-teams-edit/pm-teams-edit.component';
import { PmTeamsComponent } from './pm-teams.component';
import { PmTeamsService } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    MgEmptyStateModule,
    ManagerOverlayModule,
    MgImageModule,
    MgPipesModule,
    ActivateDeactivateToggleButtonModule,
    ArrayPipesModule,
    TooltipModule,
    MgIconModule,
    MgFormFieldModule,
    OverlayModule,
    FormModule,
    FileInputUploadModule,
    MembershipListTableModule,
    TextModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [PmTeamsComponent, PmTeamsEditComponent],
  exports: [PmTeamsComponent],
  providers: [PmTeamsService],
})
export class PmTeamsModule {}
