import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgMentionModule } from 'minga/app/src/app/elements/MgMention';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

// import {MgComponentsModule} from '../components';

import { MentionableUIComponent } from './components/MentionableUI/MentionableUI.component';
import { MgMentionable2Directive } from './directives/Mentionable2.directive';
import { MentionsService } from './services/Mentions.service';

// import {PeopleModule} from '../people/people.module';
// import {GroupsModule} from '../groups/groups.module';

export const reducers: ActionReducerMap<any> = {};

@NgModule({
  imports: [
    // Minga dependencies
    MgMentionModule,
    MgSpinnerModule,
    MgAvatarModule,
    // PeopleModule,
    // GroupsModule,
    // MgComponentsModule,

    // External dependencies
    StoreModule.forFeature('mentions', reducers),
    EffectsModule.forFeature([]),
    CommonModule,
  ],
  declarations: [MentionableUIComponent, MgMentionable2Directive],

  exports: [MentionableUIComponent, MgMentionable2Directive],
})
export class MentionsModule {}
