import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';

import { LayoutService } from '@modules/layout/services';

import { ManagerOverlayColorTheme } from '@shared/components/manager-overlay';

import { BehaviorManagerMessages } from './constants';

@Component({
  selector: 'mg-behavior-manager',
  templateUrl: './behavior-manager.component.html',
  styleUrls: ['./behavior-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BehaviorManagerComponent {
  /** Constants */
  public readonly MESSAGES = BehaviorManagerMessages;
  public readonly colorTheme = ManagerOverlayColorTheme.PBIS_MANAGER;

  /** Component Constructor */
  constructor(
    public media: MediaObserver,
    public route: ActivatedRoute,
    public layout: LayoutService,
    private _router: Router,
  ) {}

  public async returnFromManager() {
    this._router.navigateByUrl('/');
  }
}
