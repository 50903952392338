import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { ScrollTargetService } from 'minga/app/src/app/misc/ScrollTarget/service';

@Directive({
  exportAs: 'mgScrollTarget',
  selector: '[mgScrollTarget]',
})
export class ScrollTargetDirective implements OnDestroy {
  /** @internal */
  private _enabled: boolean | null = null;

  @Input('mgScrollTarget')
  set enabled(value: boolean) {
    const oldValue = this._enabled;
    this._enabled = coerceBooleanProperty(value);

    if (oldValue !== this._enabled) {
      if (this._enabled) {
        this.scrollTargetService.pushScrollTarget(
          this.elementRef.nativeElement,
        );
      } else {
        this.scrollTargetService.pullScrollTarget(
          this.elementRef.nativeElement,
        );
      }
    }
  }

  get enabled() {
    return !!this._enabled;
  }

  constructor(
    private elementRef: ElementRef<Element>,
    private scrollTargetService: ScrollTargetService,
  ) {}

  ngOnDestroy() {
    this.scrollTargetService.pullScrollTarget(this.elementRef.nativeElement);
  }
}
