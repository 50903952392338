// consequences
import { ICheckinReason } from 'minga/domain/checkin';
import { IConsequenceType } from 'minga/domain/consequences';
import { IHallPassType } from 'minga/domain/hallPass';
import { IPbisType } from 'minga/domain/pbis';

import { ICON_CONFIG_DEFAULTS as BEHAVIOR_ICON_DEFAULTS } from '@modules/behavior-manager/components/bm-types/components/bm-types-behavior-edit/bm-types-behavior-edit.constants';
import { ICON_CONFIG_DEFAULTS as CONSEQUENCE_ICON_DEFAULTS } from '@modules/behavior-manager/components/bm-types/components/bm-types-consequence-edit/bm-types-consequence-edit.constants';

import { getAppColor } from '@shared/constants';

import { AssignmentType } from '../types/tt-my-class.types';

export const getIcon = (
  type: AssignmentType,
  data: IHallPassType | IPbisType | IConsequenceType | ICheckinReason,
): {
  icon: string;
  color: string;
  iconNamespace?: string;
} => {
  if (type === AssignmentType.HALLPASS) {
    const hp = data as IHallPassType;
    return {
      icon: hp.bannerHash || 'hallpass',
      color: hp.color || getAppColor('surface'),
    };
  }

  if (type === AssignmentType.BEHAVIOR) {
    const behavior = data as IPbisType;
    const fallback = BEHAVIOR_ICON_DEFAULTS[behavior.categoryId];
    return {
      icon: behavior.iconType || fallback.icon,
      color: behavior.iconColor || fallback.color,
      iconNamespace: 'minga.behaviors',
    };
  }

  if (type === AssignmentType.CONSEQUENCE) {
    const consequence = data as IConsequenceType;
    const fallback = CONSEQUENCE_ICON_DEFAULTS[consequence.categoryId];
    return {
      icon: consequence.iconType || fallback.icon,
      color: consequence.iconColor || fallback.color,
      iconNamespace: 'minga.behaviors',
    };
  }

  if (type === AssignmentType.CHECKIN) {
    const checkinReason = data as ICheckinReason;
    return {
      icon: checkinReason.icon,
      color: checkinReason.color,
    };
  }

  console.error('Invalid assignment type', type);
};
