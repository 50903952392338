import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';

import { AvatarClusterComponent } from './AvatarCluster.component';

@NgModule({
  imports: [MgAvatarModule, CommonModule],
  declarations: [AvatarClusterComponent],
  exports: [AvatarClusterComponent],
})
export class AvatarClusterModule {}
