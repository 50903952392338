<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  overlayTitle="Edit"
  [isLoading]="isLoading$ | async">
  <ng-container>
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  </ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <!-- Asset -->
    <div
      *ngIf="data.config.changeImage"
      fxLayout="row"
      fxLayoutAlign="center center">
      <div
        class="asset-container"
        (click)="editImage()">
        <ng-container *ngIf="imagePath$ | async as imageSrc; else placeholder">
          <mg-image
            [srcs]="
              imageSrc
                | mgImageUrls
                  : [
                      'blurloading1',
                      ['studio', 'bannerlibpreview', 'cardbanner']
                    ]
            "
            [background-size]="'contain'">
          </mg-image>
        </ng-container>
        <ng-template #placeholder>
          <div
            class="placeholder-sticker"
            fxLayoutAlign="center center">
            <mg-icon>camera</mg-icon>
          </div>
        </ng-template>
        <label>{{ MSG.FIELD_LABEL_IMAGE }}</label>
      </div>
    </div>

    <!-- Name Field -->
    <mg-form-text-input
      *ngIf="form.controls['name']"
      label="{{ MSG.FIELD_LABEL_NAME }}"
      [control]="form.controls['name']">
    </mg-form-text-input>

    <!-- Color Field -->
    <div
      *ngIf="form.controls['color']"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <mg-text variant="body-md">
        {{ MSG.FIELD_LABEL_COLOR }}
      </mg-text>
      <mg-form-text-input
        inputType="color"
        widthSize="large"
        [condensed]="true"
        [control]="form.controls['color']">
      </mg-form-text-input>
    </div>

    <!-- Priority field -->
    <div
      *ngIf="data.config.changePriority"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="4px">
        <mg-text variant="body-md">{{ MSG.FIELD_LABEL_PRIORITY }}</mg-text>
        <mg-tooltip [label]="MSG.FIELD_LABEL_PRIORITY_HINT"></mg-tooltip>
      </div>
      <div>
        <mg-form-text-input
          [control]="form.controls['priority']"
          [step]="1"
          inputType="number">
        </mg-form-text-input>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    [fxLayoutAlign]="
      (canDeleteList$ | async) ? 'space-between center' : 'end center'
    ">
    <div *ngIf="canDeleteList$ | async">
      <mg-btn
        [variant]="isNewList ? 'text' : 'destructive'"
        (pressed)="delete()">
        {{ isNewList ? MSG.BUTTON_LABEL_CANCEL : MSG.BUTTON_LABEL_DELETE }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        variant="filled"
        [disabled]="!canSubmit"
        (pressed)="submit()">
        {{ MSG.BUTTON_LABEL_SAVE }}
      </mg-btn>
    </div>
  </div>
</ng-template>
