import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LogoModule } from 'minga/app/src/app/components/Logo';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { LogoAnchoredComponent } from './LogoAnchored.component';

@NgModule({
  imports: [
    // Minga dependencies
    LogoModule,
    MgImageModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [LogoAnchoredComponent],
  exports: [LogoAnchoredComponent],
})
export class LogoAnchoredModule {}
