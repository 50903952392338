import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
import { HammerModule } from '@angular/platform-browser';

import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { TilesCarouselComponent } from './tiles-carousel.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    MgIconModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTabsModule,
    ObserversModule,
    HammerModule,
  ],
  declarations: [TilesCarouselComponent],
  exports: [TilesCarouselComponent],
})
export class TilesCarouselModule {}
