import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GroupTabGuard } from 'minga/app/src/app/groups/guards';

import { GroupEffectsModule } from './effects';
import { GroupCollectionEffects } from './effects/GroupCollectionEffects';
import { GroupFormEffects } from './effects/GroupFormEffects';
import { ParentGroupEffects } from './effects/ParentGroupEffects';
import { groupFormReducer } from './reducers';
import { groupCollectionReducer } from './reducers/group-collection-reducer';
import { groupDetailReducer } from './reducers/group-detail-reducer';
import { GroupsService } from './services/Groups.service';
import { GroupStateService } from './services/GroupState.service';

@NgModule({
  imports: [
    GroupEffectsModule,
    StoreModule.forFeature('groups', {
      groupCollection: groupCollectionReducer,
      groupDetails: groupDetailReducer,
      groupForm: groupFormReducer,
    }),
    EffectsModule.forFeature([
      GroupCollectionEffects,
      GroupFormEffects,
      ParentGroupEffects,
    ]),
  ],
  declarations: [],

  providers: [GroupsService, GroupStateService, GroupTabGuard],
})
export class GroupsModule {}
