import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';

import { constants } from 'buffer';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { Observable, Subject } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { isMentionUiVisible } from 'minga/app/src/app/mentions/components/MentionableUI';
import { IMingaProfile } from 'src/app/services/MingaManager';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';

export const MG_QUILL_EDITOR_MAT_FORM_FIELD_CONTROl: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => MgQuillEditor),
};

type QuillInstance = import('quill').Quill;

type QuillOnSelectionChangedEvent = {
  editor: QuillInstance;
  oldRange: Range | null;
  range: Range | null;
  source: 'user' | 'api' | 'silent' | undefined;
};

type QuillOnContentChangedEvent = {
  editor: QuillInstance;
  html: string;
  text: string;
  content: any;
  delta: any;
  oldDelta: any;
  source: 'user' | 'api' | 'silent' | undefined;
};

type QuillOnFocusEvent = {
  editor: QuillInstance;
  source: 'user' | 'api' | 'silent' | undefined;
};

type QuillOnBlurEvent = {
  editor: QuillInstance;
  source: 'user' | 'api' | 'silent' | undefined;
};

/**
 * Wraps the `<quill-editor>` component from 'ngx-quill' and implemented the
 * mat-form-field control as well as the value accessor. This should allow you
 * to use `<mg-quill-editor>` seemlessly as a form control.
 *
 * It comes with our pre-setup quill modules that hit our business critieria as
 * well as the minor style tweaks
 */
@Component({
  providers: [MG_QUILL_EDITOR_MAT_FORM_FIELD_CONTROl],
  selector: 'mg-quill-editor',
  templateUrl: './QuillEditor.component.html',
  styleUrls: ['./QuillEditor.component.scss'],
})
export class MgQuillEditor
  extends MatFormFieldControl<string>
  implements ControlValueAccessor, OnDestroy, OnInit, DoCheck
{
  /**
   * @internal
   * This value is only here while the quill editor has not been created. This
   * way we can receive a writeValue() call while the quill editor is still
   * being setup.
   */

  private _minga: IMingaProfile;
  private _tempValue: string | null = null;
  private _onChange: any;
  private _onTouched: any;
  private _quillEditorComponent: QuillEditorComponent | null = null;
  private _quill: QuillInstance | null = null;
  private _quillEditorFocused: boolean;
  private _ngControl: NgControl | null = null;
  private _stateChanges = new Subject<void>();

  readonly controlType: string = 'quill-editor';

  @Input()
  customToolbar: boolean;

  @Input()
  customElement: Record<string, any>;

  @Input()
  richContent: boolean;

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  @Input()
  required: boolean;

  @Input()
  allowedTags: string | string[] = [];

  @Input()
  maxlength?: number;

  @Input()
  minHeight: 'auto';

  /**
   * Quill has a nasty bug where on init if we set an initial value 'dangerouslyPasteHTML' will cause whatever parent
   * scroll bar to jump to put the editor in view. This is a workaround to reset the scroll bar to the top of the parent
   */
  @Input()
  resetParentScrollbarElement: string;

  @HostBinding('class.floating')
  // @ts-ignore
  get shouldLabelFloat(): boolean {
    return !this.empty || this.focused || this.richContent;
  }
  // @ts-ignore
  get id(): string {
    if (this.quillEditorComponent && this.quillEditorComponent.editorElem) {
      const el: HTMLElement = this.quillEditorComponent.editorElem;
      return el.id || '';
    }

    return '';
  }
  // @ts-ignore
  get errorState(): boolean {
    if (!this.ngControl || !this.ngControl.touched) {
      return false;
    }

    if (this.ngControl.invalid) {
      return true;
    }

    if (this.required && this.empty) {
      return true;
    }

    const value = this.value || '';

    if (this.maxlength && value.length > this.maxlength) {
      return true;
    }

    return false;
  }
  // @ts-ignore
  get empty(): boolean {
    if (this._quill) {
      // getLength() returns 1 when empty because there's always a new line at
      // the end.
      return this._quill.getLength() <= 1;
    } else {
      return !!this._tempValue?.trim().length;
    }
  }

  get isWithinMatFormField() {
    return !!this._matFormField;
  }
  // @ts-ignore
  get stateChanges(): Observable<void> {
    return this._stateChanges.asObservable();
  }
  // @ts-ignore
  get focused(): boolean {
    return this._quillEditorFocused;
  }

  get innerPlaceholder() {
    // return no placeholder if within form field
    return this.isWithinMatFormField ? '' : this.placeholder;
  }
  // @ts-ignore
  set value(value: string | null) {
    if (this._quill && value) {
      this._tempValue = value;
      this._quill.setText('');

      this._setEditorValue(value);
    } else {
      this._tempValue = value;
    }
  }

  get value(): string | null {
    if (this._quill) {
      return this._quill.root.innerHTML;
    } else {
      return this._tempValue || '';
    }
  }

  @ViewChild('quillEditorComponent') quillEditor;
  @ViewChild('quillEditorComponent', { read: ElementRef })
  quillEditorElement: ElementRef;

  set quillEditorComponent(el: QuillEditorComponent | null) {
    this._quillEditorComponent = el;
    if (el) {
      this._initQuillEditorComponent(el);
    }
  }

  get quillEditorComponent() {
    return this._quillEditorComponent;
  }

  @ViewChild('quillTestComponent') myTestRef!: ElementRef;

  // @ts-ignore
  get ngControl() {
    return this._ngControl;
  }

  standardModule = {
    toolbar: [
      ['bold', 'italic'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, false] }],
      ['link'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false, // fixes extra <p><br></p> being created...
    },
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: () => !isMentionUiVisible(),
        },
        enter: {
          key: 13,
          handler: () => !isMentionUiVisible(),
        },
      },
    },
  } as any;

  customModule = {
    ...this.standardModule,
    toolbar: {
      container: [
        ...(this.standardModule.toolbar as any),
        [
          {
            custom: [
              '${firstName}',
              '${lastName}',
              '${typeName}',
              '${mingaName}',
              '${note}',
              '${assigner}',
              '${points}',
            ],
          },
        ],
        ['preview'],
      ],
      handlers: {
        custom: (value: string) => this.customDropdownHandler(value),
        preview: () => this.previewHandler(),
      },
    },
  } as QuillModules;

  modules: QuillModules;
  isEnabled = true;
  bodyElement: any;
  bodyElementPreviewHolder: any;
  private _mingaProfile;

  constructor(
    private _fm: FocusMonitor,
    private _element: ElementRef,
    private _injector: Injector,
    private _mingaStore: MingaStoreFacadeService,
    @Optional() private _matFormField: MatFormField,
  ) {
    super();
  }

  async ngOnInit() {
    // This is the alternative to the ControlValueAccessor provider. We cannot
    // provde ControlValueAccessor _and_ inject the `NgControl` while providing
    // the MatFormFieldControl or else we get a cyclic dependency.
    this._ngControl = this._injector.get(NgControl);
    if (this._ngControl != null) {
      this._ngControl.valueAccessor = this;
    }

    this._fm.monitor(this._element, true).subscribe(origin => {
      this._quillEditorFocused = origin !== null;
      this._stateChanges.next();
    });

    this.modules = this.customToolbar ? this.customModule : this.standardModule;

    this._mingaProfile = await this._mingaStore.getMingaAsPromise();
  }

  private _initQuillEditorComponent(component: QuillEditorComponent) {}

  onQuillEditorCreated(quill: QuillInstance) {
    if (this.customToolbar) {
      const itemElement =
        this.quillEditorElement.nativeElement.querySelectorAll(
          '.ql-custom .ql-picker-item',
        );
      itemElement.forEach(item => (item.textContent = item.dataset.value));

      const labelElement = this.quillEditorElement.nativeElement.querySelector(
        '.ql-custom .ql-picker-label',
      );
      labelElement.style.fontWeight = 'bold';
      labelElement.innerHTML = '[Insert Variable]' + labelElement.innerHTML;

      const svgElement = this.quillEditorElement.nativeElement.querySelector(
        '.ql-custom .ql-picker-label svg',
      );
      svgElement.style.right = '-20px';

      const previewElement =
        this.quillEditorElement.nativeElement.querySelector('.ql-preview');
      previewElement.innerText = 'Preview';
      previewElement.style.position = 'absolute';
      previewElement.style.bottom = '0';
      previewElement.style.right = '30px';
      previewElement.style.zIndex = '1001';
      previewElement.style.fontSize = '12px';
      previewElement.style.fontWeight = '700';
      previewElement.style.color = '#74777f';

      this.bodyElement =
        this.quillEditorElement.nativeElement.querySelector('.ql-editor');
    }

    this._quill = quill;
    if (this._tempValue) {
      this._setEditorValue(this._tempValue);
    }

    this._tempValue = null;
  }

  private _setEditorValue(value: string) {
    if (!this._quill) {
      return;
    }
    if (this.quillEditorComponent?.editorElem?.children.length) {
      // @NOTE: This bypasses the stripping of styles and other HTML
      // This was done to allow mentions
      this.quillEditorComponent.editorElem.children[0].innerHTML = value;
    } else {
      this._quill.clipboard.dangerouslyPasteHTML(this._tempValue || '', 'user');

      if (this.resetParentScrollbarElement) {
        this._resetScrollbarPosition(this.resetParentScrollbarElement);
      }
    }
  }

  onContentChanged(ev: QuillOnContentChangedEvent) {
    if (ev.source === 'user') {
      this.triggerOnChange();
      this.triggerOnTouched();
      this._stateChanges.next();
    }
  }

  triggerOnChange() {
    if (this._onChange) {
      this._onChange(this.value);
    }
  }

  triggerOnTouched() {
    if (this._onTouched) {
      this._onTouched();
    }
  }

  registerOnChange(fn: Function) {
    this._onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this._onTouched = fn;
  }

  writeValue(value: any) {
    if (typeof value === 'string') {
      this.value = value;
    } else if (value === null) {
      this.value = value;
    }
  }

  ngOnDestroy() {
    this._fm.stopMonitoring(this._element);
  }

  ngDoCheck() {
    // This was placed here to prevent the placeholder text from being blank
    // or having the outline of a mat-form-field overlapping the placeholder
    // text. It should be possible to call this in a different spot and get the
    // same result, but at the time of writing this I could not find such a
    // location. @OPTIMIZATION
    this._stateChanges.next();
  }

  // Required by MatFormFieldControl
  setDescribedByIds(ids: string[]): void {}

  // Required by MatFormFieldControl
  onContainerClick(event: MouseEvent): void {}

  public customDropdownHandler(value: string) {
    if (value && this.isEnabled) {
      const cursorPosition = this._quill.getSelection().index;
      this._quill.insertText(cursorPosition, value, 'user');
      this._quill.setSelection(cursorPosition + value.length, 0);
    }
  }

  async previewHandler() {
    const text = this.bodyElement.innerHTML;
    if (this.isEnabled) {
      this._quill.disable();
      this.bodyElementPreviewHolder = this.bodyElement.innerHTML;
      const preview = text
        .replace(new RegExp('\\$\\{firstName}', 'g'), 'John')
        .replace(new RegExp('\\$\\{lastName}', 'g'), 'Doe')
        .replace(
          new RegExp('\\$\\{typeName}', 'g'),
          this.customElement.typeName,
        )
        .replace(new RegExp('\\$\\{mingaName}', 'g'), this._mingaProfile.name)
        .replace(
          new RegExp('\\$\\{note}', 'g'),
          'This is a custom note message',
        )
        .replace(new RegExp('\\$\\{assigner}', 'g'), 'Joe Schmoe')
        .replace(new RegExp('\\$\\{points}', 'g'), '5');
      this.bodyElement.innerHTML = preview;
    } else {
      this._quill.enable();
      this.bodyElement.innerHTML = this.bodyElementPreviewHolder;
    }
    this.isEnabled = !this.isEnabled;
  }

  private _resetScrollbarPosition(el) {
    const modalBody = document.querySelector(el);

    if (modalBody) {
      modalBody.scrollTop = 0;
    }
  }
}
