import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { EventCalendarService } from 'minga/app/src/app/services/EventCalendar';
import { day } from 'minga/shared/day';
import { EVENT_SCHEDULE_TYPE } from 'minga/shared/event';

import { EventScheduleDataSource } from './EventSchedule.datasource';

interface IEventFeedFilter {
  groupHashes?: string;
  startTimestamp?: string;
}

@Component({
  templateUrl: './EventSchedule.component.html',
  styleUrls: ['./EventSchedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EventScheduleDataSource],
})
export class EventScheduleComponent implements OnDestroy {
  feedFilter$: Observable<IEventFeedFilter>;

  startDate$: Observable<Date>;
  settingsSub: Subscription;

  private _destroyed = new ReplaySubject<void>(1);

  constructor(
    private eventCalendarService: EventCalendarService,
    private route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    public ds: EventScheduleDataSource,
  ) {
    this.route.params.subscribe(params => {
      if (params.quickFilter) {
        this.eventCalendarService.setQuickFilter(params.quickFilter);
      }
    });

    this.settingsSub = eventCalendarService.settings$.subscribe(settings => {
      this.ds.setSettings(settings);
      this._cdr.markForCheck();
    });

    this.startDate$ = this.route.queryParams.pipe(
      map(params => params['start']),
      distinctUntilChanged(),
      map((start: string) => {
        // replace dashes with slashes for safari support
        if (start) {
          start = start.replace(/-/g, '/');
        }
        return day(start).startOf('day').toDate();
      }),
    );

    this.eventCalendarService.setUseCalendarSettings(false);
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
    this.settingsSub.unsubscribe();
  }
}
