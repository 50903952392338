import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';

import * as day from 'dayjs';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { CalendarMessage } from './constants';

@Component({
  selector: 'mg-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnDestroy, OnInit {
  /** Child Components */
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  /** Constants */
  public readonly MSG = CalendarMessage;

  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);

  /** Current Value */
  private readonly _currentValue = new BehaviorSubject(day());
  public readonly currentValue$ = this._currentValue.asObservable();

  /* Default Value */
  private _originalDate = day();

  /** Inputs */
  @Input() set value(value: day.Dayjs) {
    this._originalDate = value;
    this._currentValue.next(value);
  }
  @Input() allowPastDate = true;
  @Input() showControls = false;

  /**
   * Set mininum date user select
   */
  @Input() minDate: day.Dayjs;
  /**
   * Set maximum date user can select
   */
  @Input() maxDate: day.Dayjs;

  /** Outputs */
  @Output()
  dateChange = new EventEmitter<day.Dayjs>();

  constructor() {}

  ngOnInit(): void {
    if (!this.allowPastDate && !this.minDate) {
      this.minDate = day();
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._currentValue.complete();
  }

  public onSelectDate(date: any): void {
    const selectedDate = day(date);
    this._currentValue.next(selectedDate);
    this.dateChange.emit(selectedDate);
  }

  public reset(): void {
    this._currentValue.next(this._originalDate);
  }

  public submit(): void {
    const selectedDate = this._currentValue.getValue();
    this.dateChange.emit(selectedDate);
  }
}
