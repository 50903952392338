export const toolsPointsRouterLinks = {
  points: '/tools/points/your-points',
  leaderboard: '/tools/points/leaderboard',
  rewards: '/tools/points/rewards',
};

export const toolsPointsRelativeRouterLinks = {
  points: './your-points',
  leaderboard: './leaderboard',
  rewards: './rewards',
};
