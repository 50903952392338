import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';

import { Observable } from 'rxjs';

import { MingaPermission } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';

/**
 * Guard to ensure that the user is a superadmin.
 */
@Injectable({ providedIn: 'root' })
export class SuperadminGuard implements CanLoad {
  constructor(
    private _router: Router,
    private _permissionService: PermissionsService,
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): boolean | Observable<boolean> | Promise<boolean> {
    if (!this._permissionService.hasPermission(MingaPermission.SUPERADMIN)) {
      return false;
    }
    return true;
  }
}
