import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[mgTableStreamHeader]',
})
export class MgTableStreamHeaderDirective {
  constructor(templateRef: TemplateRef<any>, viewContainer: ViewContainerRef) {
    // Not directly used, but mgTableStreamHeader is intended to only be used
    // on a template.
    templateRef;
    viewContainer;
  }
}
