<ng-container *ngIf="hasItems()">
  <mat-expansion-panel
    mg-nav-item-exp-panel
    class="no-material mat-elevation-z0">
    <mat-expansion-panel-header
      [collapsedHeight]="'auto'"
      [expandedHeight]="'auto'">
      <mat-panel-title>
        <div class="navitem-contents">
          <mg-navicon [icon]="icon"></mg-navicon>
          <span mg-nav-item-text>{{ title }}</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-list class="nav-subnav-list">
        <div class="minga-select-wrapper">
          <mg-form-select
            mingaSwitcher
            appearance="primary"
            placeholder="Search For Minga"
            labelBackground="grey"
            [searchable]="true"
            [multiple]="false"
            [isClearable]="true"
            [hideSelected]="true"
            [floatingLabel]="false">
          </mg-form-select>
        </div>

        <ng-container *ngFor="let item of items">
          <ng-container
            [ngTemplateOutlet]="expansionPanelNavLinks"
            [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-container>
      </mat-list>
    </ng-template>
  </mat-expansion-panel>

  <ng-template
    #expansionPanelNavLinks
    let-item>
    <mat-list-item
      [routerLink]="item.routerLink"
      routerLinkActive="active"
      class="mg-button-hover-harbour">
      <mg-navicon
        class="sub-navicon"
        *ngIf="item.icon"
        [icon]="item.icon"></mg-navicon>
      <span
        mg-nav-item-text
        class="mg-minicopy"
        *ngIf="item.text"
        >{{ item.text }}</span
      >
      <img
        *ngIf="item.imageUrl"
        mg-nav-item-image
        [src]="item.imageUrl" />
    </mat-list-item>
  </ng-template>
</ng-container>
