import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MentionsModule } from 'minga/app/src/app/mentions';
import { QuillModule } from 'ngx-quill';

import { MgQuillEditor } from './QuillEditor.component';

@NgModule({
  imports: [
    // Minga dependencies
    MentionsModule,

    // External dependencies
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    QuillModule,
  ],
  declarations: [MgQuillEditor],
  exports: [MgQuillEditor],
})
export class MgQuillEditorModule {}
