import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import {
  FirebaseMessage,
  FirebaseMessaging,
  FirebaseMessagingPermissionStatus,
  IFirebaseNotificationMessage,
} from '../FirebaseMessaging';

export class FirebaseMessagingCordova extends FirebaseMessaging {
  /** @internal */
  private readonly _messageReceived = new Subject<FirebaseMessage>();
  /** @internal */
  private readonly _tokenRefresh = new Subject<void>();
  /** @internal */
  private readonly _notificationClicked =
    new BehaviorSubject<IFirebaseNotificationMessage | null>(null);

  readonly name = 'Native';
  readonly messageReceived$ = this._messageReceived.asObservable();
  readonly tokenRefresh$ = this._tokenRefresh.asObservable();
  readonly notificationClicked$ = this._notificationClicked.pipe(
    filter(not => !!not),
    map(not => not as IFirebaseNotificationMessage),
  );

  constructor(private _kioskPermissions: KioskPermissionsService) {
    super();
  }

  async init() {
    const FirebasePlugin = (window as any).FirebasePlugin;

    FirebasePlugin.onMessageReceived((msg: any) => {
      console.log('received push notification', msg);
      setTimeout(() => this._handleMessage(msg), 0);
    });

    FirebasePlugin.onTokenRefresh((token: any) =>
      setTimeout(() => {
        this._tokenRefresh.next();
      }, 0),
    );

    // we want to disable all notifications for users in kiosk mode
    this._kioskPermissions.isUserInKioskModeObs().subscribe(inKioskMode => {
      if (inKioskMode) {
        this.deleteCurrentToken();
      }
    });
  }

  clearLastNotificationClicked() {
    this._notificationClicked.next(null);
  }

  async deleteCurrentToken(): Promise<void> {
    (window as any).FirebasePlugin.unregister();
  }

  async getToken(): Promise<string> {
    const FirebasePlugin = (window as any).FirebasePlugin;

    return new Promise<string>(resolve => {
      FirebasePlugin.getToken((token: string | null) =>
        setTimeout(() => resolve(token || ''), 0),
      );
    });
  }

  async getDeviceId(): Promise<string> {
    return (window as any).device?.uuid || '';
  }

  async getPermissionStatus(): Promise<FirebaseMessagingPermissionStatus> {
    const FirebasePlugin = (window as any).FirebasePlugin;

    return new Promise<FirebaseMessagingPermissionStatus>(resolve => {
      const diagnostic = (window as any).cordova.plugins.diagnostic;

      const hasPermissionResolve = () =>
        FirebasePlugin.hasPermission((hasPerm: any, err: any) =>
          setTimeout(() => {
            resolve(hasPerm ? 'granted' : 'denied');
          }, 0),
        );

      // `getRemoteNotificationsAuthorizationStatus` is currently only available
      // on iOS
      if (!diagnostic.getRemoteNotificationsAuthorizationStatus) {
        hasPermissionResolve();
      } else {
        diagnostic.getRemoteNotificationsAuthorizationStatus(
          (status: any) =>
            setTimeout(() => {
              switch (status) {
                case diagnostic.permissionStatus.NOT_REQUESTED:
                  return resolve('unknown');
                case diagnostic.permissionStatus.DENIED_ALWAYS:
                  return resolve('denied');
                case diagnostic.permissionStatus.GRANTED:
                  return resolve('granted');
              }

              return resolve('denied');
            }, 0),
          (err: any) =>
            setTimeout(() => {
              // on iOS < 10 `getRemoteNotificationsAuthorizationStatus` doesn't
              // work
              hasPermissionResolve();
            }, 0),
        );
      }
    });
  }

  async requestPermission(): Promise<FirebaseMessagingPermissionStatus> {
    const FirebasePlugin = (window as any).FirebasePlugin;
    return new Promise<FirebaseMessagingPermissionStatus>(resolve => {
      FirebasePlugin.grantPermission((hasPerm: any) =>
        setTimeout(() => {
          resolve(hasPerm ? 'granted' : 'denied');
        }, 0),
      );
    });
  }

  private _handleMessage(msg: any) {
    const data = { ...msg };
    delete data.messageType;
    delete data.tap;

    const cordovaPluginFirebaseExtras = {
      messageType: msg.messageType,
      tap: msg.tap,
    };
    console.log('_handleMessage', msg);
    if (msg.messageType === 'data') {
      this._messageReceived.next({
        type: 'data',
        data,
        cordovaPluginFirebaseExtras,
      });
    } else if (msg.messageType === 'notification') {
      const notificationPayload: IFirebaseNotificationMessage = {
        type: 'notification',
        notification: {
          get title() {
            console.warn(
              'Notification title is unavailable via firebase messaging cordova plugin',
            );
            return '';
          },
          get body() {
            console.warn(
              'Notification body is unavailable via firebase messaging cordova plugin',
            );
            return '';
          },
        },
        data,
        cordovaPluginFirebaseExtras,
      };

      this._messageReceived.next(notificationPayload);

      if (cordovaPluginFirebaseExtras.tap) {
        this._notificationClicked.next(notificationPayload);
      }
    }
  }
}
