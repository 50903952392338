<button
  matRipple
  class="pill"
  type="button"
  [ngClass]="classes">
  <mg-text
    *ngIf="text"
    class="pill-text"
    variant="label-md-secondary"
    ><span>{{ text }}</span></mg-text
  >
  <mg-icon
    *ngIf="icon"
    class="pill-icon"
    iconName="mg-away"></mg-icon>
</button>
