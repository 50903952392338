<div
  fxLayout="column"
  fxLayoutAlign="start center"
  class="modal-overlay">
  <div
    #overlayIconTpl
    class="modal-overlay-header">
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="modal-overlay-header-icon"
      [class.modal-overlay-header-icon-hide-backdrop]="
        !!dialogData.hideIconBackdrop
      ">
      <ng-container *ngIf="dialogData.iconName">
        <mg-icon [iconName]="dialogData.iconName"></mg-icon>
      </ng-container>

      <ng-container *ngIf="dialogData.imagePath">
        <img [src]="dialogData.imagePath" />
      </ng-container>
    </div>
  </div>

  <div class="modal-overlay-message">
    <span>{{ dialogData.message }}</span>
    <p>{{ dialogData.body }}</p>
  </div>

  <div
    *ngIf="dialogData.buttonText"
    class="modal-overlay-action">
    <mg-generic-button
      [colorTheme]="dialogData.buttonColor || 'pink'"
      size="xlarge"
      variant="primary"
      paddingSize="large"
      (onClick)="close(true)">
      {{ dialogData.buttonText }}
    </mg-generic-button>
  </div>
</div>
