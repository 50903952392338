<div class="calendar-container">
  <mat-calendar
    [minDate]="minDate"
    [maxDate]="maxDate"
    [selected]="currentValue$ | async"
    (selectedChange)="onSelectDate($event)">
  </mat-calendar>
  <div
    *ngIf="showControls"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="10px">
    <div fxFlex="nogrow">
      <mg-generic-button
        colorTheme="blue"
        size="small"
        paddingSize="none"
        variant="clear"
        (onClick)="reset()">
        {{ MSG.BUTTON_RESET }}
      </mg-generic-button>
    </div>
    <div fxFlex="nogrow">
      <mg-generic-button
        colorTheme="blue"
        size="small"
        (onClick)="submit()">
        {{ MSG.BUTTON_DONE }}
      </mg-generic-button>
    </div>
  </div>
</div>
