export enum StudentToolsCheckinMessages {
  MODAL_TITLE = 'Self Check in',

  BUTTON_LABEL_CANCEL = 'Cancel',
  BUTTON_LABEL_CONFIRM = 'Confirm',

  DESCRIPTION = 'Are you sure you want to check yourself in?',

  ERROR_TITLE = 'Error',
}

export type StudentToolsCheckinReasonErrorType = 'not-active' | 'not-found';
