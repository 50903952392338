import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ReportTypes } from 'minga/domain/reportTypes';
import { MingaPermission } from 'minga/util';
import { MingaAppRoutes } from 'src/app/types';

import { AccessRestrictionsGuard } from '@shared/guards/access-restrictions.guard';

import { FtmActivitiesComponent } from './components/ftm-activities/ftm-activities.component';
import { FtmActivitiesModule } from './components/ftm-activities/ftm-activities.module';
import { FtmActivityTemplatesComponent } from './components/ftm-activity-templates/ftm-activity-templates.component';
import { FtmActivityTemplatesModule } from './components/ftm-activity-templates/ftm-activity-templates.module';
import { FtmDashboardComponent } from './components/ftm-dashboard/ftm-dashboard.component';
import { FtmDashboardModule } from './components/ftm-dashboard/ftm-dashboard.module';
import { FtmPeriodsComponent } from './components/ftm-periods/ftm-periods.component';
import { FtmPeriodsModule } from './components/ftm-periods/ftm-periods.module';
import { FtmReportsRegistrationComponent } from './components/ftm-reports/components/ftm-reports-reg/ftm-reports-reg.component';
import { FtmReportsUnregisteredComponent } from './components/ftm-reports/components/ftm-reports-unreg/ftm-reports-unreg.component';
import { FtmReportsComponent } from './components/ftm-reports/ftm-reports.component';
import { FtmReportsModule } from './components/ftm-reports/ftm-reports.module';
import { FtmSettingsComponent } from './components/ftm-settings/ftm-settings.component';
import { FtmSettingsModule } from './components/ftm-settings/ftm-settings.module';
import { FlexTimeManagerMessages, FlexTimeManagerRoutes } from './constants';
import { FlexTimeManagerComponent } from './flex-time-manager.component';
import { FlexTimeManagerModule } from './flex-time-manager.module';

export const FLEX_TIME_MANAGER_ROUTES: MingaAppRoutes = [
  {
    path: '',
    component: FlexTimeManagerComponent,
    data: {
      title: 'Flex Time',
    },
    children: [
      {
        path: '',
        canActivate: [AccessRestrictionsGuard],
        component: FtmDashboardComponent,
        data: {
          title: 'Dashboard',
          description: 'View and manage flex time activities',
          enableAutoRedirect: true,
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.FLEX_TIME_MANAGE,
                MingaPermission.FLEX_TIME_VIEW_REPORTS,
              ],
            },
          },
        },
      },
      {
        path: FlexTimeManagerRoutes.REPORTS,
        canActivate: [AccessRestrictionsGuard],
        component: FtmReportsComponent,
        data: {
          title: 'Reports',
          description: '',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.FLEX_TIME_MANAGE,
                MingaPermission.FLEX_TIME_VIEW_REPORTS,
              ],
            },
          },
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: ReportTypes.FLEX_PERIOD_REGISTERED,
          },
          {
            path: ReportTypes.FLEX_PERIOD_REGISTERED,
            component: FtmReportsRegistrationComponent,
            data: { title: 'History' },
          },
          {
            path: ReportTypes.FLEX_PERIOD_UNREGISTERED,
            component: FtmReportsUnregisteredComponent,
            data: { title: 'Unregistered' },
          },
        ],
      },
      {
        path: FlexTimeManagerRoutes.PERIODS,
        canActivate: [AccessRestrictionsGuard],
        component: FtmPeriodsComponent,
        data: {
          title: 'Periods',
          description: 'View and manage flex time periods',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
                MingaPermission.FLEX_TIME_MANAGE,
              ],
            },
          },
        },
      },
      {
        path: FlexTimeManagerRoutes.ACTIVITIES,
        canActivate: [AccessRestrictionsGuard],
        component: FtmActivitiesComponent,
        data: {
          title: `${FlexTimeManagerMessages.PAGE_TITLE_ACTIVITIES}`,
          description: '',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
                MingaPermission.FLEX_TIME_MANAGE,
              ],
            },
          },
        },
      },
      {
        path: FlexTimeManagerRoutes.ACTIVITY_TEMPLATES,
        canActivate: [AccessRestrictionsGuard],
        component: FtmActivityTemplatesComponent,
        data: {
          title: 'Activity Template',
          description: '',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [
                MingaPermission.FLEXTIME_MANAGE_OWN_ACTIVITY,
                MingaPermission.FLEX_TIME_MANAGE,
              ],
            },
          },
        },
      },
      {
        path: FlexTimeManagerRoutes.SETTINGS,
        canActivate: [AccessRestrictionsGuard],
        component: FtmSettingsComponent,
        data: {
          title: 'Settings',
          description: 'Manage flex time settings',
          navigationVisibility: true,
          accessRestrictions: {
            permissions: {
              atLeastOne: [MingaPermission.MINGA_ACCOUNT_MANAGE],
            },
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    FlexTimeManagerModule,
    FtmDashboardModule,
    FtmReportsModule,
    FtmPeriodsModule,
    FtmActivitiesModule,
    FtmActivityTemplatesModule,
    FtmSettingsModule,
    RouterModule.forChild(FLEX_TIME_MANAGER_ROUTES),
  ],
})
export class FlexTimeManagerRoutesModule {}
