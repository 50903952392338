import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  KeyValueDiffers,
  OnChanges,
  OnDestroy,
  Self,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { Subscription } from 'rxjs';

export const DEFAULT_REQUIRED_LABEL_SUFFIX = ' (optional)';

@Directive({
  // mg-form-field is an extension of mat-form-field. Do not add other selectors
  // here without thinking.
  selector: 'mat-form-field[mgFormField]',
})
export class FormFieldDirective implements AfterViewInit, OnChanges, OnDestroy {
  private labelDiffer?: any = null;
  private formFieldChangesSub: Subscription = new Subscription();
  private _label?: any = null;

  @Input('mgFormStyles')
  @HostBinding('class.mg-form-field')
  enableFormFieldStyles: boolean = true;

  @Input('mgNoFloatTop')
  @HostBinding('class.no-float-top')
  noFloatTop: boolean = false;

  @Input('mgNoDisabledText')
  @HostBinding('class.mg-no-disabled-input-text')
  noDisabledText: boolean = false;

  @Input('mgNoHintMargin')
  @HostBinding('class.mg-no-input-margin')
  noHintMargin: boolean = false;

  // disables the adding of "(optional" suffix when not required
  @Input('mgHideOptionalMarker')
  @HostBinding('class.mg-no-optional-suffix')
  hideOptionalMarker: boolean = false;

  @Input('mgHideInputElement')
  @HostBinding('class.mg-no-visible-input')
  hideInput: boolean = false;

  @HostBinding('class.mg-hide-required-marker')
  @Input('hideRequiredMarker')
  hideRequiredMarker: boolean = true;

  @HostBinding('class.mg-multi-line-hint')
  @Input('mgMultiLineHint')
  multiLineHint: boolean = false;

  @Input('mgReadOnly')
  @HostBinding('class.mg-readonly')
  readOnly: boolean = false;

  @Input('mgResponsiveHeight')
  @HostBinding('class.mg-responsive-height')
  responsiveHeight: boolean = false;

  @Input('mgNoBoldFontWeight')
  @HostBinding('class.mg-normal-font-weight')
  noBoldFonts: boolean = false;

  @HostBinding('class.mg-has-value')
  get hasValue() {
    return this.matFormField ? !!this.matFormField._control.value : false;
  }

  constructor(
    private element: ElementRef,
    private matFormField: MatFormField,
  ) {}

  // private control: MatFormFieldControl<any>,
  @HostListener('window:resize', [])
  onResize() {
    requestAnimationFrame(() => this.updateHintHeight());
  }

  ngAfterViewInit() {
    requestAnimationFrame(() => this.updateHintHeight());

    if (this.matFormField) {
      // hide required by default... (unless it's set differently in the @Input)
      this.matFormField.hideRequiredMarker = this.hideRequiredMarker;

      this.formFieldChangesSub =
        this.matFormField._control.stateChanges.subscribe(ev => {
          this.updateRequiredLabelling();
        });

      this.updateRequiredLabelling();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.multiLineHint) {
      requestAnimationFrame(() => this.updateHintHeight());
    }
    if (changes.hideOptionalMarker) {
      this.updateRequiredLabelling();
    }
  }

  ngOnDestroy() {
    this.formFieldChangesSub.unsubscribe();
  }

  /**
   * Add or remove "(Optional)" suffix text from mat-label when this
   * form field is required
   */
  updateRequiredLabelling() {
    // css handles the suffix text, we just need to re-calculate the gap
    // if changed
    this.matFormField.updateOutlineGap();
  }

  updateHintHeight() {
    const el: HTMLElement = this.element.nativeElement;
    const hintEls = el.getElementsByClassName('mat-form-field-hint-wrapper');

    if (hintEls.length > 0) {
      const hintElement = hintEls[0];
      const hintStyles = window.getComputedStyle(hintElement);

      if (this.multiLineHint) {
        el.style.setProperty('--hint-height', hintStyles.height);
      } else {
        el.style.removeProperty('--hint-height');
      }
    }
  }
}
