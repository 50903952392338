<div
  class="rewards-list"
  *ngIf="(rewardTypesList$ | async)?.length > 0">
  <ng-container
    *ngFor="let rewardType of rewardTypesList$ | async"
    [ngTemplateOutlet]="rewardItemTemplate"
    [ngTemplateOutletContext]="{
      $implicit: rewardType.imagePath,
      id: rewardType.id,
      title: rewardType.name,
      icon: MESSAGES.REWARD_ICON,
      value: rewardType.pointCost,
      description: rewardType.description
    }">
  </ng-container>
</div>

<ng-template
  #rewardItemTemplate
  let-imagePath
  let-id="id"
  let-title="title"
  let-icon="icon"
  let-value="value"
  let-description="description">
  <div
    class="reward-container"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="12px">
    <div
      class="reward-image"
      fxFlex.gt-sm="120px"
      fxFlex.lt-md="90px">
      <mg-image
        *ngIf="imagePath; else defaultIconTpl"
        background-size="cover"
        [srcs]="[imagePath]">
      </mg-image>
    </div>
    <div
      class="reward-details"
      fxFlex="grow"
      fxLayout="column"
      fxLayoutAlign="start start">
      <div class="reward-details-content">
        <div
          class="reward-icon"
          fxLayout="row"
          fxLayoutAlign="end center"
          fxLayoutGap="5px">
          <mg-icon>{{ icon }}</mg-icon>
          <mg-text
            fxHide.lt-md
            variant="header-lg">
            {{ value }}
          </mg-text>
          <mg-text
            fxHide.gt-sm
            variant="header-sm">
            {{ value }}
          </mg-text>
        </div>
        <mg-text
          class="reward-title"
          variant="header-md-secondary"
          [title]="title"
          [numberOfLines]="1">
          {{ title }}
        </mg-text>
        <mg-text
          variant="body-sm"
          textAlign="left"
          spacing="1"
          [numberOfLines]="2">
          {{ description }}
        </mg-text>
        <ng-container *ngIf="canRedeemPoints$ | async">
          <mg-btn
            class="reward-redeem-button"
            size="small"
            (pressed)="openPeopleSelector(title, id)">
            {{ MESSAGES.REDEEM_BUTTON }}
          </mg-btn>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultIconTpl>
  <mg-icon>mg-points-menu</mg-icon>
</ng-template>
