export type Environment = 'stage' | 'live' | 'dev';

export type Coercer<T> = {
  [K in keyof T]: () => T[K];
};

export type CoercedEnvironment = Coercer<IEnvironmentConfig>;

export interface IEnvironmentConfigFirebase {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string; // this is pulled dynamically when firebase initializes app: https://firebase.google.com/docs/analytics/get-started?platform=web#add-sdk
}
export interface IEnvironmentConfigCountry {
  country: string;
  apiUrl: string;
  cdnUrl: string;
  suggestionUrl: string;
  pinPrefix: string[];
  mingaJoinCodePrefix: string[];
  idBucket: string;
}

export interface IEnvironmentConfig {
  mgsrvGatewayHost: string;
  app_origin: string;
  environment: Environment;
  websiteAnalyticsId: string;
  gtm?: GtmConfig;
  countryAppUrls: IEnvironmentConfigCountry[];
  version: string;
  firebaseConfig: IEnvironmentConfigFirebase;
  joinCodeCollection: string;
  assetUrlPrefix: string;
  clever: {
    clientId: string;
    redirectUri: string;
    devMode: boolean;
    loginEnabled: boolean;
  };
  cleverEnableLogin: boolean;
  minimumVersion: string;
  idBucket: string;
  suggestionUrl: string;
  notificationsReminderDuration: number;
  moduleUpgradeHubspotUrl: string;
  remoteConfigFetchIntervalMillis: number;
  kioskEnabled: boolean;
  kioskLogoutRequiresPin: boolean;
  REMOTE_DEBUG: string;
  datePickerCustomPresetsEnabled: boolean;
}

export type GtmConfig = {
  id: string;
  gtm_auth: string;
  gtm_preview: string;
};

/**
 * This should never be used. This is worst case scenario.
 */
export const fallbackEnvironment: IEnvironmentConfig = {
  mgsrvGatewayHost: 'https://api.minga.io',
  app_origin: 'https://app.minga.io',
  environment: 'live',
  version: '',
  firebaseConfig: {
    apiKey: 'AIzaSyCQqKlvDCuwg3JpH9rirZBcFsC608vp1qg',
    authDomain: 'auth.minga.io',
    databaseURL: 'https://minga-platform.firebaseio.com',
    projectId: 'minga-platform',
    storageBucket: 'minga-platform.appspot.com',
    messagingSenderId: '804167793453',
    appId: '1:804167793453:web:67eb8151a5dcb011',
    measurementId: 'G-MVNLGH5CCZ',
  },
  joinCodeCollection: 'JoinCodeCountry',
  websiteAnalyticsId: 'G-KR90T0D2Q4',
  gtm: {
    id: 'GTM-TWVNR9CG',
    gtm_auth: 'CixNMDrxv7E7L3Xo24fu5A',
    gtm_preview: 'env-5',
  },
  assetUrlPrefix: 'https://cdn.minga.io/',
  countryAppUrls: [
    {
      country: 'CAN',
      apiUrl: 'https://api.minga.io',
      cdnUrl: 'https://cdn.minga.io/',
      suggestionUrl: 'http://suggestions.ca.minga.io/suggested_content/',
      pinPrefix: [],
      mingaJoinCodePrefix: [],
      idBucket: 'minga-platform-ca',
    },
    {
      country: 'USA',
      apiUrl: 'https://api.us.minga.io',
      cdnUrl: 'https://cdn.us.minga.io/',
      suggestionUrl: 'http://suggestions.us.minga.io/suggested_content/',
      pinPrefix: [],
      mingaJoinCodePrefix: [],
      idBucket: 'minga-platform-usa',
    },
    {
      country: 'INTL',
      apiUrl: 'https://api.minga.io',
      cdnUrl: 'https://cdn.minga.io/',
      suggestionUrl: 'http://suggestions.ca.minga.io/suggested_content/',
      pinPrefix: [],
      mingaJoinCodePrefix: [],
      idBucket: 'minga-platform-ca',
    },
  ],
  clever: {
    clientId: '913ef69e7a22794319c4',
    devMode: false,
    loginEnabled: false,
    redirectUri:
      'https://prod-chargebee-webhooks-us-kvl76qeaua-uc.a.run.app/clever/auth/callback',
  },
  cleverEnableLogin: false,
  minimumVersion: '',
  idBucket: 'minga-platform-usa',
  suggestionUrl: 'http://suggestions.us.minga.io/suggested_content/',
  notificationsReminderDuration: 704800000,
  moduleUpgradeHubspotUrl:
    'https://meetings.hubspot.com/jason1509/new-module-request',
  remoteConfigFetchIntervalMillis: 3600000,
  kioskEnabled: false,
  kioskLogoutRequiresPin: true,
  REMOTE_DEBUG: '',
  datePickerCustomPresetsEnabled: false,
};

export const environment: IEnvironmentConfig = <any>(
  Object.assign({}, fallbackEnvironment)
);

export function getFirebaseConfig(): IEnvironmentConfigFirebase {
  return environment.firebaseConfig;
}

export function getVersion() {
  return environment.version;
}

export function getAppOrigin() {
  return environment.app_origin;
}

export async function fetchEnvironmentConfig() {
  const response = await fetch('/config.json').catch(err => {
    console.error(err);
    return null;
  });

  if (!response) return;

  const jsonResponse = await response.json().catch(err => {
    console.error(err);
    return null;
  });

  if (!jsonResponse) return;
  Object.assign(environment, jsonResponse);
}
