import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UpdatePeopleListState {
  archiveUsers = false;
  defaultRole = 'STUDENT';
  sendPinEmails = false;
  updateOrSkip: 'skip' | 'update' = 'update';
  useStudentIdAsId = false;
  stateChanged: BehaviorSubject<UpdatePeopleListState> =
    new BehaviorSubject<UpdatePeopleListState>(this);
}
