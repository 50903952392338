import { Component, OnInit } from '@angular/core';

import * as day from 'dayjs';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { MingaManagerService } from 'src/app/services/MingaManager';
import { SmsMinga } from 'src/app/services/SmsMinga';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { ModalOverlayService } from '@shared/components/modal-overlay';

import { MmSubscriptionFeatureModalComponent } from './components/mm-subscription-feature-modal/mm-subscription-feature-modal.component';
import { MmSubscriptionUpgradeModalComponent } from './components/mm-subscription-upgrade-modal/mm-subscription-upgrade-modal.component';
import {
  BEHAVIOR_CONFIG,
  CHECKIN_CONFIG,
  FLEXTIME_CONFIG,
  HALLPASS_CONFIG,
  MingaManagerSubscriptionMessage,
  SubscriptionInfoType,
  subscriptionInfoConfig,
} from './constants';
import { moduleInfoConfig } from './constants/module-config.constants';
import { PromotionalModalConfig, SubscriptionInfo } from './types';

@Component({
  selector: 'mg-mm-subscription',
  templateUrl: './mm-subscription.component.html',
  styleUrls: ['./mm-subscription.component.scss'],
})
export class MmSubscriptionComponent implements OnInit {
  // Constants
  public readonly MESSAGES = MingaManagerSubscriptionMessage;
  public readonly SUB_INFO_TYPE = SubscriptionInfoType;

  // Subjects and Observables
  private _subInfoSubject = new BehaviorSubject<SubscriptionInfo[] | []>([]);
  public readonly subInfo$ = this._subInfoSubject.asObservable();
  public readonly moduleInfo$ = of(moduleInfoConfig);
  public hasDisabledModule$: Observable<boolean>;

  constructor(
    public mingaSettings: MingaSettingsService,
    private _mingaService: MingaManagerService,
    private _smsService: SmsMinga,
    private _modalOverlay: ModalOverlayService,
  ) {}

  async ngOnInit() {
    this._setupSubscriptionConfig();

    this.moduleInfo$.subscribe(async modules => {
      const moduleDisabledStates = await Promise.all(
        modules.map(async module => {
          const isEnabled = await this.mingaSettings.getModuleEnabled(
            module.moduleToggleKey,
          );
          return isEnabled === false;
        }),
      );
      this.hasDisabledModule$ = of(moduleDisabledStates.some(state => state));
    });
  }

  public openUpgradeRequestModal() {
    this._modalOverlay.open(MmSubscriptionUpgradeModalComponent);
  }

  public isSMSCountNearMax(info: SubscriptionInfo): boolean {
    if (!info.value || !info.maxValue) return false;
    const count = parseInt(info.value, 10);
    const max = parseInt(info.maxValue, 10);
    return count / max >= 0.8;
  }

  private async _setupSubscriptionConfig() {
    const subConfig = [...subscriptionInfoConfig];
    const mingaSubscription = (
      await this._mingaService.getUserMingaAccountInfo()
    ).dashboardInfo;
    const smsSubscription = await this._smsService.getDetails();

    const startDateObject = mingaSubscription.subscriptionStartDate;
    const startDate = startDateObject
      ? day()
          .year(startDateObject.year)
          .month(startDateObject.month - 1)
          .date(startDateObject.day)
          .format('MMM D, YYYY')
      : 'N/A';
    const endDateObject = mingaSubscription.subscriptionEndDate;
    const endDate = endDateObject
      ? day()
          .year(endDateObject.year)
          .month(endDateObject.month - 1)
          .date(endDateObject.day)
          .format('MMM D, YYYY')
      : 'N/A';

    subConfig.forEach(config => {
      switch (config.type) {
        case SubscriptionInfoType.STUDENT_TIER:
          config.value = mingaSubscription.billableCount.toString();
          config.maxValue = mingaSubscription.mingaSize.toString();
          break;
        case SubscriptionInfoType.START_DATE:
          config.value = startDate;
          break;
        case SubscriptionInfoType.END_DATE:
          config.value = endDate;
          break;
        case SubscriptionInfoType.SMS_MESSAGES:
          config.value = smsSubscription.currentSendCount.toString();
          config.maxValue = smsSubscription.maxSendCount.toString();
          break;
        default:
          throw new Error('Invalid subscription detail type');
      }
    });

    this._subInfoSubject.next(subConfig);
  }

  public openHallPassModal(): void {
    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: HALLPASS_CONFIG,
      },
    );
  }

  public openBehaviorModal(): void {
    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: BEHAVIOR_CONFIG,
      },
    );
  }

  public openCheckInModal(): void {
    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: CHECKIN_CONFIG,
      },
    );
  }

  public openFlexTimeModal(): void {
    this._modalOverlay.open<PromotionalModalConfig>(
      MmSubscriptionFeatureModalComponent,
      {
        data: FLEXTIME_CONFIG,
      },
    );
  }
}
