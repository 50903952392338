import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ReplaySubject } from 'rxjs';

import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { LandingService } from '../../services';

@Component({
  selector: 'mg-landing-password',
  templateUrl: './landing-password.component.html',
  styleUrls: ['./landing-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPasswordComponent implements OnInit, OnDestroy {
  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);
  private _destroyed = false;

  // State

  passwordFormControl = new FormControl();
  formGroup = new FormGroup({
    password: this.passwordFormControl,
  });

  /** Component constructor */
  constructor(
    public landing: LandingService,
    public router: Router,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _auth: AuthService,
    private _rootService: RootService,
    private _location: Location,
    private _matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (!this.landing.email) this.router.navigate(['..']);
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._destroyed = true;
  }

  async onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    const email = this.landing.email;
    const password = this.formGroup.controls.password.value;

    const loginPromise = this._auth.login(email, password);

    const response = await this._rootService
      .addLoadingPromise(loginPromise)
      .catch(err => {
        this.snackMessage('Something went wrong. Please try again later');
        throw err;
      });

    if (response.success) {
      if (response.mingaName) {
        this.landing.mingaName = response.mingaName;
      }

      if (response.mingaLogo) {
        this.landing.mingaLogo = response.mingaLogo;
      }

      if (response.personName) {
        this.landing.personName = response.personName;
      }

      // If our component has already been destroyed, that means we've navigated
      // somewhere else already. Changing the route again could be jarring to
      // the user.
      if (!this._destroyed) {
        await this.router.navigate(['/landing/joining'], {
          skipLocationChange: true,
        });
      }
    } else {
      this.snackMessage(response.reason);
      return response;
    }
  }

  snackMessage(message: string): void {
    // This method is only here if we need to customize options later
    this._systemAlertSnackBar.error(message);
  }

  async onForgotPassword() {
    const options = {
      data: {
        title: 'dialog.forgotPassword.title',
        text: 'dialog.forgotPassword.body',
        translate: true,
        // 'mat-icon': 'error',
      },
    };

    // @TODO: Bazel - Uncomment this
    // const dialogRef = this.matDialog.open(MessageDialog, options);
  }

  goBack() {
    this._location.back();
  }
}
