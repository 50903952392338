import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { cacheBustUrl } from 'minga/app/src/app/util/image';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

@Component({
  selector: 'mg-person-avatar',
  templateUrl: './PersonAvatar.component.html',
  styleUrls: ['./PersonAvatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// The current persons avatar.
export class PersonAvatarComponent {
  public displayName: string;
  public avatarUrl: string;
  public color: string;

  @Input() noDisplayName: boolean;
  @Input() borderless: boolean;

  public readonly avatarUrl$: Observable<string> = this._auth.authPerson$.pipe(
    map(person => (person ? cacheBustUrl(person.avatarUrl) || '' : '')),
  );
  public readonly displayName$: Observable<string> =
    this._auth.authPerson$.pipe(
      map(person => (person ? person.displayName || '' : '')),
    );
  public readonly color$: Observable<string> = this._auth.authPerson$.pipe(
    map(person => (person ? person.badgeIconColor || '' : '')),
  );

  constructor(private _auth: AuthInfoService) {}
}
