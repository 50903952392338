import { ChangeDetectionStrategy, Component } from '@angular/core';

import { KioskMessage } from '@modules/kiosk/constants';
import { KioskService } from '@modules/kiosk/services';

import { MediaService } from '@shared/services/media';

@Component({
  selector: 'mg-kiosk-category-selector',
  templateUrl: './kiosk-category-selector.component.html',
  styleUrls: ['./kiosk-category-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskCategorySelectorComponent {
  // Constants

  public readonly MSG = KioskMessage;

  // Clean up

  // State

  // Component constructor

  constructor(public media: MediaService, public kiosk: KioskService) {}

  // Lifecycle
}
