export interface Person {
  hash: string;
  displayName: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  roleType: string;
  badgeIconColor: string;
  badgeIconUrl: string;
  badgeRoleName: string;
  receivingDirectMessages: boolean;
  directMessagesPersonallyDisabled: boolean;
  directMessagesMingaDisabled: boolean;
}

export namespace Person {
  export function defaultValue(): Person {
    return {
      hash: '',
      displayName: '',
      profileImageUrl: '',
      badgeIconColor: '',
      badgeIconUrl: '',
      badgeRoleName: '',
      firstName: '',
      lastName: '',
      roleType: '',
      receivingDirectMessages: false,
      directMessagesPersonallyDisabled: false,
      directMessagesMingaDisabled: false,
    };
  }
}
