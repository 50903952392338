<mg-content-publish-controls
  [(publishDate)]="publishDate"
  (publishDateChange)="triggerChange()"
  [(unpublishDate)]="unpublishDate"
  (unpublishDateChange)="triggerChange()"
  [(pinned)]="pinned"
  (pinnedChange)="triggerChange()"
  [(pinDays)]="pinDays"
  (pinDaysChange)="triggerChange()"
  [(endPinDate)]="endPinDate"
  (endPinDateChange)="triggerChange()"
  [stackInputs]="true"
  [readonlyPinDays]="false"
  [(commentingRoles)]="allowedCommentingRoleTypes"
  (commentingRolesChange)="triggerChange()"></mg-content-publish-controls>
