import { Injectable } from '@angular/core';

import { Observable, from, of, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, shareReplay } from 'rxjs/operators';

import { InMemoryAdapter } from './adapters/in-memory.adapter';
import {
  CacheConfig,
  CacheItem,
  CacheItemMethods,
  FetchOptions,
  StorageAdapter,
} from './cache.types';

const defaultConfig: CacheConfig = {
  ttl: 5,
};

const defaultFetch: FetchOptions = {
  revalidate: false,
};

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  // lets just hard code in memory adapter for now
  private _storageAdapter: StorageAdapter = new InMemoryAdapter();
  private _inFlightObservables = new Map<string, Observable<any>>();

  constructor() {}

  public create<T>(
    key: string | ((object?: any) => string),
    fetchFn: (data: any) => Promise<T>,
    config?: CacheConfig,
  ): CacheItemMethods<T> {
    const keyFinal = typeof key === 'function' ? key() : key;

    return {
      get: (data, options) => {
        const configFinal = {
          ...defaultConfig,
          ...(config || {}),
        };

        const opts = {
          ...defaultFetch,
          ...(options || {}),
        };

        return from(this._storageAdapter.get(keyFinal)).pipe(
          mergeMap((item: CacheItem<T> | undefined) => {
            if (
              this._isValidCacheItem(item, configFinal.ttl) &&
              !opts.revalidate
            ) {
              return item.value;
            } else {
              if (this._inFlightObservables.has(keyFinal)) {
                return this._inFlightObservables.get(keyFinal)!;
              }

              const fetchObservable = this._fetchAndCacheValue(keyFinal, () =>
                fetchFn(data),
              ).pipe(
                catchError(err => {
                  this._inFlightObservables.delete(keyFinal);
                  return throwError(err);
                }),
                shareReplay(1),
              );

              this._inFlightObservables.set(keyFinal, fetchObservable);

              return fetchObservable.pipe(
                finalize(() => this._inFlightObservables.delete(keyFinal)),
              );
            }
          }),
          catchError(err => {
            return throwError(err);
          }),
        );
      },
      set: (data: T) => this._setCache(keyFinal, data),
      clear: () => this._clear(keyFinal),
    };
  }

  public clearAll(): void {
    this._storageAdapter.clearAll();
  }

  private _clear(key: string): void {
    this._storageAdapter.delete(key);
  }

  private _setCache<T>(key: string, value: T): void {
    const now = new Date();
    this._storageAdapter.set(key, { date: now, value: of(value) });
  }

  private _isValidCacheItem<T>(item: CacheItem<T>, ttl: number): boolean {
    if (!item?.date) return false;
    const now = new Date();
    return now.getTime() - item.date.getTime() <= ttl * 60 * 1000;
  }

  private _fetchAndCacheValue<T>(
    key: string,
    fetchFn: (data?: any) => Promise<T>,
  ): Observable<T> {
    return from(fetchFn()).pipe(
      mergeMap(value => {
        const date = new Date();
        const cacheItem: CacheItem<T> = { date, value: of(value) };
        this._setCache(key, value);
        return cacheItem.value;
      }),
      catchError(err => {
        return throwError(err);
      }),
      shareReplay(1),
    );
  }
}
