<ng-content select="[mgStreamItem]"></ng-content>

<div class="before-items">
  <ng-content select="[mg-before-stream-items]"></ng-content>
</div>

<virtual-scroller
  #virtualScroller
  (vsStart)="_onVsStart($event)"
  (vsEnd)="_onVsEnd($event)"
  [items]="(items$ | async) || []"
  [bufferAmount]="bufferAmount"
  [horizontal]="isHorizontalVScroller"
  [enableUnequalChildrenSizes]="true"
  [modifyOverflowStyleOfParentScroll]="false"
  [parentScroll]="parentScroll">
  <div
    class="items"
    #container>
    <ng-container *ngIf="showExtraitems">
      <ng-container *ngFor="let extraHeadItemTpl of extraHeadItems">
        <div class="item">
          <ng-container *ngTemplateOutlet="extraHeadItemTpl"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-template
      ngFor
      let-item
      let-viewIndex="viewIndex"
      [ngForOf]="virtualScroller.viewPortItems"
      [ngForTrackBy]="streamItemTrackBy">
      <div class="item">
        <ng-container
          *ngTemplateOutlet="
            streamItemTemplate;
            context: {
              $implicit: item.item,
              item: item.item,
              itemId: item.itemId,
              itemIndex: item.itemIndex,
              viewIndex: viewIndex,
              viewPortItems: virtualScroller.viewPortItems
            }
          "></ng-container>
      </div>
    </ng-template>

    <ng-container *ngIf="showExtraitems">
      <ng-container *ngFor="let extraTailItemTpl of extraTailItems">
        <div class="item">
          <ng-container *ngTemplateOutlet="extraTailItemTpl"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-container [ngSwitch]="stream?.error?.status">
    <div
      class="mg-centered user-content"
      *ngSwitchCase="'GrpcError'">
      <p>
        There was an issue loading this stream ({{ getStreamGrpcErrorCode() }})
      </p>
      <a
        href="javascript:;"
        (click)="restartStream()"
        >Retry</a
      >
    </div>
    <div
      class="mg-centered user-content"
      *ngSwitchCase="'NetworkError'"
      class="network-error">
      <p>Network Error</p>
      <a
        href="javascript:;"
        (click)="restartStream()"
        >Retry</a
      >
    </div>
  </ng-container>

  <div class="after-items">
    <ng-content select="[mg-after-stream-items]"></ng-content>
  </div>

  <mg-spinner
    *ngIf="loading$ | async"
    diameter="30"
    thickness="4"></mg-spinner>

  <div *ngIf="frontExhausted && !isStreamEmpty()">
    <ng-content select="[mg-stream-front-exhausted]"></ng-content>
  </div>

  <div *ngIf="isStreamEmpty() && !stream?.error?.status">
    <ng-content select="[mg-stream-empty]"></ng-content>
  </div>
</virtual-scroller>
