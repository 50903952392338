<table class="mat-calendar-table">
  <thead class="mat-calendar-table-header">
    <tr>
      <th
        *ngFor="let day of _weekdays"
        [attr.aria-label]="day.long">
        {{ day.short || day.narrow }}
      </th>
    </tr>
    <tr>
      <th
        class="mat-calendar-table-header-divider"
        colspan="7"
        aria-hidden="true"></th>
    </tr>
  </thead>
  <tbody
    mg-calendar-body
    [multiSelectDays]="filteredDays"
    [filteringDays]="filteredFilteringDays"
    [label]="_monthLabel"
    [rows]="_weeks"
    [todayValue]="_todayDate"
    [labelMinRequiredCells]="3"
    [pastMonth]="isActiveMonthInThePast()"
    [activeCell]="_dateAdapter.getDate(activeDate) - 1"
    (onLastRowClick)="_onLastRowClick($event)"
    (selectedValueChange)="_dateSelected($event)"></tbody>
</table>
