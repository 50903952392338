import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { ReportTypes } from 'minga/domain/reportTypes';

import { MingaManagerRoutes } from '@modules/minga-manager/constants';
import { MingaManagerMessages } from '@modules/minga-manager/constants';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { ScheduledReportsHistoryService } from '@shared/services/scheduled-reports/scheduled-reports-history.service';

import { MmSrReportsEditComponent } from '../components/mm-sr-reports-edit/mm-sr-reports-edit.component';
import { ScheduleReportMessages, getMapperConfig } from '../constants';
import {
  ClientSupportedScheduledReportFilter,
  MmSrReportsEditData,
  MmSrReportsEditResponse,
  ScheduledReportType,
  SupportedDashboardFilterType,
} from '../types';

@Injectable({ providedIn: 'root' })
export class MmScheduledReportsService {
  private _activatedRouteSubscription: Subscription;
  constructor(
    private _modalOverlay: ModalOverlayService<
      MmSrReportsEditResponse,
      MmSrReportsEditData
    >,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _scheduledReportsHistoryService: ScheduledReportsHistoryService,
    private _systemAlertModal: SystemAlertModalService,
    private _systemSnackBar: SystemAlertSnackBarService,
  ) {}
  public openModal(data?: MmSrReportsEditData) {
    return this._modalOverlay.open<MmSrReportsEditData>(
      MmSrReportsEditComponent,
      {
        data,
        disposeOnNavigation: false,
      },
    );
  }

  public async scheduleReport(
    reportType: ReportTypes,
    filters: SupportedDashboardFilterType,
  ) {
    const prepopulatedFields = this._getFiltersByType(reportType, filters);

    const modalRef = await this.openModal({
      prepopulatedFields,
    });

    modalRef.afterClosed.subscribe(async response => {
      const { type } = response;

      if (type === ModalOverlayServiceCloseEventType.CREATE) {
        this._systemSnackBar.open({
          type: 'success',
          message: ScheduleReportMessages.SCHEDULED_REPORT_CREATED_SUCCESS,
          action: {
            label: ScheduleReportMessages.SCHEDULED_REPORT_CREATED_BTN,
            onClick: () => {
              this._router.navigate([
                MingaManagerRoutes.ROOT,
                MingaManagerRoutes.SCHEDULED_REPORTS,
              ]);
            },
          },
        });
      }
    });
  }

  public captureDownloadReportAction() {
    this._activatedRouteSubscription =
      this._activatedRoute.queryParams.subscribe(async params => {
        if (params.downloadPastReport) {
          const id = parseInt(params.downloadPastReport, 10);
          this._handleReportDownload(id);
        }
      });
  }

  private async _handleReportDownload(id: number) {
    try {
      const pastReport = await this._scheduledReportsHistoryService.get(id);
      const modelRef = await this._systemAlertModal.open({
        modalType: SystemAlertModalType.DEFAULT,
        heading: MingaManagerMessages.DOWNLOAD_REPORT_TITLE,
        message: `Do you want to download ${pastReport.name} report?`,
        closeBtn: MingaManagerMessages.DOWNLOAD_REPORT_CLOSE_BTN,
        confirmActionBtn: MingaManagerMessages.DOWNLOAD_REPORT_DOWNLOAD_BTN,
      });

      const response = await modelRef.afterClosed().toPromise();

      if (response?.type === SystemAlertCloseEvents.CONFIRM) {
        await this._scheduledReportsHistoryService.download(id);
      }

      this._activatedRouteSubscription?.unsubscribe();
    } catch (e) {
      this._systemSnackBar.error(MingaManagerMessages.DOWNLOAD_REPORT_ERROR);
    }
  }

  /**
   * Transform the filters from the main report dashboards to the filters on scheduled reports
   * since they interfaces arent the same as well as the required fields
   */
  private _getFiltersByType(
    type: ReportTypes,
    filters: SupportedDashboardFilterType,
  ): ClientSupportedScheduledReportFilter {
    const CONFIG = getMapperConfig(filters);

    return CONFIG[type] || CONFIG[ScheduledReportType.PBIS_HISTORY];
  }
}
