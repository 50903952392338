import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, tap } from 'rxjs/operators';

import { ConversationNavigator } from 'minga/app/src/app/modules/direct-message/services/conversation-navigator.service';

import { MessagingActions } from '../actions';
import { MessagingService } from '../services';

@Injectable()
export class MessagingStoreEffects {
  // clang-format off
  fetchMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.fetchMessages),
      mergeMap(({ conversationId }) => {
        return this.messaging.getMessages(conversationId).pipe(
          debounceTime(500),
          map(messages =>
            MessagingActions.fetchMessagesSuccess({
              conversationId,
              messages,
            }),
          ),
          catchError((err: any) =>
            of(
              MessagingActions.fetchMessagesError({
                conversationId,
                error: err,
              }),
            ),
          ),
        );
      }),
    ),
  );

  fetchMessagesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.fetchMessagesSuccess),
      map(success =>
        MessagingActions.setMessages({
          conversationId: success.conversationId,
          messages: success.messages,
        }),
      ),
    ),
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.sendMessage),
      mergeMap(payload => {
        const {
          conversationId,
          tempMessageId,
          authorPersonHash,
          bodyText,
          assetPathList,
        } = payload;

        return from(
          this.messaging.sendMessage(conversationId, bodyText, assetPathList),
        ).pipe(
          map(response =>
            MessagingActions.sendMessageSuccess({
              conversationId,
              tempMessageId,
              message: {
                authorPersonHash,
                body: bodyText,
                createdDate: new Date(),
                id: response.messageId,
                statusMap: new Map(),
                attachmentList: response.attachmentList,
              },
            }),
          ),
          catchError((err: any) =>
            of(
              MessagingActions.sendMessageError({
                conversationId,
                error: err,
                tempMessageId,
              }),
            ),
          ),
        );
      }),
    ),
  );

  fetchConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.fetchConversation),
      mergeMap(({ conversationId }) => {
        return from(this.messaging.getConversation(conversationId)).pipe(
          map(conv =>
            MessagingActions.fetchConversationSuccess({
              conversation: conv,
              conversationId: conv.id,
            }),
          ),
          catchError((err: any) =>
            of(
              MessagingActions.fetchConversationError({
                conversationId,
                error: err,
              }),
            ),
          ),
        );
      }),
    ),
  );

  fetchConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagingActions.fetchConversations),
      mergeMap(() => {
        return this.messaging.getConversations().pipe(
          map(conversations =>
            MessagingActions.fetchConversationsSuccess({
              conversations,
            }),
          ),
          catchError((err: any) =>
            of(
              MessagingActions.fetchConversationsError({
                error: err,
              }),
            ),
          ),
        );
      }),
    ),
  );

  markConversationAsRead$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MessagingActions.markConversationAsRead),
        mergeMap(({ conversationId }) =>
          from(this.messaging.markConversationsAsRead([conversationId])),
        ),
      ),
    { dispatch: false },
  );

  clickNewMessageNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MessagingActions.clickNewMessageNotification),
        mergeMap(({ conversationId }) =>
          of(this.conversationNavigator.navigateHome()),
        ),
      ),
    { dispatch: false },
  );

  printErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MessagingActions.fetchConversationError,
          MessagingActions.fetchConversationsError,
          MessagingActions.fetchMessagesError,
          MessagingActions.sendMessageError,
        ),
        tap(action => console.error(action.error)),
      ),
    { dispatch: false },
  );
  // clang-format on

  constructor(
    private actions$: Actions<MessagingActions.Union>,
    private messaging: MessagingService,
    private conversationNavigator: ConversationNavigator,
  ) {}
}
