import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

const DEFAULT_NAVBAR_LAYOUT = 'spread';
const DEFAULT_NAVBAR_PAD_SAFE_AREA = '';
const DEFAULT_NAVBAR_DIRECTION = 'row';
const DEFAULT_NAVBAR_SIZE = 'normal';

@Component({
  selector: 'mg-navbar',
  templateUrl: './Navbar.component.html',
  styleUrls: ['./Navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Navbar {
  private _navComponents: string[];

  @Input()
  layout: '' | 'spread' = DEFAULT_NAVBAR_LAYOUT;

  @Input()
  padSafeArea: '' | 'top' | 'bottom' = DEFAULT_NAVBAR_PAD_SAFE_AREA;

  @Input()
  direction: 'row' | 'column' = DEFAULT_NAVBAR_DIRECTION;

  @Input()
  size: 'normal' | 'small' = DEFAULT_NAVBAR_SIZE;

  @HostBinding('class.direction-row')
  get directionRow() {
    return this.direction === 'row';
  }

  @HostBinding('class.direction-column')
  get directionColumn() {
    return this.direction === 'column';
  }

  @Input()
  set components(value: string | string[]) {
    if (typeof value == 'string') {
      this._navComponents = value.split('|');
    } else if (Array.isArray(value)) {
      this._navComponents = value;
    } else {
      console.warn('[Navbar] Unknown components value:', value);
    }
  }

  get components() {
    return [].concat(this._navComponents);
  }
}
