import { Validators } from '@angular/forms';

import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';

export enum PmTeamsEditMessages {
  MODAL_TITLE_NEW = 'Create A School Team',
  MODAL_TITLE_EXISTING = 'Edit School Team',

  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_SAVE = 'Save',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CANCEL = 'Cancel',

  FIELD_LABEL_NAME = 'School team name',
  FIELD_LABEL_COLOR = 'Color',
}

export enum PmTeamsEditFormFields {
  NAME = 'name',
  COLOR = 'color',
}

export const PM_TEAMS_FORMGROUP = {
  [PmTeamsEditFormFields.NAME]: [
    '',
    [Validators.required, Validators.minLength(1), Validators.maxLength(25)],
  ],
  [PmTeamsEditFormFields.COLOR]: [undefined],
};

export const DEFAULT_SCHOOL_TEAM: IMembershipList = {
  listType: MembershipListType.SCHOOL_TEAM,
};
