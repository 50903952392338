import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { NgSelectModule } from '@ng-select/ng-select';
import { ColorPickerModule } from 'ngx-color-picker';

import { MgEmptyStateModule } from 'minga/app/src/app/components/EmptyState';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { ActivateDeactivateToggleButtonModule } from 'src/app/components/ActivateDeactivateToggleButton';
import { MgFormFieldModule } from 'src/app/form-field';
import { MgPipesModule } from 'src/app/pipes';

import { FormModule } from '@shared/components/form';
import { FormRestrictionModule } from '@shared/components/form-restriction-input/form-restriction-input.module';
import { GenericModule } from '@shared/components/generic';
import { ImageViewerModalModule } from '@shared/components/image-viewer-modal';
import { OverlayModule } from '@shared/components/modal-overlay';
import { TextModule } from '@shared/components/text';
import { TilesCarouselModule } from '@shared/components/tiles-carousel';
import { TooltipModule } from '@shared/components/tooltip';

import { CheckinManagerReasonsComponent } from './checkin-manager-reasons.component';
import { CheckinManagerReasonsEditComponent } from './components/checkin-manager-reasons-edit/checkin-manager-reasons-edit.component';
import { CheckinManagerReasonsTableComponent } from './components/checkin-manager-reasons-table/checkin-manager-reasons-table.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgSpinnerModule,
    MgIconModule,
    MgEmptyStateModule,
    OverlayModule,
    ActivateDeactivateToggleButtonModule,
    MgPipesModule,
    MgFormFieldModule,
    TilesCarouselModule,
    MgImageModule,
    TooltipModule,
    FormModule,
    ColorPickerModule,
    FormRestrictionModule,
    ImageViewerModalModule,
    TextModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSlideToggleModule,
  ],
  declarations: [
    CheckinManagerReasonsComponent,
    CheckinManagerReasonsEditComponent,
    CheckinManagerReasonsTableComponent,
  ],
  exports: [
    CheckinManagerReasonsComponent,
    CheckinManagerReasonsEditComponent,
    CheckinManagerReasonsTableComponent,
  ],
})
export class CheckinManagerReasonsModule {}
