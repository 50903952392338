import { Injectable } from '@angular/core';

import { environment } from 'minga/app/src/environment';
import { firebase } from 'minga/app/src/firebase';
import { SentryService } from 'src/app/minimal/services/Sentry/Sentry.service';

import { IEnvironmentToRemoteConfig } from '@shared/utils/remote-config';

@Injectable({ providedIn: 'root' })
export class RemoteConfigService {
  constructor(private _sentryService: SentryService) {}

  /**
   * Meant to run once on startup to fetch and activate new remote config
   */
  public async startup() {
    // set default values
    firebase.remoteConfig().defaultConfig =
      IEnvironmentToRemoteConfig(environment);
    // minimum caching time between fetches
    firebase.remoteConfig().settings.minimumFetchIntervalMillis =
      environment.remoteConfigFetchIntervalMillis;

    await firebase.remoteConfig().fetchAndActivate();
  }

  public async refreshConfig(): Promise<void> {
    try {
      // i'm hoping temporarily setting this will force a fetch of new config
      firebase.remoteConfig().settings.minimumFetchIntervalMillis = 0;
      await firebase.remoteConfig().fetchAndActivate();
    } catch (e) {
      this._sentryService.captureMessageAsError(
        'Error refreshing remote config',
        e,
      );
    } finally {
      firebase.remoteConfig().settings.minimumFetchIntervalMillis =
        environment.remoteConfigFetchIntervalMillis;
    }
  }
}
