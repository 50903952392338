<mg-manager-overlay
  *ngIf="layout.useLegacyLayout$ | async"
  (overlayClose)="returnFromManager()"
  [colorTheme]="colorTheme">
  <ng-template #overlayTitle>
    <mg-icon>consequence-o</mg-icon>&nbsp; {{ MESSAGES.PAGE_TITLE }}
  </ng-template>
  <router-outlet></router-outlet>
</mg-manager-overlay>

<ng-container *ngIf="(layout.useLegacyLayout$ | async) === false">
  <router-outlet></router-outlet>
</ng-container>
