import { EventEmitter, Injectable, OnDestroy } from '@angular/core';

import { omit } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import { IMembershipList } from 'minga/domain/membershipList';
import { mingaSettingTypes } from 'minga/util';
import { HallPassService } from 'src/app/services/HallPass';
import { ListMembershipService } from 'src/app/services/ListMembership';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { HpmRestrictionsEditComponent } from '../components/hpm-restrictions-edit/hpm-restrictions-edit.component';
import {
  HP_RESTRICTIONS_NOPARTYLISTS,
  HP_RESTRICTIONS_PASSLISTS,
  HP_SELF_GRANT_LISTS,
  RESTRICT_LIST_TYPE_PRETTY_NAME,
} from '../constants';
import {
  HpmRestrictionsEditModalData,
  HpmRestrictionsEditModalResponse,
  HpmRestrictionsListType,
  HpmRestrictionsLists,
} from '../types';
import { getInitialListsState } from '../utils';

@Injectable()
export class HpmRestrictionsService implements OnDestroy {
  /** Constants */
  public readonly SELF_GRANT_LISTS = HP_SELF_GRANT_LISTS;
  public readonly PASSLISTS = HP_RESTRICTIONS_PASSLISTS;
  public readonly NOPARTYLISTS = HP_RESTRICTIONS_NOPARTYLISTS;

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Refresh List Subject */
  public readonly refreshNoPartyGroupLists = new Subject<void>();

  /** List Data */
  private _lists$ = new BehaviorSubject(
    getInitialListsState([
      ...this.PASSLISTS,
      ...this.NOPARTYLISTS,
      ...this.SELF_GRANT_LISTS,
    ]),
  ) as BehaviorSubject<HpmRestrictionsLists>;
  private _refetchList$ = new EventEmitter<HpmRestrictionsListType>();
  public readonly lists$ = this._lists$.asObservable().pipe(share());
  public readonly selfGrantLists$: Observable<boolean>;

  /** Events */
  private _listCreated$ = new BehaviorSubject<number | undefined>(undefined);
  public readonly listCreated$ = this._listCreated$.asObservable();

  /** Loading */
  private _isLoading$ = new BehaviorSubject(true);
  public readonly isLoading$ = this._isLoading$.asObservable();

  /** Service Constructor */
  constructor(
    private _listService: ListMembershipService,
    private _hpService: HallPassService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService<
      HpmRestrictionsEditModalResponse,
      HpmRestrictionsEditModalData
    >,
    private _settings: MingaSettingsService,
  ) {
    this.selfGrantLists$ = this._settings.getSettingValueObs(
      mingaSettingTypes.PASS_STUDENTS_GRANT,
    );

    this._refetchList$.subscribe(list => this._getListData(list));
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._lists$.complete();
    this._refetchList$.complete();
    this._listCreated$.complete();
    this._isLoading$.complete();
  }

  public async fetchAllLists() {
    this._getInitialListsData();
  }

  public refetchList(listType: HpmRestrictionsListType) {
    this._refetchList$.next(listType);
  }

  public async openRestrictionsEditModal(
    options?: HpmRestrictionsEditModalData,
  ) {
    const restrictionsEditModal = this._modalOverlay.open(
      HpmRestrictionsEditComponent,
      {
        data: { ...options },
        disposeOnNavigation: false,
      },
    );
    restrictionsEditModal.afterClosed.subscribe(response => {
      if (!response) return;
      const { type } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          setTimeout(() => this.refreshNoPartyGroupLists.next(), 100);
          break;
        }
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          break;
        }
        case ModalOverlayServiceCloseEventType.DELETE: {
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  public async createList(
    name: string,
    list: HpmRestrictionsListType,
  ): Promise<void> {
    try {
      const newList = await this._hpService.createHallPassList(
        name,
        list as any,
      );
      const { listType, id } = newList;
      const newListState = [...(this._lists$.value[listType] || [])];
      newListState.unshift(newList);
      this._lists$.next({
        ...this._lists$.value,
        [listType]: newListState,
      });
      this._listCreated$.next(id);
    } catch (err) {
      this._systemAlertSnackBar.error(err);
    }
  }

  public async updateList(list: IMembershipList): Promise<void> {
    try {
      const updatedList = await this._listService.updateMembershipList(list);
      const { listType, id } = updatedList;
      const newListState = [...this._lists$.value[listType]];
      const oldListIndex = newListState.findIndex(l => l.id === id);
      newListState.splice(oldListIndex, 1, updatedList);
      this._lists$.next({
        ...this._lists$.value,
        [listType]: newListState,
      });
      this._systemAlertSnackBar.success(
        `${updatedList.name} Updated Successfully.`,
      );
    } catch (error) {
      this._systemAlertSnackBar.error(`Failed to update.`);
    }
  }

  public async deleteList(
    listType: HpmRestrictionsListType,
    listId: number,
  ): Promise<void> {
    try {
      await this._listService.deleteMembershipList(listId);
      const newListState = [...this._lists$.value[listType]];
      const oldListIndex = newListState.findIndex(l => l.id === listId);
      newListState.splice(oldListIndex, 1);
      this._lists$.next({
        ...this._lists$.value,
        [listType]: newListState,
      });
      this._systemAlertSnackBar.success(`${name} Deleted Successfully.`);
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to delete.');
    }
  }

  private async _getInitialListsData(extraLists?: any[]) {
    const state = {} as HpmRestrictionsLists;
    this._isLoading$.next(true);
    try {
      await Promise.all(
        extraLists || [
          this._listService.getMembershipListByType(this.PASSLISTS),
          this._listService.getMembershipListByType(this.SELF_GRANT_LISTS),
          this._listService.getMembershipListByType(this.NOPARTYLISTS),
        ],
      ).then(async (lists: any) => {
        const flatArray = lists.flat();
        flatArray.forEach(list => {
          if (!list) return;
          if (Array.isArray(state[list.listType])) {
            state[list.listType].unshift(list);
          } else {
            state[list.listType] = Array.isArray(list) ? list : [list];
          }
        });
        const existingListTypes = flatArray.map(list => list.listType);
        await this._createMissingLists(existingListTypes);
      });
      this._lists$.next({
        ...this._lists$.value,
        ...state,
      });
    } catch (error) {
      this._systemAlertSnackBar.error(error);
    } finally {
      this._isLoading$.next(false);
    }
  }

  private async _getListData(type: HpmRestrictionsListType) {
    try {
      const listDetails = await this._listService.getMembershipListByType([
        type,
      ]);
      this._lists$.next({
        ...this._lists$.value,
        [type]: listDetails,
      });
    } catch (error) {
      this._systemAlertSnackBar.error(error);
    }
  }

  private async _removeSelfGrantLists() {
    const state = { ...this._lists$.value };
    this._lists$.next({ ...(omit(state, this.SELF_GRANT_LISTS) as any) });
  }

  private async _createMissingLists(
    existingListTypes: HpmRestrictionsListType[],
  ) {
    const restrictionListTypes = [...this.SELF_GRANT_LISTS, ...this.PASSLISTS];
    const missingListTypes: HpmRestrictionsListType[] =
      restrictionListTypes.filter(
        listType => !existingListTypes.includes(listType),
      );
    for (const listType of missingListTypes) {
      const listName = RESTRICT_LIST_TYPE_PRETTY_NAME[listType];
      await this.createList(listName, listType);
    }
  }
}
