import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ReplaySubject } from 'rxjs';

import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';

import { LandingService } from '../../services';

@Component({
  selector: 'mg-landing-country',
  templateUrl: './landing-country.component.html',
  styleUrls: ['./landing-country.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingCountryComponent implements OnInit, OnDestroy {
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  formGroup: FormGroup;

  private _redirect = '';

  /** Component constructor */
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _appConfigService: AppConfigService,
    public landing: LandingService,
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      country: new FormControl('', [Validators.required]),
    });

    this._route.queryParams.subscribe(queryParams => {
      try {
        this._redirect = atob(queryParams.redirect);
      } catch (err) {
        console.error(err);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  async onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    const country = this.formGroup.controls.country.value;
    await this._appConfigService.setAppCountry(country);
    // if the passwordToken is set, then we want to go back to the password
    // reset page instead of the email page.
    try {
      if (this._redirect) {
        await this._router.navigateByUrl(this._redirect);
      } else if (this.landing.passwordToken) {
        await this._router.navigate([
          '/landing/reset/' + this.landing.passwordToken,
        ]);
      } else {
        await this._router.navigate(['/landing']);
      }
    } catch (err) {
      location.pathname = '/';
    }
  }
}
