import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MgButtonModule } from 'minga/app/src/app/button';
import { WelcomeOwnerModule } from 'minga/app/src/app/ftue/Welcome/components/WelcomeOwner';
import { WelcomeStudentModule } from 'minga/app/src/app/ftue/Welcome/components/WelcomeStudent';
import { WelcomeTeacherManagerModule } from 'minga/app/src/app/ftue/Welcome/components/WelcomeTeacherManager';

import { WelcomeDialog } from './WelcomeDialog.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    WelcomeOwnerModule,
    WelcomeStudentModule,
    WelcomeTeacherManagerModule,

    // External dependencies
    CommonModule,
    MatDialogModule,
  ],
  declarations: [WelcomeDialog],
  exports: [WelcomeDialog],
})
export class WelcomeDialogModule {}
