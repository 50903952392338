import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TrackByFunction,
} from '@angular/core';
import * as day from 'dayjs';
import { Observable, Subscription } from 'rxjs';

import {
  CalendarScheduleEventDate,
  CalendarScheduleView,
  ICalendarScheduleEvent,
} from '../CalendarSchedule/types';

@Component({
  selector: 'mg-calendar-active-date-range',
  templateUrl: './CalendarActiveDateRange.component.html',
  styleUrls: ['./CalendarActiveDateRange.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarActiveDateRangeComponent implements OnInit, OnDestroy {
  /** @internal */
  private _initHappened = false;
  /** @internal */
  private _calendarView: CalendarScheduleView = 'month';
  /** @internal */
  private _excludeWeekend: boolean = false;
  /** @internal */
  private _activeDate: Date = new Date();
  private _activeDateSub?: Subscription;
  private _activeDateObservable$?: Observable<Date> | undefined;

  _activeDateTitle: string = '';

  @Input()
  get calendarView(): CalendarScheduleView {
    return this._calendarView;
  }
  set calendarView(value: CalendarScheduleView) {
    this._calendarView = value;
    this._updateActiveDateTitle();
  }

  @Input('date-change-observable')
  get activeDateChangeObservable(): Observable<Date> | undefined {
    return this._activeDateObservable$;
  }
  set activeDateChangeObservable(value: Observable<Date> | undefined) {
    if (!value) return;
    this._activeDateObservable$ = value;

    if (this._activeDateSub) {
      this._activeDateSub.unsubscribe();
    }
    this._activeDateSub = this._activeDateObservable$.subscribe(newDate => {
      this.changeActiveDate(newDate, false);
    });
  }

  @Input()
  get excludeWeekend(): boolean {
    return this._excludeWeekend;
  }
  set excludeWeekend(value: boolean) {
    this._excludeWeekend = value;
    this._updateActiveDateTitle();
  }

  @Output()
  readonly calendarViewChange: EventEmitter<CalendarScheduleView>;

  @Input()
  get activeDate(): Date {
    return this._activeDate;
  }
  set activeDate(value: Date) {
    this._activeDate = value;
    if (this._initHappened) {
      this._updateActiveDateTitle();
    }
  }

  @Output()
  readonly activeDateChange: EventEmitter<Date>;

  constructor(private _cdr: ChangeDetectorRef) {
    this.calendarViewChange = new EventEmitter();
    this.activeDateChange = new EventEmitter();
  }

  ngOnInit() {
    this._updateActiveDateTitle();
  }

  ngOnDestroy() {
    if (this._activeDateSub) this._activeDateSub.unsubscribe();
  }

  changeActiveDate(newDate: Date, emit: boolean = true) {
    this._activeDate = newDate;
    this._updateActiveDateTitle();
    if (emit) this.activeDateChange.emit(this._activeDate);
    this._cdr.markForCheck();
  }

  changeCalendarView(newView: CalendarScheduleView) {
    this._calendarView = newView;
    this._updateActiveDateTitle();
    this.calendarViewChange.emit(this._calendarView);
    this._cdr.markForCheck();
  }

  getWeekdayDisplay(month: number, day: number) {
    const date = new Date(this._activeDate);
    date.setMonth(month - 1);
    date.setDate(day);
    return date.toLocaleDateString(undefined, { weekday: 'short' });
  }

  getEventDisplayTime(date: CalendarScheduleEventDate) {
    if (date.allDay) {
      return 'All Day';
    } else {
      const timeFormat = 'h:mm a';
      let displayTime = '';
      displayTime += day(date.start).format(timeFormat);
      if ('end' in date && date.end) {
        displayTime += ' - ';
        displayTime += day(date.end).format(timeFormat);
      }
      return displayTime;
    }
  }

  activeDateNavLeft() {
    const oldDate = day(this._activeDate);
    let newDate: day.Dayjs;
    if (this._calendarView === 'month') {
      newDate = oldDate.subtract(1, 'M');
    } else if (this._calendarView === 'week') {
      newDate = oldDate.subtract(7, 'd');
    } else if (this._calendarView === 'day') {
      newDate = oldDate.subtract(1, 'd');
    }
    this.changeActiveDate(newDate.toDate());
  }

  activeDateNavRight() {
    const oldDate = day(this._activeDate);
    let newDate: day.Dayjs;
    if (this._calendarView === 'month') {
      newDate = oldDate.add(1, 'M');
    } else if (this._calendarView === 'week') {
      newDate = oldDate.add(7, 'd');
    } else if (this._calendarView === 'day') {
      newDate = oldDate.add(1, 'd');
    }
    this.changeActiveDate(newDate.toDate());
  }

  /** @internal */
  private _updateActiveDateTitle() {
    if (this._calendarView === 'month') {
      this._activeDateTitle = this._activeDate.toLocaleDateString(undefined, {
        month: 'long',
        year: 'numeric',
      });
    } else if (this._calendarView === 'week') {
      const date = day(this._activeDate);
      let start = day(date).startOf('week');
      let end = day(date).endOf('week');
      if (this.excludeWeekend) {
        start = start.add(1, 'd');
        end = end.subtract(1, 'd');
      }
      let startDate = start.date();
      let endDate = end.date();

      let monthName1 = day(this._activeDate).format('MMMM');
      let monthName2 = '';
      if (start.month() != end.month()) {
        // different months, need to print out both
        monthName1 = start.format('MMM');
        monthName2 = end.format('MMM') + ' ';
      }

      this._activeDateTitle =
        monthName1 +
        ' ' +
        startDate +
        ' - ' +
        monthName2 +
        endDate +
        ' ' +
        day(end).format('YYYY');
    } else if (this._calendarView === 'day') {
      this._activeDateTitle = this._activeDate.toLocaleDateString(undefined, {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'short',
      });
    }
  }
}
