import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SaBehaviorComponent } from './components/sa-behavior/sa-behavior.component';
import { SaCheckinComponent } from './components/sa-checkin/sa-checkin.component';
import { SaHallpassComponent } from './components/sa-hallpass/sa-hallpass.component';
import { SelectionAssignerRoutes } from './constants';
import { SelectionAssignerComponent } from './selection-assigner.component';
import { SelectionAssignerModule } from './selection-assigner.module';

export const ENTITY_ASSIGNER_ROUTES = [
  {
    path: '',
    component: SelectionAssignerComponent,
    children: [
      {
        path: SelectionAssignerRoutes.HALL_PASS,
        component: SaHallpassComponent,
      },
      {
        path: SelectionAssignerRoutes.BEHAVIOR,
        component: SaBehaviorComponent,
      },
      {
        path: SelectionAssignerRoutes.CHECK_IN,
        component: SaCheckinComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    SelectionAssignerModule,
    RouterModule.forChild(ENTITY_ASSIGNER_ROUTES),
  ],
})
export class SelectionAssignerRoutesModule {}
