import { Pipe, PipeTransform } from '@angular/core';

/**
 * Mimics the Array.some method used with an array of objects.
 */
@Pipe({ name: 'some' })
export class ArraySomePipe implements PipeTransform {
  transform(array: any[], field: string, value: any): boolean {
    return (array || []).some(item => item[field] === value);
  }
}
