import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MingaAppRoutes } from 'src/app/types';

import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { ManagerOverlayRoutesData } from '@shared/components/manager-overlay';
import { AuthGuard } from '@shared/guards';

import { EventsComponent, EventsModule } from './Events';
import { EventCalendarComponent } from './Events/EventCalendar';
import { EventScheduleComponent } from './Events/EventSchedule';
import { EventManagerModule } from './event-manager.module';
import { EventManagerMessages, EventManagerRoutes } from './event.constants';

export const EVENT_TAB_CONFIG: ManagerOverlayRoutesData = {
  defaultRootPath: EventManagerRoutes.ROOT,
  headerTitle: EventManagerMessages.PAGE_HEADER_TITLE,
  tabs: [],
};

const routes: MingaAppRoutes = [
  {
    path: '',
    component: EventsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Calendar',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: EventManagerRoutes.SCHEDULE,
      },
      {
        path: EventManagerRoutes.CALENDAR,
        component: EventCalendarComponent,
        data: {
          title: 'Calendar',
          navigationVisibility: false,
        },
      },
      {
        path: EventManagerRoutes.SCHEDULE,
        component: EventScheduleComponent,
        data: {
          title: 'List',
          navigationVisibility: false,
        },
      },
      {
        path: EventManagerRoutes.QUICK_FILTER,
        component: EventScheduleComponent,
        data: {
          title: 'Calendar',
          navigationVisibility: false,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    EventManagerModule,
    EventsModule,
    LayoutLegacyContentModule,
    RouterModule.forChild(routes),
  ],
})
export class EventManagerRoutesModule {}
