import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';

import {
  OverlayStructureRegionId,
  OverlayStructureRegistry,
  OverlayStructureTemplateAddMode,
} from '../../services/OverlayStructureRegistry';

@Directive({ selector: '[mgOverlayStructure]' })
export class MgOverlayStructureDirective implements OnInit, OnDestroy {
  /** @internal */
  private _registered: boolean = false;
  /** @internal */
  private _enabled: boolean = false;

  @Input('mgOverlayStructureRegion')
  region: OverlayStructureRegionId | null = null;

  /**
   * The mode this overlay structure should use.
   * @SEE `OverlayStructureTemplateAddMode` docs
   */
  @Input('mgOverlayStructureMode')
  mode: OverlayStructureTemplateAddMode = 'additive';

  @Input('mgOverlayStructure')
  set enabled(value: boolean) {
    this._enabled = coerceBooleanProperty(value);

    if (this._enabled && !this._registered) {
      this.registerSelf();
    } else if (this._registered) {
      this.removeSelf();
    }
  }

  get enabled() {
    return this._enabled;
  }

  constructor(
    private template: TemplateRef<any>,
    private overlayStructureRegistry: OverlayStructureRegistry,
  ) {}

  ngOnInit() {
    if (this._enabled && !this._registered) {
      this.registerSelf();
    }
  }

  ngOnDestroy() {
    if (this._registered) {
      this.removeSelf();
    }
  }

  private registerSelf() {
    if (!this.region) {
      console.error(
        `mgOverlayStructure directive requires the 'region' property to be set`,
      );
      return;
    }

    this.overlayStructureRegistry.addStructureTemplate(
      this.region,
      this.template,
      this.mode,
    );
    this._registered = true;
  }

  private removeSelf() {
    if (this.region) {
      this.overlayStructureRegistry.removeStructureTemplate(
        this.region,
        this.template,
      );
      this._registered = false;
    }
  }
}
