import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { BottomSheetEventType } from './bottom-sheet.types';

@Component({
  selector: 'mg-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetComponent implements OnInit {
  @ContentChild('actions', { read: TemplateRef })
  actions: TemplateRef<any>;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.onEsc.observers.length > 0) {
      this.onEsc.emit();
    }
  }

  @Input() title: string;
  @Input() isLoading: boolean;
  @Input() showBackBtn: boolean = false;
  @Input() showCloseBtn: boolean = true;
  @Input() fullHeight: boolean = false; // takes up full height instead of dynamic based on content
  @Output() onBack: EventEmitter<void> = new EventEmitter(); // back button override
  @Output() onClose: EventEmitter<void> = new EventEmitter(); // close button override
  @Output() onEsc: EventEmitter<void> = new EventEmitter(); // esc button override
  constructor(private _bottomSheetRef: MatBottomSheetRef) {}

  ngOnInit(): void {}

  get classes() {
    return {
      'has-actions': !!this.actions,
      'full-height': this.fullHeight,
    };
  }

  public back(): void {
    if (this.onBack.observers.length > 0) {
      this.onBack.emit();
    } else {
      this._bottomSheetRef.dismiss({
        type: BottomSheetEventType.BACK,
        data: {},
      });
    }
  }

  public close(): void {
    if (this.onClose.observers.length > 0) {
      this.onClose.emit();
    } else {
      this._bottomSheetRef.dismiss({
        type: BottomSheetEventType.CLOSE,
        data: {},
      });
    }
  }
}
