import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';

import { MingaPermission } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';

@Injectable()
export class MingaScheduledReportsGuard implements CanActivate {
  constructor(private _permissionService: PermissionsService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this._permissionService.observePermission(
      MingaPermission.SCHEDULED_REPORTS_MANAGE,
    );
  }
}
