<!-- Title -->
<div class="title-container">
  <!-- Success icon -->
  <div
    *ngIf="showSuccessIcon"
    class="success-charkmark"></div>
  <!-- Text -->
  <mg-text
    variant="header-xxl-secondary"
    textAlign="center">
    {{ title }}
  </mg-text>
</div>

<!-- Card -->
<mg-card class="card">
  <!-- Card header -->
  <div class="card-header">
    <!-- Card icon -->
    <mg-icon
      *ngIf="cardIconName"
      [iconName]="cardIconName"
      [iconColor]="cardIconColor">
    </mg-icon>
    <!-- Card title -->
    <mg-text variant="header-xl-secondary">{{ cardTitle }}</mg-text>
  </div>
  <!-- Content -->
  <ul class="card-list">
    <ng-content></ng-content>
  </ul>
</mg-card>

<!-- Buttons -->
<div
  fxLayout="row"
  fxLayout.lt-md="column-reverse"
  fxLayoutAlign="center center"
  fxLayoutAlign.lt-md="start stretch"
  fxLayoutGap="24px"
  fxLayoutGap.lt-md="8px"
  class="buttons-container">
  <!-- Main done button -->
  <mg-btn
    [setFocus]="focusSubject"
    [variant]="hasAdditionalButtons ? 'outlined' : 'filled'"
    [size]="(media.isMobileView$ | async) ? 'large' : 'kiosk'"
    [responsive]="true"
    (pressed)="done.emit()">
    {{ MSG.BUTTON_DONE }}
  </mg-btn>
  <!-- Additional buttons -->
  <ng-content select="[additionalButtons]"></ng-content>
</div>

<!-- Session message -->
<mg-text
  *ngIf="remainingTime$ | async as remainingTime"
  variant="header-md"
  textAlign="center">
  This session will end in {{ remainingTime }} seconds
</mg-text>
