import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mg-tools-leaderboard',
  templateUrl: './tools-leaderboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsLeaderboardComponent {
  constructor() {}
}
