import { Pipe, PipeTransform } from '@angular/core';

import * as day from 'dayjs';

import { FlexTimePeriodTile } from '../constants';

@Pipe({ name: 'getPeriodStatus' })
export class GetPeriodStatusPipe implements PipeTransform {
  transform(data: FlexTimePeriodTile): string {
    const currentTime = day().format('HH:mm:ss');
    const isSameDay = day().isSame(data.dateObject, 'day');
    if (
      (isSameDay && data.startTime > currentTime) ||
      day().isBefore(data.dateObject, 'day')
    ) {
      return 'Not Started';
    } else if (
      (isSameDay && data.endTime < currentTime) ||
      day().isAfter(data.dateObject, 'day')
    ) {
      return 'Ended';
    } else {
      return 'Started';
    }
  }
}
