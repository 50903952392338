<ng-container
  *ngIf="btnPosition === 'above'"
  [ngTemplateOutlet]="btn"></ng-container>

<!-- Collapsible Content -->
<div
  class="collapsible-content-container"
  [class.collapsed]="isCollapsed$ | async"
  [@collapse]="collasibleState$ | async">
  <ng-content></ng-content>
</div>

<ng-container
  *ngIf="btnPosition === 'below'"
  [ngTemplateOutlet]="btn"></ng-container>

<ng-template #btn>
  <!-- Toggle Filter Collapse -->
  <div
    *ngIf="isEnabled$ | async"
    class="toggle-container"
    [class.collapsed]="isCollapsed$ | async">
    <button
      type="button"
      class="toggle-button"
      (click)="toggleCollapseState()">
      {{
        (isCollapsed$ | async)
          ? expandText || MESSAGES.BUTTON_LABEL_EXPAND
          : collapseText || MESSAGES.BUTTON_LABEL_COLLAPSE
      }}
      <mg-icon
        [iconName]="
          (isCollapsed$ | async) === true
            ? 'mg-drop-down-arrow'
            : 'mg-toggle-up'
        ">
      </mg-icon>
    </button>
  </div>
</ng-template>
