import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { IMembershipList } from 'minga/domain/membershipList';
import { ListMembershipService } from 'src/app/services/ListMembership';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PointsManagerService } from '../../../services';
import { PmTeamsEditComponent } from '../components/pm-teams-edit/pm-teams-edit.component';
import { PmTeamsEditModalData, PmTeamsEditModalResponse } from '../types';

@Injectable()
export class PmTeamsService {
  public readonly refreshLists = new Subject<void>();

  /** Service Constructor */
  constructor(
    private _listService: ListMembershipService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService<
      PmTeamsEditModalResponse,
      PmTeamsEditModalData
    >,
    private _pmService: PointsManagerService,
  ) {}

  public async openModal(id?: number, image?: string): Promise<void> {
    const editModal = this._modalOverlay.open(PmTeamsEditComponent, {
      data: { id, image },
      disposeOnNavigation: false,
    });
    editModal.afterClosed.subscribe(async response => {
      if (!response) return;
      const { type } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          this._systemAlertSnackBar.success(
            'Successfully created new school team',
          );
          return;
        }
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          await this._pmService.fetchTeams();
          this._systemAlertSnackBar.success('Successfully updated school team');
          return;
        }
        case ModalOverlayServiceCloseEventType.DELETE: {
          this._systemAlertSnackBar.success('Successfully deleted school team');
          return;
        }
        default: {
          return;
        }
      }
    });
  }

  public async create(list: IMembershipList): Promise<void> {
    try {
      const result = await this._listService.updateMembershipList(list);
      this._pmService.setTeams([result, ...this._pmService.getTeams()]);
      this.refreshLists.next();
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to create school team');
    }
  }

  public async update(list: IMembershipList): Promise<void> {
    try {
      const result = await this._listService.updateMembershipList(list);
      const teams = [...this._pmService.getTeams()];
      teams[teams.findIndex(({ id }) => id === result.id)] = result;
      this._pmService.setTeams([...teams]);
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to update school team');
    }
  }

  public async deleteTeam(id: number): Promise<void> {
    try {
      await this._listService.deleteMembershipList(id);
      const teams = this._pmService.getTeams();
      this._pmService.setTeams(teams.filter(t => t.id !== id));
    } catch (error) {
      this._systemAlertSnackBar.error('Failed to delete school team');
    }
  }

  public async changeStatus(item: any, status: boolean): Promise<void> {
    try {
      const team = { ...item };
      team.active = status;
      await this._listService.updateMembershipList(team);
      this._systemAlertSnackBar.success(
        `Successfully set ${team.name} to ${status ? 'active' : 'inactive'}`,
      );
    } catch (error) {
      this._systemAlertSnackBar.error(`Failed to update school team status`);
    }
  }
}
