import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MgeIconModule } from 'minga/app/src/app/elements/MgeIcon';

import { MgPollMiniCardElement } from './MgPollMiniCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgeIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgPollMiniCardElement],
  exports: [MgPollMiniCardElement],
})
export class MgPollMiniCardModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-poll-mini-card')) {
      //       //   MgPollMiniCardElement,
      //   {injector},
      // ));
    }
  }
}
