import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BarcodeScanner } from 'src/app/barcodeScanner';
import { RootService } from 'src/app/minimal/services/RootService';
import { PointsManagerService } from 'src/app/services/PointsManager';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { PeopleSelectorService } from '../people-selector.service';
import { PeopleSelectorFormService } from '../ps-form.service';
import { PsCollectionSearchImplService } from '../search-impl/ps-collection-search.impl.service';

@Injectable()
export class PsPointsService extends PeopleSelectorFormService<'Points'> {
  /** Service Constructor */
  constructor(
    public router: Router,
    public snackbar: SystemAlertSnackBarService,
    public barCodeScanner: BarcodeScanner,
    public peopleSelector: PeopleSelectorService,
    private _psCollectionSearch: PsCollectionSearchImplService,
    private _pointsManager: PointsManagerService,
    private _rootService: RootService,
  ) {
    super(
      {
        name: 'Points',
        pageDefinitions: {
          redeem: {
            submitFn: async () => this.submitRedeem(),
            searchFn: async (type, filters) =>
              this._psCollectionSearch.collectionSearch(type, filters),
          },
        },
      },
      router,
      snackbar,
      barCodeScanner,
      peopleSelector,
    );
  }

  public async submitRedeem(): Promise<void> {
    const { type, value, category, reason } = this.data;
    const selectionHashes = this.selection.getSelectionHashes();
    const subTitle = type === 'add' ? 'Points Added' : 'Points Removed';
    if (type === 'add') {
      await this._rootService.addLoadingPromise(
        this._pointsManager.addPointsToPeople(
          selectionHashes,
          value,
          reason,
          category,
        ),
      );
    } else {
      await this._rootService.addLoadingPromise(
        this._pointsManager.removePointsFromPeople(
          selectionHashes,
          value,
          reason,
        ),
      );
    }

    await this.peopleSelector.openDialog({
      type: 'success',
      audioAlerts: true,
      title: 'Success!',
      subTitle,
    });
  }
}
