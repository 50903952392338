import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'mg-swipe-stack',
  templateUrl: './SwipeStack.component.html',
  styleUrls: ['./SwipeStack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwipeStackComponent implements OnInit, OnDestroy {
  /** @internal */
  private _containerWidth: number = 0;
  /** @internal */
  private _containerHeight: number = 0;
  /** @internal */
  private _progress: number = 0;
  /** @internal */
  private _panning: boolean = false;
  /** @internal */
  private _animationEnd: boolean = false;
  /** @internal */
  private _pendingStackIndex: number = 0;
  /** @internal */
  private _stackIndex: number = 0;
  /** @internal */
  private _cleanUpGestureHandlers?: () => void;

  @Input()
  stackSize: number = 0;

  @Input()
  set stackIndex(newStackIndex: number) {
    this._stackIndex = newStackIndex;
    this._pendingStackIndex = newStackIndex;
  }

  get stackIndex() {
    return this._stackIndex;
  }

  @Input()
  cyclic: boolean = false;

  @Output()
  readonly stackIndexChange: EventEmitter<number>;

  /**
   * Forwarded hammerjs tap recognizer. Necessary to pan swipe and tap gestures
   */
  @Output()
  readonly swipeStackTap: EventEmitter<HammerInput>;

  @ViewChild('swipeStackContainer', { static: true })
  swipeStackContainer?: ElementRef<HTMLElement>;

  get nextStackIndex() {
    if (this.cyclic) return (this.stackIndex + 1) % this.stackSize;
    return Math.min(this.stackSize - 1, this.stackIndex + 1);
  }

  get afterNextStackIndex() {
    if (this.cyclic) return (this.stackIndex + 2) % this.stackSize;
    return Math.min(this.stackSize - 1, this.stackIndex + 2);
  }

  get previousStackIndex() {
    let prevIndex = this.stackIndex - 1;
    if (this.cyclic) {
      if (prevIndex < 0) {
        prevIndex = prevIndex + this.stackSize;
      }
      return prevIndex;
    }

    return Math.max(0, prevIndex);
  }

  constructor(private ngZone: NgZone, private renderer: Renderer2) {
    this.stackIndexChange = new EventEmitter();
    this.swipeStackTap = new EventEmitter();
  }

  hasNext(): boolean {
    if (this.cyclic && this.stackIndex > 1) return true;
    return this.stackIndex < this.stackSize && this.stackSize > 1;
  }

  hasPrevious(): boolean {
    if (this.cyclic && this.stackIndex > 1) return true;
    return this.stackIndex > 0 && this.stackSize > 1;
  }

  async next() {
    if (this.hasNext()) {
      this._pendingStackIndex = this.stackIndex + 1;
      this.checkClampOrWrapStackIndex();
      this.playAnimation();
    } else {
      this.playAnimationReverse();
    }
  }

  previous() {
    // @TODO: Do previous
  }

  ngOnInit() {
    this.calcContainerWidth();
    const containerEl = this.swipeStackContainer!.nativeElement;

    const unlistenTap = this.renderer.listen(containerEl, 'tap', (ev: any) =>
      this.onTap(ev),
    );

    this.ngZone.runOutsideAngular(() => {
      const unlistenPanStart = this.renderer.listen(
        containerEl,
        'panstart',
        (ev: any) => this.onPanStart(ev),
      );
      const unlistenPan = this.renderer.listen(containerEl, 'pan', (ev: any) =>
        this.onPan(ev),
      );
      const unlistenPanEnd = this.renderer.listen(
        containerEl,
        'panend',
        (ev: any) => this.onPanEnd(ev),
      );
      const unlistenPanCancel = this.renderer.listen(
        containerEl,
        'pancancel',
        (ev: any) => this.onPanCancel(ev),
      );

      this._cleanUpGestureHandlers = () => {
        unlistenPanEnd();
        unlistenPan();
        unlistenPanStart();
        unlistenTap();
        unlistenPanCancel();
      };
    });
  }

  ngOnDestroy() {
    if (this._cleanUpGestureHandlers) {
      this._cleanUpGestureHandlers();
      delete this._cleanUpGestureHandlers;
    }
  }

  onPanStart(ev: HammerInput) {
    // If our pan start is the final event the gesture detected a pull down like
    // gesture and not the kind of panning we'd like to listen for.
    if (ev.isFinal) {
      ev.preventDefault();
      return;
    }

    this.calcContainerWidth();
    this.pauseAnimation();
    this._panning = true;
    this._animationEnd = false;
  }

  onPan(ev: HammerInput) {
    if (!this._panning) return;

    const progress = (ev.deltaX / this._containerWidth) * 2;
    this.setAnimationProgress(progress);
  }

  onPanEnd(ev: HammerInput) {
    if (!this._panning) {
      return;
    }

    if (this._progress >= 1) {
      this.previous();
    } else if (this._progress <= -1) {
      this.next();
    } else {
      this.playAnimationReverse();
    }

    this._progress = 0;
    this._panning = false;
  }

  onPanCancel(ev: HammerInput) {
    this.playAnimationReverse();
    this._progress = 0;
    this._panning = false;
  }

  onTap(ev: HammerInput) {
    if (ev.srcEvent.target instanceof Element) {
      const composedPaths = ev.srcEvent.composedPath();
      for (const entry of composedPaths) {
        if (entry instanceof Element) {
          const hasCancelClass = entry.classList.contains(
            'mg-cancel-swipe-stack-tap',
          );

          if (hasCancelClass) {
            return;
          }
        }
      }
    }

    this.swipeStackTap.emit(ev);
  }

  onAnimationIteration(ev: AnimationEvent) {
    const containerEl = this.swipeStackContainer!.nativeElement;
    const target = ev.target as Node;

    // Deep child elements trigger this event as well. Ignore them and only
    // continue for immediate descendants.
    if (target!.parentElement!.parentElement !== containerEl) {
      return;
    }

    containerEl.style.animationPlayState = 'paused';
    containerEl.style.animationDelay = '0ms';
    containerEl.style.animationDirection = '';
    this.restartAnimation();
    this._animationEnd = true;

    if (this._pendingStackIndex != this.stackIndex) {
      this.stackIndex = this._pendingStackIndex;
      this.stackIndexChange.emit(this.stackIndex);
    }
  }

  private checkClampOrWrapStackIndex() {
    if (this.cyclic) {
      this._pendingStackIndex = this._pendingStackIndex % this.stackSize;
    } else {
      this._pendingStackIndex = Math.max(
        0,
        Math.min(this._pendingStackIndex, this.stackSize - 1),
      );
    }
  }

  private setAnimationProgress(progress: number) {
    const containerEl = this.swipeStackContainer!.nativeElement;
    this._progress = Math.max(-1.99, Math.min(1.99, progress));
    if (this._progress <= 0) {
      containerEl.style.animationDelay = `${300 * this._progress}ms`;
      containerEl.style.animationDirection = '';
    } else {
      // @TODO: Do previous swiping
      // containerEl.style.animationDelay = `${300 * -this._progress}ms`;
      // containerEl.style.animationDirection = 'reverse';
    }
  }

  private calcContainerWidth() {
    const rect =
      this.swipeStackContainer!.nativeElement.getBoundingClientRect();
    this._containerWidth = rect.width;
    this._containerHeight = rect.height;
  }

  private playAnimation() {
    const containerEl = this.swipeStackContainer!.nativeElement;
    containerEl.style.animationDirection = '';
    containerEl.style.animationPlayState = '';
  }

  private playAnimationReverse() {
    const containerEl = this.swipeStackContainer!.nativeElement;
    containerEl.style.animationDirection = 'reverse';
    containerEl.style.animationPlayState = '';

    const progressMs = 300 * this._progress;
    const invertedProgress = -(progressMs + 300);
    containerEl.style.animationDelay = `${-300 + invertedProgress}ms`;
  }

  private pauseAnimation() {
    const containerEl = this.swipeStackContainer!.nativeElement;
    containerEl.style.animationPlayState = 'paused';
  }

  // https://css-tricks.com/restart-css-animation/
  private restartAnimation() {
    const containerEl = this.swipeStackContainer!.nativeElement;

    if (containerEl.classList.contains('anim1')) {
      containerEl.classList.remove('anim1');
      containerEl.classList.add('anim2');
    } else {
      containerEl.classList.remove('anim2');
      containerEl.classList.add('anim1');
    }
  }
}
