<mg-short-card
  *ngIf="content"
  [markKeywords]="markKeywords"
  [context]="context"
  [timestamp]="content?.timestamp"
  [featuredImage]="content?.featuredImage"
  [gradientOverlap]="content?.gradientOverlap"
  [gradientSize]="content?.gradientSize"
  [lightContent]="content?.lightContent"
  [backgroundColor]="content?.backgroundColor"
  [commentList]="content?.commentList"
  [commentCount]="content?.commentCount"
  [totalCommentCount]="content?.totalCommentCount"
  [commentRoles]="commentRoles"
  [pinned]="pinned"
  [content]="content">
  <mg-short-card-byline>
    <ng-container *ngIf="pinned">
      <mge-icon
        class="pinned-icon"
        icon="pin-o"></mge-icon>
    </ng-container>
  </mg-short-card-byline>
</mg-short-card>
