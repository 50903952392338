<mg-overlay-primary size="custom">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<ng-template #mainTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="8px">
    <mg-icon iconName="mg-warning"></mg-icon>
    <mg-text variant="header-lg-secondary">
      {{ MESSAGES.WARNING_TITLE }}
    </mg-text>
    <ol>
      <li>
        <mg-text
          variant="body-sm"
          textAlign="left">
          {{ MESSAGES.DEVICE_SECURITY }}
        </mg-text>
      </li>
      <li>
        <mg-text
          variant="body-sm"
          textAlign="left">
          {{ MESSAGES.PHYSICAL_SECURITY }}
        </mg-text>
      </li>
      <li>
        <mg-text
          variant="body-sm"
          textAlign="left">
          {{ MESSAGES.USER_TRAINING }}
        </mg-text>
      </li>
      <li>
        <mg-text
          variant="body-sm"
          textAlign="left">
          {{ MESSAGES.DATA_PRIVACY }}
        </mg-text>
      </li>
    </ol>
    <mg-text variant="header-lg-secondary">
      {{ MESSAGES.DISCLAIMER_TITLE }}
    </mg-text>
    <mg-text
      variant="body-sm"
      textAlign="left">
      {{ MESSAGES.DISCLAIMER_BODY }}
    </mg-text>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <mg-btn
      variant="text"
      (pressed)="close()">
      {{ MESSAGES.CANCEL_BUTTON }}
    </mg-btn>
    <mg-btn
      variant="filled"
      (pressed)="confirm()">
      {{ MESSAGES.ACCEPT_BUTTON }}
    </mg-btn>
  </div>
</ng-template>
