<div
  *ngIf="group as group"
  fxLayout="row"
  class="mg-preview-wrap"
  [ngClass]="{ 'group-new-content': hasNewContent }">
  <div
    class="group-image"
    [style.background-image]="
      'url(' +
      (getGroupBannerImageInfo() | mgAssetUrl: ['cardbanner', 'banner']) +
      ')'
    "></div>

  <div
    class="group-body"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center">
    <div class="group-name-div mg-smallcopy">
      <span *ngIf="parentGroup$ | async as parentGroup"
        >{{ parentGroup.name }} |
      </span>
      <strong [innerHTML]="">{{ getNameText(group?.name || '') }}</strong>
    </div>

    <div
      class="group-detail-container"
      fxLayout="row"
      fxLayoutAlign="space-between center">
      <div class="group-detail-wrap">
        <div class="group-category">{{ group.category }}</div>
      </div>
    </div>

    <div
      class="group-description"
      *ngIf="clickMode != 'SELECT'"
      (click)="!this.showFullBody && onMoreDescriptionClick($event)"
      [innerHTML]="getBodyText(group?.description || '')"></div>

    <div class="col-right mg-smallcopy">
      <div>
        <mat-icon *ngIf="group.isPrivate">lock</mat-icon>
      </div>
      <mg-group-join-button
        *ngIf="clickMode == 'JOIN' || clickMode == 'GROUP'"
        [group]="group"
        (leaveGroup)="leaveGroup.emit($event)"
        (requestToJoin)="onRequestToJoin($event)"
        buttonClass="micro"
        (cancelRequestToJoin)="onCancelRequestToJoin($event)">
      </mg-group-join-button>
      <mg-button
        *ngIf="clickMode == 'SELECT'"
        class="small"
        >Select</mg-button
      >
    </div>
  </div>
</div>
