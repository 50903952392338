<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [bodyNoPadding]="true"
  [isLoading]="isLoading$ | async"
  [overlayTitle]="
    dialogData.type === 'all'
      ? 'Update All Students Counter'
      : 'Update Student(s) Counter'
  "
  [skipLoadCheck]="true">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <mg-automation-counter-reset
    [type]="dialogData.type"
    [countFC]="countControl"
    [selection$]="selection$"
    [isLoading$]="isLoading$"
    (editSelection)="editSelection()">
  </mg-automation-counter-reset>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        variant="text"
        (pressed)="delete()">
        Cancel
      </mg-btn>
    </div>
    <div>
      <mg-btn
        variant="filled"
        (pressed)="submit()">
        Update
      </mg-btn>
    </div>
  </div>
</ng-template>
