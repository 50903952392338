import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { ScheduledReportEditFormFields } from '@modules/minga-manager/components/mm-scheduled-reports/constants';

@Component({
  selector: 'mg-sr-filters-wrap',
  templateUrl: './sr-filters-wrap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SrFiltersWrapComponent implements OnDestroy {
  private _fieldsInitialized = new BehaviorSubject<boolean>(false);
  public fieldsInitialized$ = this._fieldsInitialized.asObservable();
  @Input() public form: FormGroup;
  @Input() set filters(filters: any) {
    const isInitialized = this._fieldsInitialized.getValue();
    if (filters && !isInitialized) {
      this.addFilters(filters);
      this._fieldsInitialized.next(true);
    }
  }

  constructor(private _fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.removeFilters();
  }

  public addFilters(filters: FormGroup) {
    this.form.addControl(
      ScheduledReportEditFormFields.REPORT_FILTERS,
      this._fb.group(filters),
    );
  }

  public removeFilters() {
    this.form.removeControl(ScheduledReportEditFormFields.REPORT_FILTERS);
  }
}
