import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { RelatedPagesService } from 'src/app/services/RelatedPages';

import { RelatedPagesMenu } from '../../types';

@Component({
  selector: 'mg-sidenav-tabs-header',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavTabsHeaderComponent {
  constructor() {}
}

@Component({
  selector: 'mg-sidenav-tabs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./SideNavTabs.component.scss'],
  templateUrl: `SideNavTabs.component.html`,
})
export class SideNavTabsComponent implements OnChanges, OnDestroy {
  mobileFlag = !!window.MINGA_DEVICE;
  private _hmjs: any;
  relatedPagesMenu: Observable<RelatedPagesMenu[]>;

  @Input() disabled: boolean;
  @Input() mqAlias: string;
  @Input() open: boolean;
  @Input() tabLabels: string[] = [];
  @Input() selectedIndex: number | null = null;

  @Output()
  openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  selectedIndexChange: EventEmitter<number> = new EventEmitter();

  @ViewChild('sidenav', { static: false })
  sidenav?: MatSidenav;

  get fixed() {
    return this.mqAlias === 'xs' || this.mqAlias === 'sm';
  }

  get mode() {
    return this.mqAlias === 'xs' || this.mqAlias === 'sm' ? 'over' : 'side';
  }

  get isMobile() {
    return this.fixed;
  }

  constructor(
    private _router: Router,
    private _relatedPagesService: RelatedPagesService,
  ) {
    this.relatedPagesMenu = this._relatedPagesService.getRelatedPages();
  }

  onSwipeRight() {
    if (this.fixed) {
      this.onOpenedChange(false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // when switching back to smaller display size, close the sidenav
    if (
      changes.mqAlias &&
      (changes.mqAlias.currentValue === 'xs' ||
        changes.mqAlias.currentValue === 'sm')
    ) {
      this.open = false;
    }
  }

  ngOnDestroy() {
    if (this._hmjs) {
      this._hmjs.destroy();
    }
  }

  escapeKeyCloseNav() {
    // don't close when used as a sidenav in fixed position
    if (!this.fixed) return;

    this.closeSideNav();
  }

  closeSideNav() {
    if (this.sidenav) {
      this.sidenav.close();
    } else {
      console.error('#sidenav not set');
    }
  }

  onOpenedChange(ev: boolean) {
    this.openChange.emit(ev);
  }

  onNavItemClick(index: number) {
    this.selectedIndex = index;
    this.selectedIndexChange.emit(index);
    // close sidenav when clicking an item to navigate to
    this.closeSideNav();
  }

  goToRoute(path: string) {
    if (!path) return;
    this._router.navigateByUrl(path);
  }
}
