import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ReplaySubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { MingaRoleType, mingaSettingTypes } from 'minga/util';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import {
  HALLPASS_MENU_OPTIONS,
  ITeacherToolsMenu,
} from '@modules/teacher-tools';
import { PBIS_CHECKIN_TYPES } from '@modules/teacher-tools/constants/tt-view.constants';
import { TeacherToolsService } from '@modules/teacher-tools/services';

import { RedeemPointsModalService } from '@shared/components/redeem-points-modal/services';
import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { PbisService } from '@shared/services/pbis';

import { PBIS_OPTIONS, StMessages } from '../../constants';
import { StudentToolsCheckinService } from '../../services';

@Component({
  selector: 'mg-st-my-tools',
  templateUrl: './st-my-tools.component.html',
  styleUrls: ['./st-my-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StMyToolsComponent {
  /** Constants */
  public MESSAGES = StMessages;
  public pbisOptions = PBIS_OPTIONS;

  hallPassOptions: ITeacherToolsMenu[] = [
    {
      label: StMessages.HALL_PASS_CREATE,
      asset: '/assets/teacher-tools/hall_pass_img.svg',
      onClick: item => this.handleLaunchHallpassAssignmentForm(),
      type: HALLPASS_MENU_OPTIONS.ASSIGN,
    },
  ];

  /** General Observables */
  private readonly _destroyed$ = new ReplaySubject<void>(1);

  /** Modules Settings */
  public isStudentIdEnabled$ = this._mingaSettings.isIdModuleEnabled();
  public isHallPassEnabled$ = this._mingaSettings.isHallPassModuleEnabled();
  public isDigitalIDEnabled$ = this._mingaSettings.isIdModuleEnabled();
  public isPbisEnabled$ = this._mingaSettings.isPbisModuleEnabled();
  public isCheckinEnabled$ = this._mingaSettings.isCheckinModuleEnabled();
  public isLeaderboardEnabled$ = this._mingaSettings.getSettingValueObs(
    mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD,
  );
  public canCreateHallPass$ = combineLatest([
    this._mingaSettings.getSettingValueObs(
      mingaSettingTypes.PASS_STUDENTS_GRANT,
    ),
    this._permissions.mingaRoleType$,
  ]).pipe(
    map(([canStudentsSelfGrantHallPasses, mingaRoleType]) => {
      return (
        canStudentsSelfGrantHallPasses &&
        mingaRoleType !== MingaRoleType.READ_ONLY
      );
    }),
  );
  public canUserTrackBehaviors$ = this._pbisService.canUserTrackBehaviors();

  public authHash$ = this._authInfo.authPersonHash$;

  /** Component Constructor */
  constructor(
    private _mingaSettings: MingaSettingsService,
    private _teacherToolsService: TeacherToolsService,
    private _permissions: PermissionsService,
    private _studentCheckinService: StudentToolsCheckinService,
    private _router: Router,
    private _redeemPointsModal: RedeemPointsModalService,
    private _pbisService: PbisService,
    private _systemAlertModal: SystemAlertModalService,
    public _activatedRoute: ActivatedRoute,
    private _authInfo: AuthInfoService,
  ) {}

  public handleLaunchHallpassAssignmentForm() {
    this._teacherToolsService.openHallpassAssignmentForm();
  }

  public handlePbisTools(type: PBIS_CHECKIN_TYPES) {
    if (type === PBIS_CHECKIN_TYPES.REWARDS) {
      this._redeemPointsModal.openPeopleSelector();
    } else if (type === PBIS_CHECKIN_TYPES.MANAGE_POINTS) {
      this._router.navigate(['', { outlets: { o: ['points-manager'] } }]);
    } else {
      this._teacherToolsService.openPbisTrackingForm(type);
    }
  }

  public async openSelfCheckScanner(checkingOut: boolean): Promise<void> {
    await this._studentCheckinService.openScanner(checkingOut, true);
  }

  public async openViewId() {
    this._router.navigate(['', { outlets: { o: ['student-id'] } }]);
  }
}
