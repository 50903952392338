import { AuthGuard } from '@shared/guards/auth.guard';

import { GlobalSearchContentComponent } from '../components/global-search-content/global-search-content.component';
import { SearchConfig } from '../types';

export enum GlobalSearchMessage {
  INPUT_LABEL = 'Search',
  NO_RESULTS_TITLE = 'No results found',
  NO_RESULTS_SUBTITLE = 'Try searching for something else',
}

export enum GlobalSearchQueryParam {
  QUERY = 'q',
}

export const SEARCH_INPUT_DEBOUCE_TIME = 400;

export const SEARCH_SCOPES = ['content', 'conversations'] as const;

export const GLOBAL_SEARCH_ROUTES: SearchConfig = {
  content: {
    type: 'component',
    component: GlobalSearchContentComponent,
    inputLabel: 'Search minga',
    description: 'Look for content throughout your minga',
    noResults: {
      title: 'No content found',
    },
    routeConfig: {
      canActivate: [AuthGuard],
    },
  },
  conversations: {
    type: 'portal',
    description: 'Search your direct messages',
    inputLabel: 'Search conversations',
    noResults: {
      title: 'No conversations found',
    },
    routeConfig: {
      canActivate: [AuthGuard],
    },
  },
};
