export enum NavLinkIconColors {
  BLUE = 'blue',
  GREEN = 'green',
  YELLOW = 'yellow',
  PURPLE = 'purple',
  PINK = 'pink',
  NAVY = 'navy',
  OTHER = 'other',
  TEAL = 'teal',
  ALT_TEAL = 'alt-teal',
}
