import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { remove, uniqueId } from 'lodash';
import {
  BehaviorSubject,
  concat,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { PeopleFacadeService, Person } from 'minga/app/src/app/people';

import { FormLabelBackground } from '../form';
import { NgSelectModified } from '../ng-select-modified';
import { MultiPersonSearchMessages } from './multi-person-search.constants';

/**
 * Multi Person Search
 *
 * Uses ng-select under the hood.
 *
 * @link https://github.com/ng-select/ng-select
 */
@Component({
  selector: 'mg-multi-person-search',
  templateUrl: './multi-person-search.component.html',
  styleUrls: ['./multi-person-search.component.scss'],
})
export class MultiPersonSearchComponent
  extends NgSelectModified
  implements OnInit, OnDestroy
{
  /** Constants */
  public readonly MESSAGES = MultiPersonSearchMessages;

  /** General Observables */
  private readonly _destroyed$ = new ReplaySubject<void>(1);

  /** Search  */
  public readonly searchResults$: Observable<Partial<Person>[]>;
  public readonly input$ = new Subject<string>();
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading$.asObservable();

  /** Input */
  private _inputAttrs: Partial<HTMLInputElement> = {
    autocomplete: 'off',
    name: uniqueId('mg-multi-person-search'),
  };
  public readonly inputAttrs = this._inputAttrs as Record<string, string>;

  /** Form / State */
  selected: string[] = [];
  private readonly _selected$ = new BehaviorSubject<Partial<Person>[]>([]);
  public readonly selected$ = this._selected$.asObservable();

  @Input() defaultValue: string[];
  @Input() labelBackground: FormLabelBackground = 'white';
  @Input() multiple = true;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  @Output() readonly selectionChanged = new EventEmitter<Partial<Person>[]>();

  /** Component Constructor */
  constructor(private _personFacade: PeopleFacadeService) {
    super();
    this.searchResults$ = concat(
      of([]),
      this.input$.pipe(
        takeUntil(this._destroyed$),
        distinctUntilChanged(),
        tap(() => this._isLoading$.next(true)),
        debounceTime(800),
        switchMap(searchQuery => {
          return this._personFacade.searchPeople(searchQuery).pipe(
            catchError(() => of([])),
            tap(() => this._isLoading$.next(false)),
          );
        }),
      ),
    );
  }

  ngOnInit(): void {
    this.setHashValue(this.defaultValue);
    if (!this.placeholder) this.placeholder = MultiPersonSearchMessages.LABEL;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._selected$.complete();
  }

  public async removePerson(hash: string): Promise<void> {
    const state = [...this._selected$.value];
    remove(state, person => person.hash === hash);
    this.updateSelected(state);
  }

  public setValue(selection: Partial<Person>[], skipEmit?: boolean) {
    this.updateSelected(selection, skipEmit);
  }

  public setHashValue(hashes: string[], skipEmit?: boolean) {
    if (hashes?.length) {
      this._getHashPeople(hashes, skipEmit);
    }
  }

  public async updateSelected(
    selection: Partial<Person>[],
    skipEmit?: boolean,
  ): Promise<void> {
    this._selected$.next(selection);
    if (!skipEmit) {
      this.selectionChanged.emit(selection);
    }
  }

  public clearSelection() {
    this.selected = [];
    this.updateSelected([]);
  }

  public async trackByFn(person: Person) {
    return person?.hash;
  }

  private _getHashPeople(hashes: string[], skipEmit = false) {
    this._personFacade
      .getPeopleDetails(hashes)
      .pipe(
        map(data =>
          data.map(person => ({ ...person, hash: person.personHash })),
        ),
        first(people => people.length > 0),
        takeUntil(this._destroyed$),
      )
      .subscribe(people => {
        this.setValue(people, skipEmit);
      });
  }
}
