import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IPointsSummaryResult } from 'minga/domain/points';
import { PmReportsFilter } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import {
  PointsReportColumns,
  TemplateColumnKeys,
} from 'minga/shared/reports_columns';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';

import { PmReportsService } from './pm-reports.service';

@Injectable()
export class PmReportsStudentDatasourceService extends ReportDatasourceService<IPointsSummaryResult> {
  historyInfo = {
    filter: PmReportsFilter.ISSUED_TO,
    url: '/points-manager/reports/' + ReportTypes.POINTS_HISTORY,
  };

  constructor(_router: Router, private _pmService: PmReportsService) {
    super(_router, _pmService, PmReportsStudentDatasourceService.name);
    const displayCol = [
      {
        header: '',
        key: TemplateColumnKeys.HISTORY,
        columnClasses: ['w-16'],
      },
    ];
    super.setDisplayColumns(PointsReportColumns.STUDENT_POINTS, displayCol);
  }

  async fetch(offset: number, limit: number) {
    return await this._pmService.fetchPointSummary(
      offset,
      limit,
      this._currentSort,
    );
  }
}
