import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import * as _ from 'lodash';
import { grpc } from '@improbable-eng/grpc-web';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { IAddressModelValue } from 'minga/app/src/app/components/Input/Address';
import { SearchComponent } from 'minga/app/src/app/components/Search';
import { SaveCancelDialog } from 'minga/app/src/app/dialog/SaveCancel';
import { PreventNavigation } from 'minga/app/src/app/guards';
import { MgValidators } from 'minga/app/src/app/input/validators';
import { MingaCountryEnum } from 'minga/app/src/app/minimal/services/AppConfig';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import {
  IMingaFormFields,
  MingaManagerService,
} from 'minga/app/src/app/services/MingaManager';
import { PeopleManagerService } from 'minga/app/src/app/services/PeopleManager';
import { SmsAdmin } from 'minga/app/src/app/services/SmsAdmin';
import { dateTimeMessageToDateObject } from 'minga/app/src/app/util/date';
import { IMingaFeatureToggle } from 'minga/domain/featureToggle';
import { IMingaSubscription, MingaAffiliationType } from 'minga/domain/minga';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { Minga, UpdateMingasRequest } from 'minga/proto/gateway/minga_pb';
import {
  PeopleAdminManager,
  PeopleManager,
} from 'minga/proto/gateway/people_ng_grpc_pb';
import { OpenMingaResponse } from 'minga/proto/gateway/people_pb';
import { ISmsRecipientDetails } from 'minga/shared/sms/types';
import { MingaStatusValue, MingaTypeValue, SchoolTypeValue } from 'minga/util';
import { DestroyMingaDialogComponent } from 'src/app/routes/sadmin/minga/DestroyMingaDialog';
import { ChangeMingaAction } from 'src/app/store/root/rootActions';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { ModalOverlaySuccessComponent } from '@shared/components/modal-overlay/components/modal-overlay-success';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  MM_DASHBOARD_EDIT_MODAL_TITLE_MAP,
  MmDashboardEditMessages,
  MmDashboardEditModalType,
} from '../../constants';
import { MmDashboardService } from '../../services';
import { MmDashboardEditData, MmDashboardEditResponse } from '../../types';

const grpcCodeKey = (code: grpc.Code) => {
  const codeKeys = Object.keys(grpc.Code);
  const codeKey =
    codeKeys.find(key => {
      return grpc.Code[key] == code;
    }) || code;

  return codeKey;
};

@Component({
  selector: 'mg-mm-dashboard-edit',
  templateUrl: './mm-dashboard-edit.component.html',
  styleUrls: ['./mm-dashboard-edit.component.scss'],
})
export class MmDashboardEditComponent implements PreventNavigation, OnDestroy {
  /** Constants */
  public readonly MESSAGES = MmDashboardEditMessages;
  public readonly MODAL_TYPES = MmDashboardEditModalType;
  public readonly AFFILIATION_TYPES = MingaAffiliationType;

  /** Modal Config */
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.BLUE,
  };
  public readonly modalType$: Observable<MmDashboardEditModalType>;
  public readonly modalTitle$: Observable<string>;
  public readonly editingExisting$: Observable<boolean>;

  /** Form Controls */
  readonly name = new FormControl('', [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(65),
  ]);
  mingaFormGroup: FormGroup;

  loading = true;
  editing = false;
  hash = '';
  baseContentGroupHash = '';

  PeopleManager = PeopleManager;
  PeopleAdminManager = PeopleAdminManager;

  peopleSearchQuery = '';
  isMingaPaused = false;

  uploadFormControl: FormControl;
  mingaLogo = '';
  logoInputFiles: File[] = [];
  _uploading = false;
  _loading = false;
  uploadAsset = '';
  mingaCountry = MingaCountryEnum.CAN;

  mingaSubscriptions: IMingaSubscription[] = [];

  private _unsubscribe$: Subject<void> = new Subject<void>();

  startDate = new Date();
  initialFields = {};

  mingaTypes = [
    { name: 'Minga', value: MingaTypeValue.MINGA },
    { name: 'Conference', value: MingaTypeValue.CONFERENCE },
  ];

  mingaStatuses = [
    { name: 'Demo', value: MingaStatusValue.DEMO },
    { name: 'Testing', value: MingaStatusValue.TESTING },
    { name: 'Live', value: MingaStatusValue.LIVE },
    { name: 'Trial', value: MingaStatusValue.TRIAL },
    { name: 'Expired', value: MingaStatusValue.EXPIRED },
  ];

  schoolTypes = [
    { name: 'Highschool', value: SchoolTypeValue.HIGH_SCHOOL },
    { name: 'Middle School', value: SchoolTypeValue.MIDDLE_SCHOOL },
    { name: 'Elementary', value: SchoolTypeValue.ELEMENTARY },
    { name: 'K12', value: SchoolTypeValue.K12 },
    { name: 'Private', value: SchoolTypeValue.PRIVATE },
    { name: 'Other', value: SchoolTypeValue.OTHER },
  ];

  smsDetails: ISmsRecipientDetails | null = null;

  @ViewChild('peopleSearchInput', { static: true })
  peopleSearchInput: SearchComponent;
  currentMaxSendCount = 0;
  currentSmsBlockSize = 0;
  smsLoading = false;

  @ViewChild('addBlockDialogTpl', { read: TemplateRef, static: false })
  addBlockDialogTpl?: TemplateRef<any>;
  addBlockDialog?: MatDialogRef<any>;

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(e: BeforeUnloadEvent) {
    const message = this.preventNavigation();

    if (message) {
      e.returnValue = message;
      return message;
    }
  }

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA) public dialogData: MmDashboardEditData,
    private _modal: ModalOverlayRef<
      MmDashboardEditResponse,
      MmDashboardEditData
    >,
    private _router: Router,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _mingaManager: MingaManagerService,
    private _peopleManager: PeopleManagerService,
    private _dialog: MatDialog,
    private _rootService: RootService,
    private _store: Store<any>,
    private _smsAdmin: SmsAdmin,
    private _dashboardService: MmDashboardService,
    private _modalService: ModalOverlayService,
  ) {
    this.modalType$ = of(this.dialogData.type);
    this.editingExisting$ = of(this.dialogData?.hash ? true : false);

    this.modalTitle$ = this.modalType$.pipe(
      map(type => MM_DASHBOARD_EDIT_MODAL_TITLE_MAP[type]),
    );

    this.modalTitle$ = combineLatest([
      this.modalType$,
      this.editingExisting$,
    ]).pipe(
      map(([type, isEditing]) => {
        if (type === MmDashboardEditModalType.MINGA && isEditing) {
          return this.MESSAGES.EDIT_TITLE;
        }

        return MM_DASHBOARD_EDIT_MODAL_TITLE_MAP[type];
      }),
    );

    this.mingaFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      placeId: new FormControl('', [Validators.required]),
      logo: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl('', [MgValidators.NorthAmericanPhoneNumber]),
      websiteUrl: new FormControl('', [MgValidators.WebsiteUrl]),
      mingaType: new FormControl(MingaTypeValue.MINGA),
      mingaSubscriptionId: new FormControl(null, [
        Validators.required,
        Validators.min(1), // the component likes to set the id to 0 when nothing is selected, so this is essentially validating as a required field
      ]),
      mingaStatus: new FormControl(MingaStatusValue.DEMO),
      schoolType: new FormControl(SchoolTypeValue.HIGH_SCHOOL),
      adminFullName: new FormControl('', [
        this._adminFullNameValidator(),
        Validators.maxLength(80),
      ]),
      adminFirstName: new FormControl('', [
        this._adminSplitNameValidator(),
        Validators.maxLength(80),
      ]),
      adminLastName: new FormControl('', [
        this._adminSplitNameValidator(),
        Validators.maxLength(80),
      ]),
      adminPhone: new FormControl('', [MgValidators.NorthAmericanPhoneNumber]),
      adminEmail: new FormControl(''),
      adminID: new FormControl(''),
      startDate: new FormControl(day(), [Validators.required]),
      endDate: new FormControl(null, [Validators.required]),
      mingaPartnerId: new FormControl(''),
      mingaDistrictId: new FormControl(''),
      featureToggles: new FormControl(''),
      hubSpotId: new FormControl(''),
    });

    const mingaType = this.mingaFormGroup.get('mingaType');
    if (mingaType) {
      this._setSubscriptionsForMingaType(mingaType?.value);
      mingaType.valueChanges
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(value => {
          this._setSubscriptionsForMingaType(value);
        });
    }

    this.uploadFormControl = new FormControl('');
    this.uploadFormControl.statusChanges
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(status => {
        if (status === 'PENDING') {
          this._uploading = true;
        } else {
          this._uploading = false;
        }

        if (status === 'VALID') {
          // don't send the logo update here when creating a minga.
          if (this.editing) {
            this.onUploadLogoDone(this.uploadFormControl.value);
          }
        }
      });

    if (this.dialogData.type === MmDashboardEditModalType.MINGA) {
      this._setupMingaEditModal(this.dialogData.hash);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  preventNavigation(): string {
    if (this.mingaFormGroup.dirty) {
      return 'You have unsaved changes. Are you sure you want to leave?';
    }

    if (this.mingaFormGroup.pending) {
      return 'Some of your changes are still pending. Are you sure you want to leave?';
    }

    return '';
  }

  public getErrorKey(formControl: AbstractControl) {
    if (!formControl) return '';

    return formControl.errors ? Object.keys(formControl.errors)[0] : '';
  }

  public onUploadLogoDone(asset) {
    this._loading = true;
    return this._onUploadLogoDone(asset).then(
      () => {
        this._loading = false;
      },
      err => {
        console.error(err);
        this._loading = false;
      },
    );
  }

  public onAddressChange(value: IAddressModelValue) {
    if (!value) return;

    if (value.components.country === 'US') {
      this.mingaCountry = MingaCountryEnum.USA;
    } else if (value.components.country === 'CA') {
      this.mingaCountry = MingaCountryEnum.CAN;
    } else {
      this.mingaCountry = MingaCountryEnum.INTL;
    }
  }

  public async onMingaTypeChange(e) {
    if (e.value === MingaTypeValue.CONFERENCE) {
      this.mingaFormGroup.controls['schoolType'].setValue(
        SchoolTypeValue.OTHER,
      );
      this.mingaFormGroup.controls['schoolType'].markAsDirty();
    }
  }

  public isConferenceSelected(): boolean {
    if (
      this.mingaFormGroup.controls['mingaType'].value ===
      MingaTypeValue.CONFERENCE
    ) {
      return true;
    }
    return false;
  }

  public async updateMinga(params: IMingaFormFields) {
    const fieldEnteries = Object.entries(params);
    const newFields = {};
    let hasNewFields = false;

    // only update changed fields, excluding pin which isn't editable
    for (const [fieldName, fieldValue] of fieldEnteries) {
      if (!_.isEqual(this.initialFields[fieldName], fieldValue)) {
        if (
          this.mingaFormGroup.controls[fieldName] &&
          !this.mingaFormGroup.controls[fieldName].pristine
        ) {
          newFields[fieldName] = fieldValue;
          hasNewFields = true;
        }
      }
    }

    // if nothing has changes lets just exit
    if (!hasNewFields) {
      return this._postSubmit();
    }

    try {
      await this._mingaManager.updateMinga(
        this.hash,
        newFields,
        new UpdateMingasRequest(),
      );

      this._postSubmit();
      this._systemAlertSnackBar.success(`Minga updated successfully!`);
    } catch (err) {
      this._systemAlertSnackBar.error(`updateMinga error: ` + err);
      await this._submitErr(err, 'update');
    }
  }

  public async openMinga() {
    await this._rootService.addLoadingPromise(this._gotoBrowseMinga());
    this._modal.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  public async confirmTogglePlayMinga() {
    if (this.isMingaPaused) {
      return this._confirmPlayMinga();
    } else {
      return this._confirmPauseMinga();
    }
  }

  public onKeypress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  public async saveSmsMaxSend() {
    this.smsLoading = true;
    try {
      this.smsDetails = await this._smsAdmin.setMaxSendCount(
        this.hash,
        this.currentMaxSendCount,
      );
    } catch (err) {
      console.error(err);
      if (this.smsDetails) {
        this.currentMaxSendCount = this.smsDetails.maxSendCount;
      }
    } finally {
      this.smsLoading = false;
    }
  }

  public async openAddMessageBlockDialog() {
    if (this.addBlockDialog) {
      this.addBlockDialog.close();
    }
    const addBlockDialog = this._dialog.open(this.addBlockDialogTpl);
    addBlockDialog.afterClosed().subscribe(() => {
      if (this.addBlockDialog === addBlockDialog) {
        delete this.addBlockDialog;
      }
    });

    this.addBlockDialog = addBlockDialog;
  }

  public async addBlock() {
    this.smsLoading = true;
    try {
      this.smsDetails = await this._smsAdmin.addMessageBlock(
        this.hash,
        this.currentSmsBlockSize,
      );
      this.currentMaxSendCount = this.smsDetails.maxSendCount;
      if (this.addBlockDialog) {
        this.addBlockDialog.close();
      }
    } finally {
      this.smsLoading = false;
    }
  }

  public mingaPartnerChange(partnerId: number) {
    this.mingaFormGroup.controls.mingaPartnerId.setValue(partnerId);
    this.mingaFormGroup.controls.mingaPartnerId.markAsDirty();
  }

  public mingaDistrictChange(districtId: number) {
    this.mingaFormGroup.controls.mingaDistrictId.setValue(districtId);
    this.mingaFormGroup.controls.mingaDistrictId.markAsDirty();
  }

  public async deleteOrCancel(): Promise<void> {
    if (
      this.editing &&
      this.dialogData.type === MmDashboardEditModalType.MINGA
    ) {
      await this._openDestroyMingaDialog();
    } else {
      this._modal.close(ModalOverlayServiceCloseEventType.CLOSE);
    }
  }

  public onMingaModulesChange(moduleStates: IMingaFeatureToggle) {
    const control = this.mingaFormGroup.controls.featureToggles;
    control.setValue(moduleStates);
    control.markAsDirty();
  }

  private async _setupMingaEditModal(mingaHash?: string) {
    this.loading = true;
    this.hash = '';

    if (mingaHash) {
      this.hash = mingaHash;
      this.editing = true;
      await this._fetchSmsDetails(mingaHash);
      await this._mingaManager.getMinga(mingaHash).then(
        async minga => {
          this.loading = false;
          this.isMingaPaused = minga.getPaused();
          if (minga.hasBaseContentGroup()) {
            this.baseContentGroupHash = minga.getBaseContentGroup().getValue();
          }
          this._setFieldsFromMinga(minga, true);
        },
        err => {
          console.error(err);
          const codeKey = grpcCodeKey(err.code);
          this._systemAlertSnackBar.error(`Unable to load minga (${codeKey})`);
          this.deleteOrCancel();

          this.loading = false;
        },
      );
    } else {
      this.editing = false;
      this.loading = false;
    }
  }

  public submit() {
    // minga form
    if (
      MmDashboardEditModalType.MINGA === this.dialogData.type &&
      this.mingaFormGroup.invalid
    ) {
      Object.values(this.mingaFormGroup.controls).forEach(control => {
        control.markAsTouched();
        control.updateValueAndValidity();
      });
      return;
    }

    // partner and district form
    if (
      MmDashboardEditModalType.MINGA !== this.dialogData.type &&
      this.name.invalid
    ) {
      this.name.markAsTouched();
      return;
    }

    this._loading = true;
    switch (this.dialogData.type) {
      case MmDashboardEditModalType.MINGA:
        this._submitMinga();
        break;
      case MmDashboardEditModalType.PARTNER:
        this._submitPartner();
        break;
    }
  }

  private async _submitMinga() {
    if (this.mingaFormGroup.invalid) {
      this._loading = false;
      return;
    }
    const params: IMingaFormFields = {
      name: this.mingaFormGroup.controls.name.value,
      logo: this.mingaFormGroup.controls.logo.value,
      placeId: this.mingaFormGroup.controls.placeId.value,
      email: this.mingaFormGroup.controls.email.value,
      phone: this.mingaFormGroup.controls.phone.value,
      websiteUrl: this.mingaFormGroup.controls.websiteUrl.value,
      mingaType: this.mingaFormGroup.controls.mingaType.value,
      mingaStatus: this.mingaFormGroup.controls.mingaStatus.value,
      mingaSubscriptionId:
        this.mingaFormGroup.controls.mingaSubscriptionId.value,
      schoolType: this.mingaFormGroup.controls.schoolType.value,
      adminFullName: this.mingaFormGroup.controls.adminFullName.value,
      adminFirstName: this.mingaFormGroup.controls.adminFirstName.value,
      adminLastName: this.mingaFormGroup.controls.adminLastName.value,
      adminEmail: this.mingaFormGroup.controls.adminEmail.value,
      adminPhone: this.mingaFormGroup.controls.adminPhone.value,
      startDate: this.mingaFormGroup.controls.startDate.value.toDate(),
      endDate: this.mingaFormGroup.controls.endDate.value.toDate(),
      mingaPartnerId: this.mingaFormGroup.controls.mingaPartnerId.value,
      mingaDistrictId: this.mingaFormGroup.controls.mingaDistrictId.value,
      featureToggles: this.mingaFormGroup.controls.featureToggles.value,
      adminID: this.mingaFormGroup.controls.adminID.value,
      hubSpotId: this.mingaFormGroup.controls.hubSpotId.value,
    };

    if (this.editing) {
      await this.updateMinga(params);
    } else {
      this._mingaManager
        .createMingas(params)
        .then(minga => {
          this._systemAlertSnackBar.success('Successfully created minga');

          if (minga.hasHash()) {
            const mingaHash = minga.getHash().getValue();
            this.hash = mingaHash;
            if (this.uploadFormControl.value) {
              this.onUploadLogoDone(this.uploadFormControl.value);
            }

            // Change route to update route with new hash
            const updateNavigate = [
              '',
              { outlets: { o: ['minga', 'update', mingaHash] } },
            ];
            this._loading = false;
            this._postSubmit();
            this._showSuccessModal('Minga Created!');
          } else {
            this._loading = false;
            console.warn("Didn't return a minga hash, closing overlay");
            this._postSubmit();
          }
        })
        .catch(err => {
          this._submitErr(err, 'create');
        });
    }
  }

  private async _submitPartner() {
    if (this.name.invalid) {
      this._loading = false;
      return;
    }
    const error = await this._dashboardService.upsertMingaPartner({
      name: this.name.value,
    });

    if (!error) {
      this._postSubmit();
      this._showSuccessModal('Minga Partner Created!');
    }
    this._loading = false;
  }

  private _setFieldsFromMinga(minga: Minga, pristine: boolean = false) {
    const startDate = minga.getStartDate()
      ? dateTimeMessageToDateObject(minga.getStartDate())
      : new Date();
    const endDate = minga.getEndDate()
      ? dateTimeMessageToDateObject(minga.getEndDate())
      : new Date();
    this.mingaLogo = minga.getImageUrl();
    const controlValues = {
      name: minga.getName(),
      placeId: minga.getPlaceId(),
      email: minga.getEmail(),
      phone: minga.getPhoneNumber(),
      websiteUrl: minga.getWebsiteUrl(),
      mingaType: minga.getMingaType(),
      mingaStatus: minga.getMingaStatus(),
      mingaSubscriptionId: minga.getMingaSubscriptionId(),
      schoolType: minga.getSchoolType(),
      adminFullName: minga.getAdminFullName(),
      adminEmail: minga.getAdminEmail(),
      adminPhone: minga.getAdminPhone(),
      hubSpotId: minga.getHubSpotId(),
      startDate: day(startDate),
      endDate: day(endDate),
      featureToggles: '',
      mingaPartnerId: undefined,
      mingaDistrictId: undefined,
    };

    if (minga.getPartner()) {
      controlValues.mingaPartnerId = minga.getPartner().toArray()[0];
    }
    if (minga.getDistrict()) {
      controlValues.mingaDistrictId = minga.getDistrict().toArray()[0];
    }

    this.initialFields = controlValues;

    for (const key in controlValues) {
      const control = this.mingaFormGroup.controls[key];
      control.setValue(controlValues[key]);
    }

    if (pristine) {
      for (const key in controlValues) {
        const control = this.mingaFormGroup.controls[key];
        control.markAsPristine();
        control.markAsUntouched();

        // If we get a pending control then we have to wait for that pending to
        // be over to set it pristine.
        if (control.pending) {
          const sub = control.statusChanges.subscribe(() => {
            control.markAsPristine();
            control.markAsUntouched();

            sub.unsubscribe();
          });
        }
      }
    }
  }

  private async _onUploadLogoDone(asset) {
    const response = await this._mingaManager.updateMingaImage(
      this.hash,
      asset,
    );

    const status = response.getStatus();

    if (status === StatusCode.OK) {
      const profileImageInfo = response.getMingaImageInfo();
      const sizeMap = profileImageInfo.getSizeMap();
      const rawPath = sizeMap.get('raw').getPath();
      this.mingaLogo = rawPath;
    }
  }

  private async _gotoBrowseMinga() {
    let response = new OpenMingaResponse();
    // init with error state, will be overwritten
    response.setStatus(StatusCode.ERROR);
    try {
      response = await this._peopleManager.openMinga(this.hash);
    } catch (err) {
      console.warn('[MingaForm] openMinga() caught error:', err);
    }

    const statusCode = response.getStatus();

    if (statusCode === StatusCode.ERROR) {
      const reason = response.getReason();
      this._systemAlertSnackBar.error(`Error occurred (${reason})`);
    } else if (statusCode === StatusCode.BUSY) {
      this._systemAlertSnackBar.error(
        `Unable to open minga at this time (busy)`,
      );
    } else if (statusCode === StatusCode.OK) {
      this._store.dispatch(new ChangeMingaAction(this.hash));
      this._mingaManager.showMingaChangeSuccess();
      // E.T. go home!
      await this._router.navigate(['/home', { outlets: { o: null } }]);
    }
    return;
  }

  private async _pauseMinga(paused: boolean) {
    await this._rootService.addLoadingPromise(
      this._mingaManager.pauseMinga(paused, this.hash),
    );
    this.isMingaPaused = paused;
  }

  private async _confirmPlayMinga() {
    const dialogRef = this._dialog.open(SaveCancelDialog, {
      data: {
        text: 'Are you sure you want to unpause this minga?',
        saveButtonLocale: 'button.unpause',
      },
    });

    dialogRef.beforeClosed().subscribe(shouldUnpause => {
      if (shouldUnpause) {
        this._pauseMinga(false);
      }
    });
  }

  private async _confirmPauseMinga() {
    const dialogRef = this._dialog.open(SaveCancelDialog, {
      data: {
        text: 'Are you sure you want to pause this minga?',
        saveButtonLocale: 'button.pause',
      },
    });

    dialogRef.beforeClosed().subscribe(shouldPause => {
      if (shouldPause) {
        this._pauseMinga(true);
      }
    });
  }

  private _postSubmit() {
    this.mingaFormGroup.markAsPristine();
    this.mingaFormGroup.markAsUntouched();
    this.name.reset();

    this._mingaManager.emitMingasUpdate();
    this.loading = false;
    this._modal.close(ModalOverlayServiceCloseEventType.SUBMIT);
  }

  private async _submitErr(err: any, context = 'create') {
    const codeKey = grpcCodeKey(err.code);
    let message = `Could not ${context} minga`;

    if (codeKey && codeKey !== 'Unknown') {
      message += ` (${codeKey})`;
    } else if (err.message) {
      message += ': ' + err.message;
    }

    this._systemAlertSnackBar.error(message);
    this._loading = false;
  }

  private async _setSubscriptionsForMingaType(mingaType: string) {
    this.mingaSubscriptions = await this._mingaManager.getMingaSubscriptions(
      mingaType,
    );
  }

  private async _fetchSmsDetails(mingaHash: string) {
    const details = await this._smsAdmin.getDetails(mingaHash);
    this.smsDetails = details;
    this.currentMaxSendCount = details.maxSendCount;
  }

  private async _showSuccessModal(message: string) {
    this._modalService.open(ModalOverlaySuccessComponent, {
      data: {
        iconName: 'aboutminga-o',
        iconColor: '#1c2f559',
        message,
        showConfetti: true,
        hangTime: 1500,
      },
    });
  }

  private async _openDestroyMingaDialog() {
    const dialogRef = this._dialog.open(DestroyMingaDialogComponent, {
      data: { confirmDestroy: this.mingaFormGroup.controls.name.value },
    });

    dialogRef.beforeClosed().subscribe(async shouldDestroy => {
      if (shouldDestroy === true) {
        try {
          await this._mingaManager.deleteMinga(this.hash);
          this._systemAlertSnackBar.success('Successfully deleted minga');
          this._mingaManager.emitMingasUpdate();
          this._modal.close(ModalOverlayServiceCloseEventType.DELETE);
        } catch (err) {
          console.error(err);
          this._systemAlertSnackBar.error('Error deleting minga');
        }
      }
    });
  }

  private _adminFullNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.editing) return null;
      return control.value ? null : { doesContainFullName: false };
    };
  }

  private _adminSplitNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.editing) return null;
      return control.value ? null : { doesContainNames: false };
    };
  }
}
