import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'mg-tools-rewards',
  templateUrl: './tools-rewards.component.html',
  styleUrls: ['./tools-rewards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsRewardsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
