export enum CheckinReportMessages {
  NAME = 'Name, ID number or Email',
  REASON = 'Reason Type',
  ROLE = 'Role',
  GRADE = 'Grade',
  LOADING = 'Loading filters...',
  USER_LIST = 'User List',
}

export enum CheckinReportFields {
  ISSUED_TO = 'issuedTo',
  ISSUED_BY = 'issuedBy',
  REASON = 'reason',
  ROLE = 'role',
  GRADE = 'grade',
  USER_LIST = 'userList',
}
