import { NgModule } from '@angular/core';

import { MgAvatarModule } from 'src/app/elements/MgAvatar';

import { CommonUIModules } from '@shared/modules';

import { UserProfileHeaderComponent } from './user-profile-header.component';

@NgModule({
  imports: [
    // Common modules
    CommonUIModules,

    // Minga dependencies
    MgAvatarModule,

    // External dependencies
  ],
  declarations: [UserProfileHeaderComponent],
  exports: [UserProfileHeaderComponent],
})
export class UserProfileHeaderModule {}
