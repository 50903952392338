import { IPeopleCollectionPersonDetails } from 'minga/domain/person';

import { Person } from '../models';

export namespace PeopleMapper {
  export function fromIPeopleCollectionPersonDetails(
    person: IPeopleCollectionPersonDetails,
    dmMingaDisabled: boolean,
  ): Person {
    return {
      badgeIconColor: person.badgeIconColor,
      badgeIconUrl: person.badgeIconUrl,
      badgeRoleName: person.badgeRoleName,
      displayName: person.displayName,
      firstName: person.firstName,
      hash: person.personHash,
      lastName: person.lastName,
      profileImageUrl: person.profileImageUrl,
      roleType: person.roleType,
      directMessagesMingaDisabled: dmMingaDisabled,
      directMessagesPersonallyDisabled: !person.dmEnabled,
      receivingDirectMessages: person.dmEnabled,
    };
  }
}
