<mg-sidenav-tabs
  [mqAlias]="mqAlias"
  [tabLabels]="tabs.labels"
  [(open)]="openedSideNav"
  [selectedIndex]="tabs.activeIndex"
  (selectedIndexChange)="onTabIndexChange($event)">
  <mg-sidenav-tabs-header class="manager-overlay-mobile-menu">
    <ng-container *ngTemplateOutlet="overlayTitle"></ng-container>
  </mg-sidenav-tabs-header>
  <div class="overlay">
    <header [ngClass]="addColorThemeClasses('overlay-header')">
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="10px">
        <div fxFlex="nogrow">
          <button
            class="overlay-header-button"
            (click)="handleOnClickCloseIcon()">
            <mg-icon>close</mg-icon>
          </button>
        </div>
        <div
          class="overlay-header-title"
          fxFlex="grow"
          fxLayout="row"
          fxLayoutAlign="center center">
          <img
            *ngIf="showLogo"
            class="overlay-header-logo"
            src="assets/MingaLogo_white.svg" />
          <div class="overlay-header-content">
            <ng-container *ngTemplateOutlet="overlayTitle"></ng-container>
          </div>
        </div>
        <div fxFlex="nogrow">
          <ng-container *ngIf="isOnMobile; else desktopHamburger">
            <ng-container *ngTemplateOutlet="mobileHamburger"></ng-container>
          </ng-container>
        </div>
      </div>
    </header>
    <div class="overlay-inner">
      <main
        class="overlay-content"
        [mgScrollTarget]="true"
        [class.noPadding]="noPadding">
        <ng-content></ng-content>
      </main>
      <footer
        [fxHide]="tabs.labels.length === 1"
        fxHide.lt-md
        [ngClass]="addColorThemeClasses('overlay-footer')">
        <ng-container *ngTemplateOutlet="footerTabsMenu"></ng-container>
      </footer>
    </div>
  </div>
</mg-sidenav-tabs>

<!-- Mobile Hamburger Menu -->
<ng-template #mobileHamburger>
  <div *ngIf="managerRoutes.length">
    <button
      class="overlay-header-button"
      (click)="handleOnClickOpenDrawer()">
      <mg-icon>hamburger</mg-icon>
    </button>
  </div>
</ng-template>

<!-- Desktop Hamburger Menu -->
<ng-template #desktopHamburger>
  <div *ngIf="managerRoutes.length">
    <button
      class="overlay-header-button"
      [matMenuTriggerFor]="relatedPages">
      <mg-icon>hamburger</mg-icon>
    </button>
    <mat-menu #relatedPages="matMenu">
      <button
        *ngFor="let route of managerRoutes"
        mat-menu-item
        (click)="goTo(route.path)">
        {{ route.label }}
      </button>
    </mat-menu>
  </div>
</ng-template>

<!-- Footer Tabs Menu -->
<ng-template #footerTabsMenu>
  <mg-nav-tabs
    [tabLabels]="tabs.labels"
    [selectedIndex]="tabs.activeIndex"
    (selectedIndexChange)="onTabIndexChange($event)">
  </mg-nav-tabs>
</ng-template>
