<mg-button-icon
  (click)="leave()"
  class="top left"
  icon="close"></mg-button-icon>

<p class="mg-dialog message dialog-title">
  You have unsaved changes. Are you sure you want to leave?
</p>

<div class="footer-button-container">
  <mg-button
    type="text"
    (click)="leave()"
    >Leave</mg-button
  >
  <mg-button-outline (click)="stay()"> Stay </mg-button-outline>
</div>
