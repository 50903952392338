import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgIconModule } from 'minga/app/src/app/icon';

import { MgOverlayNavItemComponent } from './MgOverlayNavItem.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgOverlayNavItemComponent],
  exports: [MgOverlayNavItemComponent],
})
export class MgOverlayNavItemModule {}
