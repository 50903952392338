<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="(formState$ | async) === 'loading'"
  [overlayTitle]="
    (isNew$ | async) === true
      ? MESSAGES.MODAL_TITLE_NEW
      : MESSAGES.MODAL_TITLE_EXISTING
  ">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<ng-template #mainTemplate>
  <main
    class="modal-form"
    #formElement>
    <form
      id="consequence-type"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <fieldset *ngIf="(formState$ | async) === 'invalid'">
          <mg-system-alert
            type="error"
            [message]="MESSAGES.INVALID_FORM_MESSAGE"></mg-system-alert>
        </fieldset>
        <fieldset>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <div
              fxLayout="row"
              fxLayoutGap="12px">
              <mg-chip
                [id]="'pbis-csq-edit-click-praise'"
                [active]="
                  form.get(FORM_FIELDS.CATEGORY).value === MSG_CATEGORY.PRAISE
                "
                (pressed)="setCategory(MSG_CATEGORY.PRAISE)">
                {{ MESSAGES.BUTTON_TOGGLE_PRAISE }}
              </mg-chip>
              <mg-chip
                [id]="'pbis-csq-edit-click-guidance'"
                [active]="
                  form.get(FORM_FIELDS.CATEGORY).value === MSG_CATEGORY.GUIDANCE
                "
                (pressed)="setCategory(MSG_CATEGORY.GUIDANCE)">
                {{ MESSAGES.BUTTON_TOGGLE_GUIDANCE }}
              </mg-chip>
            </div>
            <mg-form-text-input
              [label]="MESSAGES.FIELD_LABEL_NAME"
              [control]="form.get(FORM_FIELDS.NAME)">
            </mg-form-text-input>
            <div
              fxLayout="column"
              fxLayoutGap="4px">
              <mg-form-select
                [options]="
                  form.get(FORM_FIELDS.CATEGORY).value === MSG_CATEGORY.PRAISE
                    ? praiseOptions
                    : guidanceOptions
                "
                [control]="form.get(FORM_FIELDS.TYPE)"
                [placeholder]="MESSAGES.FIELD_LABEL_TYPE"
                [isClearable]="true"
                [value]="form.get(FORM_FIELDS.TYPE).value">
              </mg-form-select>
              <mg-text
                variant="body-xs"
                color="surface-alt">
                {{
                  REQUIRED_TYPES.includes(form.get(FORM_FIELDS.TYPE).value)
                    ? MESSAGES.FIELD_LABEL_TYPE_REQUIRED_DESCRIPTION
                    : MESSAGES.FIELD_LABEL_TYPE_NOT_REQUIRED_DESCRIPTION
                }}
              </mg-text>
            </div>
            <mg-form-text-input
              [label]="MESSAGES.FIELD_LABEL_DESCRIPTION"
              [control]="form.get(FORM_FIELDS.DESCRIPTION)">
            </mg-form-text-input>
            <mg-form-toggle
              [disabled]="
                !REQUIRED_TYPES.includes(form.get(FORM_FIELDS.TYPE).value)
              "
              [label]="MESSAGES.FIELD_LABEL_ENABLE_DUE_DATE"
              [tooltip]="MESSAGES.FIELD_LABEL_ENABLE_DUE_DATE_TOOLTIP"
              [control]="form.get(FORM_FIELDS.DUE_DATE_ENABLE)">
            </mg-form-toggle>

            <div
              fxLayout="column"
              fxLayoutGap="8px">
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center">
                <mg-text variant="body-md">
                  {{ MESSAGES.FIELD_LABEL_ICON }}
                </mg-text>
                <div class="icon-input">
                  <mg-form-select
                    [value]="form.get(FORM_FIELDS.ICON).value"
                    [control]="form.get(FORM_FIELDS.ICON)"
                    [closeOnSelect]="true"
                    [multiple]="false"
                    [searchable]="false"
                    [condensed]="true"
                    [options]="ICON_OPTIONS">
                    <ng-template let-item="item">
                      <div class="icon-wrap">
                        <mg-icon
                          icon-namespace="minga.behaviors"
                          [style.color]="
                            form.get(FORM_FIELDS.ICON_COLOR).value
                          ">
                          {{ item.value }}
                        </mg-icon>
                      </div>
                    </ng-template>
                  </mg-form-select>
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center">
                <mg-text variant="body-md">
                  {{ MESSAGES.FIELD_LABEL_ICON_COLOR }}
                </mg-text>
                <div class="color-input">
                  <mg-form-text-input
                    inputType="color"
                    [condensed]="true"
                    list="colorDatalist"
                    [control]="form.get(FORM_FIELDS.ICON_COLOR)">
                  </mg-form-text-input>
                  <datalist id="colorDatalist">
                    <div *ngFor="let item of PRESET_COLORS">
                      <option>{{ item }}</option>
                    </div>
                  </datalist>
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center">
                <mg-text variant="body-md">
                  {{ MESSAGES.FIELD_LABEL_POINTS }}
                </mg-text>
                <div>
                  <mg-form-text-input
                    inputType="number"
                    [control]="form.get(FORM_FIELDS.POINTS)">
                  </mg-form-text-input>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <hr class="fieldset-seperator" />

        <!-- Escalation form -->
        <fieldset>
          <div fxLayout="column">
            <mg-text
              variant="header-sm-secondary"
              textAlign="left"
              spacing="1">
              {{ MESSAGES.FIELDSET_OVERDUE_ACTION_LABEL }}
            </mg-text>
            <mg-text
              variant="body-xs"
              color="surface-alt"
              textAlign="left"
              spacing="4">
              {{ MESSAGES.FIELD_OVERDUE_LABEL_NOTE }}
            </mg-text>

            <mg-form-toggle
              [id]="'pbis-csq-edit-click-overdue-csq'"
              [label]="MESSAGES.FIELD_LABEL_OVERDUE_TYPE_LABEL"
              [disabled]="
                !REQUIRED_TYPES.includes(form.get(FORM_FIELDS.TYPE).value) ||
                !form.get(FORM_FIELDS.DUE_DATE_ENABLE).value
              "
              [control]="form.get(FORM_FIELDS.OVERDUE_ACTION)">
            </mg-form-toggle>
            <mat-radio-group
              class="bottom-margin"
              fxLayout="column"
              [value]="this.form.get(FORM_FIELDS.OVERDUE_ACTION_TYPE).value"
              [disabled]="!form.get(FORM_FIELDS.OVERDUE_ACTION).value"
              (change)="
                form.get(FORM_FIELDS.OVERDUE_ACTION_TYPE).setValue($event.value)
              ">
              <section
                fxLayout="column"
                fxLayoutGap="8px">
                <div fxLayout="column">
                  <mat-radio-button
                    [attr.data-analytics]="'pbis-csq-edit-click-addi-csq'"
                    [value]="OVERDUE_ACTION.ADDITIONAL">
                    {{ MESSAGES.FIELD_OVERDUE_ACTION_ADDITIONAL_LABEL }}
                  </mat-radio-button>
                  <mg-text
                    class="field-hint"
                    variant="body-xs"
                    [color]="
                      form.get(FORM_FIELDS.OVERDUE_ACTION).value
                        ? 'surface-alt'
                        : 'on-disabled-bg'
                    ">
                    {{ MESSAGES.FIELD_OVERDUE_ACTION_ADDITIONAL_NOTE }}
                  </mg-text>
                </div>
                <div fxLayout="column">
                  <mat-radio-button
                    [attr.data-analytics]="'pbis-csq-edit-click-esc-csq'"
                    [value]="OVERDUE_ACTION.ESCALATE">
                    {{ MESSAGES.FIELD_OVERDUE_ACTION_ESCALATE_LABEL }}
                  </mat-radio-button>
                  <mg-text
                    class="field-hint"
                    variant="body-xs"
                    [color]="
                      form.get(FORM_FIELDS.OVERDUE_ACTION).value
                        ? 'surface-alt'
                        : 'on-disabled-bg'
                    ">
                    {{ MESSAGES.FIELD_OVERDUE_ACTION_ESCALATE_NOTE }}
                  </mg-text>
                </div>
              </section>
            </mat-radio-group>

            <mg-form-select
              class="bottom-margin"
              [id]="'pbis-csq-edit-click-can-assign'"
              [placeholder]="MESSAGES.FIELD_OVERDUE_ACTION_CONSEQUENCE_LABEL"
              [options]="consequenceSelectOptions$ | async"
              [closeOnSelect]="true"
              [searchable]="true"
              [disabled]="!form.get(FORM_FIELDS.OVERDUE_ACTION).value"
              [control]="form.get(FORM_FIELDS.OVERDUE_ACTION_CONSEQUENCE)"
              [value]="form.get(FORM_FIELDS.OVERDUE_ACTION_CONSEQUENCE).value">
              <ng-template let-item="item">
                <mg-form-select-option [option]="item"></mg-form-select-option>
              </ng-template>
            </mg-form-select>
            <mg-form-toggle
              [label]="MESSAGES.FIELD_OVERDUE_ACTION_ENABLE_DUE_DATE_LABEL"
              [control]="form.get(FORM_FIELDS.OVERDUE_ACTION_DUE_DATE)"
              [disabled]="
                !form.get(FORM_FIELDS.OVERDUE_ACTION).value ||
                !form.get(FORM_FIELDS.OVERDUE_ACTION_CONSEQUENCE).value
              ">
            </mg-form-toggle>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center">
              <div
                fxLayout="row"
                fxLayoutAlign="start center">
                <mg-text
                  variant="body-md"
                  [class.disabled-text]="
                    !form.get(FORM_FIELDS.OVERDUE_ACTION).value ||
                    !form.get(FORM_FIELDS.OVERDUE_ACTION_DUE_DATE).value
                  ">
                  {{ MESSAGES.FIELD_OVERDUE_ACTION_DAYS_TO_COMPLETE_LABEL }}
                </mg-text>
                <mg-tooltip
                  [label]="
                    MESSAGES.FIELD_OVERDUE_ACTION_DAYS_TO_COMPLETE_TOOLTIP
                  ">
                </mg-tooltip>
              </div>

              <div fxFlex="nogrow">
                <mg-form-text-input
                  inputType="number"
                  min="0"
                  [isDisabled]="
                    !form.get(FORM_FIELDS.OVERDUE_ACTION).value ||
                    !form.get(FORM_FIELDS.OVERDUE_ACTION_DUE_DATE).value
                  "
                  [control]="
                    form.get(FORM_FIELDS.OVERDUE_ACTION_DAYS_TO_COMPLETE)
                  ">
                </mg-form-text-input>
              </div>
            </div>
          </div>
        </fieldset>

        <hr class="fieldset-seperator" />

        <!-- Restriction form -->
        <fieldset>
          <legend>
            <mg-text variant="header-sm-secondary">
              {{ MESSAGES.FIELDSET_RESTRICTION_LABEL }}
            </mg-text>
          </legend>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mg-form-restriction-input
              class="mobile-padding"
              [label]="MESSAGES.FIELD_LABEL_RESTRICTION"
              [selectableTypes]="[
                RESTRICTION_TABS.Role,
                RESTRICTION_TABS.Lists,
                RESTRICTION_TABS.Groups,
                RESTRICTION_TABS.Grades,
                RESTRICTION_TABS.MembershipList
              ]"
              [membershipListType]="MEMBERSHIP_LIST_TYPE.BEHAVIOR_TYPE"
              [value]="form.get(FORM_FIELDS.RESTRICTION).value"
              [control]="form.get(FORM_FIELDS.RESTRICTION)">
            </mg-form-restriction-input>
          </div>
        </fieldset>

        <hr class="fieldset-seperator" />

        <!-- Sticker form -->
        <fieldset>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mg-text variant="header-sm-secondary">
              {{ MESSAGES.FIELDSET_STICKER_LABEL }}
            </mg-text>
            <mat-slide-toggle
              [attr.data-analytics]="'pbis-csq-edit-click-sticker'"
              [formControlName]="FORM_FIELDS.STICKER_ENABLE">
              {{ MESSAGES.FIELD_LABEL_ENABLE_STICKER }}
            </mat-slide-toggle>
            <mg-form-select
              [options]="stickerOptions$ | async"
              [control]="form.get(FORM_FIELDS.STICKER_ADD)"
              [placeholder]="MESSAGES.FIELD_LABEL_STICKER_ADD"
              [isClearable]="true"
              [disabled]="!form.get(FORM_FIELDS.STICKER_ENABLE).value"
              [value]="form.get(FORM_FIELDS.STICKER_ADD).value">
            </mg-form-select>
            <mg-form-select
              [options]="stickerOptions$ | async"
              [control]="form.get(FORM_FIELDS.STICKER_REMOVE)"
              [placeholder]="MESSAGES.FIELD_LABEL_STICKER_REMOVE"
              [isClearable]="true"
              [disabled]="!form.get(FORM_FIELDS.STICKER_ENABLE).value"
              [value]="form.get(FORM_FIELDS.STICKER_REMOVE).value">
            </mg-form-select>
          </div>
        </fieldset>

        <hr class="fieldset-seperator" />

        <!-- Notification form -->
        <fieldset>
          <legend>
            <mg-text variant="header-sm-secondary">
              {{ MESSAGES.FIELDSET_NOTIFICATION_LABEL }}
            </mg-text>
          </legend>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mat-slide-toggle
              [attr.data-analytics]="'pbis-csq-edit-click-note'"
              [formControlName]="FORM_FIELDS.NOTE_ENABLE">
              {{ MESSAGES.FIELD_LABEL_NOTE }}
              <mg-text
                variant="body-xs"
                color="surface-alt">
                {{ MESSAGES.FIELD_LABEL_NOTE_DESC }}
              </mg-text>
            </mat-slide-toggle>
            <div>
              <mat-slide-toggle
                [attr.data-analytics]="'pbis-csq-edit-click-parent-email'"
                [formControlName]="FORM_FIELDS.SEND_PARENT_EMAIL">
                {{ MESSAGES.PARENT_EMAIL_MSG }}
              </mat-slide-toggle>
              <div
                [fxHide]="!form.get(FORM_FIELDS.SEND_PARENT_EMAIL).value"
                class="top-padding">
                <div
                  fxLayout="column"
                  fxLayoutAlign="start stretch"
                  fxLayoutGap="12px">
                  <mg-form-text-input
                    [label]="MESSAGES.FIELD_LABEL_PARENT_EMAIL_SUBJECT"
                    [control]="form.get(FORM_FIELDS.PARENT_EMAIL_SUBJECT)">
                  </mg-form-text-input>
                  <mg-body-input
                    name="emailBody"
                    [placeholder]="MESSAGES.FIELD_LABEL_PARENT_EMAIL_BODY"
                    requiredMessage="A description is required"
                    minHeight="100px"
                    [maxlength]="5000"
                    [customToolbar]="true"
                    [customElement]="toolbarData"
                    [richContent]="true"
                    [formControl]="
                      $any(form.get(FORM_FIELDS.PARENT_EMAIL_BODY))
                    "
                    resetParentScrollbarElement=".modal-overlay-body">
                  </mg-body-input>
                  <pre class="instructions">{{
                    MESSAGES.QUILL_EDITOR_INSTRUCTIONS
                  }}</pre>
                </div>
              </div>
            </div>
            <div>
              <mat-slide-toggle
                [attr.data-analytics]="'pbis-csq-edit-click-parent-sms'"
                [disabled]="(hasSmsEnabled$ | async) === false"
                [formControlName]="FORM_FIELDS.SEND_PARENT_SMS">
                {{ MESSAGES.PARENT_SMS_MSG }}
                <mg-tooltip
                  *ngIf="(hasSmsEnabled$ | async) === false"
                  [label]="MESSAGES.PARENT_SMS_MSG_TOOLTIP">
                </mg-tooltip>
              </mat-slide-toggle>
              <div
                *ngIf="form.get(FORM_FIELDS.SEND_PARENT_SMS).value"
                class="top-padding">
                <mg-form-text-input
                  [label]="MESSAGES.PARENT_SMS_MSG"
                  [control]="form.get(FORM_FIELDS.PARENT_SMS_TEMPLATE)">
                </mg-form-text-input>
              </div>
            </div>
            <div>
              <mat-slide-toggle
                [attr.data-analytics]="'pbis-csq-edit-click-admin-email'"
                [formControlName]="FORM_FIELDS.SEND_ADMIN_EMAIL">
                {{ MESSAGES.ADMIN_EMAIL_MSG }}
              </mat-slide-toggle>
            </div>

            <!-- Admin Email Field -->
            <div [fxHide]="!form.get(FORM_FIELDS.SEND_ADMIN_EMAIL).value">
              <mg-form-select
                [placeholder]="MESSAGES.ADMIN_EMAIL_MSG"
                appendTo="body"
                [value]="form.get(FORM_FIELDS.ADMIN_EMAIL).value"
                [closeOnSelect]="true"
                [multiple]="true"
                [addTag]="addNew"
                [addTagText]="'Add email'"
                [searchable]="true"
                [hideSelected]="false"
                [options]="emailOptions"
                (selectionChanged)="onAdminEmailChange($event)">
              </mg-form-select>
              <mg-form-error *ngIf="form.get(FORM_FIELDS.ADMIN_EMAIL).errors">
                {{
                  form?.get(FORM_FIELDS.ADMIN_EMAIL).errors
                    | makeInputErrorMessage
                }}
              </mg-form-error>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  </main>
</ng-template>

<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [id]="
          (isNew$ | async) === true
            ? 'pbis-csq-edit-click-cancel'
            : 'pbis-csq-edit-click-delete'
        "
        [variant]="(isNew$ | async) === true ? 'text' : 'destructive'"
        (pressed)="confirmDelete()">
        {{ deleteLabel }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="consequence-type"
        variant="filled"
        [id]="
          (isNew$ | async) === true
            ? 'pbis-csq-edit-click-create'
            : 'pbis-csq-edit-click-save'
        "
        [disabled]="!canSubmit">
        {{ submitLabel }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
