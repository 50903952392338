<mg-report-table
  matSort
  [statusReference]="'hallPass'"
  [dataSourceService]="ds"
  [selectable]="canMultiSelect$ | async"
  [selectable]="true"
  [actionService]="actionService"
  [selectionMessage]="actionService.getSelectionMessage()"
  (matSortChange)="ds.sort($event)">
  <mg-archive-column [datasource]="ds"></mg-archive-column>
  <mg-edit-column [datasource]="ds"></mg-edit-column>
  <ng-template #actionBar>
    <mg-btn
      *ngIf="actionService.isSelectionValid()"
      variant="text"
      icon="mg-archive"
      iconSet="minga"
      (click)="actionService.archiveHallpass()">
      {{ MESSAGES.ARCHIVE_LABEL }}
    </mg-btn>
  </ng-template>
</mg-report-table>
