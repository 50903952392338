import { Injectable } from '@angular/core';
import { Group } from 'minga/app/src/app/groups/models';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { RolesService } from 'minga/app/src/app/roles/services';
import { MingaContent } from 'minga/proto/content/minga_ng_grpc_pb';
import { MingaSetCommentingRolesRequest } from 'minga/proto/content/minga_pb';
import { MingaPermission } from 'minga/util';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CommentHandlerService {
  constructor(
    private mingaContent: MingaContent,
    private groupsFacade: GroupsFacadeService,
    private rolesService: RolesService,
    private permissions: PermissionsService,
  ) {}

  async sendCommentRolesRequest(contentHash: string, roleTypes: string[]) {
    const request = new MingaSetCommentingRolesRequest();
    if (!roleTypes) {
      return;
    }
    request.setContentHash(contentHash);
    request.setAllowedCommentingRoleTypesList(roleTypes);

    await this.mingaContent.setCommentingRoles(request);
  }

  /**
   * Get default commentable roles. If the content is in a group with group
   * comment settings, use the group settings, otherwise use account level
   * settings.
   * @param ownerGroupHash
   */
  getDefaultCommentableRoles(ownerGroupHash?: string): Observable<string[]> {
    let groupObservable: Observable<Group | null>;
    // if a groupHash is passed, use that, otherwise get it from context.
    if (ownerGroupHash) {
      groupObservable = this.groupsFacade.getGroup(ownerGroupHash);
    } else {
      groupObservable = this.groupsFacade.getCurrentGroupIfSet();
    }
    return combineLatest([
      this.rolesService.commentPermitableRoles$,
      groupObservable,
    ]).pipe(
      map(([defaultAccountRoles, group]) => {
        if (group && group.allowedCommentingRoleTypes) {
          return group.allowedCommentingRoleTypes;
        } else {
          return defaultAccountRoles.map(r => r.roleType);
        }
      }),
    );
  }
  /**
   * Can the user comment, based on their role and comment settings
   * @param commentRoles - specific role settings saved on a piece of content.
   */
  canCurrentUserComment(
    commentRoles: string[] | null,
    ownerGroupHash?: string,
  ): Observable<boolean> {
    this.rolesService.fetchIfNeeded();

    // super admin should always be able to.
    if (this.permissions.hasPermission(MingaPermission.SUPERADMIN)) {
      return of(true);
    }
    if (
      !this.permissions.hasPermission(MingaPermission.CONTENT_COMMENT_CREATE)
    ) {
      return of(false);
    }
    return combineLatest([
      this.getDefaultCommentableRoles(ownerGroupHash),
      this.permissions.mingaRoleType$,
    ]).pipe(
      map(([defaultRoles, userRole]) => {
        if (!userRole) {
          return false;
        }
        if (commentRoles && commentRoles.length > 0) {
          return commentRoles.includes(userRole) ? true : false;
        }
        if (defaultRoles) {
          return defaultRoles.includes(userRole) ? true : false;
        }
        return false;
      }),
    );
  }
}
