<div
  class="mg-navigation-drawer"
  [class.profile-padding]="showProfile">
  <div
    class="profile-container"
    [routerLink]="'/profile'"
    routerLinkActive="active"
    *ngIf="showProfile && person"
    #profileContainer
    (click)="onRouterLinkClick()">
    <div
      class="avatar-container"
      *ngIf="person?.profileImageUrl"
      title="View User Profile">
      <mg-avatar
        [src]="person?.profileImageUrl"
        [color]="person?.badgeIconColor">
        <div
          class="current-avatar"
          [style.background-image]="
            'url(' + person?.profileImageUrl + ')'
          "></div>
      </mg-avatar>
    </div>
    <div class="name-container">
      <div class="name">
        <mg-person-minimal [person]="person"></mg-person-minimal>
      </div>
      <div
        class="link mg-microtext"
        *ngIf="showProfile">
        View profile
      </div>
    </div>
  </div>
  <div
    class="minga-container"
    #mingaContainer
    title="View Minga Profile">
    <div
      class="minga-container-link"
      [routerLink]="'/minga-profile'"
      routerLinkActive="active"
      (click)="onRouterLinkClick()">
      <div class="avatar-container">
        <div
          class="current-avatar minga"
          *ngIf="!!(minga$ | async)?.logo || mingaLogo"
          [style.background-image]="
            'url(' + ((minga$ | async)?.logo || mingaLogo) + ')'
          "></div>
        <mg-logo *ngIf="!(minga$ | async)?.logo && !mingaLogo"></mg-logo>
      </div>
      <div
        class="name-container"
        [class.with-icon]="isSuperAdmin$ | async"
        [class.with-country]="isSuperAdmin$ | async">
        <div class="name">
          {{ (minga$ | async)?.name || mingaName }}
        </div>
        <div
          class="link mg-microtext"
          *ngIf="showProfile">
          View Minga profile
        </div>
      </div>
    </div>

    <ng-container *ngIf="isSuperAdmin$ | async">
      <div class="flex-fill"></div>
      <mg-current-country [hideText]="true"></mg-current-country>
    </ng-container>
    <ng-container
      *ngIf="(isSuperAdmin$ | async) !== true && (mingas$ | async)?.length > 1">
      <mg-icon
        class="switcher-icon"
        (click)="openMingaSwitcher()"
        >minga-switcher</mg-icon
      >
    </ng-container>
  </div>

  <div class="section-divider"></div>
  <mg-nav-drawer-sub-nav
    *ngIf="'SUPERADMIN' | hasPermission"
    [icon]="'super_admin-o'"
    [title]="'Super Admin'"
    class="right-icon"
    [class.profile-padding]="showProfile">
    <mg-nav-link
      *ngIf="'SUPERADMIN' | hasPermission"
      [icon]="'challenge'"
      [routerLink]="'/admin/program'"
      routerLinkActive="active"
      [text]="'Manage Programs'"
      (click)="restartStream('')">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="'SUPERADMIN' | hasPermission"
      [icon]="'banner-select-o'"
      [routerLink]="'/sadmin/banner/library'"
      routerLinkActive="active"
      [text]="'Banner Manager'"
      (click)="restartStream('BannerLibrary')">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="'SUPERADMIN' | hasPermission"
      [icon]="'polls-o'"
      [routerLink]="'/sadmin/poll-style/library'"
      routerLinkActive="active"
      [text]="'Poll Backgrounds'"
      (click)="restartStream('PollStyleLibrary')">
    </mg-nav-link>

    <mg-nav-link
      *ngIf="'SUPERADMIN' | hasPermission"
      [icon]="'person'"
      [routerLink]="'/sadmin/people'"
      routerLinkActive="active"
      [text]="'People'"
      (click)="restartStream('')">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="'SUPERADMIN' | hasPermission"
      [icon]="'warning-o'"
      [routerLink]="'/moderation/global'"
      routerLinkActive="active"
      [text]="'Moderation'"
      (click)="restartStream('MingaGlobalReportFeed')">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="'SUPERADMIN' | hasPermission"
      [icon]="'settings'"
      [routerLink]="'/test/dashboard'"
      routerLinkActive="active"
      [text]="'Dev Area'"
      (click)="restartStream('')">
    </mg-nav-link>
  </mg-nav-drawer-sub-nav>
  <div class="section-divider"></div>
  <mg-nav-link
    *ngIf="
      ('MINGA_ACCOUNT_MANAGE' | hasPermission) ||
      ('SCHEDULED_REPORTS_MANAGE' | hasPermission)
    "
    [icon]="'settings-o'"
    [routerLink]="'/minga-manager'"
    routerLinkActive="active"
    text="Minga Manager"
    class="non-expand-navitem"
    (click)="gotoMingaManager()"
    titleText="Manage Mingas">
  </mg-nav-link>
  <mg-nav-link
    *ngIf="
      ('MINGA_PEOPLE_MANAGE' | hasPermission) ||
      ('USER_LIST_VIEW' | hasPermission)
    "
    [iconColor]="NavLinkIconColors.BLUE"
    [icon]="'person'"
    [routerLink]="'/people-manager'"
    routerLinkActive="active"
    [text]="'People Manager'"
    class="non-expand-navitem"
    (click)="restartStream('')"
    [ngClass]="{
      'ftue-highlight': (ftueKeys.addPeople | mgFtueHighlight | async)
    }"
    [titleText]="'Manage People'">
  </mg-nav-link>
  <ng-container *mgModuleEnabled="featureToggles.STUDENT_ID_ENABLED">
    <mg-nav-link
      *ngIf="
        ('MINGA_PEOPLE_MANAGE' | hasPermission) ||
        ('ADMINISTER_STUDENT_ID' | hasPermission) ||
        ('VIEW_STUDENT_ID_DASHBOARD' | hasPermission)
      "
      [iconColor]="NavLinkIconColors.PURPLE"
      [icon]="'student-id-o'"
      routerLinkActive="active"
      [text]="'ID Manager'"
      class="non-expand-navitem"
      (click)="gotoStudentIdAdminSummary()"
      [titleText]="'Manage Student IDs'">
    </mg-nav-link>
  </ng-container>
  <ng-container *mgModuleEnabled="featureToggles.HALLPASS_ENABLED">
    <mg-nav-link
      *ngIf="
        ('HALL_PASS_MANAGE' | hasPermission) ||
        ('HALL_PASS_VIEW_LIMITED_REPORTS' | hasPermission) ||
        ('HALL_PASS_VIEW_REPORTS' | hasPermission)
      "
      [iconColor]="NavLinkIconColors.PINK"
      [icon]="'hall-pass-o'"
      routerLinkActive="active"
      [text]="'Hall Pass Manager'"
      class="non-expand-navitem"
      (click)="gotoHallPasses()"
      [titleText]="'Manage Hall Passes'">
    </mg-nav-link>
  </ng-container>
  <ng-container *mgModuleEnabled="featureToggles.CHECKIN_ENABLED">
    <mg-nav-link
      *ngIf="'CHECKIN_VIEW_REPORTS' | hasPermission"
      [iconColor]="NavLinkIconColors.TEAL"
      [icon]="'tracking-o'"
      routerLinkActive="active"
      [text]="'Check In Manager'"
      class="non-expand-navitem"
      (click)="gotoCheckin()"
      [titleText]="'Manage Tracking'">
    </mg-nav-link>
  </ng-container>
  <ng-container *mgModuleEnabled="featureToggles.TRACKING_ENABLED">
    <mg-nav-link
      *ngIf="'PBIS_VIEW_REPORTS' | hasPermission"
      [iconColor]="NavLinkIconColors.GREEN"
      [icon]="'consequence-o'"
      routerLinkActive="active"
      [text]="'Behavior Manager'"
      class="non-expand-navitem"
      (click)="gotoPbis()"
      [titleText]="'Manage Behaviors'">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="canViewPointsManager()"
      [iconColor]="NavLinkIconColors.GREEN"
      [icon]="'star-o'"
      routerLinkActive="active"
      text="Points Manager"
      class="non-expand-navitem"
      (click)="gotoPointsManager()"
      [titleText]="'Manage Points'">
    </mg-nav-link>
  </ng-container>
  <ng-container *mgModuleEnabled="featureToggles.FLEX_TIME_ENABLED">
    <mg-nav-link
      *ngIf="
        ('FLEX_TIME_MANAGE' | hasPermission) ||
        ('FLEXTIME_MANAGE_OWN_ACTIVITY' | hasPermission) ||
        ('FLEX_TIME_VIEW_REPORTS' | hasPermission)
      "
      [iconColor]="NavLinkIconColors.ALT_TEAL"
      icon="FlexTime-o"
      routerLinkActive="active"
      text="FlexTime Manager"
      class="non-expand-navitem"
      (click)="gotoFlexTimeManager()"
      [titleText]="'Manage Flex Time'">
    </mg-nav-link>
  </ng-container>
  <div #navigationDrawerLinks>
    <mg-nav-link
      [icon]="'minga-files-o'"
      [routerLink]="isCommunityEnabled ? '/minga-files' : null"
      [routerLinkActive]="isCommunityEnabled ? 'active' : ''"
      [text]="'Files'"
      [disabled]="!isCommunityEnabled"
      class="non-expand-navitem"
      (click)="restartStream('')"
      [titleText]="'Minga Files'">
    </mg-nav-link>
    <mg-nav-link
      *mgModuleEnabled="featureToggles.PHOTO_GALLERY_ENABLED"
      [icon]="'photo-gallery-o'"
      [routerLink]="'/gallery'"
      routerLinkActive="active"
      [text]="'Photo Gallery'"
      class="non-expand-navitem"
      (click)="restartStream('GalleryFeed')"
      [titleText]="'Minga Photo Gallery'">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="'CONTENT_CHALLENGE_MANAGE' | hasPermission"
      [icon]="'challenges-o'"
      [routerLink]="
        isCommunityEnabled ? '/admin/challenge~polls/challenges' : null
      "
      [routerLinkActive]="isCommunityEnabled ? 'active' : ''"
      [disabled]="!isCommunityEnabled"
      [text]="'Challenges'"
      class="non-expand-navitem"
      (click)="restartStream('AdminChallenges')"
      [titleText]="'Manage Challenges'">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="'MINGA_POLLS_MANAGE' | hasPermission"
      [icon]="'polls-o'"
      [routerLink]="isCommunityEnabled ? '/admin/challenge~polls/polls' : null"
      [routerLinkActive]="isCommunityEnabled ? 'active' : ''"
      [disabled]="!isCommunityEnabled"
      [text]="'Polls'"
      class="non-expand-navitem"
      (click)="restartStream('AdminPolls')"
      [titleText]="'Manage Polls'">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="'CONTENT_REPORTS_MANAGE' | hasPermission"
      [icon]="'warning-o'"
      [routerLink]="isCommunityEnabled ? '/moderation/minga' : null"
      [routerLinkActive]="isCommunityEnabled ? 'active' : ''"
      [disabled]="!isCommunityEnabled"
      [text]="'Moderation'"
      class="non-expand-navitem"
      (click)="restartStream('MingaReportFeed')"
      [titleText]="'Manage Moderation'">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="'MINGA_PROGRAMS_MANAGE' | hasPermission"
      [icon]="'challenge'"
      [routerLink]="isCommunityEnabled ? '/admin/minga~programs' : null"
      [routerLinkActive]="isCommunityEnabled ? 'active' : ''"
      [disabled]="!isCommunityEnabled"
      [text]="'Programs'"
      class="non-expand-navitem"
      (click)="restartStream('')"
      [titleText]="'Add/Remove Programs'">
    </mg-nav-link>
    <!-- THIS FEATURE IS DEPRECATED -->
    <!-- <mg-nav-link
      *ngIf="'MINGA_BLOCKORDER_UPLOAD' | hasPermission"
      [icon]="'block-order-o'"
      [routerLink]="
        isCommunityEnabled ? ['', { outlets: { o: ['blockorder'] } }] : null
      "
      [routerLinkActive]="isCommunityEnabled ? 'active' : ''"
      [disabled]="!isCommunityEnabled"
      [text]="'Daily Bulletin'"
      class="non-expand-navitem"
      [titleText]="'Manage Minga Daily Bulletin'">
    </mg-nav-link> -->
    <ng-container *mgModuleEnabled="featureToggles.TRACKING_ENABLED">
      <mg-nav-link
        *ngIf="isLeaderboardEnabled$ | async"
        [icon]="'star-o'"
        [routerLink]="
          ('STUDENT_TOOLS' | hasPermission) || ('TEACHER_TOOLS' | hasPermission)
            ? ['/tools/points/leaderboard']
            : '/points/leaderboard'
        "
        routerLinkActive="active"
        [text]="'Leaderboard'"
        class="non-expand-navitem"
        (click)="restartStream('')"
        [titleText]="'Leaderboard'">
      </mg-nav-link>
    </ng-container>
    <mg-nav-link
      [icon]="'settings-o'"
      [routerLink]="'/user-preferences'"
      routerLinkActive="active"
      [text]="'Preferences'"
      class="non-expand-navitem"
      (click)="restartStream('')"
      [titleText]="'Manage User Preferences'">
    </mg-nav-link>

    <div class="section-divider"></div>

    <mg-nav-link
      class="non-expand-navitem"
      routerLinkActive="active"
      [icon]="'aboutminga-o'"
      [routerLink]="['', { outlets: { o: ['aboutus'] } }]"
      [text]="'About Us'"
      [titleText]="'About Us'"
      (click)="restartStream('')">
    </mg-nav-link>
    <mg-nav-link
      class="non-expand-navitem"
      routerLinkActive="active"
      [icon]="'phone-o'"
      [routerLink]="['', { outlets: { o: ['suicidepreventionhotline'] } }]"
      [text]="'Suicide Prevention Hotline'"
      [titleText]="'Suicide Prevention Hotline'"
      (click)="restartStream('')">
    </mg-nav-link>
    <mg-nav-link
      *ngIf="'TEACHER_TOOLS' | hasPermission"
      [icon]="'comment-o'"
      [routerLink]="['', { outlets: { o: ['dialog', 'feedback'] } }]"
      routerLinkActive="active"
      [text]="'Feedback'"
      class="non-expand-navitem"
      (click)="restartStream('')"
      [titleText]="'Send Feedback to Minga'">
    </mg-nav-link>
    <mg-nav-link
      [icon]="'mg-help-center'"
      [text]="'Help'"
      class="non-expand-navitem"
      (click)="gotoHelpDocs()"
      [titleText]="'Get Help'"></mg-nav-link>
    <mg-nav-link
      [icon]="'exit-app'"
      [text]="'Logout'"
      class="non-expand-navitem"
      (click)="logout()"
      [titleText]="'Logout'">
    </mg-nav-link>
  </div>
</div>
