import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { MgIconModule } from 'minga/app/src/app/icon';

import {
  SideNavTabsComponent,
  SideNavTabsHeaderComponent,
} from './SideNavTabs.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,

    // External dependencies
    HammerModule,
    CommonModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatSidenavModule,
    MatRippleModule,
    RouterModule,
  ],
  declarations: [SideNavTabsComponent, SideNavTabsHeaderComponent],
  exports: [SideNavTabsComponent, SideNavTabsHeaderComponent],
})
export class SideNavTabsModule {}
