import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReportFormComponent } from './ReportForm.component';
import { ReportFormModule } from './ReportForm.module';

export const routes: Routes = [
  {
    path: ':hash/:photoUuid',
    component: ReportFormComponent,
  },
];

@NgModule({
  imports: [
    // Minga dependencies
    ReportFormModule,

    // External dependencies
    RouterModule.forChild(routes),
  ],
})
export class ReportFormOverlayRoutesModule {}
