<ng-container *ngIf="step == 0">
  <div class="header">
    <p class="title mg-headline3">Welcome to {{ mingaName }}</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Your Home Feed is the easiest way to see everything happening at
      {{ mingaName }}.
    </p>
  </div>
  <mg-ftue-video videoName="Welcome-FTUE-StudentWelcome"></mg-ftue-video>
</ng-container>

<ng-container *ngIf="step == 1">
  <div class="header">
    <p class="title mg-headline3">Never Miss Out Again</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      An up-to-date calendar to find out what’s happening at school. Anytime.
      Anywhere.
    </p>
  </div>
  <mg-ftue-video videoName="Welcome-FTUE-NeverMissout"></mg-ftue-video>
</ng-container>

<ng-container *ngIf="step == 2 && _enableGroups">
  <div class="header">
    <p class="title mg-headline3">All Your Groups</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Join your clubs, teams and classes in Minga and stay on top of everything
      in one place.
    </p>
  </div>
  <mg-ftue-video videoName="Welcome-FTUE-AllYourGroups"></mg-ftue-video>
</ng-container>

<ng-container *ngIf="step == stepCount - 1">
  <div class="header">
    <p class="title mg-headline3">Let’s Get Started</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Update your Profile, check out your Home Feed and browse the Calendar.
    </p>
  </div>
  <mg-ftue-video videoName="Welcome-FTUE-LetsGetStartedStudent"></mg-ftue-video>
</ng-container>

<div class="footer">
  <button
    mgBubbleBtn
    class="mg-headline"
    (click)="next()">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="stepCount - 1">Get Started!</ng-container>
      <ng-container *ngSwitchDefault>Next</ng-container>
    </ng-container>
  </button>

  <div class="mg-centered">
    <mg-step-indicator
      [allowStepClickNavigation]="true"
      [(activeStep)]="step"
      [stepCount]="stepCount"></mg-step-indicator>
  </div>
</div>
