<ng-template #matDialogTemplate>
  <mat-dialog-content>
    <mg-button-icon
      [mat-dialog-close]="null"
      class="close-btn-icon mg-no-padding"
      icon="close"></mg-button-icon>
    <h1 class="mg-headline3 dialog-title">
      <ng-content select="mg-dialog-title"></ng-content>
    </h1>
    <div class="dialog-content">
      <ng-content select="mg-dialog-content"></ng-content>
    </div>
    <div
      class="dialog-actions"
      fxLayout="row"
      fxLayoutGap="16px">
      <div fxFlex="auto"></div>
      <ng-content select="[mg-dialog-action]"></ng-content>
    </div>
  </mat-dialog-content>
</ng-template>
