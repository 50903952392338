import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mg-manager-report-chart-section',
  templateUrl: './manager-report-chart-section.component.html',
  styleUrls: ['./manager-report-chart-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagerReportChartSectionComponent {
  @Input() isLoading: boolean;

  constructor() {}
}
