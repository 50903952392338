import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

import { MgFormFieldModule } from '../form-field/MgFormField.module';
import { MgIconModule } from '../icon/MgIcon.module';

import { CustomDatePickerHeader } from './CustomDatePickerHeader.component';
import { DateTimePickerComponent } from './DateTimePicker.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgFormFieldModule,
    MgIconModule,

    // External dependencies
    FormsModule,
    CommonModule,
    MatCommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
  declarations: [DateTimePickerComponent, CustomDatePickerHeader],
  exports: [DateTimePickerComponent, CustomDatePickerHeader],
})
export class MgDateTimePickerModule {}
