<ng-container
  *ngIf="(mmFeatureService.isLoading$ | async) === false; else loadingTpl">
  <div *ngIf="mmFeatureService.featureStates$ | async as featureToggles">
    <!-- Expansion: Digital ID -->
    <div class="module digital-id">
      {{ MESSAGES.DIGITAL_ID }}
      <mg-btn
        size="small"
        [variant]="!featureToggles?.studentIdEnabled ? 'outlined' : 'filled'"
        [disabled]="!isSuperAdmin"
        (pressed)="
          mmFeatureService.onStudentIdsExpansionPackChange(
            !featureToggles?.studentIdEnabled
          )
        ">
        {{ featureToggles.studentIdEnabled ? 'Enabled' : 'Disabled' }}
      </mg-btn>
    </div>

    <!-- Expansion: Community & Communication -->
    <div class="module community">
      {{ MESSAGES.COMMUNITY }}
      <mg-btn
        size="small"
        [variant]="!featureToggles?.communityEnabled ? 'outlined' : 'filled'"
        [disabled]="!isSuperAdmin"
        (pressed)="
          mmFeatureService.onCommunityModuleChange(
            !featureToggles?.communityEnabled
          )
        ">
        {{ featureToggles.communityEnabled ? 'Enabled' : 'Disabled' }}
      </mg-btn>
    </div>

    <!-- Expansion: Hall Passes -->
    <div class="module hall-pass">
      {{ MESSAGES.HALL_PASS }}
      <mg-btn
        size="small"
        [variant]="!featureToggles?.hallPassEnabled ? 'outlined' : 'filled'"
        [disabled]="!isSuperAdmin"
        (pressed)="
          mmFeatureService.onHallPassExpansionPackChange(
            !featureToggles?.hallPassEnabled
          )
        ">
        {{ featureToggles.hallPassEnabled ? 'Enabled' : 'Disabled' }}
      </mg-btn>
    </div>

    <!-- Expansion: PBIS -->
    <div class="module pbis">
      {{ MESSAGES.BEHAVIOR }}
      <mg-btn
        size="small"
        [variant]="!featureToggles?.trackingEnabled ? 'outlined' : 'filled'"
        [disabled]="!isSuperAdmin"
        (pressed)="
          mmFeatureService.onTrackingExpansionPackChange(
            !featureToggles?.trackingEnabled
          )
        ">
        {{ featureToggles.trackingEnabled ? 'Enabled' : 'Disabled' }}
      </mg-btn>
    </div>

    <!-- Expansion: Check In -->
    <div class="module checkin">
      {{ MESSAGES.CHECK_IN }}
      <mg-btn
        size="small"
        [variant]="!featureToggles?.checkinEnabled ? 'outlined' : 'filled'"
        [disabled]="!isSuperAdmin"
        (pressed)="
          mmFeatureService.onCheckInExpansionPackChange(
            !featureToggles?.checkinEnabled
          )
        ">
        {{ featureToggles.checkinEnabled ? 'Enabled' : 'Disabled' }}
      </mg-btn>
    </div>

    <!-- Expansion: FlexTime -->
    <div class="module flex-time">
      {{ MESSAGES.FLEX_TIME }}
      <mg-btn
        size="small"
        [variant]="!featureToggles?.flexTimeEnabled ? 'outlined' : 'filled'"
        [disabled]="!isSuperAdmin"
        (pressed)="
          mmFeatureService.onFlexTimeExpansionPackChange(
            !featureToggles?.flexTimeEnabled
          )
        ">
        {{ featureToggles.flexTimeEnabled ? 'Enabled' : 'Disabled' }}
      </mg-btn>
    </div>
  </div>
</ng-container>

<ng-template #loadingTpl>
  <mg-spinner [ngStyle.gt-sm]="{ 'margin-top.rem': 10 }"></mg-spinner>
</ng-template>
