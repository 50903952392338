import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import * as day from 'dayjs';
import { BehaviorSubject } from 'rxjs';

import {
  IConsequence,
  OverdueConsequenceActionType,
} from 'minga/domain/consequences';

import { StMessages } from '../../constants';
import { NormalizedBehavior } from '../../types';

@Component({
  selector: 'mg-st-behaviour-item',
  templateUrl: './st-behaviour-item.component.html',
  styleUrls: ['./st-behaviour-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StBehaviourItemComponent implements OnInit {
  private _isOverdue = new BehaviorSubject(false);
  public isOverdue$ = this._isOverdue.asObservable();
  public readonly MESSAGES = StMessages;
  public readonly OVERDUE_ACTION = OverdueConsequenceActionType;

  @Input() behaviour: NormalizedBehavior;

  @Input() isActive: boolean;

  private _now = day();

  get wrapperClasses() {
    const classes = [
      this.behaviour.isConsequence ? 'consequence' : 'behaviour',
    ];

    classes.push(
      this.behaviour?.type?.categoryId === 0 ? 'praise' : 'guidance',
    );

    const isOverdue = this._overdue();

    if (isOverdue) {
      classes.push('overdue');
    }
    if (!isOverdue && this.isActive) {
      classes.push('active');
    }

    return classes;
  }

  constructor() {}

  ngOnInit(): void {
    this._isOverdue.next(this._overdue());
  }

  get consAssignedBy(): string {
    if (this.behaviour?.sourceId) return 'ID ' + this.behaviour?.sourceId;
    else if (this.behaviour?.automationName)
      return this.behaviour?.automationName;
    else if (this.behaviour?.assignedBy)
      return `${this.behaviour?.assignedBy?.firstName} 
    ${this.behaviour?.assignedBy?.lastName}`;
    return '';
  }

  public deriveIcon(behaviour: NormalizedBehavior) {
    if (behaviour?.isConsequence) {
      return behaviour?.type?.categoryId === 0
        ? 'consequence-praise'
        : 'consequence-guidance';
    } else {
      return behaviour?.type.categoryId === 0 ? 'praise' : 'guidance';
    }
  }

  private _overdue(): boolean {
    return (
      this.behaviour?.isConsequence &&
      !(this.behaviour as IConsequence)?.complete &&
      (this.behaviour as IConsequence)?.completeBy &&
      this._now.isAfter(day((this.behaviour as IConsequence)?.completeBy))
    );
  }
}
