<mg-tools-header
  *ngIf="layout.useLegacyLayout$ | async"
  type="teacher"
  [user]="person$ | async">
  <mg-tools-nav>
    <mg-tools-nav-item
      [routerLinkActive]="'active'"
      [routerLink]="['/' + TOOLS_ROUTES.ROOT, TOOLS_ROUTES.MY_TOOLS]"
      [label]="MESSAGES.NAV_MY_TOOLS"
      icon="teacher-tools-active"
      type="my-tools"></mg-tools-nav-item>
    <mg-tools-nav-item
      *ngIf="(isPbisEnabled$ | async) && (isLeaderboardEnabled$ | async)"
      [ngClass]="{
        active: router.url.startsWith(
          '/' + TOOLS_ROUTES.ROOT + '/' + TOOLS_ROUTES.POINTS
        )
      }"
      [routerLink]="[
        '/' + TOOLS_ROUTES.ROOT,
        TOOLS_ROUTES.POINTS,
        TOOLS_ROUTES.LEADERBOARD
      ]"
      [label]="MESSAGES.NAV_POINTS"
      icon="star-o"
      type="points"></mg-tools-nav-item>
    <mg-tools-nav-item
      [routerLinkActive]="'active'"
      [routerLink]="['/' + TOOLS_ROUTES.ROOT, TOOLS_ROUTES.NOTIFICATIONS]"
      [label]="MESSAGES.NAV_NOTIFICATIONS"
      icon="notification-active"
      type="notifications"
      [showBadge]="
        badgeManager.onDot('notification') | async
      "></mg-tools-nav-item>
  </mg-tools-nav>
</mg-tools-header>

<section class="content-wrapper">
  <router-outlet></router-outlet>
</section>
