import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';

import { CurrentCountryModule } from 'minga/app/src/app/components/CurrentCountry';
import { LogoModule } from 'minga/app/src/app/components/Logo';
import { NaviconModule } from 'minga/app/src/app/components/Navicon';
import { MgDirectivesModule } from 'minga/app/src/app/directives';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgPersonMinimalModule } from 'minga/app/src/app/elements/MgPersonMinimal';
import { FtueHighlightPipeModule } from 'minga/app/src/app/ftue/Highlight/pipes/FtueHighlight';
import { MgIconModule } from 'minga/app/src/app/icon';
import { NavLinkModule } from 'minga/app/src/app/navigation/components/NavLink';
import { NavigationDrawerSubNavModule } from 'minga/app/src/app/navigation/components/NavigationDrawerSubNav';
import { PermissionsModule } from 'minga/app/src/app/permissions';

import { FormModule } from '@shared/components/form';

import { NavigationDrawerComponent } from './NavigationDrawer.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgAvatarModule,
    MgPersonMinimalModule,
    LogoModule,
    MgIconModule,
    NaviconModule,
    CurrentCountryModule,
    FtueHighlightPipeModule,
    NavLinkModule,
    NavigationDrawerSubNavModule,
    PermissionsModule,
    MgDirectivesModule,
    FormModule,

    // External dependencies
    CommonModule,
    RouterModule,
    MatExpansionModule,
    MatListModule,
  ],
  declarations: [NavigationDrawerComponent],
  exports: [NavigationDrawerComponent],
})
export class NavigationDrawerModule {}
