export interface IBox {
  width: number;
  height: number;
}

export function fitIn(box1: IBox, box2: IBox) {
  const result: IBox = {
    height: box1.height * (box2.width / box1.width),
    width: box1.width * (box2.width / box1.width),
  };

  if (result.height > box2.height) {
    const resultHeight = result.height;
    result.height = resultHeight * (box2.height / resultHeight);
    result.width = result.width * (box2.height / resultHeight);
  }

  return result;
}
