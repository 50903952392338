import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgFileExtensionBoxElement } from './MgFileExtensionBox.element';

@NgModule({
  imports: [CommonModule],
  declarations: [MgFileExtensionBoxElement],
  exports: [MgFileExtensionBoxElement],
})
export class MgFileExtensionBoxModule {
  constructor() {}
}
