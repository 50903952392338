import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { FtueService } from 'minga/app/src/app/ftue/Ftue.service';
import { DistrictSchoolSelectDialog } from 'minga/app/src/app/ftue/Welcome/containers/DistrictSchoolSelectDialog/DistrictSchoolSelectDialog.component';
import { WelcomeDialog } from 'minga/app/src/app/ftue/Welcome/containers/WelcomeDialog/WelcomeDialog.component';
import { WhatsNewDialog } from 'minga/app/src/app/ftue/Welcome/containers/WhatsNewDialog';
import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';
import { AuthInfoService } from 'minga/app/src/app/minimal/services/AuthInfo';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { PermissionsService } from 'minga/app/src/app/permissions';
import {
  MingaStoreFacadeService,
  MingaSettingsService,
} from 'minga/app/src/app/store/Minga/services';
import { obsValueToPromise } from 'minga/app/src/app/util/observable';
import { FtueKey } from 'minga/domain/ftue';
import { MingaPermission, MingaTypeValue, mingaSettingTypes } from 'minga/util';

// the role types that have dialog content components to show
export const WELCOME_FTUE_ROLE_TYPES: string[] = [
  // @NOTE: superadmin here for testing purposes
  'SUPERADMIN',
  'OWNER',
  'MANAGER',
  'TEACHER',
  'STAFF',
  'STUDENT_LEADER',
  'STUDENT',
  'MEMBER',
  'PARENT',
];

/**
 * Service to handling displaying welcome FTUE messages.
 */
@Injectable({ providedIn: 'root' })
export class WelcomeService {
  private _dialog?: MatDialogRef<
    WelcomeDialog | DistrictSchoolSelectDialog | WhatsNewDialog
  >;
  private _hasSeenStudentId: boolean = false;

  constructor(
    private dialog: MatDialog,
    private permissions: PermissionsService,
    private mingaStore: MingaStoreFacadeService,
    private overlay: Overlay,
    private ftueService: FtueService,
    private contentEvents: ContentEvents,
    private auth: AuthInfoService,
    private appConfig: AppConfigService,
    private router: Router,
    private settingService: MingaSettingsService,
  ) {}

  /**
   * Display the new user welcome screen. Don't call this function directly,
   * it should be triggered in WelcomeEffects to make sure proper checks are
   * done.
   */
  async initNewUser() {
    // no welcome screens for id only mingas.
    const communityEnabled = await obsValueToPromise(
      this.settingService.isCommunityModuleEnabled(),
    );
    if (!communityEnabled) {
      return;
    }
    const role = await this.permissions.mingaRoleType$
      .pipe(take(1))
      .toPromise();

    const hasApplicableRole = WELCOME_FTUE_ROLE_TYPES.includes(role);

    // check if minga type for showing welcome dialog
    const minga = await this.mingaStore.getMingaAsPromise();
    const mingaName = minga ? minga.name : '';
    const applicableMingaType = minga.mingaType != MingaTypeValue.CONFERENCE;

    const groupEnabled = await this.settingService.getSettingValue(
      mingaSettingTypes.FEATURE_ENABLE_GROUPS,
    );
    // check if should show group ftue
    const enableGroups =
      this.permissions.hasPermission(MingaPermission.CONTENT_GROUP_ENABLED) &&
      groupEnabled;

    // @TODO: remove 'false' when welcome screens are needed again
    if (hasApplicableRole && applicableMingaType && false) {
      await this.showWelcomeDialog(mingaName, enableGroups);
      // they've seen it, set it so they don't see it again
      await this.ftueService.markAsSeen(FtueKey.WELCOME_FTUE);
      await this.ftueService.fireNextScreen();
      delete this._dialog;
    }
  }

  /**
   * Display the welcome screen for District enabled users.
   * DO NOT CALL THIS DIRECTLY. WelcomeEffects checks whether the
   * feature is enabled or not so if you want to display this, fire the action.
   */
  async initDistrictUser() {
    // check if minga type for showing welcome dialog
    const minga = await this.mingaStore.getMingaAsPromise();
    // Check that it is a school (MINGA is school)
    const applicableMingaType = minga.mingaType == MingaTypeValue.MINGA;
    if (applicableMingaType) {
      await this.showDistrictSchoolSelectDialog();
      // they've seen it, set it so they don't see it again
      await this.ftueService.markAsSeen(FtueKey.DISTRICT_FTUE);
      await this.ftueService.fireNextScreen();
      this.contentEvents.emitContentFeedsUpdate();
      delete this._dialog;
    }
  }

  /**
   * Display the whats new screens to the user. Don't call this function
   * directly, it should be triggered in WelcomeEffects to make sure proper
   * checks are done.
   */
  async initWhatsNew() {
    // no welcome screens for id only mingas.
    const communityEnabled = await obsValueToPromise(
      this.settingService.isCommunityModuleEnabled(),
    );
    if (!communityEnabled) {
      console.log('not enabled!');
      return;
    }

    // Use role to specify who gets to see the welcome screens
    // const role =
    //   await this.permissions.mingaRoleType$.pipe(take(1)).toPromise();

    await this.showWhatsNewDialog();
    // they've seen it, set it so they don't see it again
    await this.ftueService.markAsSeen(FtueKey.WHATS_NEW);
    await this.ftueService.fireNextScreen();
    delete this._dialog;
  }

  showWelcomeDialog(
    mingaName: string = '',
    enableGroups: boolean = false,
  ): Promise<void> {
    this._dialog = this.dialog.open(WelcomeDialog, {
      data: { mingaName, enableGroups },
      panelClass: 'mg-dialog-large',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      // disable clicking backdrop/scrim to close dialog
      disableClose: true,
    });

    return this._dialog.afterClosed().toPromise();
  }

  showDistrictSchoolSelectDialog(): Promise<void> {
    this._dialog = this.dialog.open(DistrictSchoolSelectDialog, {
      panelClass: 'mg-dialog-large',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      // disable clicking backdrop/scrim to close dialog
      disableClose: true,
    });

    return this._dialog.afterClosed().toPromise();
  }

  showWhatsNewDialog(): Promise<void> {
    this._dialog = this.dialog.open(WhatsNewDialog, {
      panelClass: 'mg-dialog-large',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      // disable clicking backdrop/scrim to close dialog
      disableClose: true,
    });

    return this._dialog.afterClosed().toPromise();
  }

  async showStudentId(): Promise<void> {
    // only show once each time app is opened
    if (this._hasSeenStudentId) return;
    const studentIdEnabled = await obsValueToPromise(
      this.settingService.isIdModuleEnabled(),
    );
    const communityEnabled = await obsValueToPromise(
      this.settingService.isCommunityModuleEnabled(),
    );
    const isStudent =
      this.permissions.hasRoleType('STUDENT') ||
      this.permissions.hasRoleType('STUDENT_LEADER');
    // only show if digital ID only minga, and student/student leader role
    if (studentIdEnabled && !communityEnabled && isStudent) {
      await this.router.navigate(['', { outlets: { o: ['student-id'] } }]);
      this._hasSeenStudentId = true;
    }

    // no marking as seen, as this action should happen everytime for students
  }
}
