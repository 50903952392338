import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BannerHeadlineModule } from 'minga/app/src/app/components/BannerHeadline';
import { ClippedHtmlModule } from 'minga/app/src/app/components/ClippedHtml';
import { FeedCommentViewModule } from 'minga/app/src/app/components/FeedCommentView';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgLikeModule } from 'minga/app/src/app/elements/MgLike';
import { MgPersonMinimalModule } from 'minga/app/src/app/elements/MgPersonMinimal';
import { MgeIconModule } from 'minga/app/src/app/elements/MgeIcon';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { CommonUIModules } from '@shared/modules';

import {
  MgShortCardElement,
  MgShortCardSlot_ActionBar,
  MgShortCardSlot_Body,
  MgShortCardSlot_Byline,
  MgShortCardSlot_CommentList,
  MgShortCardSlot_FeaturedImage,
  MgShortCardSlot_GroupName,
  MgShortCardSlot_Title,
} from './MgShortCard.element';

@NgModule({
  imports: [
    // Common modules
    CommonUIModules,

    // Minga dependencies
    MgeIconModule,
    MgAvatarModule,
    MgLikeModule,
    MgEllipsisModule,
    MgPersonMinimalModule,
    MgPipesModule,
    MgImageModule,
    MgContentLinkModule,
    BannerHeadlineModule,
    FeedCommentViewModule,
    ClippedHtmlModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [
    MgShortCardElement,
    MgShortCardSlot_ActionBar,
    MgShortCardSlot_Body,
    MgShortCardSlot_Byline,
    MgShortCardSlot_CommentList,
    MgShortCardSlot_FeaturedImage,
    MgShortCardSlot_GroupName,
    MgShortCardSlot_Title,
  ],
  exports: [
    MgShortCardElement,
    MgShortCardSlot_ActionBar,
    MgShortCardSlot_Body,
    MgShortCardSlot_Byline,
    MgShortCardSlot_CommentList,
    MgShortCardSlot_FeaturedImage,
    MgShortCardSlot_GroupName,
    MgShortCardSlot_Title,
  ],
})
export class MgShortCardModule {}
