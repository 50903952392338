import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Group } from '../../models/Group';

@Component({
  selector: 'mg-group-join-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './GroupJoinButton.component.html',
  styleUrls: ['./GroupJoinButton.component.scss'],
})
export class GroupJoinButtonComponent {
  @Output()
  requestToJoin = new EventEmitter<Group>();

  @Output()
  cancelRequestToJoin = new EventEmitter<Group>();

  @Output()
  leaveGroup = new EventEmitter<Group>();

  @Input()
  group?: Group;

  @Input()
  buttonClass: string = '';

  onleaveGroup(group: Group) {
    this.leaveGroup.emit(group);
  }

  noClick(event: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }
}
