<div
  class="minga-colors"
  fxLayout="row">
  <div
    fxFlex="25%"
    class="minga-color minga-color1"></div>
  <div
    fxFlex="25%"
    class="minga-color minga-color2"></div>
  <div
    fxFlex="25%"
    class="minga-color minga-color3"></div>
  <div
    fxFlex="25%"
    class="minga-color minga-color4"></div>
</div>

<!-- Search Overlay -->
<mg-search-overlay
  [searchOverlayActive]="searchOverlayOutlet.isActivated && !overlayEnabled"
  [(navSearchValue)]="navSearchValue">
  <router-outlet
    name="search"
    #searchOverlayOutlet="outlet"
    (activate)="onSearchOverlayActivate($event)"
    (deactivate)="onSearchOverlayDeactivate($event)">
  </router-outlet>
</mg-search-overlay>

<mat-menu
  #globalMatMenu="matMenu"
  class="global-menu mg-cancel-content-link">
  <ng-container *ngFor="let menuItem of globalMenuItems">
    <div
      mat-menu-item
      (click)="menuItem.click($event)">
      {{ menuItem.name }}
    </div>
  </ng-container>
</mat-menu>

<div
  #globalMenu
  class="global-trigger mg-cancel-content-link"
  [class.fixed]="overlayEnabled">
  <div
    #globalMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="globalMatMenu"></div>
</div>

<ng-template #successOverlayTemplate>
  <div class="success-overlay">
    <div class="checkmark-wrap">
      <mg-success-checkmark></mg-success-checkmark>
      <div class="mg-bodycopy">Success!</div>
    </div>
  </div>
</ng-template>

<ng-template #modFailedOverlayTemplate>
  <div class="mod-failed-overlap">
    <div class="mod-failed-body user-content mg-centered">
      <p><mg-icon>warning-o</mg-icon></p>
      <h2 class="mg-headline2">
        {{ 'moderation.failed.headline.' + modFailHeadline | translate }}
      </h2>
      <div *ngFor="let item of getModFailItemsAsArray(); let i = index">
        <p
          class="mg-bodycopy"
          *ngIf="item.hasIndex">
          <span
            [innerHTML]="
              'moderation.failed.' + item.bodyLocale
                | translate: { value: item.index }
            "></span>
        </p>
        <p
          *ngIf="!item.hasIndex"
          class="mg-bodycopy"
          [innerHTML]="
            'moderation.failed.' + item.bodyLocale | translate: item.categories
          "></p>

        <p
          class="mg-bodycopy"
          *ngIf="item.bodySecondLocale && item.hasIndexSecond">
          <span
            [innerHTML]="
              'moderation.failed.' + item.bodySecondLocale
                | translate: { value: item.index }
            "></span>
        </p>
        <p
          class="mg-bodycopy"
          *ngIf="item.bodySecondLocale && !item.hasIndexSecond"
          [innerHTML]="
            'moderation.failed.' + item.bodySecondLocale
              | translate: item.categoriesSecond
          "></p>
      </div>
      <p
        class="mg-bodycopy"
        [innerHTML]="'moderation.failed.aiDescGeneral' | translate">
        &nbsp;
      </p>
      <p
        *ngIf="shouldAllowPostAnyways$ | async"
        [innerHTML]="'moderation.failed.aiCanPostAnyways' | translate"></p>
      <p
        *ngIf="(shouldAllowPostAnyways$ | async) === false"
        [innerHTML]="'moderation.failed.aiNoPost' | translate"></p>
      <p class="mg-centered">
        <mg-button (click)="closeActiveModal()">{{
          'moderation.failed.button.ok' | translate
        }}</mg-button>
      </p>
      <ng-container *ngIf="modFailContextHash || modFailGalleryPhotoUuid">
        <p
          class="mg-centered"
          *ngIf="shouldAllowPostAnyways$ | async">
          <mg-button
            [disabled]="isLoading"
            color="warn"
            type="outline"
            (click)="overrideModFail()"
            >{{ 'moderation.failed.button.override' | translate }}</mg-button
          >
        </p>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- @TODO: Look at if we need these still -->
<!-- [class.overlay-opened]="overlayOutlet.isActivated" -->
<!-- [class.mg-stream-skip-threshold]="overlayOutlet.isActivated" -->
<mg-sidenav
  [mqAlias]="mqAlias"
  [(open)]="openSideNav"
  [disabled]="false">
  <mg-top-nav class="placeholder"></mg-top-nav>
  <mg-top-nav [class.anim-hidden]="topbarHidden">
    <mg-top-nav-left [routerLink]="'/minga-profile'">
      <mg-logo ngClass.gt-sm="large"></mg-logo>
    </mg-top-nav-left>
    <mg-top-nav-mid>
      <mg-navsearch
        [ngModel]="search.searchQuery$ | async"
        (ngModelChange)="onNavSearchChange($event)"
        [placeholder]="search.searchPlaceholder$ | async"
        (touchstart)="clickSearch($event)"
        (mousedown)="clickSearch($event)"></mg-navsearch>
    </mg-top-nav-mid>
    <mg-top-nav-right class="avatar-nav">
      <mg-person-avatar
        title="View User Profile"
        fxHide
        fxShow.gt-sm></mg-person-avatar>
      <mg-navicon
        [class.ftue-highlight]="hamburgerFtueKeys | mgFtueHighlight | async"
        icon="hamburger"
        hover="background"
        (click)="openSideNav = !openSideNav"
        class="mg-navicon-container-centered"
        fxHide.gt-sm></mg-navicon>
    </mg-top-nav-right>
    <mg-top-nav-right>
      <div
        *ngIf="badgeManager.onDot('notification') | async"
        title="View Unread Notifications"
        class="nav-item-badge topright"></div>
      <mg-navicon
        icon="notification"
        hover="background"
        [routerLink]="
          ('STUDENT_TOOLS' | hasPermission) || ('TEACHER_TOOLS' | hasPermission)
            ? ['/tools/notifications']
            : '/notifications'
        "
        class="mg-navicon-container-centered"></mg-navicon>
    </mg-top-nav-right>
    <mg-top-nav-right *ngIf="shouldShowDirectMessagesIcon$ | async">
      <div
        *ngIf="hasUnreadMessages$ | async"
        title="View Direct Messages"
        class="nav-item-badge topright"></div>
      <mg-navicon
        icon="chatty-chat"
        hover="background"
        (click)="restartStream('ConversationPreviewFeed')"
        [routerLink]="'/messaging'"
        class="mg-navicon-container-centered"></mg-navicon>
    </mg-top-nav-right>
  </mg-top-nav>

  <div
    #mainWrapEl
    class="main-wrap"
    [class.with-sidebar]="enableSideNav"
    [ngClass.gt-sm]="'has-side-toolbar'">
    <div
      class="primary-outlet-with-bottom-nav"
      fxLayout.xs="column"
      fxLayout.sm="column"
      [class.with-sidebar]="enableSideNav"
      fxLayout.gt-sm="row-reverse">
      <div
        class="primary-outlet-container"
        [class.with-sidebar]="enableSideNav"
        [style.transform]="pullDownTransform(pullDownAmount)">
        <router-outlet></router-outlet>
      </div>

      <mg-navbar
        fxHide.gt-sm
        class="placeholder bottom-toolbar"></mg-navbar>
      <div
        [ngClass.gt-sm]="'side-toolbar'"
        [ngClass.lt-md]="'bottom-toolbar'"
        [class.hide-bottom]="openSideNav">
        <mg-navbar
          color="primary"
          [padSafeArea]="mqAlias == 'xs' || mqAlias == 'sm' ? 'bottom' : ''"
          [direction]="mqAlias == 'xs' || mqAlias == 'sm' ? 'row' : 'column'">
          <ng-container>
            <mg-content-nav [ngClass.lt-md]="'bottom-toolbar'"></mg-content-nav>
          </ng-container>
        </mg-navbar>
      </div>
    </div>
  </div>
</mg-sidenav>

<ng-content></ng-content>
