<mg-layout-legacy-content>
  <mg-stream-scroller
    [filter]="filter"
    [service]="profileContent"
    mgStreamControl="profileContent"
    rpcStream="search"
    rpcControl="searchControl">
    <ng-template
      let-item
      let-itemId="itemId"
      mgStreamItem>
      <mg-date-separator
        [text]="item.separatorText"
        streamName="profileContent">
      </mg-date-separator>
      <mg-content-feed-item
        display="miniCard"
        [context]="itemId"
        [content]="item.feedItemView"></mg-content-feed-item>
    </ng-template>
    <mg-empty-state
      mg-stream-empty
      [title]="emptyFeedString"></mg-empty-state>
  </mg-stream-scroller>

  <ng-template #action>
    <mg-home-action-button></mg-home-action-button>
  </ng-template>
</mg-layout-legacy-content>
