import { IMingaFeatureToggleKeys } from 'minga/domain/featureToggle';

import { ModuleInfo } from '../types';

export const moduleInfoConfig: ModuleInfo[] = [
  {
    title: 'Base',
    icon: 'mg-id-menu',
    colorVar: '--mg-color-id-manager',
    description: 'Digital IDs, Community & SMS',
    features: ['ID Module', 'Community Module', 'SMS'],
    learnMoreLink: 'https://minga.io/digital-student-id/',
    moduleToggleKey: IMingaFeatureToggleKeys.STUDENT_ID_ENABLED,
  },
  {
    title: 'Hall Pass',
    icon: 'mg-hallpass-menu',
    colorVar: '--mg-color-hall-pass',
    description: 'Digital hall pass: flexible, effective',
    features: [
      'Simple hall pass workflow',
      'Manage hallway access',
      'View hallway activity',
    ],
    learnMoreLink: 'https://minga.io/digital-hall-pass/',
    moduleToggleKey: IMingaFeatureToggleKeys.HALLPASS_ENABLED,
  },
  {
    title: 'Behavior & Rewards',
    icon: 'mg-behavior-menu',
    colorVar: '--mg-color-behavior-praise',
    description: 'Monitor tardies and behaviors',
    features: [
      'Address unique behaviors',
      'Streamline tardies',
      'Reduce administrative burden',
    ],
    learnMoreLink: 'https://minga.io/pbis-rewards-tracking-student-behavior/',
    moduleToggleKey: IMingaFeatureToggleKeys.TRACKING_ENABLED,
  },
  {
    title: 'Check In',
    icon: 'mg-checkin-menu',
    colorVar: '--mg-color-check-in',
    description: 'Simplify attendance reporting',
    features: [
      `Know every student's location`,
      'Flexibly monitor activities',
      'Increase campus safety',
    ],
    learnMoreLink: 'https://minga.io/school-check-in/',
    moduleToggleKey: IMingaFeatureToggleKeys.CHECKIN_ENABLED,
  },
  {
    title: 'FlexTime',
    icon: 'mg-flextime-menu',
    colorVar: '--mg-color-behavior-praise',
    description: 'Customize flex periods with ease',
    features: [
      'No more manual scheduling',
      'Assign activities to students',
      'Automation scheduling',
    ],
    learnMoreLink: 'https://minga.io/flextime/',
    moduleToggleKey: IMingaFeatureToggleKeys.FLEX_TIME_ENABLED,
  },
];
