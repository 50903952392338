import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { getGlobalSearchRoutes } from '@modules/global-search';

import {
  AccessRestrictionsGuard,
  AuthGuard,
  CanAccessGalleryGuard,
  CountryGuard,
  DeviceGuard,
  FeatureFlagGuard,
  HasUserGuard,
  PermissionsGuard,
  RoleGuard,
  SettingGuard,
  SuperadminGuard,
} from '@shared/guards';

import {
  APP_ROUTES,
  ERROR_ROUTES,
  MANAGER_ROUTES,
  MODAL_ROUTES,
  OVERLAY_ROUTES,
  REDIRECT_ROUTES,
} from './constants/routes';
import { PreventNavigationGuard } from './guards';
import { PermissionsModule } from './permissions';

const MINGA_APP_ROOT_ROUTING: Routes = [
  ...REDIRECT_ROUTES,
  ...APP_ROUTES,
  ...MANAGER_ROUTES,
  ...MODAL_ROUTES,
  ...OVERLAY_ROUTES,
  ...ERROR_ROUTES,
];

MINGA_APP_ROOT_ROUTING.push(...getGlobalSearchRoutes());

@NgModule({
  imports: [
    // Minga dependencies
    PermissionsModule,

    // External dependencies
    RouterModule.forRoot(MINGA_APP_ROOT_ROUTING, {
      enableTracing: false,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      initialNavigation: 'disabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [
    // Guards
    AuthGuard,
    HasUserGuard,
    SuperadminGuard,
    CanAccessGalleryGuard,
    RoleGuard,
    SettingGuard,
    PermissionsGuard,
    CountryGuard,
    FeatureFlagGuard,
    DeviceGuard,
    PreventNavigationGuard,
    AccessRestrictionsGuard,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
