import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  SecurityContext,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ClippedHtmlComponent } from 'minga/app/src/app/components/ClippedHtml';
import { ShortAnnouncementCardView } from 'minga/proto/gateway/content_views_pb';

import { BaseShortCardClass } from '../BaseShortCardClass';

export interface IMgAnnouncementShortCardElementProperties {
  content?: ShortAnnouncementCardView.AsObject;
}

export const ANNOUNCEMENT_CLIP_LENGTH = 160;

@Component({
  selector: 'mg-announcement-short-card',
  templateUrl: './MgAnnouncementShortCard.element.html',
  styleUrls: ['./MgAnnouncementShortCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgAnnouncementShortCardElement
  extends BaseShortCardClass
  implements IMgAnnouncementShortCardElementProperties
{
  @ViewChild('clippedBodyHtml', { static: false })
  clippedBodyHtml?: ClippedHtmlComponent;

  @Input()
  content?: ShortAnnouncementCardView.AsObject;

  @Input()
  pinned: boolean = false;

  @Input()
  context: string = '';

  @Input()
  markKeywords: string = '';

  @Input()
  forceOnlyFullBody: boolean = false;

  @Input()
  body: string = '';

  @Input()
  commentRoles: string[] | null = null;

  maxBodyLength: number = ANNOUNCEMENT_CLIP_LENGTH;

  @HostBinding('class.mg-cancel-content-link')
  readonly cancelContentLink: boolean = true;

  constructor(private _ngZone: NgZone, public cdr: ChangeDetectorRef) {
    super();
  }

  @HostBinding('class.has-expandable-body')
  get hasExpandableBody() {
    if (this.forceOnlyFullBody) {
      return false;
    }

    if (this.clippedBodyHtml) {
      return !this.clippedBodyHtml.isFullHtmlShowing;
    }

    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.forceOnlyFullBody) {
      if (this.forceOnlyFullBody && this.clippedBodyHtml) {
        this.clippedBodyHtml.showFullHtml();
      }
    }
  }

  ngAfterViewChecked() {
    // This is to prevent ExpressionChangedAfterItHasBeenCheckedError
    requestAnimationFrame(() => {
      if (this.forceOnlyFullBody && this.clippedBodyHtml) {
        this.clippedBodyHtml.showFullHtml();
      }
    });
  }

  @HostListener('click', ['$event'])
  _onClick(ev: MouseEvent) {
    if (
      ev.target instanceof Node &&
      ev.target.nodeName === 'A' &&
      !(ev.target as HTMLAnchorElement).classList.contains('more-link')
    ) {
      return;
    }

    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    const clippedBodyHtml = this.clippedBodyHtml;

    if (!this.forceOnlyFullBody && clippedBodyHtml) {
      this._ngZone.run(() => clippedBodyHtml.toggleFullHtml());
    }
  }
}
