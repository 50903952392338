import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

import { PreventNavigation } from './PreventNavigation';

export const PREVENT_NAVIGATION_HANDLED = `~PREVENT_NAVIGATION_HANDLED~`;

@Injectable()
export class PreventNavigationGuard
  implements CanDeactivate<PreventNavigation>
{
  constructor() {}

  private confirmDeactivate(msg: string): Promise<boolean> {
    if (!msg) {
      return Promise.resolve(true);
    }

    if (msg === PREVENT_NAVIGATION_HANDLED) {
      return Promise.resolve(false);
    }

    // @TODO: We could use material dialog instead of a js confirm dial
    return Promise.resolve(confirm(msg));
  }

  canDeactivate(
    component: PreventNavigation,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ) {
    if (component && component.preventNavigation) {
      let preventNavigation = component.preventNavigation(
        currentRoute,
        currentState,
        nextState,
      );
      let preventNavigationMsg = 'Are you sure you want to leave?';

      try {
        if (typeof preventNavigation == 'string') {
          preventNavigationMsg = preventNavigation;
        } else if ((<any>preventNavigation).then) {
          return (<any>preventNavigation).then(value => {
            return this.confirmDeactivate(value);
          });
        } else if (preventNavigation instanceof Observable) {
          return preventNavigation.toPromise().then(value => {
            return this.confirmDeactivate(value);
          });
        }
      } catch (err) {
        console.error(err);
        return false;
      }

      return this.confirmDeactivate(preventNavigationMsg);
    } else {
      return true;
    }
  }
}
