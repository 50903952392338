<!-- Loading -->
<ng-container *ngIf="loading$ | async; else mainTemplate">
  <mg-spinner
    [diameter]="25"
    [thickness]="4">
  </mg-spinner>
</ng-container>

<!-- Main template -->
<ng-template #mainTemplate>
  <ng-container *ngIf="stepsData$ | async as steps">
    <!-- Empty state -->
    <mg-empty-state
      *ngIf="steps.length === 0"
      class="no-results"
      subtitle="Add the first automation">
    </mg-empty-state>
    <!-- Steps -->
    <div
      *ngIf="steps.length > 0"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="8px">
      <ng-container
        *ngFor="let step of steps"
        [ngTemplateOutlet]="stepTemplate"
        [ngTemplateOutletContext]="{
          $implicit: step
        }">
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- Step template -->
<ng-template
  #stepTemplate
  let-step>
  <div
    class="step"
    [class.is-active]="
      +(activeRoute.firstChild?.params | async)?.stepId === step.id
    "
    matRipple
    [matRippleColor]="'rgba(0, 0, 0, 0.02)'"
    (click)="stepPressed.emit(step.id)">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="4px">
      <mg-text variant="body-sm-secondary">{{ step.name }}</mg-text>
      <div class="display-icon">
        <mg-icon
          icon-namespace="minga.behaviors"
          [iconName]="
            step.consequenceIconType ||
            fallbackIcons[step.consequenceCategoryId]?.icon
          "
          [style.color]="
            step.consequenceIconColor ||
            fallbackIcons[step.consequenceCategoryId]?.color
          "></mg-icon>
      </div>
      <mg-text variant="body-sm">{{ step.consequenceName }}</mg-text>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="4px"
      class="step-thresholds">
      <div
        class="step-indicator"
        fxLayout="row"
        fxLayoutAlign="center center">
        <mg-text variant="body-xs-secondary">{{ step.threshold }}</mg-text>
        <mg-text
          *ngIf="step.repeatStep && step.additionalBehaviors === null"
          variant="body-xs-secondary">
          +
        </mg-text>
      </div>

      <ng-container
        *ngIf="step.repeatStep && step.additionalBehaviors !== null">
        <div
          class="step-indicator"
          *ngFor="
            let count of [].constructor(step.additionalBehaviors);
            let i = index
          ">
          <mg-text variant="body-xs-secondary">
            {{ step.threshold + (i + 1) * step.repeatEvery }}
          </mg-text>
        </div>
      </ng-container>

      <ng-container
        *ngIf="step.repeatStep && step.additionalBehaviors === null">
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          class="step-indicator">
          <mg-icon class="inline-icon">mg-replay</mg-icon>
          <mg-text variant="body-xs-secondary">
            Every {{ step.repeatEvery }}
            {{ step.repeatEvery > 1 ? 'behaviors' : 'behavior' }}
          </mg-text>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
