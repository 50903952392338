import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';

import { MembershipListTableConfig } from '../types';

export enum MembershipListTableMessages {
  COLUMN_NAME = 'Name',
  COLUMN_DESCRIPTION = '',
  COLUMN_ACTIONS = 'Members',
  COLUMN_STATUS = 'Active',
  COLUMN_EDIT = 'Edit',
  COLUMN_MEMBER_COUNT = 'Members',
  COLUMN_IMAGE = '',
  COLUMN_DENIED_PASSES = 'Denied Passes',
  COLUMN_DENIED_PASSES_TOOLTIP = 'Last 30 Days',

  BUTTON_LABEL_ADD = 'Add',
  BUTTON_LABEL_MANAGE = 'Manage',
  BUTTON_LABEL_REMOVE_ALL = 'Remove all',

  LIST_NAME_PLACEHOLDER = 'Unnamed List',

  EMPTY_STATE_TITLE = 'No lists found',
  EMPTY_STATE_SUBTITLE = 'Create a new list to get started',
}

export const MLT_DEFAULT_CONFIG: MembershipListTableConfig = {
  canDelete: false,
  changeColor: false,
  changeImage: false,
  changeStatus: false,
  changeName: false,
  changePriority: false,
  showDescription: true,
  imagePreset: 'none',
  imageUploads: true,
};

/**
 * Default options for specific lists should go here. These are used when
 * creating new list of that type
 */
export const MEMBERSHIP_LIST_DEFAULT_MAP: Record<
  MembershipListType,
  IMembershipList
> = {
  [MembershipListType.BEHAVIOR_MANAGER]: {},
  [MembershipListType.BEHAVIOR_REPORT_VIEWER]: {},
  [MembershipListType.BEHAVIOR_TYPE]: {},
  [MembershipListType.CHECKIN_MANAGER]: {},
  [MembershipListType.CHECKIN_REPORT_VIEWER]: {},
  [MembershipListType.CHECKIN_RESTRICTION_LIST]: {},
  [MembershipListType.CONTENT_MANAGER]: {},
  [MembershipListType.FLEX_MANAGER]: {},
  [MembershipListType.FLEX_REPORT_VIEWER]: {},
  [MembershipListType.FLEX_RESTRICTION_LIST]: {},
  [MembershipListType.HALL_PASS_MANAGER]: {},
  [MembershipListType.HALL_PASS_TYPE]: {},
  [MembershipListType.HP_REPORT_VIEWER]: {},
  [MembershipListType.ID_MANAGER]: {},
  [MembershipListType.NO_ACCESS]: {},
  [MembershipListType.NO_GRANT]: {},
  [MembershipListType.NO_PARTY]: {},
  [MembershipListType.NO_PASS]: {},
  [MembershipListType.NO_TRACK]: {},
  [MembershipListType.ONE_PASS]: {},
  [MembershipListType.PASS_TYPE]: {},
  [MembershipListType.POINTS_MANAGER]: {},
  [MembershipListType.POINTS_REPORT_VIEWER]: {},
  [MembershipListType.SCHOOL_TEAM]: {},
  [MembershipListType.STICKER]: {},
  [MembershipListType.TRACK]: {},
  [MembershipListType.TWO_PASS]: {},
  [MembershipListType.USER_LIST]: {},
  [MembershipListType.USER_LIST_MANAGER]: {},
};
