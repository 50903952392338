import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';

import { LayoutService } from '@modules/layout/services';

import { ManagerOverlayColorTheme } from '@shared/components/manager-overlay';

import { HallpassManagerMessages } from './hpm.constants';
import { HpmRoutesService } from './hpm.routes.service';

/**
 * Hall Pass Manager Component
 *
 * Entry point to the hall pass manager module.
 */
@Component({
  selector: 'mg-hallpass-manager',
  templateUrl: './hallpass-manager.component.html',
  styleUrls: ['./hallpass-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HallpassManagerComponent implements OnInit {
  /** Messages */
  MESSAGES: typeof HallpassManagerMessages = HallpassManagerMessages;

  /** Manager Overlay Color Theme */
  colorTheme: ManagerOverlayColorTheme =
    ManagerOverlayColorTheme.HALL_PASS_MANAGER;

  /** Component Constructor */
  constructor(
    private _hpsRoutes: HpmRoutesService,
    public layout: LayoutService,
  ) {}

  /** Component Lifecycle: On Mount */
  ngOnInit(): void {}

  /**
   * On Overlay Close
   */
  public async onOverlayClose() {
    this._hpsRoutes.returnFromHallpassManagerRoute();
  }
}
