import { Validators } from '@angular/forms';

export enum PeopleUserListsEditMessages {
  TITLE_CREATE = 'Create User List',
  TITLE_EDIT = 'Edit User List',

  INPUT_LABEL_TITLE = 'Title',
  INPUT_LABEL_LIST_UUID = 'List ID',

  INPUT_MESSAGE_LIST_MANAGER = 'List Managers',
  INPUT_SUBMESSAGE_LIST_MANAGER = 'Allow users to edit and use this list (Admins are managers by default)',

  INPUT_MESSAGE_MEMBER = 'Members',

  INPUT_MESSAGE_PUBLIC = 'Make list public',
  INPUT_SUBMESSAGE_PUBLIC = 'Allows other users to use this list',

  BUTTON_LABEL_ADD = 'Add',
  BUTTON_LABEL_CLOSE = 'Cancel',
  BUTTON_LABEL_DELETE = 'Delete',
  BUTTON_LABEL_CREATE = 'Create',
  BUTTON_LABEL_EDIT = 'Save',
}

export enum PeopleUserListsEditFormFields {
  TITLE = 'title',
  UUID = 'uuid',
  MANAGER_LIST_ID = 'managerListId',
  PUBLIC = 'public',
}

export const PEOPLE_USER_LISTS_EDIT_FORM_GROUP = {
  [PeopleUserListsEditFormFields.TITLE]: ['', Validators.required],
  [PeopleUserListsEditFormFields.MANAGER_LIST_ID]: [undefined],
  [PeopleUserListsEditFormFields.PUBLIC]: [false],
};
