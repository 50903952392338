import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { SuperAdminService } from './services';

@Component({
  selector: 'mg-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SuperAdminService],
})
export class SuperAdminComponent implements OnDestroy {
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Component Constructor */
  constructor() {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
