import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, ignoreElements, map, switchMap } from 'rxjs/operators';

import { SuccessDialog } from 'minga/app/src/app/dialog/SuccessDialog';
import { closeCurrentOverlay } from 'minga/app/src/app/util/overlay';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  GroupCollectionActions,
  GroupDetailsActions,
  GroupFormActions,
} from '../actions';
import { GroupsService } from '../services';

@Injectable()
export class GroupFormEffects {
  constructor(
    private actions$: Actions<GroupFormActions.TypeUnion>,
    private groupsService: GroupsService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private router: Router,
    private dialog: MatDialog,
    private store: Store<any>,
  ) {}

  @Effect()
  updateGroup$: Observable<any> = this.actions$.pipe(
    ofType(GroupFormActions.TypeEnum.UpdateGroup),
    switchMap((action: GroupFormActions.UpdateGroupAction) => {
      return this.groupsService
        .updateGroup(action.payload.groupHash, action.payload.groupInputs)
        .pipe(
          map(({ group, groupDetails }) => {
            let dialog = this.dialog.open(SuccessDialog, {
              data: { text: `Group Updated!` },
            });
            // the reducer will not remove banner if the group was changed
            // to use bannerObject, and vice versa. So lets add the property to
            // the object so that it will be overwritten with null in the store.
            if (!group.banner) {
              group.banner = null;
            } else if (!group.bannerObject) {
              group.bannerObject = null;
            }
            closeCurrentOverlay(this.router);
            return new GroupFormActions.UpdateGroupSuccess(group, groupDetails);
          }),
          catchError(err => of(new GroupFormActions.UpdateGroupFailure(err))),
        );
    }),
  );

  @Effect()
  createGroup$: Observable<any> = this.actions$.pipe(
    ofType(GroupFormActions.TypeEnum.CreateGroup),
    switchMap((action: GroupFormActions.CreateGroupAction) => {
      return this.groupsService.createGroup(action.payload).pipe(
        map(
          ({ group, groupDetails }) =>
            new GroupFormActions.CreateGroupSuccess(group, groupDetails),
        ),
        catchError(err => of(new GroupFormActions.CreateGroupFailure(err))),
      );
    }),
  );

  @Effect({ dispatch: false })
  createGroupFailure$: Observable<any> = this.actions$.pipe(
    ofType(GroupFormActions.TypeEnum.CreateGroupFailure),
    switchMap((action: GroupFormActions.CreateGroupFailure) => {
      // @TODO: not really happy with how this is done.
      if ((<any>action.error).action) {
        this.store.dispatch((<any>action.error).action);
        return of((<any>action.error).action);
      }

      this._systemAlertSnackBar.error('Failed to create group');
      return ignoreElements();
    }),
  );

  @Effect({ dispatch: false })
  updateGroupFailure$: Observable<any> = this.actions$.pipe(
    ofType(GroupFormActions.TypeEnum.UpdateGroupFailure),
    switchMap((action: GroupFormActions.UpdateGroupFailure) => {
      // @TODO: not really happy with how this is done.
      if ((<any>action.error).action) {
        this.store.dispatch((<any>action.error).action);
        return of((<any>action.error).action);
      } else if ((<any>action.error).message) {
        this._systemAlertSnackBar.error((<any>action.error).message);
      } else {
        this._systemAlertSnackBar.error('Failed to update group');
      }

      return ignoreElements();
    }),
  );

  @Effect()
  updateGroupLocally$: Observable<any> = this.actions$.pipe(
    ofType(GroupFormActions.TypeEnum.UpdateGroupSuccess),
    map(
      (action: GroupFormActions.UpdateGroupSuccess) =>
        new GroupCollectionActions.UpdateGroupLocally(action.group),
    ),
  );

  @Effect()
  updateGroupDetailsLocally$: Observable<any> = this.actions$.pipe(
    ofType(
      GroupFormActions.TypeEnum.UpdateGroupSuccess,
      GroupFormActions.TypeEnum.CreateGroupSuccess,
    ),
    map(
      (
        action:
          | GroupFormActions.UpdateGroupSuccess
          | GroupFormActions.CreateGroupSuccess,
      ) =>
        new GroupDetailsActions.UpdateGroupDetailsLocally(action.groupDetails),
    ),
  );

  @Effect()
  addGroupLocally$: Observable<any> = this.actions$.pipe(
    ofType(GroupFormActions.TypeEnum.CreateGroupSuccess),
    map((action: GroupFormActions.CreateGroupSuccess) => {
      let dialog = this.dialog.open(SuccessDialog, {
        data: { text: `Group Created Successfully!` },
      });
      closeCurrentOverlay(this.router);
      return new GroupCollectionActions.AddGroupLocally(action.group);
    }),
  );
}
