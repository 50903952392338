import Fuse from 'fuse.js';

import { IConversationsWithPeople } from 'minga/app/src/app/modules/direct-message/store';

export enum DirectMessageMessage {
  BEGIN_SEARCH = 'Begin your search',
}

export const DIRECT_MESSAGES_SEARCH_FUSE_OPTIONS: Fuse.IFuseOptions<IConversationsWithPeople> =
  {
    isCaseSensitive: false,
    shouldSort: true,
    keys: ['people.displayName', 'people.firstName', 'people.lastName'],
    includeMatches: false,
    threshold: 0.4,
  };
