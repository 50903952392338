import { ComponentType, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Injectable,
  Injector,
  StaticProvider,
  ViewContainerRef,
} from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import {
  BackDropColor,
  DEFAULT_CONFIG,
  MODAL_OVERLAY_DATA,
} from '../modal-overlay.constants';
import { ModalOverlayServiceConfig } from '../modal-overlay.types';
import { ModalOverlayRef } from './modal-overlay-ref.service';

/**
 * Modal Overlay Service
 *
 * Generic Custom Overlay Component Service
 */
@Injectable()
// @Injectable({ providedIn: 'root' })
export class ModalOverlayService<R = any, T = any> {
  private _isOpen = new BehaviorSubject<boolean>(false);
  public readonly isOpen: Observable<boolean> = this._isOpen.asObservable();

  readonly isMobileDevice: boolean =
    window.MINGA_DEVICE_ANDROID || window.MINGA_DEVICE_IOS;

  /** Service Constructor */
  constructor(private _overlay: Overlay, private _injector: Injector) {}

  /**
   * Open Overlay
   */
  public open<ModalData extends T>(
    component: ComponentType<any>,
    config: ModalOverlayServiceConfig<ModalData> = {},
    viewContainerRef?: ViewContainerRef,
  ) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    const overlayConfig = this._getConfig<ModalData>(dialogConfig);

    const overlayRef = this._overlay.create(overlayConfig);

    const overlayServiceRef = new ModalOverlayRef<R, ModalData>(
      overlayRef,
      component,
      config.data,
      config?.beforeCloseSuccess,
    );

    const portal = new ComponentPortal(
      component,
      viewContainerRef || null,
      this.createInjector(overlayServiceRef, this._injector, config.data),
    );
    overlayRef.attach(portal);

    return overlayServiceRef;
  }

  /**
   * Get Config
   */
  private _getConfig<ModalData>(
    config: ModalOverlayServiceConfig<ModalData>,
  ): OverlayConfig {
    const positionStrategy = this._overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: [BackDropColor.DARK_BLUE],
      panelClass: config.panelClass,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy,
      disposeOnNavigation: config.disposeOnNavigation,
      minHeight: config.minHeight,
    });

    return overlayConfig;
  }

  createInjector(ref: ModalOverlayRef, inj: Injector, data: any) {
    const injectorTokens: StaticProvider = [];
    injectorTokens.push({ provide: ModalOverlayRef, useValue: ref });
    injectorTokens.push({ provide: MODAL_OVERLAY_DATA, useValue: data });
    return Injector.create({ parent: inj, providers: injectorTokens });
  }
}
