import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import * as day from 'dayjs';
import { ReplaySubject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { CheckinTypes } from '@modules/checkin-manager/checkin-manager.constants';
import { SelectionAssignerService } from '@modules/selection-assigner/services';
import {
  CarouselTileData,
  SaSelectorTypes,
} from '@modules/selection-assigner/types';

import { CheckinService } from '@shared/services/checkin';
import { CheckinFlowService } from '@shared/services/checkin/checkin-flow.service';
import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';

import {
  SaCheckInAssignmentLabels,
  SaCheckInFormFields,
  SaCheckInFormGroup,
} from '../../constants';

@Component({
  selector: 'mg-sa-checkin',
  templateUrl: './sa-checkin.component.html',
  styleUrls: ['./sa-checkin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FlexTimeActivityInstanceService],
})
export class SaCheckinComponent implements OnInit, OnDestroy {
  // Constants

  public readonly FORM_FIELDS = SaCheckInFormFields;
  public readonly ASSIGNMENT_LABELS = SaCheckInAssignmentLabels;

  // Clean up helpers

  private _destroyedSubject = new ReplaySubject<void>(1);

  // Form

  public readonly formGroup = this._fb.group(SaCheckInFormGroup);
  public readonly checkinTypeFC = this._saService.typeFormControl;

  public readonly activeSelector$ = this._saService.activeSelectorSubject;

  /** Component Constructor */
  constructor(
    private _fb: FormBuilder,
    private _saService: SelectionAssignerService,
    private _checkinOverlayService: CheckinFlowService,
    private _checkinService: CheckinService,
    private _flexTimeActivityInstanceService: FlexTimeActivityInstanceService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.activeSelector$,
      this.checkinTypeFC.valueChanges,
      this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)),
    ])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(([activeSelector, checkinTypeFC]) => {
        this._saService.setFormValidity(
          this.formGroup.valid && this.checkinTypeFC.valid,
        );
        if (this.formGroup.valid && this.checkinTypeFC.valid) {
          this._setPayload(activeSelector, checkinTypeFC);
        }
      });
  }

  private async _setPayload(
    activeSelector: SaSelectorTypes,
    type: CarouselTileData,
  ) {
    switch (activeSelector) {
      case 'checkin':
        const reason = await this._checkinService
          .getReason(type.id)
          .toPromise();
        this._checkinOverlayService.setReason(reason);
        this._saService.setPayload(activeSelector, {
          type: activeSelector as CheckinTypes,
          note: this.formGroup.get(this.FORM_FIELDS.NOTE).value,
        });
        break;
      case 'flex-activity':
        const flexTimeActivityInstances =
          await this._flexTimeActivityInstanceService.fetchAll({
            startDate: day(),
            endDate: day().add(1, 'month'),
            periodId: type.type === 'flex-activity' ? type.periodId : undefined,
          });
        const activityInstance = flexTimeActivityInstances.find(
          activity => activity.id === type.id,
        );
        this._saService.setPayload(activeSelector, {
          spaces: activityInstance?.spaces ?? 0,
          registered: activityInstance?.registered ?? 0,
          flexTimeActivityId: activityInstance.id,
          checkinReasonId: activityInstance.checkinReasonId,
          flexTimePeriodId: activityInstance.flexTimePeriodId,
          name: type.name,
          date: activityInstance.flexTimePeriod?.date,
          endTime: activityInstance.flexTimePeriod?.endTime,
          enableStudentManagement:
            activityInstance.flexTimePeriod?.enableStudentManagement,
        });
        break;
      default:
        throw new Error('Invalid selector type');
    }
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
