import { ImageInfo, ImageSize } from 'minga/proto/image/image_pb';

import { blueimpLoadImage } from './blueimp-load-image';

export function loadImage(src: string): Promise<HTMLImageElement> {
  const img = new Image();

  return new Promise<HTMLImageElement>((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

export function getImageInfoSize(
  imageInfo: ImageInfo.AsObject,
  sizeKey: string,
  fallbackSizeKey = 'raw',
): ImageSize.AsObject {
  let sizeMap = imageInfo.sizeMap;
  let imageSize: ImageSize.AsObject | null = null;
  let defaultSize: ImageSize.AsObject | null = null;

  for (let [key, imageSize] of sizeMap) {
    if (key == fallbackSizeKey) {
      defaultSize = imageSize;
    }

    if (key == sizeKey) {
      return imageSize;
    }
  }

  if (!imageSize && !defaultSize) {
    throw new Error(
      `getImageInfoSize() could not find image size for key '${sizeKey}' or ${fallbackSizeKey}`,
    );
  }

  if (!defaultSize) {
    throw new Error(
      `getImageInfoSize() could not find image size for key '${sizeKey}' or ${fallbackSizeKey}`,
    );
  }

  return imageSize || defaultSize;
}

export function getImageInfoSizeUrl(
  imageInfo: ImageInfo.AsObject,
  sizeKey: string,
  fallbackSizeKey = 'raw',
): string {
  return getImageInfoSize(imageInfo, sizeKey, fallbackSizeKey).path;
}

export async function populatePreviewUrlFromFile(
  file: File,
  previewSizeNum: number = 150,
): Promise<string> {
  const previewSize = previewSizeNum * devicePixelRatio;
  let previewUrl: string = '';

  return new Promise<string>((resolve, reject) => {
    const previewHandler = (canvas: any) => {
      previewUrl = canvas.toDataURL();
      return resolve(previewUrl);
    };

    const previewOptions = {
      orientation: true,
      maxWidth: previewSize,
    };

    if (file instanceof Blob) {
      blueimpLoadImage(file, previewHandler, previewOptions);
    } else {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        blueimpLoadImage(fileReader.result, previewHandler, previewOptions);
      };

      // This _looks_ stupid, but the cordova file plugin does not inherit
      // Blob and it overrides FileReader.
      fileReader.readAsDataURL(file);
    }
  });
}

export function cacheBustUrl(url: string): string {
  const urlObj = new URL(url);
  const params = urlObj.searchParams;
  params.set('timestamp', new Date().getMilliseconds().toString());

  return urlObj.toString();
}
