import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from 'minga/app/src/app/button';
import { MgEmptyStateModule } from 'minga/app/src/app/components/EmptyState';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { MgPipesModule } from 'src/app/pipes';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { OverlayModule } from '@shared/components/modal-overlay';
import { PaginatorModule } from '@shared/components/paginator';
import { TextModule } from '@shared/components/text/text.module';
import { TooltipModule } from '@shared/components/tooltip';
import {
  FlexTimeActivityInstanceService,
  FlexTimeActivityService,
  FlexTimePeriodService,
} from '@shared/services/flex-time';

import { FtmSharedModule } from '../ftm-shared/ftm-shared.module';
import { FtmPeriodsActivityEditComponent } from './components/ftm-periods-activity-edit/ftm-periods-activity-edit.component';
import { FtmPeriodsEditComponent } from './components/ftm-periods-edit/ftm-periods-edit.component';
import { FtmPeriodsComponent } from './ftm-periods.component';
import { CountActivityInstancesPipe, TotalRegisteredPipe } from './pipes';
import { FtmPeriodsService } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    MgSpinnerModule,
    MgButtonModule,
    FormModule,
    MgIconModule,
    MgEmptyStateModule,
    OverlayModule,
    MgPipesModule,
    TooltipModule,
    MgImageModule,
    FtmSharedModule,
    TextModule,
    GenericModule,
    PaginatorModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatRippleModule,
    ReactiveFormsModule,
    MatStepperModule,
    RouterModule,
  ],
  declarations: [
    // Pipes
    CountActivityInstancesPipe,
    TotalRegisteredPipe,

    // Components
    FtmPeriodsComponent,
    FtmPeriodsEditComponent,
    FtmPeriodsActivityEditComponent,
  ],
  exports: [FtmPeriodsComponent],
  providers: [
    FlexTimePeriodService,
    FlexTimeActivityService,
    FtmPeriodsService,
    FlexTimeActivityInstanceService,
  ],
})
export class FtmPeriodsModule {}
