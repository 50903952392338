import { Injectable } from '@angular/core';

import { BannerType } from 'minga/domain/banner';
import { IMembershipList } from 'minga/domain/membershipList';
import { BannerLibrary } from 'minga/proto/gateway/banner_ng_grpc_pb';
import {
  Banner,
  BannerLibraryGetAllByType,
} from 'minga/proto/gateway/banner_pb';

@Injectable({ providedIn: 'root' })
export class StickerBannersService {
  constructor(private _bannerLibrary: BannerLibrary) {}

  public async getStickerImageList(): Promise<Banner[]> {
    const request = new BannerLibraryGetAllByType();
    request.setType(BannerType.STICKER);

    const response = await this._bannerLibrary.getAllByType(request);
    return response.getBannersList();
  }

  public getStickerPath(item: IMembershipList) {
    if (item?.assetPath) {
      return item.assetPath;
    }

    if (item?.bannerImage) {
      return item.bannerImage;
    }

    return '';
  }
}
