import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mg-tools-stickers-groups',
  templateUrl: './tools-stickers-groups.component.html',
  styleUrls: ['./tools-stickers-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsStickersGroupsComponent implements OnInit {
  constructor(public route: ActivatedRoute) {}

  ngOnInit(): void {}
}
