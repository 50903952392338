<div *mgOverlayStructure="true; region: 'top'">
  <mg-overlay-nav-item
    class="mg-align-left"
    icon="close"
    (click)="onClose()">
    In this conversation
  </mg-overlay-nav-item>
</div>

<ng-container *ngIf="participants$ | async as participants; else loadingTpl">
  <div class="person-list">
    <div
      *ngFor="let person of participants"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="member-info">
      <mg-avatar
        [src]="person.profileImageUrl"
        [color]="person.badgeIconColor"></mg-avatar>

      <div class="person-wrap">
        <mg-person-minimal
          [person]="person"
          class="no-wrap"
          [class.has-pill]="
            person.hash === (currentUserHash$ | async)
          "></mg-person-minimal>

        <mg-pill-checkbox
          *ngIf="person.hash === (currentUserHash$ | async)"
          icon=""
          class="small self"
          [disabled]="true"
          >You</mg-pill-checkbox
        >
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingTpl>
  <mg-spinner></mg-spinner>
</ng-template>
