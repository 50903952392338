import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import * as pbis_pb from 'minga/proto/pbis/pbis_pb';
import { IConsequence } from 'minga/domain/consequences';
import { IPbisBehavior } from 'minga/domain/pbis';
import { PbisManager } from 'minga/proto/pbis/pbis_ng_grpc_pb';
import { ConsequenceMapper } from 'minga/shared-grpc/consequences';
import { PbisBehaviorMapper } from 'minga/shared-grpc/pbis';

import { Statuses } from '@shared/components/report-table';

import { Behavior, NormalizedBehavior } from '../types/student-tools.types';

@Injectable({ providedIn: 'root' })
export class StudentToolsService {
  private _activeBehaviors = new BehaviorSubject<NormalizedBehavior[]>([]);
  public activeBehaviors$ = this._activeBehaviors.asObservable();
  private _historicBehaviors = new BehaviorSubject<NormalizedBehavior[]>([]);
  public historicBehaviors$ = this._historicBehaviors.asObservable();
  private _activeBehaviorsCount = new BehaviorSubject(0);
  public activeBehaviorsCount$ = this._activeBehaviorsCount.asObservable();
  constructor(private _pbisManager: PbisManager) {}

  public async getStudentToolsBehaviors(personHash?: string): Promise<void> {
    const msg = new pbis_pb.GetStudentToolsBehaviorsRequest();
    if (personHash) msg.setPersonHash(personHash);
    const res = await this._pbisManager.getStudentToolsBehaviors(msg);

    const items = res.getItemList().map(item => {
      if (item.hasConsequence()) {
        const normalizedItem = this._normalizeBehavior(
          ConsequenceMapper.fromProto(item.getConsequence()),
          true,
        );

        return normalizedItem;
      } else {
        return this._normalizeBehavior(
          PbisBehaviorMapper.fromProto(item.getBehavior()),
          false,
        );
      }
    });

    const active = (items || []).filter(
      (b: any) => b.isConsequence && !b?.complete,
    );
    const historic = (items || []).filter(
      (b: any) => !b.isConsequence || b?.complete,
    );

    this._activeBehaviorsCount.next(active.length);
    this._activeBehaviors.next(active);
    this._historicBehaviors.next(historic);
  }

  /**
   * Take either a consequence or behaviour and normalize the fields
   */
  private _normalizeBehavior(
    behavior: Behavior,
    isConsequence,
  ): NormalizedBehavior {
    const assignedBy = isConsequence
      ? (behavior as IConsequence).issuedByPerson
      : (behavior as IPbisBehavior).creator;
    const displayDate = isConsequence
      ? (behavior as IConsequence).issuedAt
      : (behavior as IPbisBehavior).createdAt;
    const completeAt = isConsequence
      ? (behavior as IConsequence).completedAt
      : undefined;
    const note = isConsequence
      ? (behavior as IConsequence).note
      : (behavior as IPbisBehavior).message;
    const status = isConsequence
      ? ((behavior as IConsequence).status as Statuses)
      : undefined;
    const consequenceId = isConsequence
      ? (behavior as IConsequence).id
      : undefined;
    const sourceId = isConsequence
      ? (behavior as IConsequence).sourceId
      : undefined;
    const automationName = isConsequence
      ? (behavior as IConsequence).automationName
      : undefined;
    const overdueActionType = isConsequence
      ? (behavior as IConsequence).handledByOverdueAction
      : undefined;

    return {
      ...behavior,
      isConsequence,
      assignedBy,
      displayDate,
      completeAt,
      note,
      status,
      consequenceId,
      sourceId,
      overdueActionType,
      automationName,
    };
  }
}
