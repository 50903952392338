import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, skip } from 'rxjs/operators';

import { MingaSettingsService } from 'src/app/store/Minga/services';

import { MediaService } from '@shared/services/media';
import { NavigationService } from '@shared/services/navigation';

import {
  LayoutLocalStorageKeys,
  PRIMARY_NAVIGATION_STATE_DEFAULT_STATE,
} from '../constants';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  /** Primary navigation state */
  private readonly _isPrimaryNavigationOpenSubject =
    new BehaviorSubject<boolean>(this._getPrimaryNavigationState());
  public readonly isPrimaryNavigationOpen$ = combineLatest([
    this._isPrimaryNavigationOpenSubject.asObservable(),
    this.media.isMobileView$,
  ]).pipe(
    map(([isOpen, isMobileView]) => {
      return isMobileView ? true : isOpen;
    }),
  );

  /** Legacy layout */
  public readonly useLegacyLayout$ = of(false);

  /** Service constructor */
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public title: Title,
    public navigation: NavigationService,
    public media: MediaService,
    private _settingService: MingaSettingsService,
  ) {
    this._storePrimaryNavigationStateSubscription();
  }

  public togglePrimaryNavigation(): void {
    const isOpen = this._isPrimaryNavigationOpenSubject.getValue();
    this._isPrimaryNavigationOpenSubject.next(!isOpen);
  }

  public expandPrimaryNavigation(): void {
    this._isPrimaryNavigationOpenSubject.next(true);
  }

  public collapsePrimaryNavigation(): void {
    this._isPrimaryNavigationOpenSubject.next(false);
  }

  private _storePrimaryNavigationStateSubscription() {
    return this.isPrimaryNavigationOpen$.pipe(skip(1)).subscribe(isOpen => {
      sessionStorage.setItem(
        LayoutLocalStorageKeys.PRIMARY_NAVIGATION_STATE,
        isOpen ? '1' : '0',
      );
    });
  }

  private _getPrimaryNavigationState() {
    const s =
      sessionStorage.getItem(LayoutLocalStorageKeys.PRIMARY_NAVIGATION_STATE) ??
      PRIMARY_NAVIGATION_STATE_DEFAULT_STATE;
    return Boolean(Number(s));
  }

  public resetPrimaryNavigationState() {
    sessionStorage.removeItem(LayoutLocalStorageKeys.PRIMARY_NAVIGATION_STATE);
  }
}
