import { NgModule } from '@angular/core';

import { StudentPictureDirective } from './student-picture.directive';

@NgModule({
  imports: [],
  declarations: [StudentPictureDirective],
  exports: [StudentPictureDirective],
})
export class StudentPictureModule {}
