import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { CrudConfig, FormStateMachine } from './crud-form-base.types';

@Injectable()
export abstract class CrudFormBase<T> {
  private _formState = new BehaviorSubject<FormStateMachine>('idle');
  public formState$ = this._formState.asObservable();

  public isNewSubj = new BehaviorSubject<boolean>(false);
  public isNew$ = this.isNewSubj.asObservable();

  private _data: T;
  private _crudConfig: CrudConfig<T>;

  constructor(private _config: CrudConfig<T>) {
    this._crudConfig = this._config;
  }

  public get canSubmit() {
    const SUBMITTABLE_STATES: FormStateMachine[] = ['idle', 'invalid', 'error'];
    return SUBMITTABLE_STATES.includes(this._formState.value);
  }

  public get submitLabel() {
    const isNew = this.isNewSubj.getValue();
    const labels = this._crudConfig.labels || {};
    const createLabel = labels.create || 'Create';
    const updateLabel = labels.update || 'Save';

    return isNew ? createLabel : updateLabel;
  }

  public get deleteLabel() {
    const isNew = this.isNewSubj.getValue();
    const labels = this._crudConfig.labels || {};
    const cancelLabel = labels.cancel || 'Cancel';
    const deleteLabel = labels.delete || 'Delete';
    return isNew ? cancelLabel : deleteLabel;
  }

  public get cancelLabel() {
    const labels = this._crudConfig.labels || {};
    const cancelLabel = labels.cancel || 'Cancel';
    return cancelLabel;
  }

  public getData() {
    return this._data;
  }

  public async confirmDelete() {
    const isNew = this.isNewSubj.getValue();
    if (isNew) {
      await this._crudConfig.onCancel();
    } else {
      const confirm = await this._crudConfig.onDelete();

      if (confirm) {
        await this._delete(this._data);
      }
    }
  }

  public async cancel() {
    await this._crudConfig.onCancel();
  }

  public async submit() {
    this._setFormState('submitting');

    if (!this._crudConfig.onValidate(this._data)) {
      return this._setFormState('invalid');
    }

    const isNew = this.isNewSubj.getValue();

    const data = this._crudConfig.onSubmit(this._data);

    if (isNew) {
      await this._create(data);
    } else {
      await this._update(data);
    }
  }

  public async init(id?: number) {
    this._setFormState('loading');
    this._data = {} as T;

    if (id != null) this._crudConfig.id = id;

    if (this._crudConfig.id) {
      this._data = await this._crudConfig.get(this._crudConfig.id);
    }

    const isNew = !this._crudConfig.id;
    this.isNewSubj.next(isNew);
    this._setFormState('idle');
    this._crudConfig.onSetForm(this._data, isNew);
  }

  private async _create(data: Partial<T>) {
    const { create, onSuccess, onError } = this._crudConfig;
    try {
      const created = await this._actionWithLoader<T>(create(data), 'creating');

      onSuccess('create', created);
    } catch (e) {
      if (onError) onError('create', e);
    }
  }

  private async _update(data: Partial<T> | T) {
    const { update, onSuccess, onError } = this._crudConfig;
    try {
      const updated = await this._actionWithLoader<T>(update(data), 'updating');

      onSuccess('update', updated);
    } catch (e) {
      if (onError) onError('update', e);
    }
  }

  private async _delete(data: T) {
    const { onSuccess, onError } = this._crudConfig;
    try {
      const deleted = await this._actionWithLoader<T>(
        this._crudConfig.delete(data),
        'deleting',
      );

      onSuccess('delete', deleted);
    } catch (e) {
      if (onError) onError('delete', e);
    }
  }

  private async _actionWithLoader<DataType>(promise: Promise<DataType>, state) {
    try {
      this._setFormState(state);
      return await this._crudConfig.onShowLoader(promise);
    } catch (e) {
      throw e;
    } finally {
      this._setFormState('idle');
    }
  }

  private _setFormState(state: FormStateMachine) {
    this._formState.next(state);
    if (this._crudConfig.onFormStateChange) {
      this._crudConfig.onFormStateChange(state);
    }
  }
}
