import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ElementRef,
  ViewChild,
  OnInit,
} from '@angular/core';

import confetti from 'canvas-confetti';

import {
  ModalOverlayServiceCloseEventType,
  MODAL_OVERLAY_DATA,
} from '../../modal-overlay.constants';
import { ModalOverlayRef } from '../../services';
import {
  ModalOverlaySuccessData,
  ModalOverlaySuccessResponse,
} from './modal-overlay-success.types';

@Component({
  selector: 'mg-overlay-success',
  templateUrl: './modal-overlay-success.component.html',
  styleUrls: ['./modal-overlay-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalOverlaySuccessComponent implements OnInit {
  @ViewChild('overlayIconTpl', { static: true })
  overlayIcon: ElementRef;

  constructor(
    @Inject(MODAL_OVERLAY_DATA) public dialogData: ModalOverlaySuccessData,
    private _modalOverlayRef: ModalOverlayRef<
      ModalOverlaySuccessResponse,
      ModalOverlaySuccessData
    >,
  ) {
    if (!this.dialogData.noAutoClose) {
      setTimeout(() => {
        this.close(false);
      }, this.dialogData.hangTime || 3000);
    }
  }

  ngOnInit() {
    if (this.overlayIcon) {
      this.overlayIcon.nativeElement.style.setProperty(
        'color',
        this.dialogData.iconColor,
      );
    }

    if (this.dialogData.showConfetti) {
      this._createConfetti();
    }
  }

  close(shouldPerformAction?: boolean) {
    this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.CLOSE, {
      shouldPerformAction,
    });
  }

  private _createConfetti() {
    const colors = ['#78EFD9', '#2C8DC3', '#E67AA0'];

    confetti({
      zIndex: 1001, // to be above the modal
      origin: {
        x: 0.5,
        y: -0.5,
      },
      particleCount: 500,
      angle: 270,
      spread: 180,
      colors,
    });
  }
}
