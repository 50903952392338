import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import { ReportTypes } from 'minga/domain/reportTypes';
import { StatsReportManager } from 'minga/proto/stats_report/stats_report_ng_grpc_pb';
import {
  GetReportInfo,
  ReportSortBy,
} from 'minga/proto/stats_report/stats_report_pb';
import { RootService } from 'src/app/minimal/services/RootService';
import { downloadjs } from 'src/app/util/downloadjs';

import {
  ReportServiceFilterFunctions,
  filterPersonEmitterTriggers,
} from './report-filter.service';

/**
 * Abstract class to base report services on.
 * Necessary for the generic manager report data table to work.
 */
@Injectable()
export abstract class ReportsService<
  F,
> extends ReportServiceFilterFunctions<F> {
  constructor(
    initFilterState: F,
    nonArrayFilterTypes?: string[],
    emitTriggers?: Record<string, filterPersonEmitterTriggers>,
    protected _reportManager?: StatsReportManager,
    private _rootService?: RootService,
  ) {
    super(initFilterState, nonArrayFilterTypes, emitTriggers);
  }

  completeItem(item: any) {
    console.error('completeItem() not implemented');
  }

  protected async _getReport(
    reportType: ReportTypes,
    offset?: number,
    limit?: number,
    sortBy?: Sort,
  ): Promise<{ infos: GetReportInfo[]; pageToken?: number }> {
    try {
      const req = this._mapFiltersToFilterMessage(reportType, offset, limit);
      if (sortBy) {
        const sort = new ReportSortBy();
        sort.setColumn(sortBy.active);
        sort.setDirection(sortBy.direction);
        req.setSortBy(sort);
      }
      const res = await this._reportManager.getReport(req);
      return {
        infos: res.getInfoList(),
        pageToken: res.getPageToken(),
      };
    } catch (e) {
      console.error(e);
      return {
        infos: [],
      };
    }
  }
  public async exportReport(reportType: ReportTypes) {
    const req = this._mapFiltersToFilterMessage(reportType);
    const obs = this._reportManager.exportReport(req);
    let filename = '';
    let fileData = new Uint8Array(0);

    await this._rootService.addLoadingPromise(
      new Promise<void>((resolve, reject) => {
        obs.subscribe(
          chunk => {
            if (chunk.hasChunk()) {
              const chunkData = chunk.getChunk_asU8();
              const newFileData = new Uint8Array(
                fileData.length + chunkData.length,
              );
              newFileData.set(fileData);
              newFileData.set(chunkData, fileData.length);
              fileData = newFileData;
            } else if (chunk.hasFilename()) {
              filename = chunk.getFilename();
            }
          },
          reject,
          resolve as any,
        );
      }),
    );

    // @TODO: @types/downloadjs doesn't agree with the typings here
    downloadjs(fileData as any, filename);
  }
}
