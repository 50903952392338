import { Injectable } from '@angular/core';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { MingaRoles } from 'minga/proto/gateway/minga_roles_ng_grpc_pb';
import {
  GetMingaRoleSettingsRequest,
  SetCommentPermittableRoleRequest,
  SetFeedPermittableRoleRequest,
  SetGalleryPermittableRoleRequest,
  SetGroupCreatePermittableRoleRequest,
  SetJoinViaCodeRoleRequest,
  SetVideoUploadPermittableRoleRequest,
} from 'minga/proto/gateway/minga_roles_pb';
import { PeopleManager } from 'minga/proto/gateway/people_ng_grpc_pb';
import { GetRolesRequest } from 'minga/proto/gateway/people_pb';
import { DisplayNameFormat, RoleFields } from 'minga/util';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IRoleInfo {
  roleType: string;
  name: string;
  roleDisplayNameFormat: DisplayNameFormat;
  roleFields: RoleFields;
  feedPermittable: boolean;
  galleryPermittable: boolean;
  programManagePermittable: boolean;
  videoUploadPermittable: boolean;
  commentPermittable: boolean;
  groupCreatePermittable: boolean;
  capabilitiesDescription: string;
  joinViaCodeApplicable: boolean;
  immutableFeedPermittable: boolean;
  immutableGalleryPermittable: boolean;
  immutableGroupFeedPermittable: boolean;
  immutableProgramManagePermittable: boolean;
  immutableVideoUploadPermittable: boolean;
  immutableCommentPermittable: boolean;
  immutableGroupCreatePermittable: boolean;
  admin: boolean;
  iconColor: string;
  billable: boolean;
}

export interface IMingaRoleSettingInfo {
  feedPermittable: boolean;
  joinViaCode: boolean;
  galleryPermittable: boolean;
  videoUploadPermittable: boolean;
  commentPermittable: boolean;
  groupCreatePermittable: boolean;
}

export type MingaRoleSettings = {
  [keyname: string]: IMingaRoleSettingInfo | undefined;
};

/**
 * Convenience service for grabbing roles
 */
@Injectable({ providedIn: 'root' })
export class RolesService {
  private _currentFetch?: Promise<IRoleInfo[]>;
  private _currentSettingsFetch?: Promise<MingaRoleSettings>;
  private _roles$: BehaviorSubject<IRoleInfo[]>;
  private _mingaRoleSettings$: BehaviorSubject<MingaRoleSettings>;

  /**
   * All roles available to the current client
   */
  readonly roles$: Observable<IRoleInfo[]>;

  /**
   * All 'admin' roles available to the current client
   */
  readonly adminRoles$: Observable<IRoleInfo[]>;

  /**
   * All role settings available to the current client
   */
  readonly mingaRoleSettings$: Observable<MingaRoleSettings>;

  /**
   * Roles available to be selected as feed permittable
   */
  readonly availableFeedPermitableRoles$: Observable<IRoleInfo[]>;

  /**
   * Feed permittable roles
   */
  readonly feedPermitableRoles$: Observable<IRoleInfo[]>;

  /**
   * Gallery permittable roles
   */
  readonly galleryPermitableRoles$: Observable<IRoleInfo[]>;

  /**
   * Video upload permitable roles
   */
  readonly videoUploadPermitableRoles$: Observable<IRoleInfo[]>;

  /**
   * Comment permittable roles
   */
  readonly commentPermitableRoles$: Observable<IRoleInfo[]>;

  /**
   * Group creation permittable roles
   */
  readonly groupCreatePermittableRoles$: Observable<IRoleInfo[]>;

  /**
   * Available roles for managing programs in a minga.
   */
  readonly availableProgramManageRoles$: Observable<IRoleInfo[]>;

  /**
   * Roles available to be selected as gallery permittable
   */
  readonly availableGalleryPermitableRoles$: Observable<IRoleInfo[]>;

  /**
   * Roles available to be selected as video upload permittable
   */
  readonly availableVideoUploadPermitableRoles$: Observable<IRoleInfo[]>;

  /**
   * Roles available to be selected as comment permittable
   */
  readonly availableCommentPermitableRoles$: Observable<IRoleInfo[]>;

  /**
   * Roles available to be selected as group create permittable
   */
  readonly availableGroupCreatePermittableRoles$: Observable<IRoleInfo[]>;

  /**
   * Roles available to be selected as public joinable
   */
  readonly availablePublicJoinableRoles$: Observable<IRoleInfo[]>;

  /**
   * Public joinable roles
   */
  readonly publicJoinableRoles$: Observable<IRoleInfo[]>;

  /**
   * Roles that count in billing calculations for this minga type.
   */
  readonly billableRoles$: Observable<IRoleInfo[]>;

  constructor(
    private mingaRolesProto: MingaRoles,
    private peopleManagerProto: PeopleManager,
  ) {
    this._roles$ = new BehaviorSubject<IRoleInfo[]>([]);
    this.roles$ = this._roles$.asObservable();

    this.adminRoles$ = this.roles$.pipe(
      map(roles => roles.filter(role => role.admin)),
    );

    this.billableRoles$ = this.roles$.pipe(
      map(roles => roles.filter(role => role.billable)),
    );

    this._mingaRoleSettings$ = new BehaviorSubject<MingaRoleSettings>({});
    this.mingaRoleSettings$ = this._mingaRoleSettings$.asObservable();

    this.availableFeedPermitableRoles$ = this.roles$.pipe(
      map(roles => roles.filter(r => r.feedPermittable)),
    );

    this.availableGalleryPermitableRoles$ = this.roles$.pipe(
      map(roles => {
        return roles.filter(r => r.galleryPermittable);
      }),
    );

    this.availableVideoUploadPermitableRoles$ = this.roles$.pipe(
      map(roles => {
        return roles.filter(r => r.videoUploadPermittable);
      }),
    );

    this.availableCommentPermitableRoles$ = this.roles$.pipe(
      map(roles => {
        return roles.filter(r => r.commentPermittable);
      }),
    );

    this.availableGroupCreatePermittableRoles$ = this.roles$.pipe(
      map(roles => {
        return roles.filter(r => r.groupCreatePermittable);
      }),
    );

    this.availableProgramManageRoles$ = this.roles$.pipe(
      map(roles => {
        return roles.filter(r => {
          return (
            r.programManagePermittable || r.immutableProgramManagePermittable
          );
        });
      }),
    );

    this.publicJoinableRoles$ = combineLatest(
      this.roles$,
      this.mingaRoleSettings$,
    ).pipe(
      map(([roles, roleSettings]) => {
        return roles.filter(r => {
          const roleSetting = roleSettings[r.roleType];
          return roleSetting && roleSetting.joinViaCode;
        });
      }),
    );

    this.availablePublicJoinableRoles$ = this.roles$.pipe(
      map(roles => roles.filter(r => r.joinViaCodeApplicable)),
    );

    this.feedPermitableRoles$ = combineLatest(
      this.roles$,
      this.mingaRoleSettings$,
    ).pipe(
      map(([roles, roleSettings]) => {
        return roles.filter(r => {
          const roleSetting = roleSettings[r.roleType];
          return roleSetting && roleSetting.feedPermittable;
        });
      }),
    );

    this.galleryPermitableRoles$ = combineLatest(
      this.roles$,
      this.mingaRoleSettings$,
    ).pipe(
      map(([roles, roleSettings]) => {
        return roles.filter(r => {
          const roleSetting = roleSettings[r.roleType];
          return roleSetting && roleSetting.galleryPermittable;
        });
      }),
    );

    this.videoUploadPermitableRoles$ = combineLatest(
      this.roles$,
      this.mingaRoleSettings$,
    ).pipe(
      map(([roles, roleSettings]) => {
        return roles.filter(r => {
          const roleSetting = roleSettings[r.roleType];
          return roleSetting && roleSetting.videoUploadPermittable;
        });
      }),
    );

    this.commentPermitableRoles$ = combineLatest(
      this.roles$,
      this.mingaRoleSettings$,
    ).pipe(
      map(([roles, roleSettings]) => {
        return roles.filter(r => {
          const roleSetting = roleSettings[r.roleType];
          return roleSetting && roleSetting.commentPermittable;
        });
      }),
    );

    this.groupCreatePermittableRoles$ = combineLatest(
      this.roles$,
      this.mingaRoleSettings$,
    ).pipe(
      map(([roles, roleSettings]) => {
        return roles.filter(r => {
          const roleSetting = roleSettings[r.roleType];
          return roleSetting && roleSetting.groupCreatePermittable;
        });
      }),
    );

    (<any>window).rolesService = this;
  }

  /**
   * Fetches data only if there is no stored data or if there isn't a fetch
   * already happening
   */
  async fetchIfNeeded(): Promise<void> {
    const isFetchingRoles = !!this._currentFetch;
    const isFetchingMingaRoleSettings = !!this._currentSettingsFetch;

    if (isFetchingRoles && isFetchingMingaRoleSettings) {
      return;
    }

    const noStoredRoles = this._roles$.getValue().length === 0;
    const noMingaRoleSettings =
      Object.keys(this._mingaRoleSettings$.getValue()).length === 0;

    const promises: Promise<void>[] = [];

    if (noStoredRoles && !isFetchingRoles) {
      promises.push(this.fetchRoles());
    }

    if (noMingaRoleSettings && !isFetchingMingaRoleSettings) {
      promises.push(this.fetchMingaRoleSettings());
    }

    await Promise.all(promises);
  }

  /**
   * Fetch or re-fetch all data
   */
  async fetch(): Promise<void> {
    await Promise.all([this.fetchRoles(), this.fetchMingaRoleSettings()]);
  }

  /**
   * Fetch or re-fetch minga role settings
   */
  async fetchMingaRoleSettings(): Promise<void> {
    if (this._currentSettingsFetch) {
      await this._currentSettingsFetch;
      return;
    }

    let thisFetch = this._doMingaRoleSettingsFetch();
    try {
      this._currentSettingsFetch = thisFetch;
      this._mingaRoleSettings$.next(await thisFetch);
    } finally {
      if (this._currentSettingsFetch === thisFetch) {
        delete this._currentSettingsFetch;
      }
    }
  }

  /**
   * Fetch or re-fetch roles
   */
  async fetchRoles(): Promise<void> {
    if (this._currentFetch) {
      await this._currentFetch;
      return;
    }

    const thisFetch = this._doRolesFetch();
    try {
      this._currentFetch = thisFetch;
      this._roles$.next(await thisFetch);
    } finally {
      if (this._currentFetch === thisFetch) {
        delete this._currentFetch;
      }
    }
  }

  /**
   * Clears everything so they need to be fetched again
   */
  clear() {
    this.clearMingaRoleSettings();
    this.clearRoles();
  }

  /**
   * Clear roles so they need to be fetched again
   */
  clearRoles() {
    this._roles$.next([]);
  }

  /**
   * Clears minga role settings so they need to be fetched again
   */
  clearMingaRoleSettings() {
    this._mingaRoleSettings$.next({});
  }

  async setFeedPermittableRole(roleType: string, feedPermittable: boolean) {
    const request = new SetFeedPermittableRoleRequest();
    request.setRoleType(roleType);
    request.setFeedPermittable(feedPermittable);
    await this.mingaRolesProto.setFeedPermittableRole(request);
    const roleSettings = this._mingaRoleSettings$.getValue();
    const roleTypeSettings = roleSettings[roleType];
    if (roleTypeSettings) {
      this._mingaRoleSettings$.next({
        ...roleSettings,
        [roleType]: {
          ...roleTypeSettings,
          feedPermittable,
        },
      });
    }
  }

  async setGalleryPermittableRole(
    roleType: string,
    galleryPermittable: boolean,
  ) {
    const request = new SetGalleryPermittableRoleRequest();
    request.setRoleType(roleType);
    request.setGalleryPermittable(galleryPermittable);
    await this.mingaRolesProto.setGalleryPermittableRole(request);
    const roleSettings = this._mingaRoleSettings$.getValue();
    const roleTypeSettings = roleSettings[roleType];
    if (roleTypeSettings) {
      this._mingaRoleSettings$.next({
        ...roleSettings,
        [roleType]: {
          ...roleTypeSettings,
          galleryPermittable,
        },
      });
    }
  }

  async setJoinViaCodeRole(roleType: string, joinViaCode: boolean) {
    const request = new SetJoinViaCodeRoleRequest();
    request.setRoleType(roleType);
    request.setJoinViaCode(joinViaCode);
    await this.mingaRolesProto.setJoinViaCodeRole(request);
    const roleSettings = this._mingaRoleSettings$.getValue();
    const roleTypeSettings = roleSettings[roleType];
    if (roleTypeSettings) {
      this._mingaRoleSettings$.next({
        ...roleSettings,
        [roleType]: {
          ...roleTypeSettings,
          joinViaCode,
        },
      });
    }
  }

  async setVideoUploadPermittableRole(
    roleType: string,
    videoUploadPermittable: boolean,
  ) {
    const request = new SetVideoUploadPermittableRoleRequest();
    request.setRoleType(roleType);
    request.setVideoUploadPermittable(videoUploadPermittable);
    await this.mingaRolesProto.setVideoUploadPermittableRole(request);
    const roleSettings = this._mingaRoleSettings$.getValue();
    const roleTypeSettings = roleSettings[roleType];
    if (roleTypeSettings) {
      this._mingaRoleSettings$.next({
        ...roleSettings,
        [roleType]: {
          ...roleTypeSettings,
          videoUploadPermittable,
        },
      });
    }
  }

  async setCommentPermittableRole(
    roleType: string,
    commentPermittable: boolean,
  ) {
    const request = new SetCommentPermittableRoleRequest();
    request.setRoleType(roleType);
    request.setCommentPermittable(commentPermittable);
    await this.mingaRolesProto.setCommentPermittableRole(request);
    const roleSettings = this._mingaRoleSettings$.getValue();
    const roleTypeSettings = roleSettings[roleType];
    if (roleTypeSettings) {
      this._mingaRoleSettings$.next({
        ...roleSettings,
        [roleType]: {
          ...roleTypeSettings,
          commentPermittable,
        },
      });
    }
  }

  async setGroupCreatePermittableRole(
    roleType: string,
    groupCreatePermittable: boolean,
  ) {
    const request = new SetGroupCreatePermittableRoleRequest();
    request.setRoleType(roleType);
    request.setGroupCreatePermittable(groupCreatePermittable);
    await this.mingaRolesProto.setGroupCreatePermittableRole(request);
    const roleSettings = this._mingaRoleSettings$.getValue();
    const roleTypeSettings = roleSettings[roleType];
    if (roleTypeSettings) {
      this._mingaRoleSettings$.next({
        ...roleSettings,
        [roleType]: {
          ...roleTypeSettings,
          groupCreatePermittable,
        },
      });
    }
  }

  /** @legacy dont use in new code */
  async formShouldShowGrade(roleType: string): Promise<boolean> {
    await this.fetchIfNeeded();
    const roles = this._roles$.getValue();

    for (let role of roles) {
      if (role.roleType == roleType) {
        return (
          role.roleFields == RoleFields.GRAD ||
          role.roleFields == RoleFields.GRAD_STUDENT
        );
      }
    }

    return false;
  }

  /** @legacy dont use in new code */
  async formShouldShowStudentId(roleType: string): Promise<boolean> {
    await this.fetchIfNeeded();
    const roles = this._roles$.getValue();

    for (let role of roles) {
      if (role.roleType == roleType) {
        return (
          role.roleFields == RoleFields.STUDENT ||
          role.roleFields == RoleFields.GRAD_STUDENT
        );
      }
    }

    return false;
  }

  private async _doMingaRoleSettingsFetch(): Promise<MingaRoleSettings> {
    const request = new GetMingaRoleSettingsRequest();
    const response = await this.mingaRolesProto.getMingaRoleSettings(request);
    const respSettingsMap = response.getSettingsMap();
    const settings: MingaRoleSettings = {};

    respSettingsMap.forEach((setting, roleType) => {
      settings[roleType] = {
        feedPermittable: setting.getFeedPermittable(),
        joinViaCode: setting.getJoinViaCode(),
        galleryPermittable: setting.getGalleryPermittable(),
        videoUploadPermittable: setting.getVideoUploadPermittable(),
        commentPermittable: setting.getCommentPermittable(),
        groupCreatePermittable: setting.getGroupCreatePermittable(),
      };
    });

    return settings;
  }

  private async _doRolesFetch(): Promise<IRoleInfo[]> {
    const request = new GetRolesRequest();
    const response = await this.peopleManagerProto.getRoles(request);
    const status = response.getStatus();

    if (status != StatusCode.OK) {
      throw new Error(`Fetching roles returned status code ${status}`);
    }

    return response.getRoleList().map(role => {
      const roleInfo: IRoleInfo = {
        name: role.getName(),
        roleType: role.getType(),
        roleDisplayNameFormat: DisplayNameFormat.fromIndex(
          <number>role.getDisplaynameformat(),
        ),
        roleFields: RoleFields.fromIndex(<number>role.getRolefields()),
        feedPermittable: role.getFeedPermittable(),
        galleryPermittable: role.getGalleryPermittable(),
        videoUploadPermittable: role.getVideoUploadPermittable(),
        programManagePermittable: role.getProgramManagePermittable(),
        capabilitiesDescription: role.getCapabilitiesDescription(),
        joinViaCodeApplicable: role.getJoinViaCodeApplicable(),
        commentPermittable: role.getCommentPermittable(),
        groupCreatePermittable: role.getGroupCreatePermittable(),
        immutableFeedPermittable: role.getImmutableFeedPermittable(),
        immutableGalleryPermittable: role.getImmutableGalleryPermittable(),
        immutableGroupFeedPermittable: role.getImmutableGroupFeedPermittable(),
        immutableVideoUploadPermittable:
          role.getImmutableVideoUploadPermittable(),
        immutableProgramManagePermittable:
          role.getImmutableProgramManagePermittable(),
        admin: role.getAdmin(),
        iconColor: role.getIconColor(),
        immutableCommentPermittable: role.getImmutableCommentPermittable(),
        billable: role.getBillable(),
        immutableGroupCreatePermittable:
          role.getImmutableGroupCreatePermittable(),
      };

      return roleInfo;
    });
  }

  /**
   * Get role info observable from current roles observable (fetches if needed)
   * for a given role based off of role type (string).
   * @param roleType  string
   */
  getRoleByType(roleType: string): Observable<IRoleInfo | undefined> {
    this.fetchIfNeeded();
    return this.roles$.pipe(
      map(roles => roles.find(role => role.roleType == roleType)),
    );
  }
}
