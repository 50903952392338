<mg-stream-scroller
  *ngIf="search.value$ | async as query; else noQueryTemplate"
  [filter]="{ query: query }"
  [service]="searchManager"
  rpcStream="search"
  rpcControl="searchControl">
  <ng-template
    let-item
    let-itemId="itemId"
    mgStreamItem>
    <mg-content-feed-item
      [markKeywords]="query"
      [context]="itemId"
      [content]="item.feedItem">
    </mg-content-feed-item>
  </ng-template>

  <p
    mg-stream-empty
    class="mg-centered">
    No Results
  </p>
  <p
    mg-stream-front-exhausted
    class="mg-centered">
    End of results
  </p>
</mg-stream-scroller>

<!-- No query template -->
<ng-template #noQueryTemplate>
  <p class="mg-centered">Start typing to search your minga!</p>
</ng-template>
