<mg-legacy-overlay-nav
  [component]="this"
  [showTopNav]="showTopNav">
</mg-legacy-overlay-nav>

<mg-long-card-base
  #longCardBase
  [component]="this"
  class="actions-inherit-color">
  <div
    class="insp-image-wrap"
    [class.has-gradient-overlap]="content.gradientOverlap"
    *ngIf="content.image">
    <div
      class="insp-image"
      [style.paddingBottom]="getImagePaddingBottom()"
      [style.backgroundImage]="'url(' + getInspImageUrl() + ')'"></div>

    <div *ngIf="content.gradientOverlap">
      <div
        class="banner-gradient banner-back-gradient"
        [ngStyle]="{
          background: bannerGradientStyle(),
          top: bannerGradientSize(1)
        }"></div>

      <div
        class="banner-gradient"
        [ngStyle]="{
          background: bannerGradientStyle(),
          top: bannerGradientSize(0.85)
        }"></div>
    </div>
  </div>
</mg-long-card-base>
