<section
  class="calendar-overlay"
  [ngClass]="{
    'hide-presets': hidePresets
  }">
  <div class="calendar-wrap">
    <div
      *ngIf="hidePresets === false"
      class="presets">
      <mg-form-date-range-presets
        (presetClicked)="selectPreset($event)"
        [activePreset]="activePreset$ | async"></mg-form-date-range-presets>
    </div>

    <div class="calendar">
      <mg-form-date-range-calendar
        pickerStyle="desktop"
        [selectedRange]="selectedRange"
        [minDate]="minDateSetting"
        [maxDate]="maxDateSetting"
        (dateSelected)="
          dateSelected($event.start, $event.end)
        "></mg-form-date-range-calendar>
    </div>
  </div>
  <div class="btn-wrap">
    <mg-btn
      (pressed)="clear()"
      variant="text"
      size="small">
      {{ MESSAGES.RESET_BTN }}
    </mg-btn>
    <mg-btn
      [disabled]="!range.valid"
      (pressed)="done()"
      size="small">
      {{ MESSAGES.DONE_BTN }}
    </mg-btn>
  </div>
</section>
