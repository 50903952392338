import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MessagingStoreEffects } from '../effects';
import { messagingReducer } from '../reducer';
import { messagingStoreKeyname } from '../state';

@NgModule({
  imports: [
    StoreModule.forFeature(messagingStoreKeyname, messagingReducer),
    EffectsModule.forFeature([MessagingStoreEffects]),
  ],
  providers: [MessagingStoreEffects],
})
export class MessagingStoreModule {}
