import { Directive, OnDestroy } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import { FormSelectComponent } from 'src/app/shared/components/form/components/form-select/form-select.component';

import { FormSelectOption } from '@shared/components/form';

/**
 * Output Value Only Directive
 * Outputs raw value instead of select form item
 * Directive is to be used with form-select component
 */
@Directive({
  selector: '[outputValueOnly]',
  exportAs: 'outputValueOnly',
})
export class OutputValueOnlyDirective implements OnDestroy {
  isLoading$: BehaviorSubject<boolean>;
  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(private _host: FormSelectComponent) {
    // select menu option selected observable
    _host.valueChanges$.pipe(takeUntil(this._destroyed$)).pipe(
      map((result: FormSelectOption) => {
        return result.value;
      }),
    );

    this.isLoading$ = this._host.isLoading$;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
