<ng-template #successOverlayTemplate>
  <div class="success-overlay">
    <div class="checkmark-wrap">
      <mg-success-checkmark></mg-success-checkmark>
      <div
        class="mg-bodycopy"
        [innerHTML]="successText"></div>
    </div>
  </div>
</ng-template>
