<p>
  You are about to destroy <strong>{{ mingaName }}</strong
  >. This action CANNOT be undone.
</p>

<div>
  <mat-form-field mgFormField>
    <input
      matInput
      name="confirmDestroy"
      [(ngModel)]="confirmDestroyInput"
      placeholder="Enter '{{ confirmDestroy }}' to confirm"
      [required]="true" />
  </mat-form-field>
</div>

<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="8px">
  <mg-button
    type="outline"
    (click)="cancel()"
    >Cancel</mg-button
  >
  <mg-button
    [disabled]="confirmDestroy != confirmDestroyInput"
    (click)="submit()"
    >Destroy</mg-button
  >
</div>
