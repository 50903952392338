import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IConversationsWithPeople } from 'minga/app/src/app/modules/direct-message/store';
import { Person } from 'minga/app/src/app/people';
import { IConversationWithReadStatus } from 'minga/domain/messaging';

export const addPeopleToConversations = (
  people$: Observable<Person[]>,
  conversations$:
    | Observable<IConversationWithReadStatus[]>
    | Observable<ReadonlyArray<Readonly<IConversationWithReadStatus>>>,
): Observable<IConversationsWithPeople[]> => {
  return combineLatest([people$, conversations$]).pipe(
    map(([people, items]) => {
      const conversations = _addPeopleToConversations(people, items);
      return conversations;
    }),
  );
};

const _addPeopleToConversations = (
  people: Person[],
  conversations:
    | IConversationWithReadStatus[]
    | readonly Readonly<IConversationWithReadStatus>[],
): IConversationsWithPeople[] => {
  const peopleConversations = [];
  for (const item of conversations) {
    const itemPersons: Person[] = [];

    item.participants.forEach(participant => {
      const person = people.find(p => p.hash === participant);
      if (person) {
        itemPersons.push(person);
      }
    });

    peopleConversations.push({
      conversation: item,
      people: itemPersons,
    });
  }
  return peopleConversations;
};
