import { Component } from '@angular/core';

import { ErrorPagesMessage } from '@modules/layout/constants';

@Component({
  selector: 'mg-layout-server-error',
  templateUrl: './layout-server-error.component.html',
  styleUrls: ['./layout-server-error.component.scss'],
})
export class LayoutServerErrorComponent {
  // Constants
  public readonly MSG = ErrorPagesMessage;

  constructor() {}
}
