import { scanBarcode } from '@lib/cordova/barcode-scanner';

import { BarcodeScanner, BarcodeScannerReaders } from '../BarcodeScanner';

export class CordovaBarcodeScanner extends BarcodeScanner {
  async scan(readers?: BarcodeScannerReaders[]) {
    const isQRCode = readers?.includes('qrcode');
    const result = await scanBarcode({});
    // trim off last char if it's non-alphanumeric
    // this deals with the scanner sometimes returning bad data with
    // certain combinations of numbers.
    if (result.text && !isQRCode) {
      const lastChar = result.text.slice(-1);
      if (!lastChar.match(/^[0-9a-z]+$/)) {
        result.text = result.text.substring(0, result.text.length - 1);
      }
    }
    return result;
  }

  isAvailable(): boolean {
    return true;
  }
}
