<button
  #buttonElement
  matRipple
  class="button"
  [matRippleDisabled]="this.isDisabled || !hasRipples"
  [matRippleColor]="'rgba(255, 255, 255, .10)'"
  [disabled]="isDisabled"
  [style]="styleVariables"
  [ngClass]="styleClasses"
  [attr.form]="attrFormId"
  [attr.type]="attrType"
  [attr.data-analytics]="id"
  [attr.data-test]="id"
  (click)="!isDisabled && handleOnClickButton($event)">
  <ng-container *ngIf="iconLeft">
    <mg-icon
      [iconName]="iconLeft"
      class="left-icon"></mg-icon>
  </ng-container>
  <span
    class="label"
    [class.icon-on-left]="iconLeft"
    [class.icon-on-right]="iconRight">
    <ng-content></ng-content>
  </span>
  <ng-container *ngIf="iconRight">
    <mg-icon
      [iconName]="iconRight"
      class="right-icon"></mg-icon>
  </ng-container>
</button>
