import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { ReplaySubject } from 'rxjs';

import { DirectMessageService } from './services';

@Component({
  selector: 'mg-direct-message',
  templateUrl: './direct-message.component.html',
  styleUrls: ['./direct-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DirectMessageService],
})
export class DirectMessageComponent implements OnDestroy {
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Component Constructor */
  constructor() {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
