import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  IPageInfo,
  VirtualScrollerComponent,
} from '@minga/ngx-virtual-scroller';
import { ScrollTargetService } from 'minga/app/src/app/misc/ScrollTarget/service';
import {
  IMgStreamControl,
  IMgStreamFilter,
  IMgStreamItem,
  mgStreamControlObservable,
} from 'minga/app/src/app/util/stream';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import {
  IMgStreamScrollerInstance,
  MG_STREAM_SCROLLER_INSTANCE,
} from './MgStreamScrollerInstance';
import { MgStreamScrollerItemDirective } from './MgStreamScrollerItem.directive';
import { MgTableStreamHeaderDirective } from './MgTableStreamHeader.directive';

@Component({
  selector: 'mg-table-stream-scroller',
  templateUrl: './MgTableStreamScroller.component.html',
  styleUrls: ['./MgTableStreamScroller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MG_STREAM_SCROLLER_INSTANCE,
      useExisting: forwardRef(() => MgTableStreamScrollerComponent),
    },
  ],
})
export class MgTableStreamScrollerComponent<ServiceT = any>
  implements IMgStreamScrollerInstance, OnDestroy, OnChanges
{
  @ContentChild(MgStreamScrollerItemDirective, {
    static: false,
    read: TemplateRef,
  })
  streamItemTemplate?: TemplateRef<any>;

  @ContentChild(MgTableStreamHeaderDirective, {
    static: false,
    read: TemplateRef,
  })
  tableHeaderTemplate?: TemplateRef<any>;

  @ViewChild('virtualScroller', { static: false })
  virtualScroller?: VirtualScrollerComponent;

  @Input()
  filter: IMgStreamFilter | null = null;

  @Input()
  service: ServiceT | null = null;

  @Input()
  rpcStream?: keyof ServiceT;

  @Input()
  rpcControl?: keyof ServiceT;

  /**
   * The number of elements to be rendered above & below the current virtual
   * scroller viewport. Defaults to `5`, you may need to increase this for
   * smaller elements.
   */
  @Input()
  bufferAmount: number = 5;

  /**
   * How many items close to the top or bottom should a load trigger
   */
  @Input()
  loadThreshold: number = 5;

  @Input()
  clearOnRestart: boolean = false;

  readonly grid: boolean = false;

  private _delayedSeekTimeout: any;
  private _delayedUpdateRefreshing: boolean = false;
  private _itemDirectives: MgStreamScrollerItemDirective[] = [];
  private _loadingSub?: Subscription;
  private readonly _loading$ = new BehaviorSubject<boolean>(false);

  stream: IMgStreamControl<any> | null = null;
  items$: Observable<IMgStreamItem<any>[]> | null = null;
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  get frontExhausted() {
    if (this.stream) {
      return this.stream.frontExhausted;
    }

    return false;
  }

  get backExhausted() {
    if (this.stream) {
      return this.stream.backExhausted;
    }

    return false;
  }

  get isDone(): boolean {
    if (this.stream) {
      return this.stream.isDone;
    }

    return false;
  }

  get parentScroll() {
    return this.scrollTargetService.getScrollTarget();
  }

  streamItemTrackBy = (index: number, item: IMgStreamItem<any>) => {
    return item.itemId;
  };

  constructor(private scrollTargetService: ScrollTargetService) {}

  getStreamGrpcErrorCode(): number {
    if (this.stream && this.stream.error) {
      if (this.stream.error.status === 'GrpcError') {
        return this.stream.error.grpcErrorCode;
      }
    }

    return 0;
  }

  restartStream() {
    if (this.stream) {
      this.stream.restart({
        clear: this.clearOnRestart,
      });
    }
  }

  onUpperThreshold(pageInfo: IPageInfo) {
    if (!this.stream) return;

    if (!this.isDone && !this.stream.isBackLoading && !this.backExhausted) {
      const itemCount = this.stream.length;
      const shouldLoad =
        pageInfo.startIndex <= -itemCount - 1 - -this.loadThreshold;

      if (shouldLoad) {
        this.stream.seekBack();
      }
    }
  }

  onLowerThreshold(pageInfo: IPageInfo) {
    if (!this.stream) return;

    if (
      !this.isDone &&
      !this.stream.isFrontLoading &&
      !this.frontExhausted &&
      pageInfo.endIndex > 0
    ) {
      const itemCount = this.stream.length;
      const shouldLoad =
        pageInfo.endIndex >= itemCount - 1 - this.loadThreshold;

      if (shouldLoad) {
        this.stream.seekFront();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.service || changes.rpcStream || changes.rpcControl) {
      this._initStream();
    }

    if (changes.filter) {
      this._updateFilter();
    }
  }

  private _updateFilter() {
    if (!this.stream) return;
    this.stream.updateFilter(this.filter, {
      clear: this.clearOnRestart,
    });
  }

  private _initStream() {
    if (!this.service) return;
    if (!this.rpcStream) return;
    if (!this.rpcControl) return;

    if (this._loadingSub) {
      this._loadingSub.unsubscribe();
    }

    if (this.stream) {
      this.stream.close();
    }

    this.stream = mgStreamControlObservable(
      this.service,
      this.rpcControl,
      this.rpcStream,
      this.filter,
    );
    this.stream.seekFront();

    this._loadingSub = this.stream.loading$.subscribe(loading =>
      this._loading$.next(loading),
    );

    this.items$ = this.stream.asObservable();
  }

  ngOnDestroy() {
    if (this.stream) {
      this.stream.close();
    }
  }

  isStreamEmpty(): boolean {
    if (this.stream) {
      return this.stream.length == 0 && this.stream.isDone;
    }

    return false;
  }

  unregisterStreamItem(streamItem: MgStreamScrollerItemDirective) {
    const index = this._itemDirectives.indexOf(streamItem);
    if (index !== -1) {
      this._itemDirectives.splice(index, 1);
    }
  }

  registerStreamItem(streamItem: MgStreamScrollerItemDirective) {
    this._itemDirectives.push(streamItem);
  }
}
