export enum PsTableMessages {
  COLUMN_MOBILE = 'Select / Deselect All',
  COLUMN_NAME = 'Name',
  COLUMN_STUDENT_ID = 'ID Number',
  COLUMN_SCHOOL = 'School',
  COLUMN_GRADE = 'Grade',
  COLUMN_STATUS = 'Status',
  COLUMN_EMAIL = 'Email',
  NO_CONTENT_PLACEHOLDER = 'N/A',

  START_SEARCH_TITLE = 'Start your search',
  START_SEARCH_SUBTITLE = 'Use keyword search or use precise filters',

  NO_RESULTS_TITLE = 'No results found',
  NO_RESULTS_SUBTITLE = 'No results found that match your search criteria, please try again.',
}
