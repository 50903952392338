import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'mg-overlay-nav-item',
  templateUrl: './MgOverlayNavItem.component.html',
  styleUrls: ['./MgOverlayNavItem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgOverlayNavItemComponent {
  @Input()
  icon = '';

  @HostBinding('class.mg-disabled')
  @Input()
  disabled = false;

  @Input()
  buttonType = 'button';

  @Input()
  formId = '';
}
