<div class="root-content-wrap">
  <div class="teacher-tools">
    <!-- Desktop Tools -->
    <div
      fxHide
      fxShow.gt-sm
      class="desktop-tools">
      <ng-container *ngTemplateOutlet="desktopTools"></ng-container>
    </div>
  </div>

  <!-- Mobile Tools -->
  <div
    fxHide.gt-sm
    class="mobile-tools">
    <ng-container *ngTemplateOutlet="mobileTools"></ng-container>
  </div>

  <!-- Sticker Section -->
  <section class="section container groups-section">
    <mg-tools-stickers [personHash]="authHash$ | async"></mg-tools-stickers>
  </section>

  <!-- Gorups Section -->
  <section
    class="section container groups-section"
    *ngIf="isGroupsEnabled$ | async">
    <mg-tools-groups></mg-tools-groups>
  </section>
</div>

<ng-template #mobileTools>
  <section class="section container">
    <mg-text
      variant="header-md-secondary"
      spacing="1">
      {{ MESSAGES.TITLE_TOOLS }}
    </mg-text>

    <div
      fxLayout="row wrap"
      class="tools-wrap">
      <!-- Digital ID -->
      <ng-container
        *ngIf="isDigitalIDEnabled$ | async"
        [ngTemplateOutlet]="idSection">
      </ng-container>

      <!-- Hall pass -->
      <ng-container
        *ngIf="
          hallPassOptions
            | filterHpOptions: isHallPassEnabled$
            | async as filteredHpOptions
        "
        [ngTemplateOutlet]="hallPassSection"
        [ngTemplateOutletContext]="{
          $implicit: filteredHpOptions
        }">
      </ng-container>

      <!-- Flex time -->
      <ng-container
        *ngIf="
          ((isCheckinEnabled$ | async) && canUserPerformCheckins) ||
          (isFlexTimeEnabled$ && userHasFlexTimePermissions)
        "
        [ngTemplateOutlet]="checkInSection">
      </ng-container>

      <ng-container
        *ngIf="(isFlexTimeEnabled$ | async) && userHasFlexTimePermissions"
        [ngTemplateOutlet]="flexTimeSection">
      </ng-container>

      <!-- Kiosk -->
      <ng-container
        *ngIf="isKioskEnabled$ | async"
        [ngTemplateOutlet]="kioskSection">
      </ng-container>

      <!-- Behavior -->
      <ng-container
        *ngIf="isPbisEnabled$ | async"
        [ngTemplateOutlet]="behavioursSection">
      </ng-container>
    </div>
  </section>
</ng-template>

<!-- Desktop view -->
<ng-template #desktopTools>
  <section
    class="section container no-pt"
    fxLayout="row"
    fxLayoutAlign="center center">
    <!-- Digital ID -->
    <section
      *ngIf="isDigitalIDEnabled$ | async"
      fxLayout="column"
      fxLayoutGap="16px"
      class="sub-section">
      <mg-text
        variant="header-md-secondary"
        textAlign="center">
        {{ MESSAGES.TITLE_ID_TOOLS }}
      </mg-text>

      <div
        fxLayout="row"
        fxLayoutGap="20px">
        <ng-container *ngTemplateOutlet="idSection"></ng-container>
      </div>
    </section>

    <!-- Hall Pass -->
    <ng-container
      *ngIf="
        hallPassOptions
          | filterHpOptions: isHallPassEnabled$
          | async as filteredHpOptions
      ">
      <section
        *ngIf="filteredHpOptions.length"
        fxLayout="column"
        fxLayoutGap="16px"
        class="sub-section">
        <mg-text
          variant="header-md-secondary"
          textAlign="center">
          {{ MESSAGES.TITLE_HALL_PASS_TOOLS }}
        </mg-text>
        <div
          fxLayout="row"
          fxLayoutGap="20px">
          <ng-container
            [ngTemplateOutlet]="hallPassSection"
            [ngTemplateOutletContext]="{
              $implicit: filteredHpOptions
            }">
          </ng-container>
        </div>
      </section>
    </ng-container>

    <!-- Check In -->
    <section
      *ngIf="(isCheckinEnabled$ | async) && canUserPerformCheckins"
      fxLayout="column"
      fxLayoutGap="16px"
      class="sub-section">
      <mg-text
        variant="header-md-secondary"
        textAlign="center">
        {{ MESSAGES.TITLE_CHECKIN_TOOLS }}
      </mg-text>
      <div
        fxLayout="row"
        fxLayoutGap="20px">
        <ng-container *ngTemplateOutlet="checkInSection"></ng-container>
      </div>
    </section>

    <!-- FlexTime -->
    <section
      *ngIf="(isFlexTimeEnabled$ | async) && userHasFlexTimePermissions"
      fxLayout="column"
      fxLayoutGap="16px"
      class="sub-section">
      <mg-text
        variant="header-md-secondary"
        textAlign="center">
        {{ MESSAGES.TITLE_FLEXTIME_TOOLS }}
      </mg-text>
      <div
        fxLayout="row"
        fxLayoutGap="20px">
        <ng-container *ngTemplateOutlet="flexTimeSection"></ng-container>
      </div>
    </section>

    <!-- Kiosk -->
    <section
      *ngIf="isKioskEnabled$ | async"
      fxLayout="column"
      fxLayoutGap="16px"
      class="sub-section">
      <div
        fxLayout="row"
        fxLayoutAlign="center start"
        fxLayoutGap="4px">
        <mg-text
          variant="header-md-secondary"
          textAlign="center">
          {{ MESSAGES.TITLE_KIOSK }}
        </mg-text>
        <mg-tag
          class="beta-tag"
          color="green"
          size="small">
          {{ MESSAGES.KIOSK_BETA }}
        </mg-tag>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="20px">
        <ng-container *ngTemplateOutlet="kioskSection"></ng-container>
      </div>
    </section>
  </section>

  <!-- Behavior & Rewards -->
  <ng-container
    *ngIf="
      (isPbisEnabled$ | async) && pbisOptions
        | filterPbisOptions
        | async as options
    ">
    <section
      *ngIf="options.length > 0"
      class="section container"
      fxLayout="row"
      fxLayoutAlign="center center">
      <section
        fxLayout="column"
        fxLayoutGap="16px"
        class="sub-section">
        <mg-text
          variant="header-md-secondary"
          textAlign="center">
          {{
            (areRewardsEnabled$ | async)
              ? MESSAGES.TITLE_PBIS_REWARD_TOOLS
              : MESSAGES.TITLE_PBIS_TOOLS
          }}
        </mg-text>
        <div
          fxLayout="row"
          fxLayoutGap="24px">
          <mg-tools-link
            *ngFor="let option of options"
            [analyticEventName]="'teacherToolsPbis_' + option.type"
            [label]="option.label"
            [icon]="option.icon"
            [asset]="option.asset"
            (click)="option.onClick(option)">
          </mg-tools-link>
        </div>
      </section>
    </section>
  </ng-container>
</ng-template>

<ng-template #idSection>
  <ng-container *ngFor="let option of viewIdMenuOptions">
    <mg-tools-link
      [analyticEventName]="'teacherToolsViewYourId_' + option.type"
      [label]="option.label"
      [icon]="option.icon"
      [asset]="option.asset"
      (click)="option.onClick(option)">
    </mg-tools-link>
  </ng-container>

  <ng-container
    *ngIf="
      ('ADMINISTER_STUDENT_ID' | hasPermission) ||
      ('VIEW_STUDENT_ID_DASHBOARD' | hasPermission)
    ">
    <ng-container *ngFor="let option of idMenuOptions">
      <mg-tools-link
        *ngIf="!option?.hidden$ || (option.hidden$ | async) === false"
        [analyticEventName]="'teacherToolsViewId_' + option.type"
        [label]="option.label"
        [icon]="option.icon"
        [asset]="option.asset"
        (click)="option.onClick(option)">
      </mg-tools-link>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template
  #hallPassSection
  let-filteredHpOptions>
  <ng-container *ngFor="let option of filteredHpOptions">
    <mg-tools-link
      *ngIf="!option?.hidden$ || (option.hidden$ | async) === false"
      [analyticEventName]="'teacherToolsHallpass_' + option.type"
      [label]="option.label"
      [icon]="option.icon"
      [asset]="option.asset"
      (click)="option.onClick(option)">
    </mg-tools-link>
  </ng-container>
</ng-template>

<ng-template #behavioursSection>
  <ng-container *ngFor="let option of pbisOptions | filterPbisOptions | async">
    <mg-tools-link
      [analyticEventName]="'teacherToolsPbis_' + option.type"
      [label]="option.label"
      [icon]="option.icon"
      [asset]="option.asset"
      (click)="option.onClick(option)">
    </mg-tools-link>
  </ng-container>
</ng-template>

<ng-template #checkInSection>
  <mg-tools-link
    *ngIf="(isCheckinEnabled$ | async) && canUserPerformCheckins"
    analyticEventName="teacherToolsAssignCheckIn"
    asset="'/assets/teacher-tools/check_in_img.svg'"
    [label]="MESSAGES.REASONS_CHECKIN"
    (click)="gotoCheckinForm()">
  </mg-tools-link>
</ng-template>

<ng-template #flexTimeSection>
  <mg-tools-link
    *ngIf="(isFlexTimeEnabled$ | async) && userHasFlexTimePermissions"
    analyticEventName="teacherToolsAssignFlexActivity"
    asset="'/assets/teacher-tools/flex_img.svg'"
    [label]="MESSAGES.FLEX_TIME_ASSIGN"
    (click)="goToFlexTimeActivities()">
  </mg-tools-link>
</ng-template>

<ng-template #kioskSection>
  <mg-tools-link
    *ngIf="isKioskEnabled$ | async"
    analyticEventName="teacherToolsOpenKiosk"
    [label]="MESSAGES.KIOSK_MODE"
    asset="'/assets/teacher-tools/kiosk-img.svg'"
    (click)="showKioskModal()">
  </mg-tools-link>
</ng-template>
