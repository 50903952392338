<div class="mat-calendar-header">
  <div class="mat-calendar-controls">
    <button
      mat-icon-button
      class="mat-calendar-previous-button"
      [disabled]="!previousEnabled() || calendar.weekView"
      (click)="previousClicked()"
      [attr.aria-label]="prevButtonLabel"></button>

    <button
      mat-button
      class="mat-calendar-period-button"
      [disableRipple]="true"
      [attr.aria-label]="periodButtonLabel">
      {{ periodButtonText }}
    </button>

    <button
      mat-icon-button
      class="mat-calendar-next-button"
      [disabled]="!nextEnabled() || calendar.weekView"
      (click)="nextClicked()"
      [attr.aria-label]="nextButtonLabel"></button>
  </div>
</div>
