import { ChangeDetectionStrategy, Component } from '@angular/core';

import { HpmReportsTypesService } from '../../services';

@Component({
  selector: 'mg-hpm-reports-types',
  template: ` <mg-report-table
    matSort
    [dataSourceService]="ds"
    (matSortChange)="ds.sort($event)">
    <mg-history-column
      [datasource]="ds"
      colorTheme="navy">
    </mg-history-column>
    <mg-summary-column
      [datasource]="ds"
      colorTheme="navy">
    </mg-summary-column>
  </mg-report-table>`,
  styleUrls: ['../hpm-reports.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HpmReportsTypesService],
})
export class HpmReportsTypesComponent {
  constructor(public ds: HpmReportsTypesService) {}
}
