import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';

import { ExpansionPanelDirective } from './ExpansionPanel.directive';

@NgModule({
  imports: [MatExpansionModule, FormsModule],
  declarations: [ExpansionPanelDirective],
  exports: [ExpansionPanelDirective],
})
export class MgExpansionPanelModule {}
