import { IMgStreamItem } from 'minga/app/src/app/util/stream';
import { GalleryItem, GallerySection } from 'minga/proto/gateway/gallery_pb';

import { StreamSectionWithPhotoIndex } from '../types/home-gallery.types';

export const getGlobalIndex = (
  sections: IMgStreamItem<GallerySection.AsObject>[],
  sectionIndex: number,
  photoIndex: number,
): number => {
  return sections
    .slice(0, sectionIndex)
    .reduce(
      (globalIndex, section) => (globalIndex += section.item.itemList.length),
      photoIndex,
    );
};

export const getGlobalPhotosRange = (
  sections: IMgStreamItem<GallerySection.AsObject>[],
  globalStartIndex: number,
  globalEndIndex: number,
): GalleryItem.AsObject[] => {
  const allItems: GalleryItem.AsObject[] = [];
  for (const section of sections) {
    const sectionItems = section.item.itemList;

    allItems.push(...sectionItems);

    if (allItems.length > globalEndIndex) {
      break;
    }
  }

  return allItems.slice(globalStartIndex, globalEndIndex);
};

export const findStreamSectionFromGlobalIndex = (
  sections: IMgStreamItem<GallerySection.AsObject>[],
  globalIndex: number,
): StreamSectionWithPhotoIndex | null => {
  let currentIndex = 0;
  for (const streamSection of sections) {
    currentIndex += streamSection.item.itemList.length;

    if (currentIndex > globalIndex) {
      const photoIndex = currentIndex - globalIndex;
      return { streamSection, photoIndex };
    }
  }

  return null;
};
