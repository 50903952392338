export enum ActionButtonMessage {
  ADD = 'Add post',
  CHALLENGE = 'Challenge',
  POST = 'Post',
  VIDEO = 'Video',
  PHOTO_GALLERY = 'Photo to gallery',
  EVENT = 'Event',
  ANNOUNCEMENT = 'Announcement',
  GRAPHIC = 'Full graphic',
  POLL = 'Poll',
  SMS = 'SMS',
  ALERT = 'Alert',
}
