import { Validators } from '@angular/forms';

export enum BehaviorAssignmentLabels {
  NOTE_LABEL = 'Note to student (optional)',
  DUE_DATE_LABEL = 'Due Date',
}

export enum SaHallPassFormFields {
  NOTE = 'hpNote',
}

export const hpFormGroup = {
  [SaHallPassFormFields.NOTE]: [
    '',
    [Validators.minLength(1), Validators.maxLength(180)],
  ],
};
