import { Component, OnDestroy } from '@angular/core';

import { Observable, of, ReplaySubject } from 'rxjs';

import { MembershipListType } from 'minga/domain/membershipList';
import { mingaSettingTypes } from 'minga/util';

import { LayoutService } from '@modules/layout/services';

import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';
import { KioskService } from '@shared/services/kiosk/kiosk.service';

import {
  CheckinToggleSettingMsgs,
  defaultCheckinToggleSettings,
} from './checkin-manager-settings.constants';
import { ICheckinToggleSettings } from './checkin-manager-settings.types';

@Component({
  selector: 'mg-checkin-manager-settings',
  templateUrl: './checkin-manager-settings.component.html',
  styleUrls: ['./checkin-manager-settings.component.scss'],
})
export class CheckinManagerSettingsComponent implements OnDestroy {
  /** Messages */
  MESSAGES: typeof CheckinToggleSettingMsgs = CheckinToggleSettingMsgs;
  mingaSettingTypes = mingaSettingTypes;

  /** Loading */
  public readonly isLoading$: Observable<boolean> = of(false);

  public readonly LIST_TYPES = [
    MembershipListType.CHECKIN_MANAGER,
    MembershipListType.CHECKIN_REPORT_VIEWER,
  ];

  checkinToggleSettings = defaultCheckinToggleSettings;

  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(
    public layout: LayoutService,
    public kioskPermissions: KioskPermissionsService,
    private _kioskService: KioskService,
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public async openDisclaimer(value: boolean, setting: ICheckinToggleSettings) {
    if (value && setting.settingName === mingaSettingTypes.CHECKIN_KIOSK) {
      await this._kioskService.openDisclaimer(mingaSettingTypes.CHECKIN_KIOSK);
    }
  }
}
