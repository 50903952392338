<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [overlayTitle]="MESSAGES.MODAL_TITLE">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainTemplate>
  <mg-automation-counter-reset
    type="report"
    [countFC]="countFC"
    [isLoading$]="isLoading$">
  </mg-automation-counter-reset>
</ng-template>

<!-- Footer Content Template -->
<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <mg-btn
      variant="text"
      (pressed)="close()">
      {{ MESSAGES.BUTTON_LABEL_CANCEL }}
    </mg-btn>
    <mg-btn
      variant="filled"
      [disabled]="countFC.invalid"
      (pressed)="submit()">
      {{ MESSAGES.BUTTON_LABEL_UPDATE }}
    </mg-btn>
  </footer>
</ng-template>
