<h4 class="mg-centered">Notifications</h4>

<div class="feed-items">
  <mg-paged-scroller
    #streamScroller
    [dataSource]="ds">
    <ng-template
      let-item
      let-itemId="itemId"
      #streamItemTemplate>
      <mg-notification
        [notification]="item.toObject()"
        (click)="notificationItemClick(item)"></mg-notification>
    </ng-template>
    <ng-container mg-empty-state>
      <mg-empty-state
        mg-stream-empty
        [title]="'emptyState.noNotifications' | translate">
      </mg-empty-state>
    </ng-container>
  </mg-paged-scroller>
</div>
