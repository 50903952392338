<div
  class="person-search-input"
  #searchEle>
  <ng-container *ngIf="!isPersonSelected || !showAsChip; else showNameChip">
    <mat-form-field
      mgFormField
      appearance="outline"
      floatLabel="never"
      [mgHideOptionalMarker]="true"
      [mgNoHintMargin]="true">
      <mat-label>{{ label }}</mat-label>
      <input
        matInput
        type="text"
        [formControl]="formControl"
        (blur)="onBlurEvent.emit()" />
      <mat-icon
        matSuffix
        *ngIf="!formControl.value"
        class="navsearch-maticon">
        search
      </mat-icon>
      <mg-icon
        matSuffix
        *ngIf="formControl.value"
        class="navsearch-close-icon"
        (click)="onClickClearSearch()">
        close-thin
      </mg-icon>
    </mat-form-field>
  </ng-container>
  <ng-template #showNameChip>
    <div
      fxLayout="row"
      fxAlign="start center"
      class="chip-container">
      <div class="chip-heading">{{ label }}</div>
      <mat-chip-list>
        <mat-chip
          class="chip-chiplet"
          [removable]="true"
          (removed)="onClickClearSearch()">
          {{ formControl.value }}
          <mg-icon
            matChipRemove
            class="chip-chiplet-close">
            close-active
          </mg-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </ng-template>

  <div
    *ngIf="showDropdown"
    class="person-search-input-results-container">
    <div
      *ngIf="isLoading"
      class="person-search-input-results-spinner">
      <mg-spinner
        [diameter]="20"
        [thickness]="2">
      </mg-spinner>
    </div>
    <div *ngIf="showResults">
      <mat-option
        *ngFor="let person of searchResults | slice: 0:10"
        (click)="handleOnClickPerson(person)">
        {{ person.firstName }} {{ person.lastName }}
      </mat-option>
    </div>
    <div
      *ngIf="showNoResults"
      class="person-search-input-results-noresults">
      {{ MESSAGES.NO_RESULTS }}
    </div>
  </div>
</div>
