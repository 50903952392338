<div class="mg-top-of-feed">

  <div
    class="photo-gallery-preview"
    fxLayout="row"
    ngClass.xs="no-margin"
    *ngIf="mingaSettings.isPhotoGalleryModuleEnabled() | async">
    <mg-photo-gallery-add-photo
      *hasPermission="'GALLERY_ADD_PHOTO'"
      [routerLink]="[
        '',
        { outlets: { o: ['gallery', 'add'] } }
      ]"></mg-photo-gallery-add-photo>

    <mg-photo-gallery-preview
      routerLink="/gallery"
      fxFlex="auto"
      [galleryService]="mingaGalleryService">
      <mg-photo-gallery-preview-empty
        [class.no-add-empty]="!('GALLERY_ADD_PHOTO' | hasPermission)">
        <ng-container *hasPermission="'GALLERY_ADD_PHOTO'; else noAddEmpty">
          <p class="mg-smallcopy">
            <strong> No images in your gallery yet! </strong>
          </p>
          <p class="mg-smallcopy">
            Click on the icon to the left to add the first.
          </p>
          <div class="arrow-point-left"></div>
        </ng-container>
        <ng-template #noAddEmpty>
          <p class="mg-smallcopy">
            <strong>No Images in the gallery yet!</strong>
          </p>
        </ng-template>
      </mg-photo-gallery-preview-empty>
    </mg-photo-gallery-preview>
  </div>
  <div>
    <!-- <mg-suggested-content></mg-suggested-content> -->
  </div>
</div>
