import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { map, takeUntil, takeWhile } from 'rxjs/operators';

import { GenericButtonComponent } from '@shared/components/generic';
import { MediaService } from '@shared/services/media';

import { KioskSummaryMessage } from '../../../constants';
import { KioskService } from '../../../services';

@Component({
  selector: 'mg-kiosk-summary',
  templateUrl: './kiosk-summary.component.html',
  styleUrls: ['./kiosk-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskSummaryComponent implements AfterViewInit, OnDestroy {
  // Child components

  @ContentChild(GenericButtonComponent)
  buttonComponent: GenericButtonComponent;

  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Constants

  public readonly MSG = KioskSummaryMessage;

  // State

  // private _remainingTimer$: Observable<number> = EMPTY;
  // public readonly remainingTime$ = this._remainingTimer$;
  private _internalTimerSubscription: Subscription;

  private _remainingTimeSubject = new Subject<number>();
  public readonly remainingTime$ = this._remainingTimeSubject.asObservable();

  // Inputs

  @Input() title: string;
  @Input() showSuccessIcon = true;
  @Input() cardTitle = 'Summary';
  @Input() cardIconName: string;
  @Input() cardIconColor: string;
  @Input() hasAdditionalButtons: boolean;
  @Input() set autoDone(timeInSeconds: number) {
    this._initTimer(timeInSeconds);
  }

  // Outputs

  @Output() done = new EventEmitter<void>();
  @Output() endPass = new EventEmitter<void>();

  // Events

  public focusSubject = new Subject();

  // Derived state

  // Component Constructor

  constructor(public kiosk: KioskService, public media: MediaService) {}

  // Lifecycle

  ngAfterViewInit(): void {
    // setTimout needed to make sure element is visible before we set focus
    if (this.buttonComponent) this.buttonComponent.button.nativeElement.focus();
    else setTimeout(() => this.focusSubject.next(), 0);
  }

  ngOnDestroy() {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this.focusSubject.complete();
    if (this._internalTimerSubscription)
      this._internalTimerSubscription.unsubscribe();
  }

  // Public methos

  public stopTimer() {
    if (this._internalTimerSubscription) {
      this._internalTimerSubscription.unsubscribe();
    }
  }

  // Private methods

  private _initTimer(timeInSeconds: number) {
    if (this._internalTimerSubscription)
      this._internalTimerSubscription.unsubscribe();
    this._internalTimerSubscription = timer(0, 1000)
      .pipe(
        takeUntil(this._destroyedSubject),
        map(n => timeInSeconds - n),
        takeWhile(n => n >= 0),
      )
      .subscribe(v => {
        this._remainingTimeSubject.next(v);
        if (v === 0) this.done.emit();
      });
  }
}
