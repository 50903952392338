<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="12px">
  <ng-container *ngIf="isGoogleLinked(); else googleLogInBtn">
    <div class="sso-btn sso-btn-google sso-linked">
      <div class="sso-btn-logo"></div>
      <div class="sso-btn-text">Connected</div>
    </div>
  </ng-container>
  <ng-template #googleLogInBtn>
    <div
      class="sso-btn sso-btn-google"
      (click)="signInWithGoogle()">
      <div class="sso-btn-logo"></div>
      <div class="sso-btn-text">Sign in with Google</div>
    </div>
  </ng-template>

  <ng-container *ngIf="isMicrosoftLinked(); else microsoftLogInBtn">
    <div class="sso-btn sso-btn-microsoft sso-linked">
      <div class="sso-btn-logo"></div>
      <div class="sso-btn-text">Connected</div>
    </div>
  </ng-container>
  <ng-template #microsoftLogInBtn>
    <div
      class="sso-btn sso-btn-microsoft"
      (click)="signInWithMicrosoft()">
      <div class="sso-btn-logo"></div>
      <div class="sso-btn-text">Sign in with Microsoft</div>
    </div>
  </ng-template>

  <ng-container>
    <div
      class="sso-btn sso-btn-clever"
      *ngIf="enableClever || overrideCleverConfig"
      (click)="signInWithClever()">
      <div class="sso-btn-logo"></div>
      <div class="sso-btn-text">Sign in with Clever</div>
    </div>
  </ng-container>
</div>
