import { NgModule } from '@angular/core';

import { PortalLayoutFooterComponent } from './components/portal-layout-footer.component';
import { PortalLayoutHeaderComponent } from './components/portal-layout-header.component';
import { PortalLayoutSearchComponent } from './components/portal-layout-search.component';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [
    PortalLayoutFooterComponent,
    PortalLayoutHeaderComponent,
    PortalLayoutSearchComponent,
  ],
  exports: [
    PortalLayoutFooterComponent,
    PortalLayoutHeaderComponent,
    PortalLayoutSearchComponent,
  ],
})
export class PortalLayoutModule {}
