import { Pipe, PipeTransform } from '@angular/core';

import { from } from 'rxjs';
import { filter, mergeMap, toArray } from 'rxjs/operators';

import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { TabSaPageData } from '../types';

@Pipe({ name: 'saTabPermissionCheck' })
export class SelectionAssignerTabPipe implements PipeTransform {
  constructor(
    private _permissions: PermissionsService,
    private _settings: MingaSettingsService,
  ) {}
  transform(value: TabSaPageData[]) {
    return from(value).pipe(
      mergeMap(async tab => {
        if (tab.permissions) {
          const hasPermission = this._permissions.hasPermission(
            tab.permissions,
            false,
          );
          if (!hasPermission) return null;
        }
        if (tab.requiredModule) {
          const isModuleEnabled = await this._settings.getModuleEnabled(
            tab.requiredModule,
          );
          if (!isModuleEnabled) return null;
        }

        return tab;
      }),
      filter(Boolean),
      toArray(),
    );
  }
}
