import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgSpinnerComponent } from './MgSpinner.component';

@NgModule({
  imports: [CommonModule],
  exports: [MgSpinnerComponent],
  declarations: [MgSpinnerComponent],
})
export class MgSpinnerModule {}
