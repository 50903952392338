export enum RedeemPointsModalMessages {
  MODAL_TITLE = 'Redeem Points',

  STUDENT_NAME = 'Name',
  STUDENT_ID = 'ID Number',
  STUDENT_GRADE = 'Grade',

  REWARD_SELECT_PLACEHOLDER = 'Add to cart',

  TOTAL_POINTS = 'Total:',
  AVAILABLE_POINTS = 'Available Points',
  INSUFFICIENT_POINTS_ERROR = "You don't have enough points!",

  COLUMN_NAME = 'Name',
  COLUMN_PRICE = 'Price (points)',
  COLUMN_QUANTITY = 'Quantity',
  COLUMN_TOTAL = 'Total',

  EMPTY_STATE_TITLE = 'No items added',
  EMPTY_STATE_SUBTITLE = 'Use the select dropdown to begin adding rewards',

  BUTTON_SELECT_PERSON = 'Select student',
  BUTTON_CHECKOUT = 'Checkout',
  BUTTON_REDEEM = 'Redeem',
  BUTTON_LABEL_CANCEL = 'Cancel',
}

export const REDEEM_POINTS_MODAL_DISPLAYED_COLUMNS = [
  'name',
  'price',
  'quantity',
  'total',
  'delete',
];

export enum RedeemPointsModalFormField {
  ADD_REWARD = 'addReward',
  QUANTITY = 'quantity',
}

export const REDEEM_POINTS_MODAL_FORMGROUP = {
  [RedeemPointsModalFormField.ADD_REWARD]: [
    { value: undefined, disabled: true },
    [],
  ],
};
