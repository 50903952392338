import { Injectable } from '@angular/core';

import { Notification } from 'minga/proto/gateway/notification_pb';
import { PagedScrollerDatasource } from 'src/app/components/mg-paged-scroller/PagedScrollerDatasource';
import { StreamManager } from 'src/app/minimal/services/StreamManager';
import { ContentFeedService } from 'src/app/services/ContentFeed';

@Injectable()
export class NotificationDataSource extends PagedScrollerDatasource<Notification.AsObject> {
  protected limit = 10;
  protected offset = 0;
  streamName = 'notification';

  constructor(
    private _contentFeedService: ContentFeedService,
    streamManager: StreamManager,
  ) {
    super(streamManager);
  }

  protected async _fetch() {
    if (this.reachedEnd) return;
    const newItems = await this._contentFeedService.getNotifications(
      this.limit,
      this.offset,
    );
    this.addNewItems(newItems);
  }
}
