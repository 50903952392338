import { Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular';
export class MingaErrorHandler extends SentryErrorHandler {
  constructor(
    private router: Router,
    @Inject('errorHandlerOptions') options?: ErrorHandlerOptions,
  ) {
    super(options);
  }

  public handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      console.error('Got Chunk failed message, reloading');
      document.location.reload();
    }
    super.handleError(error);
  }
}

export function createErrorHandler(
  router: Router,
  config?: ErrorHandlerOptions,
): MingaErrorHandler {
  return new MingaErrorHandler(router, config);
}
