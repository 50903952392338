<button
  class="tile"
  type="button"
  [class.selected]="selected"
  (click)="onClick()">
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="tile-container">
    <div
      fxFlex="noshrink"
      class="tile-radio"></div>
    <div class="tile-icon">
      <mg-icon
        [style.color]="tile.color"
        [icon-namespace]="tile?.iconNamespace">
        {{ tile.icon ? tile.icon : 'hallpass' }}
      </mg-icon>
    </div>
    <ng-template #assetPath>
      <mg-image
        class="tile-asset"
        [srcs]="[tile.assetPath]">
      </mg-image>
    </ng-template>
    <div fxFlex="grow">
      <mg-text
        variant="body-sm-secondary"
        textAlign="left"
        [numberOfLines]="2">
        {{ tile.name }}
      </mg-text>
    </div>
  </div>
</button>
