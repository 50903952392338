import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgStreamScrollerModule } from 'minga/app/src/app/components/MgStreamScroller';
import { MgContentFeedItemModule } from 'minga/app/src/app/elements/MgContentFeedItem';

import { SearchContentComponent } from './SearchContent.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgStreamScrollerModule,
    MgContentFeedItemModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [SearchContentComponent],
  exports: [SearchContentComponent],
})
export class SearchContentModule {}
