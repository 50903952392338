import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { MgNotificationBaseModule } from '../MgNotificationBase';

import { MgChallengeResponseNotificationElement } from './MgChallengeResponseNotification.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgNotificationBaseModule,
    MgPipesModule,
    MgIconModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgChallengeResponseNotificationElement],
  exports: [MgChallengeResponseNotificationElement],
})
export class MgChallengeResponseNotificationModule {}
