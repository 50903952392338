import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PointReward } from 'minga/domain/points';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  PM_DEFAULT_REWARD_TYPE,
  PM_REWARDS_FORMGROUP,
  PmRewardsEditFormFields,
  PmRewardsEditMessages,
} from '../../constants';
import { PmRewardsService } from '../../services';
import {
  PmRewardsEditModalData,
  PmRewardsEditModalResponse,
} from '../../types';

@Component({
  selector: 'mg-pm-rewards-edit',
  templateUrl: './pm-rewards-edit.component.html',
  styleUrls: ['./pm-rewards-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PmRewardsEditComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = PmRewardsEditMessages;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.GREEN,
  };
  public readonly FORM_FIELD = PmRewardsEditFormFields;

  /** General Observables */
  private _destroyed = new ReplaySubject<void>(1);
  private _isLoading = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading.asObservable();

  /** Form Controls */
  public readonly form = this._fb.group(PM_REWARDS_FORMGROUP);

  /** Is Editing Existing or New */
  private readonly _isNew = this.dialogData?.id === undefined;
  public readonly isNew$ = of(this._isNew);

  /** Modal Title */
  public readonly modalTitle$ = this.isNew$.pipe(
    takeUntil(this._destroyed),
    map(isNew =>
      isNew
        ? PmRewardsEditMessages.MODAL_TITLE_NEW
        : PmRewardsEditMessages.MODAL_TITLE_EXISTING,
    ),
  );

  /** Reward Type */
  private readonly _rewardType = new BehaviorSubject<PointReward>({
    ...PM_DEFAULT_REWARD_TYPE,
  });
  public readonly rewardType$ = this._rewardType.asObservable();

  /** Computed Getters */
  get canSubmit(): boolean {
    return this.form.dirty && this.form.valid;
  }

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: PmRewardsEditModalData,
    private _modalOverlay: ModalOverlayRef<
      PmRewardsEditModalData,
      PmRewardsEditModalResponse
    >,
    private _dialog: MatDialog,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _fb: FormBuilder,
    private _rewardsService: PmRewardsService,
  ) {
    if (!this._isNew) {
      this.form.addControl(
        PmRewardsEditFormFields.UUID,
        this._fb.control('', [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(10),
        ]),
      );
      this._initRewardTypeData(this.dialogData.id);
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._isLoading.complete();
  }

  public async changeImage(imagePath: any) {
    const team = this._rewardType.getValue();
    team.imagePath = imagePath;
    this._rewardType.next({ ...team });
    this.form.markAsDirty();
  }

  public async submit(): Promise<void> {
    if (!this.canSubmit) return;
    const payload = this._getPayload();
    if (this._isNew) {
      const success = await this._rewardsService.createRewardType(payload);
      if (!success) return;
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.CREATE);
    } else {
      const success = await this._rewardsService.updateRewardType(payload);
      if (!success) return;
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT);
    }
  }

  public async delete(): Promise<void> {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: this._isNew
            ? PmRewardsEditMessages.DELETE_CONFIRMATION_NEW
            : PmRewardsEditMessages.DELETE_CONFIRMATION_EXISTING,
          deleteBtn: PmRewardsEditMessages.BUTTON_LABEL_DELETE,
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || !response.confirmed || response.cancelled) return;
      if (!this._isNew) {
        const rewardType = this._rewardType.getValue();
        this._rewardsService.deleteRewardType(rewardType.id);
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.DELETE);
      } else {
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
      }
    });
  }

  private async _initRewardTypeData(id: number): Promise<void> {
    try {
      this._isLoading.next(true);
      const result = await this._rewardsService.fetchRewardType(id);
      this._rewardType.next({ ...result });
      this._setFormValues(result);
    } catch (error) {
      this._systemAlertSnackBar.error('Something went wrong.');
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
    } finally {
      this._isLoading.next(false);
    }
  }

  private _setFormValues(rewardType: PointReward) {
    for (const key in rewardType) {
      if (rewardType.hasOwnProperty(key) && this.form.controls[key]) {
        this.form.get(key).setValue(rewardType[key]);
      }
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  private _getPayload() {
    const payload = { ...this._rewardType.getValue() };
    for (const field in this.form.controls) {
      if (this.form.controls.hasOwnProperty(field)) {
        payload[field] = this.form.controls[field].value;
      }
    }
    return payload;
  }
}
