import { Injectable } from '@angular/core';

import { Observable, Subject, Subscription } from 'rxjs';

export interface IGlobalMenuOptions {
  x: number;
  y: number;
  items: { name: string; click: (e: any) => any }[];
}

@Injectable({ providedIn: 'root' })
export class RootService {
  private _pullDownSubject: Subject<void>;
  private _refreshNavConfigSubject: Subject<void>;
  private _loadingPromises: Promise<any>[] = [];
  private _onOpenGlobalMenu: any;

  constructor() {
    this._pullDownSubject = new Subject();
    this._refreshNavConfigSubject = new Subject();
  }

  onOpenGlobalMenu(handler: any) {
    if (this._onOpenGlobalMenu) return;
    this._onOpenGlobalMenu = handler;
  }

  clearOpenGlobalMenuHandle() {
    delete this._onOpenGlobalMenu;
  }

  emitPullDown() {
    this._pullDownSubject.next();
  }

  emitRefreshNavConfig() {
    this._refreshNavConfigSubject.next();
  }

  get onRefreshNavConfig() {
    return this._refreshNavConfigSubject.asObservable();
  }

  hasPullDownSubscribers() {
    return this._pullDownSubject.observers.length > 0;
  }

  get isLoading() {
    return this._loadingPromises.length > 0;
  }

  get onPullDown() {
    return this._pullDownSubject.asObservable();
  }

  /**
   * Adds promise to the loading. Will resolve when all loadings have finished.
   */
  addLoadingPromise<T>(promise: Promise<T>): Promise<T> {
    this._loadingPromises.push(promise);

    return promise
      .then(async (arg: any) => {
        // Wait for all promises, giving a chance for others to wait for us
        await Promise.all(this._loadingPromises);

        // Remove our promise.
        this._loadingPromises = this._loadingPromises.filter(
          p => p !== promise,
        );

        // Wait again for all promises incase one was added in the resolve
        await Promise.all(this._loadingPromises);

        return arg;
      })
      .catch(err => {
        // Remove our promise.
        this._loadingPromises = this._loadingPromises.filter(
          p => p !== promise,
        );

        return Promise.reject(err);
      });
  }

  /**
   * Adds an observable that will trigger a loading indicator when the
   * observable emits true.
   */
  addLoadingObservable(observable: Observable<boolean>): Subscription {
    let lastValue: boolean = false;
    let currentResolve: (() => void) | null = null;
    let currentReject: ((err: any) => void) | null = null;

    const sub = observable.subscribe(
      value => {
        if (value && !lastValue) {
          lastValue = value;
          this.addLoadingPromise(
            new Promise((resolve, reject) => {
              currentResolve = resolve;
              currentReject = reject;
            }),
          );
        } else if (currentResolve) {
          currentResolve();
          currentResolve = null;
          currentReject = null;
        }
      },
      err => {
        if (currentReject) {
          currentReject(err);
          currentResolve = null;
          currentReject = null;
        }
      },
      () => {
        currentResolve = null;
        currentReject = null;
      },
    );

    sub.add(() => {
      if (currentResolve) {
        currentResolve();
        currentResolve = null;
        currentReject = null;
      }
    });

    return sub;
  }

  async openGlobalMenu(options: IGlobalMenuOptions): Promise<any> {
    const { x, y } = options;
    const items = options.items;

    let item = await this._onOpenGlobalMenu(options);

    return item;
  }
}
