import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import * as day from 'dayjs';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { scrollIntoView } from 'minga/app/src/app/util/scroll-into-view';
import { IDateRangePreset } from 'minga/domain/dateRange/DateRangePreset';
import { RootService } from 'src/app/minimal/services/RootService';

import { CrudFormBase } from '@shared/components/crud-form-base/crud-form-base.abstract';
import { markNestedFormGroupAsDirty } from '@shared/components/crud-form-base/crud-form-base.utils';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertModalType } from '@shared/components/system-alert-modal/constants/system-alert-modal.constants';
import { SystemAlertModalService } from '@shared/components/system-alert-modal/services/system-alert-modal.service';
import { SystemAlertCloseEvents } from '@shared/components/system-alert-modal/types/system-alert-modal.types';
import { DatePresetsService } from '@shared/services/date-presets/date-presets.service';

import {
  DATE_PRESET_FORM_FIELDS,
  DATE_PRESETS_FORM,
  DatePresetMessages,
} from './mm-date-presets-edit.constants';
import {
  MmDatePresetsEditData,
  MmDatePresetsEditResponse,
} from './mm-date-presets.types';

@Component({
  selector: 'mg-mm-date-presets-edit',
  templateUrl: './mm-date-presets-edit.component.html',
  styleUrls: ['./mm-date-presets-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmDatePresetsEditComponent
  extends CrudFormBase<IDateRangePreset>
  implements OnDestroy, OnInit
{
  private _destroyed$ = new ReplaySubject<void>(1);
  public readonly form = this._fb.group(DATE_PRESETS_FORM());
  public FORM_FIELDS = DATE_PRESET_FORM_FIELDS;
  public readonly MESSAGES = DatePresetMessages;

  public readonly today = day();

  @ViewChild('formWrap', { static: false })
  formWrap?: ElementRef<HTMLElement>;
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    private _dialogData: MmDatePresetsEditData,
    private _fb: FormBuilder,
    public rootService: RootService,
    private _systemAlertModal: SystemAlertModalService,
    private _modalRef: ModalOverlayRef<
      MmDatePresetsEditResponse,
      MmDatePresetsEditData
    >,
    private _datePresetsService: DatePresetsService,
    private _cdr: ChangeDetectorRef,
  ) {
    super({
      get: async id => {
        return this._datePresetsService.fetch(id);
      },
      create: async data => {
        return this._datePresetsService.create(data as IDateRangePreset);
      },
      update: async data => {
        return this._datePresetsService.update(data as IDateRangePreset);
      },
      delete: async data => {
        await this._datePresetsService.delete(data.id);
        return data;
      },
      onSetForm: data => {
        this.form.patchValue({
          [DATE_PRESET_FORM_FIELDS.NAME]: data.name || '',
          [DATE_PRESET_FORM_FIELDS.END_DATE_IS_CURRENT_DAY]:
            data.endDateIsCurrentDay,
          [DATE_PRESET_FORM_FIELDS.DATE_RANGE]: {
            start: data.startDate ? day(data.startDate) : null,
            end: data.endDate ? day(data.endDate) : null,
          },
        });

        this._setEndDateValidators(data.endDateIsCurrentDay);

        this.form.markAsPristine();
        this.form.markAsUntouched();
      },
      onValidate: data => {
        markNestedFormGroupAsDirty(this.form);
        return this.form.valid;
      },
      onSuccess: (type, data) => {
        if (type === 'delete') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.DELETE, {
            deleted: data.id,
          });
        }

        if (type === 'create') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
            created: data,
          });
        }

        if (type === 'update') {
          this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
            updated: data,
          });
        }
      },
      onSubmit: data => {
        const { start, end } = this.form.get(
          DATE_PRESET_FORM_FIELDS.DATE_RANGE,
        ).value;

        const mapped = {
          ...data,
          name: this.form.get(DATE_PRESET_FORM_FIELDS.NAME).value,
          endDateIsCurrentDay: this.form.get(
            DATE_PRESET_FORM_FIELDS.END_DATE_IS_CURRENT_DAY,
          ).value,
          active: data.active ?? true,
          startDate: start,
          endDate: end,
        };

        return mapped;
      },
      onShowLoader: promise => rootService.addLoadingPromise(promise),
      onCancel: async () => {
        if (this.form.pristine) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }

        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: DatePresetMessages.DELETE_CONFIRM_DISCARD_TITLE,
          message: DatePresetMessages.DELETE_CONFIRM_DISCARD_DESC,
          closeBtn: DatePresetMessages.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: DatePresetMessages.DELETE_CONFIRM_DISCARD_BTN,
        });

        const response = await modalRef.afterClosed().toPromise();

        if (response?.type === SystemAlertCloseEvents.CONFIRM) {
          this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        }
      },
      onDelete: async () => {
        const modalRef = await this._systemAlertModal.open({
          modalType: SystemAlertModalType.WARNING,
          heading: DatePresetMessages.DELETE_CONFIRM_TITLE,
          message: DatePresetMessages.DELETE_CONFIRM_DESC,
          closeBtn: DatePresetMessages.DELETE_CONFIRM_CANCEL_BTN,
          confirmActionBtn: DatePresetMessages.DELETE_CONFIRM_DELETE_BTN,
        });
        const response = await modalRef.afterClosed().toPromise();
        return response?.type === SystemAlertCloseEvents.CONFIRM;
      },
      onFormStateChange: state => {
        if (state === 'invalid') {
          // scroll to top of form
          scrollIntoView(this.formWrap.nativeElement, {
            align: { top: 0 },
          });
        }
      },
    });
  }

  ngOnInit(): void {
    this.init(this._dialogData?.id);

    this._handleDateRangeChange();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _handleDateRangeChange() {
    this.form
      .get(DATE_PRESET_FORM_FIELDS.DATE_RANGE)
      .valueChanges.pipe(takeUntil(this._destroyed$))
      .subscribe(range => {
        const { start, end } = range;

        const hasRange = !!start && !!end;
        const startIsFuture = this.isStartDateInTheFuture();

        if (hasRange || startIsFuture) {
          this.setTodayAsEndDate(false);
        }
      });
  }

  public setTodayAsEndDate(endDateIsToday: boolean) {
    this.form
      .get(DATE_PRESET_FORM_FIELDS.END_DATE_IS_CURRENT_DAY)
      .setValue(endDateIsToday);
    const control = this.form
      .get(DATE_PRESET_FORM_FIELDS.DATE_RANGE)
      .get('end');

    if (endDateIsToday) {
      control.setValue(null, { emitEvent: false });
    }
    this._setEndDateValidators(endDateIsToday);
  }

  public isStartDateInTheFuture(): boolean {
    const range = this.form.get(DATE_PRESET_FORM_FIELDS.DATE_RANGE).value;
    return range?.start ? range.start.isAfter(this.today) : false;
  }

  private _setEndDateValidators(endDateIsToday: boolean) {
    const control = this.form
      .get(DATE_PRESET_FORM_FIELDS.DATE_RANGE)
      .get('end');
    control.setValidators(endDateIsToday ? [] : [Validators.required]);
    control.updateValueAndValidity({ emitEvent: false });
  }
}
