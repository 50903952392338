<mg-legacy-overlay-nav [component]="this"></mg-legacy-overlay-nav>

<mg-stream-swipe-stack
  #streamSwipeStack
  [dataSource]="responseStream">
  <mg-long-card-base
    *mgStreamSwipeStackHead
    [component]="this"
    [class]="content?.color">
    <div
      class="hero-image-wrap"
      #heroImageWrap
      *ngIf="content.featuredImage">
      <mg-image
        class="hero-image"
        [srcs]="content?.featuredImage | mgImageUrls: bannerImageSizes">
        <ng-container *ngIf="content.points && content.points > 0">
          <mg-points-banner [points]="content.points"></mg-points-banner>
        </ng-container>
      </mg-image>
    </div>

    <div
      class="group-link-container"
      (click)="groupLinkClick($event)"
      *ngIf="content.ownerGroupHash">
      <span class="group-icon"></span>
      <span class="group-link-text"
        >Posted in
        <span class="group-link">{{ (group$ | async)?.name }}</span></span
      >
    </div>

    <div class="title-duedate">
      <div class="ellipsis">
        <mg-ellipsis
          [mgContentContextMenuClick]="{
            contentContextHash: contextHash,
            overlay: true
          }"></mg-ellipsis>
      </div>
      <p
        class="title"
        [innerHTML]="content.title | mgSafeHtml"></p>
      <p class="due-date">DUE DATE: {{ getDueDate() }}</p>
    </div>

    <div class="body-content">
      <p
        class="description"
        [innerHtml]="content.body | ugcLinksContent | mgSafeHtml"></p>
    </div>

    <div
      class="action-container"
      *ngIf="hasResponded || dueDate >= currentDate">
      <div
        class="completed-subheading"
        *ngIf="hasResponded">
        <div
          class="challenge-icon"
          [ngClass]="typeStyleClass"></div>
        <p>Completed!</p>
      </div>
      <ng-container *ngIf="hasPermission">
        <div *ngIf="!hasResponded && dueDate >= currentDate">
          <p class="action-subheading">Ready to do this?</p>
          <button
            class="mg-bodycopy response-button"
            mgBubbleBtn
            [routerLink]="[
              '',
              { outlets: { o: ['challenge~answer', content?.contentHash] } }
            ]">
            <div
              class="challenge-icon"
              [ngClass]="typeStyleClass"></div>
            <p>Let's do it!</p>
          </button>
        </div>
      </ng-container>
    </div>

    <div
      class="responses-container"
      *ngIf="content.responsesSummaryList?.length">
      <div class="avatar-container">
        <hr class="action-divider" />
        <mg-avatar-cluster [items]="avatarClusterItems"></mg-avatar-cluster>
        <hr class="action-divider" />
      </div>

      <p class="people-responded">{{ peopleResponsesText }}</p>
    </div>
  </mg-long-card-base>

  <mg-challenge-response-long-card
    *mgStreamSwipeStackItem="let item"
    [content]="item"
    [showApproval]="canApprove"
    (approved)="onResponseApproved($event)"
    (declined)="onResponseDeclined($event)"
    [themeColor]="content.color">
  </mg-challenge-response-long-card>
</mg-stream-swipe-stack>

<div class="swipe-nav">
  <div
    *ngIf="!streamSwipeStack.showingBottom"
    class="swipe-nav-icon-wrap next"
    (tap)="streamSwipeStack?.next()">
    <div class="swipe-nav-icon mg-cancel-swipe-stack-tap">
      <mg-icon>right-arrow-active</mg-icon>
    </div>
  </div>

  <div
    *ngIf="!streamSwipeStack.showingTop"
    class="swipe-nav-icon-wrap previous"
    (tap)="streamSwipeStack?.previous()">
    <div class="swipe-nav-icon mg-cancel-swipe-stack-tap">
      <mg-icon>left-arrow-active</mg-icon>
    </div>
  </div>
</div>
