import { Component, HostBinding, Input } from '@angular/core';
import { getMingaAbbreviation } from 'minga/app/src/app/util/minga';
import { MingaStatusValue, MingaTypeValue } from 'minga/util';

@Component({
  selector: 'mg-minga-identity',
  templateUrl: './MingaIdentity.component.html',
  styleUrls: ['./MingaIdentity.component.scss'],
})
export class MingaIdentityComponent {
  @Input('type')
  @HostBinding('class.has-type')
  type: MingaTypeValue | null = null;

  @Input('status')
  @HostBinding('class.has-status')
  status: MingaStatusValue | null = null;

  get mingaType() {
    if (this.type) {
      return getMingaAbbreviation(this.type);
    }
    return '';
  }

  get mingaStatus() {
    if (this.status) {
      return getMingaAbbreviation(this.status);
    }
    return '';
  }
}
