<!-- Main content -->
<mg-layout-legacy-content>
  <!-- Loading spinner -->
  <div
    *ngIf="mingaProfile.isLoading$ | async"
    class="loading-container">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>
  <!-- Main content -->
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</mg-layout-legacy-content>

<!-- Main content template -->
<ng-template #mainContentTemplate>
  <div
    class="container"
    *ngIf="mingaProfile.data$ | async as minga">
    <!-- Edit button -->
    <mg-btn
      *ngIf="'MINGA_PROFILE_EDIT' | hasPermission"
      class="edit-button"
      variant="icon"
      iconSet="material"
      [icon]="(isEditing$ | async) ? 'close' : 'more_horiz'"
      (pressed)="toggleEditMode()">
    </mg-btn>
    <!-- Main details -->
    <section id="details">
      <ul class="details-list">
        <!-- Logo -->
        <li class="minga-logo">
          <mg-avatar
            [overflow]="false"
            [square]="false"
            [borderless]="true">
            <div
              class="avatar-input"
              [class.editing]="isEditing$ | async">
              <ng-container *ngIf="minga?.logoUrl as src; else noImageTemplate">
                <div
                  class="current-avatar"
                  [style.background-image]="'url(' + src + ')'"></div>
              </ng-container>
              <ng-container *ngIf="isEditing$ | async">
                <mg-file-input
                  #fileInput
                  mgIconCompact
                  [multiple]="false"
                  [customTitleText]="'Change'"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="logoInputFiles">
                </mg-file-input>
                <mg-file-upload
                  *ngIf="logoInputFiles[0]"
                  [file]="logoInputFiles[0]"
                  [formControl]="uploadFormControl">
                </mg-file-upload>
              </ng-container>
            </div>
          </mg-avatar>
          <ng-template #noImageTemplate>
            <div class="current-avatar"></div>
          </ng-template>
        </li>
        <!-- Member count -->
        <li class="members-count">
          <span>{{ minga.memberCount }}</span>
          <mg-text variant="header-lg">{{ MSG.MEMBERS }}</mg-text>
        </li>
        <!-- Details -->
        <ng-container
          [ngTemplateOutlet]="
            (isEditing$ | async)
              ? mingaEditDetailsTemplate
              : mingaDetailsTemplate
          "
          [ngTemplateOutletContext]="{ $implicit: minga }">
        </ng-container>
      </ul>
    </section>
    <!-- Contact -->
    <section id="contact">
      <ng-container
        [ngTemplateOutlet]="
          (isEditing$ | async) ? contactInfoEditTemplate : contactInfoTemplate
        "
        [ngTemplateOutletContext]="{ $implicit: minga }">
      </ng-container>
    </section>
    <!-- Files -->
    <section id="files">
      <ng-container
        [ngTemplateOutlet]="linkTemplate"
        [ngTemplateOutletContext]="{
          $implicit: 'Files',
          icon: 'mg-folder',
          routerLink: '/home/files'
        }">
      </ng-container>
    </section>
    <!-- Links -->
    <ng-container
      [ngTemplateOutlet]="
        (isEditing$ | async) ? linksEditTemplate : linksTemplate
      "
      [ngTemplateOutletContext]="{ $implicit: minga }">
    </ng-container>
  </div>
</ng-template>

<!-- Minga details template -->
<ng-template
  #mingaDetailsTemplate
  let-minga>
  <!-- Address -->
  <li class="address">
    <mg-text variant="header-xl-secondary">{{ minga.name }}</mg-text>
    <mg-text variant="header-lg">
      {{ minga.mingaAddress }}
    </mg-text>
  </li>
  <!-- Admin details -->
  <li>
    <mg-text variant="header">{{ MSG.MINGA_ADMIN }}</mg-text>
    <mg-text variant="body-sm">{{ minga.adminFullName }}</mg-text>
  </li>
  <!-- Start / end date -->
  <li>
    <mg-text variant="header">{{ MSG.SUBSCRIPTION }}</mg-text>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="8px">
      <mg-text variant="body-sm">
        {{ MSG.SUB_START }} {{ minga.startDate | date: 'longDate' }}
      </mg-text>
      <div
        *ngIf="minga.endDate as endDate"
        fxLayout="row"
        fxLayoutGap="8px">
        <mg-text variant="body-sm">|</mg-text>
        <mg-text variant="body-sm">
          {{ MSG.SUB_END }}
          {{ endDate | date: 'longDate' }}
        </mg-text>
      </div>
    </div>
  </li>
</ng-template>

<!-- Minga edit details template -->
<ng-template #mingaEditDetailsTemplate>
  <div class="edit-details-fields">
    <!-- Name field -->
    <mg-form-text-input
      [label]="MSG.MINGA_NAME_INPUT_LABEL"
      [control]="form.controls[FORM_FIELDS.NAME]">
    </mg-form-text-input>
    <!-- Address -->
    <mg-address
      [required]="true"
      [outerFormControl]="form.controls[FORM_FIELDS.ADDRESS]"
      [(ngModel)]="newAddress"
      (placeChange)="updateAddress($event)">
    </mg-address>
  </div>
  <div class="edit-details-controls">
    <mg-btn
      variant="destructive"
      (pressed)="cancelChanges()">
      {{ MSG.BUTTON_LABEL_CANCEL }}
    </mg-btn>
    <mg-btn
      variant="filled"
      [disabled]="!enableDetailsControls"
      (pressed)="updateProfile()">
      {{ MSG.BUTTON_LABEL_SAVE }}
    </mg-btn>
  </div>
</ng-template>

<!-- Contact info template -->
<ng-template
  #contactInfoTemplate
  let-minga>
  <!-- Telephone -->
  <ng-container
    *ngIf="minga.phoneNumber || minga.phone"
    [ngTemplateOutlet]="linkTemplate"
    [ngTemplateOutletContext]="{
      $implicit: minga.phoneNumber || minga.phone,
      href: 'tel:' + minga.phoneNumber || minga.phone,
      icon: 'mg-phone'
    }">
  </ng-container>
  <!-- URL -->
  <ng-container
    *ngIf="minga.websiteUrl"
    [ngTemplateOutlet]="linkTemplate"
    [ngTemplateOutletContext]="{
      $implicit: minga.websiteUrl,
      href: minga.websiteUrl,
      icon: 'mg-link'
    }">
  </ng-container>
  <!-- Email -->
  <ng-container
    *ngIf="minga.email"
    [ngTemplateOutlet]="linkTemplate"
    [ngTemplateOutletContext]="{
      $implicit: minga.email,
      href: 'mailto:' + minga.email,
      icon: 'mg-email'
    }">
  </ng-container>
</ng-template>

<!-- Contact info edit template -->
<ng-template
  #contactInfoEditTemplate
  let-minga>
  <!-- Phone -->
  <ng-container
    [ngTemplateOutlet]="buttonTemplate"
    [ngTemplateOutletContext]="
      getEditContactContext('phone', minga.phoneNumber || minga.phone)
    ">
  </ng-container>
  <!-- Website -->
  <ng-container
    [ngTemplateOutlet]="buttonTemplate"
    [ngTemplateOutletContext]="
      getEditContactContext('website', minga.websiteUrl)
    ">
  </ng-container>
  <!-- Email -->
  <ng-container
    [ngTemplateOutlet]="buttonTemplate"
    [ngTemplateOutletContext]="getEditContactContext('email', minga.email)">
  </ng-container>
</ng-template>

<!-- Links template -->
<ng-template
  #linksTemplate
  let-minga>
  <!-- Custom links -->
  <section
    *ngFor="let link of minga.links"
    class="custom-link">
    <ng-container
      [ngTemplateOutlet]="linkTemplate"
      [ngTemplateOutletContext]="{
        $implicit: link.title,
        icon: link.icon,
        href: link.url,
        iconNameSpace: 'minga.profile'
      }">
    </ng-container>
  </section>
</ng-template>

<!-- Links edit template -->
<ng-template
  #linksEditTemplate
  let-minga>
  <!-- Custom links -->
  <section
    *ngFor="let link of minga.links"
    class="custom-link">
    <ng-container
      [ngTemplateOutlet]="buttonTemplate"
      [ngTemplateOutletContext]="getCustomLinkContext(link)">
    </ng-container>
  </section>
  <!-- Add more button -->
  <div class="add-more-links">
    <mg-btn
      iconSet="minga"
      icon="mg-add"
      (pressed)="addNewLink()">
      {{ MSG.ADD_NEW_LINK_BUTTON_LABEL }}
    </mg-btn>
  </div>
</ng-template>

<!-- Button template -->
<ng-template
  #buttonTemplate
  let-label
  let-icon="icon"
  let-iconNameSpace="iconNameSpace"
  let-handler="handler">
  <button
    matRipple
    matRippleColor="rgba(0, 0, 0, .1)"
    (click)="handler ? handler() : null">
    <mat-icon aria-hidden="false">edit</mat-icon>
    <mg-icon
      [icon-namespace]="iconNameSpace"
      [iconName]="icon">
    </mg-icon>
    <span class="button-label">{{ label }}</span>
  </button>
</ng-template>

<!-- Link template -->
<ng-template
  #linkTemplate
  let-label
  let-icon="icon"
  let-href="href"
  let-routerLink="routerLink"
  let-iconNameSpace="iconNameSpace">
  <a
    *ngIf="href; else routerLinkTemplate"
    matRipple
    matRippleColor="rgba(0, 0, 0, .1)"
    [href]="href">
    <mg-icon
      [icon-namespace]="iconNameSpace"
      [iconName]="icon">
    </mg-icon>
    <span class="button-label">{{ label }}</span>
  </a>
  <ng-template #routerLinkTemplate>
    <a
      matRipple
      matRippleColor="rgba(0, 0, 0, .1)"
      [routerLink]="routerLink">
      <mg-icon [iconName]="icon"></mg-icon>
      {{ label }}
    </a>
  </ng-template>
</ng-template>
