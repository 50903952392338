<!-- Global context menu -->
<mat-menu
  #globalMatMenu="matMenu"
  class="global-menu mg-cancel-content-link">
  <ng-container *ngFor="let menuItem of globalMenuItems">
    <div
      mat-menu-item
      (click)="menuItem.click($event)">
      {{ menuItem.name }}
    </div>
  </ng-container>
</mat-menu>
<div
  #globalMenu
  class="global-trigger mg-cancel-content-link"
  [class.fixed]="overlayIsActive">
  <div
    #globalMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="globalMatMenu"></div>
</div>

<!-- Content -->
<ng-content></ng-content>
