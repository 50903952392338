import { Component } from '@angular/core';

import { ToolsService } from '@modules/tools/services';

@Component({
  selector: 'mg-tools-behaviours',
  templateUrl: './tools-behaviours.component.html',
})
export class ToolsBehavioursComponent {
  public toolType$ = this._toolsService.toolType$;
  constructor(private _toolsService: ToolsService) {}
}
