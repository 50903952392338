<mg-legacy-overlay-nav [component]="this"></mg-legacy-overlay-nav>

<div class="present-image present-image-{{ devicePixelRatio }}"></div>
<h2 class="mg-headline">Hi {{ personName }}!</h2>
<p class="mg-bodycopy">
  <strong> A new version of Minga is available!</strong> Enjoy new features,
  enhanced performance, and that warm and fuzzy sensation that only a freshly
  upgraded app brings.
</p>
<p class="mg-bodycopy">
  You don't have to update now, but it might mean some things act funny, look
  weird, or not really work great. Just an FYI.
</p>

<a
  class="update_later"
  (click)="cancel()"
  >Update Later</a
>
<mg-button-outline
  class="update_now"
  (click)="openStoreLink()"
  >Update Now</mg-button-outline
>

<div
  *ngIf="isIos"
  class="present-image app-store-image"
  (click)="openStoreLink()"></div>
<div
  *ngIf="isAndroid"
  class="present-image play-store-image"
  (click)="openStoreLink()"></div>
