import { Injectable } from '@angular/core';
import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { StatusCode } from 'minga/proto/common/legacy_pb';
import { ErrorReporter } from 'minga/proto/gateway/connect_ng_grpc_pb';
import {
  errorPriority,
  ErrorReportRequest,
  ErrorReportResponse,
} from 'minga/proto/gateway/connect_pb';

@Injectable({ providedIn: 'root' })
export class ErrorReporterService {
  constructor(
    private errorReporter: ErrorReporter,
    private analytics: AnalyticsService,
  ) {}

  async sendErrorReport(
    context: string,
    message: string,
    priority: number = 0,
  ) {
    let req: ErrorReportRequest = new ErrorReportRequest();
    let res: ErrorReportResponse = new ErrorReportResponse();
    console.log('GRPCErrorReportRequest: ', req);
    console.log('GRPCErrorReportResponse: ', res);

    this.analytics.sendException(`[${context}]: ${message}`, priority);

    req.setErrorPriority(priority);
    req.setErrorMessage(message);
    req.setErrorContext(context);

    res = await this.errorReporter.sendErrorReport(req).catch(err => {
      console.error('[ErrorReporter] sendErrorReport error', err);
      let response = new ErrorReportResponse();
      response.setStatus(StatusCode.ERROR);
      return response;
    });
    console.log('Error Response Status:', res.getStatus());
    console.log(
      'Error Response error_handler_override:',
      res.getErrorHandlerOverride(),
    );
  }
}
