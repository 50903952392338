import { createSelector, MemoizedSelector } from '@ngrx/store';

import { GroupDetailState } from '../state';

import { getGroupsState } from './feature-selector';

export const getGroupDetailsEntitiesState: MemoizedSelector<any, any> =
  createSelector(getGroupsState, state => state.groupDetails);

const selectors = GroupDetailState.entities.getSelectors(
  getGroupDetailsEntitiesState,
);

export const getAllGroupDetails = selectors.selectAll;
