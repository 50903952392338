import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import * as day from 'dayjs';
import * as duration from 'dayjs/plugin/duration';

import { ISelectRoleSetting } from 'minga/app/src/app/roles/components/MultiSelectRole';

day.extend(duration);

// 24 hours in milliseconds
const hr24 = 8.64e7;

@Component({
  selector: 'mg-content-publish-controls',
  templateUrl: './ContentPublishControls.component.html',
  styleUrls: ['./ContentPublishControls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentPublishControlsComponent {
  @Input()
  publishDate: Date | null = null;

  @Output()
  publishDateChange = new EventEmitter<Date | null>();

  @Input()
  unpublishDate: Date | null = null;

  @Output()
  unpublishDateChange = new EventEmitter<Date | null>();

  @Input()
  pinned: boolean = false;

  @Output()
  pinnedChange = new EventEmitter<boolean>();

  @Input()
  pinDays: number = 0;

  @Output()
  pinDaysChange = new EventEmitter<number>();

  @Input()
  endPinDate: Date | null = null;

  @Output()
  endPinDateChange = new EventEmitter<Date | null>();

  @Input()
  readonlyPinDays: boolean = false;

  @Input()
  stackInputs: boolean = false;

  @Input()
  enableEmailContentOption: boolean = false;

  @Input()
  emailContent: boolean = false;

  @Output()
  emailContentChange = new EventEmitter<boolean>();

  @Input()
  commentingRoles: string[] | null = null;

  @Output()
  commentingRolesChange = new EventEmitter<string[] | null>();

  @Input()
  emailContentIncludeUnregistered: boolean = false;

  @Output()
  emailContentIncludeUnregisteredChange = new EventEmitter<boolean>();

  @Input()
  emailContentRoleOptions: ISelectRoleSetting[] = [];

  @Input()
  emailContentRoles: string[] = [];

  @Output()
  emailContentRolesChange = new EventEmitter<string[]>();

  @Input()
  enableCommentingRoles: boolean = true;

  pinRemainingTime(date: Date | null) {
    if (!date) return '';

    const days = day.duration(date.getTime() - Date.now()).days() || 1;

    return `${days} ${days == 1 ? 'day' : 'days'} remaining`;
  }

  onUnpublishDateChange(unpublish: Date) {
    this.unpublishDateChange.emit(unpublish);
  }

  onPublishDateChange(publish: Date) {
    this.publishDateChange.emit(publish);
  }

  onPinnedChange(pinned: boolean) {
    if (pinned) {
      this.pinDays = this.pinDays || 1;

      this.endPinDate = new Date();
      this.endPinDate.setHours(23);
      this.endPinDate.setMinutes(59);
      this.endPinDate.setSeconds(0);
      this.endPinDate.setMilliseconds(0);

      const ms = this.endPinDate.getTime() + hr24 * this.pinDays;
      this.endPinDate = new Date(ms);

      this.pinDaysChange.emit(this.pinDays);
      this.endPinDateChange.emit(this.endPinDate);
      this.pinnedChange.emit(pinned);
    } else {
      this.endPinDate = null;
      this.endPinDateChange.emit(this.endPinDate);

      this.pinnedChange.emit(pinned);
    }
  }

  onCommentingRolesChange(roleTypes: string[]) {
    this.commentingRolesChange.emit(roleTypes);
  }
}
