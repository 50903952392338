import { NgModule } from '@angular/core';

import { CommonUIModules } from '@shared/modules';

import { MgEllipsisElement } from './MgEllipsis.element';

@NgModule({
  declarations: [MgEllipsisElement],
  exports: [MgEllipsisElement],
  imports: [
    // Common modules
    CommonUIModules,
  ],
})
export class MgEllipsisModule {}
