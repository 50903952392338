import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MgeIconModule } from 'minga/app/src/app/elements/MgeIcon';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { MgAnnouncementMiniCardElement } from './MgAnnouncementMiniCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgeIconModule,
    MgEllipsisModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgAnnouncementMiniCardElement],
  exports: [MgAnnouncementMiniCardElement],
})
export class MgAnnouncementMiniCardModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-announcement-mini-card')) {
      //       //   MgAnnouncementMiniCardElement,
      //   {injector},
      // ));
    }
  }
}
