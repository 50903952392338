import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, NEVER, ReplaySubject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { UserStorage } from 'src/app/services/UserStorage';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { BehaviorManagerService } from '../../../services';
import { AUTO_REFRESH_STATE, POLLING_INTERVAL } from '../constants';

@Injectable()
export class BmDashboardService implements OnDestroy {
  /** Subject Behaviors & Observables */
  private readonly _destroyed$ = new ReplaySubject<void>(1);
  private readonly _bmDashboardRefreshSubj = new BehaviorSubject<void>(
    undefined,
  );
  private readonly _pollStateSubj = new BehaviorSubject<boolean>(false);
  public readonly pollState$ = this._pollStateSubj.asObservable();

  constructor(
    private _bmService: BehaviorManagerService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,

    private _userStorage: UserStorage,
  ) {
    this._initPolling();
    this._bmDashboardRefreshSubj
      .asObservable()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this._bmService.fetchTypes();
        this._bmService.fetchConsequenceTypes();
      });
    this.pollState$
      .pipe(
        takeUntil(this._destroyed$),
        switchMap(isEnabled => {
          return isEnabled ? timer(0, POLLING_INTERVAL) : NEVER;
        }),
      )
      .subscribe(() => this._bmDashboardRefreshSubj.next());
  }

  ngOnDestroy(): void {
    this._bmDashboardRefreshSubj.complete();
    this._pollStateSubj.complete();

    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public refreshBmDashboard() {
    this._bmDashboardRefreshSubj.next();
  }

  public async togglePollingState() {
    const newPollingState = !this._pollStateSubj.value;
    this._systemAlertSnackBar.success(
      `Auto refresh ${newPollingState ? 'enabled' : 'disabled'}`,
    );
    this._pollStateSubj.next(newPollingState);
    await this._userStorage.setItem(AUTO_REFRESH_STATE, newPollingState);
  }

  private async _initPolling() {
    try {
      const isPollingEnabled: boolean = await this._userStorage.getItem(
        AUTO_REFRESH_STATE,
      );
      this._pollStateSubj.next(isPollingEnabled);
    } catch (e) {
      this._systemAlertSnackBar.error('failed to init auto polling ' + e);
    }
  }
}
