import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { MediaService } from '@shared/services/media';

import { KioskLayoutPortalSection } from '../../../constants';
import {
  KioskAudioService,
  KioskLayoutService,
  KioskService,
} from '../../../services';
import { KioskType } from '../../../types';

@Component({
  selector: 'mg-kiosk-type-selector',
  templateUrl: './kiosk-type-selector.component.html',
  styleUrls: ['./kiosk-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskTypeSelectorComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  // Clean up

  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Children

  @ViewChild('kioskPortalFooterRef')
  public readonly kioskPortalFooterRef: TemplateRef<unknown>;

  @Input() public kioskTypes: KioskType[] = [];

  // Inputs

  @Input() public showBackButton = true;
  @Input() public allowAudioNotifications = true;
  @Input() public title = 'Select a type';
  @Input() public subTitle?: string;
  @Input() public backLabel = 'Back';
  @Input() public submitLabel = 'Next';
  @Input() public set types(types: KioskType[]) {
    this.kioskTypes = types || [];

    const initiallySelected = this.kioskTypes.find(t => t.initiallySelected);
    if (initiallySelected) {
      this.addToSelection(initiallySelected);
    }
  }

  /** 0 means no limit, defaults to 0 */
  @Input() public maxSelection = 0;
  @Input() public emptyStateTitle = 'No types available';
  @Input() public disableSubmit = false;
  @Input() public minimumSelection = 1;
  @Input() public isLoading: boolean;
  @Input() public emptyStateSubTitle = 'Enable Kisok types from the types page';
  @Input() public reset$?: Observable<any>;

  // Outputs

  @Output() formSubmit = new EventEmitter<KioskType[]>();
  @Output() back = new EventEmitter<string>();

  // State

  private readonly _selectionModel = new SelectionModel<KioskType>(true, []);

  // Derived state

  public readonly canSubmit$ = this._selectionModel.changed.pipe(
    takeUntil(this._destroyedSubject),
    startWith([]),
    map(
      () =>
        this._selectionModel?.selected?.length >= this.minimumSelection ??
        false,
    ),
  );

  // Component constructor

  constructor(
    public kiosk: KioskService,
    public kioskLayout: KioskLayoutService,
    public kioskAudio: KioskAudioService,
    public media: MediaService,
    private _cdr: ChangeDetectorRef,
  ) {}

  // Lifecycle
  ngOnInit(): void {
    if (this.reset$) {
      this.reset$.pipe(takeUntil(this._destroyedSubject)).subscribe(() => {
        this._selectionModel.clear();
      });
    }
  }

  ngAfterViewInit() {
    this.kioskLayout.setContent(
      KioskLayoutPortalSection.FOOTER,
      this.kioskPortalFooterRef,
    );
    this._cdr.markForCheck();
  }

  ngOnDestroy() {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this.kioskLayout.clearSectionContent(KioskLayoutPortalSection.FOOTER);
  }

  // Public methods

  public addToSelection(type: KioskType) {
    if (
      this.maxSelection &&
      this._selectionModel.selected.length >= this.maxSelection
    ) {
      if (this._selectionModel.isSelected(type)) return;
      this._selectionModel.clear();
    }
    this._selectionModel.toggle(type);
  }

  public isSelected(type: KioskType) {
    return this._selectionModel.isSelected(type);
  }

  public async submit() {
    if (this.disableSubmit) return;
    this.formSubmit.emit(this._selectionModel.selected);
  }
}
