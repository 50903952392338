<ng-container *ngIf="isDisabled">
  <span
    class="number"
    matRipple
    [class.selected]="true"
    >{{ formControl.value }}</span
  >
</ng-container>

<ng-container *ngIf="!customAmount && !isDisabled">
  <span
    *ngFor="let number of availableDefaultNumbers; let index = index"
    class="number"
    matRipple
    [class.selected]="selectedIndex == index"
    (click)="selectDurationValue(index)"
    >{{ number }}</span
  >
  <mat-icon
    class="more-ellipsis-icon"
    (click)="toggleCustomAmount()"
    >more_horiz</mat-icon
  >
</ng-container>

<ng-container
  *ngIf="customAmount"
  [formGroup]="formGroupInstance">
  <mg-icon
    class="cancel-custom-icon"
    matRipple
    (click)="toggleCustomAmount()"
    >left-arrow-active</mg-icon
  >
  <mat-form-field
    appearance="standard"
    class="no-material custom-input">
    <input
      matInput
      type="number"
      name="numberInput"
      autocomplete="off"
      placeholder="Duration (mins)"
      [attr.data-analytics]="id"
      [attr.data-test]="id"
      [formControlName]="formControlIdentifier" />
  </mat-form-field>
</ng-container>
