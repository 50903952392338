<ng-container *ngIf="shouldDisplayShortCard">
  <mg-any-short-card
    [mgContentLink]="getContentLinkContext(context)"
    [mgContentContextMenu]="context"
    [context]="context"
    [content]="content"
    [pinned]="isPinned"
    [markKeywords]="markKeywords"
    (challengeClick)="onChallengeClick($event)"
    (challengeResponseClick)="onChallengeResponseClick($event)">
  </mg-any-short-card>
</ng-container>

<ng-container *ngIf="shouldDisplayMiniCard">
  <mg-any-mini-card
    [mgContentLink]="context"
    [mgContentContextMenu]="context"
    [context]="context"
    [content]="content"
    [pinned]="isPinned"
    [markKeywords]="markKeywords">
  </mg-any-mini-card>
</ng-container>
