import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MgIconModule } from 'minga/app/src/app/icon';

import { GenericModule } from '../generic';
import { SystemAlertComponent } from './system-alert.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MatSnackBarModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [SystemAlertComponent],
  exports: [SystemAlertComponent],
})
export class SystemAlertModule {}
