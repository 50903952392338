import { ManagerOverlayColorTheme } from '@shared/components/manager-overlay';

export enum ToolsRoutes {
  // Shared Routes
  ROOT = 'tools',
  MY_TOOLS = 'my-tools',
  NOTIFICATIONS = 'notifications',
  PREFERENCES = 'preferences',

  // Student Routes
  BEHAVIOURS = 'behaviours',
  POINTS = 'points',
  FLEX_TIME = 'flextime',

  // children of POINTS
  REWARDS = 'rewards',
  YOUR_POINTS = 'your-points',
  LEADERBOARD = 'leaderboard',

  // Teacher Routes
  MY_CLASS = 'my-class',
}

export type NavType =
  | 'my-tools'
  | 'hall-pass'
  | 'behaviours'
  | 'points'
  | 'notifications'
  | 'flex-time';

export enum ToolTabs {
  TOOLS = 'tools',
  NOTIFICATIONS = 'notifications',
}

export type ToolType = 'teacher' | 'student' | 'parent';

export type ToolConfig = {
  type: ToolType;
  title: string;
  theme: ManagerOverlayColorTheme;
};
