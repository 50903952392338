import { Component, Input, OnDestroy } from '@angular/core';

import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
  selector: 'mg-advanced-tooltip',
  templateUrl: './advanced-tooltip.component.html',
  styleUrls: ['./advanced-tooltip.component.scss'],
})
export class AdvancedTooltipComponent implements OnDestroy {
  /** General Subjects */
  private _destroyedSubject = new ReplaySubject<void>(1);

  /** Is Open */
  private readonly _isOpenSubject = new BehaviorSubject<boolean>(false);
  public readonly isOpen$ = this._isOpenSubject.asObservable();

  @Input() data: [string, string][] = [];

  constructor() {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._isOpenSubject.complete();
  }

  public toggle(): void {
    this._isOpenSubject.next(!this._isOpenSubject.value);
  }
}
