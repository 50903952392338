<a
  href="{{ href }}"
  target="_blank"
  [class.loading]="loading">
  <div class="content">
    <div
      *ngIf="(publisher && publisher !== title) || loading"
      class="line line1">
      <div
        *ngIf="logo"
        class="link-logo"
        [style.background-image]="'url(' + logo + ')'"></div>
      <div>{{ publisher }}</div>
    </div>

    <div
      *ngIf="title || loading"
      class="line line2">
      <div
        *ngIf="logo && !publisher"
        class="link-logo"
        [style.background-image]="'url(' + logo + ')'"></div>
      <div class="link-style">
        <span>{{ title }}</span>
        <mat-icon *ngIf="title">open_in_new</mat-icon>
      </div>
    </div>

    <div
      *ngIf="url || loading"
      class="line line3">
      <div
        *ngIf="logo && !publisher && !title"
        class="link-logo"
        [style.background-image]="'url(' + logo + ')'"></div>
      <div>{{ url }}</div>
    </div>
  </div>
</a>
