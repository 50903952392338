<mg-overlay-primary
  size="medium"
  [overlayTitle]="MODAL_TITLE">
  <ng-container *ngTemplateOutlet="mainContentTpl"></ng-container>
  <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
</mg-overlay-primary>

<ng-template #mainContentTpl>
  <ng-container *ngIf="currentUploadState == uploadState.SETUP">
    <ng-container *ngTemplateOutlet="setupTpl"></ng-container>
  </ng-container>
  <ng-container *ngIf="currentUploadState == uploadState.UPLOADING">
    <mg-spinner></mg-spinner>
  </ng-container>
  <ng-container *ngIf="currentUploadState == uploadState.SUMMARY">
    <ng-container *ngTemplateOutlet="summaryTpl"></ng-container>
  </ng-container>
</ng-template>

<ng-template #setupTpl>
  <ng-container *ngIf="(file$ | async) === null; else xlsUploaderTemplate">
    <div class="upload-content">
      <h3>{{ MODAL_SUBTITLE }}</h3>
      <mg-file-input
        #fileInput
        name="bulkPeopleFileInput"
        icon="upload-icon-o"
        compactIcon="upload-icon-o"
        [accept]="ACCEPT_TYPES.SPREADSHEET"
        customTitleText="Drag and drop file"
        customBottomText=".csv, .xls, or .xlsx"
        [showFileDetails]="false"
        [(ngModel)]="files"
        (ngModelChange)="onFileInputChange()">
      </mg-file-input>
      <h3>or</h3>
      <mg-btn
        variant="filled"
        (pressed)="fileInput.triggerInput()">
        Browse file
      </mg-btn>
      <p>
        <a
          href="https://support.minga.io/knowledge/adding-multiple-new-members-to-your-minga-from-a-list"
          >{{ CLICK_HERE }}</a
        >
        to see how to format your file
      </p>
    </div>
  </ng-container>
</ng-template>

<ng-template #xlsUploaderTemplate>
  <mg-xls-list-uploader
    #xlsUploader
    title="Import"
    [hideNavItem]="true"
    [fields]="fields"
    [file]="file$ | async"
    [resolveErrorsDialogData]="resolveErrorsDialogData"
    [onValidate]="onValidate"
    [allowDuplicateRows]="true"
    (listSubmitted)="onListSubmit($event)">
  </mg-xls-list-uploader>
</ng-template>

<ng-template #summaryTpl>
  <h2>{{ MESSAGES.UPLOAD_SUMMARY_TITLE }}</h2>
  <mg-message-box *ngIf="errorMsg">
    <mg-message-box-content>
      <div
        fxLayout="row"
        fxLayoutAlign="center center">
        <p class="error-message">
          {{ MESSAGES.FILE_PARSE_ERROR_MSG }} {{ errorMsg }}
        </p>
        <div>
          <mg-btn
            variant="filled"
            (pressed)="reset()">
            Try again
          </mg-btn>
        </div>
      </div>
    </mg-message-box-content>
  </mg-message-box>
  <ng-container *ngIf="!errorMsg">
    <mg-list-uploader-summary
      [rowsWithErrors]="errors"
      [summaryRows]="summaryInfo"
      [headers]="headers"
      (closeSummary)="modalRef.close('close')">
    </mg-list-uploader-summary>
  </ng-container>
</ng-template>

<ng-template #footerTemplate>
  <div
    *ngIf="xlsUploader?.records.length > 0"
    fxLayout="row"
    fxLayoutAlign="flex-end center">
    <mg-btn
      variant="filled"
      (pressed)="xlsUploader.triggerSubmit()">
      Import
    </mg-btn>
  </div>
</ng-template>
