import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'mg-hall-pass-time-select',
  templateUrl: './hall-pass-time-select.component.html',
  styleUrls: ['./hall-pass-time-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HallPassTimeSelectComponent implements OnInit {
  readonly DEFAULT_VALUES = [3, 5, 10];
  public availableDefaultNumbers: number[] = [...this.DEFAULT_VALUES];
  public selectedIndex = -1;
  private _customAmount = false;
  private _defaultDuration: number;

  @Input()
  formControlIdentifier: string;

  @Input()
  formGroupInstance: FormGroup;

  @Input()
  set defaultHPTypeDuration(duration: number) {
    this._handleDefaultValueInput(duration);
  }
  get defaultHPTypeDuration() {
    return this._defaultDuration;
  }

  @Input()
  isDisabled = false;

  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  public formControl: FormControl;

  @ViewChild('numberInput', { static: false })
  numberInputEl?: ElementRef;

  @HostBinding('class.custom-amount')
  get customAmount() {
    return this._customAmount;
  }

  set customAmount(isCustom: boolean) {
    this._customAmount = isCustom;
  }

  constructor() {}

  ngOnInit() {
    this.formControl = this.formGroupInstance.controls
      .hpDuration as FormControl;
  }

  selectDurationValue(index: number) {
    this.selectedIndex = index;
    this.formControl.setValue(this.availableDefaultNumbers[index]);
  }

  // Change to custom value input ui
  toggleCustomAmount() {
    this.customAmount = !this.customAmount;

    if (this.customAmount) {
      this.selectedIndex = -1;
      if (this.numberInputEl) {
        this.numberInputEl.nativeElement.focus();
      }
    }
  }

  private _handleDefaultValueInput(duration: number) {
    this._resetDefaultValueOptions(this.defaultHPTypeDuration);
    if (!duration) return;

    this._defaultDuration = duration;
    if (!this.availableDefaultNumbers.includes(duration)) {
      this.availableDefaultNumbers.push(duration);
    }

    this.availableDefaultNumbers.sort((a, b) => a - b);
    this.customAmount = false;
    const defaultValueIndex = this.availableDefaultNumbers.findIndex(
      element => element === duration,
    );
    this.selectDurationValue(defaultValueIndex);
  }

  // Remove last custom default duration value
  private _resetDefaultValueOptions(duration: number) {
    if (
      !this.DEFAULT_VALUES.includes(duration) &&
      this.availableDefaultNumbers.includes(duration)
    ) {
      const indexToRemove = this.availableDefaultNumbers.findIndex(
        element => element === duration,
      );
      this.availableDefaultNumbers.splice(indexToRemove, 1);
    }
    // reset to index and values
    this.selectedIndex = -1;
    this.formGroupInstance.controls.hpDuration.setValue(0);
  }
}
