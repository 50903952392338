import { NgModule } from '@angular/core';

import { MessageDialogModule } from 'minga/app/src/app/minimal/components/MessageDialog';
import { MingaSnackService } from './MingaSnack.service';

@NgModule({
  imports: [MessageDialogModule],
  providers: [MingaSnackService],
})
export class MingaSnackModule {}
