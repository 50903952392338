import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CommonUIModules } from '@shared/modules';

import { DirectMessageConversationCreateModule } from './components/dm-conversation-create/dm-conversation-create.module';
import { DirectMessageConversationModule } from './components/dm-conversation/dm-conversation.module';
import { DirectMessageListModule } from './components/dm-list/dm-list.module';
import { DirectMessageComponent } from './direct-message.component';
import { DIRECT_MESSAGE_ROUTES } from './direct-message.routes';

@NgModule({
  imports: [
    // Routing
    RouterModule.forChild(DIRECT_MESSAGE_ROUTES),

    // Common modules,
    CommonUIModules,

    // Minga dependencies
    DirectMessageListModule,
    DirectMessageConversationCreateModule,
    DirectMessageConversationModule,

    // External dependencies
  ],
  declarations: [DirectMessageComponent],
  exports: [RouterModule],
})
export class DirectMessageModule {}
