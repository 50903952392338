import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { MatIconModule } from '@angular/material/icon';

import { LinkDetailComponent } from './LinkDetail.component';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    CommonModule,
    MatIconModule,
  ],
  declarations: [LinkDetailComponent],
  exports: [LinkDetailComponent],
})
export class LinkDetailModule {
  constructor(injector: Injector) {
    if (!customElements.get('mg-link-detail')) {
      const linkDetailElementCtor = createCustomElement(LinkDetailComponent, {
        injector,
      });
      customElements.define('mg-link-detail', linkDetailElementCtor);
    }
  }
}
