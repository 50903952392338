<mg-overlay-primary
  size="full"
  [overlayTitle]="MESSAGES.MODAL_TITLE">
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>

  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<ng-template #mainContentTemplate>
  <div
    fxLayout="row"
    [style.height.%]="100">
    <ng-container *ngIf="currentUploadState == uploadStates.SETUP">
      <ng-container *ngTemplateOutlet="setupTpl"></ng-container>
    </ng-container>
    <ng-container *ngIf="currentUploadState == uploadStates.UPLOADING">
      <ng-container *ngTemplateOutlet="uploadingTpl"></ng-container>
    </ng-container>
    <ng-container *ngIf="currentUploadState == uploadStates.SUMMARY">
      <ng-container *ngTemplateOutlet="summaryTpl"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #setupTpl>
  <form
    class="setup-form"
    fxLayout="column"
    fxLayoutAlign="center center"
    [class.setup-form-parsing]="hasFile">
    <ng-container *ngIf="!hasFile; else xlsUploaderTemplate">
      <mg-file-input
        name="bulkPeopleFileInput"
        icon="list_file-o"
        compactIcon="list_file-o"
        accept=".xlsx, .xls, .csv, text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        friendlyName="CSV or Excel file"
        [showFileDetails]="false"
        [(ngModel)]="files"
        (ngModelChange)="onFileInputChange()">
      </mg-file-input>
    </ng-container>
    <ng-template #xlsUploaderTemplate>
      <mg-xls-list-uploader
        #xlsUploader
        title="Import People"
        [hideNavItem]="true"
        [fields]="fields"
        [onValidate]="onValidate"
        [file]="files$ | async"
        [resolveErrorsDialogData]="resolveErrorsDialogData"
        (listSubmitted)="onSubmit($event)"
        (headersChanged)="onHeadersChanged($event)"
        (firstRowHeaders)="onFirstRowHeaders($event)">
        <mg-update-people-additional-form-fields></mg-update-people-additional-form-fields>
      </mg-xls-list-uploader>
    </ng-template>
  </form>
</ng-template>

<ng-template #uploadingTpl>
  <div class="progress-container">
    <h2>{{ MESSAGES.UPLOAD_PROGRESS_TITLE }}</h2>
    <div
      class="progress-inner"
      fxLayout="column">
      <div class="progress-inner-loader"></div>
      <ng-container>
        <div class="progress-inner-text">
          <strong>
            Uploaded {{ currentCount$ | async }} of {{ totalCount }}
          </strong>
        </div>
      </ng-container>
      <ng-container>
        <div
          class="progress-bar"
          fxLayoutAlign="center center">
          <mat-progress-bar [value]="totalProgress"> </mat-progress-bar>
        </div>
      </ng-container>
      <div
        fxLayout="row"
        fxLayoutAlign="center center">
        <mg-generic-button
          colorTheme="red"
          variant="primary"
          size="medium"
          paddingSize="medium"
          (click)="onUploadCancelled()">
          Cancel Upload
        </mg-generic-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #summaryTpl>
  <div class="summary-container">
    <h2>{{ MESSAGES.UPLOAD_PROGRESS_TITLE }}</h2>
    <mg-message-box *ngIf="errorMsg">
      <mg-message-box-content>
        <div
          fxLayout="row"
          fxLayoutAlign="center center">
          <p class="error-message">
            {{ MESSAGES.FILE_PARSE_ERROR_MSG }} {{ errorMsg }}
          </p>
          <div>
            <mg-generic-button
              colorTheme="blue"
              variant="primary"
              size="medium"
              paddingSize="medium"
              (click)="reset()">
              Try again
            </mg-generic-button>
          </div>
        </div>
      </mg-message-box-content>
    </mg-message-box>
    <ng-container *ngIf="!errorMsg">
      <mg-list-uploader-summary
        [rowsWithErrors]="errors"
        [summaryRows]="summaryInfo"
        [headers]="headers"
        [showButton]="!(emailsToArchive.length > 0)"
        (closeSummary)="onOverlayClose()"
        [errorOffset]="this.firstRowHeaders ? 2 : 1"></mg-list-uploader-summary>
      <ng-container *ngIf="emailsToArchive.length > 0">
        <div class="archive-summary">
          <h3>Archive Summary</h3>
          <div
            fxLayout="row"
            fxLayoutAlign="center center">
            <div class="error-banner row">
              There were {{ emailsToArchive.length }} users that were not in the
              list and are set to be archived.
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="center center">
            <mg-generic-button
              *ngIf="!canArchive"
              colorTheme="red"
              variant="primary"
              size="medium"
              paddingSize="medium"
              (click)="downloadToBeArchived()">
              {{ MESSAGES.ARCHIVE_REVIEW_LABEL }}
            </mg-generic-button>
            <ng-container *ngIf="canArchive">
              <mg-generic-button
                colorTheme="red"
                variant="primary"
                size="medium"
                paddingSize="medium"
                (click)="archiveUsers()">
                {{ MESSAGES.ARCHIVE_USERS_LABEL }}
              </mg-generic-button>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <div
    *ngIf="xlsUploader?.records.length > 0"
    fxLayout="row"
    fxLayoutAlign="flex-end center">
    <mg-generic-button
      colorTheme="navy"
      variant="primary"
      size="medium"
      paddingSize="medium"
      (click)="xlsUploader.triggerSubmit()">
      {{ MESSAGES.IMPORT_LABEL }}
    </mg-generic-button>
  </div>
</ng-template>
