<mg-button-icon
  (click)="close()"
  class="top left"
  icon="close"></mg-button-icon>

<div
  *ngIf="matIcon || mgIcon"
  class="mg-dialog icon"
  [ngClass]="iconColorClass"
  [style.color]="iconColor"
  [style.background-color]="iconBackground">
  <mat-icon *ngIf="matIcon">{{ matIcon }}</mat-icon>
  <mg-icon *ngIf="mgIcon">{{ mgIcon }}</mg-icon>
</div>

<div
  class="mg-dialog message"
  *ngIf="translateText">
  <p class="mg-bodycopy-bold">
    {{ title | translate }}
  </p>
  <p
    class="mg-dialog-message-body"
    [innerHTML]="text | translate"></p>
</div>

<div
  class="mg-dialog message"
  *ngIf="!translateText">
  <p class="mg-bodycopy-bold">{{ title }}</p>
  <p
    [innerHTML]="text"
    class="mg-dialog-message-body"></p>
</div>
