<mg-text
  variant="header-md-secondary"
  spacing="4">
  {{ MESSAGES.SUBSCRIPTION_DETAILS_TITLE }}
</mg-text>

<!-- Subscription Details -->
<div
  *ngIf="subInfo$ | async as subInfo; else isLoadingTemplate"
  class="subscription">
  <ng-container *ngFor="let info of subInfo">
    <ng-container
      [ngTemplateOutlet]="subscriptionInfoTemplate"
      [ngTemplateOutletContext]="{ $implicit: info }">
    </ng-container>
  </ng-container>
</div>

<div class="heading-spacing">
  <mg-text
    variant="header-md-secondary"
    spacing="4">
    {{ MESSAGES.MINGA_MODULES_TITLE }}
  </mg-text>
</div>

<!-- Module Cards -->
<div
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="12px">
  <ng-container *ngFor="let info of moduleInfo$ | async">
    <ng-container
      *ngIf="mingaSettings.getModuleEnabledObs(info.moduleToggleKey) | async"
      [ngTemplateOutlet]="moduleCardTemplate"
      [ngTemplateOutletContext]="{ $implicit: info, canUpgrade: false }">
    </ng-container>
  </ng-container>
</div>

<div
  *ngIf="hasDisabledModule$ | async"
  class="heading-spacing">
  <mg-text
    variant="header-md-secondary"
    spacing="4">
    {{ MESSAGES.ADD_ON_MODULES_TITLE }}
  </mg-text>
</div>

<!-- Add On Module Cards -->
<div
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="12px">
  <ng-container *ngFor="let info of moduleInfo$ | async">
    <ng-container
      *ngIf="
        (mingaSettings.getModuleEnabledObs(info.moduleToggleKey) | async) ===
        false
      "
      [ngTemplateOutlet]="moduleCardTemplate"
      [ngTemplateOutletContext]="{ $implicit: info, canUpgrade: true }">
    </ng-container>
  </ng-container>
</div>

<!-- Subscription Detail Template -->
<ng-template
  #subscriptionInfoTemplate
  let-subscription>
  <div
    fxLayout="column"
    fxLayoutAlign="start start">
    <div class="subscription-container">
      <mg-icon class="subscription-icon">{{ subscription.icon }}</mg-icon>
      <div class="subscription-info">
        <mg-text variant="label-md">
          {{ subscription.title }}
        </mg-text>
        <div fxLayout="row">
          <mg-text
            variant="body-sm-secondary"
            fontWeight="900"
            [color]="
              subscription.type === SUB_INFO_TYPE.SMS_MESSAGES &&
              isSMSCountNearMax(subscription)
                ? 'error'
                : 'surface'
            ">
            {{ subscription.value | convertToPrettyNumber: subscription.type }}
          </mg-text>
          <ng-container *ngIf="subscription.maxValue">
            <mg-text
              variant="body-sm-secondary"
              fontWeight="900">
              &nbsp;/&nbsp;
            </mg-text>
            <mg-text
              class="subscription-max-value"
              variant="body-xs-secondary"
              color="surface-alt">
              {{
                subscription.maxValue | convertToPrettyNumber: subscription.type
              }}
            </mg-text>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Module Card Template -->
<ng-template
  #moduleCardTemplate
  let-module
  let-canUpgrade="canUpgrade">
  <mg-card class="module-card-container">
    <div
      fxLayout="column"
      fxLayoutAlign="space-between start"
      [style.width.%]="100">
      <div class="module-card-header">
        <mg-icon
          class="module-card-icon"
          [style.color]="'var(' + module.colorVar + ')'">
          {{ module.icon }}
        </mg-icon>
        <mg-text
          variant="header-sm-secondary"
          fontWeight="900">
          {{ module.title }}
        </mg-text>
      </div>
      <mg-text
        variant="body-xs-secondary"
        spacing="2">
        {{ module.description }}
      </mg-text>
      <div
        fxLayout="column"
        fxLayoutAlign="center start"
        fxLayoutGap="4px">
        <div
          *ngFor="let feature of module.features"
          class="module-card-details">
          <mg-icon>mg-checkmark</mg-icon>
          <mg-text variant="body-xs">
            {{ feature }}
          </mg-text>
        </div>
      </div>
      <div class="module-card-actions">
        <mg-btn
          variant="text"
          size="small"
          [href]="module.learnMoreLink">
          {{ MESSAGES.LEARN_MORE_LABEL }}
        </mg-btn>
        <mg-btn
          *ngIf="canUpgrade"
          size="small"
          (pressed)="openUpgradeRequestModal()">
          {{ MESSAGES.UPGRADE_LABEL }}
        </mg-btn>
      </div>
    </div>
  </mg-card>
</ng-template>

<ng-template #isLoadingTemplate>
  <mg-spinner></mg-spinner>
</ng-template>
