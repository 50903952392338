import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MingaPermission, MingaRoleType } from 'minga/util';
import { RelatedPagesMenu } from 'src/app/navigation/types';
import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import {
  BehaviorManagerMessages,
  BehaviorManagerRoutes,
} from '@modules/behavior-manager/constants';
import {
  FlexTimeManagerMessages,
  FlexTimeManagerRoutes,
} from '@modules/flex-time-manager/constants';
import {
  PointsManagerMessages,
  PointsManagerRoutes,
} from '@modules/points-manager/constants';

@Injectable({ providedIn: 'root' })
export class RelatedPagesService {
  relatedPages: RelatedPagesMenu[] = [
    {
      title: 'Minga Manager',
      path: '/minga-manager',
    },
    {
      title: 'People Manager',
      path: '/people-manager',
    },
    {
      title: 'ID Manager',
      path: '/studentid',
    },
    {
      title: 'Hall Pass Manager',
      path: '/hall-pass',
    },
    {
      title: BehaviorManagerMessages.PAGE_TITLE,
      path: '/' + BehaviorManagerRoutes.ROOT,
    },
    {
      title: 'Check In Manager',
      path: '/checkin',
    },
    {
      title: PointsManagerMessages.PAGE_TITLE,
      path: '/' + PointsManagerRoutes.ROOT,
    },
    {
      title: FlexTimeManagerMessages.PAGE_TITLE,
      path: '/' + FlexTimeManagerRoutes.ROOT,
    },
  ];
  isHallPassEnabled$: Observable<boolean>;
  isIDEnabled$: Observable<boolean>;
  isPBISEnabled$: Observable<boolean>;
  isCheckinEnabled$: Observable<boolean>;
  menu: Observable<any[]>;
  shouldHaveMenu: boolean = true;
  userRoleType: MingaRoleType;

  constructor(
    private url: LocationStrategy,
    private permissions: PermissionsService,
    private _settingService: MingaSettingsService,
  ) {
    this.isIDEnabled$ = this._settingService.isIdModuleEnabled();
    this.isHallPassEnabled$ = this._settingService.isHallPassModuleEnabled();
    this.isPBISEnabled$ = this._settingService.isPbisModuleEnabled();
    this.isCheckinEnabled$ = this._settingService.isCheckinModuleEnabled();

    this.menu = combineLatest(
      this.isIDEnabled$,
      this.isHallPassEnabled$,
      this.isPBISEnabled$,
      this.isCheckinEnabled$,
    ).pipe(
      map(
        ([isIDEnabled, isHallPassEnabled, isPBISEnabled, isCheckinEnabled]) => {
          const items: RelatedPagesMenu[] = [];
          if (this._canRoleAccessPage(MingaPermission.MINGA_ACCOUNT_MANAGE)) {
            items.push(
              this.relatedPages.find(mm => mm.title == 'Minga Manager'),
            );
          }
          if (this._canRoleAccessPage(MingaPermission.MINGA_PEOPLE_MANAGE)) {
            items.push(
              this.relatedPages.find(pm => pm.title == 'People Manager'),
            );
          }
          if (
            isIDEnabled &&
            (this._canRoleAccessPage(
              MingaPermission.VIEW_STUDENT_ID_DASHBOARD,
            ) ||
              this._canRoleAccessPage(MingaPermission.ADMINISTER_STUDENT_ID))
          ) {
            items.push(this.relatedPages.find(id => id.title == 'ID Manager'));
          }
          if (
            isHallPassEnabled &&
            this._canRoleAccessPage(MingaPermission.HALL_PASS_MANAGE)
          ) {
            items.push(
              this.relatedPages.find(hp => hp.title == 'Hall Pass Manager'),
            );
          }
          if (
            isPBISEnabled &&
            this._canRoleAccessPage(MingaPermission.PBIS_VIEW_REPORTS)
          ) {
            items.push(
              this.relatedPages.find(pbis => pbis.title == 'Behavior Manager'),
            );
          }
          if (
            isPBISEnabled &&
            this._canRoleAccessPage(MingaPermission.POINTS_VIEW_REPORTS)
          ) {
            items.push(
              this.relatedPages.find(
                points => points.title == 'Points Manager',
              ),
            );
          }
          if (
            isCheckinEnabled &&
            this._canRoleAccessPage(MingaPermission.CHECKIN_VIEW_REPORTS)
          ) {
            items.push(
              this.relatedPages.find(
                checkin => checkin.title == 'Check In Manager',
              ),
            );
          }
          /** Flex Time */
          if (this._canRoleAccessPage(MingaPermission.FLEX_TIME_MANAGE)) {
            items.push(
              this.relatedPages.find(
                page => page.title == FlexTimeManagerMessages.PAGE_TITLE,
              ),
            );
          }
          const curLocation = this.url.path().split('/')[1];
          const pages = items.filter(item => item.path != '/' + curLocation);
          this.shouldHaveMenu = pages.length > 0;
          return pages;
        },
      ),
    );
  }

  setRelatedPages() {}

  getRelatedPages() {
    return this.menu;
  }

  getShouldHaveMenu() {
    return this.shouldHaveMenu;
  }

  private _canRoleAccessPage(permission: MingaPermission): boolean {
    return this.permissions.hasPermission(permission);
  }
}
