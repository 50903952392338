import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { MembershipListType } from 'minga/domain/membershipList';
import { MingaRoleType } from 'minga/util';
import { GroupsService } from 'src/app/groups/services';
import { MgValidators } from 'src/app/input/validators';
import { RolesService } from 'src/app/roles/services';
import { GradesService } from 'src/app/services/Grades';

import { UserListCategory } from '@shared/components/user-list-filter/user-list.types';
import { CheckinService } from '@shared/services/checkin';
import { StickersService } from '@shared/services/stickers';

import { MembershipListTableLists } from '../membership-list-table';
import {
  FormRestrictTabs,
  FormRestrictionMessages,
  FormRestrictionTabs,
  FormRestrictionValue,
} from './form-restriction-input.constants';

type Option<V = any> = { value: V; label: string };

@Component({
  selector: 'mg-form-restriction-input',
  templateUrl: './form-restriction-input.component.html',
  styleUrls: ['./form-restriction-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRestrictionInputComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly MESSAGES = FormRestrictionMessages;
  public readonly FORM_RESTRICTION_TABS = FormRestrictionTabs;

  /** Form Group */
  public form: FormGroup;

  /** Extra Form Controls */
  public readonly toggleFormControl = new FormControl(false);

  /** General Observables */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Inputs */
  @Input() label: string;
  @Input() tooltip: string;
  @Input() required: boolean;
  @Input() allowMultiple: boolean;
  @Input() membershipListType: MembershipListType;
  @Input() selectableTypes: FormRestrictionTabs[] =
    $enum(FormRestrictionTabs).getValues();
  @Input()
  set control(formGroup: FormGroup | AbstractControl) {
    this.form = formGroup as FormGroup;
  }
  @Input()
  set value(value: FormRestrictionValue) {
    if (value && !this._fetchedInitialValue) {
      const { isEmpty, keys } = this._isDataEmpty(value);
      if (!isEmpty && this.form) {
        this._fetchedInitialValue = true;
        this.toggleFormControl.setValue(true);
        this.form.patchValue(value);

        this._setLists();

        const { arrayValue: tabs, singleValue: tab } =
          this._getArrayAndValue(keys);
        this.form
          .get(this.FORM_RESTRICTION_TABS.Select)
          .setValue(this.allowMultiple ? tabs : tab);

        this._selectedTabsSubject.next(this.allowMultiple ? tabs : [tab]);
      }
    }
  }
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  /** Observables */
  private _selectedTabsSubject = new BehaviorSubject<FormRestrictionTabs[]>([]);
  public readonly selectedTabs$ = this._selectedTabsSubject
    .asObservable()
    .pipe(takeUntil(this._destroyedSubject));

  public readonly roles$: Observable<Option[]> = this._rolesService.roles$.pipe(
    takeUntil(this._destroyedSubject),
    map(roles =>
      roles
        .filter(
          role =>
            ![MingaRoleType.SUPERADMIN].includes(
              role.roleType as MingaRoleType,
            ),
        )
        .map(role => ({
          value: role.roleType as MingaRoleType,
          label: role.name,
        })),
    ),
  );
  public readonly stickers$: Observable<Option[]> = this._stickersService
    .getStickersList()
    .pipe(
      takeUntil(this._destroyedSubject),
      map(stickers => {
        return (stickers || []).map(sticker => {
          return { value: sticker.id, label: sticker.name };
        });
      }),
    );
  public readonly groups$: Observable<Option[]> = this._groupService
    .getAllGroups()
    .pipe(
      takeUntil(this._destroyedSubject),
      map(({ groups }) =>
        groups.map(group => ({
          value: group.hash,
          label: group.name,
        })),
      ),
    );
  public readonly grades$: Observable<Option[]> =
    this._gradeService.grades$.pipe(
      takeUntil(this._destroyedSubject),
      map(grades =>
        grades.map(grade => ({
          value: grade,
          label: grade,
        })),
      ),
    );
  public readonly reasons$: Observable<Option[]> = this._checkinService
    .getReasonsObs(true)
    .pipe(
      takeUntil(this._destroyedSubject),
      map(reasons => {
        return reasons.map(reason => ({
          value: reason.id,
          label: reason.name,
        }));
      }),
    );

  /** Misc */
  public lists: MembershipListTableLists[] = [];
  public tabs = [];
  public tabKeys = FormRestrictionTabs;
  private _fetchedInitialValue: boolean;
  public UserListCategory = UserListCategory;

  constructor(
    private _rolesService: RolesService,
    private _stickersService: StickersService,
    private _groupService: GroupsService,
    private _gradeService: GradesService,
    private _checkinService: CheckinService,
  ) {}

  ngOnInit(): void {
    this._rolesService.fetchIfNeeded();
    this._gradeService.fetchIfNeeded();

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroyedSubject),
        debounceTime(100),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.form.updateValueAndValidity();
      });

    this.tabs = FormRestrictTabs.filter(tab =>
      this.selectableTypes.includes(tab.key),
    ).map(tab => ({
      label: tab.label,
      value: tab.key,
    }));

    if (this.required) this.toggleFormControl.setValue(true);

    this._setLists();

    this.toggleFormControl.valueChanges
      .pipe(takeUntil(this._destroyedSubject), distinctUntilChanged())
      .subscribe(value => {
        Object.values(this.form.controls).forEach(control => {
          control.clearValidators();
          control.updateValueAndValidity();
        });
        if (!value) {
          this.form.reset();
          this._destroyLists();
          this._selectedTabsSubject.next([]);
        } else {
          this.form
            .get(this.FORM_RESTRICTION_TABS.Select)
            .setValidators(MgValidators.restrictionInputValidator);
        }
      });
  }

  ngOnDestroy() {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._selectedTabsSubject.complete();
  }

  public restrictionTabChanged(
    tab: FormRestrictionTabs | FormRestrictionTabs[],
  ) {
    if (!this.allowMultiple) {
      this.form.reset();
      this._destroyLists();
      this.form.get(this.FORM_RESTRICTION_TABS.Select).setValue(tab);
    } else {
      Object.entries(this.form.controls).forEach(([key, control]) => {
        if (
          key !== this.FORM_RESTRICTION_TABS.Select &&
          !tab.includes(key as FormRestrictionTabs)
        ) {
          control.reset();
        }
      });
    }

    const { arrayValue: tabs } = this._getArrayAndValue(tab);
    Object.entries(this.form.controls).forEach(([key, control]) => {
      if (tabs.includes(key) || key === this.FORM_RESTRICTION_TABS.Select) {
        control.setValidators(MgValidators.restrictionInputValidator);
        control.updateValueAndValidity();
      } else {
        control.clearValidators();
      }
    });

    this._selectedTabsSubject.next(tabs);
    this.form.updateValueAndValidity();
  }

  public listUpdated(value, type) {
    if (type === FormRestrictionTabs.MembershipList) {
      this.form
        .get(FormRestrictionTabs.MembershipList)
        .setValue(value.memberCount > 0 ? value : null);
    }
  }

  private _setLists() {
    const membershipList = this.form.get(this.tabKeys.MembershipList).value;
    this.lists = membershipList
      ? [membershipList]
      : [
          {
            type: this.membershipListType,
            name: 'Limited To',
          },
        ];
  }

  private _destroyLists() {
    this.lists = [
      {
        type: this.membershipListType,
        name: 'Limited To',
      } as MembershipListTableLists,
    ];
  }

  private _isDataEmpty(data: FormRestrictionValue): {
    isEmpty: boolean;
    keys: FormRestrictionTabs[];
  } {
    const keys: FormRestrictionTabs[] = [];
    let isEmpty = true;

    for (const key in data) {
      if (
        data.hasOwnProperty(key) &&
        key !== this.FORM_RESTRICTION_TABS.Select
      ) {
        if (Array.isArray(data[key]) && data[key].length > 0) {
          isEmpty = false;
          keys.push(key as FormRestrictionTabs);
        } else if (
          !Array.isArray(data[key]) &&
          data[key] !== null &&
          data[key] !== undefined
        ) {
          isEmpty = false;
          keys.push(key as FormRestrictionTabs);
        }
      }
    }
    return { isEmpty, keys };
  }

  private _getArrayAndValue(value: any): {
    arrayValue: any[];
    singleValue: string;
  } {
    if (Array.isArray(value)) {
      return { arrayValue: value, singleValue: value[0] };
    } else {
      return { arrayValue: [value], singleValue: value };
    }
  }
}
