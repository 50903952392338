import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MgButtonModule } from 'minga/app/src/app/button';
import { AvatarClusterModule } from 'minga/app/src/app/components/AvatarCluster';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { PointsBannerModule } from 'minga/app/src/app/components/Points/PointsBanner';
import { StreamSwipeStackModule } from 'minga/app/src/app/components/StreamSwipeStack';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { MgeIconModule } from 'minga/app/src/app/elements/MgeIcon';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgShortCardModule } from 'minga/app/src/app/elements/MgShortCard';
import { MgChallengeResponseShortCardModule } from 'minga/app/src/app/elements/MgShortCard/MgChallengeResponseShortCard';
import { MgIconModule } from 'minga/app/src/app/icon';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { UgcLinksHighlightPipeModule } from 'minga/app/src/app/ugc-links/pipes/UgcLinksHighlight';

import { MgChallengeShortCardElement } from './MgChallengeShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgShortCardModule,
    UgcLinksHighlightPipeModule,
    MgImageModule,
    MgPipesModule,
    MgeIconModule,
    AvatarClusterModule,
    MgButtonModule,
    StreamSwipeStackModule,
    MgChallengeResponseShortCardModule,
    MgEllipsisModule,
    MgContentLinkModule,
    PermissionsModule,
    MgSpinnerModule,
    MgIconModule,
    PointsBannerModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [MgChallengeShortCardElement],
  exports: [MgChallengeShortCardElement],
})
export class MgChallengeShortCardModule {}
