<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  overlayTitle="{{ MSG.TITLE }}"
  [isLoading]="isLoading$ | async">
  <ng-container>
    <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  </ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [ngStyle.gt-md]="{ 'min-width.px': 620 }">
    <div class="stickers-container">
      <div
        fxLayout="row wrap"
        fxLayoutAlign="center"
        fxLayoutGap="15px">
        <div class="sticker-item image-uploader">
          <mg-file-input-upload
            class="sticker-image-uploader"
            accept="image/*"
            icon="camera"
            customTitleText="Upload Your Own"
            previewAspectRatio="2:1"
            [showFileDetails]="false"
            [class.selected-border]="selectedAssetPath"
            [(ngModel)]="selectedAssetPath"
            (onFileUpload)="saveUploadedAsset($event)"
            (onStatusChange)="onStatusChange($event)">
          </mg-file-input-upload>
        </div>

        <div
          class="sticker-item"
          *ngFor="let sticker of presetImageList$ | async"
          (click)="updateSelectedBanner(sticker)">
          <mg-image
            [class.selected-border]="
              sticker.getBannerHash() == selectedBannerHash
            "
            [srcs]="sticker.getImage() | mgImageUrls: _imagePreviewSizes">
          </mg-image>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Modal Footer Template -->
<ng-template #footerTemplate>
  <div
    class="overlay-footer"
    fxLayout="row"
    fxLayoutAlign="end center">
    <mg-btn
      variant="filled"
      (pressed)="submit()">
      {{ MSG.BUTTON_LABEL_SAVE }}
    </mg-btn>
  </div>
</ng-template>
