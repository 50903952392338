import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MgIconModule } from 'minga/app/src/app/icon';

import { ManagerOverlayModule } from '@shared/components/manager-overlay';

import { MingaManagerComponent } from './minga-manager.component';
import { MingaManagerService } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    ManagerOverlayModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  providers: [MingaManagerService],
  declarations: [MingaManagerComponent],
  exports: [MingaManagerComponent],
})
export class MingaManagerModule {}
