import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';

import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  take,
  takeUntil,
  timeoutWith,
} from 'rxjs/operators';

import { IMembershipList } from 'minga/domain/membershipList';

import { newTableRowGlow } from '@shared/animations/table';

import {
  HpmRestrictionsBlackoutMessages,
  HP_RESTRICTIONS_BLACKOUT_TABLE_COLUMNS,
} from '../../constants';
import { HpmRestrictionsBlackoutService } from '../../services';

@Component({
  selector: 'mg-hpm-restrictions-blackout',
  templateUrl: './hpm-restrictions-blackout.component.html',
  styleUrls: ['./hpm-restrictions-blackout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [newTableRowGlow],
})
export class HpmRestrictionsBlackoutComponent implements OnDestroy {
  /** Constants */
  public MESSAGES = HpmRestrictionsBlackoutMessages;

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);
  public readonly media$: Observable<any>;

  /** Table Data */
  private _displayedColumns$ = new BehaviorSubject<string[]>(
    HP_RESTRICTIONS_BLACKOUT_TABLE_COLUMNS,
  );
  public readonly displayedColumns$ = this._displayedColumns$.asObservable();
  public readonly tableData$: Observable<IMembershipList[]>;
  public readonly newCreatedListId$: Observable<number>;

  /** Show More */
  private _showMore$ = new BehaviorSubject<boolean>(false);
  public readonly showMore$ = this._showMore$.asObservable();

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    public hpmResBlackout: HpmRestrictionsBlackoutService,
  ) {
    this.hpmResBlackout.initService();
    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );
    this.mediaObserver
      .asObservable()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(val => this._handleDisplayedColumns(val));
    this.tableData$ = this.hpmResBlackout.schedules$.pipe(
      takeUntil(this._destroyed$),
      map(val => val.reverse()),
    );
    this.newCreatedListId$ = this.hpmResBlackout.scheduleCreated$.pipe(
      timeoutWith(750, of(undefined).pipe(take(1))),
    );

    this.hpmResBlackout.refetchAllSchedules();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._displayedColumns$.complete();
    this._destroyed$.complete();
  }

  public async toggleViewMore(): Promise<void> {
    this._showMore$.next(!this._showMore$.getValue());
  }

  private _handleDisplayedColumns(val: MediaChange[]) {
    const { mqAlias } = val[0];
    let displayedColumns = ['xs', 'sm', 'md'].includes(mqAlias)
      ? ['tablet']
      : HP_RESTRICTIONS_BLACKOUT_TABLE_COLUMNS;
    if (mqAlias === 'xs') displayedColumns = ['mobile'];
    this._displayedColumns$.next(displayedColumns);
  }
}
