import {
  MINGA_APP_COLORS,
  MINGA_GRAYSCALE,
} from '@shared/constants/mingaColors';
import { getCssSpacingUnits } from '@shared/utils';

import { ElementConfig, Variant } from './text.types';

// make sure to update the stylesheet if this changes `text.component.scss`
export const VARIANTS = [
  // Header typo styles
  'header-xxl',
  'header-xxl-secondary',
  'header-xl',
  'header-xl-secondary',
  'header-lg',
  'header-lg-secondary',
  'header-md',
  'header-md-secondary',
  'header-sm',
  'header-sm-secondary',
  'header-xs',
  'header-xs-secondary',
  'header', // alias for header-md,
  // Body typo styles
  'body-md',
  'body-md-secondary',
  'body-sm',
  'body-sm-secondary',
  'body-xs',
  'body-xs-secondary',
  'body', // alias for body-md,
  // Label typo styles (these are utility styles)
  // eg. small labels on image/text buttons etc..
  'label-md',
  'label-md-secondary',
  'label-sm',
  'label-sm-secondary',
  'label-xs',
  'label-xs-secondary',
  'label', // alias for label-md,
] as const;

export const ELEMENTS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'span',
  'label',
  'li',
] as const;

export const SPACING = [...getCssSpacingUnits(), 'default'] as const;

// TODO atm we're just allowing any app color to be used in typography which may be bit broad
const PREFINED_COLORS = MINGA_APP_COLORS.filter(c => c);

export type PredefinedColors = typeof PREFINED_COLORS[number];

const DEFAULT_COLORS = ['black', 'white'] as const;
export const COLORS = [
  ...MINGA_GRAYSCALE,
  ...DEFAULT_COLORS,
  ...MINGA_APP_COLORS,
];

const BODY_DEFAULTS: ElementConfig = {
  element: 'p',
};

const LABEL_DEFAULTS: ElementConfig = {
  element: 'span',
};

export const DEFAULTS: Record<Variant, ElementConfig> = {
  'header-xxl': {
    element: 'h1',
  },
  'header-xxl-secondary': {
    element: 'h1',
  },
  'header-xl': {
    element: 'h1',
  },
  'header-xl-secondary': {
    element: 'h1',
  },
  'header-lg': {
    element: 'h2',
  },
  'header-lg-secondary': {
    element: 'h2',
  },
  'header-md': {
    element: 'h3',
  },
  'header-md-secondary': {
    element: 'h3',
  },
  'header-sm': {
    element: 'h4',
  },
  'header-sm-secondary': {
    element: 'h4',
  },
  'header-xs': {
    element: 'h5',
  },
  'header-xs-secondary': {
    element: 'h5',
  },
  header: {
    element: 'h3',
  },
  'body-md': {
    ...BODY_DEFAULTS,
  },
  'body-md-secondary': {
    ...BODY_DEFAULTS,
  },
  body: {
    ...BODY_DEFAULTS,
  },
  'body-sm': {
    ...BODY_DEFAULTS,
  },
  'body-sm-secondary': {
    ...BODY_DEFAULTS,
  },
  'body-xs': {
    ...BODY_DEFAULTS,
  },
  'body-xs-secondary': {
    ...BODY_DEFAULTS,
  },
  'label-md': {
    ...LABEL_DEFAULTS,
  },
  'label-sm': {
    ...LABEL_DEFAULTS,
  },
  'label-xs': {
    ...LABEL_DEFAULTS,
  },
  'label-md-secondary': {
    ...LABEL_DEFAULTS,
  },
  'label-sm-secondary': {
    ...LABEL_DEFAULTS,
  },
  'label-xs-secondary': {
    ...LABEL_DEFAULTS,
  },
  label: {
    ...LABEL_DEFAULTS,
  },
};

// aliases
DEFAULTS.header = { ...DEFAULTS['header-md'] };
DEFAULTS.body = { ...DEFAULTS['body-md'] };
DEFAULTS.label = { ...DEFAULTS['label-md'] };
