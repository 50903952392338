import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TrackByFunction,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatePresetsEditService } from '@shared/components/date-presets/components/mm-date-presets-edit/mm-date-presets-edit.service';
import {
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { DatePresetsService } from '@shared/services/date-presets/date-presets.service';

import { DatePresetMessages } from './constants/mm-date-presets.constants';
import { MmDatePresetsService } from './services/mm-date-presets.service';
import { DatePresetsStore } from './store/date-presets.store';
import { ClientDatePreset } from './types/mm-date-presets.types';
import { getNameLabel } from './utils/date-presets.utils';

@Component({
  selector: 'mg-mm-date-presets',
  templateUrl: './mm-date-presets.component.html',
  styleUrls: ['./mm-date-presets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmDatePresetsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private _destroyed$ = new ReplaySubject<void>(1);
  public TABLE_COLUMNS = ['name', 'edit', 'active'];
  public readonly dataSource = new MatTableDataSource<ClientDatePreset>([]);
  private _togglesInFlight = new BehaviorSubject<Set<string>>(new Set());
  public togglesInFlight$ = this._togglesInFlight.asObservable();
  public MESSAGES = DatePresetMessages;

  constructor(
    public datePresetsStore: DatePresetsStore,
    private _systemAlertSnackbar: SystemAlertSnackBarService,
    private _cdr: ChangeDetectorRef,
    private _dateSharedService: DatePresetsService,
    private _modalRef: ModalOverlayRef,
    private _datePresetEdit: DatePresetsEditService,
  ) {}

  ngOnInit(): void {
    this.datePresetsStore.getDatePresets({
      onlyActive: false,
      revalidate: true,
    });

    this.datePresetsStore.datePresets$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(presets => {
        this.dataSource.data = presets;
        this._cdr.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public async openEditModal(id?: number) {
    const modalRef = this._datePresetEdit.openEditModal({
      id,
    });

    const response = await modalRef.afterClosed.toPromise();

    const { type, data } = response;

    let message = '';

    if (type === ModalOverlayServiceCloseEventType.SUBMIT) {
      message = DatePresetMessages.SNACKBAR_UPDATE_SUCCESS;
      this.datePresetsStore.updateDatePreset(data.updated);
    }

    if (type === ModalOverlayServiceCloseEventType.CREATE) {
      message = DatePresetMessages.SNACKBAR_CREATE_SUCCESS;
      this.datePresetsStore.addDatePreset(data.created);
    }

    if (type === ModalOverlayServiceCloseEventType.DELETE) {
      message = DatePresetMessages.SNACKBAR_DELETE_SUCCESS;
      this.datePresetsStore.removeDatePreset(data.deleted);
    }

    if (message) {
      this._systemAlertSnackbar.open({
        type: 'success',
        message,
      });
    }
  }

  public async clickToggleActive(item: ClientDatePreset): Promise<void> {
    const { active } = item;
    const id = item.id as number;
    const inFlight = this._togglesInFlight.value;
    const newState = !active;
    inFlight.add(id.toString());

    try {
      await this._dateSharedService.toggleActive(item);
      this.datePresetsStore.toggleActiveDatePreset({
        id,
        active: newState,
      });

      this._systemAlertSnackbar.open({
        type: 'default',
        message: newState
          ? DatePresetMessages.SNACKBAR_TOOGLE_ACTIVE_SUCCESS
          : DatePresetMessages.SNACKBAR_TOOGLE_DEACTIVE_SUCCESS,
      });
    } finally {
      // underlying toggle method takes care of error we just need to remove from inFlight
      inFlight.delete(id.toString());
      this._togglesInFlight.next(inFlight);
    }
  }

  public trackByFn(index, row: any): TrackByFunction<number> {
    return row.id || index;
  }

  public cancel() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }

  public getNameLabel(item: ClientDatePreset): string {
    return getNameLabel(item);
  }
}
