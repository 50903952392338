import { Pipe, PipeTransform } from '@angular/core';

import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';

@Pipe({
  name: 'studentSort',
})
export class StudentSortPipe implements PipeTransform {
  transform(
    students: PersonViewMinimal.AsObject[],
    sortByFirstName: boolean,
  ): PersonViewMinimal.AsObject[] {
    if (!students || students.length === 0) {
      return [];
    }

    const sortFn = sortByFirstName
      ? (a, b) => a.firstName.localeCompare(b.firstName)
      : (a, b) => a.lastName.localeCompare(b.lastName);

    students.sort(sortFn);
    return students;
  }
}
