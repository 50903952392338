<mg-form-grouped-select
  *ngIf="dataLoaded$ | async; else loadingTemplate"
  [id]="id"
  [value]="value"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [options]="options"
  [multiple]="multiple"
  [lazyLoad]="true"
  (selectChange)="selectChangeHandler($event)"></mg-form-grouped-select>

<ng-template #loadingTemplate>
  <mg-form-grouped-select
    [disabled]="true"
    [loading]="true"
    [placeholder]="placeholder"></mg-form-grouped-select>
</ng-template>
