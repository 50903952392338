<div class="settings">
  <!-- Membership List Settings -->
  <section
    *ngIf="'MINGA_PEOPLE_MANAGE' | hasPermission"
    class="section">
    <mg-membership-list-table
      [types]="LIST_TYPES"
      [hideHeader]="true">
    </mg-membership-list-table>
  </section>

  <!-- Enable Tools Settings -->
  <section class="section">
    <mg-text variant="header-md-secondary">
      {{ MESSAGES.SUBHEADING_FLEXTIME_TITLE }}
    </mg-text>
    <div
      fxLayout="column"
      fxLayoutGap="12px">
      <mg-setting-toggle
        [label]="MESSAGES.FTM_TEACHERS_ENABLE"
        [setting]="SETTINGS.FTM_TEACHERS_ENABLE">
      </mg-setting-toggle>
      <mg-setting-toggle
        [label]="MESSAGES.FTM_STAFF_ENABLE"
        [setting]="SETTINGS.FTM_STAFF_ENABLE">
      </mg-setting-toggle>
      <mg-setting-toggle
        *ngIf="kioskPermissions.isKioskFeatureEnabled()"
        [setting]="SETTINGS.FTM_KIOSK"
        [label]="MESSAGES.FTM_KIOSK_ENABLE"
        (valueChange)="openDisclaimer($event)">
      </mg-setting-toggle>
    </div>
  </section>
</div>
