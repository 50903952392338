import { Pipe, PipeTransform } from '@angular/core';

import { SubscriptionInfoType } from '../constants';
import { convertToPrettyNumber } from '../utils';

@Pipe({
  name: 'convertToPrettyNumber',
})
export class ConvertToPrettyNumberPipe implements PipeTransform {
  transform(value: string, type: SubscriptionInfoType): unknown {
    if (type === SubscriptionInfoType.SMS_MESSAGES)
      return convertToPrettyNumber(value);
    else return value;
  }
}
