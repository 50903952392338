<ng-template #invitationsOverlayTemplate>
  <div
    class="invitations-overlay"
    (click)="closeInner()">
    <mg-icon>close</mg-icon>
    <div class="content-wrap">
      <div class="invite-icon"></div>
      <div class="text mg-headline">
        You have {{ invitationCount || 'many' }}<br />
        Event invitation{{ invitationCount != 1 ? 's' : '' }}
      </div>
      <div
        class="button-wrap"
        (click)="gotoEventInvitesSchedule($event)">
        <mg-button class="mg-minicopy">Go To Invitations</mg-button>
      </div>
    </div>
  </div>
</ng-template>
