import { InjectionToken } from '@angular/core';
import { Group } from 'minga/app/src/app/groups/models';
import { Observable } from 'rxjs';

export interface IRouterDerivedStateGroupFetcher {
  getGroup(groupHash: string): Observable<Group>;
}

export const ROUTER_DERIVED_STATE_GROUP_FETCHER =
  new InjectionToken<IRouterDerivedStateGroupFetcher>('GroupFetcher');
