import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ElementRef,
  Optional,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PromotionalModules } from '@modules/minga-manager/components/mm-subscription/types/feature-modal.types';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { ModalOverlayServiceCloseEventType } from '../../modal-overlay.constants';
import { ModalOverlaySizes } from '../../modal-overlay.types';
import { ModalOverlayBeforeClosedSuccess } from '../../modal-overlay.types';
import { ModalOverlayRef } from '../../services';

@Component({
  selector: 'mg-overlay-primary',
  templateUrl: './modal-overlay-primary.component.html',
  styleUrls: ['./modal-overlay-primary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalOverlayPrimaryComponent implements OnDestroy, OnInit {
  @ContentChild('modalHeader', { read: TemplateRef })
  modalHeader: TemplateRef<any>;

  @ContentChild('overlayFooter', { read: TemplateRef })
  overlayFooter: TemplateRef<any>;

  @ViewChild('modalOverlayBody', { static: false })
  public modalOverlayBody: ElementRef;

  /** Responsive Helpers */
  public isOnMobile = true;
  readonly isMobileDevice: boolean =
    window.MINGA_DEVICE_ANDROID || window.MINGA_DEVICE_IOS;

  /** Subscription Cleanup Helper */
  private _destroyed$ = new ReplaySubject<void>(1);

  public readonly beforeCloseSuccess$: Observable<ModalOverlayBeforeClosedSuccess>;

  /** Inputs */
  @Input() isLoading;
  @Input() overlayTitle = '';
  @Input() skipLoadCheck: boolean;
  @Input() size: ModalOverlaySizes = 'large';
  @Input() icon: string;
  @Input() bodyNoPadding: boolean;
  @Input() disableClose = false;
  @Input() disableCloseErrorMsg: string;
  @Input() disableEscape = false;
  @Input() promotionalColor: PromotionalModules;
  @Input() promotionalIcon: string;
  @Input() betaTag: boolean;

  /** Outputs */
  @Output() closeModal = new EventEmitter<void>();

  get sizeClass(): any {
    return {
      [this.size]: true,
    };
  }
  /** Component Constructor */
  constructor(
    @Optional() private _modalOverlayRef: ModalOverlayRef,
    private _snackService: SystemAlertSnackBarService,
  ) {
    if (this._modalOverlayRef) {
      this.beforeCloseSuccess$ = this._modalOverlayRef.beforeCloseSuccess$.pipe(
        takeUntil(this._destroyed$),
      );
    }
  }

  /** Component Lifecycle: On Unmount */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnInit(): void {
    if (this._modalOverlayRef && this.disableEscape)
      this._modalOverlayRef.disableEscape();
  }

  /**
   * Handle On Click Close
   */
  public async handleOnClickClose() {
    if (this.disableClose) {
      return;
    }

    if (this.disableCloseErrorMsg) {
      this._snackService.open({
        message: this.disableCloseErrorMsg,
        type: 'error',
      });
      return;
    }

    this.closeModal.emit();

    if (this._modalOverlayRef)
      this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }
}
