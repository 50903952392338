import { MingaPeopleSearchResult } from 'minga/proto/gateway/people_pb';

export interface IPeopleManagerNavigationTab {
  title: string;
  path: string;
}

export type PersonSearchItem = MingaPeopleSearchResult.Item.AsObject;

export enum PeopleRoute {
  ROOT = 'people',
  USER_LISTS = 'user-lists',
  ROSTERING = 'rostering',
  VIEW_PROFILE = 'profile/:hash',
}
