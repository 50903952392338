import { Component, Input } from '@angular/core';

import { SpinnerSize, SpinnerVariant } from './MgSpinner.types';
import { SPINNER_SIZE_MAP } from './spinner.constants';

@Component({
  selector: 'mg-spinner',
  templateUrl: './MgSpinner.component.html',
  styleUrls: ['./MgSpinner.component.scss'],
})
export class MgSpinnerComponent {
  // Inputs

  @Input() variant: SpinnerVariant = 'rainbow';
  @Input() diameter: number;
  @Input() thickness: number;
  @Input() size: SpinnerSize = 'large';

  get computedSize() {
    const presetSize = SPINNER_SIZE_MAP[this.size];
    return {
      width: this.diameter ?? presetSize.width,
      stroke: this.thickness ?? presetSize.stroke,
    };
  }

  get spinnerClasses(): Record<string, boolean> {
    return {
      [this.variant]: true,
    };
  }
}
