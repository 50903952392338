import { Validators } from '@angular/forms';

import { setDefaultRestrictionControlValue } from '@shared/components/form-restriction-input/form-restriction-input.constants';

export enum FtmActivityTemplatesMessages {
  PAGE_TITLE_DASHBOARD = 'FlexTime Activity Templates',
  NO_ACTIVITIES = "Looks like you haven't created any activities yet",

  TABLE_NAME = 'Name',
  TABLE_TEACHER = 'Teacher',
  TABLE_LOCATION = 'Location',
  TABLE_REGISTRATION_LIMIT = 'Seats Available',
  TABLE_REGISTRATION_UNLIMITED_LABEL = 'Unlimited',
  TABLE_EDIT = 'Edit',

  CREATE_ACTIVITY = 'Create activity',

  // Form
  MODAL_ADD_TITLE = 'Create Activity',
  MODAL_EDIT_TITLE = 'Edit Activity',
  MODAL_CANCEL_BTN = 'Cancel',
  MODAL_DELETE_BTN = 'Delete',
  MODAL_DELETING_BTN = 'Deleting...',
  MODAL_CREATE_BTN = 'Create',
  MODAL_CREATING_BTN = 'Creating...',
  MODAL_UPDATE_BTN = 'Save',
  MODAL_UPDATING_BTN = 'Saving...',
  MODAL_UPLOADING_BTN = 'Uploading...',
  MODAL_RESTRICTION_TITLE = 'Activity restrictions',

  INPUT_NAME = 'Activity Name',
  INPUT_DESCRIPTION = 'Description',
  INPUT_TEACHER = 'Teacher',
  INPUT_TEACHER_ERROR = 'This field is required',
  INPUT_REGISTRATION_LIMIT = 'Seats Available',
  INPUT_REGISTRATION_HINT = 'For activities with no registration limit, use 0',
  INPUT_BANNER = 'Activity Banner',
  INPUT_STUDENTS_TO_REGISTER = 'Allow Students to Register',
  INPUT_ASSIGNED_MEMBERSHIP_LIST = 'Assign this Activity to Specific Students',
  INPUT_RESTRICTION_MEMBERSHIP_LIST = 'Restrict Who Can Register',
  INPUT_LOCATION = 'Location',
  INPUT_ACTIVITY_TYPE = 'Activity Type',

  INVALID_FORM_MESSAGE = 'Please complete the missing information(s)',

  DIALOG_CONFIRM_DELETE = 'Are you sure you want to delete this activity?',
  DIALOG_CONFIRM_DELETE_BTN = 'Delete',
  DIALOG_CONFIRM_DISCARD = 'Are you sure you want to discard this new activity?',
  DIALOG_CONFIRM_DISCARD_BTN = 'Discard',
  DIALOG_CONFIRM_UNABLE_TO_DELETE = "This template can't be deleted, it's currently assigned in an upcoming period",
  DIALOG_CONFIRM_UNABLE_TO_DELETE_BTN = 'Ok',

  SNACKBAR_CREATE = 'Successfully created new activity',
  SNACKBAR_DELETE = 'Successfully deleted activity',
  SNACKBAR_UPDATE = 'Successfully updated activity',
  SNACKBAR_CREATE_ERROR = 'There was an issue creating activity, please try again',
  SNACKBAR_DELETE_ERROR = 'There was an issue deleting activity, please try again',
  SNACKBAR_UPDATE_ERROR = 'There was an issue updating activity, please try again',
  SNACKBAR_FETCHING_ERROR = 'There was a problem fetching activities, please try again',
}

// keys for each these need to match the shape of
// data from the db in order for form to populate correctly
export enum FtmActivityTemplatesEditFormFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  TEACHER = 'createdByPerson',
  REGISTRATION_LIMIT = 'registrationLimit',
  BANNER = 'banner',
  STUDENTS_TO_REGISTER = 'allowStudentToRegister',
  RESTRICTION_MEMBERSHIP_LIST_ID = 'restrictionId',
  RESTRICTION_MEMBERSHIP_LIST = 'restriction',
  LOCATION = 'location',
  ACTIVITY_TYPE = 'typeId',
}

export const FTM_AT_FORM = {
  [FtmActivityTemplatesEditFormFields.NAME]: [
    '',
    [Validators.required, Validators.minLength(1), Validators.maxLength(70)],
  ],
  [FtmActivityTemplatesEditFormFields.DESCRIPTION]: [
    '',
    [Validators.maxLength(250)],
  ],
  [FtmActivityTemplatesEditFormFields.TEACHER]: [
    {
      name: null,
      hash: null,
    },
    [], // required validator added on runtime for admin users
  ],
  [FtmActivityTemplatesEditFormFields.REGISTRATION_LIMIT]: [
    0,
    [Validators.min(0)],
  ],
  [FtmActivityTemplatesEditFormFields.BANNER]: [
    {
      assetPath: null,
      bannerHash: null,
    },
  ],
  [FtmActivityTemplatesEditFormFields.STUDENTS_TO_REGISTER]: [''],
  // TODO flex: wire up to data coming from db for default value
  [FtmActivityTemplatesEditFormFields.RESTRICTION_MEMBERSHIP_LIST]:
    setDefaultRestrictionControlValue(),
  [FtmActivityTemplatesEditFormFields.LOCATION]: [
    '',
    [Validators.required, Validators.maxLength(250)],
  ],
  [FtmActivityTemplatesEditFormFields.ACTIVITY_TYPE]: [
    null,
    [Validators.required],
  ],
};
