import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mg-sms-message-mini-card',
  templateUrl: './MgSmsMessageMiniCard.component.html',
  styleUrls: ['./MgSmsMessageMiniCard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgSmsMessageMiniCardComponent {
  @Input()
  message: string = '';

  @Input()
  context: string = '';
}
