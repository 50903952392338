export enum ConsequenceMessages {
  NAME = 'Name, ID number or Email',
  TYPE = 'Consequence Types',
  GRADE = 'Grade',
  LOADING = 'Loading filters...',
  USER_LIST = 'User List',
}

export enum ConsequenceFields {
  ISSUED_TO = 'issuedTo',
  ISSUED_BY = 'issuedBy',
  TYPE = 'consTypes',
  GRADE = 'grades',
  USER_LIST = 'userList',
}
