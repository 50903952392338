import { TemplateRef } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import { FormInputTypes } from '@shared/components/form';
import { MingaAppColor } from '@shared/constants';

import {
  SystemAlertModalType,
  SystemAlertModalHangTime,
  SystemAlertModalIconType,
} from '../constants';

export enum SystemAlertCloseEvents {
  /**
   * Confirm btn clicked
   */
  CONFIRM = 'CONFIRM',
  /**
   * Secondary Confirm btn clicked
   */
  SECONDARY_CONFIRM = 'SECONDARY_CONFIRM',
  /**
   * Close btn clicked
   */
  CLOSE = 'CLOSE',
  AUTO_DISMISS = 'AUTO_DISMISS',
  /**
   * Close icon
   */
  CLOSE_ICON = 'CLOSE_ICON',
}

export type SystemAlertModalData = {
  heading?: string;
  modalType?: SystemAlertModalType;
  message?: string;
  subMessage?: string;
  timer?: number; // seconds
  onTimerEnd?: () => void;
  hangTime?: SystemAlertModalHangTime;
  confirmActionBtn?: string;
  secondaryConfirmActionBtn?: string;
  closeBtn?: string;
  disableClose?: boolean;
  detailedMessage?: [string, string][];
  templateRef?: TemplateRef<any>;
  closeTimeout?: number; // milliseconds
  input?: {
    placeholder: string;
    inputType?: FormInputTypes;
    validators?: ValidatorFn[];
  };
} & (
  | {
      icon?: string;
      iconColor?: MingaAppColor;
    }
  | { asset?: string }
);

export type SystemAlertModalResponse = {
  type: SystemAlertCloseEvents;
  inputValue?: string;
};

export interface SystemAlertModalIconConfig {
  iconType: SystemAlertModalIconType;
  iconSource: string;
  iconColor?: string;
}
