<div class="presets">
  <div
    class="presets-loading"
    *ngIf="datePresetsStore.isLoading$ | async">
    <mg-spinner
      [diameter]="20"
      [thickness]="2">
    </mg-spinner>
  </div>

  <div
    *ngIf="datePresetsStore.datePresets$ | async as presets"
    class="presets-list">
    <div
      *ngFor="let preset of presets"
      class="presets-item"
      [ngClass]="{
        active: activePreset?.id === preset.id
      }">
      <mg-btn
        variant="text"
        size="small"
        (pressed)="onSelectPreset(preset)"
        [id]="preset.id.toString()">
        {{ getNameLabel(preset) }}
      </mg-btn>
    </div>
  </div>

  <div
    *ngIf="
      appConfig.getValue('datePickerCustomPresetsEnabled') &&
        'DATE_RANGE_PRESETS_MANAGE' | hasPermission
    "
    class="presets-extra">
    <mg-btn
      variant="outlined"
      size="small"
      (pressed)="createPreset()"
      id="add-custom-range">
      Add custom date range
    </mg-btn>
  </div>
</div>
