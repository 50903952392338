<div
  class="mg-file-ext-box-container image-{{hasPreviewUrl(previewUrl)}}"
  [style.backgroundImage]="'url('+previewUrl+')'">
  <span class="mg-file-ext-box-text">{{getExtensionString(extension)}}</span>
  <span
    class="mg-file-ext-box-link-icon"
    *ngIf="isUrlLink(extension)">
    <i class="material-icons">open_in_new</i>
  </span>
</div>
