import {
  Component,
  Output,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  ChangeDetectorRef,
  TrackByFunction,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { get } from 'lodash';

// type ItemType = IPbisType | ExistingConsequenceType;
type ItemType = any;

@Component({
  selector: 'mg-bm-types-list',
  templateUrl: './bm-types-list.component.html',
  styleUrls: ['./bm-types-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BmTypesListComponent implements AfterViewInit {
  @Input() set items(items: ItemType[]) {
    this.dataSource.data = items;
    this._cdr.markForCheck();
  }
  @Input() fallbackIcon: {
    icon: string;
    color: string;
  };

  @Output() toggleActive: EventEmitter<{ item: ItemType; active: boolean }> =
    new EventEmitter();
  @Output() edit: EventEmitter<ItemType> = new EventEmitter();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public TABLE_COLUMNS = ['name', 'edit', 'active'];
  public readonly dataSource = new MatTableDataSource<ItemType>([]);
  constructor(private _cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.sort?.sort({
      id: 'name',
      start: 'asc',
      disableClear: false,
    });
    this._cdr.detectChanges();
  }

  public clickEdit(item: ItemType): void {
    this.edit.emit(item);
  }

  public clickToggleActive(item: ItemType, active: boolean): void {
    item.active = active;
    this.toggleActive.emit({ item, active });
  }

  public trackByFn(index, row: any): TrackByFunction<number> {
    return row.id || index;
  }
}
