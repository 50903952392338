<mat-table
  matSort
  [dataSource]="dataSource"
  [trackBy]="trackById">
  <ng-container [matColumnDef]="COLUMN_DEF.NAME">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_NAME }}
      </mg-text>
    </mat-header-cell>

    <mat-cell *matCellDef="let element">
      <mg-text
        variant="body-sm"
        [numberOfLines]="2">
        {{ element.name }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.CREATED_BY">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_CREATED_BY }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mg-text variant="body-sm">{{ element.createdBy }}</mg-text>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.TYPE">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_TYPE }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mg-text variant="body-sm">
        {{ element.reportType | reportTypeLabel }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.SCHEDULE">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_SCHEDULE }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mg-text variant="body-sm">
        {{ element.frequency | frequencyLabel }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.TIME">
    <mat-header-cell *matHeaderCellDef>
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_TIME }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mg-text variant="body-sm">{{ element.time | formatTime }}</mg-text>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.DELIVERY_METHODS">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_DELIVERY }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mg-text variant="body-sm">
        {{ element.deliveryType | deliveryTypeLabel }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.ACTIVE">
    <mat-header-cell *matHeaderCellDef>
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_ACTIVE }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container
        *ngTemplateOutlet="activeToggleTemplate; context: { element: element }">
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.EDIT">
    <mat-header-cell *matHeaderCellDef>
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_EDIT }}
      </mg-text>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container
        *ngTemplateOutlet="
          editTemplate;
          context: { element: element }
        "></ng-container>
    </mat-cell>
  </ng-container>

  <!-- used for default sorting of table but we dont want to actually render it -->
  <ng-container [matColumnDef]="COLUMN_DEF.CREATED_AT">
    <mat-header-cell
      *matHeaderCellDef
      [fxHide]="true">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      [fxHide]="true">
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.MOBILE">
    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-expansion-panel
        hideToggle
        class="mobile-panel"
        (opened)="mobileExpanded[element.id] = true"
        (closed)="mobileExpanded[element.id] = false">
        <mat-expansion-panel-header class="mobile-header">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <mg-text variant="header-md-secondary">
              {{ element.name }}
            </mg-text>
            <mg-icon
              [iconName]="
                mobileExpanded[element.id]
                  ? 'mg-toggle-up'
                  : 'mg-drop-down-arrow'
              ">
            </mg-icon>
          </div>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <mg-text
              variant="label-sm"
              color="surface-alt">
              {{ MESSAGES.COL_CREATED_BY }}
            </mg-text>
            <mg-text variant="label-sm">{{ element.createdBy }}</mg-text>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <mg-text
              variant="label-sm"
              color="surface-alt">
              {{ MESSAGES.COL_SCHEDULE }}
            </mg-text>
            <mg-text variant="label-sm">
              {{ element.frequency | frequencyLabel }}
            </mg-text>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <mg-text
              variant="label-sm"
              color="surface-alt">
              {{ MESSAGES.COL_TIME }}
            </mg-text>
            <mg-text variant="label-sm">
              {{ element.time | formatTime }}
            </mg-text>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center">
            <mg-text
              variant="label-sm"
              color="surface-alt">
              {{ MESSAGES.COL_DELIVERY }}
            </mg-text>
            <mg-text variant="label-sm">
              <span class="capitalize">
                {{ element.deliveryType.join(', ') }}
              </span>
            </mg-text>
          </div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <ng-container
            *ngTemplateOutlet="editTemplate; context: { element: element }">
          </ng-container>
          <ng-container
            *ngTemplateOutlet="
              activeToggleTemplate;
              context: { element: element }
            ">
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns$ | async"
    [fxHide]="!dataSource?.data.length"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns$ | async"></mat-row>
</mat-table>

<div
  class="paginator"
  [fxHide]="(isLoading$ | async) === true || !dataSource?.data.length">
  <mg-paginator></mg-paginator>
</div>

<div
  *ngIf="isLoading$ | async"
  class="loading-container">
  <mg-spinner
    [diameter]="40"
    [thickness]="3">
  </mg-spinner>
</div>

<ng-container
  *ngIf="(isLoading$ | async) === false && !dataSource?.data.length">
  <mg-empty-state
    [title]="MESSAGES.NO_RESULTS_TITLE"
    [subtitle]="MESSAGES.NO_RESULTS_DESC">
  </mg-empty-state>
</ng-container>

<ng-template
  #editTemplate
  let-element="element">
  <mg-btn
    icon="mg-edit"
    variant="icon"
    iconSet="minga"
    (pressed)="onEditClick(element.id)">
  </mg-btn>
</ng-template>

<ng-template
  #activeToggleTemplate
  let-element="element">
  <div class="active-toggle">
    <mg-form-toggle
      [value]="element.active"
      [disabled]="(togglesInFlight$ | async).has(element.id)"
      (valueChange)="onActiveToggle($event, element.id)"></mg-form-toggle>
    <div
      class="active-toggle-spinner"
      *ngIf="(togglesInFlight$ | async).has(element.id)">
      <mg-spinner
        [diameter]="20"
        [thickness]="2">
      </mg-spinner>
    </div>
  </div>
</ng-template>
