<div class="mat-toolbar-container">
  <mat-toolbar
    [fxLayout]="direction"
    [ngClass]="[
      'padSafeArea-' + padSafeArea,
      'direction-' + direction,
      'size-' + size
    ]">
    <ng-content
      select="ng-container,[mg-nav-item]"
      [fxLayout]="direction"></ng-content>
  </mat-toolbar>
</div>
