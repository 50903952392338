import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IListUploadRowError,
  IListUploadValidationResponse,
  ListUploaderRow,
} from '@shared/components/XlsListUploader';

import {
  PS_FILE_UPLOAD_FIELDS,
  PS_FORM_FILTER_DEFAULT_STATE,
} from '../constants';
import { PsFilterState } from '../types';

export class PsFormFilter {
  /** Constants */
  public readonly UPLOAD_FIELDS = PS_FILE_UPLOAD_FIELDS;

  /** Filters */
  private readonly _stateSubj = new BehaviorSubject<PsFilterState>({
    ...PS_FORM_FILTER_DEFAULT_STATE,
  });
  public readonly state$ = this._stateSubj.asObservable();

  /** File  */
  private readonly _fileSubj = new BehaviorSubject<File | null>(null);
  public readonly file$ = this._fileSubj.asObservable();
  public readonly hasLoadedFile$ = this.file$.pipe(map(file => !!file));

  /** Class Constructor */
  constructor() {}

  public getCurrentState() {
    return this._stateSubj.getValue();
  }

  public set(field: keyof PsFilterState, value: PsFilterState[typeof field]) {
    const filters = { ...this._stateSubj.getValue() };
    filters[`${field as any}`] = value;
    this._stateSubj.next({ ...filters });
  }

  public setFile(file: File) {
    if (file) this._fileSubj.next(file);
    const validate = (
      records: ListUploaderRow[],
    ): IListUploadValidationResponse => {
      const rowErrors: IListUploadRowError[] = [];
      for (let index = 0; index < records.length; index++) {
        const record = records[index];
        const [email, studentId, name, firstName, lastName] =
          this.UPLOAD_FIELDS.map(field => field.getValue(record));
        if (!email && !studentId && !name && !(firstName && lastName)) {
          rowErrors.push({
            recordIndex: index,
            errors: [
              'Missing an email, studentId, name or first name and last name field',
            ],
          });
        }
      }
      return { formatErrors: [], rowErrors };
    };
    return {
      fields: this.UPLOAD_FIELDS,
      file,
      allowNoIdField: true,
      onValidate: validate,
    };
  }

  public clear(type: 'all' | 'file' = 'all') {
    switch (type) {
      case 'file': {
        this._fileSubj.next(null);
        this.set('fileUploadedPeople', []);
        break;
      }
      default: {
        this._fileSubj.next(null);
        this._stateSubj.next({ ...PS_FORM_FILTER_DEFAULT_STATE });
        break;
      }
    }
  }

  public cleanup(): void {
    this._stateSubj.complete();
    this._fileSubj.complete();
  }
}
