import { Injectable } from '@angular/core';

import * as automation_pb from 'minga/proto/automation/automation_pb';
import {
  AutomationPayload,
  IActionThresholdAutomation,
} from 'minga/domain/actions';
import { AutomationManager } from 'minga/proto/automation/automation_ng_grpc_pb';
import { AutomationMapper } from 'minga/shared-grpc/actions';

import { ErrorHandlerService } from '@shared/services/error-handler';

@Injectable()
export class AutomationGroupService {
  /** Constructor */
  constructor(
    private _errorHandler: ErrorHandlerService,
    private _automationManager: AutomationManager,
  ) {}

  public async fetchAll(
    onlyDisabledConsequence = false,
  ): Promise<IActionThresholdAutomation[]> {
    try {
      const request = new automation_pb.ListAutomationsRequest();
      request.setOnlyDisabledConsequence(onlyDisabledConsequence);
      const response = await this._automationManager.listAutomations(request);
      return response.getAutomationsList().map(AutomationMapper.fromProto);
    } catch (error) {
      // we can suppress this if we're only fetching disabled automations since it's just being used to show warning
      throw this._errorHandler.gateWayError(
        'failed to fetch all automation groups',
        error,
        !onlyDisabledConsequence,
      );
    }
  }

  public async fetch(id: number): Promise<IActionThresholdAutomation> {
    try {
      const request = new automation_pb.GetAutomationRequest();
      request.setId(id);
      return AutomationMapper.fromProto(
        await this._automationManager.getAutomation(request),
      );
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch automation group',
        error,
        true,
      );
    }
  }

  public async create(
    automation: AutomationPayload,
  ): Promise<IActionThresholdAutomation> {
    try {
      const request = new automation_pb.CreateAutomationRequest();
      request.setAutomation(AutomationMapper.toProto(automation));
      const result = await this._automationManager.createAutomation(request);
      return AutomationMapper.fromProto(result);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to create automation group',
        error,
        true,
      );
    }
  }

  public async update(
    automation: AutomationPayload,
  ): Promise<IActionThresholdAutomation> {
    try {
      const request = new automation_pb.UpdateAutomationRequest();
      const msg = AutomationMapper.toProto(automation);
      // we need to update the mapper here, but can't do it until
      // after launch so that it'll still work with old clients.
      // so until then, manually set threshold so that it doesn't
      // trigger the legacy handling.
      msg.setThreshold(null);
      request.setAutomation(msg);
      const result = await this._automationManager.updateAutomation(request);
      return AutomationMapper.fromProto(result);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to update automation group',
        error,
        true,
      );
    }
  }

  public async delete(id: number): Promise<any> {
    try {
      const request = new automation_pb.DeleteAutomationRequest();
      request.setId(id);
      await this._automationManager.deleteAutomation(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to update automation group',
        error,
        true,
      );
    }
  }
}
