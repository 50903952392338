import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { LandingService } from '../services';
import { hasAtLeastOneLandingProperty } from '../utils';

@Injectable()
export class LandingHasEmailOrPin extends hasAtLeastOneLandingProperty(
  ['email', 'pin'],
  ['/landing/email'],
) {
  constructor(landing: LandingService, router: Router) {
    super(landing, router);
  }
}
