import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { MgNotificationBaseModule } from '../MgNotificationBase';

import { MgPersonNotificationElement } from './MgPersonNotification.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgNotificationBaseModule,
    MgPipesModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgPersonNotificationElement],
  exports: [MgPersonNotificationElement],
})
export class MgPersonNotificationModule {}
