import { Route } from '@angular/router';

export const mapChildren = (routes: Route[], parentPaths: string[] = []) =>
  routes.map(c => {
    const path = parentPaths.join('/') + (c.path && '/' + c.path);
    return {
      path,
      // path: '/' + parentPaths.join('/') + (c.path && '/' + c.path),
      ...(c.data || {}),
      children: mapChildren(c.children ?? [], [...parentPaths, c.path]),
    };
  });
