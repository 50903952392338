<mat-form-field
  floatPlaceholder="never"
  mgFormField
  [mgNoHintMargin]="true"
  [mgHideOptionalMarker]="true"
  [mgNoBoldFontWeight]="true">
  <input
    matInput
    #searchInputElement
    #searchInput="ngModel"
    type="text"
    [placeholder]="placeholder"
    autocomplete="off"
    (keypress)="searchKeypress($event)"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange($event)"
    maxlength="50" />
  <mat-icon
    *ngIf="!value"
    matSuffix
    class="navsearch-maticon"
    >search</mat-icon
  >
  <mg-icon
    *ngIf="value"
    matSuffix
    (click)="clearSearch()"
    class="navsearch-close-icon"
    >close-thin</mg-icon
  >
</mat-form-field>
