<button
  [attr.aria-label]="student.firstName + ' ' + student.lastName"
  type="button"
  class="card"
  (click)="toggle()"
  [ngClass]="classes">
  <div class="card-wrap">
    <div
      *ngIf="hidePicture === false"
      class="card-avatar">
      <mg-avatar [src]="student.profileImageUrl"></mg-avatar>
    </div>
    <div class="card-name">
      <mg-text
        class="last-name"
        [matTooltip]="student.firstName + ' ' + student.lastName"
        matTooltipClass="mg-tooltip"
        [matTooltipShowDelay]="500"
        [numberOfLines]="1"
        [variant]="firstNameFirst ? 'body-sm' : 'header-md'"
        >{{ student.lastName }}</mg-text
      >
      <mg-text
        class="first-name"
        [numberOfLines]="1"
        [variant]="firstNameFirst ? 'header-md' : 'body-sm'"
        >{{ student.firstName }}
      </mg-text>
    </div>
    <div class="card-checkbox">
      <mg-form-checkbox [checked]="selected"></mg-form-checkbox>
    </div>
    <div
      *ngIf="mediaService.breakpoint$ | async as breakpoint"
      class="card-actions"
      (click)="actionsBarClicked($event, breakpoint)">
      <mg-tt-pill
        *ngIf="
          (pastPraises$ | async)?.totalCount > 0 ||
          (pastPraiseConsequences$ | async)?.totalCount > 0
        "
        [attr.aria-label]="'Active Praises'"
        type="success"
        [text]="
          (pastPraises$ | async)?.totalCount +
          (pastPraiseConsequences$ | async)?.totalCount
        "
        [size]="(mediaService.isMobileView$ | async) ? 'medium' : 'small'"
        [matMenuTriggerFor]="pastPraises"
        (click)="$event.stopPropagation()">
      </mg-tt-pill>
      <mat-menu
        #pastPraises="matMenu"
        yPosition="below"
        xPosition="after"
        [hasBackdrop]="true">
        <ng-template matMenuContent>
          <div
            class="popup-wrap"
            fxLayout="column"
            fxLayoutGap="4px">
            <div *ngIf="(pastPraises$ | async)?.totalCount > 0">
              <mg-text
                variant="label-md-secondary"
                element="p"
                >Praises</mg-text
              >
              <mg-text
                *ngFor="let praise of (pastPraises$ | async)?.items"
                variant="label-md"
                element="p">
                {{ praise.count }} {{ praise.label }}
              </mg-text>
            </div>
            <div *ngIf="(pastPraiseConsequences$ | async)?.totalCount > 0">
              <mg-text
                variant="label-md-secondary"
                element="p"
                >Consequences</mg-text
              >
              <mg-text
                *ngFor="
                  let consequence of (pastPraiseConsequences$ | async)?.items
                "
                variant="label-md"
                element="p">
                {{ consequence.count }} {{ consequence.label }}
              </mg-text>
            </div>
          </div>
        </ng-template>
      </mat-menu>

      <mg-tt-pill
        *ngIf="
          (pastGuidances$ | async)?.totalCount > 0 ||
          (pastConsequences$ | async)?.totalCount > 0
        "
        [attr.aria-label]="'Active Guidances'"
        type="error"
        [text]="
          (pastGuidances$ | async)?.totalCount +
          (pastConsequences$ | async)?.totalCount
        "
        [size]="(mediaService.isMobileView$ | async) ? 'medium' : 'small'"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="pastGuidances">
      </mg-tt-pill>
      <mat-menu
        #pastGuidances="matMenu"
        yPosition="below"
        xPosition="after"
        [hasBackdrop]="true">
        <ng-template matMenuContent>
          <div
            class="popup-wrap"
            fxLayout="column"
            fxLayoutGap="4px">
            <div *ngIf="(pastGuidances$ | async)?.totalCount > 0">
              <mg-text
                variant="label-md-secondary"
                element="p"
                >Guidances</mg-text
              >
              <mg-text
                *ngFor="
                  let guidance of (pastGuidances$ | async)?.items;
                  let i = index
                "
                variant="label-md"
                element="p">
                {{ guidance.count }} {{ guidance.label }}
              </mg-text>
            </div>
            <div *ngIf="(pastConsequences$ | async)?.totalCount > 0">
              <mg-text
                variant="label-md-secondary"
                element="p"
                >Consequences</mg-text
              >
              <mg-text
                *ngFor="
                  let consequence of (pastConsequences$ | async)?.items;
                  let i = index
                "
                variant="label-md"
                element="p">
                {{ consequence.count }} {{ consequence.label }}
              </mg-text>
            </div>
          </div>
        </ng-template>
      </mat-menu>

      <ng-container
        *ngIf="hallPass && PASS_STATUS_TO_TYPE[hallPass.status.state]">
        <ng-container
          *ngIf="
            hallPass
              | countdown
                : (recentlyEndedSetting$ | async)
                : (manuallyEndedPassesSetting$ | async)
                : myClassHallPassService.timeTicker$
                : hallPass.type?.manuallyEndPass
              | async as countdown;
            else loader
          ">
          <mg-tt-pill
            [attr.aria-label]="'Active Hall Passes'"
            [type]="
              selected && hallPass.status.state === HallPassStatus.ACTIVE
                ? 'selected'
                : PASS_STATUS_TO_TYPE[hallPass.status.state]
            "
            icon="mg-away"
            [size]="(mediaService.isMobileView$ | async) ? 'medium' : 'small'"
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="hallpass">
          </mg-tt-pill>
          <mat-menu
            #hallpass="matMenu"
            yPosition="below"
            xPosition="after"
            [hasBackdrop]="true">
            <ng-template matMenuContent>
              <div
                class="popup-wrap large"
                fxLayout="column"
                fxLayoutGap="4px">
                <mg-text
                  variant="label-md-secondary"
                  element="p"
                  >{{ hallPass.status.state }} Hall Pass</mg-text
                >
                <mg-text
                  variant="label-md"
                  element="p">
                  {{ hallPass.type?.name }}
                </mg-text>

                <ng-container
                  *ngIf="
                    hallPass.status.state === HallPassStatus.ENDED;
                    else timer
                  ">
                  <mg-text
                    variant="label-md"
                    element="p">
                    {{ hallPass.status.end | date: 'hh:mm a' }}
                  </mg-text>
                </ng-container>
                <ng-template #timer>
                  <ng-container *ngIf="countdown; else loader">
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="2px">
                      <mg-icon
                        *ngIf="
                          showIcon() &&
                          hallPass.status.state === HallPassStatus.ACTIVE
                        "
                        class="auto-end-icon"
                        iconName="mg-auto-end">
                      </mg-icon>
                      <mg-text
                        *ngIf="countdown !== 'DISABLED'"
                        variant="label-md"
                        element="p">
                        {{ countdown }}
                      </mg-text>
                    </div>
                  </ng-container>
                </ng-template>

                <div
                  fxLayout="row"
                  fxLayoutAlign="center center">
                  <mg-btn
                    *ngIf="
                      HallPassStatus.ACTIVE === hallPass.status.state ||
                      HallPassStatus.OVERDUE === hallPass.status.state
                    "
                    variant="text"
                    (pressed)="onHallPassActionClicked('end')">
                    End
                  </mg-btn>
                  <ng-container
                    *ngIf="
                      HallPassStatus.PENDING_APPROVAL ===
                        hallPass.status.state &&
                      (('HALL_PASS_TYPE_MANAGE' | hasPermission) ||
                        authHash === hallPass.authorPersonView?.personHash)
                    ">
                    <mg-btn
                      variant="text"
                      (pressed)="onHallPassActionClicked('deny')">
                      Deny
                    </mg-btn>
                    <mg-btn
                      variant="text"
                      (pressed)="onHallPassActionClicked('approve')">
                      Approve
                    </mg-btn>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </mat-menu>
        </ng-container>
        <ng-template #loader>
          <div class="inline-spinner">
            <mg-spinner
              [diameter]="16"
              [thickness]="2">
            </mg-spinner>
          </div>
        </ng-template>
      </ng-container>

      <mg-btn
        [attr.aria-label]="'Nagivate to student id'"
        class="card-student-id"
        size="small"
        variant="icon-secondary"
        icon="mg-id-menu-o"
        iconSet="minga"
        (click)="navigateToId($event, student.personHash)">
        ></mg-btn
      >
    </div>
  </div>
</button>
