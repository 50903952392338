import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import {
  IHallPassBlackOut,
  IHallPassBlackOutSchedule,
} from 'minga/domain/hallPass/IHallPassBlackOut';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  HP_RESTRICTIONS_BLACKOUT_TIME_TABLE_COLUMNS,
  HPM_RESTRICTIONS_INITIAL_SCHEDULE_ACTIVE_DAYS_STATE,
  HPM_RESTRICTIONS_INITIAL_SCHEDULE_STATE,
  HPM_RESTRICTIONS_INITIAL_TIME_BLOCK_STATE,
  HpmRestrictionsBlackoutEditForm,
  HpmRestrictionsBlackoutEditMessages,
} from '../../constants';
import { HpmRestrictionsBlackoutService } from '../../services';
import {
  HpmRestrictionsBlackoutEditModalData,
  HpmRestrictionsBlackoutEditModalResponse,
} from '../../types';

@Component({
  selector: 'mg-hpm-restrictions-blackout-edit',
  templateUrl: './hpm-restrictions-blackout-edit.component.html',
  styleUrls: ['./hpm-restrictions-blackout-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmRestrictionsBlackoutEditComponent implements OnDestroy {
  /** Constants */
  public readonly FORM_FIELD = HpmRestrictionsBlackoutEditForm;
  public readonly MESSAGES = HpmRestrictionsBlackoutEditMessages;
  public readonly DISPLAYED_COLUMNS =
    HP_RESTRICTIONS_BLACKOUT_TIME_TABLE_COLUMNS;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.PINK,
  };

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);
  private _isLoading$ = new BehaviorSubject<boolean>(true);
  public readonly isLoading$ = this._isLoading$.asObservable();

  /** Blackout Schedule */
  public schedule$: Observable<IHallPassBlackOut>;
  public isNewSchedule$: Observable<boolean>;
  private _schedule: IHallPassBlackOut;
  private _isNewSchedule: boolean;

  /** Time Blocks */
  private readonly _timeBlocks$ = new BehaviorSubject<
    IHallPassBlackOutSchedule[]
  >([]);
  public readonly timeBlocks$ = this._timeBlocks$.asObservable();

  /** Form Controls */
  public readonly form = this._fb.group({
    [this.FORM_FIELD.NAME]: [
      '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(25)],
    ],
    [this.FORM_FIELD.DAYS_ACTIVE]: [null, [Validators.required]],
    [this.FORM_FIELD.TIME_BLOCK]: this._fb.array([]),
  });
  get timeForm() {
    return this.form.get(this.FORM_FIELD.TIME_BLOCK) as FormArray;
  }
  public get canSubmit() {
    if (this._isNewSchedule) return this.form.dirty && this.form.valid;
    return this.form.valid;
  }
  get modalTitle() {
    return this._isNewSchedule
      ? this.MESSAGES.MODAL_TITLE
      : this.MESSAGES.MODAL_TITLE_EDIT;
  }

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: HpmRestrictionsBlackoutEditModalData,
    private _modalOverlay: ModalOverlayRef<
      HpmRestrictionsBlackoutEditModalResponse,
      HpmRestrictionsBlackoutEditModalData
    >,
    private _hpmResBlackout: HpmRestrictionsBlackoutService,
    private _dialog: MatDialog,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _fb: FormBuilder,
  ) {
    this._init(dialogData?.isNewSchedule ? true : false);
    this.schedule$
      .pipe(
        takeUntil(this._destroyed$),
        filter(list => !!list),
      )
      .subscribe(schedule => {
        this._schedule = schedule;
        schedule.schedules.forEach(block =>
          this.timeForm.push(
            this._fb.group(
              {
                startTime: [block.startTime, [Validators.required]],
                endTime: [block.endTime, [Validators.required]],
              },
              { validators: this._blackoutIntervalTimeGroupValidator() },
            ),
          ),
        );
        this._timeBlocks$.next(schedule.schedules);
        this.form.reset(
          {
            [this.FORM_FIELD.NAME]: schedule.name,
            [this.FORM_FIELD.DAYS_ACTIVE]: {
              blockMonday: schedule.blockMonday,
              blockTuesday: schedule.blockTuesday,
              blockWednesday: schedule.blockWednesday,
              blockThursday: schedule.blockThursday,
              blockFriday: schedule.blockFriday,
              blockSaturday: schedule.blockSaturday,
              blockSunday: schedule.blockSunday,
            },
            [this.FORM_FIELD.TIME_BLOCK]: schedule.schedules,
          },
          { onlySelf: true },
        );
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._isLoading$.complete();
    this._destroyed$.complete();
  }

  public async updateActiveDays(days: string[]) {
    const activeDays = {
      ...HPM_RESTRICTIONS_INITIAL_SCHEDULE_ACTIVE_DAYS_STATE,
    };
    days.forEach(day => (activeDays[`block${day}`] = true));
    this.form.patchValue({
      [this.FORM_FIELD.DAYS_ACTIVE]: activeDays,
    });
  }

  public async addTimeBlock(): Promise<void> {
    const arr = [...this._timeBlocks$.getValue()];
    arr.push(HPM_RESTRICTIONS_INITIAL_TIME_BLOCK_STATE);
    this._timeBlocks$.next(arr);
    this.timeForm.push(
      this._fb.group(
        {
          startTime: [
            HPM_RESTRICTIONS_INITIAL_TIME_BLOCK_STATE.startTime,
            [Validators.required],
          ],
          endTime: [
            HPM_RESTRICTIONS_INITIAL_TIME_BLOCK_STATE.endTime,
            [Validators.required],
          ],
        },
        {
          validators: this._blackoutIntervalTimeGroupValidator(),
        },
      ),
    );
  }

  public async deleteTimeBlock(index: number): Promise<void> {
    const arr = [...this._timeBlocks$.getValue()];
    arr.splice(index, 1);
    this._timeBlocks$.next([...arr]);
    this.timeForm.removeAt(index);
  }

  public async submit(): Promise<void> {
    if (!this.canSubmit) return;
    const mutatedSchedule: IHallPassBlackOut = {
      ...this._schedule,
      ...this.form.get(this.FORM_FIELD.DAYS_ACTIVE).value,
      name: this.form.get(this.FORM_FIELD.NAME).value,
      schedules: this.timeForm.value,
    };
    if (!this._isNewSchedule) return this._update(mutatedSchedule);
    return this._create(mutatedSchedule);
  }

  public async delete(): Promise<void> {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: this._isNewSchedule
            ? `Are you sure you want to discard this new schedule?`
            : `Are you sure you want to delete this schedule?`,
          deleteBtn: 'Delete',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || !response.confirmed || response.cancelled) return;
      if (!this._isNewSchedule) return this._delete();
      return this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
    });
  }

  private async _init(newList: boolean): Promise<void> {
    this.isNewSchedule$ = of(newList);
    this.isNewSchedule$.subscribe(val => (this._isNewSchedule = val));
    try {
      this._isLoading$.next(true);
      if (this.dialogData.isNewSchedule) {
        this.schedule$ = of<IHallPassBlackOut>({
          ...HPM_RESTRICTIONS_INITIAL_SCHEDULE_STATE,
        });
      } else {
        this.schedule$ = this._hpmResBlackout.schedules$.pipe(
          map(schedules =>
            schedules.find(schedule => schedule.id === this.dialogData.id),
          ),
        );
      }
    } catch (error) {
      this._systemAlertSnackBar.error(
        HpmRestrictionsBlackoutEditMessages.ERROR_OPENING_MODAL,
      );
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.ESCAPE);
    } finally {
      this._isLoading$.next(false);
    }
  }

  private async _create(schedule: IHallPassBlackOut): Promise<void> {
    await this._hpmResBlackout.create(schedule);
    this._modalOverlay.close(ModalOverlayServiceCloseEventType.CREATE);
  }

  private async _update(schedule: IHallPassBlackOut): Promise<void> {
    await this._hpmResBlackout.update(schedule);
    this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT);
  }

  private async _delete(): Promise<void> {
    await this._hpmResBlackout.delete(this._schedule);
    this._modalOverlay.close(ModalOverlayServiceCloseEventType.DELETE);
  }

  private _blackoutIntervalTimeGroupValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      let valid = true;
      const startTime = group.controls.startTime as FormControl;
      const endTime = group.controls.endTime as FormControl;
      if (startTime.value > endTime.value) {
        valid = false;
      }
      return valid ? null : { invalidValues: true };
    };
  }
}
