import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { SuccessDialog } from 'minga/app/src/app/dialog';
import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { MingaSettingsService } from 'src/app/store/Minga/services';
import {
  ModerationOverrideSuccess,
  TypeEnum,
  TypeUnion,
} from 'src/app/store/root/rootActions';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  GroupCollectionActions,
  GroupDetailsActions,
  GroupMemberActions,
} from '../actions';
import { Group, MingaGroupMemberRank } from '../models/Group';
import { GroupComposite } from '../models/GroupComposite';
import { GetGroupsResponse, GroupsService } from '../services/Groups.service';
import { GroupsFacadeService } from '../services/GroupsFacade';

function getRankFromGroup(group: Group): MingaGroupMemberRank {
  if (group.isPrivate) {
    return MingaGroupMemberRank.PENDING;
  } else {
    return MingaGroupMemberRank.MEMBER;
  }
}

@Injectable()
export class GroupCollectionEffects {
  constructor(
    private actions$: Actions<
      | TypeUnion
      | GroupCollectionActions.TypeUnion
      | GroupDetailsActions.TypeUnion
    >,
    private groupsService: GroupsService,
    private groupsFacade: GroupsFacadeService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private groupsFacadeService: GroupsFacadeService,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private store: Store<any>,
    private authInfoService: AuthInfoService,
    private _settingService: MingaSettingsService,
  ) {}

  @Effect()
  moderationOverrideSuccessInvalidate$: Observable<Action> = this.actions$.pipe(
    ofType(TypeEnum.ModerationOverrideSuccess),
    map((action: ModerationOverrideSuccess) => {
      return new GroupCollectionActions.InvalidateGroupsCollection();
    }),
  );

  /**
   * Send request to backend to join a group. Update details and group locally
   * and show success dialog if successful.
   */
  @Effect()
  joinGroup$: Observable<Action> = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.JoinGroup),
    map((action: GroupMemberActions.JoinGroup) => {
      action.payload.rank = getRankFromGroup(action.payload);
      return action.payload;
    }),
    switchMap(group =>
      this.groupsService.addGroupToMyGroups(group).pipe(
        switchMap((group: GroupComposite) => {
          const actions: Action[] = [
            new GroupMemberActions.JoinGroupSuccess(group.group),
            new GroupDetailsActions.UpdateGroupDetailsLocally(
              group.groupDetails,
            ),
            new GroupCollectionActions.UpdateGroupLocally(group.group),
          ];
          // if they just joined a new school, lets update the whole groups
          // list.
          if (group.group.isParent) {
            actions.push(
              new GroupCollectionActions.InvalidateGroupsCollection(),
            );
            actions.push(new GroupCollectionActions.LoadAllGroupsInfo());
          }
          return actions;
        }),
        catchError(error =>
          of(
            new GroupMemberActions.JoinGroupFailure({
              message: error,
              hash: group.hash,
            }),
          ),
        ),
      ),
    ),
  );

  /**
   * Send request to backend to join a group. Update details and group locally
   * and DO NOT show success dialog if successful.
   */
  @Effect()
  joinGroupNoConfirm$: Observable<Action> = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.JoinGroupNoConfirm),
    map((action: GroupMemberActions.JoinGroupNoConfirm) => {
      action.payload.rank = getRankFromGroup(action.payload);
      return action.payload;
    }),
    switchMap(group =>
      this.groupsService.addGroupToMyGroups(group).pipe(
        switchMap((group: GroupComposite) => {
          const actions: Action[] = [
            new GroupDetailsActions.UpdateGroupDetailsLocally(
              group.groupDetails,
            ),
            new GroupCollectionActions.UpdateGroupLocally(group.group),
          ];
          // if they just joined a new school, lets update the whole groups
          // list.
          if (group.group.isParent) {
            actions.push(
              new GroupCollectionActions.InvalidateGroupsCollection(),
            );
            actions.push(new GroupCollectionActions.LoadAllGroupsInfo());
          }
          return actions;
        }),
        catchError(error =>
          of(
            new GroupMemberActions.JoinGroupFailure({
              message: error,
              hash: group.hash,
            }),
          ),
        ),
      ),
    ),
  );

  /**
   * Send request to backend to cancel joining a group
   */
  @Effect()
  cancelJoinGroup$: Observable<Action> = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.CancelJoinGroup),
    map((action: GroupMemberActions.CancelJoinGroup) => action.payload),
    switchMap(group =>
      this.groupsService.cancelJoinGroup(group).pipe(
        map(
          group =>
            new GroupMemberActions.CancelJoinGroupSuccess(
              this.groupsFacade.removeRankFromGroup(group),
            ),
        ),
        catchError(error =>
          of(
            new GroupMemberActions.CancelJoinGroupFailure({
              message: error,
              hash: group.hash,
            }),
          ),
        ),
      ),
    ),
  );
  /**
   * Send an action to remove the group member if they
   * request to leave.
   */
  @Effect()
  LeaveGroup$: Observable<Action> = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.LeaveGroup),
    switchMap((action: GroupMemberActions.LeaveGroup) => {
      const person = this.authInfoService.authPerson;
      return [
        new GroupMemberActions.RemoveGroupMember(
          action.payload,
          person.hash,
          action.noSuccessInterstitial,
        ),
      ];
    }),
  );

  @Effect({ dispatch: false })
  leaveGroupSuccess$ = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.RemoveGroupMemberSuccess),
    map(async (action: GroupMemberActions.RemoveGroupMemberSuccess) => {
      const group$ = this.groupsFacadeService.getGroup(action.payload.hash);
      const group = await group$.pipe(first()).toPromise();
      if (!action.noSuccessInterstitial) {
        this.dialog.open(SuccessDialog, {
          data: { text: `You're no longer a member of ' ${group.name}!` },
        });
      }
      // update local group rank
      this.groupsFacade.removeRankFromGroup(group);

      // reload groups if this is a parent group.
      if (group && group.isParent) {
        this.store.dispatch(
          new GroupCollectionActions.InvalidateGroupsCollection(),
        );
        this.store.dispatch(new GroupCollectionActions.LoadAllGroupsInfo());
      }
      // go to groups list after leaving
      await this.router.navigate(['/groups/list', { outlets: { o: null } }]);
    }),
  );
  /**
   * Loads all groups only if needed.
   */
  @Effect()
  adhocLoadCollection$ = this.actions$.pipe(
    ofType(GroupDetailsActions.TypeEnum.LoadGroupDetails),
    withLatestFrom(this.groupsFacade.getAllGroups()),
    filter(groups => groups[1].length === 0),
    map(() => new GroupCollectionActions.LoadGroupsCollection()),
  );

  @Effect()
  groupMemberAcceptUpdate$ = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.AcceptGroupMember),
    switchMap((action: GroupMemberActions.AcceptGroupMember) =>
      this.groupsService
        .acceptGroupMember(action.payload, action.memberPersonHash)
        .pipe(
          map(
            groupDetails =>
              new GroupMemberActions.AcceptGroupMemberSuccess(groupDetails),
          ),
          catchError(error =>
            of(
              new GroupMemberActions.AcceptGroupMemberFailure({
                message: error,
                hash: action.payload.hash,
              }),
            ),
          ),
        ),
    ),
  );

  @Effect()
  groupMemberDeclineUpdate$ = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.DeclineGroupMember),
    switchMap((action: GroupMemberActions.DeclineGroupMember) =>
      this.groupsService
        .declineGroupMember(action.payload, action.memberPersonHash)
        .pipe(
          map(
            groupDetails =>
              new GroupMemberActions.DeclineGroupMemberSuccess(groupDetails),
          ),
          catchError(error =>
            of(
              new GroupMemberActions.DeclineGroupMemberFailure({
                message: error,
                hash: action.payload.hash,
              }),
            ),
          ),
        ),
    ),
  );

  @Effect()
  groupMemberRemoveUpdate$ = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.RemoveGroupMember),
    switchMap((action: GroupMemberActions.RemoveGroupMember) =>
      this.groupsService
        .removeGroupMember(action.group, action.memberPersonHash)
        .pipe(
          map(
            groupDetails =>
              new GroupMemberActions.RemoveGroupMemberSuccess(
                groupDetails,
                action.noSuccessInterstitial,
              ),
          ),
          catchError(error =>
            of(
              new GroupMemberActions.RemoveGroupMemberFailure({
                message: error,
                hash: action.group.hash,
              }),
            ),
          ),
        ),
    ),
  );

  @Effect()
  groupMembersUpdate$ = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.UpdateGroupMembers),
    switchMap((action: GroupMemberActions.UpdateGroupMembers) =>
      this.groupsService
        .updateMembers(action.groupHash, action.memberUpdates)
        .pipe(
          map(groupDetails => {
            return new GroupMemberActions.UpdateGroupMembersSuccess(
              groupDetails,
            );
          }),
          catchError(error =>
            of(
              new GroupMemberActions.UpdateGroupMembersFailure({
                message: error,
                hash: action.groupHash,
              }),
            ),
          ),
        ),
    ),
  );

  @Effect({ dispatch: false })
  updateGroupMembersSuccess$ = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.UpdateGroupMembersSuccess),
    map((action: GroupMemberActions.UpdateGroupMembersSuccess) => {
      let dialog = this.dialog.open(SuccessDialog, {
        data: {
          text: `Members updated successfully!<br>Notifications have been sent.`,
        },
      });

      dialog.afterClosed().subscribe(result => {
        // close outlet then...
        this.router.navigate(['', { outlets: { o: null } }]).then(async () => {
          // go to view group details page,
          await this.router.navigate(['/groups/view/' + action.payload.hash]);
        });
      });
    }),
  );

  @Effect()
  addGroupMembers$ = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.AddGroupMembers),
    switchMap((action: GroupMemberActions.AddGroupMembers) =>
      this.groupsService.addMembers(action.groupHash, action.memberHashes).pipe(
        map(groupDetails => {
          return new GroupMemberActions.AddGroupMembersSuccess(groupDetails);
        }),
        catchError(error =>
          of(
            new GroupMemberActions.AddGroupMembersFailure({
              message: error,
              hash: action.groupHash,
            }),
          ),
        ),
      ),
    ),
  );

  @Effect()
  removeGroupMembers$ = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.RemoveGroupMembers),
    switchMap((action: GroupMemberActions.RemoveGroupMembers) =>
      this.groupsService
        .removeMembers(action.groupHash, action.memberHashes)
        .pipe(
          map(groupDetails => {
            return new GroupMemberActions.RemoveGroupMemberSuccess(
              groupDetails,
              true,
            );
          }),
          catchError(error =>
            of(
              new GroupMemberActions.RemoveGroupMemberFailure({
                message: error,
                hash: action.groupHash,
              }),
            ),
          ),
        ),
    ),
  );

  /**
   * Load all groups for the Minga from backend.
   * Updates the store based on an expiry time, so the data never gets too
   * stale.
   */
  @Effect()
  loadCollection$: Observable<Action> = this.actions$.pipe(
    ofType(GroupCollectionActions.TypeEnum.LoadGroupsCollection),
    withLatestFrom(this._settingService.isCommunityModuleEnabled()),
    map(([action, communityEnabled]) => ({ action, communityEnabled })),
    filter(data => data.communityEnabled),
    switchMap(data =>
      this.groupsService.getAllGroups().pipe(
        switchMap((payload: GetGroupsResponse) => [
          new GroupCollectionActions.LoadGroupsSuccess(
            payload.groups,
            this.groupsFacade.getExpiryTime(),
          ),
        ]),
        // see
        // https://medium.com/city-pantry/handling-errors-in-ngrx-effects-a95d918490d9
        // for a good explanation of the pitfalls to avoid with error handling
        // if we don't want to handle it by outputing a new action, we could
        // instead throwError(error);
        catchError(error =>
          of(
            new GroupCollectionActions.LoadGroupsFailure({
              message: `There was an error loading groups. Please try again later.`,
            }),
          ),
        ),
      ),
    ),
  );

  @Effect({ dispatch: false })
  loadCollectionSuccess$: Observable<void> = this.actions$.pipe(
    ofType(GroupCollectionActions.TypeEnum.LoadGroupsSuccess),
    map(() => {}),
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(GroupDetailsActions.TypeEnum.LoadGroupDetails),
    switchMap((action: GroupDetailsActions.LoadGroupDetails) => {
      return this.groupsService.getGroupDetails(action.groupHash).pipe(
        map(
          groupDetails =>
            new GroupDetailsActions.LoadGroupDetailsSuccess(groupDetails),
        ),
        catchError(err =>
          of(new GroupDetailsActions.LoadGroupDetailsFailure({ message: err })),
        ),
      );
    }),
  );

  /**
   * Dispatch load actions for groups and my groups,
   * if we don't have the data loaded yet or if
   * the data is stale.
   */

  @Effect()
  loadAllGroupsInfo$: Observable<Action> = this.actions$.pipe(
    ofType(GroupCollectionActions.TypeEnum.LoadAllGroupsInfo),
    withLatestFrom(this.groupsFacadeService.getGroupsLoaded()),
    withLatestFrom(this.groupsFacadeService.getGroupsExpires()),
    withLatestFrom(this._settingService.isCommunityModuleEnabled()),
    switchMap(([[[action, groupsLoaded], expires], communityEnabled]) => {
      const actions = [];
      const date = new Date();
      if (communityEnabled && (!groupsLoaded || date.getTime() > expires)) {
        actions.push(new GroupCollectionActions.LoadGroupsCollection());
      }
      return actions;
    }),
  );

  @Effect()
  deleteGroup$ = this.actions$.pipe(
    ofType(GroupCollectionActions.TypeEnum.DeleteGroup),
    switchMap((action: GroupCollectionActions.DeleteGroup) => {
      return this.groupsService.deleteGroup(action.payload).pipe(
        map(
          () => new GroupCollectionActions.DeleteGroupSuccess(action.payload),
        ),
        catchError(err =>
          of(
            new GroupCollectionActions.DeleteGroupFailure(action.payload, err),
          ),
        ),
      );
    }),
  );

  @Effect({ dispatch: false })
  deleteGroupSuccess$ = this.actions$.pipe(
    ofType(GroupCollectionActions.TypeEnum.DeleteGroupSuccess),
    map((action: GroupCollectionActions.DeleteGroupSuccess) => {
      this._systemAlertSnackBar.success('Successfully deleted group.');
    }),
  );

  /**
   * When group details are updated, make sure we update the main Group store
   * for that group as well, in case the current user's rank has changed.
   */
  @Effect({ dispatch: false })
  updateGroupOnGroupDetailsUpdate$ = this.actions$.pipe(
    ofType(GroupDetailsActions.TypeEnum.LoadGroupDetailsSuccess),
    withLatestFrom(this.groupsFacade.getAllGroupsMap()),
    map(
      ([action, groupsMap]: [
        GroupDetailsActions.LoadGroupDetailsSuccess,
        Dictionary<Group>,
      ]) => {
        this.groupsFacadeService.updateGroupStoreFromGroupDetails(
          action.payload,
          groupsMap,
        );
      },
    ),
  );

  /**
   * Handle an error .
   */
  @Effect({ dispatch: false })
  displaySnackBaronError$ = this.actions$.pipe(
    ofType(
      GroupCollectionActions.TypeEnum.LoadGroupsFailure,
      GroupMemberActions.TypeEnum.JoinGroupFailure,
      GroupMemberActions.TypeEnum.AcceptGroupMemberFailure,
      GroupCollectionActions.TypeEnum.DeleteGroupFailure,
      GroupMemberActions.TypeEnum.DeclineGroupMemberFailure,
      GroupMemberActions.TypeEnum.UpdateGroupMembersFailure,
      GroupMemberActions.TypeEnum.AddGroupMembersFailure,
      GroupMemberActions.TypeEnum.RemoveGroupMemberFailure,
    ),
    map((action: GroupCollectionActions.LoadGroupsFailure) => {
      this._systemAlertSnackBar.error(action.payload.message);
    }),
  );

  /**
   * Fire off some visual cues to the user
   */
  @Effect({ dispatch: false })
  joinGroupSuccess$ = this.actions$.pipe(
    ofType(GroupMemberActions.TypeEnum.JoinGroupSuccess),
    map((action: GroupMemberActions.JoinGroupSuccess) => {
      if (action.payload.rank === MingaGroupMemberRank.PENDING) {
        this._systemAlertSnackBar.success(
          'We let the group owner know that you want to join!',
        );
      } else if (action.payload.rank === MingaGroupMemberRank.MEMBER) {
        let dialog = this.dialog.open(SuccessDialog, {
          data: { text: `You're now a member of ${action.payload.name}!` },
        });
        this.router
          .navigate(['', { outlets: { search: null } }])
          .then(() =>
            this.router.navigate(['/groups/view/' + action.payload.hash]),
          );
      }
    }),
  );
}
