import {
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import * as xlsx from 'xlsx';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GroupsFacadeService } from 'src/app/groups/services';

import {
  IListUploadDialogErrorRow,
  IResolveListUploadErrorsDialogData,
  ResolveListUploadErrorsDialogComponent,
} from '@shared/components/XlsListUploader/ResolveListUploadErrorsDialog';
import {
  IListUploadField,
  IListUploadRowError,
  IListUploadValidationResponse,
  ListUploaderRow,
} from '@shared/components/XlsListUploader/types';
import { arrayToListUploadRow } from '@shared/components/XlsListUploader/util';

import { XlsListUploaderMessages } from './XlsListUploader.constants';

const getHeaderRow = (data: string[][]): string[] => {
  const header: string[] = data[0];
  const secondRow = data[1];
  // check to make sure header row is as long as the actual data
  // and add empty columns if it isnt.
  if (secondRow && header && secondRow.length > header.length) {
    for (let index = header.length; index < secondRow.length; index++) {
      header.push('');
    }
  }
  return header;
};

@Component({
  selector: 'mg-xls-list-uploader',
  templateUrl: './XlsListUploader.component.html',
  styleUrls: ['./XlsListUploader.component.scss'],
})
export class XlsListUploaderComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  title = 'Import People';

  @Input()
  fields: IListUploadField[] = [];

  @Input()
  hasHeaders = false;

  @Input()
  updateExisting = true;

  @Input()
  file: File | null = null;

  @Input()
  enableFilter = false;

  @Input()
  hideNavItem = false;

  @Input()
  resolveErrorsDialogData: IResolveListUploadErrorsDialogData | null = null;

  /**
   * If true, skip checking for duplicate rows.
   */
  @Input()
  allowDuplicateRows = false;

  /**
   * If true, skip checking to see if an ID field is available and filled in.
   */
  @Input()
  allowNoIdField = false;

  /**
   * a validation function that is run on the list before submit.
   */
  @Input()
  onValidate?: (records: ListUploaderRow[]) => IListUploadValidationResponse;

  @Output()
  listSubmitted: EventEmitter<ListUploaderRow[]>;

  @Output()
  listParsed: EventEmitter<string[][]>;

  @Output()
  headersChanged: EventEmitter<(IListUploadField | null)[]>;

  @Output()
  firstRowHeaders: EventEmitter<boolean>;

  @ViewChild('tableSlider', { static: false })
  tableSlider?: ElementRef;

  public readonly MESSAGES = XlsListUploaderMessages;

  public records: string[][] = [];
  // stores what the header record in the file was.
  headerRecord: string[] | null = null;

  columnOptions: { value: string; name: string }[] = [];
  fieldsList: (IListUploadField | null)[] = [];
  readonly rowsToDisplay = 10;

  formatErrors: string[] = [];
  rowErrors: IListUploadRowError[] = [];

  formGroup: FormGroup = new FormGroup({
    hasHeaders: new FormControl(),
    headers: new FormArray([]),
  });
  columnFormArray: FormArray = new FormArray([]);
  groupHashes: Map<number, string[]> = new Map();
  groupsAvailable: Map<string, string> = new Map();
  private _destroyed$ = new ReplaySubject<void>(1);

  constructor(
    private _cdr: ChangeDetectorRef,
    private _matDialog: MatDialog,
    private _groupFacade: GroupsFacadeService,
  ) {
    this.headersChanged = new EventEmitter();
    this.listParsed = new EventEmitter();
    this.listSubmitted = new EventEmitter();
    this.firstRowHeaders = new EventEmitter();
    this._groupFacade
      .getAllGroups()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(groups => {
        this.groupsAvailable = new Map(
          groups.map(group => {
            return [group.name.toLowerCase(), group.hash];
          }),
        );
      });
  }

  get headers() {
    return this.formGroup?.controls.headers as FormArray;
  }

  ngOnInit(): void {
    this.columnOptions = this.getColumnOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file?.currentValue) {
      this.onUploadFile(changes.file.currentValue);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onUploadFile(file: File) {
    this.rowErrors = [];
    this.formatErrors = [];
    this.records = [];
    const filereader = new FileReader();
    filereader.onloadend = e => {
      const buffer = filereader.result as ArrayBuffer;
      const workbook = xlsx.read(buffer, {
        type: 'array',
      });

      if (workbook.Sheets) {
        for (const sheetName in workbook.Sheets) {
          if (!workbook.Sheets.hasOwnProperty(sheetName)) continue;
          const sheet = workbook.Sheets[sheetName];
          const data: [][] = xlsx.utils.sheet_to_json(sheet, {
            header: 1,
            blankrows: false,
            raw: false,
            defval: '',
          });

          const header = getHeaderRow(data);
          this.guessColumnsFromHeaders(header);
          this.records = data;
        }
        this._cdr.markForCheck();
      }
    };
    filereader.readAsArrayBuffer(file);
  }

  guessColumnsFromHeaders(headerRow: string[]) {
    const fieldsList = [];
    const formControls = new FormArray([]);
    for (const column of headerRow) {
      let value = null;
      const formControl = new FormControl({ value: '' });
      for (const field of this.fields) {
        const matcher = field.matcher;
        if (matcher && column) {
          const matches = column.match(matcher);
          if (matches && matches.length > 0) {
            formControl.setValue(field.id);
            value = field.id;
            fieldsList.push(field);
            formControls.push(formControl);
            break;
          }
        } else {
          // no matcher, nothing to do.
          continue;
        }
      }
      if (!value) {
        fieldsList.push(null);
        formControls.push(formControl);
      }
    }
    this.fieldsList = fieldsList;
    this.formGroup.setControl('headers', formControls);
    this.headersChanged.emit(this.fieldsList);
  }

  getColumnOptions(): { value: string; name: string }[] {
    const items: { value: string; name: string }[] = [];
    items.push({ value: '', name: '( Ignore )' });

    for (const field of this.fields) {
      items.push({ value: field.id, name: field.name });
    }

    return items;
  }

  onColumnHeaderChange(columnIndex: number, value: MatSelectChange) {
    const selectedField = this.fields.find(field => {
      return field.id === value.value;
    });
    if (selectedField) {
      this.fieldsList[columnIndex] = selectedField;
    } else {
      this.fieldsList[columnIndex] = null;
    }

    this.headersChanged.emit(this.fieldsList);
  }

  onHasHeadersChange(value: boolean) {
    if (value) {
      const header = getHeaderRow(this.records);
      if (header) {
        this.records.shift();
        this.headerRecord = header;
        this.guessColumnsFromHeaders(this.headerRecord);
      }
    } else {
      // if we are storing a header record, re-add that to top of records list
      if (this.headerRecord) {
        this.records.unshift(this.headerRecord);
      }
    }
    this.firstRowHeaders.emit(value);
    this._cdr.markForCheck();
  }

  _getIdFields() {
    const idFields: { name: string; index: number }[] = [];
    for (let index = 0; index < this.fieldsList.length; index++) {
      const field = this.fieldsList[index];
      if (field?.isIdField) {
        idFields.push({ index, name: field.name });
      }
    }

    return idFields;
  }

  /**
   * Basic validation that would be used on all lists.
   *
   * @returns
   */
  validate(): boolean {
    const selectedIdFields = this._getIdFields();

    const getIdFieldNamesForMessage = () => {
      const names = this.fields
        .filter(item => item.isIdField)
        .map(item => item.name);
      return names.join(' or ');
    };

    let hasIdField = false;
    const groupFields = [];

    const recordMap = new Map<string, number>();

    const fieldMap = new Map<number, string>();

    const fieldIds: string[] = [];

    // check that required fields have been selected
    for (let index = 0; index < this.fieldsList.length; index++) {
      const field = this.fieldsList[index];
      if (field?.isIdField) {
        hasIdField = true;
      }
      if (field?.name === 'Group') {
        groupFields.push(index);
      }
      if (field) {
        fieldMap.set(index, field.id);
        // check for multiples of the same field when that is not allowed.
        const exists = fieldIds.find(id => id === field.id);
        if (exists && !field.allowMultiple) {
          this.formatErrors.push(
            'Update the selected headers to only include one ' +
              field.name +
              ' field.',
          );
        } else {
          fieldIds.push(field.id);
        }
      }
    }

    if (!hasIdField && !this.allowNoIdField) {
      // no id field selected so throw error and exit.
      this.formatErrors.push(
        'One of ' +
          getIdFieldNamesForMessage() +
          ' is required and is not included',
      );
      return false;
    }

    for (let index = 0; index < this.records.length; index++) {
      // look for required field values in each row.
      const row = this.records[index];
      let foundId = false;
      const errors = [];

      for (const field of selectedIdFields) {
        const idField = field;
        const fieldIndex = idField.index;
        const value = row[fieldIndex];
        // we have this field in the row and it has a value
        if (value) {
          // check for duplicates.
          const exists = recordMap.get(value);
          if (
            exists !== undefined &&
            exists !== index &&
            !this.allowDuplicateRows
          ) {
            errors.push('Duplicate row with ' + idField.name + ' ' + value);
          } else {
            recordMap.set(value, index);
          }
          foundId = true;
        } else {
          const fieldType = this.fieldsList[fieldIndex];
          if (fieldType.required) {
            this.formatErrors.push(
              'Required field ' + field.name + ' is not included',
            );
          }
        }
      }

      for (const i of groupFields) {
        const value = row[i];
        if (value) {
          const hash = this.groupsAvailable.get(value.toLowerCase());
          if (hash) {
            const prev = this.groupHashes.get(index);
            if (prev) {
              this.groupHashes.set(index, prev.concat(hash));
            } else {
              this.groupHashes.set(index, [hash]);
            }
          } else {
            errors.push(
              'Group ' +
                value +
                ' does not exist in this Minga. Please check your spelling and try again.',
            );
          }
        }
      }

      if (!foundId && !this.allowNoIdField) {
        errors.push(
          'One of ' +
            getIdFieldNamesForMessage() +
            ' is required and is not included',
        );
      }

      if (errors.length > 0) {
        this.rowErrors.push({ recordIndex: index, errors });
      }
    }
    if (this.formatErrors.length === 0 && this.rowErrors.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  triggerSubmit() {
    this.rowErrors = [];
    this.formatErrors = [];
    // do basic validation
    const valid = this.validate();
    if (valid) {
      let records: ListUploaderRow[] = this.records.map(record =>
        arrayToListUploadRow(record, this.fieldsList),
      );
      // if exists, do custom validation passed to component.
      if (this.onValidate) {
        const results = this.onValidate(records);
        this.formatErrors = this.formatErrors.concat(results.formatErrors);
        this.rowErrors = this.rowErrors.concat(results.rowErrors);
      }
      if (this.formatErrors.length === 0 && this.rowErrors.length === 0) {
        records = this.switchGroupNameToHash(records);
        this.listSubmitted.emit(records);
      }
    }
    return;
  }

  switchGroupNameToHash(records: ListUploaderRow[]) {
    for (const entry of this.groupHashes) {
      records[entry[0]].group = entry[1];
    }
    return records;
  }

  openErrorsDialog() {
    const errors: IListUploadDialogErrorRow[] = this.rowErrors.map(error => {
      const record = this.records[error.recordIndex];
      const errorMessage = error.errors.join('\n');
      return { record, errorMessage, recordIndex: error.recordIndex };
    });
    const headers = this.fieldsList.map(column => {
      if (column) {
        return column.name;
      }
      return '';
    });
    let data: IResolveListUploadErrorsDialogData = {
      errors,
      columnNames: headers,
      dialogText:
        'Clean up your file to continue to import into Minga. This will remove any problem rows.',
    };

    if (this.resolveErrorsDialogData) {
      // overwrite default data with passed in info, if available.
      data = Object.assign(data, this.resolveErrorsDialogData);
    }
    const dialogRef = this._matDialog.open(
      ResolveListUploadErrorsDialogComponent,
      { data },
    );

    dialogRef.beforeClosed().subscribe(errorIndex => {
      if (typeof errorIndex === 'number') {
        // what to do if they pick goto.
        // this.goToError(errorIndex);
      } else if (errorIndex === true) {
        this.removeErrorRecords();
        this._cdr.markForCheck();
      }
    });
  }

  removeErrorRecords() {
    // sort backwards so that when we remove items, we don;t mess up the indexes
    // of the rest of the items.
    this.rowErrors.sort((a, b) => {
      return b.recordIndex - a.recordIndex;
    });
    for (const error of this.rowErrors) {
      const index = error.recordIndex;
      this.records.splice(index, 1);
    }
    this.rowErrors = [];
  }

  scrollLeft() {
    if (this.tableSlider) {
      this.tableSlider.nativeElement.scrollLeft -= 216;
    }
  }

  scrollRight() {
    if (this.tableSlider) {
      this.tableSlider.nativeElement.scrollLeft += 216;
    }
  }

  onClose() {
    this._matDialog.closeAll();
  }
}
