<mg-notification-base
  [read]="notification?.read"
  [viewed]="notification?.viewed"
  [icon]="notification?.iconUrl"
  [iconColor]="notification?.iconColor"
  [avatarSrc]="notification?.image | mgAssetUrl:'preview'"
  [timestamp]="notification?.timestamp">
  <mg-notification-base-title>
    <div [innerHTML]="notification?.title | mgSafeHtml"></div>
  </mg-notification-base-title>
  <mg-notification-base-footer>
    <div class="action-container">
      <p class="action-text">RSVP TO THIS EVENT</p>
    </div>
  </mg-notification-base-footer>
</mg-notification-base>
