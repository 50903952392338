import { Injector, NgModule } from '@angular/core';

import { MgVideoPlayerIconElement } from './MgVideoPlayerIcon.element';

@NgModule({
  declarations: [MgVideoPlayerIconElement],
  exports: [MgVideoPlayerIconElement],
})
export class MgVideoPlayerIconModule {
  constructor() {}
}
