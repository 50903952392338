import { Injectable } from '@angular/core';

import Fuse from 'fuse.js';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { EventsFacadeService } from 'minga/app/src/app/events/services';
import { PeopleFacadeService } from 'minga/app/src/app/people';
import { IContentEventMinimal } from 'minga/domain/content';
import { IPeopleCollectionPersonDetails } from 'minga/domain/person';

import { Group } from '../../groups/models/Group';
import { GroupsFacadeService } from '../../groups/services';
import { MgAssetUrlPipe } from '../../pipes';

const fuseOptions: Fuse.IFuseOptions<any> = {
  isCaseSensitive: false,
  keys: ['displayName', 'fullName'],
  shouldSort: true,
};

export interface IMentionable {
  readonly type: 'group' | 'person' | 'event';
  readonly hash: string;
  readonly displayName: string;
  readonly fullName: string;
  readonly imageUrl: string;
  readonly badgeIconColor?: string;
}

export interface IMentionQueryOptions {
  searchPeople?: boolean;
  searchGroups?: boolean;
  searchEvents?: boolean;
}

function personToMentionable(
  person: IPeopleCollectionPersonDetails,
): IMentionable {
  return {
    type: 'person',
    get hash() {
      return person.personHash;
    },
    get displayName() {
      return person.displayName;
    },
    get fullName() {
      return '';
    },
    get imageUrl() {
      return person.profileImageUrl;
    },
    get badgeIconColor() {
      return person.badgeIconColor;
    },
  };
}

function peopleToMentionables(people: IPeopleCollectionPersonDetails[]) {
  return people.map(personToMentionable);
}

function groupToMentionable(group: Group): IMentionable {
  return {
    type: 'group',
    get hash() {
      return group.hash;
    },
    get displayName() {
      return group.name;
    },
    get fullName() {
      return '';
    },
    get imageUrl() {
      return MgAssetUrlPipe.transform(group.banner, ['cardbanner']);
    },
  };
}

function groupsToMentionables(groups: Group[]) {
  return groups.map(groupToMentionable);
}

function eventToMentionable(event: IContentEventMinimal): IMentionable {
  return {
    type: 'event',
    get hash() {
      return event.hash;
    },
    get displayName() {
      return event.contentTagTitle;
    },
    get fullName() {
      return '';
    },
    get imageUrl() {
      return '';
    },
  };
}

function eventsToMentionables(events: IContentEventMinimal[]) {
  return events.map(eventToMentionable);
}

@Injectable({ providedIn: 'root' })
export class MentionsService {
  mentionsSearch: Fuse<any>;
  peopleMentions$: Observable<IMentionable[]>;
  eventMentions$: Observable<IMentionable[]>;
  groupMentions$: Observable<IMentionable[]>;
  searchUpdated = new BehaviorSubject<boolean>(false);
  searchUpdated$: Observable<boolean>;

  constructor(
    private groupsFacade: GroupsFacadeService,
    private eventsFacade: EventsFacadeService,
    private peopleFacade: PeopleFacadeService,
  ) {
    this.mentionsSearch = new Fuse([], fuseOptions);
    this.peopleMentions$ = this.getPeopleMentionables();
    this.eventMentions$ = this.getEventMentionables();
    this.groupMentions$ = this.getGroupMentionables();

    this.searchUpdated$ = this.searchUpdated.asObservable();

    const updateSearch = (mentionables: IMentionable[]) => {
      if (!(mentionables.length > 0)) {
        return;
      }
      const first = mentionables[0];
      const type = first.type;
      // remove any items of this type before adding the new set
      this.mentionsSearch.remove(doc => {
        return doc && doc.type == type;
      });
      for (const element of mentionables) {
        this.mentionsSearch.add(element);
      }
      this.searchUpdated.next(true);
    };
    // track each type separately so we don't have to
    // reprocess EVERY element just because one of the
    // sources emitted.
    this.peopleMentions$.subscribe(updateSearch);
    this.eventMentions$.subscribe(updateSearch);
    this.groupMentions$.subscribe(updateSearch);
  }

  /**
   * Get a mentionable by id.
   * Note: currently this just pulls from locally stored mentionables
   * and won't show anything that isn't in the local stores.
   * Depending on how you want to use this, you may want to fire off
   * a request to get info from the backend before calling this.
   * Groups and Events are already grabbed in full, but people are
   * only grabbed as requested so you might need to call
   * this.peopleAutoComplete.getPersonFromBackend(id) if it's a person
   * mention.
   * @param id
   */
  getMentionable(id: string): Observable<IMentionable | null> {
    return combineLatest([
      this.groupsFacade.getGroup(id),
      this.peopleFacade.getPersonDetails(id),
      this.eventsFacade.getEventByContentHash(id),
    ]).pipe(
      map(([group, person, event]) => {
        if (group) return groupToMentionable(group);
        if (person) return personToMentionable(person);
        if (event) return eventToMentionable(event);
        return null;
      }),
    );
  }

  getAllMentionables(): Observable<IMentionable[]> {
    return combineLatest([
      this.groupsFacade.getAllGroups().pipe(map(groupsToMentionables)),
      this.peopleFacade.getAllPeopleDetails().pipe(map(peopleToMentionables)),
      this.eventsFacade.getAllEvents().pipe(map(eventsToMentionables)),
    ]).pipe(
      map(([groups, people, events]) => [...groups, ...people, ...events]),
    );
  }

  getPeopleMentionables(): Observable<IMentionable[]> {
    return this.peopleFacade
      .getAllPeopleDetails()
      .pipe(map(peopleToMentionables));
  }

  getGroupMentionables(): Observable<IMentionable[]> {
    return this.groupsFacade.getAllGroups().pipe(map(groupsToMentionables));
  }

  getEventMentionables(): Observable<IMentionable[]> {
    return this.eventsFacade.getAllEvents().pipe(map(eventsToMentionables));
  }

  findMentionables(
    query: string,
    options?: IMentionQueryOptions,
  ): Observable<IMentionable[]> {
    if (!query) {
      return from([]);
    }
    const filterTypes: string[] = [];
    if (options) {
      if (options.searchPeople) {
        filterTypes.push('person');
        // fire off a request to search for people
        this.peopleFacade.makePeopleSearchRequest(query);
      }
      if (options.searchGroups) {
        filterTypes.push('group');
      }
      if (options.searchEvents) {
        filterTypes.push('event');
      }
    } else {
      filterTypes.push('person', 'event', 'group');
    }

    return this.searchUpdated$.pipe(
      map(() => this.mentionsSearch.search(query)),
      map(result => result.map(row => row.item)),
      map(results =>
        results.filter((item: IMentionable) => filterTypes.includes(item.type)),
      ),
    );
  }
}
