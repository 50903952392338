import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { IMingaDistrict } from 'minga/domain/minga';

import { ModalOverlayServiceCloseEventType } from '@shared/components/modal-overlay';
import { PaginatorComponent } from '@shared/components/paginator';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { DistrictsService } from '@shared/services/districts/districts.service';

import {
  DISTRICT_MESSAGES,
  DISTRICT_TABLE_COLUMNS,
  DistrictTableColumn,
} from '../../mm-districts.constants';
import { MmDistrictsService } from '../../services/mm-districts.service';

@Component({
  selector: 'mg-mm-districts-table',
  templateUrl: './mm-districts-table.component.html',
  styleUrls: ['./mm-districts-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmDistrictsTableComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(PaginatorComponent)
  paginator: PaginatorComponent;

  private _destroyed$ = new ReplaySubject<void>(1);
  public readonly COLUMN_DEF = DistrictTableColumn;
  public MESSAGES = DISTRICT_MESSAGES;
  public readonly dataSource = new MatTableDataSource<IMingaDistrict>([]);

  public readonly textSearchControl = this._fb.control('');

  private readonly _debouncedSearchValue =
    this.textSearchControl.valueChanges.pipe(
      takeUntil(this._destroyed$),
      debounceTime(300),
      distinctUntilChanged(),
    );

  public readonly displayedColumns = DISTRICT_TABLE_COLUMNS;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    public mmDistrictsService: MmDistrictsService,
    private _fb: FormBuilder,
    private _districtsService: DistrictsService,
  ) {}

  ngOnInit(): void {
    this.mmDistrictsService.fetchAll();

    this.mmDistrictsService.districts$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(districts => {
        this.dataSource.data = districts;
        this._cdr.markForCheck();
      });

    this._debouncedSearchValue.subscribe(value => {
      this.dataSource.filter = value.trim().toLowerCase() || '';
      this._cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.sort.sort({
      id: 'name',
      start: 'asc',
      disableClear: false,
    });
    this.dataSource.paginator = this.paginator.matPaginator;
    this.dataSource.filterPredicate = this._tableFilter;
  }

  public trackById(index: number, data) {
    if (!data) return index;
    return data.id;
  }

  public async onEditClick(id?: number) {
    const modalRef = this.mmDistrictsService.openModal({
      id,
    });

    const { type, data } = await modalRef.afterClosed.toPromise();

    let message = '';

    if (type === ModalOverlayServiceCloseEventType.CREATE) {
      const created = data.created;
      message = DISTRICT_MESSAGES.SNACKBAR_CREATE_SUCCESS;
      this.mmDistrictsService.add(created);
    }

    if (type === ModalOverlayServiceCloseEventType.SUBMIT) {
      const updated = data.updated;
      message = DISTRICT_MESSAGES.SNACKBAR_UPDATE_SUCCESS;
      this.mmDistrictsService.update(updated);
    }

    if (type === ModalOverlayServiceCloseEventType.DELETE) {
      this.mmDistrictsService.delete(id);
      message = DISTRICT_MESSAGES.SNACKBAR_DELETE_SUCCESS;
    }

    if (message) {
      this._systemAlertSnackBar.open({
        type: 'success',
        message,
      });
    }
  }

  public async export() {
    await this._districtsService.export();
  }

  private _tableFilter(item: IMingaDistrict, searchString: string) {
    if (!searchString.length) return true;

    const { name, hubspotId } = item;

    return (
      name.toLowerCase().includes(searchString) ||
      hubspotId.toLowerCase().includes(searchString)
    );
  }
}
