import { Observable, Subject } from 'rxjs';

import {
  IMentionSearchChangeEvent,
  IMentionSearchCommitEvent,
  IMentionSearchEndEvent,
  IMentionSearchStartEvent,
  MentionStrategy,
} from './MentionStrategy';

export class TextInputMentionStrategy implements MentionStrategy {
  private element: HTMLInputElement;
  private _onKeydown: (e: KeyboardEvent) => void;
  private _onKeypress: (e: KeyboardEvent) => void;

  private _mentionSearchStartSubject: Subject<IMentionSearchStartEvent>;
  private _mentionSearchEndSubject: Subject<IMentionSearchEndEvent>;
  private _mentionSearchCommitSubject: Subject<IMentionSearchCommitEvent>;
  private _mentionSearchChangeSubject: Subject<IMentionSearchChangeEvent>;

  get onMentionSearchStart(): Observable<IMentionSearchStartEvent> {
    return this._mentionSearchStartSubject.asObservable();
  }

  get onMentionSearchEnd(): Observable<IMentionSearchEndEvent> {
    return this._mentionSearchEndSubject.asObservable();
  }

  get onMentionSearchCommit(): Observable<IMentionSearchCommitEvent> {
    return this._mentionSearchCommitSubject.asObservable();
  }

  get onMentionSearchChange(): Observable<IMentionSearchChangeEvent> {
    return this._mentionSearchChangeSubject.asObservable();
  }

  get searchStarted(): boolean {
    return false;
  }

  constructor() {
    this._mentionSearchStartSubject = new Subject();
    this._mentionSearchEndSubject = new Subject();
    this._mentionSearchCommitSubject = new Subject();
    this._mentionSearchChangeSubject = new Subject();

    this._onKeydown = e => this.onKeydown(e);
    this._onKeypress = e => this.onKeypress(e);
  }

  private onKeydown(e: KeyboardEvent) {}

  private onKeypress(e: KeyboardEvent) {}

  connect(targetElement: HTMLElement) {
    if (!(targetElement instanceof HTMLInputElement)) {
      throw new Error('Element is not an input element');
    }

    if (targetElement.type && targetElement.type !== 'text') {
      throw new Error('Element is not a text input element');
    }

    if (this.element) {
      this.disconnect();
    }

    this.element = targetElement;

    throw new Error('@TODO: Text Input mention strategy');
  }

  disconnect() {
    if (this.element) {
      this.element.removeEventListener('keydown', this._onKeydown);
      this.element.removeEventListener('keypress', this._onKeypress);
    }
  }

  getMentionSearchPos() {
    const pos = { x: 0, y: 0 };

    return pos;
  }

  collapseSelectionAfterMention(mentionIndex: number) {}

  collapseSelectionBeforeMention(mentionIndex: number) {}

  commitMentionSearch() {}

  getMentions() {
    return [];
  }

  removeMention(mentionIndex: number) {}

  selectionEndAfterMention(mentionIndex: number) {}

  selectionEndBeforeMention(mentionIndex: number) {}

  selectionStartAfterMention(mentionIndex: number) {}

  selectionStartBeforeMention(mentionIndex: number) {}
}
