import { Injectable } from '@angular/core';
import { ContentInfoQuery } from 'minga/proto/content/common_pb';
import { Content } from 'minga/proto/content/content_ng_grpc_pb';
import { ContentMetadata } from 'minga/proto/content/content_pb';

export interface IGetContentMetadataOptions {
  contentHash: string;
}

export interface IGetContentContextMetadataOptions {
  contentContextHash: string;
}

export type GetContentMetadataOptions = Partial<IGetContentMetadataOptions> &
  Partial<IGetContentContextMetadataOptions>;

function assertValidGetContentMetadataOptions(
  options: GetContentMetadataOptions,
) {
  if (!options.contentContextHash && !options.contentHash) {
    throw new Error(
      'Invalid get content metadata options. Must supply contentHash or contentContextHash',
    );
  }
}

@Injectable({ providedIn: 'root' })
export class ContentMetadataService {
  private _contentMetadataMemCache: Map<string, ContentMetadata.AsObject>;

  constructor(private _content: Content) {
    this._contentMetadataMemCache = new Map();
  }

  // (re)fetch the content metdata from the server with content context hash
  fetchContentMetadata(
    options: IGetContentContextMetadataOptions,
  ): Promise<ContentMetadata.AsObject>;
  // (re)fetch the content metdata from the server with content hash
  fetchContentMetadata(
    options: IGetContentMetadataOptions,
  ): Promise<ContentMetadata.AsObject>;

  // (re)fetch the content metdata from the server with content hash
  fetchContentMetadata(
    options: GetContentMetadataOptions,
  ): Promise<ContentMetadata.AsObject>;

  async fetchContentMetadata(
    options: GetContentMetadataOptions,
  ): Promise<ContentMetadata.AsObject> {
    const request = new ContentInfoQuery();
    assertValidGetContentMetadataOptions(options);

    if (options.contentContextHash) {
      request.setContentContextHash(options.contentContextHash);
    } else if (options.contentHash) {
      request.setContentHash(options.contentHash);
    }

    const response = await this._content.getContentMetadata(request);

    return response.toObject();
  }

  // get the content metdata from the server with content context hash and
  // fetch if needed.
  getContentMetadata(
    options: IGetContentContextMetadataOptions,
  ): Promise<ContentMetadata.AsObject>;
  // get the content metdata from the server with content hash and fetch if
  // needed.
  getContentMetadata(
    options: IGetContentMetadataOptions,
  ): Promise<ContentMetadata.AsObject>;

  getContentMetadata(
    options: GetContentMetadataOptions,
  ): Promise<ContentMetadata.AsObject>;

  async getContentMetadata(
    options: GetContentMetadataOptions,
  ): Promise<ContentMetadata.AsObject> {
    assertValidGetContentMetadataOptions(options);

    if (options.contentHash) {
      if (this._contentMetadataMemCache.has(options.contentHash)) {
        return this._contentMetadataMemCache.get(options.contentHash);
      }
    } else if (options.contentContextHash) {
      if (this._contentMetadataMemCache.has(options.contentContextHash)) {
        return this._contentMetadataMemCache.get(options.contentContextHash);
      }
    }

    const contentMetadata = await this.fetchContentMetadata(options);

    this._contentMetadataMemCache.set(
      contentMetadata.contentHash,
      contentMetadata,
    );

    if (options.contentContextHash) {
      this._contentMetadataMemCache.set(
        options.contentContextHash,
        contentMetadata,
      );
    }
    return contentMetadata;
  }

  clearContentMetadata(hash: string) {
    this._contentMetadataMemCache.delete(hash);
  }
}
