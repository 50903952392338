<!--  Main Content -->
<div>
  <ng-container *ngTemplateOutlet="chartSectionTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="topUsersTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="topCreatorsTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="topBehaviorsTemplate"></ng-container>
</div>

<!-- Chart Template-->
<ng-template #chartSectionTemplate>
  <mg-manager-report-chart-section [isLoading]="summary.isLoading">
    <ul
      class="metrics-list"
      id="metrics">
      <li>
        <mg-count-tile
          size="auto"
          [count]="summary.totalBehaviors"
          [label]="MESSAGES.METRIC_TITLE_TRACKED_BEHAVIORS"
          [color]="'#99BAAD'"
          [icon]="'praise-1'"
          [onDisableHover]="true"
          [backgroundColor]="
            (layout.useLegacyLayout$ | async) ? 'grey' : 'white'
          ">
        </mg-count-tile>
      </li>
    </ul>
    <ul
      class="metrics-list pbis"
      id="metrics">
      <li class="praise-color">
        <mg-count-tile
          size="auto"
          [count]="summary.totalPraise"
          label=""
          [color]="'#99BAAD'"
          [staticImage]="'/assets/teacher-tools/praise-icon.svg'"
          [onDisableHover]="true"
          [backgroundColor]="
            (layout.useLegacyLayout$ | async) ? 'grey' : 'white'
          ">
        </mg-count-tile>
      </li>
      <li class="guidance-color">
        <mg-count-tile
          size="auto"
          [count]="summary.totalGuidance"
          label=""
          [color]="'#DB6578'"
          [staticImage]="'/assets/teacher-tools/behavior-icon.svg'"
          [onDisableHover]="true"
          [backgroundColor]="
            (layout.useLegacyLayout$ | async) ? 'grey' : 'white'
          ">
        </mg-count-tile>
      </li>
    </ul>
    <div id="chart">
      <mg-manager-report-chart
        [xLabels]="summary.xLabels"
        [dataSets]="summary.dataSets"
        [noStats]="summary.noStats"
        [chartOptions]="CHART_OPTIONS">
      </mg-manager-report-chart>
    </div>
  </mg-manager-report-chart-section>
</ng-template>

<!-- Top Users Template -->
<ng-template #topUsersTemplate>
  <div [class.section]="layout.useLegacyLayout$ | async">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="12px">
      <mg-text variant="header-sm-secondary">
        {{ MESSAGES.SECTION_TITLE_STUDENTS }}
      </mg-text>
      <mg-btn
        variant="outlined"
        size="small"
        [id]="'pbis-reports-summary-click-see-all-students'"
        [routerLink]="['/pbis', 'reports', REPORT_TYPES.PBIS_STUDENT]">
        {{ MESSAGES.SECTION_BUTTON_SEE_ALL }}
      </mg-btn>
    </div>
    <mg-report-table
      matSort
      [showPaginator]="false"
      [disableMaxHeight]="true"
      [dataSourceService]="studentsDataservice"
      [sortable]="false">
      <mg-history-column [datasource]="studentsDataservice"></mg-history-column>
      <mg-summary-column
        [datasource]="studentsDataservice"
        (viewSummary)="goUp()">
      </mg-summary-column>
      <mg-role-column [datasource]="studentsDataservice"></mg-role-column>
    </mg-report-table>
  </div>
</ng-template>

<!-- Top Creators Template -->
<ng-template #topCreatorsTemplate>
  <div [class.section]="layout.useLegacyLayout$ | async">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="12px">
      <mg-text variant="header-sm-secondary">
        {{ MESSAGES.SECTION_TITLE_STAFF }}
      </mg-text>
      <mg-btn
        variant="outlined"
        size="small"
        [id]="'pbis-reports-summary-click-see-all-staff'"
        [routerLink]="['/pbis', 'reports', REPORT_TYPES.PBIS_STAFF]">
        {{ MESSAGES.SECTION_BUTTON_SEE_ALL }}
      </mg-btn>
    </div>
    <mg-report-table
      matSort
      [dataSourceService]="staffDataservice"
      [showPaginator]="false"
      [disableMaxHeight]="true"
      [sortable]="false">
      <mg-history-column [datasource]="staffDataservice"></mg-history-column>
      <mg-summary-column
        [datasource]="staffDataservice"
        (viewSummary)="goUp()">
      </mg-summary-column>
      <mg-role-column [datasource]="staffDataservice"></mg-role-column>
    </mg-report-table>
  </div>
</ng-template>

<!-- Top Passes Template -->
<ng-template #topBehaviorsTemplate>
  <div [class.section]="layout.useLegacyLayout$ | async">
    <mg-text variant="header-sm-secondary">
      {{ MESSAGES.SECTION_TITLE_BEHAVIORS }}
    </mg-text>
    <div
      class="top-behavior"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start start"
      fxLayoutAlign.xs="center center"
      fxLayoutGap="20px">
      <div
        fxFlex="50"
        fxFlex.xs="grow">
        <div
          class="top-behavior-title"
          fxLayoutAlign="start center"
          fxLayoutGap="10px">
          <mg-icon class="praise-color">praise</mg-icon>
          <mg-text variant="body-sm-secondary">
            <span class="praise-color">
              {{ MESSAGES.PRAISE }}
            </span>
          </mg-text>
          <mg-btn
            variant="outlined"
            size="small"
            [id]="'pbis-reports-summary-click-see-all-praise'"
            [routerLink]="['/pbis', 'reports', REPORT_TYPES.PBIS_TYPES]">
            {{ MESSAGES.SECTION_BUTTON_SEE_ALL }}
          </mg-btn>
        </div>
        <div>
          <mg-report-table
            matSort
            [dataSourceService]="behaviorsDataservice"
            [filterInfo]="praiseFilter"
            [showPaginator]="false"
            [disableMaxHeight]="true"
            [sortable]="false">
            <mg-history-column
              [datasource]="behaviorsDataservice"></mg-history-column>
            <mg-summary-column
              [datasource]="behaviorsDataservice"></mg-summary-column>
          </mg-report-table>
        </div>
      </div>
      <div
        fxFlex="50"
        fxFlex.xs="grow">
        <div
          class="top-behavior-title"
          fxLayoutAlign="start center"
          fxLayoutGap="10px">
          <mg-icon class="guidance-color">guidance</mg-icon>
          <mg-text variant="body-sm-secondary">
            <span class="guidance-color">
              {{ MESSAGES.GUIDANCE }}
            </span>
          </mg-text>
          <mg-btn
            variant="outlined"
            size="small"
            [id]="'pbis-reports-summary-click-see-all-guid'"
            [routerLink]="['/pbis', 'reports', REPORT_TYPES.PBIS_TYPES]">
            {{ MESSAGES.SECTION_BUTTON_SEE_ALL }}
          </mg-btn>
        </div>
        <div>
          <mg-report-table
            matSort
            [dataSourceService]="behaviorsDataservice"
            [filterInfo]="guidanceFilter"
            [showPaginator]="false"
            [disableMaxHeight]="true"
            [sortable]="false">
            <mg-history-column
              [datasource]="behaviorsDataservice"></mg-history-column>
            <mg-summary-column
              [datasource]="behaviorsDataservice"></mg-summary-column>
          </mg-report-table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
