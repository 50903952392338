import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[mgCarouselItem]',
})
export class CarouselItemDirective {}

@Directive({
  selector: '[mgCarouselDef]',
})
export class CarouselDefDirective<T> {
  when: (index: number, nodeData: T) => boolean;

  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[mgCarouselOutlet]',
})
export class CarouselOutletDirective {
  constructor(public viewContainer: ViewContainerRef) {}
}
