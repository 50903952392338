import { Injectable } from '@angular/core';

//TS2742
import { Actions, createEffect, ofType } from '@ngrx/effects';
//TS2742
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

//TS2742
import { EmailContentService } from 'minga/app/src/app/content-common/services/EmailContent';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  ContentActions,
  sendContentEmail,
  sendContentEmailFailed,
  sendContentEmailSucceeded,
} from '../actions';

@Injectable()
export class ContentStoreEffects {
  // clang-format off
  readonly sendContentEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendContentEmail),
      map(action => ({
        ...action,
        sendPromise: this.emailContent.send(action.contentHash, action.options),
      })),
      switchMap(action =>
        from(action.sendPromise).pipe(
          map(() => sendContentEmailSucceeded(action)),
          catchError(error => of(sendContentEmailFailed({ ...action, error }))),
        ),
      ),
    ),
  );

  readonly sendContentEmailFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendContentEmailFailed),
        tap(action => console.error(action.error)),
        map(() =>
          this._systemAlertSnackBar.error(
            'Unable to send email at this time. Please try again later.',
          ),
        ),
      ),
    { dispatch: false },
  );
  // clang-format on

  constructor(
    private actions$: Actions<ContentActions>,
    private emailContent: EmailContentService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}
}
