import { MatFormFieldDefaultOptions } from '@angular/material/form-field';

export enum AppMessage {
  // External link modal
  EXTERNAL_LINK_MODAL_TITLE = 'You are about to leave Minga.',
  EXTERNAL_LINK_MODAL_MESSAGE = 'The link you clicked is an external link and Minga cannot validate its safety',
  EXTERNAL_LINK_MODAL_BUTTON_CANCEL = 'Cancel',
  EXTERNAL_LINK_MODAL_BUTTON_CONFIRM = 'Confirm',
}

/**
 * List of origins we allow to go through without an external link dialog.
 */
export const APP_LINKING_ALLOWED_ORIGINS = [
  'https://minga.io',
  'http://minga.io',
  'https://app.minga.io',
  'http://app.minga.io',
  'https://minga.helpdocs.com',
] as const;

/**
 * List of protocols we allow to go through without an external link dialog.
 */
export const APP_LINKING_ALLOWED_PROTOCOLS = [
  'minga:',
  'mailto:',
  'tel:',
  'javascript:', // Several places in the app use 'javascript:;` as a noop anchor
] as const;

export const MULTI_OUTLET_HACK_FINISH_TIMEOUT = 3000 as const;

export const GLOBAL_FLOATING_PORTAL_ZINDEX = 1010 as const;

export const MG_MAT_FORM_FIELD_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  hideRequiredMarker: true, // default to not show asterisk
};
