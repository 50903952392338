<div class="mg-country-selector">
  <mg-country
    country="CAN"
    [active]="value == 'CAN'"
    (click)="_setValue('CAN')"></mg-country>
  <mg-country
    country="USA"
    [active]="value == 'USA'"
    (click)="_setValue('USA')"></mg-country>
  <mg-country
    country="INTL"
    [active]="value == 'INTL'"
    (click)="_setValue('INTL')"></mg-country>
</div>
