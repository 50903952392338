import { Injectable } from '@angular/core';

import { ModalOverlayService } from '@shared/components/modal-overlay';

import { MmDatePresetsComponent } from '../mm-date-presets.component';

@Injectable()
export class MmDatePresetsService {
  constructor(private _modalOverlay: ModalOverlayService) {}

  public openPresetsList() {
    return this._modalOverlay.open(MmDatePresetsComponent, {
      disposeOnNavigation: false,
    });
  }
}
