import { Injectable } from '@angular/core';

import { FlexReportUnregisteredTableData } from 'minga/domain/flexTime';

import { PeopleSelectorService } from '@modules/people-selector';

import { SystemAlertModalService } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimeActivityInstanceService } from '@shared/services/flex-time';
import { FlexTimeRegistrationValidationService } from '@shared/services/flex-time/flex-time-registration-validation.service';
import { ReportActionService } from '@shared/services/reports';

import { FtmReportsUnregisteredDatasourceService } from './ftm-reports-unreg-data.service';

@Injectable()
export class FtmReportActionService extends ReportActionService<FlexReportUnregisteredTableData> {
  constructor(
    public snackbar: SystemAlertSnackBarService,
    public alertModal: SystemAlertModalService,
    private _flexInstanceService: FlexTimeActivityInstanceService,
    private _ftmDataSourceService: FtmReportsUnregisteredDatasourceService,
    public _peopleSelector: PeopleSelectorService,
    private _ftRegistrationValidation: FlexTimeRegistrationValidationService,
  ) {
    super(snackbar, alertModal);
  }

  /** Abstract methods */
  public setTotalSelectable(total: number): void {
    this.totalSelectable = total;
  }

  /** Action methods */
  public async assignActivity(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    const activityInstance =
      await this._flexInstanceService.openActivitySelector({
        selectorType: 'assign',
      });

    await this._ftRegistrationValidation.assign({
      activityId: activityInstance.id,
      periodId: activityInstance.flexTimePeriodId,
      selectedPeople: this.getSelection(),
    });
    this._clearSelectionAndRefetch();

    return true;
  }

  public async registerToActivity(): Promise<boolean> {
    if (!this.isSelectionValid()) return;
    const activityInstance =
      await this._flexInstanceService.openActivitySelector({
        selectorType: 'assign',
      });

    await this._ftRegistrationValidation.register({
      activityId: activityInstance.id,
      periodId: activityInstance.flexTimePeriodId,
      selectedPeople: this.getSelection(),
    });
    this._clearSelectionAndRefetch();

    return true;
  }

  public getFlexTimeSelectionMessage(): string {
    const data = this.getSelection() as FlexReportUnregisteredTableData[];
    return `${data.length} selected`;
  }

  private async _clearSelectionAndRefetch() {
    await this._ftmDataSourceService.fetch(0, 100);
    this.clearSelection();
  }
}
