<div
  [fxHide]="
    !(dashboardService.data$ | async)?.length ||
    !(filteredData$ | async)?.length
  "
  class="table-container">
  <mat-table
    class="table"
    matSort
    [dataSource]="dataSource"
    [trackBy]="trackById"
    [ngStyle]="tableMinWidth">
    <!-- Go To Minga -->
    <ng-container
      [matColumnDef]="TABLE_COLUMNS.GO_MINGA"
      sticky>
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <div class="first-col-padding-fix"></div>
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis first-col-padding-fix"
          [class.no-value]="!minga?.hash">
          <mg-icon
            (click)="
              dashboardService.openSelectedMinga(minga.hash);
              $event.stopPropagation()
            "
            >forward
          </mg-icon>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Minga Name -->
    <ng-container
      [matColumnDef]="TABLE_COLUMNS.MINGA_NAME"
      sticky>
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <div>
          {{ MESSAGES.COLUMN_LABEL_NAME }}
        </div>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let minga"
        matTooltip="{{ minga.name || undefined }}">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.name">
          {{ minga.name || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- District -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.DISTRICT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_DISTRICT }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let minga"
        matTooltip="{{ minga.district || undefined }}">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.district">
          {{ minga?.district || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Hubspot District ID -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.DISTRICT_ID">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_DISTRICT_ID }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let minga"
        matTooltip="{{ minga.districtId || undefined }}">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.districtId">
          {{ minga?.districtId || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- City -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.CITY">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_CITY }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let minga"
        matTooltip="{{ minga.city || undefined }}">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.city">
          {{ minga?.city || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- State -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.STATE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_STATE }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let minga"
        matTooltip="{{ minga.state || undefined }}">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.state">
          {{ minga?.state || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Partner -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.PARTNER">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_PARTNER }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let minga"
        matTooltip="{{ minga.partner || undefined }}">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.partner">
          {{ minga?.partner || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- SIS -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.SIS">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_SIS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div class="multiline-ellipsis">
          <div
            class="multiline-ellipsis"
            [class.no-value]="!minga?.sisType && !minga?.rostering">
            {{ minga?.sisType || minga?.rostering || 'N/A' }}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- SIS -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.SIS_SYNC">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.SIS_SYNC] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          *ngIf="minga?.sisActive; else xTemplate">
          <span class="material-icons-outlined">done</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Type -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.TYPE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_TYPE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.status">
          {{ minga?.status || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Status -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.STATUS">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_STATUS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.paused">
          {{ minga?.paused ? 'Paused' : 'Active' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Total Students -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.TOTAL_STUDENTS">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_TOTAL_STUDENTS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.totalStudents">
          {{ minga?.totalStudents || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Registered Students -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.REGISTERED_STUDENTS">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_REGISTERED_STUDENTS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.registeredStudents">
          {{ minga?.registeredStudents || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Registered Students % -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.REGISTERED_STUDENTS_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.REGISTERED_STUDENTS_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.registeredCount">
          {{ minga?.registeredStudentsPct || 0 }}%
        </div>
      </mat-cell>
    </ng-container>

    <!-- Unregistered Students -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.UNREGISTERED_STUDENTS">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_UNREGISTERED_STUDENTS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.unregisteredStudents">
          {{ minga.unregisteredStudents }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Unregistered Students % -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.UNREGISTERED_STUDENTS_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.UNREGISTERED_STUDENTS_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.totalStudents">
          {{ minga?.unregisteredStudentsPct || 0 }}%
        </div>
      </mat-cell>
    </ng-container>

    <!-- Total Staff -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.TOTAL_STAFF">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_TOTAL_STAFF }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.totalStaff">
          {{ minga?.totalStaff || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Registered Staff -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.REGISTERED_STAFF">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_REGISTERED_STAFF }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.registeredStaff">
          {{ minga?.registeredStaff }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Registered Staff % -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.REGISTERED_STAFF_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.REGISTERED_STAFF_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.registeredStaff">
          {{ minga?.registeredStaffPct || 0 }}%
        </div>
      </mat-cell>
    </ng-container>

    <!-- UnRegistered Staff -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.UNREGISTERED_STAFF">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_UNREGISTERED_STAFF }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.unregisteredStaff">
          {{ minga?.unregisteredStaff || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- UnRegistered Staff % -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.UNREGISTERED_STAFF_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.UNREGISTERED_STAFF_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.unregisteredStaff">
          {{ minga?.unregisteredStaffPct || 0 }}%
        </div>
      </mat-cell>
    </ng-container>

    <!-- Total Count -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.TOTAL_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_PEOPLE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.totalCount">
          {{ minga?.totalCount || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Registered Count -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.REGISTERED_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_REGISTERED }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.registeredCount">
          {{ minga?.registeredCount || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Registered % -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.REGISTERED_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.REGISTERED_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.registeredCount">
          {{ minga?.registeredPct || 0 }}%
        </div>
      </mat-cell>
    </ng-container>

    <!-- UnRegistered -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.UNREGISTERED">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_UNREGISTERED }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.unregistered">
          {{ minga.unregistered }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- UnRegistered % -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.UNREGISTERED_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.UNREGISTERED_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.totalCount">
          {{ minga?.unregisteredPct || 0 }}%
        </div>
      </mat-cell>
    </ng-container>

    <!-- People -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.SIZE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_SIZE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.size">
          {{ minga?.size || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Daily Active -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.DAILY_ACTIVE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_ACTIVITY_DAILY }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.dailyActiveCount">
          {{ minga?.dailyActiveCount || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Daily Active % -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.DAILY_ACTIVE_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.DAILY_ACTIVE_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.dailyActiveCount">
          {{ minga?.dailyActivePct || 0 }}%
        </div>
      </mat-cell>
    </ng-container>

    <!-- Weekly Active -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.WEEKLY_ACTIVE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_ACTIVITY_WEEKLY }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.weeklyActiveCount">
          {{ minga?.weeklyActiveCount || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Weekly Active % -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.WEEKLY_ACTIVE_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.WEEKLY_ACTIVE_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.weeklyActiveCount">
          {{ minga?.weeklyActivePct || 0 }}%
        </div>
      </mat-cell>
    </ng-container>

    <!-- Monthly Active -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.MONTHLY_ACTIVE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_ACTIVITY_MONTHLY }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.monthlyActiveCount">
          {{ minga?.monthlyActiveCount || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Monthly Active % -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.MONTHLY_ACTIVE_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.MONTHLY_ACTIVE_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.monthlyActiveCount">
          {{ minga?.monthlyActivePct || 0 }}%
        </div>
      </mat-cell>
    </ng-container>

    <!-- Sticker Count -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.STICKER_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_STICKERS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.stickerCount">
          {{ minga?.stickerCount || 0 }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Hall Pass Count -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.HALL_PASS_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <div
          class="multiline-ellipsis"
          title="{{ MESSAGES.COLUMN_LABEL_HALL_PASSES }}">
          {{ MESSAGES.COLUMN_LABEL_HALL_PASSES }}
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="
            !minga?.mingaHealthCheckStats?.hallPassCountInLast30Days
          ">
          {{
            minga?.mingaHealthCheckStats?.hallPassCountInLast30Days | zeroNotNA
          }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Posts Count -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.POSTS_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_POSTS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="
            !minga?.mingaHealthCheckStats?.postCountInLast30Days
          ">
          {{ minga?.mingaHealthCheckStats?.postCountInLast30Days | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Groups Count -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.GROUPS_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_GROUPS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.groupCount">
          {{ minga?.mingaHealthCheckStats?.groupCount | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Checkins Count -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.CHECKIN_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <div
          class="multiline-ellipsis"
          title="{{ MESSAGES.COLUMN_LABEL_CHECKIN }}">
          {{ MESSAGES.COLUMN_LABEL_CHECKIN }}
        </div>
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.checkinCount">
          {{ minga?.mingaHealthCheckStats?.checkinCount | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Behaviors Count -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.BEHAVIOR_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_BEHAVIORS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="
            !minga?.mingaHealthCheckStats?.numberOfBehaviorsInLast30Days
          ">
          {{
            minga?.mingaHealthCheckStats?.numberOfBehaviorsInLast30Days
              | zeroNotNA
          }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Date Created -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.DATE_CREATED">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_DATE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.createdAt">
          {{ minga?.createdAt || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Subscription Start Date -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.SUBSCRIPTION_START_DATE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_SUBSCRIPTION_START_DATE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.subscriptionStartDate">
          {{ minga?.subscriptionStartDate || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Subscription End Date -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.SUBSCRIPTION_END_DATE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_SUBSCRIPTION_END_DATE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.subscriptionEndDate">
          {{ minga?.subscriptionEndDate || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- HubSpot Id -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.HUB_SPOT_ID">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <div>
          {{ MESSAGES.COLUMN_LABEL_HUB_SPOT }}
        </div>
      </mat-header-cell>
      <mat-cell
        *matCellDef="let minga"
        matTooltip="{{ minga.hubSpotId || undefined }}">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.hubSpotId">
          {{ minga.hubSpotId || 'N/A' }}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Modules -->
    <ng-container [matColumnDef]="TABLE_COLUMNS.ID">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_ID }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div class="multiline-ellipsis">
          <div *ngIf="minga?.featureToggle?.studentIdEnabled; else xTemplate">
            <span class="material-icons-outlined">done</span>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.HP">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_HP }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          *ngIf="minga?.featureToggle?.hallPassEnabled; else xTemplate">
          <span class="material-icons-outlined">done</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CM">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_CM }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          *ngIf="minga?.featureToggle?.communityEnabled; else xTemplate">
          <span class="material-icons-outlined">done</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.BH">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_BH }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          *ngIf="minga?.featureToggle?.trackingEnabled; else xTemplate">
          <span class="material-icons-outlined">done</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.FL">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_FL }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          *ngIf="minga?.featureToggle?.flexTimeEnabled; else xTemplate">
          <span class="material-icons-outlined">done</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CH">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MESSAGES.COLUMN_LABEL_CH }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          *ngIf="minga?.featureToggle?.checkinEnabled; else xTemplate">
          <span class="material-icons-outlined">done</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.SIS_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.SIS_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.sisScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.MONTHLY_ACTIVE_USERS_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.MONTHLY_ACTIVE_USERS_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.monthlyActiveUsersScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.STUDENTS_REGISTERED_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.STUDENTS_REGISTERED_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.studentsRegisteredScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.TOTAL_USERS_ADDED_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.TOTAL_USERS_ADDED_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.totalUsersAddedScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.HALL_PASS_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.HALL_PASS_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.hallPassScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.POST_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.POST_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.postScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.GROUP_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.GROUP_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.groupScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.BEHAVIOR_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.BEHAVIOR_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.behaviorScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CONSEQUENCE_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.CONSEQUENCE_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.consequenceScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.POINT_ACTION_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.POINT_ACTION_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.pointActionScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.POINTS_RECEIVED_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.POINTS_RECEIVED_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.pointsReceivedScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CHECK_IN_REASON_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.CHECK_IN_REASON_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.checkInReasonScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CHECK_IN_EVENT_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.CHECK_IN_EVENT_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.checkInEventScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.FLEX_REGISTERED_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.FLEX_REGISTERED_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.flexRegisteredScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.FLEX_CHECK_IN_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.FLEX_CHECK_IN_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.flexCheckInScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.MINGA_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.MINGA_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.mingaScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.MINGA_SCORE_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.MINGA_SCORE_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.mingaScorePct,
            suffix: '%'
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.GENERAL_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.GENERAL_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.generalScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.HALL_PASS_AVERAGE_SCORE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.HALL_PASS_AVERAGE_SCORE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <ng-container
          [ngTemplateOutlet]="scoreTemplate"
          [ngTemplateOutletContext]="{
            $implicit: minga?.mingaHealthCheckStats?.hallPassAvgScore
          }"></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.TOTAL_USERS_ADDED">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.TOTAL_USERS_ADDED] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="
            !minga?.mingaHealthCheckStats?.totalUsersAddedPercent
          ">
          {{
            (minga?.mingaHealthCheckStats?.totalUsersAddedPercent || 0) + '%'
          }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.BEHAVIOR_AVERAGE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.BEHAVIOR_AVERAGE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.behaviorAvg">
          {{ minga?.mingaHealthCheckStats?.behaviorAvg | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CONSEQUENCE_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.CONSEQUENCE_COUNT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="
            !minga?.mingaHealthCheckStats
              ?.numberOfConsquencesIssuedByAutomationInLast30Days
          ">
          {{
            minga?.mingaHealthCheckStats
              ?.numberOfConsquencesIssuedByAutomationInLast30Days | zeroNotNA
          }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CONSEQUENCE_AVERAGE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.CONSEQUENCE_AVERAGE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.consequenceAvg">
          {{ minga?.mingaHealthCheckStats?.consequenceAvg | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.POINT_ACTION_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.POINT_ACTION_COUNT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.pointActionCount">
          {{ minga?.mingaHealthCheckStats?.pointActionCount | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.POINT_ACTION_AVERAGE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.POINT_ACTION_AVERAGE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.pointActionAvg">
          {{ minga?.mingaHealthCheckStats?.pointActionAvg | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.POINTS_RECEIVED_PCT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.POINTS_RECEIVED_PCT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="
            !minga?.mingaHealthCheckStats?.studentsPointsReceivedPercent
          ">
          {{
            minga?.mingaHealthCheckStats?.studentsPointsReceivedPercent
              | zeroNotNA
          }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CHECKIN_AVERAGE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.CHECKIN_AVERAGE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.checkInReasonAvg">
          {{ minga?.mingaHealthCheckStats?.checkInReasonAvg | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CHECK_IN_EVENT_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.CHECK_IN_EVENT_COUNT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.checkInEventCount">
          {{ minga?.mingaHealthCheckStats?.checkInEventCount | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.CHECK_IN_EVENT_AVERAGE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.CHECK_IN_EVENT_AVERAGE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.checkInEventAvg">
          {{ minga?.mingaHealthCheckStats?.checkInEventAvg | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.FLEX_REGISTERED_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.FLEX_REGISTERED_COUNT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="
            !minga?.mingaHealthCheckStats?.flexRegistrationCount
          ">
          {{ minga?.mingaHealthCheckStats?.flexRegistrationCount | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.FLEX_REGISTERED_AVERAGE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.FLEX_REGISTERED_AVERAGE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.flexRegisteredAvg">
          {{ minga?.mingaHealthCheckStats?.flexRegisteredAvg | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.FLEX_CHECK_IN_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.FLEX_CHECK_IN_COUNT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.flexCheckinCount">
          {{ minga?.mingaHealthCheckStats?.flexCheckinCount | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.FLEX_CHECK_IN_AVERAGE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.FLEX_CHECK_IN_AVERAGE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.flexCheckInAvg">
          {{ minga?.mingaHealthCheckStats?.flexCheckInAvg | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.HALL_PASS_AVERAGE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.HALL_PASS_AVERAGE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.hallPassAvg">
          {{ minga?.mingaHealthCheckStats?.hallPassAvg | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.SMS_SENT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.SMS_SENT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.smsCount">
          {{ minga?.smsCount | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.SMS_REMAINING">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.SMS_REMAINING] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.smsRemaining">
          {{ minga?.smsRemaining | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.USER_LIST_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.USER_LIST_COUNT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.mingaHealthCheckStats?.userListCount">
          {{ minga?.mingaHealthCheckStats?.userListCount | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.SIS_LIST_ACTIVE">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.SIS_LIST_ACTIVE] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          *ngIf="minga?.sisListActive; else xTemplate">
          <span class="material-icons-outlined">done</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMNS.SIS_LIST_COUNT">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ COLUMNS_TO_MESSAGES[TABLE_COLUMNS.SIS_LIST_COUNT] }}
      </mat-header-cell>
      <mat-cell *matCellDef="let minga">
        <div
          class="multiline-ellipsis"
          [class.no-value]="!minga?.sisListCount">
          {{ minga?.sisListCount | zeroNotNA }}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableService.displayedColumn$ | async">
    </mat-header-row>
    <mat-row
      *matRowDef="let row; columns: tableService.displayedColumn$ | async"
      (click)="dashboardService.openMingaEditModal(row.hash)"
      [class.no-action]="(isSuperAdmin$ | async) === false">
    </mat-row>
  </mat-table>
</div>

<!-- No Results -->
<div
  *ngIf="
    !(dashboardService.data$ | async)?.length ||
    !(filteredData$ | async)?.length
  "
  class="empty-state-container">
  <mg-empty-state
    [title]="MESSAGES.EMPTY_STATE_TITLE"
    [subtitle]="MESSAGES.EMPTY_STATE_SUBTITLE">
  </mg-empty-state>
</div>

<!-- Loading -->
<div
  *ngIf="dashboardService.isLoading$ | async"
  class="loading-container">
  <mg-spinner
    [diameter]="40"
    [thickness]="3">
  </mg-spinner>
</div>

<!-- Pagination -->
<mat-paginator
  [pageSizeOptions]="[100, 150, 200]"
  showFirstLastButtons>
</mat-paginator>

<ng-template #xTemplate>
  <div class="no-value">X</div>
</ng-template>

<ng-template
  #scoreTemplate
  let-score
  let-suffix="suffix">
  <div
    class="multiline-ellipsis"
    [class.no-value]="!score"
    [ngClass]="getScoreClass(score)">
    {{ score ? score.score + (suffix ? suffix : '') : 'N/A' }}
  </div>
</ng-template>
