import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';

import { MgIconModule } from 'minga/app/src/app/icon';
import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgFormFieldModule } from 'src/app/form-field';
import { MgPipesModule } from 'src/app/pipes';

import { GenericButtonModule } from '@shared/components/button';
import { FormModule } from '@shared/components/form';
import { OverlayModule } from '@shared/components/modal-overlay';
import { ArrayPipesModule } from '@shared/pipes';

import { TextModule } from '../text/text.module';
import { RedeemPointsModalComponent } from './redeem-points-modal.component';
import { RedeemPointsModalService } from './services';

@NgModule({
  imports: [
    // Minga dependencies
    MgEmptyStateModule,
    GenericButtonModule,
    MgPipesModule,
    ArrayPipesModule,
    MgIconModule,
    MgFormFieldModule,
    OverlayModule,
    FormModule,
    TextModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatTableModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
  ],
  exports: [RedeemPointsModalComponent],
  declarations: [RedeemPointsModalComponent],
  providers: [RedeemPointsModalService],
})
export class RedeemPointsModalModule {}
