import { NgModule } from '@angular/core';

import { MgNumeralPipe } from 'minga/app/src/app/pipes/MgNumeral.pipe';
import { MgRangePipe } from 'minga/app/src/app/pipes/MgRange.pipe';
import { MgStripHtmlPipe } from 'minga/app/src/app/pipes/MgStripHtml.pipe';

import { MgAssetUrlPipe } from './MgAssetUrl.pipe';
import { MgFormatTimePipe } from './MgFormatTime.pipe';
import { MgImageUrlsPipe } from './MgImageUrls.pipe';
import { MgSafeHtmlPipe } from './MgSafeHtml.pipe';
import { MgSecondsToTimePipe } from './MgSecondsToTime.pipe';
import { MgTextClipperPipe } from './MgTextClipper.pipe';
import { MgFormatTimeRangePipe } from './MgTimeRange.pipe';
import { MgZeroNotNAPipe } from './MgZeroNotNA.pipe';

@NgModule({
  declarations: [
    MgAssetUrlPipe,
    MgSafeHtmlPipe,
    MgStripHtmlPipe,
    MgImageUrlsPipe,
    MgTextClipperPipe,
    MgRangePipe,
    MgNumeralPipe,
    MgFormatTimeRangePipe,
    MgFormatTimePipe,
    MgSecondsToTimePipe,
    MgZeroNotNAPipe,
  ],
  exports: [
    MgAssetUrlPipe,
    MgSafeHtmlPipe,
    MgStripHtmlPipe,
    MgImageUrlsPipe,
    MgTextClipperPipe,
    MgRangePipe,
    MgNumeralPipe,
    MgFormatTimeRangePipe,
    MgFormatTimePipe,
    MgSecondsToTimePipe,
    MgZeroNotNAPipe,
  ],
})
export class MgPipesModule {}
