import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ActiveLayoutService } from '../ActiveLayout.service';
import { LayoutComponentBase } from '../LayoutComponentBase';

/**
 * Component for displaying the 'silhouette' style landing layout.
 */
@Component({
  selector: 'mg-silh-landing-layout',
  templateUrl: './SilhLandingLayout.component.html',
  styleUrls: ['./SilhLandingLayout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SilhLandingLayoutComponent
  extends LayoutComponentBase
  implements OnInit, OnDestroy
{
  get layoutName() {
    return 'silh-landing';
  }

  constructor(activeLayoutService: ActiveLayoutService) {
    super(activeLayoutService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
