<mg-layout-legacy-content>
  <ng-template #action>
    <mg-btn
      *ngIf="
        ('GALLERY_ADD_PHOTO' | hasPermission) &&
        (isPhotoGalleryEnabled$ | async)
      "
      variant="filled"
      [routerLink]="['', { outlets: { o: ['gallery', 'add'] } }]">
      Add photo
    </mg-btn>
  </ng-template>

  <mg-gallery-lightbox
    #multipleItemGalleryLightBox
    (deleteItem)="onDeleteItem($event)"
    (reportItem)="onReportItem($event)"
    (resolveItem)="onResolveItem($event)"
    [dataSource]="galleryDataSource">
  </mg-gallery-lightbox>

  <mg-gallery-lightbox
    #singleItemGalleryLightBox
    (deleteItem)="onDeleteItem($event)"
    (closed)="onLightboxClose($event)"
    (reportItem)="onReportItem($event)"
    (resolveItem)="onResolveItem($event)"
    [dataSource]="singleGalleryDataSource">
  </mg-gallery-lightbox>

  <div class="mg-smallcopy gallery-subtitle">
    <span *ngIf="eventContent$ | async">
      {{ (eventContent$ | async)?.contentTagTitle }}
    </span>
    <span *ngIf="group$ | async">{{ (group$ | async)?.name }}</span>
  </div>

  <!-- Feed -->
  <mg-home-gallery-feed
    [contentHash]="(eventContent$ | async)?.hash"
    [groupHash]="(group$ | async)?.hash"
    [query]="searchTextDebounced$ | async"
    [maxAspectRatio]="aspectRatioPerRow$ | async"
    [minAspectRatio]="aspectRatioPerRow$ | async"
    (openItem)="openImage($event)"
    (streamControlCreated)="onStreamControlCreated($event)">
  </mg-home-gallery-feed>
</mg-layout-legacy-content>

<!-- Delete photo dialog -->
<mg-dialog #deletePhotoDialog>
  <mg-dialog-title>Are you sure you want to delete this photo?</mg-dialog-title>
  <mg-dialog-content> This action cannot be undone. </mg-dialog-content>
  <mg-button
    [mg-dialog-action]="false"
    class="normal"
    type="text">
    Cancel
  </mg-button>
  <mg-button
    [mg-dialog-action]="true"
    class="normal"
    type="outline">
    Delete Photo
  </mg-button>
</mg-dialog>
