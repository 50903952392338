export enum BehaviorManagerMessages {
  PAGE_TITLE = 'Behavior Manager',
  PAGE_TITLE_DASHBOARD = 'Dashboard',
  PAGE_TITLE_REPORTS = 'Reports',
  PAGE_TITLE_BEHAVE_AND_CONSEQ = 'Behaviors & Consequences',
  PAGE_TITLE_SETTINGS = 'Settings',
  PAGE_TITLE_ONLY_BEHAVIORS = 'Behaviors',
  PAGE_TITLE_CONSEQUENCES = 'Consequences',
  PAGE_TITLE_AUTOMATIONS = 'Automations',
  PAGE_HEADER_TITLE = 'Behavior',
}

export enum BehaviorManagerRoutes {
  ROOT = 'behaviors',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  BEHAVIORS = 'behaviors',
  SETTINGS = 'settings',
  CONSEQUENCES = 'consequences',
  AUTOMATIONS = 'automations',
  VIEW = 'view', // legacy landing route for behaviors
}

export enum BehaviorsSubRoutes {
  BEHAVIORS = 'behaviors', // can be removed when legacy theme support ends
  CONSEQUENCES = 'consequences', // can be removed when legacy theme support ends
  AUTOMATIONS = 'automations', // can be removed when legacy theme support ends
  AUTOMATIONS_STEP_EDIT = 'step',
}

export enum BehaviorReportsRoutes {
  SUMMARY = 'pbis_summary',
  TYPES = 'pbis_types',
  STAFF = 'pbis_staff',
  STUDENTS = 'pbis_student',
  HISTORY = 'pbis_history',
  AUTOMATION_COUNTER = 'pbis_automation_counter',
  CONSEQUENCE_HISTORY = 'pbis_cons_history',
  CONSEQUENCE_TYPES = 'pbis_cons_types',
  CONSEEQUENCE_OVERDUE = 'pbis_cons_overdue',
}

export enum BehaviorManagerColors {
  PRAISE = '#99BAAD',
  GUIDANCE = '#DB6578',
  CONSEQUENCE = '#B91D77',
  AUTOMATION = '#56A599',
}
