import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgGroupPreviewModule } from 'minga/app/src/app/groups/components/MgGroupPreview';
import { GroupStateService } from 'minga/app/src/app/groups/services';

import { MgGroupListComponent } from './MgGroupList.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgGroupPreviewModule,

    // External dependencies
    CommonModule,
  ],
  providers: [GroupStateService],
  declarations: [MgGroupListComponent],
  exports: [MgGroupListComponent],
})
export class MgGroupListModule {}
