import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { GroupsFacadeService } from 'src/app/groups/services';

@Injectable({ providedIn: 'root' })
export class NavigationPermissionService {
  constructor(private _groupsFacade: GroupsFacadeService) {}
  public hasGroupDetailAccess(groupId?: string): Observable<boolean> {
    return combineLatest([
      this._groupsFacade.isMemberOrOwnerOfCurrentGroup(),
      this._groupsFacade.canAccessAllGroups(),
    ]).pipe(
      take(1),
      map(([isMemberOrOwner, canAccessAll]) => {
        return canAccessAll || isMemberOrOwner;
      }),
    );
  }
}
