<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="(ulStore.isLoading$ | async).activeList"
  [overlayTitle]="modalTitle$ | async">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainTemplate>
  <main [ngStyle.gt-sm]="{ 'min-width.px': 620 }">
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="15px">
      <!-- Name Field -->
      <mg-form-text-input
        label="{{ MSG.INPUT_LABEL_TITLE }}"
        [control]="form.controls[FORM_FIELD.TITLE]">
      </mg-form-text-input>
      <!-- UUID Field -->
      <mg-form-text-input
        *ngIf="form.controls[FORM_FIELD.UUID] as control"
        label="{{ MSG.INPUT_LABEL_LIST_UUID }}"
        [control]="control">
      </mg-form-text-input>
      <!-- Membership Lists -->
      <mg-membership-list-table
        *ngIf="(ulStore.isLoading$ | async).activeList === false"
        class="membership-lists"
        variant="secondary"
        [hideHeader]="true"
        [lists]="lists"
        [useDefaultListName]="true"
        (listUpdated)="onMembershipListChange($event)">
      </mg-membership-list-table>
      <!-- Visibility Toggle -->
      <mg-form-toggle
        [label]="MSG.INPUT_MESSAGE_PUBLIC"
        [secondaryLabel]="MSG.INPUT_SUBMESSAGE_PUBLIC"
        [control]="form.controls[FORM_FIELD.PUBLIC]"
        [disabled]="shouldDisabletoggle$ | async">
      </mg-form-toggle>
    </div>
  </main>
</ng-template>

<!-- Footer Content Template -->
<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <mg-btn
      [variant]="(ulStore.isCreatingNewList$ | async) ? 'text' : 'destructive'"
      [disabled]="isSubmitting$ | async"
      (pressed)="close()">
      {{
        (ulStore.isCreatingNewList$ | async)
          ? MSG.BUTTON_LABEL_CLOSE
          : MSG.BUTTON_LABEL_DELETE
      }}
    </mg-btn>
    <mg-btn
      variant="filled"
      [loading]="isSubmitting$ | async"
      (pressed)="submit()">
      {{
        (ulStore.isCreatingNewList$ | async)
          ? MSG.BUTTON_LABEL_CREATE
          : MSG.BUTTON_LABEL_EDIT
      }}
    </mg-btn>
  </footer>
</ng-template>
