import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export const DEFAULT_START_HOUR = 8;

@Component({
  selector: 'mg-add-program-to-minga-dialog',
  templateUrl: './AddProgramToMingaDialog.component.html',
  styleUrls: ['./AddProgramToMingaDialog.component.scss'],
})
export class AddProgramToMingaDialogComponent {
  programName: string = '';
  mingaName: string = '';
  startDate: Date = new Date();
  private _editedStartDate: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddProgramToMingaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.programName = data['programName'] || '';
    this.mingaName = data['mingaName'] || '';

    this.setDateTime();
  }

  onStartDateChange() {
    if (!this.startDate) {
      this.startDate = new Date();
    }

    this._editedStartDate = true;
    this.setDateTime();
  }

  onAllDayChange() {
    this.setDateTime();
  }

  setDateTime() {
    this.startDate.setHours(0);
    this.startDate.setMinutes(0);
    this.startDate.setSeconds(0);
    this.startDate.setMilliseconds(0);
  }

  add() {
    this.dialogRef.close(this.startDate);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
