import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { StreamItemMetadata } from 'minga/proto/common/stream_pb';
import { ContentFeed } from 'minga/proto/gateway/feed_ng_grpc_pb';
import {
  ContentFeedItemView,
  GetContentFeedRequest,
} from 'minga/proto/gateway/feed_pb';
import { NotificationManager } from 'minga/proto/gateway/notification_ng_grpc_pb';
import { GetNotificationsRequest } from 'minga/proto/gateway/notification_pb';
import {
  IMgStreamFilter,
  IMgStreamItem,
  toStreamFilterMessage,
} from 'src/app/util/stream';

@Injectable({ providedIn: 'root' })
export class ContentFeedService {
  constructor(
    private _contentEvents: ContentEvents,
    private _contentFeedProto: ContentFeed,
    private _notificationProto: NotificationManager,
  ) {}

  feedItemClick(e: any) {
    const contentFeedItem: ContentFeedItemView.AsObject = e.model.item.data;

    // There should be a better way of prevening short card opens with these
    // types...
    if (
      contentFeedItem.shortPollCard ||
      contentFeedItem.shortAnnoucementCard ||
      contentFeedItem.shortFileCard ||
      contentFeedItem.shortChallengeCard ||
      contentFeedItem.shortSmsMessageCard
    ) {
      return;
    }

    let contextHash = _.get(contentFeedItem, 'contentContextHash.value', '');

    if (!contextHash) {
      contextHash = _.get(
        contentFeedItem,
        'feedItemView.contentContextHash.value',
        '',
      );
    }

    if (contextHash) {
      this._contentEvents.emitContentOpen({ contentContextHash: contextHash });
    } else {
      console.warn('Could not find context hash on feed item.');
    }
  }

  async getHomeFeed(
    limit: number,
    offset: number,
    filter: IMgStreamFilter,
  ): Promise<IMgStreamItem<ContentFeedItemView.AsObject>[]> {
    const request = new GetContentFeedRequest();
    request.setLimit(limit);
    request.setOffset(offset);
    request.setStreamFilter(toStreamFilterMessage(filter));

    const response = await this._contentFeedProto.getContentFeed(request);
    const items = response.getItemsList();

    return items.map(item => this._mapStreamItemResponse(item));
  }

  async getNotifications(limit: number, offset: number) {
    const request = new GetNotificationsRequest();
    request.setLimit(limit);
    request.setOffset(offset);

    const response = await this._notificationProto.getNotifications(request);
    const notifications = response.getNotificationsList();
    return notifications.map(item => this._mapStreamItemResponse(item, false));
  }

  _mapStreamItemResponse(res: any, toObject = true) {
    const item = toObject ? res.getItem().toObject() : res.getItem();
    const itemMetadata: StreamItemMetadata = res.getItemMetadata();
    const itemIndex = itemMetadata?.getIndex() || null;
    const itemId = itemMetadata?.getId() || null;

    return { item, itemIndex, itemId };
  }
}
