import { ISplashScreen } from './interface';

export class BrowserSplashScreen implements ISplashScreen {
  private _showPromise?: Promise<void>;
  private _hidePromise?: Promise<void>;

  constructor() {}

  waitEvent(eventName: string) {
    let splashElement = this.getSplashScreenElement();

    return new Promise(resolve => {
      const onEventDone = () => {
        splashElement.removeEventListener(eventName, onEventDone);
        resolve();
      };

      splashElement.addEventListener(eventName, onEventDone);
    });
  }

  getSplashScreenElement() {
    let splashscreenElement = document.getElementById('splashscreen');

    if (splashscreenElement == null) {
      throw new Error('#splashscreen element not found');
    }

    return splashscreenElement;
  }

  private async _hide() {
    let splashElement = this.getSplashScreenElement();

    if (splashElement.classList.contains('hidden')) {
      return;
    }

    splashElement.classList.remove('anim-idle');
    splashElement.classList.add('anim-leave');

    await Promise.race([
      this.waitEvent('animationend'),
      this.waitEvent('animationcancel'),
      // If events failed
      new Promise(resolve => setTimeout(resolve, 3000)),
    ]);

    splashElement.classList.add('hidden');
    document.body.classList.remove('no-scrolling-splash');
  }

  private async _show() {
    let splashElement = this.getSplashScreenElement();

    document.body.classList.add('no-scrolling-splash');

    splashElement.classList.remove('hidden');
    splashElement.classList.remove('anim-leave');
    splashElement.classList.add('anim-idle');

    await Promise.race([
      this.waitEvent('animationiteration'),
      this.waitEvent('animationend'),
      this.waitEvent('animationcancel'),
      // If events failed
      new Promise(resolve => setTimeout(resolve, 3000)),
    ]);
  }

  async hide() {
    if (this._showPromise) {
      await this._showPromise.then(() => {});
    }

    if (!this._hidePromise) {
      this._hidePromise = this._hide()
        .catch(console.error)
        .then(() => {
          delete this._hidePromise;
        });
    }

    await this._hidePromise;
  }

  async show() {
    if (this._hidePromise) {
      await this._hidePromise.then(() => {});
    }

    if (!this._showPromise) {
      this._showPromise = this._show()
        .catch(console.error)
        .then(() => {
          delete this._showPromise;
        });
    }

    await this._showPromise;
  }
}
