export function mgIsFullUrl(assetUrl: string) {
  return (
    assetUrl.startsWith('http://') ||
    assetUrl.startsWith('https://') ||
    assetUrl.startsWith('//')
  );
}

export function mgResolveAssetUrl(assetUrl: string): string {
  if (!assetUrl) {
    return assetUrl;
  }
  const isFullUrl = mgIsFullUrl(assetUrl);

  if (isFullUrl) {
    return assetUrl;
  } else {
    return (<any>window).MINGA_ASSET_URL_PREFIX + assetUrl;
  }
}

/**
 * Removes asset url prefix from an asset path. Optionally will replace the
 * processed path prefix.
 * @param assetUrl
 * @param path
 */
export function mgRemoveAssetUrlPrefix(
  assetUrl: string,
  path: string = '',
): string {
  if (!assetUrl.startsWith((<any>window).MINGA_ASSET_URL_PREFIX)) {
    // no asset url prefix to remove, return as is
    return assetUrl;
  }

  const prefixLength = (<any>window).MINGA_ASSET_URL_PREFIX.length;

  assetUrl = assetUrl.substring(prefixLength);
  if (!path) {
    return assetUrl;
  }

  const endIndex = assetUrl.indexOf('/');
  assetUrl = assetUrl.substring(endIndex);
  return path + assetUrl;
}
