<ng-container *ngIf="content">
  <div
    class="type-col"
    [ngClass]="typeClass">
    <mg-icon class="flag">flag-icon</mg-icon>
    <div class="type-icon"></div>
  </div>
  <div class="right-col">
    <div class="date-row">
      <span>CHALLENGE</span>
      <span
        class="date"
        *ngIf="content?.dueDate">
        {{dueDate | date:'EEEE, MMM d'}}
      </span>
    </div>
    <div
      class="title"
      *ngIf="content?.title"
      [innerHTML]="content.title | mgSafeHtml"></div>
  </div>
</ng-container>
