import { SVG } from '@svgdotjs/svg.js';
import * as _ from 'lodash';
import {
  DesignedSvgBackgroundSlotValue,
  DesignedSvgData,
  DesignedSvgGradientData,
  DesignedSvgImageData,
  DesignedSvgImageSlotValue,
  DesignedSvgSlotPair,
  DesignedSvgTextData,
  DesignedSvgTextSlotValue,
  IDesignedSvgManipulation,
} from 'minga/shared/designed_svg/types';
import {
  createSvgLinearGradient,
  createSvgRadialGradient,
} from 'minga/shared/gradient/svg';
import { ILinearGradient, IRadialGradient } from 'minga/shared/gradient/types';

import {
  IDesignedSvgSlotMetadata,
  IDesignedSvgSlotMetadataExtra,
  IDesignedSvgSlotMetadataTextOptions,
} from '../../types';

export interface IDesignedSvgSlotMetadataWithNode
  extends IDesignedSvgSlotMetadata {
  node: SVGElement;
}

export const SHADOW_ROOT_STYLESHEET_HREF = 'SvgShadowRootStyles.css';

/**
 * Map that contains the usage count of gradient slot ids. This is to keep the
 * ids unique between viewers. On some platforms having the same id (even in
 * the shadow dom) causes the gradient to either not update or go blank.
 */
export const gradientIdCount = new Map<string, number>();

const _defaultLineHeight = 1.2;
const _weakWidthCacheMap = new WeakMap<
  SVGSVGElement,
  WeakMap<SVGTextElement, { [keyname: string]: number }>
>();

function copyAttr(
  src: SVGElement,
  dst: SVGElement,
  name: string,
  defaultValue?: string | null,
) {
  if (src.hasAttribute(name)) {
    dst.setAttribute(name, src.getAttribute(name)!);
  } else if (typeof defaultValue !== 'undefined' && defaultValue !== null) {
    dst.setAttribute(name, defaultValue);
  } else {
    dst.removeAttribute(name);
  }
}

function copyAttrOrRetain(src: SVGElement, dst: SVGElement, name: string) {
  return copyAttr(src, dst, name, dst.getAttribute(name));
}

/**
 * @param cacheTarget - Target to use for weak cache map
 */
export function getTspanWidthCached(
  tspan: SVGTSpanElement,
  cacheTarget?: SVGTextElement,
) {
  const txt = tspan.textContent;
  if (!txt) return 0;

  if (!cacheTarget) {
    cacheTarget = tspan.parentElement as any as SVGTextElement;
  }

  const svg = cacheTarget.ownerSVGElement!;
  if (!_weakWidthCacheMap.has(svg)) {
    _weakWidthCacheMap.set(svg, new WeakMap());
  }

  const cacheMap = _weakWidthCacheMap.get(svg)!;
  let cache = cacheMap.get(cacheTarget);

  if (cache) {
    const cachedValue = cache[txt];
    if (cachedValue) {
      return cachedValue;
    }
  } else {
    cache = {};
    cacheMap.set(cacheTarget, cache);
  }

  cache[txt] = tspan.getComputedTextLength();
  return cache[txt];
}

export function clearSvgWidthCache(svg: SVGSVGElement) {
  _weakWidthCacheMap.delete(svg);
}

export function clearSvgTextWidthCache(txtNode: SVGTextElement) {
  if (_weakWidthCacheMap.has(txtNode.ownerSVGElement!)) {
    _weakWidthCacheMap.get(txtNode.ownerSVGElement!)?.delete(txtNode);
  }
}

/**
 * Temporarily sets the `tspan`'s text content to be trimmed and then measures
 * the width.
 */
export function widthWithoutWhitespaceTrim(tspan: SVGTSpanElement) {
  const originalText = tspan.textContent;
  if (originalText) {
    tspan.textContent = originalText.trim();
    const width = getTspanWidthCached(tspan);
    tspan.textContent = originalText;
    return width;
  } else {
    return getTspanWidthCached(tspan);
  }
}

/**
 * Gets the element responsible for holding the text content.
 */
export function getTextSlotTextElement(node: Node): SVGTextElement | null {
  const nodeName = node.nodeName.toLowerCase();
  if (nodeName === 'text') {
    return node as SVGTextElement;
  } else if (nodeName === 'g') {
    return (node as any).querySelector('text');
  }

  return null;
}

/**
 * Gets the element that should be used as the text bounds
 * @param node - the node for the text slot
 */
export function getTextBoundsElement(
  node: SVGElement,
): SVGTextElement | SVGRectElement | null {
  const nodeName = node.nodeName.toLowerCase();
  if (nodeName === 'text') {
    return node as SVGTextElement;
  } else if (nodeName === 'g') {
    let rect = node.querySelector('rect');
    if (!rect) {
      const txtNode = SVG(getTextSlotTextElement(node));
      rect = createTextSlotRectNode();
      node.appendChild(rect);
      rect.setAttribute('width', `${txtNode.bbox().width}`);
      rect.setAttribute('height', `${txtNode.bbox().height}`);
    }

    return rect;
  }

  return null;
}

/**
 * Get the x and y attribute coordinates for the <text> element for the text
 * slot.
 */
export function getTextNodeCoords(txtNode: SVGTextElement) {
  let x = txtNode.getAttribute('x') || '';
  // If we don't have an 'x' attribute on the text node we'll try to use the
  // first tspans x attribute.
  if (!x) {
    const tspan = txtNode.querySelector('tspan');
    if (tspan) {
      x = tspan.getAttribute('x') || '0';
      txtNode.setAttribute('x', x);
    }
  }

  let y = txtNode.getAttribute('y') || '';
  if (!y) {
    const tspan = txtNode.querySelector('tspan');
    if (tspan) {
      y = tspan.getAttribute('y') || '0';
      txtNode.setAttribute('y', y);
    }
  }

  return { x, y };
}

/**
 * Get the text slots unitless line height. The result of this function must
 * be multiplied by the font size to get the px line height.
 *
 * @param txtNode - The text node fo the text slot
 */
function getTextSlotLineHeight(txtNode: SVGElement): number | undefined;

/**
 * Get the text slots unitless line height. The result of this function must
 * be multiplied by the font size to get the px line height.
 *
 * @param txtNode - The text node fo the text slot
 * @param defaultLineHeight - If unset this value is returned instead
 */
function getTextSlotLineHeight(
  txtNode: SVGElement,
  defaultLineHeight: number,
): number;

function getTextSlotLineHeight(
  txtNode: SVGElement,
  defaultLineHeight?: number,
) {
  const lineHeight = txtNode.dataset.mingaLineHeight;
  if (!lineHeight) return defaultLineHeight;
  const lineHeightF = parseFloat(lineHeight);
  if (isNaN(lineHeightF)) return defaultLineHeight;
  return lineHeightF;
}

function getTextSlotFontSize(
  value: DesignedSvgTextSlotValue,
  txtNode: SVGElement,
) {
  if (typeof value !== 'string' && value.fontSize) {
    return value.fontSize;
  }

  if (txtNode.style.fontSize) {
    return parseFloat(txtNode.style.fontSize);
  }

  console.warn('[PERFORMANCE] Using getComputedStyle for text slot font size');
  if (!txtNode.parentElement) {
    throw new Error(
      'Cannot get font size from text slot text node if not attached to dom',
    );
  }
  const fontSizeStr = getComputedStyle(txtNode).fontSize;
  if (!fontSizeStr) {
    console.warn('Using body font size for text slot');
    return parseFloat(getComputedStyle(document.body).fontSize);
  }

  const fontSize = parseFloat(fontSizeStr);

  if (isNaN(fontSize)) {
    throw new Error(`Got NaN for font size from '${fontSizeStr}'`);
  }

  return fontSize;
}

/**
 * Sets a new value to the text slot node
 * @param node - the node for the text slot
 * @param value - the text to be set
 */
export function setTextSlotValue(
  node: SVGElement,
  value: DesignedSvgTextSlotValue,
) {
  const txtNode = getTextSlotTextElement(node);

  // No text node. Can't set any text.
  if (!txtNode) {
    return;
  }

  let textAnchor: string = '';

  if (typeof value === 'string') {
    const needsWidthCacheClear =
      txtNode.style.fontSize ||
      txtNode.style.fontWeight ||
      getFontFamilyName(txtNode);

    if (needsWidthCacheClear) {
      clearSvgTextWidthCache(txtNode);
    }

    txtNode.style.fontSize = node.dataset.fontSize || '';
    txtNode.style.fill = node.dataset.color || '';
    txtNode.style.textDecoration = '';
    txtNode.style.fontWeight = '';
    txtNode.style.fontStyle = '';
    txtNode.style.textAnchor = '';
    txtNode.style.fontFamily = '';
  } else {
    const newFontSize = value.fontSize ? `${value.fontSize}px` : '';
    const newColor = value.color || '';
    const newTextDecoration = value.textDecoration || '';
    const newFontWeight = value.fontWeight ? `${value.fontWeight}` : '';
    const newFontStyle = value.fontStyle ? `${value.fontStyle}` : '';
    const newFontFamily = value.fontFamily
      ? `"${value.fontFamily}", sans-serif`
      : '';

    const needsWidthCacheClear =
      txtNode.style.fontSize != newFontSize ||
      txtNode.style.fontWeight != newFontWeight ||
      getFontFamilyName(txtNode) != (value.fontFamily || '');

    if (needsWidthCacheClear) {
      clearSvgTextWidthCache(txtNode);
    }

    txtNode.style.fontSize = newFontSize;
    txtNode.style.fill = newColor;
    txtNode.style.textDecoration = newTextDecoration;
    txtNode.style.fontWeight = newFontWeight;
    txtNode.style.fontStyle = newFontStyle;
    txtNode.style.fontFamily = newFontFamily;

    textAnchor = value.textAnchor || '';
  }

  const txtBoundsNode = getTextBoundsElement(node);
  if (txtBoundsNode) {
    updateTextAnchor(node, txtNode, txtBoundsNode, textAnchor);
  }

  const lineHeight = getTextSlotLineHeight(txtNode, _defaultLineHeight);
  const fontSize = getTextSlotFontSize(value, txtNode);

  txtNode.style.letterSpacing = 'normal';
  // We are purposely NOT using the getTextBoundsElement here because we do not
  // want the 'text' element for the rect width. Without a rect there are no
  // restrictions
  const rect: SVGRectElement | null = node.querySelector('rect');
  const rectWidth = rect ? rect.getBBox().width : 0;
  const { x, y } = getTextNodeCoords(txtNode);

  const lines = (typeof value === 'string' ? value : value.text).split('\n');

  if (
    txtNode.firstChild &&
    txtNode.firstChild.nodeType === document.TEXT_NODE
  ) {
    txtNode.removeChild(txtNode.firstChild);
  }

  const existingTspans = Array.from(txtNode.querySelectorAll('tspan'));
  let lastCreateIndex = -1;

  const createNextTspan = () => {
    lastCreateIndex += 1;

    if (existingTspans[lastCreateIndex]) {
      return existingTspans[lastCreateIndex];
    }
    const tspan = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'tspan',
    );

    existingTspans.push(tspan);

    return tspan;
  };

  let emptyLinesAbove = 0;

  const calcLineHeight = () => {
    const lineHeightPx = lineHeight * fontSize;
    const lineHeightWithLinesPx = lineHeightPx * (emptyLinesAbove + 1);
    return `${lineHeightWithLinesPx}`;
  };

  for (let lineIndex = 0; lines.length > lineIndex; ++lineIndex) {
    const line = lines[lineIndex];

    if (!line.trim()) {
      emptyLinesAbove += 1;
      continue;
    }

    const tspan = createNextTspan();
    // Remove potential mingaLineWrapCont on previous tspan
    delete tspan.dataset.mingaLineWrapCont;
    tspan.setAttribute('x', x);
    tspan.setAttribute('dy', calcLineHeight());

    if (emptyLinesAbove) {
      tspan.dataset.mingaLinesAbove = '' + emptyLinesAbove;
    } else {
      delete tspan.dataset.mingaLinesAbove;
    }

    emptyLinesAbove = 0;
    tspan.innerHTML = line;
    txtNode.appendChild(tspan);

    // No text rect information skip word wrapping
    if (rectWidth == 0) {
      continue;
    }

    let lineWidth = widthWithoutWhitespaceTrim(tspan);

    // Line width is within the bounding rect, no need to shorten it!
    if (lineWidth < rectWidth) {
      continue;
    }

    const words = tspan.textContent!.split(/(?=\s+)/g);

    const approxExtraLineCount = Math.ceil(lineWidth / rectWidth) + 1;
    const tspans = [tspan];
    const tspanWords: string[][] = [];
    tspan.textContent = '';

    // Remove leading whitespace characters and place them at the end of
    // the previous line
    for (let i = 1; words.length > i; ++i) {
      const nonWsIndex = words[i].search(/\S/);
      if (nonWsIndex > 0) {
        const ws = words[i].substr(0, nonWsIndex);
        // Update previous word with the whitespace we're removing
        words[i - 1] += ws;
        words[i] = words[i].substr(nonWsIndex);
      }
    }

    const pushNewContTspan = () => {
      const contTspan = createNextTspan();
      // Remove potentially old mingaLinesAbove value from previous tspan
      delete contTspan.dataset.mingaLinesAbove;
      contTspan.setAttribute('x', x);
      contTspan.setAttribute('dy', calcLineHeight());
      contTspan.dataset.mingaLineWrapCont = '';
      txtNode.appendChild(contTspan);
      const newIndex = tspans.length;
      tspans.push(contTspan);
      tspanWords[newIndex] = tspanWords[newIndex] || [];
    };

    for (let i = 0; approxExtraLineCount - 1 > i; ++i) {
      pushNewContTspan();
    }

    const approxWordsPerLine = Math.ceil(words.length / tspans.length);

    // Collection approximate word arrays for each tspan.
    for (let i = 0; approxExtraLineCount > i; ++i) {
      tspanWords[i] = tspanWords[i] || [];

      const contWords = tspanWords[i];
      for (let w = 0; approxWordsPerLine > w; ++w) {
        const word = words.shift();
        if (word) {
          contWords.push(word);
        }
      }
    }

    // May be incremented as needed
    let lineCount = approxExtraLineCount;

    // Measure each word array against the text bounds to fit words nicely
    // on each line.
    for (let i = 0; lineCount > i; ++i) {
      const contTspan = tspans[i];
      const contWords = tspanWords[i];

      contTspan.textContent = contWords.join('');

      let contTspanWidth = widthWithoutWhitespaceTrim(contTspan);
      if (contTspanWidth > rectWidth) {
        if (!tspanWords[i + 1]) {
          pushNewContTspan();
          lineCount += 1;
        }

        // Our line is too large for the text bounds. We are removing the
        // last word on each line one by one until it fits.
        const nextWords = tspanWords[i + 1];

        do {
          // If a single word is taking up the entire line we split up the
          // line into individual characters and treat the characters as
          // words. This isn't the most performant, but these situations are
          // usually edge cases.
          if (contWords.length === 1) {
            if (contWords[0].length > 1) {
              const splitWord = contWords.shift() || '';
              contWords.unshift(...splitWord.split(''));
            } else {
              contTspan.textContent = contWords.join('');
              break;
            }
          }

          let discardWord = '';
          do {
            discardWord = contWords.pop() || '';
          } while (!discardWord && contWords.length > 0);

          if (nextWords) {
            nextWords.unshift(discardWord);
          }

          contTspan.textContent = contWords.join('');
          contTspanWidth = widthWithoutWhitespaceTrim(contTspan);
        } while (contTspanWidth > rectWidth && contWords.length > 1);
      } else if (contTspanWidth < rectWidth && tspanWords[i + 1]) {
        // Our line is potentially not using the text bounds efficiently
        // so we add a new word until we go over bounds.
        const nextWords = tspanWords[i + 1];
        const nextNextWords = tspanWords[i + 2];

        const getNextWord = () => {
          const nextWord = nextWords.shift() || '';
          if (nextWord && nextNextWords) {
            const word = nextNextWords.shift();
            if (word) {
              nextWords.push(word);
            }
          }

          return nextWord;
        };

        do {
          if (nextWords.length == 0) {
            break;
          }

          const nextWord = getNextWord();
          contWords.push(nextWord);

          contTspan.textContent = contWords.join('');
          contTspanWidth = widthWithoutWhitespaceTrim(contTspan);
        } while (contTspanWidth < rectWidth && contWords.length > 0);

        // Adjust for going out of text bounds
        if (contTspanWidth > rectWidth) {
          let discardWord = contWords.pop() || '';
          if (discardWord) {
            nextWords.unshift(discardWord);
            contTspan.textContent = contWords.join('');
          }
        }
      }
    }
  }

  const unusedTspans = existingTspans.slice(lastCreateIndex + 1);

  if (unusedTspans.length > 0) {
    for (const unusedTspan of unusedTspans) {
      if (unusedTspan.parentElement) {
        unusedTspan.parentElement.removeChild(unusedTspan);
      }
    }
  }
}

function isBgSlotValueLinearGradient(
  value: DesignedSvgGradientData | DesignedSvgImageData,
): value is ILinearGradient {
  return 'linearGradient' in value;
}

function isBgSlotValueRadialGradient(
  value: DesignedSvgGradientData | DesignedSvgImageData,
): value is IRadialGradient {
  return 'radialGradient' in value;
}

function isBgSlotValueImage(
  value: DesignedSvgGradientData | DesignedSvgImageData,
): value is DesignedSvgImageData {
  return 'src' in value;
}

function setBgSlotFill(
  node: SVGElement,
  gradientId: string,
  svg: SVGSVGElement,
  defs: SVGDefsElement,
  value: string | DesignedSvgGradientData,
): SVGElement {
  let fillValue: string = '';
  let gradientElement:
    | SVGLinearGradientElement
    | SVGRadialGradientElement
    | null = null;
  const rectElement = ensureRectSlotNode(node);

  if (typeof value === 'string') {
    fillValue = value;
  } else if (isBgSlotValueLinearGradient(value)) {
    gradientElement = createSvgLinearGradient(value);
  } else if (isBgSlotValueRadialGradient(value)) {
    gradientElement = createSvgRadialGradient(value);
  }

  if (gradientElement) {
    fillValue = `url(#${gradientId})`;
    const existingEl = svg.getElementById(gradientId);
    if (existingEl && existingEl.parentElement) {
      existingEl.parentElement.removeChild(existingEl);
    }

    gradientElement.id = gradientId;
    defs.appendChild(gradientElement);
  }

  if (gradientElement && window.MINGA_DEVICE === 'ios') {
    const gradientValue = value as ILinearGradient | IRadialGradient;
    rectElement.style.fill = gradientValue.stops[0].color || '';
  } else {
    rectElement.style.fill = fillValue;
  }

  return rectElement;
}

function ensureBgSlotImageNode(node: SVGElement): SVGImageElement {
  if (node instanceof SVGImageElement) {
    return node;
  }

  const imageElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'image',
  );

  imageElement.id = node.id;
  copyAttr(node, imageElement, 'x');
  copyAttr(node, imageElement, 'y');
  copyAttr(node, imageElement, 'width', '100%'); // width/height _MUST_ be set
  copyAttr(node, imageElement, 'height', '100%');
  copyAttr(node, imageElement, 'transform');
  for (const key in node.dataset) {
    imageElement.dataset[key] = node.dataset[key];
  }

  node.parentElement!.replaceChild(imageElement, node);

  return imageElement;
}

function ensureRectSlotNode(node: SVGElement): SVGRectElement {
  if (node instanceof SVGRectElement) {
    return node;
  }

  const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

  rect.id = node.id;
  copyAttr(node, rect, 'x');
  copyAttr(node, rect, 'y');
  copyAttr(node, rect, 'width', '100%'); // width/height _MUST_ be set
  copyAttr(node, rect, 'height', '100%');
  // copyAttr(node, rect, 'transform');
  for (const key in node.dataset) {
    rect.dataset[key] = node.dataset[key];
  }

  node.parentElement!.replaceChild(rect, node);

  return rect;
}

function setBgSlotImage(
  node: SVGElement,
  svg: SVGSVGElement,
  value: string | DesignedSvgImageData,
): SVGElement {
  const imageElement = ensureBgSlotImageNode(node);
  // Similar to CSS `background-position: center; background-size: cover`
  imageElement.setAttribute('preserveAspectRatio', 'xMidYMid slice');

  if (typeof value === 'string') {
    imageElement.setAttribute('href', value);
  } else {
    imageElement.setAttribute('href', value.src);

    // The width/height is unused, but preserved for later use in the dataset
    // and so it can be retrieved in `getBgSlotValue()`
    imageElement.dataset.width = '' + value.width;
    imageElement.dataset.height = '' + value.width;
  }

  imageElement.removeAttributeNS('http://www.w3.org/1999/xlink', 'href');

  return imageElement;
}

/**
 * Sets the background slot node value
 * @param node - The node that is being modified
 * @param gradientId - Unique ID to give gradient URL (iOS workaround)
 * @param svg - The svg the node is a part of or _will_ be a part of
 * @param defs - SVG defs to add gradient styles
 * @param value - The value to set
 * @returns The same node that was being modified or potentially a new node if
 *          it was necessary to replace the node while setting the value.
 */
export function setBgSlotValue(
  node: SVGElement,
  gradientId: string,
  svg: SVGSVGElement,
  defs: SVGDefsElement,
  value: DesignedSvgBackgroundSlotValue,
): SVGElement {
  if (typeof value === 'string') {
    const isImageData =
      value.startsWith('http://') ||
      value.startsWith('https://') ||
      value.startsWith('data:image');

    if (isImageData) {
      return setBgSlotImage(node, svg, value);
    } else {
      return setBgSlotFill(node, gradientId, svg, defs, value);
    }
  } else if (isBgSlotValueImage(value)) {
    return setBgSlotImage(node, svg, value);
  } else {
    return setBgSlotFill(node, gradientId, svg, defs, value);
  }
}

export function setImageSlotValue(
  node: SVGElement,
  value: DesignedSvgImageSlotValue,
  viewportFactor?: number,
) {
  if (typeof value === 'string') {
    node.setAttribute('href', value);
    delete node.dataset.width;
    delete node.dataset.height;
  } else {
    if (typeof viewportFactor === 'undefined') {
      const svg = node.ownerSVGElement!;
      const viewbox = SVG(svg).viewbox();
      viewportFactor = viewbox.width / svg.getBoundingClientRect().width;
    }

    const width = value.width * viewportFactor;
    const height = value.height * viewportFactor;
    node.dataset.width = '' + value.width;
    node.dataset.height = '' + value.height;
    node.setAttribute('href', value.src);
    node.setAttribute('width', '' + width);
    node.setAttribute('height', '' + height);
  }

  node.removeAttributeNS('http://www.w3.org/1999/xlink', 'href');
}

function getFontFamilyName(txtNode: SVGElement) {
  const ff = txtNode.style.fontFamily || '';
  if (!ff) return '';

  const splitList = ff.split(',');
  let fontFamily = '';

  if (splitList && splitList[0]) {
    fontFamily = splitList[0].replace(/'|"/g, '');
  } else {
    console.warn(`Couldn't find font-family from ${ff}`);
  }

  return fontFamily;
}

export function getTextSlotValue(node: SVGElement): DesignedSvgTextSlotValue {
  const txtNode = getTextSlotTextElement(node);
  let txt = '';
  if (txtNode) {
    const childNodes = Array.from(txtNode.childNodes);

    for (const childNode of Array.from(txtNode.childNodes)) {
      const childNodeName = childNode.nodeName.toLowerCase();

      if (childNode.nodeType == document.TEXT_NODE) {
        if (childNode.textContent) {
          txt += childNode.textContent + '\n';
        }
      } else if (childNodeName === 'tspan') {
        const tspan = childNode as SVGTSpanElement;
        if (typeof tspan.dataset.mingaLineWrapCont !== 'undefined') {
          // Remove the last newline because we found a continuation line
          if (txt[txt.length - 1] === '\n') {
            txt = txt.substr(0, txt.length - 1);
          } else {
            console.warn('Expected newline, but no newline!');
          }
        }

        if (tspan.dataset.mingaLinesAbove) {
          txt += '\n'.repeat(parseInt(tspan.dataset.mingaLinesAbove));
        }
        txt += (tspan.textContent || '') + '\n';
      }
    }

    if (childNodes.length > 0) {
      // Extra newline
      if (txt[txt.length - 1] === '\n') {
        txt = txt.substr(0, txt.length - 1);
      }
    }

    const hasTextData =
      txtNode.style.fontSize ||
      txtNode.style.fill ||
      txtNode.style.textAnchor ||
      txtNode.style.textDecoration ||
      txtNode.style.fontWeight ||
      txtNode.style.fontStyle ||
      txtNode.style.fontFamily;

    if (hasTextData) {
      const textData: DesignedSvgTextData = { text: txt };
      if (txtNode.style.fontSize) {
        textData.fontSize = parseFloat(txtNode.style.fontSize);
      }

      if (txtNode.style.fill) {
        textData.color = txtNode.style.fill;
      }

      if (txtNode.style.textAnchor) {
        textData.textAnchor = txtNode.style.textAnchor as any;
      }

      if (txtNode.style.textDecoration) {
        textData.textDecoration = txtNode.style.textDecoration;
      }

      if (txtNode.style.fontWeight) {
        textData.fontWeight = parseInt(txtNode.style.fontWeight);
      }

      if (txtNode.style.fontStyle) {
        textData.fontStyle = txtNode.style.fontStyle;
      }

      if (txtNode.style.fontFamily) {
        const fontFamily = getFontFamilyName(txtNode);
        if (fontFamily) {
          textData.fontFamily = fontFamily;
        }
      }

      return textData;
    }
  }

  return txt;
}

export function getTextExtraOptions(
  node: SVGElement,
): IDesignedSvgSlotMetadataExtra {
  const txtNode = getTextSlotTextElement(node);
  // We aren't a valid text node in this case. Most likely the letters are in
  // the form of <path> elements or the SVG element was given the wrong id
  if (!txtNode) return {};

  const textAnchor = txtNode.getAttribute('text-anchor');
  const textLineHeight = getTextSlotLineHeight(txtNode);
  let textOptions: IDesignedSvgSlotMetadataTextOptions = {};

  if (textAnchor) textOptions.textAnchor = textAnchor as any;
  if (textLineHeight) textOptions.textLineHeight = textLineHeight;

  return { textOptions };
}

function getBgSlotImageValue(
  node: SVGImageElement,
): string | DesignedSvgImageData {
  const href =
    node.getAttribute('href') ||
    node.getAttributeNS('http://www.w3.org/1999/xlink', 'href') ||
    '';

  if (node.dataset.width && node.dataset.height) {
    return {
      src: href,
      width: parseFloat(node.dataset.width),
      height: parseFloat(node.dataset.height),
    };
  } else {
    return href;
  }
}

function getBgSlotRadialGradientValue(
  node: SVGRadialGradientElement,
): IRadialGradient {
  const gradient: IRadialGradient = {
    radialGradient: true,
    stops: [],
  };

  for (let i = 0; node.children.length > i; ++i) {
    const child = node.children.item(i);
    if (!(child instanceof SVGStopElement)) continue;

    const stopColor = child.getAttribute('stop-color');
    const offset = child.getAttribute('offset');

    gradient.stops.push({
      color: stopColor || '',
      offset: offset || '',
    });
  }

  return gradient;
}

function getBgSlotLinearGradientValue(
  node: SVGLinearGradientElement,
): ILinearGradient {
  const gradient: ILinearGradient = {
    linearGradient: true,
    stops: [],
    transform: {
      degrees: 0,
      rotate: true,
    },
  };

  for (let i = 0; node.children.length > i; ++i) {
    const child = node.children.item(i);
    if (!(child instanceof SVGStopElement)) continue;

    const stopColor = child.getAttribute('stop-color');
    const offset = child.getAttribute('offset');

    gradient.stops.push({
      color: stopColor || '',
      offset: offset || '',
    });
  }

  return gradient;
}

export function getBgSlotValue(
  node: SVGElement,
): DesignedSvgBackgroundSlotValue {
  if (node instanceof SVGImageElement) {
    return getBgSlotImageValue(node);
  }

  const fillUrlStart = 'url(#';
  const fillUrlEnd = ')';

  const fill = node.style.fill || '';

  if (node.ownerSVGElement && fill.startsWith(fillUrlStart)) {
    let gradientId = fill.substr(fillUrlStart.length);
    gradientId = gradientId.substr(0, gradientId.length - fillUrlEnd.length);
    const svg = node.ownerSVGElement;

    const gradientElement = svg.getElementById(gradientId);
    if (gradientElement instanceof SVGLinearGradientElement) {
      return getBgSlotLinearGradientValue(gradientElement);
    } else if (gradientElement instanceof SVGRadialGradientElement) {
      return getBgSlotRadialGradientValue(gradientElement);
    }
  }

  return fill;
}

export function getImageSlotValue(node: SVGElement): DesignedSvgImageSlotValue {
  const width = node.dataset.width;
  const height = node.dataset.height;

  const href =
    node.getAttribute('href') ||
    node.getAttributeNS('http://www.w3.org/1999/xlink', 'href') ||
    '';

  if (width && height) {
    const imageData: DesignedSvgImageData = {
      src: href,
      width: parseFloat(width),
      height: parseFloat(height),
    };

    if (isNaN(imageData.width) || isNaN(imageData.height)) {
      return imageData.src;
    }

    return imageData;
  } else {
    return href;
  }
}

/**
 * Sets a new value for the text slot's 'text-anchor'.
 * @param node - The text slot node
 * @param txtNode - The text element that contains the text of slot
 * @param txtBoundsNode - The node used as the bounds for the text node.
 * @param textAnchor - The new value to be set to the 'text-anchor' attribute
 */
export function updateTextAnchor(
  node: Node,
  txtNode: SVGTextElement,
  txtBoundsNode: SVGTextElement | SVGRectElement,
  textAnchor: string,
) {
  const children = Array.from(txtNode.children);
  for (const child of children) {
    txtNode.removeChild(child);
  }

  const xBefore = SVG(txtBoundsNode).x();
  const yBefore = SVG(txtBoundsNode).y();

  SVG(txtNode).font({ anchor: textAnchor });
  SVG(txtBoundsNode).x(xBefore);
  SVG(txtBoundsNode).y(yBefore);
  txtNode.style.textAnchor = textAnchor;
  txtNode.removeAttribute('text-anchor');

  for (const child of children) {
    txtNode.appendChild(child);
  }

  adjustForTextAnchor(txtNode, txtBoundsNode);
}

/**
 * Updates the text slots line height. Will updatie all dataset and tspans
 * inside the `txtNode`
 * @param node - The text slot node
 * @param txtNode - The text element that contains the text of slot
 * @param newLineHeight - The new value to be set for the line height
 */
export function updateTextLineHeight(
  node: SVGElement,
  txtNode: SVGTextElement,
  newLineHeight: number | undefined,
) {
  if (typeof newLineHeight === 'undefined') {
    delete txtNode.dataset.mingaLineHeight;
  } else {
    txtNode.dataset.mingaLineHeight = `${newLineHeight}`;
  }

  const tspans = txtNode.querySelectorAll('tspan');
  const lineHeight = newLineHeight || _defaultLineHeight;

  tspans.forEach(tspan => {
    let tspanDy = lineHeight;

    if (tspan.dataset.mingaLinesAbove) {
      const linesAbove = parseInt(tspan.dataset.mingaLinesAbove) || 1;
      tspanDy = lineHeight * linesAbove;
    }

    tspan.setAttribute('dy', `${tspanDy}`);
  });
}

export function refreshTextSlot(slotId: string, node: SVGElement) {
  const txtNode = getTextSlotTextElement(node);
  const boundsNode = getTextBoundsElement(node);
  if (!txtNode || !boundsNode) return;

  const txtSvg = SVG(txtNode);
  const boundsSvg = SVG(boundsNode);
  const fontSize = 0;

  const textAnchor = txtNode.getAttribute('text-anchor');
  if (textAnchor) {
    switch (textAnchor) {
      case 'middle':
        txtSvg.attr('x', boundsSvg.x() + boundsSvg.width() / 2);
        txtSvg.attr('y', boundsSvg.y() + fontSize);
        break;
      case 'start':
        txtSvg.attr('x', boundsSvg.x());
        txtSvg.attr('y', boundsSvg.y() + fontSize);
        break;
      case 'end':
        txtSvg.attr('x', boundsSvg.x() + boundsSvg.width());
        txtSvg.attr('y', boundsSvg.y() + fontSize);
        break;
    }
  }

  setTextSlotValue(node, getTextSlotValue(node));
}

function adjustForTextAnchor(txtNode: SVGElement, boundsNode: SVGElement) {
  const txtSvg = SVG(txtNode);
  const boundsSvg = SVG(boundsNode);
  const fontSize = 0;

  const textAnchor = txtNode.style.textAnchor;
  if (textAnchor) {
    switch (textAnchor) {
      case 'middle':
        txtSvg.attr('x', boundsSvg.x() + boundsSvg.width() / 2);
        txtSvg.attr('y', boundsSvg.y() + fontSize);
        break;
      case 'start':
        txtSvg.attr('x', boundsSvg.x());
        txtSvg.attr('y', boundsSvg.y() + fontSize);
        break;
      case 'end':
        txtSvg.attr('x', boundsSvg.x() + boundsSvg.width());
        txtSvg.attr('y', boundsSvg.y() + fontSize);
        break;
    }
  }
}

/**
 * Updates the text slot node with the text options
 */
export function updateTextSlot(textSlot: IDesignedSvgSlotMetadataWithNode) {
  if (textSlot.textOptions) {
    const txtNode = getTextSlotTextElement(textSlot.node);
    if (!txtNode) return;

    const newTextLineHeight = textSlot.textOptions.textLineHeight;
    const previousTextLineHeight = getTextSlotLineHeight(txtNode);

    if (newTextLineHeight !== previousTextLineHeight) {
      updateTextLineHeight(textSlot.node, txtNode, newTextLineHeight);
    }
  }
}

/** Get node that should have the width/height applied to */
export function getManipulatableSizeSlotNode(slotId: string, node: SVGElement) {
  if (DesignedSvgData.isText(slotId)) {
    return getTextBoundsElement(node);
  }
  if (DesignedSvgData.isAdditionalSlot(slotId)) {
    if (node.dataset.mingaAdditionalSlot === 'txt') {
      return getTextBoundsElement(node);
    }
  }

  return node;
}

/** Get the node that should have the transformation matrix applied to */
export function getManipulatableMatrixSlotNode(
  slotId: string,
  node: SVGElement,
) {
  return node;
}

export function getSlotDesignedSvgManipulation(
  slotId: string,
  node: SVGElement,
): IDesignedSvgManipulation {
  const manipulation: IDesignedSvgManipulation = {};

  const matrixNode = getManipulatableMatrixSlotNode(slotId, node);
  const sizeNode = getManipulatableSizeSlotNode(slotId, node);
  if (sizeNode) {
    manipulation.w = SVG(sizeNode).width();
    manipulation.h = SVG(sizeNode).height();
  }

  const matrixNodeTransform = SVG(matrixNode).transform();

  manipulation.m = [
    matrixNodeTransform.a || 0,
    matrixNodeTransform.b || 0,
    matrixNodeTransform.c || 0,
    matrixNodeTransform.d || 0,
    matrixNodeTransform.e || 0,
    matrixNodeTransform.f || 0,
  ];

  return manipulation;
}

export function removeAdditionalSlotNodes(svg: SVGSVGElement) {
  const additionalSlotNodes = svg.querySelectorAll(
    '*[data-minga-additional-slot]',
  );

  additionalSlotNodes.forEach(node => {
    node.remove();
  });
}

export function addAdditionalImageSlotNode(
  svg: SVGSVGElement,
  value: DesignedSvgImageSlotValue,
) {
  const slotNode = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'image',
  );

  slotNode.dataset.mingaAdditionalSlot = '';

  const viewbox = SVG(svg).viewbox();
  const viewportFactor = viewbox.width / svg.getBoundingClientRect().width;
  if (typeof value !== 'string') {
    value = { ...value };
    const halfWidth = viewbox.width / 2;
    const halfHeight = viewbox.height / 2;
    if (value.width >= value.height) {
      const ratio = value.height / value.width;
      value.width = Math.min(halfWidth, value.width);
      value.height = value.width * ratio;
    } else {
      const ratio = value.width / value.height;
      value.height = Math.min(halfHeight, value.height);
      value.width = value.height * ratio;
    }
  }
  setImageSlotValue(slotNode, value, viewportFactor);

  if (svg.lastElementChild?.classList.contains('minga-svg-manipulator-tool')) {
    svg.insertBefore(slotNode, svg.lastElementChild);
  } else {
    svg.appendChild(slotNode);
  }

  // Set newly added slot in the center
  SVG(slotNode).transform({
    translate: [
      viewbox.cx - parseInt(slotNode.getAttribute('width')!) / 2,
      viewbox.cy - parseInt(slotNode.getAttribute('height')!) / 2,
    ],
  });

  return slotNode;
}

function createTextSlotRectNode() {
  const rectNode = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'rect',
  );

  rectNode.setAttribute('fill-opacity', '0');

  return rectNode;
}

export function addAdditionalTextSlotNode(
  svg: SVGSVGElement,
  value: DesignedSvgTextSlotValue,
) {
  const viewbox = SVG(svg).viewbox();
  const viewportFactor = viewbox.width / svg.getBoundingClientRect().width;

  const slotNode = document.createElementNS('http://www.w3.org/2000/svg', 'g');

  const txtNode = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'text',
  );

  txtNode.dataset.mingaLineHeight = `${_defaultLineHeight}`;

  const defaultWidth = 200 * viewportFactor;
  const defaultHeight = 100 * viewportFactor;

  const rectNode = createTextSlotRectNode();
  rectNode.setAttribute('width', `${defaultWidth}`);
  rectNode.setAttribute('height', `${defaultHeight}`);

  slotNode.append(txtNode);
  slotNode.append(rectNode);

  txtNode.style.fontSize = getComputedStyle(txtNode).fontSize;

  slotNode.dataset.mingaAdditionalSlot = 'txt';
  setTextSlotValue(slotNode, value);

  if (svg.lastElementChild?.classList.contains('minga-svg-manipulator-tool')) {
    svg.insertBefore(slotNode, svg.lastElementChild);
  } else {
    svg.appendChild(slotNode);
  }

  // Set newly added slot in the center
  SVG(slotNode).transform({
    translate: [viewbox.cx - defaultWidth / 2, viewbox.cy - defaultHeight / 2],
  });

  return slotNode;
}

export function addAdditionalSlotNode(
  svg: SVGSVGElement,
  slotPair: DesignedSvgSlotPair,
) {
  switch (slotPair[0]) {
    case 'img':
      return addAdditionalImageSlotNode(svg, slotPair[1]);
    case 'txt':
      return addAdditionalTextSlotNode(svg, slotPair[1]);
  }

  return null;
}

export function setAdditionalSlotNodeValue(
  node: SVGElement,
  slotPair: DesignedSvgSlotPair,
) {
  switch (slotPair[0]) {
    case 'img':
      return setImageSlotValue(node, slotPair[1]);
    case 'txt':
      return setTextSlotValue(node, slotPair[1]);
  }
}

export function getClosestAdditionalSlotNode(ev: MouseEvent) {
  const target = ev.target as Element;
  return target.closest('[data-minga-additional-slot]');
}

export function getClosestSlotNode(ev: MouseEvent) {
  const target = ev.target as Element;
  return target.closest('[id^="minga_"],[data-minga-additional-slot]');
}

export function normalizeTextSlotStyle(node: SVGElement): SVGElement {
  return node;
}

export function normalizeBgSlotStyle(node: SVGElement): SVGElement {
  // If our background slot is a `<g>` we replace it with a child <rect> or
  // <image>
  if (node.nodeName.toLowerCase() === 'g') {
    let childNode = node.querySelector('rect') || node.querySelector('image');
    if (!childNode) {
      console.warn(
        'Cannot normalize background slot node. Invalid tree structure',
      );
      return node;
    }

    node.parentElement!.replaceChild(childNode, node);

    copyAttrOrRetain(node, childNode, 'id');
    copyAttrOrRetain(node, childNode, 'transform');
    copyAttrOrRetain(node, childNode, 'x');
    copyAttrOrRetain(node, childNode, 'y');

    node = childNode;
  }

  if (node.hasAttribute('fill') && !node.style.fill) {
    node.style.fill = node.getAttribute('fill')!;
    node.removeAttribute('fill');
  } else {
    node.removeAttribute('fill');
  }

  return node;
}

export function normalizeImageSlotStyle(node: SVGElement): SVGElement {
  return node;
}
