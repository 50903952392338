import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GroupPreviewClickMode } from 'minga/app/src/app/groups/components/MgGroupPreview';

/**
 * Wrapper around mg-parent-group-picker that has default copy text and spacing
 */
@Component({
  selector: 'mg-default-parent-group-picker',
  templateUrl: './DefaultParentGroupPicker.component.html',
  styleUrls: ['./DefaultParentGroupPicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultParentGroupPickerComponent {
  /**
   * Pass through to ParentGroupPickerComponent.clickMode
   */
  @Input()
  clickMode: GroupPreviewClickMode = GroupPreviewClickMode.GROUP;

  /**
   * Pass through to ParentGroupPickerComponent.noSuccessInterstitial
   */
  @Input()
  noSuccessInterstitial: boolean = false;
}
