import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LayoutService } from '@modules/layout/services';

import { ModalOverlayServiceCloseEventType } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  ScheduledReportMessages,
  ScheduledReportTableMessages,
} from './constants';
import { MmScheduledReportsService } from './services/mm-scheduled-reports.service';
import { ScheduledReportsStore } from './store/scheduled-reports.store';

@Component({
  selector: 'mg-mm-scheduled-reports',
  templateUrl: './mm-scheduled-reports.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./mm-scheduled-reports.component.scss'],
})
export class MmScheduledReportsComponent {
  public MESSAGES = ScheduledReportMessages;
  constructor(
    public layout: LayoutService,
    private _mmScheduledReportService: MmScheduledReportsService,
    private _scheduledReportsStore: ScheduledReportsStore,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  public addScheduledReport(event) {
    const modalRef = this._mmScheduledReportService.openModal({});

    modalRef.afterClosed.subscribe(async response => {
      const { type, data } = response;

      if (type === ModalOverlayServiceCloseEventType.CREATE) {
        this._scheduledReportsStore.addScheduledReport(data.created);
        this._systemAlertSnackBar.open({
          type: 'success',
          message: ScheduledReportTableMessages.SNACKBAR_CREATE_SUCCESS,
        });
      }
    });
  }
}
