import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import * as date_range_pb from 'minga/proto/date_range/date_range_preset_pb';
import { IDateRangePreset } from 'minga/domain/dateRange/DateRangePreset';
import { DateRangePresets } from 'minga/proto/date_range/date_range_preset_ng_grpc_pb';
import {
  fromProto,
  toProto,
} from 'minga/shared-grpc/date_range/DateRangePreset.mapper';
import { mingaSettingTypes } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { ClientDatePreset } from '@modules/minga-manager/components/mm-date-presets/types/mm-date-presets.types';
import {
  customDatePresetMapper,
  defaultDatePresetMapper,
} from '@modules/minga-manager/components/mm-date-presets/utils/date-presets.utils';

import { DATE_PICKER_DEFAULT_PRESETS } from '@shared/components/form/components/form-date-range/form-date-range.constants';

import { CacheService } from '../cache/cache.service';
import { CacheKey } from '../cache/cache.types';
import { ErrorHandlerService } from '../error-handler/error-handler.service';

@Injectable({ providedIn: 'root' })
export class DatePresetsService {
  private _datePresetChangeSubject = new Subject<ClientDatePreset>();
  public datePresetChange$ = this._datePresetChangeSubject.asObservable();

  private _cachedCustomPresets = this._cacheService.create<ClientDatePreset[]>(
    CacheKey.DATE_PRESETS_CUSTOM,
    data => {
      // lets always fetch all presets and just client side filter active/inactive
      return this._fetchPresets(data);
    },
    {
      ttl: 60,
    },
  );

  constructor(
    private _datePresetsService: DateRangePresets,
    private _errorHandler: ErrorHandlerService,
    private _mingaSettings: MingaSettingsService,
    private _cacheService: CacheService,
  ) {}

  public async fetch(id: number): Promise<IDateRangePreset> {
    try {
      const request = new date_range_pb.GetDateRangePresetRequest();
      request.setId(id);

      const response = await this._datePresetsService.getDateRangePreset(
        request,
      );
      return fromProto(response.getDateRangePreset());
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch date preset',
        error,
        true,
      );
    }
  }

  private async _fetchAll(opts?: {
    onlyActive?: boolean;
  }): Promise<ClientDatePreset[]> {
    const { onlyActive } = opts || {};

    const request = new date_range_pb.GetDateRangePresetsRequest();

    if (onlyActive) {
      request.setOnlyActive(true);
    }

    const response = await this._datePresetsService.getDateRangePresets(
      request,
    );

    const presets = response.getDateRangePresetsList().map(fromProto);
    return presets.map(customDatePresetMapper);
  }

  public updateCache(items: ClientDatePreset[]) {
    this._cachedCustomPresets.set(items);
  }

  private async _fetchPresets(opts?: {
    onlyActive?: boolean;
  }): Promise<ClientDatePreset[]> {
    const customPresets = await this._fetchAll({ onlyActive: false });
    const defaultPresets = await this._getDefaultDatePresets();
    return [...defaultPresets, ...customPresets];
  }

  public async fetchPresets(opts?: {
    onlyActive?: boolean;
    revalidate?: boolean;
  }): Promise<ClientDatePreset[]> {
    try {
      const filterFn = opts?.onlyActive ? preset => preset.active : () => true;
      const response = await this._cachedCustomPresets
        .get(
          {},
          {
            revalidate: opts?.revalidate,
          },
        )
        .toPromise();

      return response.filter(filterFn);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch date presets',
        error,
        true,
      );
    }
  }

  public async create(datePreset: IDateRangePreset): Promise<IDateRangePreset> {
    try {
      const request = new date_range_pb.CreateDateRangePresetRequest();
      request.setDateRangePreset(toProto(datePreset));
      const response = await this._datePresetsService.createDateRangePreset(
        request,
      );
      return fromProto(response.getDateRangePreset());
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to create date preset',
        error,
        true,
      );
    }
  }

  public async update(datePreset: IDateRangePreset): Promise<IDateRangePreset> {
    try {
      const request = new date_range_pb.UpdateDateRangePresetRequest();
      request.setDateRangePreset(toProto(datePreset));
      const response = await this._datePresetsService.updateDateRangePreset(
        request,
      );
      return fromProto(response.getDateRangePreset());
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to update date preset',
        error,
        true,
      );
    }
  }

  public async delete(id: number): Promise<void> {
    try {
      const request = new date_range_pb.DeleteDateRangePresetRequest();
      request.setId(id);
      await this._datePresetsService.deleteDateRangePreset(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete date preset',
        error,
        true,
      );
    }
  }

  public async toggleActive(item: ClientDatePreset): Promise<void> {
    try {
      if (item.isDefaultPreset) {
        await this._updateDefaultPresetActive(item);
      } else {
        await this._updateCustomPresetActive(item);
      }
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to toggle date preset active status',
        error,
        true,
      );
    }
  }

  private async _updateCustomPresetActive(item: ClientDatePreset) {
    try {
      const request = new date_range_pb.ToggleDateRangePresetActiveRequest();
      request.setId(item.id as number);
      await this._datePresetsService.toggleDateRangePresetActive(request);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to toggle date preset active status',
        error,
        true,
      );
    }
  }

  private async _updateDefaultPresetActive(item: ClientDatePreset) {
    const existing: string[] = await this._mingaSettings.getSettingValue(
      mingaSettingTypes.DATE_PRESETS_INACTIVE,
    );
    const active = !item.active;
    const updated: string[] = active
      ? existing.filter(preset => preset !== item.id)
      : [...existing, item.id as string];

    await this._mingaSettings.updateSetting(
      mingaSettingTypes.DATE_PRESETS_INACTIVE,
      updated,
    );
  }

  private async _getDefaultDatePresets(): Promise<ClientDatePreset[]> {
    const inactivePresets = await this._mingaSettings.getSettingValue(
      mingaSettingTypes.DATE_PRESETS_INACTIVE,
    );
    const defaultPresets = DATE_PICKER_DEFAULT_PRESETS.map(preset => {
      return defaultDatePresetMapper(preset, inactivePresets);
    });

    return defaultPresets;
  }

  public datePresetChange(preset: ClientDatePreset) {
    this._datePresetChangeSubject.next(preset);
  }
}
