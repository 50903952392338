import { FormGroup } from '@angular/forms';

import { Person } from 'minga/app/src/app/people';

import { peopleToIssuedFilters } from '@modules/behavior-manager/components/bm-reports/utils';

import { getFilterGroupControlKey } from '../../../constants';
import { ScheduledReportType } from '../../../types';

export const setIssuedOnPeopleSelect = (
  people: Partial<Person>[],
  form: FormGroup,
  {
    reportType,
    issuedToKey,
    issuedByKey,
  }: {
    reportType: ScheduledReportType;
    issuedToKey: string;
    issuedByKey: string;
  },
) => {
  const { issuedTo, issuedBy } = peopleToIssuedFilters(
    people,
    reportType as any,
  );
  const issuedToControl = form.get(getFilterGroupControlKey(issuedToKey));
  const issuedByControl = form.get(getFilterGroupControlKey(issuedByKey));

  issuedToControl.setValue(issuedTo.length ? issuedTo : null);
  issuedByControl.setValue(issuedBy.length ? issuedBy : null);
};
