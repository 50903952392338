<mg-button-icon
  (click)="cancel()"
  class="top left"
  icon="close"></mg-button-icon>

<p class="mg-dialog message dialog-title">
  Would you like to remove the program <strong>{{ programName }}</strong> from
  <strong>{{ mingaName }}</strong
  >?
</p>

<div class="btn-wrap footer-button-container">
  <mg-button
    type="text"
    (click)="cancel()"
    >{{ 'button.cancel' | translate }}</mg-button
  >
  <mg-button
    (click)="remove()"
    type="outline"
    >Remove</mg-button
  >
</div>
