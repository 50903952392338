import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import * as image_pb from 'minga/proto/image/image_pb';
import {
  IGalleryLightboxItem,
  IGalleryLightboxItemTag,
} from 'minga/app/src/app/components/Lightbox/GalleryLightbox';
import { MENTION_CHAR } from 'minga/app/src/app/mentions/constants';
import { GalleryItem } from 'minga/proto/gateway/gallery_pb';
import { MingaPermission } from 'minga/util';
import { MentionsService } from 'src/app/mentions';
import { AuthService } from 'src/app/minimal/services/Auth';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { PermissionsService } from 'src/app/permissions';

import { HomeGalleryComponent } from '../home-gallery.component';

export class GalleryItemWrapper implements IGalleryLightboxItem {
  tags: IGalleryLightboxItemTag[];

  get authorDisplayName() {
    return this._galleryItem.authorDisplayName;
  }

  get authorPersonHash() {
    return this._galleryItem.authorPersonHash;
  }

  get shouldShowDelete() {
    const canDeleteOthers = this._permissions.hasPermission(
      MingaPermission.GALLERY_DELETE_OTHERS_PHOTO,
    );
    const isAuthor =
      this._authInfoService.authPersonHash === this.authorPersonHash;
    return canDeleteOthers || isAuthor;
  }

  get shouldShowResolve() {
    return this._permissions.hasPermission(
      MingaPermission.CONTENT_REPORTS_MANAGE,
    );
  }

  get shouldShowReport() {
    return this._permissions.hasPermission(
      MingaPermission.CONTENT_REPORT_CREATE,
    );
  }

  get description() {
    return this._galleryItem.description;
  }

  get id() {
    return this._galleryItem.galleryPhotoUuid;
  }

  get timestamp() {
    return this._galleryItem.timestamp;
  }

  get imageInfo(): image_pb.ImageInfo.AsObject {
    return (
      this._galleryItem.imageInfo || {
        sizeMap: [],
      }
    );
  }

  constructor(
    private _galleryItem: GalleryItem.AsObject,
    public index: number,
    private _galleryRoute: HomeGalleryComponent,
    private _authService: AuthService,
    private _permissions: PermissionsService,
    private _mentionsService: MentionsService,
    private _authInfoService: AuthInfoService,
  ) {
    this.tags = this._galleryItem.tagList.map(tagItem => {
      let id = '';
      let name$: Observable<string>;
      const clickHandler = (ev: MouseEvent) =>
        this._galleryRoute.onTagClick(ev, tagItem);
      if (tagItem.contentTag) {
        id = tagItem.contentTag.contentHash;
      } else if (tagItem.groupTag) {
        const groupHash = tagItem.groupTag.groupHash;
        id = groupHash;
      } else if (tagItem.personTag) {
        id = tagItem.personTag.personHash;
      }
      // person, group and content tags all have MENTION_CHAR prefix
      if (tagItem.personTag && tagItem.personTag.displayName) {
        // can't rely on all people being in the mentions service
        // so we send it as part of the data from the backend.
        name$ = of(MENTION_CHAR + tagItem.personTag.displayName);
      } else {
        name$ = this._mentionsService.getMentionable(id).pipe(
          map(mentionable => {
            if (mentionable) {
              return MENTION_CHAR + mentionable.displayName;
            }

            return '';
          }),
        );
      }
      const tag: IGalleryLightboxItemTag = {
        id,
        name$,
        clickHandler,
      };
      return tag;
    });
  }
}
