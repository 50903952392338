import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Tile } from '@modules/selection-assigner/types';

import { MediaService } from '@shared/services/media';

import {
  FORM_FIELDS,
  MOST_USED_COUNT,
  MyClassMessages,
} from '../../constants/tt-my-class.constants';
import { mapActionGroupsToItems } from '../../services/my-class.utils';
import {
  ActionCategory,
  ActionGroup,
  ActionItem,
  CategoryType,
} from '../../types/tt-my-class.types';

@Component({
  selector: 'mg-my-class-sidebar',
  templateUrl: './tt-sidebar.component.html',
  styleUrls: ['./tt-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TtSidebarComponent implements OnInit, OnDestroy {
  private _destroyedSubject = new ReplaySubject<void>(1);
  private _actionGroupsSubject = new BehaviorSubject<ActionGroup[]>([]);
  private _actionGroups$ = this._actionGroupsSubject.asObservable();

  @Input() set actionGroups(actionGroups: ActionGroup[]) {
    this._actionGroupsSubject.next(actionGroups);
    this._actionsSubject.next(mapActionGroupsToItems(actionGroups));
  }
  @Input() form: FormGroup;
  @Output() assign = new EventEmitter<void>();
  private _selectedSearchAction: ActionItem;

  private _showMoreActionsSubject = new BehaviorSubject<boolean>(false);
  public showMoreActions$ = this._showMoreActionsSubject.asObservable();

  private _tilesToShowSubject = new BehaviorSubject<number>(MOST_USED_COUNT);
  public tilesToShow$ = this._tilesToShowSubject.asObservable();
  public FORM_FIELDS = FORM_FIELDS;
  public MESSAGES = MyClassMessages;

  private _assignmentActionsSubject = new BehaviorSubject<
    Array<{ tile: Tile; action: ActionItem }>
  >([]);
  public assignmentActions$ = this._assignmentActionsSubject.asObservable();

  public categories$: Observable<ActionCategory[]> = this._actionGroups$.pipe(
    map(actionGroups => {
      const categories = (actionGroups || []).map(g => {
        const { items, ...rest } = g;
        return rest;
      });

      return categories;
    }),
  );

  private _actionsSubject = new BehaviorSubject<ActionItem[]>([]);
  public actions$ = this._actionsSubject.asObservable();

  public groupedOptions$ = this._actionGroups$.pipe(
    map(categories => {
      return categories.filter(c => c.value !== CategoryType.MOST_USED);
    }),
  );

  constructor(
    public mediaService: MediaService,
    private _cdr: ChangeDetectorRef,
  ) {}

  get tileClasses() {
    const showMore = this._showMoreActionsSubject.value;
    const tilesToShow = this._tilesToShowSubject.value;

    return {
      'show-more-actions': showMore,
      [`tiles-${tilesToShow}`]: true,
    };
  }

  ngOnInit(): void {
    this._selectedSearchAction =
      this.form.get(FORM_FIELDS.SELECTED_ACTION).value || null;
    this._onCategoryChange();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public toggleShowMore() {
    const current = this._showMoreActionsSubject.value;
    this._showMoreActionsSubject.next(!current);
    this._cdr.detectChanges();
  }

  public searchActionChange(event: ActionItem) {
    this._selectedSearchAction = event;
    this.form.get(FORM_FIELDS.CATEGORY).setValue(event.category.value);
    this.form.get(FORM_FIELDS.SELECTED_ACTION).setValue(event);
  }

  public selectAction(event: ActionItem) {
    this.form.get(FORM_FIELDS.SELECTED_ACTION).setValue(event);
  }

  public onCategoryChange(event) {
    // default to setting first option as active
    const categoryActions = this._findCategoryActions(event);
    this.form.get(FORM_FIELDS.SELECTED_ACTION).setValue(categoryActions?.[0]);
    this._showMoreActionsSubject.next(false);
  }

  public trackByFn(index: number, item: { tile: Tile; action: ActionItem }) {
    return item.action.value || index;
  }

  private _onCategoryChange() {
    this.form
      .get(FORM_FIELDS.CATEGORY)
      .valueChanges.pipe(takeUntil(this._destroyedSubject))
      .subscribe(category => {
        if (!category) this._assignmentActionsSubject.next([]);

        const selectedSearchAction = this._selectedSearchAction?.value;

        const categoryActions = this._findCategoryActions(category);
        const index = categoryActions.findIndex(
          a => a.value === selectedSearchAction,
        );

        // if the user chooses an action from the search dropdown we need to make sure it's visible without having to click "show more" by bubbling it to the top of the list
        const existBelowFold = index >= MOST_USED_COUNT;
        if (existBelowFold) {
          categoryActions.sort((a, b) => {
            if (a.value === selectedSearchAction) return -1;

            return 1;
          });
        }

        this._tilesToShowSubject.next(
          existBelowFold ? MOST_USED_COUNT + 1 : MOST_USED_COUNT,
        );

        this._selectedSearchAction = null;

        const tiles = categoryActions.map(a => ({
          tile: {
            type: (a as any).category.value,
            id: a.value,
            name: a.label,
            icon: a.icon,
            iconNamespace: a.iconNamespace,
            color: a.color,
          },
          action: a,
        }));

        this._assignmentActionsSubject.next(tiles);

        // shouldnt need this but the view isn't updating for some reason
        this._cdr.detectChanges();
      });
  }

  private _findCategoryActions(category: CategoryType): ActionItem[] {
    const actions = this._actionsSubject.value;
    return actions.filter(a => a.category.value === category);
  }
}
