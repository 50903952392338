export enum CheckinTypesMessages {
  PAGE_TITLE = 'Check In Reasons',

  BUTTON_CREATE_NEW = 'Create reason',

  COLUMN_TITLE_REASON = 'Reason',
  COLUMN_TITLE_CATEGORY = 'Category',
  COLUMN_TITLE_POINTS = 'Points (optional)',
  COLUMN_TITLE_STICKER = 'Attach stickers',
  COLUMN_TITLE_ICONS = 'Icons',
  COLUMN_TITLE_SHOW_ABSENTS = 'Show absents',
  COLUMN_TITLE_STATUS = 'Status',
  COLUMN_TITLE_DELETE = 'Delete',
}
