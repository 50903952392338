<div
  *ngIf="isBool()"
  fxLayout="row"
  fxLayoutGap="8px"
  class="pref-input-wrap">
  <div
    class="checkbox-label"
    fxFlex="auto">
    <ng-content select="mg-user-preference-input-label"></ng-content>
  </div>

  <mat-slide-toggle
    class="no-mg"
    [checked]="value"
    (change)="valueChange.emit($event.checked)"></mat-slide-toggle>
</div>
<mat-form-field
  mgFormField
  *ngIf="isString()">
  <mat-label>
    <ng-content select="mg-user-preference-input-label"></ng-content>
  </mat-label>
  <input
    matInput
    type="text"
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="value"
    (ngModelChange)="valueChange.emit($event)" />
</mat-form-field>
