export enum PmSettingsMessages {
  PAGE_TITLE = 'Points Settings',
  SUBHEADING_LEADERBOARD_TITLE = 'Leaderboard Settings',
  SUBHEADING_REWARDS_TITLE = 'Rewards Settings',
  SUBHEADING_PERMISSIONS_TITLE = 'Points Settings',

  SETTING_ENABLE_LEADERBOARD = 'Enable leaderboard',
  SETTING_SHOW_INDIVIDUAL_POINTS = 'Show individual points on leaderboard',
  SETTING_SHOW_TEAM_POINTS = 'Show team points on leaderboard',
  SETTING_TEACHERS_ALLOCATE_POINTS = 'Allow Teachers to manage points',
  SETTING_STAFF_ALLOCATE_POINTS = 'Allow Staff to manage points',
  SETTING_ENABLE_REWARDS = 'Enable rewards',
  SETTING_TEACHERS_CAN_MANAGE = 'Allow Teachers to redeem points',
  SETTING_STAFF_CAN_MANAGE = 'Allow Staff to redeem points',

  SETTING_TIP_TEACHERS_POINTS_MANAGE = 'Teachers can add or remove points from students via the teacher tools',
  SETTING_TIP_STAFF_POINTS_MANAGE = 'Staff can add or remove points from students via the teacher tools',
}
