export enum BmDashboardMessages {
  SECTION_HEADER_BEHAVIORS = 'Behaviors',
  SECTION_HEADER_CONSEQUENCES = 'Consequences',
  SECTION_HEADER_AUTOMATIONS = 'Automations',

  SELECT_OPTION_NONE = 'None',

  AUTO_REFRESH = 'Auto refresh',

  BUTTON_LABEL_REFRESH = 'Refresh dashboard',
  BUTTON_LABEL_PRAISE = 'Praise',
  BUTTON_LABEL_GUIDANCE = 'Guidance',
  BUTTON_LABEL_IMPORT = 'Import',

  EMPTY_STATE_TITLE = 'No data found',
  EMPTY_STATE_SUBTITLE_BEHAVIORS = 'No data available for behaviors',
  EMPTY_STATE_SUBTITLE_CONSEQUENCES = 'No data available for consequences',
  EMPTY_STATE_SUBTITLE_AUTOMATIONS = 'No data available for automations',
}

export const AUTO_REFRESH_STATE = 'autoBmRefreshState';
export const POLLING_INTERVAL = 60000;
