<div class="container">
  <mg-icon (click)="toggle()"> mg-help-center </mg-icon>

  <div
    class="dropdown-container"
    [class.show]="isOpen$ | async">
    <div
      *ngFor="let row of data; let i = index"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="4px">
      <span class="label"> {{ row[0] }}: </span>
      <span class="value">
        {{ row[1] }}
      </span>
    </div>
  </div>
</div>
