<mg-manager-overlay-reports-subpage
  [loading]="false"
  [scheduleReportsButton]="
    (SUPPPORTED_SCHEDULE_TYPES | includes: reportType.active) &&
    (canScheduleReport$ | async)
  "
  [dataService]="dataService$ | async"
  (scheduleReportsPressed)="onScheduleReport()"
  (exportListPressed)="handleOnClickExport()">
  <!-- Header -->
  <ng-template #header>
    <ng-container
      *ngIf="
        (media$ | async) !== 'xs' && (media$ | async) !== 'sm';
        else mobileHeaderTemplate
      ">
      <div class="header-container">
        <div class="filters-group">
          <div class="filters-group-row">
            <ng-container *ngTemplateOutlet="reportTypeFieldTemplate">
            </ng-container>
            <ng-container
              *ngIf="
                reportType.active === REPORT_TYPES.CHECKIN;
                else dateRangePickerFieldTemplate
              "
              [ngTemplateOutlet]="datePickerFieldTemplate"></ng-container>
            <ng-container
              *ngTemplateOutlet="personSearchFieldTemplate"></ng-container>
          </div>
          <div class="filters-group-row">
            <ng-container *ngTemplateOutlet="gradeFieldTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="typesFieldTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="roleFieldTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="userListTemplate"></ng-container>
            <ng-container
              *ngIf="reportType.active !== REPORT_TYPES.CHECKIN"
              [ngTemplateOutlet]="filterButtonTemplate"></ng-container>
          </div>
          <div
            *ngIf="reportType.active === REPORT_TYPES.CHECKIN"
            class="filters-group-row">
            <ng-container
              *ngTemplateOutlet="absenteeToggleFieldTemplate"></ng-container>
            <ng-container
              *ngTemplateOutlet="filterButtonTemplate"></ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Field Template -->
<ng-template #reportTypeFieldTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="285px">
    <mg-form-select
      placeholder="Report Type"
      labelBackground="white"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="reportType.active"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="reportType.options"
      [floatingLabel]="false"
      (selectionChanged)="handleOnChangeReportType($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Field Template -->
<ng-template #datePickerFieldTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="250px">
    <mg-date-time-picker
      name="checkindate"
      [dateOnly]="true"
      [required]="true"
      [canReset]="true"
      [hideOptionalMarker]="true"
      [(ngModel)]="startDate"
      (ngModelChange)="handleOnChangeDate($event)">
    </mg-date-time-picker>
  </div>
</ng-template>

<!-- Date Picker Field Template -->
<ng-template #dateRangePickerFieldTemplate>
  <div class="filters-field half-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>

<!-- Person Search Field Template -->
<ng-template #personSearchFieldTemplate>
  <div
    class="filters-field full-width"
    fxFlex.gt-sm="300px">
    <mg-multi-person-search
      [id]="'report-report-view-click-filter-search'"
      (selectionChanged)="handleOnPersonSelected($event)">
    </mg-multi-person-search>
  </div>
</ng-template>

<!-- Types Field Template -->
<ng-template #typesFieldTemplate>
  <div class="half-width">
    <mg-form-select
      placeholder="Reason type"
      labelBackground="white"
      [value]="reason$ | async"
      [closeOnSelect]="true"
      [multiple]="true"
      [searchable]="false"
      [isFullWidth]="true"
      [isClearable]="true"
      [options]="reasonOptions$ | async"
      (selectionChanged)="handleOnChangeReasonFilter($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Role Field Template -->
<ng-template #roleFieldTemplate>
  <div class="half-width">
    <mg-form-select
      placeholder="Role"
      labelBackground="white"
      [id]="'report-report-view-click-filter-role'"
      [value]="role$ | async"
      [closeOnSelect]="true"
      [multiple]="true"
      [searchable]="false"
      [isFullWidth]="true"
      [isClearable]="true"
      [options]="roleOptions$ | async"
      (selectionChanged)="handleOnChangeRoleFilter($event)">
    </mg-form-select>
  </div>
</ng-template>

<ng-template #userListTemplate>
  <div class="half-width">
    <mg-user-list-filter
      [id]="'report-report-view-click-filter-userlist'"
      [placeholder]="MESSAGES.SELECT_LABEL_USER_LIST"
      [value]="(serviceFilter$ | async).userList"
      (selectChanged)="changeUserList($any($event))">
    </mg-user-list-filter>
  </div>
</ng-template>

<!-- Grade Field Template -->
<ng-template #gradeFieldTemplate>
  <div class="half-width">
    <mg-form-select
      placeholder="Grade"
      labelBackground="white"
      [id]="'report-report-view-click-filter-grade'"
      [value]="grade$ | async"
      [closeOnSelect]="true"
      [multiple]="true"
      [searchable]="false"
      [isFullWidth]="true"
      [isClearable]="true"
      [options]="gradeOptions$ | async"
      (selectionChanged)="handleOnChangeGradeFilter($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Absentee Toggle Field Template -->
<ng-template #absenteeToggleFieldTemplate>
  <mat-button-toggle-group
    name="reportType"
    class="filter-toggle-group"
    [ngClass]="{ disabled: disableAbsentee | async }"
    [disabled]="disableAbsentee | async"
    [value]="type$ | async"
    (change)="handleOnChangeReasonType($event)">
    <mat-button-toggle [value]="REASON_TYPES.CHECKINS">
      <mg-text variant="body-sm-secondary"> Check ins </mg-text>
    </mat-button-toggle>
    <mat-button-toggle [value]="REASON_TYPES.ABSENTEES">
      <mg-text variant="body-sm-secondary"> Absentees </mg-text>
    </mat-button-toggle>
  </mat-button-toggle-group>
</ng-template>

<!-- Filter Buttons Template -->
<ng-template #filterButtonTemplate>
  <div
    fxFlex="noshrink"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="10px">
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-apply'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'filled'
            : 'outlined'
        "
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [responsive]="true"
        [wide]="true"
        (pressed)="applyFilter()">
        {{ MESSAGES.BUTTON_APPLY_FILTER }}
      </mg-btn>
    </div>
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-clear'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'outlined'
            : 'text'
        "
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [responsive]="true"
        [wide]="true"
        (pressed)="clearFilter()">
        {{ MESSAGES.BUTTON_CLEAR_FILTER }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<ng-template #mobileHeaderTemplate>
  <div class="header-container">
    <div class="filters-group">
      <div class="filters-group-row">
        <ng-container *ngTemplateOutlet="reportTypeFieldTemplate">
        </ng-container>
        <ng-container
          *ngIf="
            reportType.active === REPORT_TYPES.CHECKIN;
            else dateRangePickerFieldTemplate
          "
          [ngTemplateOutlet]="datePickerFieldTemplate"></ng-container>
        <ng-container
          *ngTemplateOutlet="personSearchFieldTemplate"></ng-container>
      </div>
      <mg-collapsible
        [collapseText]="MESSAGES.HIDE_FILTERS"
        [expandText]="MESSAGES.SHOW_FILTERS">
        <div class="filters-group-row">
          <ng-container *ngTemplateOutlet="gradeFieldTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="typesFieldTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="roleFieldTemplate"></ng-container>
          <ng-container [ngTemplateOutlet]="userListTemplate"></ng-container>
          <ng-container
            *ngIf="reportType.active === REPORT_TYPES.CHECKIN"
            [ngTemplateOutlet]="absenteeToggleFieldTemplate"></ng-container>
        </div>
      </mg-collapsible>
      <div class="filters-group-row">
        <ng-container *ngTemplateOutlet="filterButtonTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
