<!-- Charts Summary -->
<section
  class="section white-bg"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start center"
  fxLayoutAlign.xs="start stretch"
  fxLayoutGap="20px">
  <div
    *ngIf="isLoading; else contentContainer"
    fxFlex="grow"
    class="loading-container">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>
</section>

<!-- Content Container-->
<ng-template #contentContainer>
  <div
    fxFlex="nogrow"
    fxFlex.xs="100%">
    <ng-content select="ul#metrics"></ng-content>
  </div>
  <div fxFlex="grow">
    <ng-content select="div#chart"></ng-content>
  </div>
</ng-template>
