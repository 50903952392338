<mat-table
  *ngIf="dataSource.data?.length; else noResults"
  matSort
  class="list-table no-explicit-mobile-layout list-table-relaxed"
  [dataSource]="dataSource"
  [trackBy]="trackById">
  <!-- Icon Column -->
  <ng-container matColumnDef="icon">
    <mat-header-cell
      *matHeaderCellDef
      class="w-10 text-center"></mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="w-10 text-center">
      <mg-icon
        class="checkin-icon"
        [style]="{ '--icon-color': item.color }">
        {{ item.icon }}
      </mg-icon>
    </mat-cell>
  </ng-container>

  <!-- Reason Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header>
      {{ MESSAGES.COLUMN_LABEL_REASON }}
    </mat-header-cell>
    <mat-cell *matCellDef="let item">
      <mg-text variant="header-sm">
        {{ item.name }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="active">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      class="w-16 text-center">
      {{ MESSAGES.COLUMN_LABEL_STATUS }}
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="w-16 text-center">
      <mg-form-toggle
        [id]="'checkin-types-click-toggle-' + item.id"
        [value]="item.active"
        (valueChange)="onStatusChange(item)">
      </mg-form-toggle>
    </mat-cell>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <mat-header-cell
      *matHeaderCellDef
      class="w-12 text-center">
      {{ MESSAGES.COLUMN_LABEL_EDIT }}
    </mat-header-cell>
    <mat-cell
      *matCellDef="let item"
      class="w-12 text-center">
      <mg-btn
        variant="icon"
        iconSet="minga"
        icon="mg-edit"
        [id]="'checkin-types-click-edit'"
        (pressed)="handleOnClickEditReason(item)">
      </mg-btn>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
  <mat-row *matRowDef="let row; columns: DISPLAYED_COLUMNS"></mat-row>
</mat-table>

<!-- Template: No Results -->
<ng-template #noResults>
  <mg-empty-state
    [title]="MESSAGES.NO_RESULTS_TITLE"
    [subtitle]="MESSAGES.NO_RESULTS_MESSAGE">
  </mg-empty-state>
</ng-template>
