<mat-form-field
  class="mg-minga-name"
  mgFormField
  hideRequiredMarker
  floatLabel="always"
  [mgMultiLineHint]="true">
  <mat-label>
    <ng-content select="mg-minga-name-label"></ng-content>
    <ng-container *ngIf="!labelSlot"> Minga Name </ng-container>
  </mat-label>
  <input
    matInput
    type="text"
    [required]="required"
    [formControl]="inputControl"
    (ngModelChange)="change($event)" />

  <div
    *ngIf="
      outerFormControl &&
      outerFormControl.touched &&
      outerFormControl.asyncValidator
    "
    class="input-icon"
    mat-icon-button
    matSuffix>
    <mat-icon *ngIf="outerFormControl.valid">done</mat-icon>
    <mat-icon *ngIf="outerFormControl.invalid">error_outline</mat-icon>
    <mg-spinner
      color="primary"
      mode="indeterminate"
      diameter="25"
      *ngIf="outerFormControl.pending">
    </mg-spinner>
  </div>

  <mat-hint>
    <ng-content select="mg-minga-name-hint"></ng-content>
    <ng-container *ngIf="!hintSlot">
      {{ 'input.mingaName.hint' | translate }}
    </ng-container>
  </mat-hint>
  <mat-error *ngIf="inputControlErrorKey">
    <ng-container [ngSwitch]="inputControlErrorKey">
      <span *ngSwitchCase="'maxlength'">
        <!-- The name must be 70 characters or less -->
        {{ 'input.mingaName.error.maxlength' | translate }}
      </span>
      <span *ngSwitchCase="'pattern'">
        <!-- The group name contains invalid characters -->
        {{ 'input.mingaName.error.pattern' | translate }}
      </span>
      <span *ngSwitchCase="'required'">
        <!-- You must provide a name -->
        {{ 'input.mingaName.error.required' | translate }}
      </span>
    </ng-container>
  </mat-error>
</mat-form-field>
