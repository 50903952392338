import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mg-form-select-option',
  templateUrl: './form-select-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSelectOptionComponent {
  @Input() option: {
    value: string;
    label: string;
    contextLabel?: string;
    disabled?: boolean;
  } & {
    [key: string]: unknown;
  };
}
