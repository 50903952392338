<mat-table
  class="data-table"
  [dataSource]="dataSource"
  [trackBy]="trackById">
  <ng-container [matColumnDef]="COLUMN_DEF.STATUS">
    <mat-header-cell
      *matHeaderCellDef
      class="text-center w-18">
      <mg-text
        variant="body-sm-secondary"
        color="surface"
        >{{ MESSAGES.COL_STATUS }}</mg-text
      >
    </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      class="text-center w-18">
      <mg-tag
        [color]="
          element.status === PAST_REPORT_STATUS.Success ? 'green' : 'red'
        "
        size="small">
        {{
          element.status === PAST_REPORT_STATUS.Success
            ? MESSAGES.STATUS_SUCCESS
            : MESSAGES.STATUS_ERROR
        }}
      </mg-tag>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.NAME">
    <mat-header-cell
      *matHeaderCellDef
      class="text-left w-md-flex">
      <mg-text
        variant="body-sm-secondary"
        color="surface"
        >{{ MESSAGES.COL_NAME }}</mg-text
      >
    </mat-header-cell>

    <mat-cell
      *matCellDef="let element"
      class="text-left w-md-flex">
      <mg-text
        variant="body-sm"
        color="surface"
        [numberOfLines]="2"
        >{{ element.name }}</mg-text
      >
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.DATE">
    <mat-header-cell
      *matHeaderCellDef
      class="text-left w-28">
      <mg-text
        variant="body-sm-secondary"
        color="surface"
        >{{ MESSAGES.COL_DATE }}</mg-text
      >
    </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      class="text-left w-28">
      <mg-text
        variant="body-sm"
        color="surface">
        {{ element.date | date: 'EEE - MMM d' }}</mg-text
      >
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.TIME">
    <mat-header-cell
      *matHeaderCellDef
      class="text-left desktop-only w-24">
      <mg-text
        variant="body-sm-secondary"
        color="surface"
        >{{ MESSAGES.COL_TIME }}</mg-text
      >
    </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      class="text-left desktop-only w-24">
      <mg-text
        variant="body-sm"
        color="surface"
        >{{ element.date | date: 'h:mm a' }}</mg-text
      >
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.DOWNLOAD">
    <mat-header-cell
      *matHeaderCellDef
      class="text-right w-grow">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      class="text-right w-grow">
      <ng-container *ngIf="element.status !== PAST_REPORT_STATUS.Success">
        <ng-container
          *ngIf="(media.isDesktopView$ | async) === false; else alertTemplate">
          <mg-btn
            class="error-icon"
            variant="icon-destructive"
            iconSet="minga"
            icon="warning-o"
            [tooltip]="'test'">
          </mg-btn>
        </ng-container>
        <ng-template #alertTemplate>
          <div class="error-wrap">
            <mg-system-alert
              type="error"
              [message]="element.status | reportError">
            </mg-system-alert>
          </div>
        </ng-template>
      </ng-container>
      <div
        *ngIf="DOWNLOADABLE_STATUSES | includes: element.status"
        [ngClass]="
          (media.isDesktopView$ | async)
            ? 'w-10 text-center'
            : 'w-10 text-right'
        ">
        <mg-btn
          icon="download-o"
          variant="icon"
          iconSet="minga"
          [disabled]="(downloadsInFlight$ | async).has(element.id)"
          (pressed)="download(element.id)">
        </mg-btn>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayColumns"
    [fxHide]="!dataSource?.data.length"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
</mat-table>

<div
  class="paginator"
  [fxHide]="(isLoading$ | async) === true || !dataSource?.data.length">
  <mg-paginator></mg-paginator>
</div>

<div
  *ngIf="isLoading$ | async"
  class="loading-container">
  <mg-spinner
    [diameter]="40"
    [thickness]="3">
  </mg-spinner>
</div>

<ng-container
  *ngIf="(isLoading$ | async) === false && !dataSource?.data.length">
  <mg-empty-state
    [title]="MESSAGES.NO_RESULTS_TITLE"
    [subtitle]="MESSAGES.NO_RESULTS_DESC">
  </mg-empty-state>
</ng-container>
