<ng-container *ngIf="media.isMobileView$ | async; else desktopTemplate">
  <mg-notifications></mg-notifications>
</ng-container>

<ng-template #desktopTemplate>
  <mg-tools-two-col-layout>
    <ng-template #leftCol>
      <mg-notifications></mg-notifications>
    </ng-template>
  </mg-tools-two-col-layout>
</ng-template>
