import * as $ngrx_entity from '@ngrx/entity';
import * as $ngrx_store_store from '@ngrx/store';
//TS2742
import { createSelector } from '@ngrx/store';

//TS2742
import { Group } from '../models/Group';
import { GroupCollectionState } from '../state';
import { getGroupsState } from './feature-selector';

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them usable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are
 * memoized and only recompute when arguments change. The created selectors can
 * also be composed together to select different pieces of state.
 */

/* selector that gets all groups (full object) in the store*/
export const getGroupEntitiesState = createSelector(
  getGroupsState,
  state => state.groupCollection,
);

const selectors = GroupCollectionState.entities.getSelectors(
  getGroupEntitiesState,
);

export const getAllGroups = selectors.selectAll;
export const getGroupEntities = selectors.selectEntities;
export const getTotalGroups = selectors.selectTotal;

export const getLoaded = (state: GroupCollectionState) => state.loaded;

export const getLoading = (state: GroupCollectionState) => state.loading;

export const getSelectedId = (state: GroupCollectionState) =>
  state.selectedGroupId;

export const getExpires = (state: GroupCollectionState) => state.expires;

export const getGroupsLoading = createSelector(
  getGroupEntitiesState,
  getLoading,
);

export const getGroupsLoaded = createSelector(getGroupEntitiesState, getLoaded);

export const getGroupsExpires = createSelector(
  getGroupEntitiesState,
  getExpires,
);

export const getMyGroups = createSelector(getAllGroups, groups =>
  groups.filter(Group.isMember),
);

export const getParentGroups = createSelector(getAllGroups, groups =>
  groups.filter(group => group.isParent),
);

export const getMyParentGroups = createSelector(getParentGroups, groups =>
  groups.filter(Group.isMember),
);

export const getAllGroupsForSchool = createSelector(getAllGroups, groups =>
  groups.filter(group => {
    if (group.isParent) {
      return Group.isMember(group);
    }
    return group;
  }),
);
export const getTotalSchoolGroups = createSelector(
  getAllGroupsForSchool,
  groups => groups.length,
);

export const getPendingGroups = createSelector(getAllGroups, groups =>
  groups.filter(Group.isPending),
);

export const getOwnerGroups = createSelector(getAllGroups, groups =>
  groups.filter(Group.isOwner),
);

export const getTotalMyGroups = createSelector(
  getMyGroups,
  groups => groups.length,
);

export const getGroupsByCategoryName = (categoryName: string) =>
  createSelector(getAllGroups, groups =>
    groups.filter(group => group.category === categoryName),
  );
