import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MediaChange } from '@angular/flex-layout';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CHECKIN_ICONS, ICheckinReason } from 'minga/domain/checkin';

import {
  BackDropColor,
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { CheckinService } from '@shared/services/checkin';

import { CheckinManagerReasonsEditComponent } from '../checkin-manager-reasons-edit';
import { newReasonRow } from './checkin-manager-reasons-table.animations';
import {
  CheckinManagerReasonsTableMessages,
  DISPLAYED_COLUMNS,
} from './checkin-manager-reasons-table.constants';

/**
 * Checkin Manager Reasons Table
 *
 * Uses v9 angular material table and sort
 *
 * @link https://v9.material.angular.io/components/table/overview
 * @link https://v9.material.angular.io/components/sort/overview
 */
@Component({
  selector: 'mg-checkin-manager-reasons-table',
  templateUrl: './checkin-manager-reasons-table.component.html',
  styleUrls: ['./checkin-manager-reasons-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [newReasonRow],
})
export class CheckinManagerReasonsTableComponent implements OnInit, OnDestroy {
  /** Mat Table Component */
  @ViewChild(MatTable)
  table: MatTable<any>;

  /** Mat Sort Component */
  @ViewChild(MatSort, { static: false })
  sort: MatSort;

  /** Messages */
  public readonly MESSAGES: typeof CheckinManagerReasonsTableMessages =
    CheckinManagerReasonsTableMessages;

  /** Displayed Columns */
  public DISPLAYED_COLUMNS = DISPLAYED_COLUMNS;

  /** Reason Icons */
  public readonly CHECKIN_REASON_ICONS = CHECKIN_ICONS;

  /** Media Subscription */
  private readonly _mediaSub$!: Observable<MediaChange[]>;

  /** Subscription Cleanup Helper */
  private _destroyed$ = new ReplaySubject<void>(1);

  /** Data Source */
  dataSource = new MatTableDataSource<ICheckinReason>([]);

  /** New Reason ID */
  newReasonId: number;

  /** Animation State */
  animationState = 'initial';

  @Input()
  set data(reasons: ICheckinReason[]) {
    this.dataSource.data = reasons;
    this.dataSource.sort = this.sort;
  }

  @Input()
  onNewReasonCreated: Observable<ICheckinReason>;
  private _onNewReasonCreated$: Subscription;

  @Output()
  refetchReasons: EventEmitter<string> = new EventEmitter();

  /** Component Constructor */
  constructor(
    private _checkinService: CheckinService,
    private _cdr: ChangeDetectorRef,
    private _modalOverlay: ModalOverlayService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {}

  /** Component Lifecycle: On Mount */
  ngOnInit(): void {
    /** On New Reason Created */
    this._onNewReasonCreated$ = this.onNewReasonCreated
      .pipe(takeUntil(this._destroyed$))
      .subscribe(d => this._handleOnNewReasonCreatedSub(d));
  }

  /** Component Lifecycle: On Unmount */
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Handle On New Reason Created Sub
   */
  private _handleOnNewReasonCreatedSub(reason: ICheckinReason): void {
    this.handleOnClickEditReason(reason, true);
  }

  /**
   * Handle On Cick Edit Reason
   */
  public async handleOnClickEditReason(
    item: ICheckinReason,
    isNewItem?: boolean,
  ): Promise<void> {
    if (!item) return;
    const { id } = item;
    const overlayData = {
      type: isNewItem ? 'new' : 'existing',
      data: isNewItem ? item : id,
    };
    const overlayRef = this._modalOverlay.open(
      CheckinManagerReasonsEditComponent,
      {
        backdrop: BackDropColor.ORANGE,
        data: overlayData,
        disposeOnNavigation: false,
      },
    );
    overlayRef.afterClosed.subscribe(d => {
      if (!d) return;
      const { type, data } = d;
      if (type === ModalOverlayServiceCloseEventType.SUBMIT) {
        if (data?.isNewReason) {
          this._systemAlertSnackBar.success(
            CheckinManagerReasonsTableMessages.SNACK_CREATE_SUCCESS,
          );

          const reason = data?.reason;
          this.newReasonId = reason.id;
          this.animationState = 'glow';
          this.dataSource.data = [reason, ...this.dataSource.data];
          this._cdr.markForCheck();
          setTimeout(() => {
            this.newReasonId = undefined;
            this.animationState = 'initial';
            this._cdr.markForCheck();
          }, 1000);
        } else {
          const msg = data?.deleted
            ? CheckinManagerReasonsTableMessages.SNACK_DELETE_SUCCESS
            : CheckinManagerReasonsTableMessages.SNACK_UPDATE_SUCCESS;
          this._systemAlertSnackBar.success(msg);
          this.refetchReasons.emit('');
          this._cdr.markForCheck();
        }
      }
    });
  }

  /**
   * On Status Change
   */
  async onStatusChange(item: ICheckinReason): Promise<void> {
    const checkinReason: ICheckinReason = item;
    try {
      await this._checkinService.toggleCheckinReasonActive(checkinReason);
      checkinReason.active = !checkinReason.active;
    } catch (error) {
      this._systemAlertSnackBar.error(
        CheckinManagerReasonsTableMessages.SNACK_UPDATE_FAIL,
      );
    }
  }

  /**
   * Track By ID
   *
   * Not sure why I am returning index if item doesn't exist.
   */
  public trackById(index: number, item: ICheckinReason) {
    return item ? item.id : index;
  }
}
