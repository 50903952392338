import { Injectable } from '@angular/core';

import { first } from 'rxjs/operators';

import { CommentHandlerService } from 'minga/app/src/app/services/CommentHandler';
import { ContentMetadataService } from 'minga/app/src/app/services/ContentMetadata';
import { dateTimeObjectToDateTimeMessage } from 'minga/app/src/app/util/date';
import { DateTimeDelta } from 'minga/proto/common/delta_pb';
import { MingaContent as MingaContentProto } from 'minga/proto/content/minga_ng_grpc_pb';
import {
  MingaAddContent,
  MingaContentResult,
  MingaMoveContent,
} from 'minga/proto/content/minga_pb';
import { ModerationResultMapper } from 'minga/shared-grpc/moderation';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { ContentManager, IContentSaveResult } from '../ContentManager';
import { MingaContentPublishInfoEditorComponent } from './MingaContentPublishInfoEditor.component';

export interface IMingaContentPublishInfo {
  publishDate: Date | null;
  unpublishDate: Date | null;
  endPinDate: Date | null;
  commentRoleTypes: string[] | null;
}

/**
 * Content manager for managing conent inside the current Minga.
 */
@Injectable({ providedIn: 'root' })
export class MingaContentManager extends ContentManager<
  null,
  IMingaContentPublishInfo
> {
  readonly name = 'MingaContentManager';

  constructor(
    private contentMetadata: ContentMetadataService,
    private mingaContentProto: MingaContentProto,
    private commentHandler: CommentHandlerService,
    private _snackbar: SystemAlertSnackBarService,
  ) {
    super();
  }

  async addContent(
    unusedMingaContext: null,
    contentHash: string,
    publishInfo: IMingaContentPublishInfo,
  ) {
    const request = new MingaAddContent();
    request.setContentHash(contentHash);

    if (publishInfo.publishDate) {
      request.setContentStartDateTime(
        dateTimeObjectToDateTimeMessage(publishInfo.publishDate),
      );
    }

    if (publishInfo.unpublishDate) {
      request.setContentEndDateTime(
        dateTimeObjectToDateTimeMessage(publishInfo.unpublishDate),
      );
    }

    if (publishInfo.endPinDate) {
      request.setContentPinEndDateTime(
        dateTimeObjectToDateTimeMessage(publishInfo.endPinDate),
      );
    }

    let response: MingaContentResult;
    try {
      response = await this.mingaContentProto.addContent(request);
    } catch (e) {
      this._snackbar.open({
        message: e.message,
        type: 'error',
      });
      return;
    }

    const moderationResult = response.hasModerationResult()
      ? ModerationResultMapper.toIModerationResult(
          response.getModerationResult(),
        )
      : null;
    const result: IContentSaveResult = {
      contentContextHash: response.getContentContextHash(),
      moderationResult,
    };

    if (publishInfo.commentRoleTypes) {
      await this.commentHandler.sendCommentRolesRequest(
        contentHash,
        publishInfo.commentRoleTypes,
      );
    }

    return result;
  }

  async moveContent(
    unusedMingaContext: null,
    contentHash: string,
    publishInfoDelta: Partial<IMingaContentPublishInfo>,
  ) {
    const request = new MingaMoveContent();

    const metadata = await this.contentMetadata.getContentMetadata({
      contentHash,
    });

    request.setContentContextHash(metadata.contentContextHash);

    if (typeof publishInfoDelta.publishDate !== 'undefined') {
      const startDate = publishInfoDelta.publishDate;
      const delta = new DateTimeDelta();
      if (startDate) {
        delta.setNewDateTime(dateTimeObjectToDateTimeMessage(startDate));
      }

      request.setContentStartDateTime(delta);
    }

    if (typeof publishInfoDelta.unpublishDate !== 'undefined') {
      const endDate = publishInfoDelta.unpublishDate;
      const delta = new DateTimeDelta();
      if (endDate) {
        delta.setNewDateTime(dateTimeObjectToDateTimeMessage(endDate));
      }

      request.setContentEndDateTime(delta);
    }

    if (typeof publishInfoDelta.endPinDate !== 'undefined') {
      const pinEnd = publishInfoDelta.endPinDate;
      const delta = new DateTimeDelta();
      if (pinEnd) {
        delta.setNewDateTime(dateTimeObjectToDateTimeMessage(pinEnd));
      }

      request.setContentPinEndDateTime(delta);
    }

    const response = await this.mingaContentProto.moveContent(request);

    const moderationResult = response.hasModerationResult()
      ? ModerationResultMapper.toIModerationResult(
          response.getModerationResult(),
        )
      : null;
    const result: IContentSaveResult = {
      contentContextHash: response.getContentContextHash(),
      moderationResult,
    };

    if (publishInfoDelta.commentRoleTypes) {
      await this.commentHandler.sendCommentRolesRequest(
        contentHash,
        publishInfoDelta.commentRoleTypes,
      );
    }

    // clear metadata cache because it's now out of date.
    this.contentMetadata.clearContentMetadata(contentHash);

    return result;
  }

  async getPublishInfo(
    unusedMingaContext: null,
    contentHash: string,
  ): Promise<IMingaContentPublishInfo | null> {
    const contentMetadata = await this.contentMetadata.getContentMetadata({
      contentHash,
    });

    const defaultCommentRoles = await this.commentHandler
      .getDefaultCommentableRoles()
      .pipe(first())
      .toPromise();

    if (contentMetadata.ownerMingaHash) {
      const endPinDate = contentMetadata.pinEndTimestamp
        ? new Date(contentMetadata.pinEndTimestamp)
        : null;
      const publishDate = contentMetadata.publishTimestamp
        ? new Date(contentMetadata.publishTimestamp)
        : null;
      const unpublishDate = contentMetadata.unpublishTimestamp
        ? new Date(contentMetadata.unpublishTimestamp)
        : null;
      const commentRoleTypes =
        contentMetadata.allowedCommentingRoleTypesList.length > 0
          ? contentMetadata.allowedCommentingRoleTypesList
          : defaultCommentRoles;

      return {
        endPinDate,
        publishDate,
        unpublishDate,
        commentRoleTypes,
      };
    }
    return null;
  }

  getDefaultPublishInfo(): IMingaContentPublishInfo {
    return {
      publishDate: null,
      unpublishDate: null,
      endPinDate: null,
      commentRoleTypes: null,
    };
  }

  getPublishInfoEditComponent() {
    return MingaContentPublishInfoEditorComponent;
  }
}
