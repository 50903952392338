<ng-template #badgeOverlayTemplate>
  <div class="badge-overlay">
    <div class="content-wrap">
      <mg-image
        [background-size]="'contain'"
        [srcs]="badgeImageAsset | mgImageUrls: badgeImageSizes"
        class="badge-image">
      </mg-image>
      <div
        class="text"
        [innerHTML]="badgeText"></div>
      <hr />
      <div
        class="body"
        [innerHTML]="badgeBody"></div>
    </div>
  </div>
</ng-template>
