<mg-tools-header
  *ngIf="layout.useLegacyLayout$ | async"
  type="student"
  [user]="person$ | async">
  <mg-tools-nav>
    <mg-tools-nav-item
      [routerLinkActive]="'active'"
      [routerLink]="['/tools', 'my-tools']"
      [label]="MESSAGES.NAV_MY_TOOLS"
      icon="teacher-tools-active"
      type="my-tools"></mg-tools-nav-item>
    <mg-tools-nav-item
      *ngIf="isPbisEnabled$ | async"
      [routerLinkActive]="'active'"
      [routerLink]="['/' + TOOLS_ROUTES.ROOT, TOOLS_ROUTES.BEHAVIOURS]"
      [label]="MESSAGES.NAV_BEHAVIOURS"
      icon="consequence"
      type="behaviours"
      [showBadge]="(activeBehaviorsCount$ | async) > 0"></mg-tools-nav-item>
    <mg-tools-nav-item
      *ngIf="(isPbisEnabled$ | async) && (isLeaderboardEnabled$ | async)"
      [routerLinkActive]="'active'"
      [routerLink]="['/' + TOOLS_ROUTES.ROOT, TOOLS_ROUTES.POINTS]"
      [label]="MESSAGES.NAV_POINTS"
      icon="star-o"
      type="points"></mg-tools-nav-item>
    <mg-tools-nav-item
      *ngIf="isFlexTimeEnabled$ | async"
      [routerLinkActive]="'active'"
      [routerLink]="['/' + TOOLS_ROUTES.ROOT, TOOLS_ROUTES.FLEX_TIME]"
      [label]="MESSAGES.NAV_FLEX_TIME"
      icon="FlexTime-o"
      type="flex-time"></mg-tools-nav-item>
    <mg-tools-nav-item
      [routerLinkActive]="'active'"
      [routerLink]="['/' + TOOLS_ROUTES.ROOT, TOOLS_ROUTES.NOTIFICATIONS]"
      [label]="MESSAGES.NAV_NOTIFICATIONS"
      icon="notification-active"
      type="notifications"
      [showBadge]="
        badgeManager.onDot('notification') | async
      "></mg-tools-nav-item>
  </mg-tools-nav>
</mg-tools-header>

<section class="content-wrapper">
  <router-outlet></router-outlet>
</section>
