<mg-notification-base
  [read]="notification?.read"
  [viewed]="notification?.viewed"
  [icon]="notification?.iconUrl"
  [iconColor]="notification?.iconColor"
  [timestamp]="notification?.timestamp">
  <mg-notification-base-image>
    <img
      class="invite-image"
      src="assets/event/event-invited.svg" />
  </mg-notification-base-image>

  <mg-notification-base-title>
    <div [innerHTML]="notification?.title | mgSafeHtml"></div>
  </mg-notification-base-title>
</mg-notification-base>
