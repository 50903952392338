<mg-overlay-primary
  size="medium"
  [overlayTitle]="modalTitle"
  [bodyNoPadding]="true">
  <ng-container>
    <main class="modal-body">
      <div
        *ngIf="(formState$ | async) !== 'idle'"
        class="loading-container">
        <mg-spinner
          [diameter]="40"
          [thickness]="3">
        </mg-spinner>
      </div>

      <ng-container *ngIf="(formState$ | async) !== 'loading'">
        <mg-ftm-activity-selector-list
          [periods]="flexPeriods$ | async"
          [activities]="activityInstances$ | async"
          [activityCardFields]="CARD_FIELDS"
          [defaultValue]="this.data"
          (filterChange)="onFilterChange($event)"
          (activitySelected)="select($event)">
          <ng-template #noResults>
            <mg-empty-state
              [title]="MESSAGES.EMPTY_STATE_TITLE"
              [subtitle]="MESSAGES.EMPTY_STATE_SUBTITLE_GENERIC">
            </mg-empty-state>
          </ng-template>
        </mg-ftm-activity-selector-list>
      </ng-container>
    </main>
  </ng-container>
</mg-overlay-primary>
