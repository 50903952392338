import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';

import { LightboxComponent } from 'minga/app/src/app/components/Lightbox/Lightbox.component';
import { fitIn, IBox } from 'minga/app/src/app/util/box';
import { hammerjs } from 'minga/app/src/app/util/hammerjs';
import { IMessageAttachment } from 'minga/domain/messaging';

function swipeMultiplier(
  deltaX: number,
  currentIndex: number,
  itemsCount: number,
) {
  return (deltaX > 0 && currentIndex === 0) ||
    (deltaX < 0 && currentIndex === itemsCount - 1)
    ? 0.12
    : 1;
}

@Component({
  selector: 'mg-message-lightbox',
  templateUrl: './MessageLightbox.component.html',
  styleUrls: ['./MessageLightbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageLightboxComponent implements OnChanges, OnInit {
  private _attachments: IMessageAttachment[] = [];

  /** @internal */
  private _hmjs: any | null = null;

  /** @internal */
  private _itemsContainerWidth: number = 0;

  /** @internal */
  private _itemsContainerPanPosition: number = 0;

  /** @internal */
  private _lastPanDelta: number = 0;

  /** @internal */
  private _frameHandler: number = 0;

  @ViewChild('lightbox', { static: true })
  lightbox?: LightboxComponent;

  @ViewChild('itemsContainerElement', { static: true })
  itemsContainerElement!: ElementRef;

  @Input()
  set attachments(items: IMessageAttachment[]) {
    this._reset();
    this._attachments = items;
  }
  get attachments() {
    return this._attachments;
  }

  @Input()
  activeImageIndex: number = 0;

  @Output()
  activeImageIndexChange = new EventEmitter<number>();

  get itemsContainerWidth(): number {
    return this._itemsContainerWidth;
  }

  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone) {}

  @HostListener('window:resize', [])
  onWindowResize() {
    this.calcContainerWidth();
    this.slideToActive();
    this.cdr.markForCheck();
  }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      const itemsElement = this.itemsContainerElement.nativeElement;

      const pan = new hammerjs.Pan({});

      this._hmjs = new hammerjs.Manager(itemsElement, {});

      this._hmjs.add([pan]);

      this._hmjs.on('panstart', (ev: any) => this.onPanstart(ev, itemsElement));
      this._hmjs.on('panend', (ev: any) => this.onPanend(ev, itemsElement));
      this._hmjs.on('pancancel', (ev: any) =>
        this.onPancancel(ev, itemsElement),
      );
      this._hmjs.on('panleft panright', (ev: any) =>
        this.onPanmove(ev, itemsElement),
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.attachments || changes.activeImageIndex) {
      this.cdr.markForCheck();
    }
  }

  private _reset() {
    this._itemsContainerWidth = 0;
    this._itemsContainerPanPosition = 0;
    this._lastPanDelta = 0;
  }

  slideToActive() {
    const itemWidth = window.innerWidth;

    const newPanPosition = -2 * this.activeImageIndex * itemWidth;

    this.itemsContainerElement.nativeElement.style.transform = `translate3d(${newPanPosition}px, 0px, 0px)`;

    this._itemsContainerPanPosition = newPanPosition;
    this.update();
  }

  open() {
    this.lightbox?.open();
  }

  close() {
    this.lightbox?.close();
  }

  slideNext() {
    this.activeImageIndex++;
    this.activeImageIndexChange.emit(this.activeImageIndex);
    this.cdr.markForCheck();
  }

  slidePrevious() {
    this.activeImageIndex--;
    this.activeImageIndexChange.emit(this.activeImageIndex);
    this.cdr.markForCheck();
  }

  hasNext() {
    return this.activeImageIndex + 1 < this.attachments.length;
  }

  hasPrevious() {
    return this.activeImageIndex > 0;
  }

  getMessageImageWidth(attachment: IMessageAttachment) {
    if (attachment.image && 'longcardbanner' in attachment.image) {
      return attachment.image['longcardbanner'].width;
    }
    return '';
  }

  getMessageImageHeight(attachment: IMessageAttachment) {
    if (attachment.image && 'longcardbanner' in attachment.image) {
      return attachment.image['longcardbanner'].height;
    }
    return '';
  }

  private _lightBoxContainerSize(): IBox {
    const heightMultiplier = 0.25;

    const height: number =
      innerHeight - Math.max(100, innerHeight * heightMultiplier);
    let width: number;

    if (innerWidth < 600) {
      width = innerWidth - 32;
    } else {
      width = innerWidth - Math.max(50, innerWidth * 0.1);
    }

    return { width, height };
  }

  lightBoxImageWidth(attachment: IMessageAttachment) {
    if (attachment.image) {
      return fitIn(
        attachment.image['longcardbanner'],
        this._lightBoxContainerSize(),
      ).width;
    }
    return 0;
  }

  lightBoxImageHeight(attachment: IMessageAttachment) {
    if (attachment.image) {
      return fitIn(
        attachment.image['longcardbanner'],
        this._lightBoxContainerSize(),
      ).height;
    }
    return 0;
  }

  private calcContainerWidth() {
    this._itemsContainerWidth =
      (this.attachments.length - 1) * window.innerWidth;
  }

  update() {
    const itemsElement = this.itemsContainerElement.nativeElement;
    this._update(itemsElement);
  }

  private _update(containerElement: any) {
    const position = this._itemsContainerPanPosition;
    const translate3d = `translate3d(${position}px, 0px, 0px)`;
    containerElement.style.transform = translate3d;
  }

  _setNearestToActive() {
    const currentPosition = this._itemsContainerPanPosition;
    const itemWidth = window.innerWidth;

    const biggestIndex = this.attachments.length - 1;

    const newIndex = Math.max(
      0,
      Math.min(Math.abs(Math.round(currentPosition / itemWidth)), biggestIndex),
    );

    const newPanPosition = -newIndex * 2 * itemWidth;
    this._itemsContainerPanPosition = newPanPosition;
    this.activeImageIndex = newIndex;
    this.activeImageIndexChange.emit(this.activeImageIndex);
  }

  private onPanstart(ev: any, containerElement: any) {
    this._lastPanDelta = 0;
    containerElement.style.transitionDuration = '0ms';
  }

  private onPanend(ev: any, containerElement: any) {
    // Add extra to the pan position if velocity is high enough. Clamping the
    // clamping the velocity so you can't skip items in the lightbox.
    const clampedVelocityX = Math.min(Math.max(ev.overallVelocityX, -3), 3);
    this._itemsContainerPanPosition += clampedVelocityX * (innerWidth * 0.1);

    containerElement.style.transitionDuration = '';
    const previousActiveIndex = this.activeImageIndex;

    this._setNearestToActive();

    if (this.activeImageIndex != previousActiveIndex) {
      this.update();
      this.cdr.markForCheck();
    }
  }

  private onPancancel(ev: any, containerElement: any) {
    containerElement.style.transitionDuration = '';
    this.slideToActive();
  }

  private onPanmove(ev: any, containerElement: any) {
    const deltaX =
      ev.deltaX *
      swipeMultiplier(
        ev.deltaX,
        this.activeImageIndex,
        this.attachments.length,
      );
    const delta = deltaX - this._lastPanDelta;
    this._itemsContainerPanPosition += delta;
    this._lastPanDelta = deltaX;

    if (!this._frameHandler) {
      this._frameHandler = requestAnimationFrame(() => {
        this._frameHandler = 0;
        this._update(containerElement);
      });
    }
  }
}
