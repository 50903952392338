import { Injectable } from '@angular/core';

import { Dayjs } from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import * as reg_pb from 'minga/proto/registration/registration_pb';
import {
  FlexTimeActivityInstance,
  MinimalFlexTimePeriod,
} from 'minga/domain/flexTime';
import { Registration, RegistrationTypes } from 'minga/domain/registration';
import { RegistrationManager } from 'minga/proto/registration/registration_ng_grpc_pb';
import { RegistrationMapper } from 'minga/shared-grpc/registration';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { SentryService } from 'src/app/minimal/services/Sentry/Sentry.service';
import { dateTimeObjectToDateTimeMessage } from 'src/app/util/date';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimePeriodService } from '@shared/services/flex-time';

import { StMessages } from '../constants';
import { FlexTimePeriodWithRegistration, Schedule } from '../types';

const deriveSchedule = (
  flexPeriods: MinimalFlexTimePeriod[],
  myActivityRegistrations: Registration[],
): Schedule => {
  const flexTimePeriodWithRegistration = (flexPeriods || []).map(period => {
    const scheduleItem: FlexTimePeriodWithRegistration = {
      ...period,
    };

    const myRegistration = myActivityRegistrations.find(
      instance => instance?.registration?.flexTimePeriodId === period.id,
    ) as Registration;

    if (myRegistration) {
      scheduleItem.myRegistration = myRegistration;
    }

    return scheduleItem;
  });

  return {
    flexTimePeriodWithRegistration,
    registeredCount: myActivityRegistrations.length || 0,
  };
};

@Injectable({ providedIn: 'root' })
export class StudentToolsFlexTimeService {
  private _scheduleIsLoading = new BehaviorSubject(null);
  public scheduleIsLoading$ = this._scheduleIsLoading
    .asObservable()
    .pipe(shareReplay(1));

  private _schedule = new BehaviorSubject<Schedule>(null);
  public schedule$ = this._schedule.asObservable().pipe(shareReplay(1));

  constructor(
    private _flexPeriods: FlexTimePeriodService,
    private _sentry: SentryService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _registrationManager: RegistrationManager,
    private _authInfoService: AuthInfoService,
  ) {}

  public async fetchSchedule({
    startDate,
    endDate,
    showLoader = true,
    personHash,
  }: {
    startDate?: Dayjs;
    endDate?: Dayjs;
    showLoader?: boolean;
    personHash?: string;
  }) {
    this._scheduleIsLoading.next(showLoader);

    try {
      const [flexPeriods, myActivityRegistrations] = await Promise.all([
        this._flexPeriods.fetchAllMinimal(startDate, endDate),
        this.fetchMyActivityRegistrations(startDate, endDate, personHash),
      ]);

      this._schedule.next(deriveSchedule(flexPeriods, myActivityRegistrations));
    } catch (e) {
      const errorMsg = StMessages.FETCH_SCHEDULE_ERROR;
      this._sentry.captureMessageAsError(errorMsg, e);
      this._systemAlertSnackBar.error(errorMsg);
    } finally {
      this._scheduleIsLoading.next(false);
    }
  }

  public async fetchMyActivityRegistrations(
    startDate?: Dayjs,
    endDate?: Dayjs,
    personHash?: string,
  ): Promise<Registration[]> {
    const request = new reg_pb.GetMyRegistrationsRequest();
    request.setType(RegistrationTypes.FLEX_TIME);
    if (personHash) request.setPersonHash(personHash);

    if (startDate) {
      const startDateTime = dateTimeObjectToDateTimeMessage(startDate.toDate());
      request.setStartDate(startDateTime);
    }

    if (endDate) {
      const endDateTime = dateTimeObjectToDateTimeMessage(endDate.toDate());
      request.setEndDate(endDateTime);
    }

    const response = await this._registrationManager.getMyRegistrations(
      request,
    );

    return response.getRegistrationList().map(RegistrationMapper.fromProto);
  }

  public async registerForActivity(
    activityInstanceId: number,
  ): Promise<FlexTimeActivityInstance> {
    const request = new reg_pb.RegisterRequest();
    request.setActivityInstanceId(activityInstanceId);
    request.setPersonHash(this._authInfoService.authPersonHash);
    request.setType(RegistrationTypes.FLEX_TIME);
    const response = await this._registrationManager.register(request);
    if (response.getError()) {
      throw new Error(response.getError());
    }

    return RegistrationMapper.fromProto(response.getRegistration())
      .registration;
  }
}
