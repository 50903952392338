import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MgSmsMessageMiniCardModule } from 'minga/app/src/app/elements/MgMiniCard/MgSmsMessageMiniCard';
import { MgChallengeMiniCardModule } from 'minga/app/src/app/elements/MgShortCard/MgChallengeMiniCard';

import { MgMiniCardModule } from '../MgMiniCard';
import { MgEventMiniCardModule } from '../MgShortCard/MgEventMiniCard/MgEventMiniCard.module';

import { MgAnyMiniCardElement } from './MgAnyMiniCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgMiniCardModule,
    MgEventMiniCardModule,
    MgChallengeMiniCardModule,
    MgSmsMessageMiniCardModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [MgAnyMiniCardElement],
  exports: [MgAnyMiniCardElement],
})
export class MgAnyMiniCardModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-any-mini-card')) {
      //       //   MgAnyMiniCardElement,
      //   {injector},
      // ));
    }
  }
}
