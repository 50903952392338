import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgIconModule } from 'minga/app/src/app/icon';

import { NavsearchComponent } from './Navsearch.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgFormFieldModule,

    // External dependencies
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ],
  declarations: [NavsearchComponent],
  exports: [NavsearchComponent],
})
export class NavsearchModule {}
