import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { VirtualScrollerModule } from '@minga/ngx-virtual-scroller';
import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from 'minga/app/src/app/button';
import { MgDirectivesModule } from 'minga/app/src/app/directives';
import { MgPersonListElementModule } from 'minga/app/src/app/elements/MgPersonListElement';
import { MgIconModule } from 'minga/app/src/app/icon';
import { SearchModule } from 'minga/app/src/app/search';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { AvatarClusterModule } from 'src/app/components/AvatarCluster';
import { MgEmptyStateModule } from 'src/app/components/EmptyState';
import { MgAvatarModule } from 'src/app/elements/MgAvatar';
import { MgPersonMinimalModule } from 'src/app/elements/MgPersonMinimal';

import { LayoutLegacyContentModule } from '@modules/layout/components/layout-legacy-content/layout-legacy-content.module';

import { GenericModule } from '@shared/components/generic';
import { PortalLayoutModule } from '@shared/components/portal-layout';

import { DmListFeedComponent } from './components/dm-list-feed/dm-list-feed.component';
import { DmListItemComponent } from './components/dm-list-item/dm-list-item.component';
import { DmListComponent } from './dm-list.component';

@NgModule({
  imports: [
    // Minga dependencies
    VirtualScrollerModule,
    SearchModule,
    MgDirectivesModule,
    MgPersonListElementModule,
    MgSpinnerModule,
    MgButtonModule,
    MgIconModule,
    MgEmptyStateModule,
    MgAvatarModule,
    AvatarClusterModule,
    MgPersonMinimalModule,
    LayoutLegacyContentModule,
    GenericModule,
    PortalLayoutModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [DmListComponent, DmListFeedComponent, DmListItemComponent],
  exports: [DmListItemComponent],
})
export class DirectMessageListModule {}
