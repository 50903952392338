<mg-sr-filters-wrap
  [form]="form"
  [filters]="filters$ | async">
  <ng-container *ngIf="form.get(PARENT_FIELDS.REPORT_FILTERS)">
    <mg-multi-person-search
      *ngIf="
        form.get(getFilterGroupControlKey(FIELDS.ISSUED_TO)) &&
          defaultIssuedHashes$ | async
      "
      (selectionChanged)="onPersonSelected($event)"
      [defaultValue]="defaultIssuedHashes$ | async">
    </mg-multi-person-search>

    <mg-user-list-filter
      [prefetchData]="[UserListCategory.ALL]"
      [placeholder]="MESSAGES.USER_LIST"
      [value]="form.get(getFilterGroupControlKey(FIELDS.USER_LIST)).value"
      (selectChanged)="
        form.get(getFilterGroupControlKey(FIELDS.USER_LIST)).setValue($event)
      ">
    </mg-user-list-filter>

    <mg-form-select
      [placeholder]="MESSAGES.PASS_TYPE"
      [value]="form.get(getFilterGroupControlKey(FIELDS.HALLPASS_TYPE)).value"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [control]="form.get(getFilterGroupControlKey(FIELDS.HALLPASS_TYPE))"
      [options]="hallpassTypeOptions$ | async">
    </mg-form-select>

    <div>
      <mat-button-toggle-group
        class="filter-toggle-group"
        [multiple]="false"
        [value]="form.get(getFilterGroupControlKey(FIELDS.CREATED_BY)).value"
        (change)="
          form
            .get(getFilterGroupControlKey(this.FIELDS.CREATED_BY))
            .setValue($event.value)
        ">
        <mat-button-toggle
          *ngFor="let option of CREATED_BY_OPTIONS"
          [value]="option.value">
          <mg-text variant="body-sm-secondary">
            {{ option.label }}
          </mg-text>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </ng-container>
</mg-sr-filters-wrap>
