import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { PointsManagerService } from 'minga/app/src/app/services/PointsManager/PointsManager.service';
import { mingaSettingTypes } from 'minga/util';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { StMessages } from '../../student-tools/constants';
import {
  toolsPointsRouterLinks,
  toolsPointsRelativeRouterLinks,
} from './constants/tools-points.constants';

@Component({
  selector: 'mg-tools-points',
  templateUrl: './tools-points.component.html',
  styleUrls: ['./tools-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsPointsComponent implements OnInit {
  private _totalPoints = new BehaviorSubject(0);
  public totalPoints$ = this._totalPoints.asObservable();
  private _redeemablePoints = new BehaviorSubject(0);
  public redeemablePoints$ = this._redeemablePoints.asObservable();
  public MESSAGES = StMessages;
  public isRewardsStoreEnabled$: Observable<boolean>;
  public isLeaderboardEnabled$ = this._settingsService.getSettingValueObs(
    mingaSettingTypes.FEATURE_TRACKING_PUBLIC_LEADERBOARD,
  );
  private _personHash = this._authService.authPersonHash;

  /** Router Links */
  public routerLinks = toolsPointsRouterLinks;

  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthInfoService,
    private _pointsManager: PointsManagerService,
    private _settingsService: MingaSettingsService,
  ) {
    this.isRewardsStoreEnabled$ = this._settingsService.getSettingValueObs(
      mingaSettingTypes.PM_REWARDS_STORE,
    );
  }

  ngOnInit() {
    const profilePersonHash = this._route.snapshot.paramMap.get('hash');
    if (profilePersonHash) {
      this.routerLinks = toolsPointsRelativeRouterLinks;
      this._personHash = profilePersonHash;
    }

    this._getPoints();
  }

  private async _getPoints() {
    const response = await this._pointsManager.getProfilePointsSummary(
      this._personHash,
    );

    this._totalPoints.next(response.totalPoints);

    // we dont want to show negative redeemable points
    const redeemablePoints = Math.max(response.redeemablePoints || 0, 0);
    this._redeemablePoints.next(redeemablePoints);
  }
}
