import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as day from 'dayjs';

import { FlexTimeActivityInstance } from 'minga/domain/flexTime';

import {
  SystemAlertModalHangTime,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';

import { StMessages } from '../../constants';
import { StudentToolsFlexTimeService } from '../../services';

@Component({
  selector: 'mg-st-flex-time',
  templateUrl: './st-flex-time.component.html',
  styleUrls: ['./st-flex-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StFlexTimeComponent implements OnInit {
  public schedule$ = this._stFlexTimeService.schedule$;
  public scheduleIsLoading$ = this._stFlexTimeService.scheduleIsLoading$;

  public MESSAGES = StMessages;

  public personHash = '';

  constructor(
    private _route: ActivatedRoute,
    private _stFlexTimeService: StudentToolsFlexTimeService,
    private _systemAlertModal: SystemAlertModalService,
  ) {}

  ngOnInit(): void {
    this.personHash = this._route.snapshot.paramMap.get('hash');

    this._fetchSchedule();
  }

  public async activityRegistered(activityInstance: FlexTimeActivityInstance) {
    this._fetchSchedule(false);

    this._systemAlertModal.open({
      modalType: SystemAlertModalType.SUCCESS,
      heading: StMessages.REGISTRATION_SUCCESS_TITLE,
      message: `${StMessages.REGISTRATION_SUCCESS_SUB_MESSAGE} ${activityInstance.flexTimeActivity?.name}`,
      hangTime: SystemAlertModalHangTime.SHORT,
    });
  }

  private async _fetchSchedule(showLoader = true) {
    const startDate = day();
    const endDate = day().add(1, 'month');
    await this._stFlexTimeService.fetchSchedule({
      startDate,
      endDate,
      showLoader,
      personHash: this.personHash,
    });
  }
}
