export const MENTION_CHAR = '@';

export interface IMentionableResultItem {
  readonly hash: string;
  readonly displayName: string;
  displayImage: string;
  displayNameHtml: string;
  highlighted?: boolean;
  badgeIconColor?: string;
}
