<mg-short-card
  #shortCard
  *ngIf="content"
  [featuredImage]="content?.featuredImage"
  [videoUrl]="content?.videoUrl"
  [authorPersonView]="content?.authorPersonView"
  [timestamp]="content?.timestamp"
  [title]="content?.title"
  [body]="content?.body"
  [likeCount]="content?.likeCount"
  [ownerGroupHash]="content?.ownerGroupHash"
  [commentList]="content?.commentList"
  [commentCount]="content?.commentCount"
  [totalCommentCount]="content?.totalCommentCount"
  [pinned]="pinned"
  [markKeywords]="markKeywords"
  [commentRoles]="commentRoles"
  [context]="context"
  [content]="content">
  <mg-short-card-featured-image>
    <div class="banner video-thumbnail">
      <div
        class="banner-image"
        [class.processing]="content.cloudflareUid && !content.cloudflareReady"
        [style.backgroundImage]="'url('+shortCard.getAssetUrl(content.featuredImage)+')'">
        <div class="processing-container">
          <mg-spinner
            color="primary"
            thickness="4"
            diameter="25">
          </mg-spinner>
          <p>We are still processing your video... check back in a bit...</p>
        </div>
        <mg-video-player-icon></mg-video-player-icon>
      </div>
    </div>
  </mg-short-card-featured-image>
</mg-short-card>
