import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgButtonModule } from 'minga/app/src/app/button';
import { MgIconModule } from 'minga/app/src/app/icon';

import { ActivateDeactivateToggleButtonComponent } from './ActivateDeactivateToggleButton.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    MgButtonModule,
    // External dependencies
    CommonModule,
  ],
  declarations: [ActivateDeactivateToggleButtonComponent],
  exports: [ActivateDeactivateToggleButtonComponent],
})
export class ActivateDeactivateToggleButtonModule {}
