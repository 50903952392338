import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ControlValueAccessor,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
  DEFAULT_IMAGE_ACCEPT,
  FileUploadManager,
  FileUploadStatus,
} from 'minga/app/src/app/file';
import { MessageDialogComponent } from 'minga/app/src/app/minimal/components/MessageDialog';
import { mgIsFullUrl } from 'minga/app/src/app/util/asset';
import { populatePreviewUrlFromFile } from 'minga/app/src/app/util/image';
import { ResponseCode } from 'minga/proto/assetservice/assetservice_pb';
import { AcceptedImageFileMimes } from 'minga/util/File';

export const MG_FILE_UPLOAD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileUploadComponent),
  multi: true,
};

export const MG_FILE_UPLOAD_VALUE_VALIDATOR: any = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => FileUploadComponent),
  multi: true,
};

@Component({
  providers: [MG_FILE_UPLOAD_VALUE_VALIDATOR, MG_FILE_UPLOAD_VALUE_ACCESSOR],
  selector: 'mg-file-upload',
  templateUrl: './FileUpload.component.html',
  styleUrls: ['./FileUpload.component.scss'],
})
export class FileUploadComponent
  implements ControlValueAccessor, OnInit, OnChanges, AsyncValidator
{
  @Input()
  file: File;

  @Input()
  previewSize: number = 150;

  @Input()
  spinnerSize: number = 60;

  @Input()
  correctPreviewAspectRatio: boolean = false;

  @Input()
  previewAspectRatio: string | number = '1:1';

  @Input()
  previewPaddingBottom: string = '';

  @Input()
  accept: string = DEFAULT_IMAGE_ACCEPT;

  private outputValue: string = null;

  previewUrl: string = '';

  onChange;
  onTouched;

  progress: number = 0;

  FileUploadStatus = FileUploadStatus;

  uploadStatus: FileUploadStatus;

  @Output('status')
  statusOutput: EventEmitter<any> = new EventEmitter();

  @Output('previewUrl')
  previewUrlOutput: EventEmitter<any> = new EventEmitter();

  @Output()
  errorAcknowledged = new EventEmitter<void>();

  validateUploadPromise: Promise<any>;

  errorCode: ResponseCode;

  get previewAspectRatioPadding() {
    if (this.previewPaddingBottom) return this.previewPaddingBottom;

    const value = this.previewAspectRatio || 1;
    let aspectRatio = 0;

    if (typeof value === 'string') {
      const colonIndex = value.indexOf(':');

      if (colonIndex > -1) {
        const [width, height] = value.split(':');
        aspectRatio = parseFloat(height) / parseFloat(width);
      } else {
        aspectRatio = parseFloat(value) || 0;
      }
    } else if (typeof value === 'number') {
      aspectRatio = value;
    }

    return aspectRatio * 100 + '%';
  }

  constructor(
    private fileUploadManager: FileUploadManager,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  // Pass through our internal
  async validate(control: AbstractControl): Promise<ValidationErrors | null> {
    if (this.validateUploadPromise) {
      return this.validateUploadPromise;
    }

    if (this.uploadStatus == FileUploadStatus.DONE) {
      return null;
    } else {
      return { required: true };
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  ngOnInit() {}

  triggerChange() {
    if (this.onTouched) {
      this.onTouched();
    }

    if (this.onChange) {
      this.onChange(this.outputValue);
    }

    this.changeDetectorRef.detectChanges();
  }

  private async populatePreviewUrlFromFile(file: File) {
    const mimeType = file.type;
    // preview urls are only created for images
    if (AcceptedImageFileMimes.includes(mimeType)) {
      this.previewUrl = await populatePreviewUrlFromFile(
        file,
        this.previewSize,
      );
      this.previewUrlOutput.emit(this.previewUrl);
    }
  }

  onFileChange() {
    const file = this.file;

    if (!file) {
      return;
    }

    const uploadObservable = this.fileUploadManager.uploadFile(
      file,
      this.accept,
    );

    uploadObservable.subscribe(
      state => {
        this.progress = state.progress * 100;

        // console.log(state.progress);

        this.outputValue = state.assetId;
        this.triggerChange();

        if (state.url) {
          this.previewUrl = state.url;
        }

        if (state.status === FileUploadStatus.DONE) {
          // Artificially delay the status change so we can see the progress
          // going all the way.
          setTimeout(() => {
            this.uploadStatus = state.status;
            this.statusOutput.emit(this.uploadStatus);
            this.triggerChange();
          }, 200);
        } else {
          this.uploadStatus = state.status;
          this.statusOutput.emit(this.uploadStatus);
          if (
            this.uploadStatus == FileUploadStatus.ERROR_UNKNOWN &&
            state.errorCode
          ) {
            this.errorCode = state.errorCode;
          }
        }
      },
      err => {
        this.errorCode = ResponseCode.ASSET_FATAL;
        this.uploadStatus = FileUploadStatus.ERROR_UNKNOWN;
        this.statusOutput.emit(this.uploadStatus);
        console.error('Upload Error:', err);
      },
      () => {
        this.triggerChange();
      },
    );

    this.validateUploadPromise = uploadObservable
      .toPromise()
      .then(state => {
        if (state.status === FileUploadStatus.DONE) {
          return null;
        } else {
          return { fileUploadUnknown: true };
        }
      })
      .catch(() => {
        return { fileUploadUnknown: true };
      });

    this.outputValue = '';
    this.triggerChange();

    this.populatePreviewUrlFromFile(file);
  }

  displayError() {
    let options = {
      data: {
        title: 'dialog.invalidAsset.title',
        text: 'dialog.invalidAsset.unknownMessage',
        'mat-icon': 'error',
        iconColor: 'white',
        iconBackgound: '#de1717',
        translate: true,
      },
      panelClass: 'mg-overflow-dialog',
    };
    switch (this.errorCode) {
      case ResponseCode.ASSET_INVALID_TYPE:
        options.data.text = 'dialog.invalidAsset.typeMessage';
        break;
      case ResponseCode.ASSET_INVALID_SIZE:
        options.data.text = 'dialog.invalidAsset.sizeMessage';
        break;
    }

    this.dialog
      .open(MessageDialogComponent, options)
      .afterClosed()
      .subscribe(() => {
        this.errorAcknowledged.emit();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.file) {
      let value = changes.file.currentValue;
      if (!(value instanceof Blob) && value) {
        console.warn(
          '<mg-file-upload> file input set to non-Blob object. Trying anyways.',
          value,
        );
        this.onFileChange();
      } else {
        this.onFileChange();
      }
    }
  }

  async fetchRemotePreviewUrl(assetId: string) {
    if (!assetId) {
      return;
    }

    const isFullUrl = mgIsFullUrl(assetId);

    if (isFullUrl) {
      this.previewUrl = assetId;
    } else {
      const assetSizes = await this.fileUploadManager.fetchAssetSizes(assetId);
      const previewUrl =
        assetSizes['uploadpreview'] ||
        assetSizes['preview'] ||
        assetSizes['cardbanner'] ||
        assetSizes['bannerlibpreview'] ||
        assetSizes['raw'];

      this.previewUrl = window.MINGA_ASSET_URL_PREFIX + previewUrl;
    }

    this.changeDetectorRef.markForCheck();
  }

  writeValue(value) {
    if (typeof value === 'string') {
      if (value) {
        this.outputValue = value;
        this.fetchRemotePreviewUrl(this.outputValue);
        this.uploadStatus = FileUploadStatus.DONE;
      }
    } else if (value !== null) {
      console.warn('<mg-file-upload> Cannot write non-string value:', value);
    } else {
      this.clearPreview();
      delete this.uploadStatus;
    }

    this.changeDetectorRef.markForCheck();
  }

  clearPreview() {
    this.previewUrl = '';
  }
}
