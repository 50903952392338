import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { MgButtonModule } from 'minga/app/src/app/button';
import { MgEmptyStateModule } from 'minga/app/src/app/components/EmptyState';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';
import { PeopleSearchInputModule } from 'src/app/components/people-search-input';
import { MgDateTimePickerModule } from 'src/app/date-time-picker';

import { GenericButtonModule } from '@shared/components/button';
import { CollapsibleModule } from '@shared/components/collapsible';
import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { OverlayModule } from '@shared/components/modal-overlay';
import { MultiPersonSearchModule } from '@shared/components/multi-person-search';
import { ReportTableModule } from '@shared/components/report-table';
import { SelectFieldsModule } from '@shared/components/select-fields';
import { SystemAlertModule } from '@shared/components/system-alert';
import { TextModule } from '@shared/components/text';
import { UserListFilterModule } from '@shared/components/user-list-filter/user-list-filter.module';
import { ArrayPipesModule } from '@shared/pipes';

import { CheckinManagerReportsComponent } from './checkin-manager-reports.component';
import { CheckinManagerReportsService } from './checkin-manager-reports.service';
import { CheckinManagerReportHistoryComponent } from './components';
import { CheckinManagerHistoryEditComponent } from './components/checkin-manager-report-history/checkin-manager-history-edit.component';
import { CheckinManagerReportReasonsComponent } from './components/checkin-manager-report-reasons/checkin-manager-report-reasons.component';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    MgButtonModule,
    MgIconModule,
    MgSpinnerModule,
    PeopleSearchInputModule,
    MgFormFieldModule,
    SelectFieldsModule,
    GenericButtonModule,
    FormModule,
    CollapsibleModule,
    MultiPersonSearchModule,
    MgDateTimePickerModule,
    ReportTableModule,
    GenericModule,
    ArrayPipesModule,
    TextModule,
    OverlayModule,
    SystemAlertModule,
    UserListFilterModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatButtonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MgEmptyStateModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
  ],
  declarations: [
    CheckinManagerReportsComponent,
    CheckinManagerReportHistoryComponent,
    CheckinManagerReportReasonsComponent,
    CheckinManagerHistoryEditComponent,
  ],
  exports: [CheckinManagerReportsComponent],
  providers: [CheckinManagerReportsService],
})
export class CheckinManagerReportsModule {}
