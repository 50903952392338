export enum CheckinManagerMessages {
  HEADER_TITLE = 'Check In Manager',
}

export enum CheckinManagerRoutes {
  ROOT = 'checkin',
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  REASONS = 'reasons',
  REPORTS = 'reports',
}

export enum CheckinTypes {
  CHECKIN = 'checkin',
  CHECKOUT = 'checkout',
  ACTIVITY = 'activity',
}
