<mg-manager-overlay-reports-subpage
  [loading]="false"
  [scheduleReportsButton]="false"
  [dataService]="dataService$ | async"
  (exportListPressed)="pmReports.exportReport(reportType.active)">
  <!-- Header -->
  <ng-template #header>
    <ng-container
      *ngIf="
        (media$ | async) !== 'xs' && (media$ | async) !== 'sm';
        else mobileHeaderTemplate
      ">
      <div class="filters-group">
        <div class="filters-group-row">
          <ng-container
            *ngTemplateOutlet="reportTypeFilterTemplate"></ng-container>
          <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
          <ng-container
            *ngIf="reportType.active !== REPORT_TYPES.POINTS_REWARDS_REDEEMED"
            [ngTemplateOutlet]="personSearchFieldTemplate"></ng-container>
        </div>
        <div class="filters-group-row">
          <ng-container
            *ngIf="
              reportType.active !== REPORT_TYPES.POINTS_TEAM_SUMMARY &&
              reportType.active !== REPORT_TYPES.POINTS_REWARDS_REDEEMED
            "
            [ngTemplateOutlet]="gradeFieldTemplate"></ng-container>
          <ng-container
            *ngIf="reportType.active !== REPORT_TYPES.POINTS_REWARDS_REDEEMED"
            [ngTemplateOutlet]="teamsFieldTemplate"></ng-container>
          <ng-container
            *ngIf="
              reportType.active === REPORT_TYPES.POINTS_REWARDS_REDEEMED ||
              reportType.active === REPORT_TYPES.POINTS_REDEEMED
            "
            [ngTemplateOutlet]="rewardsFieldTemplate"></ng-container>
          <ng-container
            *ngIf="
              reportType.active !== REPORT_TYPES.POINTS_TEAM_SUMMARY &&
              reportType.active !== REPORT_TYPES.POINTS_REWARDS_REDEEMED
            "
            [ngTemplateOutlet]="userListTemplate"></ng-container>
          <ng-container
            [ngTemplateOutlet]="filterButtonTemplate"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Report Type Filter Template -->
<ng-template #reportTypeFilterTemplate>
  <div
    class="filters-field half-width"
    fxFlex.gt-sm="308px">
    <mg-form-select
      placeholder="Report Type"
      appearance="tertiary"
      [id]="'report-report-view-click-filter-type'"
      [value]="reportType.active"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="false"
      [options]="reportType.options"
      [floatingLabel]="false"
      (selectionChanged)="changeReportType($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Date Picker Template -->
<ng-template #datePickerTemplate>
  <div class="filters-field half-width date-range">
    <mg-form-date-range
      [id]="'report-report-click-filter-date'"
      [range]="range">
    </mg-form-date-range>
  </div>
</ng-template>

<!-- Reward Template -->
<ng-template #rewardsFieldTemplate>
  <div class="filters-group-row">
    <div class="half-width">
      <mg-form-select
        [placeholder]="MESSAGES.FORM_LABEL_REWARDS"
        labelBackground="white"
        [value]="(pmReports.filter$ | async).rewards"
        [closeOnSelect]="true"
        [multiple]="true"
        [searchable]="true"
        [isFullWidth]="true"
        [hideSelected]="false"
        [options]="rewardOptions$ | async"
        (selectionChanged)="changeRewards($event)">
      </mg-form-select>
    </div>
  </div>
</ng-template>

<ng-template #userListTemplate>
  <div class="half-width">
    <mg-user-list-filter
      [id]="'report-report-view-click-filter-userlist'"
      [placeholder]="MESSAGES.SELECT_LABEL_USER_LIST"
      [value]="(pmReports.filter$ | async).userList"
      (selectChanged)="changeUserList($any($event))">
    </mg-user-list-filter>
  </div>
</ng-template>

<!-- Person Search Field Template -->
<ng-template #personSearchFieldTemplate>
  <div
    class="filters-field full-width"
    fxFlex.gt-sm="300px">
    <mg-multi-person-search
      [id]="'report-report-view-click-filter-search'"
      (selectionChanged)="changePersonHash($event)">
    </mg-multi-person-search>
  </div>
</ng-template>

<!-- Grade Field Template -->
<ng-template #gradeFieldTemplate>
  <div class="half-width">
    <mg-form-select
      labelBackground="white"
      [id]="'report-report-view-click-filter-grade'"
      [placeholder]="MESSAGES.FORM_LABEL_GRADE"
      [value]="(pmReports.filter$ | async).grades"
      [closeOnSelect]="true"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [hideSelected]="false"
      [options]="gradeOptions$ | async"
      (selectionChanged)="changeGrade($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Teams Field Template -->
<ng-template #teamsFieldTemplate>
  <div class="half-width">
    <mg-form-select
      [placeholder]="MESSAGES.FORM_LABEL_TEAMS"
      labelBackground="white"
      [value]="(pmReports.filter$ | async).teams"
      [closeOnSelect]="true"
      [multiple]="true"
      [searchable]="true"
      [isFullWidth]="true"
      [hideSelected]="false"
      [options]="teamsOptions$ | async"
      (selectionChanged)="changeTeams($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Filter Buttons Template -->
<ng-template #filterButtonTemplate>
  <div
    fxFlex="noshrink"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="10px">
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-apply'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'filled'
            : 'outlined'
        "
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [responsive]="true"
        [wide]="true"
        (pressed)="applyFilter()">
        {{ MESSAGES.BUTTON_APPLY_FILTER }}
      </mg-btn>
    </div>
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [id]="'report-report-view-click-filter-clear'"
        [variant]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'outlined'
            : 'text'
        "
        [size]="
          (media$ | async) === 'xs' || (media$ | async) === 'sm'
            ? 'medium'
            : 'small'
        "
        [responsive]="true"
        [wide]="true"
        (pressed)="clearFilter()">
        {{ MESSAGES.BUTTON_CLEAR_FILTER }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<ng-template #mobileHeaderTemplate>
  <div class="filters-group">
    <div class="filters-group-row">
      <ng-container *ngTemplateOutlet="reportTypeFilterTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
      <ng-container
        *ngIf="reportType.active !== REPORT_TYPES.POINTS_REWARDS_REDEEMED"
        [ngTemplateOutlet]="personSearchFieldTemplate"></ng-container>
    </div>
    <mg-collapsible
      [collapseText]="MESSAGES.HIDE_FILTERS"
      [expandText]="MESSAGES.SHOW_FILTERS"
      [enabled]="(media$ | async) === 'xs'">
      <div class="filters-group-row">
        <ng-container
          *ngIf="
            reportType.active !== REPORT_TYPES.POINTS_TEAM_SUMMARY &&
            reportType.active !== REPORT_TYPES.POINTS_REWARDS_REDEEMED
          "
          [ngTemplateOutlet]="gradeFieldTemplate"></ng-container>
        <ng-container
          *ngIf="reportType.active !== REPORT_TYPES.POINTS_REWARDS_REDEEMED"
          [ngTemplateOutlet]="teamsFieldTemplate"></ng-container>
        <ng-container
          *ngIf="
            reportType.active === REPORT_TYPES.POINTS_REWARDS_REDEEMED ||
            reportType.active === REPORT_TYPES.POINTS_REDEEMED
          "
          [ngTemplateOutlet]="rewardsFieldTemplate"></ng-container>
        <ng-container
          *ngIf="
            reportType.active !== REPORT_TYPES.POINTS_TEAM_SUMMARY &&
            reportType.active !== REPORT_TYPES.POINTS_REWARDS_REDEEMED
          "
          [ngTemplateOutlet]="userListTemplate"></ng-container>
      </div>
    </mg-collapsible>
    <div class="filters-group-row">
      <ng-container *ngTemplateOutlet="filterButtonTemplate"></ng-container>
    </div>
  </div>
</ng-template>
