export interface IListUploadField {
  readonly name: string;
  readonly id: string;
  readonly matcher: RegExp;
  readonly allowMultiple: boolean;
  /**
   * whether this field counts as an ID field for determining the identity
   * of the user.
   */
  isIdField: boolean;
  required: boolean;
  readonly validate: () => IUploadFieldValidationResponse;
  readonly getValue: (row: ListUploaderRow) => string;
  readonly getValues: (row: ListUploaderRow) => string[];
}

export interface IUploadFieldValidationResponse {
  valid: boolean;
  error?: string;
}

export interface IListUploadRowError {
  recordIndex: number;
  errors: string[];
}

export interface IListUploadRowWithError {
  row: ListUploaderRow;
  errors: string;
  recordIndex: number;
}

export interface IListUploadValidationResponse {
  // errors to do with specific rows.
  rowErrors: IListUploadRowError[];
  // errors to do with the file, such as missing columns or duplicate columns
  formatErrors: string[];
}

export interface IXlsListUploaderData {
  onListParsed: (results: [][], headers: IListUploadField[]) => void;
}

export type ListUploaderRow = {
  [key: string]: string | string[];
};

export abstract class AbstractListUploadField implements IListUploadField {
  abstract readonly name: string;
  abstract readonly id: string;
  abstract readonly matcher: RegExp;
  readonly allowMultiple: boolean = false;
  required = false;
  isIdField = false;
  // no validation needed.
  readonly validate = () => {
    return { valid: true };
  };

  getValue(row: ListUploaderRow): string {
    const value = row[this.id];
    if (!value) {
      return '';
    }
    if (Array.isArray(value) && value.length > 0) {
      if (this.allowMultiple) {
        throw new Error(
          'The ' +
            this.name +
            ' type accepts multiple values so getValues() should be used',
        );
      }
    } else if (typeof value == 'string') {
      return value;
    }
    return '';
  }
  getValues(row: ListUploaderRow): string[] {
    const value = row[this.id];
    if (!this.allowMultiple) {
      throw new Error(
        'The ' + this.name + " type doesn't accept multiple values",
      );
    }
    if (!value) {
      return [];
    }
    if (Array.isArray(value) && value.length > 0) {
      return value;
    }

    return [];
  }
}
export class NameListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Name';
  readonly id = 'name';
  readonly matcher = /^name/gi;
}

export class DisplayNameListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Display Name';
  readonly id = 'displayName';
  readonly matcher = /display_name|displayName|display\sname/gi;
}

export class FirstNameListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'First Name';
  readonly id = 'firstName';
  readonly matcher = /first$|first_name|first\sname|firstName/gi;
}

export class LastNameListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Last Name';
  readonly id = 'lastName';
  readonly matcher = /last$|last_name|last\sname|lastName$/gi;
}

export class GuestListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Guest';
  readonly id = 'guest';
  readonly matcher = /guest/gi;
  readonly allowMultiple = true;
}

export class EmailListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Email';
  readonly id = 'email';
  readonly matcher = /^(?!parent\s*)(email|emailAddress)/gi;
  readonly allowMultiple = false;
  isIdField = true;
}

export class CountListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Count';
  readonly id = 'count';
  readonly matcher = /count/gi;
  readonly allowMultiple = false;
}

export class StudentNumberListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'ID Number';
  readonly id = 'studentId';
  isIdField = true;
  readonly matcher =
    /student_id$|studentId$|student\sid|student\snumber|student_number|id\snumber|idnumber/gi;
  readonly allowMultiple = false;
}

export class FileNameListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'File name';
  readonly id = 'fileName';
  isIdField = false;
  readonly matcher = /file$|studentId$|file\sname|file\sname/gi;
  readonly allowMultiple = false;
}

export class PhoneNumberListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Phone Number';
  readonly id = 'phoneNumber';
  isIdField = false;
  readonly matcher = /^(?!parent\s*)(phone|mobile|cell)/gi;
  readonly allowMultiple = false;
}

export class GradeListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Grade';
  readonly id = 'grade';
  isIdField = false;
  readonly matcher = /grade/gi;
  readonly allowMultiple = false;
}

export class BirthdateListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Birthdate';
  readonly id = 'birthdate';
  isIdField = false;
  readonly matcher = /birth|birthday|birthdate/gi;
  readonly allowMultiple = false;
}

export class GroupListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Group';
  readonly id = 'group';
  isIdField = false;
  readonly matcher = /group|groups|school/gi;
  readonly allowMultiple = true;
}

export class RoleListUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Role';
  readonly id = 'role';
  isIdField = false;
  readonly matcher = /role/gi;
  readonly allowMultiple = false;
}

export class PasswordUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Password';
  readonly id = 'password';
  isIdField = false;
  readonly matcher = /password/gi;
  readonly allowMultiple = false;
}

export class IdField1UploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Custom ID Field 1';
  readonly id = 'idField1';
  isIdField = false;
  readonly matcher = /id\s*field\s*1|id[_\s]*field[_\s]*1|idField1/gi;
  readonly allowMultiple = false;
}

export class IdField2UploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Custom ID Field 2';
  readonly id = 'idField2';
  isIdField = false;
  readonly matcher = /id\s*field\s*2|id[_\s]*field[_\s]*2|idField2/gi;
  readonly allowMultiple = false;
}

export class ParentEmailUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Parent Email';
  readonly id = 'parentEmail';
  isIdField = false;
  readonly matcher = /parent\s*email|parent[_\s]*email|parentEmail/gi;
  readonly allowMultiple = false;
}

export class ParentPhoneUploadField
  extends AbstractListUploadField
  implements IListUploadField
{
  readonly name = 'Parent Phone';
  readonly id = 'parentPhone';
  isIdField = false;
  readonly matcher = /parent\s*phone|parent[_\s]*phone|parentPhone/gi;
  readonly allowMultiple = false;
}
