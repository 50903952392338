<div
  fxLayout="row"
  fxAlign="start center"
  class="behaviour-item"
  [ngClass]="wrapperClasses">
  <div class="behaviour-item-icon">
    <mg-icon>{{ deriveIcon(behaviour) }}</mg-icon>
  </div>
  <div
    class="behaviour-item-details"
    fxFlex="grow">
    <div
      *ngIf="behaviour?.status"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="4px"
      class="spacing">
      <mg-tag [color]="behaviour.status | statusTagColor: 'behavior'">
        {{ behaviour.status }}
      </mg-tag>
      <mg-tag
        *ngIf="behaviour?.sourceId && behaviour?.overdueActionType"
        color="blue">
        {{
          behaviour.overdueActionType === OVERDUE_ACTION.ESCALATE
            ? MESSAGES.OVERDUE_ESCALATE
            : MESSAGES.OVERDUE_ADD
        }}
      </mg-tag>
    </div>

    <mg-text
      variant="body-md-secondary"
      spacing="1">
      {{ behaviour.isConsequence ? MESSAGES.CONSEQUENCE_LABEL : '' }}
      {{ behaviour?.type?.name }}
    </mg-text>

    <mg-text
      *ngIf="behaviour.isConsequence"
      class="label"
      element="p"
      variant="label-md-secondary"
      spacing="0-half">
      <span>
        <strong>{{ MESSAGES.ASSIGNED_BY }}</strong>
        {{ consAssignedBy }}
      </span>
    </mg-text>

    <mg-text
      *ngIf="!behaviour.isConsequence && behaviour?.assignedBy"
      class="label"
      element="p"
      variant="label-md-secondary"
      spacing="0-half">
      <span>
        <strong>{{ MESSAGES.ASSIGNED_BY }}</strong>
        {{
          behaviour.assignedBy.firstName + ' ' + behaviour.assignedBy.lastName
        }}
      </span>
    </mg-text>

    <mg-text
      class="label"
      element="p"
      variant="label-md-secondary"
      spacing="0-half">
      <span>
        <strong>{{ MESSAGES.ASSIGNED_DATE }}</strong>
        {{ behaviour?.displayDate | date: 'MMM d, y':'' }}
      </span>
    </mg-text>

    <mg-text
      *ngIf="behaviour.completeBy && !behaviour.completeAt"
      class="label"
      element="p"
      variant="label-md-secondary"
      spacing="0-half">
      <span>
        <strong>{{ MESSAGES.DUE_DATE }}</strong>
        {{ behaviour.completeBy | date: 'MMM d, y' }}
      </span>
    </mg-text>

    <mg-text
      *ngIf="behaviour?.complete && behaviour?.completeAt"
      class="label"
      element="p"
      variant="label-md-secondary"
      spacing="0-half">
      <span>
        <strong>{{ MESSAGES.COMPLETED_DATE }}</strong>
        {{ behaviour.completeAt | date: 'MMM d, y' }}
      </span>
    </mg-text>

    <mg-text
      *ngIf="behaviour?.note"
      class="label"
      element="p"
      variant="label-md-secondary"
      spacing="0-half"
      [numberOfLines]="2">
      <span>
        <strong>{{ MESSAGES.NOTE_LABEL }}</strong>
        {{ behaviour.note }}
      </span>
    </mg-text>

    <mg-text
      *ngIf="behaviour?.overdueActionType"
      class="label"
      element="p"
      variant="label-md-secondary"
      spacing="0-half"
      [numberOfLines]="2">
      <span>
        <strong>{{ MESSAGES.NOTE_LABEL }}</strong>
        {{
          behaviour?.overdueActionType === OVERDUE_ACTION.ESCALATE
            ? MESSAGES.NOTE_ESCALATION
            : MESSAGES.NOTE_ADDITIONAL
        }}
      </span>
    </mg-text>
  </div>
  <div class="behaviour-item-date">
    <mg-text
      *ngIf="behaviour?.consequenceId"
      class="label"
      element="p"
      variant="label-md-secondary"
      color="surface-alt">
      <span>
        {{ 'ID ' + behaviour.consequenceId }}
      </span>
    </mg-text>
  </div>
</div>
