import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'mg-kiosk-btn',
  templateUrl: './kiosk-btn.component.html',
  styleUrls: ['./kiosk-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KioskBtnComponent implements OnInit {
  @Output() pressed = new EventEmitter<void>();

  @HostListener('click') onClick() {
    this.pressed.emit();
  }

  constructor() {}

  ngOnInit(): void {}
}
