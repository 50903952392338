import { Pipe } from '@angular/core';
import { UgcLinksContentService } from '../../services';

@Pipe({ name: 'ugcLinksContent' })
export class UgcLinksContentPipe {
  constructor(private ugcLinksContentService: UgcLinksContentService) {}

  transform(value: any, options?: any): string {
    return this.ugcLinksContentService.insertLinks(value);
  }
}
