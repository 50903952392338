import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SsoUiComponent } from './SsoUi.component';

@NgModule({
  imports: [
    // Minga dependencies

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [SsoUiComponent],
  exports: [SsoUiComponent],
})
export class SsoUiModule {}
