import { FormBuilder, ValidatorFn } from '@angular/forms';

import { MessageFormGroupController } from './message-form-group-controller';

export const makeMessageFormGroup = <
  Message,
  EditableMessageProperties extends keyof Message,
>(
  config: {
    [Property in EditableMessageProperties]: {
      initialValue?: Message[Property] | undefined;
      disabled?: boolean;
      validators?: ValidatorFn | ValidatorFn[];
    };
  },
  formBuilder: FormBuilder,
) => {
  const controlsConfig: { [key: string]: any } = {};
  for (const controlName in config) {
    if (config.hasOwnProperty(controlName)) {
      const control = config[controlName];
      const configMap: any = {
        value: control.initialValue || undefined,
      };
      if (control.disabled) configMap.disabled = control.disabled;
      controlsConfig[controlName] = [configMap, control.validators || []];
    }
  }
  return new MessageFormGroupController<Message, EditableMessageProperties>(
    formBuilder.group(controlsConfig, { updateOn: 'change' }),
  );
};
