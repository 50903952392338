import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { chunk } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';

export type CountTileSizes = 'small' | 'medium' | 'large';

const SIZE_MAP: Record<CountTileSizes, number> = {
  small: 90,
  medium: 140,
  large: 200,
};

@Component({
  selector: 'mg-count-tile-carousel',
  templateUrl: './count-tile-carousel.component.html',
  styleUrls: ['./count-tile-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountTileCarouselComponent implements AfterViewInit, OnDestroy {
  /** Child Components */
  @ViewChild('container', { static: false })
  private _container: ElementRef;

  /** General Observables */
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading$.asObservable();
  private readonly _destroyed$ = new ReplaySubject(1);

  private readonly _data$ = new BehaviorSubject<any>(null);
  public readonly data$ = this._data$.asObservable();

  /** Inputs */
  @Input() size: CountTileSizes = 'medium';
  @Input() activeTiles: string[] | number[];
  @Input() set data(data) {
    this._data$.next(data);
  }

  @Output() tileClicked = new EventEmitter<number>();

  /** Container Width */

  private _totalChunks: number;
  private readonly _chunkIndex$ = new BehaviorSubject<number>(0);
  public readonly chunkIndex$ = this._chunkIndex$.asObservable();
  private readonly _containerWidth$ = new BehaviorSubject<number | null>(null);
  public readonly containerWeight$ = this._containerWidth$.asObservable();
  private readonly _chunkSize$ = this._containerWidth$.pipe(
    takeUntil(this._destroyed$),
    map(containerWidth => {
      const tileWidth = SIZE_MAP[this.size] + 20;
      return (containerWidth - (containerWidth % tileWidth)) / tileWidth;
    }),
  );
  public chunkedData$: Observable<any>;
  public currentChunk$: Observable<any>;
  private _isFirstChunk: boolean;
  public firstChunk$ = this.chunkIndex$.pipe(
    takeUntil(this._destroyed$),
    map(currentIndex => currentIndex === 0),
    tap(v => (this._isFirstChunk = v)),
  );
  private _isLastChunk: boolean;
  public lastChunk$ = this.chunkIndex$.pipe(
    takeUntil(this._destroyed$),
    map(currentIndex => currentIndex === this._totalChunks - 1),
    tap(v => (this._isLastChunk = v)),
  );

  /** Component Constructor */
  constructor() {
    this.chunkedData$ = combineLatest([this.data$, this._chunkSize$]).pipe(
      takeUntil(this._destroyed$),
      map(([data, chunkLimit]) => {
        return chunk(data, chunkLimit);
      }),
      tap(chunks => (this._totalChunks = chunks.length)),
    );
    this.currentChunk$ = combineLatest([
      this.chunkIndex$,
      this.chunkedData$,
    ]).pipe(
      takeUntil(this._destroyed$),
      map(([i, d]) => d[i]),
    );
  }

  ngAfterViewInit(): void {
    this._setContainerWidth();
    fromEvent(window, 'resize')
      .pipe(takeUntil(this._destroyed$), debounceTime(100))
      .subscribe(() => this._setContainerWidth());
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._data$.complete();
    this._isLoading$.complete();
    this._chunkIndex$.complete();
    this._containerWidth$.complete();
    this._destroyed$.complete();
  }

  public async showPreviousChunk(): Promise<void> {
    const currentIndex = this._chunkIndex$.getValue();
    if (this._isFirstChunk) return;
    this._chunkIndex$.next(currentIndex - 1);
  }
  public async showNextChunk(): Promise<void> {
    const currentIndex = this._chunkIndex$.getValue();
    if (this._isLastChunk) return;
    this._chunkIndex$.next(currentIndex + 1);
  }

  private _setContainerWidth() {
    this._containerWidth$.next(this._container.nativeElement.offsetWidth);
  }
}
