import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ActivatedRoute, Router } from '@angular/router';

import * as day from 'dayjs';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  combineAll,
  distinctUntilChanged,
  map,
  take,
  takeUntil,
} from 'rxjs/operators';

import { Person } from 'minga/app/src/app/people';
import {
  HallPassStatusEnum,
  HallPassStudentCreatedFilter,
  IHallPassErrorType,
} from 'minga/domain/hallPass';
import { MembershipListType } from 'minga/domain/membershipList';
import { HpmReportsFilter } from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { MingaPermission, MingaRoleType } from 'minga/util';
import { ReportDatasourceService } from 'src/app/components/manager-report/services/report-datasource.service';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { PermissionsService } from 'src/app/permissions';
import { HallPassService } from 'src/app/services/HallPass';
import { ListMembershipService } from 'src/app/services/ListMembership';

import { MmScheduledReportsService } from '@modules/minga-manager/components/mm-scheduled-reports/services';
import {
  SUPPORTED_SCHEDULE_TYPES,
  ScheduledReportType,
} from '@modules/minga-manager/components/mm-scheduled-reports/types';

import { FormSelectComponent } from '@shared/components/form';
import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import { FormSelectOption } from '@shared/components/form/types';
import { MultiPersonSearchComponent } from '@shared/components/multi-person-search';

import { HpmRoutes } from '../../hpm.constants';
import { HpmRestrictionsBlackoutService } from '../hpm-restrictions/services';
import {
  HPM_CREATED_BY_OPTIONS,
  HPM_REPORT_SELECT_OPTIONS,
  HpmReportsMessages,
  HPM_REPORT_STATUS_OPTIONS,
  HPM_REPORT_DENIED_BY_OPTIONS,
  HPM_REPORTS_FILTER_INITIAL_STATE,
} from './hpm-reports.constants';
import { HpmReportsService } from './services';

// use 'b' and 'p' as arbitrary magic strings to allow for one filter
// without a compareWith fn, since it's possible for duplicate IDs. Only
// used in this component
const parseId = (id: string) => parseInt(id.slice(1));
const makeBlackOut = (id: number) => 'b' + id;
const isBlackOut = (id: string) => id.startsWith('b');
const makeNoParty = (id: number) => 'p' + id;
const isNoParty = (id: string) => id.startsWith('p');

/**
 * Hall Pass Manager Reports Component
 *
 * Refactored Recently, this should be the standard for building
 * and refactoring further manager report modules
 */
@Component({
  selector: 'mg-hpm-reports',
  templateUrl: './hpm-reports.component.html',
  styleUrls: ['./hpm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmReportsComponent implements OnDestroy, AfterViewInit {
  @ViewChild(MultiPersonSearchComponent)
  private _peopleSearchComponent: MultiPersonSearchComponent;
  @ViewChildren(FormSelectComponent)
  private _selectComponents: QueryList<FormSelectComponent>;

  /** Constants */
  public readonly MESSAGES = HpmReportsMessages;
  public readonly CREATED_BY_OPTIONS = HPM_CREATED_BY_OPTIONS;
  public readonly REPORT_TYPE = ReportTypes;

  /** Mobile Device */
  readonly isMobileDevice: boolean =
    window.MINGA_DEVICE_ANDROID || window.MINGA_DEVICE_IOS;

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);
  public readonly media$: Observable<any>;
  public readonly canScheduleReport$ = this._permissions.observePermission(
    MingaPermission.HALL_PASS_TYPE_MANAGE,
  );
  public SUPPPORTED_SCHEDULE_TYPES: ScheduledReportType[] =
    SUPPORTED_SCHEDULE_TYPES;

  /** Table Data Service */
  private _dataServiceSubj: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public readonly dataService$: Observable<any> =
    this._dataServiceSubj.asObservable();

  /** Filters */
  public readonly issuedBy$: Observable<string[]>;
  public readonly issuedTo$: Observable<string[]>;
  public readonly hallpassType$: Observable<number[]>;
  public readonly createdBy$: Observable<HallPassStudentCreatedFilter>;
  public readonly status$: Observable<HallPassStatusEnum[]>;
  public readonly deniedBy$: Observable<number[]>;
  public readonly denialReason$: Observable<string[]>;
  // these subjects are needed to avoid pressing apply filters
  private _denialReasonSubject = new BehaviorSubject<string[]>([]);
  private _deniedByReasonSubject = new BehaviorSubject<number[]>([]);
  private _statusSubject = new BehaviorSubject<HallPassStatusEnum[]>([]);
  private _disableDeniedBySubject = new BehaviorSubject<boolean>(false);
  public readonly disableDeniedBy$ =
    this._disableDeniedBySubject.asObservable();
  public reportType = {
    active: ReportTypes.HALL_PASS_SUMMARY,
    options: HPM_REPORT_SELECT_OPTIONS,
  };

  public range: FormGroup;

  /** Hall Pass Types Select Options */
  public readonly hallpassTypesSelectOptions$: Observable<
    FormSelectOption<number>[]
  >;
  public readonly denialReasonOptions$: Observable<FormSelectOption<string>[]>;
  public readonly STATUS_OPTIONS = HPM_REPORT_STATUS_OPTIONS;
  public readonly DENIED_BY_OPTIONS = HPM_REPORT_DENIED_BY_OPTIONS;
  public datasource$: Observable<ReportDatasourceService<any>>;

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _hallpassService: HallPassService,
    public hpmReports: HpmReportsService,
    private _analytics: AnalyticsService,
    private _mmScheduledReportService: MmScheduledReportsService,
    private _permissions: PermissionsService,
    private _blackoutService: HpmRestrictionsBlackoutService,
    private _listService: ListMembershipService,
  ) {
    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );
    // Get Select Options for Hall Pass Types Select
    this.hallpassTypesSelectOptions$ = this._hallpassService
      .getHallPassTypes()
      .pipe(
        takeUntil(this._destroyed$),
        map(types =>
          types.map(type => ({
            label: type.name,
            value: type.id,
          })),
        ),
      );

    this.createdBy$ = this.hpmReports.filter$.pipe(
      map(state => state.createdBy),
    );
    this.hallpassType$ = this.hpmReports.filter$.pipe(
      map(state => state.hallpassType),
    );
    this.issuedBy$ = this.hpmReports.filter$.pipe(map(state => state.issuedBy));
    this.issuedTo$ = this.hpmReports.filter$.pipe(map(state => state.issuedTo));
    this.status$ = this.hpmReports.filter$.pipe(map(state => state.status));
    this.deniedBy$ = combineLatest([
      this.hpmReports.filter$,
      this._statusSubject.asObservable(),
    ]).pipe(
      map(([state, status]) => {
        if (status?.length) {
          if (
            status.includes(HallPassStatusEnum.APPROVED) &&
            !status.includes(HallPassStatusEnum.DENIED)
          ) {
            this._disableDeniedBySubject.next(true);
            this.onChangeDeniedBy([]);
            return [];
          }
        }
        this._deniedByReasonSubject.next(state.deniedBy);
        this._disableDeniedBySubject.next(false);
        return state.deniedBy;
      }),
    );
    this.denialReason$ = combineLatest([
      this.hpmReports.filter$,
      this._denialReasonSubject.asObservable(),
    ]).pipe(
      map(([state, subj]) => {
        if (subj?.length) return subj;
        const blackouts = state.deniedByBlackout.map(makeBlackOut);
        const noParty = state.deniedByNoParty.map(makeNoParty);
        return [...blackouts, ...noParty];
      }),
    );

    if (
      this._permissions.hasPermission(MingaPermission.HALL_PASS_TYPE_MANAGE)
    ) {
      this._blackoutService.initService();
      this.denialReasonOptions$ = combineLatest([
        this._blackoutService.schedules$,
        from(
          this._listService.getMembershipListByType([
            MembershipListType.NO_PARTY,
          ]),
        ),
        this._deniedByReasonSubject.asObservable(),
      ]).pipe(
        takeUntil(this._destroyed$),
        map(([blackouts, noParty, deniedBy]) => {
          const options: FormSelectOption<string>[] = [];
          if (deniedBy?.includes(IHallPassErrorType.BLACK_OUT_WINDOW)) {
            const blackoutOptions = blackouts.map(blackout => ({
              label: blackout.name,
              value: makeBlackOut(blackout.id),
            }));
            options.push(...blackoutOptions);
          }
          if (deniedBy?.includes(IHallPassErrorType.NO_PARTY)) {
            const noPartyOptions = noParty.map(group => ({
              label: group.name,
              value: makeNoParty(group.id),
            }));
            options.push(...noPartyOptions);
          }
          if (!options.length) this.onChangeDenialReason([]);

          return options;
        }),
      );
    }

    this.hpmReports.onPersonSelectedFromTable
      .pipe(takeUntil(this._destroyed$))
      .subscribe(selected => {
        this._peopleSearchComponent.setHashValue([selected], true);
        this._cdr.markForCheck();
      });

    this._route.url.pipe(takeUntil(this._destroyed$)).subscribe(x => {
      if (this._route.children[0]) {
        this._route.children[0].url
          .pipe(takeUntil(this._destroyed$))
          .subscribe(url => {
            if (url[0]) {
              const report = url[0].path as ReportTypes;
              const possiblePaths = this.reportType.options.map(
                type => type.value,
              );
              if (possiblePaths.includes(report)) {
                this.reportType.active = report;
                this._cdr.markForCheck();
              }
            }
          });
      }
    });

    const currentFilter = this.hpmReports.getCurrentFilterState();

    this.range = initializeRange({
      start: {
        value: currentFilter.startDate,
      },
      end: {
        value: currentFilter.endDate,
      },
    });

    this.range.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(range => {
        this.hpmReports.setFilter(HpmReportsFilter.START_DATE, range.start);
        this.hpmReports.setFilter(HpmReportsFilter.END_DATE, range.end);
      });
  }

  ngAfterViewInit(): void {
    if (
      this.hpmReports.filter.issuedTo?.length ||
      this.hpmReports.filter.issuedBy?.length
    ) {
      this._peopleSearchComponent.setHashValue(
        [
          ...this.hpmReports.filter.issuedTo,
          ...this.hpmReports.filter.issuedBy,
        ],
        true,
      );
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public async onChangeReportType(value: string): Promise<void> {
    await this._router.navigateByUrl(
      `/${HpmRoutes.ROOT}/${HpmRoutes.REPORTS}/${value}`,
    );
    return;
  }

  public applyFilter(): void {
    this.hpmReports.applyFilter();
    this._analytics.sendManagerReport({
      managerType: 'hallpass',
      reportType: this.reportType.active,
    });
  }

  public clearFilter(): void {
    this._peopleSearchComponent.setValue([]);
    this.hpmReports.clearFilter();

    this._selectComponents.forEach((component, _) => {
      if (component.placeholder === 'Report Type') return;

      component.control.setValue(undefined);
    });

    this.range.patchValue({
      start: HPM_REPORTS_FILTER_INITIAL_STATE.startDate,
      end: HPM_REPORTS_FILTER_INITIAL_STATE.endDate,
    });
  }

  public onChangeHallpassType(value: any): void {
    this.hpmReports.setFilter(HpmReportsFilter.HALLPASS_TYPE, value);
  }

  public onPersonSelected(people: Person[]): void {
    let issuedTo: string[] = [];
    let issuedBy: string[] = [];
    if (this.reportType.active === ReportTypes.HALL_PASS_STUDENT) {
      issuedTo = people.map(p => p.hash);
    } else if (this.reportType.active === ReportTypes.HALL_PASS_STAFF) {
      issuedBy = people.map(p => p.hash);
    } else {
      for (const person of people) {
        if (
          person.roleType === MingaRoleType.STUDENT ||
          person.roleType === MingaRoleType.STUDENT_LEADER
        ) {
          issuedTo.push(person.hash);
        } else {
          issuedBy.push(person.hash);
        }
      }
    }
    this.hpmReports.setFilter(HpmReportsFilter.ISSUED_BY, issuedBy);

    this.hpmReports.setFilter(HpmReportsFilter.ISSUED_TO, issuedTo);
  }

  public onChangeCreatedBy(event: MatButtonToggleChange): void {
    const { value } = event;
    this.hpmReports.setFilter(HpmReportsFilter.CREATED_BY, value);
  }

  public onChangeUserList(value: number[]): void {
    this.hpmReports.setFilter(HpmReportsFilter.USER_LIST, value);
  }

  public onChangeStatus(value: HallPassStatusEnum[]): void {
    this._statusSubject.next(value);
    this.hpmReports.setFilter(HpmReportsFilter.STATUS, value);
  }

  public onChangeDeniedBy(value: number[]): void {
    this._deniedByReasonSubject.next(value);
    this.hpmReports.setFilter(HpmReportsFilter.DENIED_BY, value);
  }

  public onChangeDenialReason(value: string[]): void {
    this._denialReasonSubject.next(value);
    const blackouts: number[] = [];
    const noParty: number[] = [];

    for (const reason of value) {
      if (isBlackOut(reason)) blackouts.push(parseId(reason));
      else if (isNoParty(reason)) noParty.push(parseId(reason));
    }

    this.hpmReports.setFilter(HpmReportsFilter.DENIED_BY_BLACKOUT, blackouts);
    this.hpmReports.setFilter(HpmReportsFilter.DENIED_BY_NO_PARTY, noParty);
  }

  public onActivate(componentRef: any) {
    this._dataServiceSubj.next(componentRef.ds);
  }

  public async onScheduleReport() {
    const filters = await this.hpmReports.filter$.pipe(take(1)).toPromise();
    await this._mmScheduledReportService.scheduleReport(
      this.reportType.active,
      filters,
    );
  }
}
