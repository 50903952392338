import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { StepIndicatorComponent } from './StepIndicator.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgPipesModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [StepIndicatorComponent],
  exports: [StepIndicatorComponent],
})
export class StepIndicatorModule {}
