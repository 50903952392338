import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

export interface IGlobalFloatingPortalWrapper {
  portal: Portal<any>;
  viewAttachNotify: BehaviorSubject<boolean>;
}

/**
 * Service for maintaining a list of portals that are intended to be floating on
 * top of everything else.
 * @SEE https://material.angular.io/cdk/portal/overview
 */
@Injectable({ providedIn: 'root' })
export class GlobalFloatingPortalService {
  private _globalPortalsSubj: BehaviorSubject<IGlobalFloatingPortalWrapper[]>;

  readonly globalFloatingPortals$: Observable<IGlobalFloatingPortalWrapper[]>;

  constructor() {
    this._globalPortalsSubj = new BehaviorSubject<
      IGlobalFloatingPortalWrapper[]
    >([]);
    this.globalFloatingPortals$ = this._globalPortalsSubj.asObservable();
  }

  remove<T>(portalToRemove: Portal<T>) {
    const globalPortals = this._globalPortalsSubj.getValue();
    const existingPortalIndex = globalPortals.findIndex(
      ({ portal }) => portal === portalToRemove,
    );

    if (existingPortalIndex === globalPortals.length - 1) {
      this.pop();
    } else if (existingPortalIndex !== -1) {
      globalPortals.splice(existingPortalIndex, 1);
      this._globalPortalsSubj.next(globalPortals);
    }
  }

  /**
   * returns observable that triggers when the view has been attached
   */
  push<T>(portal: Portal<T>): Observable<void> {
    const globalPortals = this._globalPortalsSubj.getValue();
    const viewAttachNotify = new BehaviorSubject<boolean>(false);

    globalPortals.push({ portal, viewAttachNotify });
    this._globalPortalsSubj.next(globalPortals);

    return viewAttachNotify.pipe(
      filter(t => !!t),
      take(1),
      map(() => {}),
    );
  }

  pop() {
    const globalPortals = this._globalPortalsSubj.getValue();
    globalPortals.pop();
    this._globalPortalsSubj.next(globalPortals);
  }
}
