import { FormControl, FormGroup } from '@angular/forms';

import { IMembershipList } from 'minga/domain/membershipList';
import { Restriction } from 'minga/domain/restrictions';

export enum FormRestrictionMessages {
  DEFAULT_LABEL = 'Restrictions',
  DEFAULT_ERROR = "Restrictions cannot have more than 1 restrict condition, double check you don't have options selected on the other tabs",
  ERROR_REQUIRED = 'You must select a restriction',
  BTN_LABEL_ROLE = 'Role',
  BTN_LABEL_LISTS = 'Lists',
  BTN_LABEL_STICKERS = 'Stickers',
  BTN_LABEL_GROUPS = 'Groups',
  BTN_LABEL_GRADES = 'Grades',
  BTN_LABEL_REASONS = 'Reasons',
  BTN_LABEL_PEOPLE = 'People',

  INPUT_PLACEHOLDER_RESTRICTION = 'Select restriction',
  INPUT_PLACEHOLDER_LIST = 'Select lists',
  INPUT_PLACEHOLDER_ROLE = 'Select role(s)',
  INPUT_PLACEHOLDER_GROUP = 'Select group(s)',
  INPUT_PLACEHOLDER_GRADE = 'Select grade(s)',
  INPUT_PLACEHOLDER_REASON = 'Select reason',
  INPUT_PLACEHOLDER_STICKER = 'Select sticker(s)',
}

/**
 * Be mindful of the keys here
 * Matches field on Restrictions model except stickers and people which are bridge tables,
 */
export enum FormRestrictionTabs {
  Select = 'restrictionSelect',
  Role = 'allowedRoles',
  Lists = 'userLists',
  Stickers = 'stickerIds',
  Groups = 'allowedGroupHashes',
  Grades = 'allowedGrades',
  Reasons = 'restrictedCheckinReasonId',
  MembershipList = 'membershipList',
}

export type FormRestrictionValue = {
  [FormRestrictionTabs.Role]: any[];
  [FormRestrictionTabs.Lists]: number[];
  [FormRestrictionTabs.Stickers]: any[];
  [FormRestrictionTabs.Groups]: any[];
  [FormRestrictionTabs.Grades]: any[];
  [FormRestrictionTabs.Reasons]: any;
  [FormRestrictionTabs.MembershipList]: IMembershipList;
};

export const FormRestrictTabs = [
  {
    key: FormRestrictionTabs.Role,
    label: FormRestrictionMessages.BTN_LABEL_ROLE,
  },
  {
    key: FormRestrictionTabs.Lists,
    label: FormRestrictionMessages.BTN_LABEL_LISTS,
  },
  {
    key: FormRestrictionTabs.Stickers,
    label: FormRestrictionMessages.BTN_LABEL_STICKERS,
  },
  {
    key: FormRestrictionTabs.Groups,
    label: FormRestrictionMessages.BTN_LABEL_GROUPS,
  },
  {
    key: FormRestrictionTabs.Grades,
    label: FormRestrictionMessages.BTN_LABEL_GRADES,
  },
  {
    key: FormRestrictionTabs.Reasons,
    label: FormRestrictionMessages.BTN_LABEL_REASONS,
  },
  {
    key: FormRestrictionTabs.MembershipList,
    label: FormRestrictionMessages.BTN_LABEL_PEOPLE,
  },
];

export const toRestrictionInput = (
  restrictionModel: Restriction,
): FormRestrictionValue => {
  let membershipList = restrictionModel.membershipList;
  if (!membershipList && restrictionModel.membershipListId)
    membershipList = { id: restrictionModel.membershipListId };

  const value = {
    [FormRestrictionTabs.Role]: restrictionModel.roles || [],
    [FormRestrictionTabs.Lists]: restrictionModel.userLists || [],
    [FormRestrictionTabs.Stickers]: restrictionModel.stickerIds || [],
    [FormRestrictionTabs.Grades]: restrictionModel.grades || [],
    [FormRestrictionTabs.Groups]: restrictionModel.groupHashes || [],
    [FormRestrictionTabs.Reasons]:
      restrictionModel.restrictedCheckinReasonId || null,
    membershipList,
  };

  return value;
};

export const fromRestrictionInput = (
  input: FormRestrictionValue,
  existingModel: Restriction,
): Omit<Restriction, 'id'> => {
  const value = {
    ...(existingModel || {}),
    roles: input[FormRestrictionTabs.Role] || [],
    userLists: input[FormRestrictionTabs.Lists] || [],
    stickerIds: input[FormRestrictionTabs.Stickers] || [],
    grades: input[FormRestrictionTabs.Grades] || [],
    groupHashes: input[FormRestrictionTabs.Groups] || [],
    restrictedCheckinReasonId: input[FormRestrictionTabs.Reasons],
    membershipList: input[FormRestrictionTabs.MembershipList],
  };

  return value;
};

export const setDefaultRestrictionControlValue = () => {
  return new FormGroup({
    [FormRestrictionTabs.Select]: new FormControl(null),
    [FormRestrictionTabs.Role]: new FormControl([]),
    [FormRestrictionTabs.Lists]: new FormControl([]),
    [FormRestrictionTabs.Stickers]: new FormControl([]),
    [FormRestrictionTabs.Grades]: new FormControl([]),
    [FormRestrictionTabs.Groups]: new FormControl([]),
    [FormRestrictionTabs.Reasons]: new FormControl(null),
    [FormRestrictionTabs.MembershipList]: new FormControl(null),
  });
};
