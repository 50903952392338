import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

import {
  IMgStreamScrollerInstance,
  MG_STREAM_SCROLLER_INSTANCE,
} from './MgStreamScrollerInstance';

@Directive({
  selector: '[mgStreamItem], [mgTableStreamRow]',
})
export class MgStreamScrollerItemDirective implements OnInit, OnDestroy {
  constructor(
    @Inject(MG_STREAM_SCROLLER_INSTANCE)
    private streamScroller: IMgStreamScrollerInstance,
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
  ) {
    // Not directly used, but mgStreamItem is intended to only be used on a
    // template.
    templateRef;
    viewContainer;
  }

  ngOnInit() {
    this.streamScroller.registerStreamItem(this);
  }

  ngOnDestroy() {
    this.streamScroller.unregisterStreamItem(this);
  }
}
