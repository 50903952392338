import { Observable } from 'rxjs';

export type CacheItem<T> = {
  date: Date;
  value: Observable<T>;
};

export type StorageAdapter = {
  get<T>(key: string): Promise<CacheItem<T> | null>;
  set<T>(
    key: string,
    value: {
      date: Date;
      value: Observable<T>;
    },
  ): Promise<void>;
  delete(key: string): Promise<void>;
  clearAll(): Promise<void>;
};

export type StorageAdapterType = 'inMemory';

export enum CacheKey {
  DATE_PRESETS_CUSTOM = 'datePresets:custom',
  USER_LIST_MY_LISTS = 'userList:myLists',
  USER_LIST_ALL_LISTS = 'userList:allLists',
  DISTRICT_LIST = 'district:list',
}

export type CacheConfig = {
  /**
   * In minutes
   */
  ttl: number;
};

export type FetchOptions = {
  /**
   * Force re-fetching the data
   */
  revalidate?: boolean;
};

export type CacheItemMethods<T> = {
  get: (data?: any, options?: FetchOptions) => Observable<T>;
  set: (data: T) => void;
  clear: () => void;
};
