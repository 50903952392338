import { ModuleWithProviders, NgModule } from '@angular/core';

import { FileDownloaderService } from './FileDownloader.service';
import { FileUploadManager } from './FileUploadManager.service';

@NgModule({})
export class FileModule {
  static forRoot(): ModuleWithProviders<FileModule> {
    return {
      ngModule: FileModule,
      providers: [FileDownloaderService, FileUploadManager],
    };
  }
}
