import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { day } from 'minga/shared/day';

@Component({
  selector: 'mg-sms-message-short-card',
  templateUrl: './MgSmsMessageShortCard.component.html',
  styleUrls: ['./MgSmsMessageShortCard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgSmsMessageShortCardComponent {
  @Input()
  context: string = '';

  @Input()
  message: string = '';

  @Input()
  authorName: string = '';

  @Input()
  publishDate?: Date;

  @Input()
  pinned: boolean = false;

  get publishDateDisplayString(): string {
    return this.publishDate ? day(this.publishDate).fromNow() : '';
  }
}
