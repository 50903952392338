import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import _ from 'lodash';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';

import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';
import { MEMBERSHIP_LISTS_DATA } from 'minga/shared/membership_list/constants';
import { ListMembershipService } from 'src/app/services/ListMembership';

import { PeopleSelectorService } from '@modules/people-selector';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEvent,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MediaBreakpoints, MediaService } from '@shared/services/media';

import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '../system-alert-modal';
import { MltEditComponent } from './components/mlt-edit/mlt-edit.component';
import { MLT_DEFAULT_CONFIG, MembershipListTableMessages } from './constants';
import { MembershipListTableService } from './services';
import {
  MembershipListTableConfig,
  MembershipListTableData,
  MembershipListTableLists,
  MltEditData,
  MltEditResponse,
} from './types';

/**
 * Membership List Table
 *
 * Used to display membership lists in a table format.
 * Allows users to add/remove people from the list. Also allows for editing
 * additional list properties based on the inputs provided.
 *
 * @todo implement permission checking here instead of elsewhere
 */
@Component({
  selector: 'mg-membership-list-table',
  templateUrl: './membership-list-table.component.html',
  styleUrls: ['./membership-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipListTableComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @ContentChild('assetOverrideTemplate', { read: TemplateRef })
  assetOverrideTemplate: TemplateRef<any>;

  /** Child Components */
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Constants */
  public readonly MSG = MembershipListTableMessages;
  public readonly LIST_TYPES = MembershipListType;

  /** Misc Subjects */
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  /** Config Subject */
  private readonly _configSubj = new BehaviorSubject<MembershipListTableConfig>(
    { ...MLT_DEFAULT_CONFIG },
  );
  public readonly config$ = this._configSubj
    .asObservable()
    .pipe(distinctUntilChanged());

  /** Is Loading */
  private readonly _isLoadingSubj = new BehaviorSubject(false);
  public readonly isLoading$ = this._isLoadingSubj.asObservable();

  /** Status Form Control */
  public readonly statusControl = this._fb.control(false);

  /** Displayed Columns */
  public readonly displayedColumns$ = combineLatest([
    this.media.breakpoint$,
    this.config$,
  ]).pipe(
    takeUntil(this._destroyedSubj),
    map(([breakpoint, config]) => this._getColumns(breakpoint, config)),
  );

  /** Table DataSource */
  public readonly dataSource = new MatTableDataSource<MembershipListTableData>(
    [],
  );

  /** If this is supplied then all lists of this type will be fetched */
  @Input() public types: MembershipListType[];

  /**
   * Provide an array of lists in accepted type format for the component to either
   * fetch or create. If you want to have placeholders for new a new list, provide the type.
   * otherwise provide id or context
   */
  @Input() public lists: MembershipListTableLists[];

  /**
   * Toggle showing the table header, if listing only one list then it is
   * preferred to hide the header.
   */
  @Input() public hideHeader = false;

  @Input() public pageSizeOptions: number[] = [100, 50, 20];

  /** Auto create lists from the supplied types if they do not already exist */
  @Input() public autoCreate = false;

  /**
   * Allow changing of the status
   */
  @Input() public canChangeStatus = MLT_DEFAULT_CONFIG.changeStatus;

  /** Whether to show the tooltip descriptions for the lists or not enabled by default */
  @Input() public descriptions = MLT_DEFAULT_CONFIG.showDescription;

  @Input() public canChangeName = MLT_DEFAULT_CONFIG.changeName;

  @Input() public canChangeImage = MLT_DEFAULT_CONFIG.changeImage;

  @Input() public canChangeColor = MLT_DEFAULT_CONFIG.changeColor;

  @Input() public canChangePriority = MLT_DEFAULT_CONFIG.changePriority;

  @Input() public canRemoveAll: boolean;

  /**
   * Which selection of premade images should the user be allowed to pick from
   *
   * @todo implement sticker preset
   */
  @Input() public imagePreset = MLT_DEFAULT_CONFIG.imagePreset;

  /** Allow the user to upload their own images for the list */
  @Input() public imageUploads = MLT_DEFAULT_CONFIG.imageUploads;

  /** Refresh List */
  @Input() refreshList: Observable<void>;

  @Input() placeholderIcon: string;

  /**
   * Can the user be permitted to delete the lists rendered on this table?
   */
  @Input() public canDelete: boolean;

  /**
   * Temporary front-end fix to have legacy and new lists all use the same name
   */
  @Input() public useDefaultListName = false;

  /**
   * Override to not close outer modal after people selector is closed.
   */
  @Input() dontCloseModal = false;

  @Input() showDeniedPasses;

  /**
   * Emitted when a list has been successfully updated
   */
  @Output() listUpdated = new EventEmitter<IMembershipList>();

  @Output() navigateToDeniedPasses = new EventEmitter<IMembershipList>();

  /** Computed Classes */
  get classes() {
    return {
      'with-headers': !this.hideHeader,
      'no-headers': this.hideHeader,
    };
  }

  /** Component Constructor */
  constructor(
    public media: MediaService,
    private _fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _systemSnack: SystemAlertSnackBarService,
    private _peopleSelector: PeopleSelectorService,
    private _listService: ListMembershipService,
    private _membershipListTable: MembershipListTableService,
    private _modalOverlay: ModalOverlayService<MltEditResponse, MltEditData>,
    private _systemAlertModal: SystemAlertModalService,
  ) {}

  ngOnInit(): void {
    this._refreshListsSubscription();
    this._fetchData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(): void {
    this._updateConfig();
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
    this._isLoadingSubj.complete();
    this._configSubj.complete();
  }

  public trackById(index: number, item: MembershipListTableData) {
    return item ? item.id : index;
  }

  public async openPeopleSelector(
    item: MembershipListTableData,
  ): Promise<void> {
    const { tempId, name, memberCount } = item;
    const membershipList = this._getMembershipList(item);
    const type = memberCount > 0 ? 'remove' : 'add';
    await this._peopleSelector.open('List Membership', type, {
      title: name,
      data: {
        listId: membershipList.id,
        listType: membershipList.listType,
        tempId: tempId || null,
        contextHash: membershipList.contextHash || null,
        name: membershipList.name || '',
      },
      dontCloseModal: this.dontCloseModal,
    });
    await this._peopleSelector
      .eventWhen('after-closed')
      .pipe(takeUntil(this._destroyedSubj), take(1))
      .toPromise();
    let updatedList: IMembershipList;
    if (tempId) updatedList = await this._updateNewList(tempId);
    else updatedList = await this._updateExistingList(membershipList);
    this.listUpdated.emit(updatedList);
  }

  public async editList(item: MembershipListTableData): Promise<void> {
    const { changeColor, changeImage, changeName, canDelete, changePriority } =
      this._configSubj.getValue();
    const modalRef = this._modalOverlay.open(MltEditComponent, {
      data: {
        id: item.id,
        contextHash: item.contextHash,
        tempId: item.tempId,
        assetPath: item?.assetPath,
        config: {
          changeColor,
          changeImage,
          changeName,
          canDelete,
          changePriority,
        },
      },
    });
    modalRef.afterClosed
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(r => this._handleAfterEditClosed(r));
  }

  public async changeStatus(item: MembershipListTableData, value: boolean) {
    try {
      item.active = value;
      await this._listService.updateMembershipList(item);
      this._cdr.markForCheck();

      this._systemSnack.open({
        type: 'success',
        message: value
          ? 'Status changed to active'
          : 'Status changed to inactive',
      });
    } catch (error) {
      this._systemSnack.open({
        type: 'error',
        message: 'Failed to update status',
      });
    }
  }

  public checkForAsset(item: IMembershipList) {
    if (item.listType === MembershipListType.STICKER) {
      return false;
    }

    return this.placeholderIcon && !item.assetPath;
  }

  public async openRemoveAllMembers(list: IMembershipList) {
    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.WARNING,
      heading: 'Remove all members',
      message: `Are you sure you want to remove all the members from ${list.name}?`,
      confirmActionBtn: 'Confirm',
    });

    const result = await modalRef.afterClosed().toPromise();
    if (result.type === SystemAlertCloseEvents.CONFIRM) {
      await this._listService.removeAllMembersFromList(list.id);

      const updatedList = await this._updateExistingList(list);
      this.listUpdated.emit(updatedList);

      this._cdr.markForCheck();
      this._systemSnack.success('Successfully removed all members');
    }
  }

  public openDeniedPassesReport(list: IMembershipList) {
    this.navigateToDeniedPasses.emit(list);
  }

  private _refreshListsSubscription() {
    return this.refreshList
      ?.pipe(takeUntil(this._destroyedSubj))
      .subscribe(() => this._fetchData());
  }

  private async _handleAfterEditClosed(
    res: ModalOverlayServiceCloseEvent<MltEditResponse>,
  ): Promise<void> {
    if (!res) return;
    switch (res.type) {
      case ModalOverlayServiceCloseEventType.SUBMIT: {
        const updatedList = res.data.updatedList;
        this._updateDataSourceItem(updatedList);
        this._systemSnack.open({
          message: 'Successfully updated list',
          type: 'success',
        });
        this.listUpdated.emit(updatedList);
        break;
      }
      case ModalOverlayServiceCloseEventType.DELETE: {
        const deletedListId = res.data.deletedListId;
        let data = [...this.dataSource.data];
        data = data.filter(({ id }) => id !== deletedListId);
        this.dataSource.data = data;
        this._cdr.markForCheck();
        this._systemSnack.open({
          message: 'Successfully deleted list',
          type: 'success',
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  private _getMembershipList(
    tableItem: MembershipListTableData,
  ): IMembershipList {
    const membershipList = { ...tableItem };
    delete membershipList.tempId;
    return membershipList;
  }

  private _updateConfig() {
    this._configSubj.next({
      canDelete: this.canDelete,
      changeColor: this.canChangeColor,
      changeImage: this.canChangeImage,
      changeStatus: this.canChangeStatus,
      changeName: this.canChangeName,
      changePriority: this.canChangePriority,
      showDescription: this.descriptions,
      imagePreset: this.imagePreset,
      imageUploads: this.imageUploads,
    });
  }

  private _getColumns(
    breakpoint: MediaBreakpoints,
    config: MembershipListTableConfig,
  ): string[] {
    const showMobileViewOn: MediaBreakpoints[] = ['xsmall', 'small'];
    if (showMobileViewOn.includes(breakpoint)) {
      const result = ['mobile'];
      if (
        Object.values({
          img: config.changeImage,
          color: config.changeColor,
          name: config.changeName,
          priority: config.changePriority,
          delete: config.canDelete,
        }).find(v => v)
      )
        result.push('edit');

      if (config.changeStatus) result.push('active');
      if (this.showDeniedPasses) result.push('deniedPasses');

      return result;
    }
    const result = ['name'];
    if (this.showDeniedPasses) result.push('deniedPasses');
    result.push('actions');

    const {
      changeStatus,
      changeImage,
      showDescription,
      imagePreset,
      imageUploads,
      ...remainingOptions
    } = config;

    if (changeImage) {
      result.unshift('image');
      result.push('edit');
      result.push('active');
      return result;
    }
    if (Object.values(remainingOptions).find(value => value))
      result.push('edit');

    if (changeStatus) {
      result.push('active');
    }
    return result;
  }

  private async _updateExistingList(
    list: IMembershipList,
  ): Promise<IMembershipList> {
    try {
      let result: IMembershipList;
      if (list.contextHash) {
        result = await this._listService.getMembershipListByContextHash(
          list.contextHash,
        );
      } else {
        result = await this._listService.getMembershipList(list.id);
      }

      this._updateDataSourceItem(result);
      return result;
    } catch (error) {
      this._systemSnack.open({
        message: `Failed to update ${list.name}`,
        type: 'error',
      });
    }
  }

  private _updateDataSourceItem(updatedList: IMembershipList): void {
    const data = [...this.dataSource.data];
    data[data.findIndex(({ id }) => id === updatedList.id)] = updatedList;
    this.dataSource.data = data;
    this._cdr.markForCheck();
  }

  private async _updateNewList(tempId: string): Promise<IMembershipList> {
    const list = this._membershipListTable.getListFromReference(tempId);
    if (!list) return;
    const data = [...this.dataSource.data];
    data[data.findIndex(item => item.tempId === tempId)] = list;
    this.dataSource.data = data;
    this._cdr.markForCheck();
    this._membershipListTable.deleteTemporyReference(tempId);
    return list;
  }

  private async _fetchData(): Promise<void> {
    this._isLoadingSubj.next(true);
    if (this.types) await this._fetchAllListsByType();
    else await this._fetchLists();
    this._isLoadingSubj.next(false);
  }

  private async _fetchAllListsByType() {
    try {
      let result;
      if (this.autoCreate)
        result = await this._listService.getOrCreateMembershipLists(this.types);
      else result = await this._listService.getMembershipListByType(this.types);

      result = _.sortBy(result, item => _.indexOf(this.types, item.listType));
      this.dataSource.data = result;
    } catch (error) {
      this._systemSnack.open({
        message: 'Error fetching lists data',
        type: 'error',
      });
    }
  }

  private async _fetchLists(): Promise<void> {
    try {
      const hasContextHash = this.lists.some(({ contextHash }) => contextHash);
      if (hasContextHash) await this._fetchListByContextHash();
      else {
        const { existingLists, newLists } = this.lists.reduce(
          (acc, list) => {
            if (list.id) acc.existingLists.push(list);
            else acc.newLists.push(list);
            return acc;
          },
          {
            newLists: [],
            existingLists: [],
          } as {
            newLists: MembershipListTableLists[];
            existingLists: MembershipListTableLists[];
          },
        );
        const result = newLists.map(list =>
          this._newListWithDefaultConfig(list),
        );

        let fetchedLists: IMembershipList[] = [];
        fetchedLists = await Promise.all(
          existingLists.map(({ id }) =>
            this._listService.getMembershipList(id, false),
          ),
        );
        this.dataSource.data = [...result, ...fetchedLists];
      }
    } catch (error) {
      this._systemSnack.open({
        message: 'Error fetching lists data',
        type: 'error',
      });
    }
  }

  private _newListWithDefaultConfig(
    list: MembershipListTableLists,
  ): MembershipListTableData {
    if (!list.type) return;
    const { defaultConfig } = MEMBERSHIP_LISTS_DATA[list.type];
    return {
      listType: list.type,
      tempId: Math.random().toString(36).substring(2, 10),
      active: true,
      memberCount: 0,
      contextHash: list.contextHash,
      ...defaultConfig,
    };
  }

  private async _fetchListByContextHash() {
    const listArray = [];
    for (const list of this.lists) {
      const foundList = await this._listService.getMembershipListByContextHash(
        list.contextHash,
      );
      if (foundList) listArray.push(foundList);
      else {
        const newList = this._newListWithDefaultConfig(list);
        listArray.push(newList);
      }
    }
    this.dataSource.data = [...listArray];
  }
}
