import { Pipe, PipeTransform } from '@angular/core';

import { FlexTimeActivityInstance } from 'minga/domain/flexTime';

@Pipe({ name: 'countActivityInstances' })
export class CountActivityInstancesPipe implements PipeTransform {
  transform(instances: FlexTimeActivityInstance[], hash?: string): number {
    if (hash) {
      const myCount = instances.filter(
        a => a.flexTimeActivity?.createdByPerson.hash === hash,
      ).length;
      return myCount;
    }
    return instances.length;
  }
}
