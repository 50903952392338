import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Notification } from 'minga/proto/gateway/notification_pb';

@Component({
  selector: 'mg-challenge-response-notification',
  templateUrl: './MgChallengeResponseNotification.element.html',
  styleUrls: ['./MgChallengeResponseNotification.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgChallengeResponseNotificationElement {
  @Input()
  notification?: Notification.AsObject;
}
