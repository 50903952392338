import { Pipe, PipeTransform } from '@angular/core';

import { STATUS_REFERENCE_MAP } from '../constants/report-table.constants';
import { StatusReportManager, Statuses } from '../types/report-table.types';

@Pipe({ name: 'statusTagColor' })
export class StatusTagColorPipe implements PipeTransform {
  transform(statusText: Statuses, reportType: StatusReportManager) {
    return STATUS_REFERENCE_MAP[reportType][statusText];
  }
}
