import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import * as mingaProto from 'minga/proto/gateway/minga_pb';
import {
  IMingaDistrict,
  IMingaPartner,
  IMingaSubscription,
} from 'minga/domain/minga';
import { MingaManager } from 'minga/proto/gateway/minga_ng_grpc_pb';
import {
  MingaPartnerMapper,
  MingaSubscriptionProtoMapper,
} from 'minga/shared-grpc/minga';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { sortMingaSubscriptions } from '@shared/utils/sort-minga-subscriptions.util';

@Injectable()
export class MingaManagerService implements OnDestroy {
  /** General Observables */
  private readonly _isLoading = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoading
    .asObservable()
    .pipe(shareReplay());

  /** Minga Subscriptions */
  private readonly _subscriptions = new BehaviorSubject<IMingaSubscription[]>(
    [],
  );
  public readonly subscriptions$ = this._subscriptions
    .asObservable()
    .pipe(shareReplay());

  /** Minga Partners */
  private readonly _mingaPartners = new BehaviorSubject<IMingaPartner[]>([]);
  public readonly mingaPartners$ = this._mingaPartners
    .asObservable()
    .pipe(shareReplay());

  /** Service Constructor */
  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _mingaManager: MingaManager,
  ) {}

  ngOnDestroy(): void {
    this._isLoading.complete();
    this._subscriptions.complete();
    this._mingaPartners.complete();
  }

  public async fetchData(): Promise<void> {
    this._isLoading.next(true);
    await this.fetchMingaPartners();
    await this.fetchMingaSubscriptions();
    this._isLoading.next(false);
  }

  public async fetchMingaPartners(): Promise<void> {
    try {
      const partners = await this._listMingaPartners();
      this._mingaPartners.next(partners);
    } catch (error) {
      this._systemAlertSnackBar.error('failed to fetch minga partners');
    }
  }

  public async fetchMingaSubscriptions(mingaType?: string): Promise<void> {
    try {
      const result = await this._listMingaSubscriptions(mingaType);
      this._subscriptions.next(sortMingaSubscriptions(result));
    } catch (error) {
      this._systemAlertSnackBar.error('failed to fetch subscriptions');
    }
  }

  private async _listMingaSubscriptions(
    mingaType?: string,
  ): Promise<IMingaSubscription[]> {
    const request = new mingaProto.ReadMingaSubscriptionsRequest();
    if (mingaType) request.setFilterMingaType(mingaType);
    const response = await this._mingaManager.getMingaSubscriptions(request);
    return (
      response
        .getSubscriptionList()
        .map(MingaSubscriptionProtoMapper.toIMingaSubscription) || []
    );
  }

  private async _listMingaPartners(): Promise<IMingaPartner[]> {
    const request = new mingaProto.GetMingaPartnersRequest();
    const response = await this._mingaManager.getMingaPartners(request);
    return response.getPartnersList().map(MingaPartnerMapper.fromProto) || [];
  }
}
