/**
 * Typesafe key for the FtueHighlightService
 */
export class FtueHighlightKey {
  /** INTERNAL USE ONLY - DO NOT USE */
  _ftueHighlightKey?: Symbol;
}

export function createFtueHighlightKey(): FtueHighlightKey {
  const key = new FtueHighlightKey();
  key._ftueHighlightKey = Symbol();
  return key;
}
