import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Breadcrumb } from './breadcrumb.types';

@Component({
  selector: 'mg-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  @Input() routes: Breadcrumb[] = [];

  public trackBy(index: number, item: Breadcrumb) {
    return item.path || index;
  }
}
