import { PromotionalModalConfig } from '../types';

export const HALLPASS_CONFIG: PromotionalModalConfig = {
  module: 'hall-pass',
  title: {
    text: 'Hall Pass',
    icon: 'mg-hallpass-menu',
  },
  header: {
    title: 'Take Back Control',
    highlight: 'of Your Hallways',
    subtitle: `Meet the Minga Digital Hall Pass, the most flexible, effective, and easy-to-use hall pass solution for today's modern campus.`,
  },
  body: [
    {
      icon: 'mg-time',
      text: 'Define one hall pass workflow for your entire campus',
    },
    {
      icon: 'mg-health-and-safety',
      text: 'Manage hallway access by the student, time, reason, location and more',
    },
    {
      icon: 'mg-dashboard',
      text: 'Gain detailed visibility into all aspects of hallway usage',
    },
    {
      icon: 'mg-badge',
      text: 'Drive hallway accountability for students and staff',
    },
  ],
  learnMoreLink: 'https://minga.io/digital-hall-pass/',
};

export const BEHAVIOR_CONFIG: PromotionalModalConfig = {
  module: 'behavior',
  title: {
    text: 'Behavior & Rewards',
    icon: 'mg-behavior-menu',
  },
  header: {
    title: 'Enhance Student Accountability',
    highlight: '& Improve Behavior',
    subtitle: `Minga's Behavior Module helps to monitor tardies, behaviors, and motivate students with a rewards-based system.`,
  },
  body: [
    {
      icon: 'mg-bar-chart',
      text: 'Address unique high school student behaviors: tardies, vaping, violence, vandalism',
    },
    {
      icon: 'mg-praise',
      text: 'Motivate and incentivize young adults with custom rewards programs',
    },
    {
      icon: 'mg-dashboard',
      text: 'Reduce administrative burden: simplify accounting and record-keeping for large classrooms',
    },
    {
      icon: 'mg-manage-people',
      text: 'Drive consistent process and accountability across staff and admin',
    },
    {
      icon: 'mg-health-and-safety',
      text: 'Identify at-risk students and prevent unwanted behaviors',
    },
  ],
  learnMoreLink: 'https://minga.io/pbis-rewards-tracking-student-behavior/',
};

export const CHECKIN_CONFIG: PromotionalModalConfig = {
  module: 'check-in',
  title: {
    text: 'Check In',
    icon: 'mg-checkin-menu',
  },
  header: {
    title: 'Simplify Daily',
    highlight: 'Attendance Reporting',
    subtitle: `Easily report student attendance for non-classroom activities with Minga's Check In Module, eliminating manual monitoring.`,
  },
  body: [
    {
      icon: 'mg-distance',
      text: 'Know where every student is at all times',
    },
    {
      icon: 'mg-health-and-safety',
      text: 'Increase campus safety with secure & real time check in',
    },
    {
      icon: 'mg-dashboard',
      text: 'Flexibly track any activity outside the regular schedule',
    },
    {
      icon: 'mg-dvr',
      text: 'Live dashboard and reports showing status of every student on campus',
    },
  ],
  learnMoreLink: 'https://minga.io/school-check-in/',
};

export const FLEXTIME_CONFIG: PromotionalModalConfig = {
  module: 'flextime',
  title: {
    text: 'FlexTime',
    icon: 'mg-flextime-menu',
  },
  header: {
    title: 'Customize Flex Periods',
    highlight: 'With Effortless Ease',
    subtitle: `Optimize high school flex periods with Minga's FlexTime Module, crafting personalized learning paths for students.`,
  },
  body: [
    {
      icon: 'mg-dashboard',
      text: 'Simple for admins, with no more manual scheduling or intervention',
    },
    {
      icon: 'mg-table-chart',
      text: 'Teachers can easily assign activities to students and automate scheduling',
    },
    {
      icon: 'mg-health-and-safety',
      text: 'Ensure students receive personalized support and learning',
    },
    {
      icon: 'mg-manage-people',
      text: 'Attendance management made easy with built-in check in',
    },
  ],
  learnMoreLink: 'https://minga.io/flextime/',
};
