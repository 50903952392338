import { Observable, Subject } from 'rxjs';

import { CordovaBackgroundModeStatus } from './background-mode.types';

let _listenersRegistered = false;
const _backgroundModeStatusChangedSubj =
  new Subject<CordovaBackgroundModeStatus>();

const getBackgroundModePlugin = (): any | null => {
  return (window as any).cordova?.plugins?.backgroundMode || null;
};

export const backgroundModeStatusChanged$: Observable<CordovaBackgroundModeStatus> =
  _backgroundModeStatusChangedSubj.asObservable();

export const startListenForBackgroundModeStatusChanges = () => {
  const backgroundMode = getBackgroundModePlugin();
  if (backgroundMode && !_listenersRegistered) {
    backgroundMode.on('enable', () =>
      _backgroundModeStatusChangedSubj.next('enable'),
    );
    backgroundMode.on('disable', () =>
      _backgroundModeStatusChangedSubj.next('disable'),
    );
    backgroundMode.on('activate', () =>
      _backgroundModeStatusChangedSubj.next('activate'),
    );
    backgroundMode.on('deactivate', () =>
      _backgroundModeStatusChangedSubj.next('deactivate'),
    );
    backgroundMode.on('failure', () =>
      _backgroundModeStatusChangedSubj.next('failure'),
    );

    _listenersRegistered = true;
  }
};

/**
 * @SEE `cordova.plugins.backgroundMode.enable`
 * @SEE https://github.com/katzer/cordova-plugin-background-mode
 */
export const backgroundModeEnable = () => {
  const backgroundMode = getBackgroundModePlugin();
  if (backgroundMode) {
    backgroundMode.enable();
  }
};

/**
 * @SEE `cordova.plugins.backgroundMode.disable`
 * @SEE https://github.com/katzer/cordova-plugin-background-mode
 *
 * @NOTE No-op in non-cordova environment
 */
export const backgroundModeDisable = () => {
  const backgroundMode = getBackgroundModePlugin();
  if (backgroundMode) {
    backgroundMode.disable();
  }
};

/**
 * @SEE `cordova.plugins.backgroundMode.setEnabled`
 * @SEE https://github.com/katzer/cordova-plugin-background-mode
 *
 * @NOTE No-op in non-cordova environment
 */
export const backgroundModeSetEnabled = (value: boolean) => {
  const backgroundMode = getBackgroundModePlugin();
  if (backgroundMode) {
    backgroundMode.setEnabled(value);
  }
};

/**
 * @SEE `cordova.plugins.backgroundMode.isActive`
 * @SEE https://github.com/katzer/cordova-plugin-background-mode
 *
 * @NOTE Alawys returns `false` in non-cordova environment
 */
export const backgroundModeIsActive = () => {
  const backgroundMode = getBackgroundModePlugin();
  if (backgroundMode) {
    return backgroundMode.isActive();
  }
  return false;
};
