import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
} from 'rxjs/operators';

import { IAuthPerson } from 'minga/domain/auth';
import {
  FlexTimeActivity,
  FlexTimeActivityInstance,
  FlexTimePeriod,
} from 'minga/domain/flexTime';

import { FlexTimePeriodService } from '@shared/services/flex-time';

import {
  ActivityCardFields,
  ActivityListFilters,
  FtmSharedMessages,
} from '../../ftm-shared.constants';
import { normalizeActivity } from '../ftm-activity-card/ftm-activity-card.component';

const mapTeachers = (
  teachers: Map<string, string>,
): Array<{ value: string; label: string }> => {
  return Array.from(teachers.entries()).map(([key, value]) => {
    return {
      value: key,
      label: value,
    };
  });
};

@Component({
  selector: 'mg-ftm-activity-selector-list',
  templateUrl: './ftm-activity-selector-list.component.html',
  styleUrls: ['./ftm-activity-selector-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmActivitySelectorListComponent implements OnInit, OnDestroy {
  private readonly _destroyed = new ReplaySubject<void>(1);

  @ContentChild('noResults', { read: TemplateRef })
  noResults: TemplateRef<any>;

  public searchFilter = this._fb.control('');
  public periodFilter = this._fb.control(null);
  public teacherFilter = this._fb.control(null);
  public MESSAGES = FtmSharedMessages;

  private _activities;
  private _initialActivities;
  private _periods;

  private _periodOptionsSub = new BehaviorSubject<
    Array<{ value: number; label: string }>
  >([]);
  public periodOptions$ = this._periodOptionsSub.asObservable();
  private _teacherOptionsSub = new BehaviorSubject<
    Array<{ value: string; label: string }>
  >([]);
  public teacherOptions$ = this._teacherOptionsSub.asObservable();

  @Input() selectedId: number;

  @Input() set periods(periods: FlexTimePeriod[]) {
    this._periods = periods;
    this._updatePeriodsOptions();
  }
  @Input() set activities(
    activities: Array<FlexTimeActivityInstance | FlexTimeActivity>,
  ) {
    if (!this._initialActivities && activities.length) {
      this._initialActivities = activities;
      this._updateTeacherOptions();
    }
    this._activities = (activities || []).sort((a, b) => {
      // need to normalize name field since it could be template or instance here
      const aName = (a as any).flexTimePeriod
        ? (a as FlexTimeActivityInstance).flexTimeActivity?.name
        : (a as FlexTimeActivity).name;
      const bName = (b as any).flexTimePeriod
        ? (b as FlexTimeActivityInstance).flexTimeActivity?.name
        : (b as FlexTimeActivity).name;

      return aName.localeCompare(bName);
    });
  }

  get activities() {
    return this._activities;
  }

  @Input() defaultValue: {
    periodId?: number;
    teacher?: IAuthPerson;
  };

  @Input() activityCardFields: ActivityCardFields[];

  @Output() filterChange: EventEmitter<ActivityListFilters> =
    new EventEmitter();

  @Output() activitySelected: EventEmitter<
    FlexTimeActivityInstance | FlexTimeActivity
  > = new EventEmitter();

  private readonly _searchFilter$ = this.searchFilter.valueChanges.pipe(
    takeUntil(this._destroyed),
    startWith(''),
    debounceTime(200),
    distinctUntilChanged(),
  );

  private readonly _periodFilter$ = this.periodFilter.valueChanges.pipe(
    takeUntil(this._destroyed),
    startWith(''),
    distinctUntilChanged(),
  );

  private readonly _teacherFilter$ = this.teacherFilter.valueChanges.pipe(
    takeUntil(this._destroyed),
    startWith(''),
    distinctUntilChanged(),
  );

  constructor(
    private _fb: FormBuilder,
    private _flexPeriods: FlexTimePeriodService,
  ) {}

  ngOnInit() {
    combineLatest([
      this._searchFilter$,
      this._periodFilter$,
      this._teacherFilter$,
    ])
      .pipe(takeUntil(this._destroyed))
      .subscribe(([searchFilter, periodFilter, teacherFilter]) => {
        this.filterChange.emit({ searchFilter, periodFilter, teacherFilter });
      });

    if (this.defaultValue?.teacher?.hash) {
      this.teacherFilter.setValue(this.defaultValue?.teacher.hash);
      this.teacherFilter.disable();
    }

    if (this.defaultValue?.periodId) {
      this.periodFilter.setValue(this.defaultValue?.periodId);
      this.periodFilter.disable();
    }
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public select(activity) {
    this.activitySelected.emit(activity);
  }

  private _updatePeriodsOptions() {
    const periods = this._periods;
    if (periods) {
      this._periodOptionsSub.next(
        this._flexPeriods.formatPeriodsOptions(periods),
      );
    }
  }

  private _getTeacherOptions() {
    const teachers = new Map();

    const activities = this._initialActivities.map(normalizeActivity);

    activities
      .map(a => a.activity)
      .filter(a => a?.createdByPerson)
      .forEach(a => {
        teachers.set(a.createdByPerson.hash, a.createdByPerson.name);
      });

    return mapTeachers(teachers);
  }

  private _updateTeacherOptions() {
    const options = this._getTeacherOptions();
    this._teacherOptionsSub.next(options);
  }
}
