import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { NaviconModule } from 'minga/app/src/app/components/Navicon';
import { FtueHighlightPipeModule } from 'minga/app/src/app/ftue/Highlight/pipes/FtueHighlight';
import { NavLinkModule } from 'minga/app/src/app/navigation/components/NavLink';

import { SubNavComponent } from './SubNav.component';

@NgModule({
  imports: [
    // Minga dependencies
    FtueHighlightPipeModule,
    NavLinkModule,
    NaviconModule,
    // External dependencies
    CommonModule,
    RouterModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    FlexLayoutModule,
  ],
  declarations: [SubNavComponent],
  exports: [SubNavComponent],
})
export class SubNavModule {}
