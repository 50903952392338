<ng-container *ngIf="step == 0">
  <div class="header">
    <p class="title mg-headline3">Welcome To Minga</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      It’s a bit lonely in here so let’s fill your Minga with people from your
      community and some engaging content.
    </p>
  </div>
  <mg-ftue-video videoName="OwnerIntro2"></mg-ftue-video>
</ng-container>
<ng-container *ngIf="step == 1">
  <div class="header">
    <p class="title mg-headline3">Share Your Join Code</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Anyone with your unique 4 letter code can easily and quickly join your
      Minga.
    </p>
    <p class="subtitle mg-smallcopy">
      It’s off by default so enable this in Minga Settings / Joining and watch
      your community grow.
    </p>
  </div>
  <mg-ftue-video videoName="Invite2"></mg-ftue-video>
</ng-container>
<ng-container *ngIf="step == 2">
  <div class="header">
    <p class="title mg-headline3">Invite Your Community</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Add people one at a time or upload a list and Minga will send them an
      invite via email.
    </p>
    <p class="subtitle mg-smallcopy">
      Some district or school mail servers might block incoming emails to
      students' email addresses. Check this first or test with one or two
      students.
    </p>
  </div>
  <mg-ftue-video videoName="JoinEmail1"></mg-ftue-video>
</ng-container>
<ng-container *ngIf="step == 3">
  <div class="header">
    <p class="title mg-headline3">Your Home Feed</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Your Home Feed is full of the latest news, events, activities and stories
      from your community.
    </p>
    <p class="subtitle mg-smallcopy">
      Activity from Groups you own or belong to will also show up here.
    </p>
  </div>
  <mg-ftue-video videoName="Share3"></mg-ftue-video>
</ng-container>

<ng-container *ngIf="step == 4">
  <div class="header">
    <p class="title mg-headline3">Create a Welcome Message</p>
    <div class="divider"></div>
    <p class="subtitle mg-smallcopy">
      Start by adding a Welcome Message to your home feed with Minga Studio.
    </p>
    <p class="subtitle mg-smallcopy">
      Click +Add (look at all the types of content you can easily add!) and
      Select Studio. Then select a template to get started and write a short
      Welcome Message that everyone will see.
    </p>
  </div>
  <mg-ftue-video videoName="Share4"></mg-ftue-video>
</ng-container>

<div class="footer">
  <button
    mgBubbleBtn
    class="mg-headline"
    (click)="next()">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="stepCount - 1">Get Started!</ng-container>
      <ng-container *ngSwitchDefault>Next</ng-container>
    </ng-container>
  </button>

  <div class="mg-centered">
    <mg-step-indicator
      [allowStepClickNavigation]="true"
      [(activeStep)]="step"
      [stepCount]="stepCount"></mg-step-indicator>
  </div>
</div>
