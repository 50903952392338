import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Optional,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';
import {
  MatCalendar,
  MatCalendarHeader,
  MatDatepicker,
  MatDatepickerIntl,
} from '@angular/material/datepicker';

@Component({
  selector: 'mg-custom-dt-header',
  templateUrl: './CustomDatePickerHeader.component.html',
  styleUrls: ['./CustomDatePickerHeader.component.scss'],
  host: {
    class: 'mat-calendar-header',
    role: 'grid',
    'aria-readonly': 'true',
  },
})
export class CustomDatePickerHeader<D> extends MatCalendarHeader<D> {
  constructor(
    private _datePicker: MatDatepicker<D>,
    _intl: MatDatepickerIntl,
    @Inject(forwardRef(() => MatCalendar)) public calendar: MatCalendar<D>,
    @Optional() _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) _dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(_intl, calendar, _dateAdapter, _dateFormats, changeDetectorRef);
  }

  resetDatePicker() {
    this._datePicker.select(null);
  }

  closeDatePicker() {
    this._datePicker.close();
  }
}
