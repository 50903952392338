import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import {
  DEFAULT_VIDEO_HEIGHT,
  DEFAULT_VIDEO_MAXWIDTH,
  IVideoData,
  IVideoThumbnail,
  VideoService,
  VIMEO_MIN_THUMBNAIL_WIDTH,
} from 'minga/app/src/app/services/Video';

@Component({
  selector: 'mg-video-thumbnail',
  templateUrl: './VideoThumbnail.component.html',
  styleUrls: ['./VideoThumbnail.component.scss'],
})
export class VideoThumbnailComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input()
  videoData: IVideoData = null;

  @Output()
  videoDataChange: EventEmitter<any> = new EventEmitter();

  @Input()
  autoplay: boolean = false;

  @Input()
  maxWidth: number = DEFAULT_VIDEO_MAXWIDTH;

  @Input('processingText')
  set _processingText(text: string) {
    // only replace text if there's actual text available
    if (text) {
      this.processingText = text;
    }
  }

  youtubePlayer;
  videoThumbnail: IVideoThumbnail;
  _videoThumbnailFile: File = null;
  videoThumbnailFormControl;
  videoThumbnailFileUploaded: boolean = false;
  videoPlaying: boolean = false;
  postData;
  originalVideoData: IVideoData = null;
  currentVideoData: IVideoData = null;
  processingText: string =
    'We are still processing your video... check back in a bit';

  @ViewChild('youtubePlayer', { static: true })
  videoContainerEl: ElementRef;
  @ViewChild('thumbnailElement', { static: true })
  thumbnailEl: ElementRef;

  constructor(
    private videoService: VideoService,
    private rootService: RootService,
    private analytics: AnalyticsService,
    private elementRef: ElementRef,
  ) {}

  async ngAfterViewInit() {
    this.originalVideoData = this.videoData;
  }

  ngOnChanges(changes: SimpleChanges) {
    // update on any videoData changes

    const hasSiginificantVideoChange =
      changes.videoData &&
      !_.isEqual(
        changes.videoData.currentValue,
        changes.videoData.previousValue,
      ) &&
      !this.currentVideoData;

    if (hasSiginificantVideoChange) {
      let value = changes.videoData.currentValue;

      let newVideoBool = false;
      // backup to restore any cancelled thumbnails
      if (
        value &&
        (!this.originalVideoData ||
          value.youtubeId != this.originalVideoData.youtubeId)
      ) {
        this.originalVideoData = _.cloneDeep(value);
        newVideoBool = true;
        console.log('[VideoThumbnail] newVideo!');
      }

      this.currentVideoData = value;

      this.handleVideoDataChange(value, newVideoBool);
    }
  }

  ngOnDestroy() {
    this.videoService.destroyYouTubePlayer();
  }

  get videoThumbnailFile() {
    if (Array.isArray(this._videoThumbnailFile)) {
      this._videoThumbnailFile = this._videoThumbnailFile[0];
    }
    return this._videoThumbnailFile;
  }

  get devicePixelRatio() {
    return Math.round(devicePixelRatio);
  }

  playVideo(e) {
    if (!this.videoPlaying) {
      if (this.youtubePlayer) {
        if (e) {
          e.stopImmediatePropagation();
          e.preventDefault();
        }
        this.videoService.playVideo(this.autoplay);
      }
      this._initVideoPlayback();
    }
  }

  pauseVideo(e) {
    if (this.youtubePlayer) {
      if (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
      }
      this.videoPlaying = false;
      this.videoService.pauseVideo();
    }
  }

  private _initVideoPlayback() {
    if (this.videoData.cloudflareUid && !this.videoData.cloudflareReady) {
      return;
    }

    this.videoPlaying = true;
    setTimeout(() => {
      if (this.thumbnailEl) {
        this.thumbnailEl.nativeElement.style.display = 'none';
      }
    }, 300);
  }

  private _getGCD(width, height) {
    if (!width && !height) return 0;

    return height == 0 ? width : this._getGCD(height, width % height);
  }

  private async _calcVideoDataDimensions(
    videoData,
    width = videoData.width,
    height = videoData.height,
  ) {
    let elementWidth =
      this.videoContainerEl.nativeElement.getBoundingClientRect().width;
    width = width != '100%' ? width : elementWidth;

    if (
      height < width / 2 ||
      (videoData.width != '100%' && videoData.width < 640)
    ) {
      height = 0;

      // use thumbnail for dimensions if avail
      let needToFetchData: boolean = true;
      let updateFromThumbnails = async (
        vidData: IVideoData = null,
        attemptedFetch: boolean = false,
      ) => {
        if (vidData) {
          let largestWidth = 0;
          for (let thumbnail of vidData.thumbnails) {
            if (
              thumbnail.height &&
              thumbnail.height > height &&
              thumbnail.width
            ) {
              if (thumbnail.width > largestWidth) {
                height = thumbnail.height;
                largestWidth = width = thumbnail.width;

                if (
                  (needToFetchData && width > VIMEO_MIN_THUMBNAIL_WIDTH) ||
                  vidData.cloudflareVideoUrl
                ) {
                  needToFetchData = false;

                  if (attemptedFetch) {
                    // @TODO: tell server to update video by getting latest
                    // thumbnail data
                    const message = `[VideoThumbnail] calcDimensions() video dimesions need updating for url: ${videoData.url}`;
                    this.analytics.sendException(message);
                    console.error(message, videoData);
                  }
                }
              }

              console.log(
                `[VideoThumbnail] calcDimensions() attempting height of video fixing via thumbnail dimensions`,
              );
            }
          }
        }
        if (needToFetchData && !attemptedFetch && videoData.url) {
          console.log(
            `[VideoThumbnail] no thumbnais were suitable, fetching videoData`,
          );
          const newData = await this.videoService.getVideoDataFromUrl(
            videoData.url,
          );
          return await updateFromThumbnails(newData, true);
        }
      };
      await updateFromThumbnails(videoData);
    }

    let gcd = this._getGCD(width, height);
    if (!gcd) return null;

    let ratioWidth = width / gcd;
    let ratioHeight = height / gcd;

    // width = width > elementWidth ? elementWidth : width;
    width = elementWidth ? elementWidth : width;

    let newHeight = (ratioHeight * width) / ratioWidth;
    newHeight = Math.round(newHeight);

    videoData.width = '100%';
    videoData.height = newHeight;

    return videoData;
  }

  vidThumbFileInput(e) {
    this.videoThumbnailFormControl.setValue(this.videoThumbnailFile);
  }

  private async handleVideoDataChange(
    videoData: IVideoData,
    newVideo = false,
    pause = false,
  ) {
    this._videoThumbnailFile = null;
    this.videoThumbnail = null;

    if (videoData) {
      if (!this.videoPlaying) {
        this.videoThumbnail =
          videoData.thumbnails[videoData.thumbnails.length - 1];

        if (this.thumbnailEl) {
          this.thumbnailEl.nativeElement.style.display = 'block';
        }

        let width = videoData.width || '100%';
        let height = videoData.height || 0;
        await this._calcVideoDataDimensions(videoData, width, height);

        if (videoData.width && videoData.height) {
          this.videoThumbnail.width = videoData.width;
          this.videoThumbnail.height = videoData.height;
        } else if (
          !this.videoThumbnail ||
          this.videoThumbnail.height < DEFAULT_VIDEO_HEIGHT
        ) {
          this.videoThumbnail.height = DEFAULT_VIDEO_HEIGHT;
        }
      }

      if (newVideo) {
        this.videoPlaying = false;

        if (!videoData.cloudflareVideoUrl) {
          this.youtubePlayer = this.videoService.embedFromData(
            this.videoContainerEl.nativeElement,
            videoData,
            this.autoplay,
          );
        }
        if (this.autoplay && !pause) {
          // if(window.MINGA_APP_BROWSER) {
          // this._initVideoPlayback();
          // } else {
          this.playVideo(null);
          // }
        }
      } else if (pause) {
        console.log('handleVideoDataChange pausing');
        this.pauseVideo(null);
      }

      if (this.videoThumbnail.height) {
        this.elementRef.nativeElement.style.setProperty(
          '--mg-video-thumbnail-height',
          this.videoThumbnail.height + 'px',
        );
      }
    }
  }

  async cancelThumbnail(e) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    this._videoThumbnailFile = null;
    // this.videoThumbnail = null;

    // restore the original videoData thumbnail(s)
    if (this.originalVideoData.thumbnails) {
      let original =
        this.originalVideoData.thumbnails[
          this.originalVideoData.thumbnails.length - 1
        ];

      // no original left, go find it from youtube
      if (_.isEqual(this.videoThumbnail, original)) {
        let setToYoutubeThumbnail = result => {
          this.videoData.thumbnails = result.thumbnails;
          this.videoThumbnail = result.thumbnails[length - 1];
          this.handleVideoDataChange(this.videoData);
        };
        if (this.videoData.youtubeId || this.videoData.url) {
          await this.rootService
            .addLoadingPromise(
              this.videoService.getYouTubeData(
                this.videoData.youtubeId,
                this.videoData.url,
              ),
            )
            .then(async result => {
              setToYoutubeThumbnail(result);
            });
        } else {
          this.videoThumbnail = null;
          console.error('cancelThumbnail() no thumbnail found to restore!');
        }
      } else {
        this.videoThumbnail =
          this.originalVideoData.thumbnails[
            this.originalVideoData.thumbnails.length - 1
          ];
        this.videoData.thumbnails = this.originalVideoData.thumbnails;
      }
    } else {
      this.videoThumbnail = null;
      this.videoData.thumbnails = this.originalVideoData.thumbnails;
    }
  }
}
