<mg-short-card
  #shortCard
  *ngIf="content"
  [authorPersonView]="content?.authorPersonView"
  [timestamp]="content?.timestamp"
  [likeCount]="content?.likeCount"
  [pinned]="pinned"
  [markKeywords]="markKeywords"
  [context]="context"
  [gradientOverlap]="content?.gradientOverlap"
  [gradientSize]="content?.gradientSize"
  [lightContent]="content?.lightContent"
  [backgroundColor]="content?.backgroundColor"
  [commentList]="content?.commentList"
  [commentCount]="content?.commentCount"
  [totalCommentCount]="content?.totalCommentCount"
  [pinned]="pinned"
  [commentRoles]="commentRoles"
  [content]="content">
  <mg-short-card-byline></mg-short-card-byline>

  <mg-short-card-featured-image>
    <div class="banner">
      <div
        class="banner-image"
        [class.high-pixel-ratio]="highPixelRatio"></div>
      <div
        class="banner-gradient"
        [ngStyle]="bannerGradientStyle()"></div>
      <div
        class="banner-gradient banner-back-gradient"
        [ngStyle]="bannerGradientStyle()"></div>
    </div>
  </mg-short-card-featured-image>

  <mg-short-card-title>
    <div class="title">
      <div class="birthdate-top">{{birthdateTopLine(content?.birthdate)}}</div>
      <div class="birthdate-btm">
        <div class="birthdate-num">
          {{birthdateBtmNum(content?.birthdate)}}
          <span class="birthdate-num-suffix">
            {{birthdateBtmNumSuffix(content?.birthdate)}}
          </span>
        </div>
      </div>
    </div>
  </mg-short-card-title>

  <mg-short-card-body>
    <div class="birthday-info">
      <div class="birthday-avatars">
        <ng-container *ngFor="let person of displayBirthdayList()">
          <mg-avatar
            [src]="person.profileImageUrl"
            [color]="person.badgeIconColor"
            [personHash]="person.personHash"></mg-avatar>
        </ng-container>
      </div>

      <div class="birthday-names">{{birthdayPersonNames()}}</div>
    </div>
  </mg-short-card-body>
</mg-short-card>
