// spacing system from tailwind
// any changes here should be also reflected in underlying css app/src/styles/_spacing.scss
const spacing = [
  '0',
  '0-half',
  '1',
  '1-half',
  '2',
  '2-half',
  '3',
  '3-half',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '20',
] as const;

export const getCssSpacingUnits = () => spacing;

// used in tadem with app/src/styles/_spacing.scss
export type CssSpacing = typeof spacing[number];
