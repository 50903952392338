import { NgModule } from '@angular/core';

import { FtueVideoComponent } from './FtueVideo.component';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [FtueVideoComponent],
  exports: [FtueVideoComponent],
})
export class FtueVideoModule {}
