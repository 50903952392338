import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MultiSelectRoleModule } from 'minga/app/src/app/roles/components/MultiSelectRole';

import { CommentSettingsSelectionComponent } from './CommentSettingsSelection.component';

@NgModule({
  imports: [
    // Minga dependencies
    MultiSelectRoleModule,

    // External dependencies
    CommonModule,
    FormsModule,
  ],
  declarations: [CommentSettingsSelectionComponent],
  exports: [CommentSettingsSelectionComponent],
})
export class CommentSettingsSelectionModule {}
