import { Directive, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { MingaSearchInfo } from 'minga/domain/minga';
import { MingaManagerService } from 'src/app/services/MingaManager';
import { ChangeMingaAction } from 'src/app/store/root/rootActions';

import { FormSelectOption } from '@shared/components/form';
import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { FormSelectComponent } from '../components/form-select/form-select.component';

/**
 * Minga Switcher Directive
 * Directive is to be used with form-select component
 */
@Directive({
  selector: '[mingaSwitcher]',
  exportAs: 'mingaSwitcher',
})
export class MingaSwitcherDirective implements OnDestroy {
  isLoading$: BehaviorSubject<boolean>;
  private _destroyed$ = new ReplaySubject<void>(1);

  private _newMingaSelected = false;

  constructor(
    private _host: FormSelectComponent,
    private _router: Router,
    private _mingaService: MingaManagerService,
    private _store: Store<any>,
    private _modalService: ModalOverlayService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {
    // search text input observable
    _host.searchInput$
      .pipe(
        takeUntil(this._destroyed$),
        distinctUntilChanged(),
        tap(() => this.isLoading$.next(true)),
        debounceTime(800),
      )
      .subscribe(async value => {
        this._mapAndAssignSearchResults(
          await this._mingaService.searchForMinga(value),
        );
        this.isLoading$.next(false);
      });

    // select menu option selected observable
    _host.valueChanges$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(async mingaHash => {
        if (typeof mingaHash == 'string') {
          await this._openSelectedMinga(mingaHash);
        }
      });

    this._mingaService.onMingaChange
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        if (!this._newMingaSelected) {
          return;
        }

        this._mingaService.showMingaChangeSuccess();
      });

    this.isLoading$ = this._host.isLoading$;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _mapAndAssignSearchResults(searchResults: MingaSearchInfo[]) {
    this._host.options = searchResults.map(item => {
      return {
        label: item.name,
        value: item.hash,
      };
    }) as FormSelectOption<string>[];

    this._host.refreshSelectMenu();
  }

  private async _openSelectedMinga(mingaHash: string) {
    try {
      await this._mingaService.openMinga(mingaHash);
      this._newMingaSelected = true;

      this._store.dispatch(new ChangeMingaAction(mingaHash));
      await this._router.navigate(['/home', { outlets: { o: null } }]);
    } catch (e) {
      this._systemAlertSnackBar.error('Failed to change Minga');
    }
  }
}
