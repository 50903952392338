import { ChangeDetectorRef, Injectable, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';

import * as day from 'dayjs';
import { BehaviorSubject } from 'rxjs';

import { ClientDatePreset } from '@modules/minga-manager/components/mm-date-presets/types/mm-date-presets.types';

import { BottomSheetEventType } from '@shared/components/bottom-sheet/bottom-sheet.types';
import { DatePresetsService } from '@shared/services/date-presets/date-presets.service';

import { initializeRange } from '../form-date-range.utils';
import { FormRangeService } from './form-range.service';

type Data = {
  range?: FormGroup;
};

export type RangeResponseData = {
  type: 'submit' | 'cancel' | BottomSheetEventType;
  data: Data;
};

@Injectable()
export abstract class FormRangeAbstract implements OnInit {
  public range = initializeRange();
  public selectedRange: DateRange<Date> = new DateRange<Date>(null, null);

  private _activePresetSubject = new BehaviorSubject<ClientDatePreset>(null);
  public activePreset$ = this._activePresetSubject.asObservable();

  public minDateSetting: Date;
  public maxDateSetting: Date;

  public abstract onInit(): void;
  public abstract onDone(data: Data): void;
  public abstract onClear(data: Data): void;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _rangeService: FormRangeService,
    private _presetService: DatePresetsService,
  ) {}

  ngOnInit(): void {
    this.onInit();
  }

  public setData(range, minDate, maxDate) {
    if (range) {
      this.range = range;

      const { start, end } = range.value;

      this.selectedRange = new DateRange<Date>(
        start ? start.toDate() : null,
        end ? end.toDate() : null,
      );
    }

    if (minDate) {
      this.minDateSetting = minDate.toDate();
    }

    if (maxDate) {
      this.maxDateSetting = maxDate.toDate();
    }
  }

  public selectPreset(preset: ClientDatePreset) {
    this._updateRange(preset.startDate.toDate(), preset.endDate.toDate());
    this._activePresetSubject.next(preset);
    this._presetService.datePresetChange(preset);
  }

  public dateSelected(start: Date, end: Date) {
    this._updateRange(start, end);
    this._activePresetSubject.next(null);
  }

  private _updateRange(start: Date, end: Date) {
    this.selectedRange = new DateRange<Date>(start, end);
    const updatedRange = {
      start: day(start),
      end: end ? day(end) : null,
    };

    this.range.setValue(updatedRange, {
      emitEvent: false,
    });
    this._rangeService.rangeChange(updatedRange);
    this.range.markAsDirty();
    this._cdr.markForCheck();
  }

  public clear() {
    this.onClear({
      range: this.range,
    });
  }

  public done() {
    this.onDone({
      range: this.range,
    });
  }
}
