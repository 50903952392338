import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';

import * as hp_proto from 'minga/proto/hall_pass/hall_pass_pb';
import * as report_proto from 'minga/proto/stats_report/stats_report_pb';
import { IHallPass, IHallPassSummaryResult } from 'minga/domain/hallPass';
import {
  HpmReportsFilters,
  HpmReportsFilter,
} from 'minga/domain/reportFilters';
import { ReportTypes } from 'minga/domain/reportTypes';
import { HallPassManager } from 'minga/proto/hall_pass/hall_pass_ng_grpc_pb';
import { StatsReportManager } from 'minga/proto/stats_report/stats_report_ng_grpc_pb';
import {
  HallPassMapper,
  HpsExpiredTimeResultMapper,
  HpsPersonMapper,
  HpsSummaryMapper,
} from 'minga/shared-grpc/hall_pass';
import { HallPassFilterMapper } from 'minga/shared-grpc/report_filters';
import { ReportsService } from 'src/app/components/manager-report/services/report-service.service';
import { RootService } from 'src/app/minimal/services/RootService';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  HPM_REPORTS_FILTER_INITIAL_STATE,
  HPM_REPORTS_FILTER_NON_ARRAY_TYPES,
  HPM_REPORTS_FILTER_PERSON_EMITTER_ACCESSORS,
  HpmReportsMessages,
} from '../hpm-reports.constants';

/**
 * Hall Pass Manager Reports Service
 */
@Injectable()
export class HpmReportsService extends ReportsService<HpmReportsFilters> {
  /** Component Constructor */
  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    protected _reportManager: StatsReportManager,
    _rootService: RootService,
    private _hpManager: HallPassManager,
  ) {
    super(
      HPM_REPORTS_FILTER_INITIAL_STATE,
      HPM_REPORTS_FILTER_NON_ARRAY_TYPES,
      HPM_REPORTS_FILTER_PERSON_EMITTER_ACCESSORS,
      _reportManager,
      _rootService,
    );
  }

  /**
   * Get Summary
   */
  public async getSummary(): Promise<IHallPassSummaryResult[]> {
    try {
      const { infos } = await this._getReport(ReportTypes.HALL_PASS_SUMMARY);
      const rows = infos.map(x => HpsSummaryMapper.fromProto(x.getHpSummary()));
      return rows;
    } catch (err) {
      this._handleError(err);
    }
    return [];
  }

  /**
   * Get Creators
   */
  public async getCreators(offset: number = 0, limit = 10, sort?: Sort) {
    try {
      const { infos, pageToken } = await this._getReport(
        ReportTypes.HALL_PASS_STAFF,
        offset,
        limit,
        sort,
      );
      return {
        items: infos.map(x => HpsPersonMapper.fromProto(x.getHpPerson())),
        pageToken,
      };
    } catch (err) {
      this._handleError(err);
    }
    return { items: [], pageToken: 0 };
  }

  /**
   * Get Users
   */
  public async getUsers(offset: number = 0, limit: number = 10, sort?: Sort) {
    try {
      const { infos, pageToken } = await this._getReport(
        ReportTypes.HALL_PASS_STUDENT,
        offset,
        limit,
        sort,
      );
      return {
        items: infos.map(x => HpsPersonMapper.fromProto(x.getHpPerson())),
        pageToken,
      };
    } catch (err) {
      this._handleError(err);
    }
    return { items: [], pageToken: 0 };
  }

  /**
   * Get Users
   */
  public async getLatePassesReport(
    offset: number = 0,
    limit: number = 10,
    sort?: Sort,
  ) {
    try {
      const { infos, pageToken } = await this._getReport(
        ReportTypes.HALL_PASS_OVERDUE,
        offset,
        limit,
        sort,
      );
      return {
        items: infos.map(x =>
          HpsExpiredTimeResultMapper.fromProto(x.getHpPersonExpired()),
        ),
        pageToken,
      };
    } catch (err) {
      this._handleError(err);
    }
    return { items: [], pageToken: 0 };
  }

  /**
   * Get Types
   */
  public async getTypes(offset: number = 0, limit: number = 10, sort?: Sort) {
    try {
      const { infos, pageToken } = await this._getReport(
        ReportTypes.HALL_PASS_TYPES,
        offset,
        limit,
        sort,
      );
      return {
        items: infos.map(x => HpsSummaryMapper.fromProto(x.getHpSummary())),
        pageToken,
      };
    } catch (err) {
      this._handleError(err);
    }
    return { items: [], pageToken: 0 };
  }

  /**
   * Get Report Summary
   *
   * Used by the reports summary component
   */
  public async getReportSummary() {
    const summary = await this.getSummary();
    const reportSummary = {
      summary,
      totalPasses: summary.reduce((acc, curr) => acc + curr.totalPasses, 0),
      totalTime: summary.reduce((acc, curr) => acc + curr.totalTime, 0),
    };
    return reportSummary;
  }

  /**
   * Get Hall Pass History
   */
  public async getHallPassHistory(offset: number, limit: number, sort?: Sort) {
    try {
      const { infos, pageToken } = await this._getReport(
        ReportTypes.HALL_PASS_HISTORY,
        offset,
        limit,
        sort,
      );
      return {
        items: infos.map(x =>
          HallPassMapper.fromProtoEditable(x.getEditableHallPass()),
        ),
        pageToken,
      };
    } catch (err) {
      this._handleError(err);
    }
    return { items: [], pageToken: 0 };
  }

  /**
   * Handle Error
   *
   * @todo extend to be more useful
   */
  private async _handleError(err) {
    this._systemAlertSnackBar.error(HpmReportsMessages.BACKEND_ERROR);
    console.error(err);
  }

  _handleSetFilter(filterName: HpmReportsFilter, value: any) {
    if (!filterName) return;
    switch (filterName) {
      case HpmReportsFilter.CREATED_BY: {
        this.filter.createdBy = value;
        break;
      }
      case HpmReportsFilter.HALLPASS_TYPE: {
        this.filter.hallpassType = value;
        break;
      }
      case HpmReportsFilter.ISSUED_TO: {
        this.filter.issuedTo = value;
        break;
      }
      case HpmReportsFilter.ISSUED_BY: {
        this.filter.issuedBy = value;
        break;
      }
      case HpmReportsFilter.START_DATE: {
        this.filter.startDate = value;
        break;
      }
      case HpmReportsFilter.END_DATE: {
        this.filter.endDate = value;
        break;
      }
      case HpmReportsFilter.USER_LIST: {
        this.filter.userList = value;
        break;
      }
      case HpmReportsFilter.STATUS: {
        this.filter.status = value;
        break;
      }
      case HpmReportsFilter.DENIED_BY: {
        this.filter.deniedBy = value;
        break;
      }
      case HpmReportsFilter.DENIED_BY_BLACKOUT: {
        this.filter.deniedByBlackout = value;
        break;
      }
      case HpmReportsFilter.DENIED_BY_NO_PARTY: {
        this.filter.deniedByNoParty = value;
        break;
      }
      default: {
        break;
      }
    }
  }

  _convertTableDataForFilter(value: any) {
    if (value.person?.personHash) {
      return value.person?.personHash;
    } else if (value.typeId) {
      return value.typeId;
    }
    return value;
  }

  _mapFiltersToFilterMessage(
    reportType: ReportTypes,
    offset?: number,
    limit?: number,
  ) {
    const req = new report_proto.GetOrExportReportRequest();
    const filterProto = HallPassFilterMapper.toProto(
      this.filter,
      limit,
      offset,
    );

    req.setHallPassFilters(filterProto);
    req.setReportType(reportType);
    return req;
  }

  public async archiveHallPass(hallPass: IHallPass | IHallPass[]) {
    let ids: number[] = [];
    if (Array.isArray(hallPass)) {
      ids = hallPass.map(p => p.id);
    } else {
      ids = [hallPass.id];
    }

    const req = new hp_proto.ArchiveHallPassRequest();
    req.setIdsList(ids);
    await this._hpManager.archiveHallPass(req);
  }
}
