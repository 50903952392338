import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { PointsBannerModule } from 'minga/app/src/app/components/Points/PointsBanner';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgOdometerModule } from 'minga/app/src/app/elements/MgOdometer';
import { MgeIconModule } from 'minga/app/src/app/elements/MgeIcon';
import { MgPipesModule } from 'minga/app/src/app/pipes';

import { GenericModule } from '@shared/components/generic';

import { MgShortCardModule } from '../MgShortCard.module';
import { MgPollShortCardElement } from './MgPollShortCard.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgEllipsisModule,
    MgOdometerModule,
    MgeIconModule,
    MgShortCardModule,
    MgPipesModule,
    MgContentLinkModule,
    PointsBannerModule,
    GenericModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [MgPollShortCardElement],
  exports: [MgPollShortCardElement],
})
export class MgPollShortCardModule {
  constructor(injector: Injector) {
    if (!customElements.get('minga-poll-short-card')) {
      //       //   MgPollShortCardElement,
      //   {injector},
      // ));
    }
  }
}
