import { LeaderboardTimeFilterEnum } from 'src/app/services/PointsManager';

export enum LeaderboardMessages {
  LEADERBOARD_TITLE = 'Minga Leaderboard',
  LEADERBOARD_DESC = 'Earn points by staying active on the app. Answer Challenges, Events and Polls to work your way up!',
  MANAGE_POINTS = 'Manage Points',
  TYPE_FILTER_LABEL = 'Select Type',
  PERIOD_FILTER_LABEL = 'Select Period',
  SCHOOLS_POINT_TOTAL = "School's Total Points",
  SETTING_DISABLED = 'Oops! Looks like displaying individual and team points still needs to be enabled in the points manager.',
  EXPORT = 'Export',
}

export const periodSelectOptions = [
  { label: 'This Month', value: LeaderboardTimeFilterEnum.MONTHLY },
  { label: 'This Year', value: LeaderboardTimeFilterEnum.YEARLY },
];
