import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import Mark from 'mark.js';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';

import { IMessageComponentProperties } from 'minga/app/src/app/modules/direct-message/types';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { PeopleFacadeService } from 'src/app/people';

@Component({
  selector: 'mg-dm-conversation-message',
  templateUrl: './dm-conversation-person-message.component.html',
  styleUrls: ['./dm-conversation-person-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DmConversationPersonMessageComponent implements OnDestroy {
  private _personHash$: BehaviorSubject<string>;
  private _messageState$: BehaviorSubject<'read' | 'pending' | ''>;
  private _keywords: string[] = [];
  // @NOTE: using 'any' type here as Mark isn't working as a type here...
  private _markInstance?: any;

  @ViewChild('contentEl', { static: false })
  contentEl?: ElementRef;

  @Input()
  set personHash(personHash: string) {
    this._personHash$.next(personHash || '');
  }

  get personHash() {
    return this._personHash$.getValue();
  }

  @Input()
  set messageState(value: 'read' | 'pending' | '') {
    this._messageState$.next(value || '');
  }

  get messageState() {
    return this._messageState$.getValue();
  }

  @Input()
  set markKeywords(keywords: string[]) {
    this._keywords = keywords || [];
    // update highlighting when keywords change
    this.updateMarkedKeywords();
  }
  get markKeywords() {
    return this._keywords;
  }

  props$: Observable<IMessageComponentProperties>;
  private _messageStyle: string;
  private _messageState: string;
  private _messageAlign: string;

  @HostBinding('class.mg-message-style-received')
  get messageStyleReceived() {
    return this._messageStyle === 'received';
  }

  @HostBinding('class.mg-message-style-sent')
  get messageStyleSent() {
    return this._messageStyle === 'sent';
  }

  @HostBinding('class.mg-message-state-read')
  get messageStateRead() {
    return this._messageState === 'read';
  }

  @HostBinding('class.mg-message-state-pending')
  get messageStatePending() {
    return this._messageState === 'pending';
  }

  @HostBinding('class.mg-align-left')
  get alignedLeft() {
    return this._messageAlign == 'left';
  }

  @HostBinding('class.mg-align-right')
  get alignedRight() {
    return this._messageAlign == 'right';
  }

  constructor(people: PeopleFacadeService, authInfo: AuthInfoService) {
    this._personHash$ = new BehaviorSubject<string>('');
    this._messageState$ = new BehaviorSubject<'read' | 'pending' | ''>('');

    this.props$ = combineLatest(
      authInfo.authPerson$,
      this._personHash$,
      this._messageState$,
    ).pipe(
      switchMap(([authPerson, personHash, messageState]) => {
        return combineLatest(
          of(authPerson),
          of(personHash),
          of(messageState),
          people.getPeople([personHash]),
        );
      }),
      map(([authPerson, personHash, messageState, personArray]) => {
        const person = personArray.length > 0 ? personArray[0] : null;

        const isAuthPerson = authPerson
          ? personHash === authPerson.hash
          : false;
        const props: IMessageComponentProperties = {
          avatarSrc: person ? person.profileImageUrl || '' : '',
          avatarColor: person ? person.badgeIconColor || '' : '',
          hideAvatar: isAuthPerson,
          align: isAuthPerson ? 'right' : 'left',
          messageStyle: isAuthPerson ? 'sent' : 'received',
          messageState,
        };

        return props;
      }),
      tap(props => this._setMessageProps(props)),
    );
  }

  ngOnDestroy() {
    this._personHash$.complete();
  }

  private _setMessageProps(prop: IMessageComponentProperties) {
    this._messageStyle = prop.messageStyle;
    this._messageState = prop.messageState;
    this._messageAlign = prop.align;
  }

  updateMarkedKeywords() {
    if (this._markInstance) {
      this._markInstance.unmark();
    }
    if (this._keywords.length && this.contentEl) {
      this._markInstance = new Mark(this.contentEl.nativeElement);
      this._markInstance.mark(this._keywords, {
        caseSensitive: false,
        accuracy: 'partially',
        className: 'mg-mark-yellow',
      });
    }
  }
}
