import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MgIconModule } from 'minga/app/src/app/icon';
import { ManagerOverlayModule } from 'src/app/shared/components/manager-overlay';

import { CheckinManagerComponent } from './checkin-manager.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgIconModule,
    ManagerOverlayModule,

    // External dependencies
    CommonModule,
    RouterModule,
  ],
  declarations: [CheckinManagerComponent],
  exports: [CheckinManagerComponent],
})
export class CheckinManagerModule {}
