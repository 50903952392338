import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { ReplaySubject } from 'rxjs';

import { DEFAULT_PAGE_SIZE_OPTIONS, PaginatorMessage } from './constants';

@Component({
  selector: 'mg-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnDestroy {
  /** Children */
  @ViewChild(MatPaginator)
  private readonly _paginator: MatPaginator;

  /** Constants */
  public readonly MSG = PaginatorMessage;

  /** General subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Inputs */
  @Input() alignment: 'left' | 'center' | 'right' = 'center';
  @Input() showTooltips = true;
  @Input() showTotalCount = true;
  @Input() canChangePageSize = true;
  @Input() showFirstLastButtons = true;
  @Input() pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;
  @Input() length = 0;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  get matPaginator(): MatPaginator {
    return this._paginator;
  }

  get pageIndex(): number {
    return this._paginator?.pageIndex || 0;
  }

  get pageSize(): number {
    return this._paginator?.pageSize || 0;
  }

  get totalItems(): number {
    return this._paginator?.length || 0;
  }

  get resultsLabel(): string {
    return this.totalItems > 1 ? 'results' : 'result';
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  get isOnFirstPage(): boolean {
    return this.pageIndex === 0;
  }

  get isOnLastPage(): boolean {
    return this.pageIndex === this.totalPages - 1 || this.totalPages === 0;
  }

  /** Component Constructor */
  constructor() {}

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }

  public setPage(pageIndex: number): void {
    this._paginator.pageIndex = pageIndex;
    this._paginator.page.next({
      pageIndex,
      pageSize: this.matPaginator.pageSize,
      length: this.matPaginator.length,
    });
  }

  public setPageSize(pageSize: number): void {
    this._paginator.pageSize = pageSize;
    this._paginator.page.next({
      pageIndex: 0,
      pageSize,
      length: this.matPaginator.length,
    });
  }
}
