export enum BehaviorReportMessages {
  NAME = 'Name, ID number or Email',
  TYPE = 'Behavior Type',
  GRADE = 'Grade',
  LOADING = 'Loading filters...',
  USER_LIST = 'User List',
}

export enum BehaviorReportFields {
  ISSUED_TO = 'issuedTo',
  ISSUED_BY = 'issuedBy',
  TYPE = 'types',
  GRADE = 'grades',
  USER_LIST = 'userList',
}
