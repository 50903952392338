<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="(formState$ | async) === 'loading'"
  [overlayTitle]="
    (isNew$ | async) === true
      ? MESSAGES.MODAL_TITLE_NEW
      : MESSAGES.MODAL_TITLE_EXISTING
  ">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content -->
<ng-template #mainTemplate>
  <main
    class="modal-form"
    #formElement>
    <form
      id="behavior-type"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <fieldset *ngIf="(formState$ | async) === 'invalid'">
          <mg-system-alert
            type="error"
            [message]="MESSAGES.INVALID_FORM_MESSAGE">
          </mg-system-alert>
        </fieldset>
        <fieldset>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <div
              fxLayout="row"
              fxLayoutGap="12px">
              <mg-chip
                [id]="'pbis-behavior-edit-click-praise'"
                [active]="
                  form.get(FORM_FIELD.CATEGORY).value === MSG_CATEGORY.PRAISE
                "
                (pressed)="setCategory(MSG_CATEGORY.PRAISE)">
                {{ MESSAGES.BUTTON_TOGGLE_PRAISE }}
              </mg-chip>
              <mg-chip
                [id]="'pbis-behavior-edit-click-praise'"
                [active]="
                  form.get(FORM_FIELD.CATEGORY).value === MSG_CATEGORY.GUIDANCE
                "
                (pressed)="setCategory(MSG_CATEGORY.GUIDANCE)">
                {{ MESSAGES.BUTTON_TOGGLE_GUIDANCE }}
              </mg-chip>
            </div>

            <mg-form-text-input
              [label]="MESSAGES.FIELD_LABEL_NAME"
              [control]="form.get(FORM_FIELD.NAME)">
            </mg-form-text-input>

            <div
              fxLayout="row"
              fxLayoutAlign="space-between center">
              <mg-text variant="body-md">
                {{ MESSAGES.FIELD_LABEL_ICON }}
              </mg-text>
              <div class="icon-input">
                <mg-form-select
                  appendTo="body"
                  [value]="form.get(FORM_FIELD.ICON).value"
                  [control]="form.get(FORM_FIELD.ICON)"
                  [closeOnSelect]="true"
                  [multiple]="false"
                  [searchable]="false"
                  [condensed]="true"
                  [options]="ICON_OPTIONS">
                  <ng-template let-item="item">
                    <div class="icon-wrap">
                      <mg-icon
                        icon-namespace="minga.behaviors"
                        [style.color]="form.get(FORM_FIELD.ICON_COLOR).value">
                        {{ item.value }}
                      </mg-icon>
                    </div>
                  </ng-template>
                </mg-form-select>
              </div>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center">
              <mg-text variant="body-md">
                {{ MESSAGES.FIELD_LABEL_ICON_COLOR }}
              </mg-text>
              <div class="color-input">
                <mg-form-text-input
                  inputType="color"
                  [condensed]="true"
                  list="colorDatalist"
                  [control]="form.get(FORM_FIELD.ICON_COLOR)">
                </mg-form-text-input>
                <datalist id="colorDatalist">
                  <div *ngFor="let item of PRESET_COLORS">
                    <option>{{ item }}</option>
                  </div>
                </datalist>
              </div>
            </div>

            <div
              fxLayout="row"
              fxLayoutAlign="space-between center">
              <mg-text variant="body-md">
                {{ MESSAGES.FIELD_LABEL_POINTS }}
              </mg-text>
              <div>
                <!-- Points Field -->
                <mg-form-text-input
                  inputType="number"
                  [control]="form.get(FORM_FIELD.POINTS)">
                </mg-form-text-input>
              </div>
            </div>

            <!-- Auto Assign Hall Pass Field -->
            <mg-form-select
              [placeholder]="MESSAGES.FIELD_LABEL_HALL_PASS"
              appendTo="body"
              [value]="form.get(FORM_FIELD.HALL_PASS_ID).value"
              [control]="form.get(FORM_FIELD.HALL_PASS_ID)"
              [closeOnSelect]="true"
              [multiple]="false"
              [searchable]="true"
              [hideSelected]="false"
              [isClearable]="true"
              [options]="hallPassTypesOptions$ | async"
              [disabled]="(hasHpEnabled$ | async) === false">
            </mg-form-select>
          </div>
        </fieldset>

        <hr class="fieldset-seperator" />

        <!-- Restriction -->
        <fieldset>
          <legend>
            <mg-text variant="header-sm-secondary">
              {{ MESSAGES.FIELDSET_RESTRICTION_LABEL }}
            </mg-text>
          </legend>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <mg-form-restriction-input
              [id]="'pbis-behavior-edit-click-can-assign'"
              [label]="MESSAGES.TOGGLE_RESTRICTION_LABEL"
              [selectableTypes]="[
                RESTRICTION_TABS.Role,
                RESTRICTION_TABS.Lists,
                RESTRICTION_TABS.Groups,
                RESTRICTION_TABS.Grades,
                RESTRICTION_TABS.MembershipList
              ]"
              [membershipListType]="MEMBERSHIP_LIST_TYPE.BEHAVIOR_TYPE"
              [value]="form.get(FORM_FIELD.RESTRICTIONS).value"
              [control]="form.get(FORM_FIELD.RESTRICTIONS)">
            </mg-form-restriction-input>
          </div>
        </fieldset>

        <hr class="fieldset-seperator" />

        <!-- Notification -->
        <fieldset>
          <legend>
            <mg-text variant="header-sm-secondary">
              {{ MESSAGES.FIELDSET_NOTIFICATION_LABEL }}
            </mg-text>
          </legend>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <div>
              <mat-slide-toggle
                [attr.data-analytics]="'pbis-behavior-edit-click-parent-email'"
                [formControlName]="FORM_FIELD.PARENT_EMAIL">
                {{ MESSAGES.PARENT_EMAIL_MSG }}
              </mat-slide-toggle>
              <div
                [fxHide]="!form.get(FORM_FIELD.PARENT_EMAIL).value"
                class="top-padding">
                <div
                  fxLayout="column"
                  fxLayoutAlign="start stretch"
                  fxLayoutGap="12px">
                  <mg-form-text-input
                    [label]="MESSAGES.FIELD_LABEL_PARENT_EMAIL_SUBJECT"
                    [control]="form.get(FORM_FIELD.PARENT_EMAIL_SUBJECT)">
                  </mg-form-text-input>
                  <mg-body-input
                    name="emailBody"
                    [placeholder]="MESSAGES.FIELD_LABEL_PARENT_EMAIL_BODY"
                    requiredMessage="A description is required"
                    minHeight="100px"
                    [maxlength]="5000"
                    [customToolbar]="true"
                    [customElement]="toolbarData"
                    [richContent]="true"
                    [formControl]="$any(form.get(FORM_FIELD.PARENT_EMAIL_BODY))"
                    resetParentScrollbarElement=".modal-overlay-body">
                  </mg-body-input>
                  <pre class="instructions">{{
                    MESSAGES.QUILL_EDITOR_INSTRUCTIONS
                  }}</pre>
                </div>
              </div>
            </div>
            <div>
              <mat-slide-toggle
                [attr.data-analytics]="'pbis-behavior-edit-click-parent-sms'"
                [disabled]="(hasSmsEnabled$ | async) === false"
                [formControlName]="FORM_FIELD.PARENT_SMS">
                {{ MESSAGES.PARENT_SMS_MSG }}
                <mg-tooltip
                  *ngIf="(hasSmsEnabled$ | async) === false"
                  [label]="MESSAGES.PARENT_SMS_MSG_TOOLTIP">
                </mg-tooltip>
              </mat-slide-toggle>
              <div
                *ngIf="form.get(FORM_FIELD.PARENT_SMS).value"
                class="top-padding">
                <mg-form-text-input
                  [label]="MESSAGES.FIELD_LABEL_PARENT_SMS"
                  [control]="form.get(FORM_FIELD.PARENT_SMS_TEMPLATE)">
                </mg-form-text-input>
              </div>
            </div>
            <div>
              <mat-slide-toggle
                [attr.data-analytics]="'pbis-behavior-edit-click-admin-email'"
                [formControlName]="FORM_FIELD.SEND_ADMIN_EMAIL">
                {{ MESSAGES.ADMIN_EMAIL_MSG }}
              </mat-slide-toggle>
            </div>

            <!-- Admin Email Field -->
            <div [fxHide]="!form.get(FORM_FIELD.SEND_ADMIN_EMAIL).value">
              <mg-form-select
                [placeholder]="MESSAGES.FIELD_LABEL_ADMIN_EMAIL"
                appendTo="body"
                [value]="form.get(FORM_FIELD.ADMIN_EMAIL).value"
                [closeOnSelect]="true"
                [multiple]="true"
                [addTag]="addNew"
                [addTagText]="'Add email'"
                [searchable]="true"
                [hideSelected]="false"
                [options]="emailOptions"
                (selectionChanged)="onAdminEmailChange($event)">
              </mg-form-select>
              <mg-form-error *ngIf="form.get(FORM_FIELD.ADMIN_EMAIL).errors">
                {{
                  form?.get(FORM_FIELD.ADMIN_EMAIL).errors
                    | makeInputErrorMessage
                }}
              </mg-form-error>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  </main>
</ng-template>

<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        [id]="
          (isNew$ | async) === true
            ? 'pbis-behavior-edit-click-cancel'
            : 'pbis-behavior-edit-click-delete'
        "
        [variant]="(isNew$ | async) === true ? 'text' : 'destructive'"
        (pressed)="confirmDelete()">
        {{ deleteLabel }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="behavior-type"
        variant="filled"
        [id]="
          (isNew$ | async) === true
            ? 'pbis-behavior-edit-click-create'
            : 'pbis-behavior-edit-click-save'
        "
        [disabled]="!canSubmit">
        {{ submitLabel }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
