<div class="ios-notch-offset"></div>

<!-- Global loading overlay -->
<mg-loader-overlay *ngIf="rootService.isLoading"></mg-loader-overlay>

<!-- Global floating portals / (deprecated) -->
<ng-container
  *ngFor="let portal of globalFloatingPortals$ | async; let i = index">
  <div
    class="global-floating-portal"
    [style.z-index]="globalFloatingPortalBaseZindex + i">
    <ng-template [cdkPortalOutlet]="initPortalOutlet(portal)"></ng-template>
  </div>
</ng-container>

<!-- Old modal container -->
<mg-modal-container-wrap
  [class.opened]="modalContainer.isOpen()"
  [style.background-color]="modalBgColor$ | async">
  <div
    #modalContainer="mgModalContainer"
    mgModalContainer></div>
</mg-modal-container-wrap>

<!-- Second modal container -->
<mg-overlay
  [style.display]="overlayOutlet.isActivated ? '' : 'none'"
  (overlayClose)="overlayOutlet.onOverlayClose()"
  (overlayNext)="overlayOutlet.onOverlayNext($event)"
  (overlayPrevious)="overlayOutlet.onOverlayPrevious($event)"
  [overlayComponent]="activeOverlayComponent"
  [overlayActive]="overlayOutlet.isActivated"
  [overlayConfig]="overlayOutlet.currentOverlayConfig"
  [overlayNextInfo]="overlayOutlet.overlayNext"
  [overlayPreviousInfo]="overlayOutlet.overlayPrevious">
  <router-outlet
    name="o"
    #overlayOutlet="mgOverlayRouterOutlet"
    mgOverlayRouterOutlet
    (activate)="activateOverlay($event)"
    (deactivate)="deactivateOverlay($event)">
  </router-outlet>
</mg-overlay>

<!-- New modal routing -->
<router-outlet name="modal"></router-outlet>

<!-- Leaving app dialog -->
<mg-dialog #externalLinkDialog>
  <mg-dialog-title>{{ MSG.EXTERNAL_LINK_MODAL_TITLE }}</mg-dialog-title>
  <mg-dialog-content>{{ MSG.EXTERNAL_LINK_MODAL_MESSAGE }}</mg-dialog-content>
  <mg-button
    [mg-dialog-action]="false"
    class="normal"
    type="text">
    {{ MSG.EXTERNAL_LINK_MODAL_BUTTON_CANCEL }}
  </mg-button>
  <mg-button
    [mg-dialog-action]="true"
    class="normal"
    type="outline">
    {{ MSG.EXTERNAL_LINK_MODAL_BUTTON_CONFIRM }}
  </mg-button>
</mg-dialog>

<!-- Default / main routing outlet -->
<div
  #primaryOutletWrap
  class="primary-outlet-wrap">
  <mg-layout [overlayIsActive]="overlayOutlet?.isActivated"></mg-layout>
</div>
