<!-- Angular mat paginator -->
<mat-paginator
  [pageSizeOptions]="pageSizeOptions"
  [length]="length"></mat-paginator>

<!-- Controls -->
<div
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="12px">
  <!-- Total results -->
  <mg-text
    *ngIf="showTotalCount"
    variant="body-xs">
    {{ totalItems }} {{ resultsLabel }}
  </mg-text>
  <!-- Page buttons -->
  <ng-container *ngTemplateOutlet="pageButtonsTemplate"></ng-container>
  <!-- Page size -->
  <ng-container
    *ngIf="canChangePageSize"
    [ngTemplateOutlet]="pageSizeSelectorTemplate">
  </ng-container>
</div>

<!-- Page size selector template -->
<ng-template #pageSizeSelectorTemplate>
  <div>
    <mg-btn
      variant="text"
      size="small"
      iconSet="material"
      iconRight="keyboard_arrow_down"
      [matMenuTriggerFor]="dropdownMenu">
      {{ pageSize }} per page
    </mg-btn>
  </div>
</ng-template>

<!-- Page size selector dropdown menu -->
<mat-menu
  #dropdownMenu="matMenu"
  xPosition="before">
  <ng-container *ngFor="let option of pageSizeOptions">
    <button
      mat-menu-item
      (click)="setPageSize(option)">
      {{ option }}
    </button>
  </ng-container>
</mat-menu>

<!-- Page buttons template -->
<ng-template #pageButtonsTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="page-buttons-container test"
    [attr.data-analytics]="id"
    [attr.data-test]="id">
    <!-- First page -->
    <mg-btn
      *ngIf="showFirstLastButtons"
      variant="icon"
      iconSet="material"
      icon="first_page"
      class="smaller-icon-button"
      [tooltip]="
        !isOnFirstPage ? showTooltips && MSG.BUTTON_FIRST_PAGE : undefined
      "
      [disabled]="isOnFirstPage"
      (pressed)="matPaginator.firstPage()">
    </mg-btn>
    <!-- Previous page -->
    <mg-btn
      variant="icon"
      iconSet="material"
      icon="keyboard_arrow_left"
      class="smaller-icon-button"
      [tooltip]="
        !(pageIndex === 0)
          ? showTooltips && MSG.BUTTON_PREVIOUS_PAGE
          : undefined
      "
      [disabled]="pageIndex === 0"
      (pressed)="matPaginator.previousPage()">
    </mg-btn>
    <div *ngIf="totalPages | pageButtons: pageIndex as buttonData">
      <span *ngIf="buttonData.ellipsis.starting">&hellip;</span>
      <button
        *ngFor="let item of buttonData.buttons; let i = index"
        class="page-button"
        [class.active]="item === pageIndex"
        (click)="setPage(item)">
        {{ item + 1 }}
      </button>
      <span *ngIf="buttonData.ellipsis.ending">&hellip;</span>
    </div>
    <!-- Next page -->
    <mg-btn
      variant="icon"
      iconSet="material"
      icon="keyboard_arrow_right"
      class="smaller-icon-button"
      [tooltip]="
        !isOnLastPage ? showTooltips && MSG.BUTTON_NEXT_PAGE : undefined
      "
      [disabled]="isOnLastPage"
      (pressed)="matPaginator.nextPage()">
    </mg-btn>
    <!-- Last page -->
    <mg-btn
      *ngIf="showFirstLastButtons"
      variant="icon"
      iconSet="material"
      icon="last_page"
      class="smaller-icon-button"
      [tooltip]="
        !isOnLastPage ? showTooltips && MSG.BUTTON_LAST_PAGE : undefined
      "
      [disabled]="isOnLastPage"
      (pressed)="matPaginator.lastPage()">
    </mg-btn>
  </div>
</ng-template>
