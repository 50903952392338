<h1 class="mg-headline mg-centered">Content Debug</h1>

<table *ngIf="contentMetadata as contentMetadata">
  <tr>
    <td>Type String</td>
    <td>
      <strong>{{ contentMetadata.typeString }}</strong>
    </td>
  </tr>
  <tr>
    <td>Content Context Hash</td>
    <td>
      <strong>{{ contentContextHash }}</strong>
    </td>
  </tr>
  <tr>
    <td>Content Hash</td>
    <td>
      <strong>{{ contentMetadata.contentHash }}</strong>
    </td>
  </tr>
  <tr>
    <td>Author Person Hash</td>
    <td>
      <strong>{{ contentMetadata.authorPersonHash || 'N/A' }}</strong>
    </td>
  </tr>
  <tr>
    <td>Owner Minga Hash</td>
    <td>
      <strong>{{ contentMetadata.ownerMingaHash || 'N/A' }}</strong>
    </td>
  </tr>
  <tr>
    <td>Owner Group Hash</td>
    <td>
      <strong>{{ contentMetadata.ownerGroupHash || 'N/A' }}</strong>
    </td>
  </tr>
  <tr>
    <td>Program Context Hash</td>
    <td>
      <strong>{{ contentMetadata.programContextHash || 'N/A' }}</strong>
    </td>
  </tr>
  <tr>
    <td>Publish Date</td>
    <td>
      <strong>{{ formatTimestamp(contentMetadata.publishTimestamp) }}</strong>
    </td>
  </tr>
  <tr>
    <td>Unpublish Date</td>
    <td>
      <strong>{{ formatTimestamp(contentMetadata.unpublishTimestamp) }}</strong>
    </td>
  </tr>
  <tr>
    <td>Pin End Date</td>
    <td>
      <strong>{{ formatTimestamp(contentMetadata.pinEndTimestamp) }}</strong>
    </td>
  </tr>
  <tr>
    <td>Studio Content</td>
    <td>
      <strong>{{ contentMetadata.mingaDesignerContent ? 'YES' : 'NO' }}</strong>
    </td>
  </tr>
</table>

<p>
  <mg-button
    [attr.tooltiptext]="'Republish all content with this content hash'"
    [disabled]="republishing"
    (click)="republish()"
    >Republish</mg-button
  >
  <mg-button
    [attr.tooltiptext]="
      contentContextHash
        ? 'Republish just this one content context'
        : 'Cannot republish one without a content context hash'
    "
    [disabled]="republishing || !contentContextHash"
    (click)="republishOne()"
    >Republish One</mg-button
  >
</p>
