<mg-overlay-primary
  size="auto"
  [isLoading]="(formState$ | async) === 'loading'"
  [overlayTitle]="MESSAGES.RECORD_EDIT_LABEL">
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<ng-template #content>
  <main
    class="modal-form"
    #crudForm>
    <!-- Loading -->
    <div
      *ngIf="(formState$ | async) === 'loading'"
      class="loading-container">
      <mg-spinner
        [diameter]="40"
        [thickness]="3">
      </mg-spinner>
    </div>

    <!-- Form -->
    <form
      *ngIf="(formState$ | async) !== 'loading'"
      id="hp-reports-edit-form"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <fieldset *ngIf="(formState$ | async) === 'invalid'">
          <mg-system-alert
            type="error"
            [message]="MESSAGES.RECORD_EDIT_FORM_ERROR">
          </mg-system-alert>
        </fieldset>
        <fieldset
          fxLayout="column"
          fxLayoutGap="12px">
          <mg-form-select
            appendTo="body"
            [control]="form.get(FORM_FIELDS.TYPE)"
            [closeOnSelect]="true"
            [multiple]="false"
            [searchable]="true"
            [hideSelected]="false"
            [options]="consTypesOptions$ | async"
            [placeholder]="MESSAGES.CONSEQUENCE_EDIT_CONSEQUENCE"
            [returnMode]="'full'"
            (selectionChanged)="updateTypeName($event)">
          </mg-form-select>
          <mg-form-text-input
            [control]="form.get(FORM_FIELDS.NOTE)"
            [placeholder]="MESSAGES.CONSEQUENCE_EDIT_NOTE">
          </mg-form-text-input>
          <div
            class="date-time-row"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="16px">
            <mg-form-date
              class="half-width"
              [control]="form.get(FORM_FIELDS.ASSIGN_DATE)"
              [isClearable]="true"
              [label]="MESSAGES.CONSEQUENCE_EDIT_ASSIGN_DATE">
            </mg-form-date>
            <mg-form-text-input
              class="half-width"
              inputType="time"
              [step]="60"
              [control]="form.get(FORM_FIELDS.ASSIGN_TIME)"
              [label]="MESSAGES.CONSEQUENCE_EDIT_ASSIGN_TIME">
            </mg-form-text-input>
          </div>
          <div
            class="date-time-row"
            fxLayout="row"
            fxLayoutAlign="space-between start"
            fxLayoutGap="16px">
            <mg-form-date
              class="half-width"
              [control]="form.controls[FORM_FIELDS.COMPLETED_DATE]"
              [isClearable]="true"
              [label]="MESSAGES.CONSEQUENCE_EDIT_COMPLETED_DATE">
            </mg-form-date>
            <div class="half-width">
              <mg-form-text-input
                inputType="time"
                [step]="60"
                [isClearable]="true"
                [control]="form.get(FORM_FIELDS.COMPLETED_TIME)"
                [label]="MESSAGES.CONSEQUENCE_EDIT_COMPLETED_TIME"
                [externalError]="
                  form.dirty && form.getError('invalidTimeComparison')
                ">
              </mg-form-text-input>
              <mg-form-error
                *ngIf="form.dirty && form.getError('invalidTimeComparison')">
                {{ form?.errors | makeInputErrorMessage }}
              </mg-form-error>
            </div>
          </div>
          <div
            class="date-time-row"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="16px">
            <mg-form-date
              class="half-width"
              [control]="form.get(FORM_FIELDS.COMPLETE_BY_DATE)"
              [label]="MESSAGES.CONSEQUENCE_EDIT_COMPLETE_BY_DATE"
              [makeEndOfDay]="true">
            </mg-form-date>
          </div>
        </fieldset>
      </div>
    </form>
  </main>
</ng-template>

<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        variant="text"
        (pressed)="cancel()">
        {{ cancelLabel }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="hp-record"
        variant="filled"
        [disabled]="!canSubmit"
        (pressed)="submit()">
        {{ submitLabel }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
