import { Observable } from 'rxjs';

/**
 * Status of permission.
 *   'granted'    - The user has given permission already
 *   'data_only'  - Only data messages are permitted
 *   'denied'     - The user has explicitly denied permission
 *   'unknown'    - The permission state is unknown
 */
export type FirebaseMessagingPermissionStatus =
  | 'granted'
  | 'data_only'
  | 'denied'
  | 'unknown';

export interface IFirebaseMessage {
  /**
   * Type of message
   *   'notification' - Received a firebase notification
   *   'data' - Received a data message
   */
  type: 'notification' | 'data';

  /**
   * User defined data that was directly sent along with this message.
   */
  data: { [keyname: string]: any };

  /**
   * Extra fields sent by firebase and/or the firebase messaging implementation
   */
  [keyname: string]: any;
}

export interface IFirebaseNotificationMessage extends IFirebaseMessage {
  type: 'notification';
  notification: {
    /**
     * Notification title (available on all platforms)
     */
    title: string;

    /**
     * Notification body (available on all platforms)
     */
    body: string;

    /**
     * Extra platform specific notification fields
     */
    [keyname: string]: any;
  };
}

export interface IFirebaseDataMessage extends IFirebaseMessage {
  type: 'data';
  notification?: undefined;
}

export type FirebaseMessage =
  | IFirebaseNotificationMessage
  | IFirebaseDataMessage;

export abstract class FirebaseMessaging {
  /**
   * Name used for support/debugging
   */
  abstract readonly name: string;

  /**
   * Long standing observable for receiving new
   */
  abstract readonly messageReceived$: Observable<FirebaseMessage>;

  /**
   * Lonig standing observable that is triggered everytime there was a firebase
   * token refresh. You must still call `getToken()` to get the new token.
   */
  abstract readonly tokenRefresh$: Observable<void>;

  /**
   * Long standing observable that gets triggered when a notification is
   * clicked/tapped. When subscribing to this observable it will always emit
   * at least one _if_ there was a notification click prior and
   * `clearLastNotificationClicked()` was NOT called after a notification click
   */
  abstract readonly notificationClicked$: Observable<IFirebaseNotificationMessage>;

  /**
   * Initialize firebase messaging
   */
  abstract init(): Promise<void>;

  /**
   * Removes the last clicked firebase notification from `notificationClicked$`
   * so that when there is a new subscription to `notificationClicked$` there
   * will not be an immediate emission.
   */
  abstract clearLastNotificationClicked(): void;

  /**
   * Delete the current token returned from `getToken()` unregistering the
   * token from receiving more push notifications.
   *
   * @NOTE Calling `getToken()` after calling `deleteCurrentToken()` will
   *       register a new one.
   */
  abstract deleteCurrentToken(): Promise<void>;

  /**
   * Get firebase token for device
   */
  abstract getToken(): Promise<string>;

  /**
   * Get a unique id representing this device
   */
  abstract getDeviceId(): Promise<string>;

  /**
   * Request permission to use firebase messaging. If the current permission
   * status is 'granted' this is a noop.
   */
  abstract requestPermission(): Promise<FirebaseMessagingPermissionStatus>;

  /**
   * Get the current permission status
   */
  abstract getPermissionStatus(): Promise<FirebaseMessagingPermissionStatus>;
}
