import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { BreadcrumbService } from '@modules/layout/services/breadcrumb.service';

@Component({
  selector: 'mg-layout-secondary-breadcrumb',
  templateUrl: './layout-secondary-breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutSecondaryBreadcrumbComponent implements OnDestroy {
  constructor(public breadcrumbService: BreadcrumbService) {}

  ngOnDestroy(): void {
    this.breadcrumbService.resetBreadcrumb();
  }
}
