import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[mgSortHeader]',
})
export class SortHeaderDirective implements AfterViewInit, OnChanges {
  @Input() activeColumnKey: string;
  @Input() sortable = true;

  private _svg: any;
  private _path1: any;
  private _path2: any;

  @HostListener('click') checkIfClicked() {
    setTimeout(() => {
      const sortDirection = this._el.nativeElement.getAttribute('aria-sort');
      if (sortDirection) this._updateStyles(sortDirection);
    }, 0);
  }

  constructor(private _el: ElementRef, private _renderer: Renderer2) {
    this._svg = this._renderer.createElement('svg', 'svg');
    this._renderer.setAttribute(this._svg, 'width', '10');
    this._renderer.setAttribute(this._svg, 'height', '16');
    this._renderer.setAttribute(this._svg, 'viewBox', '0 0 10 16');
    this._renderer.setAttribute(this._svg, 'fill', 'none');
    this._renderer.setAttribute(
      this._svg,
      'xmlns',
      'http://www.w3.org/2000/svg',
    );

    this._path1 = this._renderer.createElement('path', 'svg');
    this._renderer.setAttribute(this._path1, 'fill', '#74777F');
    this._renderer.setAttribute(
      this._path1,
      'd',
      'M0.629395 4.99999L5.0332 0.596191L9.43699 4.99999H0.629395Z',
    );

    this._path2 = this._renderer.createElement('path', 'svg');
    this._renderer.setAttribute(this._path2, 'fill', '#74777F');
    this._renderer.setAttribute(
      this._path2,
      'd',
      'M4.96679 15.4038L0.562988 11H9.37059L4.96679 15.4038Z',
    );

    this._renderer.appendChild(this._svg, this._path1);
    this._renderer.appendChild(this._svg, this._path2);
  }

  ngAfterViewInit(): void {
    const parentDiv = this._el.nativeElement;
    const arrowDiv = parentDiv.querySelector('.mat-sort-header-arrow');

    // remain as hidden so header is still aligned properly
    if (arrowDiv && !this.sortable) {
      arrowDiv.style.visibility = 'hidden';
      return;
    }

    if (arrowDiv) {
      this._renderer.removeChild(parentDiv, arrowDiv);
      this._renderer.appendChild(parentDiv, this._svg);
    }

    const sortDirection = this._el.nativeElement.getAttribute('aria-sort');
    if (sortDirection) this._updateStyles(sortDirection);
  }

  ngOnChanges(): void {
    const columnKey = this._el.nativeElement.getAttribute('ng-reflect-id');
    if (columnKey !== this.activeColumnKey) this._updateStyles('');
  }

  private _updateStyles(sortDirection: string) {
    switch (sortDirection) {
      case 'ascending':
        this._renderer.setAttribute(this._path1, 'fill', '#0070E0');
        this._renderer.setAttribute(this._path2, 'fill', '#74777F');
        break;
      case 'descending':
        this._renderer.setAttribute(this._path1, 'fill', '#74777F');
        this._renderer.setAttribute(this._path2, 'fill', '#0070E0');
        break;
      default:
        this._renderer.setAttribute(this._path1, 'fill', '#74777F');
        this._renderer.setAttribute(this._path2, 'fill', '#74777F');
        break;
    }
  }
}
