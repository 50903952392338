import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { PersonViewMinimal } from 'minga/proto/gateway/person_view_pb';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';

@Component({
  selector: 'mg-sidenav',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./SideNav.component.scss'],
  templateUrl: `SideNav.component.html`,
})
export class SideNavComponent implements OnChanges, OnDestroy {
  mingaName: string = '';
  mingaLogo: string = '';
  person?: PersonViewMinimal.AsObject;
  mobileFlag: boolean = !!window.MINGA_DEVICE;
  private _hmjs: any;

  readonly person$: Observable<PersonViewMinimal.AsObject>;

  @Input()
  disabled: boolean = true;

  @Input()
  mqAlias: string = '';

  @Input()
  open: boolean = false;

  @Output()
  openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('sidenav', { static: false })
  sidenav?: MatSidenav;

  @ViewChild('navDrawerEl', { static: false })
  navDrawerEl?: ElementRef;

  get fixed() {
    return this.mqAlias == 'xs' || this.mqAlias == 'sm';
  }

  get mode() {
    return this.mqAlias == 'xs' || this.mqAlias == 'sm' ? 'over' : 'side';
  }

  constructor(private authInfo: AuthInfoService) {
    this.person$ = this.authInfo.authInfo$.pipe(
      filter(info => !!info),
      map(info => {
        return info.person;
      }),
    );
  }

  onSwipeRight() {
    if (this.fixed) {
      this.onOpenedChange(false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // when switching back to smaller display size, close the sidenav
    if (
      changes.mqAlias &&
      (changes.mqAlias.currentValue == 'xs' ||
        changes.mqAlias.currentValue == 'sm')
    ) {
      this.open = false;
    }
  }

  ngOnDestroy() {
    if (this._hmjs) {
      this._hmjs.destroy();
    }
  }

  escapeKeyCloseNav() {
    // don't close when used as a sidenav in fixed position
    if (!this.fixed) return;

    this.closeSideNav(null);
  }

  closeSideNav(ev: any) {
    if (this.sidenav) {
      this.sidenav.close();
    } else {
      console.error('#sidenav not set');
    }
  }

  onOpenedChange(ev: boolean) {
    this.openChange.emit(ev);
  }
}
