import { NgModule } from '@angular/core';
import {
  AnnouncementLongCardComponent,
  AnnouncementLongCardModule,
} from 'minga/app/src/app/content-views/components/AnnouncementLongCard';
import {
  BirthdayLongCardComponent,
  BirthdayLongCardModule,
} from 'minga/app/src/app/content-views/components/BirthdayLongCard';
import {
  ChallengeLongCardComponent,
  ChallengeLongCardModule,
} from 'minga/app/src/app/content-views/components/ChallengeLongCard';
import {
  ChallengeResponseLongCardComponent,
  ChallengeResponseLongCardModule,
} from 'minga/app/src/app/content-views/components/ChallengeResponseLongCard';
import {
  CommentViewComponent,
  CommentViewModule,
} from 'minga/app/src/app/content-views/components/CommentView';
import {
  ContentUnavailableComponent,
  ContentUnavailableModule,
} from 'minga/app/src/app/content-views/components/ContentUnavailable';
import {
  ContentViewComponent,
  ContentViewModule,
} from 'minga/app/src/app/content-views/components/ContentView';
import {
  EventLongCardComponent,
  EventLongCardModule,
} from 'minga/app/src/app/content-views/components/EventLongCard';
import {
  InspirationLongCardComponent,
  InspirationLongCardModule,
} from 'minga/app/src/app/content-views/components/InspirationLongCard';
import {
  LongCardComponent,
  LongCardModule,
} from 'minga/app/src/app/content-views/components/LongCard';
import {
  PollLongCardComponent,
  PollLongCardModule,
} from 'minga/app/src/app/content-views/components/PollLongCard';

@NgModule({
  exports: [
    AnnouncementLongCardModule,
    BirthdayLongCardModule,
    CommentViewModule,
    ContentUnavailableModule,
    ContentViewModule,
    EventLongCardModule,
    InspirationLongCardModule,
    PollLongCardModule,
    LongCardModule,
    ChallengeLongCardModule,
    ChallengeResponseLongCardModule,
  ],
})
export class ContentViewsModule {}
