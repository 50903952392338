import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';
import { ListMembershipService } from 'src/app/services/ListMembership';

import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog';
import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import {
  HpmRestrictionsEditMessages,
  RESTRICT_LIST_TYPE_PRETTY_NAME,
} from '../../constants';
import { HpmRestrictionsService } from '../../services';
import {
  HpmRestrictionsEditModalData,
  HpmRestrictionsEditModalResponse,
  HpmRestrictionsListType,
} from '../../types';
import { getNewHpmRestrictionListState } from '../../utils';

@Component({
  selector: 'mg-hpm-restrictions-edit',
  templateUrl: './hpm-restrictions-edit.component.html',
  styleUrls: ['./hpm-restrictions-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmRestrictionsEditComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = HpmRestrictionsEditMessages;
  public readonly LIST_TYPES = MembershipListType;
  public readonly MODAL_CONFIG = {
    headerBg: ModalOverlayPrimaryHeaderBackground.PINK,
  };

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);
  private _isLoading$ = new BehaviorSubject<boolean>(true);
  public readonly isLoading$ = this._isLoading$.asObservable();

  /** List Info */
  private _listSubj: BehaviorSubject<IMembershipList | null> =
    new BehaviorSubject(null);
  public list$: Observable<IMembershipList>;
  private _list: IMembershipList;

  public get list() {
    return { ...this._list };
  }
  public isNewList$: Observable<boolean>;
  private _isNewList: boolean;

  public modalTitle = this.MESSAGES.MODAL_TITLE as string;

  /** Form Controls */
  name = new FormControl('', [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(65),
  ]);
  public get canSubmit() {
    if (this._list?.listType !== this.LIST_TYPES.NO_PARTY) {
      return true;
    } else {
      return this.name.valid;
    }
  }

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA) public dialogData: HpmRestrictionsEditModalData,
    private _modalOverlay: ModalOverlayRef<
      HpmRestrictionsEditModalResponse,
      HpmRestrictionsEditModalData
    >,
    private _restrictionsService: HpmRestrictionsService,
    private _listService: ListMembershipService,
    private _dialog: MatDialog,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
  ) {
    this.isNewList$ = of(dialogData.isNewList ? true : false);
    this.isNewList$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(val => (this._isNewList = val));
    const name$ = this.name.valueChanges.pipe(startWith(''));
    const listSub$ = this._listSubj.asObservable();
    this.list$ = combineLatest([listSub$, name$]).pipe(
      takeUntil(this._destroyed$),
      filter(([list, name]) => !!list),
      map(([list, name]) => {
        // set list name to value from formcontrol
        list.name = this.name.value;
        this._list = list;
        return list;
      }),
      tap(list => {
        if (!!list.id) {
          this.modalTitle = `Edit ${
            RESTRICT_LIST_TYPE_PRETTY_NAME[list.listType]
          }`;
        }
      }),
    );

    this._getList();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._isLoading$.complete();
    this._destroyed$.complete();
  }

  public async submit(): Promise<void> {
    if (
      this._restrictionsService.PASSLISTS.includes(
        this._list.listType as HpmRestrictionsListType,
      ) ||
      (this.name.pristine && this._list.id)
    ) {
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
      return;
    }
    if (!this.canSubmit) return;
    if (!this._list?.id) {
      this._restrictionsService.createList(
        this.name.value,
        this._list.listType as HpmRestrictionsListType,
      );
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.CREATE);
      return;
    } else {
      this._restrictionsService.updateList({
        ...this._list,
        name: this.name.value,
      });
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.SUBMIT);
    }
  }

  public async onMembershipListChange(list?: any): Promise<void> {
    this._list = list.membershipList;
    this._listSubj.next(list.membershipList);
    // adding people caused the list to already be created, so
    // now it's like we are editing it.
    if (this._isNewList) {
      this.name.markAsPristine();
      this._isNewList = false;
    }
    this._restrictionsService.refetchList(
      this._list.listType as HpmRestrictionsListType,
    );
  }

  public async deleteList(): Promise<void> {
    const confirmationDialog = this._dialog.open(ConfirmationDialogComponent, {
      data: {
        text: {
          description: this._isNewList
            ? `Are you sure you want to discard this new list?`
            : `Are you sure you want to delete this list?`,
          deleteBtn: 'Delete',
        },
      },
    });
    confirmationDialog.afterClosed().subscribe(async response => {
      if (!response || response.cancelled) return;
      if (response.confirmed) {
        if (this._isNewList) {
          this._modalOverlay.close(ModalOverlayServiceCloseEventType.CLOSE);
          return;
        }
        await this._restrictionsService.deleteList(
          this._list.listType as HpmRestrictionsListType,
          this._list.id,
        );
        this._modalOverlay.close(ModalOverlayServiceCloseEventType.DELETE);
      }
    });
  }

  private async _getList() {
    this._isLoading$.next(true);
    try {
      if (this.dialogData.isNewList) {
        const list = await getNewHpmRestrictionListState(this.dialogData.type);
        this._listSubj.next(list);
        this.name.setValue(list.name);
      } else {
        const list = await this._listService.getMembershipList(
          this.dialogData.id,
          true,
        );
        // set form control value to what came from back end.
        this.name.reset(list.name);
        this._listSubj.next(list);
      }
    } catch (error) {
      this._systemAlertSnackBar.error(
        HpmRestrictionsEditMessages.ERROR_OPENING_MODAL,
      );
      this._modalOverlay.close(ModalOverlayServiceCloseEventType.ESCAPE);
    } finally {
      this._isLoading$.next(false);
    }
  }
}
