<!-- Container -->
<div
  class="container"
  [class.visible]="isActivated$ | async">
  <div class="top-bar">
    <!-- Back button -->
    <mg-btn
      variant="icon"
      size="large"
      iconSet="minga"
      icon="mg-chevron-left"
      (pressed)="close()">
    </mg-btn>
    <!-- Input element -->
    <div [ngStyle]="{ width: '300px' }">
      <mg-form-text-input
        *ngIf="isActivated$ | async"
        inputType="text"
        iconLeft="mg-search"
        [autofocus]="true"
        [label]="(searchScopeConfig$ | async)?.inputLabel || MSG.INPUT_LABEL"
        [setFocus]="inputFocusSubject.asObservable()"
        [control]="fieldControl">
      </mg-form-text-input>
    </div>
  </div>

  <!-- View container -->
  <div class="view-container">
    <!-- Component routing -->
    <router-outlet
      name="search"
      #searchRouterOutlet="outlet"
      (activate)="hasActivated()"
      (deactivate)="hasDeactivated()">
    </router-outlet>
  </div>
</div>
