import { NgModule } from '@angular/core';

import { SilhLandingLayoutComponent } from './SilhLandingLayout.component';

@NgModule({
  imports: [
    // Minga dependencies
    // External dependencies
  ],
  declarations: [SilhLandingLayoutComponent],
  exports: [SilhLandingLayoutComponent],
})
export class SilhLandingLayoutModule {}
