import { HallPassStatusEnum } from 'minga/domain/hallPass';

export const HPM_DASHBOARD_STATUS_COLOR_MAP: Record<
  Exclude<
    HallPassStatusEnum,
    | HallPassStatusEnum.EXPIRED
    | HallPassStatusEnum.OVERRIDDEN
    | HallPassStatusEnum.RESTRICTED
    | HallPassStatusEnum.APPROVED
  >,
  string
> = {
  [HallPassStatusEnum.ACTIVE]: 'green',
  [HallPassStatusEnum.SCHEDULED]: 'blue',
  [HallPassStatusEnum.OVERDUE]: 'red',
  [HallPassStatusEnum.ENDED]: 'grey',
  [HallPassStatusEnum.PENDING_APPROVAL]: 'yellow',
  [HallPassStatusEnum.DENIED]: 'grey',
};

export const HPM_DASHBOARD_STATUS_PRIORITY_MAP: Record<
  Exclude<
    HallPassStatusEnum,
    | HallPassStatusEnum.EXPIRED
    | HallPassStatusEnum.OVERRIDDEN
    | HallPassStatusEnum.RESTRICTED
    | HallPassStatusEnum.APPROVED
  >,
  number
> = {
  [HallPassStatusEnum.ACTIVE]: 3,
  [HallPassStatusEnum.SCHEDULED]: 4,
  [HallPassStatusEnum.OVERDUE]: 2,
  [HallPassStatusEnum.ENDED]: 5,
  [HallPassStatusEnum.PENDING_APPROVAL]: 1,
  [HallPassStatusEnum.DENIED]: 5,
};
