<div
  class="quill-wrap"
  mgMentionable2="inline">
  <quill-editor
    #quillEditorComponent
    name="body"
    theme="snow"
    scrollingContainer="html, body"
    [class.is-disabled]="!isEnabled"
    [class.hide-toolbar]="!shouldLabelFloat"
    [modules]="modules"
    [placeholder]="innerPlaceholder"
    [styles]="{ minHeight: minHeight, display: 'flex' }"
    (onEditorCreated)="onQuillEditorCreated($event)"
    (onContentChanged)="onContentChanged($event)">
  </quill-editor>
</div>
