import { Validators } from '@angular/forms';

export enum RepeatEnd {
  NEVER = 'Never',
  AFTER = 'After',
}

export enum EditAutomationStepMessages {
  FORM_TITLE_ADD = 'Add automation for',
  FORM_TITLE_EDIT = 'Edit automation for',

  FIELD_NAME = 'Automation name',
  FIELD_INTERNAL_DESC = 'Internal description (optional)',
  FIELD_SEND_STUDENT_NOTE = 'Send note to student',
  FIELD_STUDENT_NOTE = 'Note to student',
  FIELD_STUDENT_NOTE_PLACEHOLDER = 'e.g: You were assigned your ${behaviorName} number  ${behaviorCount} and are getting a ${consequenceName} as a result',
  FIELD_BEHAVIOR_THRESHOLD = 'Behavior count threshold',
  FIELD_BEHAVIOR_THRESHOLD_DESC = 'This automation will be triggered when this threshold is met',
  FIELD_CONSEQUENCE_TO_ASSIGN = 'Consequence to assign',
  FIELD_DAYS_TO_COMPLETED = 'Number of days to complete consequence',
  FIELD_DAYS_TO_COMPLETED_TOOLTIP_DISABLED = `To have this setting enabled, the "consequence to assign" needs to require completion and have "due date" turned on.`,
  FIELD_DAYS_TO_COMPLETED_TOOLTIP = `Choose 0 to complete a consequence before the end of the day. Weekends don’t count towards the number of days to complete the consequence.`,
  FIELD_REPEAT_AUTOMATION = 'Repeat automation',
  FIELD_REPEAT_FREQUENCY = 'Repeat every',
  FIELD_REPEAT_END = 'End',

  CANCEL_BTN = 'Cancel',
  DELETE_BTN = 'Delete',

  INVALID_FORM_MESSAGE = 'Please complete the missing information(s)',
  DISABLED_CONSEQUENCE_WARNING = 'This automation is using a deactivated consequence',

  DELETE_CONFIRM_TITLE = 'Are you sure you want to delete this automation group?',
  DELETE_CONFIRM_DISCARD_TITLE = 'Are you sure you want to discard this this automation group?',
  DELETE_CONFIRM_DISCARD_DESC = 'You will lose any unsaved changes',
  DELETE_CONFIRM_CANCEL_BTN = 'Cancel',
  DELETE_CONFIRM_DELETE_BTN = 'Delete',
  DELETE_CONFIRM_DISCARD_BTN = 'Discard',

  DISABLED_CONSEQUENCE_OPTION_LABEL = 'This consequence is currently disabled',
}

export enum EditAutomationStepFields {
  NAME = 'name',
  INTERNAL_DESC = 'description',
  SEND_STUDENT_NOTE = 'enableNotes',
  STUDENT_NOTE = 'note',
  BEHAVIOR_THRESHOLD = 'threshold',
  CONSQUENCE_TO_ASSIGN = 'consequenceId',
  DAYS_TO_COMPLETED = 'completeByDays',
  REPEAT_AUTOMATION = 'repeatStep',
  REPEAT_FREQUENCY = 'repeatEvery',
  REPEAT_END = 'repeatEnd',
  ADDITIONAL_BEHAVIORS = 'additionalBehaviors',
}

export const EDIT_AUTOMATION_STEP_FORMGROUP = {
  [EditAutomationStepFields.NAME]: [null, [Validators.required]],
  [EditAutomationStepFields.INTERNAL_DESC]: [''],
  [EditAutomationStepFields.SEND_STUDENT_NOTE]: [false, [Validators.required]],
  [EditAutomationStepFields.STUDENT_NOTE]: [null],
  [EditAutomationStepFields.BEHAVIOR_THRESHOLD]: [
    1,
    [Validators.required, Validators.min(1), Validators.max(100)],
  ],
  [EditAutomationStepFields.CONSQUENCE_TO_ASSIGN]: [
    null,
    [Validators.required],
  ],
  [EditAutomationStepFields.DAYS_TO_COMPLETED]: [
    0,
    [Validators.required, Validators.min(0), Validators.max(100)],
  ],
  [EditAutomationStepFields.REPEAT_AUTOMATION]: [false, [Validators.required]],
  [EditAutomationStepFields.REPEAT_FREQUENCY]: [1, [Validators.required]],
  [EditAutomationStepFields.REPEAT_END]: [
    RepeatEnd.NEVER,
    [Validators.required],
  ],
  [EditAutomationStepFields.ADDITIONAL_BEHAVIORS]: [null],
};

export const FREQUENCY_OPTIONS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Custom', value: 'custom' },
];
