import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MgImageComponent } from './MgImage.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MgImageComponent],
  exports: [MgImageComponent],
})
export class MgImageModule {}
