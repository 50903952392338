import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IRoleInfo } from 'minga/app/src/app/roles/services';

export interface ISelectRoleSetting {
  name: string;
  roleType: string;
  disabled: boolean;
  readonly tooltip: string;
}

export function roleInfoToSelect(
  r: IRoleInfo,
  disabled: boolean,
): ISelectRoleSetting {
  const selectRoleSetting: ISelectRoleSetting = {
    name: r.name,
    roleType: r.roleType,
    tooltip: '',
    disabled,
  };

  return selectRoleSetting;
}

interface ISelectRoleInfo {
  selected: boolean;
}

@Component({
  selector: 'mg-multi-select-role',
  templateUrl: './MultiSelectRole.component.html',
  styleUrls: ['./MultiSelectRole.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectRoleComponent {
  private _selectedRolesMap = new Map<string, ISelectRoleInfo>();
  private _roles: readonly ISelectRoleSetting[] = [];

  /**
   * All roles are selected initially by default
   */
  @Input()
  defaultSelected: boolean = false;

  /**
   * Disallow roles to be deselected
   */
  @Input()
  disallowDeselect: boolean = false;

  /**
   * reduce flex basis to allow more items per row
   */
  @Input()
  reduceFlexBasis: boolean = false;

  /**
   * The list of roles to select from
   */
  @Input()
  set roles(roles: readonly ISelectRoleSetting[]) {
    this._roles = roles;
    // Remove unknown roles
    for (const key in this._selectedRolesMap.keys()) {
      if (this._roles.findIndex(s => s.roleType == key) !== -1) {
        this._selectedRolesMap.delete(key);
      }
    }

    // Supply default info if unset
    for (const role of roles) {
      const { roleType } = role;
      this._selectedRolesMap.set(
        roleType,
        this._selectedRolesMap.get(roleType) || this.defaultSelectRoleInfo(),
      );
    }
    this.selectedRolesChange.emit(this.selectedRoles);
  }

  get roles() {
    return this._roles;
  }

  /**
   * Convenient output for capturing when a single role is deslected
   */
  @Output()
  roleSelect: EventEmitter<string> = new EventEmitter();

  /**
   * Convenient output for capturing when a single role is deslected
   */
  @Output()
  roleDeselect: EventEmitter<string> = new EventEmitter();

  @Output()
  selectedRolesChange: EventEmitter<string[]> = new EventEmitter();

  /**
   * The currently selected roles
   */
  @Input()
  set selectedRoles(newSelectedRoles: string[]) {
    // roles set isn't set yet, but lets set the map anyways.
    if (this._roles.length == 0 && newSelectedRoles.length > 0) {
      for (const role of newSelectedRoles) {
        this._selectedRolesMap.set(role, { selected: true });
      }
    }

    this._roles.forEach(key => {
      let selected = false;
      if (newSelectedRoles.includes(key.roleType)) {
        selected = true;
      }
      this._selectedRolesMap.set(key.roleType, {
        ...(this._selectedRolesMap.get(key.roleType) ||
          this.defaultSelectRoleInfo()),
        selected: selected,
      });
    });
    // the selected roles getter fires off before the selected roles map
    // is updated, so tell everything it changed.
    this.selectedRolesChange.emit(newSelectedRoles);
    this._cdr.markForCheck();
  }
  get selectedRoles() {
    const selectedRoles: string[] = [];
    this._selectedRolesMap.forEach(({ selected }, key) => {
      if (selected) {
        selectedRoles.push(key);
      }
    });
    return selectedRoles;
  }

  constructor(private _cdr: ChangeDetectorRef) {}

  private defaultSelectRoleInfo(): ISelectRoleInfo {
    return {
      selected: this.defaultSelected,
    };
  }

  isSelected(roleType: string): boolean {
    const info = this._selectedRolesMap.get(roleType);
    if (info) {
      return info.selected;
    }

    return this.defaultSelected;
  }

  toggleRoleSelection(roleType: string) {
    const info = this._selectedRolesMap.get(roleType);
    if (info) {
      info.selected = !info.selected;
      // emit the update event of the status of this role
      if (info.selected) {
        this.roleSelect.emit(roleType);
      } else {
        this.roleDeselect.emit(roleType);
      }
    } else {
      console.error(`Missing role type ${roleType}`);
    }
    // emit the update event of all selected roles
    this.selectedRolesChange.emit(this.selectedRoles);
  }
}
