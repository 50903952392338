<div class="mg-basic-mini-card">
  <ng-container *ngIf="imageUrl">
    <div
      class="image"
      [style.backgroundImage]="'url('+imageUrl+')'"></div>
  </ng-container>
  <div class="content">
    <div
      class="title"
      [ngClass]="displayTitleClasses">
      <mg-ellipsis (click)="clickContextMenu($event)"></mg-ellipsis>
      <span [innerHTML]="displayTitle"></span>
    </div>
    <div
      class="subtitle"
      [innerHTML]="timestampText"></div>
    <div class="actions">
      <mg-like
        class="action mg-cancel-content-link"
        [hideicon]="true"
        [showtext]="true"
        [context]="context"
        [count]="content?.likeCount || 0"></mg-like>

      <ng-container *ngIf="content.commentCount > 0; else noComments">
        <mg-icon>comment-active</mg-icon>
      </ng-container>
      <ng-template #noComments>
        <mg-icon class="no-comments">comments</mg-icon>
      </ng-template>
      <a
        class="action"
        (click)="commentActionClick($event)"
        >{{commentsActionText}}</a
      >
    </div>
  </div>
</div>
