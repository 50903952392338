import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction,
} from '@angular/core';

import { MingaGallery } from 'minga/proto/gateway/gallery_ng_grpc_pb';
import { GalleryItem } from 'minga/proto/gateway/gallery_pb';

@Component({
  selector: 'mg-gallery-photo-event-stream',
  templateUrl: './GalleryPhotoEventStream.component.html',
  styleUrls: ['./GalleryPhotoEventStream.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryPhotoEventStreamComponent {
  trackBy: TrackByFunction<GalleryItem.AsObject>;
  @Input() contextHash: string;
  @Input() contentHash: string;
  @Input() searchQuery: string;
  @Input() selectedGalleryPhoto: GalleryItem.AsObject | string = '';

  @Output() galleryPhotoClick: EventEmitter<string> = new EventEmitter();

  constructor(
    private _cdr: ChangeDetectorRef,
    public galleryService: MingaGallery,
  ) {
    this.trackBy = (index: number, item: GalleryItem.AsObject) => {
      return item.galleryPhotoUuid;
    };
  }

  get filter() {
    const filter: any = {};

    if (this.contentHash) {
      filter.contentHash = this.contentHash;
    }

    return filter;
  }

  isBannerSelected(item: GalleryItem.AsObject) {
    if (
      !this.selectedGalleryPhoto ||
      typeof this.selectedGalleryPhoto == 'string'
    ) {
      return false;
    }
    return this.selectedGalleryPhoto.galleryPhotoUuid === item.galleryPhotoUuid;
  }

  clickItem(item: GalleryItem.AsObject) {
    this.selectedGalleryPhoto = item;
    this.galleryPhotoClick.emit(item.galleryPhotoUuid);
    this._cdr.markForCheck();
  }
}
