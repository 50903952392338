import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  IMembershipList,
  MembershipListType,
} from 'minga/domain/membershipList';
import { mingaSettingTypes } from 'minga/util';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { LayoutService } from '@modules/layout/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';
import { KioskService } from '@shared/services/kiosk/kiosk.service';

import {
  EndHallPassOptions,
  HpmSettingsMessages,
} from './hpm-settings.constants';

@Component({
  selector: 'mg-hpm-settings',
  templateUrl: './hpm-settings.component.html',
  styleUrls: ['./hpm-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HpmSettingsComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = HpmSettingsMessages;
  public readonly END_PASS_OPTIONS = EndHallPassOptions;
  public readonly LIST_TYPES = [
    MembershipListType.HALL_PASS_MANAGER,
    MembershipListType.HP_REPORT_VIEWER,
  ];

  /** General Subjects */
  private _destroyed$ = new ReplaySubject<void>(1);

  hallPassLists$: Observable<IMembershipList[]>;
  hallPassSettings = mingaSettingTypes;

  hallPassesEnabled = true;
  canStudentsSelfGrantPasses = false;
  showRecentlyEndedHallPasses = true;
  settings$ = this._settingService.getAllSettingsAsObject();

  constructor(
    public layout: LayoutService,
    private _settingService: MingaSettingsService,
    private _systemAlertSnackbar: SystemAlertSnackBarService,
    public kioskPermissions: KioskPermissionsService,
    private _kioskService: KioskService,
  ) {
    this._settingService
      .getSettingValueObs(mingaSettingTypes.PASS_HALLPASS_ENABLE)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => (this.hallPassesEnabled = value));
    this._settingService
      .getSettingValueObs(mingaSettingTypes.PASS_STUDENTS_GRANT)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => (this.canStudentsSelfGrantPasses = value));
    this._settingService
      .getSettingValueObs(mingaSettingTypes.PASS_SHOW_RECENTLY_ENDED)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(value => (this.showRecentlyEndedHallPasses = value));
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public showSnackbar(event: boolean) {
    this._systemAlertSnackbar.success(
      `Hall passes ${event ? 'enabled' : 'disabled'}`,
    );
  }

  public async openDisclaimer(value: boolean) {
    if (value) {
      await this._kioskService.openDisclaimer(
        mingaSettingTypes.PASS_HALLPASS_KIOSK,
      );
    }
  }
}
