import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MgButtonModule } from 'minga/app/src/app/button';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';

import { OldVersionNotifierComponent } from './OldVersionNotifier.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgOverlayToolsModule,

    // External dependencies
    CommonModule,
  ],
  declarations: [OldVersionNotifierComponent],
  exports: [OldVersionNotifierComponent],
})
export class OldVersionNotifierModule {}
