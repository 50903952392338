import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { getUnitsByDateRange } from 'minga/app/src/app/shared/components/bar-chart';
import { MingaMinimalModel } from 'minga/domain';
import { MingaPermission } from 'minga/util';
import { AnalyticsService } from 'src/app/minimal/services/Analytics';
import { PermissionsService } from 'src/app/permissions';
import { MingaManagerService } from 'src/app/services/MingaManager';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';

import { LayoutService } from '@modules/layout/services';

import { initializeRange } from '@shared/components/form/components/form-date-range/form-date-range.utils';
import { MediaService } from '@shared/services/media';

import { MingaManagerMessages } from '../../constants';
import { MmDistrictsService } from '../mm-districts/services/mm-districts.service';
import {
  MmReportsFilter,
  MmReportsMessages,
  MM_REPORTS_STATS_TILES,
  tileColorMap,
  MM_REPORTS_FILTERS_INITIAL_STATE,
} from './constants';
import { MmReportsService } from './services';
import { MmReportsFiltersService } from './services/mm-filters.services';
import { MingaManagerReportStatTile } from './types';

@Component({
  selector: 'mg-mm-reports',
  templateUrl: './mm-reports.component.html',
  styleUrls: ['./mm-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmReportsComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES = MmReportsMessages;
  public readonly MM_MESSAGES = MingaManagerMessages;
  public readonly FILTERS = MmReportsFilter;
  public curMinga = { label: '', value: '' };

  /** Stat Tiles */
  public readonly statsTiles$ = of(MM_REPORTS_STATS_TILES);

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);
  public readonly media$: Observable<string>;

  public readonly graphData$: Observable<any[]>;
  public readonly timeUnit$: Observable<'day' | 'week' | 'month'>;
  public readonly tileSelectedTitle$: Observable<string>;

  public readonly tileData$;
  public isSuperAdmin = this.permissions.hasPermission(
    MingaPermission.SUPERADMIN,
  );

  public range = initializeRange({
    start: {
      value: MM_REPORTS_FILTERS_INITIAL_STATE.startDate,
    },
    end: {
      value: MM_REPORTS_FILTERS_INITIAL_STATE.endDate,
    },
  });

  /** Component Constructor */
  constructor(
    public mediaObserver: MediaObserver,
    public media: MediaService,
    public reports: MmReportsService,
    public reportsFilters: MmReportsFiltersService,
    public mingaManager: MingaManagerService,
    public permissions: PermissionsService,
    public layout: LayoutService,
    private _mingaStore: MingaStoreFacadeService,
    private _cdr: ChangeDetectorRef,
    private _analytics: AnalyticsService,
    private _mmDistrictService: MmDistrictsService,
  ) {
    // lets initial set reports data to users current minga
    this._mingaStore
      .getMingaAsObservable()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(minga => {
        this._getInitData(minga);
      });

    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );

    this.timeUnit$ = this.reportsFilters.filters$.pipe(
      map(filters => {
        const startDate = filters.startDate;
        const endDate = filters.endDate;
        return getUnitsByDateRange(startDate.toDate(), endDate.toDate());
      }),
    );

    this.graphData$ = combineLatest([
      this.reportsFilters.filters$,
      this.reports.graphData$,
    ]).pipe(
      map(([filters, data]) => {
        const chartValues = data.map(point => {
          return { x: point.timeValue, y: point.value };
        });
        const tileColor = tileColorMap[filters.type];
        return [
          {
            data: chartValues,
            label: '',
            backgroundColor: tileColor,
            hoverBackgroundColor: tileColor,
          },
        ];
      }),
    );

    this.tileData$ = combineLatest([
      this.statsTiles$,
      this.reports.totalsData$,
    ]).pipe(
      map(([tiles, totals]) => {
        return tiles.map(tile => {
          return {
            ...tile,
            total: totals[tile.type] || 0,
          };
        });
      }),
    );

    this.range.valueChanges
      .pipe(takeUntil(this._destroyed$))
      .subscribe(range => {
        this.reportsFilters.setFilter(this.FILTERS.START_DATE, range.start);
        this.reportsFilters.setFilter(this.FILTERS.END_DATE, range.end);
        this.reports.getReportsData();
      });

    this._mmDistrictService.fetchAll();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  onNameChange(hash) {
    this.reportsFilters.setFilter(this.FILTERS.HASH, hash);
  }

  onTileClick(tile) {
    this.reportsFilters.setFilter(this.FILTERS.TYPE, tile.type);
    this.reports.getGraphData();
  }

  async clearFilters() {
    await this.reportsFilters.clearFilters();
    await this.reports.getReportsData();
  }

  private _getInitData(minga: MingaMinimalModel) {
    this.reportsFilters.setFilter(this.FILTERS.HASH, minga.hash);
    this.reports.getReportsData();
    this.curMinga = { label: minga.name, value: minga.hash };
    this._cdr.markForCheck();
  }

  public applyFilter() {
    this.reports.getReportsData();
    this._analytics.sendManagerReport({ managerType: 'minga' });
  }
}
