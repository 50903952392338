import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ICancelConfirmationOptions {
  title?: string;
  text: string;
}

@Component({
  selector: 'mg-cancel-confirm-dialog',
  templateUrl: './CancelConfirmation.component.html',
  styleUrls: ['./CancelConfirmation.component.scss'],
})
export class CancelConfirmationDialogComponent {
  text: string = '';
  title: string = '';

  constructor(
    private dialogRef: MatDialogRef<CancelConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICancelConfirmationOptions,
  ) {
    this.text = data['text'] || '';
    this.title = data['title'] || '';
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
