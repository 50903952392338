export enum CheckinDashboardMessages {
  PAGE_TITLE = 'Check In Dashboard',
  SECTION_HEADER_CHECKIN = 'Check In/Out',
  SELECT_OPTION_NONE = 'None',
  AUTO_REFRESH = 'Auto refresh',
  REFRESH_DASHBOARD_TITLE = 'Refresh dashboard',
  NO_CHECKIN_REASONS_TITLE = 'No check in reasons available',
  NO_CHECKIN_REASONS_DESCRIPTION = 'Activate or create check in reasons',
}

export const AUTO_REFRESH_STATE = 'autoCheckinRefreshState';
export const POLLING_INTERVAL = 60000;
