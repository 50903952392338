<div class="msg-date mg-minitext">{{ lastMessageDateString }}</div>
<div
  fxLayout="row"
  fxLayoutAlign="start center">
  <div class="left-col">
    <mg-avatar-cluster
      [items]="avatarClusterItems$ | async"></mg-avatar-cluster>
  </div>
  <div
    class="preview-content"
    fxLayout="column"
    fxFlex="auto">
    <div
      class="participants-container"
      fxLayout="row"
      fxLayoutAlign="start center">
      <div
        class="participants mg-smallcopy"
        [class.group]="(participatingPeople$ | async)?.length > 1"
        #participantsEl>
        <ng-container
          *ngFor="
            let person of participatingPeople$ | async;
            count as c;
            index as i
          ">
          <mg-person-minimal
            *ngIf="c == 1"
            [person]="person"></mg-person-minimal>
          <span
            *ngIf="c > 1 && i < previewLimit"
            class="participant"
            [class.group]="c > 1">
            {{ person.displayName
            }}<ng-container *ngIf="c > 1 && i + 1 < previewLimit">{{
              c > 2 ? ', ' : ' & '
            }}</ng-container>
          </span>
        </ng-container>
      </div>
      <div
        *ngIf="(participatingPeople$ | async)?.length > getPreviewAmount()"
        class="mg-smallcopy group-participants-more">
        &nbsp;+
        {{ (participatingPeople$ | async)?.length - getPreviewAmount() }} more
      </div>
    </div>
    <div class="msg-body-text">{{ data?.lastMessageBody }}</div>
  </div>
</div>
