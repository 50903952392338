import { MatTableDataSource } from '@angular/material/table';

import { isEmpty } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from 'rxjs';

import {
  AvailablePsDataKeys,
  PsData,
  PsFilterState,
  PsFilters,
  PsFormsPageType,
} from '../types';

export class PsFormSearchResults {
  /** Loading State */
  private readonly _isLoadingSubj = new BehaviorSubject(false);
  public readonly isLoading$ = this._isLoadingSubj.asObservable();

  /** Current Search */
  private _currentSearch = new Subject<void>();
  private _currentSearchSub = new Subscription();

  /** Current Search */
  private _noResultsSubj = new BehaviorSubject<boolean>(false);
  public readonly noResults$ = this._noResultsSubj.asObservable();

  /** Available Columns  */
  private readonly _availableColumnsSubj = new BehaviorSubject<
    AvailablePsDataKeys[]
  >([]);
  public readonly availableColumn$ = this._availableColumnsSubj.asObservable();

  /** Data Source */
  private _dataSource = new MatTableDataSource<PsData>([]);

  /** Computed Values */
  public get dataSource() {
    return this._dataSource;
  }
  public get size() {
    return this._dataSource.data.length;
  }

  /** Constructor */
  constructor(
    protected readonly activePath$: Observable<string>,
    protected readonly activePageType$: Observable<PsFormsPageType>,
    protected readonly filterState$: Observable<PsFilterState>,
    protected readonly selectedHashes$: Observable<string[]>,
  ) {
    this.activePath$ = activePath$;
    this.activePageType$ = activePageType$;
    this.filterState$ = filterState$;
    this.selectedHashes$ = selectedHashes$;
    this._initTable();
    this._initFiltersSub();
  }

  public setLoadingState(isLoading: boolean) {
    this._isLoadingSubj.next(isLoading);
  }

  public setData(data: PsData[]) {
    const hasResults = data[0] || false;
    this._noResultsSubj.next(!hasResults);
    this._dataSource.data = data;
    if (hasResults) this._setAvailableColumns(data[0]);
  }

  public reset() {
    if (!this._currentSearch.isStopped) this._currentSearch.complete();
    this._currentSearchSub.unsubscribe();
    this._currentSearch = new Subject<void>();
    this._noResultsSubj.next(false);
    this._availableColumnsSubj.next([]);
    this._dataSource.data = [];
    this._initTable();
  }

  private _initTable() {
    this._dataSource.filterPredicate = this._tableFilter;
  }

  private _initFiltersSub() {
    return combineLatest([
      this.activePageType$,
      this.filterState$,
      this.selectedHashes$,
    ]).subscribe(([pageType, filters, selectedHashes]) => {
      let dsFilterObj = { selectedHashes } as PsFilterState & {
        selectedHashes: string[];
      };
      if (pageType === 'list') dsFilterObj = { ...dsFilterObj, ...filters };
      if (isEmpty(dsFilterObj)) return;
      this._dataSource.filter = JSON.stringify(dsFilterObj);
    });
  }

  private _tableFilter(person: PsData, filters: string) {
    const dsFilterObj = JSON.parse(filters) as PsFilterState & {
      selectedHashes: string[];
    };
    return Object.keys(dsFilterObj)
      .reduce((checks: boolean[], key: PsFilters | 'selectedHashes') => {
        const filter = dsFilterObj[key] as any;
        switch (key) {
          case 'selectedHashes': {
            if (person.personHash)
              checks.push(!filter.includes(person.personHash));
            break;
          }
          case 'fileUploadedPeople': {
            const fileUploadedPeople = dsFilterObj[key];
            if (fileUploadedPeople.length > 0) {
              const { names, studentIds } = fileUploadedPeople.reduce(
                (acc, item) => {
                  if (item.name) acc.names.push(item.name?.toLowerCase());
                  if (item.studentId) acc.studentIds.push(item.studentId);
                  return acc;
                },
                {
                  names: [],
                  studentIds: [],
                },
              );
              checks.push(
                names.includes(person.displayName.toLowerCase()) ||
                  studentIds.includes(person?.studentId?.toLowerCase()),
              );
            }
            break;
          }
          case 'keywords': {
            const keywords = dsFilterObj[key]?.toLowerCase();
            if (keywords.length)
              checks.push(
                person.displayName.toLowerCase().includes(keywords) ||
                  person?.studentId?.toLowerCase().includes(keywords),
              );
            break;
          }
          default: {
            break;
          }
        }
        return checks;
      }, [])
      .every(v => v);
  }

  private _setAvailableColumns(data: PsData) {
    if (!data) return;
    const availableKeys = Object.entries(data)
      .filter(([, v]) => v != null)
      .map(([k]) => k)
      .filter(k => ['email', 'school', 'grade', 'status'].includes(k));
    this._availableColumnsSubj.next(availableKeys as AvailablePsDataKeys[]);
  }
}
