<mg-portal-layout-header
  *ngIf="
    (mediaService.isMobileView$ | async) === true &&
    (mobileProgress$ | async) === 'assign'
  ">
  <div
    class="mobile-assigment-header"
    fxLayout="row"
    fxLayoutAlign="center center">
    <mg-text
      variant="header-xl-secondary"
      textAlign="center"
      >{{ MESSAGES.MOBILE_ASSIGNMENT_TITLE }}</mg-text
    >
    <mg-btn
      size="large"
      variant="icon-secondary"
      iconSet="minga"
      icon="mg-cross"
      (pressed)="setMobileProgress('select')"></mg-btn>
  </div>
</mg-portal-layout-header>

<mg-portal-layout-footer *ngIf="mediaService.isMobileView$ | async">
  <div
    *ngIf="(mobileProgress$ | async) === 'select'; else assignmentFooter"
    class="mobile-btn-wrap">
    <mg-btn
      [disabled]="form.get(FORM_FIELDS.SELECTED_STUDENTS).value.length === 0"
      [wide]="true"
      (pressed)="setMobileProgress('assign')">
      {{ MESSAGES.PROGRESS_NEXT }}
    </mg-btn>
  </div>
</mg-portal-layout-footer>

<section
  class="my-class full-width-layout"
  [ngClass]="{
    'no-avatar': form.get(FORM_FIELDS.HIDE_PICTURE_FILTER).value
  }"
  fxLayout="column"
  fxLayoutGap="16px"
  fxLayout.gt-sm="row">
  <div
    *ngIf="(formState$ | async) === 'loading'"
    class="my-class-loading">
    <mg-spinner></mg-spinner>
  </div>

  <div
    *ngIf="(formState$ | async) !== 'loading'"
    class="my-class-main"
    fxLayout="column"
    fxLayoutGap="12px">
    <div class="my-class-main-filters">
      <ng-container
        *ngIf="mediaService.isMobileView$ | async; else desktopFilters"
        [ngTemplateOutlet]="mobileFilters">
      </ng-container>
    </div>
    <div class="my-class-main-students">
      <ng-container
        [ngTemplateOutlet]="mainWrap"
        [ngTemplateOutletContext]="{
          $implicit: filteredStudentList$ | async
        }"></ng-container>
    </div>
  </div>

  <ng-container
    *ngIf="mediaService.isMobileView$ | async; else desktopSidebar"
    [ngTemplateOutlet]="mobileSidebar"></ng-container>
</section>

<ng-template
  #mainWrap
  let-filteredStudentList>
  <ng-container *ngIf="mediaService.isMobileView$ | async">
    <!-- just visually hiding otherwise triggers a we lose some state -->
    <div
      [style.display]="
        (mobileProgress$ | async) === 'assign' ? 'none' : 'block'
      "
      class="my-class-main-mobile">
      <ng-container
        [ngTemplateOutlet]="main"
        [ngTemplateOutletContext]="{
          $implicit: filteredStudentList
        }"></ng-container>
    </div>
  </ng-container>

  <ng-container
    *ngIf="(mediaService.isMobileView$ | async) === false"
    [ngTemplateOutlet]="main"
    [ngTemplateOutletContext]="{
      $implicit: filteredStudentList
    }"></ng-container>
</ng-template>

<ng-template
  #main
  let-filteredStudentList>
  <div
    *ngIf="fetchingStudents$ | async; else studentCards"
    class="my-class-main-loading">
    <mg-spinner></mg-spinner>
  </div>

  <ng-template #studentCards>
    <ng-container *ngIf="form.get(FORM_FIELDS.LIST_FILTER).value; else noLists">
      <div
        *ngIf="filteredStudentList?.length > 0; else noUsers"
        class="my-class-student-cards">
        <div
          #studentCard
          *ngFor="
            let student of filteredStudentList
              | studentSort: form.get(FORM_FIELDS.SORT_FIRST_NAME_FILTER).value;
            trackBy: trackByFn
          "
          [attr.data-hash]="student.personHash">
          <mg-tt-student-card
            [authHash]="authHash$ | async"
            [selected]="
              form.get(FORM_FIELDS.SELECTED_STUDENTS).value
                | includes: student.personHash
            "
            [showPulse]="(randomizer$ | async) === student.personHash"
            [student]="student"
            [hidePicture]="form.get(FORM_FIELDS.HIDE_PICTURE_FILTER).value"
            [firstNameFirst]="
              form.get(FORM_FIELDS.SORT_FIRST_NAME_FILTER).value
            "
            [pastAssignments]="
              (pastAssignments$ | async)?.get(student.personHash) || []
            "
            [hallPass]="(hallPassesByStudent$ | async)?.get(student.personHash)"
            (toggleSelected)="
              toggleSelected($event, student)
            "></mg-tt-student-card>
        </div>
      </div>

      <ng-template #noUsers>
        <mg-empty-state
          class="my-class-main-empty-state"
          [title]="MESSAGES.NO_USERS_FOUND_TITLE"
          [subtitle]="MESSAGES.NO_USERS_FOUND_SUBTITLE"></mg-empty-state>
      </ng-template>
    </ng-container>

    <ng-template #noLists>
      <mg-empty-state
        *ngIf="!form.get(FORM_FIELDS.LIST_FILTER).value"
        class="my-class-main-empty-state"
        [title]="
          (listOptions$ | async)?.length > 0
            ? MESSAGES.NO_USER_LIST_SELECTED_TITLE
            : MESSAGES.NO_USER_LIST_FOUND_TITLE
        "
        [subtitle]="
          (listOptions$ | async)?.length > 0
            ? MESSAGES.NO_USER_LIST_SELECTED_SUBTITLE
            : MESSAGES.NO_USER_LIST_FOUND_SUBTITLE
        "
        [actionButtonLabel]="MESSAGES.EMPTY_LIST_ACTION_BUTTON"
        (handleAction)="createUserList()"></mg-empty-state>
    </ng-template>
  </ng-template>
</ng-template>

<ng-template #filters>
  <section
    fxLayout="column"
    fxLayout.gt-md="row"
    fxLayoutGap="12px">
    <div
      class="inputs"
      fxLayout="column"
      fxLayout.gt-sm="row"
      fxLayoutGap="12px">
      <div class="input input-user-list">
        <mg-user-list-filter
          [prefetchData]="[UserListCategory.ALL]"
          [value]="form.get(FORM_FIELDS.LIST_FILTER).value"
          [multiple]="false"
          [placeholder]="MESSAGES.FORM_LABEL_LIST"
          (selectChanged)="userListChanged($event)">
        </mg-user-list-filter>
      </div>
      <div class="input input-search">
        <mg-form-text-input
          type="text"
          iconLeft="search"
          [control]="form.get(FORM_FIELDS.SEARCH_TEXT_FILTER)"
          [label]="MESSAGES.FORM_LABEL_TEXT_SEARCH"></mg-form-text-input>
      </div>
    </div>
    <div
      class="toggles"
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutAlign="start start"
      fxLayoutAlign.gt-sm="start end"
      fxLayoutGap="12px">
      <div
        fxLayout="column"
        fxLayout.gt-xs="row"
        fxLayout.gt-sm="column"
        fxLayoutGap="4px"
        fxLayoutGap.gt-xs="12px"
        fxLayoutGap.gt-sm="4px">
        <div
          fxLayout="row"
          fxLayoutGap="4px">
          <mg-form-checkbox
            [checked]="form.get(FORM_FIELDS.SORT_FIRST_NAME_FILTER).value"
            (changed)="
              form.get(FORM_FIELDS.SORT_FIRST_NAME_FILTER).setValue($event)
            ">
            <mg-text variant="label-md">{{
              MESSAGES.FORM_LABEL_NAME_SORT
            }}</mg-text>
          </mg-form-checkbox>
        </div>
        <div
          fxLayout="row"
          fxLayoutGap="4px">
          <mg-form-checkbox
            [checked]="form.get(FORM_FIELDS.HIDE_PICTURE_FILTER).value"
            (changed)="
              form.get(FORM_FIELDS.HIDE_PICTURE_FILTER).setValue($event)
            ">
            <mg-text variant="label-md">{{
              MESSAGES.FORM_LABEL_HIDE_PICTURES
            }}</mg-text>
          </mg-form-checkbox>
        </div>
      </div>
      <div
        *ngIf="filteredStudentList$ | async as filteredStudentList"
        fxLayout="row"
        fxLayoutGap="8px">
        <mg-chip
          [disabled]="LOCKED_STATES | includes: (formState$ | async)"
          [active]="
            filteredStudentList.length !== 0 &&
            filteredStudentList.length ===
              form.get(FORM_FIELDS.SELECTED_STUDENTS).value.length
          "
          [disabled]="filteredStudentList.length === 0"
          (click)="toggleSelectAll(filteredStudentList)"
          >{{
            filteredStudentList.length !== 0 &&
            filteredStudentList.length ===
              form.get(FORM_FIELDS.SELECTED_STUDENTS).value.length
              ? MESSAGES.FORM_LABEL_UNSELECT_ALL
              : MESSAGES.FORM_LABEL_SELECT_ALL
          }}
        </mg-chip>
        <mg-chip
          [ngClass]="{ randomizing: (formState$ | async) === 'randomizing' }"
          [disabled]="
            filteredStudentList.length === 0 ||
            (LOCKED_STATES | includes: (formState$ | async))
          "
          (click)="selectRandom(filteredStudentList)">
          {{
            (formState$ | async) === 'randomizing'
              ? MESSAGES.FORM_LABEL_SELECT_RANDOMIZING
              : MESSAGES.FORM_LABEL_SELECT_RANDOM
          }}
        </mg-chip>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #mobileFilters>
  <!-- just visually hiding otherwise triggers a bunch of data re-fetchin -->
  <mg-collapsible
    [style.display]="(mobileProgress$ | async) === 'assign' ? 'none' : 'block'"
    [collapseText]="MESSAGES.HIDE_FILTERS"
    [expandText]="MESSAGES.SHOW_FILTERS">
    <ng-container *ngTemplateOutlet="filters"></ng-container>
  </mg-collapsible>
</ng-template>

<ng-template #desktopFilters>
  <ng-container *ngTemplateOutlet="filters"></ng-container>
</ng-template>

<ng-template #sidebar>
  <mg-my-class-sidebar
    *ngIf="(formState$ | async) !== 'loading'"
    [actionGroups]="actionGroups$ | async"
    [form]="form"
    (assign)="assign()">
    <ng-container
      *ngIf="(mediaService.isMobileView$ | async) === false"
      [ngTemplateOutlet]="assignmentFooter">
    </ng-container>
  </mg-my-class-sidebar>
</ng-template>

<ng-template #mobileSidebar>
  <div
    *ngIf="(mobileProgress$ | async) === 'assign'"
    class="my-class-sidebar-mobile">
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
  </div>
</ng-template>

<ng-template #desktopSidebar>
  <div class="my-class-sidebar">
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
  </div>
</ng-template>

<ng-template #assignmentFooter>
  <mg-tt-assignment-footer
    [form]="form"
    [formState]="formState$ | async"
    [formErrors]="formErrors$ | async"
    [assignSuccess$]="assignSuccess$"
    (assign)="assign()"></mg-tt-assignment-footer>
</ng-template>
