<header
  class="header container"
  [ngClass]="type">
  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="stretch center">
    <div fxFlex.gt-sm="50%">
      <div
        class="header-profile"
        fxLayout="row"
        fxLayoutGap="20px"
        fxLayoutAlign="start center">
        <div fxFlex="nogrow">
          <div
            class="teacher-avatar"
            [style.backgroundImage]="'url(' + user.avatarUrl + ')'"
            (click)="
              router.navigate(['/profile', { outlets: { o: null } }])
            "></div>
        </div>
        <div fxFlex="grow">
          <mg-text
            variant="header-xl"
            spacing="0-half"
            >{{ user.displayName }}</mg-text
          >
          <mg-text variant="body-sm">{{ user.badgeRoleName }}</mg-text>
        </div>
      </div>
    </div>
    <div fxFlex.gt-sm="50%">
      <ng-content></ng-content>
    </div>
  </div>
</header>
