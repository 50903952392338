export enum BmTypesMessages {
  PAGE_TITLE = 'Behaviors',
  BUTTON_CREATE_NEW = 'Create',
  BUTTON_CREATE_BEHAVIOR = 'Behavior',
  BUTTON_CREATE_CONSEQUENCE = 'Consequence',
  BUTTON_CREATE_AUTOMATION = 'Automation',
  BUTTON_CREATE_AUTOMATION_GROUP = 'Automation group',
  BUTTON_TOGGLE_AUTOMATIONS = 'Automations',
  BUTTON_TOGGLE_AUTOMATION_GROUPS = 'Automation groups',
  BUTTON_TOGGLE_CONSEQUENCES = 'Consequences',
  BUTTON_TOGGLE_BEHAVIORS = 'Behaviors',
}

/**
 * Atm this order defines the order they show up in the select ui
 * If we need different ordering between behaviors and consequences
 * we'll have to add some weights for sorting
 */
export enum BehaviorIconListType {
  BEHAVIOR_PRAISE = 'behavior-praise',
  BEHAVIOR_GUIDANCE = 'behavior-guidance',
  CONSEQUENCE_PRAISE = 'consequence-praise',
  CONSEQUENCE_GUIDANCE = 'consequence-guidance',
  BELL = 'bell',
  CIRCLE = 'circle',
  CLOCK = 'clock',
  DIAMOND = 'diamond',
  FIRE = 'fire',
  HEART = 'heart',
  MOBILE = 'mobile',
  RUNNING = 'running',
  SCHOOL = 'school',
  SMOKING = 'smoking',
  SQUARE = 'square',
  STAR = 'star',
  THUMBS_UP = 'thumbs-up',
  THUMBS_DOWN = 'thumbs-down',
  TRIANGLE = 'triangle',
  WARNING = 'warning',
}
