import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, from } from 'rxjs';
import { concatMap, filter, toArray } from 'rxjs/operators';

import {
  Group,
  MingaGroupMemberRank,
} from 'minga/app/src/app/groups/models/Group';
import { GroupsFacadeService, GroupsService } from 'src/app/groups/services';
import { MingaSettingsService } from 'src/app/store/Minga/services';
import { mgResolveImageUrl } from 'src/app/util/asset';

import { ToolsMessages } from '@modules/tools/tools.constants';

import { CarouselConfig } from '@shared/components/carousel/carousel/carousel';
import { MediaService } from '@shared/services/media';

@Component({
  selector: 'mg-tools-groups',
  templateUrl: './tools-groups.component.html',
  styleUrls: ['./tools-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsGroupsComponent implements OnInit {
  public MESSAGES = ToolsMessages;
  public carouselConfig: CarouselConfig = {
    grid: { xs: 4, sm: 5, md: 6, lg: 6, xl: 8, all: 0 },
    showNavigation: true,
    showPagination: false,
  };

  public groups$: Observable<Group[]>;
  public isGroupsEnabled$ = this._settingService.isCommunityModuleEnabled();

  constructor(
    private _route: ActivatedRoute,
    private _groupsFacadeService: GroupsFacadeService,
    private _groupsService: GroupsService,
    private _settingService: MingaSettingsService,
    public mediaService: MediaService,
  ) {}

  ngOnInit(): void {
    const personHash = this._route.snapshot.paramMap.get('hash');

    this.groups$ = personHash
      ? this._groupsService.getPersonGroups(personHash).pipe(
          concatMap(groups => from(groups)),
          filter(group =>
            [MingaGroupMemberRank.MEMBER, MingaGroupMemberRank.OWNER].includes(
              group.currentMemberRank,
            ),
          ),
          toArray(),
        )
      : this._groupsFacadeService.getMyGroupsSortedByActivity();
  }

  public getGroupBanner(group: Group) {
    if (!group || (!group.banner && !group.bannerObject)) {
      return '';
    }
    const image = group.bannerObject
      ? group.bannerObject.getImage()
      : group.banner;

    return mgResolveImageUrl(image);
  }
}
