import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mg-delete-image-confirmation-dialog',
  templateUrl: './DeleteImageConfirmation.component.html',
  styleUrls: ['./DeleteImageConfirmation.component.scss'],
})
export class DeleteImageConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteImageConfirmationComponent>,
  ) {}
}
