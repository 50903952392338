import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IActionThresholdAutomation } from 'minga/domain/actions';

import {
  BehaviorManagerRoutes,
  BehaviorsSubRoutes,
} from '@modules/behavior-manager/constants';
import { LayoutService } from '@modules/layout/services';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { BmTypesAutomationsEditGroupComponent } from '../components/bm-types-automations-edit-group/bm-types-automations-edit-group.component';
import {
  BmTypesAutomationGroupEditModalData,
  BmTypesAutomationGroupEditModalResponse,
} from '../types';

@Injectable()
export class BmTypesAutomationService implements OnDestroy {
  private readonly _destroyedSubject$ = new ReplaySubject<void>(1);
  /** Updated automation group id */
  private readonly _automationGroupUpdatedSubject = new Subject<{
    id: number;
    action: 'create' | 'update' | 'delete';
    data?: IActionThresholdAutomation;
  }>();
  public readonly automationGroupUpdated$ =
    this._automationGroupUpdatedSubject.asObservable();

  private readonly _newlyCreatedAutomationGroupSubject = new BehaviorSubject<
    number | null
  >(null);
  // used to scroll the newly created group into focus
  public readonly newlyCreatedAutomationGroup$ =
    this._newlyCreatedAutomationGroupSubject.asObservable();

  private _isLegacyLayout;

  /** Service Constructor */
  constructor(
    private _systemSnackbar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService<
      BmTypesAutomationGroupEditModalResponse,
      BmTypesAutomationGroupEditModalData
    >,
    private _router: Router,
    private _layoutService: LayoutService,
  ) {
    this._layoutService.useLegacyLayout$
      .pipe(takeUntil(this._destroyedSubject$))
      .subscribe(isLegacyLayout => {
        this._isLegacyLayout = isLegacyLayout;
      });
  }

  public setUpdatedAutomationGroup(
    id: number,
    action: 'create' | 'update' | 'delete' = 'update',
    data?: IActionThresholdAutomation,
  ): void {
    this._automationGroupUpdatedSubject.next({ id, action, data });
  }

  public setNewlyCreatedAutomationGroup(id: number | null): void {
    this._newlyCreatedAutomationGroupSubject.next(id);
  }

  // create/edit flow for automation group has different UX flows so that's why we have a seperate method for just create
  public async openGroupCreateModal(scrollToTop = false): Promise<void> {
    const createModal = this._modalOverlay.open(
      BmTypesAutomationsEditGroupComponent,
      {
        disposeOnNavigation: false,
      },
    );
    createModal.afterClosed.subscribe(async response => {
      if (!response) return;
      const { type, data } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          this._systemSnackbar.success(
            'Successfully created new automation group',
          );

          const automationId = data?.created?.id;

          const segments: any[] = this._isLegacyLayout
            ? [
                BehaviorManagerRoutes.ROOT,
                BehaviorManagerRoutes.VIEW,
                BehaviorsSubRoutes.AUTOMATIONS,
                BehaviorsSubRoutes.AUTOMATIONS_STEP_EDIT,
                automationId,
              ]
            : [
                BehaviorManagerRoutes.ROOT,
                BehaviorManagerRoutes.AUTOMATIONS,
                BehaviorsSubRoutes.AUTOMATIONS_STEP_EDIT,
                automationId,
              ];

          // notify the automation list of the new automation group to get it added to the list
          this.setUpdatedAutomationGroup(automationId, 'create', data?.created);

          if (scrollToTop) {
            this.setNewlyCreatedAutomationGroup(automationId);
          }

          // we need to push user to create their first automation step
          this._router.navigate(segments);
          return;
        }
        default: {
          return;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyedSubject$.next();
    this._destroyedSubject$.complete();
  }
}
