<ng-template #pointsOverlayTemplate>
  <div class="points-overlay">
    <div class="content-wrap">
      <div class="trophy-icon"></div>
      <div
        class="text"
        [innerHTML]="pointsText"></div>
      <div
        class="body"
        [innerHTML]="pointsBody"></div>
    </div>
  </div>
</ng-template>
