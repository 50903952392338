<div
  *ngIf="settings$ | async as settings"
  class="calendar-schedule-container">
  <mg-calendar-schedule
    [(activeDate)]="activeDate"
    (activeDateChange)="onActiveDateChange($event)"
    [excludeWeekend]="settings.excludeWeekend"
    [calendarView]="settings.calendarView"
    (clickCalendarEventItem)="onClickCalendarEventItem($event)"
    [calendarEvents]="calendarEvents$ | async"></mg-calendar-schedule>
</div>
