import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { dateMessageObjectToDateObject } from 'minga/app/src/app/util/date';
import { numeral } from 'minga/app/src/app/util/numeral';
import { ChallengeColor, ChallengeType } from 'minga/domain/challenge';
import { ShortChallengeCardView } from 'minga/proto/gateway/content_views_pb';

import { BaseShortCardClass } from '../BaseShortCardClass';

@Component({
  selector: 'mg-challenge-mini-card',
  templateUrl: './MgChallengeMiniCard.element.html',
  styleUrls: ['./MgChallengeMiniCard.element.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgChallengeMiniCardElement extends BaseShortCardClass {
  /** @internal */
  private _content: ShortChallengeCardView.AsObject | null = null;

  typeClass: string = 'text-challenge';
  dueDate: Date | null = null;

  @Input()
  set content(content: ShortChallengeCardView.AsObject | null) {
    this._content = content || null;
    this.setDueDate();

    switch (this._content?.challengeType) {
      case ChallengeType.IMAGE:
        this.typeClass = 'image-challenge';
        break;
      case ChallengeType.TEXT:
      default:
        this.typeClass = 'text-challenge';
        break;
    }
  }

  get content(): ShortChallengeCardView.AsObject | null {
    return this._content;
  }

  @Input()
  pinned: boolean = false;

  @Input()
  markKeywords: string = '';

  @Input()
  context: string = '';

  setDueDate() {
    if (!this._content || !this._content.dueDate) {
      this.dueDate = null;
      return;
    }

    this.dueDate = dateMessageObjectToDateObject(this._content.dueDate);
  }

  numberize(count: number | string) {
    let value = numeral(count).value();

    if (value > 1000) {
      let numberized: string = numeral(value).format('0a');
      return numberized;
    } else {
      return value;
    }
  }
}
