import {
  Component,
  ElementRef,
  Inject,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EventContentService } from 'minga/app/src/app/minimal/services/EventContent';
import { MgModalService } from 'minga/app/src/app/minimal/services/MgModal';
import { RootService } from 'minga/app/src/app/minimal/services/RootService';
import { SentryService } from 'src/app/minimal/services/Sentry/Sentry.service';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

export interface IEventCodeCheckInDialogResponse {
  canceled?: boolean;
  correctCode?: boolean;
  successOverlay?: TemplateRef<EventCodeCheckInDialogComponent>;
}

@Component({
  selector: 'mg-event-code-check-in',
  templateUrl: './EventCodeCheckIn.component.html',
  styleUrls: ['./EventCodeCheckIn.component.scss'],
})
export class EventCodeCheckInDialogComponent {
  eventCode: string;
  eventTitle = '';
  showErrorMessage: boolean;
  checkingCode: boolean;
  isIOS: boolean = window.MINGA_DEVICE_IOS;
  eventReasonId = 0;

  @ViewChild('codeInput', { static: true })
  codeInputEl: ElementRef;

  @ViewChild('successOverlayTemplate', { static: true })
  successOverlayTemplate: TemplateRef<EventCodeCheckInDialogComponent>;

  constructor(
    private dialogRef: MatDialogRef<EventCodeCheckInDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mgModal: MgModalService,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private eventContent: EventContentService,
    private rootService: RootService,
    private sentryService: SentryService,
  ) {
    // get eventReasonId and title from data
    if (data['eventReasonId']) {
      this.eventReasonId = data['eventReasonId'];
    }
    if (data['eventTitle']) {
      this.eventTitle = data['eventTitle'];
    }
  }

  onKeydown(e: KeyboardEvent) {
    if (e.which === 13) {
      e.preventDefault();
      this.submit();
    }
  }

  close() {
    this.dialogRef.close({ canceled: true });
  }

  async submit() {
    this.showErrorMessage = false;
    this.checkingCode = true;
    if (!this.eventReasonId) {
      this.sentryService.captureMessageAsError(
        'EventCodeCheckin happend without reasonID',
      );
    }
    const errorMessage = `Unable to check in at the moment, please try again later`;
    if (!this.eventContent) {
      console.error(
        `[EventCodeCheckInDialogComponent] submit() has no eventContentService!`,
      );
      this._systemAlertSnackBar.error(errorMessage);
      return;
    }

    const response = await this.eventContent.checkInToEvent(
      this.eventReasonId,
      this.eventCode,
    );
    this.checkingCode = false;

    if (!response) {
      console.error(
        `[EventCodeCheckInDialogComponent] submit() response error`,
      );
      this.sentryService.captureMessageAsError(
        '[EventCodeCheckInDialogComponent] submit() response error',
      );
      this._systemAlertSnackBar.error(errorMessage);
    } else {
      const error = response.getErrorMessage();
      if (!error) {
        // success!

        this.dialogRef.close({
          correctCode: true,
          successOverlay: this.successOverlayTemplate,
        });
      } else {
        this._systemAlertSnackBar.error(error);
      }
    }
  }
}
