export function completeUrl(url: string) {
  if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://' + url;
  }
  return url;
}

export function displayShortUrl(url: string) {
  if (url) {
    return url.replace('http://', '').replace('https://', '');
  }
  return '';
}
