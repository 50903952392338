import { Component, Input } from '@angular/core';

/**
 * V2 of `<mg-success-checkmark>`. Simple component for displaying a fancy
 * success checkmark designed by our Designer.
 */
@Component({
  selector: 'mg-success-checkmark',
  templateUrl: './SuccessCheckmark.component.html',
  styleUrls: ['./SuccessCheckmark.component.scss'],
})
export class SuccessCheckmarkComponent {
  @Input()
  animate: boolean = true;
}
