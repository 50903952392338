import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

/**
 * Simple utility component to add a pre-setup FTUE video
 */
@Component({
  selector: 'mg-ftue-video',
  templateUrl: './FtueVideo.component.html',
  styleUrls: ['./FtueVideo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtueVideoComponent {
  @ViewChild('video', { static: true })
  videoElement!: ElementRef<HTMLVideoElement>;

  @Input()
  videoSrcPrefix: string = 'https://cdn.minga.io/_default/ftue/';

  /**
   * The video name already pre-uploaded to cloud storage. The following files
   * will be used to setup a `<video>` element:
   * - `{videoSrcPrefix}{videoName}.webm`
   * - `{videoSrcPrefix}{videoName}.mp4`
   * - `{videoSrcPrefix}{videoName}.gif`
   *
   * The poster will be set to `{videoSrcPrefix}{videoName}.png`
   */
  @Input()
  videoName: string = '';

  ngOnInit() {}
}
