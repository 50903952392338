<!-- Page Content -->
<ng-container *ngTemplateOutlet="quickActionsTemplate"></ng-container>

<div
  *ngIf="checkinDashboard.isLoading$ | async; else checkinReasonsTemplate"
  class="loading-container">
  <mg-spinner
    [diameter]="40"
    [thickness]="3">
  </mg-spinner>
</div>

<!-- Check In Section -->
<ng-template #checkinReasonsTemplate>
  <section class="tiles-section">
    <div class="tiles-section-title">
      {{ MESSAGES.SECTION_HEADER_CHECKIN }}
    </div>
    <mg-empty-state
      *ngIf="(checkinDashboard.checkinReasons$ | async)?.length === 0"
      [title]="MESSAGES.NO_CHECKIN_REASONS_TITLE"
      [subtitle]="MESSAGES.NO_CHECKIN_REASONS_DESCRIPTION">
    </mg-empty-state>
    <mg-tiles-carousel
      *ngIf="(checkinDashboard.checkinReasons$ | async)?.length > 0"
      [stylePreset]="CAROUSEL_PRESETS.LIST">
      <ng-container
        *ngFor="let tile of checkinDashboard.checkinReasons$ | async"
        [ngTemplateOutlet]="listItem"
        [ngTemplateOutletContext]="{ $implicit: tile, type: 'checkin' }">
      </ng-container>
    </mg-tiles-carousel>
  </section>
</ng-template>

<!-- List Item -->
<ng-template
  #listItem
  let-tile
  let-type="type">
  <div class="checkin-tile">
    <!-- Tile -->
    <mg-count-tile
      size="large"
      [id]="'checkin-dashboard-click-checkin-type'"
      [fixedHeight]="true"
      [count]="tile.totalCheckinCount"
      [label]="tile.name"
      [icon]="tile.icon"
      [color]="tile.color || '#1d9fb9'"
      [backgroundColor]="'white'"
      (tileClicked)="goToReports(tile.id, false)">
    </mg-count-tile>
    <!-- Absentees Button -->
    <mg-generic-button
      *ngIf="tile.showAbsentees"
      colorTheme="teal"
      size="small"
      variant="pill"
      [id]="'checkin-dashboard-click-absent'"
      [isRounded]="false"
      [isWide]="true"
      iconRight="right-arrow-active"
      (click)="goToReports(tile.id, true)">
      {{ tile.numberOfAbsentees || 0 }} absentees
    </mg-generic-button>
  </div>
</ng-template>

<!-- Quick Actions Template -->
<ng-template #quickActionsTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap.lt-md="8px"
    class="quick-action-container">
    <div fxFlex.gt-sm="nogrow">
      <span class="todays-date">{{ todaysDate }}</span>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutAlign.lt-md="space-between center"
      fxLayoutGap="15px">
      <div
        fxFlex="nogrow"
        fxLayoutAlign="row"
        fxLayoutAlign="center center"
        fxLayoutGap="8px"
        fxHide.lt-md>
        <mg-form-checkbox
          [checked]="checkinDashboard.pollState$ | async"
          [disabled]="checkinDashboard.isLoading$ | async"
          (changed)="checkinDashboard.togglePollingState()">
        </mg-form-checkbox>
        <mg-text variant="body-xs-secondary">
          {{ MESSAGES.AUTO_REFRESH }}
        </mg-text>
      </div>
      <div fxFlex="nogrow">
        <mg-btn
          variant="text"
          size="small"
          iconSet="material"
          icon="refresh"
          (pressed)="checkinDashboard.refreshReasons()">
          {{ MESSAGES.REFRESH_DASHBOARD_TITLE }}
        </mg-btn>
      </div>
    </div>
  </div>
</ng-template>
