import { Injectable, OnDestroy } from '@angular/core';

import * as day from 'dayjs';
import { Observable, ReplaySubject, Subject, merge } from 'rxjs';
import { distinct, pairwise, takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { firebase } from 'minga/app/src/firebase';
import { RealtimeEventType } from 'minga/domain/realtimeEvents';
import { CONFIG } from 'minga/shared/realtime_events/config';

import { AuthInfoService } from '../minimal/services/AuthInfo';
import { MingaStoreFacadeService } from '../store/Minga/services';
import { EventSubscription } from './RealtimeEvents.types';

@Injectable({ providedIn: 'root' })
export class RealtimeEvents implements OnDestroy {
  private _activeMingaHash: string;
  private _subscriptions: {
    [eventName in RealtimeEventType]?: Subject<EventSubscription>;
  } = {};
  private _destroyedSubj = new ReplaySubject<void>(1);
  private _firestoreUnsubscriber: firebase.Unsubscribe;
  constructor(
    private _authInfo: AuthInfoService,
    private _mingaStore: MingaStoreFacadeService,
  ) {}

  public async init() {
    this._authInfo.authPersonHash$
      .pipe(takeUntil(this._destroyedSubj), distinct(), pairwise())
      .subscribe(([prevHash, currHash]) => {
        this._setupListener(prevHash, currHash);
      });

    this._mingaStore
      .getMingaAsObservable()
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(minga => {
        if (minga) {
          this._activeMingaHash = minga.hash;
        }
      });
  }

  public observe(
    types: RealtimeEventType | RealtimeEventType[],
  ): Observable<EventSubscription> {
    const typesArray = Array.isArray(types) ? types : [types];

    const validatedTypes = typesArray
      .map(type => $enum(RealtimeEventType).asValueOrDefault(type, null))
      .filter(t => t);

    const observables = validatedTypes.map(type =>
      this._getOrCreateSubject(type).asObservable(),
    );

    return merge(...observables);
  }

  private _getOrCreateSubject(type: RealtimeEventType) {
    if (!this._subscriptions[type]) {
      this._subscriptions[type] = new Subject<EventSubscription>();
    }

    return this._subscriptions[type];
  }

  private _setupListener(
    prevAuthPersonHash: string,
    currAuthPersonHash: string,
  ) {
    if (!currAuthPersonHash) return;

    if (prevAuthPersonHash && this._firestoreUnsubscriber) {
      this._firestoreUnsubscriber();
    }

    const twoMinsAgo = day().subtract(2, 'minutes');

    try {
      this._firestoreUnsubscriber = firebase
        .firestore()
        .collection(CONFIG.collectionName)
        .doc(currAuthPersonHash)
        .collection(CONFIG.subCollection)
        .where('handledAt', '==', null)
        // lets limit to the last couple minutes
        .where('createdAt', '>', twoMinsAgo.toDate())
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            if (this._isValidEvent(change)) {
              this._handleAddedDoc(change.doc);
            }
          });
        });
    } catch (e) {
      console.error('realtime snapshot error', e);
    }
  }

  private async _handleAddedDoc(doc: firebase.firestore.QueryDocumentSnapshot) {
    this._publish(doc);
    this._updateHandledAt(doc);
  }

  private async _updateHandledAt(
    doc: firebase.firestore.QueryDocumentSnapshot,
  ) {
    try {
      await doc.ref.update({ handledAt: new Date() });
    } catch (e) {
      console.error('error updating realtime doc', e);
    }
  }

  private _publish(doc: firebase.firestore.QueryDocumentSnapshot) {
    const type = doc.data().type;
    const payload = doc.data().payload;
    const createdAt = doc.data().createdAt;

    if (this._subscriptions[type]) {
      this._subscriptions[type].next<EventSubscription>({
        type,
        payload,
        createdAt: createdAt.toDate(),
      });
    }
  }

  private _isValidEvent(change: firebase.firestore.DocumentChange): boolean {
    return (
      change.type === 'added' &&
      // lets only display events for the active minga
      change.doc.data().mingaHash === this._activeMingaHash
    );
  }

  ngOnDestroy(): void {
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
    Object.values(this._subscriptions).forEach(subj => {
      subj.complete();
    });
  }
}
