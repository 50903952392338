import { Component, Input } from '@angular/core';

export enum MingaCountryEnum {
  CAN = 'CAN',
  USA = 'USA',
  INTL = 'INTL',
}

@Component({
  selector: 'mg-country',
  templateUrl: './Country.component.html',
  styleUrls: ['./Country.component.scss'],
})
export class CountryComponent {
  @Input()
  country: MingaCountryEnum | null = null;

  @Input()
  active: boolean = false;

  @Input()
  hideText: boolean = false;
}
