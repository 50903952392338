import { NgModule } from '@angular/core';

import { MgPipesModule } from 'minga/app/src/app/pipes';

import { MgNotificationBaseModule } from '../MgNotificationBase';
import { MgEventNotificationElement } from './MgEventNotification.element';

@NgModule({
  imports: [
    // Minga dependencies
    MgNotificationBaseModule,
    MgPipesModule,

    // External dependencies
  ],
  declarations: [MgEventNotificationElement],
  exports: [MgEventNotificationElement],
})
export class MgEventNotificationModule {}
