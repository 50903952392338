import { Banner } from 'minga/proto/gateway/banner_pb';
import { lerp } from 'minga/util';

export interface IHeadlineStyleOptions {
  containerWidth: number;
  maxLength: number;
  minFontSize?: number;
}

export function createHeadlineStyle(
  headlineText: string,
  headlineOptions: Banner.HeadlineOptions.AsObject,
  styleOptions: IHeadlineStyleOptions,
) {
  // Clear out the html before measuring
  {
    const div = document.createElement('div');
    div.innerHTML = headlineText;
    headlineText = div.textContent || '';
  }

  const styleObj: any = {};

  const offsetX = headlineOptions.offsetX * 100;
  const offsetY = headlineOptions.offsetY * 100;

  switch (headlineOptions.anchor) {
    case Banner.HeadlineOptions.Anchor.TOP_LEFT:
      styleObj.top = offsetY + '%';
      styleObj.left = offsetX + '%';
      styleObj.right = 50 - offsetX + '%';
      break;
    case Banner.HeadlineOptions.Anchor.TOP_CENTER:
      styleObj.top = offsetY + '%';
      styleObj.left = offsetX + '%';
      styleObj.right = offsetX + '%';
      break;
    case Banner.HeadlineOptions.Anchor.TOP_RIGHT:
      styleObj.top = offsetY + '%';
      styleObj.right = offsetX + '%';
      styleObj.left = 50 - offsetX + '%';
      break;
    case Banner.HeadlineOptions.Anchor.MID_LEFT:
      styleObj.left = offsetX + '%';
      styleObj.top = '50%';
      styleObj.transform = `translateY(-50%)`;
      styleObj.right = 50 - offsetX + '%';
      break;
    case Banner.HeadlineOptions.Anchor.MID_RIGHT:
      styleObj.right = offsetX + '%';
      styleObj.top = '50%';
      styleObj.transform = `translateY(-50%)`;
      styleObj.left = 50 - offsetX + '%';
      break;
    case Banner.HeadlineOptions.Anchor.MID_CENTER:
      styleObj.right = offsetX + '%';
      styleObj.left = offsetX + '%';
      styleObj.top = '50%';
      styleObj.transform = `translateY(-50%)`;
      break;
    case Banner.HeadlineOptions.Anchor.BTM_LEFT:
      styleObj.bottom = offsetY + '%';
      styleObj.left = offsetX + '%';
      styleObj.right = 50 - offsetX + '%';
      break;
    case Banner.HeadlineOptions.Anchor.BTM_RIGHT:
      styleObj.bottom = offsetY + '%';
      styleObj.right = offsetX + '%';
      styleObj.left = 50 - offsetX + '%';
      break;
    case Banner.HeadlineOptions.Anchor.BTM_CENTER:
      styleObj.bottom = offsetY + '%';
      styleObj.left = offsetX + '%';
      styleObj.right = offsetX + '%';
      break;
  }

  const multiplier = headlineText.length / styleOptions.maxLength;

  // fallback to our min values in case data is missing or set to zero...
  if (!headlineOptions.maxFontSize) {
    headlineOptions.maxFontSize = 3;
    console.error(`createHeadlineStyle headlineOptions.maxFontSize is zero!`);
  }
  if (!headlineOptions.minFontSize) {
    headlineOptions.minFontSize = 3;
    console.error(`createHeadlineStyle headlineOptions.minFontSize is zero!`);
  }

  let fontSize = lerp(
    headlineOptions.maxFontSize,
    headlineOptions.minFontSize,
    multiplier,
  );
  fontSize = styleOptions.containerWidth * (fontSize / 100);

  // Don't shoot yourself in the foot with too small a font-size
  if (headlineOptions.minFontSize && fontSize < headlineOptions.minFontSize) {
    fontSize = headlineOptions.minFontSize;
  }

  styleObj.fontSize = fontSize + 'px';

  switch (headlineOptions.align) {
    case Banner.HeadlineOptions.Align.CENTER:
      styleObj.textAlign = 'center';
      break;
    case Banner.HeadlineOptions.Align.LEFT:
      styleObj.textAlign = 'left';
      break;
    case Banner.HeadlineOptions.Align.RIGHT:
      styleObj.textAlign = 'right';
      break;
  }

  styleObj.color = headlineOptions.fontColor;
  styleObj.fontFamily = headlineOptions.fontFamily || 'Muli';
  styleObj.fontWeight = headlineOptions.fontWeight;
  if (headlineOptions.italic) {
    styleObj.fontStyle = 'italic';
  }

  if (headlineOptions.lineHeight > 0) {
    styleObj.lineHeight = '' + headlineOptions.lineHeight;
  } else {
    styleObj.lineHeight = 'normal';
  }

  return styleObj;
}
