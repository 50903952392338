import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import { FormFieldDirective } from './FormField.directive';

@NgModule({
  declarations: [FormFieldDirective],
  exports: [FormFieldDirective],
  imports: [MatFormFieldModule, FormsModule],
})
export class MgFormFieldModule {}
