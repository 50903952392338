<div class="comment-content">
  <mg-clipped-html
    #clippedCommentHtml
    [fullHtml]="commentBody | mgSafeHtml">
    <mg-clipped-html-head *ngIf="comment?.personView">
      <mg-person-minimal [person]="comment?.personView"></mg-person-minimal>
    </mg-clipped-html-head>

    <mg-clipped-html-tail *ngIf="!clippedCommentHtml.shouldShowFullHtml">
      <a
        href="javascript:;"
        (click)="clippedCommentHtml.showFullHtmlClick($event)"
        >...More</a
      >
    </mg-clipped-html-tail>
  </mg-clipped-html>
  <div class="comment-subtext">
    <span class="date-posted">{{ humanize(comment?.timestamp) }}</span>
    <a href="javascript:;">
      <mg-like
        class="action no-before like mg-cancel-content-link"
        [count]="comment?.likeCount"
        [context]="comment?.commentContextHash"
        [showtext]="true"
        [hideicon]="true"
        [hidecount]="true">
      </mg-like>
    </a>
    <ng-container *ngIf="!disableActions">
      <a
        href="javascript:;"
        class="action reply"
        *hasPermission="'CONTENT_COMMENT_CREATE'"
        (click)="commentReplyActionClick($event)"
        >Reply</a
      >
    </ng-container>
  </div>
</div>
