import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { DistrictSchoolSelectDialogModule } from 'minga/app/src/app/ftue/Welcome/containers/DistrictSchoolSelectDialog';
import { WelcomeDialogModule } from 'minga/app/src/app/ftue/Welcome/containers/WelcomeDialog';
import { WhatsNewDialogModule } from 'minga/app/src/app/ftue/Welcome/containers/WhatsNewDialog';
import { WelcomeEffects } from 'minga/app/src/app/ftue/Welcome/effects';
import { GroupsModule } from 'minga/app/src/app/groups/groups.module';

@NgModule({
  imports: [
    EffectsModule.forFeature([WelcomeEffects]),
    WelcomeDialogModule,
    DistrictSchoolSelectDialogModule,
    WhatsNewDialogModule,
    GroupsModule,
    // External dependencies
    CommonModule,
  ],
  exports: [WelcomeDialogModule, DistrictSchoolSelectDialogModule],
})
export class WelcomeModule {}
