import { Injectable } from '@angular/core';

import {
  IHallPassErrorType,
  IHallPassValidationError,
} from 'minga/domain/hallPass';
import { HallPassWithType } from 'minga/proto/hall_pass/hall_pass_pb';
import { MingaPermission, mingaSettingTypes } from 'minga/util';
import { RootService } from 'src/app/minimal/services/RootService';
import { PermissionsService } from 'src/app/permissions';
import { HallPassService } from 'src/app/services/HallPass';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { IHallPassGrantDialogData } from '@shared/components/hall-pass-grant-dialog';
import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';

@Injectable({ providedIn: 'any' })
export class CreateHallpassService {
  issuingToPersonHashes: string[] = [];
  isSelfGrantHallPass = false;
  hallpassesCreated: string[] = [];
  hallPassTypeId?: number;

  constructor(
    private _hallPassService: HallPassService,
    private _settingService: MingaSettingsService,
    private _rootService: RootService,
    private _systemAlertModal: SystemAlertModalService,
    private _permissionService: PermissionsService,
  ) {}

  /**
   * Create a hallpass.
   *
   * @param isSelfGrantHallPass
   * @param hallPassTypeId
   * @param personHashes
   * @param duration
   * @param startDate
   * @param teacherHash
   * @param note
   * @returns what hall passes were created.
   */
  public async createHallPass(
    isSelfGrantHallPass: boolean,
    hallPassTypeId: number,
    personHashes: string[],
    duration: number,
    startDate?: Date,
    teacherHash?: string,
    note?: string,
    ignoreRestrictions: boolean = false,
  ): Promise<{
    personHashes: string[];
    passIds: number[];
    passes: HallPassWithType.AsObject[];
  }> {
    this.issuingToPersonHashes = [...personHashes];
    this.hallPassTypeId = hallPassTypeId;
    this.isSelfGrantHallPass = isSelfGrantHallPass;

    const { errors, passIds, passes } =
      await this._rootService.addLoadingPromise(
        this._hallPassService.createHallPass(
          hallPassTypeId,
          personHashes,
          duration,
          {
            startDate,
            teacherHash,
            note,
            ignoreRestrictions,
          },
        ),
      );

    let result = errors;

    if (!result.length) {
      // no errors! all created!
      this.hallpassesCreated.push(...this.issuingToPersonHashes);
      const created = this.hallpassesCreated;
      // make sure we cleanup for next time.
      this.hallpassesCreated = [];
      return {
        personHashes: created,
        passIds,
        passes,
      };
    } else {
      // find which of this.issuingToPersonHashes is not in result
      const created = this.issuingToPersonHashes.filter(
        hash => !result.find(row => row.personHash === hash),
      );
      this.hallpassesCreated.push(...created);
      //remove created from this.issuingToPersonHashes
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        hash => !created.includes(hash),
      );
    }
    let granted = false;

    if (isSelfGrantHallPass) {
      // error 1
      const maxPassesErrors = result.filter(
        row => row.error === IHallPassErrorType.MAX_PASSES,
      );
      if (maxPassesErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.MAX_PASSES,
          `You've reached your hall pass daily limit`,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 2
      const noPartyErrors = result.filter(
        row => row.error === IHallPassErrorType.NO_PARTY,
      );
      if (noPartyErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.NO_PARTY,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 3
      const noPassErrors = result.filter(
        row => row.error === IHallPassErrorType.NO_PASS,
      );
      if (noPassErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.NO_PASS,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 4
      const maxActivePassesErrors = result.filter(
        row => row.error === IHallPassErrorType.MAX_ACTIVE_PASSES,
      );
      if (maxActivePassesErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.MAX_ACTIVE_PASSES,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 5
      const maxHallPassTypePassesErrors = result.filter(
        row => row.error === IHallPassErrorType.MAX_HALL_PASS_TYPE_PASSES,
      );
      if (maxHallPassTypePassesErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.MAX_HALL_PASS_TYPE_PASSES,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 6
      const notInHallPassTypeListErrors = result.filter(
        row => row.error === IHallPassErrorType.NOT_IN_HALL_PASS_TYPE_LIST,
      );
      if (notInHallPassTypeListErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.NOT_IN_HALL_PASS_TYPE_LIST,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 7
      const blackOutWindowErrors = result.filter(
        row => row.error === IHallPassErrorType.BLACK_OUT_WINDOW,
      );
      if (blackOutWindowErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.BLACK_OUT_WINDOW,
          `You're attempting to create a hall pass during a restricted time period`,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 8
      const notPastMinWaitPeriodErrors = result.filter(
        row => row.error === IHallPassErrorType.NOT_PAST_MIN_WAIT_PERIOD,
      );
      if (notPastMinWaitPeriodErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.NOT_PAST_MIN_WAIT_PERIOD,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 9
      const hallPassActiveErrors = result.filter(
        row => row.error === IHallPassErrorType.HALL_PASS_ACTIVE,
      );
      if (hallPassActiveErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.HALL_PASS_ACTIVE,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 10
      const maxTypePassesErrors = result.filter(
        row => row.error === IHallPassErrorType.MAX_TYPE_PASSES,
      );
      if (maxTypePassesErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.MAX_TYPE_PASSES,
          `You've reached your daily limit for "${maxTypePassesErrors[0].hallPassName}"`,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 11
      const hallPassNotEnabledErrors = result.filter(
        row => row.error === IHallPassErrorType.HALL_PASS_NOT_ENABLED,
      );
      if (hallPassNotEnabledErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.HALL_PASS_NOT_ENABLED,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 12
      const hallPassNotAvailableInKioskErrors = result.filter(
        row => row.error === IHallPassErrorType.NOT_AVAILABLE_IN_KIOSK,
      );
      if (hallPassNotAvailableInKioskErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.NOT_AVAILABLE_IN_KIOSK,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      // error 13
      const hallPassTypeNotActiveErrors = result.filter(
        row => row.error === IHallPassErrorType.HALL_PASS_TYPE_NOT_ACTIVE,
      );
      if (hallPassTypeNotActiveErrors.length > 0) {
        await this._launchSelfGrantStudentPassNotAllowedDialog(
          IHallPassErrorType.HALL_PASS_TYPE_NOT_ACTIVE,
        );
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      }

      const checkAndDisplayErrorCode = async (code: IHallPassErrorType) => {
        const errorList = result.filter(row => row.error === code);
        if (errorList.length > 0) {
          await this._launchSelfGrantStudentPassNotAllowedDialog(code);
        }
        return {
          personHashes: this.hallpassesCreated,
          passIds,
          passes,
        };
      };
      await checkAndDisplayErrorCode(IHallPassErrorType.NO_PARTY);
      await checkAndDisplayErrorCode(
        IHallPassErrorType.MAX_HALL_PASS_TYPE_PASSES,
      );
      await checkAndDisplayErrorCode(
        IHallPassErrorType.NOT_PAST_MIN_WAIT_PERIOD,
      );
    } else {
      // error 1
      const maxPassesErrors = result.filter(
        row => row.error === IHallPassErrorType.MAX_PASSES,
      );
      if (maxPassesErrors.length > 0) {
        granted = await this._launchTooManyPassesTodayDialog(maxPassesErrors);
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            maxPassesErrors,
          );
        }
      }

      // error 2
      const noPartyErrors = result.filter(
        row => row.error === IHallPassErrorType.NO_PARTY,
      );
      if (noPartyErrors.length > 0) {
        granted = await this._launchNoPartyDialog(noPartyErrors);
        if (!granted) {
          result = this._removePeopleFromValidationList(result, noPartyErrors);
        }
      }

      // error 3
      const noPassErrors = result.filter(
        row => row.error === IHallPassErrorType.NO_PASS,
      );
      if (noPassErrors.length > 0) {
        granted = await this._launchNoPassDialog(noPassErrors);
        if (!granted) {
          result = this._removePeopleFromValidationList(result, noPassErrors);
        }
      }

      // error 4
      const maxActivePassesErrors = result.filter(
        row => row.error === IHallPassErrorType.MAX_ACTIVE_PASSES,
      );
      if (maxActivePassesErrors.length > 0) {
        granted = await this._launchTooManyActivePassesDialog(
          maxActivePassesErrors,
        );
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            maxActivePassesErrors,
          );
        }
      }

      // error 5
      const maxHallPassTypePassesErrors = result.filter(
        row => row.error === IHallPassErrorType.MAX_HALL_PASS_TYPE_PASSES,
      );
      if (maxHallPassTypePassesErrors.length > 0) {
        granted = await this._launchTooManyActivePassesOfTypeDialog(
          maxHallPassTypePassesErrors,
        );
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            maxHallPassTypePassesErrors,
          );
        }
      }

      // error 6
      const notInHallPassTypeListErrors = result.filter(
        row => row.error === IHallPassErrorType.NOT_IN_HALL_PASS_TYPE_LIST,
      );
      if (notInHallPassTypeListErrors.length > 0) {
        granted = await this._launchRestrictedHallPassTypeErrorDialog(
          notInHallPassTypeListErrors,
        );
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            notInHallPassTypeListErrors,
          );
        }
      }

      // error 7
      const blackOutWindowErrors = result.filter(
        row => row.error === IHallPassErrorType.BLACK_OUT_WINDOW,
      );
      if (blackOutWindowErrors.length > 0) {
        granted = await this._launchActiveBlackoutWindowDialog(
          blackOutWindowErrors,
          startDate !== null,
        );
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            blackOutWindowErrors,
          );
        }
      }

      // error 8
      const notPastMinWaitPeriodErrors = result.filter(
        row => row.error === IHallPassErrorType.NOT_PAST_MIN_WAIT_PERIOD,
      );
      if (notPastMinWaitPeriodErrors.length > 0) {
        granted = await this._launcWithinWaitTimeDialog(
          notPastMinWaitPeriodErrors,
        );
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            notPastMinWaitPeriodErrors,
          );
        }
      }

      // error 9
      const hallPassActiveErrors = result.filter(
        row => row.error === IHallPassErrorType.HALL_PASS_ACTIVE,
      );
      if (hallPassActiveErrors.length > 0) {
        granted = await this._launchAlreadyHavePassDialog(hallPassActiveErrors);
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            hallPassActiveErrors,
          );
        }
      }

      // error 10
      const maxTypePassesErrors = result.filter(
        row => row.error === IHallPassErrorType.MAX_TYPE_PASSES,
      );
      if (maxTypePassesErrors.length > 0) {
        granted = await this._launchTooManyTypePassesTodayDialog(
          maxTypePassesErrors,
        );
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            maxTypePassesErrors,
          );
        }
      }

      // error 11
      const hallPassNotEnabledErrors = result.filter(
        row => row.error === IHallPassErrorType.HALL_PASS_NOT_ENABLED,
      );
      if (hallPassNotEnabledErrors.length > 0) {
        granted = await this._launchNotEnabledDialog(hallPassNotEnabledErrors);
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            hallPassNotEnabledErrors,
          );
        }
      }

      // error 12
      const notAvailableInKioskErrors = result.filter(
        row => row.error === IHallPassErrorType.NOT_AVAILABLE_IN_KIOSK,
      );
      if (notAvailableInKioskErrors.length > 0) {
        granted = await this._launchNotAvailableInKioskDialog(
          notAvailableInKioskErrors,
        );
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            notAvailableInKioskErrors,
          );
        }
      }

      // error 13
      const hallPassTypeNotActive = result.filter(
        row => row.error === IHallPassErrorType.HALL_PASS_TYPE_NOT_ACTIVE,
      );
      if (hallPassTypeNotActive.length > 0) {
        granted = await this._launchPassNotEnabledDialog(hallPassTypeNotActive);
        if (!granted) {
          result = this._removePeopleFromValidationList(
            result,
            hallPassTypeNotActive,
          );
        }
      }
    }

    const systemError =
      !granted && this.issuingToPersonHashes.length === personHashes.length;
    // there are some people still on the list, so send them to the backend
    // but this time ignore all restrictions, because the user has
    // said create the passes anyways.
    if (this.issuingToPersonHashes.length && !systemError) {
      return this.createHallPass(
        isSelfGrantHallPass,
        hallPassTypeId,
        [...this.issuingToPersonHashes],
        duration,
        startDate,
        teacherHash,
        note,
        true,
      );
    } else if (systemError) {
      // we missed some error case, avoid recursion
      this._launchSystemErrorDialog();
    }
    const created = this.hallpassesCreated;
    // make sure we cleanup for next time.
    this.hallpassesCreated = [];
    return {
      personHashes: created,
      passIds,
      passes,
    };
  }

  private async _launchNotEnabledDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `Hall Passes are currently disabled`,
      message: `To assign a new pass, hall passes need to be enabled first in the Hall Pass settings`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchNoPassDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);
    const noPassListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `NO-PASS LIST`,
      message: `${peopleNames.join(', ')} ${
        peopleNames.length > 1 ? ' are ' : ' is '
      } on the No-Pass List`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPassListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchNoPartyDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `NO-PARTY GROUP`,
      message: `${peopleNames.join(', ')} ${
        peopleNames.length > 1 ? ' are ' : ' is '
      } in an active No-Party Group. Try again in a few minutes`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchTooManyPassesTodayDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `MAX PASSES EXCEEDED FOR TODAY`,
      message: `${peopleNames.join(', ')} 
      would exceed the maximum number of passes for the day.`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchNotAvailableInKioskDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `Not available in Kiosk`,
      message: 'This hall pass is not available for kiosk use',
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchPassNotEnabledDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `This hall pass is disabled`,
      message: `To use this pass, it must be enabled first in the Hall Pass Manager`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchTooManyTypePassesTodayDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `Hall pass daily limit reached`,
      message: `${peopleNames.join(', ')} has reached the daily limit of 
      ${errors[0].maxPasses} for this hall pass`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchTooManyActivePassesDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const count = this.issuingToPersonHashes.length;

    const peopleNames = errors.map(error => error.name);
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `MAX ACTIVE PASSES EXCEEDED`,
      message: `Can't create passes for: ${peopleNames.join(
        ', ',
      )}. This will exceed your active hall pass limit by ${count}.`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchTooManyActivePassesOfTypeDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const count = this.issuingToPersonHashes.length;
    const peopleNames = errors.map(error => error.name);
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `MAX ACTIVE PASSES FOR TYPE EXCEEDED`,
      message: `Can't create passes for: ${peopleNames.join(
        ', ',
      )}. This will exceed your active hall pass type limit by ${count}.`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchAlreadyHavePassDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);

    const personHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `Hall pass cannot be assigned`,
      message: `This student already has an active pass open`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !personHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchActiveBlackoutWindowDialog(
    errors: IHallPassValidationError[],
    scheduled: boolean = false,
  ): Promise<boolean> {
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    let canBypass: boolean = await this._settingService.getSettingValue(
      mingaSettingTypes.PASS_STAFF_BLACKOUT_BYPASS,
    );
    // if the user has the permission to manage hall pass types (ie owner/manager),
    // they should be able to still create passes during a blackout window
    if (
      this._permissionService.hasPermission(
        MingaPermission.HALL_PASS_TYPE_MANAGE,
      )
    ) {
      canBypass = true;
    }

    const data: IHallPassGrantDialogData = {
      peopleNames: [],
      title: `BLACKOUT WINDOW ACTIVE`,
      message: `${
        errors.length > 1 ? 'Students' : 'Student'
      } cannot be issued ${
        errors.length > 1 ? 'hall passes' : 'a hall pass'
      } because a Blackout Window ${
        scheduled ? 'will be' : 'is currently'
      } active`,
      canBypass,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launcWithinWaitTimeDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);
    const hallpassWaitTimePersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `Hall Passes are currently disabled`,
      message: `To assign a new pass, hall passes need to be enabled first in the Hall Pass settings`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !hallpassWaitTimePersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchRestrictedHallPassTypeErrorDialog(
    errors: IHallPassValidationError[],
  ): Promise<boolean> {
    const peopleNames = errors.map(error => error.name);
    const noPartyListPersonHashes = errors.map(error => error.personHash);
    const data: IHallPassGrantDialogData = {
      peopleNames,
      title: `HALL PASS IS RESTRICTED TO SPECIFIC PEOPLE`,
      message: `${peopleNames.join(', ')} ${
        peopleNames.length > 1 ? 'are' : 'is'
      } not approved to receive this hall pass type.`,
    };

    const result = await this._openHallPassGrantDialog(data);
    if (!result) {
      // personHashes included in the no pass list must be filtered out
      this.issuingToPersonHashes = this.issuingToPersonHashes.filter(
        personHash => {
          return !noPartyListPersonHashes.includes(personHash);
        },
      );
    }

    return result;
  }

  private async _launchSelfGrantStudentPassNotAllowedDialog(
    errorCode: number,
    message?: string,
  ) {
    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.ERROR,
      heading: 'Hall pass request denied',
      message: message ? message : `(CODE: ${errorCode})`,
    });
    await modalRef.afterClosed().toPromise();

    this.issuingToPersonHashes = [];
  }

  private async _launchSystemErrorDialog() {
    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.ERROR,
      heading: 'System Error',
      message: `An error occurred while creating hall passes. Please try again later.`,
    });
    await modalRef.afterClosed().toPromise();

    this.issuingToPersonHashes = [];
  }

  private async _openHallPassGrantDialog(
    data: IHallPassGrantDialogData,
  ): Promise<boolean> {
    const canBypass = data.canBypass ?? true;

    // logic and labels for confirm and close buttons are funky because of design requirements
    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.ERROR,
      heading: data.title,
      message: data.message,
      confirmActionBtn: canBypass ? 'Close' : '',
      closeBtn: canBypass ? 'Assign Pass' : 'Close',
    });
    const response = await modalRef.afterClosed().toPromise();

    // actual logic: if you click a button that says assign pass, return true,
    if (canBypass) return response.type === 'CLOSE' ? true : false;
    return false;
  }

  private _removePeopleFromValidationList(
    errorList: IHallPassValidationError[],
    targetList: IHallPassValidationError[],
  ): IHallPassValidationError[] {
    return errorList.filter(validation => {
      const found = targetList.find(noPassValidation => {
        return noPassValidation.personHash === validation.personHash;
      });
      return found ? false : true;
    });
  }
}
