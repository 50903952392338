<mat-form-field
  hideRequiredMarker
  mgFormField
  [appearance]="appearance">
  <span
    matPrefix
    *ngIf="prefix"
    >{{ prefix }}</span
  >
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select
    [errorStateMatcher]="matcher"
    [disabled]="disabled"
    [required]="required"
    [ngModel]="selectedRoleType$ | async"
    (ngModelChange)="selectedRoleType$.next($event)">
    <mat-option *ngIf="!disallowNone">None</mat-option>
    <mat-option
      *ngFor="let role of roleSelectItems$ | async"
      [value]="role.roleType"
      [disabled]="role.disabled"
      >{{ role.name }}
    </mat-option>
  </mat-select>

  <mat-hint>{{ hint }}</mat-hint>
  <mat-error>{{ 'input.roleSelect.error.required' | translate }}</mat-error>
</mat-form-field>
