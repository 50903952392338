<!-- Main Content -->
<div class="dialog-body">
  <div class="dialog-description">
    {{ text.description || MESSAGES.DEFAULT_MESSAGE }}
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between end"
    fxLayoutGap="15px">
    <mg-btn
      variant="text"
      (pressed)="handleOnClickCancel()">
      {{ MESSAGES.BUTTON_CANCEL }}
    </mg-btn>
    <mg-btn
      variant="filled"
      (pressed)="handleOnClickConfirmed()">
      {{ text.deleteBtn || MESSAGES.BUTTON_CONFIRM }}
    </mg-btn>
  </div>
</div>
