import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MgButtonModule } from 'minga/app/src/app/button';
import { AvatarClusterModule } from 'minga/app/src/app/components/AvatarCluster';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { PointsBannerModule } from 'minga/app/src/app/components/Points/PointsBanner';
import { StreamSwipeStackModule } from 'minga/app/src/app/components/StreamSwipeStack';
import { MgContentLinkModule } from 'minga/app/src/app/content-link';
import { ChallengeResponseLongCardModule } from 'minga/app/src/app/content-views/components/ChallengeResponseLongCard';
import { MgAvatarModule } from 'minga/app/src/app/elements/MgAvatar';
import { MgEllipsisModule } from 'minga/app/src/app/elements/MgEllipsis';
import { MgPersonMinimalModule } from 'minga/app/src/app/elements/MgPersonMinimal';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MgOverlayToolsModule } from 'minga/app/src/app/overlay/tools';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { UgcLinksContentPipeModule } from 'minga/app/src/app/ugc-links/pipes/UgcLinksContent';

import { LongCardBaseModule } from '../LongCardBase';

import { ChallengeLongCardComponent } from './ChallengeLongCard.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgButtonModule,
    MgAvatarModule,
    MgPersonMinimalModule,
    MgEllipsisModule,
    MgPipesModule,
    LongCardBaseModule,
    MgImageModule,
    UgcLinksContentPipeModule,
    PermissionsModule,
    MgOverlayToolsModule,
    MgIconModule,
    AvatarClusterModule,
    ChallengeResponseLongCardModule,
    StreamSwipeStackModule,
    MgContentLinkModule,
    PointsBannerModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    RouterModule,
  ],
  declarations: [ChallengeLongCardComponent],
  exports: [ChallengeLongCardComponent],
})
export class ChallengeLongCardModule {}
