<mg-manager-overlay-subpage
  *ngIf="layout.useLegacyLayout$ | async; else newLayoutTemplate"
  [loading]="ftManager.isLoading$ | async">
  <!-- Subpage Header -->
  <ng-template #subpageHeader>
    <section class="header">
      <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between start"
        fxFlex="100%"
        fxLayoutGap="10px">
        <div class="header-col">
          <mg-text variant="header-xl-secondary">{{ MSG.PAGE_TITLE }}</mg-text>
        </div>
        <div class="header-col right-col">
          <ng-container *ngIf="activePeriod$ | async as activePeriod">
            <mg-text
              variant="body-md"
              spacing="1">
              {{ activePeriod.title }} -
              {{ activePeriod.date | date: 'MMM d' }}
            </mg-text>
            <mg-text variant="header-lg-secondary">
              {{ activePeriod | formatTimeRange }}
            </mg-text>
          </ng-container>
        </div>
      </div>
    </section>
    <section>
      <ng-container [ngTemplateOutlet]="filtersSection"></ng-container>
    </section>
  </ng-template>
  <ng-container [ngTemplateOutlet]="mainContentTemplate"></ng-container>
</mg-manager-overlay-subpage>

<!-- Subpage Header -->
<ng-template #filtersSection>
  <mat-accordion class="filters-panel">
    <mat-expansion-panel
      [expanded]="(isMobileView$ | async) ? panelOpenState : true"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      class="reversed-mat-expansion-panel">
      <mat-expansion-panel-header
        *ngIf="isMobileView$ | async"
        matRipple
        matRippleColor="rgba(0, 0, 0, .1)">
        <mg-text
          color="primary"
          variant="label-md-secondary"
          textAlign="center">
          {{ panelOpenState ? MSG.HIDE_FILTERS_LABEL : MSG.SHOW_FILTERS_LABEL }}
        </mg-text>
      </mat-expansion-panel-header>
      <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutGap="10px">
        <!-- Header Left Content -->
        <div fxFlex>
          <div class="filters-controls">
            <div
              class="filters-controls-flex"
              fxLayout="column"
              fxLayout.gt-sm="row wrap"
              fxLayoutAlign="start stretch">
              <div
                fxFlex.gt-sm="325px"
                class="filters-controls-date">
                <mg-form-date-range [range]="range"></mg-form-date-range>
              </div>
              <div fxFlex.gt-sm="300px">
                <mg-form-select
                  [placeholder]="MSG.FIELD_LABEL_FLEX_PERIOD"
                  [labelBackground]="
                    (layout.useLegacyLayout$ | async) ? 'grey' : 'white'
                  "
                  [isClearable]="false"
                  [value]="ftManager.periodFilter$ | async"
                  [options]="ftManager.periodOptions$ | async"
                  (selectionChanged)="ftManager.setPeriodFilter($event)">
                </mg-form-select>
              </div>
              <div fxFlex.gt-sm="300px">
                <mg-form-text-input
                  [label]="MSG.FIELD_LABEL_SEARCH"
                  [labelBackground]="
                    (layout.useLegacyLayout$ | async) ? 'grey' : 'white'
                  "
                  [control]="searchControl">
                </mg-form-text-input>
              </div>
              <div fxFlex.gt-sm="300px">
                <mg-multi-person-search
                  (selectionChanged)="changePersonHashes($event)">
                </mg-multi-person-search>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.xs="start stretch"
    fxLayoutGap="16px"
    class="filters-second-row">
    <div>
      <mat-button-toggle-group
        name="showMineFilter"
        class="filter-toggle-group"
        [value]="showMineFilter$ | async"
        (change)="setShowMineFilter($event.value)">
        <mat-button-toggle value="all">
          <mg-text variant="body-sm-secondary">
            {{ MSG.BUTTON_ALL_ACTIVITIES }}
          </mg-text>
        </mat-button-toggle>
        <mat-button-toggle value="mine">
          <mg-text variant="body-sm-secondary">
            {{ MSG.BUTTON_MY_ACTIVITIES }}
          </mg-text>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div *ngIf="activePeriod$ | async as activePeriod">
      <div
        *ngIf="ftManager.periodFilter$ | async as periodFilter"
        fxFlex="grow"
        fxFlex.gt-xs="nogrow"
        fxLayoutAlign="center center"
        fxLayoutAlign.gt-sm="end end">
        <mg-btn
          class="add-activity-button"
          variant="filled"
          [disabled]="
            isInPast(activePeriod) ||
            (!activePeriod.enableActivityManagement &&
              !('FLEX_TIME_MANAGE' | hasPermission))
          "
          (pressed)="addActivity(periodFilter)">
          {{ MSG.BUTTON_ADD_ACTIVITY }}
        </mg-btn>
      </div>
    </div>
  </div>
</ng-template>

<!-- New layout template -->
<ng-template #newLayoutTemplate>
  <ng-container [ngTemplateOutlet]="filtersSection"></ng-container>
  <div
    *ngIf="activePeriod$ | async as activePeriod"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="start end"
    fxLayoutAlign.lt-sm="end start"
    fxLayoutGap="8px"
    fxFlex="nogrow"
    class="period-info">
    <mg-text
      variant="body-md"
      spacing="1">
      {{ activePeriod.title }} -
      {{ activePeriod.date | date: 'MMM d' }}
    </mg-text>
    <mg-text variant="header-md-secondary">
      {{ activePeriod | formatTimeRange }}
    </mg-text>
  </div>
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</ng-template>

<!-- Main Content Template -->
<ng-template #mainContentTemplate>
  <!-- Table -->
  <mat-table
    matSort
    [dataSource]="dataSource"
    [trackBy]="trackById">
    <!-- Asset Column -->
    <ng-container matColumnDef="asset">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_ASSET }}
      </mat-header-cell>
      <mat-cell
        *matCellDef="let item"
        [ngClass]="{
          'mat-column-image':
            item.flexTimeActivity?.bannerImage ||
            item.flexTimeActivity?.imagePath
        }">
        <mg-ftm-activity-img
          [bannerImage]="item.flexTimeActivity?.bannerImage"
          [imagePath]="item.flexTimeActivity?.imagePath"></mg-ftm-activity-img>
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="flexTimeActivity.name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_LABEL_TITLE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.flexTimeActivity?.name }}
      </mat-cell>
    </ng-container>

    <!-- Teacher Column -->
    <ng-container matColumnDef="flexTimeActivity.createdBy">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_LABEL_TEACHER }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.flexTimeActivity?.createdByPerson?.name }}
      </mat-cell>
    </ng-container>

    <!-- Location Name Column -->
    <ng-container matColumnDef="flexTimeActivity.location">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ MSG.COLUMN_LABEL_LOCATION }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        {{ item.flexTimeActivity?.location }}
      </mat-cell>
    </ng-container>

    <!-- Registered Column -->
    <ng-container matColumnDef="registeredCount">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_STUDENTS }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <ng-container
          [ngTemplateOutlet]="assignBtn"
          [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Checkin Column -->
    <ng-container matColumnDef="checkInAction">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_CHECKIN }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <ng-container
          [ngTemplateOutlet]="checkinBtn"
          [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Remove Column -->
    <ng-container matColumnDef="remove">
      <mat-header-cell *matHeaderCellDef>
        {{ MSG.COLUMN_LABEL_REMOVE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let item">
        <ng-container
          [ngTemplateOutlet]="removeBtn"
          [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-container>
      </mat-cell>
    </ng-container>

    <!-- Mobile View -->
    <ng-container matColumnDef="mobile">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let item">
        <div
          class="mobile-container"
          fxLayout="column"
          fxLayoutAlign="space-between center"
          [style.width.%]="100">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
            [style.width.%]="100">
            <!-- Name -->
            <div fxFlex="grow">
              <mg-text
                fontWeight="700"
                variant="body-md"
                spacing="2">
                {{ item.flexTimeActivity?.name }}
              </mg-text>
              <mg-text
                variant="label-md"
                element="p"
                spacing="1">
                <span>
                  <strong>{{ MSG.COLUMN_LABEL_TEACHER }}: </strong
                  >{{ item.flexTimeActivity?.createdByPerson?.name }}
                </span>
              </mg-text>
              <mg-text
                variant="label-md"
                element="p"
                spacing="1">
                <span>
                  <strong>{{ MSG.COLUMN_LABEL_LOCATION }}: </strong
                  >{{ item.flexTimeActivity?.location }}
                </span>
              </mg-text>
            </div>

            <!-- Edit -->
          </div>
          <div class="mobile-divider"></div>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            [style.width.%]="100">
            <ng-container
              [ngTemplateOutlet]="assignBtn"
              [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
            <ng-container
              [ngTemplateOutlet]="checkinBtn"
              [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
            <ng-container
              [ngTemplateOutlet]="removeBtn"
              [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns$ | async"
      [fxHide]="
        ((ftManager.isLoadingInstances$ | async) === false && showEmptyState) ||
        ((hasPeriods$ | async) === false && showEmptyState)
      ">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns$ | async">
    </mat-row>
  </mat-table>

  <!-- Loading -->
  <div
    *ngIf="(ftManager.isLoadingInstances$ | async) && (hasPeriods$ | async)"
    class="loading-container">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>

  <div
    [fxHide]="
      ((ftManager.isLoadingInstances$ | async) === false && showEmptyState) ||
      ((hasPeriods$ | async) === false && showEmptyState)
    "
    class="paginator-container">
    <mg-paginator> </mg-paginator>
  </div>

  <ng-container
    *ngIf="(hasPeriods$ | async) === false && showEmptyState; else noActivities"
    [ngTemplateOutlet]="noPeriods">
  </ng-container>
</ng-template>

<!-- Empty State (Activities) -->
<ng-template #noActivities>
  <ng-container
    *ngIf="(ftManager.isLoadingInstances$ | async) === false && showEmptyState">
    <mg-empty-state
      [title]="MSG.NO_ACTIVITIES_TITLE"
      [subtitle]="MSG.NO_ACTIVITIES_SUBTITLE">
    </mg-empty-state>
  </ng-container>
</ng-template>

<!-- Empty State (Periods) -->
<ng-template #noPeriods>
  <mg-empty-state
    [title]="MSG.NO_PERIODS_TITLE"
    [subtitle]="MSG.NO_PERIODS_SUBTITLE">
  </mg-empty-state>
</ng-template>

<ng-template
  #assignBtn
  let-item>
  <mg-btn
    iconSet="minga"
    icon="mg-user-list"
    variant="text"
    size="small"
    [disabled]="item.flexTimePeriod.enableStudentManagement ? false : !isAdmin"
    (pressed)="assignActivity(item)">
    {{ item | activityRegistered }}
  </mg-btn>
</ng-template>

<ng-template
  #checkinBtn
  let-item>
  <mg-btn
    class="checkin-button"
    iconSet="minga"
    icon="mg-check-in-outlined"
    variant="filled"
    size="small"
    (pressed)="activityCheckIn(item)">
    {{ item | activityCheckin }}
  </mg-btn>
</ng-template>

<ng-template
  #removeBtn
  let-item>
  <mg-btn
    icon="trash"
    iconSet="minga"
    variant="icon"
    [disabled]="
      isAdmin
        ? false
        : !(item | canEditActivityInstance) ||
          !item.flexTimePeriod.enableActivityManagement
    "
    (pressed)="deleteActivityInstance(item)"></mg-btn>
</ng-template>
