import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mg-message-dialog',
  templateUrl: './MessageDialog.component.html',
  styleUrls: ['./MessageDialog.component.scss'],
})
export class MessageDialogComponent {
  text: string = '';
  title: string = '';
  matIcon: string = '';
  mgIcon: string = '';
  iconColor: string = '';
  translate: boolean = false;
  iconBackground: string = '';

  constructor(
    private dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data['text']) {
      this.text = data['text'];
    }
    if (data['title']) {
      this.title = data['title'];
    }
    if (data['mat-icon']) {
      this.matIcon = data['mat-icon'];
    }
    if (data['mg-icon']) {
      this.mgIcon = data['mg-icon'];
    }
    if (data['icon-color']) {
      this.iconColor = data['icon-color'];
    }
    if (data['translate']) {
      this.translate = data['translate'];
    } else {
      this.translate = false;
    }
  }

  get translateText() {
    return this.translate;
  }

  get iconColorClass() {
    let colorName = this.iconColor ? this.iconColor : 'error';
    return 'color-' + colorName;
  }

  close() {
    this.dialogRef.close();
  }
}
