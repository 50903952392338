import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';

import { ReplaySubject } from 'rxjs';

import * as mgUtil from 'minga/util';
import { ContentEvents } from 'minga/app/src/app/minimal/services/ContentEvents';
import { StreamManager } from 'minga/app/src/app/minimal/services/StreamManager';
import { PermissionsService } from 'minga/app/src/app/permissions';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { ReportService } from 'minga/app/src/app/services/Report';
import { ReportStreamManager } from 'minga/proto/gateway/content_report_ng_grpc_pb';

import { LayoutService } from '@modules/layout/services';

import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { IReportContentFilter } from '../../types/minga-report-feed-base.types';
import { MingaReportFeedBaseClass } from '../../utils/minga-report-feed-base.utils';

@Component({
  selector: 'mg-moderation-reports',
  templateUrl: './moderation-reports.component.html',
  styleUrls: ['./moderation-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModerationReportsComponent
  extends MingaReportFeedBaseClass
  implements OnInit, OnDestroy
{
  // Clean up
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  get feedName() {
    if (this.globalContent) {
      return `MingaGlobalReportFeed`;
    } else {
      return `MingaReportFeed`;
    }
  }

  /** Component Constructor */
  constructor(
    public layout: LayoutService,
    protected router: Router,
    protected reportService: ReportService,
    protected contentEvents: ContentEvents,
    protected systemAlertSnackBar: SystemAlertSnackBarService,
    protected route: ActivatedRoute,
    protected permissions: PermissionsService,
    protected contentState: ContentState,
    protected streamManager: StreamManager,
    protected media: MediaObserver,
    public reportStreamManager: ReportStreamManager,
  ) {
    super(
      router,
      reportService,
      contentEvents,
      systemAlertSnackBar,
      route,
      permissions,
      contentState,
      streamManager,
      media,
    );
    this.defaultNoResultText = `reported content`;
  }

  ngOnInit() {
    this.initSetup();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this.unsubscribeSubscriptions();
  }

  async initTabs(resetToFirst: boolean = true) {
    this.primaryStatFilters = [
      {
        title: 'Unresolved',
        countText: this.getTabStatText(mgUtil.ReportFilter.REPORTED),
        total: 0,
        cached: true,
        primary: true,
        filterActive: true,
        searchFilter: mgUtil.ReportFilter.REPORTED,
        filterLinks: [
          {
            text: 'unresolved reported content',
          },
        ],
      },
      {
        title: 'Resolved',
        countText: this.getTabStatText(mgUtil.ReportFilter.RESOLVED),
        total: 0,
        cached: true,
        primary: true,
        searchFilter: mgUtil.ReportFilter.RESOLVED,
        filterLinks: [
          {
            text: 'resolved reported content',
          },
        ],
      },
    ];

    if (resetToFirst) {
      this.resetToFirstFilter();
    }
  }

  updateContentFilter(reportFilter: mgUtil.ReportFilter) {
    const filter: IReportContentFilter = {
      resolved: reportFilter === mgUtil.ReportFilter.RESOLVED,
      global: this.globalContent,
    };
    this.contentFilter = filter;
  }
}
