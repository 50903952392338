import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { PaginatorComponent } from '@shared/components/paginator';
import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { MediaBreakpoints, MediaService } from '@shared/services/media';
import { xlsxExport } from '@shared/utils/xlsx-export';

import { PS_SUMMARY_TABLE_COLUMNS, PsSummaryMessage } from '../../constants';
import {
  PsSummaryErrorsData,
  PsSummaryModalData,
  PsSummaryResponse,
} from '../../types';

@Component({
  selector: 'mg-ps-summary',
  templateUrl: './ps-summary.component.html',
  styleUrls: ['./ps-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsSummaryComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Child Components */
  @ViewChild(MatTable) matTable: MatTable<PsSummaryErrorsData>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  /** Constants */
  public readonly MSG = PsSummaryMessage;

  /** General Subjects */
  private _destroyedSubject = new ReplaySubject<void>(1);

  /** Displayed Columns */
  public readonly displayedColumns$ = this.media.breakpoint$.pipe(
    takeUntil(this._destroyedSubject),
    map(breakpoint => {
      const columns = [...PS_SUMMARY_TABLE_COLUMNS].filter(
        column => !(this.modalData.displayOnly && column === 'select'),
      );
      const showMobileViewOn: MediaBreakpoints[] = ['xsmall'];
      const displayedColumns = showMobileViewOn.includes(breakpoint)
        ? ['mobile']
        : columns || [];
      return displayedColumns;
    }),
  );

  /** DataSource */
  public readonly dataSource = new MatTableDataSource<PsSummaryErrorsData>();

  /** Error Count */
  private readonly _errorCountSubject = new BehaviorSubject<number>(0);
  public readonly errorCount$ = this._errorCountSubject.asObservable().pipe(
    takeUntil(this._destroyedSubject),
    map(v => v + ' people ' + this.modalData.message),
  );

  /** Selection */
  private _selectableData: PsSummaryErrorsData[];
  public readonly selection = new SelectionModel<PsSummaryErrorsData>(true, []);

  /** Component Constructor */
  constructor(
    public media: MediaService,
    @Inject(MODAL_OVERLAY_DATA) public modalData: PsSummaryModalData,
    private _modalOverlayRef: ModalOverlayRef<
      PsSummaryResponse,
      PsSummaryModalData
    >,
    private _cdr: ChangeDetectorRef,
    private _systemAlertModal: SystemAlertModalService,
  ) {}

  ngOnInit(): void {
    this._initTable(this.modalData.errorsData);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator.matPaginator;
    this._cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._errorCountSubject.complete();
  }

  public async submit(): Promise<void> {
    this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.SUBMIT, {
      selectionHashes: this.selection.selected.map(({ hash }) => hash),
    });
  }

  public async close(): Promise<void> {
    const totalOptions = this._selectableData.length;
    const heading = `${totalOptions} ${
      totalOptions === 1 ? 'person was' : 'people were'
    } not assigned`;
    const confirmationModalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.WARNING,
      message:
        'Are you sure you want to close the summary without adding anyone?',
      disableClose: true,
      heading,
      confirmActionBtn: 'Confirm',
      closeBtn: 'Back',
    });
    const { type } = await confirmationModalRef.afterClosed().toPromise();
    switch (type) {
      case SystemAlertCloseEvents.CLOSE: {
        break;
      }
      case SystemAlertCloseEvents.CONFIRM: {
        this._modalOverlayRef.close(ModalOverlayServiceCloseEventType.CLOSE);
        break;
      }
      default: {
        break;
      }
    }
  }

  public async exportList(): Promise<void> {
    const headers = ['Status', 'Name', 'Reason'];
    if (
      this.modalData.errorsData.some(
        ({ reasonDescriptions }) => reasonDescriptions,
      )
    ) {
      headers.push('Description');
    }
    const rows = this.modalData.errorsData.map(item => [
      item.status,
      item.name,
      item.reasons,
      item?.reasonDescriptions,
    ]);
    xlsxExport(headers, rows, this.modalData.title + '.xlsx');
  }

  public trackById(index: number, item: PsSummaryErrorsData) {
    return item ? item.hash : index;
  }

  public isAllSelected() {
    return this.selection.selected.length === this._selectableData.length;
  }

  public masterToggle() {
    return this.isAllSelected()
      ? this.selection.clear()
      : this._selectableData.forEach(row => this.selection.select(row));
  }

  private _initTable(data: PsSummaryErrorsData[]) {
    const mappedValues = new Map<string, PsSummaryErrorsData>();
    data.forEach(item => {
      const person = mappedValues.get(item.hash);

      if (person) {
        person.reasons = [...person.reasons, item.reasons[0]];
        if (item.advancedTooltip !== null) {
          person.advancedTooltip = item.advancedTooltip;
        }
      } else {
        mappedValues.set(item.hash, {
          hash: item.hash,
          name: item.name,
          status: item.status,
          reasons: [item.reasons[0]],
          advancedTooltip: item.advancedTooltip,
        });
      }
    });

    data = Array.from(mappedValues.values());
    this.dataSource.data = data;
    this._selectableData = data.filter(({ status }) => status === 'error');
    this._errorCountSubject.next(this._selectableData.length);
    this._cdr.markForCheck();
  }
}
