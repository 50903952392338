import { NgModule } from '@angular/core';

import { BodyInputModule } from './components/BodyInput';
import { CommentInputModule } from './components/CommentInput';
import { MgQuillEditorModule } from './components/QuillEditor';

@NgModule({
  imports: [BodyInputModule, CommentInputModule, MgQuillEditorModule],
  exports: [BodyInputModule, CommentInputModule, MgQuillEditorModule],
})
export class InputsModule {}
