import { Injectable } from '@angular/core';

import * as day from 'dayjs';

import * as flex_time_pb from 'minga/proto/flex_time/flex_time_pb';
import {
  FlexTimePeriod,
  FlexTimePeriodPayload,
  MinimalFlexTimePeriod,
} from 'minga/domain/flexTime';
import { FlexTimeManager } from 'minga/proto/flex_time/flex_time_ng_grpc_pb';
import { FlexTimePeriodMapper } from 'minga/shared-grpc/flex_time';
import { dateTimeObjectToDateTimeMessage } from 'src/app/util/date';

import { ErrorHandlerService } from '../error-handler';

export interface flexCloneData {
  cloneRegistered?: boolean;
  cloneAssigned?: boolean;
}

@Injectable()
export class FlexTimePeriodService {
  /** Service Constructor */
  constructor(
    private _flexTimeManager: FlexTimeManager,
    private _errorHandler: ErrorHandlerService,
  ) {}

  public async fetchAll(
    startDate?: day.Dayjs,
    endDate?: day.Dayjs,
    // if true only returns activities created by that user
    fetchOnlyMyActivities = false,
    includeAbsentees = false,
  ): Promise<FlexTimePeriod[]> {
    try {
      const request = new flex_time_pb.ListFlexTimePeriodsRequest();
      const start = startDate || day();
      const startDateTime = dateTimeObjectToDateTimeMessage(
        start.startOf('day').toDate(),
      );
      request.setStartDate(startDateTime);

      const end = endDate || day().add(14, 'days');
      const endDateTime = dateTimeObjectToDateTimeMessage(
        end.startOf('day').toDate(),
      );

      request.setEndDate(endDateTime);

      request.setFetchOnlyMyActivities(fetchOnlyMyActivities);
      request.setIncludeAbsentees(includeAbsentees);

      const response = await this._flexTimeManager.listFlexTimePeriods(request);
      return response
        .getFlexTimePeriodsList()
        .map(period => FlexTimePeriodMapper.fromProto(period));
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch all flex time periods',
        error,
        true,
      );
    }
  }

  public async fetchAllMinimal(
    startDate?: day.Dayjs,
    endDate?: day.Dayjs,
  ): Promise<MinimalFlexTimePeriod[]> {
    try {
      const request = new flex_time_pb.ListFlexTimePeriodsRequest();
      const start = startDate || day();
      const startDateTime = dateTimeObjectToDateTimeMessage(
        start.startOf('day').toDate(),
      );
      request.setStartDate(startDateTime);

      const end = endDate || day().add(14, 'days');
      const endDateTime = dateTimeObjectToDateTimeMessage(
        end.startOf('day').toDate(),
      );

      request.setEndDate(endDateTime);

      const response = await this._flexTimeManager.listMinimalFlexTimePeriods(
        request,
      );
      return response
        .getFlexTimePeriodsList()
        .map(period => FlexTimePeriodMapper.fromMinimalProto(period));
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch all flex time periods',
        error,
        true,
      );
    }
  }

  public async fetch(id: number): Promise<FlexTimePeriod> {
    try {
      const request = new flex_time_pb.GetFlexTimePeriodRequest();
      request.setId(id);
      const response = await this._flexTimeManager.getFlexTimePeriod(request);
      return FlexTimePeriodMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch flex time period',
        error,
        true,
      );
    }
  }

  public async create(
    period: FlexTimePeriodPayload,
    cloneData?: flexCloneData,
  ): Promise<FlexTimePeriod> {
    try {
      const request = new flex_time_pb.UpsertFlexTimePeriodRequest();
      request.setFlexTimePeriod(FlexTimePeriodMapper.toProto(period as any));
      let response: flex_time_pb.FlexTimePeriod;
      if (cloneData) {
        request.setCloneRegistered(cloneData.cloneRegistered);
        request.setCloneAssigned(cloneData.cloneAssigned);
        response = await this._flexTimeManager.cloneFlexTimePeriod(request);
      } else {
        response = await this._flexTimeManager.upsertFlexTimePeriod(request);
      }
      return FlexTimePeriodMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to create flex time period',
        error,
        true,
      );
    }
  }

  public async update(period: FlexTimePeriodPayload): Promise<FlexTimePeriod> {
    try {
      const request = new flex_time_pb.UpsertFlexTimePeriodRequest();
      request.setFlexTimePeriod(FlexTimePeriodMapper.toProto(period as any));
      const response = await this._flexTimeManager.upsertFlexTimePeriod(
        request,
      );
      return FlexTimePeriodMapper.fromProto(response);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to update flex time period',
        error,
        true,
      );
    }
  }

  public async delete(id: number): Promise<boolean> {
    try {
      const request = new flex_time_pb.DeleteFlexTimePeriodRequest();
      request.setId(id);
      await this._flexTimeManager.deleteFlexTimePeriod(request);
      return true;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to delete flex time period',
        error,
        true,
      );
    }
  }

  /**
   * Standardize the label and sorting of periods in Select list
   * Defaults to not showing date and sorting chronological
   */
  public formatPeriodsOptions(
    periods: FlexTimePeriod[] | MinimalFlexTimePeriod[],
    includeDateLabel = true,
  ): { value: number; label: string }[] {
    return (periods || [])
      .filter(p => p)
      .sort((a: any, b: any) => {
        return a?.date - b?.date;
      })
      .map(period => {
        const date = period.date
          ? ` - ${day(period.date).format('MMM D')}`
          : '';
        return {
          label: `${period.title}${includeDateLabel ? date : ''}`,
          value: period.id,
        };
      });
  }

  /**
   * 1. Try and get a period that's in progress
   * 2. Next try and get an upcoming period
   * 3. Fallback to any period
   */
  public getCurrentlyInProgressPeriod(
    periods: FlexTimePeriod[] | MinimalFlexTimePeriod[],
  ): FlexTimePeriod | null | MinimalFlexTimePeriod {
    if (!periods.length) return;

    const myTime = day();

    const inProgress = periods.find(p => {
      const startTime = day(p.startTime, 'HH:mm:ss');
      const endTime = day(p.endTime, 'HH:mm:ss');
      return myTime.isSameOrAfter(startTime) && myTime.isSameOrBefore(endTime);
    });

    if (inProgress) {
      return inProgress;
    }

    const upcoming = periods.find(p => {
      const startTime = day(p.startTime, 'HH:mm:ss');
      return myTime.isSameOrBefore(startTime);
    });

    if (upcoming) {
      return upcoming;
    }

    return periods[0];
  }
}
