import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { MgButtonModule } from 'minga/app/src/app/button';
import { FileInputUploadModule } from 'minga/app/src/app/components/Input/FileInputUpload';
import { LogoModule } from 'minga/app/src/app/components/Logo';
import { MgMessageBoxModule } from 'minga/app/src/app/components/MessageBox/';
import { MgExpansionPanelModule } from 'minga/app/src/app/components/MgExpansionPanel';
import { MgImageModule } from 'minga/app/src/app/components/MgImage';
import { MgIconModule } from 'minga/app/src/app/icon';
import { PermissionsModule } from 'minga/app/src/app/permissions';
import { MgPipesModule } from 'minga/app/src/app/pipes';
import { MultiSelectRoleModule } from 'minga/app/src/app/roles/components/MultiSelectRole';
import { MgSpinnerModule } from 'minga/app/src/app/spinner';

import { MMSettingService } from '@modules/minga-manager/services';

import { FormModule } from '@shared/components/form';
import { GenericModule } from '@shared/components/generic';
import { ManagerOverlayModule } from '@shared/components/manager-overlay';
import { SettingsModule } from '@shared/components/settings/settings.module';
import { TooltipModule } from '@shared/components/tooltip';

import { MmDashboardModule } from '../mm-dashboard/mm-dashboard.module';
import { MmDatePresetsModule } from '../mm-date-presets/mm-date-presets.module';
import { MmDatePresetsService } from '../mm-date-presets/services/mm-date-presets.service';
import { MmSettingsComponent } from './mm-settings.component';

@NgModule({
  imports: [
    // Minga dependencies
    ManagerOverlayModule,
    MgPipesModule,
    MgIconModule,
    MgButtonModule,
    PermissionsModule,
    MultiSelectRoleModule,
    MgMessageBoxModule,
    MgExpansionPanelModule,
    FileInputUploadModule,
    MgImageModule,
    LogoModule,
    MgSpinnerModule,
    SettingsModule,
    TooltipModule,
    FormModule,
    MmDashboardModule,
    GenericModule,
    MmDatePresetsModule,

    // External dependencies
    CommonModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    MatIconModule,
    FormsModule,
    RouterModule,
    MatExpansionModule,
    TranslateModule,
  ],
  declarations: [MmSettingsComponent],
  providers: [MMSettingService, MmDatePresetsService],
  exports: [MmSettingsComponent],
})
export class MmSettingsModule {}
