import { Pipe } from '@angular/core';
import { PeopleFacadeService, Person } from 'minga/app/src/app/people';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Convenience pipe for getting Person from a person's hash.
 */
@Pipe({ name: 'mgPerson' })
export class MgPersonPipe {
  constructor(private people: PeopleFacadeService) {}

  transform(value: any, options?: any): Observable<Person | null> {
    if (typeof value !== 'string') {
      console.warn('mgPerson requires string type. Got', value);
      return of<null>(null);
    }

    return this.people.getPeople([value]).pipe(map(result => result[0]));
  }
}
