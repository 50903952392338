import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BadgeManager {
  private _badgeSubjectMap: Map<string, BehaviorSubject<number>>;

  constructor() {
    this._badgeSubjectMap = new Map();
  }

  private _getSubject(name: string) {
    if (!this._badgeSubjectMap.has(name)) {
      this._badgeSubjectMap.set(name, new BehaviorSubject(0));
    }

    // We set it if it's not set above so this is safe.
    return <BehaviorSubject<number>>this._badgeSubjectMap.get(name);
  }

  setBadgeCount(name: string, count: number) {
    const subject = this._getSubject(name);

    const diff = count - subject.value;
    const absDiff = Math.abs(diff);
    const inc = diff > 0 ? 1 : -1;

    for (let n = 0; absDiff > n; n++) {
      const originalValue = subject.value;
      subject.next(originalValue + inc);
    }
  }

  getBadgeCount(name: string) {
    const subject = this._getSubject(name);
    return subject.getValue();
  }

  onBadge(name: string): Observable<number> {
    return this._getSubject(name).asObservable();
  }

  onDot(name: string): Observable<boolean> {
    return this._getSubject(name).pipe(
      filter(count => count === 0 || count === 1),
      map(count => !!count),
    );
  }
}
