export enum SystemAlertModalType {
  DEFAULT, // no icon auto close
  ERROR, // error icon
  WARNING, // warning icon
  SUCCESS, // success icon
}

export enum SystemAlertModalHangTime {
  SHORT = '2000',
  LONG = '5000',
}

export enum SystemAlertModalIconType {
  ICON = 'ICON',
  ASSET = 'ASSET',
}

export const MODAL_TYPE_DEFAULTS: Record<SystemAlertModalType, any> = {
  [SystemAlertModalType.ERROR]: {
    icon: 'warning-o',
    iconColor: 'error',
    hangTime: null,
    closeBtn: 'Close',
  },
  [SystemAlertModalType.WARNING]: {
    icon: 'mg-warning',
    iconColor: 'warn',
    hangTime: null,
    closeBtn: 'Close',
  },
  [SystemAlertModalType.SUCCESS]: {
    icon: 'resolved-o',
    iconColor: 'success',
    hangTime: null,
    closeBtn: 'Close',
  },
  [SystemAlertModalType.DEFAULT]: {
    hangTime: null,
    closeBtn: 'Close',
  },
};

/**
 * This is shared amongst PeopleSelector, flex-time, kiosk… etc. This might not
 * be the best home for this.
 */
export enum CloseTimeoutDuration {
  SHORT = 600, // 0.6s
  MEDIUM = 1500, // 1.5 seconds
  LONG = 5000, // 5 seconds
}
