<div
  *ngIf="consequenceType$ | async as consequenceType"
  fxLayout="column"
  fxLayoutGap="12px">
  <mg-form-text-input
    *ngIf="consequenceType.addNotes"
    [control]="group.get(CONSEQUENCE_FIELDS.NOTE)"
    [label]="MESSAGES.FORM_LABEL_NOTE">
  </mg-form-text-input>
  <mg-form-date
    *ngIf="consequenceType.enableDueDate"
    [label]="MESSAGES.FORM_LABEL_DUE_DATE"
    [minDate]="today"
    [control]="group.get(CONSEQUENCE_FIELDS.DATE)"
    [makeEndOfDay]="true"></mg-form-date>
</div>
