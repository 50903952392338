import { from } from 'rxjs';

import { waitForCordovaDeviceReady } from '../device-ready';
import { CordovaPluginDevice } from './device.types';

/**
 * To be used internally within this file, do not expose.
 */
const getCordovaDevicePlugin = async () => {
  try {
    await waitForCordovaDeviceReady();
    const device = (window as any)?.device as CordovaPluginDevice;
    return device || null;
  } catch (error) {
    console.error('getCordovaDevicePlugin() error:', error);
  }
};

export const device$ = from(getCordovaDevicePlugin());

export const getDevice = async () => {
  const device = await getCordovaDevicePlugin();
  return device ?? null;
};

export const getDeviceUUID = async () => {
  const device = await getDevice();
  return device.uuid ?? '';
};

export const getDeviceModel = async () => {
  const device = await getDevice();
  return device.model ?? '';
};

/**
 * @deprecated Use `getDeviceUUID` instead.
 */
export const _getDeviceUUID = (): string => {
  if (window.device && window.device.uuid) {
    return window.device.uuid;
  } else {
    return '';
  }
};

/**
 * @deprecated Use `getDeviceModel` instead.
 */
export const _getDeviceModel = (): string => {
  if (window.device && window.device.model) {
    return window.device.model;
  } else {
    return '';
  }
};
