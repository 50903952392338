import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { DEFAULT_PS_FORM_FILTERS, DEFAULT_PS_SEARCH_TOOLS } from '../constants';
import {
  PeopleSelectorForms,
  PsFormName,
  PsFormsConfigPageDefinitions,
  PsFormsPageType,
} from '../types';

export class PsFormPages<FormName extends PsFormName = any> {
  /** Active Form Page */
  private readonly _activePathSubj = new BehaviorSubject<string>('');
  public readonly activePath$ = this._activePathSubj.asObservable();

  /** Black listed pages store */
  private readonly _disabledPagesSubject = new BehaviorSubject<
    Set<keyof PeopleSelectorForms[FormName]['pages']>
  >(new Set());

  /** Disabled pages store */
  private readonly _pagesBlacklistSubj = new BehaviorSubject<string[]>([]);
  public readonly disabledPages$ = this._disabledPagesSubject
    .asObservable()
    .pipe(map(set => Array.from(set)));

  /** Pages store */
  private readonly _pagesSubj =
    new BehaviorSubject<PsFormsConfigPageDefinitions | null>(null);
  public readonly pages$ = combineLatest([
    this._pagesSubj.asObservable(),
    this._pagesBlacklistSubj.asObservable(),
  ]).pipe(
    map(([pages, pagesBlacklist]) => {
      return Object.keys(pages)
        .filter(page => !pagesBlacklist.includes(page))
        .map(page => {
          const {
            text,
            type,
            maxSelection = 0,
            isReadOnly = false,
            searchTools = DEFAULT_PS_SEARCH_TOOLS,
            filters = DEFAULT_PS_FORM_FILTERS,
            tooltip,
          } = pages[page];
          return {
            path: page as keyof PeopleSelectorForms[FormName]['pages'],
            type,
            text,
            maxSelection,
            isReadOnly,
            searchTools,
            filters,
            tooltip,
          };
        });
    }),
  );

  public readonly isMultiPage$ = this.pages$.pipe(
    map(pages => pages?.length > 1),
  );

  /** Active Page */
  public readonly activePage$ = combineLatest([
    this.pages$,
    this.activePath$,
  ]).pipe(
    map(([pages, activePath]) => pages.find(page => page.path === activePath)),
  );
  public readonly activePageType$ = this.activePage$.pipe(map(p => p?.type));

  /** Class Constructor */
  constructor(pages: PsFormsConfigPageDefinitions) {
    this._pagesSubj.next(pages);
    const first = Object.keys(pages)[0];
    this._activePathSubj.next(first);
  }

  public reset() {
    this._disabledPagesSubject.next(new Set());
  }

  public getActivePath(): string {
    return this._activePathSubj.getValue();
  }

  public setActive(
    page: keyof PeopleSelectorForms[FormName]['pages'] | string,
  ): void {
    this._activePathSubj.next(page as string);
  }

  public addDisabledPage(
    page:
      | keyof PeopleSelectorForms[FormName]['pages']
      | Array<keyof PeopleSelectorForms[FormName]['pages']>,
  ) {
    const set = this._disabledPagesSubject.getValue();

    if (Array.isArray(page)) {
      page.forEach(p => set.add(p));
    } else {
      set.add(page);
    }

    this._disabledPagesSubject.next(set);
  }

  public getActivePageType(): PsFormsPageType {
    return (
      this._pagesSubj.getValue()[this._activePathSubj.getValue()]?.type ??
      'search'
    );
  }

  public setPagesBlacklist(blacklist: string[]) {
    this._pagesBlacklistSubj.next(blacklist);
  }
}
