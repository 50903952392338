import { KioskFlexTypeData } from './kiosk-flextime.types';
import { KioskHallPassSummaryData } from './kiosk-hall-pass.types';

export type KioskCheckInFormSteps = any;

export type KioskCheckInTypeData = {
  enableStudentPhoto: boolean;
  hallPassId?: number;
};

export type KioskCheckInData = KioskCheckInTypeData | KioskFlexTypeData;

// we only ever show summary data when a check in generates a hall pass via automation
export type KioskCheckInSummaryData = KioskHallPassSummaryData | null;

export enum CheckInType {
  CHECK_IN = 'check-in',
  FLEX = 'flex',
}
