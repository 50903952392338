import { Pipe, PipeTransform } from '@angular/core';

import * as day from 'dayjs';

/**
 * Expects time string to come in 24 hr format eg. 13:00:00
 */
@Pipe({ name: 'formatTime' })
export class MgFormatTimePipe implements PipeTransform {
  transform(time: string): string {
    const formatted = this._getTimeFormatted(time);
    return formatted;
  }

  private _getTimeFormatted(time: string): string {
    if (!time) return '';
    const [hr = '00', min = '00', sec = '00'] = time.split(':');
    return day(`${hr}:${min}:${sec}`, 'HH:mm:ss').format('h:mm a');
  }
}
