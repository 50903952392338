<mat-drawer-container
  class="mg-sidenav-container"
  [class.fixed]="fixed"
  [class.mobile]="mobileFlag">
  <mat-drawer-content class="mg-sidenav-content">
    <ng-content></ng-content>
  </mat-drawer-content>

  <mat-drawer
    #sidenav
    *ngIf="isMobile"
    class="mg-sidenav"
    [class.fixed]="fixed"
    [class.disabled]="disabled"
    [mode]="mode"
    [opened]="!fixed || open"
    [position]="'end'"
    (keydown.escape)="(escapeKeyCloseNav)"
    (openedChange)="onOpenedChange($event)"
    (swiperight)="onSwipeRight()">
    <div
      class="close-icon-wrap"
      (click)="closeSideNav()">
      <div
        class="close-icon"
        [class.open]="!fixed || open">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="header">
      <ng-content select="mg-sidenav-tabs-header"></ng-content>
    </div>
    <div class="nav-item-wrap">
      <ng-container *ngFor="let tabLabel of tabLabels; let index = index">
        <div
          matRipple
          class="nav-item"
          [class.active]="selectedIndex == index"
          [innerHTML]="tabLabel"
          (click)="onNavItemClick(index)"></div>
      </ng-container>
    </div>
    <div class="related-pages-menu">
      <div class="nav-item-wrap">
        <ng-container
          *ngFor="let item of relatedPagesMenu | async; let index = index">
          <div
            matRipple
            class="nav-item"
            [class.active]="selectedIndex == index"
            [innerHTML]="item.title"
            (click)="goToRoute(item.path)"></div>
        </ng-container>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
