import { Pipe, PipeTransform } from '@angular/core';

/**
 * Accepts a string and returns a slugified version of it.
 * eg. 'Hello World' => 'hello-world'
 */
@Pipe({
  name: 'slugify',
})
export class SlugifyPipe implements PipeTransform {
  transform(str: string): string {
    if (!str || typeof str === 'object') return '';
    return str.toString().toLowerCase().replace(/\s+/g, '-');
  }
}
