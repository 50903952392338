import { Injectable } from '@angular/core';

import { filter, first } from 'rxjs/operators';

import { MessagingFacade } from 'minga/app/src/app/modules/direct-message/store';
import { LoginManager } from 'minga/proto/gateway/login_ng_grpc_pb';
import {
  OnStartupRequest,
  OnStartupResponse,
} from 'minga/proto/gateway/login_pb';
import { GetMingaGroupsResponse } from 'minga/proto/gateway/mingaGroup_pb';
import { Notification } from 'minga/proto/gateway/notification_pb';
import { MingaPermission } from 'minga/util';
import { GroupsFacadeService, GroupsService } from 'src/app/groups/services';
import { AppConfigService } from 'src/app/minimal/services/AppConfig';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { NotificationListener } from 'src/app/notifications';
import { BadgeNotificationListenerService } from 'src/app/notifications/badge';
import { BehaviorsNotificationListenerService } from 'src/app/notifications/behaviors';
import { NotificationClickHandler } from 'src/app/notifications/clickHandler';
import { InviteNotificationListenerService } from 'src/app/notifications/invites';
import { LegacyNotificationHandler } from 'src/app/notifications/legacy';
import { PointsNotificationListenerService } from 'src/app/notifications/points';
import { PermissionsService } from 'src/app/permissions';
import { AppUpdateService } from 'src/app/services/AppUpdate/AppUpdate.service';
import { NotificationBadgeSyncService } from 'src/app/services/NotificationBadgeSync';

@Injectable({ providedIn: 'root' })
export class StartupService {
  constructor(
    private legacyNotificationHandler: LegacyNotificationHandler,
    private notificationClickHandler: NotificationClickHandler,
    private pointsNotificationHandler: PointsNotificationListenerService,
    private inviteNotificationHandler: InviteNotificationListenerService,
    private badgeNotificationHandler: BadgeNotificationListenerService,
    private messagingService: MessagingFacade,
    private notificationListener: NotificationListener,
    private appConfig: AppConfigService,
    private appUpdater: AppUpdateService,
    private notificationBadgeSyncService: NotificationBadgeSyncService,
    private groupsFacade: GroupsFacadeService,
    private authInfo: AuthInfoService,
    private behaviorsNotificationhandler: BehaviorsNotificationListenerService,
    private loginService: LoginManager,
    private groupsService: GroupsService,
    private _permissionsService: PermissionsService,
  ) {}

  /**
   * Things that need to get started up after the initial load of the app, once
   * we have a verified user
   */
  async postStartup() {
    this.appUpdater.init();
    // not awaiting on purpose.
    this.loadPostStartupData();
    this.notificationBadgeSyncService.syncBadgeCount();
  }

  async loadPostStartupData() {
    // load some data from the backend that the client will need in the background
    const data = await this.loginService.onStartup(new OnStartupRequest());
    const groupsMessage = data.getMingaGroups();
    const notificationMessage = data.getUnreadNotificationsList();
    this.loadGroupsData(groupsMessage);
    this.loadMessagingData(data);
    this.loadNotificationData(notificationMessage);
  }

  async loadGroupsData(message: GetMingaGroupsResponse) {
    const groupData = this.groupsService.mapAllGroupResponse(message);
    this.groupsFacade.dispatchLoadGroupsSuccess(groupData.groups);
  }

  async loadNotificationData(notifications: Notification[]) {
    this.notificationListener.dispatchNotifications(notifications);
  }

  async loadMessagingData(response: OnStartupResponse) {
    this.messagingService.updateAnyUnreadStatus(
      response.getHasUnreadDirectMessages(),
    );
  }

  /**
   * Things needed to run on initial startup
   */
  async startup() {
    // Unused here. Only needed for construction to setup subscriptions
    this.legacyNotificationHandler;
    this.notificationClickHandler;
    this.pointsNotificationHandler;
    this.inviteNotificationHandler;
    this.badgeNotificationHandler;
    this.behaviorsNotificationhandler;

    this.appConfig.ensureCountrySet();
    this.authInfo.authInfo$
      .pipe(
        filter(payload => !!payload?.person),
        first(),
      )
      .subscribe(payload => {
        // if user is in kiosk mode we dont want any kind of notifications to popups
        // or app update popup
        if (
          !this._permissionsService.hasPermission(MingaPermission.KIOSK_MANAGE)
        ) {
          this.postStartup();
        }
      });
  }
}
