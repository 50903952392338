import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MgButtonModule } from 'minga/app/src/app/button';
import { PersonAvatarModule } from 'minga/app/src/app/components/PersonAvatar';
import { MgTextareaModule } from 'minga/app/src/app/components/Textarea';
import { MgFormFieldModule } from 'minga/app/src/app/form-field';
import { MgIconModule } from 'minga/app/src/app/icon';
import { MentionsModule } from 'minga/app/src/app/mentions';

import { CommentInputComponent } from './CommentInput.component';

@NgModule({
  imports: [
    // Minga dependencies
    MgTextareaModule,
    MgIconModule,
    MgButtonModule,
    PersonAvatarModule,
    MgFormFieldModule,
    MentionsModule,

    // External dependencies
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    TranslateModule,
  ],
  declarations: [CommentInputComponent],
  exports: [CommentInputComponent],
})
export class CommentInputModule {}
