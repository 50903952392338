import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import * as day from 'dayjs';

import { FileInputDisplay } from 'minga/app/src/app/components/Input/FileInput';
import { IMingaFile } from 'minga/app/src/app/models/MingaFiles';
import { FileDetails as FilesDetailsMessage } from 'minga/proto/gateway/file_pb';

import { FileListMessages } from './FileList.constants';

export enum FileListStyleEnum {
  CARDS = 'CARDS',
  LIST = 'LIST',
}

@Component({
  selector: 'mg-file-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './FileList.component.html',
  styleUrls: ['./FileList.component.scss'],
})
export class FileListComponent {
  public readonly MESSAGES = FileListMessages;
  pendingFiles: File[] = [];
  fileInputDisplayType = FileInputDisplay.BUTTON;
  hasDownloadInProgress = false;
  hoverRow: number = -1;

  @Input()
  enableDelete: boolean = false;

  @Input()
  enableCreateFile: boolean = false;

  @Input()
  enableCreateURL: boolean = false;

  @Input()
  files: IMingaFile[] = [];

  @Input()
  listStyle: FileListStyleEnum = FileListStyleEnum.LIST;

  @Input()
  loading: boolean = true;

  @Input()
  downloadingFile: boolean = false;

  @Output()
  uploadFile = new EventEmitter<any>();

  @Output()
  uploadUrl = new EventEmitter<any>();

  @Output()
  downloadFile = new EventEmitter<FilesDetailsMessage.AsObject>();

  @Output()
  deleteFileIndex = new EventEmitter<number>();

  @Output()
  abortDownloadFileIndex = new EventEmitter<number>();

  @Output()
  openFileIndex = new EventEmitter<number>();

  @ViewChild('inputEl', { static: true })
  private inputEl: ElementRef;

  getFileDateString(timestamp: number): string {
    return day(timestamp).format('LLL');
  }

  onFileChange(e) {
    this.uploadFile.emit(this.pendingFiles);
  }

  hasStatus(file: IMingaFile) {
    const hasStatus =
      file.status &&
      !file.status.canceled &&
      !file.status.error &&
      (file.status.complete || file.status.progress < 100);
    return hasStatus;
  }

  getGroupFilesString() {
    return JSON.stringify(this.files);
  }

  onAnchorClick(event, mingaFile: IMingaFile) {
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }

    this.downloadFile.emit(mingaFile.file);
  }

  getHrefValue(mingaFile: IMingaFile) {
    const noHref = 'javascript:;';
    if (!window.MINGA_APP_BROWSER) {
      return noHref;
    }
    if (mingaFile.file.url) {
      return mingaFile.file.url;
    } else if (mingaFile.file.filePath) {
      return mingaFile.file.filePath;
    } else {
      return noHref;
    }
  }

  getTitleValue(mingaFile: IMingaFile) {
    const href = this.getHrefValue(mingaFile);

    if (href == 'javascript:;') {
      return mingaFile.file.name;
    } else {
      return href;
    }
  }
}
