export const makeRatingRequest = async (): Promise<boolean> => {
  const LaunchReview = getLaunchReviewPlugin();
  if (!LaunchReview) return false;
  if (isRatingSupported()) return rating();
  return launch();
};

const getLaunchReviewPlugin = () => {
  if ((window as any).LaunchReview) {
    return (window as any).LaunchReview;
  } else {
    return null;
  }
};

const isRatingSupported = () => {
  const LaunchReview = getLaunchReviewPlugin();
  if (LaunchReview) {
    return LaunchReview.isRatingSupported();
  }
  return false;
};

const rating = async (): Promise<boolean> => {
  const LaunchReview = getLaunchReviewPlugin();
  if (!LaunchReview) {
    return false;
  }
  return new Promise<boolean>((resolve, reject) => {
    LaunchReview.rating(
      (status: string) => {
        // On Android, once dialog closed returns 'requested'
        // On iOS, when showing 'requested', if reviewed 'shown', else
        // 'dismissed'
        // Returning the dialog was shown to mark as seen, but currently not
        // knowing if user has already reviewed the app..
        setTimeout(() => resolve(true));
      },
      (err: string) => {
        setTimeout(() => resolve(false));
        console.error('Error launching rating dialog: ' + err);
      },
    );
  });
};

const launch = async (): Promise<boolean> => {
  const LaunchReview = getLaunchReviewPlugin();
  if (!LaunchReview) return false;
  return new Promise<boolean>((resolve, reject) => {
    LaunchReview.launch(
      () => {
        setTimeout(() => resolve(true));
      },
      (err: string) => {
        setTimeout(() => resolve(false));
      },
    );
  });
};
