<div class="top-nav-content">
  <div class="top-nav-left">
    <ng-content select="mg-top-nav-left"></ng-content>
  </div>

  <div class="top-nav-mid">
    <ng-content select="mg-top-nav-mid"></ng-content>
  </div>

  <div class="top-nav-right">
    <ng-content select="mg-top-nav-right"></ng-content>
  </div>
</div>
