import { NgModule } from '@angular/core';

import { SearchActiveRouteComponent } from './components/SearchActiveRoute.component';
import { SearchContainerComponent } from './containers/SearchContainer.component';
import { SearchAreaDirective } from './directives/SearchArea.directive';

@NgModule({
  declarations: [
    SearchAreaDirective,
    SearchContainerComponent,
    SearchActiveRouteComponent,
  ],
  exports: [
    SearchAreaDirective,
    SearchContainerComponent,
    SearchActiveRouteComponent,
  ],
})
export class SearchModule {}
