import { Pipe, PipeTransform } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

export interface ObsWithStatusResult<T> {
  loading?: boolean;
  value?: T;
  error?: string;
}

/**
 * Array Includes
 */
@Pipe({ name: 'obsLoading' })
export class LoadingPipe implements PipeTransform {
  defaultError = 'Something went wrong.';

  transform<T = any>(val: Observable<T>): Observable<ObsWithStatusResult<T>> {
    return val.pipe(
      map((value: any) => {
        return {
          loading: value.type === 'start',
          error: value.type === 'error' ? this.defaultError : '',
          value: value.type ? value.value : value,
        };
      }),
      startWith({ loading: true }),
      catchError(error =>
        of({
          loading: false,
          error: typeof error === 'string' ? error : this.defaultError,
        }),
      ),
    );
  }
}
