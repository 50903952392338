import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import { MgIconComponent } from '../../icon/MgIcon.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mg-button-icon',
  templateUrl: './IconButton.component.html',
  styleUrls: ['./IconButton.component.scss'],
})
export class IconButton {
  @ViewChild('iconElement', { static: true })
  iconElement?: MgIconComponent;

  @ViewChild('activeIconElement', { static: true })
  activeIconElement?: MgIconComponent;

  @HostBinding('class.disabled')
  @Input()
  disabled: boolean = false;

  @Input()
  color: string = 'primary';

  @Input()
  icon: string = '';

  @Input('no-active')
  noActive: boolean = false;

  @Input('icon-namespace')
  iconNamespace: string = '';

  constructor(private element: ElementRef) {}
}
