import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export interface IEmptyStateIcon {
  icon: string;
  sideText?: string;
  text?: string;
  image?: string;
}

@Component({
  selector: 'mg-empty-state',
  templateUrl: './EmptyState.component.html',
  styleUrls: ['./EmptyState.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
  @Input()
  icons: IEmptyStateIcon[] = [];

  @Input()
  title = '';

  @Input()
  subtitle = '';

  @Input()
  actionButtonLabel = '';

  @Input()
  imageAsset = '';

  @Output()
  handleAction = new EventEmitter<void>(undefined);
}
