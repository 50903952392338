import { from, fromEventPattern } from 'rxjs';
import { first, share, take } from 'rxjs/operators';

/**
 * To be used internally within this file, do not expose.
 */
const deviceReadyPromise = new Promise(resolve => {
  document.addEventListener('deviceready', resolve, false);
});

export const waitForCordovaDeviceReady = async (): Promise<void> => {
  if (!window.MINGA_APP_BROWSER) await deviceReadyPromise;
};

export const cordovaDeviceReady$ = from(waitForCordovaDeviceReady()).pipe(
  take(1),
);

const _addDeviceReadyHandler = handler => {
  document.addEventListener('deviceready', handler);
};

const _removeDeviceReadyHandler = handler => {
  document.removeEventListener('deviceready', handler);
};

export const onDeviceReady$ = fromEventPattern(
  _addDeviceReadyHandler,
  _removeDeviceReadyHandler,
).pipe(first(), share());
