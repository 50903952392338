// @NOTE: We need our svg icons to maintain the viewBox. There is a patch
//        coming from the angular team that should fix this, but it has been
//        inactive for awhile (https://github.com/angular/material2/issues/5188)
//
//        This monkey wrench patch is based on this closed pull request:
//        https://github.com/angular/material2/pull/5489

import { MatIconRegistry } from '@angular/material/icon';

let original_toSvgElement = (<any>MatIconRegistry).prototype._toSvgElement;

(<any>MatIconRegistry).prototype._toSvgElement = function (element: Element) {
  let svg = original_toSvgElement.call(<any>this, element);

  let viewBox = element.getAttribute('viewBox');
  if (viewBox) {
    svg.setAttribute('viewBox', viewBox);
  }

  return svg;
};
