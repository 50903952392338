import { FormInputTypes } from '../types';

export enum FormMessages {}

/**
 * Error Messages
 *
 * @see https://v9.angular.io/api/forms/Validators
 */
export enum FormErrorMessages {
  default = 'Invalid Input',
  required = 'This is a required field',
  min = 'number is smaller than min value of {max}', // {min: number; actual: number;}}
  max = 'number is larger than max value of {max}', // {max: number; actual: number;}}
  email = 'incorrect email format',
  minlength = 'required length is {requiredLength}, you have {actualLength}', // {requiredLength: number; actualLength: number;}}
  maxlength = 'max length is {requiredLength}, you have {actualLength}', // {requiredLength: number; actualLength: number;}}
  pattern = 'input entered does not match pattern', // {pattern: {requiredPattern: string; actualValue: string}}
  requiredTrue = '', // used for checkboxes, like 'accept terms before continuing'
  positiveInteger = 'This must be a positive number', // used in tandem with `positiveInteger` validator for requiring positive integer
  dateInPast = "This can't be a date in the past",
  invalidTimeComparison = 'End time cannot be before the start time',
  invalidDateTimeInPast = 'Date/time is in the past',
  invalidEmails = 'All values must be valid email addresses',
  invalidPhoneNumbers = 'All values must be valid phone numbers',
}

export const COLOR_PICKER_PRESETS: Record<string, string[]> = {
  red: ['#EA4335', '#FFBDBD', '#DF6565', '#964D47', '#7D0A00'],
  orange: ['#FF6D01', '#FFCBA5', '#F09959', '#AB612A', '#6A3208'],
  yellow: ['#FFB700', '#FFE7A0', '#F0CA5B', '#AF9B5E', '#6A4F00'],
  green: ['#1CB445', '#B9FFCB', '#6EE28D', '#60936D', '#035A1A'],
  turquoise: ['#10BDCA', '#C4FBFF', '#83E2E9', '#468A90', '#02484D'],
  blue: ['#1669F1', '#C9DDFF', '#6396E9', '#395E9B', '#002666'],
  purple: ['#7126EB', '#D8C0FF', '#9F77E1', '#62488C', '#2B0071'],
  pink: ['#F846D1', '#FFC2F1', '#EC76D2', '#A54B91', '#6C0055'],
};

export const TEXT_INPUT_CLEARABLE_TYPES: FormInputTypes[] = ['text', 'time'];
