<form
  *ngIf="error"
  class="landing-form"
  fxLayout="column">
  <h1 class="mg-centered landing-title1">Sorry! Something went wrong</h1>
  <p class="mg-centered">{{ error }}</p>

  <div fxFlex></div>

  <p class="mg-centered">
    <mg-button
      type="outline"
      class="large"
      (click)="clickExit()">
      Exit
    </mg-button>
  </p>
</form>
