<ng-template #loadingTpl>
  <div class="mg-centered">
    <mg-spinner></mg-spinner>
  </div>
</ng-template>
<div class="feed-items">
  <mg-paged-scroller
    #streamScroller
    [dataSource]="ds">
    <ng-template
      let-item
      let-itemId="itemId"
      #streamItemTemplate>
      <mg-event-short-card
        [content]="item"
        [context]="item.contextHash"
        [mgContentLink]="item.contextHash"></mg-event-short-card>
    </ng-template>

    <ng-container mg-empty-state>
      <div
        mg-stream-empty
        class="mg-centered small-user-content-page no-events-content">
        <div class="calendar-image"></div>
        <p><strong>It looks like there aren't any events.</strong></p>
      </div>
    </ng-container>
  </mg-paged-scroller>
</div>
