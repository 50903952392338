<div *ngIf="showComponent$ | async">
  <mg-system-alert
    [type]="data.type"
    [message]="data.message"
    [isAlert]="false"
    [buttonText]="data.action?.label"
    [dismissable]="DISMISSABLE_TYPES.includes(data.type)"
    (dismissSnackbar)="close()">
  </mg-system-alert>
</div>
