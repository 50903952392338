<mg-group-preview
  *ngFor="let group of groups"
  [group]="group"
  (click)="groupSelect.emit(group)"
  [hasNewContent]="hasNewContent(group)"
  (leaveGroup)="onLeaveGroup(group)"
  [clickMode]="clickMode"
  (requestToJoin)="requestToJoinGroup($event)"
  (cancelRequestToJoin)="cancelRequestToJoinGroup($event)"
  (groupContextMenu)="onGroupContextMenu($event)">
</mg-group-preview>
